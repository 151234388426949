

.services-section1 h2 {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 1.5rem;
    font-weight: bold;
    color: #333;
}

.services-section1 .highlight {
    color: #7b4ff7;
    /* Purple highlight */
}

.services-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;
}

.service-card1 {
    background-color: #E1E0E0;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    text-align: center;
    
    transition: transform 0.3s ease;
}

.service-card1:hover {
    transform: translateY(-10px);
}

.service-card1 img {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.service-card1 h3 {
    font-size: 1.25rem;
    color: #333;
    padding: 5px;
}

.service-card1 p {
    font-size: 1rem;
    color: #555;
    padding: 5px;
}

@media (max-width: 768px) {
    .services-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 480px) {
    .services-grid {
        grid-template-columns: 1fr;
    }
}