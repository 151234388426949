/* Number of elements */
.ul {
  --d: 30s;
  /* Duration */

  display: grid;
  grid-template-columns: repeat(6, 1fr);
  /* Number of visible images */
  overflow: hidden;
  -webkit-mask: linear-gradient(90deg, #0000, #000 5% 95%, #0000);
  margin: 0;
  padding: 0;

  list-style: none;
}

.ul>.li {
  grid-area: 1 / 1;
  animation: r var(--d) linear infinite;
  font-family: 'proxima-nova', sans-serif;

  border: .5rem solid #0000;
  color: #fff;
  display: flex;
  align-items: center;
  text-align: center;
  /* uli: #7F3E98 padding-box; */
  border-radius: 1rem;
}

/* Generate the animation-delay for each list item */
.ul>.li:nth-child(1) {
  animation-delay: calc((1 - 1) / 7 * var(--d));
}

.ul>.li:nth-child(2) {
  animation-delay: calc((1 - 2) / 7 * var(--d));
}

.ul>.li:nth-child(3) {
  animation-delay: calc((1 - 3) / 7 * var(--d));
}

.ul>.li:nth-child(4) {
  animation-delay: calc((1 - 4) / 7 * var(--d));
}

.ul>.li:nth-child(5) {
  animation-delay: calc((1 - 5) / 7 * var(--d));
}

.ul>.li:nth-child(6) {
  animation-delay: calc((1 - 6) / 7 * var(--d));
}

.ul>.li:nth-child(7) {
  animation-delay: calc((1 - 7) / 7 * var(--d));
}

@keyframes r {
  14.2857143% {
    transform: translate(-100%);
  }

  14.2957143% {
    transform: translate(600%);
  }
}

.uli .li {
  animation-direction: reverse;
}


.uli {
  --d: 20s;
  /* Duration */

  display: grid;
  grid-template-columns: repeat(6, 1fr);
  /* Number of visible images */
  overflow: hidden;
  -webkit-mask: linear-gradient(90deg, #0000, #000 5% 95%, #0000);
  margin: 0;
  padding: 0;
  list-style: none;
}

.uli>.li:nth-child(1) {
  animation-delay: calc((1 - 1) / 7 * var(--d));
}

.uli>.li:nth-child(2) {
  animation-delay: calc((1 - 2) / 7 * var(--d));
}

.uli>.li:nth-child(3) {
  animation-delay: calc((1 - 3) / 7 * var(--d));
}

.uli>.li:nth-child(4) {
  animation-delay: calc((1 - 4) / 7 * var(--d));
}

.uli>.li:nth-child(5) {
  animation-delay: calc((1 - 5) / 7 * var(--d));
}

.uli>.li:nth-child(6) {
  animation-delay: calc((1 - 6) / 7 * var(--d));
}

.uli>.li:nth-child(7) {
  animation-delay: calc((1 - 7) / 7 * var(--d));
}

.uli>.li {
  grid-area: 1 / 1;
  animation: r var(--d) linear infinite reverse;
  font-family: system-ui;


  border: .5rem solid #0000;
  text-align: center;
  display: flex;
  align-items: center;
  color: #fff;
  border-radius: 1rem;
}

@media (max-width: 991px) {
  .ul {
    --d: 30s;
    /* Duration */

    display: grid;
    grid-template-columns: repeat(3, 1fr) !important;
    overflow: hidden;
    -webkit-mask: linear-gradient(90deg, #0000, #000 5% 95%, #0000);
    margin: 0;
    padding: 0;

    list-style: none;
  }

  .uli {
    --d: 20s;
    /* Duration */

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    /* Number of visible images */
    overflow: hidden;
    -webkit-mask: linear-gradient(90deg, #0000, #000 5% 95%, #0000);
    margin: 0;
    padding: 0;
    list-style: none;
  }
}




.newul {
  --d: 30s;
  /* Duration */

  display: grid;
  grid-template-columns: repeat(6, 1fr);
  /* Number of visible images */
  overflow: hidden;
  -webkit-mask: linear-gradient(90deg, #0000, #000 5% 95%, #0000);
  margin: 0;
  padding: 0;

  list-style: none;
}

.newul>.li {
  grid-area: 1 / 1;
  animation: r var(--d) linear infinite;
  font-family: 'proxima-nova', sans-serif;

  border: .5rem solid #0000;
  color: #fff;
  display: flex;
  align-items: center;
  text-align: center;
  /* uli: #7F3E98 padding-box; */
  border-radius: 1rem;
}

/* Generate the animation-delay for each list item */
.newul>.li:nth-child(1) {
  animation-delay: calc((1 - 1) / 7 * var(--d));
}

.newul>.li:nth-child(2) {
  animation-delay: calc((1 - 2) / 7 * var(--d));
}

.newul>.li:nth-child(3) {
  animation-delay: calc((1 - 3) / 7 * var(--d));
}

.newul>.li:nth-child(4) {
  animation-delay: calc((1 - 4) / 7 * var(--d));
}

.newul>.li:nth-child(5) {
  animation-delay: calc((1 - 5) / 7 * var(--d));
}

.newul>.li:nth-child(6) {
  animation-delay: calc((1 - 6) / 7 * var(--d));
}

.newul>.li:nth-child(7) {
  animation-delay: calc((1 - 7) / 7 * var(--d));
}

@keyframes r {
  14.2857143% {
    transform: translate(-100%);
  }

  14.2957143% {
    transform: translate(600%);
  }
}