.banner-area.banner-area-three {
    background-image: url(/public/cyber23.jpg);
    height: 850px;
    padding: 0;
    position: relative;
    overflow: hidden;
}



.d-table {
  width: 100%;
  height: 100%;
}

.d-table-cell {
  vertical-align: middle;
}


img {
  max-width: 100%;
  height: auto;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.form-control {
  height: 50px;
  color: #0e0129;
  border: 1px solid #e8e8e8;
  background-color: #f2f9fc;
  border-radius: 0;
  font-size: 14px;
  padding: 10px 20px;
  width: 100%;
}
.form-control::-moz-placeholder {
  color: #495057;
}
.form-control::placeholder {
  color: #495057;
}
.form-control:focus {
  color: #000000;
  background-color: transparent;
  box-shadow: unset;
  outline: 0;
  border: 1px solid #d80650;
}

.form-control:hover:focus, .form-control:focus {
  box-shadow: unset;
}

textarea.form-control {
  height: auto;
}

.ptb-100 {
  padding-top: 100px;
  padding-bottom: 100px;
}

.pt-100 {
  padding-top: 100px;
}

.pb-100 {
  padding-bottom: 100px;
}

.pt-70 {
  padding-top: 70px;
}

.pb-70 {
  padding-bottom: 70px;
}

.mt-30 {
  margin-top: 30px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mt-100 {
  margin-top: -100px;
}

.buy-now-btn {
  right: 20px;
  z-index: 99;
  top: 50%;
  position: fixed;
  transform: translateY(-50%);
  border-radius: 30px;
  display: inline-block;
  color: #ffffff;
  background-color: #82b440;
  padding: 10px 20px 10px 42px;
  box-shadow: 0 1px 20px 1px #82b440;
  font-size: 13px;
  font-weight: 600;
}
.buy-now-btn img {
  top: 50%;
  left: 20px;
  width: 15px;
  position: absolute;
  transform: translateY(-50%);
}
.buy-now-btn:hover {
  background-color: #d80650;
  color: #ffffff;
}

/*
Bg-color Style*/
.bg-color {
  background-color: #010c16;
}

.bg-color-f9f9f9 {
  background-color: #f9f9f9;
}

/*
Default Btn Area Style*/
.default-btn {
  font-size: 16px;
  color: #ffffff;
  padding: 15px 30px;
  line-height: 1;
  transition: all 0.5s;
  text-transform: capitalize;
  position: relative;
  cursor: pointer;
  text-align: center;
  overflow: hidden;
  z-index: 1;
  background-color: #d80650;
}
.default-btn i {
  position: relative;
  top: 1px;
  margin-right: 5px;
}
.default-btn::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background-color: #0e0129;
  z-index: -1;
  transition: all 0.5s;
}
.default-btn::after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  width: 0;
  height: 100%;
  background-color: #0e0129;
  z-index: -1;
  transition: all 0.5s;
}
.default-btn.active {
  margin-left: 20px;
  color: #0e0129;
  background-color: #ffffff;
}
.default-btn:hover {
  color: #ffffff;
}
.default-btn:hover::before {
  left: auto;
  right: 0;
  width: 50%;
}
.default-btn:hover::after {
  left: 0;
  right: 0;
  width: 50%;
}

/*
Read More Area Style*/
.read-more {
  line-height: 1;
  color: #d80650;
  font-weight: 500;
  transition: all 0.5s;
  display: block;
  font-size: 13px;
  font-weight: 600;
}
.read-more i {
  position: relative;
  top: 2px;
}
.read-more:hover {
  color: #d80650;
}
.read-more:hover i {
  color: #d80650;
  animation: rotation 0.5s linear infinite;
}

/*===== DEFAULT STYLE END ======*/
/*====================================================
HOME PAGE ONE STYLE
======================================================*/
/*
Header Area Style
======================================================*/
.header-area .container-fluid {
  padding-left: 50px;
  padding-right: 50px;
}
.header-area .top-header-area {
  border-bottom: 1px solid #11132d;
  background-color: rgba(17, 19, 45, 0.2);
  padding: 15px 0;
}
.header-area .top-header-area .header-content-left li {
  display: inline-block;
  padding-right: 20px;
  color: #ffffff;
  font-size: 14px;
}
.header-area .top-header-area .header-content-left li:last-child {
  padding-right: 0;
}
.header-area .top-header-area .header-content-left li a {
  color: #ffffff;
  display: inline-block;
}
.header-area .top-header-area .header-content-left li i {
  color: #ffffff;
  margin-right: 5px;
  font-size: 15px;
  position: relative;
  top: 2px;
  transition: all 0.5s;
}
.header-area .top-header-area .header-content-left li:hover a {
  color: #d80650;
}
.header-area .top-header-area .header-content-left li:hover a i {
  color: #d80650;
}
.header-area .top-header-area .header-content-right {
  float: right;
  line-height: 1;
}
.header-area .top-header-area .header-content-right li {
  display: inline-block;
}
.header-area .top-header-area .header-content-right li a {
  color: #ffffff;
  padding: 0 10px;
}
.header-area .top-header-area .header-content-right li a i {
  font-size: 16px;
}
.header-area .top-header-area .header-content-right li:last-child a {
  padding-right: 0;
}
.header-area .top-header-area .header-content-right li:hover a {
  color: #d80650;
}
.header-area .top-header-area .header-content-right li:hover a i {
  color: #d80650;
}

/*
Nav Area Style
======================================================*/
.nav-area .container-fluid {
  padding-left: 50px;
  padding-right: 50px;
}
.nav-area .navbar-area .main-nav {
  background-color: transparent;
  box-shadow: none;
  padding-top: 0;
  padding-bottom: 0;
  border-bottom: 1px solid #11132d;
  background-color: rgba(17, 19, 45, 0.2);
}
.nav-area .navbar-area .main-nav .navbar {
  padding-left: 0;
  padding-right: 0;
}
.nav-area .navbar-area .main-nav nav .navbar-nav .nav-item a {
  font-size: 16px;
  color: #ffffff;
  text-transform: capitalize;
  transition: all 0.5s;
  margin-left: 0;
  margin: 0 10px;
  position: relative;
}
.nav-area .navbar-area .main-nav nav .navbar-nav .nav-item a i {
  font-size: 20px;
  line-height: 0;
  position: relative;
  top: 5px;
}
.nav-area .navbar-area .main-nav nav .navbar-nav .nav-item a:hover {
  color: #d80650;
}
.nav-area .navbar-area .main-nav nav .navbar-nav .nav-item a.active {
  color: #d80650;
}
.nav-area .navbar-area .main-nav nav .navbar-nav .nav-item .dropdown-menu {
  background-color: #ffffff;
  border: none;
  border-radius: 0;
  top: 100%;
  left: 0;
  padding: 0;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s;
  transform: scaleX(0);
}
.nav-area .navbar-area .main-nav nav .navbar-nav .nav-item .dropdown-menu li a {
  font-size: 14px;
  color: #0e0129;
  position: relative;
  padding: 10px 15px;
  border-bottom: 1px dashed #eeeeee;
  margin-left: 0;
  margin-right: 0;
  position: relative;
}
.nav-area .navbar-area .main-nav nav .navbar-nav .nav-item .dropdown-menu li a::before {
  content: "";
  position: absolute;
  bottom: -1px;
  right: 0;
  width: 0;
  height: 1px;
  background-color: #d80650;
  transition: all 0.5s;
}
.nav-area .navbar-area .main-nav nav .navbar-nav .nav-item .dropdown-menu li a i {
  float: right;
  top: 12px;
  transition: all 0.5s;
  font-size: 20px;
}
.nav-area .navbar-area .main-nav nav .navbar-nav .nav-item .dropdown-menu li a:hover {
  color: #d80650;
  background-color: #f3f3f3;
}
.nav-area .navbar-area .main-nav nav .navbar-nav .nav-item .dropdown-menu li a:hover::before {
  width: 100%;
  left: 0;
  right: auto;
}
.nav-area .navbar-area .main-nav nav .navbar-nav .nav-item .dropdown-menu li a.active {
  color: #d80650;
  background-color: #f3f3f3;
}
.nav-area .navbar-area .main-nav nav .navbar-nav .nav-item .dropdown-menu li .dropdown-menu {
  left: 100%;
  transform: scaleX(0);
  top: 0;
}
.nav-area .navbar-area .main-nav nav .navbar-nav .nav-item .dropdown-menu li:last-child a {
  border-bottom: none;
}
.nav-area .navbar-area .main-nav nav .navbar-nav .nav-item .dropdown-menu li:hover .dropdown-menu {
  top: 0px !important;
  transform: scaleX(1);
}
.nav-area .navbar-area .main-nav nav .navbar-nav .nav-item:hover ul {
  opacity: 1;
  visibility: visible;
  top: 100%;
  transform: scaleX(1);
}
.nav-area .navbar-area.is-sticky {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 999;
  background-color: #0e0129;
  box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.4);
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  transition: all 0.5s;
  width: 100% !important;
}
.nav-area.nav-area-three .navbar-area .main-nav {
  background-color: rgba(0, 2, 11, 0.88);
  border: none;
}
.nav-area.nav-area-three .navbar-area .main-nav .others-option .call-us a {
  color: #ffffff;
  position: relative;
  padding-left: 45px;
  line-height: 1;
  top: -4px;
}
.nav-area.nav-area-three .navbar-area .main-nav .others-option .call-us a i {
  font-size: 35px;
  color: #d80650;
  position: absolute;
  top: -10px;
  left: 0;
}
.nav-area .others-option {
  position: relative;
  top: 4px;
}
.nav-area .others-option .get-quote {
  display: inline-block;
  position: relative;
}
.nav-area .others-option .get-quote .default-btn {
  padding: 12px 20px;
}
.nav-area .others-option .burger-menu {
  display: inline-block;
  margin-right: 10px;
  cursor: pointer;
}
.nav-area .others-option .burger-menu i {
  font-size: 40px;
  color: #ffffff;
  position: relative;
  top: -2px;
  cursor: pointer;
}

/*
Banner Area Style
======================================================*/
.banner-area {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  position: relative;
  z-index: 1;
  padding-top: 220px;
  padding-bottom: 100px;
}

.banner-area .banner-text {
  position: relative;
  max-width: 690px;
  z-index: 1;
  overflow: hidden;
  margin-bottom: -8px;
}
.banner-area .banner-text span {
  color: #ffffff;
  font-size: 18px;
  display: block;
  margin-bottom: 10px;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 400;
}
.banner-area .banner-text h1 {
  font-size: 80px;
  color: #ffffff;
  margin-bottom: 25px;
  line-height: 1.1;
}
.banner-area .banner-text p {
  color: #ffffff;
  font-size: 16px;
  margin-bottom: 35px;
}
.banner-area .video-btn {
  margin: auto;
  display: block;
}
.banner-area.banner-area-two {
  padding-top: 0;
  padding-bottom: 0;
  height: 810px;
  background-color: #090218;
}
.banner-area.banner-area-two::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  animation: FocuS 1s linear 1s infinite;
}
.banner-area.banner-area-two .container-fluid {
  padding-left: 50px;
  padding-right: 50px;
}
.banner-area.banner-area-two .banner-text {
  margin-top: 100px;
}
.banner-area.banner-area-two .banner-text h1 {
  font-size: 68px;
}
.banner-area.banner-area-two .banner-site-img {
  position: relative;
  top: 60px;
}
.banner-area.banner-area-two .banner-site-img.four {
  top: -5px;
}
.banner-area.banner-area-three {
 
  height: 850px;
  padding: 0;
  position: relative;
  overflow: hidden;
}
.banner-area.banner-area-three .banner-text {
  margin: -30px auto 0;
  text-align: center;
}
.banner-area.banner-area-three .banner-shape-1 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
  z-index: -1;
  animation: FocuS 1s linear 1s infinite;
}
.banner-area.banner-area-three .banner-shape-2 {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
}
.banner-area.banner-area-three .banner-shape-3 {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
}
.banner-area.banner-area-three .banner-shape-4 {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -1;
}
.banner-area.banner-area-three .follow-us {
  position: absolute;
  top: 50%;
  right: -67px;
  transform: rotate(90deg);
}
.banner-area.banner-area-three .follow-us ul {
  line-height: 1;
}
.banner-area.banner-area-three .follow-us ul li {
  display: inline-block;
  color: #ffffff;
  padding: 0 2px;
}
.banner-area.banner-area-three .follow-us ul li a i {
  color: #ffffff;
  font-size: 20px;
  line-height: 30px;
  width: 30px;
  height: 30px;
  background-color: #d80650;
  text-align: center;
  border-radius: 4px;
  transform: rotate(-90deg);
  transition: all 0.5s;
}
.banner-area.banner-area-three .follow-us ul li a i:hover {
  background-color: #ffffff;
  color: #d80650;
}
.banner-area.banner-area-three .follow-us ul span {
  display: inline-block;
  position: relative;
  top: -4px;
}
.banner-area.banner-area-three.banner-area-ten {
  position: relative;
  overflow: hidden;
  z-index: 1;
}
.banner-area.banner-area-three.banner-area-ten::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: #000000;
  opacity: 0.8;
  z-index: -1;
}
.banner-area.banner-area-three.banner-area-ten .background-video {
  position: absolute;
  left: 0;
  top: 0;
  width: auto;
  height: auto;
  min-height: 100%;
  min-width: 100%;
  z-index: -2;
}
.banner-area.banner-area-three.banner-area-ten .banner-text {
  position: relative;
  overflow: hidden;
  z-index: 3;
}

/*
Lines Area Style
======================================================*/
.lines {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  margin: auto;
  width: 90vw;
  z-index: -1;
}
.lines .line {
  position: absolute;
  width: 2px;
  height: 100%;
  top: 0;
  left: 50%;
  background: rgba(255, 255, 255, 0.1);
  overflow: hidden;
}
.lines .line::after {
  content: "";
  display: block;
  position: absolute;
  height: 10px;
  width: 100%;
  top: 0;
  left: 0;
  background-color: #ffffff;
  animation: moveLeftBounces-one 20s linear infinite;
}
.lines .line:nth-child(1) {
  margin-left: -25%;
}
.lines .line:nth-child(1)::after {
  animation-delay: 2s;
}
.lines .line:nth-child(3)::after {
  animation-delay: 2.5s;
}
.lines .line:nth-child(3) {
  margin-left: 25%;
}
.lines .line-two {
  position: absolute;
  width: 2px;
  height: 100%;
  top: 0;
  left: 50%;
  background: rgba(255, 255, 255, 0.1);
  overflow: hidden;
}
.lines .line-two::after {
  content: "";
  display: block;
  position: absolute;
  height: 10px;
  width: 100%;
  top: 0;
  left: 0;
  background-color: #d80650;
  animation: moveLeftBounces-two 20s linear infinite;
}
.lines .line-two:nth-child(1) {
  margin-left: -25%;
}
.lines .line-two:nth-child(1)::after {
  animation-delay: 2s;
}
.lines .line-two:nth-child(3)::after {
  animation-delay: 2.5s;
}
.lines .line-two:nth-child(3) {
  margin-left: 25%;
}
.lines .line-three {
  position: absolute;
  width: 2px;
  height: 100%;
  top: 0;
  left: 50%;
  background: rgba(255, 255, 255, 0.1);
  overflow: hidden;
}
.lines .line-three::after {
  content: "";
  display: block;
  position: absolute;
  height: 10px;
  width: 100%;
  top: 0;
  left: 0;
  background-color: #d80650;
  animation: moveLeftBounces-one 20s linear infinite;
}
.lines .line-three:nth-child(1) {
  margin-left: -25%;
}
.lines .line-three:nth-child(1)::after {
  animation-delay: 2s;
}
.lines .line-three:nth-child(3)::after {
  animation-delay: 2.5s;
}
.lines .line-three:nth-child(3) {
  margin-left: 25%;
}

@keyframes moveLeftBounces-one {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(1000px);
  }
  100% {
    transform: translateY(0);
  }
}
/*
Focus Area Style
======================================================*/
@keyframes FocuS {
  0% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
/*
Hero Slider Area Style
======================================================*/
.hero-slider-area {
  z-index: 1;
  position: relative;
  overflow: hidden;
}
.hero-slider-area .slider-item {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.hero-slider-area .slider-item::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background-color: rgba(0, 55, 130, 0.9);
  z-index: -1;
}
.hero-slider-area .slider-item::after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  width: 50%;
  height: 100%;
  background-color: rgba(0, 55, 130, 0.9);
  z-index: -1;
}
.hero-slider-area .slider-item .slider-text {
  position: relative;
  max-width: 690px;
  z-index: 1;
  overflow: hidden;
  margin-top: 100px;
}
.hero-slider-area .slider-item .slider-text span {
  color: #ffffff;
  font-size: 15px;
  display: block;
  margin-bottom: 10px;
}
.hero-slider-area .slider-item .slider-text h1 {
  font-size: 80px;
  color: #ffffff;
  margin-bottom: 30px;
}
.hero-slider-area .slider-item .slider-text p {
  color: #ffffff;
  font-size: 16px;
  margin-bottom: 35px;
}
.hero-slider-area .slider-item .video-btn {
  margin: auto;
  display: block;
  margin-top: 100px;
}
.hero-slider-area .owl-item.active .slider-item::before {
  animation: 3s 0.3s fadeInUp both;
}
.hero-slider-area .owl-item.active .slider-item::after {
  animation: 3s 0.3s fadeInDown both;
}
.hero-slider-area .owl-item.active .slider-text.overflow-hidden {
  overflow: hidden;
}
.hero-slider-area .owl-item.active .slider-text.one h1 {
  animation: 2s 0.2s fadeInUpBig both;
}
.hero-slider-area .owl-item.active .slider-text.one p {
  animation: 3s 0.3s fadeInUpBig both;
}
.hero-slider-area .owl-item.active .slider-text.one .slider-btn {
  animation: 4s 0.4s fadeInUpBig both;
}
.hero-slider-area .owl-item.active .slider-text.two h1 {
  animation: 2s 0.2s fadeInLeft both;
}
.hero-slider-area .owl-item.active .slider-text.two p {
  animation: 2s 0.2s fadeInRight both;
}
.hero-slider-area .owl-item.active .slider-text.two .slider-btn {
  animation: 2s 0.2s fadeInUpBig both;
}
.hero-slider-area .owl-item.active .one.video-btn-animat {
  animation: 2s 0.2s zoomIn both;
}
.hero-slider-area .owl-item.active .two.video-btn-animat {
  animation: 2s 0.2s zoomIn both;
}
.hero-slider-area .owl-prev i {
  background-color: transparent !important;
  border-radius: 50%;
  border: 1px solid #ffffff;
}
.hero-slider-area .owl-prev i:hover {
  border-color: #d80650;
}
.hero-slider-area .owl-next i {
  background-color: transparent !important;
  border-radius: 50%;
  border: 1px solid #ffffff;
}
.hero-slider-area .owl-next i:hover {
  border-color: #d80650;
}
.hero-slider-area .owl-next {
  position: absolute;
  top: 50%;
  right: -100px;
  margin: 0 !important;
  border-radius: 0 !important;
  transition: all 0.5s;
  transform: translateY(30px);
}
.hero-slider-area .owl-next i {
  background-color: #d80650;
  color: #ffffff;
  width: 40px;
  height: 40px;
  line-height: 40px;
  display: inline-block;
  transition: all 0.5s;
  font-size: 26px;
  border-radius: 0;
}
.hero-slider-area .owl-next i:hover {
  background-color: #d80650 !important;
}
.hero-slider-area .owl-prev {
  position: absolute;
  top: 50%;
  left: -100px;
  margin: 0 !important;
  border-radius: 0 !important;
  transition: all 0.5s;
  transform: translateY(30px);
}
.hero-slider-area .owl-prev i {
  color: #ffffff;
  width: 40px;
  height: 40px;
  line-height: 40px;
  display: inline-block;
  transition: all 0.5s;
  font-size: 26px;
  border-radius: 0;
}
.hero-slider-area .owl-prev i:hover {
  background-color: #d80650 !important;
}
.hero-slider-area:hover .owl-prev {
  left: 30px;
}
.hero-slider-area:hover .owl-next {
  right: 30px;
}
.hero-slider-area .owl-theme .owl-nav {
  margin-top: 0 !important;
}
.hero-slider-area .owl-theme .owl-nav [class*=owl-]:hover {
  background-color: transparent;
}
.hero-slider-area .shape {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
}

/*
Features Area Style
======================================================*/
.features-area {
  margin-top: -100px;
}
.features-area .container {
  max-width: 1110px;
}
.features-area .single-features {
  background-color: #010414;
  transition: all 0.5s;
  border-right: 1px solid #101323;
}
.features-area .single-features::before {
  width: 0;
  height: 2px;
  bottom: 0;
  top: unset;
  right: 0;
  transition: all 0.5s;
  z-index: 1;
}
.features-area .single-features:hover {
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
}
.features-area .single-features:hover h3 {
  color: #d80650;
}
.features-area .single-features:hover h3 i {
  color: #d80650;
}
.features-area .single-features:hover p {
  color: #212121;
}
.features-area .single-features:hover::after {
  background-image: linear-gradient(to bottom right, #ffffff, #ffffff);
}
.features-area .single-features:hover::before {
  width: 100%;
  left: 0;
  right: auto;
  width: 100%;
}
.features-area .col-lg-4:nth-child(3) .single-features {
  border: none;
}

.single-features {
  background-color: #140f14;
  padding: 30px;
  position: relative;
  z-index: 1;
}
.single-features::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 3px;
  height: 100%;
  background-color: #d80650;
  z-index: -1;
}
.single-features::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 0%;
  height: 100%;
  background-image: linear-gradient(to bottom right, #d2044d, #ff5e68);
  z-index: -1;
  transition: all 0.5s;
}
.single-features h3 {
  color: #d80650;
  position: relative;
  padding-left: 45px;
  margin-bottom: 20px;
  transition: all 0.5s;
}
.single-features h3 i {
  color: #d80650;
  font-size: 40px;
  line-height: 1;
  position: absolute;
  top: -4px;
  left: -3px;
  transition: all 0.5s;
}
.single-features p {
  color: #ffffff;
  margin-bottom: 0;
}
.single-features span {
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 80px;
  color: #292529;
  opacity: 0.5;
}
.single-features:hover h3 {
  color: #ffffff;
}
.single-features:hover h3 i {
  color: #ffffff;
}
.single-features:hover::after {
  left: 0;
  right: auto;
  width: 100%;
}

/*
Partner Area Style
======================================================*/
.partner-area .partner-item {
  background-color: transparent;
  position: relative;
  transition: all 0.5s;
  overflow: hidden;
  text-align: center;
  line-height: 1;
}
.partner-area .partner-item .partner-overly {
  position: absolute;
  top: 200px;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 0.5s;
}
.partner-area .partner-item:hover .partner-overly {
  top: 0;
}
.partner-area .owl-carousel .owl-item img {
  width: unset;
  margin: auto;
}

/*
Security Area Style
======================================================*/
.security-area .container {
  max-width: 1300px;
}

.security-area-two {

  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  position: relative;
  z-index: 1;
}
.security-area-two .container {
  max-width: 1300px;
}
.security-area-two::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #090218;
  opacity: 0.9;
  z-index: -1;
}

.single-security {
  background-color: #fef4f8;
  padding: 28px;
  margin-bottom: 30px;
  transition: all 0.5s;
}
.single-security i {
  font-size: 30px;
  color: #ffffff;
  width: 50px;
  height: 50px;
  line-height: 50px;
  background-image: linear-gradient(to bottom right, #d2044d, #ff5e68);
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  margin-bottom: 15px;
  transition: all 0.5s;
}
.single-security h3 {
  font-size: 22px;
  margin-bottom: 9px;
}
.single-security:hover {
  transform: translateY(-5px);
}
.single-security:hover i {
  transform: rotateY(360deg);
}

/*
Approach Area Style
======================================================*/
.approach-content {
  margin-top: -10px;
}
.approach-content h2 {
  margin-bottom: 20px;
  font-size: 40px;
}
.approach-content ul {
  margin-top: 30px;
}
.approach-content ul li {
  background-color: #ffffff;
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  padding: 30px;
  position: relative;
  padding-left: 100px;
  margin-bottom: 30px;
  transition: all 0.5s;
}
.approach-content ul li:last-child {
  margin-bottom: 0;
}
.approach-content ul li i {
  position: absolute;
  top: 30px;
  left: 30px;
  font-size: 30px;
  width: 50px;
  height: 50px;
  line-height: 50px;
  background-color: #efefef;
  text-align: center;
  border-radius: 50%;
  color: #d80650;
  transition: all 0.5s;
}
.approach-content ul li h3 {
  margin-bottom: 12px;
}
.approach-content ul li:hover {
  transform: translateY(-5px);
}
.approach-content ul li:hover i {
  background-color: #d80650;
  color: #ffffff;
}

/*
Solutions Area Style
======================================================*/
.single-solutions {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 400px;
  position: relative;
  z-index: 1;
  margin-bottom: 30px;
  overflow: hidden;
  transition: all 0.5s;
}

.single-solutions::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to bottom, transparent, rgba(1, 4, 20, 0.99));
  z-index: -1;
}
.single-solutions::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0;
  background-image: linear-gradient(to bottom, rgba(216, 6, 80, 0.9), rgba(216, 6, 80, 0.9));
  opacity: 0.8;
  z-index: -1;
  transition: all 0.5s;
}
.single-solutions .solutions-content {
  position: absolute;
  bottom: 0;
  padding: 30px;
}
.single-solutions h3 {
  color: #ffffff;
  margin-bottom: 13px;
}
.single-solutions p {
  color: #ffffff;
}
.single-solutions:hover {
  transform: translateY(-5px);
}
.single-solutions:hover .read-more {
  color: #ffffff;
}
.single-solutions:hover::after {
  height: 100%;
  top: 0;
  bottom: auto;
}

/*
Electronic Area Style
=================================================*/
.electronic-content {
  margin-top: -8px;
  margin-bottom: -8px;
}
.electronic-content h2 {
  font-size: 40px;
  margin-bottom: 50px;
  color: #ffffff;
}
.electronic-content .electronic-tab-wrap .electronic-tab .tabs {
  border-bottom: 2px solid #2f3840;
  margin-bottom: 30px;
}
.electronic-content .electronic-tab-wrap .electronic-tab .tabs li {
  padding: 0;
  margin-right: 30px;
  padding-bottom: 10px;
  display: inline-block;
  font-weight: 600;
  position: relative;
}
.electronic-content .electronic-tab-wrap .electronic-tab .tabs li::before {
  content: "";
  position: absolute;
  bottom: -2px;
  right: 0;
  width: 0;
  height: 2px;
  background-color: #d80650;
  transition: all 0.5s;
}
.electronic-content .electronic-tab-wrap .electronic-tab .tabs li a {
  padding-left: 0 !important;
  color: #ffffff;
}
.electronic-content .electronic-tab-wrap .electronic-tab .tabs .current a {
  color: #d80650;
}
.electronic-content .electronic-tab-wrap .electronic-tab .tabs .current::before {
  width: 100%;
  right: auto;
  left: 0;
}
.electronic-content .electronic-tab-wrap .electronic-tab p {
  color: #ffffff;
}
.electronic-content .electronic-tab-wrap .electronic-tab .default-btn {
  width: unset !important;
  margin-top: 15px;
}
.electronic-content .tab .tabs_item {
  display: none;
}
.electronic-content .tab .tabs_item:first-child {
  display: block;
}

.electronic-img {
  text-align: center;
}

/*
Complete Area Style
=================================================*/
.complete-area {
  position: relative;
}
.complete-area .complete-shape {
  position: absolute;
  top: 150px;
  right: 0;
  z-index: -1;
  opacity: 0.6;
}
.complete-area .complete-shape.two {
  right: unset;
  left: 0;
}
.complete-area .col-lg-6.pl-0 {
  padding-left: 0;
}
.complete-area.complete-area-style-two .col-lg-6.pr-0 {
  padding-right: 0;
}
.complete-area.complete-area-style-two .complete-content {
  margin-left: auto;
  margin-right: 0;
}

.complete-content {
  margin-top: -8px;
  max-width: 545px;
  margin-right: auto;
}
.complete-content h2 {
  font-size: 40px;
}
.complete-content .single-security {
  position: relative;
  z-index: 1;
}
.complete-content .single-security::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 100%;
  background-image: linear-gradient(to bottom right, #d2044d, #ff5e68);
  transition: all 0.5s;
  z-index: -1;
}
.complete-content .single-security i {
  transition: all 0.5s;
}
.complete-content .single-security h3 {
  transition: all 0.5s;
}
.complete-content .single-security p {
  transition: all 0.5s;
}
.complete-content .single-security:hover i {
  background-image: linear-gradient(to bottom right, #ffffff, #ffffff);
  color: #d80650;
}
.complete-content .single-security:hover h3 {
  color: #ffffff;
}
.complete-content .single-security:hover p {
  color: #ffffff;
}
.complete-content .single-security:hover::before {
  width: 100%;
  left: 0;
  right: auto;
}

.complete-img {
 
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
}

/*
Client Area Style
======================================================*/
.client-area {

  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  position: relative;
  z-index: 1;
}
.client-area::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #010e19;
  opacity: 0.82;
  z-index: -1;
}
.client-area .owl-item.active.center .single-client {
  background-color: #d80650;
}
.client-area .owl-item.active.center .single-client .quotes {
  background-color: #d80650;
  color: #ffffff;
  transform: rotateX(180deg);
}
.client-area .owl-item.active.center .single-client::before {
  border-top: 45px solid #d80650;
}
.client-area .owl-item.active.center .single-client p {
  color: #ffffff;
}
.client-area .owl-item.active.center .single-client ul li i {
  color: #ffffff;
}

.single-client {
  text-align: center;
  background-color: #ffffff;
  position: relative;
  margin-top: 30px;
  margin-bottom: 165px;
  border-radius: 4px;
  transition: all 0.5s;
  padding-top: 50px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
}
.single-client .quotes {
  background-color: #ffffff;
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  color: #d80650;
  width: 60px;
  height: 60px;
  line-height: 60px;
  font-size: 30px;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: -30px;
  transition: all 0.5s;
}
.single-client p {
  transition: all 0.5s;
}
.single-client ul li {
  display: inline-block;
}
.single-client ul li i {
  font-size: 20px;
  color: #d80650;
  transition: all 0.5s;
}
.single-client::before {
  position: absolute;
  width: 0;
  height: 0;
  content: "";
  border-top: 45px solid #ffffff;
  border-right: 10px solid transparent;
  border-bottom: 0;
  border-left: 50px solid transparent;
  bottom: -24px;
  left: 20px;
  transition: all 0.5s;
}

.client-img {
  position: absolute;
  left: 30px;
  bottom: -170px;
}
.client-img img {
  border-radius: 10px;
  margin-bottom: 15px;
}
.client-img h3 {
  margin-bottom: 0;
  font-size: 20px;
  color: #ffffff;
}
.client-img span {
  font-weight: 600;
  font-size: 13px;
  line-height: 1;
  color: #d80650;
}

/*
Cybersecurity Area Style
======================================================*/
.cybersecurity-area.colo-bg {
  background-color: #010414;
}
.cybersecurity-area .col-lg-6.pr-0 {
  padding-right: 0;
}
.cybersecurity-area.cybersecurity-area-styele-two .col-lg-6.pl-0 {
  padding-left: 0;
}
.cybersecurity-area.cybersecurity-area-styele-two .cybersecurity-content {
  margin-left: 0;
  margin-right: auto;
}

.cybersecurity-content {
  max-width: 545px;
  margin-left: auto;
  margin-top: -8px;
}
.cybersecurity-content h2 {
  font-size: 40px;
  margin-bottom: 20px;
}
.cybersecurity-content p {
  margin-bottom: 30px;
}
.cybersecurity-content ul li {
  display: inline-block;
  background-color: #fdeff4;
  padding: 10px;
  margin-bottom: 30px;
  width: 100%;
  font-weight: 600;
  position: relative;
  padding-left: 35px;
  z-index: 1;
  transition: all 0.5s;
  cursor: pointer;
}
.cybersecurity-content ul li:last-child {
  margin-bottom: 0;
}
.cybersecurity-content ul li i {
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 20px;
  font-weight: 600;
  color: #d80650;
  transition: all 0.5s;
}
.cybersecurity-content ul li::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 100%;
  background-image: linear-gradient(to bottom right, #d2044d, #ff5e68);
  transition: all 0.5s;
  z-index: -1;
}
.cybersecurity-content ul li:hover {
  color: #ffffff;
}
.cybersecurity-content ul li:hover::before {
  width: 100%;
  right: auto;
  left: 0;
}
.cybersecurity-content ul li:hover i {
  color: #ffffff;
}
.cybersecurity-content.three h2 {
  color: #ffffff;
}
.cybersecurity-content.three p {
  color: #ffffff;
}



/*
Blog Area Style
======================================================*/
.single-blog {
  position: relative;
  transition: all 0.5s;
  margin-bottom: 30px;
}
.single-blog::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to bottom, rgba(255, 0, 0, 0), rgba(14, 1, 41, 0.99));
}
.single-blog span {
  background-color: #ffffff;
  color: #212121;
  position: absolute;
  top: 20px;
  left: 0;
  padding: 8px 20px;
  font-weight: 600;
  transition: all 0.5s;
}
.single-blog .blog-content {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 30px;
}
.single-blog .blog-content .date {
  color: #ffffff;
  position: relative;
  padding-left: 30px;
  margin-bottom: 15px;
}
.single-blog .blog-content .date i {
  position: absolute;
  left: 0;
  top: -1px;
  font-size: 25px;
}
.single-blog .blog-content h3 {
  margin-bottom: 12px;
}
.single-blog .blog-content h3 a {
  color: #ffffff;
}
.single-blog .blog-content p {
  color: #ffffff;
}
.single-blog .blog-content .mt-use {
  margin-top: 20px;
}
.single-blog:hover {
  transform: translateY(-5px);
}
.single-blog:hover span {
  color: #d80650;
}

/*
Footer Bottom Area Style
======================================================*/
.footer-bottom-area {
  background-color: #05224c;
  padding: 20px 0;
}
.footer-bottom-area .copy-right p {
  text-transform: capitalize;
  margin-bottom: 0;
  color: #ffffff;
}
.footer-bottom-area .copy-right p i {
  position: relative;
  top: 1px;
}
.footer-bottom-area .copy-right p a {
  color: #ffffff;
}
.footer-bottom-area .copy-right p a:hover {
  color: #d80650;
}
.footer-bottom-area .condition-privacy {
  float: right;
}
.footer-bottom-area .condition-privacy ul li {
  display: inline-block;
  padding: 0 10px;
  position: relative;
}
.footer-bottom-area .condition-privacy ul li::before {
  content: "";
  position: absolute;
  top: 11px;
  right: -7px;
  width: 10px;
  height: 2px;
  background-color: #ffffff;
}
.footer-bottom-area .condition-privacy ul li:last-child {
  padding-right: 0;
}
.footer-bottom-area .condition-privacy ul li:last-child::before {
  display: none;
}
.footer-bottom-area .condition-privacy ul li a {
  color: #ffffff;
}
.footer-bottom-area .condition-privacy ul li a:hover {
  color: #d80650;
}

/*====================================================
HOME TWO STYLE AREA
======================================================*/
/*
Manual Area Style
======================================================*/
.manual-content {
  max-width: 540px;
  margin-left: auto;
  margin-top: -8px;
}
.manual-content h2 {
  font-size: 40px;
  margin-bottom: 20px;
  color: #ffffff;
}
.manual-content p {
  margin-bottom: 30px;
  color: #ffffff;
}
.manual-content ul li {
  display: inline-block;
  background-color: #fdeff4;
  padding: 10px;
  margin-bottom: 30px;
  width: 100%;
  font-weight: 600;
  position: relative;
  padding-left: 35px;
  z-index: 1;
  transition: all 0.5s;
  cursor: pointer;
}
.manual-content ul li:last-child {
  margin-bottom: 0;
}
.manual-content ul li i {
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 20px;
  font-weight: 600;
  color: #d80650;
  transition: all 0.5s;
}
.manual-content ul li::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 100%;
  background-image: linear-gradient(to bottom right, #d2044d, #ff5e68);
  transition: all 0.5s;
  z-index: -1;
}
.manual-content ul li:hover {
  color: #ffffff;
}
.manual-content ul li:hover::before {
  width: 100%;
  right: auto;
  left: 0;
}
.manual-content ul li:hover i {
  color: #ffffff;
}

/*
Counter Area Style
======================================================*/
.single-counter {
  text-align: center;
  transition: all 0.5s;
  margin-bottom: 30px;
  background-color: #ffffff;
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  padding: 30px;
  position: relative;
}
.single-counter i {
  background-color: #e3f7fc;
  color: #d80650;
  font-size: 40px;
  line-height: 1;
  display: block;
  width: 70px;
  height: 70px;
  line-height: 70px;
  margin: 0 auto 25px;
  border-radius: 50%;
  transition: all 0.5s;
}
.single-counter p {
  color: #d80650;
  line-height: 1;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 500;
}
.single-counter h2 {
  color: #0e0129;
  line-height: 1;
  font-size: 40px;
  margin-top: -8px;
  margin-bottom: 10px;
}
.single-counter:hover {
  transform: translateY(-5px);
}
.single-counter:hover i {
  background-color: #ffffff;
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
}

/*
Pricing Area Style
======================================================*/
.pricing-area {
  padding-bottom: 120px;
}
.pricing-area .section-title {
  margin-bottom: 110px;
}

.single-pricing {
  text-align: center;
  background-color: #ffffff;
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  padding-bottom: 30px;
  margin-bottom: 30px;
  transition: all 0.5s;
  position: relative;
  z-index: 1;
}
.single-pricing::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background-position: bottom;
  background-repeat: no-repeat;
  background-size: contain;
  z-index: -1;
  opacity: 0.5;
}
.single-pricing.active {
  transform: scale(1.2);
  z-index: 2;
  position: relative;
}
.single-pricing .pricing-content {
  background-color: #fef4f8;
  padding: 30px;
  margin-bottom: 30px;
}
.single-pricing h1 {
  color: #d80650;
  font-size: 40px;
  margin-bottom: 0;
}
.single-pricing h1 sub {
  font-size: 14px;
  font-weight: normal;
  color: #9e9b9b;
}
.single-pricing ul li {
  font-weight: 500;
  margin-bottom: 10px;
  padding-bottom: 10px;
  padding-left: 30px;
  padding-right: 30px;
  border-bottom: 1px solid #fef4f8;
}
.single-pricing ul li i {
  color: #d80650;
  font-size: 25px;
  position: relative;
  top: 5px;
}
.single-pricing ul li:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}
.single-pricing ul li span {
  color: #9e9b9b;
}
.single-pricing ul li span i {
  color: #9e9b9b;
}
.single-pricing .default-btn {
  margin-top: 30px;
  margin-bottom: -8px;
}
.single-pricing:hover {
  transform: translateY(-5px);
}
.single-pricing:hover.active {
  transform: scale(1.2);
}

/*
Transform Area Style
======================================================*/
.transform-area {
  position: relative;
}
.transform-area .shape-1 {
  position: absolute;
  top: -50%;
  left: 0;
  z-index: -1;
  opacity: 0.5;
}
.transform-area .shape-2 {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -1;
  opacity: 0.5;
}

.transform-content {
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  padding: 50px;
  margin-left: -100px;
  margin-top: 100px;
}
.transform-content h2 {
  font-size: 40px;
  margin-bottom: 20px;
  margin-top: -11px;
}
.transform-content p {
  margin-bottom: 30px;
}
.transform-content .skill-bar {
  margin-bottom: 20px;
}
.transform-content .skill-bar .progress-title-holder {
  position: relative;
}
.transform-content .skill-bar .progress-title {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0 !important;
}
.transform-content .skill-bar .progress-number-wrapper {
  width: 100%;
  z-index: 10;
  font-size: 11px;
  line-height: 24px;
  height: 24px;
  letter-spacing: 0px;
  font-weight: 600;
  font-style: normal;
  text-transform: none;
  color: #ffffff;
  margin-bottom: 0;
  margin-bottom: 0 !important;
}
.transform-content .skill-bar .progress-number-mark {
  margin-bottom: 4px;
  border-radius: 3px;
  background-image: linear-gradient(to bottom right, #ff5e68, #d2044d);
  padding: 0 8px;
  position: absolute;
  bottom: 0;
  transform: translateX(-50%);
  margin-bottom: 0 !important;
}
.transform-content .skill-bar .down-arrow {
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-top: 3px solid #d80650;
  position: absolute;
  left: 50%;
  top: 100%;
  transform: translateX(-50%);
  color: #d80650;
  margin-bottom: 0 !important;
}
.transform-content .skill-bar .progress-content-outter {
  height: 6px;
  background-color: #ebeded;
  border-radius: 50px;
}
.transform-content .skill-bar .progress-content {
  height: 6px;
  background-image: linear-gradient(to bottom right, #d2044d, #ff5e68);
  border-radius: 50px;
  width: 0%;
}
.transform-content .skill-bar .percent {
  margin-bottom: 0 !important;
  color: #ffffff !important;
}

/*
Performance Area Style
======================================================*/

.performance-area.bg-none {
  background-image: none;
}
.performance-area.bg-none::before {
  display: none;
}
.performance-area.bg-none .single-security {
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.performance-area.bg-none .single-security img {
  position: absolute;
  bottom: 0;
  left: -90px;
  z-index: -1;
  transition: all 0.5s;
}
.performance-area.bg-none .single-security:hover {
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
}
.performance-area.bg-none .single-security:hover img {
  left: -20px;
}
.performance-area::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #010a1b;
  opacity: 0.78;
}
.performance-area .single-security {
  text-align: center;
  background-color: #010414;
}
.performance-area .single-security h3 {
  margin-bottom: 10px;
  color: #ffffff;
}
.performance-area .single-security p {
  margin-bottom: 10px;
  color: #ffffff;
}
.performance-area .single-security:hover {
  background-color: #ffffff;
}
.performance-area .single-security:hover h3 {
  color: #0e0129;
}
.performance-area .single-security:hover p {
  color: #0e0129;
}
.performance-area .owl-theme .owl-dots {
  line-height: 1;
}
.performance-area .owl-theme .owl-dots .owl-dot span {
  background-color: #ffffff;
  width: 10px;
  height: 5px;
  border-radius: 0 !important;
  transition: all 0.5s;
}
.performance-area .owl-theme .owl-dots .owl-dot.active span {
  background-image: linear-gradient(to bottom right, #d2044d, #ff5e68);
  width: 20px;
}

/*
Choose Area Style
======================================================*/
.choose-area {

  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: 1;
}
.choose-area::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #0a031b;
  opacity: 0.63;
  z-index: -1;
}

.choose-wrap {
  background-color: #010c16;
  max-width: 540px;
  margin: auto;
  padding: 50px;
}
.choose-wrap h2 {
  color: #ffffff;
  font-size: 40px;
  margin-top: -11px;
  margin-bottom: 22px;
}
.choose-wrap p {
  color: #ffffff;
  margin-bottom: 20px;
}
.choose-wrap ul li {
  color: #ffffff;
  position: relative;
  padding-left: 35px;
  font-weight: 500;
  font-size: 15px;
  margin-bottom: 10px;
}
.choose-wrap ul li:last-child {
  margin-bottom: 0;
}
.choose-wrap ul li i {
  display: inline-block;
  width: 25px;
  height: 25px;
  line-height: 25px;
  background-color: #221b31;
  color: #d80650;
  border-radius: 50%;
  text-align: center;
  position: absolute;
  left: 0;
  top: 0;
  font-size: 20px;
  transition: all 0.5s;
}
.choose-wrap ul li:hover i {
  background-color: #ffffff;
}
.choose-wrap .default-btn {
  margin-bottom: -8px;
}

.choose-area-four {
  background-color: #010c16;
}

/*
Graph Area Style
======================================================*/
.graph-content h2 {
  color: #ffffff;
  font-size: 40px;
  margin-bottom: 20px;
}
.graph-content p {
  color: #ffffff;
}
.graph-content h3 {
  color: #ffffff;
  max-width: 300px;
  margin-bottom: 25px;
  line-height: 1.5;
}

/*
Project Area Style
======================================================*/
.project-area {
  overflow: hidden;
}
.project-area .owl-item.active.center .single-solutions {
  height: 430px;
  margin-top: 0;
}
.project-area .owl-item.active.center .single-solutions::after {
  height: 100%;
  top: 0;
  bottom: auto;
}
.project-area .owl-item.active.center .single-solutions .read-more {
  color: #ffffff;
}
.project-area .single-solutions {
  margin-bottom: 0;
  margin-top: 30px;
}

/*====================================================
HOME SIX STYLE AREA
======================================================*/
/*
Section Title Area Style*/

/*
Header Area Style
======================================================*/
.header-area-six .container-fluid {
  padding-left: 50px;
  padding-right: 50px;
}
.header-area-six .top-header-area {
  background-color: #f9f9f9;
  padding: 15px 0;
}
.header-area-six .top-header-area .header-content-left li {
  display: inline-block;
  padding-right: 20px;
  color: #0e0129;
  font-size: 15px;
}
.header-area-six .top-header-area .header-content-left li:last-child {
  padding-right: 0;
}
.header-area-six .top-header-area .header-content-left li a {
  color: #0e0129;
  display: inline-block;
}
.header-area-six .top-header-area .header-content-left li i {
  color: #d80650;
  margin-right: 5px;
  font-size: 18px;
  position: relative;
  top: 3px;
  transition: all 0.5s;
}
.header-area-six .top-header-area .header-content-left li:hover a {
  color: #d80650;
}
.header-area-six .top-header-area .header-content-left li:hover a i {
  color: #d80650;
}
.header-area-six .top-header-area .header-content-right {
  float: right;
}
.header-area-six .top-header-area .header-content-right .language-area {
  display: inline-block;
  list-style: none;
  padding: 0;
  margin: 0;
  margin-right: 2px;
  position: relative;
  margin-right: 20px;
}
.header-area-six .top-header-area .header-content-right .language-area .language-item-top {
  position: relative;
}
.header-area-six .top-header-area .header-content-right .language-area .language-item-top .language-bar span {
  position: relative;
  top: 1px;
  color: #0e0129;
  transition: all 0.5s;
  font-size: 15px;
}
.header-area-six .top-header-area .header-content-right .language-area .language-item-top .language-bar i {
  font-size: 20px;
  color: #d80650;
  position: relative;
  top: 4px;
}
.header-area-six .top-header-area .header-content-right .language-area .language-item-top .language-item-bottom {
  list-style: none;
  position: absolute;
  top: 50px;
  padding: 20px !important;
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  width: 200px;
  left: 0;
  transition: all 0.3s ease-in-out;
  visibility: hidden;
  opacity: 0;
  z-index: 2;
  margin: 0;
  text-align: left;
}
.header-area-six .top-header-area .header-content-right .language-area .language-item-top .language-item-bottom::before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  content: "";
  border-top: 0;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #ffffff;
  border-left: 8px solid transparent;
  top: -8px;
  left: 25px;
}
.header-area-six .top-header-area .header-content-right .language-area .language-item-top .language-item-bottom .language-item {
  margin-bottom: 15px;
}
.header-area-six .top-header-area .header-content-right .language-area .language-item-top .language-item-bottom .language-item:last-child {
  margin-bottom: 0;
}
.header-area-six .top-header-area .header-content-right .language-area .language-item-top .language-item-bottom .language-item .language-link {
  color: #0e0129;
  display: block;
  font-size: 14px;
  font-weight: 500;
}
.header-area-six .top-header-area .header-content-right .language-area .language-item-top .language-item-bottom .language-item .language-link img {
  width: 20px;
  margin-right: 8px;
  border-radius: 50%;
  position: relative;
  top: -2px;
}
.header-area-six .top-header-area .header-content-right .language-area .language-item-top .language-item-bottom .language-item .language-link:hover {
  color: #d80650;
}
.header-area-six .top-header-area .header-content-right .language-area .language-item-top:hover .language-item-bottom {
  visibility: visible;
  opacity: 1;
  top: 35px;
  z-index: 9999;
}
.header-area-six .top-header-area .header-content-right .log-in-sign-up {
  display: inline-block;
}
.header-area-six .top-header-area .header-content-right .log-in-sign-up li {
  display: inline-block;
  margin-right: 30px;
}
.header-area-six .top-header-area .header-content-right .log-in-sign-up li:last-child {
  margin-right: 0;
}
.header-area-six .top-header-area .header-content-right .log-in-sign-up li i {
  color: #d80650;
  font-size: 18px;
  display: inline-block;
  margin-right: 5px;
  position: relative;
  top: 3px;
}
.header-area-six .top-header-area .header-content-right .log-in-sign-up li a {
  color: #0e0129;
  font-size: 15px;
}
.header-area-six .top-header-area .header-content-right .log-in-sign-up li a:hover {
  color: #d80650;
}

/*
Nav Area Style
======================================================*/
.nav-area-six .container-fluid {
  padding-left: 50px;
  padding-right: 50px;
}
.nav-area-six .navbar-area .main-nav {
  background-color: transparent;
  box-shadow: none;
  padding-top: 0;
  padding-bottom: 0;
}
.nav-area-six .navbar-area .main-nav .navbar {
  padding-left: 0;
  padding-right: 0;
}
.nav-area-six .navbar-area .main-nav nav .navbar-nav {
  margin-left: auto;
  margin-right: 0;
}
.nav-area-six .navbar-area .main-nav nav .navbar-nav .nav-item a {
  font-size: 16px;
  color: #0e0129;
  text-transform: capitalize;
  transition: all 0.5s;
  margin-left: 0;
  margin: 0 10px;
  position: relative;
}
.nav-area-six .navbar-area .main-nav nav .navbar-nav .nav-item a i {
  font-size: 20px;
  line-height: 0;
  position: relative;
  top: 5px;
}
.nav-area-six .navbar-area .main-nav nav .navbar-nav .nav-item a:hover {
  color: #d80650;
}
.nav-area-six .navbar-area .main-nav nav .navbar-nav .nav-item a.active {
  color: #d80650;
}
.nav-area-six .navbar-area .main-nav nav .navbar-nav .nav-item .dropdown-menu {
  background-color: #ffffff;
  border: none;
  border-radius: 0;
  top: 100%;
  left: 0;
  padding: 0;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s;
  transform: scaleX(0);
}
.nav-area-six .navbar-area .main-nav nav .navbar-nav .nav-item .dropdown-menu li a {
  font-size: 14px;
  color: #0e0129;
  position: relative;
  padding: 10px 15px;
  border-bottom: 1px dashed #eeeeee;
  margin-left: 0;
  margin-right: 0;
  position: relative;
}
.nav-area-six .navbar-area .main-nav nav .navbar-nav .nav-item .dropdown-menu li a::before {
  content: "";
  position: absolute;
  bottom: -1px;
  right: 0;
  width: 0;
  height: 1px;
  background-color: #d80650;
  transition: all 0.5s;
}
.nav-area-six .navbar-area .main-nav nav .navbar-nav .nav-item .dropdown-menu li a i {
  float: right;
  top: 12px;
  transition: all 0.5s;
  font-size: 20px;
}
.nav-area-six .navbar-area .main-nav nav .navbar-nav .nav-item .dropdown-menu li a:hover {
  color: #d80650;
  background-color: #f3f3f3;
}
.nav-area-six .navbar-area .main-nav nav .navbar-nav .nav-item .dropdown-menu li a:hover::before {
  width: 100%;
  left: 0;
  right: auto;
}
.nav-area-six .navbar-area .main-nav nav .navbar-nav .nav-item .dropdown-menu li a.active {
  color: #d80650;
  background-color: #f3f3f3;
}
.nav-area-six .navbar-area .main-nav nav .navbar-nav .nav-item .dropdown-menu li .dropdown-menu {
  left: 100%;
  transform: scaleX(0);
  top: 0;
}
.nav-area-six .navbar-area .main-nav nav .navbar-nav .nav-item .dropdown-menu li:last-child a {
  border-bottom: none;
}
.nav-area-six .navbar-area .main-nav nav .navbar-nav .nav-item .dropdown-menu li:hover .dropdown-menu {
  top: 0px !important;
  transform: scaleX(1);
}
.nav-area-six .navbar-area .main-nav nav .navbar-nav .nav-item:hover ul {
  opacity: 1;
  visibility: visible;
  top: 100%;
  transform: scaleX(1);
}
.nav-area-six .navbar-area.is-sticky {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 999;
  background-color: #ffffff;
  box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.4);
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  transition: all 0.5s;
  width: 100% !important;
}
.nav-area-six .others-option {
  position: relative;
  top: 2px;
  margin-right: 40px;
}
.nav-area-six .others-option .option-item {
  color: #212121;
  display: inline-block;
  line-height: 1;
  position: relative;
  top: 0;
  margin-right: 20px;
  margin-left: 20px;
}
.nav-area-six .others-option .option-item .search-overlay {
  display: none;
}
.nav-area-six .others-option .option-item .search-overlay.search-popup {
  position: absolute;
  top: 100%;
  width: 500px;
  right: 0;
  background: #ffffff;
  z-index: 2;
  padding: 20px;
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  margin-top: 20px;
}
.nav-area-six .others-option .option-item .search-overlay.search-popup .search-form {
  position: relative;
}
.nav-area-six .others-option .option-item .search-overlay.search-popup .search-form .search-input {
  display: block;
  width: 100%;
  height: 50px;
  line-height: initial;
  border: 1px solid #eeeeee;
  color: #0e0129;
  outline: 0;
  transition: all 0.5s;
  font-size: 15px;
  padding-top: 4px;
  padding-left: 15px;
}
.nav-area-six .others-option .option-item .search-overlay.search-popup .search-form .search-input:focus {
  border-color: #d80650;
}
.nav-area-six .others-option .option-item .search-overlay.search-popup .search-form .search-button {
  position: absolute;
  right: 0;
  top: 0;
  height: 50px;
  background: #d80650;
  border: none;
  width: 50px;
  outline: 0;
  color: #ffffff;
  transition: all 0.5s;
  padding: 0;
}
.nav-area-six .others-option .option-item .search-overlay.search-popup .search-form .search-button:hover {
  background-color: #0e0129;
}
.nav-area-six .others-option .option-item .search-btn {
  cursor: pointer;
  transition: all 0.5s;
  color: #0e0129;
  font-size: 20px;
  text-align: center;
}
.nav-area-six .others-option .option-item .search-btn:hover {
  color: #d80650;
}
.nav-area-six .others-option .option-item .close-btn {
  cursor: pointer;
  display: none;
  transition: all 0.5s;
  color: #0e0129;
  font-size: 20px;
  text-align: center;
}
.nav-area-six .others-option .option-item .close-btn.active {
  display: block;
  color: #d80650;
}
.nav-area-six .others-option .cart-icon {
  display: inline-block;
  margin-right: 50px;
}
.nav-area-six .others-option .cart-icon .cart {
  position: relative;
}
.nav-area-six .others-option .cart-icon .cart i {
  font-size: 20px;
  position: relative;
  top: -2px;
  color: #0e0129;
}
.nav-area-six .others-option .cart-icon .cart i:hover {
  color: #d80650;
}
.nav-area-six .others-option .cart-icon .cart span {
  position: absolute;
  top: -6px;
  right: -6px;
  background-color: #d80650;
  width: 15px;
  height: 15px;
  line-height: 16px;
  border-radius: 50%;
  font-size: 10px;
  color: #ffffff;
  text-align: center;
}
.nav-area-six .others-option .sidebar-menu {
  display: inline-block;
  position: relative;
  z-index: 1;
}
.nav-area-six .others-option .sidebar-menu::before {
  content: "";
  position: absolute;
  top: -19px;
  right: -122px;
  width: 170px;
  height: 74px;
  background-color: #d80650;
  z-index: -1;
  border-radius: 0 0 0 40px;
}
.nav-area-six .others-option .sidebar-menu .burger-menu {
  display: inline-block;
  text-align: center;
  color: #d80650;
  font-size: 25px;
}
.nav-area-six .others-option .sidebar-menu .burger-menu i {
  color: #ffffff;
}

/*
Sidebar Area Style
======================================================*/
.sidebar-modal {
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  z-index: 9999;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s;
  overflow: hidden;
}
.sidebar-modal .sidebar-modal-inner {
  position: absolute;
  right: -100%;
  top: 0;
  max-width: 400px;
  overflow-y: scroll;
  height: 100%;
  background-color: #ffffff;
  transition: all 0.5s;
  z-index: 1;
  padding: 40px;
}
.sidebar-modal .sidebar-header {
  margin-bottom: 30px;
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 30px;
}
.sidebar-modal .sidebar-header .sidebar-logo {
  display: inline-block;
}
.sidebar-modal .sidebar-header .close-btn {
  display: inline-block;
  font-size: 20px;
  transition: all 0.5s;
  cursor: pointer;
  width: 30px;
  height: 30px;
  line-height: 33px;
  background-color: #d80650;
  color: #ffffff;
  border-radius: 0;
  float: right;
  text-align: center;
  position: relative;
  top: 1px;
  border-radius: 50%;
}
.sidebar-modal .sidebar-header .close-btn:hover {
  color: #ffffff;
  background-color: #d80650;
}
.sidebar-modal .sidebar-about {
  margin-bottom: 30px;
}
.sidebar-modal .sidebar-about .title h2 {
  margin-bottom: 0;
  font-size: 20px;
}
.sidebar-modal .sidebar-about .title p {
  margin-bottom: 0;
  font-size: 15px;
}
.sidebar-modal .contact-us {
  margin-bottom: 30px;
}
.sidebar-modal .contact-us h3 {
  font-size: 20px;
  margin-bottom: 25px;
  line-height: 1;
  position: relative;
  padding-left: 10px;
}
.sidebar-modal .contact-us h3::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 3px;
  height: 100%;
  background-color: #d80650;
}
.sidebar-modal .contact-us ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.sidebar-modal .contact-us ul li {
  position: relative;
  padding-left: 45px;
  margin-bottom: 20px;
}
.sidebar-modal .contact-us ul li:last-child {
  margin-bottom: 0;
}
.sidebar-modal .contact-us ul li i {
  position: absolute;
  top: 3px;
  left: 0;
  font-size: 18px;
  color: #ffffff;
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 50%;
  background-color: #d80650;
  text-align: center;
}
.sidebar-modal .contact-us ul li a {
  display: block;
}
.sidebar-modal .sidebar-gallery-feed {
  margin-bottom: 30px;
}
.sidebar-modal .sidebar-gallery-feed h3 {
  margin-bottom: 25px;
  font-size: 20px;
  position: relative;
  padding-left: 10px;
  line-height: 1;
}
.sidebar-modal .sidebar-gallery-feed h3::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 3px;
  height: 100%;
  background-color: #d80650;
}
.sidebar-modal .sidebar-gallery-feed ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  list-style-type: none;
  margin-left: -5px;
  margin-right: -5px;
  margin-bottom: 0;
  margin-top: -10px;
}
.sidebar-modal .sidebar-gallery-feed ul li {
  flex: 0 0 33%;
  max-width: 33%;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 10px;
}
.sidebar-modal .sidebar-gallery-feed ul li a {
  display: block;
  position: relative;
}
.sidebar-modal .sidebar-gallery-feed ul li a::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #000000;
  transition: all 0.5s;
  opacity: 0;
  visibility: hidden;
}
.sidebar-modal .sidebar-gallery-feed ul li a:hover::before {
  opacity: 0.5;
  visibility: visible;
}
.sidebar-modal .sidebar-follow-us h3 {
  font-size: 20px;
  margin-bottom: 25px;
  line-height: 1;
  position: relative;
  padding-left: 10px;
}
.sidebar-modal .sidebar-follow-us h3::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 3px;
  height: 100%;
  background-color: #d80650;
}
.sidebar-modal .sidebar-follow-us .social-wrap {
  padding: 0;
  margin: 0;
  list-style-type: none;
  line-height: 1;
}
.sidebar-modal .sidebar-follow-us .social-wrap li {
  display: inline-block;
  padding-right: 10px;
}
.sidebar-modal .sidebar-follow-us .social-wrap li a i {
  font-size: 18px;
  color: #ffffff;
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 50%;
  background-color: #d80650;
  text-align: center;
  transition: all 0.5s;
}
.sidebar-modal .sidebar-follow-us .social-wrap li a:hover i {
  background-color: #0e0129;
}

.sidebar-modal.active {
  opacity: 1;
  visibility: visible;
  background-color: rgba(0, 0, 0, 0.9);
}
.sidebar-modal.active .sidebar-modal-inner {
  right: 0;
}

/*
Banner Area Style
======================================================*/
.banner-area-six {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  position: relative;
  z-index: 1;
  padding-top: 150px;
  padding-bottom: 70px;
}
.banner-area-six .container-fluid {
  padding-left: 50px;
  padding-right: 50px;
}
.banner-area-six .banner-text {
  position: relative;
  max-width: 690px;
  z-index: 1;
  overflow: hidden;
  margin-bottom: -8px;
}
.banner-area-six .banner-text h1 {
  font-size: 68px;
  margin-bottom: 25px;
  line-height: 1.1;
}
.banner-area-six .banner-text p {
  font-size: 16px;
  margin-bottom: 35px;
}
.banner-area-six .banner-text .default-btn {
  border: 1px solid #d80650;
}
.banner-area-six .banner-text .default-btn:hover {
  border-color: #0e0129;
}
.banner-area-six .banner-text .default-btn.active i {
  color: #d80650;
  transition: all 0.5s;
}
.banner-area-six .banner-text .default-btn.active:hover {
  border-color: #0e0129;
}
.banner-area-six .banner-text .default-btn.active:hover i {
  color: #ffffff;
}
.banner-area-six .banner-shape-1 {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
  animation: FocuS 1s linear 1s infinite;
}
.banner-area-six .banner-shape-2 {
  position: absolute;
  top: 100px;
  right: 0;
  z-index: -1;
  animation: FocuS 1s linear 1s infinite;
}

/*
Services Area Style
=================================================*/
.services-slider-content {
  position: relative;
}
.services-slider-content h3 {
  margin-bottom: 22px;
  font-size: 35px;
  font-weight: 700;
}
.services-slider-content .default-btn {
  width: unset !important;
  margin-top: 15px;
}
.services-slider-content .thumbs-wrap {
  text-align: center;
  margin-bottom: 50px;
}
.services-slider-content .thumbs-wrap ul li {
  display: inline-block;
  font-weight: 600;
  margin: 0 10px;
  cursor: pointer;
  border-bottom: 2px solid #212121;
  padding-bottom: 4px;
  color: #212121;
  transition: all 0.5s;
}
.services-slider-content .thumbs-wrap ul li:hover {
  color: #d80650;
  border-color: #d80650;
}
.services-slider-content .thumbs-wrap ul li.active {
  color: #d80650;
  border-color: #d80650;
}
.services-slider-content .owl-theme .owl-nav .owl-prev, .services-slider-content .owl-theme .owl-nav .owl-next {
  position: absolute;
  left: -50px;
  top: 50%;
  transform: translateY(-40px);
  margin: 0;
}
.services-slider-content .owl-theme .owl-nav .owl-prev i, .services-slider-content .owl-theme .owl-nav .owl-next i {
  font-size: 20px;
  color: #0e0129;
  border: 1px solid #d80650;
  width: 40px;
  height: 40px;
  line-height: 38px;
  border-radius: 50%;
  display: inline-block;
  transition: all 0.5s;
}
.services-slider-content .owl-theme .owl-nav .owl-prev:hover i, .services-slider-content .owl-theme .owl-nav .owl-next:hover i {
  background-color: #d80650;
  color: #ffffff;
}
.services-slider-content .owl-theme .owl-nav .owl-next {
  right: -50px;
  left: auto;
}
.services-slider-content .owl-theme .owl-nav [class*=owl-]:hover {
  background-color: transparent;
}

/*
Serve Area Style
=================================================*/
.single-serve {
  position: relative;
  padding-left: 90px;
  margin-bottom: 30px;
  transition: all 0.5s;
}
.single-serve i {
  position: absolute;
  top: 7px;
  left: 0;
  color: #d80650;
  border: 1px solid #d80650;
  width: 70px;
  height: 70px;
  line-height: 70px;
  border-radius: 4px;
  font-size: 40px;
  text-align: center;
  background-color: #ffffff;
}
.single-serve h3 {
  margin-bottom: 10px;
  font-weight: 700;
}
.single-serve:hover {
  transform: translateY(-5px);
}

/*
Counter Area Style
=================================================*/
.counter-area-six {
  background-color: #0e0129;
}
.counter-area-six .col-lg-3:nth-child(4) .single-counters::before {
  display: none;
}

.single-counters {
  text-align: center;
  transition: all 0.5s;
  margin-bottom: 30px;
  padding: 30px;
  position: relative;
}
.single-counters::before {
  content: "";
  position: absolute;
  top: 0;
  right: -15px;
  width: 1px;
  height: 100%;
  background-color: #232223;
}
.single-counters i {
  border: 1px solid #d80650;
  color: #d80650;
  font-size: 40px;
  line-height: 1;
  display: block;
  width: 70px;
  height: 70px;
  line-height: 70px;
  margin: 0 auto 25px;
  border-radius: 50%;
  transition: all 0.5s;
}
.single-counters p {
  color: #ffffff;
  line-height: 1;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 500;
}
.single-counters h2 {
  color: #d80650;
  line-height: 1;
  font-size: 40px;
  margin-top: -8px;
  margin-bottom: 10px;
}
.single-counters .target {
  position: relative;
  top: 2px;
  right: 9px;
}
.single-counters:hover {
  transform: translateY(-5px);
}
.single-counters:hover i {
  background-color: #ffffff;
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  border-color: #ffffff;
}

/*
Services Area Style
=================================================*/
.choose-us-six-content .tabs li {
  padding: 0;
  padding: 10px 20px;
  font-weight: 600;
  position: relative;
  background-color: #e7e7e7;
  margin-bottom: 20px;
  border-radius: 50px 0 0 50px;
  cursor: pointer;
  color: #212121;
  text-align: right;
  display: block;
}
.choose-us-six-content .tabs li i {
  float: left;
  position: relative;
  top: -2px;
  left: -8px;
  font-size: 30px;
}
.choose-us-six-content .tabs li:last-child {
  margin-bottom: 0;
}
.choose-us-six-content .tabs .current {
  background-color: #d80650;
  color: #ffffff;
}

.choose-us-content {
  background-color: rgba(216, 6, 80, 0.9);
  width: 442px;
  height: 442px;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 50%;
  margin-right: -30px;
  position: relative;
  right: -60px;
}
.choose-us-content .choose-us {
  padding: 80px;
  color: #ffffff;
}
.choose-us-content .choose-us h3 {
  color: #ffffff;
  margin-bottom: 15px;
  font-weight: 700;
}
.choose-us-content .shape-1 {
  position: absolute;
  top: -50px;
  right: 60px;
  animation: rotation 100s infinite linear;
}
.choose-us-content .shape-3 {
  position: absolute;
  bottom: 50px;
  right: 50px;
  animation: rotation 100s infinite linear;
}

.choose-us-img {
  position: relative;
  left: -60px;
  z-index: -1;
}
.choose-us-img img {
  border-radius: 50%;
}
.choose-us-img .shape-2 {
  position: absolute;
  bottom: 25px;
  right: 0;
  animation: rotation 100s infinite linear;
}

.tab .tabs_item {
  display: none;
}
.tab .tabs_item:first-child {
  display: block;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
/*
Testimonials Area Style
======================================================*/
.testimonials-area .section-title-six {
  position: relative;
  z-index: 2;
}

.testimonials {
  text-align: center;
  max-width: 810px;
  margin: auto;
  position: relative;
  z-index: 1;
}
.testimonials .owl-nav .owl-prev {
  position: absolute;
  left: -100px;
  top: 50%;
  transform: translateY(-90px);
  margin: 0;
}
.testimonials .owl-nav .owl-prev i {
  font-size: 20px;
  color: #0e0129;
  background-color: #ffffff;
  width: 40px;
  height: 40px;
  line-height: 38px;
  border-radius: 50%;
  display: inline-block;
  transition: all 0.5s;
}
.testimonials .owl-nav .owl-prev:hover i {
  background-color: #d80650;
  color: #ffffff;
}
.testimonials .owl-nav .owl-next {
  position: absolute;
  right: -100px;
  top: 50%;
  transform: translateY(-90px);
  margin: 0;
}
.testimonials .owl-nav .owl-next i {
  font-size: 20px;
  color: #0e0129;
  background-color: #ffffff;
  width: 40px;
  height: 40px;
  line-height: 38px;
  border-radius: 50%;
  display: inline-block;
  transition: all 0.5s;
}
.testimonials .owl-nav .owl-next:hover i {
  background-color: #d80650;
  color: #ffffff;
}
.testimonials .owl-nav [class*=owl-]:hover {
  background-color: transparent;
}
.testimonials .owl-carousel .owl-item img {
  width: auto;
  margin: 0 auto 20px;
  border-radius: 50%;
}
.testimonials .quote {
  position: absolute;
  top: -175px;
  left: 0;
  right: 0;
  font-size: 350px;
  line-height: 1;
  color: #ebebeb;
  z-index: -1;
}

.testimonials-item {
  margin-bottom: 30px;
}
.testimonials-item p {
  margin-bottom: 40px;
  font-size: 20px;
}
.testimonials-item .testimonials-view {
  position: relative;
  display: inline-block;
  padding-left: 90px;
  text-align: left;
}
.testimonials-item .testimonials-view img {
  position: absolute;
  top: -12px;
  left: 0;
}
.testimonials-item .testimonials-view h3 {
  margin-bottom: 5px;
  font-size: 18px;
}
.testimonials-item .testimonials-view span {
  color: #d80650;
  font-weight: normal;
  margin-bottom: 0;
}

/*
video Area Style
======================================================*/
.video-area-six {
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.video-area-six::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background-color: #fafafa;
}

.video-img-six {
  max-width: 810px;
  margin: auto;
  position: relative;
}
.video-img-six .video-button {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}
.video-img-six h3 {
  position: absolute;
  top: 80px;
  left: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  color: #ffffff;
  font-size: 30px;
  margin-bottom: 0;
}
.video-img-six .video-btn {
  border-radius: 50%;
  background-image: linear-gradient(to bottom right, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5));
  transition: all 0.5s;
}
.video-img-six .video-btn i {
  color: #d80650;
}
.video-img-six .video-btn:hover {
  background-image: linear-gradient(to bottom right, #d80650, #d80650);
}
.video-img-six .video-btn:hover i {
  color: #ffffff;
}
.video-img-six .video-btn::before {
  border-radius: 50%;
  background-image: linear-gradient(to bottom right, #ffffff, #ffffff);
}
.video-img-six .video-btn::after {
  border-radius: 50%;
  background-image: linear-gradient(to bottom right, #ffffff, #ffffff);
}

/*
Lats Talk Area Style
======================================================*/
.lats-talk-area {
  
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: 1;
}
.lats-talk-area::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #0e0129;
  opacity: 0.2;
  z-index: -1;
}
.lats-talk-area .lats-talk-content {
  text-align: center;
  max-width: 810px;
  margin: auto;
}
.lats-talk-area .lats-talk-content h2 {
  margin-bottom: 20px;
  color: #ffffff;
  font-size: 40px;
}
.lats-talk-area .lats-talk-content p {
  max-width: 500px;
  margin: 0 auto 30px;
  color: #ffffff;
}

/*
Blog Area Style
======================================================*/
.single-blog-post {
  position: relative;
  transition: all 0.5s;
  margin-bottom: 30px;
}
.single-blog-post .blog-content {
  padding-top: 20px;
}
.single-blog-post .blog-content ul {
  line-height: 1;
  margin-bottom: 15px;
}
.single-blog-post .blog-content ul li {
  display: inline-block;
  margin-right: 10px;
}
.single-blog-post .blog-content ul li i {
  margin-right: 5px;
  color: #d80650;
}
.single-blog-post .blog-content h3 {
  margin-bottom: 0;
  font-weight: 700;
}
.single-blog-post .blog-content h3 a {
  color: #0e0129;
}
.single-blog-post:hover {
  transform: translateY(-5px);
}
.single-blog-post:hover h3 a {
  color: #d80650;
}

/*
Footer Top Area Style
======================================================*/
.single-footer-widget {
  margin-bottom: 30px;
}
.single-footer-widget .logo {
  margin-bottom: 34px;
}
.single-footer-widget p {
  margin-bottom: 30px;
}
.single-footer-widget .social-icon {
  line-height: 1;
}
.single-footer-widget .social-icon li {
  display: inline-block;
  margin-right: 5px;
}
.single-footer-widget .social-icon li a i {
  width: 30px;
  height: 30px;
  line-height: 31px;
  color: #ffffff;
  text-align: center;
  transition: all 0.5s;
  background-color: #0e0129;
  border-radius: 50%;
}
.single-footer-widget .social-icon li a:hover {
  transform: translateY(-2px);
}
.single-footer-widget .social-icon li a:hover i {
  background-color: #d80650;
}
.single-footer-widget h3 {
  font-size: 24px;
  margin-bottom: 20px;
  padding-bottom: 10px;
  position: relative;
  font-weight: 700;
}
.single-footer-widget h3::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 50px;
  height: 2px;
  background-color: #b2b2b2;
}
.single-footer-widget .address li {
  position: relative;
  margin-bottom: 12px;
}
.single-footer-widget .address li a {
  color: #212121;
}
.single-footer-widget .address li a:hover {
  color: #d80650;
}
.single-footer-widget .address li:last-child {
  margin-bottom: 0;
}
.single-footer-widget .address li i {
  position: absolute;
  color: #d80650;
  left: 0;
  top: 5px;
  font-size: 25px;
}
.single-footer-widget .import-link li {
  margin-bottom: 10px;
  position: relative;
}
.single-footer-widget .import-link li:last-child {
  margin-bottom: 0;
}
.single-footer-widget .import-link li a {
  color: #212121;
}
.single-footer-widget .import-link li:hover::before {
  border-radius: 50%;
}
.single-footer-widget .import-link li:hover a {
  color: #d80650;
}

/*
Copy Right Area Style
======================================================*/
.copy-right-area {
  border-top: 1px solid #e1e2e6;
  padding-top: 15px;
  padding-bottom: 15px;
}
.copy-right-area p {
  color: #212121;
}
.copy-right-area p a {
  color: #d80650;
}
.copy-right-area .footer-menu {
  float: right;
}
.copy-right-area .footer-menu li {
  display: inline-block;
  margin-right: 25px;
  position: relative;
}
.copy-right-area .footer-menu li::before {
  content: "";
  position: absolute;
  top: 12px;
  left: -18px;
  width: 10px;
  height: 2px;
  background-color: #212121;
}
.copy-right-area .footer-menu li:first-child::before {
  display: none;
}
.copy-right-area .footer-menu li:last-child {
  margin-right: 0;
}
.copy-right-area .footer-menu li a {
  color: #212121;
}
.copy-right-area .footer-menu li a:hover {
  color: #d80650;
}

/*====================================================
INNER PAGE STYLE AREA
======================================================*/
/* 

/*
Page Title Area Style
=====================================================*/
.page-title-area {
  position: relative;
  padding-top: 240px;
  padding-bottom: 150px;
  text-align: center;
  z-index: 1;
  background-color: #0e0129;
}
.page-title-area::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  animation: FocuS 1s linear 1s infinite;
}
.page-title-area .page-title-content h2 {
  margin-bottom: 15px;
  color: #ffffff;
  font-size: 40px;
  font-weight: 700;
}
.page-title-area .page-title-content ul {
  padding-left: 0;
  list-style-type: none;
  margin-top: 10px;
  margin-bottom: 0;
}
.page-title-area .page-title-content ul li {
  display: inline-block;
  position: relative;
  font-size: 15px;
  font-weight: 500;
  padding-right: 15px;
  padding-left: 15px;
  color: #ffffff;
}
.page-title-area .page-title-content ul li::before {
  content: "";
  position: absolute;
  top: 10px;
  right: -7px;
  background-color: #d80650;
  width: 10px;
  height: 10px;
}
.page-title-area .page-title-content ul li:last-child::before {
  display: none;
}
.page-title-area .page-title-content ul li a {
  color: #ffffff;
}
.page-title-area .page-title-content ul li a:hover {
  color: #d80650;
}
.page-title-area .page-title-content ul .active {
  color: #d80650;
}

/*
Client Page Area Style
=====================================================*/
.client-area-page .single-client {
  background-color: #fef4f8;
  margin-bottom: 195px;
  transition: all 0.5s;
}
.client-area-page .single-client::before {
  border-top: 45px solid #fef4f8;
  border-right: 45px solid transparent;
  border-left: 45px solid transparent;
  left: 0;
  right: 0;
  margin: auto;
}
.client-area-page .single-client .client-img {
  left: 0;
  right: 0;
}
.client-area-page .single-client .client-img h3 {
  color: #0e0129;
}
.client-area-page .single-client:hover {
  transform: translateY(-5px);
}

/*
Team Area Style
=================================================*/
.team-area .container {
  max-width: 1300px;
}

.single-team {
  text-align: center;
  margin-bottom: 30px;
  position: relative;
  transition: all 0.5s;
}
.single-team .image {
  position: relative;
}
.single-team .image::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 100%;
  background-image: linear-gradient(to bottom right, #d2044d, #ff5e68);
  opacity: 0.5;
  transition: all 0.5s;
}
.single-team .image .social {
  list-style-type: none;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 40px;
}
.single-team .image .social li {
  display: inline-block;
  transform: translateY(30px);
  transition: all 0.3s ease-in-out 0s;
  opacity: 0;
  visibility: hidden;
}
.single-team .image .social li a {
  display: block;
  width: 35px;
  height: 35px;
  background-color: #0e0129;
  text-align: center;
  position: relative;
  font-size: 15px;
  color: #ffffff;
  border-radius: 30px;
  transition: all 0.5s;
}
.single-team .image .social li a i {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  margin-left: auto;
  margin-right: auto;
}
.single-team .image .social li a:hover {
  color: #ffffff;
  background-color: #d80650;
}
.single-team .image .social li:nth-child(1) {
  transition-delay: 0.2s;
}
.single-team .image .social li:nth-child(2) {
  transition-delay: 0.3s;
}
.single-team .image .social li:nth-child(3) {
  transition-delay: 0.4s;
}
.single-team .image .social li:nth-child(4) {
  transition-delay: 0.5s;
}
.single-team .content {
  background-color: #ffffff;
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  padding: 30px;
  position: relative;
  margin: -30px auto 0;
  max-width: 250px;
  transition: all 0.5s;
}
.single-team .content h3 {
  margin-bottom: 0;
  font-size: 20px;
}
.single-team .content span {
  display: block;
  color: #d80650;
  font-size: 14px;
  margin-top: 6px;
  font-weight: 600;
}
.single-team:hover {
  transform: translateY(-5px);
}
.single-team:hover .content {
  max-width: 100%;
}
.single-team:hover .image::before {
  width: 100%;
  left: 0;
  right: auto;
}
.single-team:hover .image .social li {
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
}

/*
FAQ Area CSS
======================================================*/
.faq-accordion .accordion {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
}
.faq-accordion .accordion .accordion-item {
  border-radius: 5px;
  display: block;
  background-color: #ffffff;
  margin-bottom: 15px;
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
}
.faq-accordion .accordion .accordion-item:last-child {
  margin-bottom: 0;
}
.faq-accordion .accordion .accordion-title {
  padding: 20px 60px 17px 25px;
  color: #0e0129;
  text-decoration: none;
  position: relative;
  display: block;
  font-size: 16px;
  font-weight: 600;
}
.faq-accordion .accordion .accordion-title i {
  position: absolute;
  right: 25px;
  top: 20px;
  font-size: 25px;
  transition: 0.5s;
}
.faq-accordion .accordion .accordion-title.active i {
  transform: rotate(180deg);
}
.faq-accordion .accordion .accordion-title.active i::before {
  content: "\eaf8";
}
.faq-accordion .accordion .accordion-content {
  display: none;
  position: relative;
  margin-top: -5px;
  padding-bottom: 30px;
  padding-right: 30px;
  padding-left: 30px;
}
.faq-accordion .accordion .accordion-content p {
  line-height: 1.8;
}
.faq-accordion .accordion .accordion-content.show {
  display: block;
}

.faq-contact-area #contactForm {
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  padding: 50px;
}
.faq-contact-area #contactForm .form-group {
  margin-bottom: 30px;
}
.faq-contact-area #contactForm .default-btn {
  margin: 0 auto -8px;
  display: table;
}
.faq-contact-area #contactForm #msgSubmit {
  margin-bottom: 0;
  text-align: center;
  margin-top: 30px;
}
.faq-contact-area #contactForm .hidden {
  display: none;
}
.faq-contact-area #contactForm .list-unstyled {
  margin-top: 10px;
  color: #dc3545;
}

/*
Terms Conditions Area CSS
======================================================*/
.terms-conditions-area h3 {
  margin-bottom: 30px;
}
.terms-conditions-area ul li {
  margin-bottom: 20px;
}
.terms-conditions-area ul li p {
  position: relative;
  padding-left: 35px;
}
.terms-conditions-area ul li p strong {
  position: absolute;
  left: 0;
  top: 0;
}

.single-privacy h3 {
  margin-bottom: 30px;
}
.single-privacy ul li {
  margin-bottom: 20px;
}
.single-privacy ul li p {
  position: relative;
  padding-left: 35px;
}
.single-privacy ul li p strong {
  position: absolute;
  left: 0;
  top: 0;
}

/*
Sign In & Log In  Area Style
=====================================================*/
.user-area-all-style {
  position: relative;
  z-index: 1;
}
.user-area-all-style .contact-form-action {
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  padding: 40px;
  margin: auto;
  max-width: 570px;
}
.user-area-all-style .contact-form-action form .submit-btn {
  margin-top: 24px;
}
.user-area-all-style .contact-form-action form .default-btn {
  font-size: 14px;
  padding: 15px 40px;
  margin-bottom: 30px;
}
.user-area-all-style .contact-form-action form .default-btn.btn-two {
  display: table;
  margin: 0 auto 0;
  padding: 15px 40px;
}
.user-area-all-style .contact-form-action form .col-lg-4:nth-child(1) .default-btn {
  background-color: #db4a39;
}
.user-area-all-style .contact-form-action form .col-lg-4:nth-child(2) .default-btn {
  background-color: #3b5998;
  display: table;
  margin: 0 auto 30px;
}
.user-area-all-style .contact-form-action form .col-lg-4:nth-child(3) .default-btn {
  background-color: #00acee;
  float: right;
}
.user-area-all-style .contact-form-action form .reset-btn {
  margin-top: 0;
}
.user-area-all-style .contact-form-action form .form-condition {
  margin-bottom: 20px;
}
.user-area-all-style .contact-form-action form .form-condition .agree-label {
  font-weight: 600;
}
.user-area-all-style .contact-form-action form .form-condition .agree-label a {
  color: #d80650;
}
.user-area-all-style .contact-form-action form .form-group {
  margin-bottom: 30px;
}
.user-area-all-style .contact-form-action .account-desc {
  margin: 25px auto 0;
  display: table;
  font-weight: 600;
}
.user-area-all-style .contact-form-action .account-desc a {
  color: #d80650;
  margin-left: 10px;
  font-weight: 600;
}
.user-area-all-style .contact-form-action #chb1 {
  position: relative;
  top: 2px;
  margin-right: 4px;
}
.user-area-all-style .contact-form-action #chb2 {
  position: relative;
  top: 2px;
  margin-right: 4px;
}
.user-area-all-style .contact-form-action .forget {
  float: right;
  color: #d80650;
  font-weight: 600;
}
.user-area-all-style .contact-form-action .now-register {
  font-weight: 600;
  float: right;
  margin-bottom: 30px;
}
.user-area-all-style .contact-form-action .now-register a {
  color: #d80650;
}
.user-area-all-style .contact-form-action .now-log-in {
  color: #d80650;
  font-weight: 600;
}
.user-area-all-style .contact-form-action .now-log-in .font-q {
  font-weight: 600;
}


.recover-password-area .contact-form-action {
  max-width: 570px;
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
}
.recover-password-area .contact-form-action .form-heading h3 {
  font-size: 30px;
  margin-bottom: 20px;
}
.recover-password-area .contact-form-action .form-heading p {
  margin-bottom: 30px;
}

/* 
Coming Soon Area Style
=====================================================*/
.coming-soon-area {
  position: relative;
  height: 100vh;
  overflow: hidden;

  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.coming-soon-area::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #212121;
  opacity: 0.4;
}
.coming-soon-area .coming-soon-content {
  text-align: center;
  max-width: 750px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 2;
  -webkit-clip-path: polygon(0% 15%, 15% 15%, 15% 0%, 85% 0%, 85% 15%, 100% 15%, 100% 85%, 85% 85%, 85% 100%, 15% 100%, 15% 85%, 0% 85%);
          clip-path: polygon(0% 15%, 15% 15%, 15% 0%, 85% 0%, 85% 15%, 100% 15%, 100% 85%, 85% 85%, 85% 100%, 15% 100%, 15% 85%, 0% 85%);
  background-color: #fef4f8;
  padding: 70px 30px;
}
.coming-soon-area .coming-soon-content h1 {
  margin-bottom: 0;
  color: #0e0129;
  font-size: 60px;
  margin-top: -18px;
}
.coming-soon-area .coming-soon-content p {
  font-size: 16px;
  max-width: 600px;
  margin-top: 15px;
  margin-bottom: 0;
  margin-left: auto;
  margin-right: auto;
}
.coming-soon-area .coming-soon-content #timer {
  margin-top: 30px;
}
.coming-soon-area .coming-soon-content #timer div {
  display: inline-block;
  color: #0e0129;
  position: relative;
  margin-left: 35px;
  margin-right: 35px;
  font-size: 45px;
  font-weight: 700;
}
.coming-soon-area .coming-soon-content #timer div span {
  display: block;
  text-transform: capitalize;
  margin-top: -15px;
  font-size: 16px;
  font-weight: normal;
}
.coming-soon-area .coming-soon-content #timer div:last-child {
  margin-right: 0;
}
.coming-soon-area .coming-soon-content #timer div:last-child::before {
  display: none;
}
.coming-soon-area .coming-soon-content #timer div:first-child {
  margin-left: 0;
}
.coming-soon-area .coming-soon-content #timer div::before {
  content: "";
  position: absolute;
  right: -50px;
  top: -10px;
  font-size: 70px;
  color: #ffffff;
}
.coming-soon-area .coming-soon-content .newsletter-form {
  position: relative;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
}
.coming-soon-area .coming-soon-content .newsletter-form .input-newsletter {
  display: block;
  width: 100%;
  height: 60px;
  border: none;
  background-color: rgba(255, 255, 255, 0.3);
  padding-left: 15px;
  color: #ffffff;
  outline: 0;
  transition: 0.5s;
  border: 1px solid #0e0129;
  color: #212121;
}
.coming-soon-area .coming-soon-content .newsletter-form .input-newsletter:focus {
  border-color: #d80650;
}
.coming-soon-area .coming-soon-content .newsletter-form button {
  position: absolute;
  right: 0;
  top: 0;
  height: 60px;
  padding: 0 30px;
  text-transform: uppercase;
  outline: 0;
  color: #ffffff;
  transition: 0.5s;
  font-weight: 500;
  font-size: 15px;
  cursor: pointer;
}
.coming-soon-area .coming-soon-content .newsletter-form button::after {
  border-radius: 0;
}
.coming-soon-area .coming-soon-content .newsletter-form button::before {
  border-radius: 0;
}
.coming-soon-area .coming-soon-content .newsletter-form button:hover {
  color: #ffffff;
  background-color: #d80650;
}
.coming-soon-area .coming-soon-content .newsletter-form #validator-newsletter {
  color: #ffffff;
  font-size: 14px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -30px;
  margin: 0 auto;
}
.coming-soon-area .coming-soon-content ul {
  margin-top: 30px;
}
.coming-soon-area .coming-soon-content ul li {
  display: inline-block;
  margin: 0 5px;
}
.coming-soon-area .coming-soon-content ul li a {
  width: 30px;
  height: 30px;
  line-height: 36px;
  background-color: #d80650;
  border-radius: 50%;
  color: #ffffff;
  transition: all 0.5s;
}
.coming-soon-area .coming-soon-content ul li a i {
  font-size: 18px;
}
.coming-soon-area .coming-soon-content ul li a:hover {
  transform: translateY(-5px);
}
.coming-soon-area #validator-newsletter {
  text-align: left;
  color: #dc3545 !important;
}

/*
404 Error Area Style
=====================================================*/
.error-area {
  padding: 50px 0;
  height: 100vh;
  text-align: center;
  position: relative;
  z-index: 1;
  background-color: #ffffff;
}
.error-area .error-content-wrap {
  z-index: 1;
  position: relative;
}
.error-area .error-content-wrap h1 {
  font-size: 300px;
  line-height: 1;
  color: #0e0129;
}
.error-area .error-content-wrap h1 .a {
  animation: bounce 3s infinite linear;
  display: inline-block;
}
.error-area .error-content-wrap h1 .red {
  color: #ff0000;
  animation: bounce 2s infinite linear;
  display: inline-block;
}
.error-area .error-content-wrap h1 .b {
  animation: bounce 3s infinite linear;
  display: inline-block;
}
.error-area .error-content-wrap h3 {
  margin: 30px 0 0;
  position: relative;
  color: #ff0000;
}
.error-area .error-content-wrap p {
  margin: 20px 0 20px;
  font-size: 19px;
  color: #0e0129;
}

/*
Product Area Style
=====================================================*/
.product-area .woocommerce-topbar {
  margin-bottom: 40px;
}
.product-area .woocommerce-topbar .woocommerce-topbar-ordering {
  text-align: right;
}
.product-area .woocommerce-topbar .woocommerce-topbar-ordering .nice-select {
  background-color: #ffffff;
  color: #d80650;
  border-radius: 0;
  border: none;
  box-shadow: 0 0 29px 0 rgba(102, 102, 102, 0.1);
  float: unset;
  height: unset;
  line-height: initial;
  padding: 17px 35px 13px 20px;
  font-size: 16px;
}
.product-area .woocommerce-topbar .woocommerce-topbar-ordering .nice-select .list {
  background-color: #ffffff;
  box-shadow: 0 0 29px 0 rgba(102, 102, 102, 0.1);
  border-radius: 0;
  margin-top: 0;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
}
.product-area .woocommerce-topbar .woocommerce-topbar-ordering .nice-select .list .option {
  transition: 0.6s;
  font-size: 16px;
  padding-left: 20px;
  padding-right: 20px;
}
.product-area .woocommerce-topbar .woocommerce-topbar-ordering .nice-select .list .option:hover {
  background-color: #d80650 !important;
  color: #ffffff;
}
.product-area .woocommerce-topbar .woocommerce-topbar-ordering .nice-select .list .option.selected {
  font-weight: 700;
  color: #d80650;
}
.product-area .woocommerce-topbar .woocommerce-topbar-ordering .nice-select:after {
  right: 20px;
}
.product-area .single-product-box {
  text-align: center;
  margin-bottom: 30px;
  background-color: #ffffff;
  box-shadow: 0 0 40px 3px rgba(0, 0, 0, 0.05);
}
.product-area .single-product-box .product-image {
  overflow: hidden;
  position: relative;
  text-align: left;
}
.product-area .single-product-box .product-image a img {
  transition: 0.6s;
}
.product-area .single-product-box .product-image .add-to-cart-btn {
  position: absolute;
  bottom: -46px;
  left: 0;
  width: 100%;
  background-color: #0e0129;
  color: #ffffff;
  padding: 18px 20px 13px;
  text-transform: uppercase;
  opacity: 0;
  visibility: hidden;
  text-align: center;
  font-size: 15px;
  font-weight: 700;
}
.product-area .single-product-box .product-image .add-to-cart-btn i {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}
.product-area .single-product-box .product-image .add-to-cart-btn:hover {
  background-color: #d80650;
  color: #ffffff;
}
.product-area .single-product-box .product-image .sale-btn {
  position: absolute;
  left: 20px;
  top: 20px;
  background-color: #d80650;
  color: #ffffff;
  width: 55px;
  height: 55px;
  line-height: 55px;
  text-align: center;
  border-radius: 50%;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 14px;
}
.product-area .single-product-box .product-content {
  padding: 20px;
}
.product-area .single-product-box .product-content h3 {
  margin-bottom: 12px;
  font-size: 18px;
  font-weight: 700;
}
.product-area .single-product-box .product-content h3 a {
  display: inline-block;
  color: #0e0129;
  text-decoration: none;
}
.product-area .single-product-box .product-content h3 a:hover {
  color: #d80650;
}
.product-area .single-product-box .product-content .price {
  color: #0e0129;
  font-weight: 500;
  font-size: 15px;
}
.product-area .single-product-box .product-content .price span {
  padding: 0 5px;
}
.product-area .single-product-box .product-content .price .old {
  text-decoration: line-through;
  color: #d80650;
  font-size: 14px;
}
.product-area .single-product-box .product-content .rating {
  margin-top: 8px;
  color: #ffb708;
  font-size: 15px;
}
.product-area .single-product-box .product-content .rating i {
  margin-right: -1px;
}
.product-area .single-product-box:hover .product-image .add-to-cart-btn {
  bottom: 0;
  opacity: 1;
  visibility: visible;
  transition: 0.6s;
  text-decoration: none;
}

/* 
Cart Area Style
=====================================================*/
.cart-area .cart-table table {
  margin-bottom: 0;
}
.cart-area .cart-table table thead tr th {
  border-bottom-width: 0px;
  vertical-align: middle;
  padding: 0 0 15px;
  text-transform: uppercase;
  border: none;
  font-weight: 600;
  font-size: 15px;
}
.cart-area .cart-table table tbody tr td {
  vertical-align: middle;
  color: #666666;
  padding-left: 0;
  padding-right: 0;
  font-size: 14px;
  border-color: #eaedff;
  border-left: none;
  border-right: none;
}
.cart-area .cart-table table tbody tr td.product-thumbnail img {
  width: 60px;
  height: 60px;
}
.cart-area .cart-table table tbody tr td.product-thumbnail a {
  display: block;
}
.cart-area .cart-table table tbody tr td.product-name a {
  color: #666666;
  font-weight: 600;
  display: inline-block;
}
.cart-area .cart-table table tbody tr td.product-name a:hover {
  color: #0e0129;
}
.cart-area .cart-table table tbody tr td.product-price span {
  font-weight: 500;
}
.cart-area .cart-table table tbody tr td.product-subtotal {
  overflow: hidden;
}
.cart-area .cart-table table tbody tr td.product-subtotal .remove {
  color: #666666;
  float: right;
  position: relative;
  top: 1px;
  transition: all 0.5s;
}
.cart-area .cart-table table tbody tr td.product-subtotal .remove i {
  font-size: 22px;
}
.cart-area .cart-table table tbody tr td.product-subtotal .remove:hover {
  color: red;
}
.cart-area .cart-table table tbody tr td.product-subtotal span {
  font-weight: 500;
}
.cart-area .cart-table table tbody tr td.product-quantity .input-counter {
  max-width: 130px;
  min-width: 130px;
  text-align: center;
  display: inline-block;
  position: relative;
}
.cart-area .cart-table table tbody tr td.product-quantity .input-counter span {
  position: absolute;
  top: 0;
  background-color: transparent;
  cursor: pointer;
  color: #0e0129;
  width: 40px;
  height: 100%;
  line-height: 48px;
  transition: 0.5s;
}
.cart-area .cart-table table tbody tr td.product-quantity .input-counter span:hover {
  color: #d80650;
}
.cart-area .cart-table table tbody tr td.product-quantity .input-counter span.minus-btn {
  left: 0;
}
.cart-area .cart-table table tbody tr td.product-quantity .input-counter span.plus-btn {
  right: 0;
}
.cart-area .cart-table table tbody tr td.product-quantity .input-counter input {
  height: 45px;
  color: #0e0129;
  outline: 0;
  display: block;
  border: none;
  background-color: #f8f8f8;
  text-align: center;
  width: 100%;
  font-size: 15px;
  font-weight: 500;
}
.cart-area .cart-table table tbody tr td.product-quantity .input-counter input::-moz-placeholder {
  color: #0e0129;
}
.cart-area .cart-table table tbody tr td.product-quantity .input-counter input::placeholder {
  color: #0e0129;
}
.cart-area .cart-buttons {
  margin-top: 30px;
}
.cart-area .cart-totals {
  background: #ffffff;
  padding: 40px;
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  border-radius: 0;
  margin: auto;
  margin-top: 50px;
}
.cart-area .cart-totals h3 {
  margin-bottom: 25px;
}
.cart-area .cart-totals ul {
  padding: 0;
  margin: 0 0 25px;
  list-style-type: none;
}
.cart-area .cart-totals ul li {
  border: 1px solid #eaedff;
  padding: 10px 15px;
  color: #323232;
  overflow: hidden;
  font-weight: 600;
}
.cart-area .cart-totals ul li:first-child {
  border-bottom: none;
}
.cart-area .cart-totals ul li:last-child {
  border-top: none;
}
.cart-area .cart-totals ul li span {
  float: right;
  color: #666666;
  font-weight: normal;
}
.cart-area .cart-area .nice-select {
  height: 50px;
  line-height: 49px;
  border-radius: 0;
  width: 100%;
  margin-bottom: 18px;
}
.cart-area .cart-wraps {
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  background-color: #fff;
  padding: 40px;
}
.cart-area .coupon-wrap {
  margin-top: 50px;
  background-color: #ffffff;
  padding: 40px;
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
}
.cart-area .coupon-wrap .nice-select {
  background-color: #f2f9fc;
}
.cart-area .coupon-wrap .nice-select .list {
  width: 100%;
}
.cart-area .coupon-wrap .nice-select .option {
  color: #0e0129 !important;
}
.cart-area .coupon-wrap .nice-select .option:hover {
  color: #ffffff !important;
  background-color: #1089d4 !important;
}
.cart-area .coupon-wrap select .option.focus, .cart-area .coupon-wrap .nice-select .option.selected.focus {
  background-color: #1089d4 !important;
  color: #ffffff !important;
}
.cart-area .coupon-wrap h3 {
  padding-bottom: 25px;
}
.cart-area .coupon-wrap .form-group {
  margin-bottom: 18px;
}
.cart-area .coupon-wrap .nice-select {
  height: 50px;
  width: 100%;
  margin-bottom: 19px;
  border-radius: 0;
}
.cart-area .coupon-wrap .nice-select .current {
  position: relative;
  top: 4px;
}

/* 
Checkout Area Style 
=====================================================*/
.checkout-area .user-actions {
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  background: #ffffff;
  padding: 18px 20px 15px;
  margin-bottom: 65px;
  border-top: 3px solid #d80650;
  position: relative;
  color: #d80650;
}
.checkout-area .user-actions::before {
  content: "";
  position: absolute;
  right: 0;
  bottom: -35px;
  width: 100%;
  height: 1px;
  background: #eeeeee;
}
.checkout-area .user-actions i {
  color: #d80650;
  margin-right: 2px;
  font-size: 20px;
  position: relative;
  top: 4px;
}
.checkout-area .user-actions span {
  display: inline-block;
  font-weight: 500;
  color: #0e0129;
}
.checkout-area .user-actions span a {
  display: inline-block;
  color: #d80650;
  font-size: 15px;
}
.checkout-area .user-actions span a:hover {
  color: #d80650;
}
.checkout-area .user-actions span a:focus {
  color: #d80650;
}
.checkout-area .checkout-area .title {
  margin-bottom: 25px;
  font-size: 20px;
  font-weight: 700;
}
.checkout-area .billing-details {
  background-color: #ffffff;
  padding: 30px;
  box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
}
.checkout-area .billing-details .form-group {
  margin-bottom: 20px;
}
.checkout-area .billing-details .form-group label {
  display: block;
  color: #0e0129;
  margin-bottom: 10px;
  font-size: 15px;
  font-weight: 500;
}
.checkout-area .billing-details .form-group label .required {
  color: red;
}
.checkout-area .billing-details .form-group .nice-select {
  float: unset;
  line-height: 45px;
  color: #0e0129;
  padding-top: 0;
  padding-bottom: 0;
}
.checkout-area .billing-details .form-group .nice-select .list {
  background-color: #ffffff;
  box-shadow: 0px 0px 29px 0px rgba(102, 102, 102, 0.1);
  border-radius: 0;
  margin-top: 0;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
}
.checkout-area .billing-details .form-group .nice-select .list .option {
  transition: 0.5s;
  padding-left: 20px;
  padding-right: 20px;
}
.checkout-area .billing-details .form-group .nice-select .list .option:hover {
  background-color: #d80650 !important;
  color: #ffffff;
}
.checkout-area .billing-details .form-group .nice-select .list .option.selected {
  background-color: transparent;
}
.checkout-area .billing-details .form-group .nice-select:after {
  right: 20px;
}
.checkout-area .billing-details .form-group .nice-select .option:hover, .checkout-area .billing-details .form-group .nice-select .option.focus, .checkout-area .billing-details .form-group .nice-select .option.selected.focus {
  background-color: #d80650 !important;
  color: #ffffff !important;
}
.checkout-area .billing-details .form-group textarea {
  padding-top: 13px;
}
.checkout-area .billing-details .form-check {
  margin-bottom: 20px;
}
.checkout-area .billing-details .form-check .form-check-label {
  color: #0e0129;
}
.checkout-area .billing-details .form-check label {
  position: relative;
  top: 1px;
  font-size: 14px;
  font-weight: 500;
}
.checkout-area .billing-details .col-lg-12:last-child .form-group {
  margin-bottom: 0;
}
.checkout-area .order-details .order-table {
  background-color: #ffffff;
  padding: 30px;
  box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
}
.checkout-area .order-details .order-table table {
  margin-bottom: 0;
}
.checkout-area .order-details .order-table table thead tr th {
  border-bottom-width: 0;
  vertical-align: middle;
  border-color: #eaedff;
  padding-left: 20px;
  padding-top: 15px;
  padding-right: 20px;
  padding-bottom: 15px;
  font-weight: 700;
}
.checkout-area .order-details .order-table table tbody tr td {
  vertical-align: middle;
  color: #0e0129;
  border-color: #eaedff;
  font-size: 14px;
  padding-left: 20px;
  padding-right: 20px;
}
.checkout-area .order-details .order-table table tbody tr td.product-name a {
  color: #0e0129;
  display: inline-block;
  font-weight: 500;
  font-size: 14px;
}
.checkout-area .order-details .order-table table tbody tr td.product-name a:hover {
  color: #0e0129;
}
.checkout-area .order-details .order-table table tbody tr td.order-subtotal span {
  color: #0e0129;
  font-weight: 600;
}
.checkout-area .order-details .order-table table tbody tr td.order-shipping span {
  color: #0e0129;
  font-weight: 600;
}
.checkout-area .order-details .order-table table tbody tr td.total-price span {
  color: #0e0129;
  font-weight: 600;
}
.checkout-area .order-details .order-table table tbody tr td.shipping-price {
  font-weight: 600;
}
.checkout-area .order-details .order-table table tbody tr td.order-subtotal-price {
  font-weight: 600;
}
.checkout-area .order-details .order-table table tbody tr td.product-subtotal {
  font-weight: 600;
}
.checkout-area .order-details .payment-box {
  background-color: #ffffff;
  box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
  margin-top: 30px;
  padding: 30px;
}
.checkout-area .order-details .payment-box .payment-method p [type=radio]:checked {
  display: none;
}
.checkout-area .order-details .payment-box .payment-method p [type=radio]:checked + label {
  padding-left: 27px;
  cursor: pointer;
  display: block;
  font-weight: 600;
  color: #d80650;
  position: relative;
  margin-bottom: 8px;
}
.checkout-area .order-details .payment-box .payment-method p [type=radio]:checked + label::before {
  content: "";
  position: absolute;
  left: 0;
  top: 3px;
  width: 18px;
  height: 18px;
  border: 1px solid #dddddd;
  border-radius: 50%;
  background: #ffffff;
}
.checkout-area .order-details .payment-box .payment-method p [type=radio]:checked + label::after {
  content: "";
  width: 12px;
  height: 12px;
  background: #d80650;
  position: absolute;
  top: 6px;
  left: 3px;
  border-radius: 50%;
  transition: 0.5s;
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}
.checkout-area .order-details .payment-box .payment-method p [type=radio]:not(:checked) {
  display: none;
}
.checkout-area .order-details .payment-box .payment-method p [type=radio]:not(:checked) + label {
  padding-left: 27px;
  cursor: pointer;
  display: block;
  font-weight: 600;
  color: #0e0129;
  position: relative;
  margin-bottom: 8px;
}
.checkout-area .order-details .payment-box .payment-method p [type=radio]:not(:checked) + label::before {
  content: "";
  position: absolute;
  left: 0;
  top: 3px;
  width: 18px;
  height: 18px;
  border: 1px solid #dddddd;
  border-radius: 50%;
  background: #ffffff;
}
.checkout-area .order-details .payment-box .payment-method p [type=radio]:not(:checked) + label::after {
  content: "";
  width: 12px;
  height: 12px;
  background: #d80650;
  position: absolute;
  top: 6px;
  left: 3px;
  border-radius: 50%;
  transition: 0.5s;
  opacity: 0;
  visibility: hidden;
  transform: scale(0);
}
.checkout-area .order-details .payment-box .btn.order-btn {
  display: block;
  margin-top: 25px;
}
.checkout-area .order-details .payment-box .default-btn {
  margin-top: 20px;
  display: block;
  text-align: center;
}

/*
Product Details Area Style
=====================================================*/
.product-details-area .product-details-desc h3 {
  margin-bottom: 12px;
  font-weight: 700;
}
.product-details-area .product-details-desc p {
  margin-bottom: 0;
}
.product-details-area .product-details-desc .price {
  margin-bottom: 10px;
  color: #333333;
  font-weight: 700;
}
.product-details-area .product-details-desc .price span {
  padding: 0 5px;
}
.product-details-area .product-details-desc .price .old-price {
  text-decoration: line-through;
  color: #828893;
  font-size: 13px;
}
.product-details-area .product-details-desc .product-review {
  margin-bottom: 15px;
}
.product-details-area .product-details-desc .product-review .rating {
  display: inline-block;
  padding-right: 5px;
  font-size: 14px;
}
.product-details-area .product-details-desc .product-review .rating i {
  color: #ffba0a;
}
.product-details-area .product-details-desc .product-review .rating-count {
  display: inline-block;
  color: #333333;
  border-bottom: 1px solid #333333;
  line-height: initial;
}
.product-details-area .product-details-desc .product-review .rating-count:hover {
  color: #d80650;
  border-color: #d80650;
}
.product-details-area .product-details-desc .product-add-to-cart {
  margin-top: 20px;
}
.product-details-area .product-details-desc .product-add-to-cart .input-counter {
  max-width: 130px;
  min-width: 130px;
  margin-right: 10px;
  text-align: center;
  display: inline-block;
  position: relative;
}
.product-details-area .product-details-desc .product-add-to-cart .input-counter span {
  position: absolute;
  top: 0;
  background-color: transparent;
  cursor: pointer;
  color: #d0d0d0;
  width: 40px;
  height: 100%;
  line-height: 48px;
  transition: 0.5s;
}
.product-details-area .product-details-desc .product-add-to-cart .input-counter span:hover {
  color: #0e0129;
}
.product-details-area .product-details-desc .product-add-to-cart .input-counter span.minus-btn {
  left: 0;
}
.product-details-area .product-details-desc .product-add-to-cart .input-counter span.plus-btn {
  right: 0;
}
.product-details-area .product-details-desc .product-add-to-cart .input-counter input {
  height: 45px;
  color: #333333;
  outline: 0;
  display: block;
  border: none;
  background-color: #f8f8f8;
  text-align: center;
  width: 100%;
  font-size: 17px;
  font-weight: 600;
}
.product-details-area .product-details-desc .product-add-to-cart .input-counter input::-moz-placeholder {
  color: #333333;
}
.product-details-area .product-details-desc .product-add-to-cart .input-counter input::placeholder {
  color: #333333;
}
.product-details-area .product-details-desc .product-add-to-cart .default-btn {
  border: none;
  cursor: pointer;
  float: right;
}
.product-details-area .product-details-desc .product-add-to-cart .btn {
  height: 45px;
  top: -2px;
  position: relative;
  padding-top: 0;
  padding-bottom: 0;
}
.product-details-area .product-details-desc .product-add-to-cart .btn i {
  margin-right: 2px;
}
.product-details-area .product-details-desc .buy-checkbox-btn {
  margin-top: 20px;
}
.product-details-area .product-details-desc .buy-checkbox-btn .default-btn::before {
  display: none;
}
.product-details-area .product-details-desc .buy-checkbox-btn input {
  display: none;
}
.product-details-area .product-details-desc .buy-checkbox-btn .cbx {
  margin: auto;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  cursor: pointer;
}
.product-details-area .product-details-desc .buy-checkbox-btn .cbx span {
  display: inline-block;
  vertical-align: middle;
  transform: translate3d(0, 0, 0);
}
.product-details-area .product-details-desc .buy-checkbox-btn .cbx span:first-child {
  position: relative;
  width: 18px;
  height: 18px;
  border-radius: 3px;
  transform: scale(1);
  vertical-align: middle;
  border: 1px solid #ebebeb;
  transition: all 0.2s ease;
  transition: 0.5s;
}
.product-details-area .product-details-desc .buy-checkbox-btn .cbx span:first-child svg {
  position: absolute;
  top: 3px;
  left: 2px;
  fill: none;
  stroke: #ffffff;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 16px;
  stroke-dashoffset: 16px;
  transition: all 0.3s ease;
  transition-delay: 0.1s;
  transform: translate3d(0, 0, 0);
  transition: 0.5s;
}
.product-details-area .product-details-desc .buy-checkbox-btn .cbx span:first-child:before {
  content: "";
  width: 100%;
  height: 100%;
  background: #333333;
  display: block;
  transform: scale(0);
  opacity: 1;
  border-radius: 50%;
  transition: 0.5s;
}
.product-details-area .product-details-desc .buy-checkbox-btn .cbx span:last-child {
  padding-left: 4px;
  color: #57647c;
}
.product-details-area .product-details-desc .buy-checkbox-btn .cbx:hover span:first-child {
  border-color: #333333;
}
.product-details-area .product-details-desc .buy-checkbox-btn .inp-cbx:checked + .cbx span:first-child {
  background: #333333;
  border-color: #333333;
  animation: wave 0.4s ease;
}
.product-details-area .product-details-desc .buy-checkbox-btn .inp-cbx:checked + .cbx span:first-child svg {
  stroke-dashoffset: 0;
}
.product-details-area .product-details-desc .buy-checkbox-btn .inp-cbx:checked + .cbx span:first-child:before {
  transform: scale(3.5);
  opacity: 0;
  transition: all 0.6s ease;
}
.product-details-area .product-details-desc .buy-checkbox-btn .item:not(:first-child) {
  margin-top: 15px;
}
.product-details-area .product-details-desc .buy-checkbox-btn .btn {
  display: block;
  width: 100%;
}
.product-details-area .product-details-desc .buy-checkbox-btn .default-btn {
  text-transform: uppercase;
  display: block;
  text-align: center;
}
.product-details-area .product-details-desc .custom-payment-options {
  margin-top: 20px;
}
.product-details-area .product-details-desc .custom-payment-options span {
  display: block;
  color: #57647c;
  margin-bottom: 15px;
}
.product-details-area .product-details-desc .custom-payment-options .payment-methods a {
  display: inline-block;
}
.product-details-area .product-details-desc .custom-payment-options .payment-methods a img {
  width: 40px;
  margin-right: 5px;
}
.product-details-area .product-details-image {
  text-align: center;
  background-color: #f7f7f7;
}
.product-details-area .tab .tabs_item {
  display: none;
}
.product-details-area .tab .tabs_item:first-child {
  display: block;
}
.product-details-area .products-details-tab {
  margin-top: 50px;
}
.product-details-area .products-details-tab .tabs {
  list-style-type: none;
  margin-bottom: -1px;
  padding-left: 0;
}
.product-details-area .products-details-tab .tabs li {
  display: inline-block;
  line-height: initial;
  margin-right: 5px;
}
.product-details-area .products-details-tab .tabs li a {
  display: inline-block;
  position: relative;
  text-transform: uppercase;
  color: #333333;
  border: 1px solid #eeeeee;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-right: 30px;
  padding-left: 48px;
  font-weight: 700;
}
.product-details-area .products-details-tab .tabs li a .dot {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 30px;
  width: 12px;
  height: 12px;
  border: 1px solid #333333;
  transition: 0.5s;
  border-radius: 50%;
}
.product-details-area .products-details-tab .tabs li a .dot::before {
  position: absolute;
  top: 0;
  content: "";
  left: 0;
  right: 0;
  bottom: 0;
  background: #333333;
  margin: 2px;
  border-radius: 50%;
  transition: 0.5s;
}
.product-details-area .products-details-tab .tabs li a:hover {
  color: #ffffff;
  background-color: #d80650;
  border-color: #d80650;
}
.product-details-area .products-details-tab .tabs li a:hover .dot {
  border-color: #ffffff;
}
.product-details-area .products-details-tab .tabs li a:hover .dot::before {
  background: #ffffff;
}
.product-details-area .products-details-tab .tabs li a:focus {
  color: #ffffff;
  background-color: #d80650;
  border-color: #d80650;
}
.product-details-area .products-details-tab .tabs li a:focus .dot {
  border-color: #ffffff;
}
.product-details-area .products-details-tab .tabs li a:focus .dot::before {
  background: #ffffff;
}
.product-details-area .products-details-tab .tabs li:last-child {
  margin-right: 0;
}
.product-details-area .products-details-tab .tabs li.current a {
  color: #ffffff;
  background-color: #d80650;
  border-color: #d80650;
}
.product-details-area .products-details-tab .tabs li.current a .dot {
  border: 1px solid #ffffff;
}
.product-details-area .products-details-tab .tabs li.current a .dot::before {
  background: #ffffff;
}
.product-details-area .products-details-tab .tab_content {
  border: 1px solid #eeeeee;
  padding: 30px;
}
.product-details-area .products-details-tab .tab_content .tabs_item .products-details-tab-content p {
  margin-bottom: 20px;
  font-size: 14px;
}
.product-details-area .products-details-tab .tab_content .tabs_item .products-details-tab-content p:last-child {
  margin-bottom: 0;
}
.product-details-area .products-details-tab .tab_content .tabs_item .products-details-tab-content .additional-information {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
  text-align: left;
}
.product-details-area .products-details-tab .tab_content .tabs_item .products-details-tab-content .additional-information li {
  border: 1px solid #eeeeee;
  border-bottom: none;
  padding: 10px 15px;
  color: #57647c;
}
.product-details-area .products-details-tab .tab_content .tabs_item .products-details-tab-content .additional-information li:last-child {
  border-bottom: 1px solid #eeeeee;
}
.product-details-area .products-details-tab .tab_content .tabs_item .products-details-tab-content .additional-information li span {
  display: inline-block;
  width: 30%;
  color: #333333;
  font-size: 16px;
  font-weight: 600;
}
.product-details-area .products-details-tab .tab_content .tabs_item .products-details-tab-content h3 {
  margin-bottom: 15px;
  font-weight: 700;
}
.product-details-area .products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-title {
  position: relative;
}
.product-details-area .products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-title .rating {
  display: inline-block;
}
.product-details-area .products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-title .rating .fas.fa-star {
  color: #ffba0a;
}
.product-details-area .products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-title .rating i {
  color: #ffba0a;
  font-size: 14px;
}
.product-details-area .products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-title p {
  margin-bottom: 0;
  display: inline-block;
  padding-left: 5px;
  line-height: initial;
}
.product-details-area .products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-title .btn {
  position: absolute;
  right: 0;
  bottom: 10px;
  padding: 10px 11px;
  font-size: 11px;
}
.product-details-area .products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-title .btn:focus {
  border: none !important;
  box-shadow: none !important;
  border: 4px;
  background-color: #0e0129;
}
.product-details-area .products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-comments {
  margin-top: 35px;
}
.product-details-area .products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-comments .review-item {
  margin-top: 30px;
  position: relative;
  padding-right: 200px;
  border-top: 1px dashed #eeeeee;
  padding-top: 30px;
}
.product-details-area .products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-comments .review-item .rating .fas.fa-star {
  color: #ffba0a;
}
.product-details-area .products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-comments .review-item .rating i {
  font-size: 14px;
  color: #ffba0a;
}
.product-details-area .products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-comments .review-item h3 {
  margin-top: 10px;
  margin-bottom: 10px;
}
.product-details-area .products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-comments .review-item span {
  margin-bottom: 10px;
  font-size: 13px;
  display: block;
}
.product-details-area .products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-comments .review-item span strong {
  font-weight: 600;
}
.product-details-area .products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-comments .review-item p {
  margin-bottom: 0;
}
.product-details-area .products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-comments .review-item .review-report-link {
  position: absolute;
  right: 0;
  color: #57647c;
  top: 40px;
  text-decoration: underline;
}
.product-details-area .products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-comments .review-item .review-report-link:hover {
  color: #ff4800;
}
.product-details-area .products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-form {
  margin-top: 30px;
}
.product-details-area .products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-form form .form-group {
  margin-bottom: 20px;
}
.product-details-area .products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-form form .form-group label {
  color: #444444;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 600;
}
.product-details-area .products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-form form .form-group textarea {
  padding-top: 15px;
}
.product-details-area .products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-form form .form-group .form-control {
  font-size: 14px;
}
.product-details-area .products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-form form .btn:focus {
  box-shadow: none;
  border: none;
  border-radius: 0;
}

/*
Solutions Area Style
=====================================================*/
.solutions-area.section-width .container {
  max-width: 1300px;
}

/*
Blog Details Area Style
=====================================================*/
.blog-details-area {
  /*
  Sidebar Widget Area Style
  ================================*/
}
.blog-details-area .blog-details-desc .article-content {
  margin-top: 0;
  margin-bottom: 30px;
}
.blog-details-area .blog-details-desc .article-content .article-image {
  margin-bottom: 30px;
}
.blog-details-area .blog-details-desc .article-content .entry-meta {
  margin-bottom: -10px;
}
.blog-details-area .blog-details-desc .article-content .entry-meta ul {
  padding-left: 0;
  margin-bottom: 15px;
  list-style-type: none;
}
.blog-details-area .blog-details-desc .article-content .entry-meta ul li {
  position: relative;
  display: inline-block;
  color: #d80650;
  margin-right: 21px;
  font-size: 14px;
}
.blog-details-area .blog-details-desc .article-content .entry-meta ul li span {
  display: inline-block;
  color: #d80650;
  font-weight: 500;
}
.blog-details-area .blog-details-desc .article-content .entry-meta ul li a {
  display: inline-block;
  color: #666666;
  font-size: 13px;
}
.blog-details-area .blog-details-desc .article-content .entry-meta ul li a:hover {
  color: #d80650;
}
.blog-details-area .blog-details-desc .article-content .entry-meta ul li i {
  color: #d80650;
  margin-right: 2px;
}
.blog-details-area .blog-details-desc .article-content .entry-meta ul li::before {
  content: "";
  position: absolute;
  top: 11px;
  right: -15px;
  width: 6px;
  height: 1px;
  background: #d80650;
}
.blog-details-area .blog-details-desc .article-content .entry-meta ul li:last-child {
  margin-right: 0;
}
.blog-details-area .blog-details-desc .article-content .entry-meta ul li:last-child::before {
  display: none;
}
.blog-details-area .blog-details-desc .article-content h3 {
  margin-bottom: 30px;
  margin-top: 0;
  font-size: 30px;
}
.blog-details-area .blog-details-desc .article-content .wp-block-gallery.columns-3 {
  padding-left: 0;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
  margin-bottom: 30px;
  margin-top: 30px;
}
.blog-details-area .blog-details-desc .article-content .wp-block-gallery.columns-3 li {
  flex: 0 0 33.3333%;
  max-width: 33.3333%;
  padding-right: 10px;
  padding-left: 10px;
}
.blog-details-area .blog-details-desc .article-content .wp-block-gallery.columns-3 li figure {
  margin-bottom: 0;
}
.blog-details-area .blog-details-desc .article-footer {
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
}
.blog-details-area .blog-details-desc .article-footer .article-tags {
  flex: 0 0 50%;
  max-width: 50%;
}
.blog-details-area .blog-details-desc .article-footer .article-tags span {
  display: inline-block;
  color: #d80650;
  font-size: 20px;
  margin-right: 5px;
  position: relative;
  top: 4px;
}
.blog-details-area .blog-details-desc .article-footer .article-tags a {
  display: inline-block;
  color: #666666;
  font-weight: 600;
}
.blog-details-area .blog-details-desc .article-footer .article-tags a:hover {
  color: #d80650;
}
.blog-details-area .blog-details-desc .article-footer .article-share {
  flex: 0 0 50%;
  max-width: 50%;
}
.blog-details-area .blog-details-desc .article-footer .article-share .social {
  padding-left: 0;
  list-style-type: none;
  text-align: right;
  margin-bottom: 0;
}
.blog-details-area .blog-details-desc .article-footer .article-share .social li {
  display: inline-block;
}
.blog-details-area .blog-details-desc .article-footer .article-share .social li span {
  display: inline-block;
  margin-right: 2px;
  font-weight: 500;
  color: #666666;
}
.blog-details-area .blog-details-desc .article-footer .article-share .social li a {
  display: block;
  color: #ffffff;
  width: 32px;
  height: 32px;
  line-height: 33px;
  border-radius: 50%;
  background-color: #d80650;
  text-align: center;
  font-size: 14px;
}
.blog-details-area .blog-details-desc .article-footer .article-share .social li a:hover {
  animation: rubberBand 1s linear;
}
.blog-details-area .blog-details-desc .post-navigation {
  margin-top: 30px;
}
.blog-details-area blockquote {
  overflow: hidden;
  background-color: #f7f7f7;
  padding: 50px !important;
  position: relative;
  z-index: 1;
  margin-bottom: 20px;
  margin-top: 20px;
  border-radius: 4px;
}
.blog-details-area blockquote p {
  color: #0e0129;
  line-height: 1.6;
  margin-bottom: 0;
  font-style: italic;
  font-weight: 600;
  font-size: 17px !important;
  text-align: left;
}
.blog-details-area blockquote cite {
  display: none;
}
.blog-details-area blockquote::before {
  color: #d80650;
  content: "\ed67";
  position: absolute;
  left: 50px;
  top: -50px;
  z-index: -1;
  font-family: "boxicons";
  font-size: 140px;
  font-weight: 900;
  opacity: 0.1;
}
.blog-details-area blockquote::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 3px;
  background-color: #d80650;
  margin-top: 20px;
  margin-bottom: 20px;
}
.blog-details-area .post-navigation {
  border-top: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
  padding-top: 20px;
  padding-bottom: 20px;
}
.blog-details-area .post-navigation .navigation-links {
  display: flex;
  flex-wrap: wrap;
}
.blog-details-area .post-navigation .navigation-links .nav-previous {
  flex: 0 0 50%;
  max-width: 50%;
}
.blog-details-area .post-navigation .navigation-links .nav-previous a i {
  margin-right: 0;
  font-size: 20px;
  position: relative;
  top: 4px;
}
.blog-details-area .post-navigation .navigation-links .nav-next {
  flex: 0 0 50%;
  max-width: 50%;
  text-align: right;
}
.blog-details-area .post-navigation .navigation-links .nav-next a i {
  margin-left: 0;
  font-size: 20px;
  position: relative;
  top: 4px;
}
.blog-details-area .post-navigation .navigation-links div a {
  display: inline-block;
  font-weight: 600;
  color: #0e0129;
}
.blog-details-area .post-navigation .navigation-links div a:hover {
  color: #d80650;
}
.blog-details-area .comments-area {
  padding: 0;
  margin-top: 30px;
}
.blog-details-area .comments-area .comments-title {
  position: relative;
  margin-bottom: 30px;
  line-height: initial;
  font-size: 22px;
}
.blog-details-area .comments-area ol {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.blog-details-area .comments-area ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.blog-details-area .comments-area .comment-list {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.blog-details-area .comments-area .children {
  margin-left: 40px;
}
.blog-details-area .comments-area .comment-body {
  border-bottom: 1px solid #eeeeee;
  padding-left: 65px;
  font-size: 14px;
  margin-bottom: 20px;
  padding-bottom: 20px;
}
.blog-details-area .comments-area .comment-body.border-none {
  border: none;
  margin-bottom: 0;
  padding-bottom: 0;
}
.blog-details-area .comments-area .comment-body .reply {
  margin-top: 15px;
}
.blog-details-area .comments-area .comment-body .reply a {
  border: 1px solid #ded9d9;
  color: #0e0129;
  display: inline-block;
  padding: 5px 20px;
  border-radius: 30px;
  text-transform: uppercase;
  position: relative;
  z-index: 1;
  font-size: 13px;
  font-weight: 500;
}
.blog-details-area .comments-area .comment-body .reply a:hover {
  color: #ffffff;
  background-color: #d80650;
  border-color: #d80650;
}
.blog-details-area .comments-area .comment-meta {
  margin-bottom: 0.8em;
}
.blog-details-area .comments-area .comment-author {
  font-size: 16px;
  margin-bottom: 0.4em;
  position: relative;
  z-index: 2;
}
.blog-details-area .comments-area .comment-author .avatar {
  height: 50px;
  left: -65px;
  position: absolute;
  width: 50px;
  border-radius: 50%;
}
.blog-details-area .comments-area .comment-author .fn {
  font-weight: 500;
  color: #0e0129;
  font-size: 16px;
}
.blog-details-area .comments-area .comment-author .says {
  display: none;
}
.blog-details-area .comments-area .comment-metadata {
  color: #666666;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 500;
}
.blog-details-area .comments-area .comment-metadata a {
  color: #666666;
  font-size: 11px;
}
.blog-details-area .comments-area .comment-metadata a:hover {
  color: #d80650;
}
.blog-details-area .comments-area .comment-content p {
  font-size: 14px;
}
.blog-details-area .comments-area .comment-respond {
  margin-top: 30px;
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  padding: 30px;
}
.blog-details-area .comments-area .comment-respond .comment-reply-title {
  margin-bottom: 15px;
  font-size: 22px;
}
.blog-details-area .comments-area .comment-respond .comment-reply-title #cancel-comment-reply-link {
  display: inline-block;
}
.blog-details-area .comments-area .comment-respond .comment-form {
  overflow: hidden;
}
.blog-details-area .comments-area .comment-respond .comment-form-comment {
  margin-bottom: 30px;
  float: left;
  width: 100%;
}
.blog-details-area .comments-area .comment-respond label {
  display: block;
  font-weight: 500;
  color: #0e0129;
  margin-bottom: 5px;
}
.blog-details-area .comments-area .comment-respond input[type=datetime-local] {
  display: block;
  width: 100%;
  background-color: #f7f7f7;
  border: 1px solid #eeeeee;
  padding: 0.625em 0.7375em;
  outline: 0;
  transition: 0.5s;
}
.blog-details-area .comments-area .comment-respond input[type=datetime-local]:focus {
  border-color: #d80650;
}
.blog-details-area .comments-area .comment-respond input[type=week] {
  display: block;
  width: 100%;
  background-color: #f7f7f7;
  border: 1px solid #eeeeee;
  padding: 0.625em 0.7375em;
  outline: 0;
  transition: 0.5s;
}
.blog-details-area .comments-area .comment-respond input[type=week]:focus {
  border-color: #d80650;
}
.blog-details-area .comments-area .comment-respond input[type=month] {
  display: block;
  width: 100%;
  background-color: #f7f7f7;
  border: 1px solid #eeeeee;
  padding: 0.625em 0.7375em;
  outline: 0;
  transition: 0.5s;
}
.blog-details-area .comments-area .comment-respond input[type=month]:focus {
  border-color: #d80650;
}
.blog-details-area .comments-area .comment-respond input[type=text] {
  display: block;
  width: 100%;
  background-color: #f7f7f7;
  border: 1px solid #eeeeee;
  padding: 0.625em 0.7375em;
  outline: 0;
  transition: 0.5s;
}
.blog-details-area .comments-area .comment-respond input[type=text]:focus {
  border-color: #d80650;
}
.blog-details-area .comments-area .comment-respond input[type=email] {
  display: block;
  width: 100%;
  background-color: #f7f7f7;
  border: 1px solid #eeeeee;
  padding: 0.625em 0.7375em;
  outline: 0;
  transition: 0.5s;
}
.blog-details-area .comments-area .comment-respond input[type=email]:focus {
  border-color: #d80650;
}
.blog-details-area .comments-area .comment-respond input[type=url] {
  display: block;
  width: 100%;
  background-color: #f7f7f7;
  border: 1px solid #eeeeee;
  padding: 0.625em 0.7375em;
  outline: 0;
  transition: 0.5s;
}
.blog-details-area .comments-area .comment-respond input[type=url]:focus {
  border-color: #d80650;
}
.blog-details-area .comments-area .comment-respond input[type=password] {
  display: block;
  width: 100%;
  background-color: #f7f7f7;
  border: 1px solid #eeeeee;
  padding: 0.625em 0.7375em;
  outline: 0;
  transition: 0.5s;
}
.blog-details-area .comments-area .comment-respond input[type=password]:focus {
  border-color: #d80650;
}
.blog-details-area .comments-area .comment-respond input[type=search] {
  display: block;
  width: 100%;
  background-color: #f7f7f7;
  border: 1px solid #eeeeee;
  padding: 0.625em 0.7375em;
  outline: 0;
  transition: 0.5s;
}
.blog-details-area .comments-area .comment-respond input[type=search]:focus {
  border-color: #d80650;
}
.blog-details-area .comments-area .comment-respond input[type=tel] {
  display: block;
  width: 100%;
  background-color: #f7f7f7;
  border: 1px solid #eeeeee;
  padding: 0.625em 0.7375em;
  outline: 0;
  transition: 0.5s;
}
.blog-details-area .comments-area .comment-respond input[type=tel]:focus {
  border-color: #d80650;
}
.blog-details-area .comments-area .comment-respond input[type=number] {
  display: block;
  width: 100%;
  background-color: #f7f7f7;
  border: 1px solid #eeeeee;
  padding: 0.625em 0.7375em;
  outline: 0;
  transition: 0.5s;
}
.blog-details-area .comments-area .comment-respond input[type=number]:focus {
  border-color: #d80650;
}
.blog-details-area .comments-area .comment-respond textarea {
  display: block;
  width: 100%;
  background-color: #f7f7f7;
  border: 1px solid #eeeeee;
  padding: 0.625em 0.7375em;
  outline: 0;
  transition: 0.5s;
}
.blog-details-area .comments-area .comment-respond textarea:focus {
  border-color: #d80650;
}
.blog-details-area .comments-area .comment-respond .comment-form-author {
  float: left;
  width: 50%;
  padding-right: 10px;
  margin-bottom: 20px;
}
.blog-details-area .comments-area .comment-respond .comment-form-email {
  float: left;
  width: 50%;
  padding-left: 12px;
  margin-bottom: 20px;
}
.blog-details-area .comments-area .comment-respond .comment-form-url {
  float: left;
  width: 100%;
  margin-bottom: 20px;
}
.blog-details-area .comments-area .comment-respond .comment-form-cookies-consent {
  width: 100%;
  float: left;
  position: relative;
  padding-left: 20px;
  margin-bottom: 20px;
}
.blog-details-area .comments-area .comment-respond .comment-form-cookies-consent input {
  position: absolute;
  left: 0;
  top: 6px;
}
.blog-details-area .comments-area .comment-respond .comment-form-cookies-consent label {
  display: inline-block;
  margin: 0;
  color: #666666;
  font-weight: normal;
}
.blog-details-area .comments-area .comment-respond .form-submit {
  float: left;
  width: 100%;
}
.blog-details-area .comments-area .comment-respond .form-submit input {
  background: #d80650;
  border: none;
  color: #ffffff;
  padding: 14px 30px 12px;
  display: inline-block;
  cursor: pointer;
  outline: 0;
  border-radius: 0;
  text-transform: uppercase;
  transition: 0.5s;
  font-weight: 500;
  font-size: 14px;
}
.blog-details-area .comments-area .comment-respond .form-submit input:hover {
  color: #ffffff;
  background-color: #0e0129;
}
.blog-details-area .comments-area .comment-respond .form-submit input:focus {
  color: #ffffff;
  background-color: #d80650;
}
.blog-details-area .related-posts {
  margin-bottom: 20px !important;
  font-size: 25px !important;
}
.blog-details-area .b-d-s-item {
  position: relative;
  margin-bottom: 0;
}
.blog-details-area .b-d-s-item img {
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  margin-bottom: 10px;
}
.blog-details-area .b-d-s-item .s-date {
  position: absolute;
  left: 0;
  top: 0;
  background-color: #ffffff;
  padding: 10px 24px;
  line-height: 1.5;
  display: inline-block;
}
.blog-details-area .b-d-s-item h3 {
  margin-bottom: 10px !important;
  font-size: 20px !important;
  margin-bottom: 0 !important;
}
.blog-details-area .b-d-s-item p {
  margin-bottom: 12px;
  margin-top: 10px;
}
.blog-details-area .widget-area .widget {
  margin-top: 35px;
}
.blog-details-area .widget-area .widget .post-wrap {
  padding-top: 20px;
}
.blog-details-area .widget-area .widget:first-child {
  margin-top: 0;
}
.blog-details-area .widget-area .widget .widget-title {
  text-transform: capitalize;
  position: relative;
  font-size: 20px;
  margin-bottom: 0;
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
  color: #0e0129;
  position: relative;
}
.blog-details-area .widget-area .widget_search form {
  position: relative;
}
.blog-details-area .widget-area .widget_search form label {
  display: block;
  margin-bottom: 0;
}
.blog-details-area .widget-area .widget_search form .screen-reader-text {
  display: none;
}
.blog-details-area .widget-area .widget_search form .search-field {
  background-color: transparent;
  height: 50px;
  padding: 6px 15px;
  border: 1px solid #eeeeee;
  width: 100%;
  display: block;
  outline: 0;
  transition: 0.5s;
}
.blog-details-area .widget-area .widget_search form .search-field:focus {
  border-color: #d80650;
}
.blog-details-area .widget-area .widget_search form button {
  position: absolute;
  right: 0;
  outline: 0;
  bottom: 0;
  height: 50px;
  width: 50px;
  border: none;
  color: #ffffff;
  background-color: #d80650;
  transition: 0.5s;
  cursor: pointer;
}
.blog-details-area .widget-area .widget_search form button:hover {
  background-color: #0e0129;
}
.blog-details-area .widget-area .widget-peru-posts-thumb {
  position: relative;
  overflow: hidden;
}
.blog-details-area .widget-area .widget-peru-posts-thumb .item {
  overflow: hidden;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #efefef;
}
.blog-details-area .widget-area .widget-peru-posts-thumb .item:last-child {
  margin-bottom: 0;
  border-bottom: none;
  padding-bottom: 0;
}
.blog-details-area .widget-area .widget-peru-posts-thumb .item .thumb {
  float: left;
  height: 130px;
  overflow: hidden;
  position: relative;
  width: 130px;
  margin-right: 15px;
}
.blog-details-area .widget-area .widget-peru-posts-thumb .item .thumb .fullimage {
  width: 130px;
  height: 130px;
  display: inline-block;
  background-size: cover !important;
  background-repeat: no-repeat;
  background-position: center center !important;
  position: relative;
  background-color: #0e0129;
}
.blog-details-area .widget-area .widget-peru-posts-thumb .item .info {
  overflow: hidden;
}
.blog-details-area .widget-area .widget-peru-posts-thumb .item .info span {
  display: block;
  color: #666666;
  text-transform: uppercase;
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 12px;
  font-weight: 500;
}
.blog-details-area .widget-area .widget-peru-posts-thumb .item .info .title {
  margin-bottom: 0;
  line-height: 1.5;
  font-size: 15px;
  font-weight: 600;
}
.blog-details-area .widget-area .widget-peru-posts-thumb .item .info .title a {
  display: inline-block;
  color: #474c40;
}
.blog-details-area .widget-area .widget-peru-posts-thumb .item .info .title a:hover {
  color: #d80650;
}
.blog-details-area .widget-area .widget_recent_entries ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.blog-details-area .widget-area .widget_recent_entries ul li {
  position: relative;
  margin-bottom: 12px;
  color: #666666;
  padding-left: 17px;
  line-height: 1.5;
  font-size: 15px;
  font-weight: 600;
}
.blog-details-area .widget-area .widget_recent_entries ul li:last-child {
  margin-bottom: 0;
}
.blog-details-area .widget-area .widget_recent_entries ul li::before {
  background: #d80650;
  position: absolute;
  height: 7px;
  width: 7px;
  content: "";
  left: 0;
  top: 7px;
}
.blog-details-area .widget-area .widget_recent_entries ul li .post-date {
  display: block;
  font-size: 13px;
  color: #666666;
  margin-top: 4px;
}
.blog-details-area .widget-area .widget_categories ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.blog-details-area .widget-area .widget_categories ul li {
  position: relative;
  padding-bottom: 10px;
  margin-bottom: 10px;
  color: #666666;
  padding-left: 20px;
  font-size: 15px;
  font-weight: 600;
  border-bottom: 1px solid #efefef;
}
.blog-details-area .widget-area .widget_categories ul li:last-child {
  margin-bottom: 0;
  border-bottom: none;
  padding-bottom: 0;
}
.blog-details-area .widget-area .widget_categories ul li::before {
  background: #d80650;
  position: absolute;
  height: 10px;
  width: 10px;
  content: "";
  left: 0;
  top: 7px;
  -webkit-clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
          clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
}
.blog-details-area .widget-area .widget_categories ul li a {
  display: block;
  color: #666666;
  font-weight: normal;
  font-size: 14px;
}
.blog-details-area .widget-area .widget_categories ul li a span {
  float: right;
  font-size: 13px;
}
.blog-details-area .widget-area .widget_categories ul li a:hover {
  color: #d80650;
}
.blog-details-area .widget-area .widget_categories ul li .post-count {
  float: right;
}
.blog-details-area .widget-area .widget_meta ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.blog-details-area .widget-area .widget_meta ul li {
  position: relative;
  padding-bottom: 10px;
  margin-bottom: 10px;
  color: #666666;
  padding-left: 17px;
  font-size: 15px;
  font-weight: 400;
  border-bottom: 1px solid #efefef;
}
.blog-details-area .widget-area .widget_meta ul li a {
  color: #212121;
  color: #666666;
}
.blog-details-area .widget-area .widget_meta ul li a:hover {
  color: #d80650;
}
.blog-details-area .widget-area .widget_meta ul li:last-child {
  margin-bottom: 0;
}
.blog-details-area .widget-area .widget_meta ul li::before {
  background: #d80650;
  position: absolute;
  height: 10px;
  width: 10px;
  content: "";
  left: 0;
  top: 8px;
  -webkit-clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
          clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
}
.blog-details-area .widget-area .widget_tag_cloud .widget-title {
  margin-bottom: 0;
}
.blog-details-area .widget-area .tagcloud a {
  display: inline-block;
  color: #666666;
  font-weight: normal;
  font-size: 14px !important;
  padding: 7px 10px;
  border: 1px dashed #eeeeee;
  margin-top: 10px;
  margin-right: 10px;
  text-transform: capitalize;
}
.blog-details-area .widget-area .tagcloud a:hover {
  background-color: #d80650;
  color: #ffffff;
}
.blog-details-area .widget-area .tagcloud a:focus {
  background-color: #d80650;
  color: #ffffff;
  border-color: #d80650;
}
.blog-details-area .widget-area .widget_services_list ul {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;
  box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.04);
  background-color: #ffffff;
}
.blog-details-area .widget-area .widget_services_list ul li a {
  display: block;
  position: relative;
  border-bottom: 1px solid #eeeeee;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 15px;
  padding-right: 35px;
  color: #5d6576;
  z-index: 1;
  font-weight: 500;
}
.blog-details-area .widget-area .widget_services_list ul li a::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 100%;
  background: #252920;
  transition: 0.5s;
  opacity: 0;
  visibility: hidden;
}
.blog-details-area .widget-area .widget_services_list ul li a i {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
}
.blog-details-area .widget-area .widget_services_list ul li a:hover {
  background-color: #d80650;
  color: #ffffff;
  padding-left: 20px;
}
.blog-details-area .widget-area .widget_services_list ul li a:hover::before {
  opacity: 1;
  visibility: visible;
  width: 5px;
}
.blog-details-area .widget-area .widget_services_list ul li a.active {
  background-color: #d80650;
  color: #ffffff;
  padding-left: 20px;
}
.blog-details-area .widget-area .widget_services_list ul li a.active::before {
  opacity: 1;
  visibility: visible;
  width: 5px;
}
.blog-details-area .widget-area .widget_services_list ul li:last-child a {
  border-bottom: none;
}
.blog-details-area .widget-area .widget_download ul {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;
  box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.04);
  background-color: #ffffff;
}
.blog-details-area .widget-area .widget_download ul li a {
  display: block;
  position: relative;
  border-bottom: 1px solid #eeeeee;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 15px;
  padding-right: 35px;
  color: #5d6576;
  z-index: 1;
  font-weight: 500;
}
.blog-details-area .widget-area .widget_download ul li a::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 100%;
  background: #252920;
  transition: 0.5s;
  opacity: 0;
  visibility: hidden;
}
.blog-details-area .widget-area .widget_download ul li a i {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
}
.blog-details-area .widget-area .widget_download ul li a:hover {
  background-color: #d80650;
  color: #ffffff;
  padding-left: 20px;
}
.blog-details-area .widget-area .widget_download ul li a:hover::before {
  opacity: 1;
  visibility: visible;
  width: 5px;
}
.blog-details-area .widget-area .widget_download ul li a.active {
  background-color: #d80650;
  color: #ffffff;
  padding-left: 20px;
}
.blog-details-area .widget-area .widget_download ul li a.active::before {
  opacity: 1;
  visibility: visible;
  width: 5px;
}
.blog-details-area .widget-area .widget_download ul li:last-child a {
  border-bottom: none;
}
.blog-details-area .widget-area .info time {
  font-size: 13px;
  color: #929292;
  display: block;
  margin-bottom: 4px;
}

/*
Contact Info Area Style
=====================================================*/
.single-contact-info {
  text-align: center;
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  padding: 100px 30px;
}
.single-contact-info i {
  font-size: 40px;
  width: 60px;
  height: 60px;
  line-height: 60px;
  background-color: #d80650;
  color: #ffffff;
  display: inline-block;
  border-radius: 50%;
  margin-bottom: 15px;
}
.single-contact-info h3 {
  margin-bottom: 10px;
}
.single-contact-info p {
  margin-bottom: 5px;
}
.single-contact-info a {
  display: block;
  margin-bottom: 5px;
}
.single-contact-info a:last-child {
  margin-bottom: 0;
}

.single-contact-map iframe {
  height: 395px;
  width: 100%;
}

/*
Contact Info Area Style
=====================================================*/
.services-details h3 {
  font-size: 28px;
  margin-bottom: 20px;
}
.services-details img {
  margin-bottom: 20px;
}
.services-details p {
  margin-bottom: 20px;
}
.services-details .choose-wrap {
  background-color: #fcf2f6 !important;
  max-width: unset !important;
  margin-bottom: 20px;
}
.services-details .choose-wrap h2 {
  color: #0e0129;
  font-size: 28px;
}
.services-details .choose-wrap p {
  color: #212121;
}
.services-details .choose-wrap ul li {
  color: #0e0129;
}


.transform-area-five .transform-content-five {
  padding: 35px;
  margin: 0;
}

/*====================================================
OTHERS STYLE AREA
======================================================*/
/*
Preloader Area Style*/
.loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  overflow: hidden;
  z-index: 9999;
}
.loader-wrapper .loader-section {
  position: fixed;
  top: 0;
  width: 51%;
  height: 100%;
  background: #ffffff;
  z-index: 10;
}
.loader-wrapper .loader-section.section-left {
  left: 0;
}
.loader-wrapper .loader-section.section-right {
  right: 0;
}

.loader {
  display: block;
  position: relative;
  left: 50%;
  top: 50%;
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #16a085;
  animation: spin 1.7s linear infinite;
  z-index: 11;
}
.loader:before {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #e74c3c;
  animation: spin-reverse 0.6s linear infinite;
}
.loader:after {
  content: "";
  position: absolute;
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #f9c922;
  animation: spin 1s linear infinite;
}

.loaded .loader-wrapper {
  visibility: hidden;
  transform: translateY(-100%);
  transition: all 0.3s 1s ease-out;
}
.loaded .loader-wrapper .loader-section.section-left {
  transform: translateX(-100%);
  transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.loaded .loader-wrapper .loader-section.section-right {
  transform: translateX(100%);
  transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.loaded .loader {
  opacity: 0;
  transition: all 0.3s ease-out;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes spin-reverse {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
/*
Go Top Style*/
.go-top {
  position: fixed;
  cursor: pointer;
  top: 85%;
  right: -10%;
  background-color: #d80650;
  z-index: 4;
  width: 40px;
  text-align: center;
  height: 42px;
  line-height: 42px;
  opacity: 0;
  visibility: hidden;
  transition: 0.9s;
}
.go-top i {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0 auto;
  color: #ffffff;
  transition: 0.5s;
  font-size: 20px;
}
.go-top i:last-child {
  opacity: 0;
  visibility: hidden;
  top: 60%;
}
.go-top::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: #d80650;
  opacity: 0;
  visibility: hidden;
  transition: 0.5s;
}
.go-top:hover {
  color: #ffffff;
  background-color: #0e0129;
}
.go-top:hover::before {
  opacity: 1;
  visibility: visible;
}
.go-top:hover i:first-child {
  opacity: 0;
  top: 0;
  visibility: hidden;
}
.go-top:hover i:last-child {
  opacity: 1;
  visibility: visible;
  top: 50%;
}
.go-top:focus {
  color: #ffffff;
}
.go-top:focus::before {
  opacity: 1;
  visibility: visible;
}
.go-top:focus i:first-child {
  opacity: 0;
  top: 0;
  visibility: hidden;
}
.go-top:focus i:last-child {
  opacity: 1;
  visibility: visible;
  top: 50%;
}

.go-top.active {
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
  right: 3%;
  top: 85%;
}

/*
Video wave Style*/
.video-btn {
  display: inline-block;
  width: 80px;
  height: 80px;
  line-height: 89px;
  text-align: center;
  border-radius: 0;
  color: #d80650;
  position: relative;
  top: 3px;
  z-index: 1;
  background-image: linear-gradient(to bottom right, #d2044d, #ff5e68);
}
.video-btn i {
  font-size: 50px;
  font-weight: 700;
  color: #ffffff;
  position: absolute;
  top: 0;
  left: 4px;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100%;
  height: 100%;
}
.video-btn::after, .video-btn::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
  bottom: 0;
  left: 0;
  border-radius: 0;
  background-image: linear-gradient(to bottom right, #d2044d, #ff5e68);
}
.video-btn::before {
  animation: ripple 1.6s ease-out infinite;
}
.video-btn::after {
  animation: ripple 1.6s ease-out infinite;
}
.video-btn:hover {
  background-color: #d80650;
}
.video-btn:hover i {
  color: #ffffff;
}
.video-btn:hover::after, .video-btn:hover::before {
  background-color: #d80650;
}

@keyframes ripple {
  0%, 35% {
    transform: scale(0);
    opacity: 1;
  }
  50% {
    transform: scale(1.5);
    opacity: 0.8;
  }
  100% {
    opacity: 0;
    transform: scale(2);
  }
}
/*
Section Title Area Style*/
.section-title {
  max-width: 660px;
  margin: -12px auto 45px;
  text-align: center;
  position: relative;
}
.section-title span {
  color: #d80650;
  display: block;
  margin-bottom: 8px;
  font-weight: 600;
}
.section-title h2 {
  font-size: 50px;
  margin-bottom: 15px;
  position: relative;
  display: block;
  text-transform: capitalize;
}
.section-title h2:last-child {
  margin-bottom: 0;
}
.section-title.white-title span {
  color: #ffffff;
}
.section-title.white-title h2 {
  color: #ffffff;
}
.section-title.white-title p {
  color: #ffffff;
}

/*
Nice select Area Style*/
.nice-select .list {
  width: 100%;
}

.nice-select .option:hover {
  background-color: #0e0129;
  color: #ffffff;
}
.nice-select .option.selected.focus {
  color: #0e0129;
}

/*
Page-navigation Area Style*/
.page-navigation-area {
  text-align: center;
  margin: 20px auto 0;
  display: table;
}
.page-navigation-area .page-link {
  color: #d80650;
  background-color: #ffffff;
  box-shadow: 0 0 15px #d0d0d0;
  width: 40px;
  height: 40px;
  border: 1px solid #ffffff;
  line-height: 24px;
  transition: all 0.5s;
  font-weight: 700;
}
.page-navigation-area .page-link i {
  margin-right: -4px;
  font-size: 21px;
}
.page-navigation-area .page-link:hover {
  color: #ffffff;
  background-color: #d80650;
  border: 1px solid #d80650;
}
.page-navigation-area .page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: none;
}
.page-navigation-area .page-link.page-links i::before {
  margin-left: -4px;
}
.page-navigation-area .page-item {
  padding: 0 8px 0;
}
.page-navigation-area .page-item:first-child .page-link {
  border-radius: none;
}
.page-navigation-area .page-item:last-child .page-link {
  border-radius: none;
}
.page-navigation-area .page-item.active .page-link {
  z-index: 1;
  color: #ffffff;
  background-color: #d80650;
  border-color: #d80650;
}

.pb-75 {
  padding-bottom: 75px;
}

.body-with-black-color {
  background-color: #1C0531;
}
.body-with-black-color .section-title h2 {
  color: #ffffff;
}
.body-with-black-color .section-title p {
  color: #ABA8AE !important;
}
.body-with-black-color .section-title.white-title p {
  color: #ffffff !important;
}

/*
Section Title Wrap CSS
======================================================*/
.section-title-wrap {
  margin-bottom: 45px;
}
.section-title-wrap h2 {
  font-size: 50px;
  margin-bottom: 15px;
}
.section-title-wrap p {
  color: #707070;
}
.section-title-wrap .wrap-btn {
  text-align: end;
}
.section-title-wrap .wrap-btn .default-btn {
  background: linear-gradient(136.1deg, #D80650 1.84%, #FE5D68 100%);
  border-radius: 5px;
}

/*
Seku New Navbar Area CSS
======================================================*/
.seku-new-nav-area .container-fluid {
  padding-left: 30px;
  padding-right: 30px;
}
.seku-new-nav-area .navbar-area .main-nav {
  background-color: transparent;
  box-shadow: none;
  padding-top: 0;
  padding-bottom: 0;
}
.seku-new-nav-area .navbar-area .main-nav .navbar {
  padding-left: 0;
  padding-right: 0;
}
.seku-new-nav-area .navbar-area .main-nav nav .navbar-nav .nav-item a {
  font-size: 16px;
  color: #0e0129;
  text-transform: capitalize;
  transition: all 0.5s;
  margin-left: 0;
  margin: 0 10px;
  position: relative;
}
.seku-new-nav-area .navbar-area .main-nav nav .navbar-nav .nav-item a i {
  font-size: 20px;
  line-height: 0;
  position: relative;
  top: 5px;
}
.seku-new-nav-area .navbar-area .main-nav nav .navbar-nav .nav-item a:hover {
  color: #d80650;
}
.seku-new-nav-area .navbar-area .main-nav nav .navbar-nav .nav-item a.active {
  color: #d80650;
}
.seku-new-nav-area .navbar-area .main-nav nav .navbar-nav .nav-item .dropdown-menu {
  background-color: #ffffff;
  border: none;
  border-radius: 0;
  top: 100%;
  left: 0;
  padding: 0;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s;
  transform: scaleX(0);
}
.seku-new-nav-area .navbar-area .main-nav nav .navbar-nav .nav-item .dropdown-menu li a {
  font-size: 14px;
  color: #0e0129;
  position: relative;
  padding: 10px 15px;
  border-bottom: 1px dashed #eeeeee;
  margin-left: 0;
  margin-right: 0;
  position: relative;
}
.seku-new-nav-area .navbar-area .main-nav nav .navbar-nav .nav-item .dropdown-menu li a::before {
  content: "";
  position: absolute;
  bottom: -1px;
  right: 0;
  width: 0;
  height: 1px;
  background-color: #d80650;
  transition: all 0.5s;
}
.seku-new-nav-area .navbar-area .main-nav nav .navbar-nav .nav-item .dropdown-menu li a i {
  float: right;
  top: 12px;
  transition: all 0.5s;
  font-size: 20px;
}
.seku-new-nav-area .navbar-area .main-nav nav .navbar-nav .nav-item .dropdown-menu li a:hover {
  color: #d80650;
  background-color: #f3f3f3;
}
.seku-new-nav-area .navbar-area .main-nav nav .navbar-nav .nav-item .dropdown-menu li a:hover::before {
  width: 100%;
  left: 0;
  right: auto;
}
.seku-new-nav-area .navbar-area .main-nav nav .navbar-nav .nav-item .dropdown-menu li a.active {
  color: #d80650;
  background-color: #f3f3f3;
}
.seku-new-nav-area .navbar-area .main-nav nav .navbar-nav .nav-item .dropdown-menu li .dropdown-menu {
  left: 100%;
  transform: scaleX(0);
  top: 0;
}
.seku-new-nav-area .navbar-area .main-nav nav .navbar-nav .nav-item .dropdown-menu li:last-child a {
  border-bottom: none;
}
.seku-new-nav-area .navbar-area .main-nav nav .navbar-nav .nav-item .dropdown-menu li:hover .dropdown-menu {
  top: 0px !important;
  transform: scaleX(1);
}
.seku-new-nav-area .navbar-area .main-nav nav .navbar-nav .nav-item:hover ul {
  opacity: 1;
  visibility: visible;
  top: 100%;
  transform: scaleX(1);
}
.seku-new-nav-area .navbar-area.is-sticky {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 999;
  background-color: #ffffff;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  transition: all 0.5s;
  width: 100% !important;
}
.seku-new-nav-area .others-option {
  margin-left: 30px;
}
.seku-new-nav-area .others-option .option-item {
  color: #212121;
  display: inline-block;
  margin-right: 20px;
  position: relative;
}
.seku-new-nav-area .others-option .option-item:last-child {
  margin-right: 0;
}
.seku-new-nav-area .others-option .option-item .search-overlay {
  display: none;
}
.seku-new-nav-area .others-option .option-item .search-overlay.search-popup {
  position: absolute;
  top: 100%;
  width: 500px;
  right: 0;
  background: #ffffff;
  z-index: 2;
  padding: 20px;
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  margin-top: 20px;
}
.seku-new-nav-area .others-option .option-item .search-overlay.search-popup .search-form {
  position: relative;
}
.seku-new-nav-area .others-option .option-item .search-overlay.search-popup .search-form .search-input {
  display: block;
  width: 100%;
  height: 50px;
  line-height: initial;
  border: 1px solid #eeeeee;
  color: #0e0129;
  outline: 0;
  transition: all 0.5s;
  font-size: 15px;
  padding-top: 4px;
  padding-left: 15px;
}
.seku-new-nav-area .others-option .option-item .search-overlay.search-popup .search-form .search-input:focus {
  border-color: #d80650;
}
.seku-new-nav-area .others-option .option-item .search-overlay.search-popup .search-form .search-button {
  position: absolute;
  right: 0;
  top: 0;
  height: 50px;
  background: #d80650;
  border: none;
  width: 50px;
  outline: 0;
  color: #ffffff;
  transition: all 0.5s;
  padding: 0;
}
.seku-new-nav-area .others-option .option-item .search-overlay.search-popup .search-form .search-button:hover {
  background-color: #0e0129;
}
.seku-new-nav-area .others-option .option-item .search-btn {
  cursor: pointer;
  transition: all 0.5s;
  color: #0e0129;
  font-size: 25px;
  position: relative;
  top: 2.8px;
}
.seku-new-nav-area .others-option .option-item .search-btn:hover {
  color: #d80650;
}
.seku-new-nav-area .others-option .option-item .close-btn {
  cursor: pointer;
  display: none;
  transition: all 0.5s;
  color: #0e0129;
  font-size: 25px;
  text-align: center;
}
.seku-new-nav-area .others-option .option-item .close-btn.active {
  display: block;
  color: #d80650;
}
.seku-new-nav-area .others-option .option-item .default-btn {
  display: inline-flex;
  padding: 15px 30px;
  border-radius: 5px;
  background-image: linear-gradient(136.1deg, #D80650 1.84%, #FE5D68 100%);
}
.seku-new-nav-area.nav-with-black-color {
  background: #1C0531;
}
.seku-new-nav-area.nav-with-black-color .navbar-area .main-nav nav .navbar-nav .nav-item a {
  color: #ffffff;
}
.seku-new-nav-area.nav-with-black-color .navbar-area.is-sticky {
  background: #1C0531;
}
.seku-new-nav-area.nav-with-black-color .others-option .option-item .search-btn {
  color: #ffffff;
}
.seku-new-nav-area.nav-with-black-color .others-option .option-item .search-btn:hover {
  color: #d80650;
}
.seku-new-nav-area.nav-with-black-color .others-option .option-item .close-btn {
  color: #ffffff;
}
.seku-new-nav-area.nav-with-black-color .others-option .option-item .close-btn.active {
  color: #d80650;
}

/*
Seku New Top Header CSS
======================================================*/
.new-top-header-area {
  background: #241531;
  padding-top: 12px;
  padding-bottom: 12px;
}
.new-top-header-area .container-fluid {
  padding-left: 30px;
  padding-right: 30px;
}
.new-top-header-area .header-content-left li {
  display: inline-block;
  padding-right: 20px;
  color: #ffffff;
  font-size: 14px;
}
.new-top-header-area .header-content-left li:last-child {
  padding-right: 0;
}
.new-top-header-area .header-content-left li a {
  color: #ffffff;
  display: inline-block;
}
.new-top-header-area .header-content-left li i {
  color: #ffffff;
  margin-right: 5px;
  font-size: 15px;
  position: relative;
  top: 1px;
  transition: all 0.5s;
}
.new-top-header-area .header-content-left li:hover a {
  color: #d80650;
}
.new-top-header-area .header-content-left li:hover a i {
  color: #d80650;
}
.new-top-header-area .header-content-right {
  text-align: end;
  padding: 0;
  margin-bottom: 0;
}
.new-top-header-area .header-content-right li {
  display: inline-block;
  margin-right: 5px;
}
.new-top-header-area .header-content-right li:last-child {
  margin-right: 0;
}
.new-top-header-area .header-content-right li a {
  color: #ffffff;
}
.new-top-header-area .header-content-right li a i {
  font-size: 16px;
  position: relative;
  line-height: 1;
  top: 1.8px;
  transition: all 0.5s;
}
.new-top-header-area .header-content-right li:hover a {
  color: #d80650;
}
.new-top-header-area .header-content-right li:hover a i {
  color: #d80650;
}

/*
New Main Banner Area CSS
======================================================*/
.new-main-banner-area {
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.new-main-banner-slides-item {
  background: linear-gradient(136.1deg, #D80650 1.84%, #FE5D68 100%);
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.new-main-banner-slides-item .container-fluid {
  padding-left: 30px;
  padding-right: 0;
}

.new-main-banner-content {
  position: relative;
  z-index: 9;
  padding-top: 250px;
  padding-bottom: 250px;
  margin-right: -200px;
}
.new-main-banner-content span {
  font-size: 18px;
  font-weight: 600;
  color: #ffffff;
  display: inline-block;
  margin-bottom: 18px;
  font-family: "Barlow Condensed", sans-serif;
  letter-spacing: 1px;
}
.new-main-banner-content h1 {
  font-size: 80px;
  margin-bottom: 25px;
  color: #ffffff;
}
.new-main-banner-content p {
  color: #ffffff;
  margin-bottom: 0;
}
.new-main-banner-content .banner-btn {
  padding: 0;
  margin-top: 35px;
  margin-bottom: 0;
}
.new-main-banner-content .banner-btn li {
  list-style-type: none;
  display: inline-block;
  margin-right: 20px;
}
.new-main-banner-content .banner-btn li:last-child {
  margin-right: 0;
}
.new-main-banner-content .banner-btn li .default-btn {
  padding: 15px 30px;
  border-radius: 5px;
  background: #000000;
}
.new-main-banner-content .banner-btn li .default-btn::before {
  background: #d80650;
}
.new-main-banner-content .banner-btn li .default-btn::after {
  background: #d80650;
}
.new-main-banner-content .banner-btn li .default-btn.color-two {
  border: 1px solid #ffffff;
  background-color: transparent;
}
.new-main-banner-content .banner-btn li .default-btn.color-two::before {
  background: #ffffff;
}
.new-main-banner-content .banner-btn li .default-btn.color-two::after {
  background: #ffffff;
}
.new-main-banner-content .banner-btn li .default-btn.color-two:hover {
  color: #000000;
}

.new-main-banner-image {

  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  position: relative;
  z-index: 1;
}
.new-main-banner-image::before {
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: #010A32;
  opacity: 0.5;
}
.new-main-banner-image.image-two {

}

.new-main-home-slides.owl-theme .owl-nav {
  margin-top: 0;
  position: absolute;
  right: 2.5%;
  top: 45%;
  transform: translateY(-45%) translateX(-2.5%);
}
.new-main-home-slides.owl-theme .owl-nav [class*=owl-] {
  display: block;
  margin: 15px 0;
  width: 55px;
  height: 55px;
  background: #000000;
  border-radius: 0;
  border: none;
  transition: all 0.5s;
  color: #ffffff;
  font-size: 25px;
  position: relative;
  z-index: 1;
}
.new-main-home-slides.owl-theme .owl-nav [class*=owl-]::before {
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(136.1deg, #D80650 1.84%, #FE5D68 100%);
  z-index: -1;
  transition: all 0.5s;
  opacity: 0;
}
.new-main-home-slides.owl-theme .owl-nav [class*=owl-]:hover::before {
  opacity: 1;
}

.new-main-banner-shape {
  position: absolute;
  left: 100px;
  top: 0;
  z-index: 1;
  max-width: 550px;
}

.new-main-banner-area-wrap {
  position: relative;
  z-index: 1;
}
.new-main-banner-area-wrap::before {
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  width: 100%;
  height: 100px;
  background-color: #000000;
  bottom: 0;
  z-index: -1;
}

.new-main-banner-wrap-item {
  
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
  padding-top: 250px;
  padding-bottom: 250px;
  margin-left: 30px;
  margin-right: 30px;
}

.new-main-banner-wrap-content {
  max-width: 550px;
  margin-left: auto;
  margin-right: 130px;
}
.new-main-banner-wrap-content h1 {
  font-size: 80px;
  margin-bottom: 25px;
  color: #ffffff;
}
.new-main-banner-wrap-content p {
  color: #ffffff;
  margin-bottom: 0;
}
.new-main-banner-wrap-content .banner-btn {
  padding: 0;
  margin-top: 35px;
  margin-bottom: 0;
}
.new-main-banner-wrap-content .banner-btn li {
  list-style-type: none;
  display: inline-block;
  margin-right: 20px;
}
.new-main-banner-wrap-content .banner-btn li:last-child {
  margin-right: 0;
}
.new-main-banner-wrap-content .banner-btn li .default-btn {
  padding: 15px 30px;
  border-radius: 5px;
  background: linear-gradient(136.1deg, #D80650 1.84%, #FE5D68 100%);
}
.new-main-banner-wrap-content .banner-btn li .default-btn::before {
  background: #d80650;
}
.new-main-banner-wrap-content .banner-btn li .default-btn::after {
  background: #d80650;
}
.new-main-banner-wrap-content .banner-btn li .default-btn.color-two {
  border: 1px solid #ffffff;
  background: transparent;
}
.new-main-banner-wrap-content .banner-btn li .default-btn.color-two::before {
  background: #ffffff;
}
.new-main-banner-wrap-content .banner-btn li .default-btn.color-two::after {
  background: #ffffff;
}
.new-main-banner-wrap-content .banner-btn li .default-btn.color-two:hover {
  color: #000000;
}

.new-main-banner-area-with-black-color {
  padding-top: 50px;
  padding-bottom: 50px;
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.new-main-banner-area-with-black-color .container-fluid {
  padding-left: 30px;
  padding-right: 30px;
}

.new-main-banner-black-content h1 {
  font-size: 70px;
  margin-bottom: 25px;
  color: #ffffff;
}
.new-main-banner-black-content p {
  color: #ABA8AE;
  margin-bottom: 0;
}
.new-main-banner-black-content .banner-btn {
  padding: 0;
  margin-top: 35px;
  margin-bottom: 0;
}
.new-main-banner-black-content .banner-btn li {
  list-style-type: none;
  display: inline-block;
  margin-right: 20px;
}
.new-main-banner-black-content .banner-btn li:last-child {
  margin-right: 0;
}
.new-main-banner-black-content .banner-btn li .default-btn {
  padding: 15px 30px;
  border-radius: 5px;
  background: linear-gradient(136.1deg, #D80650 1.84%, #FE5D68 100%);
}
.new-main-banner-black-content .banner-btn li .default-btn::before {
  background: #d80650;
}
.new-main-banner-black-content .banner-btn li .default-btn::after {
  background: #d80650;
}
.new-main-banner-black-content .banner-btn li .default-btn.color-two {
  border: 1px solid #d80650;
  background: transparent;
}
.new-main-banner-black-content .banner-btn li .default-btn.color-two::before {
  background: #d80650;
}
.new-main-banner-black-content .banner-btn li .default-btn.color-two::after {
  background: #d80650;
}
.new-main-banner-black-content .banner-btn li .default-btn.color-two:hover {
  color: #ffffff;
}

.new-main-banner-black-image {
  text-align: center;
}

.new-main-banner-black-shape {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 45px;
  z-index: -1;
}

/*
Seku Features Area CSS
======================================================*/
.seku-features-area.with-black-color {
  background-color: #000000;
}
.seku-features-area.with-black-color .single-seku-features-card {
  background-color: #382549;
}

.single-seku-features-card {
  background-color: #000000;
  padding: 35px 30px 30px 30px;
  position: relative;
  z-index: 1;
  transition: all 0.5s;
  margin-bottom: 25px;
}
.single-seku-features-card::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 0%;
  height: 100%;
  background: linear-gradient(136.1deg, #D80650 1.84%, #FE5D68 100%);
  z-index: -1;
  transition: all 0.5s;
}
.single-seku-features-card h3 {
  margin-bottom: 30px;
  color: #ffffff;
  position: relative;
  padding-left: 50px;
  transition: all 0.5s;
}
.single-seku-features-card h3 img {
  position: absolute;
  top: -5px;
  left: 0;
  transition: all 0.5s;
}
.single-seku-features-card p {
  color: #ABA8AE;
  margin-bottom: 0;
  transition: all 0.5s;
}
.single-seku-features-card:hover {
  transform: translateY(-5px);
}
.single-seku-features-card:hover h3 {
  color: #ffffff;
}
.single-seku-features-card:hover h3 i {
  color: #ffffff;
}
.single-seku-features-card:hover p {
  color: #ffffff;
}
.single-seku-features-card:hover::after {
  left: 0;
  right: auto;
  width: 100%;
}
.single-seku-features-card.bg-382549 {
  background-color: #382549;
}

/*
Safer World Area CSS
======================================================*/
.safer-world-area {
  background-color: #FBFBFB;
}
.safer-world-area.with-black-color {
  background-color: #000000;
}
.safer-world-area.with-black-color .safer-world-content h3 {
  color: #ffffff;
}
.safer-world-area.with-black-color .safer-world-content p {
  color: #ABA8AE;
  border-bottom: 1px solid #452C5B;
}
.safer-world-area.with-black-color .safer-world-content .safer-world-inner-box h4 {
  color: #ffffff;
}
.safer-world-area.with-black-color .safer-world-content .safer-world-inner-box p {
  color: #ABA8AE;
  border-bottom: none;
}

.safer-world-content {
  padding-left: 30px;
}
.safer-world-content h3 {
  font-size: 50px;
  margin-bottom: 18px;
}
.safer-world-content p {
  margin-bottom: 0;
  border-bottom: 1px solid #ECECEC;
  color: #707070;
  padding-bottom: 25px;
}
.safer-world-content .safer-world-inner-box {
  margin-top: 25px;
}
.safer-world-content .safer-world-inner-box h4 {
  font-size: 28px;
  margin-bottom: 15px;
}
.safer-world-content .safer-world-inner-box p {
  margin-bottom: 0;
  border-bottom: none;
  padding-bottom: 0;
}
.safer-world-content .safer-world-btn {
  margin-top: 30px;
}
.safer-world-content .safer-world-btn .default-btn {
  background: linear-gradient(136.1deg, #D80650 1.84%, #FE5D68 100%);
  border-radius: 5px;
}
.safer-world-content.white-color-content h3 {
  color: #ffffff;
}
.safer-world-content.white-color-content p {
  border-bottom: 1px solid #452C5B;
  color: #ABA8AE;
}
.safer-world-content.white-color-content .safer-world-inner-box h4 {
  color: #ffffff;
}
.safer-world-content.white-color-content .safer-world-inner-box p {
  color: #ABA8AE;
  border-bottom: none;
}

/*
Complete Website Security Area CSS
======================================================*/
.complete-website-security-area {
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.complete-website-security-area .section-title p {
  color: #707070;
}

.complete-website-security-card {
  background-color: #382549;
  padding: 30px;
  position: relative;
  z-index: 1;
  transition: all 0.5s;
}
.complete-website-security-card.with-white-color {
  background: #ffffff;
  border: 1px solid #f9f9f9;
}
.complete-website-security-card.with-white-color h3 a {
  color: #000000;
}
.complete-website-security-card.with-white-color p {
  color: #707070;
}
.complete-website-security-card.with-white-color:hover h3 a {
  color: #ffffff;
}
.complete-website-security-card::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 0%;
  height: 100%;
  background: linear-gradient(136.1deg, #D80650 1.84%, #FE5D68 100%);
  z-index: -1;
  transition: all 0.5s;
}
.complete-website-security-card .icon {
  margin-bottom: 25px;
}
.complete-website-security-card .icon i {
  display: inline-block;
  height: 100px;
  width: 100px;
  line-height: 100px;
  background: linear-gradient(136.1deg, #D80650 1.84%, #FE5D68 100%);
  color: #ffffff;
  font-size: 45px;
  border-radius: 50px;
  text-align: center;
}
.complete-website-security-card h3 {
  margin-bottom: 15px;
  font-size: 28px;
}
.complete-website-security-card h3 a {
  color: #ffffff;
}
.complete-website-security-card p {
  color: #ABA8AE;
  transition: all 0.5s;
  margin-bottom: 0;
}
.complete-website-security-card .security-shape {
  position: absolute;
  right: -30px;
  top: -30px;
  transition: all 0.5s;
  opacity: 0;
}
.complete-website-security-card:hover {
  transform: translateY(-5px);
}
.complete-website-security-card:hover::after {
  left: 0;
  right: auto;
  width: 100%;
}
.complete-website-security-card:hover p {
  color: #ffffff;
}
.complete-website-security-card:hover .security-shape {
  right: 0;
  top: 0;
  opacity: 1;
}
.complete-website-security-card.mb-25 {
  margin-bottom: 25px;
}

.complete-website-security-slides.owl-theme .owl-nav {
  margin-top: 0;
}
.complete-website-security-slides.owl-theme .owl-nav [class*=owl-] {
  margin: 0 10px;
  padding: 0;
  background: #ECECEC;
  color: #d80650;
  transition: var(--transition);
  display: inline-block;
  width: 45px;
  height: 45px;
  line-height: 48px;
  text-align: center;
  font-size: 22px;
  position: absolute;
  left: -70px;
  top: 45%;
  transform: translateY(-45%);
  border-radius: 5px;
}
.complete-website-security-slides.owl-theme .owl-nav [class*=owl-]::before {
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(136.1deg, #D80650 1.84%, #FE5D68 100%);
  z-index: -1;
  transition: all 0.5s;
  opacity: 0;
  border-radius: 5px;
}
.complete-website-security-slides.owl-theme .owl-nav [class*=owl-].owl-next {
  right: -70px;
  left: auto;
}
.complete-website-security-slides.owl-theme .owl-nav [class*=owl-]:hover {
  color: #ffffff;
}
.complete-website-security-slides.owl-theme .owl-nav [class*=owl-]:hover::before {
  opacity: 1;
}

.website-security-shape {
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}

/*================================================
Funfacts Style Two Area CSS
=================================================*/
.funfacts-style-two-area {
  background: linear-gradient(136.1deg, #D80650 1.84%, #FE5D68 100%);
}

.single-funfacts-card {
  margin-bottom: 25px;
  position: relative;
  padding-left: 105px;
  transition: all 0.5s;
}
.single-funfacts-card .icon {
  left: 0;
  top: 50%;
  position: absolute;
  transform: translateY(-50%);
}
.single-funfacts-card .icon i {
  display: inline-block;
  height: 90px;
  width: 75px;
  line-height: 90px;
  background: rgba(255, 255, 255, 0.35);
  color: #ffffff;
  font-size: 40px;
  text-align: center;
  -webkit-clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
          clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
  transition: all 0.5s;
}
.single-funfacts-card h3 {
  line-height: 1;
  font-size: 55px;
  margin-bottom: 10px;
  color: #ffffff;
}
.single-funfacts-card .sign {
  position: relative;
  top: 1.5px;
  left: 5px;
}
.single-funfacts-card p {
  color: #ffffff;
  font-size: 15px;
  font-weight: 500;
}
.single-funfacts-card:hover {
  transform: translateY(-5px);
}
.single-funfacts-card:hover .icon i {
  background-color: #ffffff;
  color: #d80650;
}

/*================================================
Success Projects Area CSS
=================================================*/
.success-projects-area {
  overflow: hidden;
}
.success-projects-area .container-fluid {
  overflow: hidden;
  left: calc((100% - 1320px) / 2);
  position: relative;
}

.single-success-projects-card {
  margin-bottom: 25px;
  transition: all 0.5s;
  position: relative;
}
.single-success-projects-card .projects-image {
  position: relative;
  overflow: hidden;
}
.single-success-projects-card .projects-image .icon {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s;
}
.single-success-projects-card .projects-image .icon a i {
  display: inline-block;
  height: 65px;
  width: 65px;
  line-height: 65px;
  background: linear-gradient(136.1deg, #D80650 1.84%, #FE5D68 100%);
  color: #ffffff;
  font-size: 25px;
  transition: all 0.5s;
}
.single-success-projects-card .projects-image::before {
  position: absolute;
  content: "";
  background: linear-gradient(180deg, rgba(56, 37, 73, 0) 41.93%, #382549 100%);
  width: 100%;
  height: 100%;
  left: 0;
  bottom: 0;
  transition: all 0.5s;
}
.single-success-projects-card .projects-content {
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 30px;
}
.single-success-projects-card .projects-content h3 {
  font-size: 22px;
  margin-bottom: 0;
}
.single-success-projects-card .projects-content h3 a {
  color: #ffffff;
}
.single-success-projects-card:hover {
  transform: translateY(-10px) !important;
}
.single-success-projects-card:hover .projects-image .icon {
  top: 45%;
  transform: translateY(-45%);
  opacity: 1;
  visibility: visible;
}
.single-success-projects-card:hover .projects-content h3 a {
  color: linear-gradient(136.1deg, #D80650 1.84%, #FE5D68 100%);
}

.success-projects-section-content {
  padding-right: 25px;
}
.success-projects-section-content h3 {
  font-size: 50px;
  margin-bottom: 15px;
  line-height: 1.5;
}
.success-projects-section-content p {
  margin-bottom: 25px;
  color: #707070;
}
.success-projects-section-content .default-btn {
  background: linear-gradient(136.1deg, #D80650 1.84%, #FE5D68 100%);
  border-radius: 5px;
}

.success-projects-slides.owl-theme .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  background-color: #ffffff;
  transition: all 0.5s;
  border-radius: 50%;
  position: relative;
  margin-top: 0;
  margin-bottom: 0;
  margin: 0 10px;
  border: 1px solid #d80650;
}
.success-projects-slides.owl-theme .owl-dots .owl-dot:hover span, .success-projects-slides.owl-theme .owl-dots .owl-dot.active span {
  background: #d80650;
  transform: scale(1.5);
}
.success-projects-slides.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 20px;
  text-align: start;
}

/*================================================
Testimonials Wrap Area CSS
=================================================*/
.testimonials-wrap-area {

  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.testimonials-wrap-area::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  background: #1C0531;
  opacity: 0.75;
  left: 0;
  right: 0;
  top: 0;
  z-index: -1;
}

.single-testimonials-card {
  background: #251533;
  padding: 35px;
  position: relative;
  z-index: 1;
  transition: all 0.5s;
  overflow: hidden;
}
.single-testimonials-card::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background: linear-gradient(136.1deg, #D80650 1.84%, #FE5D68 100%);
  z-index: -1;
  transition: all 0.5s;
}
.single-testimonials-card p {
  color: #ffffff;
  margin-bottom: 0;
  font-size: 18px;
}
.single-testimonials-card .client-info {
  margin-top: 20px;
}
.single-testimonials-card .client-info h3 {
  font-size: 22px;
  margin-bottom: 0;
  color: #ffffff;
}
.single-testimonials-card .client-info span {
  font-size: 14px;
  color: #FAD6DD;
  display: inline-block;
  margin-top: 12px;
}
.single-testimonials-card .rating {
  padding: 0;
  margin-bottom: 0;
  position: absolute;
  right: 45px;
  bottom: 50px;
}
.single-testimonials-card .rating li {
  display: inline-block;
  margin-right: 2px;
}
.single-testimonials-card .rating li:last-child {
  margin-right: 0;
}
.single-testimonials-card .rating li i {
  color: #FFB800;
}
.single-testimonials-card .quote-shape {
  position: absolute;
  right: 45px;
  bottom: -20px;
}
.single-testimonials-card:hover::after {
  width: 100%;
}

.testimonials-wrap-slides.owl-theme .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  background-color: #382549;
  transition: all 0.5s;
  border-radius: 50%;
  position: relative;
  margin-top: 0;
  margin-bottom: 0;
  margin: 0 10px;
  border: 1px solid #d80650;
}
.testimonials-wrap-slides.owl-theme .owl-dots .owl-dot:hover span, .testimonials-wrap-slides.owl-theme .owl-dots .owl-dot.active span {
  background: #d80650;
  transform: scale(1.5);
}
.testimonials-wrap-slides.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 30px;
  text-align: center;
}

/*================================================
Home Control Area CSS
=================================================*/
.home-control-content h3 {
  font-size: 50px;
  margin-bottom: 18px;
}
.home-control-content p {
  margin-bottom: 0;
  color: #707070;
}
.home-control-content .home-control-tab-wrap {
  margin-top: 30px;
}
.home-control-content .home-control-tab-wrap .home-control-tab .tabs {
  margin-bottom: 30px;
}
.home-control-content .home-control-tab-wrap .home-control-tab .tabs li {
  display: inline-block;
  background-color: #F8F8F8;
  padding: 10px 25px;
  font-weight: 500;
  position: relative;
  color: #848484;
  margin-right: -4px;
}
.home-control-content .home-control-tab-wrap .home-control-tab .tabs li::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 2px;
  height: 0;
  background-color: #d80650;
  transition: all 0.5s;
}
.home-control-content .home-control-tab-wrap .home-control-tab .tabs li a {
  color: #848484;
}
.home-control-content .home-control-tab-wrap .home-control-tab .tabs .current a {
  color: #d80650;
}
.home-control-content .home-control-tab-wrap .home-control-tab .tabs .current::before {
  height: 100%;
  right: auto;
  left: 0;
}
.home-control-content .home-control-tab-wrap .home-control-tab p {
  color: #ffffff;
}
.home-control-content .home-control-tab-wrap .home-control-tab .default-btn {
  width: unset !important;
  margin-top: 15px;
}
.home-control-content .home-control-tab-wrap .tab_content .tabs_item p {
  margin-bottom: 0;
  color: #707070;
}
.home-control-content .home-control-tab-wrap .tab_content .tabs_item .list {
  padding: 0;
  margin-top: 25px;
  margin-bottom: 25px;
}
.home-control-content .home-control-tab-wrap .tab_content .tabs_item .list li {
  list-style-type: none;
  margin-bottom: 25px;
  color: #382549;
  position: relative;
  padding-left: 35px;
}
.home-control-content .home-control-tab-wrap .tab_content .tabs_item .list li:last-child {
  margin-bottom: 0;
}
.home-control-content .home-control-tab-wrap .tab_content .tabs_item .list li i {
  position: absolute;
  left: 0;
  top: 0;
  display: inline-block;
  height: 25px;
  width: 25px;
  line-height: 25px;
  text-align: center;
  border-radius: 5px;
  background: linear-gradient(136.1deg, #D80650 1.84%, #FE5D68 100%);
  color: #ffffff;
  font-size: 20px;
  border-radius: 50px;
}
.home-control-content .home-control-tab-wrap .tab_content .tabs_item .default-btn {
  background: linear-gradient(136.1deg, #D80650 1.84%, #FE5D68 100%);
  border-radius: 5px;
}
.home-control-content .tab .tabs_item {
  display: none;
}
.home-control-content .tab .tabs_item:first-child {
  display: block;
}
.home-control-content.white-color-content h3 {
  color: #ffffff;
}
.home-control-content.white-color-content p {
  color: #ABA8AE;
}
.home-control-content.white-color-content .home-control-tab-wrap .home-control-tab .tabs li {
  background-color: #241036;
  color: #B0A8A8;
}
.home-control-content.white-color-content .home-control-tab-wrap .tab_content .tabs_item p {
  color: #ABA8AE;
}
.home-control-content.white-color-content .home-control-tab-wrap .tab_content .tabs_item .list li {
  color: #E8E8E8;
}
.home-control-content.white-color-content .home-control-tab-wrap .tab_content .tabs_item .list li i {
  background: linear-gradient(129.37deg, #603364 8.09%, #7F3668 100%);
}

.home-control-image {
  padding-left: 35px;
}
.home-control-image img {
  border-radius: 30px;
}

/*================================================
Plans Area CSS
=================================================*/
.plans-area {
  background-color: #FBFBFB;
}
.plans-area .section-title p {
  color: #707070;
}

.single-plans-card {
  margin-bottom: 25px;
  border-radius: 0;
  position: relative;
  overflow: hidden;
  padding: 30px;
  transition: all 0.5s;
  border: 1px solid #d80650;
  position: relative;
  z-index: 1;
}
.single-plans-card::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background: linear-gradient(136.1deg, #D80650 1.84%, #FE5D68 100%);
  z-index: -1;
  transition: all 0.5s;
}
.single-plans-card .pricing-header h3 {
  margin-bottom: 10px;
  font-size: 25px;
  transition: all 0.5s;
}
.single-plans-card .pricing-header p {
  color: #848484;
  font-size: 14px;
  transition: all 0.5s;
}
.single-plans-card .price {
  margin-top: 20px;
}
.single-plans-card .price h4 {
  margin-bottom: 0;
  font-size: 45px;
  line-height: 1;
  transition: all 0.5s;
}
.single-plans-card .price h4 span {
  color: #848484;
  font-size: 14px;
  font-weight: normal;
  position: relative;
  left: 8px;
  top: -5px;
  transition: all 0.5s;
}
.single-plans-card .features-list {
  margin-top: 30px;
}
.single-plans-card .features-list h5 {
  font-size: 15px;
  letter-spacing: 1.5px;
  margin-bottom: 25px;
  transition: all 0.5s;
}
.single-plans-card .features-list ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}
.single-plans-card .features-list ul li {
  color: #848484;
  margin-bottom: 15px;
  padding-left: 20px;
  position: relative;
  transition: all 0.5s;
}
.single-plans-card .features-list ul li i {
  position: absolute;
  color: #d80650;
  font-size: 15px;
  top: 3.5px;
  left: 0;
  transition: all 0.5s;
}
.single-plans-card .features-list ul li:last-child {
  margin-bottom: 0;
}
.single-plans-card .features-list ul li.bg-C4C4C4 {
  color: #C4C4C4;
}
.single-plans-card .features-list ul li.bg-C4C4C4 i {
  color: #C4C4C4;
}
.single-plans-card .link-btn {
  width: 100%;
  display: block;
  margin-top: 25px;
  border-radius: 5px;
  padding: 15px 30px;
  text-align: center;
  color: #ffffff;
  background: linear-gradient(136.1deg, #D80650 1.84%, #FE5D68 100%);
  box-shadow: 0 7px 18px 0 rgba(107, 124, 147, 0.25);
  font-size: 15px;
  font-weight: 500;
}
.single-plans-card .link-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 30px 50px rgba(107, 124, 147, 0.15);
}
.single-plans-card .plans-shape {
  position: absolute;
  right: -50px;
  top: -50px;
  transition: all 0.5s;
}
.single-plans-card:hover {
  border: 1px solid #d80650;
  transform: translateY(-5px);
}
.single-plans-card:hover::after {
  width: 100%;
}
.single-plans-card:hover .pricing-header h3 {
  color: #ffffff;
}
.single-plans-card:hover .pricing-header p {
  color: #ffffff;
}
.single-plans-card:hover .price h4 {
  color: #ffffff;
}
.single-plans-card:hover .price h4 span {
  color: #ffffff;
}
.single-plans-card:hover .features-list h5 {
  color: #ffffff;
}
.single-plans-card:hover .features-list ul li {
  color: #ffffff;
}
.single-plans-card:hover .features-list ul li i {
  color: #ffffff;
}
.single-plans-card:hover .plans-shape {
  right: 0;
  top: 0;
  opacity: 1;
}

/*================================================
Operation Center Area CSS
=================================================*/
.operation-center-image {
  border-radius: 0px 30px 30px 30px;
  overflow: hidden;
}
.operation-center-image img {
  border-radius: 0px 30px 30px 30px;
  transition: all 0.5s;
}
.operation-center-image:hover img {
  transform: scale(1.2);
}

.operation-center-content {
  padding-left: 25px;
}
.operation-center-content h3 {
  font-size: 50px;
  margin-bottom: 18px;
}
.operation-center-content p {
  color: #707070;
  margin-bottom: 0;
}
.operation-center-content .operation-list {
  padding: 0;
  margin-top: 25px;
  margin-bottom: 0;
}
.operation-center-content .operation-list li {
  list-style-type: none;
  margin-bottom: 15px;
}
.operation-center-content .operation-list li:last-child {
  margin-bottom: 0;
}
.operation-center-content .operation-list li span {
  color: #848484;
  font-weight: 500;
  display: inline-block;
  width: 100%;
  border: 1px solid #FFE4EE;
  position: relative;
  padding: 20px 20px 20px 65px;
  border-radius: 5px;
}
.operation-center-content .operation-list li span i {
  left: 20px;
  top: 50%;
  width: 30px;
  height: 30px;
  font-size: 15px;
  line-height: 30px;
  border-radius: 50%;
  text-align: center;
  position: absolute;
  transform: translateY(-50%);
  color: #ffffff;
  background: linear-gradient(136.1deg, #D80650 1.84%, #FE5D68 100%);
  transition: all 0.5s;
}
.operation-center-content.white-color-content h3 {
  color: #ffffff;
}
.operation-center-content.white-color-content p {
  color: #ABA8AE;
}
.operation-center-content.white-color-content .operation-list li span {
  border: 1px solid #382549;
  color: #ffffff;
}
.operation-center-content.white-color-content .operation-list li span i {
  background: linear-gradient(129.37deg, #603364 8.09%, #7F3668 100%);
}

/*================================================
Expert Team Area CSS
=================================================*/
.expert-team-area {
  background-color: #FBFBFB;
}

.expert-team-content.with-left {
  padding-left: 30px;
}
.expert-team-content h3 {
  font-size: 50px;
  margin-bottom: 15px;
}
.expert-team-content p {
  color: #707070;
  margin-bottom: 20px;
}
.expert-team-content .default-btn {
  background-color: linear-gradient(136.1deg, #D80650 1.84%, #FE5D68 100%);
  border-radius: 5px;
}

/*================================================
Latest News Area CSS
=================================================*/
.latest-news-area {
  overflow: hidden;
}
.latest-news-area .section-title p {
  color: #707070;
}
.latest-news-area .container-fluid {
  overflow: hidden;
  left: calc((100% - 1320px) / 2);
  position: relative;
}
.latest-news-area .container-fluid .latest-news-slides.owl-theme .owl-nav {
  margin-top: 0;
}
.latest-news-area .container-fluid .latest-news-slides.owl-theme .owl-nav [class*=owl-] {
  left: -20px;
  top: 40%;
  transform: translateY(-40%);
  background-color: transparent;
}
.latest-news-area .container-fluid .latest-news-slides.owl-theme .owl-nav [class*=owl-].owl-next {
  right: 22%;
  transform: translateX(-22%);
  left: auto;
}

.latest-news-card {
  margin-bottom: 25px;
  transition: all 0.5s;
}
.latest-news-card .news-image a img {
  display: inline-block;
}
.latest-news-card .news-content {
  margin-top: 25px;
}
.latest-news-card .news-content h3 {
  margin-bottom: 15px;
  font-size: 24px;
}
.latest-news-card .news-content h3 a {
  color: #000000;
}
.latest-news-card .news-content p {
  color: #707070;
}
.latest-news-card:hover {
  transform: translateY(-5px);
}
.latest-news-card.white-color-content {
  background-color: transparent;
  border: 1px solid #241036;
  padding: 30px;
  border-radius: 5px;
}
.latest-news-card.white-color-content .news-content h3 a {
  color: #ffffff;
}
.latest-news-card.white-color-content .news-content p {
  color: #88808E;
}

.latest-news-slides.owl-theme .owl-nav {
  margin-top: 0;
}
.latest-news-slides.owl-theme .owl-nav [class*=owl-] {
  margin: 0 10px;
  padding: 0;
  background: #ffffff;
  color: #d80650;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1);
  transition: var(--transition);
  display: inline-block;
  width: 45px;
  height: 45px;
  line-height: 48px;
  text-align: center;
  font-size: 22px;
  position: absolute;
  left: -70px;
  top: 45%;
  transform: translateY(-45%);
  border-radius: 50px;
  border: 1px solid #d80650;
}
.latest-news-slides.owl-theme .owl-nav [class*=owl-]::before {
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(136.1deg, #D80650 1.84%, #FE5D68 100%);
  z-index: -1;
  transition: all 0.5s;
  opacity: 0;
  border-radius: 50px;
}
.latest-news-slides.owl-theme .owl-nav [class*=owl-].owl-next {
  right: -70px;
  left: auto;
}
.latest-news-slides.owl-theme .owl-nav [class*=owl-]:hover {
  color: #ffffff;
}
.latest-news-slides.owl-theme .owl-nav [class*=owl-]:hover::before {
  opacity: 1;
}

/*================================================
Footer Style Two Area CSS
=================================================*/
.footer-style-two-with-color {
  background-color: #251533;
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.single-footer-widget-card {
  margin-bottom: 25px;
}
.single-footer-widget-card .logo {
  margin-bottom: 25px;
}
.single-footer-widget-card p {
  color: #ABA8AE;
  margin-bottom: 20px;
}
.single-footer-widget-card .social-links {
  padding: 0;
  list-style-type: none;
  margin-top: 0;
  margin-bottom: 0;
}
.single-footer-widget-card .social-links li {
  display: inline-block;
  margin-right: 5px;
}
.single-footer-widget-card .social-links li a {
  width: 30px;
  height: 30px;
  display: block;
  border-radius: 50%;
  text-align: center;
  position: relative;
  color: #ffffff;
  background: linear-gradient(136.1deg, #D80650 1.84%, #FE5D68 100%);
}
.single-footer-widget-card .social-links li a i {
  left: 0;
  right: 0;
  top: 50%;
  position: absolute;
  transform: translateY(-50%);
}
.single-footer-widget-card .social-links li a:hover {
  transform: translateY(-5px);
}
.single-footer-widget-card h3 {
  color: #ffffff;
  margin-bottom: 25px;
}
.single-footer-widget-card .custom-links {
  padding: 0;
  margin-bottom: 0;
}
.single-footer-widget-card .custom-links li {
  list-style-type: none;
  margin-bottom: 10px;
}
.single-footer-widget-card .custom-links li:last-child {
  margin-bottom: 0;
}
.single-footer-widget-card .custom-links li a {
  color: #ABA8AE;
}
.single-footer-widget-card .custom-links li a:hover {
  color: #d80650;
  letter-spacing: 1px;
}
.single-footer-widget-card .footer-contact-info {
  padding: 0;
  margin-bottom: 0;
}
.single-footer-widget-card .footer-contact-info li {
  color: #ABA8AE;
  margin-bottom: 15px;
}
.single-footer-widget-card .footer-contact-info li:last-child {
  margin-bottom: 0;
}
.single-footer-widget-card .footer-contact-info li span {
  color: #ffffff;
}
.single-footer-widget-card .footer-contact-info li a {
  color: #ABA8AE;
}
.single-footer-widget-card .footer-contact-info li a:hover {
  color: #d80650;
}
.single-footer-widget-card .widget-newsletter-content {
  margin-bottom: 20px;
}
.single-footer-widget-card .widget-newsletter-content p {
  color: #ABA8AE;
  margin-bottom: 0;
}
.single-footer-widget-card .newsletter-form {
  margin-top: 20px;
}
.single-footer-widget-card .newsletter-form .input-newsletter {
  display: block;
  width: 100%;
  background-color: #392C45;
  border: 1px solid #392C45;
  height: 50px;
  padding: 20px;
  border-radius: 5px;
  outline: 0;
  color: #86818A;
}
.single-footer-widget-card .newsletter-form .input-newsletter::-moz-placeholder {
  color: #86818A;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
}
.single-footer-widget-card .newsletter-form .input-newsletter::placeholder {
  color: #86818A;
  transition: all 0.5s;
}
.single-footer-widget-card .newsletter-form .input-newsletter:focus::-moz-placeholder {
  color: transparent;
}
.single-footer-widget-card .newsletter-form .input-newsletter:focus::placeholder {
  color: transparent;
}
.single-footer-widget-card .newsletter-form .default-btn {
  display: inline-block;
  border: none;
  margin-top: 10px;
  width: 100%;
  background: linear-gradient(136.1deg, #D80650 1.84%, #FE5D68 100%);
  border-radius: 5px;
}
.single-footer-widget-card .newsletter-form #validator-newsletter {
  color: red;
  margin-top: 5px;
  font-weight: 400;
  font-weight: 400;
}

.footer-shape-1 {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}

.footer-shape-2 {
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}

.copyright-style-two-with-color {
  background-color: #1C0531;
  text-align: center;
  padding-top: 35px;
  padding-bottom: 35px;
  margin-top: 75px;
}
.copyright-style-two-with-color p {
  color: #ABA8AE;
}
.copyright-style-two-with-color p b {
  color: #d80650;
}
.copyright-style-two-with-color p a {
  color: #d80650;
  font-weight: 500;
}

.lines-line {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  margin: auto;
  width: 90vw;
  z-index: -1;
}
.lines-line .line {
  position: absolute;
  width: 1px;
  height: 100%;
  top: 0;
  left: 50%;
  background: #382647;
  overflow: hidden;
}
.lines-line .line::after {
  content: "";
  display: block;
  position: absolute;
  height: 15vh;
  width: 100%;
  top: -50%;
  left: 0;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #ffffff 75%, #ffffff 100%);
  animation: run 15s 0s infinite;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
  border-radius: 50%;
}
.lines-line .line:nth-child(1) {
  margin-left: -18%;
}
.lines-line .line:nth-child(2) {
  margin-left: 115px;
}
.lines-line .line:nth-child(3) {
  margin-left: 30%;
}

@keyframes run {
  0% {
    top: -50%;
  }
  100% {
    top: 110%;
  }
}
/*================================================
Partner Style Two Area CSS
=================================================*/
.partner-slides .partner-item-card {
  text-align: center;
  overflow: hidden;
}
.partner-slides .partner-item-card img {
  transition: all 0.5s;
}
.partner-slides .partner-item-card:hover img {
  transform: scale(0.9);
}

/*================================================
Overview Area CSS
=================================================*/
.overview-area {
  overflow: hidden;
}
.overview-area .container-fluid {
  padding-left: 0;
  padding-right: 0;
}
.overview-area .container-fluid .row {
  padding-left: 0;
  padding-right: 0;
}
.overview-area .container-fluid .row .col-lg-6 {
  padding-left: 0;
  padding-right: 0;
}

.overview-content-box {
  position: relative;
}
.overview-content-box img {
  width: 100%;
}
.overview-content-box .content {
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 45px;
  max-width: 555px;
}
.overview-content-box .content h3 {
  font-size: 50px;
  margin-bottom: 30px;
  color: #ffffff;
}
.overview-content-box .content .default-btn {
  background: linear-gradient(136.1deg, #D80650 1.84%, #FE5D68 100%);
  border-radius: 5px;
}
.overview-content-box .content .default-btn::before {
  background: #d80650;
}
.overview-content-box .content .default-btn::after {
  background: #d80650;
}

/*================================================
Cyber Defenses Area CSS
=================================================*/
.cyber-defenses-area {
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.cyber-defenses-list-tabs .nav {
  padding: 0;
  list-style-type: none;
  display: block;
  border: none;
  margin-bottom: 50px;
  text-align: center;
}
.cyber-defenses-list-tabs .nav .nav-item {
  display: inline-block;
  margin-right: 20px;
}
.cyber-defenses-list-tabs .nav .nav-item:last-child {
  margin-right: 0;
}
.cyber-defenses-list-tabs .nav .nav-item .nav-link {
  display: inline-block;
  background: transparent;
  border: 1px solid #382549;
  padding: 15px 30px;
  border-radius: 5px;
  position: relative;
  z-index: 1;
  color: #ffffff;
  font-weight: 500;
  font-size: 15px;
}
.cyber-defenses-list-tabs .nav .nav-item .nav-link::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 0;
  background: linear-gradient(129.37deg, #603364 8.09%, #7F3668 100%);
  transition: all 0.5s;
  border-radius: 5px;
  z-index: -1;
}
.cyber-defenses-list-tabs .nav .nav-item .nav-link:hover, .cyber-defenses-list-tabs .nav .nav-item .nav-link.active {
  color: #ffffff;
}
.cyber-defenses-list-tabs .nav .nav-item .nav-link:hover::before, .cyber-defenses-list-tabs .nav .nav-item .nav-link.active::before {
  height: 100%;
}

.cyber-defenses-content {
  max-width: 550px;
}
.cyber-defenses-content h3 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #ffffff;
}
.cyber-defenses-content p {
  margin-bottom: 0;
  color: #ABA8AE;
}
.cyber-defenses-content .cyber-list {
  padding: 0;
  margin-top: 25px;
  margin-bottom: 25px;
}
.cyber-defenses-content .cyber-list li {
  color: #E8E8E8;
  font-weight: 400;
  list-style-type: none;
  margin-bottom: 25px;
  position: relative;
  padding-left: 32px;
  font-size: 15px;
}
.cyber-defenses-content .cyber-list li:last-child {
  margin-bottom: 0;
}
.cyber-defenses-content .cyber-list li i {
  display: inline-block;
  height: 22px;
  width: 22px;
  line-height: 22px;
  background: linear-gradient(129.37deg, #603364 8.09%, #7F3668 100%);
  color: #ffffff;
  text-align: center;
  border-radius: 50px;
  font-size: 12px;
  position: absolute;
  top: 0;
  left: 0;
}
.cyber-defenses-content .cyber-btn {
  margin-top: 30px;
}
.cyber-defenses-content .cyber-btn .default-btn {
  background: linear-gradient(136.1deg, #D80650 1.84%, #FE5D68 100%);
  border-radius: 5px;
}

.cyber-defenses-image {
  text-align: end;
}

.cyber-defenses-shape {
  position: absolute;
  right: 0;
  top: 10%;
  transform: translateY(-10%);
  z-index: -1;
}/*# sourceMappingURL=style.css.map */

@media (max-width:991px) {
  .banner-area .banner-text h1 {
    font-size: 40px;
    color: #ffffff;
    margin-bottom: 25px;
    line-height: 1.1;
  }
  .banner-shape-2 , .banner-shape-3{
    display: none !important;
  }
}

.banner-shape-2 , .banner-shape-3{
  display: block ;
}