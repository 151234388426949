.outerdiv {
	width: 100%;
	min-height: 100vh;
	/* background: #EDF2F8; */
	display: flex;
	align-items: center;
	justify-content: center;
}

.innerdiv {
	transform: scale(0.9);
	margin: 1rem;
	display: grid;
	grid-gap: 1.5rem;

}

.eachdiv {
	padding: 1rem 2rem;
	border-radius: 0.8rem;
	box-shadow: 5px 5px 20px #6d6b6b6b;
	color: white;
}

.div1 {
	background: #733FC8;
	grid-column: 1/3;
	grid-row: 1/2;
	background-image: url(https://raw.githubusercontent.com/RahulSahOfficial/testimonials_grid_section/5532c958b7d3c9b910a216b198fdd21c73112d84/images/bg-pattern-quotation.svg);
	background-repeat: no-repeat;
	background-position-x: 25rem;
}

.div2 {
	background: #49556B;
	grid-column: 3/4;
	grid-row: 1/2;
}

.div3 {
	background: white;
	grid-column: 4/5;
	color: black;
}

.div4 {
	background: white;
	grid-column: 1/2;
	grid-row: 2/3;
	color: black;
}

.div5 {
	background: #18202D;
	grid-column: 2/4;
	grid-row: 2/3;
}

.userdetails {
	display: flex;
}

.imgbox {
	margin-right: 1rem;
}

.imgbox img {
	border-radius: 50%;
	/* width: 3.5rem;
	height: 3.5rem; */
	border: 2px solid #cec5c5;
}

.detbox {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.detbox p {
	margin: 0;
}

.detbox .name {
	color: hsl(0, 0%, 81%);
	font-size: 0.9rem;
	margin-bottom: 0.1rem;
	font-weight: 600;
}

.detbox .name.dark {
	color: #49505A;
}

.detbox .designation {
	color: hsl(0, 0%, 81%);
	opacity: 50%;
	font-size: 0.8rem;
}

.detbox .designation.dark {
	color: #49505A;
}

.review h4 {
	font-size: 1.4rem;
	color: #F3DEFF;
	font-weight: 600;
	line-height: 1.5;
	margin-bottom: 0.8rem;
}

.review.dark h4 {
	color: #4B5258;
}

.review p {
	font-size: 0.95rem;
	color: #F3DEFF;
	font-weight: 500;
	opacity: 50%;
	line-height: 1.5;
}

.review.dark p {
	color: #0e0e0e;
}

.attribution {
	font-size: 1rem;
	line-height: 1.5;
	position: fixed;
	bottom: 1rem;
	right: 1rem;
	text-align: right;
}

.attribution a {
	text-decoration: none;
}

@media only screen and (max-width: 1000px) {
	.innerdiv {
		transform: scale(0.7);
	}
}

@media only screen and (max-width: 800px) {
	.innerdiv {
		transform: scale(0.6);
	}
}

@media only screen and (max-width: 600px) {
	.div1 {
		background-position-x: 10rem;
	}

	.innerdiv {
		display: flex;
		flex-direction: column;
		transform: scale(1);
		margin: 2rem;
		margin-bottom: 5rem;
	}

	.attribution {
		position: relative;
	}

	.outerdiv {
		width: 100%;
		min-height: 100vh;
		/* background: #EDF2F8; */
		display: block;
		align-items: center;
		justify-content: center;
	}
}
.overlay h2 {
    color: #fff;
    font-family: var(--);
    font-family: proxima-nova, sans-serif;
    font-family: var(--body-font-family);
    font-size: 1.2em;
    margin-bottom: 10px;
    text-align: center;
	z-index: 9999;
}
.overlay {
    align-items: center;
    bottom: 13%;
    display: flex;
    flex-direction: column;
    justify-content: center;
	z-index: 9999;
    left: 7%;
	
    position: absolute;
    right: 0;
    /* top: 0%; */
}
.overlay h2 {
	
	font-size: 30px;
	
} 
.play-button {
    align-items: center;
    border: 2px solid #fff;
    border-radius: 50%;
    display: flex;
    height: 50px;
    justify-content: center;
    width: 50px;
}
.play-button svg {
    fill: #fff;
    height: 30px;
    width: 30px;
}
.video-card {
    border-radius: 8px;
    box-shadow: 0 4px 8px #0003;
    cursor: pointer;
    height: 300px;
    margin: 15px;
    overflow: hidden;
    position: relative;
    transition: transform .3s ease-in-out;
    width: 500px;
}

@media (max-width:991px) {
	.overlay {
		align-items: center;
		bottom: 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		left: 50%;
		position: absolute;
		right: 0;
		z-index: 9999;
		top: 50%;
	}
	.play-button {
        align-items: center;
        border: 2px solid #fff;
        border-radius: 50%;
        display: flex;
        height: 77px;
        justify-content: center;
        width: 38px;
    }
	.video-card {
		border-radius: 8px;
		box-shadow: 0 4px 8px #0003;
		cursor: pointer;
		height: auto;
	
		overflow: hidden;
		position: relative;
		transition: transform .3s ease-in-out;
		width: 80%;
		margin: auto
	}
}