:root {
  --body-font-family: 'Proxima Nova', sans-serif;
  ---background-color: #7F3E98;
  --sliderpaddingTop: 83px;
  --sliderpaddingBottom: 83px;
  --sliderheadingTitle: 54px;
  --sliderdescTitle: 20px;
  --slidertileHeading: 24px;
  --slidertileDesc: 15px
}

.heros {
  width: 100%;
  min-height: 60vh;
  position: relative;
  font-family: var(--body-font-family);
  padding: 80px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.heros img {
  position: absolute;

  display: block;
  width: 100%;
  height: 100%;

  z-index: 1;
}

.heros:before {
  content: "";
  background: color-mix(in srgb, var(--background-color), transparent 30%);
  position: absolute;

  z-index: 2;
}

.heros .container {
  position: relative;
  z-index: 3;
}

.heros h2 {
  margin: 0;
  font-family: var(--body-font-family);
  font-size: 40px;
  font-weight: 700;
  color: #fff;
}

.heros h4 {
  margin: 0;
  font-family: var(--body-font-family);
  font-size: 1.5rem;
  font-weight: 300;
  color: #fff;
}


.heros1 {
  align-items: center;
  display: flex;
  font-family: Proxima Nova, sans-serif;
  font-family: var(--body-font-family);
  justify-content: center;
  min-height: 60vh;
  padding: 80px 0;
  position: relative;
  width: 100%;
}


.heros1 img {
  display: block;
  height: 100%;
  object-fit: cover;
  position: absolute;
  width: 100%;
  z-index: 1;
}

.heros1:before {
  content: "";
  background: color-mix(in srgb, var(--background-color), transparent 30%);
  position: absolute;

  z-index: 2;
}

.heros1 .container {
  position: relative;
  z-index: 3;
}

.heros1 h2 {
  margin: 0;
  font-family: var(--body-font-family);
  font-size: 3rem;
  font-weight: 700;
  color: #fff;
}

.heros1 h4 {
  margin: 0;
  font-family: var(--body-font-family);
  font-size: 1.5rem;
  font-weight: 300;
  color: #fff;
}

.background {
  background-color: #7F3E98;
  padding: 20px;
}



@media (max-width: 768px) {
  .heros h2 {
    font-size: 40px;
    font-family: var(--body-font-family);
    color: #fff;
  }

  .cmp-responsive-image-banner .cmp-responsive-image-banner__section .cmp-responsive-image-banner__text-overlay {

    bottom: 100px !important;

    font-size: 30px !important;
  }

  .background span {
    font-size: 13px;
    font-family: var(--body-font-family);
    color: #fff;
    margin-left: 25px;
  }
}

.bg2 {
  width: 100%;
  min-height: 50vh;
  position: relative;
  padding: 80px 0;
  display: flex;
  align-items: center;
  font-family: var(--body-font-family);
  justify-content: center;
}


.newbg2 {
  width: 100%;
  min-height: 90vh;
  height: 100%;

  padding: 80px 0;
  position: relative;
  display: flex;
  align-items: start;
  justify-content: center;
}

.newbg2 img {
  position: absolute;
  inset: 0;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
}

.newbg2:before {
  content: "";
  background: color-mix(in srgb, var(--background-color), transparent 30%);
  position: absolute;

  z-index: 2;
}

.newbg2 .container {
  position: relative;
  z-index: 3;
}

.newbg2 h2 {
  margin: 0;
  font-family: var(--body-font-family);
  font-size: 5rem;
  font-weight: 700;
}

.newbg2 p {
  margin: 0;
  font-family: var(--body-font-family);
  font-size: 20px;
  color: #222222;
}

.btn2 {

  width: 400px;
  margin: auto;
  padding: 15px;
  border-radius: 25px;
  background-color: #7F3E98;
  color: #fff;
  border: none;

}

.bg2 img {
  position: absolute;
  inset: 0;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
}

.bg2:before {
  content: "";
  background: color-mix(in srgb, var(--background-color), transparent 30%);
  position: absolute;

  z-index: 2;
}

.bg2 .container {
  position: relative;
  z-index: 3;
}

.bg2 h2 {
  margin: 0;
  font-family: var(--body-font-family);
  font-size: 2.5rem;
  font-weight: 700;
}

.bg2 p {
  margin: auto;
  font-family: var(--body-font-family);
  font-size: 20px;
  text-align: center;
}

.services-section {
  text-align: center;
  padding: 40px 20px;
  background-color: #E1E0E0;

}

.desc {
  font-size: 10px;
  color: #222222;
  font-family: var(--body-font-family);
}

.services-section h2 {
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 30px;
  color: #222222;
  font-family: var(--body-font-family);
}

.service {
  display: flex;
  justify-content: center;
  align-items: center;

}

.services {
  display: ruby;
  margin: auto;
}

.services-grid {

  flex-wrap: wrap;
  margin: auto;
  display: flex;
  justify-content: center;


}

.service-card {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  width: 300px;
  /* height: 130px; */
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.service-cards{
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  width: 300px;
  height: auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.services-card {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  width: 220px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.service-title {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 15px;
  font-family: var(--body-font-family);
}

.service-btn {
  background-color: #333;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-family: var(--body-font-family);
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.service-btn:hover {
  background-color: #555;
}

.service-card:before {
  content: "";
  position: absolute;
  top: -38px;
  left: 50%;
  transform: translateX(-60%);
  background-color: white;
  min-width: 80px;
  min-height: 80px;
  border-radius: 50%;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  background-image: var(--image-url);
  /* Use the custom property */
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.service-cards:before {
  content: "";
  position: absolute;
  top: -38px;
  left: 50%;
  transform: translateX(-60%);
  background-color: white;
  min-width: 80px;
  min-height: 80px;
  border-radius: 50%;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  background-image: var(--image-url);
  /* Use the custom property */
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.span {
  margin-left: 40px;
  color: #fff;
  font-family: var(--body-font-family);
}


.cyber h2 {
  font-family: var(--body-font-family);
  font-size: 40px;
  font-weight: 700;
  width: 75%;
}

.cyber p {
  width: 50%;
  font-family: var(--body-font-family);
}

@media (max-width: 991px) {
  .cyber h2 {
    font-family: var(--body-font-family);
    font-size: 40px;
    font-weight: 700;
    width: 100%;
  }

  .services-section h2 {
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 30px;
    color: #222222;
    font-family: var(--body-font-family);
  }

  .heros1 {
    width: 100%;
    min-height: 60vh;
    position: relative;
    font-family: var(--body-font-family);
    padding: 80px 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .heros1 h2 {
    margin: 0;
    font-family: var(--body-font-family);
    font-size: 2rem;
    font-weight: 700;
    color: #fff;
  }

  .service-btn {
    margin: auto;
    display: flex;
  }

  .examples-section {
    display: block !important;
    justify-content: start;
    gap: 40px;
    flex-wrap: wrap;
    /* Allow wrapping on smaller screens */
  }

  .vertical {
    border-left: none !important;
    height: 50px !important;
  }

  .network-container {
    text-align: start !important;
  }

  .cyber p {
    width: 100%;
  }

  .service-card {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    width: 250px;
    height: 100px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }

  .newbg2 h2 {
    margin: 0;
    font-family: var(--body-font-family);
    font-size: 3rem;
    font-weight: 700;
  }

  .newbg2 p {
    margin: 0;
    font-family: var(--body-font-family);
    font-size: 20px;
    color: #222222;
  }

  .newbg2 {
    width: 100%;
    min-height: 50vh;
    height: 100%;

    padding: 80px 0;
    position: relative;
    display: flex;
    align-items: start;
    justify-content: center;
  }

  .newbg2 img {
    position: absolute;
    inset: 0;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
  }

  .cyberheading h2 {
    font-size: 40px;
    font-weight: 700;
    font-family: var(--body-font-family);
    text-align: center;
    width: 75%;
    margin: auto;
  }

  .cyberheading p {

    font-family: var(--body-font-family);
    text-align: center;
    margin: auto;
    width: 100%;
  }

  .cyber3 h2 {
    margin: 0;
    font-family: var(--body-font-family);
    font-size: 40px;
    font-weight: 700;
    color: #fff;
    text-align: center;
    margin: auto;
  }

  .cyber3 p {
    width: 75%;
    font-family: var(--body-font-family);
    text-align: center;
    margin: auto;
    color: #fff;
  }
}

#cyber {
  display: flex;
  align-items: center;
}

.cyberheading h2 {
  font-size: 40px;
  font-weight: 700;
  font-family: var(--body-font-family);
}




.cyber3 {
  width: 100%;
  min-height: auto;
  position: relative;
  padding: 80px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cyber3 img {
  position: absolute;
  inset: 0;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
}


.cyber3 .container {
  position: relative;
  z-index: 3;
}

.cyber3 h2 {
  margin: 0;
  font-family: var(--body-font-family);
  font-size: 40px;
  font-weight: 700;
  color: #fff;
}

.cyber3 p {
  width: 75%;
  font-family: var(--body-font-family);

  color: #fff;
}


.services2-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* 3 columns */
  grid-template-rows: repeat(2, 1fr);
  /* 2 rows */
  gap: 10px;
  max-width: 1200px;
  margin: 0 auto;
}

.services-item {
  position: relative;
  width: 100%;
  padding-bottom: 75%;
  /* This gives a 4:3 aspect ratio */

  /* Background color for empty or loading state */
}

.services-image {
  position: absolute;
  top: 0;
  left: 5%;
  width: 90%;
  margin: auto;
  height: 100%;
  z-index: 9999;
  object-fit: cover;
  border-radius: 5px;
  /* Optional if you want rounded corners */
}

@media (max-width: 991px) {
  .services2-grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    /* 3 columns */
    grid-template-rows: repeat(1, 1fr);
    /* 2 rows */
    gap: 10px;

    margin: 0 auto;
  }

  .services3-grid3 {
    display: grid;
    grid-template-columns: repeat(1, 1fr) !important;
    gap: 20px;
    /* Space between the items */
  }

  .services-image {
    border-radius: 20px;
  }
}

.consulting p {
  font-size: 22px;
  font-family: var(--body-font-family);
}

.innerpage h2 {
  font-family: var(--body-font-family);
}

.innerpage p {
  font-family: var(--body-font-family);
}

.innerpage ul li {
  font-family: var(--body-font-family);
}

.services3 {
  background-color: #e0e0e0;
  /* Light gray background */
  padding: 50px 20px;
  text-align: center;
}



.services4 {
  filter: drop-shadow(3px 5px 4px rgb(255, 255, 255));
  /* Light gray background */
  padding: 50px 20px;
  background-color: #F1EEF0;
  color: #000000;
  text-align: center;
}

.services3 {
  background-color: #e0e0e0;
  /* Light gray background */
  padding: 50px 20px;
  text-align: center;
}

.services3 h2 {
  font-size: 24px;
  margin-bottom: 40px;
  color: #333;
}

.services3 .highlight {
  color: #6f2da8;
  /* Purple color */
  font-size: 40px;
  font-weight: bold;
}
.para23
{
  /* Purple color */
  font-size: 40px;
  text-align: center;
  font-weight: bold;
}
.services3-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  /* 4 columns */
  gap: 20px;
  /* Space between the items */
}

.services3-grid3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* 4 columns */
  gap: 20px;
  /* Space between the items */
}

.service3-item {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  /* Light shadow for depth */
  display: flex;
  align-items: center;
  height: 30vh;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
  color: #333;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  /* Smooth hover effects */
  cursor: pointer;
}

.service3-items {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  margin: auto;
  height: 20vh;
  font-size: 16px;
  font-weight: 500;
  color: #333;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  /* Smooth hover effects */
  cursor: pointer;
}

.service3-item:hover {
  transform: translateY(-5px);
  /* Slight hover lift effect */
  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.2);
  /* Darker shadow on hover */
}

.service3-items:hover {
  transform: translateY(-5px);
  /* Slight hover lift effect */
  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.2);
  /* Darker shadow on hover */
}

/* Responsive Design */
@media (max-width: 1024px) {
  .services3-grid {
    grid-template-columns: repeat(3, 1fr);
    /* 3 columns for tablets */
  }
}

@media (max-width: 768px) {
  .services3-grid {
    grid-template-columns: repeat(2, 1fr);
    /* 2 columns for mobile */
  }
}

@media (max-width: 480px) {
  .services3-grid {
    grid-template-columns: 1fr;

  }
}




/* demo */

.cmp-accordion__header {
  margin: 0
}

.cmp-accordion__button {
  display: block;
  width: 100%;
  text-align: left
}

.cmp-accordion__panel--hidden {
  display: none
}

.cmp-accordion__panel--expanded {
  display: block
}

.cmp-tabs__tablist {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  list-style: none
}

.cmp-tabs__tab {
  box-sizing: border-box;
  border-bottom: 1px solid transparent;
  padding: .5rem 1rem;
  cursor: pointer
}

.cmp-tabs__tab--active {
  border-color: inherit
}

.cmp-tabs__tabpanel {
  display: none
}

.cmp-tabs__tabpanel--active {
  display: block
}

.cmp-carousel__content {
  position: relative
}

.cmp-carousel__item {
  display: none
}

.cmp-carousel__item--active {
  display: block
}

.cmp-carousel__action {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none
}

.cmp-carousel__indicators {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0;
  padding: 0;
  list-style: none
}

.cmp-carousel__indicator {
  position: relative;
  flex: 0 1 auto;
  width: 10px;
  height: 10px;
  margin: 0 7px;
  border-radius: 50%;
  font-size: 0;
  text-indent: -3000px;
  background-color: rgba(0, 0, 0, 0.5)
}

.cmp-carousel__indicator--active {
  background-color: rgba(0, 0, 0, 0.8)
}

.cmp-image__image {
  width: 100%;
  height: auto
}

.cmp-breadcrumb {
  display: inline-block;
  list-style: none;
  padding: 0
}

.cmp-breadcrumb__item {
  display: inline-block;
  vertical-align: top
}

.cmp-search {
  position: relative
}

.cmp-search__field {
  position: relative;
  height: 2rem
}

.cmp-search__input {
  padding-left: 2rem;
  padding-right: 2rem;
  height: 100%;
  width: 100%
}

.cmp-search__icon {
  display: block;
  position: absolute;
  left: .5rem;
  top: .5rem;
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNi4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB3aWR0aD0iMTE1MnB4IiBoZWlnaHQ9IjExNTJweCIgdmlld0JveD0iMCAwIDExNTIgMTE1MiIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgMTE1MiAxMTUyIiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxwYXRoIGQ9Ik02NzIsMEM0MDYuOTAzLDAsMTkyLDIxNC45MDMsMTkyLDQ4MGMwLDk1LjcsMjguMDExLDE4NC44NTUsNzYuMjc1LDI1OS43MjVDMTgxLjY0Niw4MjYuMzU0LDQ4LjA3NSw5NTkuOTI1LDM2LDk3Mg0KCWMtMTgsMTgtMzYsMzYtMzYsNzJzMTgsNTQsMzYsNzJzMzYuMDEyLDM2LDcyLDM2czU0LTE4LDcyLTM2YzEyLjA3NS0xMi4wNzUsMTQ1LjY0Ni0xNDUuNjQ2LDIzMi4yNzUtMjMyLjI3NQ0KCUM0ODcuMTQ0LDkzMS45ODgsNTc2LjMsOTYwLDY3Miw5NjBjMjY1LjA5NywwLDQ4MC0yMTQuOTAzLDQ4MC00ODBDMTE1MiwyMTQuOTAzLDkzNy4wOTcsMCw2NzIsMHogTTY3Miw4MTYNCgljLTE4NS41NjgsMC0zMzYtMTUwLjQzMy0zMzYtMzM2YzAtMTg1LjU2OCwxNTAuNDMyLTMzNiwzMzYtMzM2YzE4NS41NjcsMCwzMzYsMTUwLjQzMiwzMzYsMzM2QzEwMDgsNjY1LjU2Nyw4NTcuNTY3LDgxNiw2NzIsODE2eiINCgkvPg0KPC9zdmc+DQo=");
  background-size: contain;
  width: 1rem;
  height: 1rem;
  pointer-events: none
}

.cmp-search__loading-indicator {
  display: none;
  position: absolute;
  top: .5rem;
  left: .5rem;
  border: 3px solid #ccc;
  border-top-color: #333;
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  animation: cmp-search__loading-indicator-spin 2s linear infinite
}


.cmp-search__clear {
  display: none;
  margin: 0;
  padding: 0;
  border: 0;
  background: transparent
}

.cmp-search__clear-icon {
  position: absolute;
  top: .5rem;
  right: .5rem;
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxOS4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB3aWR0aD0iMTE1MnB4IiBoZWlnaHQ9IjExNTJweCIgdmlld0JveD0iMCAwIDExNTIgMTE1MiIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgMTE1MiAxMTUyIiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxwYXRoIGlkPSJYTUxJRF85XyIgZD0iTTgyLjYsOTM3LjJsMzYyLTM2Mkw4NCwyMTQuOGMtMTQuMS0xNC4xLTE0LjEtMzYuOSwwLTUxbDgxLjItODEuMmMxNC4xLTE0LjEsMzYuOS0xNC4xLDUxLDBsMzYwLjUsMzYwLjUNCglMOTM3LjIsODIuNmMxNC4xLTE0LjEsMzYuOS0xNC4xLDUxLDBsODEuMiw4MS4yYzE0LjEsMTQuMSwxNC4xLDM2LjksMCw1MUw3MDguOSw1NzUuM2wzNjAuNSwzNjAuNWMxNC4xLDE0LjEsMTQuMSwzNi45LDAsNTENCglsLTgxLjIsODEuMmMtMTQuMSwxNC4xLTM2LjksMTQuMS01MSwwTDU3Ni43LDcwNy41bC0zNjIsMzYyYy0xNC4xLDE0LjEtMzYuOSwxNC4xLTUxLDBsLTgxLjItODEuMg0KCUM2OC41LDk3NC4yLDY4LjUsOTUxLjMsODIuNiw5MzcuMnoiLz4NCjwvc3ZnPg0K");
  background-size: contain;
  width: 1rem;
  height: 1rem
}

.cmp-search__results {
  display: none;
  overflow-y: auto;
  min-width: 100%;
  max-height: 10rem;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 999999;
  border: 1px solid #ccc;
  background: #fff
}

.cmp-search__item {
  display: block
}

.cmp-search__item--is-focused {
  background-color: #ccc
}

.cmp-pdfviewer__content {
  height: 500px
}

.pdfviewer:not(.cq-Editable-dom) .cmp-pdfviewer__full-window-borderless {
  height: 100vh;
  position: fixed;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 9999
}

.pdfviewer.cq-Editable-dom .cmp-pdfviewer__full-window-borderless {
  height: 500px
}

.aem-Grid {
  display: block;
  width: 100%
}

.aem-Grid:before,
.aem-Grid:after {
  display: table;
  content: " "
}

.aem-Grid:after {
  clear: both
}

.aem-Grid-newComponent {
  clear: both;
  margin: 0
}

.aem-GridColumn {
  box-sizing: border-box;
  clear: both
}

.aem-GridShowHidden>.aem-Grid>.aem-GridColumn {
  display: block !important
}

.aem-Grid.aem-Grid--1>.aem-GridColumn.aem-GridColumn--default--1 {
  float: left;
  clear: none;
  width: 100%
}

.aem-Grid.aem-Grid--1>.aem-GridColumn.aem-GridColumn--offset--default--0 {
  margin-left: 0
}

.aem-Grid.aem-Grid--1>.aem-GridColumn.aem-GridColumn--offset--default--1 {
  margin-left: 100%
}

.aem-Grid.aem-Grid--2>.aem-GridColumn.aem-GridColumn--default--1 {
  float: left;
  clear: none;
  width: 50%
}

.aem-Grid.aem-Grid--2>.aem-GridColumn.aem-GridColumn--default--2 {
  float: left;
  clear: none;
  width: 100%
}

.aem-Grid.aem-Grid--2>.aem-GridColumn.aem-GridColumn--offset--default--0 {
  margin-left: 0
}

.aem-Grid.aem-Grid--2>.aem-GridColumn.aem-GridColumn--offset--default--1 {
  margin-left: 50%
}

.aem-Grid.aem-Grid--2>.aem-GridColumn.aem-GridColumn--offset--default--2 {
  margin-left: 100%
}

.aem-Grid.aem-Grid--3>.aem-GridColumn.aem-GridColumn--default--1 {
  float: left;
  clear: none;
  width: 33.33333333%
}

.aem-Grid.aem-Grid--3>.aem-GridColumn.aem-GridColumn--default--2 {
  float: left;
  clear: none;
  width: 66.66666667%
}

.aem-Grid.aem-Grid--3>.aem-GridColumn.aem-GridColumn--default--3 {
  float: left;
  clear: none;
  width: 100%
}

.aem-Grid.aem-Grid--3>.aem-GridColumn.aem-GridColumn--offset--default--0 {
  margin-left: 0
}

.aem-Grid.aem-Grid--3>.aem-GridColumn.aem-GridColumn--offset--default--1 {
  margin-left: 33.33333333%
}

.aem-Grid.aem-Grid--3>.aem-GridColumn.aem-GridColumn--offset--default--2 {
  margin-left: 66.66666667%
}

.aem-Grid.aem-Grid--3>.aem-GridColumn.aem-GridColumn--offset--default--3 {
  margin-left: 100%
}

.aem-Grid.aem-Grid--4>.aem-GridColumn.aem-GridColumn--default--1 {
  float: left;
  clear: none;
  width: 25%
}

.aem-Grid.aem-Grid--4>.aem-GridColumn.aem-GridColumn--default--2 {
  float: left;
  clear: none;
  width: 50%
}

.aem-Grid.aem-Grid--4>.aem-GridColumn.aem-GridColumn--default--3 {
  float: left;
  clear: none;
  width: 75%
}

.aem-Grid.aem-Grid--4>.aem-GridColumn.aem-GridColumn--default--4 {
  float: left;
  clear: none;
  width: 100%
}

.aem-Grid.aem-Grid--4>.aem-GridColumn.aem-GridColumn--offset--default--0 {
  margin-left: 0
}

.aem-Grid.aem-Grid--4>.aem-GridColumn.aem-GridColumn--offset--default--1 {
  margin-left: 25%
}

.aem-Grid.aem-Grid--4>.aem-GridColumn.aem-GridColumn--offset--default--2 {
  margin-left: 50%
}

.aem-Grid.aem-Grid--4>.aem-GridColumn.aem-GridColumn--offset--default--3 {
  margin-left: 75%
}

.aem-Grid.aem-Grid--4>.aem-GridColumn.aem-GridColumn--offset--default--4 {
  margin-left: 100%
}

.aem-Grid.aem-Grid--5>.aem-GridColumn.aem-GridColumn--default--1 {
  float: left;
  clear: none;
  width: 20%
}

.aem-Grid.aem-Grid--5>.aem-GridColumn.aem-GridColumn--default--2 {
  float: left;
  clear: none;
  width: 40%
}

.aem-Grid.aem-Grid--5>.aem-GridColumn.aem-GridColumn--default--3 {
  float: left;
  clear: none;
  width: 60%
}

.aem-Grid.aem-Grid--5>.aem-GridColumn.aem-GridColumn--default--4 {
  float: left;
  clear: none;
  width: 80%
}

.aem-Grid.aem-Grid--5>.aem-GridColumn.aem-GridColumn--default--5 {
  float: left;
  clear: none;
  width: 100%
}

.aem-Grid.aem-Grid--5>.aem-GridColumn.aem-GridColumn--offset--default--0 {
  margin-left: 0
}

.aem-Grid.aem-Grid--5>.aem-GridColumn.aem-GridColumn--offset--default--1 {
  margin-left: 20%
}

.aem-Grid.aem-Grid--5>.aem-GridColumn.aem-GridColumn--offset--default--2 {
  margin-left: 40%
}

.aem-Grid.aem-Grid--5>.aem-GridColumn.aem-GridColumn--offset--default--3 {
  margin-left: 60%
}

.aem-Grid.aem-Grid--5>.aem-GridColumn.aem-GridColumn--offset--default--4 {
  margin-left: 80%
}

.aem-Grid.aem-Grid--5>.aem-GridColumn.aem-GridColumn--offset--default--5 {
  margin-left: 100%
}

.aem-Grid.aem-Grid--6>.aem-GridColumn.aem-GridColumn--default--1 {
  float: left;
  clear: none;
  width: 16.66666667%
}

.aem-Grid.aem-Grid--6>.aem-GridColumn.aem-GridColumn--default--2 {
  float: left;
  clear: none;
  width: 33.33333333%
}

.aem-Grid.aem-Grid--6>.aem-GridColumn.aem-GridColumn--default--3 {
  float: left;
  clear: none;
  width: 50%
}

.aem-Grid.aem-Grid--6>.aem-GridColumn.aem-GridColumn--default--4 {
  float: left;
  clear: none;
  width: 66.66666667%
}

.aem-Grid.aem-Grid--6>.aem-GridColumn.aem-GridColumn--default--5 {
  float: left;
  clear: none;
  width: 83.33333333%
}

.aem-Grid.aem-Grid--6>.aem-GridColumn.aem-GridColumn--default--6 {
  float: left;
  clear: none;
  width: 100%
}

.aem-Grid.aem-Grid--6>.aem-GridColumn.aem-GridColumn--offset--default--0 {
  margin-left: 0
}

.aem-Grid.aem-Grid--6>.aem-GridColumn.aem-GridColumn--offset--default--1 {
  margin-left: 16.66666667%
}

.aem-Grid.aem-Grid--6>.aem-GridColumn.aem-GridColumn--offset--default--2 {
  margin-left: 33.33333333%
}

.aem-Grid.aem-Grid--6>.aem-GridColumn.aem-GridColumn--offset--default--3 {
  margin-left: 50%
}

.aem-Grid.aem-Grid--6>.aem-GridColumn.aem-GridColumn--offset--default--4 {
  margin-left: 66.66666667%
}

.aem-Grid.aem-Grid--6>.aem-GridColumn.aem-GridColumn--offset--default--5 {
  margin-left: 83.33333333%
}

.aem-Grid.aem-Grid--6>.aem-GridColumn.aem-GridColumn--offset--default--6 {
  margin-left: 100%
}

.aem-Grid.aem-Grid--7>.aem-GridColumn.aem-GridColumn--default--1 {
  float: left;
  clear: none;
  width: 14.28571429%
}

.aem-Grid.aem-Grid--7>.aem-GridColumn.aem-GridColumn--default--2 {
  float: left;
  clear: none;
  width: 28.57142857%
}

.aem-Grid.aem-Grid--7>.aem-GridColumn.aem-GridColumn--default--3 {
  float: left;
  clear: none;
  width: 42.85714286%
}

.aem-Grid.aem-Grid--7>.aem-GridColumn.aem-GridColumn--default--4 {
  float: left;
  clear: none;
  width: 57.14285714%
}

.aem-Grid.aem-Grid--7>.aem-GridColumn.aem-GridColumn--default--5 {
  float: left;
  clear: none;
  width: 71.42857143%
}

.aem-Grid.aem-Grid--7>.aem-GridColumn.aem-GridColumn--default--6 {
  float: left;
  clear: none;
  width: 85.71428571%
}

.aem-Grid.aem-Grid--7>.aem-GridColumn.aem-GridColumn--default--7 {
  float: left;
  clear: none;
  width: 100%
}

.aem-Grid.aem-Grid--7>.aem-GridColumn.aem-GridColumn--offset--default--0 {
  margin-left: 0
}

.aem-Grid.aem-Grid--7>.aem-GridColumn.aem-GridColumn--offset--default--1 {
  margin-left: 14.28571429%
}

.aem-Grid.aem-Grid--7>.aem-GridColumn.aem-GridColumn--offset--default--2 {
  margin-left: 28.57142857%
}

.aem-Grid.aem-Grid--7>.aem-GridColumn.aem-GridColumn--offset--default--3 {
  margin-left: 42.85714286%
}

.aem-Grid.aem-Grid--7>.aem-GridColumn.aem-GridColumn--offset--default--4 {
  margin-left: 57.14285714%
}

.aem-Grid.aem-Grid--7>.aem-GridColumn.aem-GridColumn--offset--default--5 {
  margin-left: 71.42857143%
}

.aem-Grid.aem-Grid--7>.aem-GridColumn.aem-GridColumn--offset--default--6 {
  margin-left: 85.71428571%
}

.aem-Grid.aem-Grid--7>.aem-GridColumn.aem-GridColumn--offset--default--7 {
  margin-left: 100%
}

.aem-Grid.aem-Grid--8>.aem-GridColumn.aem-GridColumn--default--1 {
  float: left;
  clear: none;
  width: 12.5%
}

.aem-Grid.aem-Grid--8>.aem-GridColumn.aem-GridColumn--default--2 {
  float: left;
  clear: none;
  width: 25%
}

.aem-Grid.aem-Grid--8>.aem-GridColumn.aem-GridColumn--default--3 {
  float: left;
  clear: none;
  width: 37.5%
}

.aem-Grid.aem-Grid--8>.aem-GridColumn.aem-GridColumn--default--4 {
  float: left;
  clear: none;
  width: 50%
}

.aem-Grid.aem-Grid--8>.aem-GridColumn.aem-GridColumn--default--5 {
  float: left;
  clear: none;
  width: 62.5%
}

.aem-Grid.aem-Grid--8>.aem-GridColumn.aem-GridColumn--default--6 {
  float: left;
  clear: none;
  width: 75%
}

.aem-Grid.aem-Grid--8>.aem-GridColumn.aem-GridColumn--default--7 {
  float: left;
  clear: none;
  width: 87.5%
}

.aem-Grid.aem-Grid--8>.aem-GridColumn.aem-GridColumn--default--8 {
  float: left;
  clear: none;
  width: 100%
}

.aem-Grid.aem-Grid--8>.aem-GridColumn.aem-GridColumn--offset--default--0 {
  margin-left: 0
}

.aem-Grid.aem-Grid--8>.aem-GridColumn.aem-GridColumn--offset--default--1 {
  margin-left: 12.5%
}

.aem-Grid.aem-Grid--8>.aem-GridColumn.aem-GridColumn--offset--default--2 {
  margin-left: 25%
}

.aem-Grid.aem-Grid--8>.aem-GridColumn.aem-GridColumn--offset--default--3 {
  margin-left: 37.5%
}

.aem-Grid.aem-Grid--8>.aem-GridColumn.aem-GridColumn--offset--default--4 {
  margin-left: 50%
}

.aem-Grid.aem-Grid--8>.aem-GridColumn.aem-GridColumn--offset--default--5 {
  margin-left: 62.5%
}

.aem-Grid.aem-Grid--8>.aem-GridColumn.aem-GridColumn--offset--default--6 {
  margin-left: 75%
}

.aem-Grid.aem-Grid--8>.aem-GridColumn.aem-GridColumn--offset--default--7 {
  margin-left: 87.5%
}

.aem-Grid.aem-Grid--8>.aem-GridColumn.aem-GridColumn--offset--default--8 {
  margin-left: 100%
}

.aem-Grid.aem-Grid--9>.aem-GridColumn.aem-GridColumn--default--1 {
  float: left;
  clear: none;
  width: 11.11111111%
}

.aem-Grid.aem-Grid--9>.aem-GridColumn.aem-GridColumn--default--2 {
  float: left;
  clear: none;
  width: 22.22222222%
}

.aem-Grid.aem-Grid--9>.aem-GridColumn.aem-GridColumn--default--3 {
  float: left;
  clear: none;
  width: 33.33333333%
}

.aem-Grid.aem-Grid--9>.aem-GridColumn.aem-GridColumn--default--4 {
  float: left;
  clear: none;
  width: 44.44444444%
}

.aem-Grid.aem-Grid--9>.aem-GridColumn.aem-GridColumn--default--5 {
  float: left;
  clear: none;
  width: 55.55555556%
}

.aem-Grid.aem-Grid--9>.aem-GridColumn.aem-GridColumn--default--6 {
  float: left;
  clear: none;
  width: 66.66666667%
}

.aem-Grid.aem-Grid--9>.aem-GridColumn.aem-GridColumn--default--7 {
  float: left;
  clear: none;
  width: 77.77777778%
}

.aem-Grid.aem-Grid--9>.aem-GridColumn.aem-GridColumn--default--8 {
  float: left;
  clear: none;
  width: 88.88888889%
}

.aem-Grid.aem-Grid--9>.aem-GridColumn.aem-GridColumn--default--9 {
  float: left;
  clear: none;
  width: 100%
}

.aem-Grid.aem-Grid--9>.aem-GridColumn.aem-GridColumn--offset--default--0 {
  margin-left: 0
}

.aem-Grid.aem-Grid--9>.aem-GridColumn.aem-GridColumn--offset--default--1 {
  margin-left: 11.11111111%
}

.aem-Grid.aem-Grid--9>.aem-GridColumn.aem-GridColumn--offset--default--2 {
  margin-left: 22.22222222%
}

.aem-Grid.aem-Grid--9>.aem-GridColumn.aem-GridColumn--offset--default--3 {
  margin-left: 33.33333333%
}

.aem-Grid.aem-Grid--9>.aem-GridColumn.aem-GridColumn--offset--default--4 {
  margin-left: 44.44444444%
}

.aem-Grid.aem-Grid--9>.aem-GridColumn.aem-GridColumn--offset--default--5 {
  margin-left: 55.55555556%
}

.aem-Grid.aem-Grid--9>.aem-GridColumn.aem-GridColumn--offset--default--6 {
  margin-left: 66.66666667%
}

.aem-Grid.aem-Grid--9>.aem-GridColumn.aem-GridColumn--offset--default--7 {
  margin-left: 77.77777778%
}

.aem-Grid.aem-Grid--9>.aem-GridColumn.aem-GridColumn--offset--default--8 {
  margin-left: 88.88888889%
}

.aem-Grid.aem-Grid--9>.aem-GridColumn.aem-GridColumn--offset--default--9 {
  margin-left: 100%
}

.aem-Grid.aem-Grid--10>.aem-GridColumn.aem-GridColumn--default--1 {
  float: left;
  clear: none;
  width: 10%
}

.aem-Grid.aem-Grid--10>.aem-GridColumn.aem-GridColumn--default--2 {
  float: left;
  clear: none;
  width: 20%
}

.aem-Grid.aem-Grid--10>.aem-GridColumn.aem-GridColumn--default--3 {
  float: left;
  clear: none;
  width: 30%
}

.aem-Grid.aem-Grid--10>.aem-GridColumn.aem-GridColumn--default--4 {
  float: left;
  clear: none;
  width: 40%
}

.aem-Grid.aem-Grid--10>.aem-GridColumn.aem-GridColumn--default--5 {
  float: left;
  clear: none;
  width: 50%
}

.aem-Grid.aem-Grid--10>.aem-GridColumn.aem-GridColumn--default--6 {
  float: left;
  clear: none;
  width: 60%
}

.aem-Grid.aem-Grid--10>.aem-GridColumn.aem-GridColumn--default--7 {
  float: left;
  clear: none;
  width: 70%
}

.aem-Grid.aem-Grid--10>.aem-GridColumn.aem-GridColumn--default--8 {
  float: left;
  clear: none;
  width: 80%
}

.aem-Grid.aem-Grid--10>.aem-GridColumn.aem-GridColumn--default--9 {
  float: left;
  clear: none;
  width: 90%
}

.aem-Grid.aem-Grid--10>.aem-GridColumn.aem-GridColumn--default--10 {
  float: left;
  clear: none;
  width: 100%
}

.aem-Grid.aem-Grid--10>.aem-GridColumn.aem-GridColumn--offset--default--0 {
  margin-left: 0
}

.aem-Grid.aem-Grid--10>.aem-GridColumn.aem-GridColumn--offset--default--1 {
  margin-left: 10%
}

.aem-Grid.aem-Grid--10>.aem-GridColumn.aem-GridColumn--offset--default--2 {
  margin-left: 20%
}

.aem-Grid.aem-Grid--10>.aem-GridColumn.aem-GridColumn--offset--default--3 {
  margin-left: 30%
}

.aem-Grid.aem-Grid--10>.aem-GridColumn.aem-GridColumn--offset--default--4 {
  margin-left: 40%
}

.aem-Grid.aem-Grid--10>.aem-GridColumn.aem-GridColumn--offset--default--5 {
  margin-left: 50%
}

.aem-Grid.aem-Grid--10>.aem-GridColumn.aem-GridColumn--offset--default--6 {
  margin-left: 60%
}

.aem-Grid.aem-Grid--10>.aem-GridColumn.aem-GridColumn--offset--default--7 {
  margin-left: 70%
}

.aem-Grid.aem-Grid--10>.aem-GridColumn.aem-GridColumn--offset--default--8 {
  margin-left: 80%
}

.aem-Grid.aem-Grid--10>.aem-GridColumn.aem-GridColumn--offset--default--9 {
  margin-left: 90%
}

.aem-Grid.aem-Grid--10>.aem-GridColumn.aem-GridColumn--offset--default--10 {
  margin-left: 100%
}

.aem-Grid.aem-Grid--11>.aem-GridColumn.aem-GridColumn--default--1 {
  float: left;
  clear: none;
  width: 9.09090909%
}

.aem-Grid.aem-Grid--11>.aem-GridColumn.aem-GridColumn--default--2 {
  float: left;
  clear: none;
  width: 18.18181818%
}

.aem-Grid.aem-Grid--11>.aem-GridColumn.aem-GridColumn--default--3 {
  float: left;
  clear: none;
  width: 27.27272727%
}

.aem-Grid.aem-Grid--11>.aem-GridColumn.aem-GridColumn--default--4 {
  float: left;
  clear: none;
  width: 36.36363636%
}

.aem-Grid.aem-Grid--11>.aem-GridColumn.aem-GridColumn--default--5 {
  float: left;
  clear: none;
  width: 45.45454545%
}

.aem-Grid.aem-Grid--11>.aem-GridColumn.aem-GridColumn--default--6 {
  float: left;
  clear: none;
  width: 54.54545455%
}

.aem-Grid.aem-Grid--11>.aem-GridColumn.aem-GridColumn--default--7 {
  float: left;
  clear: none;
  width: 63.63636364%
}

.aem-Grid.aem-Grid--11>.aem-GridColumn.aem-GridColumn--default--8 {
  float: left;
  clear: none;
  width: 72.72727273%
}

.aem-Grid.aem-Grid--11>.aem-GridColumn.aem-GridColumn--default--9 {
  float: left;
  clear: none;
  width: 81.81818182%
}

.aem-Grid.aem-Grid--11>.aem-GridColumn.aem-GridColumn--default--10 {
  float: left;
  clear: none;
  width: 90.90909091%
}

.aem-Grid.aem-Grid--11>.aem-GridColumn.aem-GridColumn--default--11 {
  float: left;
  clear: none;
  width: 100%
}

.aem-Grid.aem-Grid--11>.aem-GridColumn.aem-GridColumn--offset--default--0 {
  margin-left: 0
}

.aem-Grid.aem-Grid--11>.aem-GridColumn.aem-GridColumn--offset--default--1 {
  margin-left: 9.09090909%
}

.aem-Grid.aem-Grid--11>.aem-GridColumn.aem-GridColumn--offset--default--2 {
  margin-left: 18.18181818%
}

.aem-Grid.aem-Grid--11>.aem-GridColumn.aem-GridColumn--offset--default--3 {
  margin-left: 27.27272727%
}

.aem-Grid.aem-Grid--11>.aem-GridColumn.aem-GridColumn--offset--default--4 {
  margin-left: 36.36363636%
}

.aem-Grid.aem-Grid--11>.aem-GridColumn.aem-GridColumn--offset--default--5 {
  margin-left: 45.45454545%
}

.aem-Grid.aem-Grid--11>.aem-GridColumn.aem-GridColumn--offset--default--6 {
  margin-left: 54.54545455%
}

.aem-Grid.aem-Grid--11>.aem-GridColumn.aem-GridColumn--offset--default--7 {
  margin-left: 63.63636364%
}

.aem-Grid.aem-Grid--11>.aem-GridColumn.aem-GridColumn--offset--default--8 {
  margin-left: 72.72727273%
}

.aem-Grid.aem-Grid--11>.aem-GridColumn.aem-GridColumn--offset--default--9 {
  margin-left: 81.81818182%
}

.aem-Grid.aem-Grid--11>.aem-GridColumn.aem-GridColumn--offset--default--10 {
  margin-left: 90.90909091%
}

.aem-Grid.aem-Grid--11>.aem-GridColumn.aem-GridColumn--offset--default--11 {
  margin-left: 100%
}

.aem-Grid.aem-Grid--12>.aem-GridColumn.aem-GridColumn--default--1 {
  float: left;
  clear: none;
  width: 8.33333333%
}

.aem-Grid.aem-Grid--12>.aem-GridColumn.aem-GridColumn--default--2 {
  float: left;
  clear: none;
  width: 16.66666667%
}

.aem-Grid.aem-Grid--12>.aem-GridColumn.aem-GridColumn--default--3 {
  float: left;
  clear: none;
  width: 25%
}

.aem-Grid.aem-Grid--12>.aem-GridColumn.aem-GridColumn--default--4 {
  float: left;
  clear: none;
  width: 33.33333333%
}

.aem-Grid.aem-Grid--12>.aem-GridColumn.aem-GridColumn--default--5 {
  float: left;
  clear: none;
  width: 41.66666667%
}

.aem-Grid.aem-Grid--12>.aem-GridColumn.aem-GridColumn--default--6 {
  float: left;
  clear: none;
  width: 50%
}

.aem-Grid.aem-Grid--12>.aem-GridColumn.aem-GridColumn--default--7 {
  float: left;
  clear: none;
  width: 58.33333333%
}

.aem-Grid.aem-Grid--12>.aem-GridColumn.aem-GridColumn--default--8 {
  float: left;
  clear: none;
  width: 66.66666667%
}

.aem-Grid.aem-Grid--12>.aem-GridColumn.aem-GridColumn--default--9 {
  float: left;
  clear: none;
  width: 75%
}

.aem-Grid.aem-Grid--12>.aem-GridColumn.aem-GridColumn--default--10 {
  float: left;
  clear: none;
  width: 83.33333333%
}

.aem-Grid.aem-Grid--12>.aem-GridColumn.aem-GridColumn--default--11 {
  float: left;
  clear: none;
  width: 91.66666667%
}

.aem-Grid.aem-Grid--12>.aem-GridColumn.aem-GridColumn--default--12 {
  float: left;
  clear: none;
  width: 100%
}

.aem-Grid.aem-Grid--12>.aem-GridColumn.aem-GridColumn--offset--default--0 {
  margin-left: 0
}

.aem-Grid.aem-Grid--12>.aem-GridColumn.aem-GridColumn--offset--default--1 {
  margin-left: 8.33333333%
}

.aem-Grid.aem-Grid--12>.aem-GridColumn.aem-GridColumn--offset--default--2 {
  margin-left: 16.66666667%
}

.aem-Grid.aem-Grid--12>.aem-GridColumn.aem-GridColumn--offset--default--3 {
  margin-left: 25%
}

.aem-Grid.aem-Grid--12>.aem-GridColumn.aem-GridColumn--offset--default--4 {
  margin-left: 33.33333333%
}

.aem-Grid.aem-Grid--12>.aem-GridColumn.aem-GridColumn--offset--default--5 {
  margin-left: 41.66666667%
}

.aem-Grid.aem-Grid--12>.aem-GridColumn.aem-GridColumn--offset--default--6 {
  margin-left: 50%
}

.aem-Grid.aem-Grid--12>.aem-GridColumn.aem-GridColumn--offset--default--7 {
  margin-left: 58.33333333%
}

.aem-Grid.aem-Grid--12>.aem-GridColumn.aem-GridColumn--offset--default--8 {
  margin-left: 66.66666667%
}

.aem-Grid.aem-Grid--12>.aem-GridColumn.aem-GridColumn--offset--default--9 {
  margin-left: 75%
}

.aem-Grid.aem-Grid--12>.aem-GridColumn.aem-GridColumn--offset--default--10 {
  margin-left: 83.33333333%
}

.aem-Grid.aem-Grid--12>.aem-GridColumn.aem-GridColumn--offset--default--11 {
  margin-left: 91.66666667%
}

.aem-Grid.aem-Grid--12>.aem-GridColumn.aem-GridColumn--offset--default--12 {
  margin-left: 100%
}

.aem-Grid.aem-Grid--default--1>.aem-GridColumn.aem-GridColumn--default--1 {
  float: left;
  clear: none;
  width: 100%
}

.aem-Grid.aem-Grid--default--1>.aem-GridColumn.aem-GridColumn--offset--default--0 {
  margin-left: 0
}

.aem-Grid.aem-Grid--default--1>.aem-GridColumn.aem-GridColumn--offset--default--1 {
  margin-left: 100%
}

.aem-Grid.aem-Grid--default--2>.aem-GridColumn.aem-GridColumn--default--1 {
  float: left;
  clear: none;
  width: 50%
}

.aem-Grid.aem-Grid--default--2>.aem-GridColumn.aem-GridColumn--default--2 {
  float: left;
  clear: none;
  width: 100%
}

.aem-Grid.aem-Grid--default--2>.aem-GridColumn.aem-GridColumn--offset--default--0 {
  margin-left: 0
}

.aem-Grid.aem-Grid--default--2>.aem-GridColumn.aem-GridColumn--offset--default--1 {
  margin-left: 50%
}

.aem-Grid.aem-Grid--default--2>.aem-GridColumn.aem-GridColumn--offset--default--2 {
  margin-left: 100%
}

.aem-Grid.aem-Grid--default--3>.aem-GridColumn.aem-GridColumn--default--1 {
  float: left;
  clear: none;
  width: 33.33333333%
}

.aem-Grid.aem-Grid--default--3>.aem-GridColumn.aem-GridColumn--default--2 {
  float: left;
  clear: none;
  width: 66.66666667%
}

.aem-Grid.aem-Grid--default--3>.aem-GridColumn.aem-GridColumn--default--3 {
  float: left;
  clear: none;
  width: 100%
}

.aem-Grid.aem-Grid--default--3>.aem-GridColumn.aem-GridColumn--offset--default--0 {
  margin-left: 0
}

.aem-Grid.aem-Grid--default--3>.aem-GridColumn.aem-GridColumn--offset--default--1 {
  margin-left: 33.33333333%
}

.aem-Grid.aem-Grid--default--3>.aem-GridColumn.aem-GridColumn--offset--default--2 {
  margin-left: 66.66666667%
}

.aem-Grid.aem-Grid--default--3>.aem-GridColumn.aem-GridColumn--offset--default--3 {
  margin-left: 100%
}

.aem-Grid.aem-Grid--default--4>.aem-GridColumn.aem-GridColumn--default--1 {
  float: left;
  clear: none;
  width: 25%
}

.aem-Grid.aem-Grid--default--4>.aem-GridColumn.aem-GridColumn--default--2 {
  float: left;
  clear: none;
  width: 50%
}

.aem-Grid.aem-Grid--default--4>.aem-GridColumn.aem-GridColumn--default--3 {
  float: left;
  clear: none;
  width: 75%
}

.aem-Grid.aem-Grid--default--4>.aem-GridColumn.aem-GridColumn--default--4 {
  float: left;
  clear: none;
  width: 100%
}

.aem-Grid.aem-Grid--default--4>.aem-GridColumn.aem-GridColumn--offset--default--0 {
  margin-left: 0
}

.aem-Grid.aem-Grid--default--4>.aem-GridColumn.aem-GridColumn--offset--default--1 {
  margin-left: 25%
}

.aem-Grid.aem-Grid--default--4>.aem-GridColumn.aem-GridColumn--offset--default--2 {
  margin-left: 50%
}

.aem-Grid.aem-Grid--default--4>.aem-GridColumn.aem-GridColumn--offset--default--3 {
  margin-left: 75%
}

.aem-Grid.aem-Grid--default--4>.aem-GridColumn.aem-GridColumn--offset--default--4 {
  margin-left: 100%
}

.aem-Grid.aem-Grid--default--5>.aem-GridColumn.aem-GridColumn--default--1 {
  float: left;
  clear: none;
  width: 20%
}

.aem-Grid.aem-Grid--default--5>.aem-GridColumn.aem-GridColumn--default--2 {
  float: left;
  clear: none;
  width: 40%
}

.aem-Grid.aem-Grid--default--5>.aem-GridColumn.aem-GridColumn--default--3 {
  float: left;
  clear: none;
  width: 60%
}

.aem-Grid.aem-Grid--default--5>.aem-GridColumn.aem-GridColumn--default--4 {
  float: left;
  clear: none;
  width: 80%
}

.aem-Grid.aem-Grid--default--5>.aem-GridColumn.aem-GridColumn--default--5 {
  float: left;
  clear: none;
  width: 100%
}

.aem-Grid.aem-Grid--default--5>.aem-GridColumn.aem-GridColumn--offset--default--0 {
  margin-left: 0
}

.aem-Grid.aem-Grid--default--5>.aem-GridColumn.aem-GridColumn--offset--default--1 {
  margin-left: 20%
}

.aem-Grid.aem-Grid--default--5>.aem-GridColumn.aem-GridColumn--offset--default--2 {
  margin-left: 40%
}

.aem-Grid.aem-Grid--default--5>.aem-GridColumn.aem-GridColumn--offset--default--3 {
  margin-left: 60%
}

.aem-Grid.aem-Grid--default--5>.aem-GridColumn.aem-GridColumn--offset--default--4 {
  margin-left: 80%
}

.aem-Grid.aem-Grid--default--5>.aem-GridColumn.aem-GridColumn--offset--default--5 {
  margin-left: 100%
}

.aem-Grid.aem-Grid--default--6>.aem-GridColumn.aem-GridColumn--default--1 {
  float: left;
  clear: none;
  width: 16.66666667%
}

.aem-Grid.aem-Grid--default--6>.aem-GridColumn.aem-GridColumn--default--2 {
  float: left;
  clear: none;
  width: 33.33333333%
}

.aem-Grid.aem-Grid--default--6>.aem-GridColumn.aem-GridColumn--default--3 {
  float: left;
  clear: none;
  width: 50%
}

.aem-Grid.aem-Grid--default--6>.aem-GridColumn.aem-GridColumn--default--4 {
  float: left;
  clear: none;
  width: 66.66666667%
}

.aem-Grid.aem-Grid--default--6>.aem-GridColumn.aem-GridColumn--default--5 {
  float: left;
  clear: none;
  width: 83.33333333%
}

.aem-Grid.aem-Grid--default--6>.aem-GridColumn.aem-GridColumn--default--6 {
  float: left;
  clear: none;
  width: 100%
}

.aem-Grid.aem-Grid--default--6>.aem-GridColumn.aem-GridColumn--offset--default--0 {
  margin-left: 0
}

.aem-Grid.aem-Grid--default--6>.aem-GridColumn.aem-GridColumn--offset--default--1 {
  margin-left: 16.66666667%
}

.aem-Grid.aem-Grid--default--6>.aem-GridColumn.aem-GridColumn--offset--default--2 {
  margin-left: 33.33333333%
}

.aem-Grid.aem-Grid--default--6>.aem-GridColumn.aem-GridColumn--offset--default--3 {
  margin-left: 50%
}

.aem-Grid.aem-Grid--default--6>.aem-GridColumn.aem-GridColumn--offset--default--4 {
  margin-left: 66.66666667%
}

.aem-Grid.aem-Grid--default--6>.aem-GridColumn.aem-GridColumn--offset--default--5 {
  margin-left: 83.33333333%
}

.aem-Grid.aem-Grid--default--6>.aem-GridColumn.aem-GridColumn--offset--default--6 {
  margin-left: 100%
}

.aem-Grid.aem-Grid--default--7>.aem-GridColumn.aem-GridColumn--default--1 {
  float: left;
  clear: none;
  width: 14.28571429%
}

.aem-Grid.aem-Grid--default--7>.aem-GridColumn.aem-GridColumn--default--2 {
  float: left;
  clear: none;
  width: 28.57142857%
}

.aem-Grid.aem-Grid--default--7>.aem-GridColumn.aem-GridColumn--default--3 {
  float: left;
  clear: none;
  width: 42.85714286%
}

.aem-Grid.aem-Grid--default--7>.aem-GridColumn.aem-GridColumn--default--4 {
  float: left;
  clear: none;
  width: 57.14285714%
}

.aem-Grid.aem-Grid--default--7>.aem-GridColumn.aem-GridColumn--default--5 {
  float: left;
  clear: none;
  width: 71.42857143%
}

.aem-Grid.aem-Grid--default--7>.aem-GridColumn.aem-GridColumn--default--6 {
  float: left;
  clear: none;
  width: 85.71428571%
}

.aem-Grid.aem-Grid--default--7>.aem-GridColumn.aem-GridColumn--default--7 {
  float: left;
  clear: none;
  width: 100%
}

.aem-Grid.aem-Grid--default--7>.aem-GridColumn.aem-GridColumn--offset--default--0 {
  margin-left: 0
}

.aem-Grid.aem-Grid--default--7>.aem-GridColumn.aem-GridColumn--offset--default--1 {
  margin-left: 14.28571429%
}

.aem-Grid.aem-Grid--default--7>.aem-GridColumn.aem-GridColumn--offset--default--2 {
  margin-left: 28.57142857%
}

.aem-Grid.aem-Grid--default--7>.aem-GridColumn.aem-GridColumn--offset--default--3 {
  margin-left: 42.85714286%
}

.aem-Grid.aem-Grid--default--7>.aem-GridColumn.aem-GridColumn--offset--default--4 {
  margin-left: 57.14285714%
}

.aem-Grid.aem-Grid--default--7>.aem-GridColumn.aem-GridColumn--offset--default--5 {
  margin-left: 71.42857143%
}

.aem-Grid.aem-Grid--default--7>.aem-GridColumn.aem-GridColumn--offset--default--6 {
  margin-left: 85.71428571%
}

.aem-Grid.aem-Grid--default--7>.aem-GridColumn.aem-GridColumn--offset--default--7 {
  margin-left: 100%
}

.aem-Grid.aem-Grid--default--8>.aem-GridColumn.aem-GridColumn--default--1 {
  float: left;
  clear: none;
  width: 12.5%
}

.aem-Grid.aem-Grid--default--8>.aem-GridColumn.aem-GridColumn--default--2 {
  float: left;
  clear: none;
  width: 25%
}

.aem-Grid.aem-Grid--default--8>.aem-GridColumn.aem-GridColumn--default--3 {
  float: left;
  clear: none;
  width: 37.5%
}

.aem-Grid.aem-Grid--default--8>.aem-GridColumn.aem-GridColumn--default--4 {
  float: left;
  clear: none;
  width: 50%
}

.aem-Grid.aem-Grid--default--8>.aem-GridColumn.aem-GridColumn--default--5 {
  float: left;
  clear: none;
  width: 62.5%
}

.aem-Grid.aem-Grid--default--8>.aem-GridColumn.aem-GridColumn--default--6 {
  float: left;
  clear: none;
  width: 75%
}

.aem-Grid.aem-Grid--default--8>.aem-GridColumn.aem-GridColumn--default--7 {
  float: left;
  clear: none;
  width: 87.5%
}

.aem-Grid.aem-Grid--default--8>.aem-GridColumn.aem-GridColumn--default--8 {
  float: left;
  clear: none;
  width: 100%
}

.aem-Grid.aem-Grid--default--8>.aem-GridColumn.aem-GridColumn--offset--default--0 {
  margin-left: 0
}

.aem-Grid.aem-Grid--default--8>.aem-GridColumn.aem-GridColumn--offset--default--1 {
  margin-left: 12.5%
}

.aem-Grid.aem-Grid--default--8>.aem-GridColumn.aem-GridColumn--offset--default--2 {
  margin-left: 25%
}

.aem-Grid.aem-Grid--default--8>.aem-GridColumn.aem-GridColumn--offset--default--3 {
  margin-left: 37.5%
}

.aem-Grid.aem-Grid--default--8>.aem-GridColumn.aem-GridColumn--offset--default--4 {
  margin-left: 50%
}

.aem-Grid.aem-Grid--default--8>.aem-GridColumn.aem-GridColumn--offset--default--5 {
  margin-left: 62.5%
}

.aem-Grid.aem-Grid--default--8>.aem-GridColumn.aem-GridColumn--offset--default--6 {
  margin-left: 75%
}

.aem-Grid.aem-Grid--default--8>.aem-GridColumn.aem-GridColumn--offset--default--7 {
  margin-left: 87.5%
}

.aem-Grid.aem-Grid--default--8>.aem-GridColumn.aem-GridColumn--offset--default--8 {
  margin-left: 100%
}

.aem-Grid.aem-Grid--default--9>.aem-GridColumn.aem-GridColumn--default--1 {
  float: left;
  clear: none;
  width: 11.11111111%
}

.aem-Grid.aem-Grid--default--9>.aem-GridColumn.aem-GridColumn--default--2 {
  float: left;
  clear: none;
  width: 22.22222222%
}

.aem-Grid.aem-Grid--default--9>.aem-GridColumn.aem-GridColumn--default--3 {
  float: left;
  clear: none;
  width: 33.33333333%
}

.aem-Grid.aem-Grid--default--9>.aem-GridColumn.aem-GridColumn--default--4 {
  float: left;
  clear: none;
  width: 44.44444444%
}

.aem-Grid.aem-Grid--default--9>.aem-GridColumn.aem-GridColumn--default--5 {
  float: left;
  clear: none;
  width: 55.55555556%
}

.aem-Grid.aem-Grid--default--9>.aem-GridColumn.aem-GridColumn--default--6 {
  float: left;
  clear: none;
  width: 66.66666667%
}

.aem-Grid.aem-Grid--default--9>.aem-GridColumn.aem-GridColumn--default--7 {
  float: left;
  clear: none;
  width: 77.77777778%
}

.aem-Grid.aem-Grid--default--9>.aem-GridColumn.aem-GridColumn--default--8 {
  float: left;
  clear: none;
  width: 88.88888889%
}

.aem-Grid.aem-Grid--default--9>.aem-GridColumn.aem-GridColumn--default--9 {
  float: left;
  clear: none;
  width: 100%
}

.aem-Grid.aem-Grid--default--9>.aem-GridColumn.aem-GridColumn--offset--default--0 {
  margin-left: 0
}

.aem-Grid.aem-Grid--default--9>.aem-GridColumn.aem-GridColumn--offset--default--1 {
  margin-left: 11.11111111%
}

.aem-Grid.aem-Grid--default--9>.aem-GridColumn.aem-GridColumn--offset--default--2 {
  margin-left: 22.22222222%
}

.aem-Grid.aem-Grid--default--9>.aem-GridColumn.aem-GridColumn--offset--default--3 {
  margin-left: 33.33333333%
}

.aem-Grid.aem-Grid--default--9>.aem-GridColumn.aem-GridColumn--offset--default--4 {
  margin-left: 44.44444444%
}

.aem-Grid.aem-Grid--default--9>.aem-GridColumn.aem-GridColumn--offset--default--5 {
  margin-left: 55.55555556%
}

.aem-Grid.aem-Grid--default--9>.aem-GridColumn.aem-GridColumn--offset--default--6 {
  margin-left: 66.66666667%
}

.aem-Grid.aem-Grid--default--9>.aem-GridColumn.aem-GridColumn--offset--default--7 {
  margin-left: 77.77777778%
}

.aem-Grid.aem-Grid--default--9>.aem-GridColumn.aem-GridColumn--offset--default--8 {
  margin-left: 88.88888889%
}

.aem-Grid.aem-Grid--default--9>.aem-GridColumn.aem-GridColumn--offset--default--9 {
  margin-left: 100%
}

.aem-Grid.aem-Grid--default--10>.aem-GridColumn.aem-GridColumn--default--1 {
  float: left;
  clear: none;
  width: 10%
}

.aem-Grid.aem-Grid--default--10>.aem-GridColumn.aem-GridColumn--default--2 {
  float: left;
  clear: none;
  width: 20%
}

.aem-Grid.aem-Grid--default--10>.aem-GridColumn.aem-GridColumn--default--3 {
  float: left;
  clear: none;
  width: 30%
}

.aem-Grid.aem-Grid--default--10>.aem-GridColumn.aem-GridColumn--default--4 {
  float: left;
  clear: none;
  width: 40%
}

.aem-Grid.aem-Grid--default--10>.aem-GridColumn.aem-GridColumn--default--5 {
  float: left;
  clear: none;
  width: 50%
}

.aem-Grid.aem-Grid--default--10>.aem-GridColumn.aem-GridColumn--default--6 {
  float: left;
  clear: none;
  width: 60%
}

.aem-Grid.aem-Grid--default--10>.aem-GridColumn.aem-GridColumn--default--7 {
  float: left;
  clear: none;
  width: 70%
}

.aem-Grid.aem-Grid--default--10>.aem-GridColumn.aem-GridColumn--default--8 {
  float: left;
  clear: none;
  width: 80%
}

.aem-Grid.aem-Grid--default--10>.aem-GridColumn.aem-GridColumn--default--9 {
  float: left;
  clear: none;
  width: 90%
}

.aem-Grid.aem-Grid--default--10>.aem-GridColumn.aem-GridColumn--default--10 {
  float: left;
  clear: none;
  width: 100%
}

.aem-Grid.aem-Grid--default--10>.aem-GridColumn.aem-GridColumn--offset--default--0 {
  margin-left: 0
}

.aem-Grid.aem-Grid--default--10>.aem-GridColumn.aem-GridColumn--offset--default--1 {
  margin-left: 10%
}

.aem-Grid.aem-Grid--default--10>.aem-GridColumn.aem-GridColumn--offset--default--2 {
  margin-left: 20%
}

.aem-Grid.aem-Grid--default--10>.aem-GridColumn.aem-GridColumn--offset--default--3 {
  margin-left: 30%
}

.aem-Grid.aem-Grid--default--10>.aem-GridColumn.aem-GridColumn--offset--default--4 {
  margin-left: 40%
}

.aem-Grid.aem-Grid--default--10>.aem-GridColumn.aem-GridColumn--offset--default--5 {
  margin-left: 50%
}

.aem-Grid.aem-Grid--default--10>.aem-GridColumn.aem-GridColumn--offset--default--6 {
  margin-left: 60%
}

.aem-Grid.aem-Grid--default--10>.aem-GridColumn.aem-GridColumn--offset--default--7 {
  margin-left: 70%
}

.aem-Grid.aem-Grid--default--10>.aem-GridColumn.aem-GridColumn--offset--default--8 {
  margin-left: 80%
}

.aem-Grid.aem-Grid--default--10>.aem-GridColumn.aem-GridColumn--offset--default--9 {
  margin-left: 90%
}

.aem-Grid.aem-Grid--default--10>.aem-GridColumn.aem-GridColumn--offset--default--10 {
  margin-left: 100%
}

.aem-Grid.aem-Grid--default--11>.aem-GridColumn.aem-GridColumn--default--1 {
  float: left;
  clear: none;
  width: 9.09090909%
}

.aem-Grid.aem-Grid--default--11>.aem-GridColumn.aem-GridColumn--default--2 {
  float: left;
  clear: none;
  width: 18.18181818%
}

.aem-Grid.aem-Grid--default--11>.aem-GridColumn.aem-GridColumn--default--3 {
  float: left;
  clear: none;
  width: 27.27272727%
}

.aem-Grid.aem-Grid--default--11>.aem-GridColumn.aem-GridColumn--default--4 {
  float: left;
  clear: none;
  width: 36.36363636%
}

.aem-Grid.aem-Grid--default--11>.aem-GridColumn.aem-GridColumn--default--5 {
  float: left;
  clear: none;
  width: 45.45454545%
}

.aem-Grid.aem-Grid--default--11>.aem-GridColumn.aem-GridColumn--default--6 {
  float: left;
  clear: none;
  width: 54.54545455%
}

.aem-Grid.aem-Grid--default--11>.aem-GridColumn.aem-GridColumn--default--7 {
  float: left;
  clear: none;
  width: 63.63636364%
}

.aem-Grid.aem-Grid--default--11>.aem-GridColumn.aem-GridColumn--default--8 {
  float: left;
  clear: none;
  width: 72.72727273%
}

.aem-Grid.aem-Grid--default--11>.aem-GridColumn.aem-GridColumn--default--9 {
  float: left;
  clear: none;
  width: 81.81818182%
}

.aem-Grid.aem-Grid--default--11>.aem-GridColumn.aem-GridColumn--default--10 {
  float: left;
  clear: none;
  width: 90.90909091%
}

.aem-Grid.aem-Grid--default--11>.aem-GridColumn.aem-GridColumn--default--11 {
  float: left;
  clear: none;
  width: 100%
}

.aem-Grid.aem-Grid--default--11>.aem-GridColumn.aem-GridColumn--offset--default--0 {
  margin-left: 0
}

.aem-Grid.aem-Grid--default--11>.aem-GridColumn.aem-GridColumn--offset--default--1 {
  margin-left: 9.09090909%
}

.aem-Grid.aem-Grid--default--11>.aem-GridColumn.aem-GridColumn--offset--default--2 {
  margin-left: 18.18181818%
}

.aem-Grid.aem-Grid--default--11>.aem-GridColumn.aem-GridColumn--offset--default--3 {
  margin-left: 27.27272727%
}

.aem-Grid.aem-Grid--default--11>.aem-GridColumn.aem-GridColumn--offset--default--4 {
  margin-left: 36.36363636%
}

.aem-Grid.aem-Grid--default--11>.aem-GridColumn.aem-GridColumn--offset--default--5 {
  margin-left: 45.45454545%
}

.aem-Grid.aem-Grid--default--11>.aem-GridColumn.aem-GridColumn--offset--default--6 {
  margin-left: 54.54545455%
}

.aem-Grid.aem-Grid--default--11>.aem-GridColumn.aem-GridColumn--offset--default--7 {
  margin-left: 63.63636364%
}

.aem-Grid.aem-Grid--default--11>.aem-GridColumn.aem-GridColumn--offset--default--8 {
  margin-left: 72.72727273%
}

.aem-Grid.aem-Grid--default--11>.aem-GridColumn.aem-GridColumn--offset--default--9 {
  margin-left: 81.81818182%
}

.aem-Grid.aem-Grid--default--11>.aem-GridColumn.aem-GridColumn--offset--default--10 {
  margin-left: 90.90909091%
}

.aem-Grid.aem-Grid--default--11>.aem-GridColumn.aem-GridColumn--offset--default--11 {
  margin-left: 100%
}

.aem-Grid.aem-Grid--default--12>.aem-GridColumn.aem-GridColumn--default--1 {
  float: left;
  clear: none;
  width: 8.33333333%
}

.aem-Grid.aem-Grid--default--12>.aem-GridColumn.aem-GridColumn--default--2 {
  float: left;
  clear: none;
  width: 16.66666667%
}

.aem-Grid.aem-Grid--default--12>.aem-GridColumn.aem-GridColumn--default--3 {
  float: left;
  clear: none;
  width: 25%
}

.aem-Grid.aem-Grid--default--12>.aem-GridColumn.aem-GridColumn--default--4 {
  float: left;
  clear: none;
  width: 33.33333333%
}

.aem-Grid.aem-Grid--default--12>.aem-GridColumn.aem-GridColumn--default--5 {
  float: left;
  clear: none;
  width: 41.66666667%
}

.aem-Grid.aem-Grid--default--12>.aem-GridColumn.aem-GridColumn--default--6 {
  float: left;
  clear: none;
  width: 50%
}

.aem-Grid.aem-Grid--default--12>.aem-GridColumn.aem-GridColumn--default--7 {
  float: left;
  clear: none;
  width: 58.33333333%
}

.aem-Grid.aem-Grid--default--12>.aem-GridColumn.aem-GridColumn--default--8 {
  float: left;
  clear: none;
  width: 66.66666667%
}

.aem-Grid.aem-Grid--default--12>.aem-GridColumn.aem-GridColumn--default--9 {
  float: left;
  clear: none;
  width: 75%
}

.aem-Grid.aem-Grid--default--12>.aem-GridColumn.aem-GridColumn--default--10 {
  float: left;
  clear: none;
  width: 83.33333333%
}

.aem-Grid.aem-Grid--default--12>.aem-GridColumn.aem-GridColumn--default--11 {
  float: left;
  clear: none;
  width: 91.66666667%
}

.aem-Grid.aem-Grid--default--12>.aem-GridColumn.aem-GridColumn--default--12 {
  float: left;
  clear: none;
  width: 100%
}

.aem-Grid.aem-Grid--default--12>.aem-GridColumn.aem-GridColumn--offset--default--0 {
  margin-left: 0
}

.aem-Grid.aem-Grid--default--12>.aem-GridColumn.aem-GridColumn--offset--default--1 {
  margin-left: 8.33333333%
}

.aem-Grid.aem-Grid--default--12>.aem-GridColumn.aem-GridColumn--offset--default--2 {
  margin-left: 16.66666667%
}

.aem-Grid.aem-Grid--default--12>.aem-GridColumn.aem-GridColumn--offset--default--3 {
  margin-left: 25%
}

.aem-Grid.aem-Grid--default--12>.aem-GridColumn.aem-GridColumn--offset--default--4 {
  margin-left: 33.33333333%
}

.aem-Grid.aem-Grid--default--12>.aem-GridColumn.aem-GridColumn--offset--default--5 {
  margin-left: 41.66666667%
}

.aem-Grid.aem-Grid--default--12>.aem-GridColumn.aem-GridColumn--offset--default--6 {
  margin-left: 50%
}

.aem-Grid.aem-Grid--default--12>.aem-GridColumn.aem-GridColumn--offset--default--7 {
  margin-left: 58.33333333%
}

.aem-Grid.aem-Grid--default--12>.aem-GridColumn.aem-GridColumn--offset--default--8 {
  margin-left: 66.66666667%
}

.aem-Grid.aem-Grid--default--12>.aem-GridColumn.aem-GridColumn--offset--default--9 {
  margin-left: 75%
}

.aem-Grid.aem-Grid--default--12>.aem-GridColumn.aem-GridColumn--offset--default--10 {
  margin-left: 83.33333333%
}

.aem-Grid.aem-Grid--default--12>.aem-GridColumn.aem-GridColumn--offset--default--11 {
  margin-left: 91.66666667%
}

.aem-Grid.aem-Grid--default--12>.aem-GridColumn.aem-GridColumn--offset--default--12 {
  margin-left: 100%
}

.aem-Grid>.aem-GridColumn.aem-GridColumn--default--newline {
  display: block;
  clear: both !important
}

.aem-Grid>.aem-GridColumn.aem-GridColumn--default--none {
  display: block;
  clear: none !important;
  float: left
}

.aem-Grid>.aem-GridColumn.aem-GridColumn--default--hide {
  display: none
}

@media(max-width: 768px) {
  .aem-Grid.aem-Grid--1>.aem-GridColumn.aem-GridColumn--phone--1 {
    float: left;
    clear: none;
    width: 100%
  }

  .aem-Grid.aem-Grid--1>.aem-GridColumn.aem-GridColumn--offset--phone--0 {
    margin-left: 0
  }

  .aem-Grid.aem-Grid--1>.aem-GridColumn.aem-GridColumn--offset--phone--1 {
    margin-left: 100%
  }

  .aem-Grid.aem-Grid--2>.aem-GridColumn.aem-GridColumn--phone--1 {
    float: left;
    clear: none;
    width: 50%
  }

  .aem-Grid.aem-Grid--2>.aem-GridColumn.aem-GridColumn--phone--2 {
    float: left;
    clear: none;
    width: 100%
  }

  .aem-Grid.aem-Grid--2>.aem-GridColumn.aem-GridColumn--offset--phone--0 {
    margin-left: 0
  }

  .aem-Grid.aem-Grid--2>.aem-GridColumn.aem-GridColumn--offset--phone--1 {
    margin-left: 50%
  }

  .aem-Grid.aem-Grid--2>.aem-GridColumn.aem-GridColumn--offset--phone--2 {
    margin-left: 100%
  }

  .aem-Grid.aem-Grid--3>.aem-GridColumn.aem-GridColumn--phone--1 {
    float: left;
    clear: none;
    width: 33.33333333%
  }

  .aem-Grid.aem-Grid--3>.aem-GridColumn.aem-GridColumn--phone--2 {
    float: left;
    clear: none;
    width: 66.66666667%
  }

  .aem-Grid.aem-Grid--3>.aem-GridColumn.aem-GridColumn--phone--3 {
    float: left;
    clear: none;
    width: 100%
  }

  .aem-Grid.aem-Grid--3>.aem-GridColumn.aem-GridColumn--offset--phone--0 {
    margin-left: 0
  }

  .aem-Grid.aem-Grid--3>.aem-GridColumn.aem-GridColumn--offset--phone--1 {
    margin-left: 33.33333333%
  }

  .aem-Grid.aem-Grid--3>.aem-GridColumn.aem-GridColumn--offset--phone--2 {
    margin-left: 66.66666667%
  }

  .aem-Grid.aem-Grid--3>.aem-GridColumn.aem-GridColumn--offset--phone--3 {
    margin-left: 100%
  }

  .aem-Grid.aem-Grid--4>.aem-GridColumn.aem-GridColumn--phone--1 {
    float: left;
    clear: none;
    width: 25%
  }

  .aem-Grid.aem-Grid--4>.aem-GridColumn.aem-GridColumn--phone--2 {
    float: left;
    clear: none;
    width: 50%
  }

  .aem-Grid.aem-Grid--4>.aem-GridColumn.aem-GridColumn--phone--3 {
    float: left;
    clear: none;
    width: 75%
  }

  .aem-Grid.aem-Grid--4>.aem-GridColumn.aem-GridColumn--phone--4 {
    float: left;
    clear: none;
    width: 100%
  }

  .aem-Grid.aem-Grid--4>.aem-GridColumn.aem-GridColumn--offset--phone--0 {
    margin-left: 0
  }

  .aem-Grid.aem-Grid--4>.aem-GridColumn.aem-GridColumn--offset--phone--1 {
    margin-left: 25%
  }

  .aem-Grid.aem-Grid--4>.aem-GridColumn.aem-GridColumn--offset--phone--2 {
    margin-left: 50%
  }

  .aem-Grid.aem-Grid--4>.aem-GridColumn.aem-GridColumn--offset--phone--3 {
    margin-left: 75%
  }

  .aem-Grid.aem-Grid--4>.aem-GridColumn.aem-GridColumn--offset--phone--4 {
    margin-left: 100%
  }

  .aem-Grid.aem-Grid--5>.aem-GridColumn.aem-GridColumn--phone--1 {
    float: left;
    clear: none;
    width: 20%
  }

  .aem-Grid.aem-Grid--5>.aem-GridColumn.aem-GridColumn--phone--2 {
    float: left;
    clear: none;
    width: 40%
  }

  .aem-Grid.aem-Grid--5>.aem-GridColumn.aem-GridColumn--phone--3 {
    float: left;
    clear: none;
    width: 60%
  }

  .aem-Grid.aem-Grid--5>.aem-GridColumn.aem-GridColumn--phone--4 {
    float: left;
    clear: none;
    width: 80%
  }

  .aem-Grid.aem-Grid--5>.aem-GridColumn.aem-GridColumn--phone--5 {
    float: left;
    clear: none;
    width: 100%
  }

  .aem-Grid.aem-Grid--5>.aem-GridColumn.aem-GridColumn--offset--phone--0 {
    margin-left: 0
  }

  .aem-Grid.aem-Grid--5>.aem-GridColumn.aem-GridColumn--offset--phone--1 {
    margin-left: 20%
  }

  .aem-Grid.aem-Grid--5>.aem-GridColumn.aem-GridColumn--offset--phone--2 {
    margin-left: 40%
  }

  .aem-Grid.aem-Grid--5>.aem-GridColumn.aem-GridColumn--offset--phone--3 {
    margin-left: 60%
  }

  .aem-Grid.aem-Grid--5>.aem-GridColumn.aem-GridColumn--offset--phone--4 {
    margin-left: 80%
  }

  .aem-Grid.aem-Grid--5>.aem-GridColumn.aem-GridColumn--offset--phone--5 {
    margin-left: 100%
  }

  .aem-Grid.aem-Grid--6>.aem-GridColumn.aem-GridColumn--phone--1 {
    float: left;
    clear: none;
    width: 16.66666667%
  }

  .aem-Grid.aem-Grid--6>.aem-GridColumn.aem-GridColumn--phone--2 {
    float: left;
    clear: none;
    width: 33.33333333%
  }

  .aem-Grid.aem-Grid--6>.aem-GridColumn.aem-GridColumn--phone--3 {
    float: left;
    clear: none;
    width: 50%
  }

  .aem-Grid.aem-Grid--6>.aem-GridColumn.aem-GridColumn--phone--4 {
    float: left;
    clear: none;
    width: 66.66666667%
  }

  .aem-Grid.aem-Grid--6>.aem-GridColumn.aem-GridColumn--phone--5 {
    float: left;
    clear: none;
    width: 83.33333333%
  }

  .aem-Grid.aem-Grid--6>.aem-GridColumn.aem-GridColumn--phone--6 {
    float: left;
    clear: none;
    width: 100%
  }

  .aem-Grid.aem-Grid--6>.aem-GridColumn.aem-GridColumn--offset--phone--0 {
    margin-left: 0
  }

  .aem-Grid.aem-Grid--6>.aem-GridColumn.aem-GridColumn--offset--phone--1 {
    margin-left: 16.66666667%
  }

  .aem-Grid.aem-Grid--6>.aem-GridColumn.aem-GridColumn--offset--phone--2 {
    margin-left: 33.33333333%
  }

  .aem-Grid.aem-Grid--6>.aem-GridColumn.aem-GridColumn--offset--phone--3 {
    margin-left: 50%
  }

  .aem-Grid.aem-Grid--6>.aem-GridColumn.aem-GridColumn--offset--phone--4 {
    margin-left: 66.66666667%
  }

  .aem-Grid.aem-Grid--6>.aem-GridColumn.aem-GridColumn--offset--phone--5 {
    margin-left: 83.33333333%
  }

  .aem-Grid.aem-Grid--6>.aem-GridColumn.aem-GridColumn--offset--phone--6 {
    margin-left: 100%
  }

  .aem-Grid.aem-Grid--7>.aem-GridColumn.aem-GridColumn--phone--1 {
    float: left;
    clear: none;
    width: 14.28571429%
  }

  .aem-Grid.aem-Grid--7>.aem-GridColumn.aem-GridColumn--phone--2 {
    float: left;
    clear: none;
    width: 28.57142857%
  }

  .aem-Grid.aem-Grid--7>.aem-GridColumn.aem-GridColumn--phone--3 {
    float: left;
    clear: none;
    width: 42.85714286%
  }

  .aem-Grid.aem-Grid--7>.aem-GridColumn.aem-GridColumn--phone--4 {
    float: left;
    clear: none;
    width: 57.14285714%
  }

  .aem-Grid.aem-Grid--7>.aem-GridColumn.aem-GridColumn--phone--5 {
    float: left;
    clear: none;
    width: 71.42857143%
  }

  .aem-Grid.aem-Grid--7>.aem-GridColumn.aem-GridColumn--phone--6 {
    float: left;
    clear: none;
    width: 85.71428571%
  }

  .aem-Grid.aem-Grid--7>.aem-GridColumn.aem-GridColumn--phone--7 {
    float: left;
    clear: none;
    width: 100%
  }

  .aem-Grid.aem-Grid--7>.aem-GridColumn.aem-GridColumn--offset--phone--0 {
    margin-left: 0
  }

  .aem-Grid.aem-Grid--7>.aem-GridColumn.aem-GridColumn--offset--phone--1 {
    margin-left: 14.28571429%
  }

  .aem-Grid.aem-Grid--7>.aem-GridColumn.aem-GridColumn--offset--phone--2 {
    margin-left: 28.57142857%
  }

  .aem-Grid.aem-Grid--7>.aem-GridColumn.aem-GridColumn--offset--phone--3 {
    margin-left: 42.85714286%
  }

  .aem-Grid.aem-Grid--7>.aem-GridColumn.aem-GridColumn--offset--phone--4 {
    margin-left: 57.14285714%
  }

  .aem-Grid.aem-Grid--7>.aem-GridColumn.aem-GridColumn--offset--phone--5 {
    margin-left: 71.42857143%
  }

  .aem-Grid.aem-Grid--7>.aem-GridColumn.aem-GridColumn--offset--phone--6 {
    margin-left: 85.71428571%
  }

  .aem-Grid.aem-Grid--7>.aem-GridColumn.aem-GridColumn--offset--phone--7 {
    margin-left: 100%
  }

  .aem-Grid.aem-Grid--8>.aem-GridColumn.aem-GridColumn--phone--1 {
    float: left;
    clear: none;
    width: 12.5%
  }

  .aem-Grid.aem-Grid--8>.aem-GridColumn.aem-GridColumn--phone--2 {
    float: left;
    clear: none;
    width: 25%
  }

  .aem-Grid.aem-Grid--8>.aem-GridColumn.aem-GridColumn--phone--3 {
    float: left;
    clear: none;
    width: 37.5%
  }

  .aem-Grid.aem-Grid--8>.aem-GridColumn.aem-GridColumn--phone--4 {
    float: left;
    clear: none;
    width: 50%
  }

  .aem-Grid.aem-Grid--8>.aem-GridColumn.aem-GridColumn--phone--5 {
    float: left;
    clear: none;
    width: 62.5%
  }

  .aem-Grid.aem-Grid--8>.aem-GridColumn.aem-GridColumn--phone--6 {
    float: left;
    clear: none;
    width: 75%
  }

  .aem-Grid.aem-Grid--8>.aem-GridColumn.aem-GridColumn--phone--7 {
    float: left;
    clear: none;
    width: 87.5%
  }

  .aem-Grid.aem-Grid--8>.aem-GridColumn.aem-GridColumn--phone--8 {
    float: left;
    clear: none;
    width: 100%
  }

  .aem-Grid.aem-Grid--8>.aem-GridColumn.aem-GridColumn--offset--phone--0 {
    margin-left: 0
  }

  .aem-Grid.aem-Grid--8>.aem-GridColumn.aem-GridColumn--offset--phone--1 {
    margin-left: 12.5%
  }

  .aem-Grid.aem-Grid--8>.aem-GridColumn.aem-GridColumn--offset--phone--2 {
    margin-left: 25%
  }

  .aem-Grid.aem-Grid--8>.aem-GridColumn.aem-GridColumn--offset--phone--3 {
    margin-left: 37.5%
  }

  .aem-Grid.aem-Grid--8>.aem-GridColumn.aem-GridColumn--offset--phone--4 {
    margin-left: 50%
  }

  .aem-Grid.aem-Grid--8>.aem-GridColumn.aem-GridColumn--offset--phone--5 {
    margin-left: 62.5%
  }

  .aem-Grid.aem-Grid--8>.aem-GridColumn.aem-GridColumn--offset--phone--6 {
    margin-left: 75%
  }

  .aem-Grid.aem-Grid--8>.aem-GridColumn.aem-GridColumn--offset--phone--7 {
    margin-left: 87.5%
  }

  .aem-Grid.aem-Grid--8>.aem-GridColumn.aem-GridColumn--offset--phone--8 {
    margin-left: 100%
  }

  .aem-Grid.aem-Grid--9>.aem-GridColumn.aem-GridColumn--phone--1 {
    float: left;
    clear: none;
    width: 11.11111111%
  }

  .aem-Grid.aem-Grid--9>.aem-GridColumn.aem-GridColumn--phone--2 {
    float: left;
    clear: none;
    width: 22.22222222%
  }

  .aem-Grid.aem-Grid--9>.aem-GridColumn.aem-GridColumn--phone--3 {
    float: left;
    clear: none;
    width: 33.33333333%
  }

  .aem-Grid.aem-Grid--9>.aem-GridColumn.aem-GridColumn--phone--4 {
    float: left;
    clear: none;
    width: 44.44444444%
  }

  .aem-Grid.aem-Grid--9>.aem-GridColumn.aem-GridColumn--phone--5 {
    float: left;
    clear: none;
    width: 55.55555556%
  }

  .aem-Grid.aem-Grid--9>.aem-GridColumn.aem-GridColumn--phone--6 {
    float: left;
    clear: none;
    width: 66.66666667%
  }

  .aem-Grid.aem-Grid--9>.aem-GridColumn.aem-GridColumn--phone--7 {
    float: left;
    clear: none;
    width: 77.77777778%
  }

  .aem-Grid.aem-Grid--9>.aem-GridColumn.aem-GridColumn--phone--8 {
    float: left;
    clear: none;
    width: 88.88888889%
  }

  .aem-Grid.aem-Grid--9>.aem-GridColumn.aem-GridColumn--phone--9 {
    float: left;
    clear: none;
    width: 100%
  }

  .aem-Grid.aem-Grid--9>.aem-GridColumn.aem-GridColumn--offset--phone--0 {
    margin-left: 0
  }

  .aem-Grid.aem-Grid--9>.aem-GridColumn.aem-GridColumn--offset--phone--1 {
    margin-left: 11.11111111%
  }

  .aem-Grid.aem-Grid--9>.aem-GridColumn.aem-GridColumn--offset--phone--2 {
    margin-left: 22.22222222%
  }

  .aem-Grid.aem-Grid--9>.aem-GridColumn.aem-GridColumn--offset--phone--3 {
    margin-left: 33.33333333%
  }

  .aem-Grid.aem-Grid--9>.aem-GridColumn.aem-GridColumn--offset--phone--4 {
    margin-left: 44.44444444%
  }

  .aem-Grid.aem-Grid--9>.aem-GridColumn.aem-GridColumn--offset--phone--5 {
    margin-left: 55.55555556%
  }

  .aem-Grid.aem-Grid--9>.aem-GridColumn.aem-GridColumn--offset--phone--6 {
    margin-left: 66.66666667%
  }

  .aem-Grid.aem-Grid--9>.aem-GridColumn.aem-GridColumn--offset--phone--7 {
    margin-left: 77.77777778%
  }

  .aem-Grid.aem-Grid--9>.aem-GridColumn.aem-GridColumn--offset--phone--8 {
    margin-left: 88.88888889%
  }

  .aem-Grid.aem-Grid--9>.aem-GridColumn.aem-GridColumn--offset--phone--9 {
    margin-left: 100%
  }

  .aem-Grid.aem-Grid--10>.aem-GridColumn.aem-GridColumn--phone--1 {
    float: left;
    clear: none;
    width: 10%
  }

  .aem-Grid.aem-Grid--10>.aem-GridColumn.aem-GridColumn--phone--2 {
    float: left;
    clear: none;
    width: 20%
  }

  .aem-Grid.aem-Grid--10>.aem-GridColumn.aem-GridColumn--phone--3 {
    float: left;
    clear: none;
    width: 30%
  }

  .aem-Grid.aem-Grid--10>.aem-GridColumn.aem-GridColumn--phone--4 {
    float: left;
    clear: none;
    width: 40%
  }

  .aem-Grid.aem-Grid--10>.aem-GridColumn.aem-GridColumn--phone--5 {
    float: left;
    clear: none;
    width: 50%
  }

  .aem-Grid.aem-Grid--10>.aem-GridColumn.aem-GridColumn--phone--6 {
    float: left;
    clear: none;
    width: 60%
  }

  .aem-Grid.aem-Grid--10>.aem-GridColumn.aem-GridColumn--phone--7 {
    float: left;
    clear: none;
    width: 70%
  }

  .aem-Grid.aem-Grid--10>.aem-GridColumn.aem-GridColumn--phone--8 {
    float: left;
    clear: none;
    width: 80%
  }

  .aem-Grid.aem-Grid--10>.aem-GridColumn.aem-GridColumn--phone--9 {
    float: left;
    clear: none;
    width: 90%
  }

  .aem-Grid.aem-Grid--10>.aem-GridColumn.aem-GridColumn--phone--10 {
    float: left;
    clear: none;
    width: 100%
  }

  .aem-Grid.aem-Grid--10>.aem-GridColumn.aem-GridColumn--offset--phone--0 {
    margin-left: 0
  }

  .aem-Grid.aem-Grid--10>.aem-GridColumn.aem-GridColumn--offset--phone--1 {
    margin-left: 10%
  }

  .aem-Grid.aem-Grid--10>.aem-GridColumn.aem-GridColumn--offset--phone--2 {
    margin-left: 20%
  }

  .aem-Grid.aem-Grid--10>.aem-GridColumn.aem-GridColumn--offset--phone--3 {
    margin-left: 30%
  }

  .aem-Grid.aem-Grid--10>.aem-GridColumn.aem-GridColumn--offset--phone--4 {
    margin-left: 40%
  }

  .aem-Grid.aem-Grid--10>.aem-GridColumn.aem-GridColumn--offset--phone--5 {
    margin-left: 50%
  }

  .aem-Grid.aem-Grid--10>.aem-GridColumn.aem-GridColumn--offset--phone--6 {
    margin-left: 60%
  }

  .aem-Grid.aem-Grid--10>.aem-GridColumn.aem-GridColumn--offset--phone--7 {
    margin-left: 70%
  }

  .aem-Grid.aem-Grid--10>.aem-GridColumn.aem-GridColumn--offset--phone--8 {
    margin-left: 80%
  }

  .aem-Grid.aem-Grid--10>.aem-GridColumn.aem-GridColumn--offset--phone--9 {
    margin-left: 90%
  }

  .aem-Grid.aem-Grid--10>.aem-GridColumn.aem-GridColumn--offset--phone--10 {
    margin-left: 100%
  }

  .aem-Grid.aem-Grid--11>.aem-GridColumn.aem-GridColumn--phone--1 {
    float: left;
    clear: none;
    width: 9.09090909%
  }

  .aem-Grid.aem-Grid--11>.aem-GridColumn.aem-GridColumn--phone--2 {
    float: left;
    clear: none;
    width: 18.18181818%
  }

  .aem-Grid.aem-Grid--11>.aem-GridColumn.aem-GridColumn--phone--3 {
    float: left;
    clear: none;
    width: 27.27272727%
  }

  .aem-Grid.aem-Grid--11>.aem-GridColumn.aem-GridColumn--phone--4 {
    float: left;
    clear: none;
    width: 36.36363636%
  }

  .aem-Grid.aem-Grid--11>.aem-GridColumn.aem-GridColumn--phone--5 {
    float: left;
    clear: none;
    width: 45.45454545%
  }

  .aem-Grid.aem-Grid--11>.aem-GridColumn.aem-GridColumn--phone--6 {
    float: left;
    clear: none;
    width: 54.54545455%
  }

  .aem-Grid.aem-Grid--11>.aem-GridColumn.aem-GridColumn--phone--7 {
    float: left;
    clear: none;
    width: 63.63636364%
  }

  .aem-Grid.aem-Grid--11>.aem-GridColumn.aem-GridColumn--phone--8 {
    float: left;
    clear: none;
    width: 72.72727273%
  }

  .aem-Grid.aem-Grid--11>.aem-GridColumn.aem-GridColumn--phone--9 {
    float: left;
    clear: none;
    width: 81.81818182%
  }

  .aem-Grid.aem-Grid--11>.aem-GridColumn.aem-GridColumn--phone--10 {
    float: left;
    clear: none;
    width: 90.90909091%
  }

  .aem-Grid.aem-Grid--11>.aem-GridColumn.aem-GridColumn--phone--11 {
    float: left;
    clear: none;
    width: 100%
  }

  .aem-Grid.aem-Grid--11>.aem-GridColumn.aem-GridColumn--offset--phone--0 {
    margin-left: 0
  }

  .aem-Grid.aem-Grid--11>.aem-GridColumn.aem-GridColumn--offset--phone--1 {
    margin-left: 9.09090909%
  }

  .aem-Grid.aem-Grid--11>.aem-GridColumn.aem-GridColumn--offset--phone--2 {
    margin-left: 18.18181818%
  }

  .aem-Grid.aem-Grid--11>.aem-GridColumn.aem-GridColumn--offset--phone--3 {
    margin-left: 27.27272727%
  }

  .aem-Grid.aem-Grid--11>.aem-GridColumn.aem-GridColumn--offset--phone--4 {
    margin-left: 36.36363636%
  }

  .aem-Grid.aem-Grid--11>.aem-GridColumn.aem-GridColumn--offset--phone--5 {
    margin-left: 45.45454545%
  }

  .aem-Grid.aem-Grid--11>.aem-GridColumn.aem-GridColumn--offset--phone--6 {
    margin-left: 54.54545455%
  }

  .aem-Grid.aem-Grid--11>.aem-GridColumn.aem-GridColumn--offset--phone--7 {
    margin-left: 63.63636364%
  }

  .aem-Grid.aem-Grid--11>.aem-GridColumn.aem-GridColumn--offset--phone--8 {
    margin-left: 72.72727273%
  }

  .aem-Grid.aem-Grid--11>.aem-GridColumn.aem-GridColumn--offset--phone--9 {
    margin-left: 81.81818182%
  }

  .aem-Grid.aem-Grid--11>.aem-GridColumn.aem-GridColumn--offset--phone--10 {
    margin-left: 90.90909091%
  }

  .aem-Grid.aem-Grid--11>.aem-GridColumn.aem-GridColumn--offset--phone--11 {
    margin-left: 100%
  }

  .aem-Grid.aem-Grid--12>.aem-GridColumn.aem-GridColumn--phone--1 {
    float: left;
    clear: none;
    width: 8.33333333%
  }

  .aem-Grid.aem-Grid--12>.aem-GridColumn.aem-GridColumn--phone--2 {
    float: left;
    clear: none;
    width: 16.66666667%
  }

  .aem-Grid.aem-Grid--12>.aem-GridColumn.aem-GridColumn--phone--3 {
    float: left;
    clear: none;
    width: 25%
  }

  .aem-Grid.aem-Grid--12>.aem-GridColumn.aem-GridColumn--phone--4 {
    float: left;
    clear: none;
    width: 33.33333333%
  }

  .aem-Grid.aem-Grid--12>.aem-GridColumn.aem-GridColumn--phone--5 {
    float: left;
    clear: none;
    width: 41.66666667%
  }

  .aem-Grid.aem-Grid--12>.aem-GridColumn.aem-GridColumn--phone--6 {
    float: left;
    clear: none;
    width: 50%
  }

  .aem-Grid.aem-Grid--12>.aem-GridColumn.aem-GridColumn--phone--7 {
    float: left;
    clear: none;
    width: 58.33333333%
  }

  .aem-Grid.aem-Grid--12>.aem-GridColumn.aem-GridColumn--phone--8 {
    float: left;
    clear: none;
    width: 66.66666667%
  }

  .aem-Grid.aem-Grid--12>.aem-GridColumn.aem-GridColumn--phone--9 {
    float: left;
    clear: none;
    width: 75%
  }

  .aem-Grid.aem-Grid--12>.aem-GridColumn.aem-GridColumn--phone--10 {
    float: left;
    clear: none;
    width: 83.33333333%
  }

  .aem-Grid.aem-Grid--12>.aem-GridColumn.aem-GridColumn--phone--11 {
    float: left;
    clear: none;
    width: 91.66666667%
  }

  .aem-Grid.aem-Grid--12>.aem-GridColumn.aem-GridColumn--phone--12 {
    float: left;
    clear: none;
    width: 100%
  }

  .aem-Grid.aem-Grid--12>.aem-GridColumn.aem-GridColumn--offset--phone--0 {
    margin-left: 0
  }

  .aem-Grid.aem-Grid--12>.aem-GridColumn.aem-GridColumn--offset--phone--1 {
    margin-left: 8.33333333%
  }

  .aem-Grid.aem-Grid--12>.aem-GridColumn.aem-GridColumn--offset--phone--2 {
    margin-left: 16.66666667%
  }

  .aem-Grid.aem-Grid--12>.aem-GridColumn.aem-GridColumn--offset--phone--3 {
    margin-left: 25%
  }

  .aem-Grid.aem-Grid--12>.aem-GridColumn.aem-GridColumn--offset--phone--4 {
    margin-left: 33.33333333%
  }

  .aem-Grid.aem-Grid--12>.aem-GridColumn.aem-GridColumn--offset--phone--5 {
    margin-left: 41.66666667%
  }

  .aem-Grid.aem-Grid--12>.aem-GridColumn.aem-GridColumn--offset--phone--6 {
    margin-left: 50%
  }

  .aem-Grid.aem-Grid--12>.aem-GridColumn.aem-GridColumn--offset--phone--7 {
    margin-left: 58.33333333%
  }

  .aem-Grid.aem-Grid--12>.aem-GridColumn.aem-GridColumn--offset--phone--8 {
    margin-left: 66.66666667%
  }

  .aem-Grid.aem-Grid--12>.aem-GridColumn.aem-GridColumn--offset--phone--9 {
    margin-left: 75%
  }

  .aem-Grid.aem-Grid--12>.aem-GridColumn.aem-GridColumn--offset--phone--10 {
    margin-left: 83.33333333%
  }

  .aem-Grid.aem-Grid--12>.aem-GridColumn.aem-GridColumn--offset--phone--11 {
    margin-left: 91.66666667%
  }

  .aem-Grid.aem-Grid--12>.aem-GridColumn.aem-GridColumn--offset--phone--12 {
    margin-left: 100%
  }

  .aem-Grid.aem-Grid--phone--1>.aem-GridColumn.aem-GridColumn--phone--1 {
    float: left;
    clear: none;
    width: 100%
  }

  .aem-Grid.aem-Grid--phone--1>.aem-GridColumn.aem-GridColumn--offset--phone--0 {
    margin-left: 0
  }

  .aem-Grid.aem-Grid--phone--1>.aem-GridColumn.aem-GridColumn--offset--phone--1 {
    margin-left: 100%
  }

  .aem-Grid.aem-Grid--phone--2>.aem-GridColumn.aem-GridColumn--phone--1 {
    float: left;
    clear: none;
    width: 50%
  }

  .aem-Grid.aem-Grid--phone--2>.aem-GridColumn.aem-GridColumn--phone--2 {
    float: left;
    clear: none;
    width: 100%
  }

  .aem-Grid.aem-Grid--phone--2>.aem-GridColumn.aem-GridColumn--offset--phone--0 {
    margin-left: 0
  }

  .aem-Grid.aem-Grid--phone--2>.aem-GridColumn.aem-GridColumn--offset--phone--1 {
    margin-left: 50%
  }

  .aem-Grid.aem-Grid--phone--2>.aem-GridColumn.aem-GridColumn--offset--phone--2 {
    margin-left: 100%
  }

  .aem-Grid.aem-Grid--phone--3>.aem-GridColumn.aem-GridColumn--phone--1 {
    float: left;
    clear: none;
    width: 33.33333333%
  }

  .aem-Grid.aem-Grid--phone--3>.aem-GridColumn.aem-GridColumn--phone--2 {
    float: left;
    clear: none;
    width: 66.66666667%
  }

  .aem-Grid.aem-Grid--phone--3>.aem-GridColumn.aem-GridColumn--phone--3 {
    float: left;
    clear: none;
    width: 100%
  }

  .aem-Grid.aem-Grid--phone--3>.aem-GridColumn.aem-GridColumn--offset--phone--0 {
    margin-left: 0
  }

  .aem-Grid.aem-Grid--phone--3>.aem-GridColumn.aem-GridColumn--offset--phone--1 {
    margin-left: 33.33333333%
  }

  .aem-Grid.aem-Grid--phone--3>.aem-GridColumn.aem-GridColumn--offset--phone--2 {
    margin-left: 66.66666667%
  }

  .aem-Grid.aem-Grid--phone--3>.aem-GridColumn.aem-GridColumn--offset--phone--3 {
    margin-left: 100%
  }

  .aem-Grid.aem-Grid--phone--4>.aem-GridColumn.aem-GridColumn--phone--1 {
    float: left;
    clear: none;
    width: 25%
  }

  .aem-Grid.aem-Grid--phone--4>.aem-GridColumn.aem-GridColumn--phone--2 {
    float: left;
    clear: none;
    width: 50%
  }

  .aem-Grid.aem-Grid--phone--4>.aem-GridColumn.aem-GridColumn--phone--3 {
    float: left;
    clear: none;
    width: 75%
  }

  .aem-Grid.aem-Grid--phone--4>.aem-GridColumn.aem-GridColumn--phone--4 {
    float: left;
    clear: none;
    width: 100%
  }

  .aem-Grid.aem-Grid--phone--4>.aem-GridColumn.aem-GridColumn--offset--phone--0 {
    margin-left: 0
  }

  .aem-Grid.aem-Grid--phone--4>.aem-GridColumn.aem-GridColumn--offset--phone--1 {
    margin-left: 25%
  }

  .aem-Grid.aem-Grid--phone--4>.aem-GridColumn.aem-GridColumn--offset--phone--2 {
    margin-left: 50%
  }

  .aem-Grid.aem-Grid--phone--4>.aem-GridColumn.aem-GridColumn--offset--phone--3 {
    margin-left: 75%
  }

  .aem-Grid.aem-Grid--phone--4>.aem-GridColumn.aem-GridColumn--offset--phone--4 {
    margin-left: 100%
  }

  .aem-Grid.aem-Grid--phone--5>.aem-GridColumn.aem-GridColumn--phone--1 {
    float: left;
    clear: none;
    width: 20%
  }

  .aem-Grid.aem-Grid--phone--5>.aem-GridColumn.aem-GridColumn--phone--2 {
    float: left;
    clear: none;
    width: 40%
  }

  .aem-Grid.aem-Grid--phone--5>.aem-GridColumn.aem-GridColumn--phone--3 {
    float: left;
    clear: none;
    width: 60%
  }

  .aem-Grid.aem-Grid--phone--5>.aem-GridColumn.aem-GridColumn--phone--4 {
    float: left;
    clear: none;
    width: 80%
  }

  .aem-Grid.aem-Grid--phone--5>.aem-GridColumn.aem-GridColumn--phone--5 {
    float: left;
    clear: none;
    width: 100%
  }

  .aem-Grid.aem-Grid--phone--5>.aem-GridColumn.aem-GridColumn--offset--phone--0 {
    margin-left: 0
  }

  .aem-Grid.aem-Grid--phone--5>.aem-GridColumn.aem-GridColumn--offset--phone--1 {
    margin-left: 20%
  }

  .aem-Grid.aem-Grid--phone--5>.aem-GridColumn.aem-GridColumn--offset--phone--2 {
    margin-left: 40%
  }

  .aem-Grid.aem-Grid--phone--5>.aem-GridColumn.aem-GridColumn--offset--phone--3 {
    margin-left: 60%
  }

  .aem-Grid.aem-Grid--phone--5>.aem-GridColumn.aem-GridColumn--offset--phone--4 {
    margin-left: 80%
  }

  .aem-Grid.aem-Grid--phone--5>.aem-GridColumn.aem-GridColumn--offset--phone--5 {
    margin-left: 100%
  }

  .aem-Grid.aem-Grid--phone--6>.aem-GridColumn.aem-GridColumn--phone--1 {
    float: left;
    clear: none;
    width: 16.66666667%
  }

  .aem-Grid.aem-Grid--phone--6>.aem-GridColumn.aem-GridColumn--phone--2 {
    float: left;
    clear: none;
    width: 33.33333333%
  }

  .aem-Grid.aem-Grid--phone--6>.aem-GridColumn.aem-GridColumn--phone--3 {
    float: left;
    clear: none;
    width: 50%
  }

  .aem-Grid.aem-Grid--phone--6>.aem-GridColumn.aem-GridColumn--phone--4 {
    float: left;
    clear: none;
    width: 66.66666667%
  }

  .aem-Grid.aem-Grid--phone--6>.aem-GridColumn.aem-GridColumn--phone--5 {
    float: left;
    clear: none;
    width: 83.33333333%
  }

  .aem-Grid.aem-Grid--phone--6>.aem-GridColumn.aem-GridColumn--phone--6 {
    float: left;
    clear: none;
    width: 100%
  }

  .aem-Grid.aem-Grid--phone--6>.aem-GridColumn.aem-GridColumn--offset--phone--0 {
    margin-left: 0
  }

  .aem-Grid.aem-Grid--phone--6>.aem-GridColumn.aem-GridColumn--offset--phone--1 {
    margin-left: 16.66666667%
  }

  .aem-Grid.aem-Grid--phone--6>.aem-GridColumn.aem-GridColumn--offset--phone--2 {
    margin-left: 33.33333333%
  }

  .aem-Grid.aem-Grid--phone--6>.aem-GridColumn.aem-GridColumn--offset--phone--3 {
    margin-left: 50%
  }

  .aem-Grid.aem-Grid--phone--6>.aem-GridColumn.aem-GridColumn--offset--phone--4 {
    margin-left: 66.66666667%
  }

  .aem-Grid.aem-Grid--phone--6>.aem-GridColumn.aem-GridColumn--offset--phone--5 {
    margin-left: 83.33333333%
  }

  .aem-Grid.aem-Grid--phone--6>.aem-GridColumn.aem-GridColumn--offset--phone--6 {
    margin-left: 100%
  }

  .aem-Grid.aem-Grid--phone--7>.aem-GridColumn.aem-GridColumn--phone--1 {
    float: left;
    clear: none;
    width: 14.28571429%
  }

  .aem-Grid.aem-Grid--phone--7>.aem-GridColumn.aem-GridColumn--phone--2 {
    float: left;
    clear: none;
    width: 28.57142857%
  }

  .aem-Grid.aem-Grid--phone--7>.aem-GridColumn.aem-GridColumn--phone--3 {
    float: left;
    clear: none;
    width: 42.85714286%
  }

  .aem-Grid.aem-Grid--phone--7>.aem-GridColumn.aem-GridColumn--phone--4 {
    float: left;
    clear: none;
    width: 57.14285714%
  }

  .aem-Grid.aem-Grid--phone--7>.aem-GridColumn.aem-GridColumn--phone--5 {
    float: left;
    clear: none;
    width: 71.42857143%
  }

  .aem-Grid.aem-Grid--phone--7>.aem-GridColumn.aem-GridColumn--phone--6 {
    float: left;
    clear: none;
    width: 85.71428571%
  }

  .aem-Grid.aem-Grid--phone--7>.aem-GridColumn.aem-GridColumn--phone--7 {
    float: left;
    clear: none;
    width: 100%
  }

  .aem-Grid.aem-Grid--phone--7>.aem-GridColumn.aem-GridColumn--offset--phone--0 {
    margin-left: 0
  }

  .aem-Grid.aem-Grid--phone--7>.aem-GridColumn.aem-GridColumn--offset--phone--1 {
    margin-left: 14.28571429%
  }

  .aem-Grid.aem-Grid--phone--7>.aem-GridColumn.aem-GridColumn--offset--phone--2 {
    margin-left: 28.57142857%
  }

  .aem-Grid.aem-Grid--phone--7>.aem-GridColumn.aem-GridColumn--offset--phone--3 {
    margin-left: 42.85714286%
  }

  .aem-Grid.aem-Grid--phone--7>.aem-GridColumn.aem-GridColumn--offset--phone--4 {
    margin-left: 57.14285714%
  }

  .aem-Grid.aem-Grid--phone--7>.aem-GridColumn.aem-GridColumn--offset--phone--5 {
    margin-left: 71.42857143%
  }

  .aem-Grid.aem-Grid--phone--7>.aem-GridColumn.aem-GridColumn--offset--phone--6 {
    margin-left: 85.71428571%
  }

  .aem-Grid.aem-Grid--phone--7>.aem-GridColumn.aem-GridColumn--offset--phone--7 {
    margin-left: 100%
  }

  .aem-Grid.aem-Grid--phone--8>.aem-GridColumn.aem-GridColumn--phone--1 {
    float: left;
    clear: none;
    width: 12.5%
  }

  .aem-Grid.aem-Grid--phone--8>.aem-GridColumn.aem-GridColumn--phone--2 {
    float: left;
    clear: none;
    width: 25%
  }

  .aem-Grid.aem-Grid--phone--8>.aem-GridColumn.aem-GridColumn--phone--3 {
    float: left;
    clear: none;
    width: 37.5%
  }

  .aem-Grid.aem-Grid--phone--8>.aem-GridColumn.aem-GridColumn--phone--4 {
    float: left;
    clear: none;
    width: 50%
  }

  .aem-Grid.aem-Grid--phone--8>.aem-GridColumn.aem-GridColumn--phone--5 {
    float: left;
    clear: none;
    width: 62.5%
  }

  .aem-Grid.aem-Grid--phone--8>.aem-GridColumn.aem-GridColumn--phone--6 {
    float: left;
    clear: none;
    width: 75%
  }

  .aem-Grid.aem-Grid--phone--8>.aem-GridColumn.aem-GridColumn--phone--7 {
    float: left;
    clear: none;
    width: 87.5%
  }

  .aem-Grid.aem-Grid--phone--8>.aem-GridColumn.aem-GridColumn--phone--8 {
    float: left;
    clear: none;
    width: 100%
  }

  .aem-Grid.aem-Grid--phone--8>.aem-GridColumn.aem-GridColumn--offset--phone--0 {
    margin-left: 0
  }

  .aem-Grid.aem-Grid--phone--8>.aem-GridColumn.aem-GridColumn--offset--phone--1 {
    margin-left: 12.5%
  }

  .aem-Grid.aem-Grid--phone--8>.aem-GridColumn.aem-GridColumn--offset--phone--2 {
    margin-left: 25%
  }

  .aem-Grid.aem-Grid--phone--8>.aem-GridColumn.aem-GridColumn--offset--phone--3 {
    margin-left: 37.5%
  }

  .aem-Grid.aem-Grid--phone--8>.aem-GridColumn.aem-GridColumn--offset--phone--4 {
    margin-left: 50%
  }

  .aem-Grid.aem-Grid--phone--8>.aem-GridColumn.aem-GridColumn--offset--phone--5 {
    margin-left: 62.5%
  }

  .aem-Grid.aem-Grid--phone--8>.aem-GridColumn.aem-GridColumn--offset--phone--6 {
    margin-left: 75%
  }

  .aem-Grid.aem-Grid--phone--8>.aem-GridColumn.aem-GridColumn--offset--phone--7 {
    margin-left: 87.5%
  }

  .aem-Grid.aem-Grid--phone--8>.aem-GridColumn.aem-GridColumn--offset--phone--8 {
    margin-left: 100%
  }

  .aem-Grid.aem-Grid--phone--9>.aem-GridColumn.aem-GridColumn--phone--1 {
    float: left;
    clear: none;
    width: 11.11111111%
  }

  .aem-Grid.aem-Grid--phone--9>.aem-GridColumn.aem-GridColumn--phone--2 {
    float: left;
    clear: none;
    width: 22.22222222%
  }

  .aem-Grid.aem-Grid--phone--9>.aem-GridColumn.aem-GridColumn--phone--3 {
    float: left;
    clear: none;
    width: 33.33333333%
  }

  .aem-Grid.aem-Grid--phone--9>.aem-GridColumn.aem-GridColumn--phone--4 {
    float: left;
    clear: none;
    width: 44.44444444%
  }

  .aem-Grid.aem-Grid--phone--9>.aem-GridColumn.aem-GridColumn--phone--5 {
    float: left;
    clear: none;
    width: 55.55555556%
  }

  .aem-Grid.aem-Grid--phone--9>.aem-GridColumn.aem-GridColumn--phone--6 {
    float: left;
    clear: none;
    width: 66.66666667%
  }

  .aem-Grid.aem-Grid--phone--9>.aem-GridColumn.aem-GridColumn--phone--7 {
    float: left;
    clear: none;
    width: 77.77777778%
  }

  .aem-Grid.aem-Grid--phone--9>.aem-GridColumn.aem-GridColumn--phone--8 {
    float: left;
    clear: none;
    width: 88.88888889%
  }

  .aem-Grid.aem-Grid--phone--9>.aem-GridColumn.aem-GridColumn--phone--9 {
    float: left;
    clear: none;
    width: 100%
  }

  .aem-Grid.aem-Grid--phone--9>.aem-GridColumn.aem-GridColumn--offset--phone--0 {
    margin-left: 0
  }

  .aem-Grid.aem-Grid--phone--9>.aem-GridColumn.aem-GridColumn--offset--phone--1 {
    margin-left: 11.11111111%
  }

  .aem-Grid.aem-Grid--phone--9>.aem-GridColumn.aem-GridColumn--offset--phone--2 {
    margin-left: 22.22222222%
  }

  .aem-Grid.aem-Grid--phone--9>.aem-GridColumn.aem-GridColumn--offset--phone--3 {
    margin-left: 33.33333333%
  }

  .aem-Grid.aem-Grid--phone--9>.aem-GridColumn.aem-GridColumn--offset--phone--4 {
    margin-left: 44.44444444%
  }

  .aem-Grid.aem-Grid--phone--9>.aem-GridColumn.aem-GridColumn--offset--phone--5 {
    margin-left: 55.55555556%
  }

  .aem-Grid.aem-Grid--phone--9>.aem-GridColumn.aem-GridColumn--offset--phone--6 {
    margin-left: 66.66666667%
  }

  .aem-Grid.aem-Grid--phone--9>.aem-GridColumn.aem-GridColumn--offset--phone--7 {
    margin-left: 77.77777778%
  }

  .aem-Grid.aem-Grid--phone--9>.aem-GridColumn.aem-GridColumn--offset--phone--8 {
    margin-left: 88.88888889%
  }

  .aem-Grid.aem-Grid--phone--9>.aem-GridColumn.aem-GridColumn--offset--phone--9 {
    margin-left: 100%
  }

  .aem-Grid.aem-Grid--phone--10>.aem-GridColumn.aem-GridColumn--phone--1 {
    float: left;
    clear: none;
    width: 10%
  }

  .aem-Grid.aem-Grid--phone--10>.aem-GridColumn.aem-GridColumn--phone--2 {
    float: left;
    clear: none;
    width: 20%
  }

  .aem-Grid.aem-Grid--phone--10>.aem-GridColumn.aem-GridColumn--phone--3 {
    float: left;
    clear: none;
    width: 30%
  }

  .aem-Grid.aem-Grid--phone--10>.aem-GridColumn.aem-GridColumn--phone--4 {
    float: left;
    clear: none;
    width: 40%
  }

  .aem-Grid.aem-Grid--phone--10>.aem-GridColumn.aem-GridColumn--phone--5 {
    float: left;
    clear: none;
    width: 50%
  }

  .aem-Grid.aem-Grid--phone--10>.aem-GridColumn.aem-GridColumn--phone--6 {
    float: left;
    clear: none;
    width: 60%
  }

  .aem-Grid.aem-Grid--phone--10>.aem-GridColumn.aem-GridColumn--phone--7 {
    float: left;
    clear: none;
    width: 70%
  }

  .aem-Grid.aem-Grid--phone--10>.aem-GridColumn.aem-GridColumn--phone--8 {
    float: left;
    clear: none;
    width: 80%
  }

  .aem-Grid.aem-Grid--phone--10>.aem-GridColumn.aem-GridColumn--phone--9 {
    float: left;
    clear: none;
    width: 90%
  }

  .aem-Grid.aem-Grid--phone--10>.aem-GridColumn.aem-GridColumn--phone--10 {
    float: left;
    clear: none;
    width: 100%
  }

  .aem-Grid.aem-Grid--phone--10>.aem-GridColumn.aem-GridColumn--offset--phone--0 {
    margin-left: 0
  }

  .aem-Grid.aem-Grid--phone--10>.aem-GridColumn.aem-GridColumn--offset--phone--1 {
    margin-left: 10%
  }

  .aem-Grid.aem-Grid--phone--10>.aem-GridColumn.aem-GridColumn--offset--phone--2 {
    margin-left: 20%
  }

  .aem-Grid.aem-Grid--phone--10>.aem-GridColumn.aem-GridColumn--offset--phone--3 {
    margin-left: 30%
  }

  .aem-Grid.aem-Grid--phone--10>.aem-GridColumn.aem-GridColumn--offset--phone--4 {
    margin-left: 40%
  }

  .aem-Grid.aem-Grid--phone--10>.aem-GridColumn.aem-GridColumn--offset--phone--5 {
    margin-left: 50%
  }

  .aem-Grid.aem-Grid--phone--10>.aem-GridColumn.aem-GridColumn--offset--phone--6 {
    margin-left: 60%
  }

  .aem-Grid.aem-Grid--phone--10>.aem-GridColumn.aem-GridColumn--offset--phone--7 {
    margin-left: 70%
  }

  .aem-Grid.aem-Grid--phone--10>.aem-GridColumn.aem-GridColumn--offset--phone--8 {
    margin-left: 80%
  }

  .aem-Grid.aem-Grid--phone--10>.aem-GridColumn.aem-GridColumn--offset--phone--9 {
    margin-left: 90%
  }

  .aem-Grid.aem-Grid--phone--10>.aem-GridColumn.aem-GridColumn--offset--phone--10 {
    margin-left: 100%
  }

  .aem-Grid.aem-Grid--phone--11>.aem-GridColumn.aem-GridColumn--phone--1 {
    float: left;
    clear: none;
    width: 9.09090909%
  }

  .aem-Grid.aem-Grid--phone--11>.aem-GridColumn.aem-GridColumn--phone--2 {
    float: left;
    clear: none;
    width: 18.18181818%
  }

  .aem-Grid.aem-Grid--phone--11>.aem-GridColumn.aem-GridColumn--phone--3 {
    float: left;
    clear: none;
    width: 27.27272727%
  }

  .aem-Grid.aem-Grid--phone--11>.aem-GridColumn.aem-GridColumn--phone--4 {
    float: left;
    clear: none;
    width: 36.36363636%
  }

  .aem-Grid.aem-Grid--phone--11>.aem-GridColumn.aem-GridColumn--phone--5 {
    float: left;
    clear: none;
    width: 45.45454545%
  }

  .aem-Grid.aem-Grid--phone--11>.aem-GridColumn.aem-GridColumn--phone--6 {
    float: left;
    clear: none;
    width: 54.54545455%
  }

  .aem-Grid.aem-Grid--phone--11>.aem-GridColumn.aem-GridColumn--phone--7 {
    float: left;
    clear: none;
    width: 63.63636364%
  }

  .aem-Grid.aem-Grid--phone--11>.aem-GridColumn.aem-GridColumn--phone--8 {
    float: left;
    clear: none;
    width: 72.72727273%
  }

  .aem-Grid.aem-Grid--phone--11>.aem-GridColumn.aem-GridColumn--phone--9 {
    float: left;
    clear: none;
    width: 81.81818182%
  }

  .aem-Grid.aem-Grid--phone--11>.aem-GridColumn.aem-GridColumn--phone--10 {
    float: left;
    clear: none;
    width: 90.90909091%
  }

  .aem-Grid.aem-Grid--phone--11>.aem-GridColumn.aem-GridColumn--phone--11 {
    float: left;
    clear: none;
    width: 100%
  }

  .aem-Grid.aem-Grid--phone--11>.aem-GridColumn.aem-GridColumn--offset--phone--0 {
    margin-left: 0
  }

  .aem-Grid.aem-Grid--phone--11>.aem-GridColumn.aem-GridColumn--offset--phone--1 {
    margin-left: 9.09090909%
  }

  .aem-Grid.aem-Grid--phone--11>.aem-GridColumn.aem-GridColumn--offset--phone--2 {
    margin-left: 18.18181818%
  }

  .aem-Grid.aem-Grid--phone--11>.aem-GridColumn.aem-GridColumn--offset--phone--3 {
    margin-left: 27.27272727%
  }

  .aem-Grid.aem-Grid--phone--11>.aem-GridColumn.aem-GridColumn--offset--phone--4 {
    margin-left: 36.36363636%
  }

  .aem-Grid.aem-Grid--phone--11>.aem-GridColumn.aem-GridColumn--offset--phone--5 {
    margin-left: 45.45454545%
  }

  .aem-Grid.aem-Grid--phone--11>.aem-GridColumn.aem-GridColumn--offset--phone--6 {
    margin-left: 54.54545455%
  }

  .aem-Grid.aem-Grid--phone--11>.aem-GridColumn.aem-GridColumn--offset--phone--7 {
    margin-left: 63.63636364%
  }

  .aem-Grid.aem-Grid--phone--11>.aem-GridColumn.aem-GridColumn--offset--phone--8 {
    margin-left: 72.72727273%
  }

  .aem-Grid.aem-Grid--phone--11>.aem-GridColumn.aem-GridColumn--offset--phone--9 {
    margin-left: 81.81818182%
  }

  .aem-Grid.aem-Grid--phone--11>.aem-GridColumn.aem-GridColumn--offset--phone--10 {
    margin-left: 90.90909091%
  }

  .aem-Grid.aem-Grid--phone--11>.aem-GridColumn.aem-GridColumn--offset--phone--11 {
    margin-left: 100%
  }

  .aem-Grid.aem-Grid--phone--12>.aem-GridColumn.aem-GridColumn--phone--1 {
    float: left;
    clear: none;
    width: 8.33333333%
  }

  .aem-Grid.aem-Grid--phone--12>.aem-GridColumn.aem-GridColumn--phone--2 {
    float: left;
    clear: none;
    width: 16.66666667%
  }

  .aem-Grid.aem-Grid--phone--12>.aem-GridColumn.aem-GridColumn--phone--3 {
    float: left;
    clear: none;
    width: 25%
  }

  .aem-Grid.aem-Grid--phone--12>.aem-GridColumn.aem-GridColumn--phone--4 {
    float: left;
    clear: none;
    width: 33.33333333%
  }

  .aem-Grid.aem-Grid--phone--12>.aem-GridColumn.aem-GridColumn--phone--5 {
    float: left;
    clear: none;
    width: 41.66666667%
  }

  .aem-Grid.aem-Grid--phone--12>.aem-GridColumn.aem-GridColumn--phone--6 {
    float: left;
    clear: none;
    width: 50%
  }

  .aem-Grid.aem-Grid--phone--12>.aem-GridColumn.aem-GridColumn--phone--7 {
    float: left;
    clear: none;
    width: 58.33333333%
  }

  .aem-Grid.aem-Grid--phone--12>.aem-GridColumn.aem-GridColumn--phone--8 {
    float: left;
    clear: none;
    width: 66.66666667%
  }

  .aem-Grid.aem-Grid--phone--12>.aem-GridColumn.aem-GridColumn--phone--9 {
    float: left;
    clear: none;
    width: 75%
  }

  .aem-Grid.aem-Grid--phone--12>.aem-GridColumn.aem-GridColumn--phone--10 {
    float: left;
    clear: none;
    width: 83.33333333%
  }

  .aem-Grid.aem-Grid--phone--12>.aem-GridColumn.aem-GridColumn--phone--11 {
    float: left;
    clear: none;
    width: 91.66666667%
  }

  .aem-Grid.aem-Grid--phone--12>.aem-GridColumn.aem-GridColumn--phone--12 {
    float: left;
    clear: none;
    width: 100%
  }

  .aem-Grid.aem-Grid--phone--12>.aem-GridColumn.aem-GridColumn--offset--phone--0 {
    margin-left: 0
  }

  .aem-Grid.aem-Grid--phone--12>.aem-GridColumn.aem-GridColumn--offset--phone--1 {
    margin-left: 8.33333333%
  }

  .aem-Grid.aem-Grid--phone--12>.aem-GridColumn.aem-GridColumn--offset--phone--2 {
    margin-left: 16.66666667%
  }

  .aem-Grid.aem-Grid--phone--12>.aem-GridColumn.aem-GridColumn--offset--phone--3 {
    margin-left: 25%
  }

  .aem-Grid.aem-Grid--phone--12>.aem-GridColumn.aem-GridColumn--offset--phone--4 {
    margin-left: 33.33333333%
  }

  .aem-Grid.aem-Grid--phone--12>.aem-GridColumn.aem-GridColumn--offset--phone--5 {
    margin-left: 41.66666667%
  }

  .aem-Grid.aem-Grid--phone--12>.aem-GridColumn.aem-GridColumn--offset--phone--6 {
    margin-left: 50%
  }

  .aem-Grid.aem-Grid--phone--12>.aem-GridColumn.aem-GridColumn--offset--phone--7 {
    margin-left: 58.33333333%
  }

  .aem-Grid.aem-Grid--phone--12>.aem-GridColumn.aem-GridColumn--offset--phone--8 {
    margin-left: 66.66666667%
  }

  .aem-Grid.aem-Grid--phone--12>.aem-GridColumn.aem-GridColumn--offset--phone--9 {
    margin-left: 75%
  }

  .aem-Grid.aem-Grid--phone--12>.aem-GridColumn.aem-GridColumn--offset--phone--10 {
    margin-left: 83.33333333%
  }

  .aem-Grid.aem-Grid--phone--12>.aem-GridColumn.aem-GridColumn--offset--phone--11 {
    margin-left: 91.66666667%
  }

  .aem-Grid.aem-Grid--phone--12>.aem-GridColumn.aem-GridColumn--offset--phone--12 {
    margin-left: 100%
  }

  .aem-Grid>.aem-GridColumn.aem-GridColumn--phone--newline {
    display: block;
    clear: both !important
  }

  .aem-Grid>.aem-GridColumn.aem-GridColumn--phone--none {
    display: block;
    clear: none !important;
    float: left
  }

  .aem-Grid>.aem-GridColumn.aem-GridColumn--phone--hide {
    display: none
  }
}

@media(min-width: 769px) and (max-width:1200px) {
  .aem-Grid.aem-Grid--1>.aem-GridColumn.aem-GridColumn--tablet--1 {
    float: left;
    clear: none;
    width: 100%
  }

  .aem-Grid.aem-Grid--1>.aem-GridColumn.aem-GridColumn--offset--tablet--0 {
    margin-left: 0
  }

  .aem-Grid.aem-Grid--1>.aem-GridColumn.aem-GridColumn--offset--tablet--1 {
    margin-left: 100%
  }

  .aem-Grid.aem-Grid--2>.aem-GridColumn.aem-GridColumn--tablet--1 {
    float: left;
    clear: none;
    width: 50%
  }

  .aem-Grid.aem-Grid--2>.aem-GridColumn.aem-GridColumn--tablet--2 {
    float: left;
    clear: none;
    width: 100%
  }

  .aem-Grid.aem-Grid--2>.aem-GridColumn.aem-GridColumn--offset--tablet--0 {
    margin-left: 0
  }

  .aem-Grid.aem-Grid--2>.aem-GridColumn.aem-GridColumn--offset--tablet--1 {
    margin-left: 50%
  }

  .aem-Grid.aem-Grid--2>.aem-GridColumn.aem-GridColumn--offset--tablet--2 {
    margin-left: 100%
  }

  .aem-Grid.aem-Grid--3>.aem-GridColumn.aem-GridColumn--tablet--1 {
    float: left;
    clear: none;
    width: 33.33333333%
  }

  .aem-Grid.aem-Grid--3>.aem-GridColumn.aem-GridColumn--tablet--2 {
    float: left;
    clear: none;
    width: 66.66666667%
  }

  .aem-Grid.aem-Grid--3>.aem-GridColumn.aem-GridColumn--tablet--3 {
    float: left;
    clear: none;
    width: 100%
  }

  .aem-Grid.aem-Grid--3>.aem-GridColumn.aem-GridColumn--offset--tablet--0 {
    margin-left: 0
  }

  .aem-Grid.aem-Grid--3>.aem-GridColumn.aem-GridColumn--offset--tablet--1 {
    margin-left: 33.33333333%
  }

  .aem-Grid.aem-Grid--3>.aem-GridColumn.aem-GridColumn--offset--tablet--2 {
    margin-left: 66.66666667%
  }

  .aem-Grid.aem-Grid--3>.aem-GridColumn.aem-GridColumn--offset--tablet--3 {
    margin-left: 100%
  }

  .aem-Grid.aem-Grid--4>.aem-GridColumn.aem-GridColumn--tablet--1 {
    float: left;
    clear: none;
    width: 25%
  }

  .aem-Grid.aem-Grid--4>.aem-GridColumn.aem-GridColumn--tablet--2 {
    float: left;
    clear: none;
    width: 50%
  }

  .aem-Grid.aem-Grid--4>.aem-GridColumn.aem-GridColumn--tablet--3 {
    float: left;
    clear: none;
    width: 75%
  }

  .aem-Grid.aem-Grid--4>.aem-GridColumn.aem-GridColumn--tablet--4 {
    float: left;
    clear: none;
    width: 100%
  }

  .aem-Grid.aem-Grid--4>.aem-GridColumn.aem-GridColumn--offset--tablet--0 {
    margin-left: 0
  }

  .aem-Grid.aem-Grid--4>.aem-GridColumn.aem-GridColumn--offset--tablet--1 {
    margin-left: 25%
  }

  .aem-Grid.aem-Grid--4>.aem-GridColumn.aem-GridColumn--offset--tablet--2 {
    margin-left: 50%
  }

  .aem-Grid.aem-Grid--4>.aem-GridColumn.aem-GridColumn--offset--tablet--3 {
    margin-left: 75%
  }

  .aem-Grid.aem-Grid--4>.aem-GridColumn.aem-GridColumn--offset--tablet--4 {
    margin-left: 100%
  }

  .aem-Grid.aem-Grid--5>.aem-GridColumn.aem-GridColumn--tablet--1 {
    float: left;
    clear: none;
    width: 20%
  }

  .aem-Grid.aem-Grid--5>.aem-GridColumn.aem-GridColumn--tablet--2 {
    float: left;
    clear: none;
    width: 40%
  }

  .aem-Grid.aem-Grid--5>.aem-GridColumn.aem-GridColumn--tablet--3 {
    float: left;
    clear: none;
    width: 60%
  }

  .aem-Grid.aem-Grid--5>.aem-GridColumn.aem-GridColumn--tablet--4 {
    float: left;
    clear: none;
    width: 80%
  }

  .aem-Grid.aem-Grid--5>.aem-GridColumn.aem-GridColumn--tablet--5 {
    float: left;
    clear: none;
    width: 100%
  }

  .aem-Grid.aem-Grid--5>.aem-GridColumn.aem-GridColumn--offset--tablet--0 {
    margin-left: 0
  }

  .aem-Grid.aem-Grid--5>.aem-GridColumn.aem-GridColumn--offset--tablet--1 {
    margin-left: 20%
  }

  .aem-Grid.aem-Grid--5>.aem-GridColumn.aem-GridColumn--offset--tablet--2 {
    margin-left: 40%
  }

  .aem-Grid.aem-Grid--5>.aem-GridColumn.aem-GridColumn--offset--tablet--3 {
    margin-left: 60%
  }

  .aem-Grid.aem-Grid--5>.aem-GridColumn.aem-GridColumn--offset--tablet--4 {
    margin-left: 80%
  }

  .aem-Grid.aem-Grid--5>.aem-GridColumn.aem-GridColumn--offset--tablet--5 {
    margin-left: 100%
  }

  .aem-Grid.aem-Grid--6>.aem-GridColumn.aem-GridColumn--tablet--1 {
    float: left;
    clear: none;
    width: 16.66666667%
  }

  .aem-Grid.aem-Grid--6>.aem-GridColumn.aem-GridColumn--tablet--2 {
    float: left;
    clear: none;
    width: 33.33333333%
  }

  .aem-Grid.aem-Grid--6>.aem-GridColumn.aem-GridColumn--tablet--3 {
    float: left;
    clear: none;
    width: 50%
  }

  .aem-Grid.aem-Grid--6>.aem-GridColumn.aem-GridColumn--tablet--4 {
    float: left;
    clear: none;
    width: 66.66666667%
  }

  .aem-Grid.aem-Grid--6>.aem-GridColumn.aem-GridColumn--tablet--5 {
    float: left;
    clear: none;
    width: 83.33333333%
  }

  .aem-Grid.aem-Grid--6>.aem-GridColumn.aem-GridColumn--tablet--6 {
    float: left;
    clear: none;
    width: 100%
  }

  .aem-Grid.aem-Grid--6>.aem-GridColumn.aem-GridColumn--offset--tablet--0 {
    margin-left: 0
  }

  .aem-Grid.aem-Grid--6>.aem-GridColumn.aem-GridColumn--offset--tablet--1 {
    margin-left: 16.66666667%
  }

  .aem-Grid.aem-Grid--6>.aem-GridColumn.aem-GridColumn--offset--tablet--2 {
    margin-left: 33.33333333%
  }

  .aem-Grid.aem-Grid--6>.aem-GridColumn.aem-GridColumn--offset--tablet--3 {
    margin-left: 50%
  }

  .aem-Grid.aem-Grid--6>.aem-GridColumn.aem-GridColumn--offset--tablet--4 {
    margin-left: 66.66666667%
  }

  .aem-Grid.aem-Grid--6>.aem-GridColumn.aem-GridColumn--offset--tablet--5 {
    margin-left: 83.33333333%
  }

  .aem-Grid.aem-Grid--6>.aem-GridColumn.aem-GridColumn--offset--tablet--6 {
    margin-left: 100%
  }

  .aem-Grid.aem-Grid--7>.aem-GridColumn.aem-GridColumn--tablet--1 {
    float: left;
    clear: none;
    width: 14.28571429%
  }

  .aem-Grid.aem-Grid--7>.aem-GridColumn.aem-GridColumn--tablet--2 {
    float: left;
    clear: none;
    width: 28.57142857%
  }

  .aem-Grid.aem-Grid--7>.aem-GridColumn.aem-GridColumn--tablet--3 {
    float: left;
    clear: none;
    width: 42.85714286%
  }

  .aem-Grid.aem-Grid--7>.aem-GridColumn.aem-GridColumn--tablet--4 {
    float: left;
    clear: none;
    width: 57.14285714%
  }

  .aem-Grid.aem-Grid--7>.aem-GridColumn.aem-GridColumn--tablet--5 {
    float: left;
    clear: none;
    width: 71.42857143%
  }

  .aem-Grid.aem-Grid--7>.aem-GridColumn.aem-GridColumn--tablet--6 {
    float: left;
    clear: none;
    width: 85.71428571%
  }

  .aem-Grid.aem-Grid--7>.aem-GridColumn.aem-GridColumn--tablet--7 {
    float: left;
    clear: none;
    width: 100%
  }

  .aem-Grid.aem-Grid--7>.aem-GridColumn.aem-GridColumn--offset--tablet--0 {
    margin-left: 0
  }

  .aem-Grid.aem-Grid--7>.aem-GridColumn.aem-GridColumn--offset--tablet--1 {
    margin-left: 14.28571429%
  }

  .aem-Grid.aem-Grid--7>.aem-GridColumn.aem-GridColumn--offset--tablet--2 {
    margin-left: 28.57142857%
  }

  .aem-Grid.aem-Grid--7>.aem-GridColumn.aem-GridColumn--offset--tablet--3 {
    margin-left: 42.85714286%
  }

  .aem-Grid.aem-Grid--7>.aem-GridColumn.aem-GridColumn--offset--tablet--4 {
    margin-left: 57.14285714%
  }

  .aem-Grid.aem-Grid--7>.aem-GridColumn.aem-GridColumn--offset--tablet--5 {
    margin-left: 71.42857143%
  }

  .aem-Grid.aem-Grid--7>.aem-GridColumn.aem-GridColumn--offset--tablet--6 {
    margin-left: 85.71428571%
  }

  .aem-Grid.aem-Grid--7>.aem-GridColumn.aem-GridColumn--offset--tablet--7 {
    margin-left: 100%
  }

  .aem-Grid.aem-Grid--8>.aem-GridColumn.aem-GridColumn--tablet--1 {
    float: left;
    clear: none;
    width: 12.5%
  }

  .aem-Grid.aem-Grid--8>.aem-GridColumn.aem-GridColumn--tablet--2 {
    float: left;
    clear: none;
    width: 25%
  }

  .aem-Grid.aem-Grid--8>.aem-GridColumn.aem-GridColumn--tablet--3 {
    float: left;
    clear: none;
    width: 37.5%
  }

  .aem-Grid.aem-Grid--8>.aem-GridColumn.aem-GridColumn--tablet--4 {
    float: left;
    clear: none;
    width: 50%
  }

  .aem-Grid.aem-Grid--8>.aem-GridColumn.aem-GridColumn--tablet--5 {
    float: left;
    clear: none;
    width: 62.5%
  }

  .aem-Grid.aem-Grid--8>.aem-GridColumn.aem-GridColumn--tablet--6 {
    float: left;
    clear: none;
    width: 75%
  }

  .aem-Grid.aem-Grid--8>.aem-GridColumn.aem-GridColumn--tablet--7 {
    float: left;
    clear: none;
    width: 87.5%
  }

  .aem-Grid.aem-Grid--8>.aem-GridColumn.aem-GridColumn--tablet--8 {
    float: left;
    clear: none;
    width: 100%
  }

  .aem-Grid.aem-Grid--8>.aem-GridColumn.aem-GridColumn--offset--tablet--0 {
    margin-left: 0
  }

  .aem-Grid.aem-Grid--8>.aem-GridColumn.aem-GridColumn--offset--tablet--1 {
    margin-left: 12.5%
  }

  .aem-Grid.aem-Grid--8>.aem-GridColumn.aem-GridColumn--offset--tablet--2 {
    margin-left: 25%
  }

  .aem-Grid.aem-Grid--8>.aem-GridColumn.aem-GridColumn--offset--tablet--3 {
    margin-left: 37.5%
  }

  .aem-Grid.aem-Grid--8>.aem-GridColumn.aem-GridColumn--offset--tablet--4 {
    margin-left: 50%
  }

  .aem-Grid.aem-Grid--8>.aem-GridColumn.aem-GridColumn--offset--tablet--5 {
    margin-left: 62.5%
  }

  .aem-Grid.aem-Grid--8>.aem-GridColumn.aem-GridColumn--offset--tablet--6 {
    margin-left: 75%
  }

  .aem-Grid.aem-Grid--8>.aem-GridColumn.aem-GridColumn--offset--tablet--7 {
    margin-left: 87.5%
  }

  .aem-Grid.aem-Grid--8>.aem-GridColumn.aem-GridColumn--offset--tablet--8 {
    margin-left: 100%
  }

  .aem-Grid.aem-Grid--9>.aem-GridColumn.aem-GridColumn--tablet--1 {
    float: left;
    clear: none;
    width: 11.11111111%
  }

  .aem-Grid.aem-Grid--9>.aem-GridColumn.aem-GridColumn--tablet--2 {
    float: left;
    clear: none;
    width: 22.22222222%
  }

  .aem-Grid.aem-Grid--9>.aem-GridColumn.aem-GridColumn--tablet--3 {
    float: left;
    clear: none;
    width: 33.33333333%
  }

  .aem-Grid.aem-Grid--9>.aem-GridColumn.aem-GridColumn--tablet--4 {
    float: left;
    clear: none;
    width: 44.44444444%
  }

  .aem-Grid.aem-Grid--9>.aem-GridColumn.aem-GridColumn--tablet--5 {
    float: left;
    clear: none;
    width: 55.55555556%
  }

  .aem-Grid.aem-Grid--9>.aem-GridColumn.aem-GridColumn--tablet--6 {
    float: left;
    clear: none;
    width: 66.66666667%
  }

  .aem-Grid.aem-Grid--9>.aem-GridColumn.aem-GridColumn--tablet--7 {
    float: left;
    clear: none;
    width: 77.77777778%
  }

  .aem-Grid.aem-Grid--9>.aem-GridColumn.aem-GridColumn--tablet--8 {
    float: left;
    clear: none;
    width: 88.88888889%
  }

  .aem-Grid.aem-Grid--9>.aem-GridColumn.aem-GridColumn--tablet--9 {
    float: left;
    clear: none;
    width: 100%
  }

  .aem-Grid.aem-Grid--9>.aem-GridColumn.aem-GridColumn--offset--tablet--0 {
    margin-left: 0
  }

  .aem-Grid.aem-Grid--9>.aem-GridColumn.aem-GridColumn--offset--tablet--1 {
    margin-left: 11.11111111%
  }

  .aem-Grid.aem-Grid--9>.aem-GridColumn.aem-GridColumn--offset--tablet--2 {
    margin-left: 22.22222222%
  }

  .aem-Grid.aem-Grid--9>.aem-GridColumn.aem-GridColumn--offset--tablet--3 {
    margin-left: 33.33333333%
  }

  .aem-Grid.aem-Grid--9>.aem-GridColumn.aem-GridColumn--offset--tablet--4 {
    margin-left: 44.44444444%
  }

  .aem-Grid.aem-Grid--9>.aem-GridColumn.aem-GridColumn--offset--tablet--5 {
    margin-left: 55.55555556%
  }

  .aem-Grid.aem-Grid--9>.aem-GridColumn.aem-GridColumn--offset--tablet--6 {
    margin-left: 66.66666667%
  }

  .aem-Grid.aem-Grid--9>.aem-GridColumn.aem-GridColumn--offset--tablet--7 {
    margin-left: 77.77777778%
  }

  .aem-Grid.aem-Grid--9>.aem-GridColumn.aem-GridColumn--offset--tablet--8 {
    margin-left: 88.88888889%
  }

  .aem-Grid.aem-Grid--9>.aem-GridColumn.aem-GridColumn--offset--tablet--9 {
    margin-left: 100%
  }

  .aem-Grid.aem-Grid--10>.aem-GridColumn.aem-GridColumn--tablet--1 {
    float: left;
    clear: none;
    width: 10%
  }

  .aem-Grid.aem-Grid--10>.aem-GridColumn.aem-GridColumn--tablet--2 {
    float: left;
    clear: none;
    width: 20%
  }

  .aem-Grid.aem-Grid--10>.aem-GridColumn.aem-GridColumn--tablet--3 {
    float: left;
    clear: none;
    width: 30%
  }

  .aem-Grid.aem-Grid--10>.aem-GridColumn.aem-GridColumn--tablet--4 {
    float: left;
    clear: none;
    width: 40%
  }

  .aem-Grid.aem-Grid--10>.aem-GridColumn.aem-GridColumn--tablet--5 {
    float: left;
    clear: none;
    width: 50%
  }

  .aem-Grid.aem-Grid--10>.aem-GridColumn.aem-GridColumn--tablet--6 {
    float: left;
    clear: none;
    width: 60%
  }

  .aem-Grid.aem-Grid--10>.aem-GridColumn.aem-GridColumn--tablet--7 {
    float: left;
    clear: none;
    width: 70%
  }

  .aem-Grid.aem-Grid--10>.aem-GridColumn.aem-GridColumn--tablet--8 {
    float: left;
    clear: none;
    width: 80%
  }

  .aem-Grid.aem-Grid--10>.aem-GridColumn.aem-GridColumn--tablet--9 {
    float: left;
    clear: none;
    width: 90%
  }

  .aem-Grid.aem-Grid--10>.aem-GridColumn.aem-GridColumn--tablet--10 {
    float: left;
    clear: none;
    width: 100%
  }

  .aem-Grid.aem-Grid--10>.aem-GridColumn.aem-GridColumn--offset--tablet--0 {
    margin-left: 0
  }

  .aem-Grid.aem-Grid--10>.aem-GridColumn.aem-GridColumn--offset--tablet--1 {
    margin-left: 10%
  }

  .aem-Grid.aem-Grid--10>.aem-GridColumn.aem-GridColumn--offset--tablet--2 {
    margin-left: 20%
  }

  .aem-Grid.aem-Grid--10>.aem-GridColumn.aem-GridColumn--offset--tablet--3 {
    margin-left: 30%
  }

  .aem-Grid.aem-Grid--10>.aem-GridColumn.aem-GridColumn--offset--tablet--4 {
    margin-left: 40%
  }

  .aem-Grid.aem-Grid--10>.aem-GridColumn.aem-GridColumn--offset--tablet--5 {
    margin-left: 50%
  }

  .aem-Grid.aem-Grid--10>.aem-GridColumn.aem-GridColumn--offset--tablet--6 {
    margin-left: 60%
  }

  .aem-Grid.aem-Grid--10>.aem-GridColumn.aem-GridColumn--offset--tablet--7 {
    margin-left: 70%
  }

  .aem-Grid.aem-Grid--10>.aem-GridColumn.aem-GridColumn--offset--tablet--8 {
    margin-left: 80%
  }

  .aem-Grid.aem-Grid--10>.aem-GridColumn.aem-GridColumn--offset--tablet--9 {
    margin-left: 90%
  }

  .aem-Grid.aem-Grid--10>.aem-GridColumn.aem-GridColumn--offset--tablet--10 {
    margin-left: 100%
  }

  .aem-Grid.aem-Grid--11>.aem-GridColumn.aem-GridColumn--tablet--1 {
    float: left;
    clear: none;
    width: 9.09090909%
  }

  .aem-Grid.aem-Grid--11>.aem-GridColumn.aem-GridColumn--tablet--2 {
    float: left;
    clear: none;
    width: 18.18181818%
  }

  .aem-Grid.aem-Grid--11>.aem-GridColumn.aem-GridColumn--tablet--3 {
    float: left;
    clear: none;
    width: 27.27272727%
  }

  .aem-Grid.aem-Grid--11>.aem-GridColumn.aem-GridColumn--tablet--4 {
    float: left;
    clear: none;
    width: 36.36363636%
  }

  .aem-Grid.aem-Grid--11>.aem-GridColumn.aem-GridColumn--tablet--5 {
    float: left;
    clear: none;
    width: 45.45454545%
  }

  .aem-Grid.aem-Grid--11>.aem-GridColumn.aem-GridColumn--tablet--6 {
    float: left;
    clear: none;
    width: 54.54545455%
  }

  .aem-Grid.aem-Grid--11>.aem-GridColumn.aem-GridColumn--tablet--7 {
    float: left;
    clear: none;
    width: 63.63636364%
  }

  .aem-Grid.aem-Grid--11>.aem-GridColumn.aem-GridColumn--tablet--8 {
    float: left;
    clear: none;
    width: 72.72727273%
  }

  .aem-Grid.aem-Grid--11>.aem-GridColumn.aem-GridColumn--tablet--9 {
    float: left;
    clear: none;
    width: 81.81818182%
  }

  .aem-Grid.aem-Grid--11>.aem-GridColumn.aem-GridColumn--tablet--10 {
    float: left;
    clear: none;
    width: 90.90909091%
  }

  .aem-Grid.aem-Grid--11>.aem-GridColumn.aem-GridColumn--tablet--11 {
    float: left;
    clear: none;
    width: 100%
  }

  .aem-Grid.aem-Grid--11>.aem-GridColumn.aem-GridColumn--offset--tablet--0 {
    margin-left: 0
  }

  .aem-Grid.aem-Grid--11>.aem-GridColumn.aem-GridColumn--offset--tablet--1 {
    margin-left: 9.09090909%
  }

  .aem-Grid.aem-Grid--11>.aem-GridColumn.aem-GridColumn--offset--tablet--2 {
    margin-left: 18.18181818%
  }

  .aem-Grid.aem-Grid--11>.aem-GridColumn.aem-GridColumn--offset--tablet--3 {
    margin-left: 27.27272727%
  }

  .aem-Grid.aem-Grid--11>.aem-GridColumn.aem-GridColumn--offset--tablet--4 {
    margin-left: 36.36363636%
  }

  .aem-Grid.aem-Grid--11>.aem-GridColumn.aem-GridColumn--offset--tablet--5 {
    margin-left: 45.45454545%
  }

  .aem-Grid.aem-Grid--11>.aem-GridColumn.aem-GridColumn--offset--tablet--6 {
    margin-left: 54.54545455%
  }

  .aem-Grid.aem-Grid--11>.aem-GridColumn.aem-GridColumn--offset--tablet--7 {
    margin-left: 63.63636364%
  }

  .aem-Grid.aem-Grid--11>.aem-GridColumn.aem-GridColumn--offset--tablet--8 {
    margin-left: 72.72727273%
  }

  .aem-Grid.aem-Grid--11>.aem-GridColumn.aem-GridColumn--offset--tablet--9 {
    margin-left: 81.81818182%
  }

  .aem-Grid.aem-Grid--11>.aem-GridColumn.aem-GridColumn--offset--tablet--10 {
    margin-left: 90.90909091%
  }

  .aem-Grid.aem-Grid--11>.aem-GridColumn.aem-GridColumn--offset--tablet--11 {
    margin-left: 100%
  }

  .aem-Grid.aem-Grid--12>.aem-GridColumn.aem-GridColumn--tablet--1 {
    float: left;
    clear: none;
    width: 8.33333333%
  }

  .aem-Grid.aem-Grid--12>.aem-GridColumn.aem-GridColumn--tablet--2 {
    float: left;
    clear: none;
    width: 16.66666667%
  }

  .aem-Grid.aem-Grid--12>.aem-GridColumn.aem-GridColumn--tablet--3 {
    float: left;
    clear: none;
    width: 25%
  }

  .aem-Grid.aem-Grid--12>.aem-GridColumn.aem-GridColumn--tablet--4 {
    float: left;
    clear: none;
    width: 33.33333333%
  }

  .aem-Grid.aem-Grid--12>.aem-GridColumn.aem-GridColumn--tablet--5 {
    float: left;
    clear: none;
    width: 41.66666667%
  }

  .aem-Grid.aem-Grid--12>.aem-GridColumn.aem-GridColumn--tablet--6 {
    float: left;
    clear: none;
    width: 50%
  }

  .aem-Grid.aem-Grid--12>.aem-GridColumn.aem-GridColumn--tablet--7 {
    float: left;
    clear: none;
    width: 58.33333333%
  }

  .aem-Grid.aem-Grid--12>.aem-GridColumn.aem-GridColumn--tablet--8 {
    float: left;
    clear: none;
    width: 66.66666667%
  }

  .aem-Grid.aem-Grid--12>.aem-GridColumn.aem-GridColumn--tablet--9 {
    float: left;
    clear: none;
    width: 75%
  }

  .aem-Grid.aem-Grid--12>.aem-GridColumn.aem-GridColumn--tablet--10 {
    float: left;
    clear: none;
    width: 83.33333333%
  }

  .aem-Grid.aem-Grid--12>.aem-GridColumn.aem-GridColumn--tablet--11 {
    float: left;
    clear: none;
    width: 91.66666667%
  }

  .aem-Grid.aem-Grid--12>.aem-GridColumn.aem-GridColumn--tablet--12 {
    float: left;
    clear: none;
    width: 100%
  }

  .aem-Grid.aem-Grid--12>.aem-GridColumn.aem-GridColumn--offset--tablet--0 {
    margin-left: 0
  }

  .aem-Grid.aem-Grid--12>.aem-GridColumn.aem-GridColumn--offset--tablet--1 {
    margin-left: 8.33333333%
  }

  .aem-Grid.aem-Grid--12>.aem-GridColumn.aem-GridColumn--offset--tablet--2 {
    margin-left: 16.66666667%
  }

  .aem-Grid.aem-Grid--12>.aem-GridColumn.aem-GridColumn--offset--tablet--3 {
    margin-left: 25%
  }

  .aem-Grid.aem-Grid--12>.aem-GridColumn.aem-GridColumn--offset--tablet--4 {
    margin-left: 33.33333333%
  }

  .aem-Grid.aem-Grid--12>.aem-GridColumn.aem-GridColumn--offset--tablet--5 {
    margin-left: 41.66666667%
  }

  .aem-Grid.aem-Grid--12>.aem-GridColumn.aem-GridColumn--offset--tablet--6 {
    margin-left: 50%
  }

  .aem-Grid.aem-Grid--12>.aem-GridColumn.aem-GridColumn--offset--tablet--7 {
    margin-left: 58.33333333%
  }

  .aem-Grid.aem-Grid--12>.aem-GridColumn.aem-GridColumn--offset--tablet--8 {
    margin-left: 66.66666667%
  }

  .aem-Grid.aem-Grid--12>.aem-GridColumn.aem-GridColumn--offset--tablet--9 {
    margin-left: 75%
  }

  .aem-Grid.aem-Grid--12>.aem-GridColumn.aem-GridColumn--offset--tablet--10 {
    margin-left: 83.33333333%
  }

  .aem-Grid.aem-Grid--12>.aem-GridColumn.aem-GridColumn--offset--tablet--11 {
    margin-left: 91.66666667%
  }

  .aem-Grid.aem-Grid--12>.aem-GridColumn.aem-GridColumn--offset--tablet--12 {
    margin-left: 100%
  }

  .aem-Grid.aem-Grid--tablet--1>.aem-GridColumn.aem-GridColumn--tablet--1 {
    float: left;
    clear: none;
    width: 100%
  }

  .aem-Grid.aem-Grid--tablet--1>.aem-GridColumn.aem-GridColumn--offset--tablet--0 {
    margin-left: 0
  }

  .aem-Grid.aem-Grid--tablet--1>.aem-GridColumn.aem-GridColumn--offset--tablet--1 {
    margin-left: 100%
  }

  .aem-Grid.aem-Grid--tablet--2>.aem-GridColumn.aem-GridColumn--tablet--1 {
    float: left;
    clear: none;
    width: 50%
  }

  .aem-Grid.aem-Grid--tablet--2>.aem-GridColumn.aem-GridColumn--tablet--2 {
    float: left;
    clear: none;
    width: 100%
  }

  .aem-Grid.aem-Grid--tablet--2>.aem-GridColumn.aem-GridColumn--offset--tablet--0 {
    margin-left: 0
  }

  .aem-Grid.aem-Grid--tablet--2>.aem-GridColumn.aem-GridColumn--offset--tablet--1 {
    margin-left: 50%
  }

  .aem-Grid.aem-Grid--tablet--2>.aem-GridColumn.aem-GridColumn--offset--tablet--2 {
    margin-left: 100%
  }

  .aem-Grid.aem-Grid--tablet--3>.aem-GridColumn.aem-GridColumn--tablet--1 {
    float: left;
    clear: none;
    width: 33.33333333%
  }

  .aem-Grid.aem-Grid--tablet--3>.aem-GridColumn.aem-GridColumn--tablet--2 {
    float: left;
    clear: none;
    width: 66.66666667%
  }

  .aem-Grid.aem-Grid--tablet--3>.aem-GridColumn.aem-GridColumn--tablet--3 {
    float: left;
    clear: none;
    width: 100%
  }

  .aem-Grid.aem-Grid--tablet--3>.aem-GridColumn.aem-GridColumn--offset--tablet--0 {
    margin-left: 0
  }

  .aem-Grid.aem-Grid--tablet--3>.aem-GridColumn.aem-GridColumn--offset--tablet--1 {
    margin-left: 33.33333333%
  }

  .aem-Grid.aem-Grid--tablet--3>.aem-GridColumn.aem-GridColumn--offset--tablet--2 {
    margin-left: 66.66666667%
  }

  .aem-Grid.aem-Grid--tablet--3>.aem-GridColumn.aem-GridColumn--offset--tablet--3 {
    margin-left: 100%
  }

  .aem-Grid.aem-Grid--tablet--4>.aem-GridColumn.aem-GridColumn--tablet--1 {
    float: left;
    clear: none;
    width: 25%
  }

  .aem-Grid.aem-Grid--tablet--4>.aem-GridColumn.aem-GridColumn--tablet--2 {
    float: left;
    clear: none;
    width: 50%
  }

  .aem-Grid.aem-Grid--tablet--4>.aem-GridColumn.aem-GridColumn--tablet--3 {
    float: left;
    clear: none;
    width: 75%
  }

  .aem-Grid.aem-Grid--tablet--4>.aem-GridColumn.aem-GridColumn--tablet--4 {
    float: left;
    clear: none;
    width: 100%
  }

  .aem-Grid.aem-Grid--tablet--4>.aem-GridColumn.aem-GridColumn--offset--tablet--0 {
    margin-left: 0
  }

  .aem-Grid.aem-Grid--tablet--4>.aem-GridColumn.aem-GridColumn--offset--tablet--1 {
    margin-left: 25%
  }

  .aem-Grid.aem-Grid--tablet--4>.aem-GridColumn.aem-GridColumn--offset--tablet--2 {
    margin-left: 50%
  }

  .aem-Grid.aem-Grid--tablet--4>.aem-GridColumn.aem-GridColumn--offset--tablet--3 {
    margin-left: 75%
  }

  .aem-Grid.aem-Grid--tablet--4>.aem-GridColumn.aem-GridColumn--offset--tablet--4 {
    margin-left: 100%
  }

  .aem-Grid.aem-Grid--tablet--5>.aem-GridColumn.aem-GridColumn--tablet--1 {
    float: left;
    clear: none;
    width: 20%
  }

  .aem-Grid.aem-Grid--tablet--5>.aem-GridColumn.aem-GridColumn--tablet--2 {
    float: left;
    clear: none;
    width: 40%
  }

  .aem-Grid.aem-Grid--tablet--5>.aem-GridColumn.aem-GridColumn--tablet--3 {
    float: left;
    clear: none;
    width: 60%
  }

  .aem-Grid.aem-Grid--tablet--5>.aem-GridColumn.aem-GridColumn--tablet--4 {
    float: left;
    clear: none;
    width: 80%
  }

  .aem-Grid.aem-Grid--tablet--5>.aem-GridColumn.aem-GridColumn--tablet--5 {
    float: left;
    clear: none;
    width: 100%
  }

  .aem-Grid.aem-Grid--tablet--5>.aem-GridColumn.aem-GridColumn--offset--tablet--0 {
    margin-left: 0
  }

  .aem-Grid.aem-Grid--tablet--5>.aem-GridColumn.aem-GridColumn--offset--tablet--1 {
    margin-left: 20%
  }

  .aem-Grid.aem-Grid--tablet--5>.aem-GridColumn.aem-GridColumn--offset--tablet--2 {
    margin-left: 40%
  }

  .aem-Grid.aem-Grid--tablet--5>.aem-GridColumn.aem-GridColumn--offset--tablet--3 {
    margin-left: 60%
  }

  .aem-Grid.aem-Grid--tablet--5>.aem-GridColumn.aem-GridColumn--offset--tablet--4 {
    margin-left: 80%
  }

  .aem-Grid.aem-Grid--tablet--5>.aem-GridColumn.aem-GridColumn--offset--tablet--5 {
    margin-left: 100%
  }

  .aem-Grid.aem-Grid--tablet--6>.aem-GridColumn.aem-GridColumn--tablet--1 {
    float: left;
    clear: none;
    width: 16.66666667%
  }

  .aem-Grid.aem-Grid--tablet--6>.aem-GridColumn.aem-GridColumn--tablet--2 {
    float: left;
    clear: none;
    width: 33.33333333%
  }

  .aem-Grid.aem-Grid--tablet--6>.aem-GridColumn.aem-GridColumn--tablet--3 {
    float: left;
    clear: none;
    width: 50%
  }

  .aem-Grid.aem-Grid--tablet--6>.aem-GridColumn.aem-GridColumn--tablet--4 {
    float: left;
    clear: none;
    width: 66.66666667%
  }

  .aem-Grid.aem-Grid--tablet--6>.aem-GridColumn.aem-GridColumn--tablet--5 {
    float: left;
    clear: none;
    width: 83.33333333%
  }

  .aem-Grid.aem-Grid--tablet--6>.aem-GridColumn.aem-GridColumn--tablet--6 {
    float: left;
    clear: none;
    width: 100%
  }

  .aem-Grid.aem-Grid--tablet--6>.aem-GridColumn.aem-GridColumn--offset--tablet--0 {
    margin-left: 0
  }

  .aem-Grid.aem-Grid--tablet--6>.aem-GridColumn.aem-GridColumn--offset--tablet--1 {
    margin-left: 16.66666667%
  }

  .aem-Grid.aem-Grid--tablet--6>.aem-GridColumn.aem-GridColumn--offset--tablet--2 {
    margin-left: 33.33333333%
  }

  .aem-Grid.aem-Grid--tablet--6>.aem-GridColumn.aem-GridColumn--offset--tablet--3 {
    margin-left: 50%
  }

  .aem-Grid.aem-Grid--tablet--6>.aem-GridColumn.aem-GridColumn--offset--tablet--4 {
    margin-left: 66.66666667%
  }

  .aem-Grid.aem-Grid--tablet--6>.aem-GridColumn.aem-GridColumn--offset--tablet--5 {
    margin-left: 83.33333333%
  }

  .aem-Grid.aem-Grid--tablet--6>.aem-GridColumn.aem-GridColumn--offset--tablet--6 {
    margin-left: 100%
  }

  .aem-Grid.aem-Grid--tablet--7>.aem-GridColumn.aem-GridColumn--tablet--1 {
    float: left;
    clear: none;
    width: 14.28571429%
  }

  .aem-Grid.aem-Grid--tablet--7>.aem-GridColumn.aem-GridColumn--tablet--2 {
    float: left;
    clear: none;
    width: 28.57142857%
  }

  .aem-Grid.aem-Grid--tablet--7>.aem-GridColumn.aem-GridColumn--tablet--3 {
    float: left;
    clear: none;
    width: 42.85714286%
  }

  .aem-Grid.aem-Grid--tablet--7>.aem-GridColumn.aem-GridColumn--tablet--4 {
    float: left;
    clear: none;
    width: 57.14285714%
  }

  .aem-Grid.aem-Grid--tablet--7>.aem-GridColumn.aem-GridColumn--tablet--5 {
    float: left;
    clear: none;
    width: 71.42857143%
  }

  .aem-Grid.aem-Grid--tablet--7>.aem-GridColumn.aem-GridColumn--tablet--6 {
    float: left;
    clear: none;
    width: 85.71428571%
  }

  .aem-Grid.aem-Grid--tablet--7>.aem-GridColumn.aem-GridColumn--tablet--7 {
    float: left;
    clear: none;
    width: 100%
  }

  .aem-Grid.aem-Grid--tablet--7>.aem-GridColumn.aem-GridColumn--offset--tablet--0 {
    margin-left: 0
  }

  .aem-Grid.aem-Grid--tablet--7>.aem-GridColumn.aem-GridColumn--offset--tablet--1 {
    margin-left: 14.28571429%
  }

  .aem-Grid.aem-Grid--tablet--7>.aem-GridColumn.aem-GridColumn--offset--tablet--2 {
    margin-left: 28.57142857%
  }

  .aem-Grid.aem-Grid--tablet--7>.aem-GridColumn.aem-GridColumn--offset--tablet--3 {
    margin-left: 42.85714286%
  }

  .aem-Grid.aem-Grid--tablet--7>.aem-GridColumn.aem-GridColumn--offset--tablet--4 {
    margin-left: 57.14285714%
  }

  .aem-Grid.aem-Grid--tablet--7>.aem-GridColumn.aem-GridColumn--offset--tablet--5 {
    margin-left: 71.42857143%
  }

  .aem-Grid.aem-Grid--tablet--7>.aem-GridColumn.aem-GridColumn--offset--tablet--6 {
    margin-left: 85.71428571%
  }

  .aem-Grid.aem-Grid--tablet--7>.aem-GridColumn.aem-GridColumn--offset--tablet--7 {
    margin-left: 100%
  }

  .aem-Grid.aem-Grid--tablet--8>.aem-GridColumn.aem-GridColumn--tablet--1 {
    float: left;
    clear: none;
    width: 12.5%
  }

  .aem-Grid.aem-Grid--tablet--8>.aem-GridColumn.aem-GridColumn--tablet--2 {
    float: left;
    clear: none;
    width: 25%
  }

  .aem-Grid.aem-Grid--tablet--8>.aem-GridColumn.aem-GridColumn--tablet--3 {
    float: left;
    clear: none;
    width: 37.5%
  }

  .aem-Grid.aem-Grid--tablet--8>.aem-GridColumn.aem-GridColumn--tablet--4 {
    float: left;
    clear: none;
    width: 50%
  }

  .aem-Grid.aem-Grid--tablet--8>.aem-GridColumn.aem-GridColumn--tablet--5 {
    float: left;
    clear: none;
    width: 62.5%
  }

  .aem-Grid.aem-Grid--tablet--8>.aem-GridColumn.aem-GridColumn--tablet--6 {
    float: left;
    clear: none;
    width: 75%
  }

  .aem-Grid.aem-Grid--tablet--8>.aem-GridColumn.aem-GridColumn--tablet--7 {
    float: left;
    clear: none;
    width: 87.5%
  }

  .aem-Grid.aem-Grid--tablet--8>.aem-GridColumn.aem-GridColumn--tablet--8 {
    float: left;
    clear: none;
    width: 100%
  }

  .aem-Grid.aem-Grid--tablet--8>.aem-GridColumn.aem-GridColumn--offset--tablet--0 {
    margin-left: 0
  }

  .aem-Grid.aem-Grid--tablet--8>.aem-GridColumn.aem-GridColumn--offset--tablet--1 {
    margin-left: 12.5%
  }

  .aem-Grid.aem-Grid--tablet--8>.aem-GridColumn.aem-GridColumn--offset--tablet--2 {
    margin-left: 25%
  }

  .aem-Grid.aem-Grid--tablet--8>.aem-GridColumn.aem-GridColumn--offset--tablet--3 {
    margin-left: 37.5%
  }

  .aem-Grid.aem-Grid--tablet--8>.aem-GridColumn.aem-GridColumn--offset--tablet--4 {
    margin-left: 50%
  }

  .aem-Grid.aem-Grid--tablet--8>.aem-GridColumn.aem-GridColumn--offset--tablet--5 {
    margin-left: 62.5%
  }

  .aem-Grid.aem-Grid--tablet--8>.aem-GridColumn.aem-GridColumn--offset--tablet--6 {
    margin-left: 75%
  }

  .aem-Grid.aem-Grid--tablet--8>.aem-GridColumn.aem-GridColumn--offset--tablet--7 {
    margin-left: 87.5%
  }

  .aem-Grid.aem-Grid--tablet--8>.aem-GridColumn.aem-GridColumn--offset--tablet--8 {
    margin-left: 100%
  }

  .aem-Grid.aem-Grid--tablet--9>.aem-GridColumn.aem-GridColumn--tablet--1 {
    float: left;
    clear: none;
    width: 11.11111111%
  }

  .aem-Grid.aem-Grid--tablet--9>.aem-GridColumn.aem-GridColumn--tablet--2 {
    float: left;
    clear: none;
    width: 22.22222222%
  }

  .aem-Grid.aem-Grid--tablet--9>.aem-GridColumn.aem-GridColumn--tablet--3 {
    float: left;
    clear: none;
    width: 33.33333333%
  }

  .aem-Grid.aem-Grid--tablet--9>.aem-GridColumn.aem-GridColumn--tablet--4 {
    float: left;
    clear: none;
    width: 44.44444444%
  }

  .aem-Grid.aem-Grid--tablet--9>.aem-GridColumn.aem-GridColumn--tablet--5 {
    float: left;
    clear: none;
    width: 55.55555556%
  }

  .aem-Grid.aem-Grid--tablet--9>.aem-GridColumn.aem-GridColumn--tablet--6 {
    float: left;
    clear: none;
    width: 66.66666667%
  }

  .aem-Grid.aem-Grid--tablet--9>.aem-GridColumn.aem-GridColumn--tablet--7 {
    float: left;
    clear: none;
    width: 77.77777778%
  }

  .aem-Grid.aem-Grid--tablet--9>.aem-GridColumn.aem-GridColumn--tablet--8 {
    float: left;
    clear: none;
    width: 88.88888889%
  }

  .aem-Grid.aem-Grid--tablet--9>.aem-GridColumn.aem-GridColumn--tablet--9 {
    float: left;
    clear: none;
    width: 100%
  }

  .aem-Grid.aem-Grid--tablet--9>.aem-GridColumn.aem-GridColumn--offset--tablet--0 {
    margin-left: 0
  }

  .aem-Grid.aem-Grid--tablet--9>.aem-GridColumn.aem-GridColumn--offset--tablet--1 {
    margin-left: 11.11111111%
  }

  .aem-Grid.aem-Grid--tablet--9>.aem-GridColumn.aem-GridColumn--offset--tablet--2 {
    margin-left: 22.22222222%
  }

  .aem-Grid.aem-Grid--tablet--9>.aem-GridColumn.aem-GridColumn--offset--tablet--3 {
    margin-left: 33.33333333%
  }

  .aem-Grid.aem-Grid--tablet--9>.aem-GridColumn.aem-GridColumn--offset--tablet--4 {
    margin-left: 44.44444444%
  }

  .aem-Grid.aem-Grid--tablet--9>.aem-GridColumn.aem-GridColumn--offset--tablet--5 {
    margin-left: 55.55555556%
  }

  .aem-Grid.aem-Grid--tablet--9>.aem-GridColumn.aem-GridColumn--offset--tablet--6 {
    margin-left: 66.66666667%
  }

  .aem-Grid.aem-Grid--tablet--9>.aem-GridColumn.aem-GridColumn--offset--tablet--7 {
    margin-left: 77.77777778%
  }

  .aem-Grid.aem-Grid--tablet--9>.aem-GridColumn.aem-GridColumn--offset--tablet--8 {
    margin-left: 88.88888889%
  }

  .aem-Grid.aem-Grid--tablet--9>.aem-GridColumn.aem-GridColumn--offset--tablet--9 {
    margin-left: 100%
  }

  .aem-Grid.aem-Grid--tablet--10>.aem-GridColumn.aem-GridColumn--tablet--1 {
    float: left;
    clear: none;
    width: 10%
  }

  .aem-Grid.aem-Grid--tablet--10>.aem-GridColumn.aem-GridColumn--tablet--2 {
    float: left;
    clear: none;
    width: 20%
  }

  .aem-Grid.aem-Grid--tablet--10>.aem-GridColumn.aem-GridColumn--tablet--3 {
    float: left;
    clear: none;
    width: 30%
  }

  .aem-Grid.aem-Grid--tablet--10>.aem-GridColumn.aem-GridColumn--tablet--4 {
    float: left;
    clear: none;
    width: 40%
  }

  .aem-Grid.aem-Grid--tablet--10>.aem-GridColumn.aem-GridColumn--tablet--5 {
    float: left;
    clear: none;
    width: 50%
  }

  .aem-Grid.aem-Grid--tablet--10>.aem-GridColumn.aem-GridColumn--tablet--6 {
    float: left;
    clear: none;
    width: 60%
  }

  .aem-Grid.aem-Grid--tablet--10>.aem-GridColumn.aem-GridColumn--tablet--7 {
    float: left;
    clear: none;
    width: 70%
  }

  .aem-Grid.aem-Grid--tablet--10>.aem-GridColumn.aem-GridColumn--tablet--8 {
    float: left;
    clear: none;
    width: 80%
  }

  .aem-Grid.aem-Grid--tablet--10>.aem-GridColumn.aem-GridColumn--tablet--9 {
    float: left;
    clear: none;
    width: 90%
  }

  .aem-Grid.aem-Grid--tablet--10>.aem-GridColumn.aem-GridColumn--tablet--10 {
    float: left;
    clear: none;
    width: 100%
  }

  .aem-Grid.aem-Grid--tablet--10>.aem-GridColumn.aem-GridColumn--offset--tablet--0 {
    margin-left: 0
  }

  .aem-Grid.aem-Grid--tablet--10>.aem-GridColumn.aem-GridColumn--offset--tablet--1 {
    margin-left: 10%
  }

  .aem-Grid.aem-Grid--tablet--10>.aem-GridColumn.aem-GridColumn--offset--tablet--2 {
    margin-left: 20%
  }

  .aem-Grid.aem-Grid--tablet--10>.aem-GridColumn.aem-GridColumn--offset--tablet--3 {
    margin-left: 30%
  }

  .aem-Grid.aem-Grid--tablet--10>.aem-GridColumn.aem-GridColumn--offset--tablet--4 {
    margin-left: 40%
  }

  .aem-Grid.aem-Grid--tablet--10>.aem-GridColumn.aem-GridColumn--offset--tablet--5 {
    margin-left: 50%
  }

  .aem-Grid.aem-Grid--tablet--10>.aem-GridColumn.aem-GridColumn--offset--tablet--6 {
    margin-left: 60%
  }

  .aem-Grid.aem-Grid--tablet--10>.aem-GridColumn.aem-GridColumn--offset--tablet--7 {
    margin-left: 70%
  }

  .aem-Grid.aem-Grid--tablet--10>.aem-GridColumn.aem-GridColumn--offset--tablet--8 {
    margin-left: 80%
  }

  .aem-Grid.aem-Grid--tablet--10>.aem-GridColumn.aem-GridColumn--offset--tablet--9 {
    margin-left: 90%
  }

  .aem-Grid.aem-Grid--tablet--10>.aem-GridColumn.aem-GridColumn--offset--tablet--10 {
    margin-left: 100%
  }

  .aem-Grid.aem-Grid--tablet--11>.aem-GridColumn.aem-GridColumn--tablet--1 {
    float: left;
    clear: none;
    width: 9.09090909%
  }

  .aem-Grid.aem-Grid--tablet--11>.aem-GridColumn.aem-GridColumn--tablet--2 {
    float: left;
    clear: none;
    width: 18.18181818%
  }

  .aem-Grid.aem-Grid--tablet--11>.aem-GridColumn.aem-GridColumn--tablet--3 {
    float: left;
    clear: none;
    width: 27.27272727%
  }

  .aem-Grid.aem-Grid--tablet--11>.aem-GridColumn.aem-GridColumn--tablet--4 {
    float: left;
    clear: none;
    width: 36.36363636%
  }

  .aem-Grid.aem-Grid--tablet--11>.aem-GridColumn.aem-GridColumn--tablet--5 {
    float: left;
    clear: none;
    width: 45.45454545%
  }

  .aem-Grid.aem-Grid--tablet--11>.aem-GridColumn.aem-GridColumn--tablet--6 {
    float: left;
    clear: none;
    width: 54.54545455%
  }

  .aem-Grid.aem-Grid--tablet--11>.aem-GridColumn.aem-GridColumn--tablet--7 {
    float: left;
    clear: none;
    width: 63.63636364%
  }

  .aem-Grid.aem-Grid--tablet--11>.aem-GridColumn.aem-GridColumn--tablet--8 {
    float: left;
    clear: none;
    width: 72.72727273%
  }

  .aem-Grid.aem-Grid--tablet--11>.aem-GridColumn.aem-GridColumn--tablet--9 {
    float: left;
    clear: none;
    width: 81.81818182%
  }

  .aem-Grid.aem-Grid--tablet--11>.aem-GridColumn.aem-GridColumn--tablet--10 {
    float: left;
    clear: none;
    width: 90.90909091%
  }

  .aem-Grid.aem-Grid--tablet--11>.aem-GridColumn.aem-GridColumn--tablet--11 {
    float: left;
    clear: none;
    width: 100%
  }

  .aem-Grid.aem-Grid--tablet--11>.aem-GridColumn.aem-GridColumn--offset--tablet--0 {
    margin-left: 0
  }

  .aem-Grid.aem-Grid--tablet--11>.aem-GridColumn.aem-GridColumn--offset--tablet--1 {
    margin-left: 9.09090909%
  }

  .aem-Grid.aem-Grid--tablet--11>.aem-GridColumn.aem-GridColumn--offset--tablet--2 {
    margin-left: 18.18181818%
  }

  .aem-Grid.aem-Grid--tablet--11>.aem-GridColumn.aem-GridColumn--offset--tablet--3 {
    margin-left: 27.27272727%
  }

  .aem-Grid.aem-Grid--tablet--11>.aem-GridColumn.aem-GridColumn--offset--tablet--4 {
    margin-left: 36.36363636%
  }

  .aem-Grid.aem-Grid--tablet--11>.aem-GridColumn.aem-GridColumn--offset--tablet--5 {
    margin-left: 45.45454545%
  }

  .aem-Grid.aem-Grid--tablet--11>.aem-GridColumn.aem-GridColumn--offset--tablet--6 {
    margin-left: 54.54545455%
  }

  .aem-Grid.aem-Grid--tablet--11>.aem-GridColumn.aem-GridColumn--offset--tablet--7 {
    margin-left: 63.63636364%
  }

  .aem-Grid.aem-Grid--tablet--11>.aem-GridColumn.aem-GridColumn--offset--tablet--8 {
    margin-left: 72.72727273%
  }

  .aem-Grid.aem-Grid--tablet--11>.aem-GridColumn.aem-GridColumn--offset--tablet--9 {
    margin-left: 81.81818182%
  }

  .aem-Grid.aem-Grid--tablet--11>.aem-GridColumn.aem-GridColumn--offset--tablet--10 {
    margin-left: 90.90909091%
  }

  .aem-Grid.aem-Grid--tablet--11>.aem-GridColumn.aem-GridColumn--offset--tablet--11 {
    margin-left: 100%
  }

  .aem-Grid.aem-Grid--tablet--12>.aem-GridColumn.aem-GridColumn--tablet--1 {
    float: left;
    clear: none;
    width: 8.33333333%
  }

  .aem-Grid.aem-Grid--tablet--12>.aem-GridColumn.aem-GridColumn--tablet--2 {
    float: left;
    clear: none;
    width: 16.66666667%
  }

  .aem-Grid.aem-Grid--tablet--12>.aem-GridColumn.aem-GridColumn--tablet--3 {
    float: left;
    clear: none;
    width: 25%
  }

  .aem-Grid.aem-Grid--tablet--12>.aem-GridColumn.aem-GridColumn--tablet--4 {
    float: left;
    clear: none;
    width: 33.33333333%
  }

  .aem-Grid.aem-Grid--tablet--12>.aem-GridColumn.aem-GridColumn--tablet--5 {
    float: left;
    clear: none;
    width: 41.66666667%
  }

  .aem-Grid.aem-Grid--tablet--12>.aem-GridColumn.aem-GridColumn--tablet--6 {
    float: left;
    clear: none;
    width: 50%
  }

  .aem-Grid.aem-Grid--tablet--12>.aem-GridColumn.aem-GridColumn--tablet--7 {
    float: left;
    clear: none;
    width: 58.33333333%
  }

  .aem-Grid.aem-Grid--tablet--12>.aem-GridColumn.aem-GridColumn--tablet--8 {
    float: left;
    clear: none;
    width: 66.66666667%
  }

  .aem-Grid.aem-Grid--tablet--12>.aem-GridColumn.aem-GridColumn--tablet--9 {
    float: left;
    clear: none;
    width: 75%
  }

  .aem-Grid.aem-Grid--tablet--12>.aem-GridColumn.aem-GridColumn--tablet--10 {
    float: left;
    clear: none;
    width: 83.33333333%
  }

  .aem-Grid.aem-Grid--tablet--12>.aem-GridColumn.aem-GridColumn--tablet--11 {
    float: left;
    clear: none;
    width: 91.66666667%
  }

  .aem-Grid.aem-Grid--tablet--12>.aem-GridColumn.aem-GridColumn--tablet--12 {
    float: left;
    clear: none;
    width: 100%
  }

  .aem-Grid.aem-Grid--tablet--12>.aem-GridColumn.aem-GridColumn--offset--tablet--0 {
    margin-left: 0
  }

  .aem-Grid.aem-Grid--tablet--12>.aem-GridColumn.aem-GridColumn--offset--tablet--1 {
    margin-left: 8.33333333%
  }

  .aem-Grid.aem-Grid--tablet--12>.aem-GridColumn.aem-GridColumn--offset--tablet--2 {
    margin-left: 16.66666667%
  }

  .aem-Grid.aem-Grid--tablet--12>.aem-GridColumn.aem-GridColumn--offset--tablet--3 {
    margin-left: 25%
  }

  .aem-Grid.aem-Grid--tablet--12>.aem-GridColumn.aem-GridColumn--offset--tablet--4 {
    margin-left: 33.33333333%
  }

  .aem-Grid.aem-Grid--tablet--12>.aem-GridColumn.aem-GridColumn--offset--tablet--5 {
    margin-left: 41.66666667%
  }

  .aem-Grid.aem-Grid--tablet--12>.aem-GridColumn.aem-GridColumn--offset--tablet--6 {
    margin-left: 50%
  }

  .aem-Grid.aem-Grid--tablet--12>.aem-GridColumn.aem-GridColumn--offset--tablet--7 {
    margin-left: 58.33333333%
  }

  .aem-Grid.aem-Grid--tablet--12>.aem-GridColumn.aem-GridColumn--offset--tablet--8 {
    margin-left: 66.66666667%
  }

  .aem-Grid.aem-Grid--tablet--12>.aem-GridColumn.aem-GridColumn--offset--tablet--9 {
    margin-left: 75%
  }

  .aem-Grid.aem-Grid--tablet--12>.aem-GridColumn.aem-GridColumn--offset--tablet--10 {
    margin-left: 83.33333333%
  }

  .aem-Grid.aem-Grid--tablet--12>.aem-GridColumn.aem-GridColumn--offset--tablet--11 {
    margin-left: 91.66666667%
  }

  .aem-Grid.aem-Grid--tablet--12>.aem-GridColumn.aem-GridColumn--offset--tablet--12 {
    margin-left: 100%
  }

  .aem-Grid>.aem-GridColumn.aem-GridColumn--tablet--newline {
    display: block;
    clear: both !important
  }

  .aem-Grid>.aem-GridColumn.aem-GridColumn--tablet--none {
    display: block;
    clear: none !important;
    float: left
  }

  .aem-Grid>.aem-GridColumn.aem-GridColumn--tablet--hide {
    display: none
  }
}

.aem-GridShowHidden>.cmp-container>.aem-Grid>.aem-GridColumn {
  display: block !important
}


.wipro-dot__period:not(:has(p)):after {
  color: var(--var-dot-color);
  content: ".";
  display: inline;
  font-family: var(--body-font-family);
  font-size: 56px;
  line-height: 0;
  margin-top: -40px
}

.wipro-dot__exclamation:not(:has(p)):after {
  color: var(--var-dot-color);
  content: "!";
  display: inline;
  font-family: var(--body-font-family);
  font-size: 56px;
  line-height: 0;
  margin-top: -40px
}

.wipro-dot__question:not(:has(p)):after {
  color: var(--var-dot-color);
  content: "?";
  display: inline;
  font-family: var(--body-font-family);
  font-size: 56px;
  line-height: 0;
  margin-top: -40px
}

.wipro-dot__period p:after {
  content: "."
}

.wipro-dot__exclamation p:after,
.wipro-dot__period p:after {
  color: var(--var-dot-color);
  display: inline;
  font-family: var(--body-font-family);
  font-size: 56px;
  line-height: 0;
  margin-top: -40px
}

.wipro-dot__exclamation p:after {
  content: "!"
}

.wipro-dot__question p:after {
  color: var(--var-dot-color);
  content: "?";
  display: inline;
  font-family: var(--body-font-family);
  font-size: 56px;
  line-height: 0;
  margin-top: -40px
}

body,
html {
  color: #202020;
  font-family: var(--body-font-family);
  font-size: 18px;
  line-height: 1.5;
  margin: 0
}

a {
  color: #3198b3;
  text-decoration: none !important;
  list-style: none !important;
}



button,
input,
optgroup,
select,
textarea {
  font: inherit
}

/* 
.root {
  padding-top: 110px !important
} */



.header {
  padding-left: 15px;
  padding-right: 15px
}

.automatic-list__form,
.automatic-list__form-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex
}

.automatic-list__form {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  -ms-flex-direction: row;
  flex-direction: row;
  gap: 10px;
  justify-content: flex-end;
  margin: 15px;
  width: 100%
}

.automatic-list__filter-dropdown {
  display: none
}

.automatic-list__filter-dropdown-button {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  background-color: #fff;
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, #fff), to(#e0e0e0));
  background-image: linear-gradient(180deg, #fff 0, #e0e0e0);
  border: 2px solid #adadad;
  border-radius: 50px;
  color: #333;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
  padding: 10px 25px;
  white-space: nowrap;
  width: 200px
}

.automatic-list__filter-dropdown-button:hover {
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, #fff), to(#adadad));
  background-image: linear-gradient(180deg, #fff, #adadad)
}

.automatic-list__filter-dropdown-button-label {
  -ms-flex-item-align: center;
  align-self: center;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis
}

.automatic-list__filter-dropdown-button-arrow {
  font-size: 20px
}

.automatic-list__filter-dropdown-button-arrow span {
  color: #000
}

.automatic-list__filter-dropdown-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex
}

.automatic-list__filter-dropdown-container.open .automatic-list__filter-dropdown-visible {
  display: block
}

.automatic-list__filter-dropdown-container.open .automatic-list__filter-dropdown-button-arrow span {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg)
}

.automatic-list__filter-dropdown-visible {
  background-color: #fff;
  border: 2px solid #ccc;
  border-radius: 10px;
  -webkit-box-shadow: 0 6px 9pt rgba(0, 0, 0, .18);
  box-shadow: 0 6px 9pt rgba(0, 0, 0, .18);
  display: none;
  margin-top: 55px;
  max-height: 300px;
  overflow-y: scroll;
  position: absolute;
  width: 200px
}

.automatic-list__filter-dropdown-visible:-webkit-scrollbar {
  width: 10px
}

.automatic-list__filter-dropdown-visible:-webkit-scrollbar-track {
  background: transparent
}

.automatic-list__filter-dropdown-visible:-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px
}

.automatic-list__filter-dropdown-visible div {
  border-bottom: 1px solid #ccc;
  border-right: 1px solid #ccc;
  cursor: pointer;
  padding: 13px
}

.automatic-list__filter-dropdown-visible div.selected,
.automatic-list__filter-dropdown-visible div:hover {
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, #f5f5f5), to(#e8e8e8));
  background-image: linear-gradient(180deg, #f5f5f5 0, #e8e8e8)
}

.automatic-list__list-container .cmp-list {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  all: unset;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column
}

.automatic-list__list-container .cmp-list__item {
  all: unset
}

.automatic-list__list-container .cmp-list__item-properties {
  display: none
}

.automatic-list__list-container.event-list .cmp-list {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap
}

.automatic-list__list-container.event-list .cmp-list__item {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  border: 1px solid rgba(0, 0, 0, .4);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: 9pt 15px;
  padding: 10px 20px;
  width: calc(33.33333% - 72px)
}

.automatic-list__list-container.event-list .cmp-list__item-title {
  color: #000;
  font-family: var(--body-font-family);
  font-size: 22px;
  line-height: 28px;
  margin-bottom: 10px
}

.automatic-list__list-container.event-list .cmp-list__item-title:before {
  background: #00a7ff;
  content: "";
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 6px;
  margin-bottom: 25px;
  margin-top: 15px;
  width: 3.8em
}

.automatic-list__list-container.event-list .cmp-list__item-date {
  color: #707070;
  font-family: var(--body-font-family);
  font-size: 18px;
  line-height: 24px
}

.automatic-list__list-container.event-list .cmp-list__item-description {
  -ms-flex-preferred-size: 60%;
  color: #555;
  flex-basis: 60%;
  font-family: var(--body-font-family);
  font-size: 18px;
  margin-bottom: 10px
}

.automatic-list__list-container.event-list .cmp-list__item-link {
  border: 1px solid #c4c4c4;
  border-radius: 50px;
  color: #000;
  font-family: var(--body-font-family);
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 20px;
  padding: 10px 30px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content
}

.automatic-list__list-container.event-list .cmp-list__item:hover {
  background-color: #00a7ff;
  border-color: #00a7ff
}

.automatic-list__list-container.event-list .cmp-list__item:hover .cmp-list__item-date,
.automatic-list__list-container.event-list .cmp-list__item:hover .cmp-list__item-description,
.automatic-list__list-container.event-list .cmp-list__item:hover .cmp-list__item-title {
  color: #fff
}

.automatic-list__list-container.event-list .cmp-list__item:hover .cmp-list__item-date:before,
.automatic-list__list-container.event-list .cmp-list__item:hover .cmp-list__item-description:before,
.automatic-list__list-container.event-list .cmp-list__item:hover .cmp-list__item-title:before {
  background: #fff
}

.automatic-list__list-container.event-list .cmp-list__item:hover .cmp-list__item-link {
  background-color: #fff;
  color: #00a7ff
}

.automatic-list__list-container.newsflash-list .cmp-list__item,
.automatic-list__list-container.press-release-list .cmp-list__item,
.automatic-list__list-container.wipro-news-list .cmp-list__item {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  border-bottom: 1px solid rgba(0, 0, 0, .4);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 10px 0 20px
}

.automatic-list__list-container.newsflash-list .cmp-list__item-title,
.automatic-list__list-container.press-release-list .cmp-list__item-title,
.automatic-list__list-container.wipro-news-list .cmp-list__item-title {
  font-family: var(--body-font-family);
  font-size: 22px;
  line-height: 2pc;
  margin-bottom: 10px
}

.automatic-list__list-container.newsflash-list .cmp-list__item-date,
.automatic-list__list-container.newsflash-list .cmp-list__item-description,
.automatic-list__list-container.newsflash-list .cmp-list__item-source,
.automatic-list__list-container.press-release-list .cmp-list__item-date,
.automatic-list__list-container.press-release-list .cmp-list__item-description,
.automatic-list__list-container.press-release-list .cmp-list__item-source,
.automatic-list__list-container.wipro-news-list .cmp-list__item-date,
.automatic-list__list-container.wipro-news-list .cmp-list__item-description,
.automatic-list__list-container.wipro-news-list .cmp-list__item-source {
  font-family: var(--body-font-family);
  font-size: 18px;
  line-height: 26px;
  margin-bottom: 15px;
  vertical-align: middle
}

.automatic-list__list-container.newsflash-list .cmp-list__item-link,
.automatic-list__list-container.press-release-list .cmp-list__item-link,
.automatic-list__list-container.wipro-news-list .cmp-list__item-link {
  border: 2px solid #ccc;
  border-radius: 50px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: rgba(0, 0, 0, .6);
  font-family: var(--body-font-family);
  font-size: 18px;
  line-height: 14px;
  padding: 10px 20px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content
}

.automatic-list__list-container .cmp-list__item.disappear {
  display: none
}

.banner {
  font-family: var(--body-font-family);
}

.banner .cmp-teaser {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex
}

.banner .cmp-teaser__play-container {
  position: absolute;
  right: calc(25% - 50px);
  top: calc(75% - 50px)
}

@media only screen and (min-width: 768px) {
  .banner .cmp-teaser__play-container {
    top: calc(50% - 50px)
  }
}

.banner .cmp-teaser__image {
  height: 100%;
  overflow: hidden;
  position: absolute;
  width: 100%
}

.banner .cmp-teaser__image video {
  height: 100%;
  pointer-events: none;
  width: 100%
}

.banner .cmp-teaser__image .cmp-image {
  height: 100%
}

.banner .cmp-teaser__image .cmp-image canvas,
.banner .cmp-teaser__image .cmp-image img {
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%
}

.banner .cmp-teaser__image .cmp-image #pdf-canvas {
  border: 1px solid rgba(0, 0, 0, .2);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: block
}

.banner .cmp-teaser__mobile-image {
  display: block
}

@media only screen and (min-width: 768px) {
  .banner .cmp-teaser__mobile-image {
    display: none
  }
}

.banner .cmp-teaser__tablet-image {
  display: block
}

@media only screen and (min-width: 1200px) {
  .banner .cmp-teaser__tablet-image {
    display: none
  }
}

.banner .cmp-teaser .cmp-teaser__mobile-image~.cmp-teaser__desktop-image {
  display: none
}

@media only screen and (min-width: 768px) {
  .banner .cmp-teaser .cmp-teaser__mobile-image~.cmp-teaser__desktop-image {
    display: block
  }
}

.banner .cmp-teaser .cmp-teaser__mobile-image~.cmp-teaser__tablet-image {
  display: none
}

@media only screen and (min-width: 768px) {
  .banner .cmp-teaser .cmp-teaser__mobile-image~.cmp-teaser__tablet-image {
    display: block
  }
}

.banner .cmp-teaser .cmp-teaser__tablet-image~.cmp-teaser__desktop-image {
  display: none
}

@media only screen and (min-width: 1200px) {
  .banner .cmp-teaser .cmp-teaser__tablet-image~.cmp-teaser__desktop-image {
    display: block
  }
}

.banner .cmp-teaser__content {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  justify-content: center;
  margin-left: 4pc;
  margin-right: 4pc;
  min-height: 357px;
  z-index: 1
}

@media only screen and (min-width: 768px)and (max-width:1200px) {
  .banner .cmp-teaser__content {
    margin-left: 9pc;
    margin-right: 9pc
  }
}

@media only screen and (min-width: 1200px) {
  .banner .cmp-teaser__content {
    margin-left: auto;
    margin-right: auto;
    max-width: 75pc
  }
}

@media only screen and (min-width: 768px) {
  .banner .cmp-teaser__content {
    width: calc(100% - 8pc)
  }
}

@media only screen and (max-width: 768px) {
  .banner .cmp-teaser__content--show-tout-background {
    background-color: rgba(0, 0, 0, .55);
    margin: 0;
    padding: 4pc
  }
}

@media only screen and (min-width: 768px) {
  .banner .cmp-teaser__content--show-tout-background .cmp-teaser__tout {
    background-color: rgba(0, 0, 0, .55);
    padding: 70px 90pt
  }
}

.banner .cmp-teaser__tout {
  margin-bottom: 4pc;
  margin-top: 4pc;
  width: 100%
}

@media only screen and (min-width: 768px) {
  .banner .cmp-teaser__tout {
    max-width: 500px
  }
}

@media only screen and (min-width: 1200px) {
  .banner .cmp-teaser__tout {
    max-width: 600px
  }
}

.banner .cmp-teaser__pretitle {
  font-size: 14px
}

.banner .cmp-teaser .cmp-breadcrumb__list {
  margin: 0;
  padding: 0
}

.banner .cmp-teaser .cmp-breadcrumb__item:not(:last-child):after {
  content: ">"
}

.banner .cmp-teaser .cmp-breadcrumb__item-link {
  all: unset;
  cursor: pointer;
  display: inline-block;
  text-decoration: underline
}

.banner .cmp-teaser__icon-container {
  padding-bottom: 2pc
}

.banner .cmp-teaser__icon-container img {
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: 0 100%;
  object-position: 0 100%;
  width: 100%
}

.banner .cmp-teaser__title {
  display: inline;
  font-size: 38px;
  font-weight: 700;
  line-height: 41.8px;
  margin-top: 0
}

.banner .cmp-teaser__title h1,
.banner .cmp-teaser__title h2,
.banner .cmp-teaser__title h3 {
  display: inline;
  font-size: inherit;
  font-weight: inherit
}

.banner .cmp-teaser__action-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 40px
}

.banner .cmp-teaser__action-link {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  all: unset;
  align-items: center;
  border-radius: 53px;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 14px;
  justify-content: center;
  margin-bottom: 1pc;
  margin-right: 2pc;
  margin-top: 1pc;
  max-width: 20pc;
  min-width: 10pc;
  padding: 9pt;
  text-align: center
}

.banner .cmp-teaser__action-link:hover {
  text-decoration: none
}

.banner .cmp-teaser__floatingcard-placeholder,
.banner .cmp-teaser__playicon--hidden {
  display: none
}

.banner .cmp-teaser__background-image-parallax {
  background-attachment: fixed;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%
}

.banner .cmp-teaser__gradient-overlay {
  background: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, .85)), to(transparent));
  background: linear-gradient(90deg, rgba(0, 0, 0, .85), transparent);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1
}

.banner .cmp-teaser__custom-gradient-overlay {
  background: -webkit-gradient(linear, left top, left bottom, from(var(--var-gradient-direction, 90deg)), color-stop(var(--var-gradient-color, rgba(0, 0, 0, .85))), to(transparent));
  background: linear-gradient(var(--var-gradient-direction, 90deg), var(--var-gradient-color, rgba(0, 0, 0, .85)) var(--var-gradient-position, 0), transparent 100%);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1
}

@media only screen and (max-width: 768px) {
  .banner .cmp-teaser__gradient-overlay {
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, .85)), to(transparent));
    background: linear-gradient(180deg, rgba(0, 0, 0, .85), transparent)
  }

  .banner .cmp-teaser__custom-gradient-overlay {
    background: -webkit-gradient(linear, left top, left bottom, from(var(--var-gradient-direction, 180deg)), color-stop(var(--var-gradient-color, rgba(0, 0, 0, .85))), to(transparent));
    background: linear-gradient(var(--var-gradient-direction, 180deg), var(--var-gradient-color, rgba(0, 0, 0, .85)) var(--var-gradient-position, 0), transparent 100%)
  }
}

.banner .cmp-teaser__theme-white {
  color: #fff
}

.banner .cmp-teaser__theme-white .cmp-teaser__action-container .cmp-teaser__action-link {
  background-color: #fff;
  color: #372358
}

.banner .cmp-teaser__theme-white .cmp-teaser__action-container .cmp-teaser__action-link:hover {
  color: #372358
}

.banner .cmp-teaser__theme-white .cmp-teaser__action-container .cmp-teaser__action-link .cmp-teaser__action-icon svg {
  stroke: #372358
}

.banner .cmp-teaser__theme-white .cmp-teaser__play-icon {
  stroke: #fff
}

.banner .cmp-teaser__theme-primary {
  color: #372358
}

.banner .cmp-teaser__theme-primary .cmp-teaser__action-container .cmp-teaser__action-link {
  background-color: #372358;
  color: #fff
}

.banner .cmp-teaser__theme-primary .cmp-teaser__action-container .cmp-teaser__action-link:hover {
  color: #fff
}

.banner .cmp-teaser__theme-primary .cmp-teaser__action-container .cmp-teaser__action-link .cmp-teaser__action-icon svg {
  stroke: #fff
}

.banner .cmp-teaser__theme-primary .cmp-teaser__play-icon {
  stroke: #372358
}

.banner .cmp-teaser__theme-grey {
  color: #000
}

.banner .cmp-teaser__theme-grey .cmp-teaser__action-container .cmp-teaser__action-link {
  background-color: #000;
  color: #fff
}

.banner .cmp-teaser__theme-grey .cmp-teaser__action-container .cmp-teaser__action-link:hover {
  color: #333
}

.banner .cmp-teaser__theme-grey .cmp-teaser__action-container .cmp-teaser__action-link .cmp-teaser__action-icon svg {
  stroke: #1b1b1b
}

.banner .cmp-teaser__theme-grey .cmp-teaser__play-icon {
  stroke: #000
}

.banner .cmp-teaser__play-container--center {
  position: absolute;
  right: calc(50% - 50px);
  top: calc(50% - 50px)
}

.banner .cmp-teaser__play-container--right {
  position: absolute;
  right: calc(25% - 50px);
  top: calc(75% - 50px)
}

@media only screen and (min-width: 768px) {
  .banner .cmp-teaser__play-container--right {
    top: calc(50% - 50px)
  }
}

.banner--content-left .cmp-teaser__content {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  text-align: left
}

.banner--content-center .cmp-teaser__content {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center
}

.banner--content-right .cmp-teaser__content {
  text-align: right
}

.banner--content-right .cmp-teaser__content,
.banner--tout-right .cmp-teaser__content {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end
}

.carouselbanner .cmp-carousel__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex
}

.carouselbanner .cmp-carousel__item .banner {
  width: 100%
}

.carouselbanner .cmp-carousel__actions,
.carouselbanner .cmp-carousel__indicators {
  display: none
}

.carouselbanner .cmp-carousel__toggle-button {
  bottom: 20px;
  display: inline-block;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  -webkit-transform: translateX(66px);
  transform: translateX(66px);
  width: 20px;
  z-index: 99
}

.carouselbanner .cmp-carousel__toggle-button .play-btn {
  border-bottom: 6px solid transparent;
  border-left: 10px solid #fff;
  border-top: 6px solid transparent;
  cursor: pointer;
  display: block;
  float: right;
  height: 9pt;
  width: 9px
}

.carouselbanner .cmp-carousel__toggle-button .play-btn.disappear {
  display: none
}

.carouselbanner .cmp-carousel__toggle-button .pause-btn {
  border-left: 3px solid #fff;
  border-right: 3px solid #fff;
  cursor: pointer;
  float: right;
  height: 9pt;
  width: 9px
}

.carouselbanner .cmp-carousel__toggle-button .pause-btn.disappear {
  display: none
}

.carouselbanner .cmp-carousel .slick-dots {
  all: unset;
  bottom: 20px;
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  text-align: center;
  width: 100%
}

.carouselbanner .cmp-carousel .slick-dots li {
  cursor: pointer;
  display: inline-block;
  height: 10px;
  margin: 0 5px;
  padding: 0;
  position: relative;
  width: 10px
}

.carouselbanner .cmp-carousel .slick-dots li.slick-active button {
  background-color: #9b9b9b;
  opacity: 1
}

.carouselbanner .cmp-carousel .slick-dots button {
  background: #f5f5f5;
  border: 0;
  border-radius: 50px;
  color: transparent;
  cursor: pointer;
  display: block;
  font-size: 0;
  height: 10px;
  line-height: 0;
  outline: none;
  padding: 5px;
  width: 10px
}

.casestudy.teaser {
  -ms-flex-item-align: stretch;
  align-self: stretch;
  margin-bottom: 68px;
  margin-left: 68px;
  margin-right: 68px
}

@media only screen and (min-width: 768px)and (max-width:1200px) {
  .casestudy.teaser {
    margin-left: 140px;
    margin-right: 140px
  }
}

@media only screen and (min-width: 1200px) {
  .casestudy.teaser {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
    max-width: 100%;
    min-width: 100%;
    padding-right: 74px
  }

  .casestudy.teaser:nth-child(2n) {
    padding-left: 74px;
    padding-right: 0
  }

  .casestudy.teaser:nth-child(odd) {
    border-right: 1px solid #fff
  }

  .casestudy.teaser:nth-last-child(-n+3):nth-child(odd) {
    border-right-width: 0;
    grid-column: span 2;
    padding-left: 0;
    padding-right: 0
  }
}

.casestudy.teaser .cmp-teaser {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: center;
  align-items: flex-start;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  font-family: var(--body-font-family);
  font-weight: 400;
  height: 100%;
  width: 100%
}

@media only screen and (min-width: 1200px) {
  .casestudy.teaser .cmp-teaser {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    margin-left: auto;
    margin-right: auto
  }
}

.casestudy.teaser .cmp-teaser__action-background,
.casestudy.teaser .cmp-teaser__content-wrapper {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%
}

.casestudy.teaser .cmp-teaser__pretitle {
  font-size: 18px;
  line-height: 22px
}

.casestudy.teaser .cmp-teaser__pretitle p {
  margin: 0
}

.casestudy.teaser .cmp-teaser__title {
  font-size: 36px;
  font-weight: 700;
  line-height: 36px
}

.casestudy.teaser .cmp-teaser__description {
  font-size: 1pc;
  font-weight: 400;
  line-height: 19px
}

.casestudy.teaser .cmp-teaser__action-container {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-bottom: 50px;
  margin-top: auto
}

.casestudy.teaser .cmp-teaser__action-container .cmp-teaser__action-link {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: var(--var-button-color, #fff);
  border-radius: 53px;
  color: var(--var-button-text-color, #372358);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 14px;
  justify-content: center;
  max-width: 20pc;
  min-width: 10pc;
  padding: 9pt
}

.casestudy.teaser .cmp-teaser__action-link {
  margin-top: auto
}

.casestudy.teaser .cmp-teaser__content {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
  margin-top: auto
}

.casestudy.teaser .cmp-teaser__link {
  all: unset;
  cursor: pointer
}

.casestudy.teaser .cmp-teaser__link:hover {
  text-decoration: underline
}

.casestudy.teaser.case--grey-color {
  background: #000
}

.casestudycontainer {
  background-color: #372358
}

.casestudycontainer .cmp-container {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  justify-items: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 75pc;
  padding-top: 68px
}

@media only screen and (min-width: 768px)and (max-width:1200px) {
  .casestudycontainer .cmp-container {
    padding-top: 95px
  }
}

@media only screen and (min-width: 1200px) {
  .casestudycontainer .cmp-container {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    display: grid;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    grid-template-columns: repeat(2, 1fr);
    padding-bottom: 68px
  }
}

.casestudycontainer .cmp-container .cmp-container__button {
  grid-column: 1/-1
}

.casestudycontainer .cmp-container .cmp-container__button .cmp-button {
  color: var(--var-container-color);
  font-size: 14px;
  line-height: 17px
}

.casestudycontainer .cmp-container .cmp-container__button-container {
  grid-column: 1/3
}

.casestudycontainer .cmp-container .cmp-container__button.expand .cmp-button {
  background-color: var(--var-expand-button-color, var(--var-button-color, #fff)) !important;
  color: var(--var-expand-button-text-color, var(--var-button-text-color, #372358)) !important
}

.casestudycontainer .cmp-container__popup .casestudy.teaser {
  border-bottom: 1px solid #ccc;
  padding-right: 0
}

.casestudycontainer .cmp-container__popup .casestudy.teaser:nth-child(2n) {
  padding-left: 0
}

@media only screen and (min-width: 1200px) {
  .casestudycontainer .cmp-container__popup .casestudy.teaser:nth-child(2n) {
    padding-left: 50px
  }

  .casestudycontainer .cmp-container__popup .casestudy.teaser:nth-child(2n-1) {
    border-right: 1px solid #ccc;
    padding-right: 50px
  }

  .casestudycontainer .cmp-container__popup .casestudy.teaser {
    border-bottom: none;
    width: 45%
  }
}

.casestudycontainer .cmp-container__popup .casestudy.teaser .cmp-teaser__description,
.casestudycontainer .cmp-container__popup .casestudy.teaser .cmp-teaser__pretitle,
.casestudycontainer .cmp-container__popup .casestudy.teaser .cmp-teaser__title {
  color: #000
}

.casestudycontainer .cmp-container__popup .cmp-container__popup-content {
  padding: 20px 40px
}

@media only screen and (min-width: 1200px) {
  .casestudycontainer .cmp-container__popup .cmp-container__popup-content {
    gap: 0
  }
}

.cmp-contactdetail {
  background: #fff;
  border: 1px solid rgba(0, 0, 0, .1);
  border-radius: 4px
}

.cmp-contactdetail__container {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 30px 40px
}

.cmp-contactdetail__name {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 14px
}

.cmp-contactdetail__address,
.cmp-contactdetail__email,
.cmp-contactdetail__phone,
.cmp-contactdetail__position {
  font-size: 18px;
  font-weight: 400
}

.cmp-contactdetail__address p,
.cmp-contactdetail__email p,
.cmp-contactdetail__phone p,
.cmp-contactdetail__position p {
  margin: 0
}

.contactdetailcontainer .cmp-container {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 22px;
  margin: 0 auto;
  max-width: 83.5pc;
  padding: 0 68px
}

.contactdetailcontainer .cmp-teaser__floatingcard {
  display: none
}

.contactdetailcontainer .contactdetail {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  width: calc(50% - 11px)
}

.contactdetailcontainer .contactdetail__wide {
  -webkit-box-flex: 2;
  -ms-flex-positive: 2;
  flex-grow: 2
}

@media only screen and (max-width: 768px) {
  .contactdetailcontainer .contactdetail {
    width: 100%
  }
}



.container.container--grey-color {
  background: #1b1b1b
}

.container.container--black-color {
  background: #000
}

.container .collapsed {
  display: none
}

.container .cmp-container__button {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  margin-bottom: 56px;
  width: 100%
}

@media only screen and (min-width: 1200px) {
  .container .cmp-container__button {
    margin-top: 75px
  }
}

.container .cmp-container__button-container {
  width: 100%
}

.container .cmp-container .cmp-button {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: var(--var-button-color);
  border-radius: 53px;
  border-width: 0;
  color: var(--var-button-text-color);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 14px;
  height: 40px;
  justify-content: center;
  line-height: 17px;
  max-width: 20pc;
  min-width: 10pc;
  padding: 9pt;
  text-align: center;
  width: 10pc
}

.container .cmp-container .cmp-container__button.expand .cmp-button {
  background-color: var(--var-expand-button-color);
  color: var(--var-expand-button-text-color)
}

.container .cmp-container .cmp-container__button.collapse .cmp-button {
  background-color: var(--var-collapse-button-color);
  color: var(--var-collapse-button-text-color)
}

.container .cmp-container__data {
  display: none
}

.container .cmp-container__parallax {
  background-attachment: fixed;
  background-position: 50%
}

.container .cmp-container__popup {
  background-color: #fff;
  border-radius: 10px;
  -webkit-box-shadow: 10px 10px 25px 0 rgba(0, 0, 0, .5);
  box-shadow: 10px 10px 25px 0 rgba(0, 0, 0, .5);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  left: 5%;
  max-height: 70vh;
  position: fixed;
  top: 15vh;
  width: 90%;
  z-index: 100000000000
}

.container .cmp-container__popup-container {
  background-color: #fff;
  margin: 20px 10px;
  overflow-y: scroll
}

.container .cmp-container__popup-container:-webkit-scrollbar {
  width: 8px
}

.container .cmp-container__popup-container:-webkit-scrollbar-track {
  background: transparent
}

.container .cmp-container__popup-container:-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 10px
}

.container .cmp-container__popup-title {
  color: #362358;
  font-size: 44px;
  font-weight: 700;
  line-height: 54px;
  margin-bottom: 20px;
  text-align: center
}

.container .cmp-container__popup-close {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #fff;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 40px;
  justify-content: center;
  position: absolute;
  right: 20px;
  top: 20px;
  width: 40px
}

.container .cmp-container__popup-close:after {
  color: #000;
  content: "X";
  font-size: 34px;
  line-height: 40px
}

.container .cmp-container__popup-close.svg-loaded:after {
  display: none
}

.container .cmp-container__popup-close img {
  height: 25px;
  width: 25px
}

.container .cmp-container__popup-content {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  padding: 20px;
  width: 100%
}

.container .cmp-container__popup-background {
  background-color: rgba(0, 0, 0, .8);
  border-radius: 10px;
  display: none;
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 10000000000
}

.container .cmp-container__popup.hide {
  display: none
}

.container .cmp-container.popup-open .cmp-container__popup-background {
  display: block
}

@media only screen and (min-width: 1200px) {
  .container--layout-horizontal>.cmp-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
  }
}

.container--width-regular>.cmp-container {
  margin-left: 2pc;
  margin-right: 2pc
}

@media only screen and (min-width: 768px)and (max-width:1200px) {
  .container--width-regular>.cmp-container {
    margin-left: 9pc;
    margin-right: 9pc
  }
}

@media only screen and (min-width: 1200px) {
  .container--width-regular>.cmp-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 75pc
  }
}

.container--width-regular.container--full-width>.cmp-container {
  margin-left: unset;
  margin-right: unset;
  padding-left: 2pc;
  padding-right: 2pc
}

@media only screen and (min-width: 1200px) {
  .container--width-regular.container--full-width>.cmp-container {
    max-width: 100%;
    padding-left: calc(50% - 600px);
    padding-right: calc(50% - 600px)
  }
}

.container--width-narrow>.cmp-container {
  margin-left: 2pc;
  margin-right: 2pc
}

@media only screen and (min-width: 768px)and (max-width:1200px) {
  .container--width-narrow>.cmp-container {
    margin-left: 9pc;
    margin-right: 9pc
  }
}

@media only screen and (min-width: 1200px) {
  .container--width-narrow>.cmp-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 8in
  }
}

.container--width-narrow.container--full-width>.cmp-container {
  margin-left: unset;
  margin-right: unset;
  padding-left: 2pc;
  padding-right: 2pc
}

@media only screen and (min-width: 1200px) {
  .container--width-narrow.container--full-width>.cmp-container {
    max-width: 100%;
    padding-left: calc(50% - 4in);
    padding-right: calc(50% - 4in)
  }
}

.container--width-wide>.cmp-container {
  margin-left: 2pc;
  margin-right: 2pc
}

@media only screen and (min-width: 768px) {
  .container--width-wide>.cmp-container {
    margin-left: 9pc;
    margin-right: 9pc
  }
}

.container--width-wide.container--full-width>.cmp-container {
  margin-left: unset;
  margin-right: unset
}

@media only screen and (min-width: 1200px) {
  .container--width-wide.container--full-width>.cmp-container {
    max-width: 100%;
    padding-left: calc(50% - 60pc);
    padding-right: calc(50% - 60pc)
  }
}

.container--items-in-row-2>.cmp-container {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 1pc;
  justify-content: space-between
}

@media only screen and (min-width: 768px)and (max-width:1200px) {
  .container--items-in-row-2>.cmp-container>* {
    width: 100%
  }
}

@media only screen and (min-width: 1200px) {
  .container--items-in-row-2>.cmp-container>* {
    width: calc(50% - 8px)
  }
}

.container--items-in-row-3>.cmp-container {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 1pc;
  justify-content: space-between
}

@media only screen and (min-width: 768px)and (max-width:1200px) {
  .container--items-in-row-3>.cmp-container>* {
    width: calc(50% - 8px)
  }
}

@media only screen and (min-width: 1200px) {
  .container--items-in-row-3>.cmp-container>* {
    width: calc(33.33333% - 10.66667px)
  }
}

.container--items-in-row-4>.cmp-container {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 1pc;
  justify-content: space-between
}

@media only screen and (min-width: 768px)and (max-width:1200px) {
  .container--items-in-row-4>.cmp-container>* {
    width: calc(33.33333% - 10.66667px)
  }
}

@media only screen and (min-width: 1200px) {
  .container--items-in-row-4>.cmp-container>* {
    width: calc(25% - 9pt)
  }
}

.container--items-in-row-1-2>.cmp-container {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 1pc;
  justify-content: space-between
}

@media only screen and (min-width: 768px)and (max-width:1200px) {
  .container--items-in-row-1-2>.cmp-container>:nth-child(odd) {
    margin: unset;
    width: 32.3333333333%
  }
}

@media only screen and (min-width: 1200px) {
  .container--items-in-row-1-2>.cmp-container>:nth-child(odd) {
    margin: unset;
    width: 32.3333333333%
  }
}

@media only screen and (min-width: 768px)and (max-width:1200px) {
  .container--items-in-row-1-2>.cmp-container>:nth-child(2n) {
    margin: unset;
    width: 65.6666666667%
  }
}

@media only screen and (min-width: 1200px) {
  .container--items-in-row-1-2>.cmp-container>:nth-child(2n) {
    margin: unset;
    width: 65.6666666667%
  }
}

.container--items-in-row-1-2 .cmp-container__button.collapse,
.container--items-in-row-1-2 .cmp-container__button.expand {
  margin: auto;
  width: 32.3333333333%
}

.container--items-in-row-2-1>.cmp-container {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 1pc;
  justify-content: space-between
}

@media only screen and (min-width: 768px)and (max-width:1200px) {
  .container--items-in-row-2-1>.cmp-container>:nth-child(odd) {
    margin: unset;
    width: 65.6666666667%
  }
}

@media only screen and (min-width: 1200px) {
  .container--items-in-row-2-1>.cmp-container>:nth-child(odd) {
    margin: unset;
    width: 65.6666666667%
  }
}

@media only screen and (min-width: 768px)and (max-width:1200px) {
  .container--items-in-row-2-1>.cmp-container>:nth-child(2n) {
    margin: unset;
    width: 32.3333333333%
  }
}

@media only screen and (min-width: 1200px) {
  .container--items-in-row-2-1>.cmp-container>:nth-child(2n) {
    margin: unset;
    width: 32.3333333333%
  }
}

.container--items-in-row-2-1 .cmp-container__button.collapse,
.container--items-in-row-2-1 .cmp-container__button.expand {
  margin: auto;
  width: 32.3333333333%
}

.container--items-in-row-1-3>.cmp-container {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 1pc;
  justify-content: space-between
}

@media only screen and (min-width: 768px)and (max-width:1200px) {
  .container--items-in-row-1-3>.cmp-container>:nth-child(odd) {
    margin: unset;
    width: 24%
  }
}

@media only screen and (min-width: 1200px) {
  .container--items-in-row-1-3>.cmp-container>:nth-child(odd) {
    margin: unset;
    width: 24%
  }
}

@media only screen and (min-width: 768px)and (max-width:1200px) {
  .container--items-in-row-1-3>.cmp-container>:nth-child(2n) {
    margin: unset;
    width: 74%
  }
}

@media only screen and (min-width: 1200px) {
  .container--items-in-row-1-3>.cmp-container>:nth-child(2n) {
    margin: unset;
    width: 74%
  }
}

.container--items-in-row-1-3 .cmp-container__button.collapse,
.container--items-in-row-1-3 .cmp-container__button.expand {
  margin: auto;
  width: 24%
}

.container--items-in-row-3-1>.cmp-container {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 1pc;
  justify-content: space-between
}

@media only screen and (min-width: 768px)and (max-width:1200px) {
  .container--items-in-row-3-1>.cmp-container>:nth-child(odd) {
    margin: unset;
    width: 74%
  }
}

@media only screen and (min-width: 1200px) {
  .container--items-in-row-3-1>.cmp-container>:nth-child(odd) {
    margin: unset;
    width: 74%
  }
}

@media only screen and (min-width: 768px)and (max-width:1200px) {
  .container--items-in-row-3-1>.cmp-container>:nth-child(2n) {
    margin: unset;
    width: 24%
  }
}

@media only screen and (min-width: 1200px) {
  .container--items-in-row-3-1>.cmp-container>:nth-child(2n) {
    margin: unset;
    width: 24%
  }
}

.container--items-in-row-3-1 .cmp-container__button.collapse,
.container--items-in-row-3-1 .cmp-container__button.expand {
  margin: auto;
  width: 24%
}

.container--items-in-row-1-1-2>.cmp-container {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 1pc;
  justify-content: space-between
}

@media only screen and (min-width: 768px)and (max-width:1200px) {
  .container--items-in-row-1-1-2>.cmp-container>:nth-child(3n+1) {
    margin: unset;
    width: 24%
  }
}

@media only screen and (min-width: 1200px) {
  .container--items-in-row-1-1-2>.cmp-container>:nth-child(3n+1) {
    margin: unset;
    width: 24%
  }
}

@media only screen and (min-width: 768px)and (max-width:1200px) {
  .container--items-in-row-1-1-2>.cmp-container>:nth-child(3n+2) {
    margin: unset;
    width: 24%
  }
}

@media only screen and (min-width: 1200px) {
  .container--items-in-row-1-1-2>.cmp-container>:nth-child(3n+2) {
    margin: unset;
    width: 24%
  }
}

@media only screen and (min-width: 768px)and (max-width:1200px) {
  .container--items-in-row-1-1-2>.cmp-container>:nth-child(3n) {
    margin: unset;
    width: 49%
  }
}

@media only screen and (min-width: 1200px) {
  .container--items-in-row-1-1-2>.cmp-container>:nth-child(3n) {
    margin: unset;
    width: 49%
  }
}

.container--items-in-row-1-1-2>.cmp-container .cmp-container__button.collapse,
.container--items-in-row-1-1-2>.cmp-container .cmp-container__button.expand {
  margin: auto;
  width: 24%
}

.container--items-in-row-1-2-1>.cmp-container {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 1pc;
  justify-content: space-between
}

@media only screen and (min-width: 768px)and (max-width:1200px) {
  .container--items-in-row-1-2-1>.cmp-container>:nth-child(3n+1) {
    margin: unset;
    width: 24%
  }
}

@media only screen and (min-width: 1200px) {
  .container--items-in-row-1-2-1>.cmp-container>:nth-child(3n+1) {
    margin: unset;
    width: 24%
  }
}

@media only screen and (min-width: 768px)and (max-width:1200px) {
  .container--items-in-row-1-2-1>.cmp-container>:nth-child(3n+2) {
    margin: unset;
    width: 49%
  }
}

@media only screen and (min-width: 1200px) {
  .container--items-in-row-1-2-1>.cmp-container>:nth-child(3n+2) {
    margin: unset;
    width: 49%
  }
}

@media only screen and (min-width: 768px)and (max-width:1200px) {
  .container--items-in-row-1-2-1>.cmp-container>:nth-child(3n) {
    margin: unset;
    width: 24%
  }
}

@media only screen and (min-width: 1200px) {
  .container--items-in-row-1-2-1>.cmp-container>:nth-child(3n) {
    margin: unset;
    width: 24%
  }
}

.container--items-in-row-1-2-1>.cmp-container .cmp-container__button.collapse,
.container--items-in-row-1-2-1>.cmp-container .cmp-container__button.expand {
  margin: auto;
  width: 24%
}

.container--items-in-row-2-1-1>.cmp-container {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 1pc;
  justify-content: space-between
}

@media only screen and (min-width: 768px)and (max-width:1200px) {
  .container--items-in-row-2-1-1>.cmp-container>:nth-child(3n+1) {
    margin: unset;
    width: 49%
  }
}

@media only screen and (min-width: 1200px) {
  .container--items-in-row-2-1-1>.cmp-container>:nth-child(3n+1) {
    margin: unset;
    width: 49%
  }
}

@media only screen and (min-width: 768px)and (max-width:1200px) {
  .container--items-in-row-2-1-1>.cmp-container>:nth-child(3n+2) {
    margin: unset;
    width: 24%
  }
}

@media only screen and (min-width: 1200px) {
  .container--items-in-row-2-1-1>.cmp-container>:nth-child(3n+2) {
    margin: unset;
    width: 24%
  }
}

@media only screen and (min-width: 768px)and (max-width:1200px) {
  .container--items-in-row-2-1-1>.cmp-container>:nth-child(3n) {
    margin: unset;
    width: 24%
  }
}

@media only screen and (min-width: 1200px) {
  .container--items-in-row-2-1-1>.cmp-container>:nth-child(3n) {
    margin: unset;
    width: 24%
  }
}

.container--items-in-row-2-1-1>.cmp-container .cmp-container__button.collapse,
.container--items-in-row-2-1-1>.cmp-container .cmp-container__button.expand {
  margin: auto;
  width: 24%
}

.container--justify-left .cmp-container {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start
}

.container--justify-center .cmp-container {
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly
}

.container--justify-right .cmp-container {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end
}

.container--layout-left-right>.cmp-container {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 1pc;
  justify-content: space-between
}

@media only screen and (min-width: 768px)and (max-width:1200px) {
  .container--layout-left-right>.cmp-container>* {
    width: 100%
  }
}

@media only screen and (min-width: 1200px) {
  .container--layout-left-right>.cmp-container>* {
    width: calc(50% - 8px)
  }
}

.container--layout-left-right>.cmp-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex
}

@media only screen and (max-width: 1200px) {
  .container--layout-left-right>.cmp-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse
  }
}

body.no-scroll,
html.no-scroll {
  overflow: hidden !important
}

.download {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

.cmp-download--image-and-button,
.download {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 421px
}

.cmp-download--image-and-button {
  -webkit-box-align: left;
  -ms-flex-align: left;
  -ms-flex-pack: distribute;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: left;
  align-items: center;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: 10px;
  -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, .25);
  box-shadow: 0 4px 4px rgba(0, 0, 0, .25);
  justify-content: space-around;
  text-align: left;
  width: 80%;
  width: 344px
}

@media only screen and (min-width: 768px)and (max-width:1200px) {
  .cmp-download--image-and-button {
    max-width: 530px
  }
}

@media only screen and (min-width: 1200px) {
  .cmp-download--image-and-button {
    max-width: 390px
  }
}

@media only screen and (min-width: 768px)and (max-width:1200px) {
  .cmp-download--image-and-button {
    height: 348px;
    width: 284.35px
  }
}

@media only screen and (max-width: 768px) {
  .cmp-download--image-and-button {
    height: 178px;
    width: 344px
  }
}

.cmp-download--image-and-button .cmp-download__image {
  -ms-flex-item-align: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  align-self: center;
  background: -webkit-gradient(linear, left top, left bottom, from(#ececec), to(hsla(0, 0%, 77%, 0)));
  background: linear-gradient(180deg, #ececec, hsla(0, 0%, 77%, 0));
  border-radius: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 76%;
  justify-content: space-evenly;
  position: relative;
  width: 82%
}

@media only screen and (max-width: 768px) {
  .cmp-download--image-and-button .cmp-download__image {
    background: unset;
    height: unset
  }
}

.cmp-download--image-and-button .cmp-download__image .cmp-download__image-resource {
  -ms-flex-item-align: center;
  align-self: center;
  border: 5px solid #000;
  border-radius: 10px;
  -webkit-box-shadow: 5px 5px 5px rgba(89, 89, 89, .522);
  box-shadow: 5px 5px 5px rgba(89, 89, 89, .522);
  max-height: 70%;
  max-width: 90%;
  -o-object-fit: contain;
  object-fit: contain;
  outline: 1.5px solid #bababa
}

@media only screen and (max-width: 768px) {
  .cmp-download--image-and-button .cmp-download__image .cmp-download__image-resource {
    display: none
  }
}

.cmp-download--image-and-button .cmp-download__image .cmp-download__title {
  color: #372358;
  font-size: 18px;
  font-weight: 700;
  padding: 2px;
  text-align: center;
  width: 100%
}

.cmp-download--image-and-button .cmp-download__action {
  background-color: #372358;
  border-radius: 53px;
  color: #fff;
  font-size: 1pc;
  font-weight: 400;
  padding: 9pt 25px;
  position: relative;
  top: -10px;
  white-space: nowrap
}

.cmp-download--image-and-button .cmp-download__action:active,
.cmp-download--image-and-button .cmp-download__action:focus,
.cmp-download--image-and-button .cmp-download__action:hover {
  color: #fff;
  text-decoration: none
}

.cmp-download--full-image {
  -webkit-box-align: left;
  -ms-flex-align: left;
  -ms-flex-pack: distribute;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  align-items: left;
  align-items: center;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: 10px;
  border-radius: 20px;
  -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, .25);
  box-shadow: 0 4px 4px rgba(0, 0, 0, .25);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 275px;
  justify-content: space-around;
  text-align: left;
  width: 80%;
  width: 195px
}

@media only screen and (min-width: 768px)and (max-width:1200px) {
  .cmp-download--full-image {
    max-width: 530px
  }
}

@media only screen and (min-width: 1200px) {
  .cmp-download--full-image {
    max-width: 390px
  }
}

@media only screen and (max-width: 768px) {
  .cmp-download--full-image {
    display: none
  }
}

.cmp-download--full-image .cmp-download__image {
  -ms-flex-item-align: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  align-self: center;
  border-radius: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
  justify-content: space-evenly;
  position: relative;
  width: 100%
}

.cmp-download--full-image .cmp-download__image .cmp-download__image-resource {
  -ms-flex-item-align: center;
  align-self: center;
  border: 5px solid #000;
  border-radius: 20px;
  -webkit-box-shadow: 10px 10px 10px rgba(0, 0, 0, .25);
  box-shadow: 10px 10px 10px rgba(0, 0, 0, .25);
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  outline: 3px solid #bababa;
  width: 100%
}

@media only screen and (max-width: 768px) {
  .cmp-download--full-image .cmp-download__image .cmp-download__image-resource {
    display: none
  }
}

.cmp-event,
.event {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column
}

.cmp-event {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: 10px;
  -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, .25);
  box-shadow: 0 4px 4px rgba(0, 0, 0, .25);
  padding: 0 65px;
  text-align: center;
  width: 80%
}

@media only screen and (min-width: 768px)and (max-width:1200px) {
  .cmp-event {
    max-width: 530px
  }
}

@media only screen and (min-width: 1200px) {
  .cmp-event {
    max-width: 390px
  }
}

@media only screen and (max-width: 768px) {
  .cmp-event {
    margin-bottom: 20px;
    width: 100%
  }
}

@media only screen and (min-width: 768px) {
  .cmp-event {
    margin-bottom: 65px
  }
}

.cmp-event__logo-container {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column
}

.cmp-event__logo {
  height: 50px;
  margin-top: 30px;
  width: auto
}

@media only screen and (min-width: 768px) {
  .cmp-event__logo {
    height: auto;
    width: 230px
  }
}

.cmp-event__logo-container:after {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, .53);
  content: "";
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 0;
  margin-bottom: 30px;
  margin-top: 15px;
  width: 230px
}

@media only screen and (min-width: 768px)and (max-width:1200px) {
  .cmp-event__logo-container:after {
    margin-bottom: 10px
  }
}

.cmp-event__info {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -ms-flex-pack: distribute;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  justify-content: space-around;
  width: 100%
}

@media only screen and (min-width: 768px)and (max-width:1200px) {
  .cmp-event__info {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 0
  }
}

@media only screen and (min-width: 1200px) {
  .cmp-event__info {
    width: 230px
  }
}

.cmp-event__info .cmp-event__info-card {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column
}

@media only screen and (max-width: 768px) {
  .cmp-event__info .cmp-event__info-card {
    margin-left: 5px;
    margin-right: 5px
  }
}

@media only screen and (min-width: 1200px) {
  .cmp-event__info .cmp-event__info-card {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    margin-bottom: 45px;
    margin-left: 4pc;
    margin-right: 4pc;
    overflow-wrap: normal;
    text-align: left;
    width: 230px
  }
}

.cmp-event__info .cmp-event__info-card .cmp-event__info-spec {
  font-size: 24px;
  font-weight: 250;
  line-height: 30.19px
}

.cmp-event__info-detail {
  color: #342254;
  font-size: 34px;
  font-weight: 600;
  line-height: 40px
}

.cmp-event__info-detail-after,
.cmp-event__info-detail-before {
  white-space: nowrap
}

@media only screen and (min-width: 768px) {
  .cmp-event__info-detail {
    font-size: 34px;
    line-height: 42.77px
  }
}

.cmp-event__info-detail-sub {
  color: #6b6b6b;
  font-size: 24px;
  font-weight: 500
}

.cmp-event__button {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  align-items: center;
  border-radius: 53px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  font-size: 14px;
  justify-content: center;
  margin-bottom: 42px;
  margin-top: 42px;
  max-width: 20pc;
  min-width: 10pc;
  padding: 9pt
}

@media only screen and (min-width: 1200px) {
  .cmp-event__button {
    margin-top: 0
  }
}

.cmp-event__button-text {
  color: #fff;
  font-size: 18px;
  font-weight: 400;
  line-height: 19px
}

.cmp-event__button a:hover {
  color: #fff;
  text-decoration: none
}

.cmp-teaser__floatingcard {
  margin-bottom: -50px
}

.cmp-teaser__floatingcard:has(.cmp-event),
.cmp-teaser__floatingcard:has(.cmp-partnercard) {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  width: 100%
}

@media only screen and (max-width: 768px) {
  .cmp-teaser__floatingcard {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center
  }
}

@media only screen and (min-width: 768px) {
  .cmp-teaser__floatingcard {
    float: right
  }
}

@media only screen and (min-width: 1200px) {

  .cmp-teaser__floatingcard:has(.cmp-event),
  .cmp-teaser__floatingcard:has(.cmp-partnercard) {
    width: auto
  }

  .cmp-teaser__floatingcard.floatingcard-high {
    margin-top: var(--margin-top)
  }
}

.cmp-teaser__floatingcard>* {
  margin-left: 2pc;
  margin-right: 2pc;
  position: relative;
  top: -60px;
  z-index: 2
}

.cmp-helloworld__item-label {
  margin-bottom: 0
}

.cmp-helloworld__item-output {
  margin-top: 0
}

.iconteaser {
  margin-left: auto;
  margin-right: auto
}

@media only screen and (min-width: 1200px) {
  .iconteaser {
    height: 300px
  }
}

@media only screen and (max-width: 1200px) {
  .iconteaser {
    padding-top: 40px
  }

  .iconteaser .cmp-iconteaser {
    border-top-left-radius: 0
  }

  .iconteaser .cmp-iconteaser .cmp-iconteaser__icon-container {
    -webkit-box-pack: left;
    -ms-flex-pack: left;
    bottom: 40px;
    justify-content: left;
    margin-bottom: -5pc
  }

  .iconteaser .cmp-iconteaser .cmp-iconteaser__content {
    padding: 2pc
  }

  .iconteaser .cmp-iconteaser .cmp-iconteaser__description,
  .iconteaser .cmp-iconteaser .cmp-iconteaser__title {
    text-align: left
  }
}

.cmp-iconteaser {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  background-color: var(--var-bg-color);
  border-radius: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 350px
}

.cmp-iconteaser__icon-background {
  background-color: var(--var-bg-color);
  border-radius: 50%;
  height: 5pc;
  width: 5pc
}

.cmp-iconteaser__icon-container {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  position: relative
}

.cmp-iconteaser__icon {
  background-color: var(--icon-color);
  height: 100%;
  -webkit-mask: var(--icon-path);
  mask: var(--icon-path);
  width: 100%
}

.cmp-iconteaser__content {
  padding: 0 2pc 2pc
}

.cmp-iconteaser__title {
  color: #fff;
  font-size: 1pc;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 6px;
  margin-top: 6px;
  text-align: center
}

@media only screen and (min-width: 1200px) {
  .cmp-iconteaser__title {
    font-size: 20px
  }
}

.cmp-iconteaser__description {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  margin-bottom: 6px;
  margin-top: 6px;
  text-align: center
}

.cmp-iconteaser__description p:not(:first-child) {
  display: none
}

.cmp-iconteaser__description p:first-child {
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden
}

.iconteaser--icon-ext {
  padding-top: 40px
}

.iconteaser--icon-ext .cmp-iconteaser {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between
}

.iconteaser--icon-ext .cmp-iconteaser__icon-container {
  bottom: 40px;
  margin-bottom: -5pc
}

.iconteaser--icon-ext .cmp-iconteaser__content {
  margin-bottom: 2pc;
  padding: 2pc
}

@media only screen and (max-width: 1200px) {
  .iconteaser--icon-ext .cmp-iconteaser__content {
    margin-bottom: 0
  }

  .cmp-container>.iconteaser {
    width: 100%
  }
}

.infographic {
  width: 100%
}

.infographic .cmp-infographic {
  height: 100%
}

.infographic .cmp-infographic--v1,
.infographic .cmp-infographic--v2 {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
  justify-content: space-evenly;
  position: relative;
  width: 100%
}

@media only screen and (min-width: 768px)and (max-width:1200px) {

  .infographic .cmp-infographic--v1,
  .infographic .cmp-infographic--v2 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
  }
}

@media only screen and (min-width: 1200px) {

  .infographic .cmp-infographic--v1,
  .infographic .cmp-infographic--v2 {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row
  }
}

.infographic .cmp-infographic--v1 .cmp-infographic__text-section,
.infographic .cmp-infographic--v2 .cmp-infographic__text-section {
  -webkit-box-flex: 3;
  -ms-flex-positive: 3;
  flex-grow: 3;
  margin-top: 8%;
  top: 10%;
  width: 80%
}

@media only screen and (min-width: 768px)and (max-width:1200px) {

  .infographic .cmp-infographic--v1 .cmp-infographic__text-section,
  .infographic .cmp-infographic--v2 .cmp-infographic__text-section {
    -webkit-box-flex: 3;
    -ms-flex-positive: 3;
    flex-grow: 3;
    margin-top: 5%;
    top: 10%;
    width: 60%
  }
}

@media only screen and (min-width: 1200px) {

  .infographic .cmp-infographic--v1 .cmp-infographic__text-section,
  .infographic .cmp-infographic--v2 .cmp-infographic__text-section {
    -webkit-box-flex: unset;
    -ms-flex-positive: unset;
    flex-grow: unset;
    height: 70%;
    margin-top: 0;
    top: 0;
    width: 40%;
    z-index: 2
  }
}

.infographic .cmp-infographic--v1 .cmp-infographic__text-section .cmp-infographic__minor-stats,
.infographic .cmp-infographic--v2 .cmp-infographic__text-section .cmp-infographic__minor-stats {
  -ms-flex-pack: distribute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 3%;
  justify-content: space-around;
  margin-top: -5%
}

@media only screen and (min-width: 1200px) {

  .infographic .cmp-infographic--v1 .cmp-infographic__text-section .cmp-infographic__minor-stats,
  .infographic .cmp-infographic--v2 .cmp-infographic__text-section .cmp-infographic__minor-stats {
    margin-top: -2%
  }
}

.infographic .cmp-infographic--v1 .cmp-infographic__text-section .cmp-infographic__title,
.infographic .cmp-infographic--v2 .cmp-infographic__text-section .cmp-infographic__title {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  font-size: 30px;
  font-weight: 600;
  height: 40%;
  line-height: 1;
  overflow: hidden;
  text-align: center
}

@media only screen and (min-width: 768px)and (max-width:1200px) {

  .infographic .cmp-infographic--v1 .cmp-infographic__text-section .cmp-infographic__title,
  .infographic .cmp-infographic--v2 .cmp-infographic__text-section .cmp-infographic__title {
    line-height: 1
  }
}

@media only screen and (min-width: 1200px) {

  .infographic .cmp-infographic--v1 .cmp-infographic__text-section .cmp-infographic__title,
  .infographic .cmp-infographic--v2 .cmp-infographic__text-section .cmp-infographic__title {
    line-height: normal
  }
}

.infographic .cmp-infographic--v1 .cmp-infographic__text-section .cmp-infographic__first,
.infographic .cmp-infographic--v1 .cmp-infographic__text-section .cmp-infographic__second,
.infographic .cmp-infographic--v2 .cmp-infographic__text-section .cmp-infographic__first,
.infographic .cmp-infographic--v2 .cmp-infographic__text-section .cmp-infographic__second {
  display: inline-block;
  text-align: center
}

.infographic .cmp-infographic--v1 .cmp-infographic__text-section .cmp-infographic__first-stat,
.infographic .cmp-infographic--v1 .cmp-infographic__text-section .cmp-infographic__second-stat,
.infographic .cmp-infographic--v2 .cmp-infographic__text-section .cmp-infographic__first-stat,
.infographic .cmp-infographic--v2 .cmp-infographic__text-section .cmp-infographic__second-stat {
  font-size: 75pt;
  font-weight: 700;
  line-height: 1.3
}

@media only screen and (max-width: 768px) {

  .infographic .cmp-infographic--v1 .cmp-infographic__text-section .cmp-infographic__first-stat,
  .infographic .cmp-infographic--v1 .cmp-infographic__text-section .cmp-infographic__second-stat,
  .infographic .cmp-infographic--v2 .cmp-infographic__text-section .cmp-infographic__first-stat,
  .infographic .cmp-infographic--v2 .cmp-infographic__text-section .cmp-infographic__second-stat {
    font-size: 70px
  }
}

.infographic .cmp-infographic--v1 .cmp-infographic__text-section .cmp-infographic__first-title,
.infographic .cmp-infographic--v1 .cmp-infographic__text-section .cmp-infographic__second-title,
.infographic .cmp-infographic--v2 .cmp-infographic__text-section .cmp-infographic__first-title,
.infographic .cmp-infographic--v2 .cmp-infographic__text-section .cmp-infographic__second-title {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  font-size: 38px;
  line-height: 50px;
  overflow: hidden
}

.infographic .cmp-infographic--v1 .cmp-infographic__circle-section,
.infographic .cmp-infographic--v2 .cmp-infographic__circle-section {
  -ms-flex-item-align: center;
  align-self: center;
  aspect-ratio: 1/1;
  height: auto;
  margin-top: -8%;
  position: relative
}

@media only screen and (min-width: 768px)and (max-width:1200px) {

  .infographic .cmp-infographic--v1 .cmp-infographic__circle-section,
  .infographic .cmp-infographic--v2 .cmp-infographic__circle-section {
    aspect-ratio: 1/1;
    margin-top: -5%
  }
}

@media only screen and (min-width: 1200px) {

  .infographic .cmp-infographic--v1 .cmp-infographic__circle-section,
  .infographic .cmp-infographic--v2 .cmp-infographic__circle-section {
    -ms-flex-item-align: end;
    align-self: end;
    aspect-ratio: 1/1;
    height: 100%;
    margin-top: auto;
    min-width: unset
  }
}

.infographic .cmp-infographic--v1 .cmp-infographic__circle-section .cmp-infographic__main-circle-stat,
.infographic .cmp-infographic--v2 .cmp-infographic__circle-section .cmp-infographic__main-circle-stat {
  font-size: 75pt
}

.infographic .cmp-infographic--v1 .cmp-infographic__circle-section .cmp-infographic__main-circle-title,
.infographic .cmp-infographic--v2 .cmp-infographic__circle-section .cmp-infographic__main-circle-title {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  font-size: 40px;
  line-height: 50px;
  overflow: hidden
}

.infographic .cmp-infographic--v1 .cmp-infographic__circle-section {
  height: 290px
}

@media only screen and (min-width: 768px)and (max-width:1200px) {
  .infographic .cmp-infographic--v1 .cmp-infographic__circle-section {
    height: 342px
  }
}

@media only screen and (min-width: 1200px) {
  .infographic .cmp-infographic--v1 .cmp-infographic__circle-section {
    height: 342px
  }
}

.infographic .cmp-infographic--v1 .cmp-infographic__circle-section .cmp-infographic__main-circle {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -ms-flex-item-align: end;
  align-items: center;
  align-self: end;
  aspect-ratio: 1/1;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  position: relative;
  z-index: 2
}

.infographic .cmp-infographic--v1 .cmp-infographic__circle-section .cmp-infographic__main-circle-stat {
  font-weight: 600
}

.infographic .cmp-infographic--v1 .cmp-infographic__circle-section .cmp-infographic__main-circle-title {
  text-align: center;
  width: 50%
}

@media only screen and (min-width: 768px)and (max-width:1200px) {
  .infographic .cmp-infographic--v1 .cmp-infographic__circle-section .cmp-infographic__main-circle {
    height: 100%
  }
}

@media only screen and (min-width: 1200px) {
  .infographic .cmp-infographic--v1 .cmp-infographic__circle-section .cmp-infographic__main-circle {
    height: 100%
  }
}

.infographic .cmp-infographic--v1 .cmp-infographic__circle-section .cmp-infographic__left-circle {
  aspect-ratio: 1/1;
  border-radius: 50%;
  content: "";
  height: 60%;
  left: -30%;
  position: absolute;
  top: 40%;
  z-index: 1
}

.infographic .cmp-infographic--v1 .cmp-infographic__circle-section .cmp-infographic__right-circle {
  aspect-ratio: 1/1;
  border-radius: 50%;
  content: "";
  height: 60%;
  left: 74%;
  position: absolute;
  top: 40%;
  z-index: 1
}

@media only screen and (min-width: 1200px) {
  .infographic .cmp-infographic--v1 .cmp-infographic__circle-section .cmp-infographic__right-circle {
    left: 68%
  }
}

.infographic .cmp-infographic--v1 .cmp-infographic__circle-section .cmp-infographic__black-box {
  background-color: #000;
  height: 50%;
  left: -3%;
  position: absolute;
  top: 50%;
  width: 108%;
  z-index: 0
}

@media only screen and (min-width: 1200px) {
  .infographic .cmp-infographic--v1 .cmp-infographic__circle-section .cmp-infographic__black-box {
    left: 0;
    width: 100%
  }
}

.infographic .cmp-infographic--v2 .cmp-infographic__text-section {
  margin-top: 10%
}

@media only screen and (min-width: 1200px) {
  .infographic .cmp-infographic--v2 .cmp-infographic__text-section {
    margin-bottom: unset;
    margin-top: unset
  }
}

.infographic .cmp-infographic--v2 .cmp-infographic__text-section .cmp-infographic__minor-stats {
  margin-top: 0
}

@media only screen and (min-width: 768px)and (max-width:1200px) {
  .infographic .cmp-infographic--v2 .cmp-infographic__text-section .cmp-infographic__minor-stats {
    margin-top: -1%
  }
}

@media only screen and (min-width: 1200px) {
  .infographic .cmp-infographic--v2 .cmp-infographic__text-section .cmp-infographic__minor-stats {
    margin-bottom: unset;
    margin-top: unset
  }
}

.infographic .cmp-infographic--v2 .cmp-infographic__circle-section {
  height: 335px
}

@media only screen and (min-width: 1200px) {
  .infographic .cmp-infographic--v2 .cmp-infographic__circle-section {
    height: 442px
  }
}

.infographic .cmp-infographic--v2 .cmp-infographic__circle-section .cmp-infographic__main-circle {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -ms-flex-item-align: end;
  align-items: center;
  align-self: end;
  aspect-ratio: 1/1;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  position: relative;
  top: 25%;
  z-index: 3
}

.infographic .cmp-infographic--v2 .cmp-infographic__circle-section .cmp-infographic__main-circle-stat {
  font-weight: 600
}

.infographic .cmp-infographic--v2 .cmp-infographic__circle-section .cmp-infographic__main-circle-title {
  text-align: center;
  width: 70%
}

@media only screen and (min-width: 768px)and (max-width:1200px) {
  .infographic .cmp-infographic--v2 .cmp-infographic__circle-section .cmp-infographic__main-circle {
    height: 100%
  }
}

@media only screen and (min-width: 1200px) {
  .infographic .cmp-infographic--v2 .cmp-infographic__circle-section .cmp-infographic__main-circle {
    height: 100%;
    top: 1%
  }
}

.infographic .cmp-infographic--v2 .cmp-infographic__circle-section .cmp-infographic__left-circle {
  aspect-ratio: 1/1;
  border-radius: 50%;
  content: "";
  height: 93pt;
  left: 5%;
  position: absolute;
  top: 25%;
  z-index: 2
}

@media only screen and (min-width: 1200px) {
  .infographic .cmp-infographic--v2 .cmp-infographic__circle-section .cmp-infographic__left-circle {
    height: 164px;
    left: 10%;
    top: -5%
  }
}

.infographic:has(.cmp-infographic--v1) {
  height: 645px
}

@media only screen and (min-width: 768px)and (max-width:1200px) {
  .infographic:has(.cmp-infographic--v1) {
    height: 682px
  }
}

@media only screen and (min-width: 1200px) {
  .infographic:has(.cmp-infographic--v1) {
    height: 377px
  }
}

.infographic:has(.cmp-infographic--v2) {
  margin-bottom: 90pt
}

@media only screen and (min-width: 768px)and (max-width:1200px) {
  .infographic:has(.cmp-infographic--v2) {
    margin-bottom: 150px
  }
}

@media only screen and (min-width: 1200px) {
  .infographic:has(.cmp-infographic--v2) {
    margin-bottom: 25px;
    margin-top: 30px
  }
}

.infographic:has(.cmp-infographic--v3) .cmp-infographic--v3 {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  align-items: center;
  height: 100%;
  justify-content: space-evenly;
  position: relative;
  width: 100%
}

@media only screen and (min-width: 768px)and (max-width:1200px) {
  .infographic:has(.cmp-infographic--v3) .cmp-infographic--v3 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
  }
}

@media only screen and (min-width: 1200px) {
  .infographic:has(.cmp-infographic--v3) .cmp-infographic--v3 {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row
  }
}

.infographic:has(.cmp-infographic--v3) .cmp-infographic--v3 .cmp-infographic__text-section {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  justify-content: space-evenly;
  row-gap: -10%;
  width: 100%
}

@media only screen and (min-width: 768px)and (max-width:1200px) {
  .infographic:has(.cmp-infographic--v3) .cmp-infographic--v3 .cmp-infographic__text-section {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
  }
}

@media only screen and (min-width: 1200px) {
  .infographic:has(.cmp-infographic--v3) .cmp-infographic--v3 .cmp-infographic__text-section {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-direction: row;
    flex-direction: row
  }
}

@media only screen and (min-width: 768px)and (max-width:1200px) {

  .infographic:has(.cmp-infographic--v3) .cmp-infographic--v3 .cmp-infographic__text-section .cmp-infographic__first,
  .infographic:has(.cmp-infographic--v3) .cmp-infographic--v3 .cmp-infographic__text-section .cmp-infographic__second {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%
  }
}

.infographic:has(.cmp-infographic--v3) .cmp-infographic--v3 .cmp-infographic__text-section .cmp-infographic__first,
.infographic:has(.cmp-infographic--v3) .cmp-infographic--v3 .cmp-infographic__text-section .cmp-infographic__second,
.infographic:has(.cmp-infographic--v3) .cmp-infographic--v3 .cmp-infographic__text-section .cmp-infographic__third {
  display: inline-block;
  margin-bottom: 23px;
  margin-top: 23px;
  text-align: center
}

.infographic:has(.cmp-infographic--v3) .cmp-infographic--v3 .cmp-infographic__text-section .cmp-infographic__first-stat,
.infographic:has(.cmp-infographic--v3) .cmp-infographic--v3 .cmp-infographic__text-section .cmp-infographic__second-stat,
.infographic:has(.cmp-infographic--v3) .cmp-infographic--v3 .cmp-infographic__text-section .cmp-infographic__third-stat {
  font-size: 75pt;
  font-weight: 700;
  line-height: 1.2
}

.infographic:has(.cmp-infographic--v3) .cmp-infographic--v3 .cmp-infographic__text-section .cmp-infographic__first-title,
.infographic:has(.cmp-infographic--v3) .cmp-infographic--v3 .cmp-infographic__text-section .cmp-infographic__second-title,
.infographic:has(.cmp-infographic--v3) .cmp-infographic--v3 .cmp-infographic__text-section .cmp-infographic__third-title {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  font-size: 38px;
  line-height: 50px;
  overflow: hidden
}

.cmp-infographic.greyscale {
  background: #1b1b1b
}

.cmp-infographic.greyscale .cmp-infographic__text-section .cmp-infographic__first-stat,
.cmp-infographic.greyscale .cmp-infographic__text-section .cmp-infographic__first-title,
.cmp-infographic.greyscale .cmp-infographic__text-section .cmp-infographic__second-stat,
.cmp-infographic.greyscale .cmp-infographic__text-section .cmp-infographic__second-title,
.cmp-infographic.greyscale .cmp-infographic__text-section .cmp-infographic__third-stat,
.cmp-infographic.greyscale .cmp-infographic__text-section .cmp-infographic__third-title,
.cmp-infographic.greyscale .cmp-infographic__text-section .cmp-infographic__title {
  color: #fff
}

.cmp-infographic.greyscale .cmp-infographic__circle-section .cmp-infographic__main-circle {
  color: #000
}

.cmp-infographic.greyscale .cmp-infographic__circle-section .cmp-infographic__black-box,
.cmp-infographic.greyscale .cmp-infographic__circle-section .cmp-infographic__left-circle,
.cmp-infographic.greyscale .cmp-infographic__circle-section .cmp-infographic__right-circle {
  background-color: #333
}

.cmp-infographic.greyscale .cmp-infographic__circle-section .cmp-infographic__main-circle {
  background-color: #fff
}

.cmp-infographic.light-text .cmp-infographic__circle-section .cmp-infographic__main-circle,
.cmp-infographic.light-text .cmp-infographic__text-section .cmp-infographic__first-stat,
.cmp-infographic.light-text .cmp-infographic__text-section .cmp-infographic__first-title,
.cmp-infographic.light-text .cmp-infographic__text-section .cmp-infographic__second-stat,
.cmp-infographic.light-text .cmp-infographic__text-section .cmp-infographic__second-title,
.cmp-infographic.light-text .cmp-infographic__text-section .cmp-infographic__third-stat,
.cmp-infographic.light-text .cmp-infographic__text-section .cmp-infographic__third-title,
.cmp-infographic.light-text .cmp-infographic__text-section .cmp-infographic__title {
  color: #fff
}

.cmp-infographic.dark-text .cmp-infographic__text-section .cmp-infographic__first-title,
.cmp-infographic.dark-text .cmp-infographic__text-section .cmp-infographic__second-title,
.cmp-infographic.dark-text .cmp-infographic__text-section .cmp-infographic__third-title,
.cmp-infographic.dark-text .cmp-infographic__text-section .cmp-infographic__title {
  color: #372358
}

.cmp-infographic.dark-text .cmp-infographic__text-section .cmp-infographic__first-stat,
.cmp-infographic.dark-text .cmp-infographic__text-section .cmp-infographic__second-stat,
.cmp-infographic.dark-text .cmp-infographic__text-section .cmp-infographic__third-stat {
  color: #355492
}

.cmp-infographic.dark-text .cmp-infographic__circle-section .cmp-infographic__main-circle {
  color: #fff
}

.marketoforms--modern-design:before {
  border: 2px solid #000;
  content: "Add form image here";
  display: block;
  height: 200px;
  width: 200px
}

.mktoform2 .mktoForm {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 26px
}

@media only screen and (min-width: 1200px) {
  .mktoform2 .mktoForm {
    margin-right: 15%
  }
}

.mktoform2 .mktoForm .mktoFormRow {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%
}

.mktoform2 .mktoForm .mktoFormRow:has(.mktoLogicalField) {
  -webkit-box-pack: left;
  -ms-flex-pack: left;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  justify-content: left
}

.mktoform2 .mktoForm .mktoFormRow .mktoFieldDescriptor {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  margin-left: 0;
  margin-right: 0;
  max-width: 100% !important;
  width: 100%
}

.mktoform2 .mktoForm .mktoFormRow .mktoFieldDescriptor:has(textarea) {
  width: 100%
}

.mktoform2 .mktoForm .mktoFormRow .mktoFieldDescriptor:has(textarea) textarea.mktoField {
  height: 155px !important;
  line-height: unset !important;
  margin-bottom: 10px;
  margin-top: 10px;
  padding-top: 20px;
  width: 100%
}

.mktoform2 .mktoForm .mktoFormRow .mktoFieldDescriptor:has(select#customField6.mktoField) {
  width: 100%
}

.mktoform2 .mktoForm .mktoFormRow .mktoFieldDescriptor:has(select#customField6.mktoField) select#customField6.mktoField {
  height: 155px !important;
  line-height: unset !important;
  margin-bottom: 10px;
  margin-top: 10px;
  width: 90% !important
}

@media only screen and (min-width: 1200px) {
  .mktoform2 .mktoForm .mktoFormRow .mktoFieldDescriptor:has(select#customField6.mktoField) select#customField6.mktoField {
    width: 98% !important
  }
}

.mktoform2 .mktoForm .mktoFormRow .mktoFieldDescriptor:has(.mktoRadioList) {
  height: 40px
}

.mktoform2 .mktoForm .mktoFormRow .mktoFieldDescriptor .mktoFieldWrap {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  width: 80%
}

.mktoform2 .mktoForm .mktoFormRow .mktoFieldDescriptor .mktoFieldWrap .mktoLogicalField.mktoCheckboxList label:before,
.mktoform2 .mktoForm .mktoFormRow .mktoFieldDescriptor .mktoFieldWrap input.mktoField,
.mktoform2 .mktoForm .mktoFormRow .mktoFieldDescriptor .mktoFieldWrap select.mktoField {
  border: 1px solid #aeb0b6
}

.mktoform2 .mktoForm .mktoFormRow .mktoFieldDescriptor .mktoFieldWrap:has(.mktoRadioList) {
  height: 40px
}

@media only screen and (min-width: 1200px) {
  .mktoform2 .mktoForm .mktoFormRow .mktoFieldDescriptor .mktoFieldWrap {
    width: 100%
  }
}

.mktoform2 .mktoForm .mktoFormRow .mktoFieldDescriptor .mktoFieldWrap .mktoLabel {
  width: 0
}

.mktoform2 .mktoForm .mktoFormRow .mktoFieldDescriptor .mktoFieldWrap .mktoField {
  border-radius: 10px !important;
  -webkit-box-shadow: unset;
  box-shadow: unset;
  font-family: var(--body-font-family) !important;
  font-size: 1pc;
  font-weight: 400;
  line-height: 14px !important;
  margin-bottom: 10px;
  margin-left: 0 !important;
  margin-right: 0 !important;
  margin-top: 10px;
  min-height: 50px !important;
  width: 90% !important
}

.mktoform2 .mktoForm .mktoFormRow .mktoFieldDescriptor .mktoFieldWrap .mktoField:-webkit-input-placeholder {
  font-family: var(--body-font-family);
  font-size: 1pc !important
}

.mktoform2 .mktoForm .mktoFormRow .mktoFieldDescriptor .mktoFieldWrap .mktoField:-moz-placeholder {
  font-family: var(--body-font-family);
  font-size: 1pc !important
}

.mktoform2 .mktoForm .mktoFormRow .mktoFieldDescriptor .mktoFieldWrap .mktoField:-ms-input-placeholder {
  font-family: var(--body-font-family);
  font-size: 1pc !important
}

.mktoform2 .mktoForm .mktoFormRow .mktoFieldDescriptor .mktoFieldWrap .mktoField:-ms-input-placeholder {
  font-family: var(--body-font-family);
  font-size: 1pc !important
}

.mktoform2 .mktoForm .mktoFormRow .mktoFieldDescriptor .mktoFieldWrap .mktoField:placeholder {
  font-family: var(--body-font-family);
  font-size: 1pc !important
}

.mktoform2 .mktoForm .mktoFormRow .mktoFieldDescriptor .mktoFieldWrap .mktoRadioList {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex
}

.mktoform2 .mktoForm .mktoFormRow .mktoFieldDescriptor .mktoFieldWrap .mktoRadioList>label {
  font-family: var(--body-font-family) !important;
  margin-left: 0 !important
}

.mktoform2 .mktoForm .mktoFormRow .mktoFieldDescriptor .mktoFieldWrap .mktoRadioList>label:before {
  border: unset;
  -webkit-box-shadow: unset;
  box-shadow: unset
}

.mktoform2 .mktoForm .mktoFormRow .mktoFieldDescriptor .mktoFieldWrap .mktoRadioList>input {
  padding-left: 0 !important;
  width: 50px !important
}

.mktoform2 .mktoForm .mktoFormRow .mktoFieldDescriptor .mktoFieldWrap .mktoLogicalField input.mktoField {
  padding-left: 0 !important;
  width: 0 !important
}

.mktoform2 .mktoForm .mktoFormRow .mktoFieldDescriptor .mktoFieldWrap .mktoLogicalField label {
  font-family: var(--body-font-family);
  white-space: normal
}

@media only screen and (min-width: 1200px) {
  .mktoform2 .mktoForm .mktoFormRow .mktoFieldDescriptor .mktoFieldWrap .mktoLogicalField label {
    overflow-x: unset
  }
}

.mktoform2 .mktoForm .mktoFormRow .mktoFieldDescriptor .mktoFieldWrap .mktoLogicalField label:before {
  border: unset;
  border-radius: 2px;
  -webkit-box-shadow: unset;
  box-shadow: unset;
  margin-left: -20px
}

.mktoform2 .mktoForm .mktoFormRow .mktoFieldDescriptor .mktoFieldWrap select.mktoField {
  font-size: 1pc !important
}

.mktoform2 .mktoForm .mktoFormRow .mktoFieldDescriptor .mktoFormCol .mktoTextField {
  font-size: 14px !important
}

.mktoform2 .mktoForm .mktoFormRow .mktoFieldDescriptor .mktoFormCol .mktoHtmlText {
  width: 40% !important
}

.mktoform2 .mktoForm .mktoFormRow .mktoFieldDescriptor .mktoFormCol .mktoFieldWrap select.mktoField {
  font-size: 16x !important
}

.mktoform2 .mktoForm .mktoButtonRow {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%
}

.mktoform2 .mktoForm .mktoButtonRow .mktoButton {
  background-image: unset !important;
  background: #382356;
  border-radius: 53px !important;
  color: #fff;
  font-family: var(--body-font-family) !important;
  font-size: 1pc !important;
  font-weight: 400;
  width: 10pc !important
}

.mktoform2 .form-purple-button .mktoButtonRow .mktoButtonWrap.mktoShadow .mktoButton {
  background-color: #382356 !important;
  border: 1px solid #382356 !important;
  color: #fff !important
}

.mktoform2 .form-purple-button .mktoButtonRow .mktoButtonWrap.mktoShadow .mktoButton:hover {
  background-color: #382356 !important;
  background-image: unset !important;
  border: 1px solid #382356 !important;
  color: #fff !important
}

.mktoform2 .form-purple-button .mktoButtonRow .mktoButtonWrap.mktoShadow .mktoButtonWrap.mktoInset .mktoButton {
  background: #382356 !important;
  border: 1px solid #382356 !important;
  color: #fff !important
}

.mktoform2 .form-purple-button .mktoButtonRow .mktoButtonWrap.mktoShadow .mktoButtonWrap.mktoInset .mktoButton:hover {
  background-color: #382356 !important;
  background-image: unset !important;
  border: 1px solid #382356 !important;
  color: #fff !important
}

.mktoform2 .form-lime-button .mktoButtonRow .mktoButtonWrap.mktoShadow .mktoButton,
.mktoform2 .form-lime-button .mktoButtonRow .mktoButtonWrap.mktoShadow .mktoButton:hover {
  background-color: #7ec45f !important;
  border: 1px solid #7ec45f !important;
  color: #fff !important
}

.mktoform2 .form-lime-button .mktoButtonRow .mktoButtonWrap.mktoShadow .mktoButton:hover {
  background-image: unset !important
}

.mktoform2 .form-lime-button .mktoButtonRow .mktoButtonWrap.mktoShadow .mktoButtonWrap.mktoInset .mktoButton {
  background: #7ec45f !important;
  border: 1px solid #7ec45f !important;
  color: #fff !important
}

.mktoform2 .form-lime-button .mktoButtonRow .mktoButtonWrap.mktoShadow .mktoButtonWrap.mktoInset .mktoButton:hover {
  background-color: #7ec45f !important;
  background-image: unset !important;
  border: 1px solid #7ec45f !important;
  color: #fff !important
}

.mktoform2 .form-aquamarine-button .mktoButtonRow .mktoButtonWrap.mktoShadow .mktoButton {
  background-color: #3498b3 !important;
  border: 1px solid #3498b3 !important;
  color: #fff !important
}

.mktoform2 .form-aquamarine-button .mktoButtonRow .mktoButtonWrap.mktoShadow .mktoButton:hover {
  background-color: #3498b3 !important;
  background-image: unset !important;
  border: 1px solid #3498b3 !important;
  color: #fff !important
}

.mktoform2 .form-aquamarine-button .mktoButtonRow .mktoButtonWrap.mktoShadow .mktoButtonWrap.mktoInset .mktoButton {
  background: #3498b3 !important;
  border: 1px solid #3498b3 !important;
  color: #fff !important
}

.mktoform2 .form-aquamarine-button .mktoButtonRow .mktoButtonWrap.mktoShadow .mktoButtonWrap.mktoInset .mktoButton:hover {
  background-color: #3498b3 !important;
  background-image: unset !important;
  border: 1px solid #3498b3 !important;
  color: #fff !important
}

.mktoform2 .form-navy-button .mktoButtonRow .mktoButtonWrap.mktoShadow .mktoButton,
.mktoform2 .form-navy-button .mktoButtonRow .mktoButtonWrap.mktoShadow .mktoButton:hover {
  background-color: #355492 !important;
  border: 1px solid #355492 !important;
  color: #fff !important
}

.mktoform2 .form-navy-button .mktoButtonRow .mktoButtonWrap.mktoShadow .mktoButton:hover {
  background-image: unset !important
}

.mktoform2 .form-navy-button .mktoButtonRow .mktoButtonWrap.mktoShadow .mktoButtonWrap.mktoInset .mktoButton {
  background: #355492 !important;
  border: 1px solid #355492 !important;
  color: #fff !important
}

.mktoform2 .form-navy-button .mktoButtonRow .mktoButtonWrap.mktoShadow .mktoButtonWrap.mktoInset .mktoButton:hover {
  background-color: #355492 !important;
  background-image: unset !important;
  border: 1px solid #355492 !important;
  color: #fff !important
}

.mktoform2 .form-lavender-button .mktoButtonRow .mktoButtonWrap.mktoShadow .mktoButton {
  background-color: #7470a6 !important;
  border: 1px solid #7470a6 !important;
  color: #fff !important
}

.mktoform2 .form-lavender-button .mktoButtonRow .mktoButtonWrap.mktoShadow .mktoButton:hover {
  background-color: #7470a6 !important;
  background-image: unset !important;
  border: 1px solid #7470a6 !important;
  color: #fff !important
}

.mktoform2 .form-lavender-button .mktoButtonRow .mktoButtonWrap.mktoShadow .mktoButtonWrap.mktoInset .mktoButton {
  background: #7470a6 !important;
  border: 1px solid #7470a6 !important;
  color: #fff !important
}

.mktoform2 .form-lavender-button .mktoButtonRow .mktoButtonWrap.mktoShadow .mktoButtonWrap.mktoInset .mktoButton:hover {
  background-color: #7470a6 !important;
  background-image: unset !important;
  border: 1px solid #7470a6 !important;
  color: #fff !important
}

.mktoform2 .form-magenta-button .mktoButtonRow .mktoButtonWrap.mktoShadow .mktoButton {
  background-color: #bd266a !important;
  border: 1px solid #bd266a !important;
  color: #fff !important
}

.mktoform2 .form-magenta-button .mktoButtonRow .mktoButtonWrap.mktoShadow .mktoButton:hover {
  background-color: #bd266a !important;
  background-image: unset !important;
  border: 1px solid #bd266a !important;
  color: #fff !important
}

.mktoform2 .form-magenta-button .mktoButtonRow .mktoButtonWrap.mktoShadow .mktoButtonWrap.mktoInset .mktoButton {
  background: #bd266a !important;
  border: 1px solid #bd266a !important;
  color: #fff !important
}

.mktoform2 .form-magenta-button .mktoButtonRow .mktoButtonWrap.mktoShadow .mktoButtonWrap.mktoInset .mktoButton:hover {
  background-color: #bd266a !important;
  background-image: unset !important;
  border: 1px solid #bd266a !important;
  color: #fff !important
}

.mktoform2 .form-orange-button .mktoButtonRow .mktoButtonWrap.mktoShadow .mktoButton {
  background-color: #f47f2b !important;
  border: 1px solid #f47f2b !important;
  color: #fff !important
}

.mktoform2 .form-orange-button .mktoButtonRow .mktoButtonWrap.mktoShadow .mktoButton:hover {
  background-color: #f47f2b !important;
  background-image: unset !important;
  color: #fff !important
}

.mktoform2 .form-orange-button .mktoButtonRow .mktoButtonWrap.mktoShadow .mktoButtonWrap.mktoInset .mktoButton {
  background: #f47f2b !important;
  border: 1px solid #f47f2b !important;
  color: #fff !important
}

.mktoform2 .form-orange-button .mktoButtonRow .mktoButtonWrap.mktoShadow .mktoButtonWrap.mktoInset .mktoButton:hover {
  background-color: #f47f2b !important;
  background-image: unset !important;
  border: 1px solid #f47f2b !important;
  color: #fff !important
}

.mktoform2 .form-lemon-button .mktoButtonRow .mktoButtonWrap.mktoShadow .mktoButton,
.mktoform2 .form-lemon-button .mktoButtonRow .mktoButtonWrap.mktoShadow .mktoButton:hover {
  background-color: #fbcd2e !important;
  border: 1px solid #fbcd2e !important;
  color: #000 !important
}

.mktoform2 .form-lemon-button .mktoButtonRow .mktoButtonWrap.mktoShadow .mktoButton:hover {
  background-image: unset !important
}

.mktoform2 .form-black-button .mktoButtonRow .mktoButtonWrap.mktoShadow .mktoButton {
  background-color: #000 !important;
  border: 1px solid #000 !important;
  color: #fff !important
}

.mktoform2 .form-black-button .mktoButtonRow .mktoButtonWrap.mktoShadow .mktoButton:hover {
  background-color: #000 !important;
  background-image: unset !important;
  border: 1px solid #000 !important;
  color: #fff !important
}

.mktoform2 .form-white-button .mktoButtonRow .mktoButtonWrap.mktoShadow .mktoButton {
  background-color: #fff !important;
  border: 1px solid #fff !important;
  color: #000 !important
}

.mktoform2 .form-white-button .mktoButtonRow .mktoButtonWrap.mktoShadow .mktoButton:hover {
  background-color: #fff !important;
  background-image: unset !important;
  border: 1px solid #fff !important;
  color: #000 !important
}

.mktoform2 .form-white-button .mktoButtonRow .mktoButtonWrap.mktoShadow .mktoButtonWrap.mktoInset .mktoButton {
  background: #fbcd2e !important;
  border: 1px solid #fbcd2e !important;
  color: #000 !important
}

.mktoform2 .form-white-button .mktoButtonRow .mktoButtonWrap.mktoShadow .mktoButtonWrap.mktoInset .mktoButton:hover {
  background-color: #fbcd2e !important;
  background-image: unset !important;
  border: 1px solid #fbcd2e !important;
  color: #000 !important
}

.mktoform2 .vertical-bg-img {
  height: auto !important;
  max-width: 471px !important;
  padding-bottom: 0 !important
}

@media only screen and (min-width: 768px)and (max-width:1200px) {
  .mktoform2 .vertical-bg-img {
    max-width: 533px !important
  }
}

@media only screen and (min-width: 1200px) {
  .mktoform2 .vertical-bg-img {
    max-width: 397px !important
  }
}

.mktoform2 .vertical-bg-img .mktoForm .mktoFormRow .mktoFieldWrap {
  width: 80%
}

.mktoform2 .vertical-bg-img .mktoForm .mktoFormRow .mktoFieldWrap .mktoLogicalField {
  margin-left: 0;
  width: 90% !important
}

.mktoform2 .vertical-bg-img .mktoForm .mktoFormRow .mktoFieldWrap .mktoLogicalField input.mktoField {
  padding-left: 0 !important;
  width: 0 !important
}

.mktoform2 .vertical-bg-img .mktoForm .mktoFormRow .mktoFieldWrap .mktoLogicalField label {
  font-family: var(--body-font-family);
  overflow-x: auto
}

.mktoform2 .vertical-bg-img .mktoForm .mktoFormRow .mktoFieldWrap .mktoLogicalField label:before {
  border: unset;
  border-radius: 2px;
  -webkit-box-shadow: unset;
  box-shadow: unset;
  margin-left: -20px
}

.mktoform2 .vertical-bg-img .mktoForm .mktoButtonRow {
  -ms-flex-preferred-size: 100%;
  bottom: 90px;
  flex-basis: 100%
}

.mktoform2 .horizontal-bg-img {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  border-radius: unset;
  -ms-flex-direction: column;
  flex-direction: column
}

.mktoform2 .horizontal-bg-img,
.mktoform2 .horizontal-bg-img .header-container {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center
}

.mktoform2 .horizontal-bg-img .header-container {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -ms-flex-item-align: center;
  align-items: center;
  align-self: center;
  width: 100%
}

.mktoform2 .horizontal-bg-img .header-container .title-container {
  display: block;
  position: relative;
  right: -70px;
  white-space: nowrap
}

@media only screen and (min-width: 1200px) {
  .mktoform2 .horizontal-bg-img .header-container .title-container {
    max-width: 70%;
    right: -15%
  }
}

.mktoform2 .horizontal-bg-img .header-container .title-container p {
  -ms-flex-item-align: center;
  align-self: center;
  direction: rtl;
  font-size: 38px;
  padding: unset;
  position: relative;
  text-align: center;
  z-index: 1
}

@media only screen and (min-width: 1200px) {
  .mktoform2 .horizontal-bg-img .header-container .title-container p {
    text-align: right
  }
}

.mktoform2 .horizontal-bg-img .header-container .title-container p:after {
  content: "‎"
}

.mktoform2 .horizontal-bg-img .header-container .title-container div {
  -ms-flex-item-align: center;
  align-self: center;
  direction: rtl;
  font-size: 38px;
  padding: unset;
  position: relative;
  text-align: center;
  z-index: 1
}

@media only screen and (min-width: 1200px) {
  .mktoform2 .horizontal-bg-img .header-container .title-container div {
    text-align: right
  }
}

.mktoform2 .horizontal-bg-img .header-container .title-container div:after {
  content: "‎"
}

.mktoform2 .horizontal-bg-img .header-container .header-image-container {
  position: relative
}

@media only screen and (min-width: 1200px) {
  .mktoform2 .horizontal-bg-img .header-container .header-image-container {
    position: relative;
    right: 0
  }
}

.mktoform2 .horizontal-bg-img .header-container .header-image-container .header-image {
  -ms-flex-item-align: center;
  align-self: center;
  max-height: 200px;
  -o-object-fit: contain;
  object-fit: contain;
  position: relative
}

@media only screen and (min-width: 1200px) {
  .mktoform2 .horizontal-bg-img .header-container .header-image-container .header-image {
    float: right;
    max-height: 300px;
    max-width: 90%
  }

  .mktoform2 .horizontal-bg-img {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-column-gap: 0;
    -moz-column-gap: 0;
    column-gap: 0;
    -ms-flex-direction: row;
    flex-direction: row
  }

  .mktoform2 .horizontal-bg-img .mktoForm .mktoFormRow .mktoFieldDescriptor {
    width: 100%
  }

  .mktoform2 .horizontal-bg-img .mktoForm .mktoFormRow .mktoFieldDescriptor .mktoField {
    width: 98% !important
  }
}

@media only screen and (min-width: 1200px) {
  .mktoform2 .horizontal-bg-img .mktoForm .mktoFormRow:has(.mktoFieldDescriptor+.mktoFieldDescriptor) .mktoFieldDescriptor {
    width: 50%
  }
}

.mktoForm fieldset legend {
  border: none;
  margin: auto;
  padding-left: 2pc;
  width: 80%
}

@media only screen and (min-width: 1200px) {
  .mktoForm fieldset legend {
    border: none;
    width: 100%
  }
}

.mktoFormRow .mktoFormCol {
  border: none
}

.hidden,
.mktoform2 .mktoForm .mktoFormRow .mktoFieldDescriptor .mktoFieldWrap .mktoLogicalField input.mktoField {
  display: none
}

.partnercard {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column
}

.cmp-partnercard {
  -webkit-box-align: left;
  -ms-flex-align: left;
  align-items: left;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: 10px;
  -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, .25);
  box-shadow: 0 4px 4px rgba(0, 0, 0, .25);
  height: auto;
  text-align: left;
  width: 80%
}

@media only screen and (min-width: 768px)and (max-width:1200px) {
  .cmp-partnercard {
    max-width: 530px
  }
}

@media only screen and (min-width: 1200px) {
  .cmp-partnercard {
    max-width: 390px
  }
}

.cmp-partnercard__logo {
  height: 67px;
  margin-bottom: 25px;
  margin-left: 30px;
  margin-top: 35px;
  max-width: 200px
}

.cmp-partnercard__info {
  display: inline-block;
  font-family: var(--body-font-family);
  font-size: 18px;
  font-weight: 500;
  line-height: 1.37pc;
  margin-bottom: 25px;
  margin-left: 45px;
  margin-right: 45px
}

@media only screen and (min-width: 768px) {
  .cmp-partnercard__info {
    margin-bottom: 50px;
    margin-left: 55px;
    margin-right: 55px
  }
}

.cmp-partnercard__info .cmp-partnercard__subheader {
  font-weight: 700
}

.cmp-partnercard__info .cmp-partnercard__quote {
  margin-bottom: 25px
}

@media only screen and (min-width: 1200px) {
  .cmp-partnercard__info .cmp-partnercard__quote {
    margin-bottom: 40px
  }
}

.cmp-partnercard__info .cmp-partnercard__info-detail:after {
  content: "\a";
  white-space: pre
}

.profilecard {
  margin-bottom: 2pc;
  margin-top: 2pc
}

@media only screen and (min-width: 768px) {
  .profilecard {
    margin-left: 0;
    margin-right: 0
  }
}

.profilecard {
  text-align: center
}

@media only screen and (max-width: 768px) {
  .profilecard {
    margin-left: 0;
    margin-right: 0;
    width: 100%
  }
}

.profilecard .cmp-profilecard {
  font-weight: 300;
  line-height: 1.1
}

.profilecard .cmp-profilecard__flex {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 20px;
  justify-content: center
}

@media only screen and (max-width: 1200px) {
  .profilecard .cmp-profilecard__flex {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse
  }
}

@media only screen and (min-width: 1200px) {
  .profilecard .cmp-profilecard__flex {
    -webkit-box-align: start;
    -ms-flex-align: start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: flex-start;
    align-items: center;
    gap: 20px
  }
}

@media only screen and (max-width: 1200px) {
  .profilecard .cmp-profilecard__flex:has(>.cmp-profilecard__quote) {
    text-align: left
  }

  .profilecard .cmp-profilecard__flex:has(>.cmp-profilecard__quote) .cmp-profilecard__propic-container {
    aspect-ratio: 1/1
  }
}

@media only screen and (min-width: 1200px) {
  .profilecard .cmp-profilecard__flex:not(:has(>.cmp-profilecard__propic-container)) {
    margin-left: 65px;
    margin-right: 65px
  }
}

.profilecard .cmp-profilecard__content {
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  display: inline;
  margin-bottom: auto;
  margin-top: auto;
  order: 2;
  text-align: center
}

@media only screen and (min-width: 1200px) {
  .profilecard .cmp-profilecard__content {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1
  }
}

.profilecard .cmp-profilecard__propic-container {
  aspect-ratio: 1/1;
  border-radius: 50%;
  display: inline-block;
  margin-bottom: 20px;
  max-width: 219px;
  overflow: hidden
}

.profilecard .cmp-profilecard__propic {
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%
}

.profilecard .cmp-profilecard__link-container {
  -ms-flex-pack: distribute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-around;
  margin: auto;
  width: 80%
}

.profilecard .cmp-profilecard .cmp-profilecard__name {
  font-weight: 700
}

@media only screen and (min-width: 1200px) {
  .profilecard .cmp-profilecard .cmp-profilecard__name {
    font-size: 1pc
  }
}

.profilecard .cmp-profilecard .cmp-profilecard__name p {
  margin: unset
}

@media only screen and (min-width: 1200px) {
  .profilecard .cmp-profilecard .cmp-profilecard__credentials {
    font-size: 1pc
  }
}

.profilecard .cmp-profilecard .cmp-profilecard__credentials p {
  margin: unset
}

@media only screen and (min-width: 1200px) {
  .profilecard .cmp-profilecard .cmp-profilecard__organization {
    font-size: 1pc
  }
}

.profilecard .cmp-profilecard .cmp-profilecard__organization p {
  margin: unset
}

.profilecard .cmp-profilecard .cmp-profilecard__email-btn,
.profilecard .cmp-profilecard .cmp-profilecard__linkedin-btn {
  aspect-ratio: 1/1;
  display: inline-block;
  margin-top: 10px;
  max-width: 20px
}

.profilecard .cmp-profilecard .cmp-profilecard__email-btn {
  width: 100%
}

.profilecard .cmp-profilecard__quote {
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  color: #3391ac;
  font-size: 24px;
  font-weight: 700;
  line-height: 1.5;
  order: 1
}

.profilecard .cmp-profilecard__quote:not(:has(div)):before {
  content: open-quote
}

.profilecard .cmp-profilecard__quote:not(:has(div)):after {
  content: close-quote
}

.profilecard .cmp-profilecard__quote div:before {
  content: open-quote
}

.profilecard .cmp-profilecard__quote div:after {
  content: close-quote
}

@media only screen and (min-width: 768px)and (max-width:1200px) {
  .profilecard .cmp-profilecard__quote {
    width: 316px
  }
}

@media only screen and (min-width: 1200px) {
  .profilecard .cmp-profilecard__quote {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
    width: 300px
  }
}

.profilecard .cmp-profilecard__intro {
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2
}

@media only screen and (min-width: 768px)and (max-width:1200px) {
  .profilecard .cmp-profilecard__intro {
    width: 316px
  }
}

@media only screen and (min-width: 1200px) {
  .profilecard .cmp-profilecard__intro {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
    width: 300px
  }

  .profilecard.cmp-profilecard__layout-vertical .cmp-profilecard__flex {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-direction: column;
    flex-direction: column
  }

  .profilecard.cmp-profilecard__layout-vertical .cmp-profilecard__flex.flex-reverse {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse
  }

  .profilecard.cmp-profilecard__layout-horizontal .cmp-profilecard__flex {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-direction: row;
    flex-direction: row
  }

  .profilecard.cmp-profilecard__layout-horizontal .cmp-profilecard__flex.flex-reverse {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse
  }
}

@media only screen and (min-width: 768px) {
  .cmp-container:has(>.profilecard) {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
  }
}

.center-justify {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

.resource {
  -ms-flex-item-align: stretch;
  align-self: stretch;
  margin: 1pc
}

.resource .cmp-teaser {
  -webkit-box-align: left;
  -ms-flex-align: left;
  align-items: left;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: 10px;
  -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, .25);
  box-shadow: 0 4px 4px rgba(0, 0, 0, .25);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-family: var(--body-font-family);
  font-weight: 400;
  height: 100%;
  padding: 20px;
  width: 100%
}

@media only screen and (min-width: 1200px) {
  .resource .cmp-teaser {
    width: 450px
  }
}

.resource .cmp-teaser__content {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-box-flex: 2;
  color: #000;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 2;
  flex: 2;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative
}

.resource .cmp-teaser__content .cmp-teaser__pretitle {
  font-size: 1pc;
  line-height: 19px
}

.resource .cmp-teaser__content .cmp-teaser__pretitle:not(:empty) {
  margin-bottom: 20px
}

.resource .cmp-teaser__content .cmp-teaser__pretitle p {
  margin: 0
}

.resource .cmp-teaser__content .cmp-teaser__title {
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  margin-top: 0
}

.resource .cmp-teaser__content .cmp-teaser__description {
  font-size: 18px;
  line-height: 22px
}

.resource .cmp-teaser__content .cmp-teaser__description,
.resource .cmp-teaser__content .cmp-teaser__description p:first-child {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden
}

.resource .cmp-teaser__content .cmp-teaser__description p:not(:first-child) {
  display: none
}

.resource .cmp-teaser__content .cmp-teaser__action-container {
  -webkit-box-pack: left;
  -ms-flex-pack: left;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  flex-grow: 1;
  justify-content: left;
  margin-top: 1pc;
  text-align: center
}

.resource .cmp-teaser__content .cmp-teaser__action-container .cmp-teaser__action-link {
  background-color: var(--var-button-color, #fff);
  bottom: 0;
  color: var(--var-button-text-color, #000);
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  position: absolute
}

.resource .cmp-teaser__content .cmp-teaser__action-container .cmp-teaser__action-link,
.resource .cmp-teaser__content .cmp-teaser__action-container .cmp-teaser__action-link-hidden {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 53px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 14px;
  justify-content: center;
  line-height: 17px;
  max-width: 20pc;
  min-width: 10pc;
  padding: 9pt
}

.resource .cmp-teaser__content .cmp-teaser__action-container .cmp-teaser__action-link-hidden {
  bottom: unset;
  position: relative;
  visibility: hidden
}

.resource .cmp-teaser__image {
  border-radius: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 25px;
  margin-right: 20px;
  max-height: 10pc;
  max-width: 10pc;
  overflow: hidden;
  width: 40%
}

.resource .cmp-teaser__image img {
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: 50% 50%;
  object-position: 50% 50%;
  width: 100%
}

.resource .cmp-teaser .cmp-teaser__link {
  -webkit-box-align: left;
  -ms-flex-align: left;
  align-items: left;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%
}

.resource.resource--image-left .cmp-teaser__image {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start
}

.resource.resource--image-center .cmp-teaser__image {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center
}

.resource.resource--image-right .cmp-teaser__image {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end
}

.resourcecontainer .cmp-container {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  justify-content: center
}

@media only screen and (min-width: 768px) {
  .resourcecontainer .cmp-container {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-box-pack: space-betweens;
    -ms-flex-pack: space-betweens;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    justify-content: space-betweens;
    margin-left: 150px;
    margin-right: 150px
  }
}

.resourcecontainer .cmp-container .cmp-container__button.expand .cmp-button {
  background-color: var(--var-expand-button-color, var(--var-button-color, #372358));
  color: var(--var-expand-button-text-color, var(--var-button-text-color, #fff))
}

.resourcecontainer.resourcecontainer--grey-color {
  background-color: #1b1b1b
}

.resourcecontainer.resourcecontainer--grey-color .cmp-container .resource {
  background: #1b1b1b;
  border: solid;
  border-color: #333;
  border-radius: 10px;
  color: #fff
}

.resourcecontainer.resourcecontainer--grey-color .cmp-container .resource .cmp-teaser__description,
.resourcecontainer.resourcecontainer--grey-color .cmp-container .resource .cmp-teaser__pretitle,
.resourcecontainer.resourcecontainer--grey-color .cmp-container .resource .cmp-teaser__title {
  color: #fff
}

.resourcecontainer.resourcecontainer--grey-color .cmp-container .resource .cmp-teaser__action-link {
  background: #fff;
  color: #000
}

.resourcecontainer.resourcecontainer--grey-color .cmp-container .cmp-container__button .cmp-button {
  background-color: #fff;
  color: #000
}

.resourcecontainer.resourcecontainer--black-color {
  background-color: #000
}

.resourcecontainer.resourcecontainer--black-color .cmp-container .resource {
  background: #000;
  border: solid;
  border-color: #333;
  border-radius: 10px;
  color: #fff
}

.resourcecontainer.resourcecontainer--black-color .cmp-container .resource .cmp-teaser__description,
.resourcecontainer.resourcecontainer--black-color .cmp-container .resource .cmp-teaser__pretitle,
.resourcecontainer.resourcecontainer--black-color .cmp-container .resource .cmp-teaser__title {
  color: #fff
}

.resourcecontainer.resourcecontainer--black-color .cmp-container .resource .cmp-teaser__action-link {
  background: #fff;
  color: #000
}

.resourcecontainer.resourcecontainer--black-color .cmp-container .cmp-container__button .cmp-button {
  background-color: #fff;
  color: #000
}

@media only screen and (max-width: 768px) {
  .secondary-navigation {
    display: none
  }
}

.secondary-navigation .nd_vn .in_nd_vn {
  background-color: #fff;
  background-image: none;
  -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, .25);
  box-shadow: 0 4px 4px rgba(0, 0, 0, .25)
}

.secondary-navigation .nd_vn .in_nd_vn a.active {
  color: #341c55
}

@media only screen and (max-width: 768px) {
  .secondary-navigation .nd_vn .in_nd_vn {
    height: auto
  }
}

.secondary-navigation .container-fluid {
  margin-left: 4pc;
  margin-right: 4pc;
  padding: 0
}

@media only screen and (min-width: 768px)and (max-width:1200px) {
  .secondary-navigation .container-fluid {
    margin-left: 9pc;
    margin-right: 9pc
  }
}

@media only screen and (min-width: 1200px) {
  .secondary-navigation .container-fluid {
    margin-left: auto;
    margin-right: auto;
    max-width: 75pc
  }
}

@media only screen and (max-width: 768px) {
  .secondary-navigation .container-fluid {
    margin-left: 2pc;
    margin-right: 2pc
  }
}

.secondary-navigation .snd_nv {
  padding: 20px 0
}

.secondary-navigation ul.snd_nv_lst {
  -webkit-box-pack: normal;
  -ms-flex-pack: normal;
  justify-content: normal;
  padding: 0
}

.secondary-navigation ul.snd_nv_lst li {
  font-family: var(--body-font-family);
  font-size: 14px
}

.secondary-navigation ul.snd_nv_lst li:first-child {
  padding-left: 0
}

@media only screen and (max-width: 768px) {
  .secondary-navigation ul.snd_nv_lst li {
    border-bottom: none;
    width: auto
  }
}

@media only screen and (max-width: 1200px) {
  .secondary-navigation ul.snd_nv_lst li {
    padding: 0 50px 0 0 !important
  }
}

.secondary-navigation ul.snd_nv_lst li a {
  color: #000
}

.cmp-secondary-navigation--consulting-landing .snd_nv {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center
}

@media only screen and (min-width: 768px) {
  .cmp-secondary-navigation--consulting-landing ul.snd_nv_lst:before {
    content: "HIGHLIGHTS:";
    font-size: 24px;
    font-weight: 600;
    margin-right: 40px
  }
}

.cmp-secondary-navigation--consulting-landing ul.snd_nv_lst li {
  padding: 0 10px !important
}

@media only screen and (min-width: 768px) {
  .cmp-secondary-navigation--consulting-landing ul.snd_nv_lst li {
    padding: 0 30px !important
  }
}

.cmp-secondary-navigation--consulting-landing ul.snd_nv_lst li a.active,
.cmp-secondary-navigation--consulting-landing ul.snd_nv_lst li a:hover {
  color: #341c55 !important;
  font-size: 1pc
}

@media only screen and (min-width: 768px) {

  .cmp-secondary-navigation--consulting-landing ul.snd_nv_lst li a.active,
  .cmp-secondary-navigation--consulting-landing ul.snd_nv_lst li a:hover {
    font-size: 24px
  }
}

@media only screen and (max-width: 768px) {
  .cmp-secondary-navigation--consulting-landing ul.snd_nv_lst li.active a.active {
    border-bottom: 2px solid #341c55
  }
}

@media only screen and (min-width: 768px) {
  .cmp-secondary-navigation--consulting-landing ul.snd_nv_lst li:not(:last-child):after {
    background-color: #341c55;
    content: "";
    display: inline-block;
    height: 14px;
    margin-left: 20px;
    width: 2px
  }
}

@media only screen and (min-width: 768px)and (min-width:768px) {
  .cmp-secondary-navigation--consulting-landing ul.snd_nv_lst li:not(:last-child):after {
    font-size: 24px;
    height: 1pc;
    margin-left: 50px
  }
}

@media only screen and (max-width: 768px) {
  .cmp-secondary-navigation--consulting-landing {
    display: none
  }
}


.servicewithicon .cmp-teaser__content-wrapper {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal
}

.servicewithicon .cmp-teaser__content,
.servicewithicon .cmp-teaser__content-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%
}

.servicewithicon .cmp-teaser__content {
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  order: 2
}

.servicewithicon .cmp-teaser__title {
  color: #342254;
  font-size: 26px;
  font-weight: 700;
  line-height: 25px;
  margin-top: 0
}

.servicewithicon .cmp-teaser__description {
  font-size: 1pc;
  line-height: 19px
}

.servicewithicon .cmp-teaser__description,
.servicewithicon .cmp-teaser__description p:first-child {
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden
}

.servicewithicon .cmp-teaser__description p:not(:first-child) {
  display: none
}

.servicewithicon .cmp-teaser__action-container {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  text-align: center
}

.servicewithicon .cmp-teaser__action-container .cmp-teaser__action-link {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: var(--var-button-color, #372358);
  border-radius: 53px;
  color: var(--var-button-text-color, #fff);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 14px;
  justify-content: center;
  line-height: 17px;
  max-width: 20pc;
  min-width: 10pc;
  padding: 9pt
}

.servicewithicon .cmp-teaser__image {
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  background-color: #fff;
  border-radius: 50%;
  height: auto;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  order: 1;
  padding: 20px;
  position: absolute;
  right: 0;
  text-align: center;
  top: -58px;
  width: 111.5px
}

.servicewithicon .cmp-teaser__image svg {
  stroke: var(--var-service-icon-color, #bd266a);
  height: auto;
  max-height: 65px;
  width: 72px
}

.servicewithicon .cmp-teaser__link {
  all: unset;
  cursor: pointer
}

.servicewithicon .cmp-teaser__link:hover {
  text-decoration: underline
}

.servicewithicon.servicewithicon--grey-color {
  background: #000;
  border: solid;
  border-color: #333;
  color: #fff
}

.servicewithicon.servicewithicon--grey-color .cmp-teaser__title {
  color: #fff
}

.servicewithicon.servicewithicon--grey-color .cmp-teaser__image {
  background: #000
}

.servicewithicon.servicewithicon--grey-color .cmp-teaser__image svg {
  stroke: #fff
}

.servicewithicon.servicewithicon--grey-color .cmp-teaser__action-link {
  background: #fff;
  color: #000
}

.cmp-container__button button.cmp-button {
  background-color: #fff;
  color: #000
}

.socialshare {
  margin-bottom: 2pc;
  margin-top: 2pc
}

.socialshare.socialshare--white-color .cmp-socialshare .cmp-socialshare__button {
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1)
}

.cmp-socialshare {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 1pc
}

.cmp-socialshare__button {
  height: 24px
}

.justify-center .cmp-socialshare {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center
}

.teaser--featured-resource .cmp-teaser__link,
.teaser--featured-resource .cmp-teaser__link:hover,
.teaser--featured-resource .cmp-teaser__link:visited {
  color: unset
}

.teaser--featured-resource .cmp-teaser,
.teaser--featured-resource .cmp-teaser__link {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-pack: distribute;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  height: auto;
  justify-content: space-around;
  margin-left: 4pc;
  margin-right: 4pc;
  min-height: 600px
}

@media only screen and (min-width: 768px)and (max-width:1200px) {

  .teaser--featured-resource .cmp-teaser,
  .teaser--featured-resource .cmp-teaser__link {
    margin-left: 9pc;
    margin-right: 9pc
  }
}

@media only screen and (min-width: 1200px) {

  .teaser--featured-resource .cmp-teaser,
  .teaser--featured-resource .cmp-teaser__link {
    margin-left: auto;
    margin-right: auto;
    max-width: 75pc
  }
}

.teaser--featured-resource .cmp-teaser .cmp-teaser__content,
.teaser--featured-resource .cmp-teaser__link .cmp-teaser__content {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 90%;
  justify-content: space-evenly;
  z-index: 2
}

@media only screen and (min-width: 1200px) {

  .teaser--featured-resource .cmp-teaser .cmp-teaser__content,
  .teaser--featured-resource .cmp-teaser__link .cmp-teaser__content {
    margin-right: 10%;
    width: 60%
  }
}

.teaser--featured-resource .cmp-teaser .cmp-teaser__content .cmp-teaser__title,
.teaser--featured-resource .cmp-teaser__link .cmp-teaser__content .cmp-teaser__title {
  color: #342254;
  font-family: var(--body-font-family);
  font-size: 3pc;
  font-weight: 700px;
  line-height: 3pc
}

.teaser--featured-resource .cmp-teaser .cmp-teaser__content .cmp-teaser__title span,
.teaser--featured-resource .cmp-teaser__link .cmp-teaser__content .cmp-teaser__title span {
  display: block;
  line-height: normal
}

.teaser--featured-resource .cmp-teaser .cmp-teaser__content .cmp-teaser__description,
.teaser--featured-resource .cmp-teaser__link .cmp-teaser__content .cmp-teaser__description {
  color: #000;
  font-family: var(--body-font-family);
  font-size: 24px;
  font-weight: 500px;
  line-height: 30px
}

.teaser--featured-resource .cmp-teaser .cmp-teaser__content .cmp-teaser__action-container,
.teaser--featured-resource .cmp-teaser__link .cmp-teaser__content .cmp-teaser__action-container {
  -webkit-box-pack: left;
  -ms-flex-pack: left;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: left;
  margin-top: 20px
}

.teaser--featured-resource .cmp-teaser .cmp-teaser__content .cmp-teaser__action-link,
.teaser--featured-resource .cmp-teaser__link .cmp-teaser__content .cmp-teaser__action-link {
  background-color: #372358;
  color: #fff
}

.teaser--featured-resource .cmp-teaser .cmp-teaser__content .cmp-teaser__action-link .cmp-teaser__action-icon svg,
.teaser--featured-resource .cmp-teaser__link .cmp-teaser__content .cmp-teaser__action-link .cmp-teaser__action-icon svg {
  stroke: #fff
}

.teaser--featured-resource .cmp-teaser .cmp-teaser__circle,
.teaser--featured-resource .cmp-teaser__link .cmp-teaser__circle {
  aspect-ratio: 1;
  border-radius: 50%;
  content: "";
  display: none;
  height: 600px;
  left: 58%;
  margin-left: 4pc;
  margin-right: 4pc;
  position: absolute;
  text-align: center;
  z-index: 1
}

@media only screen and (min-width: 768px)and (max-width:1200px) {

  .teaser--featured-resource .cmp-teaser .cmp-teaser__circle,
  .teaser--featured-resource .cmp-teaser__link .cmp-teaser__circle {
    margin-left: 9pc;
    margin-right: 9pc
  }
}

@media only screen and (min-width: 1200px) {

  .teaser--featured-resource .cmp-teaser .cmp-teaser__circle,
  .teaser--featured-resource .cmp-teaser__link .cmp-teaser__circle {
    display: unset;
    margin-left: auto;
    margin-right: auto;
    max-width: 75pc
  }
}

.teaser--featured-resource .cmp-teaser .cmp-teaser__image,
.teaser--featured-resource .cmp-teaser__link .cmp-teaser__image {
  display: none;
  position: relative;
  z-index: 2
}

@media only screen and (min-width: 1200px) {

  .teaser--featured-resource .cmp-teaser .cmp-teaser__image,
  .teaser--featured-resource .cmp-teaser__link .cmp-teaser__image {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    max-width: 40%
  }
}

.teaser--featured-resource .cmp-teaser .cmp-teaser__image img,
.teaser--featured-resource .cmp-teaser__link .cmp-teaser__image img {
  -ms-flex-item-align: center;
  align-self: center;
  -webkit-filter: drop-shadow(10px 10px 10px #000);
  filter: drop-shadow(10px 10px 10px #000000);
  height: auto;
  max-height: 518px;
  max-width: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  width: auto
}

.teaser--featured-resource.teaser--featured-resource-no-truncate .cmp-teaser .cmp-teaser__content .cmp-teaser__description p:first-child,
.teaser--featured-resource.teaser--featured-resource-no-truncate .cmp-teaser__link .cmp-teaser__content .cmp-teaser__description p:first-child {
  -webkit-line-clamp: unset
}

.teaser--featured-resource.teaser--featured-resource-no-truncate .cmp-teaser .cmp-teaser__content .cmp-teaser__description p:not(:first-child),
.teaser--featured-resource.teaser--featured-resource-no-truncate .cmp-teaser__link .cmp-teaser__content .cmp-teaser__description p:not(:first-child) {
  display: block
}

.teaser--featured-resource.teaser--image-left .cmp-teaser {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse
}

@media only screen and (min-width: 1200px) {
  .teaser--featured-resource.teaser--image-left .cmp-teaser .cmp-teaser__content {
    margin-left: 0;
    margin-right: 0
  }

  .teaser--featured-resource.teaser--image-left .cmp-teaser .cmp-teaser__image {
    margin-left: 0;
    margin-right: 10%
  }
}

.teaser--featured-resource.teaser--image-left .cmp-teaser .cmp-teaser__circle {
  left: unset;
  right: 58%
}

.teaser--featured-resource:has(.cmp-infographic--v1) {
  height: 645px
}

@media only screen and (min-width: 768px)and (max-width:1200px) {
  .teaser--featured-resource:has(.cmp-infographic--v1) {
    height: 682px
  }
}

@media only screen and (min-width: 1200px) {
  .teaser--featured-resource:has(.cmp-infographic--v1) {
    height: 377px
  }
}

.cmp-teaser__title-link,
.cmp-teaser__title-link:hover {
  color: unset
}

.cmp-teaser__action-container {
  -webkit-box-pack: left;
  -ms-flex-pack: left;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: left
}

.cmp-teaser__action-container .cmp-teaser__action-link {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 53px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 14px;
  justify-content: center;
  max-width: 20pc;
  min-width: 10pc;
  padding: 9pt
}

.cmp-teaser__action-container .cmp-teaser__action-link .cmp-teaser__action-icon {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  padding-left: 0;
  padding-right: 6px;
  vertical-align: middle
}

.cmp-teaser__action-container .cmp-teaser__action-link .cmp-teaser__action-icon svg {
  stroke: var(--var-button-text-color, #372358);
  height: 15px;
  width: 15px
}

.cmp-teaser__action-container .cmp-teaser__action-background {
  border-radius: 53px;
  max-width: 20pc
}

h3.wipro-dot__exclamation:after,
h3.wipro-dot__period:after,
h3.wipro-dot__question:after {
  margin-left: -10px
}

.teaser.teaser--text-left .cmp-teaser {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  gap: 50px
}

.teaser.teaser--text-left .cmp-teaser__title {
  font-size: 38px;
  font-weight: 700
}

@media only screen and (max-width: 1200px) {
  .teaser.teaser--text-left .cmp-teaser {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
  }
}

.teaser.teaser--text-left .cmp-teaser .cmp-teaser__image .cmp-image img {
  width: auto
}

.teaser--grey-color {
  background: #1b1b1b
}

.teaser--grey-color .cmp-teaser .cmp-teaser__content,
.teaser--grey-color .cmp-teaser .cmp-teaser__content .cmp-teaser__description,
.teaser--grey-color .cmp-teaser .cmp-teaser__content .cmp-teaser__title {
  color: #fff
}

.teaser--grey-color .cmp-teaser .cmp-teaser__content .cmp-teaser__action-container .cmp-teaser__action-link {
  background-color: #fff;
  color: #000
}

.teaser--grey-color .cmp-teaser .cmp-teaser__circle {
  background-color: #333
}

.teaser--black-color {
  background: #000
}

.teaser--black-color .cmp-teaser .cmp-teaser__content,
.teaser--black-color .cmp-teaser .cmp-teaser__content .cmp-teaser__description,
.teaser--black-color .cmp-teaser .cmp-teaser__content .cmp-teaser__title {
  color: #fff
}

.teaser--black-color .cmp-teaser .cmp-teaser__content .cmp-teaser__action-container .cmp-teaser__action-link {
  background-color: #fff;
  color: #000
}

.teaser--black-color .cmp-teaser .cmp-teaser__circle {
  background-color: #333
}

.teaser--purple-color {
  background: #362358
}

.teaser--purple-color .cmp-teaser .cmp-teaser__content,
.teaser--purple-color .cmp-teaser .cmp-teaser__content .cmp-teaser__description,
.teaser--purple-color .cmp-teaser .cmp-teaser__content .cmp-teaser__title {
  color: #fff
}

.teaser--purple-color .cmp-teaser .cmp-teaser__content .cmp-teaser__action-container {
  background-color: #362358
}

.teaser--purple-color .cmp-teaser .cmp-teaser__content .cmp-teaser__action-container .cmp-teaser__action-link {
  background-color: #362358;
  color: #fff
}

.teaser--full-size .cmp-teaser {
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
  overflow: hidden
}

@media only screen and (max-width: 768px) {
  .teaser--full-size .cmp-teaser__image {
    height: 16pc
  }
}

.teaser--full-size .cmp-teaser__image img {
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center center;
  object-position: center center;
  width: 100%
}

@media only screen and (min-width: 1200px) {
  .teaser--full-size .cmp-teaser__image img {
    -webkit-transition: height .3s ease-in-out;
    transition: height .3s ease-in-out
  }
}

.teaser--full-size .cmp-teaser__content {
  background-color: #f1eef0;
  color: #341c55;
  padding: 2pc 20px
}

@media only screen and (min-width: 768px) {
  .teaser--full-size .cmp-teaser__content {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    min-height: 0
  }
}

.teaser--full-size .cmp-teaser__action-link {
  color: #341c55;
  min-width: auto;
  padding-left: 0
}

.teaser--full-size .cmp-teaser__action-link:after {
  content: "▶";
  margin-left: 8px
}

.teaser--full-size .cmp-teaser__image {
  position: relative
}

.teaser--full-size .cmp-teaser__image .cmp-teaser__video {
  left: 50%;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%)
}

@media only screen and (min-width: 768px) {
  .teaser--full-size .cmp-teaser {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    min-height: 392px
  }

  .teaser--full-size .cmp-teaser__image {
    overflow: hidden;
    position: relative;
    width: 50%
  }

  .teaser--full-size .cmp-teaser__image img {
    -o-object-fit: cover;
    object-fit: cover;
    position: absolute
  }

  .teaser--full-size .cmp-teaser__content {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    justify-content: center;
    padding: 40px;
    width: 50%
  }
}

@media only screen and (min-width: 768px)and (min-width:1200px) {
  .teaser--full-size .cmp-teaser__content {
    padding: 60px
  }
}

@media only screen and (min-width: 768px) {
  .teaser--full-size .cmp-teaser__title {
    font-size: 45px
  }

  .teaser--full-size .cmp-teaser__action-link,
  .teaser--full-size .cmp-teaser__description {
    font-size: 20px
  }

  .teaser--full-size.teaser--image-left .cmp-teaser {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse
  }

  .teaser--full-size.teaser--image-left .cmp-teaser__content {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start
  }

  .teaser--full-size.teaser--image-top .cmp-teaser {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse
  }
}

.teaser--full-size.teaser--image-top .cmp-teaser__content {
  width: 100%
}

@media only screen and (min-width: 768px) {
  .teaser--full-size.teaser--image-top .cmp-teaser__content {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start
  }
}

.teaser--full-size.teaser--image-top .cmp-teaser__image {
  width: 100%
}

@media only screen and (min-width: 768px) {
  .teaser--full-size.teaser--image-top .cmp-teaser__image {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    min-height: 90px
  }

  .teaser--full-size.teaser--card .cmp-teaser {
    max-height: 392px
  }
}

.teaser--full-size.teaser--card .cmp-teaser__content-wrapper {
  width: 100%
}

@media only screen and (min-width: 768px) {
  .teaser--full-size.teaser--card .cmp-teaser__content-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-height: 0
  }
}

.teaser--full-size.teaser--card .cmp-teaser__content {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  /* justify-content: flex-end */
}

@media only screen and (min-width: 768px) {
  .teaser--full-size.teaser--card .cmp-teaser__content {
    padding: 40px
  }
}

.teaser--full-size.teaser--card .cmp-teaser__title {
  font-size: 24px
}

@media only screen and (min-width: 768px) {
  .teaser--full-size.teaser--card .cmp-teaser__description {
    overflow: hidden
  }
}

.teaser--full-size.teaser--card .cmp-teaser__action-link,
.teaser--full-size.teaser--card .cmp-teaser__description {
  color: #fff;
  font-size: 1pc
}

.teaser--full-size.teaser--card .cmp-teaser:hover .cmp-teaser__action-container {
  opacity: 1;
  -webkit-transition: opacity .1s ease-in-out;
  transition: opacity .1s ease-in-out
}

.teaser--full-size.teaser--card .cmp-teaser:hover .cmp-teaser__image img {
  -webkit-transition: height .3s ease-in-out;
  transition: height .3s ease-in-out
}

@media only screen and (min-width: 1200px) {
  .teaser--full-size.teaser--card .cmp-teaser:hover .cmp-teaser__image img {
    height: 110%
  }
}

.teaser--full-size.teaser--card .cmp-teaser__action-container {
  background-color: #8d96ff;
  height: 3pc;
  margin: 30px -20px -2pc
}

@media only screen and (min-width: 1200px) {
  .teaser--full-size.teaser--card .cmp-teaser__action-container {
    opacity: 0;
    -webkit-transition: opacity .1s ease-in-out;
    transition: opacity .1s ease-in-out
  }
}

@media only screen and (min-width: 768px) {
  .teaser--full-size.teaser--card .cmp-teaser__action-container {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    margin-bottom: -40px;
    margin-left: -40px;
    margin-right: -40px
  }
}

.teaser--full-size.teaser--card .cmp-teaser__action-background {
  border-radius: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  max-width: none;

  width: 100%
}


.teaser--full-size.teaser--card .cmp-teaser--show-action .cmp-teaser__action-container {
  margin-top: 0;
  opacity: 1
}

.teaser--text-and-image-banner .cmp-teaser__link,
.teaser--text-and-image-banner .cmp-teaser__link:hover,
.teaser--text-and-image-banner .cmp-teaser__link:visited {
  color: unset
}

.teaser--text-and-image-banner .cmp-teaser,
.teaser--text-and-image-banner .cmp-teaser__link {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-pack: distribute;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  height: auto;
  justify-content: space-around;
  margin-left: 4pc;
  margin-right: 4pc;
  min-height: 600px
}

@media only screen and (min-width: 768px)and (max-width:1200px) {

  .teaser--text-and-image-banner .cmp-teaser,
  .teaser--text-and-image-banner .cmp-teaser__link {
    margin-left: 9pc;
    margin-right: 9pc
  }
}

@media only screen and (min-width: 1200px) {

  .teaser--text-and-image-banner .cmp-teaser,
  .teaser--text-and-image-banner .cmp-teaser__link {
    margin-left: auto;
    margin-right: auto;
    max-width: 75pc
  }
}

.teaser--text-and-image-banner .cmp-teaser .cmp-teaser__content,
.teaser--text-and-image-banner .cmp-teaser__link .cmp-teaser__content {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 90%;
  justify-content: space-evenly;
  z-index: 2
}

@media only screen and (min-width: 1200px) {

  .teaser--text-and-image-banner .cmp-teaser .cmp-teaser__content,
  .teaser--text-and-image-banner .cmp-teaser__link .cmp-teaser__content {
    margin-right: 10%;
    width: 60%
  }
}

.teaser--text-and-image-banner .cmp-teaser .cmp-teaser__content .cmp-teaser__title,
.teaser--text-and-image-banner .cmp-teaser__link .cmp-teaser__content .cmp-teaser__title {
  color: #342254;
  font-family: var(--body-font-family);
  font-size: 3pc;
  font-weight: 700px;
  line-height: 3pc
}

.teaser--text-and-image-banner .cmp-teaser .cmp-teaser__content .cmp-teaser__title span,
.teaser--text-and-image-banner .cmp-teaser__link .cmp-teaser__content .cmp-teaser__title span {
  display: block;
  line-height: normal
}

.teaser--text-and-image-banner .cmp-teaser .cmp-teaser__content .cmp-teaser__description,
.teaser--text-and-image-banner .cmp-teaser__link .cmp-teaser__content .cmp-teaser__description {
  color: #000;
  font-family: var(--body-font-family);
  font-size: 24px;
  font-weight: 500px;
  line-height: 30px
}

.teaser--text-and-image-banner .cmp-teaser .cmp-teaser__content .cmp-teaser__action-container,
.teaser--text-and-image-banner .cmp-teaser__link .cmp-teaser__content .cmp-teaser__action-container {
  -webkit-box-pack: left;
  -ms-flex-pack: left;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: left;
  margin-top: 20px
}

.teaser--text-and-image-banner .cmp-teaser .cmp-teaser__content .cmp-teaser__action-link,
.teaser--text-and-image-banner .cmp-teaser__link .cmp-teaser__content .cmp-teaser__action-link {
  background-color: #372358;
  color: #fff
}

.teaser--text-and-image-banner .cmp-teaser .cmp-teaser__content .cmp-teaser__action-link .cmp-teaser__action-icon svg,
.teaser--text-and-image-banner .cmp-teaser__link .cmp-teaser__content .cmp-teaser__action-link .cmp-teaser__action-icon svg {
  stroke: #fff
}

.teaser--text-and-image-banner .cmp-teaser__image .cmp-teaser__video,
.teaser--text-and-image-banner .cmp-teaser__link__image .cmp-teaser__video {
  left: 50%;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%)
}

.teaser--text-and-image-banner .cmp-teaser__image {
  display: none;
  position: relative;
  z-index: 2
}

@media only screen and (min-width: 1200px) {
  .teaser--text-and-image-banner .cmp-teaser__image {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    max-width: 40%
  }
}

.teaser--text-and-image-banner .cmp-teaser__image img {
  -ms-flex-item-align: center;
  align-self: center;
  height: auto;
  max-height: 518px;
  max-width: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  width: auto
}

@media only screen and (min-width: 768px)and (max-width:1200px) {
  .teaser--text-and-image-banner.teaser--text-and-image-tablet .cmp-teaser {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    justify-content: center
  }

  .teaser--text-and-image-banner.teaser--text-and-image-tablet .cmp-teaser .cmp-teaser__content {
    margin-right: 0;
    width: 100%
  }

  .teaser--text-and-image-banner.teaser--text-and-image-tablet .cmp-teaser .cmp-teaser__image {
    display: block
  }
}

@media only screen and (max-width: 768px) {
  .teaser--text-and-image-banner.teaser--text-and-image-mobile .cmp-teaser {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    justify-content: center
  }

  .teaser--text-and-image-banner.teaser--text-and-image-mobile .cmp-teaser .cmp-teaser__content {
    margin-right: 0;
    width: 100%
  }

  .teaser--text-and-image-banner.teaser--text-and-image-mobile .cmp-teaser .cmp-teaser__image {
    display: block
  }
}

.cmp-teaserlist__filters {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 20px;
  justify-content: center;
  margin: 1pc 4pc
}

@media only screen and (min-width: 768px)and (max-width:1200px) {
  .cmp-teaserlist__filters {
    margin-left: 9pc;
    margin-right: 9pc
  }
}

@media only screen and (min-width: 1200px) {
  .cmp-teaserlist__filters {
    margin-left: auto;
    margin-right: auto;
    max-width: 75pc
  }
}

@media only screen and (max-width: 768px) {
  .cmp-teaserlist__filters {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column
  }
}

@media only screen and (min-width: 1200px) {
  .cmp-teaserlist__filters {
    margin-bottom: 2pc;
    margin-top: 2pc
  }
}

.cmp-teaserlist__filters-clear-button {
  color: #555;
  cursor: pointer;
  font-size: 14px;
  text-decoration: underline
}

.cmp-teaserlist__filters-clear-button:hover {
  color: #000
}

.cmp-teaserlist__filters-intro {
  font-size: 27px;
  margin-left: 1pc;
  margin-right: 1pc
}

.cmp-teaserlist__filter {
  border: 1px solid #999;
  border-radius: 50px;
  color: #999;
  cursor: pointer;
  display: none;
  font-size: 1pc;
  padding: 5px 35px 5px 20px;
  width: 100%
}

.cmp-teaserlist__filter .selected,
.cmp-teaserlist__filter:hover {
  border: 1px solid #000;
  color: #000
}

.cmp-teaserlist__filter .selected:after,
.cmp-teaserlist__filter:hover:after {
  color: #000
}

.cmp-teaserlist__filter:after {
  background: none;
  border-bottom: 0;
  border-left: 7px solid transparent;
  border-right: 8px solid transparent;
  border-top: 9pt solid;
  color: #999;
  content: "";
  display: inline-block;
  height: 0;
  margin-left: 6px;
  position: absolute;
  right: 14px;
  top: 9pt;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  vertical-align: 6px;
  width: 0
}

@media only screen and (max-width: 768px) {
  .cmp-teaserlist__filter {
    display: block
  }
}

.cmp-teaserlist__filter-fake {
  background-color: #fff;
  position: relative
}

.cmp-teaserlist__filter-fake-dropdown {
  border: 1px solid #999;
  border-radius: 50px;
  color: #999;
  cursor: pointer;
  font-size: 1pc;
  padding: 5px 35px 5px 20px;
  width: var(--dropdown-width)
}

.cmp-teaserlist__filter-fake-dropdown .selected,
.cmp-teaserlist__filter-fake-dropdown:hover {
  border: 1px solid #000;
  color: #000
}

.cmp-teaserlist__filter-fake-dropdown .selected:after,
.cmp-teaserlist__filter-fake-dropdown:hover:after {
  color: #000
}

.cmp-teaserlist__filter-fake-dropdown:after {
  background: none;
  border-bottom: 0;
  border-left: 7px solid transparent;
  border-right: 8px solid transparent;
  border-top: 9pt solid;
  color: #999;
  content: "";
  display: inline-block;
  height: 0;
  margin-left: 6px;
  position: absolute;
  right: 14px;
  top: 9pt;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  vertical-align: 6px;
  width: 0
}

@media only screen and (max-width: 768px) {
  .cmp-teaserlist__filter-fake-dropdown {
    display: none
  }
}

.cmp-teaserlist__filter-fake-option {
  color: #444;
  cursor: pointer;
  font-size: 1pc;
  margin-right: 20px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content
}

.cmp-teaserlist__filter-fake-option:hover {
  color: #000
}

.cmp-teaserlist__filter-fake-option-container {
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 10px;
  display: none;
  left: 0;
  position: absolute;
  top: 45px;
  z-index: 100
}

.cmp-teaserlist__filter-fake-option-container--open {
  display: block
}

.cmp-teaserlist__filter-fake-option-list {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  max-height: min(250px, 50vh);
  padding: 14px 20px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content
}

@media only screen and (max-width: 768px) {
  .cmp-teaserlist__filter-fake {
    width: 100%
  }
}

.cmp-teaserlist__load-more {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #372358;
  border-radius: 53px;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 14px;
  font-size: 27px;
  justify-content: center;
  margin: 1pc auto;
  max-width: 20pc;
  min-width: 10pc;
  min-width: 25pc;
  padding: 9pt
}

.cmp-teaserlist__load-more:hover {
  background-color: #3391ac
}

@media only screen and (min-width: 1200px) {
  .cmp-teaserlist__load-more {
    margin-bottom: 3pc;
    margin-top: 3pc
  }
}

.cmp-teaserlist__filters-hidden {
  display: none
}

.cmp-text {
  font-family: var(--body-font-family);
  font-size: 18px;
  line-height: 1.5
}

@media only screen and (min-width: 1200px) {
  .text--columns-2 .cmp-text {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2
  }

  .text--columns-3 .cmp-text {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3
  }
}

.text--primary-color {
  color: #372358
}

.text--secondary-color {
  color: #3391ac
}

.text--white-color {
  color: #fff
}

.text.text--grey-color {
  background-color: #1b1b1b;
  color: #fff
}

.text.text--black-color {
  background-color: #000;
  color: #fff
}

.cmp-text blockquote {
  border-left: 0
}

.text--size-large {
  font-size: 20px
}

.cmp-title .cmp-title__content h2.wipro-dot__exclamation:after,
.cmp-title .cmp-title__content h2.wipro-dot__period:after,
.cmp-title .cmp-title__content h2.wipro-dot__question:after {
  margin-left: -4px
}

footer.experiencefragment,
main.container {
  padding: .5em 1em
}

footer.experiencefragment .cmp-separator__horizontal-rule {
  border: 0;
  margin: 0
}

header.experiencefragment {
  padding: .5em 1em
}

header.experiencefragment a {
  color: #202020;
  text-decoration: none
}

header.experiencefragment a:focus,
header.experiencefragment a:hover {
  color: #3198b3;
  text-decoration: underline
}

header.experiencefragment .cmp-container {
  display: grid;
  grid-template-columns: 4fr 1fr 1fr
}

header.experiencefragment .cmp-navigation__group {
  list-style: none;
  margin: 0;
  padding: 0
}

header.experiencefragment .cmp-navigation__item--level-0 {
  display: grid;
  grid-template-columns: 1fr 3fr
}

header.experiencefragment .cmp-navigation__item--level-0>.cmp-navigation__group {
  display: grid;
  grid-template-columns: repeat(3, 1fr)
}

header.experiencefragment .cmp-navigation__item-link {
  display: block
}

header.experiencefragment .cmp-navigation__item--active>.cmp-navigation__item-link {
  font-weight: 700
}

header.experiencefragment .cmp-languagenavigation {
  position: relative
}

header.experiencefragment .cmp-languagenavigation:before {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="rgb(32,32,32)" d="M12.1,0.2h-0.1c-3.1,0-6.1,1.3-8.3,3.5C1.4,6,0.2,8.9,0.2,12.1c0,3.1,1.3,6.1,3.5,8.3c2.2,2.2,5.1,3.4,8.3,3.4 c0,0,0,0,0.1,0c6.5,0,11.7-5.3,11.7-11.8C23.7,5.5,18.5,0.3,12.1,0.2z M18.3,11.5c-0.1-1.4-0.3-2.8-0.7-4.1C18.5,7,19.4,6.5,20.3,6 c1.1,1.6,1.9,3.5,2,5.5H18.3z M22.2,12.5c-0.1,2.1-0.8,4-2,5.5c-0.8-0.6-1.7-1-2.6-1.4c0.3-1.1,0.6-2.2,0.7-3.3c0-0.3,0-0.5,0-0.8 H22.2z M19.6,5.2c-0.8,0.5-1.6,0.9-2.4,1.3c-0.6-1.4-1.4-2.7-2.3-3.8c-0.2-0.2-0.4-0.4-0.6-0.6C16.4,2.5,18.3,3.7,19.6,5.2z M12.8,22.2c-0.1,0-0.2,0-0.3,0v-5.7c1.3,0,2.6,0.3,3.8,0.7C15.6,19.1,14.3,20.8,12.8,22.2z M9.9,20.8c-0.9-1.1-1.7-2.3-2.2-3.6 c1.2-0.4,2.5-0.6,3.8-0.7v5.7c-0.1,0-0.2,0-0.3,0C10.7,21.8,10.3,21.3,9.9,20.8z M11.4,1.8c0,0,0.1,0,0.1,0v5.7 c-1.3,0-2.6-0.3-3.8-0.7C8.5,4.9,9.7,3.2,11.4,1.8z M14.1,3.3c0.9,1.1,1.6,2.3,2.2,3.5c-1.2,0.4-2.5,0.6-3.8,0.7V1.8 c0.1,0,0.1,0,0.2,0C13.2,2.2,13.7,2.7,14.1,3.3z M16.7,7.7c0.4,1.2,0.6,2.5,0.7,3.8h-4.8V8.4C13.9,8.4,15.3,8.2,16.7,7.7z M11.5,8.4 v3.1H6.7C6.7,10.2,7,9,7.4,7.7C8.7,8.2,10.1,8.4,11.5,8.4z M11.5,12.5v3.1c-1.4,0-2.8,0.3-4.2,0.7c-0.4-1.2-0.6-2.5-0.7-3.8H11.5z M12.5,15.6v-3.1h4.8c0,0.2,0,0.5,0,0.7c-0.1,1.1-0.3,2.1-0.6,3.1C15.3,15.8,13.9,15.6,12.5,15.6z M9.6,2C8.4,3.3,7.4,4.8,6.8,6.5 C5.9,6.1,5.1,5.7,4.4,5.2c0.1-0.1,0.2-0.3,0.4-0.4C6.1,3.4,7.8,2.5,9.6,2z M5.7,11.5H1.8c0.1-2,0.8-3.9,1.9-5.5 C4.6,6.5,5.5,7,6.4,7.4C6,8.7,5.7,10.1,5.7,11.5z M6.4,16.6C5.5,17,4.6,17.5,3.7,18c-1.2-1.6-1.9-3.5-1.9-5.5h3.9 C5.7,13.9,5.9,15.3,6.4,16.6z M4.4,18.8c0.8-0.5,1.5-0.9,2.4-1.3c0.6,1.4,1.4,2.7,2.4,3.9c0.1,0.2,0.3,0.3,0.4,0.5 c-1.8-0.4-3.4-1.3-4.7-2.7C4.6,19.1,4.5,19,4.4,18.8z M14.5,21.9c1.2-1.3,2.1-2.8,2.8-4.4c0.8,0.3,1.6,0.8,2.4,1.2 C18.3,20.3,16.5,21.4,14.5,21.9z"/></svg>');
  background-repeat: no-repeat;
  content: " ";
  display: block;
  height: 1.5em
}

header.experiencefragment .cmp-languagenavigation>.cmp-languagenavigation__group {
  background: #ececec;
  border: 1px solid #202020;
  border-top: 0;
  padding: 0 8px;
  position: absolute;
  top: 34px;
  -webkit-transition-delay: .5s;
  transition-delay: .5s;
  visibility: hidden;
  width: 20em
}

header.experiencefragment .cmp-languagenavigation:hover>.cmp-languagenavigation__group {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  visibility: visible
}

header.experiencefragment .cmp-languagenavigation__group {
  list-style: none;
  margin: 0;
  padding: 0
}

header.experiencefragment .cmp-languagenavigation__item-title {
  font-size: x-small;
  text-transform: uppercase
}

header.experiencefragment .cmp-languagenavigation__item--level-0 {
  margin-bottom: .5em
}

header.experiencefragment .cmp-languagenavigation__item--level-1 {
  display: inline
}

header.experiencefragment .cmp-languagenavigation__item--level-1:not(:first-child):before {
  content: " | "
}

header.experiencefragment .cmp-languagenavigation__item--active>.cmp-languagenavigation__item-link {
  font-weight: 700
}

header.experiencefragment .cmp-search__field {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: -3px 0
}

header.experiencefragment .cmp-search__input {
  height: 26px
}

/* NetworkExamples.css */

.network-container {
  background-color: #fff;
  /* Background color matching the screenshot */
  color: #000000;
  /* White text color */
  padding: 40px;
  text-align: center;
  font-family: var(--body-font-family);
}

.title {
  font-size: 2.5em;
  margin-bottom: 20px;
}

/* .description {
  font-size: 1.1em;
  max-width: 800px;
  margin: 0 auto 40px;
} */

.examples-section {
  display: flex;
  justify-content: center;
  gap: 40px;
  flex-wrap: wrap;
  /* Allow wrapping on smaller screens */
}

.example-box {

  flex: 1;
}

.example-title {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.example-description {
  font-size: 1em;
  line-height: 1.5em;
}

.vertical {
  border-left: 2px solid rgb(255, 255, 255);
  height: 140px;

}

.container1 {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.card {
  margin: 10px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  width: 300px;
}

.ca {
  margin: 10px;
  /* background-color: #fff; */
  border-radius: 10px;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  width: 300px;
}

.card-headers img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.card-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;

}

.tag {
  background: #cccccc;
  border-radius: 50px;
  font-size: 12px;
  margin: 0;
  color: #fff;
  padding: 2px 10px;
  text-transform: uppercase;
  cursor: pointer;
}

.tag-teal {
  background-color: #47bcd4;
}

.tag-purple {
  background-color: #5e76bf;
}

.tag-pink {
  background-color: #cd5b9f;
}

.card-body p {
  font-size: 13px;
  margin: 0 0 40px;
}

.user {
  display: flex;
  margin-top: auto;
}

.user img {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.user-info h5 {
  margin: 0;
}

.user-info small {
  color: #545d7a;
}


/* CaseStudiesGrid.css */

.case-studies-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px;
  /* Space between the grid items */
  max-width: 1200px;
  /* Maximum width of the grid */
  margin: 0 auto;
  /* Center align the grid */
  padding: 20px;
}

.case-study {
  position: relative;
  width: calc(33.33% - 20px);
  /* Adjust size for 3 columns */
  height: 250px;
  overflow: hidden;
  cursor: pointer;
}

.case-studys {
  position: relative;
  width: calc(33.33% - 20px);
  /* Adjust size for 3 columns */
  height: 250px;
  overflow: hidden;
  cursor: pointer;
}

.case-study1 {

  position: relative;
  width: calc(33.33% - 20px);
  /* Adjust size for 3 columns */
  height: auto;
  overflow: hidden;
  cursor: pointer;

}

.cmp-border-header .cmp-border-header__top-overlay-line {
  height: 3px;
  background-color: #000;
  z-index: 2;
  position: relative;
  top: 50%;
  flex: 1;
}

.para {
  font-size: 20px;
  font-family: var(--body-font-family);
  color: #222222;
}

.cmp-border-h2 {
  font-size: 40px;
  font-family: var(--body-font-family);
  font-weight: bold;
}

.cmp-border-header .cmp-border-header__top-overlay {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.cmp-border-header {
  background-color: unset;
}

.cmp-border-header {
  height: max-content;
  padding-left: 10%;
  padding-right: 10%;
}

.case-study-image {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  transition: transform 0.3s ease;
}

.wipro-case-studies-bg-textBox {
  position: absolute;
  bottom: 0;
  padding: 0 25px 12px 25px;
  /* bottom: 90%; */
  /* top: 10%; */
  color: #fff;
  padding: 0 25px 12px 25px;
}

.innerpage h2 span {
  font-family: var(--body-font-family);
  font-weight: normal;
  font-size: 40px;
}

.wipro-case-studies-bg {
  display: block;
  position: relative;
  color: #fff;
  box-sizing: border-box;
  padding-left: 0;
  margin-bottom: 24px;
}

.case-study:hover .case-study-image {
  transform: scale(1.1);
  /* Zoom effect on hover */
}

.case-study-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  /* Semi-transparent overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 25px;
  text-align: center;
  font-family: var(--body-font-family);
  font-weight: 500;
}


@media (max-width: 991px) {
  .case-studies-grid {
    display: block !important;

    gap: 15px;
    /* Space between the grid items */
    max-width: 1200px;
    /* Maximum width of the grid */
    margin: 0 auto;
    /* Center align the grid */
    padding: 20px;
  }

  .card {
    margin: 0px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    width: 100%;
    text-align: start;
  }

  .case-study {
    position: relative;
    width: auto;
    height: 250px;
    overflow: hidden;
    cursor: pointer;
  }
}

.workCarousel .wCarousel .card {
  overflow: hidden;
  background: #BCBCBC;
  box-shadow: 1px 4px 6px rgba(0, 0, 0, 0.2);
  border-radius: 24px;
  margin: 0 15px;
  height: 100%;
  position: relative;

}

.workCarousel .wCarousel .slick-track {
  display: flex;
  padding: 0 0 10px;
}

.sustainability-slider .workCarousel .cust_container {
  padding-top: var(--sliderpaddingTop) !important;
  padding-bottom: var(--sliderpaddingBottom) !important;
}

.cust_container {
  overflow: hidden;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
}



.workCarousel {
  background: #fff;
  overflow: hidden;
  width: 100%;
  position: relative;
  font-family: var(--body-font-family)
}

.workCarousel .title {
  font-family: var(--body-font-family);
  font-style: normal;
  font-weight: 600;
  font-size: var(--sliderheadingTitle) !important;
  line-height: 39px;
  letter-spacing: -0.03em;
  color: #271342;
  position: relative;
  width: 100%;
  margin: 0 0 30px;
  padding: 0 20px
}

.workCarousel .title span {
  margin: 0 0 0 8px
}

.workCarousel .title span img {
  height: auto;
  width: 70px
}

.workCarousel .title span strong {
  margin: 0 0 0 6px;
  font-size: 55px
}

.workCarousel .desc {
  font-size: var(--sliderdescTitle) !important;
  line-height: 1.1;
  margin-bottom: 50px;
  /* padding: 0 20px */
}

.sustainability-slider .workCarousel .cust_container {
  padding-top: var(--sliderpaddingTop) !important;
  padding-bottom: var(--sliderpaddingBottom) !important
}

.workCarousel .wCarousel {
  position: relative;
  padding-bottom: 50px
}

.workCarousel .wCarousel>button {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(0, -30%);
  text-indent: -10000px;
  border: 3px solid rgba(0, 0, 0, 1);
  border-radius: 50%;
  width: 32px;
  height: 32px;
  background: transparent;
  cursor: pointer;
  top: unset;
  display: block !important
}

.workCarousel .wCarousel.noArrows>button {
  display: none !important
}

.workCarousel .wCarousel>button.slick-prev {
  left: calc(50% - 40px)
}

.workCarousel .wCarousel>button.slick-next {
  left: unset;
  right: calc(50% - 40px)
}

.workCarousel .wCarousel>button:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  border-top: 2px solid rgba(0, 0, 0, 1);
  border-right: 2px solid rgba(0, 0, 0, 1);
  width: 10px;
  height: 10px;
  transform: translate(-20%, -50%) rotate(-135deg)
}

.workCarousel .wCarousel>button.slick-next:before {
  transform: translate(-75%, -50%) rotate(45deg)
}

.workCarousel .wCarousel .slick-track {
  /* display: flex; */
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  padding: 0 0 10px
}

.workCarousel .wCarousel .slick-slide {
  height: inherit;
  margin: 0
}

.workCarousel .wCarousel .slick-slide .title {
  text-align: left;
  color: #000
}

.workCarousel .wCarousel .card {
  overflow: hidden;
  background: #e6e1e1;
  box-shadow: 1px 4px 6px rgba(0, 0, 0, 0.2);
  border-radius: 24px;
  margin: 0 15px;
  height: 100%;
  height: 100%;
  padding: 0 0 50px;
  position: relative;

}

.services-section {
  background: #e7e2e2;
}

.workCarousel .wCarousel .card .imageContainer {
  position: relative;
  height: fit-content;
  height: -moz-fit-content;
  max-height: 300px
}

.workCarousel .wCarousel .card .imageContainer .playButton {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)
}

.workCarousel .wCarousel .card .imageContainer>img {
  width: 100%;
  position: relative;
  top: unset;
  left: unset;
  right: unset;
  bottom: unset
}


.workCarousel .wCarousel .card .textContainer .title,
.workCarousel .wCarousel .card .textContainer .desc {
  margin-bottom: 0;
  font-family: 10px;
  font-size: var(--slidertileHeading) !important;
  line-height: normal
}

.workCarousel .wCarousel .card .textContainer .title {
  font-family: var(--body-font-family)
}

.workCarousel .wCarousel .card .textContainer .desc {
  font-family: var(--body-font-family);
  font-size: 15px !important
}

.workCarousel .wCarousel .card .textContainer .buttonContainer {
  position: absolute;
  bottom: 30px;
  left: 0
}

.workCarousel .wCarousel.noArrows .slick-track {
  margin: 0 auto
}

button.slick-arrow.slick-disabled {
  opacity: 1
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 1 !important
}

.workCarousel .wCarousel .card .plusIcon>img {
  width: 100%;
  position: relative;
  top: unset;
  left: unset;
  right: unset;
  bottom: unset
}

.workCarousel .wCarousel .card .playButton img {
  width: 100%;
  position: relative;
  top: unset;
  left: unset;
  right: unset;
  bottom: unset
}



@media only screen and (max-width: 768px) and (max-height:1024px) and (orientation:portrait) {
  .workCarousel .title {
    font-size: 36px
  }

  .workCarousel .title span {
    display: block
  }
}

@media only screen and (max-width: 834px) and (max-height:1194px) and (orientation:portrait) {
  .workCarousel .title span {
    display: inline-block
  }
}

@media only screen and (max-width: 801px) {
  .workCarousel .desc {
    font-size: 18px
  }
}

@media only screen and (max-width: 768px) and (max-height:1024px) and (orientation:portrait) {
  .workCarousel .desc {
    font-size: 18px
  }

  .articles .card.fullwidth .textContainer .desc {
    width: 100%
  }
}

@media only screen and (max-width: 767px) {
  .workCarousel {
    padding: 30px 0
  }

  .workCarousel .title {
    font-size: 28px;
    margin: 0 auto 20px;
    width: 80%;
    line-height: 1
  }

  .workCarousel .title img {
    height: 30px
  }

  .workCarousel .wCarousel {
    margin: 0 auto;
    padding: 0 0 50px;
    width: 80%
  }

  .workCarousel .wCarousel .card {
    margin: 0 5%;
    width: 90%
  }

  .workCarousel .wCarousel .card .title {
    width: 100%
  }

  .workCarousel .wCarousel .card .imageContainer .playButton {
    width: 50px;
    height: 50px
  }

  .workCarousel .wCarousel .card .imageContainer .playButton img {
    width: 100%
  }

  .workCarousel .title span {
    display: block
  }

  .workCarousel .title span img {
    width: auto;
    height: 35px
  }

  .workCarousel .title span strong {
    font-size: 32px;
    margin: 0
  }
}

@media only screen and (max-width: 414px) {
  .workCarousel .desc {
    font-size: 16px;
    margin: 0 0 30px
  }
}

@media only screen and (max-width: 375px) {
  .workCarousel .title span {
    display: block
  }
}

@media only screen and (max-width: 1024px) {
  .sustainability-slider .workCarousel .cust_container {
    padding-top: calc(var(--sliderpaddingTop)/2) !important;
    padding-bottom: calc(var(--sliderpaddingBottom)/2) !important
  }
}

@media only screen and (max-width: 768px) {
  .workCarousel .title {
    font-size: calc(var(--sliderheadingTitle) - 20px) !important;
    padding: 0 30px
  }

  .workCarousel .desc {
    font-size: calc(var(--sliderdescTitle) - 12px) !important;
    padding: 0 30px
  }

  .workCarousel .wCarousel .card .textContainer .title {
    font-size: calc(var(--slidertileHeading) - 10px) !important;
    padding: 0
  }

  .workCarousel .wCarousel .card .textContainer .desc {
    font-size: calc(var(--slidertileDesc) - 5px) !important;
    padding: 0
  }
}

@media only screen and (max-width: 414px) {
  .sustainability-slider .workCarousel .cust_container {
    padding-top: calc(var(--sliderpaddingTop)/3) !important;
    padding-bottom: calc(var(--sliderpaddingBottom)/3) !important
  }

  .workCarousel .title {
    font-size: calc(var(--sliderheadingTitle) - 26px) !important;
    text-align: center !important;
    padding: 0
  }

  .workCarousel .desc {
    font-size: calc(var(--sliderdescTitle) - 15px) !important;
    padding: 0
  }

  .workCarousel .wCarousel .card .textContainer .title {
    font-size: calc(var(--slidertileHeading) - 10px) !important;
    padding: 0
  }

  .workCarousel .wCarousel .card .textContainer .desc {
    font-size: calc(var(--slidertileDesc) - 5px) !important;
    padding: 0
  }

  .workCarousel .cust_container>.desc {
    text-align: center !important
  }
}

@media only screen and (min-width: 1170px) and (orientation:portrait) {
  .sustainability-slider .workCarousel .cust_container {
    padding-top: calc(var(--sliderpaddingTop)/3) !important;
    padding-bottom: calc(var(--sliderpaddingBottom)/3) !important
  }

  .workCarousel .title {
    font-size: calc(var(--sliderheadingTitle) - 26px) !important;
    text-align: center !important
  }

  .workCarousel .desc {
    font-size: calc(var(--sliderdescTitle) - 15px) !important
  }

  .workCarousel .wCarousel .card .textContainer .title {
    font-size: calc(var(--slidertileHeading) - 10px) !important;
    padding: 0
  }

  .workCarousel .wCarousel .card .textContainer .desc {
    font-size: calc(var(--slidertileDesc) - 5px) !important;
    padding: 0
  }

  .workCarousel .cust_container>.desc {
    text-align: center !important
  }
}

@media (max-width:991px) {
  .workCarousel .wCarousel .slick-track {
    /* display: flex; */
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
    padding: 0 0 10px
  }

  .service-card:before {
    content: "";
    position: absolute;
    top: -38px;
    left: 50%;
    transform: translateX(-60%);
    background-color: white;
    min-width: 60px;
    min-height: 60px;
    border-radius: 50%;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    background-image: var(--image-url);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  .cmp-border-h2 {
    font-size: 30px;
    font-family: var(--body-font-family);
    font-weight: bold;
  }

  .para {
    font-size: 15px;
    font-family: var(--body-font-family);
    color: #222222;
  }
}

@media (max-width: 768px) {
  .cmp-responsive-image-banner .cmp-responsive-image-banner__title {
    font-size: 55px;
    line-height: 45px;
  }
}

.cmp-responsive-image-banner .cmp-responsive-image-banner__title {
  z-index: 2;
  padding-top: 40px;
  font-family: var(--body-font-family);
  color: white;
  font-weight: 600;
  width: max-content;
  font-size: 80px;
  line-height: 70px;

}

@media only screen and (min-width: 768px) {
  .cmp-responsive-image-banner .cmp-responsive-image-banner__top-overlay {
    height: 130px;
    width: 100%;
  }
}

.cmp-responsive-image-banner .cmp-responsive-image-banner__top-overlay {
  height: 130px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-left: 10%;
  padding-right: 10%;
  background: black;
}

.cmp-responsive-image-banner {
  display: flex;
  flex-direction: column;
  margin-bottom: -100px;
  background-color: transparent
}

.cmp-responsive-image-banner .cmp-responsive-image-banner__content {
  display: flex;
  flex-direction: row;
  position: relative;
  top: -130px
}

.cmp-responsive-image-banner .cmp-responsive-image-banner__top-overlay {
  height: 130px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-left: 10%;
  padding-right: 10%;
  background: black
}

.cmp-responsive-image-banner .cmp-responsive-image-banner__title {
  z-index: 2;
  padding-top: 40px;
  font-family: var(--body-font-family);
  color: white;
  font-weight: 600;
  width: max-content;
  font-size: 80px;
  line-height: 70px;
}

.cmp-responsive-image-banner .cmp-responsive-image-banner__top-overlay-box {
  background-image: linear-gradient(black, rgba(0, 0, 0, 0));
  width: 100%;
  height: 130px;
  z-index: 1;
  position: relative;
  /* top: -30% */
}

.cmp-responsive-image-banner .cmp-responsive-image-banner__top-overlay-line {
  width: 45%;
  height: 1px;
  background-color: white;
  z-index: 2;
  margin-top: 30px;
  flex: 1
}

.cmp-responsive-image-banner .cmp-responsive-image-banner__section {
  display: flex;
  flex-direction: column;
  position: relative
}

.cmp-responsive-image-banner .cmp-responsive-image-banner__section .cmp-responsive-image-banner__image {
  object-fit: cover;
  aspect-ratio: 1 / .85;
  width: 100%
}

.cmp-responsive-image-banner .cmp-responsive-image-banner__section .cmp-responsive-image-banner__text-overlay {
  position: absolute;
  bottom: 20px;
  width: 100%;
  font-family: var(--body-font-family);
  color: white;
  font-weight: 600;
  filter: drop-shadow(3px 5px 4px rgb(0, 0, 0));
  text-align: center;
  z-index: 2;
  font-size: 40px
}

.cmp-responsive-image-banner .cmp-responsive-image-banner__section .cmp-responsive-image-banner__bottom-overlay-box {
  position: absolute;
  width: 100%;
  background-image: linear-gradient(rgba(0, 0, 0, 0), black);
  bottom: 0;
  height: 10%
}

@media only screen and (max-width: 768px) {
  .cmp-responsive-image-banner .cmp-responsive-image-banner__title {
    font-size: 30px;
    line-height: 45px
  }
}

@media only screen and (min-width: 768px) and (max-width:1000px) {
  .cmp-responsive-image-banner .cmp-responsive-image-banner__title {
    font-size: 60px;
    line-height: 50px
  }

  .cmp-responsive-image-banner .cmp-responsive-image-banner__section .cmp-responsive-image-banner__text-overlay {
    font-size: 35px;
    line-height: 30px
  }
}

@media only screen and (min-width: 1000px) and (max-width:1300px) {
  .cmp-responsive-image-banner .cmp-responsive-image-banner__title {
    font-size: 70px;
    line-height: 60px
  }
}

@media only screen and (min-width: 1300px) {
  .cmp-responsive-image-banner .cmp-responsive-image-banner__title {
    font-size: 80px;
    line-height: 70px
  }

  .cmp-responsive-image-banner .cmp-responsive-image-banner__section .cmp-responsive-image-banner__text-overlay {
    font-size: 40px;
    line-height: 30px
  }
}

@media only screen and (max-width: 768px) {
  .cmp-responsive-image-banner .cmp-responsive-image-banner__content {
    flex-direction: column
  }

  .cmp-responsive-image-banner .cmp-responsive-image-banner__section {
    width: 100%
  }

  .cmp-responsive-image-banner .cmp-responsive-image-banner__top-overlay {
    height: 100px;
    width: 100%
  }
}

@media only screen and (min-width: 768px) {
  .cmp-responsive-image-banner .cmp-responsive-image-banner__content {
    flex-direction: row
  }

  .cmp-responsive-image-banner .cmp-responsive-image-banner__section {
    width: 33.33%
  }

  .cmp-responsive-image-banner .cmp-responsive-image-banner__top-overlay {
    height: 130px;
    width: 100%
  }
}

@media (max-width:991px) {
  .case-studys {
    position: relative;
    width: 100%;
    /* Adjust size for 3 columns */
    height: 250px;
    overflow: hidden;
    cursor: pointer;
  }
}


.backround1 {
  background: url(/public/background.png);
  max-width: 100%;
}