
.flickity-enabled {
    position: relative
}

.flickity-enabled:focus {
    outline: none
}

.flickity-viewport {
    height: 100%;
    overflow: hidden;
    position: relative;
    -ms-touch-action: pan-y;
    touch-action: pan-y
}

.flickity-slider {
    height: 100%;
    left: 0;
    position: absolute;
    width: 100%
}

.flickity-rtl .flickity-slider {
    left: unset;
    right: 0
}

.flickity-enabled.is-draggable {
    -webkit-tap-highlight-color: transparent;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.flickity-enabled.is-draggable .flickity-viewport {
    cursor: move;
    cursor: -webkit-grab;
    cursor: grab
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
    cursor: -webkit-grabbing;
    cursor: grabbing
}

.flickity-cell {
    left: 0;

}

.flickity-rtl .flickity-cell {
    left: unset;
    right: 0
}

.flickity-button {
    background: hsl(0 0% 100%/75%);
    border: none;
    color: #333;
    position: absolute
}

.flickity-button:hover {
    background: #fff;
    cursor: pointer
}

.flickity-button:focus {
    -webkit-box-shadow: 0 0 0 5px #19f;
    box-shadow: 0 0 0 5px #19f;
    outline: none
}

.flickity-button:active {
    opacity: .6
}

.flickity-button:disabled {
    cursor: auto;
    opacity: .3;
    pointer-events: none
}

.flickity-button-icon {
    fill: currentColor
}

.flickity-prev-next-button {
    border-radius: 50%;
    height: 44px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 44px
}

.flickity-prev-next-button.previous {
    left: 10px
}

.flickity-prev-next-button.next {
    right: 10px
}

.flickity-rtl .flickity-prev-next-button.previous {
    left: auto;
    right: 10px
}

.flickity-rtl .flickity-prev-next-button.next {
    left: 10px;
    right: auto
}

.flickity-prev-next-button .flickity-button-icon {
    height: 60%;
    left: 20%;
    position: absolute;
    top: 20%;
    width: 60%
}

.flickity-page-dots {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    bottom: -25px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    justify-content: center;
    position: absolute;
    text-align: center;
    width: 100%
}

.flickity-rtl .flickity-page-dots {
    direction: rtl
}

.flickity-page-dot {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: hsl(0 0% 20%/25%);
    border: none;
    border-radius: 50%;
    cursor: pointer;
    display: block;
    height: 10px;
    margin: 0 8px;
    overflow: hidden;
    padding: 0;
    text-indent: -9999px;
    width: 10px
}

.flickity-rtl .flickity-page-dot {
    text-indent: 9999px
}

.flickity-page-dot:focus {
    -webkit-box-shadow: 0 0 0 5px #19f;
    box-shadow: 0 0 0 5px #19f;
    outline: none
}

.flickity-page-dot.is-selected {
    background: #333
}

:root {
    --rad-global-header-height: 72px;
    --rad-nav-height: 56px
}

@media(min-width: 600px) {
    :root {
        --rad-global-header-height:80px;
        --rad-nav-height: 64px
    }
}

@media(min-width: 1024px) {
    :root {
        --rad-global-header-height:88px;
        --rad-nav-height: 72px
    }
}

@media(min-width: 1441px) {
    :root {
        --rad-global-header-height:calc(-0.24495px + 6.12387vw)
    }
}

@media(min-width: 1920px) {
    :root {
        --rad-global-header-height:117.3333333333px
    }
}

@media(min-width: 1441px) {
    :root {
        --rad-nav-height:calc(-0.20042px + 5.01044vw)
    }
}

@media(min-width: 1920px) {
    :root {
        --rad-nav-height:96px
    }
}



:root {
    --rad-spacing-sm: 16px;
    --rad-spacing-md: 32px;
    --rad-spacing-lg: 48px;
    --rad-spacing-xl: 96px;
    --rad-spacing-xxl: 160px;
    --rad-component-spacing-none: 0;
    --rad-component-spacing-small: 10px;
    --rad-component-spacing-medium: 20px;
    --rad-component-spacing-large: 40px
}



.rad-spacing-top-sm {
    padding-top: 1rem
}

.rad-spacing-bottom-sm {
    padding-bottom: 1rem
}

.rad-spacing-vertical-sm {
    padding-bottom: 1rem;
    padding-top: 1rem
}

.rad-spacing-top-md {
    padding-top: 1.5rem
}

.rad-spacing-bottom-md {
    padding-bottom: 1.5rem
}

.rad-spacing-vertical-md {
    padding-bottom: 1.5rem;
    padding-top: 1.5rem
}

.rad-spacing-top-lg {
    padding-top: 5rem
}

.rad-spacing-bottom-lg {
    padding-bottom: 5rem
}

.rad-spacing-vertical-lg {
    padding-bottom: 5rem;
    padding-top: 5rem
}

.rad-component-spacing-top-none {
    padding-top: var(--rad-component-spacing-none)
}

.rad-component-spacing-bottom-none {
    padding-bottom: var(--rad-component-spacing-none)
}

.rad-component-spacing-top-small {
    padding-top: var(--rad-component-spacing-small)
}

.rad-component-spacing-bottom-small {
    padding-bottom: var(--rad-component-spacing-small)
}

.rad-component-spacing-top-medium {
    padding-top: var(--rad-component-spacing-medium)
}

.rad-component-spacing-bottom-medium {
    padding-bottom: var(--rad-component-spacing-medium)
}

.rad-component-spacing-top-large {
    padding-top: var(--rad-component-spacing-large)
}

.rad-component-spacing-bottom-large {
    padding-bottom: var(--rad-component-spacing-large)
}

@media(min-width: 600px) {
    :root {
        --rad-spacing-sm:24px;
        --rad-spacing-md: 48px;
        --rad-spacing-lg: 64px;
        --rad-spacing-xl: 120px;
        --rad-spacing-xxl: 200px;
        --rad-component-spacing-small: 15px;
        --rad-component-spacing-medium: 30px;
        --rad-component-spacing-large: 60px
    }

    .rad-spacing-top-sm {
        padding-top: 1.5rem
    }

    .rad-spacing-bottom-sm {
        padding-bottom: 1.5rem
    }

    .rad-spacing-vertical-sm {
        padding-bottom: 1.5rem;
        padding-top: 1.5rem
    }

    .rad-spacing-top-md {
        padding-top: 3.75rem
    }

    .rad-spacing-bottom-md {
        padding-bottom: 3.75rem
    }

    .rad-spacing-vertical-md {
        padding-bottom: 3.75rem;
        padding-top: 3.75rem
    }

    .rad-spacing-top-lg {
        padding-top: 6.25rem
    }

    .rad-spacing-bottom-lg {
        padding-bottom: 6.25rem
    }

    .rad-spacing-vertical-lg {
        padding-bottom: 6.25rem;
        padding-top: 6.25rem
    }
}

@media(min-width: 1024px) {
    :root {
        --rad-spacing-sm:32px;
        --rad-spacing-md: 80px;
        --rad-spacing-lg: 96px;
        --rad-spacing-xl: 160px;
        --rad-spacing-xxl: 240px;
        --rad-component-spacing-small: 20px;
        --rad-component-spacing-medium: 40px;
        --rad-component-spacing-large: 80px
    }
}

@media(min-width: 1441px) {
    :root {
        --rad-spacing-sm:calc(-0.08907px + 2.22686vw)
    }
}

@media(min-width: 1920px) {
    :root {
        --rad-spacing-sm:42.6666666667px
    }
}

@media(min-width: 1441px) {
    :root {
        --rad-spacing-md:calc(-0.22269px + 5.56715vw)
    }
}

@media(min-width: 1920px) {
    :root {
        --rad-spacing-md:106.6666666667px
    }
}

@media(min-width: 1441px) {
    :root {
        --rad-spacing-lg:calc(-0.26722px + 6.68058vw)
    }
}

@media(min-width: 1920px) {
    :root {
        --rad-spacing-lg:128px
    }
}

@media(min-width: 1441px) {
    :root {
        --rad-spacing-xl:calc(-0.44537px + 11.13431vw)
    }
}

@media(min-width: 1920px) {
    :root {
        --rad-spacing-xl:213.3333333333px
    }
}

@media(min-width: 1441px) {
    :root {
        --rad-spacing-xxl:calc(-0.66806px + 16.70146vw)
    }
}

@media(min-width: 1920px) {
    :root {
        --rad-spacing-xxl:320px
    }
}

@media(min-width: 1441px) {
    :root {
        --rad-component-spacing-small:calc(-0.05567px + 1.39179vw)
    }
}

@media(min-width: 1920px) {
    :root {
        --rad-component-spacing-small:26.6666666667px
    }
}

@media(min-width: 1441px) {
    :root {
        --rad-component-spacing-medium:calc(-0.11134px + 2.78358vw)
    }
}

@media(min-width: 1920px) {
    :root {
        --rad-component-spacing-medium:53.3333333333px
    }
}

@media(min-width: 1441px) {
    :root {
        --rad-component-spacing-large:calc(-0.22269px + 5.56715vw)
    }
}

@media(min-width: 1920px) {
    :root {
        --rad-component-spacing-large:106.6666666667px
    }
}

@media(min-width: 1024px) {
    .rad-spacing-top-sm {
        padding-top:2rem
    }
}

@media(min-width: 1441px) {
    .rad-spacing-top-sm {
        padding-top:calc(2rem + 2.22686vw - 32.08907px)
    }
}

@media(min-width: 1920px) {
    .rad-spacing-top-sm {
        padding-top:2.6666666667rem
    }
}

@media(min-width: 1024px) {
    .rad-spacing-bottom-sm {
        padding-bottom:2rem
    }
}

@media(min-width: 1441px) {
    .rad-spacing-bottom-sm {
        padding-bottom:calc(2rem + 2.22686vw - 32.08907px)
    }
}

@media(min-width: 1920px) {
    .rad-spacing-bottom-sm {
        padding-bottom:2.6666666667rem
    }
}

@media(min-width: 1024px) {
    .rad-spacing-vertical-sm {
        padding-bottom:2rem;
        padding-top: 2rem
    }
}

@media(min-width: 1441px) {
    .rad-spacing-vertical-sm {
        padding-bottom:calc(2rem + 2.22686vw - 32.08907px)
    }
}

@media(min-width: 1920px) {
    .rad-spacing-vertical-sm {
        padding-bottom:2.6666666667rem
    }
}

@media(min-width: 1441px) {
    .rad-spacing-vertical-sm {
        padding-top:calc(2rem + 2.22686vw - 32.08907px)
    }
}

@media(min-width: 1920px) {
    .rad-spacing-vertical-sm {
        padding-top:2.6666666667rem
    }
}

@media(min-width: 1024px) {
    .rad-spacing-top-md {
        padding-top:3.75rem
    }
}

@media(min-width: 1441px) {
    .rad-spacing-top-md {
        padding-top:calc(3.75rem + 4.17537vw - 60.16701px)
    }
}

@media(min-width: 1920px) {
    .rad-spacing-top-md {
        padding-top:5rem
    }
}

@media(min-width: 1024px) {
    .rad-spacing-bottom-md {
        padding-bottom:3.75rem
    }
}

@media(min-width: 1441px) {
    .rad-spacing-bottom-md {
        padding-bottom:calc(3.75rem + 4.17537vw - 60.16701px)
    }
}

@media(min-width: 1920px) {
    .rad-spacing-bottom-md {
        padding-bottom:5rem
    }
}

@media(min-width: 1024px) {
    .rad-spacing-vertical-md {
        padding-bottom:3.75rem;
        padding-top: 3.75rem
    }
}

@media(min-width: 1441px) {
    .rad-spacing-vertical-md {
        padding-bottom:calc(3.75rem + 4.17537vw - 60.16701px)
    }
}

@media(min-width: 1920px) {
    .rad-spacing-vertical-md {
        padding-bottom:5rem
    }
}

@media(min-width: 1441px) {
    .rad-spacing-vertical-md {
        padding-top:calc(3.75rem + 4.17537vw - 60.16701px)
    }
}

@media(min-width: 1920px) {
    .rad-spacing-vertical-md {
        padding-top:5rem
    }
}

@media(min-width: 1024px) {
    .rad-spacing-top-md-alt {
        padding-top:2.5rem
    }
}

@media(min-width: 1441px) {
    .rad-spacing-top-md-alt {
        padding-top:calc(2.5rem + 2.78358vw - 40.11134px)
    }
}

@media(min-width: 1920px) {
    .rad-spacing-top-md-alt {
        padding-top:3.3333333333rem
    }
}

@media(min-width: 1024px) {
    .rad-spacing-bottom-md-alt {
        padding-bottom:2.5rem
    }
}

@media(min-width: 1441px) {
    .rad-spacing-bottom-md-alt {
        padding-bottom:calc(2.5rem + 2.78358vw - 40.11134px)
    }
}

@media(min-width: 1920px) {
    .rad-spacing-bottom-md-alt {
        padding-bottom:3.3333333333rem
    }
}

@media(min-width: 1024px) {
    .rad-spacing-vertical-md-alt {
        padding-bottom:2.5rem;
        padding-top: 2.5rem
    }
}

@media(min-width: 1441px) {
    .rad-spacing-vertical-md-alt {
        padding-bottom:calc(2.5rem + 2.78358vw - 40.11134px)
    }
}

@media(min-width: 1920px) {
    .rad-spacing-vertical-md-alt {
        padding-bottom:3.3333333333rem
    }
}

@media(min-width: 1441px) {
    .rad-spacing-vertical-md-alt {
        padding-top:calc(2.5rem + 2.78358vw - 40.11134px)
    }
}

@media(min-width: 1920px) {
    .rad-spacing-vertical-md-alt {
        padding-top:3.3333333333rem
    }
}

@media(min-width: 1024px) {
    .rad-spacing-top-lg {
        padding-top:7.5rem
    }
}

@media(min-width: 1441px) {
    .rad-spacing-top-lg {
        padding-top:calc(7.5rem + 8.35073vw - 120.33403px)
    }
}

@media(min-width: 1920px) {
    .rad-spacing-top-lg {
        padding-top:10rem
    }
}

@media(min-width: 1024px) {
    .rad-spacing-bottom-lg {
        padding-bottom:7.5rem
    }
}

@media(min-width: 1441px) {
    .rad-spacing-bottom-lg {
        padding-bottom:calc(7.5rem + 8.35073vw - 120.33403px)
    }
}

@media(min-width: 1920px) {
    .rad-spacing-bottom-lg {
        padding-bottom:10rem
    }
}

@media(min-width: 1024px) {
    .rad-spacing-vertical-lg {
        padding-bottom:7.5rem;
        padding-top: 7.5rem
    }
}

@media(min-width: 1441px) {
    .rad-spacing-vertical-lg {
        padding-bottom:calc(7.5rem + 8.35073vw - 120.33403px)
    }
}

@media(min-width: 1920px) {
    .rad-spacing-vertical-lg {
        padding-bottom:10rem
    }
}

@media(min-width: 1441px) {
    .rad-spacing-vertical-lg {
        padding-top:calc(7.5rem + 8.35073vw - 120.33403px)
    }
}

@media(min-width: 1920px) {
    .rad-spacing-vertical-lg {
        padding-top:10rem
    }
}

.rad-icon {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-family: icont;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    justify-content: center
}

@media(min-width: 1441px) {
    .rad-icon {
        font-size:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-icon {
        font-size:2rem
    }
}

.rad-icon.rad-icon--compact {
    font-size: 1.25rem
}

@media(min-width: 1441px) {
    .rad-icon.rad-icon--compact {
        font-size:calc(1.25rem + 1.39179vw - 20.05567px)
    }
}

@media(min-width: 1920px) {
    .rad-icon.rad-icon--compact {
        font-size:1.6666666667rem
    }
}

.rad-icon.rad-icon--large {
    font-size: 2.5rem
}

@media(min-width: 1441px) {
    .rad-icon.rad-icon--large {
        font-size:calc(2.5rem + 2.78358vw - 40.11134px)
    }
}

@media(min-width: 1920px) {
    .rad-icon.rad-icon--large {
        font-size:3.3333333333rem
    }
}

.rad-icon:after {
    position: relative
}

.rad-icon.rad-icon__north-east:after {
    content: "";
    font-size: 62.5%
}

.rad-icon.rad-icon__north-west:after {
    content: "";
    font-size: 62.5%
}

.rad-icon.rad-icon__south-east:after {
    content: "";
    font-size: 62.5%
}

.rad-icon.rad-icon__south-west:after {
    content: "";
    font-size: 62.5%
}

.rad-icon.rad-icon__down:after {
    content: "";
    font-size: 66.6666666667%
}

.rad-icon.rad-icon__up:after {
    content: "";
    font-size: 66.6666666667%
}

.rad-icon.rad-icon__right:after {
    content: "";
    font-size: 66.6666666667%
}

.rad-icon.rad-icon__left:after {
    content: "";
    font-size: 66.6666666667%
}

.rad-icon.rad-icon__chevron-left:after {
    content: "";
    font-size: 50%
}

.rad-icon.rad-icon__chevron-right:after {
    content: "";
    font-size: 50%
}

.rad-icon.rad-icon__chevron-down:after {
    content: "";
    font-size: 37.5%
}

.rad-icon.rad-icon__chevron-up:after {
    content: "";
    font-size: 37.5%
}

.rad-icon.rad-icon__favorite-default:after {
    content: "";
    font-size: 79.1555683963%
}

.rad-icon.rad-icon__favorite-active:after {
    content: "";
    font-size: 79.1555683963%
}

.rad-icon.rad-icon__search:after {
    content: "";
    font-size: 75%
}

.rad-icon.rad-icon__menu:after {
    content: "";
    font-size: 66.6666666667%
}

.rad-icon.rad-icon__filter:after {
    content: "";
    font-size: 50%
}

.rad-icon.rad-icon__sort:after {
    content: "";
    font-size: 50%
}

.rad-icon.rad-icon__close:after {
    content: "";
    font-size: 58.3333333333%
}

.rad-icon.rad-icon__restart:after {
    content: "";
    font-size: 83.3333333333%
}

.rad-icon.rad-icon__share-alt-1:after {
    content: "";
    font-size: 83.3333333333%
}

.rad-icon.rad-icon__download:after {
    content: "";
    font-size: 66.6666666667%
}

.rad-icon.rad-icon__error:after {
    content: "";
    font-size: 83.3333333333%
}

.rad-icon.rad-icon__explore:after {
    content: "";
    font-size: 83.3333333333%
}

.rad-icon.rad-icon__share:after {
    content: "";
    font-size: 90%
}

.rad-icon.rad-icon__link:after {
    content: "";
    font-size: 41.6666666667%
}

.rad-icon.rad-icon__check:after {
    content: "";
    font-size: 50%
}

.rad-icon.rad-icon__minus:after {
    content: "";
    font-size: 8.3333333333%
}

.rad-icon.rad-icon__plus:after {
    content: "";
    font-size: 58.3333333333%
}

.rad-icon.rad-icon__play-default:after {
    content: "";
    font-size: 58.3333333333%
}

.rad-icon.rad-icon__play-circle:after {
    content: "";
    font-size: 83.3333333333%
}

.rad-icon.rad-icon__stop-default:after {
    content: "";
    font-size: 50%
}

.rad-icon.rad-icon__stop-circle:after {
    content: "";
    font-size: 83.3333333333%
}

.rad-icon.rad-icon__pause-default:after {
    content: "";
    font-size: 58.3333333333%
}

.rad-icon.rad-icon__pause-circle:after {
    content: "";
    font-size: 83.3333333333%
}

.rad-icon.rad-icon__hearing-active:after {
    content: "";
    font-size: 87.5201174714%
}

.rad-icon.rad-icon__hearing-disabled:after {
    content: "";
    font-size: 91.6666666667%
}

.rad-icon.rad-icon__brightness-dark:after {
    content: "";
    font-size: 83.3333333333%
}

.rad-icon.rad-icon__brightness-light:after {
    content: "";
    font-size: 83.3333333333%
}

.rad-icon.rad-icon__social-mail:after {
    content: "";
    font-size: 66.6666666667%
}

.rad-icon.rad-icon__social-twitter:after {
    content: "";
    font-size: 67.7083333333%
}

.rad-icon.rad-icon__social-x-twitter:after {
    content: "";
    font-size: 75%
}

.rad-icon.rad-icon__social-linkedin:after {
    content: "";
    font-size: 75%
}

.rad-icon.rad-icon__social-youtube:after {
    content: "";
    font-size: 62.5%
}

.rad-icon.rad-icon__social-facebook:after {
    content: "";
    font-size: 83.3333333333%
}

.rad-icon.rad-icon__social-google:after {
    content: "";
    font-size: 83.3333333333%
}

.rad-icon.rad-icon__social-telegram:after {
    content: "";
    font-size: 83.3333333333%
}

.rad-icon.rad-icon__social-instagram:after {
    content: "";
    font-size: 75%
}

.rad-icon.rad-icon__language-globe:after {
    content: "";
    font-size: 83.3333333333%
}

.rad-icon.rad-icon__external:after {
    content: ""
}

.rad-icon.rad-icon__wand:after {
    content: "";
    font-size: 87.5%
}

.rad-icon.rad-icon__alert:after {
    content: "";
    font-size: 83.3333333333%
}

.rad-icon.rad-icon__social-apple-podcast:after {
    content: "";
    font-size: 83.3333333333%
}

.rad-icon.rad-icon__social-spotify:after {
    content: "";
    font-size: 83.3333333333%
}

.rad-icon.rad-icon__social-google-podcast:after {
    content: "";
    font-size: 83.3333333333%
}

.rad-icon.rad-icon__social-pandora:after {
    content: "";
    font-size: 83.3333333333%
}

.rad-icon.rad-icon__social-soundcloud:after {
    content: "";
    font-size: 66.6666666667%
}

.rad-icon.rad-icon__social-radio-public:after {
    content: "";
    font-size: 83.3333333333%
}

.rad-icon.rad-icon__social-simplecast:after {
    content: "";
    font-size: 83.3333333333%
}

.rad-icon.rad-icon__location:after {
    content: ""
}

.rad-icon.rad-icon__fax:after {
    content: ""
}

.rad-icon.rad-icon__call:after {
    content: ""
}

.rad-icon.rad-icon__check-circle:after {
    content: "";
    font-size: 83.3333333333%
}

.rad-accordion-atom__content-wrapper {
    max-height: 0;
    overflow: hidden;
    -webkit-transition-duration: 550ms;
    transition-duration: 550ms;
    -webkit-transition-property: max-height,visibility;
    transition-property: max-height,visibility;
    -webkit-transition-timing-function: cubic-bezier(0.85,0,0,1);
    transition-timing-function: cubic-bezier(0.85,0,0,1);
    visibility: hidden
}

.rad-accordion-atom__content-wrapper--open {
    max-height: none;
    visibility: visible
}

.rad-accordion-atom__content-wrapper--no-animation {
    -webkit-transition-duration: 0s;
    transition-duration: 0s;
    -webkit-transition-property: none;
    transition-property: none
}

.rad-accordion-atom__toggle--animated {
    position: relative
}

.rad-accordion-atom__toggle--animated .rad-icon:last-child {
    position: absolute;
    rotate: -90deg;
    -webkit-transition-duration: 550ms;
    transition-duration: 550ms;
    -webkit-transition-property: rotate,visibility;
    transition-property: rotate,visibility;
    -webkit-transition-timing-function: cubic-bezier(0.85,0,0,1);
    transition-timing-function: cubic-bezier(0.85,0,0,1);
    visibility: visible
}

.rad-accordion-atom__toggle--animated .rad-accordion-atom__toggle-icon:after,.rad-accordion-atom__toggle--animated .rad-accordion-atom__toggle-icon:before {
    background-color: #fff;
    content: "";
    display: block;
    height: 2px;
    margin: 0 auto;
    position: absolute;
    top: 50%;
    -webkit-transition-duration: 550ms;
    transition-duration: 550ms;
    -webkit-transition-property: rotate;
    transition-property: rotate;
    -webkit-transition-timing-function: cubic-bezier(0.85,0,0,1);
    transition-timing-function: cubic-bezier(0.85,0,0,1);
    translate: 0 -50%;
    width: .875rem
}

.rad-accordion-atom__toggle--animated .rad-accordion-atom__toggle-icon:after {
    rotate: -90deg
}

.rad-accordion-atom__toggle--animated .rad-icon:last-child,.rad-accordion-atom__toggle--animated:active .rad-icon:last-child,.rad-accordion-atom__toggle--animated:disabled .rad-icon:last-child,.rad-accordion-atom__toggle--animated:focus .rad-icon:last-child,.rad-accordion-atom__toggle--animated:hover .rad-icon:last-child {
    background: none
}



.rad-accordion-atom__toggle--animated.rad-accordion-atom__toggle--open .rad-icon:last-child {
    rotate: 0deg;
    visibility: hidden
}

.rad-accordion-atom__toggle--animated.rad-accordion-atom__toggle--open .rad-accordion-atom__toggle-icon:after {
    rotate: 0deg
}

.rad-accordion-atom__toggle--no-anination {
    -webkit-transition-duration: 0s;
    transition-duration: 0s;
    -webkit-transition-property: none;
    transition-property: none
}

.rad-button {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-family: Graphik,Arial,Helvetica,sans-serif;
    font-size: .875rem;
    font-weight: 500;
    letter-spacing: -0.005em;
    line-height: 1.5;
    min-height: 3rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    text-decoration: none;
    text-underline-offset: .25rem;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content
}

.rad-button:lang(ja-JP),.rad-button:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-button {
        font-size:1rem
    }
}

@media(min-width: 1441px) {
    .rad-button {
        font-size:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-button {
        font-size:1.3333333333rem
    }
}

@media(min-width: 1441px) {
    .rad-button {
        min-height:calc(3rem + 3.34029vw - 48.13361px)
    }
}

@media(min-width: 1920px) {
    .rad-button {
        min-height:4rem
    }
}

@media(min-width: 1441px) {
    .rad-button {
        padding-left:calc(0.5rem + 0.55672vw - 8.02227px)
    }
}

@media(min-width: 1920px) {
    .rad-button {
        padding-left:0.6666666667rem
    }
}

@media(min-width: 1441px) {
    .rad-button {
        padding-right:calc(0.5rem + 0.55672vw - 8.02227px)
    }
}

@media(min-width: 1920px) {
    .rad-button {
        padding-right:0.6666666667rem
    }
}

.rad-button:-moz-any-link {
    color: inherit
}

.rad-button,.rad-button:any-link {
    color: inherit
}

.rad-button--disabled,.rad-button:disabled {
    cursor: default;
    opacity: .5;
    pointer-events: none
}

.rad-button--primary {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    position: relative;
    -webkit-transition: background-color 550ms cubic-bezier(0.85,0,0,1);
    transition: background-color 550ms cubic-bezier(0.85,0,0,1)
}

.rad-button--primary:before {
    background-image: -webkit-gradient(linear,left top,right top,color-stop(50%,#7500c0),color-stop(50%,#a100ff));
    background-image: linear-gradient(90deg,#7500c0 50%,#a100ff 0);
    background-position-x: 100%;
    background-size: 200% 200%;
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    -webkit-transition: background-position-x 550ms cubic-bezier(0.85,0,0,1);
    transition: background-position-x 550ms cubic-bezier(0.85,0,0,1);
    width: 100%
}

.rad-button--primary:hover:before {
    background-position-x: 0
}

.rad-button--primary:active {
    background-color: #57008f
}

.rad-button--secondary {
    border: 1px solid;
    padding-left: 1.25rem;
    padding-right: 1.25rem
}

@media(min-width: 1441px) {
    .rad-button--secondary {
        padding-left:calc(1.25rem + 1.39179vw - 20.05567px)
    }
}

@media(min-width: 1920px) {
    .rad-button--secondary {
        padding-left:1.6666666667rem
    }
}

@media(min-width: 1441px) {
    .rad-button--secondary {
        padding-right:calc(1.25rem + 1.39179vw - 20.05567px)
    }
}

@media(min-width: 1920px) {
    .rad-button--secondary {
        padding-right:1.6666666667rem
    }
}

.rad-button--secondary .rad-button__icon-right {
    color: #a100ff;
    margin-left: 0.75rem;
    padding-right: .25rem
}

@media(min-width: 1441px) {
    .rad-button--secondary .rad-button__icon-right {
        margin-left:calc(0.75rem + 0.83507vw - 12.0334px)
    }
}

@media(min-width: 1920px) {
    .rad-button--secondary .rad-button__icon-right {
        margin-left:1rem
    }
}

.rad-button--secondary:hover {
    color: #a2a2a0
}

.rad-button--secondary:active {
    border-color: #e3e3df;
    color: #e3e3df
}

.rad-button--tertiary {
    padding: 0
}

.rad-button--tertiary .rad-button__icon-right {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    height: 1.25rem;
    margin-left: 0.75rem;
    position: relative;
    -webkit-transition: background-color 550ms cubic-bezier(0.85,0,0,1),translate 550ms cubic-bezier(0.85,0,0,1);
    transition: background-color 550ms cubic-bezier(0.85,0,0,1),translate 550ms cubic-bezier(0.85,0,0,1);
    width: 1.25rem
}

@media(min-width: 1441px) {
    .rad-button--tertiary .rad-button__icon-right {
        height:calc(1.25rem + 1.39179vw - 20.05567px)
    }
}

@media(min-width: 1920px) {
    .rad-button--tertiary .rad-button__icon-right {
        height:1.6666666667rem
    }
}

@media(min-width: 1441px) {
    .rad-button--tertiary .rad-button__icon-right {
        margin-left:calc(0.75rem + 0.83507vw - 12.0334px)
    }
}

@media(min-width: 1920px) {
    .rad-button--tertiary .rad-button__icon-right {
        margin-left:1rem
    }
}

@media(min-width: 1441px) {
    .rad-button--tertiary .rad-button__icon-right {
        width:calc(1.25rem + 1.39179vw - 20.05567px)
    }
}

@media(min-width: 1920px) {
    .rad-button--tertiary .rad-button__icon-right {
        width:1.6666666667rem
    }
}

.rad-button--tertiary .rad-button__icon-right:before {
    background-image: -webkit-gradient(linear,left top,right top,color-stop(50%,#7500c0),color-stop(50%,#a100ff));
    background-image: linear-gradient(90deg,#7500c0 50%,#a100ff 0);
    background-position-x: 100%;
    background-size: 200% 200%;
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    -webkit-transition: background-position-x 550ms cubic-bezier(0.85,0,0,1);
    transition: background-position-x 550ms cubic-bezier(0.85,0,0,1);
    width: 100%
}

.rad-button--tertiary:hover .rad-button__icon-right {
    translate: 0.25rem
}

@media(min-width: 1441px) {
    .rad-button--tertiary:hover .rad-button__icon-right {
        translate:calc(0.25rem + 0.27836vw - 4.01113px)
    }
}

@media(min-width: 1920px) {
    .rad-button--tertiary:hover .rad-button__icon-right {
        translate:0.3333333333rem
    }
}

.rad-button--tertiary:hover .rad-button__icon-right:before {
    background-position-x: 0
}

.rad-button--tertiary:active .rad-button__icon-right {
    background-color: #57008f
}

.rad-button--tertiary-dynamic {
    padding: 0.25rem
}

@-webkit-keyframes tertiary-dynamic-start {
    to {
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }
}

@keyframes tertiary-dynamic-start {
    to {
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }
}

@media(min-width: 1441px) {
    .rad-button--tertiary-dynamic {
        padding:calc(0.25rem + 0.27836vw - 4.01113px)
    }
}

@media(min-width: 1920px) {
    .rad-button--tertiary-dynamic {
        padding:0.3333333333rem
    }
}

.rad-button--tertiary-dynamic:not(:disabled):not(.rad-button--disabled) .rad-button__icons-animation-container {
    -webkit-animation-delay: 700ms;
    animation-delay: 700ms;
    -webkit-animation-duration: 550ms;
    animation-duration: 550ms;
    -webkit-animation-name: tertiary-dynamic-start;
    animation-name: tertiary-dynamic-start;
    -webkit-animation-timing-function: cubic-bezier(0.85,0,0,1);
    animation-timing-function: cubic-bezier(0.85,0,0,1)
}

.rad-button--tertiary-dynamic:hover .rad-button__icons-animation-container {
    -webkit-transform: translateX(0);
    transform: translateX(0)
}

.rad-button--tertiary-dynamic:focus-visible {
    outline-offset: 0.25rem
}

@media(min-width: 1441px) {
    .rad-button--tertiary-dynamic:focus-visible {
        outline-offset:calc(0.25rem + 0.27836vw - 4.01113px)
    }
}

@media(min-width: 1920px) {
    .rad-button--tertiary-dynamic:focus-visible {
        outline-offset:0.3333333333rem
    }
}

.rad-button--tertiary-dynamic .rad-button__text {
    white-space: nowrap
}

.rad-button--tertiary-dynamic .rad-button__icons-animation-viewbox {
    margin-left: 0.5rem;
    overflow: hidden;
    width: 1.5rem
}

@media(min-width: 1441px) {
    .rad-button--tertiary-dynamic .rad-button__icons-animation-viewbox {
        margin-left:calc(0.5rem + 0.55672vw - 8.02227px)
    }
}

@media(min-width: 1920px) {
    .rad-button--tertiary-dynamic .rad-button__icons-animation-viewbox {
        margin-left:0.6666666667rem
    }
}

@media(min-width: 1441px) {
    .rad-button--tertiary-dynamic .rad-button__icons-animation-viewbox {
        width:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-button--tertiary-dynamic .rad-button__icons-animation-viewbox {
        width:2rem
    }
}

.rad-button--tertiary-dynamic .rad-button__icons-animation-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-transform: translateX(-200%);
    transform: translateX(-200%);
    -webkit-transition-duration: 550ms;
    transition-duration: 550ms;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform,-webkit-transform;
    -webkit-transition-timing-function: cubic-bezier(0.85,0,0,1);
    transition-timing-function: cubic-bezier(0.85,0,0,1)
}

.rad-button--tertiary-dynamic .rad-button__icons-animation-container :last-child {
    margin-left: 1.5rem
}

@media(min-width: 1441px) {
    .rad-button--tertiary-dynamic .rad-button__icons-animation-container :last-child {
        margin-left:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-button--tertiary-dynamic .rad-button__icons-animation-container :last-child {
        margin-left:2rem
    }
}

.rad-button--tertiary-dynamic .rad-icon {
    -ms-flex-negative: 0;
    color: #a100ff;
    flex-shrink: 0;
    height: 1.5rem;
    width: 1.5rem
}

@media(min-width: 1441px) {
    .rad-button--tertiary-dynamic .rad-icon {
        height:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-button--tertiary-dynamic .rad-icon {
        height:2rem
    }
}

@media(min-width: 1441px) {
    .rad-button--tertiary-dynamic .rad-icon {
        width:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-button--tertiary-dynamic .rad-icon {
        width:2rem
    }
}

.rad-button--ghost {
    padding: 0
}

.rad-button--ghost .rad-button__text {
    padding: .5rem 0;
    position: relative
}

.rad-button--ghost .rad-button__text:after {
    border-bottom: 1px solid;
    bottom: 0;
    content: "";
    display: block;
    left: 0;
    position: absolute;
    -webkit-transition-duration: 550ms;
    transition-duration: 550ms;
    -webkit-transition-property: width;
    transition-property: width;
    -webkit-transition-timing-function: cubic-bezier(0.85,0,0,1);
    transition-timing-function: cubic-bezier(0.85,0,0,1);
    width: 0
}

:hover>.rad-button__text:after {
    width: 100%
}

.rad-button--ghost .rad-button__icon-left,.rad-button--ghost .rad-button__icon-right {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 1.25rem;
    justify-content: center;
    width: 1.25rem
}

@media(min-width: 1441px) {
    .rad-button--ghost .rad-button__icon-left,.rad-button--ghost .rad-button__icon-right {
        height:calc(1.25rem + 1.39179vw - 20.05567px)
    }
}

@media(min-width: 1920px) {
    .rad-button--ghost .rad-button__icon-left,.rad-button--ghost .rad-button__icon-right {
        height:1.6666666667rem
    }
}

@media(min-width: 1441px) {
    .rad-button--ghost .rad-button__icon-left,.rad-button--ghost .rad-button__icon-right {
        width:calc(1.25rem + 1.39179vw - 20.05567px)
    }
}

@media(min-width: 1920px) {
    .rad-button--ghost .rad-button__icon-left,.rad-button--ghost .rad-button__icon-right {
        width:1.6666666667rem
    }
}

.rad-button--ghost .rad-button__icon-left {
    margin-right: 0.5rem
}

@media(min-width: 1441px) {
    .rad-button--ghost .rad-button__icon-left {
        margin-right:calc(0.5rem + 0.55672vw - 8.02227px)
    }
}

@media(min-width: 1920px) {
    .rad-button--ghost .rad-button__icon-left {
        margin-right:0.6666666667rem
    }
}

.rad-button--ghost .rad-button__icon-right {
    margin-left: 0.5rem
}

@media(min-width: 1441px) {
    .rad-button--ghost .rad-button__icon-right {
        margin-left:calc(0.5rem + 0.55672vw - 8.02227px)
    }
}

@media(min-width: 1920px) {
    .rad-button--ghost .rad-button__icon-right {
        margin-left:0.6666666667rem
    }
}

.rad-button__text,.rad-icon-button {
    position: relative
}

.rad-icon-button {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    align-items: center;
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 48px;
    justify-content: center;
    text-decoration: none;
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
    width: 48px
}

@media(min-width: 1441px) {
    .rad-icon-button {
        height:calc(-0.13361px + 3.34029vw)
    }
}

@media(min-width: 1920px) {
    .rad-icon-button {
        height:64px
    }
}

@media(min-width: 1441px) {
    .rad-icon-button {
        width:calc(-0.13361px + 3.34029vw)
    }
}

@media(min-width: 1920px) {
    .rad-icon-button {
        width:64px
    }
}

.rad-icon-button:-moz-any-link {
    color: inherit
}

.rad-icon-button,.rad-icon-button:any-link {
    color: inherit
}

.rad-icon-button .rad-icon-button__text {
    clip: rect(0 0 0 0);
    -webkit-clip-path: inset(50%);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px
}

.rad-icon-button--disabled,.rad-icon-button:disabled {
    cursor: default;
    opacity: .5;
    pointer-events: none
}

.rad-icon-button--square {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    min-height: 48px;
    min-width: 48px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content
}

@media(min-width: 1441px) {
    .rad-icon-button--square {
        min-height:calc(-0.13361px + 3.34029vw)
    }
}

@media(min-width: 1920px) {
    .rad-icon-button--square {
        min-height:64px
    }
}

@media(min-width: 1441px) {
    .rad-icon-button--square {
        min-width:calc(-0.13361px + 3.34029vw)
    }
}

@media(min-width: 1920px) {
    .rad-icon-button--square {
        min-width:64px
    }
}

.rad-icon-button--square:focus-visible {
    outline: none
}



.rad-icon-button--square:hover .rad-icon {
    background-color: #7500c0
}

.rad-icon-button--square:active .rad-icon {
    background-color: #460073
}

.rad-icon-button--square .rad-icon {
    background-color: #a100ff;
    height: 2.5rem;
    width: 2.5rem
}

@media(min-width: 600px) {
    .rad-icon-button--square .rad-icon {
        height:3rem;
        width: 3rem
    }
}

@media(min-width: 1024px) {
    .rad-icon-button--square .rad-icon {
        height:3.25rem;
        width: 3.25rem
    }
}

@media(min-width: 1441px) {
    .rad-icon-button--square .rad-icon {
        height:calc(3.25rem + 3.61865vw - 52.14475px)
    }
}

@media(min-width: 1920px) {
    .rad-icon-button--square .rad-icon {
        height:4.3333333333rem
    }
}

@media(min-width: 1441px) {
    .rad-icon-button--square .rad-icon {
        width:calc(3.25rem + 3.61865vw - 52.14475px)
    }
}

@media(min-width: 1920px) {
    .rad-icon-button--square .rad-icon {
        width:4.3333333333rem
    }
}

.rad-icon-button--square .rad-icon:after {
    scale: .8333333333
}

@media(min-width: 600px) {
    .rad-icon-button--square .rad-icon:after {
        scale:1
    }
}

.rad-icon-button--square-universal .rad-icon {
    height: 1.5rem;
    width: 1.5rem
}

@media(min-width: 1441px) {
    .rad-icon-button--square-universal .rad-icon {
        height:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-icon-button--square-universal .rad-icon {
        height:2rem
    }
}

@media(min-width: 1441px) {
    .rad-icon-button--square-universal .rad-icon {
        width:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-icon-button--square-universal .rad-icon {
        width:2rem
    }
}

.rad-icon-button--square-universal .rad-icon:after {
    scale: 1
}

.rad-icon-button--primary {
    -webkit-transition-duration: 550ms;
    transition-duration: 550ms;
    -webkit-transition-property: color;
    transition-property: color;
    -webkit-transition-timing-function: cubic-bezier(0.85,0,0,1);
    transition-timing-function: cubic-bezier(0.85,0,0,1)
}

.rad-icon-button--primary:hover {
    color: #a2a2a0
}

.rad-icon-button--primary:active {
    color: #e3e3df
}

.rad-icon-button--primary:focus-visible {
    outline-offset: -2px
}

.rad-icon-button--secondary {
    background-color: transparent;
    -webkit-transition-duration: 550ms;
    transition-duration: 550ms;
    -webkit-transition-property: background-color,color;
    transition-property: background-color,color;
    -webkit-transition-timing-function: cubic-bezier(0.85,0,0,1);
    transition-timing-function: cubic-bezier(0.85,0,0,1)
}

.rad-icon-button--secondary:hover {
    background-color: #ffffff
}

.rad-icon-button--secondary:active {
    background-color: #101010;
    color: #e3e3df
}

@media(min-width: 1441px) {
    .rad-icon-button--secondary.rad-icon-button--selected {
        border-bottom-width:calc(-0.00557px + 0.13918vw)
    }
}

@media(min-width: 1920px) {
    .rad-icon-button--secondary.rad-icon-button--selected {
        border-bottom-width:2.6666666667px
    }
}

.rad-icon-button--tertiary:focus-visible {
    outline-offset: -2px
}

.rad-icon-button--color-icon-small .rad-icon {
    background-color: #a100ff;
    height: 24px;
    -webkit-transition-duration: 550ms;
    transition-duration: 550ms;
    -webkit-transition-property: background-color;
    transition-property: background-color;
    -webkit-transition-timing-function: cubic-bezier(0.85,0,0,1);
    transition-timing-function: cubic-bezier(0.85,0,0,1);
    width: 24px
}

@media(min-width: 1441px) {
    .rad-icon-button--color-icon-small .rad-icon {
        height:calc(-0.06681px + 1.67015vw)
    }
}

@media(min-width: 1920px) {
    .rad-icon-button--color-icon-small .rad-icon {
        height:32px
    }
}

@media(min-width: 1441px) {
    .rad-icon-button--color-icon-small .rad-icon {
        width:calc(-0.06681px + 1.67015vw)
    }
}

@media(min-width: 1920px) {
    .rad-icon-button--color-icon-small .rad-icon {
        width:32px
    }
}

.rad-icon-button--color-icon-small:hover .rad-icon {
    background-color: #7500c0
}

.rad-icon-button--color-icon-small:active .rad-icon {
    background-color: #460073
}

.rad-icon-button--color-icon-small:focus-visible {
    outline-offset: -2px
}

.rad-icon-button.rad-icon-button--grey .rad-icon {
    background-color: #2b2b2b;
    height: var(--rad-icon-button-grey-size);
    width: var(--rad-icon-button-grey-size)
}

.rad-icon-button.rad-icon-button--grey:hover {
    color: #fff
}

.rad-icon-button.rad-icon-button--grey:hover .rad-icon {
    background-color: #202020
}

.rad-icon-button.rad-icon-button--grey:active .rad-icon {
    background-color: #202020;
    color: #a2a2a0
}

.rad-icon-button.rad-icon-button--grey-24 .rad-icon {
    --rad-icon-button-grey-size: 24px
}

@media(min-width: 1441px) {
    .rad-icon-button.rad-icon-button--grey-24 .rad-icon {
        --rad-icon-button-grey-size:calc(-0.06681px + 1.67015vw)
    }
}

@media(min-width: 1920px) {
    .rad-icon-button.rad-icon-button--grey-24 .rad-icon {
        --rad-icon-button-grey-size:32px
    }
}

.rad-icon-button.rad-icon-button--grey-32 .rad-icon {
    --rad-icon-button-grey-size: 32px
}

@media(min-width: 1441px) {
    .rad-icon-button.rad-icon-button--grey-32 .rad-icon {
        --rad-icon-button-grey-size:calc(-0.08907px + 2.22686vw)
    }
}

@media(min-width: 1920px) {
    .rad-icon-button.rad-icon-button--grey-32 .rad-icon {
        --rad-icon-button-grey-size:42.6666666667px
    }
}

.rad-icon-button.rad-icon-button--grey-40 .rad-icon {
    --rad-icon-button-grey-size: 40px
}

@media(min-width: 1441px) {
    .rad-icon-button.rad-icon-button--grey-40 .rad-icon {
        --rad-icon-button-grey-size:calc(-0.11134px + 2.78358vw)
    }
}

@media(min-width: 1920px) {
    .rad-icon-button.rad-icon-button--grey-40 .rad-icon {
        --rad-icon-button-grey-size:53.3333333333px
    }
}

.rad-icon-button.rad-icon-button--grey-48 .rad-icon {
    --rad-icon-button-grey-size: 48px
}

@media(min-width: 1441px) {
    .rad-icon-button.rad-icon-button--grey-48 .rad-icon {
        --rad-icon-button-grey-size:calc(-0.13361px + 3.34029vw)
    }
}

@media(min-width: 1920px) {
    .rad-icon-button.rad-icon-button--grey-48 .rad-icon {
        --rad-icon-button-grey-size:64px
    }
}

.rad-icon-button--icon-size-24 .rad-icon:after {
    scale: 1.2
}

.rad-icon-button--icon-size-40 .rad-icon:after {
    scale: 2
}

.rad-icon-button--icon-size-48 .rad-icon:after {
    scale: 2.4
}

@media(min-width: 600px) {
    .rad-button--primary {
        padding-left:1.5rem;
        padding-right: 1.5rem
    }
}

@media(min-width: 1441px) {
    .rad-button--primary {
        padding-left:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-button--primary {
        padding-left:2rem
    }
}

@media(min-width: 1441px) {
    .rad-button--primary {
        padding-right:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-button--primary {
        padding-right:2rem
    }
}

@media(min-width: 600px) {
    .rad-button--secondary {
        padding-left:1.5rem;
        padding-right: 1.5rem
    }
}

@media(min-width: 1024px) {
    .rad-button--primary,.rad-button--secondary {
        height:3.25rem
    }
}

@media(min-width: 1441px) {
    .rad-button--primary,.rad-button--secondary {
        height:calc(3.25rem + 3.61865vw - 52.14475px)
    }
}

@media(min-width: 1920px) {
    .rad-button--primary,.rad-button--secondary {
        height:4.3333333333rem
    }
}

@media(min-width: 1024px) {
    .rad-button--tertiary .rad-button__icon-right {
        height:1.5rem;
        width: 1.5rem
    }
}

@media(min-width: 1441px) {
    .rad-button--tertiary .rad-button__icon-right {
        width:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-button--tertiary .rad-button__icon-right {
        width:2rem
    }
}

@media(min-width: 1441px) {
    .rad-button--tertiary .rad-button__icon-right {
        height:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-button--tertiary .rad-button__icon-right {
        height:2rem
    }
}

.rad__range-input-slider-for-carousel {
    display: none
}

.rad-carousel .flickity-enabled {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    justify-content: flex-end
}

.rad-carousel .flickity-button:focus {
    -webkit-box-shadow: none;
    box-shadow: none
}

.rad-carousel .flickity-prev-next-button {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    align-items: center;
    background: none;
    border-radius: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 48px;
    justify-content: center;
    left: 0;
    margin-top: -12px;
    position: relative;
    right: 0;
    -webkit-transform: unset;
    transform: unset;
    width: 48px
}

@media(min-width: 1441px) {
    .rad-carousel .flickity-prev-next-button {
        width:calc(-0.13361px + 3.34029vw)
    }
}

@media(min-width: 1920px) {
    .rad-carousel .flickity-prev-next-button {
        width:64px
    }
}

@media(min-width: 1441px) {
    .rad-carousel .flickity-prev-next-button {
        height:calc(-0.13361px + 3.34029vw)
    }
}

@media(min-width: 1920px) {
    .rad-carousel .flickity-prev-next-button {
        height:64px
    }
}

@media(min-width: 600px) {
    .rad-carousel .flickity-prev-next-button {
        margin-top:0
    }
}

.rad-carousel .next,.rad-carousel .previous {
    --rad-icon-button-grey-size: 32px
}

@media(min-width: 600px) {
    .rad-carousel .next,.rad-carousel .previous {
        --rad-icon-button-grey-size:40px
    }
}

@media(min-width: 1024px) {
    .rad-carousel .next,.rad-carousel .previous {
        --rad-icon-button-grey-size:48px
    }
}

@media(min-width: 1441px) {
    .rad-carousel .next,.rad-carousel .previous {
        --rad-icon-button-grey-size:calc(-0.13361px + 3.34029vw)
    }
}

@media(min-width: 1920px) {
    .rad-carousel .next,.rad-carousel .previous {
        --rad-icon-button-grey-size:64px
    }
}

@media(min-width: 1024px) {
    .rad-carousel .next .rad-icon:after,.rad-carousel .previous .rad-icon:after {
        scale:1.2
    }
}

.rad-carousel .previous {
    margin-right: 4px
}

@media(min-width: 600px) {
    .rad-carousel .previous {
        margin-right:16px
    }
}

@media(min-width: 1024px) {
    .rad-carousel .previous {
        margin-right:24px
    }
}

@media(min-width: 1441px) {
    .rad-carousel .previous {
        margin-right:calc(-0.06681px + 1.67015vw)
    }
}

@media(min-width: 1920px) {
    .rad-carousel .previous {
        margin-right:32px
    }
}

.rad-carousel .flickity-button-icon {
    fill: #fff;
    position: static;
    width: 1.25rem
}

@media(min-width: 1441px) {
    .rad-carousel .flickity-button-icon {
        width:calc(1.25rem + 1.39179vw - 20.05567px)
    }
}

@media(min-width: 1920px) {
    .rad-carousel .flickity-button-icon {
        width:1.6666666667rem
    }
}

.rad-carousel.flickity-enabled {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    justify-content: flex-end
}

.rad-carousel .flickity-viewport {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    margin-bottom: 16px
}

@media(min-width: 600px) {
    .rad-carousel .flickity-viewport {
        margin-bottom:24px
    }
}

@media(min-width: 1024px) {
    .rad-carousel .flickity-viewport {
        margin-bottom:32px
    }
}

@media(min-width: 1441px) {
    .rad-carousel .flickity-viewport {
        margin-bottom:calc(-0.08907px + 2.22686vw)
    }
}

@media(min-width: 1920px) {
    .rad-carousel .flickity-viewport {
        margin-bottom:42.6666666667px
    }
}

.rad-carousel .flickity-button {
    background: none
}

.rad-carousel .next,.rad-carousel .previous,.rad-carousel__autorotate-toggle {
    --rad-icon-button-grey-size: 32px
}

@media(min-width: 600px) {
    .rad-carousel .next,.rad-carousel .previous,.rad-carousel__autorotate-toggle {
        --rad-icon-button-grey-size:40px
    }
}

@media(min-width: 1024px) {
    .rad-carousel .next,.rad-carousel .previous,.rad-carousel__autorotate-toggle {
        --rad-icon-button-grey-size:48px
    }
}

@media(min-width: 1441px) {
    .rad-carousel .next,.rad-carousel .previous,.rad-carousel__autorotate-toggle {
        --rad-icon-button-grey-size:calc(-0.13361px + 3.34029vw)
    }
}

@media(min-width: 1920px) {
    .rad-carousel .next,.rad-carousel .previous,.rad-carousel__autorotate-toggle {
        --rad-icon-button-grey-size:64px
    }
}

@media(min-width: 1024px) {
    .rad-carousel .next .rad-icon:after,.rad-carousel .previous .rad-icon:after,.rad-carousel__autorotate-toggle .rad-icon:after {
        scale:1.2
    }
}

.rad-carousel .previous {
    margin-right: 0
}

.rad-carousel--multi-focus .previous {
    margin-right: 4px
}

@media(min-width: 600px) {
    .rad-carousel--multi-focus .previous {
        margin-right:16px
    }
}

@media(min-width: 1024px) {
    .rad-carousel--multi-focus .previous {
        margin-right:24px
    }
}

@media(min-width: 1441px) {
    .rad-carousel--multi-focus .previous {
        margin-right:calc(-0.06681px + 1.67015vw)
    }
}

@media(min-width: 1920px) {
    .rad-carousel--multi-focus .previous {
        margin-right:32px
    }
}

.rad-carousel .next {
    margin-right: var(--rad-spacing-sm)
}

@media(min-width: 600px) {
    .rad-carousel .next {
        margin-right:var(--rad-spacing-md)
    }
}

.rad-carousel__autorotate-toggle {
    bottom: 0;
    left: var(--rad-spacing-sm);
    position: absolute
}

@media(min-width: 600px) {
    .rad-carousel__autorotate-toggle {
        left:var(--rad-spacing-md)
    }
}

.rad-carousel__autorotate-toggle--disabled {
    display: none
}

.rad-carousel__page-numbers {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-size: .875rem;
    font-weight: 500;
    justify-content: center;
    letter-spacing: -0.005em;
    line-height: 1.5;
    text-underline-offset: .25rem;
    width: 40px
}

.rad-carousel__page-numbers:lang(ja-JP),.rad-carousel__page-numbers:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-carousel__page-numbers {
        font-size:1rem
    }
}

@media(min-width: 1441px) {
    .rad-carousel__page-numbers {
        font-size:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-carousel__page-numbers {
        font-size:1.3333333333rem
    }
}

@media(min-width: 600px) {
    .rad-carousel__page-numbers {
        margin:0 12px;
        width: 48px
    }
}

@media(min-width: 1441px) {
    .rad-carousel__page-numbers {
        width:calc(-0.13361px + 3.34029vw)
    }
}

@media(min-width: 1920px) {
    .rad-carousel__page-numbers {
        width:64px
    }
}

@media(min-width: 1024px) {
    .rad-carousel__page-numbers {
        margin-left:16px;
        margin-right: 16px
    }
}

@media(min-width: 1441px) {
    .rad-carousel__page-numbers {
        margin-left:calc(-0.04454px + 1.11343vw)
    }
}

@media(min-width: 1920px) {
    .rad-carousel__page-numbers {
        margin-left:21.3333333333px
    }
}

@media(min-width: 1441px) {
    .rad-carousel__page-numbers {
        margin-right:calc(-0.04454px + 1.11343vw)
    }
}

@media(min-width: 1920px) {
    .rad-carousel__page-numbers {
        margin-right:21.3333333333px
    }
}

.rad-carousel__page-numbers--sr-only {
    clip: rect(0 0 0 0);
    -webkit-clip-path: inset(50%);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px
}


.rad-chip {
    color: #fff;
    cursor: pointer;
    height: 3rem
}

@media(min-width: 1441px) {
    .rad-chip {
        height:calc(3rem + 3.34029vw - 48.13361px)
    }
}

@media(min-width: 1920px) {
    .rad-chip {
        height:4rem
    }
}

.rad-chip__inner {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #000;
    border: 1px solid #616160;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 0;
    height: 2.25rem;
    padding: 0 .75rem;
    -webkit-transition-duration: 550ms;
    transition-duration: 550ms;
    -webkit-transition-property: border,gap,padding-left;
    transition-property: border,gap,padding-left;
    -webkit-transition-timing-function: cubic-bezier(0.85,0,0,1);
    transition-timing-function: cubic-bezier(0.85,0,0,1)
}

@media(min-width: 600px) {
    .rad-chip__inner {
        height:2.5rem
    }
}

@media(min-width: 1024px) {
    .rad-chip__inner {
        height:2.75rem;
        padding-left: 0.75rem;
        padding-right: 0.75rem
    }
}

@media(min-width: 1441px) {
    .rad-chip__inner {
        padding-left:calc(0.75rem + 0.83507vw - 12.0334px)
    }
}

@media(min-width: 1920px) {
    .rad-chip__inner {
        padding-left:1rem
    }
}

@media(min-width: 1441px) {
    .rad-chip__inner {
        padding-right:calc(0.75rem + 0.83507vw - 12.0334px)
    }
}

@media(min-width: 1920px) {
    .rad-chip__inner {
        padding-right:1rem
    }
}

.rad-chip__text {
    font-size: .75rem;
    font-weight: 500;
    font-weight: 400;
    letter-spacing: -0.005em;
    line-height: 1.5;
    text-underline-offset: .25rem;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content
}

.rad-chip__text:lang(ja-JP),.rad-chip__text:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-chip__text {
        font-size:0.875rem
    }
}

@media(min-width: 1441px) {
    .rad-chip__text {
        font-size:calc(0.875rem + 0.97425vw - 14.03897px)
    }
}

@media(min-width: 1920px) {
    .rad-chip__text {
        font-size:1.1666666667rem
    }
}

.rad-chip__text:lang(ja-JP),.rad-chip__text:lang(zh-CN) {
    font-weight: 400
}

.rad-chip .rad-icon__check {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    height: 1.125rem;
    opacity: 0;
    overflow: hidden;
    -webkit-transition-duration: 550ms;
    transition-duration: 550ms;
    -webkit-transition-property: translate,width;
    transition-property: translate,width;
    -webkit-transition-timing-function: cubic-bezier(0.85,0,0,1);
    transition-timing-function: cubic-bezier(0.85,0,0,1);
    translate: -0.75rem;
    width: 0
}

.rad-chip:hover .rad-chip__inner {
    background-color: #202020
}

.rad-chip:active .rad-chip__inner {
    background-color: #2b2b2b
}

.rad-chip:focus-visible {
    outline: none
}


.rad-chip:disabled {
    color: #616160;
    cursor: default;
    pointer-events: none
}

.rad-chip--selected .rad-chip__inner {
    border: 2px solid #fff;
    gap: 0.25rem;
    padding-left: .625rem
}

@media(min-width: 1441px) {
    .rad-chip--selected .rad-chip__inner {
        gap:calc(0.25rem + 0.27836vw - 4.01113px)
    }
}

@media(min-width: 1920px) {
    .rad-chip--selected .rad-chip__inner {
        gap:0.3333333333rem
    }
}

@media(min-width: 1024px) {
    .rad-chip--selected .rad-chip__inner {
        padding-left:0.75rem
    }
}

@media(min-width: 1441px) {
    .rad-chip--selected .rad-chip__inner {
        padding-left:calc(0.75rem + 0.83507vw - 12.0334px)
    }
}

@media(min-width: 1920px) {
    .rad-chip--selected .rad-chip__inner {
        padding-left:1rem
    }
}

.rad-chip--selected .rad-chip__inner .rad-icon__check {
    opacity: 1;
    translate: 0;
    width: 1.125rem
}

.rad-chip--selected:hover .rad-chip__inner {
    background-color: #202020
}

.rad-chip--selected:active .rad-chip__inner {
    background-color: #2b2b2b
}

.rad-chip--selected:focus-visible .rad-chip__inner {
    background-color: #000
}

.rad-dropdown {
    font-size: .75rem;
    font-weight: 500;
    font-weight: 400;
    letter-spacing: -0.005em;
    line-height: 1.5;
    position: relative;
    text-underline-offset: .25rem
}

.rad-dropdown:lang(ja-JP),.rad-dropdown:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-dropdown {
        font-size:0.875rem
    }
}

@media(min-width: 1441px) {
    .rad-dropdown {
        font-size:calc(0.875rem + 0.97425vw - 14.03897px)
    }
}

@media(min-width: 1920px) {
    .rad-dropdown {
        font-size:1.1666666667rem
    }
}

.rad-dropdown:lang(ja-JP),.rad-dropdown:lang(zh-CN) {
    font-weight: 400
}

.rad-dropdown__category {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    align-items: center;
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: .25rem;
    justify-content: flex-end;
    margin: .5rem 0 .5rem auto
}

.rad-dropdown__category .rad-icon__chevron-down {
    -webkit-transition: 550ms cubic-bezier(0.85,0,0,1);
    transition: 550ms cubic-bezier(0.85,0,0,1)
}

.rad-dropdown__category .rad-icon__chevron-down:after {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    align-items: center;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    height: 1.25rem;
    justify-content: center;
    width: 1.25rem
}

.rad-dropdown__category-title {
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
    -webkit-transition: 550ms cubic-bezier(0.85,0,0,1);
    transition: 550ms cubic-bezier(0.85,0,0,1)
}

.rad-dropdown__list-item {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    background-color: #202020;
    color: #a2a2a0;
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: space-between;
    padding: .75rem .75rem .75rem 1rem;
    -webkit-transition-duration: 550ms;
    transition-duration: 550ms;
    -webkit-transition-property: visibility;
    transition-property: visibility;
    -webkit-transition-timing-function: cubic-bezier(0.85,0,0,1);
    transition-timing-function: cubic-bezier(0.85,0,0,1);
    visibility: hidden;
    width: 100%
}

.rad-dropdown__list-item .rad-icon__check {
    display: none
}

.rad-dropdown__list-item--selected {
    background-color: #202020;
    color: #fff
}

.rad-dropdown__list-item--selected .rad-icon__check {
    color: #fff;
    display: inline-block;
    padding-left: .5rem
}

.rad-dropdown__list-item:hover {
    background-color: #2b2b2b;
    color: #fff;
    opacity: .95
}

.rad-dropdown__list-item:active {
    background-color: #101010;
    color: #a2a2a0
}


.rad-dropdown__list {
    background-color: #202020;
    max-height: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    -webkit-transition: max-height 550ms cubic-bezier(0.85,0,0,1);
    transition: max-height 550ms cubic-bezier(0.85,0,0,1);
    width: 14.6875rem;
    z-index: 5
}

.rad-dropdown__list li:first-child {
    padding-top: .5rem
}

.rad-dropdown__list li:last-child {
    padding-bottom: .5rem
}

.rad-dropdown__list .rad-icon__check:after {
    display: block;
    height: 1.25rem;
    top: .125rem;
    width: 1.25rem
}



.rad-dropdown--open .rad-dropdown__category .rad-icon__chevron-down,.rad-dropdown--open .rad-dropdown__category-title {
    color: #a2a2a0
}

.rad-dropdown--open .rad-icon__chevron-down {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg)
}

.rad-dropdown--open .rad-dropdown__list {
    max-height: min(var(--dropdown-height),var(--max-items,999)*(1lh + (2 * 0.75rem)))
}

.rad-dropdown--open .rad-dropdown__list-item {
    visibility: visible
}

.rad-dropdown--select .rad-dropdown__category {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    background-color: #202020;
    justify-content: space-between;
    margin-bottom: 0;
    margin-left: 0;
    padding: .875rem .75rem;
    width: 100%
}

.rad-dropdown--select .rad-dropdown__category:hover {
    background-color: #2b2b2b
}

.rad-dropdown--select .rad-dropdown__category:focus-visible {
    outline-offset: 0
}

.rad-dropdown--select .rad-dropdown__list {
    -webkit-box-shadow: 0 8px 12px 6px rgba(0,0,0,.15),0 4px 4px rgba(0,0,0,.3);
    box-shadow: 0 8px 12px 6px rgba(0,0,0,.15),0 4px 4px rgba(0,0,0,.3);
    width: 100%
}

.rad-dropdown--select .rad-dropdown__list-item[aria-selected=true] {
    background-color: #202020;
    color: #fff
}

.rad-dropdown--select .rad-dropdown__list-item[aria-selected=true] .rad-icon__check {
    color: #fff;
    display: inline-block;
    padding-left: .5rem
}

.rad-dropdown--select-error .rad-form-field__message {
    display: block
}

.rad-dropdown--select-error .rad-dropdown__category {
    border-bottom: 1px;
    border-color: #ff3246;
    border-style: solid
}

.rad-dropdown--select-error .rad-dropdown__category .rad-icon {
    color: #ff3246
}

.rad-dropdown--select-error .rad-dropdown__category:focus-visible {
    border-color: transparent;
    outline-color: #ff3246
}

.cmp-logo {
    display: block;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content
}

.cmp-logo__svg-wrapper {
    width: 9.5rem
}

@media(min-width: 1441px) {
    .cmp-logo__svg-wrapper {
        width:calc(9.5rem + 10.57759vw - 152.4231px)
    }
}

@media(min-width: 1920px) {
    .cmp-logo__svg-wrapper {
        width:12.6666666667rem
    }
}

.cmp-logo__svg-wrapper svg {
    display: block;
    width: 100%
}

.cmp-logo__greater-than,.cmp-logo__text {
    -webkit-transition: translate 550ms cubic-bezier(0.85,0,0,1),scale 550ms cubic-bezier(0.85,0,0,1),-webkit-transform 550ms cubic-bezier(0.85,0,0,1);
    transition: translate 550ms cubic-bezier(0.85,0,0,1),scale 550ms cubic-bezier(0.85,0,0,1),-webkit-transform 550ms cubic-bezier(0.85,0,0,1);
    transition: transform 550ms cubic-bezier(0.85,0,0,1),translate 550ms cubic-bezier(0.85,0,0,1),scale 550ms cubic-bezier(0.85,0,0,1);
    transition: transform 550ms cubic-bezier(0.85,0,0,1),translate 550ms cubic-bezier(0.85,0,0,1),scale 550ms cubic-bezier(0.85,0,0,1),-webkit-transform 550ms cubic-bezier(0.85,0,0,1)
}

.cmp-logo__greater-than {
    fill: #a100ff
}

.cmp-logo:hover .cmp-logo__greater-than {
    scale: .396;
    translate: 88.5px
}

.cmp-logo__text {
    fill: #fff;
    -webkit-transform: translateY(24px);
    transform: translateY(24px)
}

@media(min-width: 1441px) {
    .cmp-logo__text {
        -webkit-transform:translateY(calc(-0.06681px + 1.67015vw));
        transform: translateY(calc(-0.06681px + 1.67015vw))
    }
}

@media(min-width: 1920px) {
    .cmp-logo__text {
        -webkit-transform:translateY(32px);
        transform: translateY(32px)
    }
}

.cmp-logo:hover .cmp-logo__text {
    -webkit-transform: translateY(0);
    transform: translateY(0)
}

.cmp-logo.cmp-logo--white-on-dark .cmp-logo__greater-than {
    fill: #fff
}

.cmp-logo.cmp-logo--black-on-light .cmp-logo__greater-than,.cmp-logo.cmp-logo--black-on-light .cmp-logo__text {
    fill: #000
}

.cmp-logo.cmp-logo--purple-on-light .cmp-logo__greater-than {
    fill: #a100ff
}

.cmp-logo.cmp-logo--purple-on-light .cmp-logo__text {
    fill: #000
}

.rad-media-overlay {
    background: -webkit-gradient(linear,left top,left bottom,from(transparent),to(rgba(0,0,0,.75)));
    background: linear-gradient(180deg,transparent,rgba(0,0,0,.75));
    bottom: 0;
    position: absolute;
    width: 100%
}

.rad-media-overlay,.rad-media-overlay-atom--large .rad-media-overlay {
    --toggle-spacing: 32px;
    height: 160px
}

@media(min-width: 1441px) {
    .rad-media-overlay,.rad-media-overlay-atom--large .rad-media-overlay {
        --toggle-spacing:calc(-0.08907px + 2.22686vw)
    }
}

@media(min-width: 1920px) {
    .rad-media-overlay,.rad-media-overlay-atom--large .rad-media-overlay {
        --toggle-spacing:42.6666666667px
    }
}

@media(min-width: 1441px) {
    .rad-media-overlay,.rad-media-overlay-atom--large .rad-media-overlay {
        height:calc(-0.44537px + 11.13431vw)
    }
}

@media(min-width: 1920px) {
    .rad-media-overlay,.rad-media-overlay-atom--large .rad-media-overlay {
        height:213.3333333333px
    }
}

.nestedmedia .rad-media-overlay,.rad-media-overlay--small,.rad-media-overlay-atom--small .rad-media-overlay {
    --toggle-spacing: 8px;
    height: 88px
}

@media(min-width: 1441px) {
    .nestedmedia .rad-media-overlay,.rad-media-overlay--small,.rad-media-overlay-atom--small .rad-media-overlay {
        --toggle-spacing:calc(-0.02227px + 0.55672vw)
    }
}

@media(min-width: 1920px) {
    .nestedmedia .rad-media-overlay,.rad-media-overlay--small,.rad-media-overlay-atom--small .rad-media-overlay {
        --toggle-spacing:10.6666666667px
    }
}

@media(min-width: 1441px) {
    .nestedmedia .rad-media-overlay,.rad-media-overlay--small,.rad-media-overlay-atom--small .rad-media-overlay {
        height:calc(-0.24495px + 6.12387vw)
    }
}

@media(min-width: 1920px) {
    .nestedmedia .rad-media-overlay,.rad-media-overlay--small,.rad-media-overlay-atom--small .rad-media-overlay {
        height:117.3333333333px
    }
}

.rad-media-overlay__toggle {
    bottom: var(--toggle-spacing);
    left: var(--toggle-spacing);
    position: absolute
}

.rad-media-overlay__toggle--disabled {
    display: none
}

.rad-media-overlay--controls-right .rad-media-overlay__toggle {
    left: auto;
    right: var(--toggle-spacing)
}

.rad-pagination {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    align-items: center;
    display: none;
    justify-content: space-between
}

@media(min-width: 600px) {
    .rad-pagination {
        -webkit-box-pack:start;
        -ms-flex-pack: start;
        -webkit-column-gap: 4px;
        -moz-column-gap: 4px;
        column-gap: 4px;
        justify-content: flex-start;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content
    }
}

@media(min-width: 1441px) {
    .rad-pagination {
        -webkit-column-gap:calc(-0.01113px + 0.27836vw);
        -moz-column-gap: calc(-0.01113px + 0.27836vw);
        column-gap: calc(-0.01113px + 0.27836vw)
    }
}

@media(min-width: 1920px) {
    .rad-pagination {
        -webkit-column-gap:5.3333333333px;
        -moz-column-gap: 5.3333333333px;
        column-gap: 5.3333333333px
    }
}

.rad-pagination--initialized {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.rad-pagination__mobile-pages {
    display: grid;
    grid-template-areas: "select";
    height: 48px
}

@media(min-width: 600px) {
    .rad-pagination__mobile-pages {
        display:none
    }
}

.rad-pagination__mobile-pages .rad-icon,.rad-pagination__mobile-pages-display-text,.rad-pagination__mobile-pages-select,.rad-pagination__mobile-pages:after {
    grid-area: select
}

.rad-pagination__mobile-pages:after {
    border-bottom: 2px solid #a100ff;
    content: "";
    display: block;
    pointer-events: none
}

.rad-pagination__mobile-pages-select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: transparent;
    border: none;
    color: #000
}



.rad-pagination__mobile-pages .rad-icon,.rad-pagination__mobile-pages-display-text {
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.rad-pagination__mobile-pages-display-text {
    -ms-flex-item-align: center;
    align-self: center;
    color: #a2a2a0;
    font-size: 1rem;
    font-weight: 500;
    font-weight: 400;
    letter-spacing: -0.005em;
    line-height: 1.6;
    padding-left: 8px;
    padding-right: 24px;
    text-underline-offset: .25rem
}

.rad-pagination__mobile-pages-display-text:lang(ja-JP),.rad-pagination__mobile-pages-display-text:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-pagination__mobile-pages-display-text {
        font-size:1.125rem
    }
}

@media(min-width: 1024px) {
    .rad-pagination__mobile-pages-display-text {
        font-size:1.25rem
    }
}

@media(min-width: 1441px) {
    .rad-pagination__mobile-pages-display-text {
        font-size:calc(1.25rem + 1.39179vw - 20.05567px)
    }
}

@media(min-width: 1920px) {
    .rad-pagination__mobile-pages-display-text {
        font-size:1.6666666667rem
    }
}

.rad-pagination__mobile-pages-display-text:lang(ja-JP),.rad-pagination__mobile-pages-display-text:lang(zh-CN) {
    font-weight: 400
}

.rad-pagination__mobile-pages-current-page {
    color: #fff;
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: -0.005em;
    line-height: 1.6;
    text-underline-offset: .25rem
}

.rad-pagination__mobile-pages-current-page:lang(ja-JP),.rad-pagination__mobile-pages-current-page:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-pagination__mobile-pages-current-page {
        font-size:1.125rem
    }
}

@media(min-width: 1024px) {
    .rad-pagination__mobile-pages-current-page {
        font-size:1.25rem
    }
}

@media(min-width: 1441px) {
    .rad-pagination__mobile-pages-current-page {
        font-size:calc(1.25rem + 1.39179vw - 20.05567px)
    }
}

@media(min-width: 1920px) {
    .rad-pagination__mobile-pages-current-page {
        font-size:1.6666666667rem
    }
}

.rad-pagination__mobile-pages .rad-icon {
    justify-self: end;
    width: 20px
}

.rad-pagination__pages {
    display: none;
    gap: 0.25rem
}

@media(min-width: 1441px) {
    .rad-pagination__pages {
        gap:calc(0.25rem + 0.27836vw - 4.01113px)
    }
}

@media(min-width: 1920px) {
    .rad-pagination__pages {
        gap:0.3333333333rem
    }
}

@media(min-width: 600px) {
    .rad-pagination__pages {
        display:-webkit-box;
        display: -ms-flexbox;
        display: flex
    }
}

.rad-pagination__page-number {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    align-items: center;
    border-bottom: 2px solid transparent;
    border-left-color: transparent;
    border-left-style: solid;
    border-right-color: transparent;
    border-right-style: solid;
    border-top: 2px solid transparent;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-size: .875rem;
    font-weight: 500;
    height: 3rem;
    justify-content: center;
    letter-spacing: -0.005em;
    line-height: 1.5;
    min-width: 3rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    text-underline-offset: .25rem;
    -webkit-transition-duration: 550ms;
    transition-duration: 550ms;
    -webkit-transition-property: background-color,color;
    transition-property: background-color,color;
    -webkit-transition-timing-function: cubic-bezier(0.85,0,0,1);
    transition-timing-function: cubic-bezier(0.85,0,0,1)
}

.rad-pagination__page-number:lang(ja-JP),.rad-pagination__page-number:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-pagination__page-number {
        font-size:1rem
    }
}

@media(min-width: 1441px) {
    .rad-pagination__page-number {
        font-size:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-pagination__page-number {
        font-size:1.3333333333rem
    }
}

@media(min-width: 1441px) {
    .rad-pagination__page-number {
        border-bottom-width:calc(-0.00557px + 0.13918vw)
    }
}

@media(min-width: 1920px) {
    .rad-pagination__page-number {
        border-bottom-width:2.6666666667px
    }
}

@media(min-width: 1441px) {
    .rad-pagination__page-number {
        border-top-width:calc(-0.00557px + 0.13918vw)
    }
}

@media(min-width: 1920px) {
    .rad-pagination__page-number {
        border-top-width:2.6666666667px
    }
}

@media(min-width: 1441px) {
    .rad-pagination__page-number {
        height:calc(3rem + 3.34029vw - 48.13361px)
    }
}

@media(min-width: 1920px) {
    .rad-pagination__page-number {
        height:4rem
    }
}

@media(min-width: 1441px) {
    .rad-pagination__page-number {
        min-width:calc(3rem + 3.34029vw - 48.13361px)
    }
}

@media(min-width: 1920px) {
    .rad-pagination__page-number {
        min-width:4rem
    }
}

@media(min-width: 1441px) {
    .rad-pagination__page-number {
        padding-left:calc(0.5rem + 0.55672vw - 8.02227px)
    }
}

@media(min-width: 1920px) {
    .rad-pagination__page-number {
        padding-left:0.6666666667rem
    }
}

@media(min-width: 1441px) {
    .rad-pagination__page-number {
        padding-right:calc(0.5rem + 0.55672vw - 8.02227px)
    }
}

@media(min-width: 1920px) {
    .rad-pagination__page-number {
        padding-right:0.6666666667rem
    }
}

.rad-pagination__page-number:disabled {
    opacity: .5
}

.rad-pagination__page-number:focus-visible {
   
    outline: none;
    padding-left: 0.375rem;
    padding-right: 0.375rem
}

@media(min-width: 1441px) {
    .rad-pagination__page-number:focus-visible {
        padding-left:calc(0.375rem + 0.41754vw - 6.0167px)
    }
}

@media(min-width: 1920px) {
    .rad-pagination__page-number:focus-visible {
        padding-left:0.5rem
    }
}

@media(min-width: 1441px) {
    .rad-pagination__page-number:focus-visible {
        padding-right:calc(0.375rem + 0.41754vw - 6.0167px)
    }
}

@media(min-width: 1920px) {
    .rad-pagination__page-number:focus-visible {
        padding-right:0.5rem
    }
}

.rad-pagination__page-number:hover {
    background-color: #2b2b2b
}

.rad-pagination__page-number:active {
    background-color: #101010;
    color: #e3e3df
}

.rad-pagination__page-number--selected {
    background-color: #101010;
    border-bottom-color: #a100ff;
    color: #a2a2a0
}

.rad-pagination__page-number-sr-text {
    clip: rect(0 0 0 0);
    -webkit-clip-path: inset(50%);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px
}

.rad-pagination__page-number-gap {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: -0.005em;
    line-height: 1.6;
    margin-left: -0.25rem;
    margin-right: -0.25rem;
    text-underline-offset: .25rem
}

.rad-pagination__page-number-gap:lang(ja-JP),.rad-pagination__page-number-gap:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-pagination__page-number-gap {
        font-size:1.125rem
    }
}

@media(min-width: 1024px) {
    .rad-pagination__page-number-gap {
        font-size:1.25rem
    }
}

@media(min-width: 1441px) {
    .rad-pagination__page-number-gap {
        font-size:calc(1.25rem + 1.39179vw - 20.05567px)
    }
}

@media(min-width: 1920px) {
    .rad-pagination__page-number-gap {
        font-size:1.6666666667rem
    }
}

@media(min-width: 1441px) {
    .rad-pagination__page-number-gap {
        margin-left:calc(-0.25rem - 0.27836vw + 4.01113px)
    }
}

@media(min-width: 1920px) {
    .rad-pagination__page-number-gap {
        margin-left:-0.3333333333rem
    }
}

@media(min-width: 1441px) {
    .rad-pagination__page-number-gap {
        margin-right:calc(-0.25rem - 0.27836vw + 4.01113px)
    }
}

@media(min-width: 1920px) {
    .rad-pagination__page-number-gap {
        margin-right:-0.3333333333rem
    }
}

.rad-pagination__page-number-gap:after {
    color: #a2a2a0;
    content: "...";
    display: inline
}

.rad-podcast-badge {
    background-repeat: no-repeat;
    background-size: contain;
    display: block;
    height: 2.5rem;
    width: 10rem
}

@media(min-width: 1441px) {
    .rad-podcast-badge {
        height:calc(2.5rem + 2.78358vw - 40.11134px)
    }
}

@media(min-width: 1920px) {
    .rad-podcast-badge {
        height:3.3333333333rem
    }
}

@media(min-width: 1441px) {
    .rad-podcast-badge {
        width:calc(10rem + 11.13431vw - 160.44537px)
    }
}

@media(min-width: 1920px) {
    .rad-podcast-badge {
        width:13.3333333333rem
    }
}



.rad-textbox {
    height: 3.25rem
}

@media(min-width: 1441px) {
    .rad-textbox {
        height:calc(3.25rem + 3.61865vw - 52.14475px)
    }
}

@media(min-width: 1920px) {
    .rad-textbox {
        height:4.3333333333rem
    }
}

.rad-textbox__label {
    color: #a2a2a0;
    display: block;
    font-size: .75rem;
    font-weight: 500;
    letter-spacing: -0.005em;
    line-height: 1.5;
    margin-bottom: .5rem;
    text-underline-offset: .25rem
}

.rad-textbox__label:lang(ja-JP),.rad-textbox__label:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-textbox__label {
        font-size:0.875rem
    }
}

@media(min-width: 1441px) {
    .rad-textbox__label {
        font-size:calc(0.875rem + 0.97425vw - 14.03897px)
    }
}

@media(min-width: 1920px) {
    .rad-textbox__label {
        font-size:1.1666666667rem
    }
}

.rad-textbox__helper-text {
    color: #a2a2a0;
    font-size: .75rem;
    font-weight: 500;
    font-weight: 400;
    letter-spacing: -0.005em;
    line-height: 1.5;
    margin-top: .5rem;
    text-underline-offset: .25rem
}

.rad-textbox__helper-text:lang(ja-JP),.rad-textbox__helper-text:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-textbox__helper-text {
        font-size:0.875rem
    }
}

@media(min-width: 1441px) {
    .rad-textbox__helper-text {
        font-size:calc(0.875rem + 0.97425vw - 14.03897px)
    }
}

@media(min-width: 1920px) {
    .rad-textbox__helper-text {
        font-size:1.1666666667rem
    }
}

.rad-textbox__helper-text:lang(ja-JP),.rad-textbox__helper-text:lang(zh-CN) {
    font-weight: 400
}

.rad-textbox__text-field-wrapper {
    height: 100%;
    min-width: 6.625rem;
    position: relative
}

.rad-textbox__input-area {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #202020;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    justify-content: space-between;
    padding: 0 .75rem
}

.rad-textbox__text-input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: transparent;
    border-width: 0;
    bottom: 0;
    color: #fff;
    display: block;
    font-size: .875rem;
    font-weight: 500;
    font-weight: 400;
    height: 100%;
    left: 0;
    letter-spacing: -0.005em;
    line-height: 1.5;
    padding: 0 .75rem;
    position: absolute;
    right: 0;
    text-underline-offset: .25rem;
    top: 0;
    width: 100%
}

.rad-textbox__text-input:lang(ja-JP),.rad-textbox__text-input:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-textbox__text-input {
        font-size:1rem
    }
}

@media(min-width: 1441px) {
    .rad-textbox__text-input {
        font-size:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-textbox__text-input {
        font-size:1.3333333333rem
    }
}

.rad-textbox__text-input:lang(ja-JP),.rad-textbox__text-input:lang(zh-CN) {
    font-weight: 400
}

@media(max-width: 599px) {
    .rad-textbox__text-input {
        font-size:16px
    }
}

.rad-textbox__text-input:focus-visible {
    outline-offset: 0;
    outline-width: 1px
}

.rad-textbox__text-input[type=search]::-webkit-search-cancel-button {
    -webkit-appearance: none;
    appearance: none
}

.rad-textbox .rad-icon {
    height: 1.25rem;
    width: 1.25rem
}

.rad-textbox--icon-left .rad-textbox__text-input {
    padding-left: 2.5rem
}

.rad-textbox--icon-left .rad-textbox__input-left-icon {
    pointer-events: none
}

.rad-textbox--icon-right .rad-textbox__clear-button {
    display: none
}

.rad-textbox--icon-right .rad-textbox__text-input {
    padding-right: 2.5rem
}

.rad-textbox--icon-right .rad-textbox__text-input:not(:-moz-placeholder-shown)+.rad-textbox__input-area .rad-textbox__clear-button {
    cursor: pointer;
    display: flex
}

.rad-textbox--icon-right .rad-textbox__text-input:not(:-ms-input-placeholder)+.rad-textbox__input-area .rad-textbox__clear-button {
    cursor: pointer;
    display: -ms-flexbox;
    display: flex
}

.rad-textbox--icon-right .rad-textbox__text-input:not(:placeholder-shown)+.rad-textbox__input-area .rad-textbox__clear-button {
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.rad-textbox--outline .rad-textbox__input-area {
    border: 1px solid #616160;
    border-radius: 1px
}

.rad-textbox--outline .rad-textbox__text-input:hover:not(:disabled)+.rad-textbox__input-area {
    border-color: #a2a2a0
}

.rad-textbox--outline .rad-textbox__text-input:focus-visible:hover+.rad-textbox__input-area {
    border-color: transparent
}

.rad-textbox .rad-textbox__text-input:hover+.rad-textbox__input-area {
    background-color: #2b2b2b
}

.rad-textbox .rad-textbox__text-input:disabled+.rad-textbox__input-area {
    background-color: #101010;
    color: #616160
}

.rad-textbox.rad-textbox--error .rad-icon {
    color: #ff3246
}

.rad-textbox.rad-textbox--error .rad-textbox__input-area {
    border-bottom: 1px solid #ff3246
}

.rad-textbox.rad-textbox--error .rad-textbox__text-input {
    color: #ff3246
}

.rad-textbox.rad-textbox--error .rad-textbox__text-input:focus,.rad-textbox.rad-textbox--error .rad-textbox__text-input:focus-visible {
    outline-color: #ff3246
}

.rad-textbox.rad-textbox--error .rad-textbox__text-input:focus+.rad-text-box__input-area,.rad-textbox.rad-textbox--error .rad-textbox__text-input:focus-visible+.rad-text-box__input-area {
    border-color: transparent
}

.rad-textbox.rad-textbox--error.rad-textbox--outline .rad-textbox__input-area {
    border-bottom-color: #ff3246
}

.rad-textbox.rad-textbox--error.rad-textbox--outline .rad-textbox__text-input:hover:not(:disabled)+.rad-textbox__input-area {
    border-color: #a2a2a0 #a2a2a0 #ff3246
}

.rad-textbox.rad-textbox--error.rad-textbox--outline .rad-textbox__text-input:focus-visible:not(:disabled)+.rad-textbox__input-area,.rad-textbox.rad-textbox--error.rad-textbox--outline .rad-textbox__text-input:focus:not(:disabled)+.rad-textbox__input-area {
    border-color: transparent
}



.rad-filter-list-item {
    position: relative
}

.rad-filter-list-item--hidden {
    display: none
}

.rad-filter-list-item__label {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    -ms-flex-line-pack: start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-content: flex-start;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-size: .875rem;
    font-weight: 500;
    font-weight: 400;
    gap: .5rem;
    justify-content: space-between;
    letter-spacing: -0.005em;
    line-height: 1.5;
    padding-bottom: 0.75rem;
    padding-left: 0.25rem;
    padding-top: 0.75rem;
    text-underline-offset: .25rem;
    width: 100%
}

.rad-filter-list-item__label:lang(ja-JP),.rad-filter-list-item__label:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-filter-list-item__label {
        font-size:1rem
    }
}

@media(min-width: 1441px) {
    .rad-filter-list-item__label {
        font-size:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-filter-list-item__label {
        font-size:1.3333333333rem
    }
}

.rad-filter-list-item__label:lang(ja-JP),.rad-filter-list-item__label:lang(zh-CN) {
    font-weight: 400
}

@media(min-width: 1441px) {
    .rad-filter-list-item__label {
        padding-bottom:calc(0.75rem + 0.83507vw - 12.0334px)
    }
}

@media(min-width: 1920px) {
    .rad-filter-list-item__label {
        padding-bottom:1rem
    }
}

@media(min-width: 1441px) {
    .rad-filter-list-item__label {
        padding-left:calc(0.25rem + 0.27836vw - 4.01113px)
    }
}

@media(min-width: 1920px) {
    .rad-filter-list-item__label {
        padding-left:0.3333333333rem
    }
}

@media(min-width: 1441px) {
    .rad-filter-list-item__label {
        padding-top:calc(0.75rem + 0.83507vw - 12.0334px)
    }
}

@media(min-width: 1920px) {
    .rad-filter-list-item__label {
        padding-top:1rem
    }
}

.rad-filter-list-item__label .sr-only {
    clip: rect(0 0 0 0);
    -webkit-clip-path: inset(50%);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px
}

.rad-filter-list-item__count {
    color: #a2a2a0;
    font-size: .75rem;
    font-weight: 500;
    font-weight: 400;
    letter-spacing: -0.005em;
    line-height: 1.5;
    margin-left: auto;
    margin-top: 2px;
    text-underline-offset: .25rem
}

.rad-filter-list-item__count:lang(ja-JP),.rad-filter-list-item__count:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-filter-list-item__count {
        font-size:0.875rem
    }
}

@media(min-width: 1441px) {
    .rad-filter-list-item__count {
        font-size:calc(0.875rem + 0.97425vw - 14.03897px)
    }
}

@media(min-width: 1920px) {
    .rad-filter-list-item__count {
        font-size:1.1666666667rem
    }
}

.rad-filter-list-item__count:lang(ja-JP),.rad-filter-list-item__count:lang(zh-CN) {
    font-weight: 400
}

.rad-filter-list-item__faux-checkbox,.rad-filter-list-item__text {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.rad-filter-list-item__faux-checkbox {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -ms-flex-negative: 0;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    align-items: center;
    border: 1px solid #a2a2a0;
    flex-shrink: 0;
    height: 1.125rem;
    justify-content: center;
    width: 1.125rem
}

.rad-filter-list-item__faux-checkbox .rad-icon {
    opacity: 0
}

.rad-filter-list-item__checkbox {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: transparent;
    bottom: 0;
    left: 0;
    margin: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%
}

.rad-filter-list-item__checkbox:focus-visible {
    outline-offset: 0;
    z-index: 1
}

.rad-filter-list-item__checkbox:checked+.rad-filter-list-item__label .rad-filter-list-item__faux-checkbox {
    background-color: #a100ff;
 
}

.rad-filter-list-item__checkbox:checked+.rad-filter-list-item__label .rad-filter-list-item__faux-checkbox .rad-icon {
    opacity: 1
}

.rad-filter-list-item__checkbox:checked:hover+.rad-filter-list-item__label .rad-filter-list-item__faux-checkbox {
    background-color: #7500c0;
    border-color: #7500c0
}

.rad-filter-list-item__checkbox:checked:hover+.rad-filter-list-item__label .rad-filter-list-item__faux-checkbox .rad-icon {
    opacity: 1
}

.rad-filter-list-item__checkbox:checked:active+.rad-filter-list-item__label .rad-filter-list-item__faux-checkbox {
    background-color: #460073;
    border-color: #460073
}

.rad-filter-list-item__checkbox:checked:active+.rad-filter-list-item__label .rad-filter-list-item__faux-checkbox .rad-icon {
    opacity: 1
}

.rad-filter-list-item__checkbox:checked:focus+.rad-filter-list-item__label .rad-filter-list-item__faux-checkbox {
    background-color: #a100ff;
    border-color: #a100ff
}

.rad-filter-list-item__checkbox:checked:focus+.rad-filter-list-item__label .rad-filter-list-item__faux-checkbox .rad-icon {
    opacity: 1
}

.rad-filter-list-item.rad-filter-list-item--compact .rad-filter-list-item__label {
    padding-bottom: .5rem;
    padding-top: .5rem
}

.rad-overflow-menu .rad-filter-list-item__label {
    background-color: #202020;
    color: #a2a2a0;
    padding-left: .75rem;
    padding-right: 1rem
}

.rad-overflow-menu .rad-filter-list-item__checkbox:hover+.rad-filter-list-item__label {
    background-color: transparent;
    color: #fff
}

.rad-overflow-menu .rad-filter-list-item__checkbox:active+.rad-filter-list-item__label {
    background-color: #000;
    color: #a2a2a0
}

.rad-overflow-menu .rad-filter-list-item__checkbox:checked+.rad-filter-list-item__label {
    color: #fff
}

.rad-overflow-menu .rad-filter-list-item__checkbox:checked:hover+.rad-filter-list-item__label {
    background-color: transparent
}

.rad-tags-items {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap
}

@media(min-width: 600px) {
    .rad-tags-items {
        row-gap:.5rem
    }
}

@media(min-width: 1024px) {
    .rad-tags-items {
        -webkit-column-gap:0.375rem;
        -moz-column-gap: 0.375rem;
        column-gap: 0.375rem;
        row-gap: 0.75rem
    }
}

@media(min-width: 1441px) {
    .rad-tags-items {
        -webkit-column-gap:calc(0.375rem + 0.41754vw - 6.0167px);
        -moz-column-gap: calc(0.375rem + 0.41754vw - 6.0167px);
        column-gap: calc(0.375rem + 0.41754vw - 6.0167px)
    }
}

@media(min-width: 1920px) {
    .rad-tags-items {
        -webkit-column-gap:0.5rem;
        -moz-column-gap: 0.5rem;
        column-gap: 0.5rem
    }
}

@media(min-width: 1441px) {
    .rad-tags-items {
        row-gap:calc(0.75rem + 0.83507vw - 12.0334px)
    }
}

@media(min-width: 1920px) {
    .rad-tags-items {
        row-gap:1rem
    }
}

.rad-tag-item {
    -ms-flex-line-pack: center;
    align-content: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 3rem;
    padding: 0 .375rem
}

@media(min-width: 600px) {
    .rad-tag-item {
        padding:0 .25rem
    }
}

@media(min-width: 1024px) {
    .rad-tag-item {
        height:3rem;
        padding-left: 0.125rem;
        padding-right: 0.125rem
    }
}

@media(min-width: 1441px) {
    .rad-tag-item {
        padding-left:calc(0.125rem + 0.13918vw - 2.00557px)
    }
}

@media(min-width: 1920px) {
    .rad-tag-item {
        padding-left:0.1666666667rem
    }
}

@media(min-width: 1441px) {
    .rad-tag-item {
        padding-right:calc(0.125rem + 0.13918vw - 2.00557px)
    }
}

@media(min-width: 1920px) {
    .rad-tag-item {
        padding-right:0.1666666667rem
    }
}

@media(min-width: 1441px) {
    .rad-tag-item {
        height:calc(3rem + 3.34029vw - 48.13361px)
    }
}

@media(min-width: 1920px) {
    .rad-tag-item {
        height:4rem
    }
}

.rad-tag-item:focus-within {
    outline: 2px solid #a100ff;
    outline-offset: -4px
}

@media(min-width: 600px) {
    .rad-tag-item:focus-within {
        outline-offset:-2px
    }
}

@media(min-width: 1024px) {
    .rad-tag-item:focus-within {
        outline-offset:0
    }
}

.rad-tag-item--collapsed {
    display: none
}

@media(min-width: 1024px) {
    .rad-tag-item--collapsed {
        display:-webkit-box;
        display: -ms-flexbox;
        display: flex
    }
}

.rad-tag__inner {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border: 2px solid #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-size: .75rem;
    font-weight: 500;
    font-weight: 400;
    gap: .25rem;
    letter-spacing: -0.005em;
    line-height: 1.5;
    margin: .375rem 0;
    padding: .5rem .625rem;
    text-underline-offset: .25rem
}

.rad-tag__inner:lang(ja-JP),.rad-tag__inner:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-tag__inner {
        font-size:0.875rem
    }
}

@media(min-width: 1441px) {
    .rad-tag__inner {
        font-size:calc(0.875rem + 0.97425vw - 14.03897px)
    }
}

@media(min-width: 1920px) {
    .rad-tag__inner {
        font-size:1.1666666667rem
    }
}

.rad-tag__inner:lang(ja-JP),.rad-tag__inner:lang(zh-CN) {
    font-weight: 400
}

@media(min-width: 600px) {
    .rad-tag__inner {
        margin:.25rem 0
    }
}

@media(min-width: 1024px) {
    .rad-tag__inner {
        margin-bottom:0.125rem;
        margin-top: 0.125rem
    }
}

@media(min-width: 1441px) {
    .rad-tag__inner {
        margin-top:calc(0.125rem + 0.13918vw - 2.00557px)
    }
}

@media(min-width: 1920px) {
    .rad-tag__inner {
        margin-top:0.1666666667rem
    }
}

@media(min-width: 1441px) {
    .rad-tag__inner {
        margin-bottom:calc(0.125rem + 0.13918vw - 2.00557px)
    }
}

@media(min-width: 1920px) {
    .rad-tag__inner {
        margin-bottom:0.1666666667rem
    }
}

.rad-tag__remove-button {
    cursor: pointer;
    height: 3rem;
    width: 1.125rem
}

.rad-tag__remove-button:after {
    -webkit-transform: scale(0.9);
    transform: scale(0.9)
}

.rad-tag__remove-button:focus-visible {
    outline-color: transparent
}

@media(min-width: 1024px) {
    .rad-tag__remove-button {
        height:3rem
    }
}

@media(min-width: 1441px) {
    .rad-tag__remove-button {
        height:calc(3rem + 3.34029vw - 48.13361px)
    }
}

@media(min-width: 1920px) {
    .rad-tag__remove-button {
        height:4rem
    }
}

.rad-tags__toggle-wrapper {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: none;
    padding: .375rem
}

@media(min-width: 600px) {
    .rad-tags__toggle-wrapper {
        padding:.25rem
    }
}

.rad-tags__toggle-wrapper--visible {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

@media(min-width: 1024px) {
    .rad-tags__toggle-wrapper--visible {
        display:none
    }
}

.rad-tags__toggle-button:focus-visible {
    outline-offset: 2px
}

.rad-button--tags.rad-button {
    background-color: #a100ff;
    color: #fff;
    font-size: .75rem;
    font-weight: 500;
    font-weight: 400;
    height: 100%;
    letter-spacing: -0.005em;
    line-height: 1.5;
    min-height: 0;
    padding: 0 .75rem;
    text-underline-offset: .25rem
}

.rad-button--tags.rad-button:lang(ja-JP),.rad-button--tags.rad-button:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-button--tags.rad-button {
        font-size:0.875rem
    }
}

@media(min-width: 1441px) {
    .rad-button--tags.rad-button {
        font-size:calc(0.875rem + 0.97425vw - 14.03897px)
    }
}

@media(min-width: 1920px) {
    .rad-button--tags.rad-button {
        font-size:1.1666666667rem
    }
}

.rad-button--tags.rad-button:lang(ja-JP),.rad-button--tags.rad-button:lang(zh-CN) {
    font-weight: 400
}

.rad-save-job:focus-visible {
    outline-offset: -2px
}

.rad-save-job--hidden {
    display: none
}

.rad-save-job--display-title [title] {
    -webkit-column-gap: 8px;
    -moz-column-gap: 8px;
    column-gap: 8px;
    min-width: 48px;
    width: auto
}

@media(min-width: 1441px) {
    .rad-save-job--display-title [title] {
        -webkit-column-gap:calc(-0.02227px + 0.55672vw);
        -moz-column-gap: calc(-0.02227px + 0.55672vw);
        column-gap: calc(-0.02227px + 0.55672vw)
    }
}

@media(min-width: 1920px) {
    .rad-save-job--display-title [title] {
        -webkit-column-gap:10.6666666667px;
        -moz-column-gap: 10.6666666667px;
        column-gap: 10.6666666667px
    }
}

@media(min-width: 1441px) {
    .rad-save-job--display-title [title] {
        min-width:calc(-0.13361px + 3.34029vw)
    }
}

@media(min-width: 1920px) {
    .rad-save-job--display-title [title] {
        min-width:64px
    }
}

.rad-save-job--display-title [title]:before {
    content: attr(data-icon-label);
    display: inline;
    font-size: .875rem;
    font-weight: 500;
    letter-spacing: -0.005em;
    line-height: 1.5;
    text-underline-offset: .25rem
}

.rad-save-job--display-title [title]:before:lang(ja-JP),.rad-save-job--display-title [title]:before:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-save-job--display-title [title]:before {
        font-size:1rem
    }
}

@media(min-width: 1441px) {
    .rad-save-job--display-title [title]:before {
        font-size:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-save-job--display-title [title]:before {
        font-size:1.3333333333rem
    }
}

html {
    background-color: #000;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: #fff;
    font-family: Graphik,Arial,Helvetica,sans-serif
}

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow: hidden auto
}

*,:after,:before {
    -webkit-box-sizing: inherit;
    box-sizing: inherit
}

a {
    color: #fff
}

a:visited {
    color: #a2a2a0
}

a:hover {
    color: #dcafff
}

::-moz-selection {
    background-color: #460073
}

::selection {
    background-color: #460073
}


#main {
    background: #000;
    margin: 0 auto;
    max-width: 100%;
    position: relative;
    width: 100%
}

img,svg {
    height: auto;
    max-width: 100%
}

a {
    text-decoration: underline
}

button {
    background: none;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: inherit;
    padding: 0
}

button:hover {
    cursor: pointer
}

b,strong {
    font-weight: 500
}

b:lang(ja-JP),b:lang(zh-CN),strong:lang(ja-JP),strong:lang(zh-CN) {
    font-weight: 700
}

em,i {
    font-style: italic
}

.cmp-skip-link {
    background-color: transparent;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-size: .875rem;
    height: auto;
    margin: 0 auto;
    opacity: 0;
    padding: .5rem;
    position: absolute;
    text-decoration: none;
    top: -3em;
    -webkit-transition: top .2s,background-color .2s;
    transition: top .2s,background-color .2s;
    z-index: 10000
}

.cmp-skip-link:active,.cmp-skip-link:focus-visible {
    background-color: #202020;
    opacity: 1;
    outline-offset: -2px;
    top: 0;
    -webkit-transition: top .2s,background-color .2s;
    transition: top .2s,background-color .2s
}

.rad-360-value-title {
    padding-bottom: 3rem;
    padding-left: var(--rad-spacing-sm);
    padding-right: var(--rad-spacing-sm);
    padding-top: 4rem
}

@media(min-width: 600px) {
    .rad-360-value-title {
        padding-left:var(--rad-spacing-md);
        padding-right: var(--rad-spacing-md)
    }
}

.rad-component--nested .rad-360-value-title {
    padding-left: 0;
    padding-right: 0
}

.rad-360-value-title__header {
    -ms-flex-line-pack: end;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    align-content: flex-end;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: var(--rad-spacing-sm)
}

.rad-360-value-title__header:after {
    content: "";
    display: block;
    width: 100%
}

.rad-360-value-title__header-first-line {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: .625rem
}

.rad-360-value-title__header-second-line {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1
}

.rad-360-value-title__header-360-wrapper,.rad-360-value-title__header-value-wrapper {
    overflow: hidden
}

.rad-360-value-title__header-360,.rad-360-value-title__header-value {
    height: 2.8125rem
}

.rad-360-value-title__header-360 {
    aspect-ratio: 225/89
}

.rad-360-value-title__header-circle-placeholder {
    margin-left: .25rem;
    position: relative
}

.rad-360-value-title__header-circle,.rad-360-value-title__header-circle-placeholder {
    aspect-ratio: 1;
    height: 1.25rem
}

.rad-360-value-title__header-circle {
    max-width: none;
    position: absolute;
    rotate: -90deg
}

.rad-360-value-title__header-circle-path {
    r: 155px;
    stroke-width: 90px
}

.rad-360-value-title__header-value {
    aspect-ratio: 367/88
}

.rad-360-value-title__sub-header {
    font-family: GT Sectra Fine,Palatino;
    font-size: 1.375rem;
    font-weight: 300;
    line-height: 1.25;
    text-align: center
}

@media(min-width: 600px) {
    .rad-360-value-title__sub-header {
        font-size:1.75rem;
        line-height: 1.2
    }
}

@media(min-width: 1024px) {
    .rad-360-value-title__sub-header {
        font-size:2rem;
        line-height: 1.25
    }
}

@media(min-width: 1441px) {
    .rad-360-value-title__sub-header {
        font-size:calc(2rem + 2.22686vw - 32.08907px)
    }
}

@media(min-width: 1920px) {
    .rad-360-value-title__sub-header {
        font-size:2.6666666667rem
    }
}

@media(min-width: 600px) {
    .rad-360-value-title {
        padding-bottom:0;
        padding-top: 5rem
    }

    .rad-360-value-title__header {
        -ms-flex-line-pack: normal;
        -webkit-box-align: end;
        -ms-flex-align: end;
        align-content: normal;
        align-items: flex-end;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap
    }

    .rad-360-value-title__header:after {
        display: none
    }

    .rad-360-value-title__header-first-line {
        margin-bottom: 0
    }

    .rad-360-value-title__header-360,.rad-360-value-title__header-value {
        height: 4.21875rem
    }

    .rad-360-value-title__header-circle-placeholder {
        margin-left: .375rem
    }

    .rad-360-value-title__header-circle,.rad-360-value-title__header-circle-placeholder {
        height: 1.875rem
    }

    .rad-360-value-title__header-value {
        margin-left: 1.171875rem
    }
}

@media(min-width: 1024px) {
    .rad-360-value-title {
        -webkit-box-align:center;
        -ms-flex-align: center;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        align-items: center;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
        padding-top: 7.5rem
    }
}

@media(min-width: 1441px) {
    .rad-360-value-title {
        padding-top:calc(7.5rem + 8.35073vw - 120.33403px)
    }
}

@media(min-width: 1920px) {
    .rad-360-value-title {
        padding-top:10rem
    }
}

@media(min-width: 1024px) {
    .rad-360-value-title__header {
        margin-bottom:1.5rem;
        width: calc(200% - var(--rad-spacing-sm)*3/4*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-sm)*0);
        width: calc(100% - var(--rad-spacing-sm)*7/8*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0);
        width: calc(66.66667% - var(--rad-spacing-sm)*11/12*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1441px) {
    .rad-360-value-title__header {
        margin-bottom:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-360-value-title__header {
        margin-bottom:2rem
    }
}

@media(min-width: 1024px) {
    .rad-360-value-title__header-360,.rad-360-value-title__header-value {
        height:5.625rem;
        max-height: 7vw
    }
}

@media(min-width: 1441px) {
    .rad-360-value-title__header-360,.rad-360-value-title__header-value {
        height:calc(5.625rem + 6.26305vw - 90.25052px)
    }
}

@media(min-width: 1920px) {
    .rad-360-value-title__header-360,.rad-360-value-title__header-value {
        height:7.5rem
    }
}

@media(min-width: 1024px) {
    .rad-360-value-title__header-circle-placeholder {
        margin-left:0.5rem
    }
}

@media(min-width: 1441px) {
    .rad-360-value-title__header-circle-placeholder {
        margin-left:calc(0.5rem + 0.55672vw - 8.02227px)
    }
}

@media(min-width: 1920px) {
    .rad-360-value-title__header-circle-placeholder {
        margin-left:0.6666666667rem
    }
}

@media(min-width: 1024px) {
    .rad-360-value-title__header-circle,.rad-360-value-title__header-circle-placeholder {
        height:2.5rem
    }
}

@media(min-width: 1441px) {
    .rad-360-value-title__header-circle,.rad-360-value-title__header-circle-placeholder {
        height:calc(2.5rem + 2.78358vw - 40.11134px)
    }
}

@media(min-width: 1920px) {
    .rad-360-value-title__header-circle,.rad-360-value-title__header-circle-placeholder {
        height:3.3333333333rem
    }
}

@media(min-width: 1024px) {
    .rad-360-value-title__header-value {
        margin-left:1.5625rem
    }
}

@media(min-width: 1441px) {
    .rad-360-value-title__header-value {
        margin-left:calc(1.5625rem + 1.73974vw - 25.06959px)
    }
}

@media(min-width: 1920px) {
    .rad-360-value-title__header-value {
        margin-left:2.0833333333rem
    }
}

@media(min-width: 1024px) {
    .rad-360-value-title__sub-header {
        width:calc(200% - var(--rad-spacing-sm)*3/4*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-sm)*0);
        width: calc(100% - var(--rad-spacing-sm)*7/8*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0);
        width: calc(66.66667% - var(--rad-spacing-sm)*11/12*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0)
    }
}

.rad-absorb-cta {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 1rem;
    padding-left: var(--rad-spacing-sm);
    padding-right: var(--rad-spacing-sm)
}

@media(min-width: 600px) {
    .rad-absorb-cta {
        padding-left:var(--rad-spacing-md);
        padding-right: var(--rad-spacing-md)
    }
}

.rad-component--nested .rad-absorb-cta {
    padding-left: 0;
    padding-right: 0
}

@media(min-width: 600px) {
    .rad-absorb-cta {
        -webkit-box-align:center;
        -ms-flex-align: center;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        align-items: center;
        -ms-flex-direction: row;
        flex-direction: row;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        gap: 1.5rem
    }
}

@media(min-width: 1441px) {
    .rad-absorb-cta {
        gap:2rem;
        gap: calc(2rem + 2.22686vw - 32.08907px)
    }
}

@media(min-width: 1920px) {
    .rad-absorb-cta {
        gap:2.6666666667rem
    }
}

.rad-absorb-editorial-text {
    padding-left: var(--rad-spacing-sm);
    padding-right: var(--rad-spacing-sm)
}

@media(min-width: 600px) {
    .rad-absorb-editorial-text {
        padding-left:var(--rad-spacing-md);
        padding-right: var(--rad-spacing-md)
    }
}

.rad-component--nested .rad-absorb-editorial-text {
    padding-left: 0;
    padding-right: 0
}

.rad-absorb-editorial-text>h2,.rad-absorb-editorial-text>h3,.rad-absorb-editorial-text>h4,.rad-absorb-editorial-text>h5,.rad-absorb-editorial-text>ol,.rad-absorb-editorial-text>p,.rad-absorb-editorial-text>ul {
    width: calc(100% - var(--rad-spacing-sm)*3/4*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-sm)*0)
}

@media(min-width: 600px) {
    .rad-absorb-editorial-text>h2,.rad-absorb-editorial-text>h3,.rad-absorb-editorial-text>h4,.rad-absorb-editorial-text>h5,.rad-absorb-editorial-text>ol,.rad-absorb-editorial-text>p,.rad-absorb-editorial-text>ul {
        width:calc(50% - var(--rad-spacing-sm)*7/8*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-absorb-editorial-text>h2,.rad-absorb-editorial-text>h3,.rad-absorb-editorial-text>h4,.rad-absorb-editorial-text>h5,.rad-absorb-editorial-text>ol,.rad-absorb-editorial-text>p,.rad-absorb-editorial-text>ul {
        width:calc(33.33333% - var(--rad-spacing-sm)*11/12*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0)
    }
}

.rad-absorb-editorial-text h2 {
    font-size: 1.75rem;
    font-weight: 500;
    letter-spacing: -0.03em;
    line-height: 1.3
}

.rad-absorb-editorial-text h2:lang(ja-JP),.rad-absorb-editorial-text h2:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-absorb-editorial-text h2 {
        font-size:2.5rem;
        line-height: 1.2
    }
}

@media(min-width: 1024px) {
    .rad-absorb-editorial-text h2 {
        font-size:3rem;
        line-height: 1.2
    }
}

@media(min-width: 1441px) {
    .rad-absorb-editorial-text h2 {
        font-size:calc(3rem + 3.34029vw - 48.13361px)
    }
}

@media(min-width: 1920px) {
    .rad-absorb-editorial-text h2 {
        font-size:4rem
    }
}

.rad-absorb-editorial-text h2+h2,.rad-absorb-editorial-text h2+h3 {
    margin-top: 1rem
}

.rad-absorb-editorial-text h2+h4 {
    margin-top: 1.5rem
}

.rad-absorb-editorial-text h2+h5 {
    margin-top: .5rem
}

.rad-absorb-editorial-text h2+ol,.rad-absorb-editorial-text h2+p,.rad-absorb-editorial-text h2+ul {
    margin-top: 1.5rem
}

.rad-absorb-editorial-text h3 {
    font-size: 1.375rem;
    font-weight: 500;
    letter-spacing: -0.02em;
    line-height: 1.25
}

.rad-absorb-editorial-text h3:lang(ja-JP),.rad-absorb-editorial-text h3:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-absorb-editorial-text h3 {
        font-size:1.75rem;
        line-height: 1.3
    }
}

@media(min-width: 1024px) {
    .rad-absorb-editorial-text h3 {
        font-size:2rem;
        line-height: 1.2
    }
}

@media(min-width: 1441px) {
    .rad-absorb-editorial-text h3 {
        font-size:calc(2rem + 2.22686vw - 32.08907px)
    }
}

@media(min-width: 1920px) {
    .rad-absorb-editorial-text h3 {
        font-size:2.6666666667rem
    }
}

.rad-absorb-editorial-text h3+h2 {
    margin-top: 2rem
}

.rad-absorb-editorial-text h3+h3 {
    margin-top: 1rem
}

.rad-absorb-editorial-text h3+h4 {
    margin-top: .5rem
}

.rad-absorb-editorial-text h3+h5 {
    margin-top: 1rem
}

.rad-absorb-editorial-text h3+ol,.rad-absorb-editorial-text h3+p,.rad-absorb-editorial-text h3+ul {
    margin-top: 1.5rem
}

.rad-absorb-editorial-text h4 {
    font-size: 1.125rem;
    font-weight: 500;
    letter-spacing: -0.02em;
    line-height: 1.4
}

.rad-absorb-editorial-text h4:lang(ja-JP),.rad-absorb-editorial-text h4:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-absorb-editorial-text h4 {
        font-size:1.25rem;
        line-height: 1.4
    }
}

@media(min-width: 1024px) {
    .rad-absorb-editorial-text h4 {
        font-size:1.5rem;
        line-height: 1.3
    }
}

@media(min-width: 1441px) {
    .rad-absorb-editorial-text h4 {
        font-size:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-absorb-editorial-text h4 {
        font-size:2rem
    }
}

.rad-absorb-editorial-text h4+h2,.rad-absorb-editorial-text h4+h3 {
    margin-top: 2rem
}

.rad-absorb-editorial-text h4+h4 {
    margin-top: .5rem
}

.rad-absorb-editorial-text h4+h5 {
    margin-top: 2rem
}

.rad-absorb-editorial-text h4+ol,.rad-absorb-editorial-text h4+p,.rad-absorb-editorial-text h4+ul {
    margin-top: .5rem
}

.rad-absorb-editorial-text h5 {
    font-family: GT Sectra Fine,Palatino;
    font-size: 1.25rem;
    font-weight: 300;
    line-height: 1.25
}

@media(min-width: 600px) {
    .rad-absorb-editorial-text h5 {
        font-size:1.5rem;
        line-height: 1.25
    }
}

@media(min-width: 1024px) {
    .rad-absorb-editorial-text h5 {
        font-size:1.75rem;
        line-height: 1.25
    }
}

@media(min-width: 1441px) {
    .rad-absorb-editorial-text h5 {
        font-size:calc(1.75rem + 1.9485vw - 28.07794px)
    }
}

@media(min-width: 1920px) {
    .rad-absorb-editorial-text h5 {
        font-size:2.3333333333rem
    }
}

.rad-absorb-editorial-text h5+h2 {
    margin-top: 2rem
}

.rad-absorb-editorial-text h5+h3,.rad-absorb-editorial-text h5+h4 {
    margin-top: 1.5rem
}

.rad-absorb-editorial-text h5+h5 {
    margin-top: 1rem
}

.rad-absorb-editorial-text h5+ol,.rad-absorb-editorial-text h5+p,.rad-absorb-editorial-text h5+ul {
    margin-top: 1.5rem
}

.rad-absorb-editorial-text ol,.rad-absorb-editorial-text p,.rad-absorb-editorial-text ul {
    font-size: .875rem;
    font-weight: 500;
    font-weight: 400;
    letter-spacing: -0.005em;
    line-height: 1.5;
    padding-right: var(--scrollbar-width);
    text-underline-offset: .25rem
}

.rad-absorb-editorial-text ol:lang(ja-JP),.rad-absorb-editorial-text ol:lang(zh-CN),.rad-absorb-editorial-text p:lang(ja-JP),.rad-absorb-editorial-text p:lang(zh-CN),.rad-absorb-editorial-text ul:lang(ja-JP),.rad-absorb-editorial-text ul:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-absorb-editorial-text ol,.rad-absorb-editorial-text p,.rad-absorb-editorial-text ul {
        font-size:1rem
    }
}

@media(min-width: 1024px) {
    .rad-absorb-editorial-text ol,.rad-absorb-editorial-text p,.rad-absorb-editorial-text ul {
        font-size:1.125rem;
        line-height: 1.6
    }
}

@media(min-width: 1441px) {
    .rad-absorb-editorial-text ol,.rad-absorb-editorial-text p,.rad-absorb-editorial-text ul {
        font-size:calc(1.125rem + 1.25261vw - 18.0501px)
    }
}

@media(min-width: 1920px) {
    .rad-absorb-editorial-text ol,.rad-absorb-editorial-text p,.rad-absorb-editorial-text ul {
        font-size:1.5rem
    }
}

.rad-absorb-editorial-text ol:lang(ja-JP),.rad-absorb-editorial-text ol:lang(zh-CN),.rad-absorb-editorial-text p:lang(ja-JP),.rad-absorb-editorial-text p:lang(zh-CN),.rad-absorb-editorial-text ul:lang(ja-JP),.rad-absorb-editorial-text ul:lang(zh-CN) {
    font-weight: 400
}

.rad-absorb-editorial-text ol a,.rad-absorb-editorial-text p a,.rad-absorb-editorial-text ul a {
    font-weight: 500
}

.rad-absorb-editorial-text ol a:focus-visible,.rad-absorb-editorial-text p a:focus-visible,.rad-absorb-editorial-text ul a:focus-visible {
    outline-offset: 2px
}

.rad-absorb-editorial-text ol+h2,.rad-absorb-editorial-text ol+h3,.rad-absorb-editorial-text ol+h4,.rad-absorb-editorial-text ol+h5,.rad-absorb-editorial-text p+h2,.rad-absorb-editorial-text p+h3,.rad-absorb-editorial-text p+h4,.rad-absorb-editorial-text p+h5,.rad-absorb-editorial-text ul+h2,.rad-absorb-editorial-text ul+h3,.rad-absorb-editorial-text ul+h4,.rad-absorb-editorial-text ul+h5 {
    margin-top: 2rem
}

.rad-absorb-editorial-text ol+ol,.rad-absorb-editorial-text ol+p,.rad-absorb-editorial-text ol+ul,.rad-absorb-editorial-text p+ol,.rad-absorb-editorial-text p+p,.rad-absorb-editorial-text p+ul,.rad-absorb-editorial-text ul+ol,.rad-absorb-editorial-text ul+p,.rad-absorb-editorial-text ul+ul {
    margin-top: .5rem
}

.rad-absorb-editorial-text p {
    font-size: 1rem;
    font-weight: 500;
    font-weight: 400;
    letter-spacing: -0.005em;
    line-height: 1.6;
    text-underline-offset: .25rem
}

.rad-absorb-editorial-text p:lang(ja-JP),.rad-absorb-editorial-text p:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-absorb-editorial-text p {
        font-size:1.125rem
    }
}

@media(min-width: 1024px) {
    .rad-absorb-editorial-text p {
        font-size:1.25rem
    }
}

@media(min-width: 1441px) {
    .rad-absorb-editorial-text p {
        font-size:calc(1.25rem + 1.39179vw - 20.05567px)
    }
}

@media(min-width: 1920px) {
    .rad-absorb-editorial-text p {
        font-size:1.6666666667rem
    }
}

.rad-absorb-editorial-text p:lang(ja-JP),.rad-absorb-editorial-text p:lang(zh-CN) {
    font-weight: 400
}

.rad-absorb-editorial-text ol li {
    counter-increment: li;
    list-style-type: none;
    padding-left: 30px;
    position: relative
}

@media(min-width: 1441px) {
    .rad-absorb-editorial-text ol li {
        padding-left:calc(-0.08351px + 2.08768vw)
    }
}

@media(min-width: 1920px) {
    .rad-absorb-editorial-text ol li {
        padding-left:40px
    }
}

.rad-absorb-editorial-text ol li:before {
    color: #a100ff;
    content: counter(li) attr(start) ". ";
    left: 0;
    position: absolute
}

.rad-absorb-editorial-text ul {
    list-style: none;
    padding-left: 0
}

.rad-absorb-editorial-text ul li {
    padding-left: 29px;
    position: relative
}

@media(min-width: 1441px) {
    .rad-absorb-editorial-text ul li {
        padding-left:calc(-0.08072px + 2.01809vw)
    }
}

@media(min-width: 1920px) {
    .rad-absorb-editorial-text ul li {
        padding-left:38.6666666667px
    }
}

.rad-absorb-editorial-text ul li:before {
    background-color: #a100ff;
    content: "";
    display: inline-block;
    height: .375rem;
    left: 0;
    position: absolute;
    text-align: center;
    top: 10px;
    width: .375rem
}

@media(min-width: 1441px) {
    .rad-absorb-editorial-text ul li:before {
        top:calc(-0.02784px + 0.69589vw)
    }
}

@media(min-width: 1920px) {
    .rad-absorb-editorial-text ul li:before {
        top:13.3333333333px
    }
}

@media(min-width: 600px) {
    .rad-absorb-editorial-text>h2,.rad-absorb-editorial-text>h3,.rad-absorb-editorial-text>h4,.rad-absorb-editorial-text>h5,.rad-absorb-editorial-text>ol,.rad-absorb-editorial-text>p,.rad-absorb-editorial-text>ul {
        width:calc(150% - var(--rad-spacing-sm)*3/4*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-sm)*0);
        width: calc(75% - var(--rad-spacing-sm)*7/8*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-absorb-editorial-text>h2,.rad-absorb-editorial-text>h3,.rad-absorb-editorial-text>h4,.rad-absorb-editorial-text>h5,.rad-absorb-editorial-text>ol,.rad-absorb-editorial-text>p,.rad-absorb-editorial-text>ul {
        width:calc(50% - var(--rad-spacing-sm)*11/12*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 600px) {
    .rad-absorb-editorial-text h2+h2,.rad-absorb-editorial-text h2+h3 {
        margin-top:1.5rem
    }

    .rad-absorb-editorial-text h2+h4 {
        margin-top: 2rem
    }

    .rad-absorb-editorial-text h2+h5 {
        margin-top: .5rem
    }

    .rad-absorb-editorial-text h2+ol,.rad-absorb-editorial-text h2+p,.rad-absorb-editorial-text h2+ul {
        margin-top: 2rem
    }

    .rad-absorb-editorial-text h3+h2 {
        margin-top: 3rem
    }

    .rad-absorb-editorial-text h3+h3 {
        margin-top: 1.5rem
    }

    .rad-absorb-editorial-text h3+h4 {
        margin-top: .75rem
    }

    .rad-absorb-editorial-text h3+h5 {
        margin-top: 1.5rem
    }

    .rad-absorb-editorial-text h3+ol,.rad-absorb-editorial-text h3+p,.rad-absorb-editorial-text h3+ul {
        margin-top: 2rem
    }

    .rad-absorb-editorial-text h4+h2,.rad-absorb-editorial-text h4+h3 {
        margin-top: 3rem
    }

    .rad-absorb-editorial-text h4+h4 {
        margin-top: 1rem
    }

    .rad-absorb-editorial-text h4+h5 {
        margin-top: 3rem
    }

    .rad-absorb-editorial-text h4+p {
        margin-top: .75rem
    }

    .rad-absorb-editorial-text h4+ol,.rad-absorb-editorial-text h4+ul {
        margin-top: 1rem
    }

    .rad-absorb-editorial-text h5+h2 {
        margin-top: 3rem
    }

    .rad-absorb-editorial-text h5+h3 {
        margin-top: 2.5rem
    }

    .rad-absorb-editorial-text h5+h4 {
        margin-top: 2rem
    }

    .rad-absorb-editorial-text h5+h5 {
        margin-top: 1.5rem
    }

    .rad-absorb-editorial-text h5+ol,.rad-absorb-editorial-text h5+p,.rad-absorb-editorial-text h5+ul {
        margin-top: 2rem
    }

    .rad-absorb-editorial-text ol,.rad-absorb-editorial-text p,.rad-absorb-editorial-text ul {
        padding-right: 0
    }

    .rad-absorb-editorial-text ol+h2,.rad-absorb-editorial-text ol+h3,.rad-absorb-editorial-text ol+h4,.rad-absorb-editorial-text ol+h5,.rad-absorb-editorial-text p+h2,.rad-absorb-editorial-text p+h3,.rad-absorb-editorial-text p+h4,.rad-absorb-editorial-text p+h5,.rad-absorb-editorial-text ul+h2,.rad-absorb-editorial-text ul+h3,.rad-absorb-editorial-text ul+h4,.rad-absorb-editorial-text ul+h5 {
        margin-top: 3rem
    }

    .rad-absorb-editorial-text ol+ol,.rad-absorb-editorial-text ol+p,.rad-absorb-editorial-text ol+ul,.rad-absorb-editorial-text p+ol,.rad-absorb-editorial-text p+p,.rad-absorb-editorial-text p+ul,.rad-absorb-editorial-text ul+ol,.rad-absorb-editorial-text ul+p,.rad-absorb-editorial-text ul+ul {
        margin-top: 1rem
    }

    .rad-absorb-editorial-text ol li:before,.rad-absorb-editorial-text ul li:before {
        height: .5rem;
        width: .5rem
    }
}

@media(min-width: 1024px) {
    .rad-absorb-editorial-text>h2,.rad-absorb-editorial-text>h3,.rad-absorb-editorial-text>h4,.rad-absorb-editorial-text>h5,.rad-absorb-editorial-text>ol,.rad-absorb-editorial-text>p,.rad-absorb-editorial-text>ul {
        width:calc(200% - var(--rad-spacing-sm)*3/4*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-sm)*0);
        width: calc(100% - var(--rad-spacing-sm)*7/8*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0);
        width: calc(66.66667% - var(--rad-spacing-sm)*11/12*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0)
    }

    .rad-absorb-editorial-text h2+h2,.rad-absorb-editorial-text h2+h3 {
        margin-top: 2rem
    }

    .rad-absorb-editorial-text h2+h4 {
        margin-top: 3rem
    }

    .rad-absorb-editorial-text h2+h5 {
        margin-top: .5rem
    }

    .rad-absorb-editorial-text h2+ol,.rad-absorb-editorial-text h2+p,.rad-absorb-editorial-text h2+ul {
        margin-top: 3rem
    }

    .rad-absorb-editorial-text h3+h2 {
        margin-top: 4rem
    }

    .rad-absorb-editorial-text h3+h3 {
        margin-top: 2rem
    }

    .rad-absorb-editorial-text h3+h4 {
        margin-top: 1rem
    }

    .rad-absorb-editorial-text h3+h5 {
        margin-top: 2rem
    }

    .rad-absorb-editorial-text h3+ol,.rad-absorb-editorial-text h3+p,.rad-absorb-editorial-text h3+ul {
        margin-top: 3rem
    }

    .rad-absorb-editorial-text h4+h2,.rad-absorb-editorial-text h4+h3 {
        margin-top: 4rem
    }

    .rad-absorb-editorial-text h4+h4 {
        margin-top: 1.5rem
    }

    .rad-absorb-editorial-text h4+h5 {
        margin-top: 4rem
    }

    .rad-absorb-editorial-text h4+p {
        margin-top: 1rem
    }

    .rad-absorb-editorial-text h4+ol,.rad-absorb-editorial-text h4+ul {
        margin-top: 1.5rem
    }

    .rad-absorb-editorial-text h5+h2 {
        margin-top: 4rem
    }

    .rad-absorb-editorial-text h5+h3,.rad-absorb-editorial-text h5+h4 {
        margin-top: 3rem
    }

    .rad-absorb-editorial-text h5+h5 {
        margin-top: 2rem
    }

    .rad-absorb-editorial-text h5+ol,.rad-absorb-editorial-text h5+p,.rad-absorb-editorial-text h5+ul {
        margin-top: 3rem
    }

    .rad-absorb-editorial-text ol+h2,.rad-absorb-editorial-text ol+h3,.rad-absorb-editorial-text ol+h4,.rad-absorb-editorial-text ol+h5,.rad-absorb-editorial-text p+h2,.rad-absorb-editorial-text p+h3,.rad-absorb-editorial-text p+h4,.rad-absorb-editorial-text p+h5,.rad-absorb-editorial-text ul+h2,.rad-absorb-editorial-text ul+h3,.rad-absorb-editorial-text ul+h4,.rad-absorb-editorial-text ul+h5 {
        margin-top: 4rem
    }

    .rad-absorb-editorial-text ol+ol,.rad-absorb-editorial-text ol+p,.rad-absorb-editorial-text ol+ul,.rad-absorb-editorial-text p+ol,.rad-absorb-editorial-text p+p,.rad-absorb-editorial-text p+ul,.rad-absorb-editorial-text ul+ol,.rad-absorb-editorial-text ul+p,.rad-absorb-editorial-text ul+ul {
        margin-top: 1.5rem
    }
}

@media(min-width: 600px)and (max-width:1023px) {
    .rad-absorb-editorial-text {
        padding-bottom:2.5rem;
        padding-top: 2.5rem
    }
}

.rad-layout--nested .rad-absorb-editorial-text {
    padding-left: 0;
    padding-right: 0
}

.rad-layout--nested .rad-absorb-editorial-text>h2,.rad-layout--nested .rad-absorb-editorial-text>h3,.rad-layout--nested .rad-absorb-editorial-text>h4,.rad-layout--nested .rad-absorb-editorial-text>h5,.rad-layout--nested .rad-absorb-editorial-text>ol,.rad-layout--nested .rad-absorb-editorial-text>p,.rad-layout--nested .rad-absorb-editorial-text>ul {
    width: 100%
}

.rad-layout--nested .rad-absorb-editorial-text h3 {
    font-size: 1.25rem;
    font-weight: 500;
    letter-spacing: -0.02em;
    line-height: 1.3
}

.rad-layout--nested .rad-absorb-editorial-text h3:lang(ja-JP),.rad-layout--nested .rad-absorb-editorial-text h3:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-layout--nested .rad-absorb-editorial-text h3 {
        font-size:1.5rem;
        line-height: 1.3
    }
}

@media(min-width: 1024px) {
    .rad-layout--nested .rad-absorb-editorial-text h3 {
        font-size:1.75rem;
        line-height: 1.25
    }
}

@media(min-width: 1441px) {
    .rad-layout--nested .rad-absorb-editorial-text h3 {
        font-size:calc(1.75rem + 1.9485vw - 28.07794px)
    }
}

@media(min-width: 1920px) {
    .rad-layout--nested .rad-absorb-editorial-text h3 {
        font-size:2.3333333333rem
    }
}

.rad-layout--nested .rad-absorb-editorial-text p {
    font-size: .875rem;
    font-weight: 500;
    font-weight: 400;
    letter-spacing: -0.005em;
    line-height: 1.5;
    text-underline-offset: .25rem
}

.rad-layout--nested .rad-absorb-editorial-text p:lang(ja-JP),.rad-layout--nested .rad-absorb-editorial-text p:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-layout--nested .rad-absorb-editorial-text p {
        font-size:1rem
    }
}

@media(min-width: 1024px) {
    .rad-layout--nested .rad-absorb-editorial-text p {
        font-size:1.125rem;
        line-height: 1.6
    }
}

@media(min-width: 1441px) {
    .rad-layout--nested .rad-absorb-editorial-text p {
        font-size:calc(1.125rem + 1.25261vw - 18.0501px)
    }
}

@media(min-width: 1920px) {
    .rad-layout--nested .rad-absorb-editorial-text p {
        font-size:1.5rem
    }
}

.rad-layout--nested .rad-absorb-editorial-text p:lang(ja-JP),.rad-layout--nested .rad-absorb-editorial-text p:lang(zh-CN) {
    font-weight: 400
}

.rad-article-hero {
    color: #fff
}

@-webkit-keyframes from-right {
    0% {
        -webkit-transform: translateX(10vw);
        transform: translateX(10vw)
    }
}

@keyframes from-right {
    0% {
        -webkit-transform: translateX(10vw);
        transform: translateX(10vw)
    }
}

.rad-article-hero__home-button {
    margin-left: var(--rad-spacing-sm)
}

@media(min-width: 600px) {
    .rad-article-hero__home-button {
        margin-left:var(--rad-spacing-md)
    }
}

.rad-article-hero__text-content {
    padding-left: var(--rad-spacing-sm);
    padding-right: var(--rad-spacing-sm)
}

@media(min-width: 600px) {
    .rad-article-hero__text-content {
        padding-left:var(--rad-spacing-md);
        padding-right: var(--rad-spacing-md)
    }
}

.rad-component--nested .rad-article-hero__text-content {
    padding-left: 0;
    padding-right: 0
}

.rad-article-hero__eyebrow {
    font-size: .75rem;
    font-weight: 500;
    letter-spacing: .02em;
    line-height: 1.2;
    padding: 1.5rem 0 1rem;
    text-transform: uppercase;
    width: calc(100% - var(--rad-spacing-sm)*3/4*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-sm)*0)
}

.rad-article-hero__eyebrow:lang(ja-JP),.rad-article-hero__eyebrow:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 1024px) {
    .rad-article-hero__eyebrow {
        font-size:0.875rem
    }
}

@media(min-width: 1441px) {
    .rad-article-hero__eyebrow {
        font-size:calc(0.875rem + 0.97425vw - 14.03897px)
    }
}

@media(min-width: 1920px) {
    .rad-article-hero__eyebrow {
        font-size:1.1666666667rem
    }
}

@media(min-width: 600px) {
    .rad-article-hero__eyebrow {
        width:calc(50% - var(--rad-spacing-sm)*7/8*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-article-hero__eyebrow {
        width:calc(33.33333% - var(--rad-spacing-sm)*11/12*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0)
    }
}

.page-transition--from-right .rad-article-hero__eyebrow {
    -webkit-animation-delay: 100ms;
    animation-delay: 100ms;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-name: from-right;
    animation-name: from-right;
    -webkit-animation-timing-function: cubic-bezier(0.77,0,0.18,1);
    animation-timing-function: cubic-bezier(0.77,0,0.18,1)
}

@media(min-width: 600px) {
    .rad-article-hero__eyebrow {
        padding:3.75rem 0 1.5rem;
        width: calc(150% - var(--rad-spacing-sm)*3/4*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-sm)*0);
        width: calc(75% - var(--rad-spacing-sm)*7/8*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-article-hero__eyebrow {
        padding-bottom:2rem;
        padding-top: 3.75rem;
        width: calc(50% - var(--rad-spacing-sm)*11/12*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0);
        width: calc(200% - var(--rad-spacing-sm)*3/4*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-sm)*0);
        width: calc(100% - var(--rad-spacing-sm)*7/8*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0);
        width: calc(66.66667% - var(--rad-spacing-sm)*11/12*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1441px) {
    .rad-article-hero__eyebrow {
        padding-top:calc(3.75rem + 4.17537vw - 60.16701px)
    }
}

@media(min-width: 1920px) {
    .rad-article-hero__eyebrow {
        padding-top:5rem
    }
}

@media(min-width: 1441px) {
    .rad-article-hero__eyebrow {
        padding-bottom:calc(2rem + 2.22686vw - 32.08907px)
    }
}

@media(min-width: 1920px) {
    .rad-article-hero__eyebrow {
        padding-bottom:2.6666666667rem
    }
}

.rad-article-hero__eyebrow .rad-article-hero__client-name:before {
    content: "•";
    padding-right: .25em
}

.rad-article-hero__headline {
    font-size: 2rem;
    font-weight: 500;
    letter-spacing: -0.03em;
    line-height: 1.25;
    width: calc(100% - var(--rad-spacing-sm)*3/4*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-sm)*0)
}

.rad-article-hero__headline:lang(ja-JP),.rad-article-hero__headline:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-article-hero__headline {
        font-size:3.75rem;
        line-height: 1.15
    }
}

@media(min-width: 1441px) {
    .rad-article-hero__headline {
        font-size:calc(3.75rem + 4.17537vw - 60.16701px)
    }
}

@media(min-width: 1920px) {
    .rad-article-hero__headline {
        font-size:5rem
    }
}

@media(min-width: 600px) {
    .rad-article-hero__headline {
        width:calc(50% - var(--rad-spacing-sm)*7/8*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-article-hero__headline {
        width:calc(33.33333% - var(--rad-spacing-sm)*11/12*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0)
    }
}

.page-transition--from-right .rad-article-hero__headline {
    -webkit-animation-delay: 150ms;
    animation-delay: 150ms;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-name: from-right;
    animation-name: from-right;
    -webkit-animation-timing-function: cubic-bezier(0.77,0,0.18,1);
    animation-timing-function: cubic-bezier(0.77,0,0.18,1)
}

@media(min-width: 600px) {
    .rad-article-hero__headline {
        width:calc(150% - var(--rad-spacing-sm)*3/4*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-sm)*0);
        width: calc(75% - var(--rad-spacing-sm)*7/8*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-article-hero__headline {
        width:calc(50% - var(--rad-spacing-sm)*11/12*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0);
        width: calc(200% - var(--rad-spacing-sm)*3/4*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-sm)*0);
        width: calc(100% - var(--rad-spacing-sm)*7/8*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0);
        width: calc(66.66667% - var(--rad-spacing-sm)*11/12*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0)
    }
}

.rad-article-hero__subheader {
    font-family: GT Sectra Fine,Palatino;
    font-size: 1.25rem;
    font-weight: 300;
    line-height: 1.25;
    padding-top: 16px;
    width: calc(100% - var(--rad-spacing-sm)*3/4*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-sm)*0)
}

@media(min-width: 600px) {
    .rad-article-hero__subheader {
        font-size:1.5rem;
        line-height: 1.25
    }
}

@media(min-width: 1024px) {
    .rad-article-hero__subheader {
        font-size:1.75rem;
        line-height: 1.25
    }
}

@media(min-width: 1441px) {
    .rad-article-hero__subheader {
        font-size:calc(1.75rem + 1.9485vw - 28.07794px)
    }
}

@media(min-width: 1920px) {
    .rad-article-hero__subheader {
        font-size:2.3333333333rem
    }
}

@media(min-width: 600px) {
    .rad-article-hero__subheader {
        width:calc(50% - var(--rad-spacing-sm)*7/8*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-article-hero__subheader {
        width:calc(33.33333% - var(--rad-spacing-sm)*11/12*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0)
    }
}

.page-transition--from-right .rad-article-hero__subheader {
    -webkit-animation-delay: 150ms;
    animation-delay: 150ms;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-name: from-right;
    animation-name: from-right;
    -webkit-animation-timing-function: cubic-bezier(0.77,0,0.18,1);
    animation-timing-function: cubic-bezier(0.77,0,0.18,1)
}

@media(min-width: 600px) {
    .rad-article-hero__subheader {
        padding-top:24px;
        width: calc(150% - var(--rad-spacing-sm)*3/4*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-sm)*0);
        width: calc(75% - var(--rad-spacing-sm)*7/8*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-article-hero__subheader {
        padding-top:2rem;
        width: calc(50% - var(--rad-spacing-sm)*11/12*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0);
        width: calc(200% - var(--rad-spacing-sm)*3/4*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-sm)*0);
        width: calc(100% - var(--rad-spacing-sm)*7/8*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0);
        width: calc(66.66667% - var(--rad-spacing-sm)*11/12*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1441px) {
    .rad-article-hero__subheader {
        padding-top:calc(2rem + 2.22686vw - 32.08907px)
    }
}

@media(min-width: 1920px) {
    .rad-article-hero__subheader {
        padding-top:2.6666666667rem
    }
}

.rad-article-hero__time-and-date {
    font-size: .75rem;
    font-weight: 500;
    letter-spacing: .02em;
    line-height: 1.2;
    padding-top: 1.5rem;
    text-transform: uppercase;
    width: calc(100% - var(--rad-spacing-sm)*3/4*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-sm)*0)
}

.rad-article-hero__time-and-date:lang(ja-JP),.rad-article-hero__time-and-date:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 1024px) {
    .rad-article-hero__time-and-date {
        font-size:0.875rem
    }
}

@media(min-width: 1441px) {
    .rad-article-hero__time-and-date {
        font-size:calc(0.875rem + 0.97425vw - 14.03897px)
    }
}

@media(min-width: 1920px) {
    .rad-article-hero__time-and-date {
        font-size:1.1666666667rem
    }
}

@media(min-width: 600px) {
    .rad-article-hero__time-and-date {
        width:calc(50% - var(--rad-spacing-sm)*7/8*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-article-hero__time-and-date {
        width:calc(33.33333% - var(--rad-spacing-sm)*11/12*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0)
    }
}

.page-transition--from-right .rad-article-hero__time-and-date {
    -webkit-animation-delay: 200ms;
    animation-delay: 200ms;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-name: from-right;
    animation-name: from-right;
    -webkit-animation-timing-function: cubic-bezier(0.77,0,0.18,1);
    animation-timing-function: cubic-bezier(0.77,0,0.18,1)
}

@media(min-width: 600px) {
    .rad-article-hero__time-and-date {
        padding-top:3.75rem;
        width: calc(150% - var(--rad-spacing-sm)*3/4*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-sm)*0);
        width: calc(75% - var(--rad-spacing-sm)*7/8*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-article-hero__time-and-date {
        width:calc(50% - var(--rad-spacing-sm)*11/12*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1441px) {
    .rad-article-hero__time-and-date {
        padding-top:calc(3.75rem + 4.17537vw - 60.16701px)
    }
}

@media(min-width: 1920px) {
    .rad-article-hero__time-and-date {
        padding-top:5rem
    }
}

@media(min-width: 1024px) {
    .rad-article-hero__time-and-date {
        width:calc(200% - var(--rad-spacing-sm)*3/4*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-sm)*0);
        width: calc(100% - var(--rad-spacing-sm)*7/8*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0);
        width: calc(66.66667% - var(--rad-spacing-sm)*11/12*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0)
    }
}

.rad-article-hero__time-and-date .rad-article-hero__publish-date,.rad-article-hero__time-and-date .rad-article-hero__read-time {
    display: inline-block;
    padding-right: 16px
}

@media(min-width: 600px) {
    .rad-article-hero__time-and-date .rad-article-hero__publish-date,.rad-article-hero__time-and-date .rad-article-hero__read-time {
        padding-right:24px
    }
}

@media(min-width: 1024px) {
    .rad-article-hero__time-and-date .rad-article-hero__publish-date,.rad-article-hero__time-and-date .rad-article-hero__read-time {
        padding-right:32px
    }
}

.rad-article-hero__graphic {
    padding-left: var(--rad-spacing-sm);
    padding-right: var(--rad-spacing-sm);
    padding-top: 1.5rem
}

@media(min-width: 600px) {
    .rad-article-hero__graphic {
        padding-left:var(--rad-spacing-md);
        padding-right: var(--rad-spacing-md)
    }
}

.rad-component--nested .rad-article-hero__graphic {
    padding-left: 0;
    padding-right: 0
}

.page-transition--from-right .rad-article-hero__graphic {
    -webkit-animation-delay: 250ms;
    animation-delay: 250ms;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-name: from-right;
    animation-name: from-right;
    -webkit-animation-timing-function: cubic-bezier(0.77,0,0.18,1);
    animation-timing-function: cubic-bezier(0.77,0,0.18,1)
}

@media(min-width: 600px) {
    .rad-article-hero__graphic {
        padding-top:3.75rem
    }
}

@media(min-width: 1024px) {
    .rad-article-hero__graphic {
        padding-top:60px
    }
}

@media(min-width: 1441px) {
    .rad-article-hero__graphic {
        padding-top:calc(-0.16701px + 4.17537vw)
    }
}

@media(min-width: 1920px) {
    .rad-article-hero__graphic {
        padding-top:80px
    }
}

.rad-article-hero__hero-image {
    margin-left: calc(var(--rad-spacing-sm)*-1);
    padding-left: 0;
    padding-right: 0;
    width: calc(100% - var(--rad-spacing-sm)*3/4*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-sm)*1)
}

@media(min-width: 600px) {
    .rad-article-hero__hero-image {
        width:calc(50% - var(--rad-spacing-sm)*7/8*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*1)
    }
}

@media(min-width: 1024px) {
    .rad-article-hero__hero-image {
        width:calc(33.33333% - var(--rad-spacing-sm)*11/12*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*1)
    }
}

@media(min-width: 600px) {
    .rad-article-hero__hero-image {
        margin-left:calc(var(--rad-spacing-md)*-1);
        width: calc(150% - var(--rad-spacing-sm)*3/4*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-sm)*1);
        width: calc(75% - var(--rad-spacing-sm)*7/8*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*1)
    }
}

@media(min-width: 1024px) {
    .rad-article-hero__hero-image {
        width:calc(50% - var(--rad-spacing-sm)*11/12*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*1);
        width: calc(200% - var(--rad-spacing-sm)*3/4*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-sm)*1);
        width: calc(100% - var(--rad-spacing-sm)*7/8*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*1);
        width: calc(66.66667% - var(--rad-spacing-sm)*11/12*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*1)
    }
}

.rad-article-hero__hero-image img,.rad-article-hero__hero-image video {
    width: 100%
}

.rad-article-hero__hero-image .rad-media-overlay--hidden {
    display: none
}

.rad-article-hero__hero-image .rad-vidyard-player__container {
    padding: 0
}

.rad-article-hero__hero-image .cmp-video__video {
    display: inline
}

.rad-article-hero__horizontal-line {
    height: 4px;
    width: calc(100% - var(--rad-spacing-sm)*3/4*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-sm)*0);
    width: auto
}

@media(min-width: 600px) {
    .rad-article-hero__horizontal-line {
        width:calc(50% - var(--rad-spacing-sm)*7/8*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-article-hero__horizontal-line {
        width:calc(33.33333% - var(--rad-spacing-sm)*11/12*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 600px) {
    .rad-article-hero__horizontal-line {
        height:6px;
        width: calc(150% - var(--rad-spacing-sm)*3/4*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-sm)*0);
        width: calc(75% - var(--rad-spacing-sm)*7/8*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-article-hero__horizontal-line {
        height:8px;
        width: calc(50% - var(--rad-spacing-sm)*11/12*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0);
        width: calc(200% - var(--rad-spacing-sm)*3/4*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-sm)*0);
        width: calc(100% - var(--rad-spacing-sm)*7/8*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0);
        width: calc(66.66667% - var(--rad-spacing-sm)*11/12*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1441px) {
    .rad-article-hero__horizontal-line {
        height:calc(-0.02227px + 0.55672vw)
    }
}

@media(min-width: 1920px) {
    .rad-article-hero__horizontal-line {
        height:10.6666666667px
    }
}

.rad-article-hero__horizontal-line.rad-line-gradient--dark {
    background: -webkit-gradient(linear,left top,right top,color-stop(13.38%,#460073),color-stop(36.59%,#a100ff),color-stop(65.35%,#ff50a0),color-stop(81.57%,#ff7800),color-stop(91.26%,#feb149));
    background: linear-gradient(90deg,#460073 13.38%,#a100ff 36.59%,#ff50a0 65.35%,#ff7800 81.57%,#feb149 91.26%)
}

.rad-article-hero__horizontal-line.rad-line-gradient--light {
    background: -webkit-gradient(linear,left top,right top,from(#f9cbef),color-stop(21%,#f482f1),color-stop(45.23%,#f385cd),color-stop(81.93%,#dfbf74),to(#9bf2ad));
    background: linear-gradient(90deg,#f9cbef,#f482f1 21%,#f385cd 45.23%,#dfbf74 81.93%,#9bf2ad)
}

.rad-article-hero__podcast {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    font-size: .875rem;
    font-weight: 500;
    gap: .5rem;
    letter-spacing: -0.005em;
    line-height: 1.5;
    margin-top: 1rem;
    text-underline-offset: .25rem;
    width: calc(100% - var(--rad-spacing-sm)*3/4*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-sm)*0)
}

.rad-article-hero__podcast:lang(ja-JP),.rad-article-hero__podcast:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-article-hero__podcast {
        font-size:1rem
    }
}

@media(min-width: 1441px) {
    .rad-article-hero__podcast {
        font-size:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-article-hero__podcast {
        font-size:1.3333333333rem
    }
}

@media(min-width: 600px) {
    .rad-article-hero__podcast {
        width:calc(50% - var(--rad-spacing-sm)*7/8*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-article-hero__podcast {
        width:calc(33.33333% - var(--rad-spacing-sm)*11/12*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 600px) {
    .rad-article-hero__podcast {
        gap:1rem;
        margin-top: 1.5rem;
        width: calc(150% - var(--rad-spacing-sm)*3/4*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-sm)*0);
        width: calc(75% - var(--rad-spacing-sm)*7/8*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-article-hero__podcast {
        gap:1.5rem;
        margin-top: 2rem;
        width: calc(50% - var(--rad-spacing-sm)*11/12*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0);
        width: calc(200% - var(--rad-spacing-sm)*3/4*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-sm)*0)
    }
}

@media(min-width: 1441px) {
    .rad-article-hero__podcast {
        margin-top:calc(2rem + 2.22686vw - 32.08907px)
    }
}

@media(min-width: 1920px) {
    .rad-article-hero__podcast {
        margin-top:2.6666666667rem
    }
}

@media(min-width: 1441px) {
    .rad-article-hero__podcast {
        gap:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-article-hero__podcast {
        gap:2rem
    }
}

@media(min-width: 1024px) {
    .rad-article-hero__podcast {
        width:calc(100% - var(--rad-spacing-sm)*7/8*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0);
        width: calc(66.66667% - var(--rad-spacing-sm)*11/12*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0)
    }
}

.rad-article-hero__podcast .rad-article-hero__podcast-icons {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: .5rem
}

@media(min-width: 600px) {
    .rad-article-hero__podcast .rad-article-hero__podcast-icons {
        gap:1rem
    }
}

@media(min-width: 1024px) {
    .rad-article-hero__podcast .rad-article-hero__podcast-icons {
        gap:2rem
    }
}

@media(min-width: 1441px) {
    .rad-article-hero__podcast .rad-article-hero__podcast-icons {
        gap:calc(2rem + 2.22686vw - 32.08907px)
    }
}

@media(min-width: 1920px) {
    .rad-article-hero__podcast .rad-article-hero__podcast-icons {
        gap:2.6666666667rem
    }
}

.rad-absorb-video__iframe {
    aspect-ratio: 1.7777777778;
    display: block;
    height: 100%;
    margin: 0 auto;
    max-height: calc(100svh - var(--rad-nav-height));
    max-width: calc(100svh*1.77778 - var(--rad-nav-height)*1.77778);
    -webkit-transition-duration: 550ms;
    transition-duration: 550ms;
    -webkit-transition-property: max-height,max-width;
    transition-property: max-height,max-width;
    -webkit-transition-timing-function: cubic-bezier(0.85,0,0,1);
    transition-timing-function: cubic-bezier(0.85,0,0,1);
    width: 100%
}

.rad-absorb-video__transcript {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-size: .875rem;
    font-weight: 500;
    font-weight: 400;
    gap: 5rem;
    justify-content: flex-end;
    letter-spacing: -0.005em;
    line-height: 1.5;
    padding-right: 1rem;
    padding-top: 1rem;
    text-underline-offset: .25rem
}

.rad-absorb-video__transcript:lang(ja-JP),.rad-absorb-video__transcript:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-absorb-video__transcript {
        font-size:1rem
    }
}

@media(min-width: 1441px) {
    .rad-absorb-video__transcript {
        font-size:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-absorb-video__transcript {
        font-size:1.3333333333rem
    }
}

.rad-absorb-video__transcript:lang(ja-JP),.rad-absorb-video__transcript:lang(zh-CN) {
    font-weight: 400
}

@media(min-width: 1441px) {
    .rad-absorb-video__transcript {
        padding-top:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-absorb-video__transcript {
        padding-top:1.3333333333rem
    }
}

@media(min-width: 600px) {
    .rad-absorb-video__transcript {
        padding-right:3rem
    }
}

@media(min-width: 1024px) {
    .rad-absorb-video__transcript {
        padding-right:0
    }

    .rad-absorb-video {
        margin-left: 5rem;
        margin-right: 5rem
    }
}

@media(min-width: 1441px) {
    .rad-absorb-video {
        margin-right:calc(5rem + 5.56715vw - 80.22269px)
    }
}

@media(min-width: 1920px) {
    .rad-absorb-video {
        margin-right:6.6666666667rem
    }
}

@media(min-width: 1441px) {
    .rad-absorb-video {
        margin-left:calc(5rem + 5.56715vw - 80.22269px)
    }
}

@media(min-width: 1920px) {
    .rad-absorb-video {
        margin-left:6.6666666667rem
    }
}

.rad--has-subnav .rad-absorb-video__iframe {
    max-height: calc(100svh - var(--rad-nav-height) - 3.25rem);
    max-width: calc(100svh*1.77778 - var(--rad-nav-height)*1.77778 - 5.77778rem)
}

.rad-content-grid-card {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 424px;
    overflow: hidden;
    position: relative;
    -webkit-transition-duration: 550ms;
    transition-duration: 550ms;
    -webkit-transition-property: scale;
    transition-property: scale;
    -webkit-transition-timing-function: cubic-bezier(0.85,0,0,1);
    transition-timing-function: cubic-bezier(0.85,0,0,1);
    width: 300px
}

@media(min-width: 1441px) {
    .rad-content-grid-card {
        height:calc(-1.18024px + 29.50592vw)
    }
}

@media(min-width: 1920px) {
    .rad-content-grid-card {
        height:565.3333333333px
    }
}

@media(min-width: 600px) {
    .rad-content-grid-card {
        width:276px
    }
}

@media(min-width: 1441px) {
    .rad-content-grid-card {
        width:calc(-0.76827px + 19.20668vw)
    }
}

@media(min-width: 1920px) {
    .rad-content-grid-card {
        width:368px
    }
}

.rad-content-grid-card:focus-within {
    outline: 2px solid #a100ff;
    outline-offset: 8px
}

.rad-content-grid-card .cmp-image__image {
    display: block
}

.rad-content-grid-card__cta-cover,.rad-content-grid-card__front-toggle {
    cursor: pointer;
    left: 0;
    outline: none;
    position: absolute;
    width: 100%;
    z-index: 1
}

.rad-content-grid-card__front-toggle {
    height: 100%;
    top: 0
}

.rad-content-grid-card__cta-cover {
    bottom: 0;
    height: 72px;
    visibility: hidden
}

@media(min-width: 1024px) {
    .rad-content-grid-card__cta-cover {
        height:100%
    }
}


.rad-content-grid-card__close-button {
    height: 24px;
    position: absolute;
    right: 24px;
    top: 28px;
    -webkit-transition-duration: 550ms;
    transition-duration: 550ms;
    -webkit-transition-property: translate;
    transition-property: translate;
    -webkit-transition-timing-function: cubic-bezier(0.85,0,0,1);
    transition-timing-function: cubic-bezier(0.85,0,0,1);
    translate: calc(100% + 24px);
    width: 24px
}

@media(min-width: 600px) {
    .rad-content-grid-card__close-button {
        display:none
    }
}

.rad-content-grid-card__label {
    font-size: .75rem;
    font-weight: 500;
    letter-spacing: .02em;
    line-height: 1.2;
    margin-bottom: 1.5rem;
    margin-left: 1.5rem;
    padding-top: 1.75rem;
    position: relative;
    text-transform: uppercase
}

.rad-content-grid-card__label:lang(ja-JP),.rad-content-grid-card__label:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 1024px) {
    .rad-content-grid-card__label {
        font-size:0.875rem
    }
}

@media(min-width: 1441px) {
    .rad-content-grid-card__label {
        font-size:calc(0.875rem + 0.97425vw - 14.03897px)
    }
}

@media(min-width: 1920px) {
    .rad-content-grid-card__label {
        font-size:1.1666666667rem
    }
}

@media(min-width: 1441px) {
    .rad-content-grid-card__label {
        margin-bottom:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-content-grid-card__label {
        margin-bottom:2rem
    }
}

@media(min-width: 1441px) {
    .rad-content-grid-card__label {
        margin-left:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-content-grid-card__label {
        margin-left:2rem
    }
}

@media(min-width: 1441px) {
    .rad-content-grid-card__label {
        padding-top:calc(1.75rem + 1.9485vw - 28.07794px)
    }
}

@media(min-width: 1920px) {
    .rad-content-grid-card__label {
        padding-top:2.3333333333rem
    }
}

.rad-content-grid-card__title {
    font-size: 1.25rem;
    font-weight: 500;
    letter-spacing: -0.02em;
    line-height: 1.2;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    position: relative
}

@media(min-width: 1441px) {
    .rad-content-grid-card__title {
        font-size:calc(1.25rem + 1.39179vw - 20.05567px)
    }
}

@media(min-width: 1920px) {
    .rad-content-grid-card__title {
        font-size:1.6666666667rem
    }
}

@media(min-width: 1441px) {
    .rad-content-grid-card__title {
        margin-left:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-content-grid-card__title {
        margin-left:2rem
    }
}

@media(min-width: 1441px) {
    .rad-content-grid-card__title {
        margin-right:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-content-grid-card__title {
        margin-right:2rem
    }
}

.rad-content-grid-card__sliding-content {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-grow: 1;
    left: 0;
    position: relative;
    -webkit-transition-duration: 550ms;
    transition-duration: 550ms;
    -webkit-transition-property: left;
    transition-property: left;
    -webkit-transition-timing-function: cubic-bezier(0.85,0,0,1);
    transition-timing-function: cubic-bezier(0.85,0,0,1)
}

.rad-content-grid-card__sliding-content>* {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    width: 100%
}

.rad-content-grid-card__front-content {
    -webkit-box-align: end;
    -ms-flex-align: end;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    align-items: flex-end;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse
}

.rad-content-grid-card__half-image .cmp-image__image,.rad-content-grid-card__quarter-image .cmp-image__image {
    height: 212px;
    width: auto
}

@media(min-width: 1441px) {
    .rad-content-grid-card__half-image .cmp-image__image,.rad-content-grid-card__quarter-image .cmp-image__image {
        height:calc(-0.59012px + 14.75296vw)
    }
}

@media(min-width: 1920px) {
    .rad-content-grid-card__half-image .cmp-image__image,.rad-content-grid-card__quarter-image .cmp-image__image {
        height:282.6666666667px
    }
}

.rad-content-grid-card__half-image {
    width: 100%
}

.rad-content-grid-card__half-image .cmp-image__image {
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%
}

.rad-content-grid-card__half-video {
    width: 100%
}

.rad-content-grid-card__half-video:after {
    background: -webkit-gradient(linear,left top,left bottom,from(rgba(0,0,0,.7)),to(rgba(0,0,0,.1)));
    background: linear-gradient(180deg,rgba(0,0,0,.7) 0%,rgba(0,0,0,.1));
    bottom: 0;
    content: "";
    height: 13.25rem;
    left: 0;
    position: relative;
    width: 100%
}

@media(min-width: 1441px) {
    .rad-content-grid-card__half-video:after {
        height:calc(13.25rem + 14.75296vw - 212.59012px)
    }
}

@media(min-width: 1920px) {
    .rad-content-grid-card__half-video:after {
        height:17.6666666667rem
    }
}

.rad-content-grid-card__half-video .rad-lazy-video {
    bottom: 0;
    height: 13.25rem;
    left: 0;
    -o-object-fit: cover;
    object-fit: cover;
    position: absolute;
    width: 100%
}

@media(min-width: 1441px) {
    .rad-content-grid-card__half-video .rad-lazy-video {
        height:calc(13.25rem + 14.75296vw - 212.59012px)
    }
}

@media(min-width: 1920px) {
    .rad-content-grid-card__half-video .rad-lazy-video {
        height:17.6666666667rem
    }
}

@media(min-width: 600px) {
    .rad-content-grid-card__half-video .rad-lazy-video {
        -o-object-fit:fill;
        object-fit: fill
    }
}

.rad-content-grid-card__half-video.show-overlay:after {
    display: block
}

.rad-content-grid-card__full-image {
    aspect-ratio: .7075471698;
    pointer-events: none;
    position: absolute;
    -webkit-transition-duration: 550ms;
    transition-duration: 550ms;
    -webkit-transition-property: opacity,width,-webkit-filter;
    transition-property: opacity,width,-webkit-filter;
    transition-property: filter,opacity,width;
    transition-property: filter,opacity,width,-webkit-filter;
    -webkit-transition-timing-function: cubic-bezier(0.85,0,0,1);
    transition-timing-function: cubic-bezier(0.85,0,0,1);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 100%
}

.rad-content-grid-card__full-image .cmp-image,.rad-content-grid-card__full-image .image.radimage {
    height: 100%
}

.rad-content-grid-card__full-image .cmp-image__image {
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%
}

@media(min-width: 600px) {
    .rad-content-grid-card__full-image {
        aspect-ratio:.6509433962
    }
}

.rad-content-grid-card__full-video {
    aspect-ratio: .7075471698;
    pointer-events: none;
    position: absolute;
    -webkit-transition-duration: 550ms;
    transition-duration: 550ms;
    -webkit-transition-property: opacity,width,-webkit-filter;
    transition-property: opacity,width,-webkit-filter;
    transition-property: filter,opacity,width;
    transition-property: filter,opacity,width,-webkit-filter;
    -webkit-transition-timing-function: cubic-bezier(0.85,0,0,1);
    transition-timing-function: cubic-bezier(0.85,0,0,1);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 100%
}

.rad-content-grid-card__full-video:after {
    background: -webkit-gradient(linear,left top,left bottom,from(rgba(0,0,0,.7)),to(rgba(0,0,0,.1)));
    background: linear-gradient(180deg,rgba(0,0,0,.7) 0%,rgba(0,0,0,.1));
    content: "";
    height: 100%;
    left: 0;
    position: relative;
    top: 0;
    width: 100%
}

.rad-content-grid-card__full-video .rad-lazy-video {
    height: 100%;
    left: 0;
    -o-object-fit: cover;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 100%
}

@media(min-width: 600px) {
    .rad-content-grid-card__full-video .rad-lazy-video {
        -o-object-fit:fill;
        object-fit: fill
    }
}

.rad-content-grid-card__full-video.show-overlay:after {
    display: block
}

@media(min-width: 600px) {
    .rad-content-grid-card__full-video {
        aspect-ratio:.6509433962
    }
}

.rad-content-grid-card__logo {
    font-size: 1.25rem;
    font-weight: 500;
    letter-spacing: -0.05em;
    line-height: 1.4;
    margin-bottom: 1.5rem;
    margin-left: 1.5rem;
    margin-right: auto;
    text-transform: uppercase
}

@media(min-width: 1441px) {
    .rad-content-grid-card__logo {
        font-size:calc(1.25rem + 1.39179vw - 20.05567px)
    }
}

@media(min-width: 1920px) {
    .rad-content-grid-card__logo {
        font-size:1.6666666667rem
    }
}

@media(min-width: 1441px) {
    .rad-content-grid-card__logo {
        margin-bottom:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-content-grid-card__logo {
        margin-bottom:2rem
    }
}

@media(min-width: 1441px) {
    .rad-content-grid-card__logo {
        margin-left:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-content-grid-card__logo {
        margin-left:2rem
    }
}

.rad-content-grid-card__back-content {
    -webkit-box-align: end;
    -ms-flex-align: end;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    align-items: flex-end;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    justify-content: space-between
}

.rad-content-grid-card__content {
    font-size: .75rem;
    font-weight: 500;
    font-weight: 400;
    letter-spacing: -0.005em;
    line-height: 1.5;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    margin-top: 1.5rem;
    text-underline-offset: .25rem
}

.rad-content-grid-card__content:lang(ja-JP),.rad-content-grid-card__content:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-content-grid-card__content {
        font-size:0.875rem
    }
}

@media(min-width: 1441px) {
    .rad-content-grid-card__content {
        font-size:calc(0.875rem + 0.97425vw - 14.03897px)
    }
}

@media(min-width: 1920px) {
    .rad-content-grid-card__content {
        font-size:1.1666666667rem
    }
}

.rad-content-grid-card__content:lang(ja-JP),.rad-content-grid-card__content:lang(zh-CN) {
    font-weight: 400
}

@media(min-width: 1441px) {
    .rad-content-grid-card__content {
        margin-top:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-content-grid-card__content {
        margin-top:2rem
    }
}

@media(min-width: 1441px) {
    .rad-content-grid-card__content {
        margin-left:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-content-grid-card__content {
        margin-left:2rem
    }
}

@media(min-width: 1441px) {
    .rad-content-grid-card__content {
        margin-right:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-content-grid-card__content {
        margin-right:2rem
    }
}

.rad-content-grid-card__content-event {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    font-weight: 500;
    margin-bottom: 1rem;
    row-gap: 0.5rem
}

@media(min-width: 1441px) {
    .rad-content-grid-card__content-event {
        row-gap:calc(0.5rem + 0.55672vw - 8.02227px)
    }
}

@media(min-width: 1920px) {
    .rad-content-grid-card__content-event {
        row-gap:0.6666666667rem
    }
}

@media(min-width: 1441px) {
    .rad-content-grid-card__content-event {
        margin-bottom:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-content-grid-card__content-event {
        margin-bottom:1.3333333333rem
    }
}

.rad-content-grid-card__content-event svg {
    min-height: 1.25rem;
    min-width: 1.25rem
}

@media(min-width: 1441px) {
    .rad-content-grid-card__content-event svg {
        min-height:calc(1.25rem + 1.39179vw - 20.05567px)
    }
}

@media(min-width: 1920px) {
    .rad-content-grid-card__content-event svg {
        min-height:1.6666666667rem
    }
}

@media(min-width: 1441px) {
    .rad-content-grid-card__content-event svg {
        min-width:calc(1.25rem + 1.39179vw - 20.05567px)
    }
}

@media(min-width: 1920px) {
    .rad-content-grid-card__content-event svg {
        min-width:1.6666666667rem
    }
}

.rad-content-grid-card__content-event-line {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-column-gap: 0.5rem;
    -moz-column-gap: 0.5rem;
    column-gap: 0.5rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

@media(min-width: 1441px) {
    .rad-content-grid-card__content-event-line {
        -webkit-column-gap:calc(0.5rem + 0.55672vw - 8.02227px);
        -moz-column-gap: calc(0.5rem + 0.55672vw - 8.02227px);
        column-gap: calc(0.5rem + 0.55672vw - 8.02227px)
    }
}

@media(min-width: 1920px) {
    .rad-content-grid-card__content-event-line {
        -webkit-column-gap:0.6666666667rem;
        -moz-column-gap: 0.6666666667rem;
        column-gap: 0.6666666667rem
    }
}

.rad-content-grid-card .rad-button {
    bottom: 0;
    margin-right: 1.5rem;
    position: absolute;
    right: 0;
    -webkit-transition-duration: 550ms;
    transition-duration: 550ms;
    -webkit-transition-property: bottom,right,translate;
    transition-property: bottom,right,translate;
    -webkit-transition-timing-function: cubic-bezier(0.85,0,0,1);
    transition-timing-function: cubic-bezier(0.85,0,0,1);
    translate: 0 100%;
    z-index: 2
}

@media(min-width: 1441px) {
    .rad-content-grid-card .rad-button {
        margin-right:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-content-grid-card .rad-button {
        margin-right:2rem
    }
}

@media(min-width: 600px) {
    .rad-content-grid-card--open {
        scale:1.04
    }
}

.rad-content-grid-card--open .rad-content-grid-card__front-toggle {
    height: 352px
}

@media(min-width: 1024px) {
    .rad-content-grid-card--open .rad-content-grid-card__front-toggle {
        height:0
    }
}

.rad-content-grid-card--open .rad-content-grid-card__cta-cover {
    visibility: visible
}

.rad-content-grid-card--open .rad-content-grid-card__close-button {
    translate: 0
}

.rad-content-grid-card--open .rad-content-grid-card__sliding-content {
    left: -100%
}

.rad-content-grid-card--open .rad-content-grid-card__full-image,.rad-content-grid-card--open .rad-content-grid-card__full-video {
    -webkit-filter: blur(75px);
    filter: blur(75px);
    opacity: .5;
    width: 800px
}

.rad-content-grid-card--open .rad-button {
    bottom: 0.75rem;
    right: -100%;
    translate: 0
}

@media(min-width: 1441px) {
    .rad-content-grid-card--open .rad-button {
        bottom:calc(0.75rem + 0.83507vw - 12.0334px)
    }
}

@media(min-width: 1920px) {
    .rad-content-grid-card--open .rad-button {
        bottom:1rem
    }
}

.rad-content-grid-card--announcement {
    background-color: #39005e
}

.rad-content-grid-card--announcement .rad-content-grid-card__quarter-image {
    mix-blend-mode: screen
}

.rad-content-grid-card--announcement .cmp-image__image {
    -webkit-filter: grayscale(1);
    filter: grayscale(1)
}

.rad-content-grid-card--live-interview-landscape,.rad-content-grid-card--live-interview-portrait {
    background-color: #202020
}

.rad-content-grid-card--live-interview-landscape .cmp-image__image,.rad-content-grid-card--live-interview-portrait .cmp-image__image {
    -webkit-filter: grayscale(1);
    filter: grayscale(1)
}

.rad-content-grid-card--video {
    background-color: #202020
}

.rad-content-grid-card--podcast-landscape,.rad-content-grid-card--podcast-portrait {
    background-color: #f1f1ef;
    color: #000
}

.rad-content-grid-card--podcast-landscape .cmp-image__image,.rad-content-grid-card--podcast-portrait .cmp-image__image {
    -webkit-filter: grayscale(1);
    filter: grayscale(1)
}

.rad-content-grid-card--case-study,.rad-content-grid-card--client-stories,.rad-content-grid-card--event,.rad-content-grid-card--perspective {
    background-color: #E4E4E4;
    color: #000
}

.rad-content-grid-card--case-study-dark,.rad-content-grid-card--research-report-dark {
    background-color: #000
}

.rad-content-grid-card--research-report-light {
    background-color: #fff;
    color: #000
}

.rad-breadcrumb {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-size: .75rem;
    font-weight: 500;
    font-weight: 400;
    letter-spacing: -0.005em;
    line-height: 1.5;
    padding: 1.5rem 0;
    text-underline-offset: .25rem
}

.rad-breadcrumb:lang(ja-JP),.rad-breadcrumb:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-breadcrumb {
        font-size:0.875rem
    }
}

@media(min-width: 1441px) {
    .rad-breadcrumb {
        font-size:calc(0.875rem + 0.97425vw - 14.03897px)
    }
}

@media(min-width: 1920px) {
    .rad-breadcrumb {
        font-size:1.1666666667rem
    }
}

.rad-breadcrumb:lang(ja-JP),.rad-breadcrumb:lang(zh-CN) {
    font-weight: 400
}

@media(min-width: 600px) {
    .rad-breadcrumb {
        padding-left:var(--rad-spacing-md);
        padding-right: var(--rad-spacing-md)
    }
}

.rad-component--nested .rad-breadcrumb {
    padding-left: 0;
    padding-right: 0
}

.rad-breadcrumb__item-see {
    display: none
}

@media(max-width: 767px) {
    .rad-breadcrumb__item {
        overflow:hidden
    }

    .rad-breadcrumb__item:not(.rad-breadcrumb__link:last-of-type) {
        display: none
    }

    .rad-breadcrumb__item-see {
        display: inline
    }

    .rad-breadcrumb__item-text {
        text-overflow: ellipsis
    }

    .rad-breadcrumb__link:last-of-type:before {
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        align-items: center;
        content: "";
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        font-family: icont;
        font-size: 80%;
        height: 20px;
        justify-content: center;
        margin-right: 8px;
        width: 20px
    }

    .rad-breadcrumb__separator {
        display: none
    }
}

@media(min-width: 600px) {
    .rad-breadcrumb {
        padding-bottom:3rem;
        padding-top: 3rem
    }
}

@media(min-width: 1441px) {
    .rad-breadcrumb {
        padding-bottom:calc(3rem + 3.34029vw - 48.13361px)
    }
}

@media(min-width: 1920px) {
    .rad-breadcrumb {
        padding-bottom:4rem
    }
}

@media(min-width: 1441px) {
    .rad-breadcrumb {
        padding-top:calc(3rem + 3.34029vw - 48.13361px)
    }
}

@media(min-width: 1920px) {
    .rad-breadcrumb {
        padding-top:4rem
    }
}

.rad-breadcrumb__item {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 2.5rem;
    padding: 0.625rem 0.5rem
}

@media(min-width: 1441px) {
    .rad-breadcrumb__item {
        height:calc(2.5rem + 2.78358vw - 40.11134px)
    }
}

@media(min-width: 1920px) {
    .rad-breadcrumb__item {
        height:3.3333333333rem
    }
}

@media(min-width: 1441px) {
    .rad-breadcrumb__item {
        padding-bottom:calc(0.625rem + 0.69589vw - 10.02784px)
    }
}

@media(min-width: 1920px) {
    .rad-breadcrumb__item {
        padding-bottom:0.8333333333rem
    }
}

@media(min-width: 1441px) {
    .rad-breadcrumb__item {
        padding-left:calc(0.5rem + 0.55672vw - 8.02227px)
    }
}

@media(min-width: 1920px) {
    .rad-breadcrumb__item {
        padding-left:0.6666666667rem
    }
}

@media(min-width: 1441px) {
    .rad-breadcrumb__item {
        padding-right:calc(0.5rem + 0.55672vw - 8.02227px)
    }
}

@media(min-width: 1920px) {
    .rad-breadcrumb__item {
        padding-right:0.6666666667rem
    }
}

@media(min-width: 1441px) {
    .rad-breadcrumb__item {
        padding-top:calc(0.625rem + 0.69589vw - 10.02784px)
    }
}

@media(min-width: 1920px) {
    .rad-breadcrumb__item {
        padding-top:0.8333333333rem
    }
}

.rad-breadcrumb__item:not(.rad-breadcrumb__link) {
    color: #a2a2a0
}

.rad-breadcrumb__item:last-child {
    overflow: hidden
}

.rad-breadcrumb__item-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.rad-breadcrumb__link {
    text-decoration: none
}

.rad-breadcrumb__link,.rad-breadcrumb__link:visited {
    color: #fff
}

.rad-breadcrumb__link:hover {
    color: #dcafff
}

.rad-breadcrumb__link .rad-breadcrumb__item-text {
    position: relative
}

.rad-breadcrumb__link .rad-breadcrumb__item-text:after {
    border-bottom: 1px solid;
    bottom: 0;
    content: "";
    display: block;
    left: 0;
    position: absolute;
    -webkit-transition-duration: 550ms;
    transition-duration: 550ms;
    -webkit-transition-property: width;
    transition-property: width;
    -webkit-transition-timing-function: cubic-bezier(0.85,0,0,1);
    transition-timing-function: cubic-bezier(0.85,0,0,1);
    width: 0
}

:hover>.rad-breadcrumb__item-text:after {
    width: 100%
}

@media(min-width: 1920px) {
    .rad-banner {
        margin-left:calc(-50vw + 960px);
        margin-right: calc(-50vw + 960px)
    }
}

.rad-banner__background-color-container {
    background-color: #101010
}

.rad-banner__alignment-container {
    margin: 0 auto;
    max-width: 120rem;
    padding-left: var(--rad-spacing-sm);
    padding-right: var(--rad-spacing-sm);
    width: 100%
}

@media(min-width: 600px) {
    .rad-banner__alignment-container {
        padding-left:var(--rad-spacing-md);
        padding-right: var(--rad-spacing-md)
    }
}

.rad-component--nested .rad-banner__alignment-container {
    padding-left: 0;
    padding-right: 0
}

.rad-banner__content-container {
    margin: 0 auto;
    max-width: 120rem;
    padding-bottom: 1.5rem;
    padding-left: var(--rad-spacing-sm);
    padding-right: var(--rad-spacing-sm);
    padding-top: 1.5rem
}

@media(min-width: 600px) {
    .rad-banner__content-container {
        padding-left:var(--rad-spacing-md);
        padding-right: var(--rad-spacing-md)
    }
}

.rad-component--nested .rad-banner__content-container {
    padding-left: 0;
    padding-right: 0
}

@media(min-width: 600px) {
    .rad-banner__content-container {
        padding-bottom:3.75rem;
        padding-top: 3.75rem
    }
}

@media(min-width: 1441px) {
    .rad-banner__content-container {
        padding-top:calc(3.75rem + 4.17537vw - 60.16701px)
    }
}

@media(min-width: 1920px) {
    .rad-banner__content-container {
        padding-top:5rem
    }
}

@media(min-width: 1441px) {
    .rad-banner__content-container {
        padding-bottom:calc(3.75rem + 4.17537vw - 60.16701px)
    }
}

@media(min-width: 1920px) {
    .rad-banner__content-container {
        padding-bottom:5rem
    }
}

.rad-banner__headline {
    font-size: 1.75rem;
    font-weight: 500;
    letter-spacing: -0.03em;
    line-height: 1.3
}

.rad-banner__headline:lang(ja-JP),.rad-banner__headline:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-banner__headline {
        font-size:2.5rem;
        line-height: 1.2
    }
}

@media(min-width: 1024px) {
    .rad-banner__headline {
        font-size:3rem;
        line-height: 1.2
    }
}

@media(min-width: 1441px) {
    .rad-banner__headline {
        font-size:calc(3rem + 3.34029vw - 48.13361px)
    }
}

@media(min-width: 1920px) {
    .rad-banner__headline {
        font-size:4rem
    }
}

@media(min-width: 600px) {
    .rad-banner__headline {
        width:calc(150% - var(--rad-spacing-sm)*3/4*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-sm)*0);
        width: calc(75% - var(--rad-spacing-sm)*7/8*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-banner__headline {
        width:calc(50% - var(--rad-spacing-sm)*11/12*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0);
        width: calc(200% - var(--rad-spacing-sm)*3/4*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-sm)*0);
        width: calc(100% - var(--rad-spacing-sm)*7/8*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0);
        width: calc(66.66667% - var(--rad-spacing-sm)*11/12*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0)
    }
}

.rad-banner.rad-banner--headline-large .rad-banner__headline {
    font-size: 2rem;
    font-weight: 500;
    letter-spacing: -0.03em;
    line-height: 1.25
}

.rad-banner.rad-banner--headline-large .rad-banner__headline:lang(ja-JP),.rad-banner.rad-banner--headline-large .rad-banner__headline:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-banner.rad-banner--headline-large .rad-banner__headline {
        font-size:3.75rem;
        line-height: 1.15
    }
}

@media(min-width: 1441px) {
    .rad-banner.rad-banner--headline-large .rad-banner__headline {
        font-size:calc(3.75rem + 4.17537vw - 60.16701px)
    }
}

@media(min-width: 1920px) {
    .rad-banner.rad-banner--headline-large .rad-banner__headline {
        font-size:5rem
    }
}

.rad-banner__subheader {
    font-family: GT Sectra Fine,Palatino;
    font-size: 1.25rem;
    font-weight: 300;
    line-height: 1.25;
    margin-top: .5rem
}

@media(min-width: 600px) {
    .rad-banner__subheader {
        font-size:1.5rem;
        line-height: 1.25
    }
}

@media(min-width: 1024px) {
    .rad-banner__subheader {
        font-size:1.75rem;
        line-height: 1.25
    }
}

@media(min-width: 1441px) {
    .rad-banner__subheader {
        font-size:calc(1.75rem + 1.9485vw - 28.07794px)
    }
}

@media(min-width: 1920px) {
    .rad-banner__subheader {
        font-size:2.3333333333rem
    }
}

@media(min-width: 600px) {
    .rad-banner__subheader {
        margin-top:.5rem;
        width: calc(150% - var(--rad-spacing-sm)*3/4*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-sm)*0);
        width: calc(75% - var(--rad-spacing-sm)*7/8*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-banner__subheader {
        margin-top:0.5rem;
        width: calc(50% - var(--rad-spacing-sm)*11/12*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0);
        width: calc(200% - var(--rad-spacing-sm)*3/4*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-sm)*0);
        width: calc(100% - var(--rad-spacing-sm)*7/8*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0);
        width: calc(66.66667% - var(--rad-spacing-sm)*11/12*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1441px) {
    .rad-banner__subheader {
        margin-top:calc(0.5rem + 0.55672vw - 8.02227px)
    }
}

@media(min-width: 1920px) {
    .rad-banner__subheader {
        margin-top:0.6666666667rem
    }
}

.rad-banner__body {
    font-size: .875rem;
    font-weight: 500;
    font-weight: 400;
    letter-spacing: -0.005em;
    line-height: 1.5;
    margin-top: 1rem;
    text-underline-offset: .25rem
}

.rad-banner__body:lang(ja-JP),.rad-banner__body:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-banner__body {
        font-size:1rem
    }
}

@media(min-width: 1441px) {
    .rad-banner__body {
        font-size:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-banner__body {
        font-size:1.3333333333rem
    }
}

.rad-banner__body:lang(ja-JP),.rad-banner__body:lang(zh-CN) {
    font-weight: 400
}

@media(min-width: 600px) {
    .rad-banner__body {
        margin-top:1rem;
        width: calc(150% - var(--rad-spacing-sm)*3/4*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-sm)*0);
        width: calc(75% - var(--rad-spacing-sm)*7/8*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-banner__body {
        margin-top:1rem;
        width: calc(50% - var(--rad-spacing-sm)*11/12*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0);
        width: calc(175% - var(--rad-spacing-sm)*3/4*7 + var(--rad-spacing-sm)*6 + var(--rad-spacing-sm)*0);
        width: calc(87.5% - var(--rad-spacing-sm)*7/8*7 + var(--rad-spacing-sm)*6 + var(--rad-spacing-md)*0);
        width: calc(58.33333% - var(--rad-spacing-sm)*11/12*7 + var(--rad-spacing-sm)*6 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1441px) {
    .rad-banner__body {
        margin-top:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-banner__body {
        margin-top:1.3333333333rem
    }
}

.rad-banner__buttons {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-top: 1.5rem;
    row-gap: 1rem;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content
}

@media(min-width: 600px) {
    .rad-banner__buttons {
        -webkit-box-orient:horizontal;
        -webkit-box-direction: normal;
        -webkit-column-gap: 1.5rem;
        -moz-column-gap: 1.5rem;
        column-gap: 1.5rem;
        -ms-flex-direction: row;
        flex-direction: row;
        margin-top: 2rem
    }
}

@media(min-width: 1024px) {
    .rad-banner__buttons {
        -webkit-column-gap:2rem;
        -moz-column-gap: 2rem;
        column-gap: 2rem;
        margin-top: 2rem
    }
}

@media(min-width: 1441px) {
    .rad-banner__buttons {
        -webkit-column-gap:calc(2rem + 2.22686vw - 32.08907px);
        -moz-column-gap: calc(2rem + 2.22686vw - 32.08907px);
        column-gap: calc(2rem + 2.22686vw - 32.08907px)
    }
}

@media(min-width: 1920px) {
    .rad-banner__buttons {
        -webkit-column-gap:2.6666666667rem;
        -moz-column-gap: 2.6666666667rem;
        column-gap: 2.6666666667rem
    }
}

@media(min-width: 1441px) {
    .rad-banner__buttons {
        margin-top:calc(2rem + 2.22686vw - 32.08907px)
    }
}

@media(min-width: 1920px) {
    .rad-banner__buttons {
        margin-top:2.6666666667rem
    }
}

.rad-banner__buttons .rad-button {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
    width: 100%
}

@media(min-width: 600px) {
    .rad-banner__buttons .rad-button {
        width:auto
    }
}

.rad-banner .rad-button--primary {
    padding: 0 1.25rem
}

@media(min-width: 600px) {
    .rad-banner .rad-button--primary {
        padding-left:1.5rem;
        padding-right: 1.5rem
    }
}

@media(min-width: 1441px) {
    .rad-banner .rad-button--primary {
        padding-right:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-banner .rad-button--primary {
        padding-right:2rem
    }
}

@media(min-width: 1441px) {
    .rad-banner .rad-button--primary {
        padding-left:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-banner .rad-button--primary {
        padding-left:2rem
    }
}

.rad-banner .rad-button--secondary {
    padding: 0 1.1875rem
}

@media(min-width: 600px) {
    .rad-banner .rad-button--secondary {
        padding-left:1.4375rem;
        padding-right: 1.4375rem
    }
}

@media(min-width: 1441px) {
    .rad-banner .rad-button--secondary {
        padding-right:calc(1.4375rem + 1.60056vw - 23.06402px)
    }
}

@media(min-width: 1920px) {
    .rad-banner .rad-button--secondary {
        padding-right:1.9166666667rem
    }
}

@media(min-width: 1441px) {
    .rad-banner .rad-button--secondary {
        padding-left:calc(1.4375rem + 1.60056vw - 23.06402px)
    }
}

@media(min-width: 1920px) {
    .rad-banner .rad-button--secondary {
        padding-left:1.9166666667rem
    }
}

.rad-banner.rad-banner--centered {
    text-align: center
}

.rad-banner.rad-banner--centered .rad-banner__body,.rad-banner.rad-banner--centered .rad-banner__buttons,.rad-banner.rad-banner--centered .rad-banner__headline,.rad-banner.rad-banner--centered .rad-banner__subheader {
    margin-left: auto;
    margin-right: auto
}

@media(min-width: 1024px) {
    .rad-banner.rad-banner--centered .rad-banner__body {
        width:calc(150% - var(--rad-spacing-sm)*3/4*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-sm)*0);
        width: calc(75% - var(--rad-spacing-sm)*7/8*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0);
        width: calc(50% - var(--rad-spacing-sm)*11/12*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0)
    }
}

.rad-banner.rad-banner--centered .rad-banner__buttons {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    align-items: center;
    justify-content: center
}


@media(min-width: 1441px) {
    .rad-banner.rad-banner--background-image .rad-banner__content-container {
        background-size:100% auto
    }
}

.rad-banner__video-media-variation .rad-banner__background-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    background-color: rgba(0,0,0,.6);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    justify-content: center;
    min-height: 344px;
    padding-bottom: 56px;
    padding-top: 56px;
    position: relative;
    z-index: 100
}

@media(min-width: 600px) {
    .rad-banner__video-media-variation .rad-banner__background-container {
        min-height:390px;
        padding-bottom: 3.75rem;
        padding-top: 3.75rem
    }
}

@media(min-width: 1441px) {
    .rad-banner__video-media-variation .rad-banner__background-container {
        padding-top:calc(3.75rem + 4.17537vw - 60.16701px)
    }
}

@media(min-width: 1920px) {
    .rad-banner__video-media-variation .rad-banner__background-container {
        padding-top:5rem
    }
}

@media(min-width: 1441px) {
    .rad-banner__video-media-variation .rad-banner__background-container {
        padding-bottom:calc(3.75rem + 4.17537vw - 60.16701px)
    }
}

@media(min-width: 1920px) {
    .rad-banner__video-media-variation .rad-banner__background-container {
        padding-bottom:5rem
    }
}

@media(min-width: 1024px) {
    .rad-banner__video-media-variation .rad-banner__background-container {
        min-height:396px
    }
}

.rad-banner__video-media-variation .rad-banner__text-content {
    position: relative
}

.rad-banner__video-media-variation .rad-banner__headline {
    font-size: 1.5rem;
    font-weight: 500;
    letter-spacing: -0.03em;
    line-height: 1.35
}

.rad-banner__video-media-variation .rad-banner__headline:lang(ja-JP),.rad-banner__video-media-variation .rad-banner__headline:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-banner__video-media-variation .rad-banner__headline {
        font-size:2rem;
        line-height: 1.2
    }
}

@media(min-width: 1024px) {
    .rad-banner__video-media-variation .rad-banner__headline {
        font-size:2.5rem;
        line-height: 1.2
    }
}

@media(min-width: 1441px) {
    .rad-banner__video-media-variation .rad-banner__headline {
        font-size:calc(2.5rem + 2.78358vw - 40.11134px)
    }
}

@media(min-width: 1920px) {
    .rad-banner__video-media-variation .rad-banner__headline {
        font-size:3.3333333333rem
    }
}

.rad-banner__video-media-variation .rad-banner__background-media {
    height: 100%;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0
}

.rad-banner__video-media-variation .rad-banner__background-media .dynamicmedia,.rad-banner__video-media-variation .rad-banner__background-media .radimage {
    left: 0;
    position: absolute;
    top: 0
}

.rad-banner__video-media-variation .rad-banner__background-media div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    pointer-events: none;
    width: 100%
}

.rad-banner__video-media-variation .rad-banner__background-media img,.rad-banner__video-media-variation .rad-banner__background-media video {
    -webkit-filter: blur(8px);
    filter: blur(8px);
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%
}

.rad-banner__video-media-variation .rad-media-overlay {
    background: none;
    bottom: 8px;
    height: auto;
    margin-left: auto;
    position: absolute;
    right: 8px;
    width: auto
}

@media(min-width: 600px) {
    .rad-banner__video-media-variation .rad-media-overlay {
        bottom:16px;
        right: 16px
    }
}

@media(min-width: 1024px) {
    .rad-banner__video-media-variation .rad-media-overlay {
        bottom:32px;
        right: 32px
    }
}

.rad-banner__video-media-variation .rad-media-overlay__toggle {
    background-color: inherit;
    bottom: 0;
    left: auto;
    right: 0
}

.rad-banner__video-media-variation .rad-media-overlay__toggle:hover {
    color: #a2a2a0
}

.rad-banner__video-media-variation .rad-media-overlay--hide {
    display: none
}

.rad-banner__image-media-variation .rad-banner__background-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    background-color: rgba(0,0,0,.6);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    justify-content: center;
    min-height: 344px;
    padding-bottom: 56px;
    padding-top: 56px;
    position: relative;
    z-index: 100
}

@media(min-width: 600px) {
    .rad-banner__image-media-variation .rad-banner__background-container {
        min-height:390px;
        padding-bottom: 3.75rem;
        padding-top: 3.75rem
    }
}

@media(min-width: 1441px) {
    .rad-banner__image-media-variation .rad-banner__background-container {
        padding-top:calc(3.75rem + 4.17537vw - 60.16701px)
    }
}

@media(min-width: 1920px) {
    .rad-banner__image-media-variation .rad-banner__background-container {
        padding-top:5rem
    }
}

@media(min-width: 1441px) {
    .rad-banner__image-media-variation .rad-banner__background-container {
        padding-bottom:calc(3.75rem + 4.17537vw - 60.16701px)
    }
}

@media(min-width: 1920px) {
    .rad-banner__image-media-variation .rad-banner__background-container {
        padding-bottom:5rem
    }
}

@media(min-width: 1024px) {
    .rad-banner__image-media-variation .rad-banner__background-container {
        min-height:396px
    }
}

.rad-banner__image-media-variation .rad-banner__text-content {
    position: relative
}

.rad-banner__image-media-variation .rad-banner__headline {
    font-size: 1.5rem;
    font-weight: 500;
    letter-spacing: -0.03em;
    line-height: 1.35
}

.rad-banner__image-media-variation .rad-banner__headline:lang(ja-JP),.rad-banner__image-media-variation .rad-banner__headline:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-banner__image-media-variation .rad-banner__headline {
        font-size:2rem;
        line-height: 1.2
    }
}

@media(min-width: 1024px) {
    .rad-banner__image-media-variation .rad-banner__headline {
        font-size:2.5rem;
        line-height: 1.2
    }
}

@media(min-width: 1441px) {
    .rad-banner__image-media-variation .rad-banner__headline {
        font-size:calc(2.5rem + 2.78358vw - 40.11134px)
    }
}

@media(min-width: 1920px) {
    .rad-banner__image-media-variation .rad-banner__headline {
        font-size:3.3333333333rem
    }
}

.rad-banner__image-media-variation .rad-banner__background-media {
    height: 100%;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0
}

.rad-banner__image-media-variation .rad-banner__background-media .dynamicmedia,.rad-banner__image-media-variation .rad-banner__background-media .radimage {
    left: 0;
    top: 0
}

.rad-banner__image-media-variation .rad-banner__background-media div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    width: 100%
}

.rad-banner__image-media-variation .rad-banner__background-media img,.rad-banner__image-media-variation .rad-banner__background-media video {
    -webkit-filter: blur(8px);
    filter: blur(8px);
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%
}



@media(min-width: 1441px) {
    .rad-banner.rad-banner--background-image.rad-banner--centered .rad-banner__background-color-container {
        background-size:152% auto
    }
}

@media(min-width: 1920px) {
    .rad-banner.rad-banner--background-image.rad-banner--centered .rad-banner__background-color-container {
        background-size:auto 924px
    }
}

@media(min-width: 1024px) {
    .rad-banner.rad-banner--background-image.rad-banner--centered .rad-banner__content-container {
        background-image:none
    }
}

.rad-banner.rad-banner--no-background .rad-banner__background-color-container {
    background: none
}

@media(min-width: 600px)and (max-width:1023px) {
    .banners:not(.rad-component-spacing-top-none,.rad-component-spacing-top-small,.rad-component-spacing-top-medium,.rad-component-spacing-top-large) .rad-banner.rad-spacing-vertical-md {
        padding-top:2.5rem
    }

    .banners:not(.rad-component-spacing-bottom-none,.rad-component-spacing-bottom-small,.rad-component-spacing-bottom-medium,.rad-component-spacing-bottom-large) .rad-banner.rad-spacing-vertical-md {
        padding-bottom: 2.5rem
    }
}

.rad-carousel-block {
    padding-left: var(--rad-spacing-sm);
    padding-right: var(--rad-spacing-sm)
}

@media(min-width: 600px) {
    .rad-carousel-block {
        padding-left:var(--rad-spacing-md);
        padding-right: var(--rad-spacing-md)
    }
}

.rad-component--nested .rad-carousel-block {
    padding-left: 0;
    padding-right: 0
}

.rad-carousel-block__image {
    display: block;
    margin-bottom: 24px;
    margin-left: calc(var(--rad-spacing-sm)*-1);
    margin-right: calc(var(--rad-spacing-sm)*-1);
    max-width: none;
    width: calc(100vw - var(--scrollbar-width))
}

.rad-carousel-block__title {
    font-size: 1.125rem;
    font-weight: 500;
    letter-spacing: -0.02em;
    line-height: 1.4;
    margin-bottom: .5rem
}

.rad-carousel-block__title:lang(ja-JP),.rad-carousel-block__title:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-carousel-block__title {
        font-size:1.25rem;
        line-height: 1.4
    }
}

@media(min-width: 1024px) {
    .rad-carousel-block__title {
        font-size:1.5rem;
        line-height: 1.3
    }
}

@media(min-width: 1441px) {
    .rad-carousel-block__title {
        font-size:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-carousel-block__title {
        font-size:2rem
    }
}

.rad-carousel-block__body {
    font-size: .875rem;
    font-weight: 500;
    font-weight: 400;
    letter-spacing: -0.005em;
    line-height: 1.5;
    margin-bottom: .25rem;
    text-underline-offset: .25rem
}

.rad-carousel-block__body:lang(ja-JP),.rad-carousel-block__body:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-carousel-block__body {
        font-size:1rem
    }
}

@media(min-width: 1441px) {
    .rad-carousel-block__body {
        font-size:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-carousel-block__body {
        font-size:1.3333333333rem
    }
}

.rad-carousel-block__body:lang(ja-JP),.rad-carousel-block__body:lang(zh-CN) {
    font-weight: 400
}

@media(min-width: 600px) {
    .rad-carousel-block {
        -webkit-box-align:end;
        -ms-flex-align: end;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        align-items: flex-end;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: row;
        flex-direction: row;
        justify-content: space-between
    }

    .rad-carousel-block__image {
        margin: 0;
        margin-left: calc(var(--rad-spacing-md)*-1);
        width: calc(100% - var(--rad-spacing-sm)*3/4*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-sm)*1);
        width: calc(50% - var(--rad-spacing-sm)*7/8*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*1)
    }
}

@media(min-width: 1024px) {
    .rad-carousel-block__image {
        width:calc(33.33333% - var(--rad-spacing-sm)*11/12*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*1)
    }
}

@media(min-width: 600px) {
    .rad-carousel-block__text-container {
        width:calc(75% - var(--rad-spacing-sm)*3/4*3 + var(--rad-spacing-sm)*2 + var(--rad-spacing-sm)*0);
        width: calc(37.5% - var(--rad-spacing-sm)*7/8*3 + var(--rad-spacing-sm)*2 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-carousel-block__text-container {
        width:calc(25% - var(--rad-spacing-sm)*11/12*3 + var(--rad-spacing-sm)*2 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 600px) {
    .rad-carousel-block__title {
        margin-bottom:.75rem
    }
}

@media(min-width: 1024px) {
    .rad-carousel-block__image {
        width:calc(150% - var(--rad-spacing-sm)*3/4*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-sm)*1);
        width: calc(75% - var(--rad-spacing-sm)*7/8*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*1);
        width: calc(50% - var(--rad-spacing-sm)*11/12*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*1)
    }

    .rad-carousel-block__text-container {
        width: calc(100% - var(--rad-spacing-sm)*3/4*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-sm)*0);
        width: calc(50% - var(--rad-spacing-sm)*7/8*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0);
        width: calc(33.33333% - var(--rad-spacing-sm)*11/12*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0)
    }

    .rad-carousel-block__title {
        margin-bottom: 1rem
    }
}

@media(min-width: 1441px) {
    .rad-carousel-block__title {
        margin-bottom:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-carousel-block__title {
        margin-bottom:1.3333333333rem
    }
}

@media(min-width: 1024px) {
    .rad-carousel-block__body {
        margin-bottom:0.75rem
    }
}

@media(min-width: 1441px) {
    .rad-carousel-block__body {
        margin-bottom:calc(0.75rem + 0.83507vw - 12.0334px)
    }
}

@media(min-width: 1920px) {
    .rad-carousel-block__body {
        margin-bottom:1rem
    }
}

.rad-footer {
    background: #000;
    color: #fff;
    margin: 0 auto;
    max-width: 120rem;
    overflow: hidden;
    padding: 0;
    position: relative
}

@media(min-width: 600px) {
    .rad-footer {
        --lottie-top:60;
        --lottie-top: 9.25rem;
        padding: 0
    }
}

@media(min-width: 1441px) {
    .rad-footer {
        --lottie-top:calc(9.25rem + 10.29923vw - 148.41197px)
    }
}

@media(min-width: 1920px) {
    .rad-footer {
        --lottie-top:12.3333333333rem
    }
}

.rad-footer__main {
    padding: 1.5rem 1rem
}

@media(min-width: 600px) {
    .rad-footer__main {
        -webkit-box-orient:vertical;
        -webkit-box-direction: normal;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
        justify-content: space-between;
        min-height: 100svh;
        padding: 3.75rem 3rem 3rem;
        position: relative;
        z-index: 1
    }
}

@media(min-width: 1024px) {
    .rad-footer__main {
        margin:0 auto;
        max-width: 120rem;
        padding-bottom: 4rem;
        padding-left: 5rem;
        padding-right: 5rem;
        padding-top: var(--lottie-top)
    }
}

@media(min-width: 1441px) {
    .rad-footer__main {
        padding-right:calc(5rem + 5.56715vw - 80.22269px)
    }
}

@media(min-width: 1920px) {
    .rad-footer__main {
        padding-right:6.6666666667rem
    }
}

@media(min-width: 1441px) {
    .rad-footer__main {
        padding-bottom:calc(4rem + 4.45372vw - 64.17815px)
    }
}

@media(min-width: 1920px) {
    .rad-footer__main {
        padding-bottom:5.3333333333rem
    }
}

@media(min-width: 1441px) {
    .rad-footer__main {
        padding-left:calc(5rem + 5.56715vw - 80.22269px)
    }
}

@media(min-width: 1920px) {
    .rad-footer__main {
        padding-left:6.6666666667rem
    }
}

@media(min-width: 600px) {
    .rad-footer__copyright,.rad-footer__links-container,.rad-footer__title {
        width:calc(50% - 0.75rem)
    }
}

.rad-footer__title {
    color: #fff;
    font-size: 1.375rem;
    font-weight: 500;
    letter-spacing: -0.02em;
    line-height: 1.25;
    margin-bottom: 2rem
}

.rad-footer__title:lang(ja-JP),.rad-footer__title:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-footer__title {
        font-size:1.75rem;
        line-height: 1.3
    }
}

@media(min-width: 1024px) {
    .rad-footer__title {
        font-size:2rem;
        line-height: 1.2
    }
}

@media(min-width: 1441px) {
    .rad-footer__title {
        font-size:calc(2rem + 2.22686vw - 32.08907px)
    }
}

@media(min-width: 1920px) {
    .rad-footer__title {
        font-size:2.6666666667rem
    }
}

@media(min-width: 600px) {
    .rad-footer__title {
        margin-bottom:3rem
    }
}

@media(min-width: 1024px) {
    .rad-footer__title {
        margin-bottom:5rem;
        width: 25.3125rem
    }
}

@media(min-width: 1441px) {
    .rad-footer__title {
        margin-bottom:calc(5rem + 5.56715vw - 80.22269px)
    }
}

@media(min-width: 1920px) {
    .rad-footer__title {
        margin-bottom:6.6666666667rem
    }
}

.rad-footer__links-container {
    margin-bottom: 1rem
}

@media(min-width: 600px) {
    .rad-footer__links-container {
        margin-bottom:1.5rem
    }
}

@media(min-width: 1024px) {
    .rad-footer__links-container {
        display:-webkit-box;
        display: -ms-flexbox;
        display: flex;
        margin-bottom: 2rem
    }
}

@media(min-width: 1441px) {
    .rad-footer__links-container {
        margin-bottom:calc(2rem + 2.22686vw - 32.08907px)
    }
}

@media(min-width: 1920px) {
    .rad-footer__links-container {
        margin-bottom:2.6666666667rem
    }
}

.rad-footer__links-column {
    list-style: none;
    margin: 0;
    padding-left: 0
}

@media(min-width: 1024px) {
    .rad-footer__links-column {
        width:11.625rem
    }
}

@media(min-width: 1441px) {
    .rad-footer__links-column {
        width:calc(11.625rem + 12.94363vw - 186.51775px)
    }
}

@media(min-width: 1920px) {
    .rad-footer__links-column {
        width:15.5rem
    }
}

@media(min-width: 1024px) {
    .rad-footer__links-column:first-child {
        margin-right:2rem
    }
}

@media(min-width: 1441px) {
    .rad-footer__links-column:first-child {
        margin-right:calc(2rem + 2.22686vw - 32.08907px)
    }
}

@media(min-width: 1920px) {
    .rad-footer__links-column:first-child {
        margin-right:2.6666666667rem
    }
}

@media(min-width: 1024px) {
    .rad-footer__link-item:last-child .rad-footer__link {
        margin-bottom:0
    }
}

.rad-footer__link {
    color: #fff;
    display: block;
    font-size: .875rem;
    font-weight: 500;
    font-weight: 400;
    letter-spacing: -0.005em;
    line-height: 1.5;
    margin-bottom: 1rem;
    position: relative;
    text-decoration: none;
    text-underline-offset: .25rem;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content
}

.rad-footer__link:lang(ja-JP),.rad-footer__link:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-footer__link {
        font-size:1rem
    }
}

@media(min-width: 1441px) {
    .rad-footer__link {
        font-size:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-footer__link {
        font-size:1.3333333333rem
    }
}

.rad-footer__link:lang(ja-JP),.rad-footer__link:lang(zh-CN) {
    font-weight: 400
}

@media(min-width: 1441px) {
    .rad-footer__link {
        margin-bottom:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-footer__link {
        margin-bottom:1.3333333333rem
    }
}

.rad-footer__link:after {
    border-bottom: 1px solid;
    bottom: 0;
    content: "";
    display: block;
    left: 0;
    position: absolute;
    -webkit-transition-duration: 550ms;
    transition-duration: 550ms;
    -webkit-transition-property: width;
    transition-property: width;
    -webkit-transition-timing-function: cubic-bezier(0.85,0,0,1);
    transition-timing-function: cubic-bezier(0.85,0,0,1);
    width: 0
}

.rad-footer__link:hover:after {
    width: 100%
}

.rad-footer__link:hover {
    color: #a2a2a0
}

.rad-footer__copyright {
    font-size: .75rem;
    font-weight: 500;
    font-weight: 400;
    letter-spacing: -0.005em;
    line-height: 1.5;
    text-underline-offset: .25rem
}

.rad-footer__copyright:lang(ja-JP),.rad-footer__copyright:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-footer__copyright {
        font-size:0.875rem
    }
}

@media(min-width: 1441px) {
    .rad-footer__copyright {
        font-size:calc(0.875rem + 0.97425vw - 14.03897px)
    }
}

@media(min-width: 1920px) {
    .rad-footer__copyright {
        font-size:1.1666666667rem
    }
}

.rad-footer__copyright:lang(ja-JP),.rad-footer__copyright:lang(zh-CN) {
    font-weight: 400
}

.rad-footer__lottie-positioner {
    overflow: hidden
}

@media(min-width: 600px) {
    .rad-footer__lottie-positioner {
        bottom:0;
        max-width: calc(50% - 0.375rem);
        position: absolute;
        right: 0;
        top: var(--lottie-top)
    }
}

@media(min-width: 1024px) {
    .rad-footer__lottie-positioner {
        max-width:calc(50% - 0.5rem)
    }
}

.rad-footer__lottie-centerer {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    justify-content: center;
    max-width: 100%;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content
}

@media(min-width: 600px) {
    .rad-footer__lottie-ratio-maintainer {
        min-width:999vw
    }
}

.cmp-global-header {
    background: #000;
    color: #fff;
    padding-top: var(--rad-global-header-height)
}

.cmp-global-header nav {
    background: #000;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1001
}

.cmp-global-header.cmp-global-header--menu-open .cmp-global-header__content {
    bottom: 0;
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    visibility: visible
}

.cmp-global-header.cmp-global-header--menu-open .cmp-global-header__menu-button {
    border: none
}

.cmp-global-header.cmp-global-header--menu-open .cmp-global-header__menu-button div {
    width: 14px
}

.cmp-global-header.cmp-global-header--menu-open .cmp-global-header__menu-button span {
    -webkit-transform-origin: left center;
    transform-origin: left center;
    width: 18px
}

.cmp-global-header.cmp-global-header--menu-open .cmp-global-header__menu-button span:first-child {
    top: -4px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg)
}

.cmp-global-header.cmp-global-header--menu-open .cmp-global-header__menu-button span:nth-child(2) {
    opacity: 0;
    width: 0%
}

.cmp-global-header.cmp-global-header--menu-open .cmp-global-header__menu-button span:nth-child(3) {
    top: 9px;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg)
}

.cmp-global-header.cmp-global-header--menu-open .cmp-global-header__logo {
    display: none
}

@media(min-width: 600px) {
    .cmp-global-header.cmp-global-header--menu-open .cmp-global-header__content {
        -webkit-transform:translateX(0);
        transform: translateX(0)
    }

    .cmp-global-header.cmp-global-header--menu-open .cmp-global-header__logo {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex
    }

    .cmp-global-header.cmp-global-header--menu-open .cmp-global-header__menu-overlay {
        opacity: .6;
        -webkit-transform: none;
        transform: none;
        z-index: 999
    }
}

.cmp-global-header__navbar-container {
    -webkit-animation-duration: 550ms;
    animation-duration: 550ms;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-name: enterFromTop;
    animation-name: enterFromTop;
    -webkit-animation-timing-function: cubic-bezier(0.85,0,0,1);
    animation-timing-function: cubic-bezier(0.85,0,0,1);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: var(--rad-global-header-height)
}

@-webkit-keyframes enterFromTop {
    0% {
        -webkit-transform: translateY(-var(--rad-global-header-height));
        transform:translateY(-var(--rad-global-header-height))}
}

@keyframes enterFromTop {
    0% {
        -webkit-transform: translateY(-var(--rad-global-header-height));
        transform:translateY(-var(--rad-global-header-height))}
}

@media(min-width: 600px) {
    .cmp-global-header__navbar-container {
        background-color:#000;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        z-index: 1
    }
}

@media(min-width: 1201px) {
    .cmp-global-header__navbar-container {
        -webkit-box-pack:justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        margin: 0 auto;
        max-width: 1920px
    }
}

.cmp-global-header__primary-nav {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 66%
}

@media(min-width: 1201px) {
    .cmp-global-header__primary-nav {
        -webkit-box-pack:justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        width: 75vw
    }
}

@media(min-width: 1920px) {
    .cmp-global-header__primary-nav {
        width:1440px
    }
}

.cmp-global-header__utility-nav {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: flex-end;
    width: 34%
}

@media(min-width: 1201px) {
    .cmp-global-header__utility-nav {
        width:25vw
    }
}

@media(min-width: 1441px) {
    .cmp-global-header__utility-nav {
        width:25%
    }
}

@media(min-width: 1920px) {
    .cmp-global-header__utility-nav {
        width:480px
    }
}

.cmp-global-header__search {
    z-index: 2
}

.cmp-global-header__search a {
    color: #fff;
    height: 48px;
    width: 48px
}

.cmp-global-header__search a,.cmp-global-header__search a:after {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    text-decoration: none
}

.cmp-global-header__search a:after {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-font-smoothing: antialiased;
    align-items: center;
    content: "";
    font-family: icont;
    font-size: 1.5rem;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400
}

@media(min-width: 1441px) {
    .cmp-global-header__search a:after {
        font-size:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .cmp-global-header__search a:after {
        font-size:2rem
    }
}

.cmp-global-header__search a:focus {
    outline-offset: -1px
}

@media(min-width: 600px) {
    .cmp-global-header__search a {
        -webkit-box-pack:right;
        -ms-flex-pack: right;
        height: var(--rad-global-header-height);
        justify-content: right
    }
}

.cmp-global-header__menu {
    z-index: 2
}

@media(min-width: 1201px) {
    .cmp-global-header__menu {
        display:none
    }
}

.cmp-global-header__menu-button {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    align-items: center;
    background: transparent;
    border: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: var(--rad-global-header-height);
    justify-content: flex-start;
    padding: 0;
    width: 5rem
}

.cmp-global-header__menu-button div {
    height: 16px;
    left: 16px;
    position: relative;
    width: 14px
}

@media(min-width: 600px) {
    .cmp-global-header__menu-button div {
        left:48px
    }
}

@media(min-width: 1024px) {
    .cmp-global-header__menu-button div {
        left:80px
    }
}

.cmp-global-header__menu-button span {
    background: #fff;
    display: block;
    height: 2px;
    left: 0;
    opacity: 1;
    position: absolute;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: -webkit-transform .25s ease-in-out;
    transition: -webkit-transform .25s ease-in-out;
    transition: transform .25s ease-in-out;
    transition: transform .25s ease-in-out,-webkit-transform .25s ease-in-out;
    width: 100%
}

.cmp-global-header__menu-button span:first-child {
    top: 0
}

.cmp-global-header__menu-button span:nth-child(2) {
    top: 6px;
    width: calc(100% + 4px)
}

.cmp-global-header__menu-button span:nth-child(3) {
    top: 12px
}

.cmp-global-header__logo {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    width: 100%;
    z-index: 1
}

.cmp-global-header__logo .cmp-logo {
    display: block
}

.cmp-global-header__logo .cmp-logo__svg-wrapper svg {
    height: 24px
}

@media(min-width: 600px) {
    .cmp-global-header__logo .cmp-logo__svg-wrapper svg {
        height:32px
    }
}

@media(min-width: 1201px) {
    .cmp-global-header__logo .cmp-logo__svg-wrapper svg {
        height:auto
    }
}

@media(max-width: 599px) {
    .cmp-global-header__logo .cmp-logo .cmp-logo__greater-than {
        -webkit-transform:translateX(57px);
        transform: translateX(57px)
    }

    .cmp-global-header__logo .cmp-logo .cmp-logo__text {
        display: none;
        -webkit-transform: none;
        transform: none;
        -webkit-transition: none;
        transition: none;
        translate: none
    }

    .cmp-global-header__logo .cmp-logo:hover .cmp-logo__greater-than,.cmp-global-header__logo .cmp-logo:hover .cmp-logo__text {
        scale: none;
        -webkit-transform: none;
        transform: none;
        -webkit-transition: none;
        transition: none;
        translate: none
    }

    .cmp-global-header__logo .cmp-logo:hover .cmp-logo__greater-than {
        -webkit-transform: translateX(57px);
        transform: translateX(57px)
    }
}

@media(min-width: 600px) {
    .cmp-global-header__logo {
        display:-webkit-box;
        display: -ms-flexbox;
        display: flex
    }
}

@media(min-width: 1201px) {
    .cmp-global-header__logo {
        -webkit-box-pack:start;
        -ms-flex-pack: start;
        display: block;
        justify-content: flex-start;
        margin: 0;
        max-width: 168px;
        padding-left: 80px;
        position: relative;
        right: 0;
        width: 25vw;
        z-index: 1
    }
}

@media(min-width: 1441px) {
    .cmp-global-header__logo {
        max-width:unset;
        padding-left: 80px;
        padding-left: calc(-0.22269px + 5.56715vw)
    }
}

@media(min-width: 1920px) {
    .cmp-global-header__logo {
        padding-left:106.6666666667px;
        width: 480px
    }
}

.cmp-global-header__content {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    background: #202020;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    height: calc(100vh - var(--rad-global-header-height));
    left: 0;
    margin-top: var(--rad-global-header-height);
    opacity: 0;
    overflow-x: hidden;
    overflow-y: auto;
    position: fixed;
    scrollbar-color: #a100ff #460073;
    scrollbar-width: thin;
    top: 0;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
    -webkit-transition: .45s ease-in-out;
    transition: .45s ease-in-out;
    visibility: hidden;
    width: 100%
}

.cmp-global-header__content::-webkit-scrollbar {
    height: 4px;
    width: 4px
}

.cmp-global-header__content::-webkit-scrollbar-track {
    background-color: #460073
}

.cmp-global-header__content::-webkit-scrollbar-thumb {
    background-color: #a100ff
}

@media(min-width: 600px) {
    .cmp-global-header__content {
        -webkit-transform:translateX(-105%);
        transform: translateX(-105%);
        -webkit-transition: .2s ease-in-out;
        transition: .2s ease-in-out;
        width: 320px
    }
}

@media(min-width: 1201px) {
    .cmp-global-header__content {
        background-color:#000;
        height: auto;
        margin-top: 0;
        opacity: 1;
        overflow: visible;
        position: static;
        top: auto;
        -webkit-transform: none;
        transform: none;
        -webkit-transition-property: opacity;
        transition-property: opacity;
        visibility: visible;
        width: 50vw
    }
}

@media(min-width: 1920px) {
    .cmp-global-header__content {
        width:960px
    }
}

.cmp-global-header__nav-menu {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    list-style-type: none;
    margin: 0;
    padding: 24px 16px
}

@media(min-width: 600px) {
    .cmp-global-header__nav-menu {
        padding:48px
    }
}

@media(min-width: 1201px) {
    .cmp-global-header__nav-menu {
        -webkit-box-flex:1;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex: 1;
        flex: 1;
        gap: 40px;
        justify-content: center;
        padding: 0
    }
}

@media(min-width: 1441px) {
    .cmp-global-header__nav-menu {
        gap:40px
    }
}

.cmp-global-header__nav-menu-item.cmp-global-header__nav-menu-item--open .cmp-global-header__nav-menu-item-content,.cmp-global-header__nav-menu-item.cmp-global-header__nav-menu-item--open .cmp-global-header__title-link-list__content {
    background-color: #202020;
    height: 100%
}

@media(min-width: 1024px) {
    .cmp-global-header__nav-menu-item.cmp-global-header__nav-menu-item--open .cmp-global-header__nav-menu-item-content,.cmp-global-header__nav-menu-item.cmp-global-header__nav-menu-item--open .cmp-global-header__title-link-list__content {
        height:unset;
        visibility: visible
    }
}

.cmp-global-header__nav-menu-item.cmp-global-header__nav-menu-item--open .cmp-global-header__nav-menu-label-button {
    font-size: .875rem;
    font-weight: 500;
    letter-spacing: -0.005em;
    line-height: 1.5;
    text-underline-offset: .25rem
}

.cmp-global-header__nav-menu-item.cmp-global-header__nav-menu-item--open .cmp-global-header__nav-menu-label-button:lang(ja-JP),.cmp-global-header__nav-menu-item.cmp-global-header__nav-menu-item--open .cmp-global-header__nav-menu-label-button:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .cmp-global-header__nav-menu-item.cmp-global-header__nav-menu-item--open .cmp-global-header__nav-menu-label-button {
        font-size:1rem
    }
}

@media(min-width: 1441px) {
    .cmp-global-header__nav-menu-item.cmp-global-header__nav-menu-item--open .cmp-global-header__nav-menu-label-button {
        font-size:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .cmp-global-header__nav-menu-item.cmp-global-header__nav-menu-item--open .cmp-global-header__nav-menu-label-button {
        font-size:1.3333333333rem
    }
}

.cmp-global-header__nav-menu-item.cmp-global-header__nav-menu-item--open .cmp-global-header__nav-menu-label-button:after {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg)
}

@media(min-width: 1201px) {
    .cmp-global-header__nav-menu-item.cmp-global-header__nav-menu-item--open .cmp-global-header__nav-menu-label-button {
        color:#a2a2a0
    }
}

.cmp-global-header__nav-menu-item.cmp-global-header__nav-menu-item--no-animation .cmp-global-header__nav-menu-item-content {
    -webkit-transition: none;
    transition: none
}

@media(min-width: 1201px) {
    .cmp-global-header__nav-menu-item {
        margin-left:2px;
        margin-right: 2px
    }
}

.cmp-global-header__nav-menu-label-button {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: transparent;
    border: none;
    color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-size: .875rem;
    font-weight: 500;
    height: 40px;
    letter-spacing: -0.005em;
    line-height: 1.5;
    margin-bottom: 8px;
    padding: 0;
    position: relative;
    text-underline-offset: .25rem;
    width: 100%
}

.cmp-global-header__nav-menu-label-button:lang(ja-JP),.cmp-global-header__nav-menu-label-button:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .cmp-global-header__nav-menu-label-button {
        font-size:1rem
    }
}

@media(min-width: 1441px) {
    .cmp-global-header__nav-menu-label-button {
        font-size:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .cmp-global-header__nav-menu-label-button {
        font-size:1.3333333333rem
    }
}

.cmp-global-header__nav-menu-label-button:after {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-font-smoothing: antialiased;
    align-items: center;
    bottom: 0;
    content: "";
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-family: icont;
    font-size: 1.5rem;
    font-size: .5rem;
    font-style: normal;
    font-weight: 400;
    height: 40px;
    justify-content: center;
    position: absolute;
    right: 0;
    text-decoration: none;
    top: 0;
    -webkit-transition: -webkit-transform .5s ease-in-out;
    transition: -webkit-transform .5s ease-in-out;
    transition: transform .5s ease-in-out;
    transition: transform .5s ease-in-out,-webkit-transform .5s ease-in-out;
    width: 20px
}

@media(min-width: 1441px) {
    .cmp-global-header__nav-menu-label-button:after {
        font-size:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .cmp-global-header__nav-menu-label-button:after {
        font-size:2rem
    }
}

@media(min-width: 1441px) {
    .cmp-global-header__nav-menu-label-button:after {
        font-size:0.625rem;
        font-size: calc(0.625rem + 0.69589vw - 10.02784px)
    }
}

@media(min-width: 1920px) {
    .cmp-global-header__nav-menu-label-button:after {
        font-size:0.8333333333rem
    }
}

.cmp-global-header__nav-menu-label-button:focus {
    outline-offset: -1px
}

@media(min-width: 1201px) {
    .cmp-global-header__nav-menu-label-button {
        -webkit-box-sizing:border-box;
        box-sizing: border-box;
        min-height: 100%;
        padding-top: 3px;
        white-space: nowrap
    }

    .cmp-global-header__nav-menu-label-button:after {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        margin-left: 8px;
        position: relative
    }
}

@media(min-width: 1441px) {
    .cmp-global-header__nav-menu-label-button:after {
        margin-left:calc(-0.02227px + 0.55672vw)
    }
}

@media(min-width: 1920px) {
    .cmp-global-header__nav-menu-label-button:after {
        margin-left:10.6666666667px
    }
}

@media(min-width: 1201px) {
    .cmp-global-header__nav-menu-label-button--no-drawer:after {
        display:none
    }
}

@media(min-width: 1920px) {
    .cmp-global-header__nav-menu-label-button:after {
        font-size:.75rem
    }
}

.cmp-global-header__nav-menu-item-content {
    max-height: 0;
    overflow: hidden;
    -webkit-transition: 400ms cubic-bezier(0.02,0.01,0.47,1);
    transition: 400ms cubic-bezier(0.02,0.01,0.47,1);
    will-change: max-height
}

@media(min-width: 1201px) {
    .cmp-global-header__nav-menu-item-content {
        bottom:unset;
        left: 0;
        position: absolute;
        right: 0;
        top: var(--rad-global-header-height);
        visibility: hidden;
        width: 100vw;
        z-index: -1
    }
}

@media(min-width: 1441px) {
    .cmp-global-header__nav-menu-item-content {
        margin-left:0
    }

    .cmp-global-header__nav-menu-item-content .cmp-global-header__drawer {
        margin: 0 auto;
        max-width: 1920px
    }
}

.cmp-global-header__menu-footer {
    margin-bottom: 0;
    margin-top: auto;
    padding: 0 16px 24px
}

@media(min-width: 600px) {
    .cmp-global-header__menu-footer {
        padding:0 48px 48px
    }
}

@media(min-width: 1201px) {
    .cmp-global-header__menu-footer {
        display:none
    }
}

.cmp-global-header__menu-footer .cmp-global-header__action-container {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

.cmp-global-header__social-icons {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: flex-start;
    margin: 0;
    padding: 24px 0 0
}

.cmp-global-header__social-icons a,.cmp-global-header__social-icons li {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.cmp-global-header__social-icons a {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-font-smoothing: antialiased;
    color: #fff;
    font-family: icont;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    height: 24px;
    justify-content: center;
    margin-right: 16px;
    text-decoration: none;
    width: 24px
}

@media(min-width: 1441px) {
    .cmp-global-header__social-icons a {
        font-size:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .cmp-global-header__social-icons a {
        font-size:2rem
    }
}

.cmp-global-header__social-icons a.cmp-global-header__icon--facebook:after {
    content: "";
    font-size: 83.3333333333%
}

.cmp-global-header__social-icons a.cmp-global-header__icon--twitter:after {
    content: "";
    font-size: 67.7083333333%
}

.cmp-global-header__social-icons a.cmp-global-header__icon--linkedin:after {
    content: "";
    font-size: 75%
}

.cmp-global-header__social-icons a.cmp-global-header__icon--google:after {
    content: "";
    font-size: 83.3333333333%
}

.cmp-global-header__social-icons a.cmp-global-header__icon--instagram:after {
    content: "";
    font-size: 75%
}

.cmp-global-header__utility-links {
    margin: 0;
    padding: 0
}

.cmp-global-header__utility-links li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 24px;
    margin-bottom: 8px
}

.cmp-global-header__utility-links a {
    color: #fff;
    font-size: 1rem;
    text-align: center;
    text-decoration: none
}

@media(min-width: 600px) {
    .cmp-global-header__menu-overlay {
        background-color:rgba(32,32,32,.75);
        height: 100%;
        opacity: 0;
        position: fixed;
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
        -webkit-transition: opacity .2s ease-in-out;
        transition: opacity .2s ease-in-out;
        width: 100%;
        z-index: -9999
    }
}

html.cmp-global-header--menu-open {
    overflow: hidden
}

.cmp-global-header__group-link-list {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column
}

.cmp-global-header__group-link-list .content-card--cardwithbutton {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1
}

@media(min-width: 1201px) {
    .cmp-global-header__group-link-list {
        display:block
    }

    .cmp-global-header__group-link-list .content-card--cardwithbutton {
        -webkit-box-ordinal-group: unset;
        -ms-flex-order: unset;
        order: unset
    }
}

.cmp-global-header__group-link-list .cmp-global-header__menu-footer {
    background-color: #202020;
    padding-top: 32px
}

.cmp-global-header__title-link-list__content {
    position: relative;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    visibility: visible
}

@media(max-width: 1200px) {
    .cmp-global-header:not(.cmp-global-header--menu-open) .cmp-global-header__content {
        display:none;
        height: 0;
        opacity: 0;
        -webkit-transform: none;
        transform: none;
        -webkit-transition: none;
        transition: none;
        visibility: hidden;
        width: 0
    }
}

.rad--has-subnav .cmp-global-header nav {
    -webkit-transition-duration: 550ms;
    transition-duration: 550ms;
    -webkit-transition-property: top;
    transition-property: top
}

.rad--has-subnav.rad-global-header--offscreen .cmp-global-header nav {
    top: calc(var(--rad-global-header-height)*-1)
}

.cmp-global-header.cmp-global-header--menu-open .cmp-global-header__language-container {
    background-color: #000;
    position: absolute;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-transition: .7s ease-in-out;
    transition: .7s ease-in-out;
    width: auto;
    z-index: 2
}

@media(min-width: 600px) {
    .cmp-global-header.cmp-global-header--menu-open .cmp-global-header__language-container {
        position:relative
    }
}

.cmp-global-header__language-container {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    min-height: 100%;
    -webkit-transform: translateY(-200%);
    transform: translateY(-200%);
    width: 0;
    z-index: 2
}

@media(min-width: 600px) {
    .cmp-global-header__language-container {
        position:relative;
        -webkit-transform: none;
        transform: none;
        width: auto
    }
}

.cmp-global-header__language-selector {
    background-color: #000;
    border: none;
    color: #fff;
    margin-left: 24px;
    margin-right: 16px;
    min-height: 100%;
    opacity: 1
}

.cmp-global-header__language-selector,.cmp-global-header__language-selector:before {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.cmp-global-header__language-selector:before {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    content: "";
    font-family: icont;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    justify-content: center
}

@media(min-width: 1441px) {
    .cmp-global-header__language-selector:before {
        font-size:calc(1.25rem + 1.39179vw - 20.05567px)
    }
}

@media(min-width: 1920px) {
    .cmp-global-header__language-selector:before {
        font-size:1.6666666667rem
    }
}

.cmp-global-header__language-selector .current-country-text {
    padding-left: 6px
}

.cmp-global-header__language-selector:focus,.cmp-global-header__language-selector:hover {
    outline-offset: -1px
}

@media(min-width: 600px) {
    .cmp-global-header__language-selector {
        margin-right:48px;
        -webkit-transform: inherit;
        transform: inherit
    }
}

@media(min-width: 1024px) {
    .cmp-global-header__language-selector {
        margin-left:32px;
        margin-right: 80px
    }
}

@media(min-width: 1441px) {
    .cmp-global-header__language-selector {
        margin-left:32px;
        margin-right: 80px;
        margin-right: calc(-0.22269px + 5.56715vw)
    }
}

@media(min-width: 1920px) {
    .cmp-global-header__language-selector {
        margin-right:106.6666666667px
    }
}

@media(min-width: 1441px) {
    .cmp-global-header__language-selector {
        margin-left:calc(-0.08907px + 2.22686vw)
    }
}

@media(min-width: 1920px) {
    .cmp-global-header__language-selector {
        margin-left:42.6666666667px
    }
}

.cmp-global-header__language-selector .icon-down-caret {
    display: inline-block;
    height: 24px;
    position: relative;
    width: 24px
}

.cmp-global-header__language-selector .icon-down-caret:before {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    align-items: center;
    content: "";
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-family: icont;
    font-size: 37.5%;
    font-style: normal;
    font-weight: 400;
    height: 100%;
    justify-content: center;
    -webkit-transition: -webkit-transform .5s ease-in-out;
    transition: -webkit-transform .5s ease-in-out;
    transition: transform .5s ease-in-out;
    transition: transform .5s ease-in-out,-webkit-transform .5s ease-in-out
}

@media(min-width: 1441px) {
    .cmp-global-header__language-selector .icon-down-caret:before {
        font-size:0.625rem;
        font-size: calc(0.625rem + 0.69589vw - 10.02784px)
    }
}

@media(min-width: 1920px) {
    .cmp-global-header__language-selector .icon-down-caret:before {
        font-size:0.8333333333rem
    }
}

.cmp-global-header__language-selector[aria-expanded=true] .icon-down-caret:before {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg)
}

.cmp-global-header__language-options {
    background-color: #202020;
    display: none;
    position: absolute;
    right: 20px;
    top: 70px;
    width: 304px
}

@media(min-width: 600px) {
    .cmp-global-header__language-options {
        top:80px
    }
}

@media(min-width: 1201px) {
    .cmp-global-header__language-options {
        top:88px
    }
}

@media(min-width: 1441px) {
    .cmp-global-header__language-options {
        top:calc(-0.24495px + 6.12387vw)
    }
}

@media(min-width: 1920px) {
    .cmp-global-header__language-options {
        top:117.3333333333px
    }
}

.cmp-global-header__language-options .arrow-up {
    display: none
}

.cmp-global-header__language-options .input-group {
    height: 16px;
    width: 100%
}

.cmp-global-header__language-options .country-list {
    height: 410px;
    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-color: #a100ff #460073;
    scrollbar-width: thin
}


.heroimage {
    padding-left: 3rem;
}
@media (max-width:991px) {
    .rad-grid-card-carousel__card-size-maintainer{
        height: auto !important;
    width: auto !important;
    }
    .rad-content-grid-card__front-content {
        -webkit-box-align: end;
        -ms-flex-align: end;
        align-items: center !important;
        display: flex;
        flex-direction: row-reverse;
    }
    .rad-content-grid-card {
        display: flex;
        flex-direction: column;
        height: 424px;

        position: relative;

        width: auto;
    }
.heroimage {
    padding-left: 0rem;
}
}
.cmp-global-header__language-menu {
    font-size: .875rem;
    font-weight: 500;
    font-weight: 400;
    letter-spacing: -0.005em;
    line-height: 1.5;
    list-style-type: none;
    margin: 0;
    padding: 0;
    text-underline-offset: .25rem
}

.cmp-global-header__language-menu:lang(ja-JP),.cmp-global-header__language-menu:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .cmp-global-header__language-menu {
        font-size:1rem
    }
}

@media(min-width: 1441px) {
    .cmp-global-header__language-menu {
        font-size:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .cmp-global-header__language-menu {
        font-size:1.3333333333rem
    }
}

.cmp-global-header__language-menu:lang(ja-JP),.cmp-global-header__language-menu:lang(zh-CN) {
    font-weight: 400
}

.cmp-global-header__language-menu li {
    -webkit-box-shadow: inset 0 -1px 0 0 hsla(0,0%,100%,.2);
    box-shadow: inset 0 -1px 0 0 hsla(0,0%,100%,.2);
    padding: 12px 32px
}

.cmp-global-header__language-menu li a {
    color: #fff;
    text-decoration: none
}

.cmp-global-header__language-menu li :hover {
    color: #a2a2a0;
    text-decoration: underline
}

.cmp-global-header__language-menu li :focus {
    text-decoration: underline
}

.cmp-global-header__language-menu .default {
    -webkit-box-shadow: none;
    box-shadow: none;
    font-size: .75rem;
    font-weight: 500;
    letter-spacing: -0.005em;
    line-height: 1.5;
    text-underline-offset: .25rem
}

.cmp-global-header__language-menu .default:lang(ja-JP),.cmp-global-header__language-menu .default:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .cmp-global-header__language-menu .default {
        font-size:0.875rem
    }
}

@media(min-width: 1441px) {
    .cmp-global-header__language-menu .default {
        font-size:calc(0.875rem + 0.97425vw - 14.03897px)
    }
}

@media(min-width: 1920px) {
    .cmp-global-header__language-menu .default {
        font-size:1.1666666667rem
    }
}

.cmp-global-header__language-menu .dropdown-header {
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #fff;
    font-size: .75rem;
    font-weight: 500;
    letter-spacing: .02em;
    line-height: 1.2;
    padding-bottom: 24px;
    padding-top: 32px
}

.cmp-global-header__language-menu .dropdown-header:lang(ja-JP),.cmp-global-header__language-menu .dropdown-header:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 1024px) {
    .cmp-global-header__language-menu .dropdown-header {
        font-size:0.875rem
    }
}

@media(min-width: 1441px) {
    .cmp-global-header__language-menu .dropdown-header {
        font-size:calc(0.875rem + 0.97425vw - 14.03897px)
    }
}

@media(min-width: 1920px) {
    .cmp-global-header__language-menu .dropdown-header {
        font-size:1.1666666667rem
    }
}

.cmp-global-header .cmp-global-header__language-selector--show,.cmp-global-header .cmp-global-header__signed-in-option-selector--show {
    display: block
}

#cmp-global-header__current-country {
    display: none
}

@media(min-width: 1201px) {
    .cmp-global-header__drawer {
        background-color:#202020;
        max-height: calc(100vh - 88px);
        overflow-y: auto;
        padding-bottom: 80px;
        padding-top: 80px
    }
}

@media(min-width: 1441px) {
    .cmp-global-header__drawer {
        padding-top:calc(-0.22269px + 5.56715vw)
    }
}

@media(min-width: 1920px) {
    .cmp-global-header__drawer {
        padding-top:106.6666666667px
    }
}

@media(min-width: 1441px) {
    .cmp-global-header__drawer {
        padding-bottom:calc(-0.22269px + 5.56715vw)
    }
}

@media(min-width: 1920px) {
    .cmp-global-header__drawer {
        padding-bottom:106.6666666667px
    }
}

@media(min-width: 1201px) {
    .cmp-global-header__drawer-content {
        margin-left:auto;
        margin-right: auto;
        padding-left: var(--rad-spacing-sm);
        padding-left: var(--rad-spacing-md);
        padding-right: var(--rad-spacing-sm);
        padding-right: var(--rad-spacing-md)
    }

    .rad-component--nested .cmp-global-header__drawer-content {
        padding-left: 0;
        padding-right: 0
    }

    .cmp-global-header__drawer-two-columns {
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        justify-content: space-between
    }

    .cmp-global-header__drawer-left-column {
        width: calc(75% - var(--rad-spacing-sm)*3/4*3 + var(--rad-spacing-sm)*2 + var(--rad-spacing-sm)*0);
        width: calc(37.5% - var(--rad-spacing-sm)*7/8*3 + var(--rad-spacing-sm)*2 + var(--rad-spacing-md)*0);
        width: calc(25% - var(--rad-spacing-sm)*11/12*3 + var(--rad-spacing-sm)*2 + var(--rad-spacing-md)*0)
    }

    .cmp-global-header__drawer-right-column {
        width: calc(200% - var(--rad-spacing-sm)*3/4*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-sm)*0);
        width: calc(100% - var(--rad-spacing-sm)*7/8*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0);
        width: calc(66.66667% - var(--rad-spacing-sm)*11/12*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0)
    }

    .cmp-global-header__drawer-right-column .cmp-global-header__group-link-list {
        max-width: 100%
    }
}

.cmp-global-header__inner-link-list {
    list-style-type: none;
    padding-left: 0
}

.cmp-global-header__inner-link-list a {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-size: .875rem;
    font-weight: 500;
    font-weight: 400;
    letter-spacing: -0.005em;
    line-height: 1.5;
    margin-bottom: 16px;
    padding-left: 16px;
    position: relative;
    text-decoration: none;
    text-underline-offset: .25rem;
    width: auto
}

.cmp-global-header__inner-link-list a:lang(ja-JP),.cmp-global-header__inner-link-list a:lang(zh-CN) {
    font-weight: 700;
    font-weight: 400
}

@media(min-width: 600px) {
    .cmp-global-header__inner-link-list a {
        font-size:.875rem;
        font-weight: 400;
        font-weight: 500;
        letter-spacing: -0.005em;
        line-height: 1.5;
        text-underline-offset: .25rem
    }

    .cmp-global-header__inner-link-list a:lang(ja-JP),.cmp-global-header__inner-link-list a:lang(zh-CN) {
        font-weight: 700
    }

    .cmp-global-header__inner-link-list a {
        font-size: 1rem
    }
}

@media(min-width: 1441px) {
    .cmp-global-header__inner-link-list a {
        font-size:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .cmp-global-header__inner-link-list a {
        font-size:1.3333333333rem
    }
}

@media(min-width: 1201px) {
    .cmp-global-header__inner-link-list {
        -webkit-box-orient:horizontal;
        -webkit-box-direction: normal;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: row;
        flex-direction: row;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap
    }

    .cmp-global-header__inner-link-list li {
        -webkit-box-flex: 1;
        -ms-flex: 1 1 25%;
        flex: 1 1 25%;
        max-width: 25%;
        padding-right: 15px
    }

    .cmp-global-header__inner-link-list a {
        border: none;
        color: #fff;
        display: inline-block;
        margin-bottom: 16px;
        min-height: auto;
        padding-left: 0;
        padding-right: 0;
        word-break: break-word
    }

    .cmp-global-header__inner-link-list a:focus,.cmp-global-header__inner-link-list a:hover {
        text-decoration: underline
    }

    .cmp-global-header__link-list.cmp-global-header__link-list--full-width-li .cmp-global-header__inner-link-list li:first-child {
        -webkit-box-flex: 1;
        -ms-flex: 1 1 100%;
        flex: 1 1 100%;
        max-width: none
    }

    .cmp-global-header__link-list.cmp-global-header__link-list--with-arrows {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex
    }

    .cmp-global-header__link-list.cmp-global-header__link-list--with-arrows .cmp-global-header__inner-link-list li {
        -webkit-box-flex: 0;
        -ms-flex: none;
        flex: none;
        max-width: none;
        padding-right: 50px
    }

    .cmp-global-header__link-list.cmp-global-header__link-list--with-arrows .cmp-global-header__inner-link-list a {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        color: #fff;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        font-size: 1.125rem;
        font-weight: 500;
        letter-spacing: -0.02em;
        line-height: 1.4;
        position: relative
    }

    .cmp-global-header__link-list.cmp-global-header__link-list--with-arrows .cmp-global-header__inner-link-list a:lang(ja-JP),.cmp-global-header__link-list.cmp-global-header__link-list--with-arrows .cmp-global-header__inner-link-list a:lang(zh-CN) {
        font-weight: 700
    }

    .cmp-global-header__link-list.cmp-global-header__link-list--with-arrows .cmp-global-header__inner-link-list a {
        font-size: 1.25rem;
        font-size: 1.5rem;
        line-height: 1.4;
        line-height: 1.3
    }
}

@media(min-width: 1441px) {
    .cmp-global-header__link-list.cmp-global-header__link-list--with-arrows .cmp-global-header__inner-link-list a {
        font-size:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .cmp-global-header__link-list.cmp-global-header__link-list--with-arrows .cmp-global-header__inner-link-list a {
        font-size:2rem
    }
}

@media(min-width: 1201px) {
    .cmp-global-header__link-list__content .cmp-global-header__inner-link-list a {
        padding-left:0
    }

    .cmp-global-header__link-list+.cmp-global-header__link-list,.cmp-global-header__link-list+.cmp-global-header__title-link-list {
        margin-top: 20px
    }
}

.cmp-global-header__title-link-list__menu.cmp-global-header__title-link-list__menu--open .cmp-global-header__title-link-list__content {
    left: 0;
    scrollbar-color: #a100ff #460073;
    scrollbar-width: thin;
    -webkit-transform: translateX(0);
    transform: translateX(0);
    visibility: visible
}

.cmp-global-header__title-link-list__menu.cmp-global-header__title-link-list__menu--open .cmp-global-header__title-link-list__content::-webkit-scrollbar {
    height: 4px;
    width: 4px
}

.cmp-global-header__title-link-list__menu.cmp-global-header__title-link-list__menu--open .cmp-global-header__title-link-list__content::-webkit-scrollbar-track {
    background-color: #460073
}

.cmp-global-header__title-link-list__menu.cmp-global-header__title-link-list__menu--open .cmp-global-header__title-link-list__content::-webkit-scrollbar-thumb {
    background-color: #a100ff
}

.cmp-global-header__title-link-list__menu.cmp-global-header__title-link-list__menu--open .cmp-global-header__title-link-list__content .cmp-global-header__inner-link-list {
    padding: 0 16px
}

@media(min-width: 600px) {
    .cmp-global-header__title-link-list__menu.cmp-global-header__title-link-list__menu--open .cmp-global-header__title-link-list__content .cmp-global-header__inner-link-list {
        padding:0 48px
    }
}

.cmp-global-header__title-link-list__menu.cmp-global-header__title-link-list__menu--open .cmp-global-header__title-link-list__content:hover {
    scrollbar-width: auto
}

.cmp-global-header__title-link-list__menu.cmp-global-header__title-link-list__menu--open .cmp-global-header__title-link-list__content:hover::-webkit-scrollbar {
    width: 12px
}

.cmp-global-header__title-link-list__menu-label {
    display: none
}

@media(min-width: 1201px) {
    .cmp-global-header__title-link-list__menu-label {
        display:block;
        font-size: .75rem;
        font-weight: 500;
        letter-spacing: .02em;
        line-height: 1.2;
        margin-bottom: 24px;
        padding-left: 15px;
        text-transform: uppercase
    }

    .cmp-global-header__title-link-list__menu-label:lang(ja-JP),.cmp-global-header__title-link-list__menu-label:lang(zh-CN) {
        font-weight: 700
    }

    .cmp-global-header__title-link-list__menu-label {
        font-size: 0.875rem
    }
}

@media(min-width: 1441px) {
    .cmp-global-header__title-link-list__menu-label {
        font-size:calc(0.875rem + 0.97425vw - 14.03897px)
    }
}

@media(min-width: 1920px) {
    .cmp-global-header__title-link-list__menu-label {
        font-size:1.1666666667rem
    }
}

.cmp-global-header__title-link-list__menu-button {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    background-color: transparent;
    border: none;
    color: #fff;
    font-size: 1rem;
    justify-content: space-between;
    line-height: 1.5;
    min-height: 40px;
    padding: 0 0 0 16px;
    position: relative;
    text-align: left;
    width: 100%
}

.cmp-global-header__title-link-list__menu-button,.cmp-global-header__title-link-list__menu-button:after {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-weight: 400
}

.cmp-global-header__title-link-list__menu-button:after {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    content: "";
    font-family: icont;
    font-size: 1.5rem;
    font-size: 50%;
    font-style: normal;
    height: 20px;
    justify-content: center;
    -webkit-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    width: 20px
}

@media(min-width: 1441px) {
    .cmp-global-header__title-link-list__menu-button:after {
        font-size:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .cmp-global-header__title-link-list__menu-button:after {
        font-size:2rem
    }
}

.cmp-global-header__title-link-list__menu-button:focus {
    outline-offset: -1px
}

@media(min-width: 1201px) {
    .cmp-global-header__title-link-list__menu-button {
        display:none
    }
}

.cmp-global-header__title-link-list__content {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    bottom: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: auto;
    position: fixed;
    top: 0;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    -webkit-transition: .45s ease-in-out;
    transition: .45s ease-in-out;
    visibility: hidden;
    width: 100%;
    z-index: 1
}

@media(min-width: 1201px) {
    .cmp-global-header__title-link-list__content {
        overflow:visible;
        position: static;
        -webkit-transform: none;
        transform: none
    }
}

.cmp-global-header__title-link-list__content-button {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #202020;
    border: none;
    color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-size: .875rem;
    font-weight: 500;
    letter-spacing: -0.005em;
    line-height: 1.5;
    margin-bottom: 16px;
    margin-left: 12px;
    margin-top: 16px;
    min-height: 44px;
    padding-right: .9375rem;
    position: relative;
    text-underline-offset: .25rem;
    width: 100%
}

.cmp-global-header__title-link-list__content-button:lang(ja-JP),.cmp-global-header__title-link-list__content-button:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .cmp-global-header__title-link-list__content-button {
        font-size:1rem
    }
}

@media(min-width: 1441px) {
    .cmp-global-header__title-link-list__content-button {
        font-size:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .cmp-global-header__title-link-list__content-button {
        font-size:1.3333333333rem
    }
}

.cmp-global-header__title-link-list__content-button:before {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    align-items: center;
    content: "";
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-family: icont;
    font-size: 1.5rem;
    font-size: 50%;
    font-style: normal;
    font-weight: 400;
    height: 20px;
    justify-content: center;
    width: 20px
}

@media(min-width: 1441px) {
    .cmp-global-header__title-link-list__content-button:before {
        font-size:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .cmp-global-header__title-link-list__content-button:before {
        font-size:2rem
    }
}

@media(min-width: 600px) {
    .cmp-global-header__title-link-list__content-button {
        margin-left:44px;
        margin-top: 48px
    }
}

@media(min-width: 1201px) {
    .cmp-global-header__title-link-list__content-button {
        display:none
    }

    .cmp-global-header__title-link-list+.cmp-global-header__link-list,.cmp-global-header__title-link-list+.cmp-global-header__title-link-list {
        margin-top: 20px
    }

    .cmp-global-header__title-link-list+.cmp-global-header__link-list:before,.cmp-global-header__title-link-list+.cmp-global-header__title-link-list:before {
        content: "";
        display: block;
        margin-left: 15px;
        margin-right: 15px;
        padding-bottom: 40px
    }
}

.cmp-global-header__group-link-list .cmp-global-header__title-link-list__menu-label {
    margin-bottom: 16px;
    padding-left: 24px;
    padding-top: 16px;
    text-transform: uppercase
}

.cmp-global-header__group-link-list .cmp-global-header__inner-link-list {
    background-color: #202020
}

.cmp-global-header__group-link-list .cmp-global-header__inner-link-list a p {
    display: none
}

@media(min-width: 1201px) {
    .cmp-global-header__group-link-list .cmp-global-header__title-link-list__menu-label {
        margin-bottom:24px;
        padding-left: 0;
        padding-top: 0
    }

    .cmp-global-header__group-link-list .cmp-global-header__inner-link-list {
        border-bottom: 0;
        row-gap: 16px
    }

    .cmp-global-header__group-link-list .cmp-global-header__inner-link-list a {
        border-bottom: 0;
        margin-bottom: 16px;
        padding-left: 0
    }

    .cmp-global-header__group-link-list .cmp-global-header__inner-link-list a p {
        display: block
    }

    .cmp-global-header__group-link-list .cmp-global-header__link-list--with-arrows .cmp-global-header__inner-link-list a {
        margin-bottom: 52px
    }

    .cmp-global-header__drawer--column-layout .cmp-global-header__group-link-list {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap
    }

    .cmp-global-header__drawer--column-layout .cmp-global-header__group-link-list .cmp-global-header__link-list,.cmp-global-header__drawer--column-layout .cmp-global-header__group-link-list .cmp-global-header__title-link-list {
        -webkit-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
        min-width: 33.3333333333%
    }

    .cmp-global-header__drawer--column-layout .cmp-global-header__group-link-list .cmp-global-header__inner-link-list {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column
    }

    .cmp-global-header__drawer--column-layout .cmp-global-header__group-link-list .cmp-global-header__link-list+.cmp-global-header__link-list,.cmp-global-header__drawer--column-layout .cmp-global-header__group-link-list .cmp-global-header__link-list+.cmp-global-header__title-link-list {
        margin-top: 0
    }

    .cmp-global-header__drawer--column-layout .cmp-global-header__group-link-list .cmp-global-header__link-list+.cmp-global-header__link-list:before,.cmp-global-header__drawer--column-layout .cmp-global-header__group-link-list .cmp-global-header__link-list+.cmp-global-header__title-link-list:before {
        content: none
    }

    .cmp-global-header__drawer--column-layout .cmp-global-header__group-link-list .cmp-global-header__title-link-list+.cmp-global-header__link-list,.cmp-global-header__drawer--column-layout .cmp-global-header__group-link-list .cmp-global-header__title-link-list+.cmp-global-header__title-link-list {
        margin-top: 0
    }

    .cmp-global-header__drawer--column-layout .cmp-global-header__group-link-list .cmp-global-header__title-link-list+.cmp-global-header__link-list:before,.cmp-global-header__drawer--column-layout .cmp-global-header__group-link-list .cmp-global-header__title-link-list+.cmp-global-header__title-link-list:before {
        content: none
    }
}

.cmp-header-featured-content .cmp-teaser__content {
    background-color: transparent;
    border: none;
    margin: 16px 0
}

@media(min-width: 1201px) {
    .cmp-header-featured-content .cmp-teaser__content {
        margin-bottom:40px;
        margin-top: 0;
        max-width: 300px
    }
}

.cmp-header-featured-content .cmp-teaser__pretitle {
    color: #fff;
    font-size: .75rem;
    font-weight: 500;
    letter-spacing: .02em;
    line-height: 1.2;
    margin-bottom: 16px;
    text-transform: uppercase
}

.cmp-header-featured-content .cmp-teaser__pretitle:lang(ja-JP),.cmp-header-featured-content .cmp-teaser__pretitle:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 1024px) {
    .cmp-header-featured-content .cmp-teaser__pretitle {
        font-size:0.875rem
    }
}

@media(min-width: 1441px) {
    .cmp-header-featured-content .cmp-teaser__pretitle {
        font-size:calc(0.875rem + 0.97425vw - 14.03897px)
    }
}

@media(min-width: 1920px) {
    .cmp-header-featured-content .cmp-teaser__pretitle {
        font-size:1.1666666667rem
    }
}

@media(min-width: 1201px) {
    .cmp-header-featured-content .cmp-teaser__pretitle {
        margin-bottom:24px
    }
}

.cmp-header-featured-content .cmp-teaser__title {
    font-size: 1.125rem;
    font-weight: 500;
    letter-spacing: -0.02em;
    line-height: 1.4;
    margin-bottom: 16px
}

.cmp-header-featured-content .cmp-teaser__title:lang(ja-JP),.cmp-header-featured-content .cmp-teaser__title:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .cmp-header-featured-content .cmp-teaser__title {
        font-size:1.25rem;
        line-height: 1.4
    }
}

@media(min-width: 1024px) {
    .cmp-header-featured-content .cmp-teaser__title {
        font-size:1.5rem;
        line-height: 1.3
    }
}

@media(min-width: 1441px) {
    .cmp-header-featured-content .cmp-teaser__title {
        font-size:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .cmp-header-featured-content .cmp-teaser__title {
        font-size:2rem
    }
}

@media(min-width: 1201px) {
    .cmp-header-featured-content .cmp-teaser__title {
        margin-top:auto
    }
}

.cmp-header-featured-content .cmp-teaser__title-link {
    color: #fff;
    display: inline-block;
    text-decoration: none
}

.cmp-header-featured-content .cmp-teaser__title-link:hover {
    text-decoration: underline
}

@media(min-width: 1201px) {
    .cmp-header-featured-content .cmp-teaser__title-link,.cmp-header-featured-content .cmp-teaser__title-link:hover {
        color:#fff
    }

    .cmp-header-featured-content .cmp-teaser__title-link:focus {
        text-decoration: underline
    }
}

.cmp-header-featured-content .cmp-teaser__description {
    color: #a2a2a0;
    font-size: .75rem;
    font-weight: 500;
    font-weight: 400;
    letter-spacing: -0.005em;
    line-height: 1.5;
    margin: auto;
    text-underline-offset: .25rem
}

.cmp-header-featured-content .cmp-teaser__description:lang(ja-JP),.cmp-header-featured-content .cmp-teaser__description:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .cmp-header-featured-content .cmp-teaser__description {
        font-size:0.875rem
    }
}

@media(min-width: 1441px) {
    .cmp-header-featured-content .cmp-teaser__description {
        font-size:calc(0.875rem + 0.97425vw - 14.03897px)
    }
}

@media(min-width: 1920px) {
    .cmp-header-featured-content .cmp-teaser__description {
        font-size:1.1666666667rem
    }
}

.cmp-header-featured-content .cmp-teaser__description:lang(ja-JP),.cmp-header-featured-content .cmp-teaser__description:lang(zh-CN) {
    font-weight: 400
}

.rad-icon-text-carousel-tier-1__headline {
    margin-bottom: 24px;
    padding-left: var(--rad-spacing-sm);
    padding-right: var(--rad-spacing-sm)
}

@media(min-width: 600px) {
    .rad-icon-text-carousel-tier-1__headline {
        padding-left:var(--rad-spacing-md);
        padding-right: var(--rad-spacing-md)
    }
}

.rad-component--nested .rad-icon-text-carousel-tier-1__headline {
    padding-left: 0;
    padding-right: 0
}

.rad-icon-text-carousel-tier-1__header {
    font-size: 1.75rem;
    font-weight: 500;
    letter-spacing: -0.03em;
    line-height: 1.3;
    margin-bottom: .25rem
}

.rad-icon-text-carousel-tier-1__header:lang(ja-JP),.rad-icon-text-carousel-tier-1__header:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-icon-text-carousel-tier-1__header {
        font-size:2.5rem;
        line-height: 1.2
    }
}

@media(min-width: 1024px) {
    .rad-icon-text-carousel-tier-1__header {
        font-size:3rem;
        line-height: 1.2
    }
}

@media(min-width: 1441px) {
    .rad-icon-text-carousel-tier-1__header {
        font-size:calc(3rem + 3.34029vw - 48.13361px)
    }
}

@media(min-width: 1920px) {
    .rad-icon-text-carousel-tier-1__header {
        font-size:4rem
    }
}

.rad-icon-text-carousel-tier-1__carousel-card {
    padding-left: var(--rad-spacing-sm);
    padding-right: var(--rad-spacing-sm)
}

.rad-icon-text-carousel-tier-1__carousel-card-logo {
    margin-bottom: 16px;
    max-width: 160px;
    min-height: 80px;
    min-width: 80px
}

.rad-icon-text-carousel-tier-1__carousel-card-image {
    margin-bottom: 16px;
    max-width: 142px;
    min-height: 80px;
    min-width: 80px
}

.rad-icon-text-carousel-tier-1__carousel-card-eyebrow {
    font-size: .75rem;
    font-weight: 500;
    letter-spacing: .02em;
    line-height: 1.2;
    margin-bottom: 8px
}

.rad-icon-text-carousel-tier-1__carousel-card-eyebrow:lang(ja-JP),.rad-icon-text-carousel-tier-1__carousel-card-eyebrow:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 1024px) {
    .rad-icon-text-carousel-tier-1__carousel-card-eyebrow {
        font-size:0.875rem
    }
}

@media(min-width: 1441px) {
    .rad-icon-text-carousel-tier-1__carousel-card-eyebrow {
        font-size:calc(0.875rem + 0.97425vw - 14.03897px)
    }
}

@media(min-width: 1920px) {
    .rad-icon-text-carousel-tier-1__carousel-card-eyebrow {
        font-size:1.1666666667rem
    }
}

.rad-icon-text-carousel-tier-1__carousel-card-headline {
    font-size: 1.125rem;
    font-weight: 500;
    letter-spacing: -0.02em;
    line-height: 1.4;
    margin-bottom: .5rem
}

.rad-icon-text-carousel-tier-1__carousel-card-headline:lang(ja-JP),.rad-icon-text-carousel-tier-1__carousel-card-headline:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-icon-text-carousel-tier-1__carousel-card-headline {
        font-size:1.25rem;
        line-height: 1.4
    }
}

@media(min-width: 1024px) {
    .rad-icon-text-carousel-tier-1__carousel-card-headline {
        font-size:1.5rem;
        line-height: 1.3
    }
}

@media(min-width: 1441px) {
    .rad-icon-text-carousel-tier-1__carousel-card-headline {
        font-size:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-icon-text-carousel-tier-1__carousel-card-headline {
        font-size:2rem
    }
}

.rad-icon-text-carousel-tier-1__carousel-card-body {
    font-size: .875rem;
    font-weight: 500;
    font-weight: 400;
    letter-spacing: -0.005em;
    line-height: 1.5;
    margin-bottom: .25rem;
    text-underline-offset: .25rem
}

.rad-icon-text-carousel-tier-1__carousel-card-body:lang(ja-JP),.rad-icon-text-carousel-tier-1__carousel-card-body:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-icon-text-carousel-tier-1__carousel-card-body {
        font-size:1rem
    }
}

@media(min-width: 1441px) {
    .rad-icon-text-carousel-tier-1__carousel-card-body {
        font-size:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-icon-text-carousel-tier-1__carousel-card-body {
        font-size:1.3333333333rem
    }
}

.rad-icon-text-carousel-tier-1__carousel-card-body:lang(ja-JP),.rad-icon-text-carousel-tier-1__carousel-card-body:lang(zh-CN) {
    font-weight: 400
}

.rad-icon-text-carousel-tier-1+.rad-icon-text-carousel-tier-2 {
    margin-top: 64px
}

.rad-icon-text-carousel-tier-1+.rad-icon-text-carousel-tier-2 .rad-icon-text-carousel-tier-2__headline {
    margin-bottom: 1.5rem
}

.rad-icon-text-carousel-tier-1+.rad-icon-text-carousel-tier-2 .rad-icon-text-carousel-tier-2__header {
    font-size: 1.25rem;
    font-weight: 500;
    letter-spacing: -0.02em;
    line-height: 1.3
}

.rad-icon-text-carousel-tier-1+.rad-icon-text-carousel-tier-2 .rad-icon-text-carousel-tier-2__header:lang(ja-JP),.rad-icon-text-carousel-tier-1+.rad-icon-text-carousel-tier-2 .rad-icon-text-carousel-tier-2__header:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-icon-text-carousel-tier-1+.rad-icon-text-carousel-tier-2 .rad-icon-text-carousel-tier-2__header {
        font-size:1.5rem;
        line-height: 1.3
    }
}

@media(min-width: 1024px) {
    .rad-icon-text-carousel-tier-1+.rad-icon-text-carousel-tier-2 .rad-icon-text-carousel-tier-2__header {
        font-size:1.75rem;
        line-height: 1.25
    }
}

@media(min-width: 1441px) {
    .rad-icon-text-carousel-tier-1+.rad-icon-text-carousel-tier-2 .rad-icon-text-carousel-tier-2__header {
        font-size:calc(1.75rem + 1.9485vw - 28.07794px)
    }
}

@media(min-width: 1920px) {
    .rad-icon-text-carousel-tier-1+.rad-icon-text-carousel-tier-2 .rad-icon-text-carousel-tier-2__header {
        font-size:2.3333333333rem
    }
}

.rad-icon-text-carousel-tier-2 {
    padding-left: var(--rad-spacing-sm);
    padding-right: var(--rad-spacing-sm)
}

@media(min-width: 600px) {
    .rad-icon-text-carousel-tier-2 {
        padding-left:var(--rad-spacing-md);
        padding-right: var(--rad-spacing-md)
    }
}

.rad-component--nested .rad-icon-text-carousel-tier-2 {
    padding-left: 0;
    padding-right: 0
}

.rad-icon-text-carousel-tier-2__headline {
    margin-bottom: 1.5rem
}

.rad-icon-text-carousel-tier-2__header {
    font-size: 1.75rem;
    font-weight: 500;
    letter-spacing: -0.03em;
    line-height: 1.3;
    margin-bottom: .25rem
}

.rad-icon-text-carousel-tier-2__header:lang(ja-JP),.rad-icon-text-carousel-tier-2__header:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-icon-text-carousel-tier-2__header {
        font-size:2.5rem;
        line-height: 1.2
    }
}

@media(min-width: 1024px) {
    .rad-icon-text-carousel-tier-2__header {
        font-size:3rem;
        line-height: 1.2
    }
}

@media(min-width: 1441px) {
    .rad-icon-text-carousel-tier-2__header {
        font-size:calc(3rem + 3.34029vw - 48.13361px)
    }
}

@media(min-width: 1920px) {
    .rad-icon-text-carousel-tier-2__header {
        font-size:4rem
    }
}

.rad-icon-text-carousel-tier-2__grid {
    -webkit-column-gap: 12.5%;
    -moz-column-gap: 12.5%;
    column-gap: 12.5%;
    display: grid;
    grid-template-columns: repeat(3,25%);
    row-gap: 16px
}

.rad-icon-text-carousel-tier-2__grid img {
    width: 100%
}

@media(min-width: 600px) {
    .rad-icon-text-carousel-tier-1__headline {
        -webkit-box-align:center;
        -ms-flex-align: center;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        align-items: center;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: row;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 40px
    }

    .rad-icon-text-carousel-tier-1__header {
        margin-bottom: 0;
        width: calc(125% - var(--rad-spacing-sm)*3/4*5 + var(--rad-spacing-sm)*4 + var(--rad-spacing-sm)*0);
        width: calc(62.5% - var(--rad-spacing-sm)*7/8*5 + var(--rad-spacing-sm)*4 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-icon-text-carousel-tier-1__header {
        width:calc(41.66667% - var(--rad-spacing-sm)*11/12*5 + var(--rad-spacing-sm)*4 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 600px) {
    .rad-icon-text-carousel-tier-1__button {
        margin-left:auto
    }

    .rad-icon-text-carousel-tier-1__carousel-card {
        padding-left: var(--rad-spacing-md);
        padding-right: calc((min(100vw,1920px) - 2*var(--rad-spacing-md))*0.18 - var(--rad-spacing-md));
        width: calc((min(100vw,1920px) - 2*var(--rad-spacing-md))*66/100)
    }

    .rad-icon-text-carousel-tier-1__carousel-card-logo {
        height: 48px;
        margin-bottom: 24px;
        max-width: 96px;
        min-height: 48px;
        min-width: 48px
    }

    .rad-icon-text-carousel-tier-1__carousel-card-image {
        margin-bottom: 24px;
        max-width: 142px;
        min-height: 80px;
        min-width: 80px
    }

    .rad-icon-text-carousel-tier-1__carousel-card-headline {
        margin-bottom: .75rem
    }

    .rad-icon-text-carousel-tier-1__carousel-card-eyebrow {
        margin-bottom: .5rem
    }

    .rad-icon-text-carousel-tier-1+.rad-icon-text-carousel-tier-2 {
        margin-top: 80px
    }

    .rad-icon-text-carousel-tier-1+.rad-icon-text-carousel-tier-2 .rad-icon-text-carousel-tier-2__header {
        width: 100%
    }

    .rad-icon-text-carousel-tier-2__headline {
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        align-items: center;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: row;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 2.5rem
    }

    .rad-icon-text-carousel-tier-2__header {
        margin-bottom: 0;
        width: calc(125% - var(--rad-spacing-sm)*3/4*5 + var(--rad-spacing-sm)*4 + var(--rad-spacing-sm)*0);
        width: calc(62.5% - var(--rad-spacing-sm)*7/8*5 + var(--rad-spacing-sm)*4 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-icon-text-carousel-tier-2__header {
        width:calc(41.66667% - var(--rad-spacing-sm)*11/12*5 + var(--rad-spacing-sm)*4 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 600px) {
    .rad-icon-text-carousel-tier-2__grid {
        -webkit-column-gap:12%;
        -moz-column-gap: 12%;
        column-gap: 12%;
        grid-template-columns: repeat(4,16%);
        row-gap: 24px
    }
}

@media(min-width: 1024px) {
    .rad-icon-text-carousel-tier-1__headline {
        margin-bottom:48px
    }
}

@media(min-width: 1441px) {
    .rad-icon-text-carousel-tier-1__headline {
        margin-bottom:calc(-0.13361px + 3.34029vw)
    }
}

@media(min-width: 1920px) {
    .rad-icon-text-carousel-tier-1__headline {
        margin-bottom:64px
    }
}

@media(min-width: 1024px) {
    .rad-icon-text-carousel-tier-1__header {
        width:calc(225% - var(--rad-spacing-sm)*3/4*9 + var(--rad-spacing-sm)*8 + var(--rad-spacing-sm)*0);
        width: calc(112.5% - var(--rad-spacing-sm)*7/8*9 + var(--rad-spacing-sm)*8 + var(--rad-spacing-md)*0);
        width: calc(75% - var(--rad-spacing-sm)*11/12*9 + var(--rad-spacing-sm)*8 + var(--rad-spacing-md)*0)
    }

    .rad-icon-text-carousel-tier-1__carousel-card {
        padding-right: calc((min(100vw,1920px) - 2*var(--rad-spacing-md))*0.1 - var(--rad-spacing-md));
        width: calc((min(100vw,1920px) - 2*var(--rad-spacing-md))*50/100)
    }

    .rad-icon-text-carousel-tier-1__carousel-card-logo {
        height: unset;
        margin-bottom: 24px;
        max-width: 160px;
        min-height: 80px;
        min-width: 80px
    }
}

@media(min-width: 1441px) {
    .rad-icon-text-carousel-tier-1__carousel-card-logo {
        max-width:calc(-0.44537px + 11.13431vw)
    }
}

@media(min-width: 1920px) {
    .rad-icon-text-carousel-tier-1__carousel-card-logo {
        max-width:213.3333333333px
    }
}

@media(min-width: 1441px) {
    .rad-icon-text-carousel-tier-1__carousel-card-logo {
        min-width:calc(-0.22269px + 5.56715vw)
    }
}

@media(min-width: 1920px) {
    .rad-icon-text-carousel-tier-1__carousel-card-logo {
        min-width:106.6666666667px
    }
}

@media(min-width: 1441px) {
    .rad-icon-text-carousel-tier-1__carousel-card-logo {
        min-height:calc(-0.22269px + 5.56715vw)
    }
}

@media(min-width: 1920px) {
    .rad-icon-text-carousel-tier-1__carousel-card-logo {
        min-height:106.6666666667px
    }
}

@media(min-width: 1441px) {
    .rad-icon-text-carousel-tier-1__carousel-card-logo {
        margin-bottom:calc(-0.06681px + 1.67015vw)
    }
}

@media(min-width: 1920px) {
    .rad-icon-text-carousel-tier-1__carousel-card-logo {
        margin-bottom:32px
    }
}

@media(min-width: 1024px) {
    .rad-icon-text-carousel-tier-1__carousel-card-image {
        margin-bottom:24px;
        max-width: 142px;
        min-height: 80px;
        min-width: 80px
    }
}

@media(min-width: 1441px) {
    .rad-icon-text-carousel-tier-1__carousel-card-image {
        max-width:calc(-0.39527px + 9.8817vw)
    }
}

@media(min-width: 1920px) {
    .rad-icon-text-carousel-tier-1__carousel-card-image {
        max-width:189.3333333333px
    }
}

@media(min-width: 1441px) {
    .rad-icon-text-carousel-tier-1__carousel-card-image {
        min-width:calc(-0.22269px + 5.56715vw)
    }
}

@media(min-width: 1920px) {
    .rad-icon-text-carousel-tier-1__carousel-card-image {
        min-width:106.6666666667px
    }
}

@media(min-width: 1441px) {
    .rad-icon-text-carousel-tier-1__carousel-card-image {
        min-height:calc(-0.22269px + 5.56715vw)
    }
}

@media(min-width: 1920px) {
    .rad-icon-text-carousel-tier-1__carousel-card-image {
        min-height:106.6666666667px
    }
}

@media(min-width: 1441px) {
    .rad-icon-text-carousel-tier-1__carousel-card-image {
        margin-bottom:calc(-0.06681px + 1.67015vw)
    }
}

@media(min-width: 1920px) {
    .rad-icon-text-carousel-tier-1__carousel-card-image {
        margin-bottom:32px
    }
}

@media(min-width: 1024px) {
    .rad-icon-text-carousel-tier-1__carousel-card-headline {
        margin-bottom:1rem
    }
}

@media(min-width: 1441px) {
    .rad-icon-text-carousel-tier-1__carousel-card-headline {
        margin-bottom:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-icon-text-carousel-tier-1__carousel-card-headline {
        margin-bottom:1.3333333333rem
    }
}

@media(min-width: 1024px) {
    .rad-icon-text-carousel-tier-1__carousel-card-eyebrow {
        margin-bottom:1rem
    }
}

@media(min-width: 1441px) {
    .rad-icon-text-carousel-tier-1__carousel-card-eyebrow {
        margin-bottom:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-icon-text-carousel-tier-1__carousel-card-eyebrow {
        margin-bottom:1.3333333333rem
    }
}

@media(min-width: 1024px) {
    .rad-icon-text-carousel-tier-1__carousel-card-body {
        margin-bottom:0.75rem
    }
}

@media(min-width: 1441px) {
    .rad-icon-text-carousel-tier-1__carousel-card-body {
        margin-bottom:calc(0.75rem + 0.83507vw - 12.0334px)
    }
}

@media(min-width: 1920px) {
    .rad-icon-text-carousel-tier-1__carousel-card-body {
        margin-bottom:1rem
    }
}

@media(min-width: 1024px) {
    .rad-icon-text-carousel-tier-1+.rad-icon-text-carousel-tier-2 {
        margin-top:120px
    }
}

@media(min-width: 1441px) {
    .rad-icon-text-carousel-tier-1+.rad-icon-text-carousel-tier-2 {
        margin-top:calc(-0.33403px + 8.35073vw)
    }
}

@media(min-width: 1920px) {
    .rad-icon-text-carousel-tier-1+.rad-icon-text-carousel-tier-2 {
        margin-top:160px
    }
}

@media(min-width: 1024px) {
    .rad-icon-text-carousel-tier-1+.rad-icon-text-carousel-tier-2 .rad-icon-text-carousel-tier-2__headline {
        margin-bottom:2.5rem
    }
}

@media(min-width: 1441px) {
    .rad-icon-text-carousel-tier-1+.rad-icon-text-carousel-tier-2 .rad-icon-text-carousel-tier-2__headline {
        margin-bottom:calc(2.5rem + 2.78358vw - 40.11134px)
    }
}

@media(min-width: 1920px) {
    .rad-icon-text-carousel-tier-1+.rad-icon-text-carousel-tier-2 .rad-icon-text-carousel-tier-2__headline {
        margin-bottom:3.3333333333rem
    }
}

@media(min-width: 1024px) {
    .rad-icon-text-carousel-tier-2__headline {
        margin-bottom:3rem
    }
}

@media(min-width: 1441px) {
    .rad-icon-text-carousel-tier-2__headline {
        margin-bottom:calc(3rem + 3.34029vw - 48.13361px)
    }
}

@media(min-width: 1920px) {
    .rad-icon-text-carousel-tier-2__headline {
        margin-bottom:4rem
    }
}

@media(min-width: 1024px) {
    .rad-icon-text-carousel-tier-2__header {
        width:calc(225% - var(--rad-spacing-sm)*3/4*9 + var(--rad-spacing-sm)*8 + var(--rad-spacing-sm)*0);
        width: calc(112.5% - var(--rad-spacing-sm)*7/8*9 + var(--rad-spacing-sm)*8 + var(--rad-spacing-md)*0);
        width: calc(75% - var(--rad-spacing-sm)*11/12*9 + var(--rad-spacing-sm)*8 + var(--rad-spacing-md)*0)
    }

    .rad-icon-text-carousel-tier-2__grid {
        -webkit-column-gap: 12.5%;
        -moz-column-gap: 12.5%;
        column-gap: 12.5%;
        grid-template-columns: repeat(5,10%);
        row-gap: 48px
    }
}

@media(min-width: 1441px) {
    .rad-icon-text-carousel-tier-2__grid {
        row-gap:calc(-0.13361px + 3.34029vw)
    }
}

@media(min-width: 1920px) {
    .rad-icon-text-carousel-tier-2__grid {
        row-gap:64px
    }
}

@-webkit-keyframes filters-vertical-skeleton-loading {
    0%,5.4% {
        opacity: .66
    }

    35.12%,35.17% {
        opacity: 1
    }

    64.88%,70.29% {
        opacity: .8
    }

    to {
        opacity: .66
    }
}

@keyframes filters-vertical-skeleton-loading {
    0%,5.4% {
        opacity: .66
    }

    35.12%,35.17% {
        opacity: 1
    }

    64.88%,70.29% {
        opacity: .8
    }

    to {
        opacity: .66
    }
}

.rad-job-search {
    padding-bottom: 24px;
    padding-left: var(--rad-spacing-sm);
    padding-right: var(--rad-spacing-sm)
}

@media(min-width: 600px) {
    .rad-job-search {
        padding-left:var(--rad-spacing-md);
        padding-right: var(--rad-spacing-md)
    }
}

.rad-component--nested .rad-job-search {
    padding-left: 0;
    padding-right: 0
}

@media(min-width: 600px) {
    .rad-job-search {
        padding-bottom:0
    }
}

.rad-job-search__live-region {
    clip: rect(0 0 0 0);
    -webkit-clip-path: inset(50%);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px
}

.rad-job-search__filters-and-cards {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column
}

@media(min-width: 1024px) {
    .rad-job-search__filters-and-cards {
        -webkit-box-orient:horizontal;
        -webkit-box-direction: normal;
        -webkit-column-gap: var(--rad-spacing-sm);
        -moz-column-gap: var(--rad-spacing-sm);
        column-gap: var(--rad-spacing-sm);
        -ms-flex-direction: row;
        flex-direction: row
    }
}

.rad-job-search .rad-job-search__filters-toggle-button-count:empty {
    display: none
}

.rad-job-search .rad-job-search__filters-toggle-button-count:before {
    content: "(";
    display: inline
}

.rad-job-search .rad-job-search__filters-toggle-button-count:after {
    content: ")";
    display: inline
}

@media(min-width: 1024px) {
    .rad-job-search .rad-filters-vertical {
        height:-webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        max-height: calc(100svh - var(--rad-nav-height));
        overflow: auto;
        padding-right: 20px;
        position: sticky;
        scrollbar-color: #a100ff #460073;
        scrollbar-width: thin;
        top: var(--rad-nav-height);
        -webkit-transition-duration: 550ms;
        transition-duration: 550ms;
        -webkit-transition-property: max-height,top;
        transition-property: max-height,top;
        width: calc(100% - var(--rad-spacing-sm)*3/4*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-sm)*0);
        width: calc(50% - var(--rad-spacing-sm)*7/8*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0);
        width: calc(33.33333% - var(--rad-spacing-sm)*11/12*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-job-search .rad-filters-vertical::-webkit-scrollbar {
        height:4px;
        width: 4px
    }

    .rad-job-search .rad-filters-vertical::-webkit-scrollbar-track {
        background-color: #460073
    }

    .rad-job-search .rad-filters-vertical::-webkit-scrollbar-thumb {
        background-color: #a100ff
    }
}

@media(min-width: 1441px) {
    .rad-job-search .rad-filters-vertical {
        padding-right:calc(-0.05567px + 1.39179vw)
    }
}

@media(min-width: 1920px) {
    .rad-job-search .rad-filters-vertical {
        padding-right:26.6666666667px
    }
}

@media(min-width: 1024px) {
    .rad-global-header--offscreen .rad-job-search .rad-filters-vertical {
        max-height:100svh;
        top: 0
    }

    .rad-global-header--offscreen.rad--has-subnav .rad-job-search .rad-filters-vertical {
        max-height: calc(100svh - 3.25rem);
        top: 3.25rem
    }

    .rad--has-subnav .rad-job-search .rad-filters-vertical {
        max-height: calc(100svh - var(--rad-nav-height) - 3.25rem);
        top: calc(var(--rad-nav-height) + 3.25rem)
    }
}

.rad-job-search .rad-job-cards__column {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    row-gap: var(--rad-spacing-sm)
}

@media(min-width: 1024px) {
    .rad-job-search .rad-job-cards__column {
        width:calc(200% - var(--rad-spacing-sm)*3/4*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-sm)*0);
        width: calc(100% - var(--rad-spacing-sm)*7/8*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0);
        width: calc(66.66667% - var(--rad-spacing-sm)*11/12*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0)
    }
}

.rad-job-search .rad-job-cards__results-count-and-sort {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    align-items: center;
    display: none;
    justify-content: flex-end;
    padding-bottom: 1rem;
    padding-top: 1rem
}

@media(min-width: 1441px) {
    .rad-job-search .rad-job-cards__results-count-and-sort {
        padding-bottom:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-job-search .rad-job-cards__results-count-and-sort {
        padding-bottom:1.3333333333rem
    }
}

@media(min-width: 1441px) {
    .rad-job-search .rad-job-cards__results-count-and-sort {
        padding-top:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-job-search .rad-job-cards__results-count-and-sort {
        padding-top:1.3333333333rem
    }
}

@media(min-width: 1024px) {
    .rad-job-search .rad-job-cards__results-count-and-sort {
        display:-webkit-box;
        display: -ms-flexbox;
        display: flex
    }
}

.rad-job-search .rad-job-cards__results-text,.rad-job-search .rad-job-cards__sort-dropdown .rad-dropdown__category-title {
    font-size: .875rem;
    font-weight: 500;
    font-weight: 400;
    letter-spacing: -0.005em;
    line-height: 1.5;
    text-underline-offset: .25rem
}

.rad-job-search .rad-job-cards__results-text:lang(ja-JP),.rad-job-search .rad-job-cards__results-text:lang(zh-CN),.rad-job-search .rad-job-cards__sort-dropdown .rad-dropdown__category-title:lang(ja-JP),.rad-job-search .rad-job-cards__sort-dropdown .rad-dropdown__category-title:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-job-search .rad-job-cards__results-text,.rad-job-search .rad-job-cards__sort-dropdown .rad-dropdown__category-title {
        font-size:1rem
    }
}

@media(min-width: 1441px) {
    .rad-job-search .rad-job-cards__results-text,.rad-job-search .rad-job-cards__sort-dropdown .rad-dropdown__category-title {
        font-size:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-job-search .rad-job-cards__results-text,.rad-job-search .rad-job-cards__sort-dropdown .rad-dropdown__category-title {
        font-size:1.3333333333rem
    }
}

.rad-job-search .rad-job-cards__results-text:lang(ja-JP),.rad-job-search .rad-job-cards__results-text:lang(zh-CN),.rad-job-search .rad-job-cards__sort-dropdown .rad-dropdown__category-title:lang(ja-JP),.rad-job-search .rad-job-cards__sort-dropdown .rad-dropdown__category-title:lang(zh-CN) {
    font-weight: 400
}

.rad-job-search .rad-job-cards__results-text {
    border-right: 1px solid #a100ff;
    margin-right: 12px;
    padding-right: 12px
}

@media(min-width: 1441px) {
    .rad-job-search .rad-job-cards__results-text {
        margin-right:calc(-0.0334px + 0.83507vw)
    }
}

@media(min-width: 1920px) {
    .rad-job-search .rad-job-cards__results-text {
        margin-right:16px
    }
}

@media(min-width: 1441px) {
    .rad-job-search .rad-job-cards__results-text {
        padding-right:calc(-0.0334px + 0.83507vw)
    }
}

@media(min-width: 1920px) {
    .rad-job-search .rad-job-cards__results-text {
        padding-right:16px
    }
}

.rad-job-search .rad-job-cards__sort-dropdown--disabled {
    color: #616160;
    pointer-events: none
}

.rad-job-search .rad-job-cards__list {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    row-gap: 1rem
}

@media(min-width: 1024px) {
    .rad-job-search .rad-job-cards__list {
        row-gap:1.5rem
    }
}

@media(min-width: 1441px) {
    .rad-job-search .rad-job-cards__list {
        row-gap:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-job-search .rad-job-cards__list {
        row-gap:2rem
    }
}

@media(min-width: 600px) {
    .rad-job-search__pagination {
        -ms-flex-item-align:end;
        align-self: flex-end
    }
}

.rad-job-search__pagination--hidden {
    display: none
}

@media(min-width: 1024px) {
    .rad-job-search__search-container {
        margin-left:-5rem;
        margin-right: -5rem
    }
}

@media(min-width: 1441px) {
    .rad-job-search__search-container {
        margin-left:calc(-5rem - 5.56715vw + 80.22269px)
    }
}

@media(min-width: 1920px) {
    .rad-job-search__search-container {
        margin-left:-6.6666666667rem
    }
}

@media(min-width: 1441px) {
    .rad-job-search__search-container {
        margin-right:calc(-5rem - 5.56715vw + 80.22269px)
    }
}

@media(min-width: 1920px) {
    .rad-job-search__search-container {
        margin-right:-6.6666666667rem
    }
}

.rad-job-search__no-results {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    display: none;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 3rem 1rem
}

@media(min-width: 600px) {
    .rad-job-search__no-results {
        margin:0 auto;
        padding: 3.75rem 0;
        width: calc(min(100vw, 1920px)/4*6 - var(--rad-spacing-sm)*2/4*6 - var(--rad-spacing-sm)*3/4*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-sm)*0);
        width: calc(min(100vw, 1920px)/8*6 - var(--rad-spacing-md)*2/8*6 - var(--rad-spacing-sm)*7/8*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-job-search__no-results {
        width:calc(min(100vw, 1920px)/12*6 - var(--rad-spacing-md)*2/12*6 - var(--rad-spacing-sm)*11/12*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0)
    }
}

.rad-job-search__no-results-text-content {
    font-size: .875rem;
    font-weight: 500;
    font-weight: 400;
    letter-spacing: -0.005em;
    line-height: 1.5;
    text-align: center;
    text-underline-offset: .25rem
}

.rad-job-search__no-results-text-content:lang(ja-JP),.rad-job-search__no-results-text-content:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-job-search__no-results-text-content {
        font-size:1rem
    }
}

@media(min-width: 1441px) {
    .rad-job-search__no-results-text-content {
        font-size:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-job-search__no-results-text-content {
        font-size:1.3333333333rem
    }
}

.rad-job-search__no-results-text-content:lang(ja-JP),.rad-job-search__no-results-text-content:lang(zh-CN) {
    font-weight: 400
}

.rad-job-search__no-results-text-content .no-results__new-search-button {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex
}

.rad-job-search__no-results-text-content .rad-job-search__alert-signup-link {
    color: #fff;
    display: block;
    font-size: .875rem;
    font-weight: 500;
    letter-spacing: -0.005em;
    line-height: 1.5;
    margin: .25rem 0 1.5rem;
    text-underline-offset: .25rem
}

.rad-job-search__no-results-text-content .rad-job-search__alert-signup-link:lang(ja-JP),.rad-job-search__no-results-text-content .rad-job-search__alert-signup-link:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-job-search__no-results-text-content .rad-job-search__alert-signup-link {
        font-size:1rem
    }
}

@media(min-width: 1441px) {
    .rad-job-search__no-results-text-content .rad-job-search__alert-signup-link {
        font-size:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-job-search__no-results-text-content .rad-job-search__alert-signup-link {
        font-size:1.3333333333rem
    }
}

.rad-job-search__no-results-graphic-content {
    padding-top: 3rem
}

@media(min-width: 600px) {
    .rad-job-search__no-results-graphic-content {
        -webkit-box-ordinal-group:0;
        -ms-flex-order: -1;
        margin-bottom: 1.5rem;
        order: -1;
        padding-top: 0
    }
}

.rad-job-search__no-results .cmp-image,.rad-job-search__no-results .no-results__lottie-player {
    height: 14.125rem;
    margin: 0 auto;
    width: 20rem
}

.rad-job-search__no-results--visible {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.rad-job-search__eeo-wrapper {
    font-size: .875rem;
    font-weight: 500;
    font-weight: 400;
    letter-spacing: -0.005em;
    line-height: 1.5;
    margin-top: 2rem;
    text-underline-offset: .25rem;
    width: 100%
}

.rad-job-search__eeo-wrapper:lang(ja-JP),.rad-job-search__eeo-wrapper:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-job-search__eeo-wrapper {
        font-size:1rem
    }
}

@media(min-width: 1024px) {
    .rad-job-search__eeo-wrapper {
        font-size:1.125rem;
        line-height: 1.6
    }
}

@media(min-width: 1441px) {
    .rad-job-search__eeo-wrapper {
        font-size:calc(1.125rem + 1.25261vw - 18.0501px)
    }
}

@media(min-width: 1920px) {
    .rad-job-search__eeo-wrapper {
        font-size:1.5rem
    }
}

.rad-job-search__eeo-wrapper:lang(ja-JP),.rad-job-search__eeo-wrapper:lang(zh-CN) {
    font-weight: 400
}

@media(min-width: 600px) {
    .rad-job-search__eeo-wrapper {
        margin-bottom:3rem;
        margin-top: 1.5rem
    }
}

@media(min-width: 1024px) {
    .rad-job-search__eeo-wrapper {
        margin-bottom:3.75rem;
        margin-top: 1rem
    }
}

@media(min-width: 1441px) {
    .rad-job-search__eeo-wrapper {
        margin-top:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-job-search__eeo-wrapper {
        margin-top:1.3333333333rem
    }
}

@media(min-width: 1441px) {
    .rad-job-search__eeo-wrapper {
        margin-bottom:calc(3.75rem + 4.17537vw - 60.16701px)
    }
}

@media(min-width: 1920px) {
    .rad-job-search__eeo-wrapper {
        margin-bottom:5rem
    }
}

.rad-job-search--filters-loading .rad-job-cards__results-text {
    -webkit-animation: filters-vertical-skeleton-loading 1851ms ease-in-out infinite;
    animation: filters-vertical-skeleton-loading 1851ms ease-in-out infinite;
    background-color: #202020;
    border-color: transparent;
    color: transparent;
    margin-right: 1.5rem;
    padding-right: 0;
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

@media(min-width: 1441px) {
    .rad-job-search--filters-loading .rad-job-cards__results-text {
        margin-right:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-job-search--filters-loading .rad-job-cards__results-text {
        margin-right:2rem
    }
}

.rad-job-search--filters-loading .rad-job-cards__results-text ::-webkit-input-placeholder,.rad-job-search--filters-loading .rad-job-cards__results-text::-webkit-input-placeholder {
    color: transparent
}

.rad-job-search--filters-loading .rad-job-cards__results-text ::-moz-placeholder,.rad-job-search--filters-loading .rad-job-cards__results-text::-moz-placeholder {
    color: transparent
}

.rad-job-search--filters-loading .rad-job-cards__results-text :-ms-input-placeholder,.rad-job-search--filters-loading .rad-job-cards__results-text:-ms-input-placeholder {
    color: transparent
}

.rad-job-search--filters-loading .rad-job-cards__results-text ::-ms-input-placeholder,.rad-job-search--filters-loading .rad-job-cards__results-text::-ms-input-placeholder {
    color: transparent
}

.rad-job-search--filters-loading .rad-job-cards__results-text ::placeholder,.rad-job-search--filters-loading .rad-job-cards__results-text::placeholder {
    color: transparent
}

.rad-job-search--filters-loading .rad-job-cards__sort-dropdown:before {
    border-left: 1px solid #a100ff;
    content: "";
    display: block;
    height: 1.5rem;
    left: -0.8125rem;
    position: absolute;
    top: 50%;
    translate: 0 -50%
}

@media(min-width: 1441px) {
    .rad-job-search--filters-loading .rad-job-cards__sort-dropdown:before {
        height:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-job-search--filters-loading .rad-job-cards__sort-dropdown:before {
        height:2rem
    }
}

@media(min-width: 1441px) {
    .rad-job-search--filters-loading .rad-job-cards__sort-dropdown:before {
        left:calc(-0.8125rem - 0.90466vw + 13.03619px)
    }
}

@media(min-width: 1920px) {
    .rad-job-search--filters-loading .rad-job-cards__sort-dropdown:before {
        left:-1.0833333333rem
    }
}

.rad-job-search .rad-snackbar__message--relevant {
    display: none
}

.rad-job-search .rad-snackbar__container--show-relevant .rad-snackbar__message--relevant {
    display: block
}

.rad-job-search .rad-snackbar__container--show-relevant .rad-snackbar__message--newest {
    display: none
}

.rad-filters-vertical {
    display: none;
    padding-bottom: 4.8125rem
}

@media(min-width: 600px) {
    .rad-filters-vertical {
        padding-bottom:5.5625rem
    }
}

@media(min-width: 1024px) {
    .rad-filters-vertical {
        display:block;
        padding-bottom: 0
    }
}

.rad-filters-vertical__mobile-filter-panel-header {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: space-between;
    padding-bottom: 1rem
}

@media(min-width: 600px) {
    .rad-filters-vertical__mobile-filter-panel-header {
        padding-bottom:1.5rem
    }
}

@media(min-width: 1024px) {
    .rad-filters-vertical__mobile-filter-panel-header {
        display:none
    }
}

.rad-filters-vertical__mobile-filter-panel-header .job-search__results-display {
    color: #a2a2a0;
    font-size: .75rem;
    font-weight: 500;
    font-weight: 400;
    letter-spacing: -0.005em;
    line-height: 1.5;
    text-underline-offset: .25rem
}

.rad-filters-vertical__mobile-filter-panel-header .job-search__results-display:lang(ja-JP),.rad-filters-vertical__mobile-filter-panel-header .job-search__results-display:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-filters-vertical__mobile-filter-panel-header .job-search__results-display {
        font-size:0.875rem
    }
}

@media(min-width: 1441px) {
    .rad-filters-vertical__mobile-filter-panel-header .job-search__results-display {
        font-size:calc(0.875rem + 0.97425vw - 14.03897px)
    }
}

@media(min-width: 1920px) {
    .rad-filters-vertical__mobile-filter-panel-header .job-search__results-display {
        font-size:1.1666666667rem
    }
}

.rad-filters-vertical__mobile-filter-panel-header .job-search__results-display:lang(ja-JP),.rad-filters-vertical__mobile-filter-panel-header .job-search__results-display:lang(zh-CN) {
    font-weight: 400
}

.rad-filters-vertical__mobile-filter-panel-header .rad-job-search__filters-toggle-button {
    font-size: .75rem;
    font-weight: 500;
    letter-spacing: -0.005em;
    line-height: 1.5;
    text-underline-offset: .25rem
}

.rad-filters-vertical__mobile-filter-panel-header .rad-job-search__filters-toggle-button:lang(ja-JP),.rad-filters-vertical__mobile-filter-panel-header .rad-job-search__filters-toggle-button:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-filters-vertical__mobile-filter-panel-header .rad-job-search__filters-toggle-button {
        font-size:0.875rem
    }
}

@media(min-width: 1441px) {
    .rad-filters-vertical__mobile-filter-panel-header .rad-job-search__filters-toggle-button {
        font-size:calc(0.875rem + 0.97425vw - 14.03897px)
    }
}

@media(min-width: 1920px) {
    .rad-filters-vertical__mobile-filter-panel-header .rad-job-search__filters-toggle-button {
        font-size:1.1666666667rem
    }
}

@media(min-width: 600px) {
    .rad-filters-vertical__mobile-filter-panel-header .rad-job-search__filters-toggle-button {
        font-size:.75rem
    }
}

.rad-filters-vertical__desktop-header {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    align-items: center;
    background-color: #000;
    border-bottom: 1px solid #a100ff;
    display: none;
    justify-content: space-between;
    padding-bottom: 16px;
    padding-top: 16px;
    position: sticky;
    top: 0;
    z-index: 1
}

@media(min-width: 1441px) {
    .rad-filters-vertical__desktop-header {
        padding-bottom:calc(-0.04454px + 1.11343vw)
    }
}

@media(min-width: 1920px) {
    .rad-filters-vertical__desktop-header {
        padding-bottom:21.3333333333px
    }
}

@media(min-width: 1441px) {
    .rad-filters-vertical__desktop-header {
        padding-top:calc(-0.04454px + 1.11343vw)
    }
}

@media(min-width: 1920px) {
    .rad-filters-vertical__desktop-header {
        padding-top:21.3333333333px
    }
}

@media(min-width: 1024px) {
    .rad-filters-vertical__desktop-header {
        display:-webkit-box;
        display: -ms-flexbox;
        display: flex
    }
}

.rad-filters-vertical__desktop-header .rad-filters-vertical__desktop-label {
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: -0.005em;
    line-height: 1.6;
    text-underline-offset: .25rem
}

.rad-filters-vertical__desktop-header .rad-filters-vertical__desktop-label:lang(ja-JP),.rad-filters-vertical__desktop-header .rad-filters-vertical__desktop-label:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-filters-vertical__desktop-header .rad-filters-vertical__desktop-label {
        font-size:1.125rem
    }
}

@media(min-width: 1024px) {
    .rad-filters-vertical__desktop-header .rad-filters-vertical__desktop-label {
        font-size:1.25rem
    }
}

@media(min-width: 1441px) {
    .rad-filters-vertical__desktop-header .rad-filters-vertical__desktop-label {
        font-size:calc(1.25rem + 1.39179vw - 20.05567px)
    }
}

@media(min-width: 1920px) {
    .rad-filters-vertical__desktop-header .rad-filters-vertical__desktop-label {
        font-size:1.6666666667rem
    }
}

.rad-filters-vertical__desktop-header .rad-filters-vertical__desktop-label-count:empty {
    display: none
}

.rad-filters-vertical__desktop-header .rad-filters-vertical__desktop-label-count:before {
    content: "(";
    display: inline
}

.rad-filters-vertical__desktop-header .rad-filters-vertical__desktop-label-count:after {
    content: ")";
    display: inline
}

.rad-filters-vertical--open {
    background-color: #000;
    bottom: 0;
    display: block;
    left: 0;
    overflow: auto;
    position: fixed;
    right: 0;
    scrollbar-color: #a100ff #460073;
    scrollbar-width: thin;
    top: 0;
    z-index: 1110
}

.rad-filters-vertical--open::-webkit-scrollbar {
    height: 4px;
    width: 4px
}

.rad-filters-vertical--open::-webkit-scrollbar-track {
    background-color: #460073
}

.rad-filters-vertical--open::-webkit-scrollbar-thumb {
    background-color: #a100ff
}

.rad-filters-vertical .rad-filters-accordion-wrapper {
    border-bottom: 1px solid #a100ff;
    margin: 0 1rem
}

.rad-filters-vertical .rad-filters-accordion-wrapper .rad-accordion-atom__content {
    padding-bottom: 2px;
    padding-top: 2px
}

@media(min-width: 600px) {
    .rad-filters-vertical .rad-filters-accordion-wrapper {
        margin:0 3rem
    }
}

@media(min-width: 1024px) {
    .rad-filters-vertical .rad-filters-accordion-wrapper {
        margin:0
    }

    .rad-filters-vertical .rad-filters-accordion-wrapper .rad-accordion-atom__content {
        padding-bottom: 1.5rem
    }
}

.rad-job-search--filters-loading .rad-filters-vertical .rad-filters-accordion-wrapper {
    border-color: transparent
}

.rad-filters-vertical .filters-vertical__accordion-toggle {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-color: #000;
    border-style: solid;
    border-width: 1.09375rem 1rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-size: .875rem;
    font-weight: 500;
    letter-spacing: -0.005em;
    line-height: 1.5;
    text-underline-offset: .25rem;
    width: 100%
}

.rad-filters-vertical .filters-vertical__accordion-toggle:lang(ja-JP),.rad-filters-vertical .filters-vertical__accordion-toggle:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-filters-vertical .filters-vertical__accordion-toggle {
        font-size:1rem
    }
}

@media(min-width: 1441px) {
    .rad-filters-vertical .filters-vertical__accordion-toggle {
        font-size:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-filters-vertical .filters-vertical__accordion-toggle {
        font-size:1.3333333333rem
    }
}

.rad-job-search--filters-loading .rad-filters-vertical .filters-vertical__accordion-toggle {
    -webkit-animation: filters-vertical-skeleton-loading 1851ms ease-in-out infinite;
    animation: filters-vertical-skeleton-loading 1851ms ease-in-out infinite;
    background-color: #202020;
    color: transparent;
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.rad-job-search--filters-loading .rad-filters-vertical .filters-vertical__accordion-toggle ::-webkit-input-placeholder,.rad-job-search--filters-loading .rad-filters-vertical .filters-vertical__accordion-toggle::-webkit-input-placeholder {
    color: transparent
}

.rad-job-search--filters-loading .rad-filters-vertical .filters-vertical__accordion-toggle ::-moz-placeholder,.rad-job-search--filters-loading .rad-filters-vertical .filters-vertical__accordion-toggle::-moz-placeholder {
    color: transparent
}

.rad-job-search--filters-loading .rad-filters-vertical .filters-vertical__accordion-toggle :-ms-input-placeholder,.rad-job-search--filters-loading .rad-filters-vertical .filters-vertical__accordion-toggle:-ms-input-placeholder {
    color: transparent
}

.rad-job-search--filters-loading .rad-filters-vertical .filters-vertical__accordion-toggle ::-ms-input-placeholder,.rad-job-search--filters-loading .rad-filters-vertical .filters-vertical__accordion-toggle::-ms-input-placeholder {
    color: transparent
}

.rad-job-search--filters-loading .rad-filters-vertical .filters-vertical__accordion-toggle ::placeholder,.rad-job-search--filters-loading .rad-filters-vertical .filters-vertical__accordion-toggle::placeholder {
    color: transparent
}

@media(min-width: 600px) {
    .rad-filters-vertical .filters-vertical__accordion-toggle {
        border-width:1rem
    }
}

@media(min-width: 1024px) {
    .rad-filters-vertical .filters-vertical__accordion-toggle {
        border-width:1rem 0.75rem 1rem 0
    }
}

@media(min-width: 1441px) {
    .rad-filters-vertical .filters-vertical__accordion-toggle {
        border-bottom-width:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-filters-vertical .filters-vertical__accordion-toggle {
        border-bottom-width:1.3333333333rem
    }
}

@media(min-width: 1441px) {
    .rad-filters-vertical .filters-vertical__accordion-toggle {
        border-right-width:calc(0.75rem + 0.83507vw - 12.0334px)
    }
}

@media(min-width: 1920px) {
    .rad-filters-vertical .filters-vertical__accordion-toggle {
        border-right-width:1rem
    }
}

@media(min-width: 1441px) {
    .rad-filters-vertical .filters-vertical__accordion-toggle {
        border-top-width:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-filters-vertical .filters-vertical__accordion-toggle {
        border-top-width:1.3333333333rem
    }
}

.rad-filters-vertical .filters-vertical__accordion-toggle:focus-visible {
    outline-offset: -2px
}

.rad-filters-vertical .filters-vertical__accordion-toggle-count {
    color: #a2a2a0;
    font-size: .75rem;
    font-weight: 500;
    font-weight: 400;
    letter-spacing: -0.005em;
    line-height: 1.5;
    margin-left: .5rem;
    text-underline-offset: .25rem
}

.rad-filters-vertical .filters-vertical__accordion-toggle-count:lang(ja-JP),.rad-filters-vertical .filters-vertical__accordion-toggle-count:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-filters-vertical .filters-vertical__accordion-toggle-count {
        font-size:0.875rem
    }
}

@media(min-width: 1441px) {
    .rad-filters-vertical .filters-vertical__accordion-toggle-count {
        font-size:calc(0.875rem + 0.97425vw - 14.03897px)
    }
}

@media(min-width: 1920px) {
    .rad-filters-vertical .filters-vertical__accordion-toggle-count {
        font-size:1.1666666667rem
    }
}

.rad-filters-vertical .filters-vertical__accordion-toggle-count:lang(ja-JP),.rad-filters-vertical .filters-vertical__accordion-toggle-count:lang(zh-CN) {
    font-weight: 400
}

@media(min-width: 1024px) {
    .rad-filters-vertical .filters-vertical__accordion-toggle-count {
        display:none
    }
}

.rad-filters-vertical .filters-vertical__accordion-toggle-count:empty {
    display: none
}

.rad-filters-vertical .filters-vertical__accordion-toggle-count:before {
    content: "(";
    display: inline
}

.rad-filters-vertical .filters-vertical__accordion-toggle-count:after {
    content: ")";
    display: inline
}

.rad-filters-vertical .filters-vertical__accordion-toggle .rad-accordion-atom__toggle-icon {
    height: 1.25rem;
    margin-left: auto;
    outline: none;
    position: relative;
    width: 1.25rem
}

.rad-filters-vertical .filters-vertical__accordion-toggle .rad-accordion-atom__toggle-icon:after,.rad-filters-vertical .filters-vertical__accordion-toggle .rad-accordion-atom__toggle-icon:before {
    background-color: #fff;
    content: "";
    display: block;
    height: 2px;
    left: 50%;
    margin: 0 auto;
    position: absolute;
    top: 50%;
    -webkit-transition-duration: 550ms;
    transition-duration: 550ms;
    -webkit-transition-property: rotate;
    transition-property: rotate;
    -webkit-transition-timing-function: cubic-bezier(0.85,0,0,1);
    transition-timing-function: cubic-bezier(0.85,0,0,1);
    translate: -50% -50%;
    width: .875rem
}

.rad-filters-vertical .filters-vertical__accordion-toggle .rad-accordion-atom__toggle-icon:after {
    rotate: -90deg
}

.rad-filters-vertical .filters-vertical__accordion-toggle.rad-accordion-atom__toggle--open .rad-accordion-atom__toggle-icon:after {
    rotate: 0deg
}

.rad-job-search--cards-loading .rad-filters-vertical .filters-vertical__accordion-toggle .rad-accordion-atom__toggle-icon,.rad-job-search--filters-loading .rad-filters-vertical .filters-vertical__accordion-toggle .rad-accordion-atom__toggle-icon {
    opacity: 0
}

.rad-filters-vertical .rad-filter-list-items {
    padding: 0 1rem
}

@media(min-width: 1024px) {
    .rad-filters-vertical .rad-filter-list-items {
        padding-left:0
    }
}

.rad-job-search--filters-loading .rad-filters-vertical .rad-filter-list-items .rad-filter-list-item__checkbox+.rad-filter-list-item__label .rad-filter-list-item__faux-checkbox,.rad-job-search--filters-loading .rad-filters-vertical .rad-filter-list-items .rad-filter-list-item__count,.rad-job-search--filters-loading .rad-filters-vertical .rad-filter-list-items .rad-filter-list-item__text {
    -webkit-animation: filters-vertical-skeleton-loading 1851ms ease-in-out infinite;
    animation: filters-vertical-skeleton-loading 1851ms ease-in-out infinite;
    background-color: #202020;
    color: transparent;
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.rad-job-search--filters-loading .rad-filters-vertical .rad-filter-list-items .rad-filter-list-item__checkbox+.rad-filter-list-item__label .rad-filter-list-item__faux-checkbox ::-webkit-input-placeholder,.rad-job-search--filters-loading .rad-filters-vertical .rad-filter-list-items .rad-filter-list-item__checkbox+.rad-filter-list-item__label .rad-filter-list-item__faux-checkbox::-webkit-input-placeholder,.rad-job-search--filters-loading .rad-filters-vertical .rad-filter-list-items .rad-filter-list-item__count ::-webkit-input-placeholder,.rad-job-search--filters-loading .rad-filters-vertical .rad-filter-list-items .rad-filter-list-item__count::-webkit-input-placeholder,.rad-job-search--filters-loading .rad-filters-vertical .rad-filter-list-items .rad-filter-list-item__text ::-webkit-input-placeholder,.rad-job-search--filters-loading .rad-filters-vertical .rad-filter-list-items .rad-filter-list-item__text::-webkit-input-placeholder {
    color: transparent
}

.rad-job-search--filters-loading .rad-filters-vertical .rad-filter-list-items .rad-filter-list-item__checkbox+.rad-filter-list-item__label .rad-filter-list-item__faux-checkbox ::-moz-placeholder,.rad-job-search--filters-loading .rad-filters-vertical .rad-filter-list-items .rad-filter-list-item__checkbox+.rad-filter-list-item__label .rad-filter-list-item__faux-checkbox::-moz-placeholder,.rad-job-search--filters-loading .rad-filters-vertical .rad-filter-list-items .rad-filter-list-item__count ::-moz-placeholder,.rad-job-search--filters-loading .rad-filters-vertical .rad-filter-list-items .rad-filter-list-item__count::-moz-placeholder,.rad-job-search--filters-loading .rad-filters-vertical .rad-filter-list-items .rad-filter-list-item__text ::-moz-placeholder,.rad-job-search--filters-loading .rad-filters-vertical .rad-filter-list-items .rad-filter-list-item__text::-moz-placeholder {
    color: transparent
}

.rad-job-search--filters-loading .rad-filters-vertical .rad-filter-list-items .rad-filter-list-item__checkbox+.rad-filter-list-item__label .rad-filter-list-item__faux-checkbox :-ms-input-placeholder,.rad-job-search--filters-loading .rad-filters-vertical .rad-filter-list-items .rad-filter-list-item__checkbox+.rad-filter-list-item__label .rad-filter-list-item__faux-checkbox:-ms-input-placeholder,.rad-job-search--filters-loading .rad-filters-vertical .rad-filter-list-items .rad-filter-list-item__count :-ms-input-placeholder,.rad-job-search--filters-loading .rad-filters-vertical .rad-filter-list-items .rad-filter-list-item__count:-ms-input-placeholder,.rad-job-search--filters-loading .rad-filters-vertical .rad-filter-list-items .rad-filter-list-item__text :-ms-input-placeholder,.rad-job-search--filters-loading .rad-filters-vertical .rad-filter-list-items .rad-filter-list-item__text:-ms-input-placeholder {
    color: transparent
}

.rad-job-search--filters-loading .rad-filters-vertical .rad-filter-list-items .rad-filter-list-item__checkbox+.rad-filter-list-item__label .rad-filter-list-item__faux-checkbox ::-ms-input-placeholder,.rad-job-search--filters-loading .rad-filters-vertical .rad-filter-list-items .rad-filter-list-item__checkbox+.rad-filter-list-item__label .rad-filter-list-item__faux-checkbox::-ms-input-placeholder,.rad-job-search--filters-loading .rad-filters-vertical .rad-filter-list-items .rad-filter-list-item__count ::-ms-input-placeholder,.rad-job-search--filters-loading .rad-filters-vertical .rad-filter-list-items .rad-filter-list-item__count::-ms-input-placeholder,.rad-job-search--filters-loading .rad-filters-vertical .rad-filter-list-items .rad-filter-list-item__text ::-ms-input-placeholder,.rad-job-search--filters-loading .rad-filters-vertical .rad-filter-list-items .rad-filter-list-item__text::-ms-input-placeholder {
    color: transparent
}

.rad-job-search--filters-loading .rad-filters-vertical .rad-filter-list-items .rad-filter-list-item__checkbox+.rad-filter-list-item__label .rad-filter-list-item__faux-checkbox ::placeholder,.rad-job-search--filters-loading .rad-filters-vertical .rad-filter-list-items .rad-filter-list-item__checkbox+.rad-filter-list-item__label .rad-filter-list-item__faux-checkbox::placeholder,.rad-job-search--filters-loading .rad-filters-vertical .rad-filter-list-items .rad-filter-list-item__count ::placeholder,.rad-job-search--filters-loading .rad-filters-vertical .rad-filter-list-items .rad-filter-list-item__count::placeholder,.rad-job-search--filters-loading .rad-filters-vertical .rad-filter-list-items .rad-filter-list-item__text ::placeholder,.rad-job-search--filters-loading .rad-filters-vertical .rad-filter-list-items .rad-filter-list-item__text::placeholder {
    color: transparent
}

.rad-job-search--filters-loading .rad-filters-vertical .rad-filter-list-items .rad-filter-list-item__checkbox+.rad-filter-list-item__label .rad-filter-list-item__faux-checkbox,.rad-job-search--filters-loading .rad-filters-vertical .rad-filter-list-items .rad-filter-list-item__count,.rad-job-search--filters-loading .rad-filters-vertical .rad-filter-list-items .rad-filter-list-item__text {
    height: 18px
}

@media(min-width: 1441px) {
    .rad-job-search--filters-loading .rad-filters-vertical .rad-filter-list-items .rad-filter-list-item__checkbox+.rad-filter-list-item__label .rad-filter-list-item__faux-checkbox,.rad-job-search--filters-loading .rad-filters-vertical .rad-filter-list-items .rad-filter-list-item__count,.rad-job-search--filters-loading .rad-filters-vertical .rad-filter-list-items .rad-filter-list-item__text {
        height:calc(-0.0501px + 1.25261vw)
    }
}

@media(min-width: 1920px) {
    .rad-job-search--filters-loading .rad-filters-vertical .rad-filter-list-items .rad-filter-list-item__checkbox+.rad-filter-list-item__label .rad-filter-list-item__faux-checkbox,.rad-job-search--filters-loading .rad-filters-vertical .rad-filter-list-items .rad-filter-list-item__count,.rad-job-search--filters-loading .rad-filters-vertical .rad-filter-list-items .rad-filter-list-item__text {
        height:24px
    }
}

.rad-job-search--filters-loading .rad-filters-vertical .rad-filter-list-items .rad-filter-list-item__checkbox,.rad-job-search--filters-loading .rad-filters-vertical .rad-filter-list-items .rad-filter-list-item__label {
    outline: none;
    pointer-events: none
}

.rad-job-search--filters-loading .rad-filters-vertical .rad-filter-list-items .rad-filter-list-item__faux-checkbox {
    border: 0
}

.rad-filters-vertical .mobile-filter-panel__topper {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    border-bottom: 1px solid #616160;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    padding: 1rem
}

@media(min-width: 600px) {
    .rad-filters-vertical .mobile-filter-panel__topper {
        margin-bottom:1rem;
        padding: 1rem 3rem
    }
}

@media(min-width: 1024px) {
    .rad-filters-vertical .mobile-filter-panel__topper {
        display:none
    }
}

.rad-filters-vertical .mobile-filter-panel__mobile-active-tags {
    display: block;
    padding: 0 1rem 2rem
}

@media(min-width: 600px) {
    .rad-filters-vertical .mobile-filter-panel__mobile-active-tags {
        margin-top:1.5rem;
        padding: 0 3rem 2rem
    }
}

@media(min-width: 1024px) {
    .rad-filters-vertical .mobile-filter-panel__mobile-active-tags {
        display:none
    }
}

.rad-filters-vertical .mobile-filter-panel__mobile-active-tags--empty {
    display: none
}

.rad-filters-vertical .mobile-filter-panel__mobile-active-tags:not(.rad-filters-vertical .mobile-filter-panel__mobile-active-tags--empty)+.rad-location-search-block {
    border-top: 1px solid #a100ff
}

@media(min-width: 1024px) {
    .rad-filters-vertical .mobile-filter-panel__mobile-active-tags:not(.rad-filters-vertical .mobile-filter-panel__mobile-active-tags--empty)+.rad-location-search-block {
        border-top:none
    }
}

.rad-filters-vertical .mobile-filter-panel__mobile-active-tags .rad-tag__count {
    display: none
}

.rad-filters-vertical .location-filter-panel__bottom,.rad-filters-vertical .mobile-filter-panel__bottom {
    border-top: 1px solid #616160;
    padding: 1rem 1rem 1.5rem;
    z-index: 1
}

.rad-filters-vertical .location-filter-panel__bottom .rad-button--primary,.rad-filters-vertical .location-filter-panel__bottom .rad-button--primary .rad-button__text,.rad-filters-vertical .mobile-filter-panel__bottom .rad-button--primary,.rad-filters-vertical .mobile-filter-panel__bottom .rad-button--primary .rad-button__text {
    width: 100%
}

@media(min-width: 600px) {
    .rad-filters-vertical .location-filter-panel__bottom,.rad-filters-vertical .mobile-filter-panel__bottom {
        padding:1rem 3rem 1.5rem
    }
}

@media(min-width: 1024px) {
    .rad-filters-vertical .location-filter-panel__bottom,.rad-filters-vertical .mobile-filter-panel__bottom {
        display:none
    }
}

.rad-filters-vertical .mobile-filter-panel__bottom {
    background-color: #000;
    bottom: 0;
    position: fixed;
    width: 100%
}

.rad-filters-vertical .mobile-filter-panel__bottom.mobile-filter-panel__bottom--hide {
    display: none
}

.rad-filters-vertical .mobile-filters__sort-by .rad-accordion-atom__content {
    padding-bottom: 1.5rem
}

@media(min-width: 1024px) {
    .rad-filters-vertical .mobile-filters__sort-by {
        display:none
    }
}

.rad-filters-vertical .location-filter-panel__scroller {
    max-height: calc(100dvh - 9.375rem);
    overflow: auto;
    padding: 0 1rem;
    scrollbar-color: #a100ff #460073;
    scrollbar-width: thin
}

.rad-filters-vertical .location-filter-panel__scroller::-webkit-scrollbar {
    height: 4px;
    width: 4px
}

.rad-filters-vertical .location-filter-panel__scroller::-webkit-scrollbar-track {
    background-color: #460073
}

.rad-filters-vertical .location-filter-panel__scroller::-webkit-scrollbar-thumb {
    background-color: #a100ff
}

@media(min-width: 600px) {
    .rad-filters-vertical .location-filter-panel__scroller {
        max-height:calc(100dvh - 10.125rem);
        padding: 1.5rem 3rem 0
    }
}

@media(min-width: 1024px) {
    .rad-filters-vertical .location-filter-panel__scroller {
        max-height:unset;
        overflow: visible;
        padding: .5rem .0625rem 0
    }
}

.rad-filters-vertical .location-filter-panel__count:empty {
    display: none
}

.rad-filters-vertical .location-filter-panel__count:before {
    content: "(";
    display: inline
}

.rad-filters-vertical .location-filter-panel__count:after {
    content: ")";
    display: inline
}

.rad-filters-vertical .filters-vertical__mobile-location-launch {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #202020;
    color: #a2a2a0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: .5rem;
    justify-content: flex-start;
    margin: 1rem 1rem 1.5rem;
    padding: 1rem .5rem;
    text-align: left;
    width: calc(100% - 2rem)
}

@media(min-width: 600px) {
    .rad-filters-vertical .filters-vertical__mobile-location-launch {
        margin-left:0;
        margin-right: 0;
        width: 100%
    }
}

@media(min-width: 1024px) {
    .rad-filters-vertical .filters-vertical__mobile-location-launch {
        display:none
    }
}

.rad-filters-vertical .filters-vertical__mobile-location-launch .rad-icon {
    color: #fff
}

.rad-filters-vertical .rad-location-search__panel {
    display: none
}

.rad-job-search--filters-loading .rad-filters-vertical .rad-location-search__panel .rad-textbox {
    -webkit-animation: filters-vertical-skeleton-loading 1851ms ease-in-out infinite;
    animation: filters-vertical-skeleton-loading 1851ms ease-in-out infinite;
    background-color: #202020;
    color: transparent;
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.rad-job-search--filters-loading .rad-filters-vertical .rad-location-search__panel .rad-textbox ::-webkit-input-placeholder,.rad-job-search--filters-loading .rad-filters-vertical .rad-location-search__panel .rad-textbox::-webkit-input-placeholder {
    color: transparent
}

.rad-job-search--filters-loading .rad-filters-vertical .rad-location-search__panel .rad-textbox ::-moz-placeholder,.rad-job-search--filters-loading .rad-filters-vertical .rad-location-search__panel .rad-textbox::-moz-placeholder {
    color: transparent
}

.rad-job-search--filters-loading .rad-filters-vertical .rad-location-search__panel .rad-textbox :-ms-input-placeholder,.rad-job-search--filters-loading .rad-filters-vertical .rad-location-search__panel .rad-textbox:-ms-input-placeholder {
    color: transparent
}

.rad-job-search--filters-loading .rad-filters-vertical .rad-location-search__panel .rad-textbox ::-ms-input-placeholder,.rad-job-search--filters-loading .rad-filters-vertical .rad-location-search__panel .rad-textbox::-ms-input-placeholder {
    color: transparent
}

.rad-job-search--filters-loading .rad-filters-vertical .rad-location-search__panel .rad-textbox ::placeholder,.rad-job-search--filters-loading .rad-filters-vertical .rad-location-search__panel .rad-textbox::placeholder {
    color: transparent
}

.rad-filters-vertical .rad-location-search__panel .rad-textbox__label {
    clip: rect(0 0 0 0);
    -webkit-clip-path: inset(50%);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px
}

.rad-filters-vertical .rad-location-search__panel .location-filter-panel__desktop-active-tags {
    display: none;
    padding-top: 1.5rem
}

@media(min-width: 1024px) {
    .rad-filters-vertical .rad-location-search__panel .location-filter-panel__desktop-active-tags {
        display:block
    }
}

.rad-filters-vertical .rad-location-search__panel .location-filter-panel__desktop-active-tags--empty {
    display: none
}

@media(min-width: 600px) {
    .rad-filters-vertical .rad-location-search__panel .mobile-filter-panel__topper {
        margin-bottom:0
    }
}

.rad-filters-vertical .rad-location-search__panel.rad-location-search__panel--open {
    background-color: #000;
    bottom: 0;
    display: block;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1
}

@media(min-width: 1024px) {
    .rad-filters-vertical .rad-location-search__panel {
        display:block;
        padding-bottom: 0.5rem
    }
}

@media(min-width: 1441px) {
    .rad-filters-vertical .rad-location-search__panel {
        padding-bottom:calc(0.5rem + 0.55672vw - 8.02227px)
    }
}

@media(min-width: 1920px) {
    .rad-filters-vertical .rad-location-search__panel {
        padding-bottom:0.6666666667rem
    }
}

@media(min-width: 1024px) {
    .rad-job-search--filters-loading .rad-filters-vertical .rad-location-search__panel {
        margin-right:1rem
    }
}

@media(min-width: 1441px) {
    .rad-job-search--filters-loading .rad-filters-vertical .rad-location-search__panel {
        margin-right:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-job-search--filters-loading .rad-filters-vertical .rad-location-search__panel {
        margin-right:1.3333333333rem
    }
}

@media(min-width: 1024px) {
    .rad-filters-vertical .rad-location-search__panel.rad-location-search__panel--active {
        position:relative
    }

    .rad-filters-vertical .rad-location-search__panel.rad-location-search__panel--active .location-filter-panel__bottom {
        background-color: #202020;
        border-width: 0;
        display: block;
        margin-left: 1px;
        padding: 0 .75rem .75rem;
        position: absolute;
        text-align: center;
        top: 303px;
        width: calc(100% - 2px)
    }
}

@media(min-width: 1441px) {
    .rad-filters-vertical .rad-location-search__panel.rad-location-search__panel--active .location-filter-panel__bottom {
        top:calc(-0.84342px + 21.08559vw)
    }
}

@media(min-width: 1920px) {
    .rad-filters-vertical .rad-location-search__panel.rad-location-search__panel--active .location-filter-panel__bottom {
        top:404px
    }
}

@media(min-width: 1024px) {
    .rad-filters-vertical .rad-accordion-atom__content-wrapper--open.desktop-location-overflow--active {
        overflow:visible
    }
}

.rad-filters-vertical .rad-location-search__list {
    margin-top: .5rem
}

@media(min-width: 600px) {
    .rad-filters-vertical .rad-location-search__list {
        margin-top:1rem
    }
}

@media(min-width: 1024px) {
    .rad-filters-vertical .rad-location-search__list {
        display:none;
        margin-top: 0
    }

    .rad-filters-vertical .rad-location-search__list.rad-overflow-menu {
        background-color: #202020;
        display: block;
        position: relative;
        top: 3px
    }
}

@media(min-width: 1441px) {
    .rad-filters-vertical .rad-location-search__list.rad-overflow-menu {
        top:calc(-0.00835px + 0.20877vw)
    }
}

@media(min-width: 1920px) {
    .rad-filters-vertical .rad-location-search__list.rad-overflow-menu {
        top:4px
    }
}

@media(min-width: 1024px) {
    .rad-filters-vertical .rad-location-search__list.rad-overflow-menu .rad-filter-list-items {
        background-color:#202020;
        max-height: 240px;
        overflow: auto;
        padding-right: 0;
        padding-top: 8px;
        position: absolute;
        scrollbar-color: #a100ff #460073;
        scrollbar-width: thin;
        width: 100%;
        z-index: 3
    }

    .rad-filters-vertical .rad-location-search__list.rad-overflow-menu .rad-filter-list-items::-webkit-scrollbar {
        height: 4px;
        width: 4px
    }

    .rad-filters-vertical .rad-location-search__list.rad-overflow-menu .rad-filter-list-items::-webkit-scrollbar-track {
        background-color: #460073
    }

    .rad-filters-vertical .rad-location-search__list.rad-overflow-menu .rad-filter-list-items::-webkit-scrollbar-thumb {
        background-color: #a100ff
    }
}

@media(min-width: 1441px) {
    .rad-filters-vertical .rad-location-search__list.rad-overflow-menu .rad-filter-list-items {
        padding-top:calc(-0.02227px + 0.55672vw)
    }
}

@media(min-width: 1920px) {
    .rad-filters-vertical .rad-location-search__list.rad-overflow-menu .rad-filter-list-items {
        padding-top:10.6666666667px
    }
}

@media(min-width: 1441px) {
    .rad-filters-vertical .rad-location-search__list.rad-overflow-menu .rad-filter-list-items {
        max-height:calc(-0.66806px + 16.70146vw)
    }
}

@media(min-width: 1920px) {
    .rad-filters-vertical .rad-location-search__list.rad-overflow-menu .rad-filter-list-items {
        max-height:320px
    }
}

@media(min-width: 1024px) {
    .rad-filters-vertical .rad-location-search__list.rad-overflow-menu .rad-filter-list__no-results {
        background-color:#202020;
        color: #a2a2a0;
        display: none;
        padding: 20px 12px 12px 16px;
        position: absolute;
        width: 100%;
        z-index: 3
    }
}

@media(min-width: 1441px) {
    .rad-filters-vertical .rad-location-search__list.rad-overflow-menu .rad-filter-list__no-results {
        padding-bottom:calc(-0.0334px + 0.83507vw)
    }
}

@media(min-width: 1920px) {
    .rad-filters-vertical .rad-location-search__list.rad-overflow-menu .rad-filter-list__no-results {
        padding-bottom:16px
    }
}

@media(min-width: 1441px) {
    .rad-filters-vertical .rad-location-search__list.rad-overflow-menu .rad-filter-list__no-results {
        padding-left:calc(-0.04454px + 1.11343vw)
    }
}

@media(min-width: 1920px) {
    .rad-filters-vertical .rad-location-search__list.rad-overflow-menu .rad-filter-list__no-results {
        padding-left:21.3333333333px
    }
}

@media(min-width: 1441px) {
    .rad-filters-vertical .rad-location-search__list.rad-overflow-menu .rad-filter-list__no-results {
        padding-right:calc(-0.0334px + 0.83507vw)
    }
}

@media(min-width: 1920px) {
    .rad-filters-vertical .rad-location-search__list.rad-overflow-menu .rad-filter-list__no-results {
        padding-right:16px
    }
}

@media(min-width: 1441px) {
    .rad-filters-vertical .rad-location-search__list.rad-overflow-menu .rad-filter-list__no-results {
        padding-top:calc(-0.05567px + 1.39179vw)
    }
}

@media(min-width: 1920px) {
    .rad-filters-vertical .rad-location-search__list.rad-overflow-menu .rad-filter-list__no-results {
        padding-top:26.6666666667px
    }
}

@media(min-width: 1024px) {
    .rad-filters-vertical .rad-location-search__list.rad-overflow-menu .rad-filter-list__no-results--visible {
        display:block
    }
}

@media(max-width: 1023px) {
    .rad-filters-vertical .rad-location-search__list .rad-filter-list-items {
        padding:0
    }
}

.rad-filters-vertical .rad-location-search__list .rad-filter-list__no-results {
    color: #fff;
    display: none;
    font-size: .875rem;
    font-weight: 500;
    font-weight: 400;
    letter-spacing: -0.005em;
    line-height: 1.5;
    padding: .5rem 0 .75rem;
    text-underline-offset: .25rem
}

.rad-filters-vertical .rad-location-search__list .rad-filter-list__no-results:lang(ja-JP),.rad-filters-vertical .rad-location-search__list .rad-filter-list__no-results:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-filters-vertical .rad-location-search__list .rad-filter-list__no-results {
        font-size:1rem
    }
}

@media(min-width: 1441px) {
    .rad-filters-vertical .rad-location-search__list .rad-filter-list__no-results {
        font-size:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-filters-vertical .rad-location-search__list .rad-filter-list__no-results {
        font-size:1.3333333333rem
    }
}

.rad-filters-vertical .rad-location-search__list .rad-filter-list__no-results:lang(ja-JP),.rad-filters-vertical .rad-location-search__list .rad-filter-list__no-results:lang(zh-CN) {
    font-weight: 400
}

.rad-filters-vertical .rad-location-search__list .rad-filter-list__no-results--visible {
    display: block
}

.rad-filters-vertical .rad-sorting-radio {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 0 1rem;
    position: relative
}

@media(min-width: 1024px) {
    .rad-filters-vertical .rad-sorting-radio {
        display:none
    }
}

.rad-filters-vertical .rad-sorting-radio__label {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-size: .875rem;
    font-weight: 500;
    font-weight: 400;
    justify-content: space-between;
    letter-spacing: -0.005em;
    line-height: 1.5;
    padding: .75rem 0;
    text-underline-offset: .25rem;
    width: 100%
}

.rad-filters-vertical .rad-sorting-radio__label:lang(ja-JP),.rad-filters-vertical .rad-sorting-radio__label:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-filters-vertical .rad-sorting-radio__label {
        font-size:1rem
    }
}

@media(min-width: 1441px) {
    .rad-filters-vertical .rad-sorting-radio__label {
        font-size:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-filters-vertical .rad-sorting-radio__label {
        font-size:1.3333333333rem
    }
}

.rad-filters-vertical .rad-sorting-radio__label:lang(ja-JP),.rad-filters-vertical .rad-sorting-radio__label:lang(zh-CN) {
    font-weight: 400
}

.rad-filters-vertical .rad-sorting-radio__label .rad-icon {
    display: none;
    margin-left: .5rem
}

.rad-filters-vertical .rad-sorting-radio__input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: transparent;
    height: 100%;
    margin: 0;
    position: absolute;
    width: 100%
}

.rad-filters-vertical .rad-sorting-radio__input:disabled+.rad-sorting-radio__label {
    color: #616160
}

.rad-filters-vertical .rad-sorting-radio__input:focus {
    outline-offset: 0
}

.rad-filters-vertical .rad-sorting-radio__input:checked+.rad-sorting-radio__label .rad-icon {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

@-webkit-keyframes job-cards-skeleton-loading-loop {
    0%,5.4% {
        opacity: .3
    }

    35.12%,35.17% {
        opacity: 1
    }

    64.88%,70.29% {
        opacity: .6
    }

    to {
        opacity: .3
    }
}

@keyframes job-cards-skeleton-loading-loop {
    0%,5.4% {
        opacity: .3
    }

    35.12%,35.17% {
        opacity: 1
    }

    64.88%,70.29% {
        opacity: .6
    }

    to {
        opacity: .3
    }
}

.rad-filters-vertical__job-card {
    background-color: #101010;
    -webkit-transition-duration: 550ms;
    transition-duration: 550ms;
    -webkit-transition-property: background-color;
    transition-property: background-color;
    -webkit-transition-timing-function: cubic-bezier(0.85,0,0,1);
    transition-timing-function: cubic-bezier(0.85,0,0,1)
}

.rad-filters-vertical__job-card--open,.rad-filters-vertical__job-card:hover {
    background-color: #202020
}

.rad-filters-vertical__job-card-header {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-column-gap: 0.25rem;
    -moz-column-gap: 0.25rem;
    column-gap: 0.25rem;
    cursor: pointer;
    display: grid;
    grid-template-columns: 1fr -webkit-min-content;
    grid-template-columns: 1fr min-content;
    padding: 1.5rem 1rem;
    row-gap: 1rem
}

@media(min-width: 1441px) {
    .rad-filters-vertical__job-card-header {
        -webkit-column-gap:calc(0.25rem + 0.27836vw - 4.01113px);
        -moz-column-gap: calc(0.25rem + 0.27836vw - 4.01113px);
        column-gap: calc(0.25rem + 0.27836vw - 4.01113px)
    }
}

@media(min-width: 1920px) {
    .rad-filters-vertical__job-card-header {
        -webkit-column-gap:0.3333333333rem;
        -moz-column-gap: 0.3333333333rem;
        column-gap: 0.3333333333rem
    }
}

@media(min-width: 600px) {
    .rad-filters-vertical__job-card-header {
        padding:1.5rem 2rem;
        row-gap: 0.25rem
    }
}

@media(min-width: 1441px) {
    .rad-filters-vertical__job-card-header {
        padding-left:calc(2rem + 2.22686vw - 32.08907px)
    }
}

@media(min-width: 1920px) {
    .rad-filters-vertical__job-card-header {
        padding-left:2.6666666667rem
    }
}

@media(min-width: 1441px) {
    .rad-filters-vertical__job-card-header {
        padding-right:calc(2rem + 2.22686vw - 32.08907px)
    }
}

@media(min-width: 1920px) {
    .rad-filters-vertical__job-card-header {
        padding-right:2.6666666667rem
    }
}

@media(min-width: 1441px) {
    .rad-filters-vertical__job-card-header {
        padding-bottom:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-filters-vertical__job-card-header {
        padding-bottom:2rem
    }
}

@media(min-width: 1441px) {
    .rad-filters-vertical__job-card-header {
        padding-top:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-filters-vertical__job-card-header {
        padding-top:2rem
    }
}

@media(min-width: 1441px) {
    .rad-filters-vertical__job-card-header {
        row-gap:calc(0.25rem + 0.27836vw - 4.01113px)
    }
}

@media(min-width: 1920px) {
    .rad-filters-vertical__job-card-header {
        row-gap:0.3333333333rem
    }
}

.rad-filters-vertical__job-card-header .rad-filters-vertical__job-card-toggle {
    pointer-events: none
}

.rad-filters-vertical__job-card-header:hover .rad-filters-vertical__job-card-toggle .rad-accordion-atom__toggle-icon {
    background-color: #7500c0
}

.rad-filters-vertical__job-card-header--active .rad-filters-vertical__job-card-toggle .rad-accordion-atom__toggle-icon {
    background-color: #460073
}

.rad-filters-vertical__job-card-title {
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: -0.005em;
    line-height: 1.6;
    text-underline-offset: .25rem;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content
}

.rad-filters-vertical__job-card-title:lang(ja-JP),.rad-filters-vertical__job-card-title:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-filters-vertical__job-card-title {
        font-size:1.125rem
    }
}

@media(min-width: 1024px) {
    .rad-filters-vertical__job-card-title {
        font-size:1.25rem
    }
}

@media(min-width: 1441px) {
    .rad-filters-vertical__job-card-title {
        font-size:calc(1.25rem + 1.39179vw - 20.05567px)
    }
}

@media(min-width: 1920px) {
    .rad-filters-vertical__job-card-title {
        font-size:1.6666666667rem
    }
}

.rad-job-search--cards-loading .rad-filters-vertical__job-card-title,.rad-job-search--filters-loading .rad-filters-vertical__job-card-title {
    -webkit-animation: filters-vertical-skeleton-loading 1851ms ease-in-out infinite;
    animation: filters-vertical-skeleton-loading 1851ms ease-in-out infinite;
    -webkit-animation-name: job-cards-skeleton-loading-loop;
    animation-name: job-cards-skeleton-loading-loop;
    background-color: #202020;
    color: transparent;
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.rad-job-search--cards-loading .rad-filters-vertical__job-card-title ::-webkit-input-placeholder,.rad-job-search--cards-loading .rad-filters-vertical__job-card-title::-webkit-input-placeholder,.rad-job-search--filters-loading .rad-filters-vertical__job-card-title ::-webkit-input-placeholder,.rad-job-search--filters-loading .rad-filters-vertical__job-card-title::-webkit-input-placeholder {
    color: transparent
}

.rad-job-search--cards-loading .rad-filters-vertical__job-card-title ::-moz-placeholder,.rad-job-search--cards-loading .rad-filters-vertical__job-card-title::-moz-placeholder,.rad-job-search--filters-loading .rad-filters-vertical__job-card-title ::-moz-placeholder,.rad-job-search--filters-loading .rad-filters-vertical__job-card-title::-moz-placeholder {
    color: transparent
}

.rad-job-search--cards-loading .rad-filters-vertical__job-card-title :-ms-input-placeholder,.rad-job-search--cards-loading .rad-filters-vertical__job-card-title:-ms-input-placeholder,.rad-job-search--filters-loading .rad-filters-vertical__job-card-title :-ms-input-placeholder,.rad-job-search--filters-loading .rad-filters-vertical__job-card-title:-ms-input-placeholder {
    color: transparent
}

.rad-job-search--cards-loading .rad-filters-vertical__job-card-title ::-ms-input-placeholder,.rad-job-search--cards-loading .rad-filters-vertical__job-card-title::-ms-input-placeholder,.rad-job-search--filters-loading .rad-filters-vertical__job-card-title ::-ms-input-placeholder,.rad-job-search--filters-loading .rad-filters-vertical__job-card-title::-ms-input-placeholder {
    color: transparent
}

.rad-job-search--cards-loading .rad-filters-vertical__job-card-title ::placeholder,.rad-job-search--cards-loading .rad-filters-vertical__job-card-title::placeholder,.rad-job-search--filters-loading .rad-filters-vertical__job-card-title ::placeholder,.rad-job-search--filters-loading .rad-filters-vertical__job-card-title::placeholder {
    color: transparent
}

.rad-job-search--cards-loading .rad-filters-vertical__job-card-title:empty:before,.rad-job-search--filters-loading .rad-filters-vertical__job-card-title:empty:before {
    content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit";
    display: inline
}

.rad-filters-vertical__job-card-toggle {
    display: grid;
    grid-template-areas: "button";
    translate: 25%
}

@media(min-width: 600px) {
    .rad-filters-vertical__job-card-toggle {
        grid-row:span 2
    }
}

.rad-filters-vertical__job-card-toggle .rad-accordion-atom__toggle-icon,.rad-filters-vertical__job-card-toggle:after,.rad-filters-vertical__job-card-toggle:before {
    grid-area: button
}

.rad-filters-vertical__job-card-toggle .rad-accordion-atom__toggle-icon {
    background-color: #a100ff;
    height: 1.25rem;
    position: relative;
    width: 1.25rem
}

@media(min-width: 600px) {
    .rad-filters-vertical__job-card-toggle .rad-accordion-atom__toggle-icon {
        height:1.5rem;
        width: 1.5rem
    }
}

@media(min-width: 1441px) {
    .rad-filters-vertical__job-card-toggle .rad-accordion-atom__toggle-icon {
        width:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-filters-vertical__job-card-toggle .rad-accordion-atom__toggle-icon {
        width:2rem
    }
}

@media(min-width: 1441px) {
    .rad-filters-vertical__job-card-toggle .rad-accordion-atom__toggle-icon {
        height:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-filters-vertical__job-card-toggle .rad-accordion-atom__toggle-icon {
        height:2rem
    }
}

.rad-filters-vertical__job-card-toggle .rad-accordion-atom__toggle-icon:after,.rad-filters-vertical__job-card-toggle .rad-accordion-atom__toggle-icon:before {
    background-color: currentcolor;
    content: "";
    display: block;
    height: .125rem;
    left: 50%;
    margin: 0 auto;
    position: absolute;
    top: 50%;
    -webkit-transition-duration: 550ms;
    transition-duration: 550ms;
    -webkit-transition-property: rotate;
    transition-property: rotate;
    -webkit-transition-timing-function: cubic-bezier(0.85,0,0,1);
    transition-timing-function: cubic-bezier(0.85,0,0,1);
    translate: -50% -50%;
    width: 12px;
    z-index: 1
}

@media(min-width: 1441px) {
    .rad-filters-vertical__job-card-toggle .rad-accordion-atom__toggle-icon:after,.rad-filters-vertical__job-card-toggle .rad-accordion-atom__toggle-icon:before {
        width:calc(-0.0334px + 0.83507vw)
    }
}

@media(min-width: 1920px) {
    .rad-filters-vertical__job-card-toggle .rad-accordion-atom__toggle-icon:after,.rad-filters-vertical__job-card-toggle .rad-accordion-atom__toggle-icon:before {
        width:16px
    }
}

.rad-filters-vertical__job-card-toggle .rad-accordion-atom__toggle-icon:after {
    rotate: -90deg
}

.rad-filters-vertical__job-card-toggle .rad-accordion-atom__toggle-icon:hover {
    background-color: #7500c0
}

.rad-filters-vertical__job-card-toggle .rad-accordion-atom__toggle-icon:active {
    background-color: #460073
}

.rad-filters-vertical__job-card-toggle.rad-accordion-atom__toggle--open .rad-accordion-atom__toggle-icon:after {
    rotate: 0deg
}

.rad-job-search--cards-loading .rad-filters-vertical__job-card-toggle,.rad-job-search--filters-loading .rad-filters-vertical__job-card-toggle {
    pointer-events: none;
    visibility: hidden
}

.rad-filters-vertical__job-card-details {
    color: #a2a2a0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    font-size: .75rem;
    font-weight: 500;
    font-weight: 400;
    grid-column: span 2;
    letter-spacing: -0.005em;
    line-height: 1.5;
    text-underline-offset: .25rem;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content
}

.rad-filters-vertical__job-card-details:lang(ja-JP),.rad-filters-vertical__job-card-details:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-filters-vertical__job-card-details {
        font-size:0.875rem
    }
}

@media(min-width: 1441px) {
    .rad-filters-vertical__job-card-details {
        font-size:calc(0.875rem + 0.97425vw - 14.03897px)
    }
}

@media(min-width: 1920px) {
    .rad-filters-vertical__job-card-details {
        font-size:1.1666666667rem
    }
}

.rad-filters-vertical__job-card-details:lang(ja-JP),.rad-filters-vertical__job-card-details:lang(zh-CN) {
    font-weight: 400
}

.rad-job-search--cards-loading .rad-filters-vertical__job-card-details,.rad-job-search--filters-loading .rad-filters-vertical__job-card-details {
    -webkit-animation: filters-vertical-skeleton-loading 1851ms ease-in-out infinite;
    animation: filters-vertical-skeleton-loading 1851ms ease-in-out infinite;
    -webkit-animation-name: job-cards-skeleton-loading-loop;
    animation-name: job-cards-skeleton-loading-loop;
    background-color: #202020;
    color: transparent;
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.rad-job-search--cards-loading .rad-filters-vertical__job-card-details ::-webkit-input-placeholder,.rad-job-search--cards-loading .rad-filters-vertical__job-card-details::-webkit-input-placeholder,.rad-job-search--filters-loading .rad-filters-vertical__job-card-details ::-webkit-input-placeholder,.rad-job-search--filters-loading .rad-filters-vertical__job-card-details::-webkit-input-placeholder {
    color: transparent
}

.rad-job-search--cards-loading .rad-filters-vertical__job-card-details ::-moz-placeholder,.rad-job-search--cards-loading .rad-filters-vertical__job-card-details::-moz-placeholder,.rad-job-search--filters-loading .rad-filters-vertical__job-card-details ::-moz-placeholder,.rad-job-search--filters-loading .rad-filters-vertical__job-card-details::-moz-placeholder {
    color: transparent
}

.rad-job-search--cards-loading .rad-filters-vertical__job-card-details :-ms-input-placeholder,.rad-job-search--cards-loading .rad-filters-vertical__job-card-details:-ms-input-placeholder,.rad-job-search--filters-loading .rad-filters-vertical__job-card-details :-ms-input-placeholder,.rad-job-search--filters-loading .rad-filters-vertical__job-card-details:-ms-input-placeholder {
    color: transparent
}

.rad-job-search--cards-loading .rad-filters-vertical__job-card-details ::-ms-input-placeholder,.rad-job-search--cards-loading .rad-filters-vertical__job-card-details::-ms-input-placeholder,.rad-job-search--filters-loading .rad-filters-vertical__job-card-details ::-ms-input-placeholder,.rad-job-search--filters-loading .rad-filters-vertical__job-card-details::-ms-input-placeholder {
    color: transparent
}

.rad-job-search--cards-loading .rad-filters-vertical__job-card-details ::placeholder,.rad-job-search--cards-loading .rad-filters-vertical__job-card-details::placeholder,.rad-job-search--filters-loading .rad-filters-vertical__job-card-details ::placeholder,.rad-job-search--filters-loading .rad-filters-vertical__job-card-details::placeholder {
    color: transparent
}

@media(min-width: 600px) {
    .rad-filters-vertical__job-card-details {
        grid-column:auto
    }
}

.rad-filters-vertical__job-card-details span:not(:last-of-type):after {
    content: "|";
    margin: 0 .5rem
}

.rad-filters-vertical__job-card-details-skills {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-top: .5rem;
    width: 100%
}

@media(min-width: 600px) {
    .rad-filters-vertical__job-card-details-skills {
        padding-top:.25rem
    }
}

@media(min-width: 1024px) {
    .rad-filters-vertical__job-card-details-skills {
        padding-top:0.5rem
    }
}

@media(min-width: 1441px) {
    .rad-filters-vertical__job-card-details-skills {
        padding-top:calc(0.5rem + 0.55672vw - 8.02227px)
    }
}

@media(min-width: 1920px) {
    .rad-filters-vertical__job-card-details-skills {
        padding-top:0.6666666667rem
    }
}

.rad-filters-vertical__job-card-content {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    font-size: .75rem;
    font-weight: 500;
    font-weight: 400;
    letter-spacing: -0.005em;
    line-height: 1.5;
    padding-bottom: 1.25rem;
    padding-top: .25rem;
    text-underline-offset: .25rem
}

.rad-filters-vertical__job-card-content:lang(ja-JP),.rad-filters-vertical__job-card-content:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-filters-vertical__job-card-content {
        font-size:0.875rem
    }
}

@media(min-width: 1441px) {
    .rad-filters-vertical__job-card-content {
        font-size:calc(0.875rem + 0.97425vw - 14.03897px)
    }
}

@media(min-width: 1920px) {
    .rad-filters-vertical__job-card-content {
        font-size:1.1666666667rem
    }
}

.rad-filters-vertical__job-card-content:lang(ja-JP),.rad-filters-vertical__job-card-content:lang(zh-CN) {
    font-weight: 400
}

@media(min-width: 600px) {
    .rad-filters-vertical__job-card-content {
        padding-top:.5rem
    }
}

@media(min-width: 1024px) {
    .rad-filters-vertical__job-card-content {
        padding-bottom:1.5rem
    }
}

@media(min-width: 1441px) {
    .rad-filters-vertical__job-card-content {
        padding-bottom:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-filters-vertical__job-card-content {
        padding-bottom:2rem
    }
}

.rad-filters-vertical__job-card-content-wrapper {
    padding: 0 1rem
}

@media(min-width: 600px) {
    .rad-filters-vertical__job-card-content-wrapper {
        padding-left:2rem;
        padding-right: 2rem
    }
}

@media(min-width: 1441px) {
    .rad-filters-vertical__job-card-content-wrapper {
        padding-left:calc(2rem + 2.22686vw - 32.08907px)
    }
}

@media(min-width: 1920px) {
    .rad-filters-vertical__job-card-content-wrapper {
        padding-left:2.6666666667rem
    }
}

@media(min-width: 1441px) {
    .rad-filters-vertical__job-card-content-wrapper {
        padding-right:calc(2rem + 2.22686vw - 32.08907px)
    }
}

@media(min-width: 1920px) {
    .rad-filters-vertical__job-card-content-wrapper {
        padding-right:2.6666666667rem
    }
}

.rad-filters-vertical__job-card-content-job-title-and-number {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-bottom: .5rem;
    row-gap: 0.5rem
}

@media(min-width: 1441px) {
    .rad-filters-vertical__job-card-content-job-title-and-number {
        row-gap:calc(0.5rem + 0.55672vw - 8.02227px)
    }
}

@media(min-width: 1920px) {
    .rad-filters-vertical__job-card-content-job-title-and-number {
        row-gap:0.6666666667rem
    }
}

@media(min-width: 600px) {
    .rad-filters-vertical__job-card-content-job-title-and-number {
        margin-bottom:1rem
    }
}

@media(min-width: 1441px) {
    .rad-filters-vertical__job-card-content-job-title-and-number {
        margin-bottom:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-filters-vertical__job-card-content-job-title-and-number {
        margin-bottom:1.3333333333rem
    }
}

.rad-filters-vertical__job-card-content-standard-title {
    color: #a2a2a0;
    -webkit-column-gap: 8px;
    -moz-column-gap: 8px;
    column-gap: 8px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

@media(min-width: 600px) {
    .rad-filters-vertical__job-card-content-standard-title {
        display:block
    }
}

.rad-filters-vertical__job-card-content-standard-title-dynamic-text {
    color: #fff
}

.rad-filters-vertical__job-card-content-job-number {
    color: #a2a2a0
}

.rad-filters-vertical__job-card-content-job-description {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-bottom: .5rem;
    row-gap: 0.5rem
}

@media(min-width: 1441px) {
    .rad-filters-vertical__job-card-content-job-description {
        row-gap:calc(0.5rem + 0.55672vw - 8.02227px)
    }
}

@media(min-width: 1920px) {
    .rad-filters-vertical__job-card-content-job-description {
        row-gap:0.6666666667rem
    }
}

@media(min-width: 600px) {
    .rad-filters-vertical__job-card-content-job-description {
        margin-bottom:0.75rem
    }
}

@media(min-width: 1441px) {
    .rad-filters-vertical__job-card-content-job-description {
        margin-bottom:calc(0.75rem + 0.83507vw - 12.0334px)
    }
}

@media(min-width: 1920px) {
    .rad-filters-vertical__job-card-content-job-description {
        margin-bottom:1rem
    }
}

.rad-filters-vertical__job-card-content-job-description-title {
    font-weight: 500
}

.rad-filters-vertical__job-card-content-buttons {
    -webkit-box-align: start;
    -ms-flex-align: start;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    align-items: flex-start;
    -webkit-column-gap: 1.5rem;
    -moz-column-gap: 1.5rem;
    column-gap: 1.5rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    row-gap: .25rem
}

@media(min-width: 1441px) {
    .rad-filters-vertical__job-card-content-buttons {
        -webkit-column-gap:calc(1.5rem + 1.67015vw - 24.06681px);
        -moz-column-gap: calc(1.5rem + 1.67015vw - 24.06681px);
        column-gap: calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-filters-vertical__job-card-content-buttons {
        -webkit-column-gap:2rem;
        -moz-column-gap: 2rem;
        column-gap: 2rem
    }
}

@media(min-width: 600px) {
    .rad-filters-vertical__job-card-content-buttons {
        -webkit-box-orient:horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row
    }
}

.rad-search-bar {
    color: #fff
}

@media(min-width: 1024px) {
    .rad-search-bar {
        margin:0 auto;
        width: calc(250% - var(--rad-spacing-sm)*3/4*10 + var(--rad-spacing-sm)*9 + var(--rad-spacing-sm)*0);
        width: calc(125% - var(--rad-spacing-sm)*7/8*10 + var(--rad-spacing-sm)*9 + var(--rad-spacing-md)*0);
        width: calc(83.33333% - var(--rad-spacing-sm)*11/12*10 + var(--rad-spacing-sm)*9 + var(--rad-spacing-md)*0)
    }
}

.rad-search-bar__inner {
    padding: 1.5rem 0
}

@media(min-width: 600px) {
    .rad-search-bar__inner {
        padding:2rem 0
    }
}

@media(min-width: 1024px) {
    .rad-search-bar__inner {
        padding-bottom:6.25rem;
        padding-top: 5rem;
        position: relative
    }
}

@media(min-width: 1441px) {
    .rad-search-bar__inner {
        padding-top:calc(5rem + 5.56715vw - 80.22269px)
    }
}

@media(min-width: 1920px) {
    .rad-search-bar__inner {
        padding-top:6.6666666667rem
    }
}

@media(min-width: 1441px) {
    .rad-search-bar__inner {
        padding-bottom:calc(6.25rem + 6.95894vw - 100.27836px)
    }
}

@media(min-width: 1920px) {
    .rad-search-bar__inner {
        padding-bottom:8.3333333333rem
    }
}

.rad-search-bar__intro-animation {
    display: none
}

@media(min-width: 1024px) {
    .rad-search-bar__intro-animation {
        -webkit-box-align:center;
        -ms-flex-align: center;
        align-items: center;
        background: #000;
        border-bottom: 1px solid #616160;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        font-size: 1.75rem;
        font-weight: 500;
        height: 5.5rem;
        letter-spacing: -0.03em;
        line-height: 1.3;
        opacity: 0;
        position: absolute;
        top: 5rem;
        width: 100%
    }
}

@media(min-width: 1441px) {
    .rad-search-bar__intro-animation {
        top:calc(5rem + 5.56715vw - 80.22269px)
    }
}

@media(min-width: 1920px) {
    .rad-search-bar__intro-animation {
        top:6.6666666667rem
    }
}

@media(min-width: 1441px) {
    .rad-search-bar__intro-animation {
        height:calc(5.5rem + 6.12387vw - 88.24495px)
    }
}

@media(min-width: 1920px) {
    .rad-search-bar__intro-animation {
        height:7.3333333333rem
    }
}

@media(min-width: 1024px) {
    .rad-search-bar__intro-animation:lang(ja-JP),.rad-search-bar__intro-animation:lang(zh-CN) {
        font-weight:700
    }

    .rad-search-bar__intro-animation {
        font-size: 2.5rem;
        font-size: 3rem;
        line-height: 1.2
    }
}

@media(min-width: 1441px) {
    .rad-search-bar__intro-animation {
        font-size:calc(3rem + 3.34029vw - 48.13361px)
    }
}

@media(min-width: 1920px) {
    .rad-search-bar__intro-animation {
        font-size:4rem
    }
}

@media(min-width: 1024px) {
    .rad-search-bar__intro-animation--playing {
        opacity:1
    }

    .rad-search-bar__intro-animation--hidden {
        display: none
    }

    .rad-search-bar__intro-animation .rad-search-bar__typing {
        border-right: 2px solid #a100ff;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        height: 3.625rem;
        margin: 0 auto;
        padding-left: 1rem;
        padding-right: 1rem;
        position: relative;
        text-align: center;
        white-space: break-spaces
    }

    .rad-search-bar__intro-animation .rad-search-bar__typing:before {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        content: "";
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        font-family: icont;
        font-size: 1.875rem;
        font-style: normal;
        font-weight: 400;
        height: 100%;
        padding-right: 1rem
    }

    .rad-search-bar__intro-animation .rad-search-bar__typing span {
        display: none
    }

    .rad-search-bar__intro-animation .rad-search-bar__typing span.show {
        display: inline
    }

    .rad-search-bar__intro-animation .rad-search-bar__typing--cursor-blinking {
        border-right-color: transparent
    }
}

.rad-search-bar__max-char-message,.rad-search-bar__pro-tip {
    color: #a2a2a0;
    font-size: .875rem;
    font-weight: 500;
    font-weight: 400;
    letter-spacing: -0.005em;
    line-height: 1.5;
    max-height: 0;
    overflow: hidden;
    text-underline-offset: .25rem;
    -webkit-transition-duration: 550ms;
    transition-duration: 550ms;
    -webkit-transition-property: max-height;
    transition-property: max-height;
    -webkit-transition-timing-function: cubic-bezier(0.85,0,0,1);
    transition-timing-function: cubic-bezier(0.85,0,0,1)
}

.rad-search-bar__max-char-message:lang(ja-JP),.rad-search-bar__max-char-message:lang(zh-CN),.rad-search-bar__pro-tip:lang(ja-JP),.rad-search-bar__pro-tip:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-search-bar__max-char-message,.rad-search-bar__pro-tip {
        font-size:1rem
    }
}

@media(min-width: 1441px) {
    .rad-search-bar__max-char-message,.rad-search-bar__pro-tip {
        font-size:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-search-bar__max-char-message,.rad-search-bar__pro-tip {
        font-size:1.3333333333rem
    }
}

.rad-search-bar__max-char-message:lang(ja-JP),.rad-search-bar__max-char-message:lang(zh-CN),.rad-search-bar__pro-tip:lang(ja-JP),.rad-search-bar__pro-tip:lang(zh-CN) {
    font-weight: 400
}

@media(min-width: 1024px) {
    .rad-search-bar__max-char-message,.rad-search-bar__pro-tip {
        visibility:hidden
    }
}

.rad-search-bar .max-char__headline,.rad-search-bar .pro-tip__headline {
    color: #e3e3df;
    font-size: .75rem;
    font-weight: 500;
    letter-spacing: .02em;
    line-height: 1.2;
    padding-bottom: .5rem
}

.rad-search-bar .max-char__headline:lang(ja-JP),.rad-search-bar .max-char__headline:lang(zh-CN),.rad-search-bar .pro-tip__headline:lang(ja-JP),.rad-search-bar .pro-tip__headline:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 1024px) {
    .rad-search-bar .max-char__headline,.rad-search-bar .pro-tip__headline {
        font-size:.75rem;
        font-weight: 500;
        letter-spacing: -0.005em;
        line-height: 1.5;
        text-underline-offset: .25rem
    }

    .rad-search-bar .max-char__headline:lang(ja-JP),.rad-search-bar .max-char__headline:lang(zh-CN),.rad-search-bar .pro-tip__headline:lang(ja-JP),.rad-search-bar .pro-tip__headline:lang(zh-CN) {
        font-weight: 700
    }

    .rad-search-bar .max-char__headline,.rad-search-bar .pro-tip__headline {
        font-size: 0.875rem
    }
}

@media(min-width: 1441px) {
    .rad-search-bar .max-char__headline,.rad-search-bar .pro-tip__headline {
        font-size:calc(0.875rem + 0.97425vw - 14.03897px)
    }
}

@media(min-width: 1920px) {
    .rad-search-bar .max-char__headline,.rad-search-bar .pro-tip__headline {
        font-size:1.1666666667rem
    }
}

.rad-search-bar .max-char__headline .rad-icon,.rad-search-bar .pro-tip__headline .rad-icon {
    display: inline;
    margin-right: .5rem
}

.rad-search-bar__typeahead {
    display: none;
    font-size: .875rem;
    font-weight: 500;
    font-weight: 400;
    letter-spacing: -0.005em;
    line-height: 1.5;
    text-underline-offset: .25rem
}

.rad-search-bar__typeahead:lang(ja-JP),.rad-search-bar__typeahead:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-search-bar__typeahead {
        font-size:1rem
    }
}

@media(min-width: 1441px) {
    .rad-search-bar__typeahead {
        font-size:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-search-bar__typeahead {
        font-size:1.3333333333rem
    }
}

.rad-search-bar__typeahead:lang(ja-JP),.rad-search-bar__typeahead:lang(zh-CN) {
    font-weight: 400
}

.rad-search-bar__typeahead--visible {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    background-color: #202020;
    color: #a2a2a0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 1rem 0;
    position: absolute;
    width: 100%;
    z-index: 3
}

@media(min-width: 1024px) {
    .rad-search-bar__typeahead--visible {
        margin-top:2px;
        padding: 1.5rem 0
    }
}

.rad-search-bar__typeahead--visible .autocomplete__option {
    cursor: pointer;
    padding: .375rem .75rem
}

.rad-search-bar__typeahead--visible .autocomplete__option mark {
    background-color: transparent;
    color: #fff;
    font-size: .875rem;
    font-weight: 500;
    letter-spacing: -0.005em;
    line-height: 1.5;
    text-underline-offset: .25rem
}

.rad-search-bar__typeahead--visible .autocomplete__option mark:lang(ja-JP),.rad-search-bar__typeahead--visible .autocomplete__option mark:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-search-bar__typeahead--visible .autocomplete__option mark {
        font-size:1rem
    }
}

@media(min-width: 1441px) {
    .rad-search-bar__typeahead--visible .autocomplete__option mark {
        font-size:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-search-bar__typeahead--visible .autocomplete__option mark {
        font-size:1.3333333333rem
    }
}

@media(min-width: 1024px) {
    .rad-search-bar__typeahead--visible .autocomplete__option {
        padding:.75rem 3rem
    }
}

.rad-search-bar__typeahead--visible .autocomplete__option:active,.rad-search-bar__typeahead--visible .autocomplete__option:hover {
    background-color: #2b2b2b
}

.rad-search-bar__typeahead--visible .autocomplete__option[aria-selected=true] {
    background-color: #202020;
    outline: 2px solid #a100ff
}

.rad-search-bar__desktop-clear-search-button,.rad-search-bar__desktop-input-overlay,.rad-search-bar__mobile-input-back-button {
    display: none
}

@media(min-width: 1024px) {
    .rad-search-bar .rad-search-bar__textbox {
        height:5.5rem;
        opacity: 0
    }
}

@media(min-width: 1441px) {
    .rad-search-bar .rad-search-bar__textbox {
        height:calc(5.5rem + 6.12387vw - 88.24495px)
    }
}

@media(min-width: 1920px) {
    .rad-search-bar .rad-search-bar__textbox {
        height:7.3333333333rem
    }
}

.rad-search-bar .rad-textbox__input-area {
    padding-right: 0
}

@media(min-width: 1024px) {
    .rad-search-bar .rad-textbox__input-area {
        height:5.5rem;
        padding: 1.5rem 2.5rem
    }
}

@media(min-width: 1441px) {
    .rad-search-bar .rad-textbox__input-area {
        height:calc(5.5rem + 6.12387vw - 88.24495px)
    }
}

@media(min-width: 1920px) {
    .rad-search-bar .rad-textbox__input-area {
        height:7.3333333333rem
    }
}

@media(min-width: 1441px) {
    .rad-search-bar .rad-textbox__input-area {
        padding-top:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-search-bar .rad-textbox__input-area {
        padding-top:2rem
    }
}

@media(min-width: 1441px) {
    .rad-search-bar .rad-textbox__input-area {
        padding-bottom:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-search-bar .rad-textbox__input-area {
        padding-bottom:2rem
    }
}

@media(min-width: 1441px) {
    .rad-search-bar .rad-textbox__input-area {
        padding-left:calc(2.5rem + 2.78358vw - 40.11134px)
    }
}

@media(min-width: 1920px) {
    .rad-search-bar .rad-textbox__input-area {
        padding-left:3.3333333333rem
    }
}

@media(min-width: 1441px) {
    .rad-search-bar .rad-textbox__input-area {
        padding-right:calc(2.5rem + 2.78358vw - 40.11134px)
    }
}

@media(min-width: 1920px) {
    .rad-search-bar .rad-textbox__input-area {
        padding-right:3.3333333333rem
    }
}

@media(min-width: 1024px) {
    .rad-search-bar .rad-textbox__input-area .rad-textbox__input-left-icon {
        height:2rem;
        width: 2rem
    }

    .rad-search-bar .rad-textbox__input-area .rad-textbox__input-left-icon:after {
        font-size: 1.5rem
    }
}

.rad-search-bar .rad-search-bar__search-input {
    caret-color: #a100ff
}

@media(min-width: 1024px) {
    .rad-search-bar .rad-search-bar__search-input {
        font-size:1.25rem;
        font-weight: 500;
        letter-spacing: -0.02em;
        line-height: 1.3;
        padding-left: 5.25rem;
        padding-right: 6rem
    }

    .rad-search-bar .rad-search-bar__search-input:lang(ja-JP),.rad-search-bar .rad-search-bar__search-input:lang(zh-CN) {
        font-weight: 700
    }

    .rad-search-bar .rad-search-bar__search-input {
        font-size: 1.5rem;
        font-size: 1.75rem;
        line-height: 1.3;
        line-height: 1.25
    }
}

@media(min-width: 1441px) {
    .rad-search-bar .rad-search-bar__search-input {
        font-size:calc(1.75rem + 1.9485vw - 28.07794px)
    }
}

@media(min-width: 1920px) {
    .rad-search-bar .rad-search-bar__search-input {
        font-size:2.3333333333rem
    }
}

.rad-search-bar--mobile-typing-mode {
    background-color: #000;
    height: 100%;
    margin-left: -1rem;
    padding-left: var(--rad-spacing-sm);
    padding-right: var(--rad-spacing-sm);
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1110
}

@media(min-width: 600px) {
    .rad-search-bar--mobile-typing-mode {
        padding-left:var(--rad-spacing-md);
        padding-right: var(--rad-spacing-md)
    }
}

.rad-component--nested .rad-search-bar--mobile-typing-mode {
    padding-left: 0;
    padding-right: 0
}

@media(min-width: 600px) {
    .rad-search-bar--mobile-typing-mode {
        margin-left:-3rem
    }
}

.rad-search-bar--mobile-typing-mode .rad-search-bar__inner {
    padding-top: 1rem
}

@media(min-width: 600px) {
    .rad-search-bar--mobile-typing-mode .rad-search-bar__inner {
        padding-top:2rem
    }
}

.rad-search-bar--mobile-typing-mode .rad-search-bar__spyglass {
    opacity: 0
}

.rad-search-bar--mobile-typing-mode .rad-search-bar__mobile-input-back-button {
    display: block;
    height: 3.25rem;
    left: .75rem;
    position: absolute;
    translate: var(--rad-spacing-sm);
    width: 1.25rem;
    z-index: 2
}

@media(min-width: 600px) {
    .rad-search-bar--mobile-typing-mode .rad-search-bar__mobile-input-back-button {
        translate:var(--rad-spacing-md)
    }
}

.rad-search-bar--mobile-typing-mode .rad-search-bar__pro-tip {
    margin-top: 1rem;
    max-height: none;
    padding: .5rem .5rem 1rem;
    visibility: visible
}

@media(min-width: 600px) {
    .rad-search-bar--mobile-typing-mode .rad-search-bar__pro-tip {
        padding-left:1rem;
        padding-right: 1rem
    }
}

.rad-search-bar--mobile-typing-mode .rad-search-bar__pro-tip--hide {
    max-height: 0;
    visibility: hidden
}

.rad-search-bar--mobile-typing-mode .rad-search-bar__max-char-message--visible {
    max-height: none;
    padding: .5rem;
    visibility: visible
}

@media(min-width: 1024px) {
    .rad-search-bar--desktop-default-mode .rad-search-bar__desktop-input-overlay,.rad-search-bar--desktop-display-mode .rad-search-bar__desktop-input-overlay {
        -webkit-box-pack:center;
        -ms-flex-pack: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        border-bottom: 1px solid #616160;
        color: #616160;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        font-size: 1.25rem;
        font-weight: 500;
        height: 5.5rem;
        justify-content: center;
        letter-spacing: -0.02em;
        line-height: 1.3;
        position: absolute;
        top: 5rem;
        width: 100%
    }
}

@media(min-width: 1441px) {
    .rad-search-bar--desktop-default-mode .rad-search-bar__desktop-input-overlay,.rad-search-bar--desktop-display-mode .rad-search-bar__desktop-input-overlay {
        height:calc(5.5rem + 6.12387vw - 88.24495px)
    }
}

@media(min-width: 1920px) {
    .rad-search-bar--desktop-default-mode .rad-search-bar__desktop-input-overlay,.rad-search-bar--desktop-display-mode .rad-search-bar__desktop-input-overlay {
        height:7.3333333333rem
    }
}

@media(min-width: 1441px) {
    .rad-search-bar--desktop-default-mode .rad-search-bar__desktop-input-overlay,.rad-search-bar--desktop-display-mode .rad-search-bar__desktop-input-overlay {
        top:calc(5rem + 5.56715vw - 80.22269px)
    }
}

@media(min-width: 1920px) {
    .rad-search-bar--desktop-default-mode .rad-search-bar__desktop-input-overlay,.rad-search-bar--desktop-display-mode .rad-search-bar__desktop-input-overlay {
        top:6.6666666667rem
    }
}

@media(min-width: 1024px) {
    .rad-search-bar--desktop-default-mode .rad-search-bar__desktop-input-overlay:lang(ja-JP),.rad-search-bar--desktop-default-mode .rad-search-bar__desktop-input-overlay:lang(zh-CN),.rad-search-bar--desktop-display-mode .rad-search-bar__desktop-input-overlay:lang(ja-JP),.rad-search-bar--desktop-display-mode .rad-search-bar__desktop-input-overlay:lang(zh-CN) {
        font-weight:700
    }

    .rad-search-bar--desktop-default-mode .rad-search-bar__desktop-input-overlay,.rad-search-bar--desktop-display-mode .rad-search-bar__desktop-input-overlay {
        font-size: 1.5rem;
        font-size: 1.75rem;
        line-height: 1.3;
        line-height: 1.25
    }
}

@media(min-width: 1441px) {
    .rad-search-bar--desktop-default-mode .rad-search-bar__desktop-input-overlay,.rad-search-bar--desktop-display-mode .rad-search-bar__desktop-input-overlay {
        font-size:calc(1.75rem + 1.9485vw - 28.07794px)
    }
}

@media(min-width: 1920px) {
    .rad-search-bar--desktop-default-mode .rad-search-bar__desktop-input-overlay,.rad-search-bar--desktop-display-mode .rad-search-bar__desktop-input-overlay {
        font-size:2.3333333333rem
    }
}

@media(min-width: 1024px) {
    .rad-search-bar--desktop-default-mode .rad-search-bar__desktop-input-overlay:before,.rad-search-bar--desktop-display-mode .rad-search-bar__desktop-input-overlay:before {
        -webkit-box-align:center;
        -ms-flex-align: center;
        align-items: center;
        color: #fff;
        content: "";
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        font-family: icont;
        font-size: 1.875rem;
        font-style: normal;
        font-weight: 400;
        height: 100%;
        padding-right: 1rem
    }

    .rad-search-bar--desktop-default-mode .rad-search-bar__desktop-input-overlay .desktop-input-overlay__text,.rad-search-bar--desktop-display-mode .rad-search-bar__desktop-input-overlay .desktop-input-overlay__text {
        max-width: 0;
        overflow: hidden;
        -webkit-transition-duration: 550ms;
        transition-duration: 550ms;
        -webkit-transition-property: width,max-width;
        transition-property: width,max-width;
        -webkit-transition-timing-function: cubic-bezier(0.85,0,0,1);
        transition-timing-function: cubic-bezier(0.85,0,0,1);
        white-space: nowrap;
        width: 0
    }

    .rad-search-bar--desktop-default-mode .rad-search-bar__desktop-input-overlay--expanded,.rad-search-bar--desktop-display-mode .rad-search-bar__desktop-input-overlay--expanded {
        background-color: #000
    }

    .rad-search-bar--desktop-default-mode .rad-search-bar__desktop-input-overlay--expanded .desktop-input-overlay__text.dekstop-input-overlay__text--visible,.rad-search-bar--desktop-display-mode .rad-search-bar__desktop-input-overlay--expanded .desktop-input-overlay__text.dekstop-input-overlay__text--visible {
        max-width: 100%;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content
    }

    .rad-search-bar--desktop-default-mode .rad-search-bar__textbox,.rad-search-bar--desktop-display-mode .rad-search-bar__textbox {
        opacity: 1
    }

    .rad-search-bar--desktop-display-mode .rad-search-bar__desktop-clear-search-button {
        -ms-flex-negative: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        flex-shrink: 0
    }

    .rad-search-bar--desktop-display-mode .rad-search-bar__desktop-input-overlay {
        color: #fff;
        cursor: default
    }

    .rad-search-bar--desktop-display-mode .rad-search-bar__desktop-input-overlay .desktop-input-overlay__text {
        cursor: pointer
    }

    .rad-search-bar--desktop-display-mode .rad-search-bar__textbox {
        visibility: hidden
    }

    .rad-search-bar--desktop-typing-mode .rad-search-bar__textbox {
        opacity: 1
    }

    .rad-search-bar--desktop-typing-mode .rad-search-bar__desktop-clear-search-button,.rad-search-bar--desktop-typing-mode .rad-search-bar__mobile-clear-search-button {
        height: 100%;
        z-index: 2
    }

    .rad-search-bar--desktop-typing-mode .rad-search-bar__max-char-message--visible,.rad-search-bar--desktop-typing-mode .rad-search-bar__pro-tip--desktop-visible {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        background-color: #202020;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
        gap: 0.5rem;
        margin-top: .125rem;
        max-height: none;
        padding: 1.5rem 3rem 2rem;
        position: absolute;
        visibility: visible;
        width: 100%;
        z-index: 1
    }
}

@media(min-width: 1441px) {
    .rad-search-bar--desktop-typing-mode .rad-search-bar__max-char-message--visible,.rad-search-bar--desktop-typing-mode .rad-search-bar__pro-tip--desktop-visible {
        padding-top:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-search-bar--desktop-typing-mode .rad-search-bar__max-char-message--visible,.rad-search-bar--desktop-typing-mode .rad-search-bar__pro-tip--desktop-visible {
        padding-top:2rem
    }
}

@media(min-width: 1441px) {
    .rad-search-bar--desktop-typing-mode .rad-search-bar__max-char-message--visible,.rad-search-bar--desktop-typing-mode .rad-search-bar__pro-tip--desktop-visible {
        padding-bottom:calc(2rem + 2.22686vw - 32.08907px)
    }
}

@media(min-width: 1920px) {
    .rad-search-bar--desktop-typing-mode .rad-search-bar__max-char-message--visible,.rad-search-bar--desktop-typing-mode .rad-search-bar__pro-tip--desktop-visible {
        padding-bottom:2.6666666667rem
    }
}

@media(min-width: 1441px) {
    .rad-search-bar--desktop-typing-mode .rad-search-bar__max-char-message--visible,.rad-search-bar--desktop-typing-mode .rad-search-bar__pro-tip--desktop-visible {
        padding-left:calc(3rem + 3.34029vw - 48.13361px)
    }
}

@media(min-width: 1920px) {
    .rad-search-bar--desktop-typing-mode .rad-search-bar__max-char-message--visible,.rad-search-bar--desktop-typing-mode .rad-search-bar__pro-tip--desktop-visible {
        padding-left:4rem
    }
}

@media(min-width: 1441px) {
    .rad-search-bar--desktop-typing-mode .rad-search-bar__max-char-message--visible,.rad-search-bar--desktop-typing-mode .rad-search-bar__pro-tip--desktop-visible {
        padding-right:calc(3rem + 3.34029vw - 48.13361px)
    }
}

@media(min-width: 1920px) {
    .rad-search-bar--desktop-typing-mode .rad-search-bar__max-char-message--visible,.rad-search-bar--desktop-typing-mode .rad-search-bar__pro-tip--desktop-visible {
        padding-right:4rem
    }
}

@media(min-width: 1441px) {
    .rad-search-bar--desktop-typing-mode .rad-search-bar__max-char-message--visible,.rad-search-bar--desktop-typing-mode .rad-search-bar__pro-tip--desktop-visible {
        gap:calc(0.5rem + 0.55672vw - 8.02227px)
    }
}

@media(min-width: 1920px) {
    .rad-search-bar--desktop-typing-mode .rad-search-bar__max-char-message--visible,.rad-search-bar--desktop-typing-mode .rad-search-bar__pro-tip--desktop-visible {
        gap:0.6666666667rem
    }
}

@media(min-width: 1024px) {
    .rad-search-bar--desktop-typing-mode .rad-search-bar__max-char-message--visible .max-char__text,.rad-search-bar--desktop-typing-mode .rad-search-bar__max-char-message--visible .pro-tip__text,.rad-search-bar--desktop-typing-mode .rad-search-bar__pro-tip--desktop-visible .max-char__text,.rad-search-bar--desktop-typing-mode .rad-search-bar__pro-tip--desktop-visible .pro-tip__text {
        max-width:38.75rem
    }

    .rad-search-bar--desktop-typing-mode .rad-search-bar__intro-animation {
        opacity: 0;
        z-index: -1
    }
}

.rad-layout-division {
    display: grid;
    padding-left: var(--rad-spacing-sm);
    padding-right: var(--rad-spacing-sm)
}

@media(min-width: 600px) {
    .rad-layout-division {
        padding-left:var(--rad-spacing-md);
        padding-right: var(--rad-spacing-md)
    }
}

.rad-component--nested .rad-layout-division {
    padding-left: 0;
    padding-right: 0
}

.rad-layout-division__container {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content
}

@media(min-width: 1024px) {
    .rad-layout-division--sticky-containers.rad-layout-division--4-7 .rad-layout-division__container:first-of-type,.rad-layout-division--sticky-containers.rad-layout-division--7-4 .rad-layout-division__container:last-of-type,.rad-layout-division--sticky-containers.rad-layout-division--8-3 .rad-layout-division__container:last-of-type,.rad-layout-division--sticky-containers.rad-layout-division--mega-accordion .rad-layout-division__container:last-of-type {
        position:sticky;
        top: 0
    }
}

@media(min-width: 600px) {
    .rad-layout-division--2-column {
        -webkit-column-gap:calc(0% - var(--rad-spacing-sm)*3/4*0 + var(--rad-spacing-sm)*1 + var(--rad-spacing-sm)*0);
        -moz-column-gap: calc(0% - var(--rad-spacing-sm)*3/4*0 + var(--rad-spacing-sm)*1 + var(--rad-spacing-sm)*0);
        column-gap: calc(0% - var(--rad-spacing-sm)*3/4*0 + var(--rad-spacing-sm)*1 + var(--rad-spacing-sm)*0);
        grid-template-columns: repeat(2,1fr)
    }
}

@media(min-width: 1024px) {
    .rad-layout-division--2-column {
        -webkit-column-gap:calc(0% - var(--rad-spacing-sm)*5/6*0 + var(--rad-spacing-sm)*1 + var(--rad-spacing-md)*0);
        -moz-column-gap: calc(0% - var(--rad-spacing-sm)*5/6*0 + var(--rad-spacing-sm)*1 + var(--rad-spacing-md)*0);
        column-gap: calc(0% - var(--rad-spacing-sm)*5/6*0 + var(--rad-spacing-sm)*1 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 600px) {
    .rad-layout-division--3-column {
        -webkit-column-gap:calc(0% - var(--rad-spacing-sm)*7/8*0 + var(--rad-spacing-sm)*1 + var(--rad-spacing-md)*0);
        -moz-column-gap: calc(0% - var(--rad-spacing-sm)*7/8*0 + var(--rad-spacing-sm)*1 + var(--rad-spacing-md)*0);
        column-gap: calc(0% - var(--rad-spacing-sm)*7/8*0 + var(--rad-spacing-sm)*1 + var(--rad-spacing-md)*0);
        grid-template-columns: repeat(2,1fr)
    }
}

@media(min-width: 1024px) {
    .rad-layout-division--3-column {
        -webkit-column-gap:calc(0% - var(--rad-spacing-sm)*11/12*0 + var(--rad-spacing-sm)*1 + var(--rad-spacing-md)*0);
        -moz-column-gap: calc(0% - var(--rad-spacing-sm)*11/12*0 + var(--rad-spacing-sm)*1 + var(--rad-spacing-md)*0);
        column-gap: calc(0% - var(--rad-spacing-sm)*11/12*0 + var(--rad-spacing-sm)*1 + var(--rad-spacing-md)*0);
        grid-template-columns: repeat(3,1fr)
    }
}

@media(min-width: 600px)and (max-width:1023px) {
    .rad-layout-division--3-column-tablet-single-column {
        grid-template-columns:repeat(1,1fr)
    }
}

@media(min-width: 600px) {
    .rad-layout-division--4-column {
        -webkit-column-gap:calc(0% - var(--rad-spacing-sm)*7/8*0 + var(--rad-spacing-sm)*1 + var(--rad-spacing-md)*0);
        -moz-column-gap: calc(0% - var(--rad-spacing-sm)*7/8*0 + var(--rad-spacing-sm)*1 + var(--rad-spacing-md)*0);
        column-gap: calc(0% - var(--rad-spacing-sm)*7/8*0 + var(--rad-spacing-sm)*1 + var(--rad-spacing-md)*0);
        grid-template-columns: repeat(2,1fr)
    }
}

@media(min-width: 1024px) {
    .rad-layout-division--4-column {
        -webkit-column-gap:calc(0% - var(--rad-spacing-sm)*11/12*0 + var(--rad-spacing-sm)*1 + var(--rad-spacing-md)*0);
        -moz-column-gap: calc(0% - var(--rad-spacing-sm)*11/12*0 + var(--rad-spacing-sm)*1 + var(--rad-spacing-md)*0);
        column-gap: calc(0% - var(--rad-spacing-sm)*11/12*0 + var(--rad-spacing-sm)*1 + var(--rad-spacing-md)*0);
        grid-template-columns: repeat(4,1fr)
    }
}

@media(min-width: 600px)and (max-width:1023px) {
    .rad-layout-division--4-column-tablet-single-column {
        grid-template-columns:repeat(1,1fr)
    }
}

@media(min-width: 600px) {
    .rad-layout-division--4-7 {
        grid-template-columns:calc(75% - var(--rad-spacing-sm)*7/8*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-layout-division--4-7 {
        -webkit-column-gap:calc(8.33333% - var(--rad-spacing-sm)*11/12*1 + var(--rad-spacing-sm)*2 + var(--rad-spacing-md)*0);
        -moz-column-gap: calc(8.33333% - var(--rad-spacing-sm)*11/12*1 + var(--rad-spacing-sm)*2 + var(--rad-spacing-md)*0);
        column-gap: calc(8.33333% - var(--rad-spacing-sm)*11/12*1 + var(--rad-spacing-sm)*2 + var(--rad-spacing-md)*0);
        grid-template-columns: calc(33.33333% - var(--rad-spacing-sm)*11/12*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0) calc(58.33333% - var(--rad-spacing-sm)*11/12*7 + var(--rad-spacing-sm)*6 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 600px) {
    .rad-layout-division--7-4 {
        grid-template-columns:calc(75% - var(--rad-spacing-sm)*7/8*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-layout-division--7-4 {
        -webkit-column-gap:calc(8.33333% - var(--rad-spacing-sm)*11/12*1 + var(--rad-spacing-sm)*2 + var(--rad-spacing-md)*0);
        -moz-column-gap: calc(8.33333% - var(--rad-spacing-sm)*11/12*1 + var(--rad-spacing-sm)*2 + var(--rad-spacing-md)*0);
        column-gap: calc(8.33333% - var(--rad-spacing-sm)*11/12*1 + var(--rad-spacing-sm)*2 + var(--rad-spacing-md)*0);
        grid-template-columns: calc(58.33333% - var(--rad-spacing-sm)*11/12*7 + var(--rad-spacing-sm)*6 + var(--rad-spacing-md)*0) calc(33.33333% - var(--rad-spacing-sm)*11/12*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0)
    }
}

.rad-layout-division--8-3,.rad-layout-division--mega-accordion {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

@media(min-width: 1024px) {
    .rad-layout-division--8-3,.rad-layout-division--mega-accordion {
        -webkit-box-orient:horizontal;
        -webkit-box-direction: normal;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        -ms-flex-direction: row;
        flex-direction: row;
        justify-content: space-between
    }
}

.rad-layout-division--8-3 .rad-layout-division__container:first-child {
    width: calc(100% - var(--rad-spacing-sm)*3/4*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-sm)*0)
}

@media(min-width: 600px) {
    .rad-layout-division--8-3 .rad-layout-division__container:first-child {
        width:calc(50% - var(--rad-spacing-sm)*7/8*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-layout-division--8-3 .rad-layout-division__container:first-child {
        width:calc(33.33333% - var(--rad-spacing-sm)*11/12*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 600px) {
    .rad-layout-division--8-3 .rad-layout-division__container:first-child {
        width:calc(150% - var(--rad-spacing-sm)*3/4*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-sm)*0);
        width: calc(75% - var(--rad-spacing-sm)*7/8*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-layout-division--8-3 .rad-layout-division__container:first-child {
        width:calc(50% - var(--rad-spacing-sm)*11/12*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0);
        width: calc(200% - var(--rad-spacing-sm)*3/4*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-sm)*0);
        width: calc(100% - var(--rad-spacing-sm)*7/8*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0);
        width: calc(66.66667% - var(--rad-spacing-sm)*11/12*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0)
    }
}

.rad-layout-division--8-3 .rad-layout-division__container:last-child {
    width: calc(75% - var(--rad-spacing-sm)*3/4*3 + var(--rad-spacing-sm)*2 + var(--rad-spacing-sm)*0)
}

@media(min-width: 600px) {
    .rad-layout-division--8-3 .rad-layout-division__container:last-child {
        width:calc(37.5% - var(--rad-spacing-sm)*7/8*3 + var(--rad-spacing-sm)*2 + var(--rad-spacing-md)*0);
        width: calc(100% - var(--rad-spacing-sm)*3/4*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-sm)*0);
        width: calc(50% - var(--rad-spacing-sm)*7/8*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-layout-division--8-3 .rad-layout-division__container:last-child {
        width:calc(33.33333% - var(--rad-spacing-sm)*11/12*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0);
        width: calc(75% - var(--rad-spacing-sm)*3/4*3 + var(--rad-spacing-sm)*2 + var(--rad-spacing-sm)*0);
        width: calc(37.5% - var(--rad-spacing-sm)*7/8*3 + var(--rad-spacing-sm)*2 + var(--rad-spacing-md)*0);
        width: calc(25% - var(--rad-spacing-sm)*11/12*3 + var(--rad-spacing-sm)*2 + var(--rad-spacing-md)*0)
    }
}

.rad-layout-division--8-3-right .rad-layout-division__container:last-child {
    margin-left: calc(25% - var(--rad-spacing-sm)*3/4*1 + var(--rad-spacing-sm)*1 + var(--rad-spacing-sm)*0)
}

@media(min-width: 600px) {
    .rad-layout-division--8-3-right .rad-layout-division__container:last-child {
        margin-left:calc(12.5% - var(--rad-spacing-sm)*7/8*1 + var(--rad-spacing-sm)*1 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-layout-division--8-3-right .rad-layout-division__container:last-child {
        margin-left:calc(8.33333% - var(--rad-spacing-sm)*11/12*1 + var(--rad-spacing-sm)*1 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 600px) {
    .rad-layout-division--8-3-right .rad-layout-division__container:last-child {
        margin-left:calc(50% - var(--rad-spacing-sm)*3/4*2 + var(--rad-spacing-sm)*2 + var(--rad-spacing-sm)*0);
        margin-left: calc(25% - var(--rad-spacing-sm)*7/8*2 + var(--rad-spacing-sm)*2 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-layout-division--8-3-right .rad-layout-division__container:last-child {
        margin-left:calc(16.66667% - var(--rad-spacing-sm)*11/12*2 + var(--rad-spacing-sm)*2 + var(--rad-spacing-md)*0);
        margin-left: 0
    }
}

.rad-layout-division--mega-accordion .rad-layout-division__container:first-child {
    width: calc(100% - var(--rad-spacing-sm)*3/4*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-sm)*0)
}

@media(min-width: 600px) {
    .rad-layout-division--mega-accordion .rad-layout-division__container:first-child {
        width:calc(50% - var(--rad-spacing-sm)*7/8*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-layout-division--mega-accordion .rad-layout-division__container:first-child {
        width:calc(33.33333% - var(--rad-spacing-sm)*11/12*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 600px) {
    .rad-layout-division--mega-accordion .rad-layout-division__container:first-child {
        width:calc(150% - var(--rad-spacing-sm)*3/4*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-sm)*0);
        width: calc(75% - var(--rad-spacing-sm)*7/8*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-layout-division--mega-accordion .rad-layout-division__container:first-child {
        margin-left:calc(25% - var(--rad-spacing-sm)*3/4*1 + var(--rad-spacing-sm)*2 + var(--rad-spacing-sm)*0);
        margin-left: calc(12.5% - var(--rad-spacing-sm)*7/8*1 + var(--rad-spacing-sm)*2 + var(--rad-spacing-md)*0);
        margin-left: calc(8.33333% - var(--rad-spacing-sm)*11/12*1 + var(--rad-spacing-sm)*2 + var(--rad-spacing-md)*0);
        width: calc(50% - var(--rad-spacing-sm)*11/12*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0);
        width: calc(175% - var(--rad-spacing-sm)*3/4*7 + var(--rad-spacing-sm)*6 + var(--rad-spacing-sm)*0);
        width: calc(87.5% - var(--rad-spacing-sm)*7/8*7 + var(--rad-spacing-sm)*6 + var(--rad-spacing-md)*0);
        width: calc(58.33333% - var(--rad-spacing-sm)*11/12*7 + var(--rad-spacing-sm)*6 + var(--rad-spacing-md)*0)
    }
}

.rad-layout-division--mega-accordion .rad-layout-division__container:last-child {
    width: calc(75% - var(--rad-spacing-sm)*3/4*3 + var(--rad-spacing-sm)*2 + var(--rad-spacing-sm)*0)
}

@media(min-width: 600px) {
    .rad-layout-division--mega-accordion .rad-layout-division__container:last-child {
        width:calc(37.5% - var(--rad-spacing-sm)*7/8*3 + var(--rad-spacing-sm)*2 + var(--rad-spacing-md)*0);
        width: calc(100% - var(--rad-spacing-sm)*3/4*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-sm)*0);
        width: calc(50% - var(--rad-spacing-sm)*7/8*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-layout-division--mega-accordion .rad-layout-division__container:last-child {
        width:calc(33.33333% - var(--rad-spacing-sm)*11/12*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0);
        width: calc(75% - var(--rad-spacing-sm)*3/4*3 + var(--rad-spacing-sm)*2 + var(--rad-spacing-sm)*0);
        width: calc(37.5% - var(--rad-spacing-sm)*7/8*3 + var(--rad-spacing-sm)*2 + var(--rad-spacing-md)*0);
        width: calc(25% - var(--rad-spacing-sm)*11/12*3 + var(--rad-spacing-sm)*2 + var(--rad-spacing-md)*0)
    }
}

.rad-layout-division--mega-accordion-right .rad-layout-division__container:last-child {
    margin-left: calc(25% - var(--rad-spacing-sm)*3/4*1 + var(--rad-spacing-sm)*1 + var(--rad-spacing-sm)*0)
}

@media(min-width: 600px) {
    .rad-layout-division--mega-accordion-right .rad-layout-division__container:last-child {
        margin-left:calc(12.5% - var(--rad-spacing-sm)*7/8*1 + var(--rad-spacing-sm)*1 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-layout-division--mega-accordion-right .rad-layout-division__container:last-child {
        margin-left:calc(8.33333% - var(--rad-spacing-sm)*11/12*1 + var(--rad-spacing-sm)*1 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 600px) {
    .rad-layout-division--mega-accordion-right .rad-layout-division__container:last-child {
        margin-left:calc(50% - var(--rad-spacing-sm)*3/4*2 + var(--rad-spacing-sm)*2 + var(--rad-spacing-sm)*0);
        margin-left: calc(25% - var(--rad-spacing-sm)*7/8*2 + var(--rad-spacing-sm)*2 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-layout-division--mega-accordion-right .rad-layout-division__container:last-child {
        margin-left:calc(16.66667% - var(--rad-spacing-sm)*11/12*2 + var(--rad-spacing-sm)*2 + var(--rad-spacing-md)*0);
        margin-left: 0
    }
}

.rad-locations {
    padding-left: 1rem;
    padding-right: 1rem
}

@media(min-width: 600px) {
    .rad-locations {
        padding-left:3rem;
        padding-right: 3rem
    }
}

@media(min-width: 1200px) {
    .rad-locations {
        padding-left:5rem;
        padding-right: 5rem
    }
}

@media(min-width: 1441px) {
    .rad-locations {
        padding-right:calc(5rem + 5.56715vw - 80.22269px)
    }
}

@media(min-width: 1920px) {
    .rad-locations {
        padding-right:6.6666666667rem
    }
}

@media(min-width: 1441px) {
    .rad-locations {
        padding-left:calc(5rem + 5.56715vw - 80.22269px)
    }
}

@media(min-width: 1920px) {
    .rad-locations {
        padding-left:6.6666666667rem
    }
}

.rad-locations__hidden-on-mobile {
    display: none
}

@media(min-width: 600px) {
    .rad-locations__hidden-on-mobile {
        display:block
    }

    .rad-locations__visible-on-mobile {
        display: none
    }
}

.rad-locations__top {
    margin-bottom: 1.5rem
}

@media(min-width: 600px) {
    .rad-locations__top {
        -webkit-box-align:center;
        -ms-flex-align: center;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        align-items: center;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        justify-content: space-between;
        margin-bottom: 3rem
    }
}

@media(min-width: 1200px) {
    .rad-locations__top {
        height:2.6875rem
    }
}

@media(min-width: 1441px) {
    .rad-locations__top {
        height:calc(2.6875rem + 2.99235vw - 43.11969px)
    }
}

@media(min-width: 1920px) {
    .rad-locations__top {
        height:3.5833333333rem
    }
}

@media(min-width: 1441px) {
    .rad-locations__top {
        margin-bottom:5rem;
        margin-bottom: calc(5rem + 5.56715vw - 80.22269px)
    }
}

@media(min-width: 1920px) {
    .rad-locations__top {
        margin-bottom:6.6666666667rem
    }
}

.rad-locations__title {
    font-size: 1.375rem;
    font-weight: 500;
    letter-spacing: -0.02em;
    line-height: 1.25;
    text-align: center
}

.rad-locations__title:lang(ja-JP),.rad-locations__title:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-locations__title {
        font-size:1.75rem;
        line-height: 1.3
    }
}

@media(min-width: 1024px) {
    .rad-locations__title {
        font-size:2rem;
        line-height: 1.2
    }
}

@media(min-width: 1441px) {
    .rad-locations__title {
        font-size:calc(2rem + 2.22686vw - 32.08907px)
    }
}

@media(min-width: 1920px) {
    .rad-locations__title {
        font-size:2.6666666667rem
    }
}

@media(min-width: 600px) {
    .rad-locations__title {
        text-align:left
    }
}

.rad-locations__cta .rad-button {
    height: 2.5rem;
    margin: 0 auto
}

.rad-locations__cities {
    --image-closed-height: 2rem;
    --image-closed-width: 3rem;
    pointer-events: none
}

@media(max-width: 599px) {
    .rad-locations__cities {
        margin-bottom:1rem
    }
}

@media(min-width: 600px) {
    .rad-locations__cities {
        --image-closed-height:2.75rem;
        --image-closed-width: 4.125rem;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-column-gap: 1.25rem;
        -moz-column-gap: 1.25rem;
        column-gap: 1.25rem;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        justify-content: center;
        row-gap: 1rem
    }
}

@media(min-width: 1200px) {
    .rad-locations__cities {
        --image-closed-height:3.5625rem;
        --image-closed-width: 5.34375rem;
        --image-open-height: 8.8125rem;
        --image-open-width: 5.875rem;
        -webkit-column-gap: 1.5rem;
        -moz-column-gap: 1.5rem;
        column-gap: 1.5rem;
        row-gap: 1.5rem
    }
}

@media(min-width: 1441px) {
    .rad-locations__cities {
        --image-closed-height:5.375rem;
        --image-closed-width: 8.0625rem;
        --image-open-height: 13.125rem;
        --image-open-width: 8.75rem;
        --image-closed-height: calc(5.375rem + 5.98469vw - 86.23939px);
        -webkit-column-gap: 2rem;
        -moz-column-gap: 2rem;
        column-gap: 2rem;
        row-gap: 2.5rem
    }
}

@media(min-width: 1920px) {
    .rad-locations__cities {
        --image-closed-height:7.1666666667rem
    }
}

@media(min-width: 1441px) {
    .rad-locations__cities {
        --image-closed-width:calc(8.0625rem + 8.97704vw - 129.35908px)
    }
}

@media(min-width: 1920px) {
    .rad-locations__cities {
        --image-closed-width:10.75rem
    }
}

@media(min-width: 1441px) {
    .rad-locations__cities {
        --image-open-height:calc(13.125rem + 14.61378vw - 210.58455px)
    }
}

@media(min-width: 1920px) {
    .rad-locations__cities {
        --image-open-height:17.5rem
    }
}

@media(min-width: 1441px) {
    .rad-locations__cities {
        --image-open-width:calc(8.75rem + 9.74252vw - 140.3897px)
    }
}

@media(min-width: 1920px) {
    .rad-locations__cities {
        --image-open-width:11.6666666667rem
    }
}

@media(min-width: 1441px) {
    .rad-locations__cities {
        row-gap:calc(2.5rem + 2.78358vw - 40.11134px)
    }
}

@media(min-width: 1920px) {
    .rad-locations__cities {
        -webkit-column-gap:1.375rem;
        -moz-column-gap: 1.375rem;
        column-gap: 1.375rem;
        -webkit-column-gap: calc(1.375rem + 1.53097vw - 22.06124px);
        -moz-column-gap: calc(1.375rem + 1.53097vw - 22.06124px);
        column-gap: calc(1.375rem + 1.53097vw - 22.06124px);
        -webkit-column-gap: 1.8333333333rem;
        -moz-column-gap: 1.8333333333rem;
        column-gap: 1.8333333333rem;
        row-gap: 3.3333333333rem
    }
}

.rad-locations__cities:focus-within .rad-locations__city-link:not(:hover):not(:focus-visible) .rad-locations__city-image,.rad-locations__cities:hover .rad-locations__city-link:not(:hover):not(:focus-visible) .rad-locations__city-image {
    opacity: .3
}

@media(min-width: 600px) {
    .rad-locations__cities:focus-within .rad-locations__city-link:not(:hover):not(:focus-visible) .rad-locations__city-image,.rad-locations__cities:hover .rad-locations__city-link:not(:hover):not(:focus-visible) .rad-locations__city-image {
        opacity:.2
    }
}

.rad-locations__cities:focus-within .rad-locations__city-link:not(:hover):not(:focus-visible) .rad-locations__city-name,.rad-locations__cities:focus-within .rad-locations__city-link:not(:hover):not(:focus-visible):after,.rad-locations__cities:hover .rad-locations__city-link:not(:hover):not(:focus-visible) .rad-locations__city-name,.rad-locations__cities:hover .rad-locations__city-link:not(:hover):not(:focus-visible):after {
    color: #616160;
    -webkit-transition-duration: 550ms;
    transition-duration: 550ms;
    -webkit-transition-property: color,-webkit-transform;
    transition-property: color,-webkit-transform;
    transition-property: color,transform;
    transition-property: color,transform,-webkit-transform;
    -webkit-transition-timing-function: cubic-bezier(0.85,0,0,1);
    transition-timing-function: cubic-bezier(0.85,0,0,1)
}

@media(min-width: 600px) {
    .rad-locations__cities:focus-within .rad-locations__city-link:not(:hover):not(:focus-visible) .rad-locations__city-name,.rad-locations__cities:focus-within .rad-locations__city-link:not(:hover):not(:focus-visible):after,.rad-locations__cities:hover .rad-locations__city-link:not(:hover):not(:focus-visible) .rad-locations__city-name,.rad-locations__cities:hover .rad-locations__city-link:not(:hover):not(:focus-visible):after {
        color:#2b2b2b
    }
}

.rad-locations .rad-locations__city {
    margin-bottom: .75rem;
    max-width: 100%;
    overflow: visible;
    pointer-events: auto
}

.rad-locations .rad-locations__city:last-of-type {
    margin-bottom: 0
}

@media(min-width: 600px) {
    .rad-locations .rad-locations__city {
        margin-bottom:0
    }
}

.rad-locations .rad-locations__city:hover {
    -webkit-transition: none;
    transition: none
}

.rad-locations .rad-locations__city-link {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -ms-flex-negative: 0;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    text-decoration: none;
    -webkit-transition-duration: 550ms;
    transition-duration: 550ms;
    -webkit-transition-property: opacity;
    transition-property: opacity;
    -webkit-transition-timing-function: cubic-bezier(0.85,0,0,1);
    transition-timing-function: cubic-bezier(0.85,0,0,1)
}

.rad-locations .rad-locations__city-link:after {
    -webkit-transition-duration: 550ms;
    transition-duration: 550ms;
    -webkit-transition-property: color;
    transition-property: color;
    -webkit-transition-timing-function: cubic-bezier(0.85,0,0,1);
    transition-timing-function: cubic-bezier(0.85,0,0,1)
}

@media(max-width: 599px) {
    .rad-locations .rad-locations__city-link:after {
        -webkit-box-align:center;
        -ms-flex-align: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        align-items: center;
        content: "";
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        font-family: icont;
        font-size: .75rem;
        font-style: normal;
        font-weight: 400;
        height: 1.5rem;
        justify-content: center;
        width: 1.5rem
    }
}

.rad-locations .rad-locations__city-link:focus-visible,.rad-locations .rad-locations__city-link:hover {
    color: #fff;
    cursor: pointer
}

@media(min-width: 1200px) {
    .rad-locations .rad-locations__city-link:hover .rad-locations__city-image-sizer {
        height:var(--image-open-height);
        opacity: 1;
        width: var(--image-open-width)
    }

    .rad-locations .rad-locations__city-link:hover .rad-locations__city-name,.rad-locations .rad-locations__city-link:hover:after {
        -webkit-transform: translateX(calc(var(--image-open-width)/2 - var(--image-closed-width)/2));
        transform: translateX(calc(var(--image-open-width)/2 - var(--image-closed-width)/2))
    }
}

.rad-locations .rad-locations__city-link:active .rad-locations__city-image {
    opacity: .7
}

@media(min-width: 600px) {
    .rad-locations .rad-locations__city-link:active .rad-locations__city-image {
        opacity:.8
    }
}

.rad-locations .rad-locations__city-link:active .rad-locations__city-name,.rad-locations .rad-locations__city-link:active:after {
    color: #a2a2a0
}

@media(min-width: 1200px) {
    .rad-locations .rad-locations__city-link:active .rad-locations__city-image,.rad-locations .rad-locations__city-link:active:after {
        opacity:.5
    }
}

.rad-locations .rad-locations__city-link:visited {
    color: #fff
}

.rad-locations__city-image,.rad-locations__city-image-sizer {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: var(--image-closed-height);
    justify-content: center;
    -webkit-transition-duration: 550ms;
    transition-duration: 550ms;
    -webkit-transition-property: opacity;
    transition-property: opacity;
    -webkit-transition-timing-function: cubic-bezier(0.85,0,0,1);
    transition-timing-function: cubic-bezier(0.85,0,0,1);
    width: var(--image-closed-width)
}

.rad-locations__city-image-sizer {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    overflow: hidden;
    -webkit-transition-duration: 550ms;
    transition-duration: 550ms;
    -webkit-transition-property: height,width;
    transition-property: height,width;
    -webkit-transition-timing-function: cubic-bezier(0.85,0,0,1);
    transition-timing-function: cubic-bezier(0.85,0,0,1)
}

.rad-locations__city-image-sizer__image {
    width: 100%
}

.rad-locations__city-name {
    font-family: Graphik;
    font-size: clamp(19px,6vw,38px);
    font-weight: 500;
    letter-spacing: -0.03em;
    line-height: 1.15;
    margin-left: .5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-transition-duration: 550ms;
    transition-duration: 550ms;
    -webkit-transition-property: color;
    transition-property: color;
    -webkit-transition-timing-function: cubic-bezier(0.85,0,0,1);
    transition-timing-function: cubic-bezier(0.85,0,0,1);
    white-space: nowrap
}

@media(min-width: 1441px) {
    .rad-locations__city-name {
        margin-left:0.75rem;
        margin-left: calc(0.75rem + 0.83507vw - 12.0334px)
    }
}

@media(min-width: 1920px) {
    .rad-locations__city-name {
        margin-left:1rem
    }
}

@media(min-width: 600px) {
    .rad-locations__city-name,.rad-locations__city:not(:last-child) .rad-locations__city-link:after {
        content:",";
        font-family: Graphik;
        font-size: clamp(33px,6.1vw,65px);
        font-weight: 500;
        letter-spacing: -0.03em;
        line-height: 1.1
    }
}

@media(min-width: 1024px) {
    .rad-locations__city-name,.rad-locations__city:not(:last-child) .rad-locations__city-link:after {
        font-size:clamp(55px,6.5vw,88px)
    }
}

@media(min-width: 1200px) {
    .rad-locations__city-name,.rad-locations__city:not(:last-child) .rad-locations__city-link:after {
        -webkit-transition-duration:550ms;
        transition-duration: 550ms;
        -webkit-transition-property: color,-webkit-transform;
        transition-property: color,-webkit-transform;
        transition-property: transform,color;
        transition-property: transform,color,-webkit-transform;
        -webkit-transition-timing-function: cubic-bezier(0.85,0,0,1);
        transition-timing-function: cubic-bezier(0.85,0,0,1)
    }
}

@media(min-width: 1441px) {
    .rad-locations__city-name,.rad-locations__city:not(:last-child) .rad-locations__city-link:after {
        font-size:88px;
        font-size: calc(-0.24495px + 6.12387vw)
    }
}

@media(min-width: 1920px) {
    .rad-locations__city-name,.rad-locations__city:not(:last-child) .rad-locations__city-link:after {
        font-size:117.3333333333px
    }
}

.rad-component__title--large-text .rad-locations__title {
    font-size: 1.75rem;
    font-weight: 500;
    letter-spacing: -0.03em;
    line-height: 1.3
}

.rad-component__title--large-text .rad-locations__title:lang(ja-JP),.rad-component__title--large-text .rad-locations__title:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-component__title--large-text .rad-locations__title {
        font-size:2.5rem;
        line-height: 1.2
    }
}

@media(min-width: 1024px) {
    .rad-component__title--large-text .rad-locations__title {
        font-size:3rem;
        line-height: 1.2
    }
}

@media(min-width: 1441px) {
    .rad-component__title--large-text .rad-locations__title {
        font-size:calc(3rem + 3.34029vw - 48.13361px)
    }
}

@media(min-width: 1920px) {
    .rad-component__title--large-text .rad-locations__title {
        font-size:4rem
    }
}

@media(max-width: 599px) {
    .rad-locations.rad-spacing-vertical-md {
        padding-bottom:1rem
    }
}

.rad-mixed-media-and-text {
    --secondary-media-overlap: 12px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    max-width: 1920px;
    position: relative
}

.rad-mixed-media-and-text__media {
    aspect-ratio: 1.5;
    margin-bottom: 24px;
    position: relative
}

.rad-mixed-media-and-text__media .rad-mixed-media-and-text__primary-media {
    max-width: 100vw;
    -o-object-fit: cover;
    object-fit: cover;
    position: absolute;
    width: 100%
}

.rad-mixed-media-and-text__media .rad-mixed-media-and-text__primary-media .cmp-image {
    height: 100%
}

.rad-mixed-media-and-text__media .rad-mixed-media-and-text__primary-media img,.rad-mixed-media-and-text__media .rad-mixed-media-and-text__primary-media video {
    display: block;
    height: 100%;
    max-width: none;
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%
}

.rad-mixed-media-and-text__media .rad-mixed-media-and-text__secondary-media {
    aspect-ratio: 4/1;
    max-width: none;
    position: absolute;
    top: calc(100% - var(--secondary-media-overlap));
    width: 100%
}

.rad-mixed-media-and-text__text {
    padding-left: var(--rad-spacing-sm);
    padding-right: var(--rad-spacing-sm);
    position: relative
}

@media(min-width: 600px) {
    .rad-mixed-media-and-text__text {
        padding-left:var(--rad-spacing-md);
        padding-right: var(--rad-spacing-md)
    }
}

.rad-component--nested .rad-mixed-media-and-text__text {
    padding-left: 0;
    padding-right: 0
}

.rad-mixed-media-and-text__text .rad-mixed-media-and-text__label {
    font-size: .75rem;
    font-weight: 500;
    letter-spacing: .02em;
    line-height: 1.2;
    margin-bottom: 1rem;
    text-transform: uppercase
}

.rad-mixed-media-and-text__text .rad-mixed-media-and-text__label:lang(ja-JP),.rad-mixed-media-and-text__text .rad-mixed-media-and-text__label:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 1024px) {
    .rad-mixed-media-and-text__text .rad-mixed-media-and-text__label {
        font-size:0.875rem
    }
}

@media(min-width: 1441px) {
    .rad-mixed-media-and-text__text .rad-mixed-media-and-text__label {
        font-size:calc(0.875rem + 0.97425vw - 14.03897px)
    }
}

@media(min-width: 1920px) {
    .rad-mixed-media-and-text__text .rad-mixed-media-and-text__label {
        font-size:1.1666666667rem
    }
}

.rad-mixed-media-and-text__text .rad-mixed-media-and-text__title {
    font-size: 1.5rem;
    font-weight: 500;
    letter-spacing: -0.03em;
    line-height: 1.35;
    margin-bottom: 1rem
}

.rad-mixed-media-and-text__text .rad-mixed-media-and-text__title:lang(ja-JP),.rad-mixed-media-and-text__text .rad-mixed-media-and-text__title:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-mixed-media-and-text__text .rad-mixed-media-and-text__title {
        font-size:2rem;
        line-height: 1.2
    }
}

@media(min-width: 1024px) {
    .rad-mixed-media-and-text__text .rad-mixed-media-and-text__title {
        font-size:2.5rem;
        line-height: 1.2
    }
}

@media(min-width: 1441px) {
    .rad-mixed-media-and-text__text .rad-mixed-media-and-text__title {
        font-size:calc(2.5rem + 2.78358vw - 40.11134px)
    }
}

@media(min-width: 1920px) {
    .rad-mixed-media-and-text__text .rad-mixed-media-and-text__title {
        font-size:3.3333333333rem
    }
}

.rad-mixed-media-and-text__text .rad-mixed-media-and-text__description {
    font-family: GT Sectra Fine,Palatino;
    font-size: 1.125rem;
    font-weight: 300;
    line-height: 1.3;
    margin-bottom: .5rem
}

@media(min-width: 600px) {
    .rad-mixed-media-and-text__text .rad-mixed-media-and-text__description {
        font-size:1.25rem;
        line-height: 1.3
    }
}

@media(min-width: 1024px) {
    .rad-mixed-media-and-text__text .rad-mixed-media-and-text__description {
        font-size:1.5rem;
        line-height: 1.25
    }
}

@media(min-width: 1441px) {
    .rad-mixed-media-and-text__text .rad-mixed-media-and-text__description {
        font-size:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-mixed-media-and-text__text .rad-mixed-media-and-text__description {
        font-size:2rem
    }
}

.rad-mixed-media-and-text.rad-mixed-media-and-text--with-optional .rad-mixed-media-and-text__media {
    margin-bottom: calc(24px - var(--secondary-media-overlap) + 25vw)
}

.rad-mixed-media-and-text-header {
    margin-bottom: 1.5rem;
    margin-left: 1rem
}

@media(min-width: 600px) {
    .rad-mixed-media-and-text-header {
        -ms-flex-preferred-size:100%;
        flex-basis: 100%;
        margin-bottom: 2.5rem;
        margin-left: 3rem
    }
}

@media(min-width: 1024px) {
    .rad-mixed-media-and-text-header {
        margin-bottom:3rem;
        margin-left: 5rem
    }
}

@media(min-width: 1441px) {
    .rad-mixed-media-and-text-header {
        margin-bottom:calc(3rem + 3.34029vw - 48.13361px)
    }
}

@media(min-width: 1920px) {
    .rad-mixed-media-and-text-header {
        margin-bottom:4rem
    }
}

@media(min-width: 1441px) {
    .rad-mixed-media-and-text-header {
        margin-left:calc(5rem + 5.56715vw - 80.22269px)
    }
}

@media(min-width: 1920px) {
    .rad-mixed-media-and-text-header {
        margin-left:6.6666666667rem
    }
}

.rad-mixed-media-and-text-header__headline {
    font-size: 1.75rem;
    font-weight: 500;
    letter-spacing: -0.03em;
    line-height: 1.3;
    width: calc(75% - var(--rad-spacing-sm)*3/4*3 + var(--rad-spacing-sm)*2 + var(--rad-spacing-sm)*0)
}

.rad-mixed-media-and-text-header__headline:lang(ja-JP),.rad-mixed-media-and-text-header__headline:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-mixed-media-and-text-header__headline {
        font-size:2.5rem;
        line-height: 1.2
    }
}

@media(min-width: 1024px) {
    .rad-mixed-media-and-text-header__headline {
        font-size:3rem;
        line-height: 1.2
    }
}

@media(min-width: 1441px) {
    .rad-mixed-media-and-text-header__headline {
        font-size:calc(3rem + 3.34029vw - 48.13361px)
    }
}

@media(min-width: 1920px) {
    .rad-mixed-media-and-text-header__headline {
        font-size:4rem
    }
}

@media(min-width: 600px) {
    .rad-mixed-media-and-text-header__headline {
        width:calc(37.5% - var(--rad-spacing-sm)*7/8*3 + var(--rad-spacing-sm)*2 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-mixed-media-and-text-header__headline {
        width:calc(25% - var(--rad-spacing-sm)*11/12*3 + var(--rad-spacing-sm)*2 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 600px) {
    .rad-mixed-media-and-text-header__headline {
        width:calc(200% - var(--rad-spacing-sm)*3/4*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-sm)*0);
        width: calc(100% - var(--rad-spacing-sm)*7/8*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-mixed-media-and-text-header__headline {
        width:calc(66.66667% - var(--rad-spacing-sm)*11/12*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 600px) {
    .rad-mixed-media-and-text {
        -webkit-box-orient:horizontal;
        -webkit-box-direction: normal;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        -ms-flex-direction: row;
        flex-direction: row;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        justify-content: space-between
    }

    .rad-mixed-media-and-text__media {
        -ms-flex-item-align: start;
        align-self: start;
        margin: 0;
        width: calc(min(100vw, 1920px)/4*4 - var(--rad-spacing-sm)*2/4*4 - var(--rad-spacing-sm)*3/4*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-sm)*1);
        width: calc(min(100vw, 1920px)/8*4 - var(--rad-spacing-md)*2/8*4 - var(--rad-spacing-sm)*7/8*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*1)
    }
}

@media(min-width: 1024px) {
    .rad-mixed-media-and-text__media {
        width:calc(min(100vw, 1920px)/12*4 - var(--rad-spacing-md)*2/12*4 - var(--rad-spacing-sm)*11/12*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*1)
    }
}

@media(min-width: 600px) {
    .rad-mixed-media-and-text__media .rad-mixed-media-and-text__primary-media {
        aspect-ratio:1.5
    }

    .rad-mixed-media-and-text__media .rad-mixed-media-and-text__secondary-media {
        bottom: var(--secondary-media-overlap);
        top: auto
    }

    .rad-mixed-media-and-text__text {
        margin-right: var(--rad-spacing-md);
        margin-top: 7.5rem;
        padding: 0;
        width: calc(min(100vw, 1920px)/4*3 - var(--rad-spacing-sm)*2/4*3 - var(--rad-spacing-sm)*3/4*3 + var(--rad-spacing-sm)*2 + var(--rad-spacing-sm)*0);
        width: calc(min(100vw, 1920px)/8*3 - var(--rad-spacing-md)*2/8*3 - var(--rad-spacing-sm)*7/8*3 + var(--rad-spacing-sm)*2 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-mixed-media-and-text__text {
        width:calc(min(100vw, 1920px)/12*3 - var(--rad-spacing-md)*2/12*3 - var(--rad-spacing-sm)*11/12*3 + var(--rad-spacing-sm)*2 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 600px) {
    .rad-mixed-media-and-text.rad-mixed-media-and-text--with-optional .rad-mixed-media-and-text__media {
        aspect-ratio:12/11;
        margin-bottom: calc(var(--secondary-media-overlap)*-1)
    }
}

@media(min-width: 1024px) {
    .rad-mixed-media-and-text {
        --secondary-media-overlap:24px
    }
}

@media(min-width: 1441px) {
    .rad-mixed-media-and-text {
        --secondary-media-overlap:calc(-0.06681px + 1.67015vw)
    }
}

@media(min-width: 1920px) {
    .rad-mixed-media-and-text {
        --secondary-media-overlap:32px
    }
}

@media(min-width: 1024px) {
    .rad-mixed-media-and-text__media {
        width:calc(min(100vw, 1920px)/4*6 - var(--rad-spacing-sm)*2/4*6 - var(--rad-spacing-sm)*3/4*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-sm)*1);
        width: calc(min(100vw, 1920px)/8*6 - var(--rad-spacing-md)*2/8*6 - var(--rad-spacing-sm)*7/8*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*1);
        width: calc(min(100vw, 1920px)/12*6 - var(--rad-spacing-md)*2/12*6 - var(--rad-spacing-sm)*11/12*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*1)
    }

    .rad-mixed-media-and-text__text {
        margin-top: 11.5rem;
        width: calc(min(100vw, 1920px)/4*4 - var(--rad-spacing-sm)*2/4*4 - var(--rad-spacing-sm)*3/4*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-sm)*0);
        width: calc(min(100vw, 1920px)/8*4 - var(--rad-spacing-md)*2/8*4 - var(--rad-spacing-sm)*7/8*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0);
        width: calc(min(100vw, 1920px)/12*4 - var(--rad-spacing-md)*2/12*4 - var(--rad-spacing-sm)*11/12*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1441px) {
    .rad-mixed-media-and-text__text {
        margin-top:calc(11.5rem + 12.80445vw - 184.51218px)
    }
}

@media(min-width: 1920px) {
    .rad-mixed-media-and-text__text {
        margin-top:15.3333333333rem
    }
}

@media(min-width: 1024px) {
    .rad-mixed-media-and-text__text .rad-mixed-media-and-text__label {
        margin-bottom:1.5rem
    }
}

@media(min-width: 1441px) {
    .rad-mixed-media-and-text__text .rad-mixed-media-and-text__label {
        margin-bottom:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-mixed-media-and-text__text .rad-mixed-media-and-text__label {
        margin-bottom:2rem
    }
}

@media(min-width: 1024px) {
    .rad-mixed-media-and-text__text .rad-mixed-media-and-text__title {
        margin-bottom:1.5rem
    }
}

@media(min-width: 1441px) {
    .rad-mixed-media-and-text__text .rad-mixed-media-and-text__title {
        margin-bottom:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-mixed-media-and-text__text .rad-mixed-media-and-text__title {
        margin-bottom:2rem
    }
}

@media(min-width: 1024px) {
    .rad-mixed-media-and-text__text .rad-mixed-media-and-text__description {
        margin-bottom:1rem
    }
}

@media(min-width: 1441px) {
    .rad-mixed-media-and-text__text .rad-mixed-media-and-text__description {
        margin-bottom:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-mixed-media-and-text__text .rad-mixed-media-and-text__description {
        margin-bottom:1.3333333333rem
    }
}

.rad-news {
    --controls-spacing-height: 64px;
    overflow: hidden;
    position: relative
}

.rad-news .rad-news-container {
    padding-top: 0;
    position: relative
}

.rad-news .rad-news-slideshow {
    padding-bottom: var(--controls-spacing-height);
    pointer-events: none
}

.rad-news .flickity-viewport {
    pointer-events: all;
    width: 100%
}

.rad-news .flickity-button {
    background: none
}

.rad-news .next,.rad-news .previous,.rad-news-play-pause {
    --rad-icon-button-grey-size: 32px
}

@media(min-width: 600px) {
    .rad-news .next,.rad-news .previous,.rad-news-play-pause {
        --rad-icon-button-grey-size:40px
    }
}

@media(min-width: 1024px) {
    .rad-news .next,.rad-news .previous,.rad-news-play-pause {
        --rad-icon-button-grey-size:48px
    }
}

@media(min-width: 1441px) {
    .rad-news .next,.rad-news .previous,.rad-news-play-pause {
        --rad-icon-button-grey-size:calc(-0.13361px + 3.34029vw)
    }
}

@media(min-width: 1920px) {
    .rad-news .next,.rad-news .previous,.rad-news-play-pause {
        --rad-icon-button-grey-size:64px
    }
}

@media(min-width: 1024px) {
    .rad-news .next .rad-icon:after,.rad-news .previous .rad-icon:after,.rad-news-play-pause .rad-icon:after {
        scale:1.2
    }
}

.rad-news .next,.rad-news .previous {
    bottom: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    pointer-events: all;
    position: absolute
}

.rad-news .previous {
    right: 4px;
    translate: calc((100% + var(--rad-spacing-sm))*-1)
}

.rad-news .next {
    right: var(--rad-spacing-sm)
}

.rad-news-play-pause {
    bottom: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-left: var(--rad-spacing-sm);
    pointer-events: all;
    position: absolute
}

.rad-news-play-pause:focus-visible {
    outline-offset: 0
}

.rad-news-play-pause--disabled {
    display: none
}

.rad-news-header {
    font-size: 1.375rem;
    font-weight: 500;
    letter-spacing: -0.02em;
    line-height: 1.25;
    margin-bottom: 1.5rem;
    padding-left: var(--rad-spacing-sm);
    padding-right: var(--rad-spacing-sm);
    width: calc(100% - var(--rad-spacing-sm)*3/4*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-sm)*0)
}

@media(min-width: 600px) {
    .rad-news-header {
        width:calc(50% - var(--rad-spacing-sm)*7/8*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-news-header {
        width:calc(33.33333% - var(--rad-spacing-sm)*11/12*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0)
    }
}

.rad-news-header:lang(ja-JP),.rad-news-header:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-news-header {
        font-size:1.75rem;
        line-height: 1.3
    }
}

@media(min-width: 1024px) {
    .rad-news-header {
        font-size:2rem;
        line-height: 1.2
    }
}

@media(min-width: 1441px) {
    .rad-news-header {
        font-size:calc(2rem + 2.22686vw - 32.08907px)
    }
}

@media(min-width: 1920px) {
    .rad-news-header {
        font-size:2.6666666667rem
    }
}

.rad-news-card {
    text-decoration: none
}

.rad-news-card:-moz-any-link {
    color: #fff
}

.rad-news-card:any-link {
    color: #fff
}

.rad-news-card:hover {
    color: #a2a2a0;
    cursor: pointer
}

.rad-news-card:active {
    color: #e3e3df
}

.rad-news-card:focus-visible {
    display: block;
    outline-offset: .0625rem
}

.rad-news-card .rad-news-eyebrow {
    font-size: .75rem;
    font-weight: 500;
    letter-spacing: -0.005em;
    line-height: 1.5;
    padding-bottom: 1rem;
    text-underline-offset: .25rem
}

.rad-news-card .rad-news-eyebrow:lang(ja-JP),.rad-news-card .rad-news-eyebrow:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-news-card .rad-news-eyebrow {
        font-size:0.875rem
    }
}

@media(min-width: 1441px) {
    .rad-news-card .rad-news-eyebrow {
        font-size:calc(0.875rem + 0.97425vw - 14.03897px)
    }
}

@media(min-width: 1920px) {
    .rad-news-card .rad-news-eyebrow {
        font-size:1.1666666667rem
    }
}

.rad-news-card .rad-news-title {
    font-size: 1.5rem;
    font-weight: 500;
    letter-spacing: -0.03em;
    line-height: 1.35
}

.rad-news-card .rad-news-title:lang(ja-JP),.rad-news-card .rad-news-title:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-news-card .rad-news-title {
        font-size:2rem;
        line-height: 1.2
    }
}

@media(min-width: 1024px) {
    .rad-news-card .rad-news-title {
        font-size:2.5rem;
        line-height: 1.2
    }
}

@media(min-width: 1441px) {
    .rad-news-card .rad-news-title {
        font-size:calc(2.5rem + 2.78358vw - 40.11134px)
    }
}

@media(min-width: 1920px) {
    .rad-news-card .rad-news-title {
        font-size:3.3333333333rem
    }
}

.rad-news-carousel-cell {
    padding-left: var(--rad-spacing-sm);
    padding-right: var(--rad-spacing-sm)
}

@media(min-width: 600px) {
    .rad-news {
        --controls-spacing-height:72px
    }

    .rad-news .previous {
        right: 16px;
        translate: calc((100% + var(--rad-spacing-md))*-1)
    }

    .rad-news .next {
        right: var(--rad-spacing-md)
    }

    .rad-news-header {
        margin-bottom: 2.5rem;
        padding-left: var(--rad-spacing-md);
        padding-right: 0;
        width: calc(150% - var(--rad-spacing-sm)*3/4*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-sm)*0);
        width: calc(75% - var(--rad-spacing-sm)*7/8*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-news-header {
        width:calc(50% - var(--rad-spacing-sm)*11/12*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 600px) {
    .rad-news-carousel-cell {
        margin-top:.09375rem;
        padding-left: var(--rad-spacing-md);
        padding-right: 0;
        padding-right: calc(min(100vw, 1920px)/4*1 - var(--rad-spacing-sm)*2/4*1 - var(--rad-spacing-sm)*3/4*1 + var(--rad-spacing-sm)*2 + var(--rad-spacing-sm)*-1);
        width: calc(min(100vw, 1920px)/4*7 - var(--rad-spacing-sm)*2/4*7 - var(--rad-spacing-sm)*3/4*7 + var(--rad-spacing-sm)*7 + var(--rad-spacing-sm)*0);
        width: calc(min(100vw, 1920px)/8*7 - var(--rad-spacing-md)*2/8*7 - var(--rad-spacing-sm)*7/8*7 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-news-carousel-cell {
        width:calc(min(100vw, 1920px)/12*7 - var(--rad-spacing-md)*2/12*7 - var(--rad-spacing-sm)*11/12*7 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 600px) {
    .rad-news-carousel-cell {
        padding-right:calc(min(100vw, 1920px)/8*1 - var(--rad-spacing-md)*2/8*1 - var(--rad-spacing-sm)*7/8*1 + var(--rad-spacing-sm)*2 + var(--rad-spacing-md)*-1)
    }
}

@media(min-width: 1024px) {
    .rad-news-carousel-cell {
        padding-right:calc(min(100vw, 1920px)/12*1 - var(--rad-spacing-md)*2/12*1 - var(--rad-spacing-sm)*11/12*1 + var(--rad-spacing-sm)*2 + var(--rad-spacing-md)*-1)
    }
}

@media(min-width: 600px) {
    .rad-news-card .rad-news-eyebrow {
        padding-bottom:1.5rem
    }

    .rad-news-play-pause {
        margin-left: var(--rad-spacing-md)
    }
}

@media(min-width: 1024px) {
    .rad-news {
        --controls-spacing-height:80px
    }
}

@media(min-width: 1441px) {
    .rad-news {
        --controls-spacing-height:calc(-0.22269px + 5.56715vw)
    }
}

@media(min-width: 1920px) {
    .rad-news {
        --controls-spacing-height:106.6666666667px
    }
}

@media(min-width: 1024px) {
    .rad-news .previous {
        right:24px
    }
}

@media(min-width: 1441px) {
    .rad-news .previous {
        right:calc(-0.06681px + 1.67015vw)
    }
}

@media(min-width: 1920px) {
    .rad-news .previous {
        right:32px
    }
}

@media(min-width: 1024px) {
    .rad-news-carousel-cell {
        padding-right:calc(min(100vw, 1920px)/4*1 - var(--rad-spacing-sm)*2/4*1 - var(--rad-spacing-sm)*3/4*1 + var(--rad-spacing-sm)*1 + var(--rad-spacing-sm)*-1);
        padding-right: calc(min(100vw, 1920px)/8*1 - var(--rad-spacing-md)*2/8*1 - var(--rad-spacing-sm)*7/8*1 + var(--rad-spacing-sm)*1 + var(--rad-spacing-md)*-1);
        padding-right: calc(min(100vw, 1920px)/12*1 - var(--rad-spacing-md)*2/12*1 - var(--rad-spacing-sm)*11/12*1 + var(--rad-spacing-sm)*1 + var(--rad-spacing-md)*-1);
        width: calc(min(100vw, 1920px)/4*9 - var(--rad-spacing-sm)*2/4*9 - var(--rad-spacing-sm)*3/4*9 + var(--rad-spacing-sm)*8 + var(--rad-spacing-sm)*0);
        width: calc(min(100vw, 1920px)/8*9 - var(--rad-spacing-md)*2/8*9 - var(--rad-spacing-sm)*7/8*9 + var(--rad-spacing-sm)*8 + var(--rad-spacing-md)*0);
        width: calc(min(100vw, 1920px)/12*9 - var(--rad-spacing-md)*2/12*9 - var(--rad-spacing-sm)*11/12*9 + var(--rad-spacing-sm)*8 + var(--rad-spacing-md)*0)
    }

    .rad-news-header {
        margin-bottom: 5rem
    }
}

@media(min-width: 1441px) {
    .rad-news-header {
        margin-bottom:calc(5rem + 5.56715vw - 80.22269px)
    }
}

@media(min-width: 1920px) {
    .rad-news-header {
        margin-bottom:6.6666666667rem
    }
}

@media(min-width: 1024px) {
    .rad-news-eyebrow {
        padding-bottom:2rem
    }
}

.rad-component__title--large-text .rad-news-header {
    font-size: 1.75rem;
    font-weight: 500;
    letter-spacing: -0.03em;
    line-height: 1.3
}

.rad-component__title--large-text .rad-news-header:lang(ja-JP),.rad-component__title--large-text .rad-news-header:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-component__title--large-text .rad-news-header {
        font-size:2.5rem;
        line-height: 1.2
    }
}

@media(min-width: 1024px) {
    .rad-component__title--large-text .rad-news-header {
        font-size:3rem;
        line-height: 1.2
    }
}

@media(min-width: 1441px) {
    .rad-component__title--large-text .rad-news-header {
        font-size:calc(3rem + 3.34029vw - 48.13361px)
    }
}

@media(min-width: 1920px) {
    .rad-component__title--large-text .rad-news-header {
        font-size:4rem
    }
}

.rad-related-links {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: var(--rad-spacing-sm);
    padding-right: var(--rad-spacing-sm)
}

@media(min-width: 600px) {
    .rad-related-links {
        padding-left:var(--rad-spacing-md);
        padding-right: var(--rad-spacing-md)
    }
}

.rad-component--nested .rad-related-links {
    padding-left: 0;
    padding-right: 0
}

.rad-related-links__section-header {
    word-wrap: break-word;
    font-size: 1.375rem;
    font-weight: 500;
    letter-spacing: -0.02em;
    line-height: 1.25;
    margin-bottom: 1rem
}

.rad-related-links__section-header:lang(ja-JP),.rad-related-links__section-header:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-related-links__section-header {
        font-size:1.75rem;
        line-height: 1.3
    }
}

@media(min-width: 1024px) {
    .rad-related-links__section-header {
        font-size:2rem;
        line-height: 1.2
    }
}

@media(min-width: 1441px) {
    .rad-related-links__section-header {
        font-size:calc(2rem + 2.22686vw - 32.08907px)
    }
}

@media(min-width: 1920px) {
    .rad-related-links__section-header {
        font-size:2.6666666667rem
    }
}

.rad-related-links__list {
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: -0.005em;
    line-height: 1.6;
    padding-left: 48px;
    text-underline-offset: .25rem
}

.rad-related-links__list:lang(ja-JP),.rad-related-links__list:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-related-links__list {
        font-size:1.125rem
    }
}

@media(min-width: 1024px) {
    .rad-related-links__list {
        font-size:1.25rem
    }
}

@media(min-width: 1441px) {
    .rad-related-links__list {
        font-size:calc(1.25rem + 1.39179vw - 20.05567px)
    }
}

@media(min-width: 1920px) {
    .rad-related-links__list {
        font-size:1.6666666667rem
    }
}

@media(min-width: 1441px) {
    .rad-related-links__list {
        padding-left:calc(-0.13361px + 3.34029vw)
    }
}

@media(min-width: 1920px) {
    .rad-related-links__list {
        padding-left:64px
    }
}

.rad-related-links__list li {
    margin-bottom: 0.5rem;
    position: relative
}

@media(min-width: 1441px) {
    .rad-related-links__list li {
        margin-bottom:calc(0.5rem + 0.55672vw - 8.02227px)
    }
}

@media(min-width: 1920px) {
    .rad-related-links__list li {
        margin-bottom:0.6666666667rem
    }
}

.rad-related-links__list li:last-of-type {
    margin-bottom: 0
}

.rad-related-links__list li:before {
    color: #a100ff;
    content: "";
    font-family: icont;
    font-size: 0.9375rem;
    position: absolute;
    right: 100%;
    top: 0.375rem;
    translate: -1.8125rem
}

@media(min-width: 1441px) {
    .rad-related-links__list li:before {
        font-size:calc(0.9375rem + 1.04384vw - 15.04175px)
    }
}

@media(min-width: 1920px) {
    .rad-related-links__list li:before {
        font-size:1.25rem
    }
}

@media(min-width: 1441px) {
    .rad-related-links__list li:before {
        top:calc(0.375rem + 0.41754vw - 6.0167px)
    }
}

@media(min-width: 1920px) {
    .rad-related-links__list li:before {
        top:0.5rem
    }
}

@media(min-width: 1441px) {
    .rad-related-links__list li:before {
        translate:calc(-1.8125rem - 2.01809vw + 29.08072px)
    }
}

@media(min-width: 1920px) {
    .rad-related-links__list li:before {
        translate:-2.4166666667rem
    }
}

.rad-related-links__list li a {
    background-image: -webkit-gradient(linear,left top,left bottom,from(#dcafff),to(#dcafff));
    background-image: linear-gradient(#dcafff,#dcafff);
    background-position: 0 100%;
    background-repeat: no-repeat;
    background-size: 0 1px;
    text-decoration: none;
    -webkit-transition: background-size 550ms cubic-bezier(0.85,0,0,1);
    transition: background-size 550ms cubic-bezier(0.85,0,0,1)
}

.rad-related-links__list li a:hover {
    background-size: 100% 1px
}

.rad-related-links__list li sup {
    font-size: .625rem;
    font-weight: 600
}

@media(min-width: 600px) {
    .rad-related-links {
        -webkit-box-orient:horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row
    }

    .rad-related-links__section-header {
        -ms-flex-negative: 0;
        flex-shrink: 0;
        margin-bottom: 0;
        margin-right: var(--rad-spacing-sm);
        width: calc(75% - var(--rad-spacing-sm)*3/4*3 + var(--rad-spacing-sm)*2 + var(--rad-spacing-sm)*0);
        width: calc(37.5% - var(--rad-spacing-sm)*7/8*3 + var(--rad-spacing-sm)*2 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-related-links__section-header {
        width:calc(25% - var(--rad-spacing-sm)*11/12*3 + var(--rad-spacing-sm)*2 + var(--rad-spacing-md)*0)
    }
}

.rad-component__title--large-text .rad-related-links__section-header {
    font-size: 1.75rem;
    font-weight: 500;
    letter-spacing: -0.03em;
    line-height: 1.3
}

.rad-component__title--large-text .rad-related-links__section-header:lang(ja-JP),.rad-component__title--large-text .rad-related-links__section-header:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-component__title--large-text .rad-related-links__section-header {
        font-size:2.5rem;
        line-height: 1.2
    }
}

@media(min-width: 1024px) {
    .rad-component__title--large-text .rad-related-links__section-header {
        font-size:3rem;
        line-height: 1.2
    }
}

@media(min-width: 1441px) {
    .rad-component__title--large-text .rad-related-links__section-header {
        font-size:calc(3rem + 3.34029vw - 48.13361px)
    }
}

@media(min-width: 1920px) {
    .rad-component__title--large-text .rad-related-links__section-header {
        font-size:4rem
    }
}

.rad-smart-filter-2 {
    padding-bottom: 1.5rem;
    padding-left: var(--rad-spacing-sm);
    padding-right: var(--rad-spacing-sm)
}

@media(min-width: 600px) {
    .rad-smart-filter-2 {
        padding-left:var(--rad-spacing-md);
        padding-right: var(--rad-spacing-md)
    }
}

.rad-component--nested .rad-smart-filter-2 {
    padding-left: 0;
    padding-right: 0
}

@media(min-width: 600px) {
    .rad-smart-filter-2 {
        padding-bottom:3.75rem
    }
}

@media(min-width: 1441px) {
    .rad-smart-filter-2 {
        padding-bottom:calc(3.75rem + 4.17537vw - 60.16701px)
    }
}

@media(min-width: 1920px) {
    .rad-smart-filter-2 {
        padding-bottom:5rem
    }
}

.rad-smart-filter-2__sticky-header {
    background-color: #000;
    overflow: hidden;
    position: sticky;
    top: var(--rad-nav-height);
    -webkit-transition-duration: 550ms;
    transition-duration: 550ms;
    -webkit-transition-property: top;
    transition-property: top;
    z-index: 6
}

@media(min-width: 600px) {
    .rad-smart-filter-2__sticky-header {
        margin-left:calc(var(--rad-spacing-md)*-1);
        margin-right: calc(var(--rad-spacing-md)*-1);
        padding-left: var(--rad-spacing-md);
        padding-right: var(--rad-spacing-md)
    }
}

.rad-smart-filter-2__filter-mobile-controls {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    background-color: #000;
    display: none;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 100%;
    justify-content: flex-start;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1060
}

.rad-smart-filter-2__filter-mobile-controls--open {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

@media(min-width: 600px) {
    .rad-smart-filter-2__filter-mobile-controls {
        display:none
    }
}

.rad-smart-filter-2__filter-mobile-controls-header {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    border-bottom: 1px solid #616160;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: space-between;
    padding-bottom: 1rem;
    padding-left: var(--rad-spacing-sm);
    padding-right: var(--rad-spacing-sm);
    padding-top: 1rem
}

@media(min-width: 600px) {
    .rad-smart-filter-2__filter-mobile-controls-header {
        padding-left:var(--rad-spacing-md);
        padding-right: var(--rad-spacing-md)
    }
}

.rad-component--nested .rad-smart-filter-2__filter-mobile-controls-header {
    padding-left: 0;
    padding-right: 0
}

.rad-smart-filter-2__filter-mobile-controls-filters {
    overflow: auto;
    padding-bottom: 2rem;
    padding-left: var(--rad-spacing-sm);
    padding-right: var(--rad-spacing-sm);
    padding-top: 1rem
}

@media(min-width: 600px) {
    .rad-smart-filter-2__filter-mobile-controls-filters {
        padding-left:var(--rad-spacing-md);
        padding-right: var(--rad-spacing-md)
    }
}

.rad-component--nested .rad-smart-filter-2__filter-mobile-controls-filters {
    padding-left: 0;
    padding-right: 0
}

.rad-smart-filter-2__filter-mobile-controls-filters-heading {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-size: .875rem;
    font-weight: 500;
    justify-content: space-between;
    letter-spacing: -0.005em;
    line-height: 1.5;
    padding: .5rem 0;
    text-underline-offset: .25rem;
    -webkit-transition-duration: 550ms;
    transition-duration: 550ms;
    -webkit-transition-property: color;
    transition-property: color;
    -webkit-transition-timing-function: cubic-bezier(0.85,0,0,1);
    transition-timing-function: cubic-bezier(0.85,0,0,1);
    width: 100%
}

.rad-smart-filter-2__filter-mobile-controls-filters-heading:lang(ja-JP),.rad-smart-filter-2__filter-mobile-controls-filters-heading:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-smart-filter-2__filter-mobile-controls-filters-heading {
        font-size:1rem
    }
}

@media(min-width: 1441px) {
    .rad-smart-filter-2__filter-mobile-controls-filters-heading {
        font-size:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-smart-filter-2__filter-mobile-controls-filters-heading {
        font-size:1.3333333333rem
    }
}

.rad-smart-filter-2__filter-mobile-controls-filters-heading:after {
    content: "";
    font-family: icont;
    font-size: .5rem;
    -webkit-transition-duration: 550ms;
    transition-duration: 550ms;
    -webkit-transition-property: rotate;
    transition-property: rotate;
    -webkit-transition-timing-function: cubic-bezier(0.85,0,0,1);
    transition-timing-function: cubic-bezier(0.85,0,0,1);
    width: 1.25rem
}

.rad-smart-filter-2__filter-mobile-controls-filters-heading.rad-accordion-atom__toggle--open {
    color: #a2a2a0
}

.rad-smart-filter-2__filter-mobile-controls-filters-heading.rad-accordion-atom__toggle--open:after {
    rotate: -180deg
}

.rad-smart-filter-2__filter-mobile-controls-filters-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: .75rem .5rem;
    padding: 1rem 0 1rem 1rem
}

.rad-smart-filter-2__filter-mobile-controls-filters-list-wrapper {
    max-height: 0;
    overflow: hidden;
    -webkit-transition-duration: 550ms;
    transition-duration: 550ms;
    -webkit-transition-property: max-height,visibility;
    transition-property: max-height,visibility;
    -webkit-transition-timing-function: cubic-bezier(0.85,0,0,1);
    transition-timing-function: cubic-bezier(0.85,0,0,1);
    visibility: hidden
}

.rad-smart-filter-2__filter-mobile-controls-filters-list-wrapper--open {
    max-height: none;
    visibility: visible
}

.rad-smart-filter-2__filter-mobile-controls-filters-list-wrapper--no-animation {
    -webkit-transition-duration: 0s;
    transition-duration: 0s;
    -webkit-transition-property: none;
    transition-property: none
}

.rad-smart-filter-2__filter-mobile-controls-filters-list-wrapper.rad-accordion-atom__content-wrapper--open {
    max-height: none;
    visibility: visible
}

.rad-smart-filter-2__filter-mobile-controls-footer {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    align-items: center;
    border-top: 1px solid #616160;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    margin-top: auto;
    padding: 1rem 0 1.5rem
}

.rad-smart-filter-2__filter-toggle-button-container {
    background-color: #616160;
    border-top: 1px solid #616160;
    -webkit-column-gap: .0625rem;
    -moz-column-gap: .0625rem;
    column-gap: .0625rem;
    display: none;
    grid-template-columns: repeat(3,1fr)
}

@media(min-width: 600px) {
    .rad-smart-filter-2__filter-toggle-button-container {
        display:grid
    }
}

.rad-smart-filter-2__filter-mobile-toggle-button,.rad-smart-filter-2__filter-toggle-button {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #000;
    border-bottom: 1px solid #616160;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-size: .875rem;
    font-weight: 500;
    height: 3rem;
    letter-spacing: -0.005em;
    line-height: 1.5;
    padding: .5rem 0;
    text-underline-offset: .25rem;
    width: 100%
}

.rad-smart-filter-2__filter-mobile-toggle-button:lang(ja-JP),.rad-smart-filter-2__filter-mobile-toggle-button:lang(zh-CN),.rad-smart-filter-2__filter-toggle-button:lang(ja-JP),.rad-smart-filter-2__filter-toggle-button:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-smart-filter-2__filter-mobile-toggle-button,.rad-smart-filter-2__filter-toggle-button {
        font-size:1rem
    }
}

@media(min-width: 1441px) {
    .rad-smart-filter-2__filter-mobile-toggle-button,.rad-smart-filter-2__filter-toggle-button {
        font-size:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-smart-filter-2__filter-mobile-toggle-button,.rad-smart-filter-2__filter-toggle-button {
        font-size:1.3333333333rem
    }
}

.rad-smart-filter-2__filter-mobile-toggle-button:before,.rad-smart-filter-2__filter-toggle-button:before {
    content: "";
    display: inline;
    font-family: icont;
    font-size: 75%;
    margin-right: 8px;
    width: 20px
}

@media(min-width: 1441px) {
    .rad-smart-filter-2__filter-mobile-toggle-button:before,.rad-smart-filter-2__filter-toggle-button:before {
        width:calc(-0.05567px + 1.39179vw)
    }
}

@media(min-width: 1920px) {
    .rad-smart-filter-2__filter-mobile-toggle-button:before,.rad-smart-filter-2__filter-toggle-button:before {
        width:26.6666666667px
    }
}

@media(min-width: 600px) {
    .rad-smart-filter-2__filter-mobile-toggle-button:before,.rad-smart-filter-2__filter-toggle-button:before {
        margin-right:0.75rem
    }
}

@media(min-width: 1441px) {
    .rad-smart-filter-2__filter-mobile-toggle-button:before,.rad-smart-filter-2__filter-toggle-button:before {
        margin-right:calc(0.75rem + 0.83507vw - 12.0334px)
    }
}

@media(min-width: 1920px) {
    .rad-smart-filter-2__filter-mobile-toggle-button:before,.rad-smart-filter-2__filter-toggle-button:before {
        margin-right:1rem
    }
}

.rad-smart-filter-2__filter-mobile-toggle-button:focus-visible,.rad-smart-filter-2__filter-toggle-button:focus-visible {
    outline-offset: -2px
}

@media(min-width: 600px) {
    .rad-smart-filter-2__filter-mobile-toggle-button,.rad-smart-filter-2__filter-toggle-button {
        height:auto;
        padding: .875rem 1rem
    }
}

@media(min-width: 1441px) {
    .rad-smart-filter-2__filter-mobile-toggle-button,.rad-smart-filter-2__filter-toggle-button {
        padding-left:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-smart-filter-2__filter-mobile-toggle-button,.rad-smart-filter-2__filter-toggle-button {
        padding-left:1.3333333333rem
    }
}

@media(min-width: 1441px) {
    .rad-smart-filter-2__filter-mobile-toggle-button,.rad-smart-filter-2__filter-toggle-button {
        padding-right:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-smart-filter-2__filter-mobile-toggle-button,.rad-smart-filter-2__filter-toggle-button {
        padding-right:1.3333333333rem
    }
}

@media(min-width: 1024px) {
    .rad-smart-filter-2__filter-mobile-toggle-button,.rad-smart-filter-2__filter-toggle-button {
        padding-bottom:0.875rem;
        padding-top: 0.875rem
    }
}

@media(min-width: 1441px) {
    .rad-smart-filter-2__filter-mobile-toggle-button,.rad-smart-filter-2__filter-toggle-button {
        padding-bottom:calc(0.875rem + 0.97425vw - 14.03897px)
    }
}

@media(min-width: 1920px) {
    .rad-smart-filter-2__filter-mobile-toggle-button,.rad-smart-filter-2__filter-toggle-button {
        padding-bottom:1.1666666667rem
    }
}

@media(min-width: 1441px) {
    .rad-smart-filter-2__filter-mobile-toggle-button,.rad-smart-filter-2__filter-toggle-button {
        padding-top:calc(0.875rem + 0.97425vw - 14.03897px)
    }
}

@media(min-width: 1920px) {
    .rad-smart-filter-2__filter-mobile-toggle-button,.rad-smart-filter-2__filter-toggle-button {
        padding-top:1.1666666667rem
    }
}

.rad-smart-filter-2__filter-mobile-toggle-button--open,.rad-smart-filter-2__filter-toggle-button--open {
    border-bottom-color: #000
}

.rad-smart-filter-2__filter-mobile-toggle-button--open:before,.rad-smart-filter-2__filter-toggle-button--open:before {
    content: "";
    font-size: 2.05px
}

.rad-smart-filter-2__filter-mobile-toggle-button-count,.rad-smart-filter-2__filter-toggle-button-count {
    white-space: pre
}

@media(min-width: 600px) {
    .rad-smart-filter-2__filter-mobile-toggle-button {
        display:none
    }
}

.rad-smart-filter-2__filter-list-wrapper {
    display: none;
    padding-bottom: 1.5rem
}

@media(min-width: 600px) {
    .rad-smart-filter-2__filter-list-wrapper {
        border-bottom:0 solid #616160;
        display: block;
        height: 0;
        padding-bottom: 0;
        position: relative;
        -webkit-transition-duration: 550ms;
        transition-duration: 550ms;
        -webkit-transition-property: border-bottom-width,height,visibility;
        transition-property: border-bottom-width,height,visibility;
        -webkit-transition-timing-function: cubic-bezier(0.85,0,0,1);
        transition-timing-function: cubic-bezier(0.85,0,0,1);
        visibility: hidden
    }
}

.rad-smart-filter-2__filter-list-wrapper--open {
    border-bottom-width: 1px;
    visibility: visible
}

@media(min-width: 1441px) {
    .rad-smart-filter-2__filter-list-wrapper--open {
        border-bottom-width:calc(-0.00278px + 0.06959vw)
    }
}

@media(min-width: 1920px) {
    .rad-smart-filter-2__filter-list-wrapper--open {
        border-bottom-width:1.3333333333px
    }
}

.rad-smart-filter-2__filter-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: .75rem .5rem;
    padding-bottom: 1.5rem;
    padding-top: 1rem;
    position: absolute;
    top: 0;
    visibility: hidden
}

@media(min-width: 1441px) {
    .rad-smart-filter-2__filter-list {
        padding-top:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-smart-filter-2__filter-list {
        padding-top:1.3333333333rem
    }
}

@media(min-width: 1024px) {
    .rad-smart-filter-2__filter-list {
        padding-bottom:1rem;
        row-gap: 1rem
    }
}

@media(min-width: 1441px) {
    .rad-smart-filter-2__filter-list {
        padding-bottom:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-smart-filter-2__filter-list {
        padding-bottom:1.3333333333rem
    }
}

@media(min-width: 1441px) {
    .rad-smart-filter-2__filter-list {
        row-gap:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-smart-filter-2__filter-list {
        row-gap:1.3333333333rem
    }
}

.rad-smart-filter-2__filter-list--visible {
    visibility: visible
}

.rad-smart-filter-2__filter-controls {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-column-gap: 2rem;
    -moz-column-gap: 2rem;
    column-gap: 2rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-bottom: 1.5rem;
    position: absolute;
    right: 0
}

@media(min-width: 1441px) {
    .rad-smart-filter-2__filter-controls {
        -webkit-column-gap:calc(2rem + 2.22686vw - 32.08907px);
        -moz-column-gap: calc(2rem + 2.22686vw - 32.08907px);
        column-gap: calc(2rem + 2.22686vw - 32.08907px)
    }
}

@media(min-width: 1920px) {
    .rad-smart-filter-2__filter-controls {
        -webkit-column-gap:2.6666666667rem;
        -moz-column-gap: 2.6666666667rem;
        column-gap: 2.6666666667rem
    }
}

@media(min-width: 1441px) {
    .rad-smart-filter-2__filter-controls {
        padding-bottom:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-smart-filter-2__filter-controls {
        padding-bottom:2rem
    }
}

.rad-smart-filter-2__filter-show-results {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1
}

.rad-smart-filter-2__results-and-sort {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 1rem;
    padding-top: 1.5rem
}

@media(min-width: 600px) {
    .rad-smart-filter-2__results-and-sort {
        margin-bottom:1.5rem;
        padding-top: 2.5rem
    }
}

@media(min-width: 1441px) {
    .rad-smart-filter-2__results-and-sort {
        margin-bottom:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-smart-filter-2__results-and-sort {
        margin-bottom:2rem
    }
}

@media(min-width: 1441px) {
    .rad-smart-filter-2__results-and-sort {
        padding-top:calc(2.5rem + 2.78358vw - 40.11134px)
    }
}

@media(min-width: 1920px) {
    .rad-smart-filter-2__results-and-sort {
        padding-top:3.3333333333rem
    }
}

.rad-smart-filter-2__sort {
    margin-left: auto
}

.rad-smart-filter-2__cards {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column
}

.rad-smart-filter-2__cards-list {
    -webkit-column-gap: 48px;
    -moz-column-gap: 48px;
    column-gap: 48px;
    display: grid;
    margin-left: calc(var(--rad-spacing-sm)*-1);
    margin-right: calc(var(--rad-spacing-sm)*-1);
    row-gap: 1.5rem
}

@media(min-width: 1441px) {
    .rad-smart-filter-2__cards-list {
        -webkit-column-gap:calc(-0.13361px + 3.34029vw);
        -moz-column-gap: calc(-0.13361px + 3.34029vw);
        column-gap: calc(-0.13361px + 3.34029vw)
    }
}

@media(min-width: 1920px) {
    .rad-smart-filter-2__cards-list {
        -webkit-column-gap:64px;
        -moz-column-gap: 64px;
        column-gap: 64px
    }
}

@media(min-width: 600px) {
    .rad-smart-filter-2__cards-list {
        grid-template-columns:repeat(2,1fr);
        margin-left: calc(var(--rad-spacing-md)*-1);
        margin-right: calc(var(--rad-spacing-md)*-1);
        row-gap: 48px
    }
}

@media(min-width: 1441px) {
    .rad-smart-filter-2__cards-list {
        row-gap:calc(-0.13361px + 3.34029vw)
    }
}

@media(min-width: 1920px) {
    .rad-smart-filter-2__cards-list {
        row-gap:64px
    }
}

@media(min-width: 924px) {
    .rad-smart-filter-2__cards-list {
        grid-template-columns:repeat(3,1fr)
    }
}

@media(min-width: 1248px) {
    .rad-smart-filter-2__cards-list {
        grid-template-columns:repeat(4,1fr)
    }
}

.rad-smart-filter-2__cards-row {
    -webkit-column-gap: 3rem;
    -moz-column-gap: 3rem;
    column-gap: 3rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-transition-duration: 550ms;
    transition-duration: 550ms;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform,-webkit-transform;
    -webkit-transition-timing-function: cubic-bezier(0.85,0,0,1);
    transition-timing-function: cubic-bezier(0.85,0,0,1)
}

@media(min-width: 1441px) {
    .rad-smart-filter-2__cards-row {
        -webkit-column-gap:calc(3rem + 3.34029vw - 48.13361px);
        -moz-column-gap: calc(3rem + 3.34029vw - 48.13361px);
        column-gap: calc(3rem + 3.34029vw - 48.13361px)
    }
}

@media(min-width: 1920px) {
    .rad-smart-filter-2__cards-row {
        -webkit-column-gap:4rem;
        -moz-column-gap: 4rem;
        column-gap: 4rem
    }
}

@media(min-width: 600px) {
    .rad-smart-filter-2__cards-row {
        width:600px
    }
}

@media(min-width: 924px) {
    .rad-smart-filter-2__cards-row {
        width:924px
    }
}

@media(min-width: 1248px) {
    .rad-smart-filter-2__cards-row {
        width:1248px
    }
}

@media(min-width: 1441px) {
    .rad-smart-filter-2__cards-row {
        width:calc(-3.4739px + 86.8476vw)
    }
}

@media(min-width: 1920px) {
    .rad-smart-filter-2__cards-row {
        width:1664px
    }
}

.rad-smart-filter-2__cards-load-more {
    margin-top: 24px
}

.rad-smart-filter-2__cards-load-more:disabled {
    display: none
}

@media(min-width: 600px) {
    .rad-smart-filter-2__cards-load-more {
        margin-top:48px
    }
}

@media(min-width: 1441px) {
    .rad-smart-filter-2__cards-load-more {
        margin-top:calc(-0.13361px + 3.34029vw)
    }
}

@media(min-width: 1920px) {
    .rad-smart-filter-2__cards-load-more {
        margin-top:64px
    }
}

.rad-smart-filter-2__no-results {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: none;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 3rem 1rem;
    text-align: center
}

.rad-smart-filter-2__no-results .no-results__header {
    font-size: 1.375rem;
    font-weight: 500;
    letter-spacing: -0.02em;
    line-height: 1.25;
    padding-bottom: 1.5rem
}

.rad-smart-filter-2__no-results .no-results__header:lang(ja-JP),.rad-smart-filter-2__no-results .no-results__header:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-smart-filter-2__no-results .no-results__header {
        font-size:1.75rem;
        line-height: 1.3
    }
}

@media(min-width: 1024px) {
    .rad-smart-filter-2__no-results .no-results__header {
        font-size:2rem;
        line-height: 1.2
    }
}

@media(min-width: 1441px) {
    .rad-smart-filter-2__no-results .no-results__header {
        font-size:calc(2rem + 2.22686vw - 32.08907px)
    }
}

@media(min-width: 1920px) {
    .rad-smart-filter-2__no-results .no-results__header {
        font-size:2.6666666667rem
    }
}

.rad-smart-filter-2__no-results .no-results__subheader {
    font-size: .875rem;
    font-weight: 500;
    font-weight: 400;
    letter-spacing: -0.005em;
    line-height: 1.5;
    padding-bottom: 1.5rem;
    text-underline-offset: .25rem
}

.rad-smart-filter-2__no-results .no-results__subheader:lang(ja-JP),.rad-smart-filter-2__no-results .no-results__subheader:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-smart-filter-2__no-results .no-results__subheader {
        font-size:1rem
    }
}

@media(min-width: 1441px) {
    .rad-smart-filter-2__no-results .no-results__subheader {
        font-size:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-smart-filter-2__no-results .no-results__subheader {
        font-size:1.3333333333rem
    }
}

.rad-smart-filter-2__no-results .no-results__subheader:lang(ja-JP),.rad-smart-filter-2__no-results .no-results__subheader:lang(zh-CN) {
    font-weight: 400
}

.rad-smart-filter-2__no-results .no-results__graphic {
    height: 14.125rem;
    margin-top: 3rem;
    width: 20rem
}

@media(min-width: 600px) {
    .rad-smart-filter-2__no-results {
        margin:0 auto;
        padding: 3.75rem 0;
        width: calc(150% - var(--rad-spacing-sm)*3/4*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-sm)*0);
        width: calc(75% - var(--rad-spacing-sm)*7/8*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-smart-filter-2__no-results {
        width:calc(50% - var(--rad-spacing-sm)*11/12*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 600px) {
    .rad-smart-filter-2__no-results .no-results__graphic {
        -webkit-box-ordinal-group:0;
        -ms-flex-order: -1;
        margin-bottom: 2rem;
        margin-top: 0;
        order: -1
    }
}

@media(min-width: 1024px) {
    .rad-smart-filter-2__no-results {
        width:calc(200% - var(--rad-spacing-sm)*3/4*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-sm)*0);
        width: calc(100% - var(--rad-spacing-sm)*7/8*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0);
        width: calc(66.66667% - var(--rad-spacing-sm)*11/12*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0)
    }

    .rad-smart-filter-2__no-results .no-results__header {
        padding-bottom: 1rem
    }

    .rad-smart-filter-2__no-results .no-results__graphic {
        margin-bottom: 3rem
    }
}

.rad-smart-filter-2__no-results--visible {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.rad-global-header--offscreen .rad-smart-filter-2__sticky-header {
    top: 0
}

.rad--has-subnav.rad-global-header--offscreen .rad-smart-filter-2__sticky-header {
    top: 3.25rem
}

.rad--has-subnav .rad-smart-filter-2__sticky-header {
    top: calc(var(--rad-nav-height) + 3.25rem)
}

@-webkit-keyframes rad-vertical-tabs-description-mobile-slide-in {
    0% {
        translate: var(--description-slide-start-amount)
    }

    to {
        translate: 0
    }
}

@keyframes rad-vertical-tabs-description-mobile-slide-in {
    0% {
        translate: var(--description-slide-start-amount)
    }

    to {
        translate: 0
    }
}

.rad-vertical-tabs {
    padding-left: var(--rad-spacing-sm);
    padding-right: var(--rad-spacing-sm)
}

@media(min-width: 600px) {
    .rad-vertical-tabs {
        padding-left:var(--rad-spacing-md);
        padding-right: var(--rad-spacing-md)
    }
}

.rad-component--nested .rad-vertical-tabs {
    padding-left: 0;
    padding-right: 0
}

.rad-vertical-tabs__header {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-bottom: 1.5rem;
    row-gap: .25rem
}

@media(min-width: 600px) {
    .rad-vertical-tabs__header {
        margin-bottom:2.5rem;
        row-gap: 0.5rem
    }
}

@media(min-width: 1441px) {
    .rad-vertical-tabs__header {
        row-gap:calc(0.5rem + 0.55672vw - 8.02227px)
    }
}

@media(min-width: 1920px) {
    .rad-vertical-tabs__header {
        row-gap:0.6666666667rem
    }
}

@media(min-width: 1024px) {
    .rad-vertical-tabs__header {
        margin-bottom:3.75rem
    }
}

@media(min-width: 1441px) {
    .rad-vertical-tabs__header {
        margin-bottom:calc(3.75rem + 4.17537vw - 60.16701px)
    }
}

@media(min-width: 1920px) {
    .rad-vertical-tabs__header {
        margin-bottom:5rem
    }
}

.rad-vertical-tabs__header-large-headline {
    font-size: 3rem;
    font-weight: 500;
    letter-spacing: -0.03em;
    line-height: 1.15;
    text-align: center
}

.rad-vertical-tabs__header-large-headline:lang(ja-JP),.rad-vertical-tabs__header-large-headline:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-vertical-tabs__header-large-headline {
        font-size:5rem;
        font-size: 2rem;
        font-weight: 500;
        letter-spacing: -0.03em;
        line-height: 1.1;
        line-height: 1.25
    }

    .rad-vertical-tabs__header-large-headline:lang(ja-JP),.rad-vertical-tabs__header-large-headline:lang(zh-CN) {
        font-weight: 700
    }

    .rad-vertical-tabs__header-large-headline {
        font-size: 3.75rem;
        line-height: 1.15
    }
}

@media(min-width: 1441px) {
    .rad-vertical-tabs__header-large-headline {
        font-size:calc(3.75rem + 4.17537vw - 60.16701px)
    }
}

@media(min-width: 1920px) {
    .rad-vertical-tabs__header-large-headline {
        font-size:5rem
    }
}

@media(min-width: 768px) {
    .rad-vertical-tabs__header-large-headline {
        font-size:3rem;
        font-weight: 500;
        letter-spacing: -0.03em;
        line-height: 1.15
    }

    .rad-vertical-tabs__header-large-headline:lang(ja-JP),.rad-vertical-tabs__header-large-headline:lang(zh-CN) {
        font-weight: 700
    }

    .rad-vertical-tabs__header-large-headline {
        font-size: 5rem;
        line-height: 1.1
    }
}

@media(min-width: 1024px) {
    .rad-vertical-tabs__header-large-headline {
        font-size:6.25rem
    }
}

@media(min-width: 1441px) {
    .rad-vertical-tabs__header-large-headline {
        font-size:calc(6.25rem + 6.95894vw - 100.27836px)
    }
}

@media(min-width: 1920px) {
    .rad-vertical-tabs__header-large-headline {
        font-size:8.3333333333rem
    }
}

.rad-vertical-tabs__header-sub-header {
    -ms-flex-item-align: center;
    align-self: center;
    font-family: GT Sectra Fine,Palatino;
    font-size: 1.25rem;
    font-weight: 300;
    line-height: 1.25;
    text-align: center
}

@media(min-width: 600px) {
    .rad-vertical-tabs__header-sub-header {
        font-family:GT Sectra Fine,Palatino;
        font-size: 1.5rem;
        font-size: 1.125rem;
        font-size: 1.25rem;
        font-weight: 300;
        line-height: 1.25;
        line-height: 1.3;
        width: calc(150% - var(--rad-spacing-sm)*3/4*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-sm)*0)
    }
}

@media(min-width: 1024px) {
    .rad-vertical-tabs__header-sub-header {
        font-size:1.5rem;
        line-height: 1.25
    }
}

@media(min-width: 1441px) {
    .rad-vertical-tabs__header-sub-header {
        font-size:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-vertical-tabs__header-sub-header {
        font-size:2rem
    }
}

@media(min-width: 600px) {
    .rad-vertical-tabs__header-sub-header {
        width:calc(75% - var(--rad-spacing-sm)*7/8*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-vertical-tabs__header-sub-header {
        width:calc(50% - var(--rad-spacing-sm)*11/12*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 768px) {
    .rad-vertical-tabs__header-sub-header {
        font-family:GT Sectra Fine,Palatino;
        font-size: 1.25rem;
        font-size: 1.5rem;
        font-weight: 300;
        line-height: 1.25
    }
}

@media(min-width: 1024px) {
    .rad-vertical-tabs__header-sub-header {
        font-size:1.75rem;
        line-height: 1.25
    }
}

@media(min-width: 1441px) {
    .rad-vertical-tabs__header-sub-header {
        font-size:calc(1.75rem + 1.9485vw - 28.07794px)
    }
}

@media(min-width: 1920px) {
    .rad-vertical-tabs__header-sub-header {
        font-size:2.3333333333rem
    }
}

@media(min-width: 1024px) {
    .rad-vertical-tabs__header-sub-header {
        width:calc(200% - var(--rad-spacing-sm)*3/4*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-sm)*0);
        width: calc(100% - var(--rad-spacing-sm)*7/8*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0);
        width: calc(66.66667% - var(--rad-spacing-sm)*11/12*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0)
    }
}

.rad-vertical-tabs__header-label {
    font-size: .75rem;
    font-weight: 500;
    letter-spacing: .02em;
    line-height: 1.2;
    margin-bottom: 1rem;
    text-transform: uppercase
}

.rad-vertical-tabs__header-label:lang(ja-JP),.rad-vertical-tabs__header-label:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 1024px) {
    .rad-vertical-tabs__header-label {
        font-size:0.875rem
    }
}

@media(min-width: 1441px) {
    .rad-vertical-tabs__header-label {
        font-size:calc(0.875rem + 0.97425vw - 14.03897px)
    }
}

@media(min-width: 1920px) {
    .rad-vertical-tabs__header-label {
        font-size:1.1666666667rem
    }
}

@media(min-width: 1441px) {
    .rad-vertical-tabs__header-label {
        margin-bottom:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-vertical-tabs__header-label {
        margin-bottom:1.3333333333rem
    }
}

.rad-vertical-tabs__header-small-headline {
    font-size: 1.75rem;
    font-weight: 500;
    letter-spacing: -0.03em;
    line-height: 1.3
}

.rad-vertical-tabs__header-small-headline:lang(ja-JP),.rad-vertical-tabs__header-small-headline:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-vertical-tabs__header-small-headline {
        font-size:2.5rem;
        line-height: 1.2
    }
}

@media(min-width: 1024px) {
    .rad-vertical-tabs__header-small-headline {
        font-size:3rem;
        line-height: 1.2
    }
}

@media(min-width: 1441px) {
    .rad-vertical-tabs__header-small-headline {
        font-size:calc(3rem + 3.34029vw - 48.13361px)
    }
}

@media(min-width: 1920px) {
    .rad-vertical-tabs__header-small-headline {
        font-size:4rem
    }
}

@media(min-width: 1024px) {
    .rad-vertical-tabs__header-small-headline {
        margin-bottom:1.25rem
    }
}

.rad-vertical-tabs__tabs {
    position: relative
}

@media(min-width: 600px) {
    .rad-vertical-tabs__tabs {
        -webkit-box-pack:justify;
        -ms-flex-pack: justify;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        justify-content: space-between
    }
}

.rad-vertical-tabs__tabs-list {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    font-size: 1.375rem;
    font-weight: 500;
    letter-spacing: -0.02em;
    line-height: 1.25
}

.rad-vertical-tabs__tabs-list:lang(ja-JP),.rad-vertical-tabs__tabs-list:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-vertical-tabs__tabs-list {
        font-size:1.75rem;
        font-size: 1.25rem;
        font-weight: 500;
        letter-spacing: -0.02em;
        line-height: 1.3;
        row-gap: 1rem;
        width: calc(100% - var(--rad-spacing-sm)*3/4*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-sm)*0);
        width: calc(50% - var(--rad-spacing-sm)*7/8*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-vertical-tabs__tabs-list {
        width:calc(33.33333% - var(--rad-spacing-sm)*11/12*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 600px) {
    .rad-vertical-tabs__tabs-list:lang(ja-JP),.rad-vertical-tabs__tabs-list:lang(zh-CN) {
        font-weight:700
    }

    .rad-vertical-tabs__tabs-list {
        font-size: 1.5rem;
        line-height: 1.3
    }
}

@media(min-width: 1024px) {
    .rad-vertical-tabs__tabs-list {
        font-size:1.75rem;
        line-height: 1.25
    }
}

@media(min-width: 1441px) {
    .rad-vertical-tabs__tabs-list {
        font-size:calc(1.75rem + 1.9485vw - 28.07794px)
    }
}

@media(min-width: 1920px) {
    .rad-vertical-tabs__tabs-list {
        font-size:2.3333333333rem
    }
}

@media(min-width: 768px) {
    .rad-vertical-tabs__tabs-list {
        font-size:1.375rem;
        font-weight: 500;
        letter-spacing: -0.02em;
        line-height: 1.25;
        margin-top: -0.375rem;
        row-gap: 0.25rem
    }

    .rad-vertical-tabs__tabs-list:lang(ja-JP),.rad-vertical-tabs__tabs-list:lang(zh-CN) {
        font-weight: 700
    }

    .rad-vertical-tabs__tabs-list {
        font-size: 1.75rem;
        line-height: 1.3
    }
}

@media(min-width: 1024px) {
    .rad-vertical-tabs__tabs-list {
        font-size:2rem;
        line-height: 1.2
    }
}

@media(min-width: 1441px) {
    .rad-vertical-tabs__tabs-list {
        font-size:calc(2rem + 2.22686vw - 32.08907px)
    }
}

@media(min-width: 1920px) {
    .rad-vertical-tabs__tabs-list {
        font-size:2.6666666667rem
    }
}

@media(min-width: 1441px) {
    .rad-vertical-tabs__tabs-list {
        margin-top:calc(-0.375rem - 0.41754vw + 6.0167px)
    }
}

@media(min-width: 1920px) {
    .rad-vertical-tabs__tabs-list {
        margin-top:-0.5rem
    }
}

@media(min-width: 1441px) {
    .rad-vertical-tabs__tabs-list {
        row-gap:calc(0.25rem + 0.27836vw - 4.01113px)
    }
}

@media(min-width: 1920px) {
    .rad-vertical-tabs__tabs-list {
        row-gap:0.3333333333rem
    }
}

@media(min-width: 1024px) {
    .rad-vertical-tabs__tabs-list {
        width:calc(150% - var(--rad-spacing-sm)*3/4*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-sm)*0);
        width: calc(75% - var(--rad-spacing-sm)*7/8*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0);
        width: calc(50% - var(--rad-spacing-sm)*11/12*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0)
    }
}

.rad-vertical-tabs__tabs-list-entry {
    --entry-transition-duration: 100ms;
    min-height: 3rem;
    position: relative;
    text-align: left;
    width: 100%
}

@media(min-width: 600px) {
    .rad-vertical-tabs__tabs-list-entry {
        --dot-size:6px;
        min-width: 3em;
        -webkit-transition: color var(--entry-transition-duration) cubic-bezier(0.85,0,0,1),translate var(--entry-transition-duration) cubic-bezier(0.85,0,0,1);
        transition: color var(--entry-transition-duration) cubic-bezier(0.85,0,0,1),translate var(--entry-transition-duration) cubic-bezier(0.85,0,0,1);
        width: auto
    }
}

@media(min-width: 1024px) {
    .rad-vertical-tabs__tabs-list-entry {
        --dot-size:8px;
        min-height: 3.125rem
    }
}

@media(min-width: 1441px) {
    .rad-vertical-tabs__tabs-list-entry {
        min-height:calc(3.125rem + 3.47947vw - 50.13918px)
    }
}

@media(min-width: 1920px) {
    .rad-vertical-tabs__tabs-list-entry {
        min-height:4.1666666667rem
    }
}

.rad-vertical-tabs__tabs-list--active .rad-vertical-tabs__tabs-list-entry {
    color: #616160
}

.rad-vertical-tabs__tabs-list--active .rad-vertical-tabs__tabs-list-entry--active {
    color: #fff
}

.rad-vertical-tabs__tabs-list-entry:before {
    background-color: #a100ff;
    content: "";
    display: block;
    height: var(--dot-size);
    left: 0;
    opacity: 0;
    position: absolute;
    top: 50%;
    -webkit-transition: opacity var(--entry-transition-duration) cubic-bezier(0.85,0,0,1),translate var(--entry-transition-duration) cubic-bezier(0.85,0,0,1);
    transition: opacity var(--entry-transition-duration) cubic-bezier(0.85,0,0,1),translate var(--entry-transition-duration) cubic-bezier(0.85,0,0,1);
    translate: 0 -50%;
    width: var(--dot-size)
}

@media(min-width: 600px) {
    .rad-vertical-tabs__tabs-list-entry--active {
        translate:calc(16px + var(--dot-size))
    }

    .rad-vertical-tabs__tabs-list-entry--active:before {
        opacity: 1;
        translate: calc(-100% - 16px) -50%
    }
}

.rad-vertical-tabs__tabs-list-entry--mobile-active {
    color: #fff
}

.rad-vertical-tabs__tabs-list-entry--mobile-inactive {
    color: #616160
}

@media(min-width: 600px) {
    .rad-vertical-tabs__tabs-description-positioner {
        height:100%;
        position: absolute;
        right: 0;
        top: 0;
        width: calc(75% - var(--rad-spacing-sm)*3/4*3 + var(--rad-spacing-sm)*2 + var(--rad-spacing-sm)*0);
        width: calc(37.5% - var(--rad-spacing-sm)*7/8*3 + var(--rad-spacing-sm)*2 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-vertical-tabs__tabs-description-positioner {
        width:calc(25% - var(--rad-spacing-sm)*11/12*3 + var(--rad-spacing-sm)*2 + var(--rad-spacing-md)*0);
        width: calc(100% - var(--rad-spacing-sm)*3/4*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-sm)*0);
        width: calc(50% - var(--rad-spacing-sm)*7/8*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0);
        width: calc(33.33333% - var(--rad-spacing-sm)*11/12*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0)
    }
}

.rad-vertical-tabs__tabs-description {
    height: 100%;
    left: 0;
    overflow-x: hidden;
    position: fixed;
    top: 0;
    translate: 100%;
    width: 100%;
    z-index: 1060
}

@media(min-width: 600px) {
    .rad-vertical-tabs__tabs-description {
        height:-webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        overflow: visible;
        position: sticky;
        top: 180px;
        translate: 0;
        visibility: visible;
        z-index: 1
    }
}

@media(min-width: 1441px) {
    .rad-vertical-tabs__tabs-description {
        top:calc(-0.50104px + 12.5261vw)
    }
}

@media(min-width: 1920px) {
    .rad-vertical-tabs__tabs-description {
        top:240px
    }
}

.rad-vertical-tabs__tabs-description-card {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    background-color: #000;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-height: 100%;
    padding-left: var(--rad-spacing-sm);
    padding-right: var(--rad-spacing-sm)
}

@media(min-width: 600px) {
    .rad-vertical-tabs__tabs-description-card {
        padding-left:var(--rad-spacing-md);
        padding-right: var(--rad-spacing-md)
    }
}

.rad-component--nested .rad-vertical-tabs__tabs-description-card {
    padding-left: 0;
    padding-right: 0
}

@media(min-width: 600px) {
    .rad-vertical-tabs__tabs-description-card {
        -ms-flex-item-align:start;
        align-self: flex-start;
        padding: 0
    }
}

.rad-vertical-tabs--mobile .rad-vertical-tabs__tabs-list--active .rad-vertical-tabs__tabs-description {
    --description-slide-start-amount: 100%;
    -webkit-animation: rad-vertical-tabs-description-mobile-slide-in .5s cubic-bezier(0.77,0,0.18,1) 0s 1 forwards running;
    animation: rad-vertical-tabs-description-mobile-slide-in .5s cubic-bezier(0.77,0,0.18,1) 0s 1 forwards running
}

@media(min-width: 600px) {
    .rad-vertical-tabs--mobile .rad-vertical-tabs__tabs-list--active .rad-vertical-tabs__tabs-description {
        -webkit-animation:none;
        animation: none
    }
}

.rad-vertical-tabs--mobile .rad-vertical-tabs__tabs-list--active .rad-vertical-tabs__tabs-description .rad-vertical-tabs__tabs-description-header {
    --description-slide-start-amount: 8vw;
    -webkit-animation: rad-vertical-tabs-description-mobile-slide-in .5s cubic-bezier(0.77,0,0.18,1) .04s 1 both running;
    animation: rad-vertical-tabs-description-mobile-slide-in .5s cubic-bezier(0.77,0,0.18,1) .04s 1 both running
}

@media(min-width: 600px) {
    .rad-vertical-tabs--mobile .rad-vertical-tabs__tabs-list--active .rad-vertical-tabs__tabs-description .rad-vertical-tabs__tabs-description-header {
        -webkit-animation:none;
        animation: none
    }
}

.rad-vertical-tabs--mobile .rad-vertical-tabs__tabs-list--active .rad-vertical-tabs__tabs-description .rad-vertical-tabs__tabs-description-sub-header {
    --description-slide-start-amount: 10vw;
    -webkit-animation: rad-vertical-tabs-description-mobile-slide-in .5s cubic-bezier(0.77,0,0.18,1) .05s 1 both running;
    animation: rad-vertical-tabs-description-mobile-slide-in .5s cubic-bezier(0.77,0,0.18,1) .05s 1 both running
}

@media(min-width: 600px) {
    .rad-vertical-tabs--mobile .rad-vertical-tabs__tabs-list--active .rad-vertical-tabs__tabs-description .rad-vertical-tabs__tabs-description-sub-header {
        -webkit-animation:none;
        animation: none
    }
}

.rad-vertical-tabs--mobile .rad-vertical-tabs__tabs-list--active .rad-vertical-tabs__tabs-description .rad-vertical-tabs__tabs-description-body {
    --description-slide-start-amount: 10vw;
    -webkit-animation: rad-vertical-tabs-description-mobile-slide-in .5s cubic-bezier(0.77,0,0.18,1) .05s 1 both running;
    animation: rad-vertical-tabs-description-mobile-slide-in .5s cubic-bezier(0.77,0,0.18,1) .05s 1 both running
}

@media(min-width: 600px) {
    .rad-vertical-tabs--mobile .rad-vertical-tabs__tabs-list--active .rad-vertical-tabs__tabs-description .rad-vertical-tabs__tabs-description-body {
        -webkit-animation:none;
        animation: none
    }
}

.rad-vertical-tabs--mobile .rad-vertical-tabs__tabs-list--active .rad-vertical-tabs__tabs-description .rad-vertical-tabs__tabs-description-button {
    --description-slide-start-amount: 12vw;
    -webkit-animation: rad-vertical-tabs-description-mobile-slide-in .5s cubic-bezier(0.77,0,0.18,1) .06s 1 both running;
    animation: rad-vertical-tabs-description-mobile-slide-in .5s cubic-bezier(0.77,0,0.18,1) .06s 1 both running
}

@media(min-width: 600px) {
    .rad-vertical-tabs--mobile .rad-vertical-tabs__tabs-list--active .rad-vertical-tabs__tabs-description .rad-vertical-tabs__tabs-description-button {
        -webkit-animation:none;
        animation: none
    }
}

.rad-vertical-tabs--mobile .rad-vertical-tabs__tabs-list--active .rad-vertical-tabs__tabs-description .cmp-image {
    --description-slide-start-amount: 14vw;
    -webkit-animation: rad-vertical-tabs-description-mobile-slide-in .5s cubic-bezier(0.77,0,0.18,1) .07s 1 both running;
    animation: rad-vertical-tabs-description-mobile-slide-in .5s cubic-bezier(0.77,0,0.18,1) .07s 1 both running
}

@media(min-width: 600px) {
    .rad-vertical-tabs--mobile .rad-vertical-tabs__tabs-list--active .rad-vertical-tabs__tabs-description .cmp-image {
        -webkit-animation:none;
        animation: none
    }
}

.rad-vertical-tabs__tabs-description-close-button {
    -ms-flex-item-align: end;
    align-self: flex-end;
    margin: .75rem -0.25rem .5rem 0
}

@media(min-width: 600px) {
    .rad-vertical-tabs__tabs-description-close-button {
        display:none
    }
}

.rad-vertical-tabs__tabs-description-header {
    font-size: 1.375rem;
    font-weight: 500;
    letter-spacing: -0.02em;
    line-height: 1.25;
    margin-bottom: 1rem
}

.rad-vertical-tabs__tabs-description-header:lang(ja-JP),.rad-vertical-tabs__tabs-description-header:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-vertical-tabs__tabs-description-header {
        font-size:1.75rem;
        line-height: 1.3
    }
}

@media(min-width: 1024px) {
    .rad-vertical-tabs__tabs-description-header {
        font-size:2rem;
        line-height: 1.2
    }
}

@media(min-width: 1441px) {
    .rad-vertical-tabs__tabs-description-header {
        font-size:calc(2rem + 2.22686vw - 32.08907px)
    }
}

@media(min-width: 1920px) {
    .rad-vertical-tabs__tabs-description-header {
        font-size:2.6666666667rem
    }
}

@media(min-width: 1441px) {
    .rad-vertical-tabs__tabs-description-header {
        margin-bottom:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-vertical-tabs__tabs-description-header {
        margin-bottom:1.3333333333rem
    }
}

@media(min-width: 600px) {
    .rad-vertical-tabs__tabs-description-header {
        display:none
    }
}

.rad-vertical-tabs__tabs-description-sub-header {
    font-size: 1.125rem;
    font-weight: 500;
    letter-spacing: -0.02em;
    line-height: 1.4;
    margin-bottom: .5rem
}

.rad-vertical-tabs__tabs-description-sub-header:lang(ja-JP),.rad-vertical-tabs__tabs-description-sub-header:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-vertical-tabs__tabs-description-sub-header {
        font-size:1.25rem;
        line-height: 1.4
    }
}

@media(min-width: 1024px) {
    .rad-vertical-tabs__tabs-description-sub-header {
        font-size:1.5rem;
        line-height: 1.3
    }
}

@media(min-width: 1441px) {
    .rad-vertical-tabs__tabs-description-sub-header {
        font-size:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-vertical-tabs__tabs-description-sub-header {
        font-size:2rem
    }
}

@media(min-width: 600px) {
    .rad-vertical-tabs__tabs-description-sub-header {
        margin-bottom:.75rem
    }
}

@media(min-width: 1024px) {
    .rad-vertical-tabs__tabs-description-sub-header {
        margin-bottom:1rem
    }
}

@media(min-width: 1441px) {
    .rad-vertical-tabs__tabs-description-sub-header {
        margin-bottom:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-vertical-tabs__tabs-description-sub-header {
        margin-bottom:1.3333333333rem
    }
}

.rad-vertical-tabs__tabs-description-body {
    font-size: .875rem;
    font-weight: 500;
    font-weight: 400;
    letter-spacing: -0.005em;
    line-height: 1.5;
    text-underline-offset: .25rem
}

.rad-vertical-tabs__tabs-description-body:lang(ja-JP),.rad-vertical-tabs__tabs-description-body:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-vertical-tabs__tabs-description-body {
        font-size:1rem
    }
}

@media(min-width: 1441px) {
    .rad-vertical-tabs__tabs-description-body {
        font-size:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-vertical-tabs__tabs-description-body {
        font-size:1.3333333333rem
    }
}

.rad-vertical-tabs__tabs-description-body:lang(ja-JP),.rad-vertical-tabs__tabs-description-body:lang(zh-CN) {
    font-weight: 400
}

.rad-vertical-tabs__tabs-description-button {
    margin-top: .25rem
}

@media(min-width: 1024px) {
    .rad-vertical-tabs__tabs-description-button {
        margin-top:0.75rem
    }
}

@media(min-width: 1441px) {
    .rad-vertical-tabs__tabs-description-button {
        margin-top:calc(0.75rem + 0.83507vw - 12.0334px)
    }
}

@media(min-width: 1920px) {
    .rad-vertical-tabs__tabs-description-button {
        margin-top:1rem
    }
}

.rad-vertical-tabs__tabs-description .cmp-image,.rad-vertical-tabs__tabs-description .cmp-video {
    aspect-ratio: 16/9;
    margin-top: 2.5rem;
    overflow: hidden
}

@media(min-width: 600px) {
    .rad-vertical-tabs__tabs-description .cmp-image,.rad-vertical-tabs__tabs-description .cmp-video {
        margin-top:3rem
    }
}

@media(min-width: 1024px) {
    .rad-vertical-tabs__tabs-description .cmp-image,.rad-vertical-tabs__tabs-description .cmp-video {
        margin-top:4rem
    }
}

@media(min-width: 1441px) {
    .rad-vertical-tabs__tabs-description .cmp-image,.rad-vertical-tabs__tabs-description .cmp-video {
        margin-top:calc(4rem + 4.45372vw - 64.17815px)
    }
}

@media(min-width: 1920px) {
    .rad-vertical-tabs__tabs-description .cmp-image,.rad-vertical-tabs__tabs-description .cmp-video {
        margin-top:5.3333333333rem
    }
}

.rad-vertical-tabs__tabs-description .cmp-image__image {
    height: 100%;
    margin-left: calc(var(--rad-spacing-sm)*-1);
    margin-right: calc(var(--rad-spacing-sm)*-1);
    max-width: calc(100% + var(--rad-spacing-sm)*2);
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%
}

@media(min-width: 600px) {
    .rad-vertical-tabs__tabs-description .cmp-image__image {
        margin-left:0;
        margin-right: 0;
        max-width: 100%
    }
}

.rad-vertical-tabs__tabs-description--hidden {
    display: none
}

@media(min-width: 600px) {
    .rad-vertical-tabs__tabs-description--hidden {
        display:block;
        visibility: hidden
    }
}

.rad-vertical-tabs__button {
    margin-top: var(--rad-spacing-sm)
}

.rad-horizontal-tabs-mobile-dropdown {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #202020;
    color: #a2a2a0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-size: .875rem;
    font-size: 1rem;
    font-weight: 500;
    font-weight: 400;
    letter-spacing: -0.005em;
    line-height: 1.5;
    line-height: 1.5rem;
    margin-bottom: 2px;
    margin-left: 1rem;
    margin-right: 1rem;
    padding: .75rem;
    text-underline-offset: .25rem;
    width: calc(100% - 2rem)
}

.rad-horizontal-tabs-mobile-dropdown:lang(ja-JP),.rad-horizontal-tabs-mobile-dropdown:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-horizontal-tabs-mobile-dropdown {
        font-size:1rem
    }
}

@media(min-width: 1441px) {
    .rad-horizontal-tabs-mobile-dropdown {
        font-size:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-horizontal-tabs-mobile-dropdown {
        font-size:1.3333333333rem
    }
}

.rad-horizontal-tabs-mobile-dropdown:lang(ja-JP),.rad-horizontal-tabs-mobile-dropdown:lang(zh-CN) {
    font-weight: 400
}

.rad-horizontal-tabs-mobile-dropdown .rad-icon__chevron-down {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    color: #fff;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    justify-content: center;
    margin-left: auto;
    -webkit-transition-duration: 550ms;
    transition-duration: 550ms;
    -webkit-transition-property: rotate;
    transition-property: rotate;
    -webkit-transition-timing-function: cubic-bezier(0.85,0,0,1);
    transition-timing-function: cubic-bezier(0.85,0,0,1)
}

@media(min-width: 600px) {
    .rad-horizontal-tabs-mobile-dropdown {
        display:none
    }
}

.rad-horizontal-tabs__category-title-selected-item {
    color: #fff
}

.rad-horizontal-tabs__tabs-lists {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    background-color: #202020;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    left: 1rem;
    max-height: 0;
    overflow: hidden;
    position: absolute;
    right: 1rem;
    -webkit-transition-duration: 550ms;
    transition-duration: 550ms;
    -webkit-transition-property: max-height;
    transition-property: max-height;
    -webkit-transition-timing-function: cubic-bezier(0.85,0,0,1);
    transition-timing-function: cubic-bezier(0.85,0,0,1);
    width: calc(100% - 2rem);
    z-index: 5
}

.rad-horizontal-tabs__tabs-lists:focus-visible {
    outline-offset: 1px
}

@media(min-width: 600px) {
    .rad-horizontal-tabs__tabs-lists {
        -webkit-box-orient:horizontal;
        -webkit-box-direction: normal;
        background-color: unset;
        -ms-flex-direction: row;
        flex-direction: row;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        left: unset;
        max-height: 100%;
        overflow: visible;
        padding-left: var(--rad-spacing-sm);
        padding-left: var(--rad-spacing-md);
        padding-right: var(--rad-spacing-sm);
        padding-right: var(--rad-spacing-md);
        position: relative;
        right: unset
    }

    .rad-component--nested .rad-horizontal-tabs__tabs-lists {
        padding-left: 0;
        padding-right: 0
    }
}

.rad-horizontal-tabs__tabs-list-entry {
    --entry-transition-duration: 300ms;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #a2a2a0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    font-size: .875rem;
    font-weight: 500;
    font-weight: 400;
    justify-content: flex-start;
    letter-spacing: -0.005em;
    line-height: 1.5;
    padding: .75rem;
    text-underline-offset: .25rem;
    width: 100%
}

.rad-horizontal-tabs__tabs-list-entry:lang(ja-JP),.rad-horizontal-tabs__tabs-list-entry:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-horizontal-tabs__tabs-list-entry {
        font-size:1rem
    }
}

@media(min-width: 1441px) {
    .rad-horizontal-tabs__tabs-list-entry {
        font-size:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-horizontal-tabs__tabs-list-entry {
        font-size:1.3333333333rem
    }
}

.rad-horizontal-tabs__tabs-list-entry:lang(ja-JP),.rad-horizontal-tabs__tabs-list-entry:lang(zh-CN) {
    font-weight: 400
}

.rad-horizontal-tabs__tabs-list-entry:focus-visible {
    outline-offset: -3px
}

.rad-horizontal-tabs__tabs-list-entry:before {
    background-color: #a100ff;
    content: "";
    display: block;
    height: var(--dot-size);
    opacity: 0;
    position: absolute;
    top: 50%;
    -webkit-transition: opacity var(--entry-transition-duration) cubic-bezier(0.85,0,0,1),translate var(--entry-transition-duration) cubic-bezier(0.85,0,0,1);
    transition: opacity var(--entry-transition-duration) cubic-bezier(0.85,0,0,1),translate var(--entry-transition-duration) cubic-bezier(0.85,0,0,1);
    translate: calc(-100% - 8px) -50%;
    width: var(--dot-size)
}

.rad-horizontal-tabs__tabs-list-entry:hover {
    background-color: #2b2b2b;
    color: #fff;
    opacity: .95
}

@media(min-width: 600px) {
    .rad-horizontal-tabs__tabs-list-entry {
        --dot-size:3px;
        color: #616160;
        font-size: 1.25rem;
        font-weight: 500;
        letter-spacing: -0.02em;
        line-height: 1.3;
        padding-bottom: 0.5rem;
        padding-top: 0;
        -webkit-transition: translate var(--entry-transition-duration) cubic-bezier(0.85,0,0,1);
        transition: translate var(--entry-transition-duration) cubic-bezier(0.85,0,0,1);
        width: auto
    }

    .rad-horizontal-tabs__tabs-list-entry:lang(ja-JP),.rad-horizontal-tabs__tabs-list-entry:lang(zh-CN) {
        font-weight: 700
    }

    .rad-horizontal-tabs__tabs-list-entry {
        font-size: 1.5rem;
        line-height: 1.3
    }
}

@media(min-width: 1024px) {
    .rad-horizontal-tabs__tabs-list-entry {
        font-size:1.75rem;
        line-height: 1.25
    }
}

@media(min-width: 1441px) {
    .rad-horizontal-tabs__tabs-list-entry {
        padding-bottom:calc(0.5rem + 0.55672vw - 8.02227px)
    }
}

@media(min-width: 1920px) {
    .rad-horizontal-tabs__tabs-list-entry {
        padding-bottom:0.6666666667rem
    }
}

@media(min-width: 600px) {
    .rad-horizontal-tabs__tabs-list-entry:hover {
        background-color:unset;
        opacity: 1
    }
}

@media(min-width: 600px) {
    .rad-horizontal-tabs__tabs-list-entry:focus-visible {
        outline-offset:1px
    }
}

@media(min-width: 1024px) {
    .rad-horizontal-tabs__tabs-list-entry {
        --dot-size:5px;
        font-size: 1.75rem;
        line-height: 2.1875rem;
        padding-left: 1rem;
        padding-right: 1rem
    }
}

@media(min-width: 1441px) {
    .rad-horizontal-tabs__tabs-list-entry {
        --dot-size:calc(-0.01392px + 0.34795vw)
    }
}

@media(min-width: 1920px) {
    .rad-horizontal-tabs__tabs-list-entry {
        --dot-size:6.6666666667px
    }
}

@media(min-width: 1441px) {
    .rad-horizontal-tabs__tabs-list-entry {
        padding-left:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-horizontal-tabs__tabs-list-entry {
        padding-left:1.3333333333rem
    }
}

@media(min-width: 1441px) {
    .rad-horizontal-tabs__tabs-list-entry {
        padding-right:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-horizontal-tabs__tabs-list-entry {
        padding-right:1.3333333333rem
    }
}

@media(min-width: 1441px) {
    .rad-horizontal-tabs__tabs-list-entry {
        font-size:calc(1.75rem + 1.9485vw - 28.07794px)
    }
}

@media(min-width: 1920px) {
    .rad-horizontal-tabs__tabs-list-entry {
        font-size:2.3333333333rem
    }
}

@media(min-width: 1441px) {
    .rad-horizontal-tabs__tabs-list-entry {
        line-height:calc(2.1875rem + 2.43563vw - 35.09743px)
    }
}

@media(min-width: 1920px) {
    .rad-horizontal-tabs__tabs-list-entry {
        line-height:2.9166666667rem
    }
}

.rad-horizontal-tabs__tabs-list-entry--active {
    color: #fff
}

@media(min-width: 600px) {
    .rad-horizontal-tabs__tabs-list-entry--active {
        translate:calc(8px - var(--dot-size))
    }

    .rad-horizontal-tabs__tabs-list-entry--active:before {
        opacity: 1
    }
}

.rad-horizontal-tabs-mobile-dropdown--expanded .rad-horizontal-tabs__tabs-lists {
    max-height: var(--dropdown-height)
}

.rad-horizontal-tabs-mobile-dropdown--expanded .rad-icon__chevron-down {
    rotate: 180deg
}

.rad-horizontal-tabs .cmp-tabs {
    position: relative
}

.rad-horizontal-tabs .cmp-tabs__tab {
    border-bottom: unset
}

.rad-horizontal-tabs .cmp-tabs__tab--active {
    border-color: unset
}

.rad-horizontal-tabs .cmp-tabs__tablist {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap
}

@media(min-width: 600px) {
    .rad-horizontal-tabs .cmp-tabs__tablist {
        -ms-flex-wrap:wrap;
        flex-wrap: wrap
    }
}

.rad-client-carousel {
    position: relative
}

.rad-client-carousel .flickity-cell {
    bottom: 0
}

.rad-client-carousel__wrapper {
    position: relative
}

.rad-client-carousel__container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-bottom: 1rem
}

.rad-client-carousel__cell,.rad-client-carousel__left {
    width: 100%
}

.rad-client-carousel__left .rad-client-carousel__image-container .rad-client-carousel__image {
    display: block;
    width: 100%
}

.rad-client-carousel__right {
    padding: 0 var(--rad-spacing-sm) 48px;
    position: static;
    width: 100%
}

.rad-client-carousel__right .rad-client-carousel__cell {
    padding-top: 1.5rem
}

.rad-client-carousel__right .rad-client-carousel__title {
    font-size: 1.125rem;
    font-weight: 500;
    letter-spacing: -0.02em;
    line-height: 1.4;
    margin-bottom: .5rem
}

.rad-client-carousel__right .rad-client-carousel__title:lang(ja-JP),.rad-client-carousel__right .rad-client-carousel__title:lang(zh-CN) {
    font-weight: 700
}

.rad-client-carousel__right .rad-client-carousel__info {
    font-size: .875rem;
    font-weight: 500;
    font-weight: 400;
    letter-spacing: -0.005em;
    line-height: 1.5;
    margin-bottom: .25rem;
    text-underline-offset: .25rem
}

.rad-client-carousel__right .rad-client-carousel__info:lang(ja-JP),.rad-client-carousel__right .rad-client-carousel__info:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-client-carousel__right .rad-client-carousel__info {
        font-size:1rem
    }
}

@media(min-width: 1441px) {
    .rad-client-carousel__right .rad-client-carousel__info {
        font-size:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-client-carousel__right .rad-client-carousel__info {
        font-size:1.3333333333rem
    }
}

.rad-client-carousel__right .rad-client-carousel__info:lang(ja-JP),.rad-client-carousel__right .rad-client-carousel__info:lang(zh-CN) {
    font-weight: 400
}

.rad-client-carousel__right .rad-client-carousel__read {
    font-size: .875rem;
    font-weight: 500;
    letter-spacing: -0.005em;
    line-height: 1.5;
    padding: 0;
    text-underline-offset: .25rem;
    visibility: hidden
}

.rad-client-carousel__right .rad-client-carousel__read:lang(ja-JP),.rad-client-carousel__right .rad-client-carousel__read:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-client-carousel__right .rad-client-carousel__read {
        font-size:1rem
    }
}

@media(min-width: 1441px) {
    .rad-client-carousel__right .rad-client-carousel__read {
        font-size:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-client-carousel__right .rad-client-carousel__read {
        font-size:1.3333333333rem
    }
}

.rad-client-carousel__right .rad-client-carousel__cell.is-selected .rad-client-carousel__read {
    visibility: visible
}

.rad-client-carousel__controllers {
    bottom: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 0 var(--rad-spacing-sm);
    position: absolute;
    width: 100%
}

.rad-client-carousel__controllers .rad-client-carousel__play-pause--disabled {
    display: none
}

.rad-client-carousel__controllers .rad-client-carousel__previous {
    margin-left: auto
}

.rad-carousel-header {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 0 1rem 1.5rem;
    row-gap: 0.5rem;
    width: calc(min(100vw, 1920px)/4*4 - var(--rad-spacing-sm)*2/4*4 - var(--rad-spacing-sm)*3/4*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-sm)*0)
}

@media(min-width: 600px) {
    .rad-carousel-header {
        width:calc(min(100vw, 1920px)/8*4 - var(--rad-spacing-md)*2/8*4 - var(--rad-spacing-sm)*7/8*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-carousel-header {
        width:calc(min(100vw, 1920px)/12*4 - var(--rad-spacing-md)*2/12*4 - var(--rad-spacing-sm)*11/12*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1441px) {
    .rad-carousel-header {
        row-gap:calc(0.5rem + 0.55672vw - 8.02227px)
    }
}

@media(min-width: 1920px) {
    .rad-carousel-header {
        row-gap:0.6666666667rem
    }
}

@media(min-width: 600px) {
    .rad-carousel-header {
        padding:0 0 2.5rem 3rem;
        width: calc(150% - var(--rad-spacing-sm)*3/4*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-sm)*0);
        width: calc(75% - var(--rad-spacing-sm)*7/8*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-carousel-header {
        padding-bottom:3rem;
        padding-left: 5rem;
        width: calc(50% - var(--rad-spacing-sm)*11/12*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0);
        width: calc(200% - var(--rad-spacing-sm)*3/4*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-sm)*0)
    }
}

@media(min-width: 1441px) {
    .rad-carousel-header {
        padding-bottom:calc(3rem + 3.34029vw - 48.13361px)
    }
}

@media(min-width: 1920px) {
    .rad-carousel-header {
        padding-bottom:4rem
    }
}

@media(min-width: 1441px) {
    .rad-carousel-header {
        padding-left:calc(5rem + 5.56715vw - 80.22269px)
    }
}

@media(min-width: 1920px) {
    .rad-carousel-header {
        padding-left:6.6666666667rem
    }
}

@media(min-width: 1024px) {
    .rad-carousel-header {
        width:calc(100% - var(--rad-spacing-sm)*7/8*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0);
        width: calc(66.66667% - var(--rad-spacing-sm)*11/12*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0)
    }
}

.rad-carousel-header__title {
    font-size: 1.375rem;
    font-weight: 500;
    letter-spacing: -0.02em;
    line-height: 1.25
}

.rad-carousel-header__title:lang(ja-JP),.rad-carousel-header__title:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-carousel-header__title {
        font-size:1.75rem;
        line-height: 1.3
    }
}

@media(min-width: 1024px) {
    .rad-carousel-header__title {
        font-size:2rem;
        line-height: 1.2
    }
}

@media(min-width: 1441px) {
    .rad-carousel-header__title {
        font-size:calc(2rem + 2.22686vw - 32.08907px)
    }
}

@media(min-width: 1920px) {
    .rad-carousel-header__title {
        font-size:2.6666666667rem
    }
}

.rad-carousel-header__headline {
    font-size: 1.75rem;
    font-weight: 500;
    letter-spacing: -0.03em;
    line-height: 1.3
}

.rad-carousel-header__headline:lang(ja-JP),.rad-carousel-header__headline:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-carousel-header__headline {
        font-size:2.5rem;
        line-height: 1.2
    }
}

@media(min-width: 1024px) {
    .rad-carousel-header__headline {
        font-size:3rem;
        line-height: 1.2
    }
}

@media(min-width: 1441px) {
    .rad-carousel-header__headline {
        font-size:calc(3rem + 3.34029vw - 48.13361px)
    }
}

@media(min-width: 1920px) {
    .rad-carousel-header__headline {
        font-size:4rem
    }
}

.rad-carousel-header__subheader {
    font-family: GT Sectra Fine,Palatino;
    font-size: 1.25rem;
    font-weight: 300;
    line-height: 1.25
}

@media(min-width: 600px) {
    .rad-carousel-header__subheader {
        font-size:1.5rem;
        line-height: 1.25
    }
}

@media(min-width: 1024px) {
    .rad-carousel-header__subheader {
        font-size:1.75rem;
        line-height: 1.25
    }
}

@media(min-width: 1441px) {
    .rad-carousel-header__subheader {
        font-size:calc(1.75rem + 1.9485vw - 28.07794px)
    }
}

@media(min-width: 1920px) {
    .rad-carousel-header__subheader {
        font-size:2.3333333333rem
    }
}

@media(min-width: 600px) {
    .rad-client-carousel {
        padding-left:0;
        padding-right: 3rem;
        position: relative
    }

    .rad-client-carousel .flickity-cell {
        margin-bottom: 0
    }

    .rad-client-carousel__wrapper {
        padding-bottom: 3rem
    }
}

@media(min-width: 1441px) {
    .rad-client-carousel__wrapper {
        padding-bottom:calc(3rem + 3.34029vw - 48.13361px)
    }
}

@media(min-width: 1920px) {
    .rad-client-carousel__wrapper {
        padding-bottom:4rem
    }
}

@media(min-width: 600px) {
    .rad-client-carousel__container {
        -webkit-box-orient:horizontal;
        -webkit-box-direction: normal;
        -webkit-box-align: baseline;
        -ms-flex-align: baseline;
        align-items: baseline;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: row;
        flex-direction: row;
        margin-bottom: 1.5rem;
        padding-bottom: 0
    }


}

@media(min-width: 600px) {
    .rad-client-carousel__read {
        margin-bottom:-0.5rem
    }

    .rad-client-carousel__controllers {
        padding-left: var(--rad-spacing-sm);
        padding-right: 0
    }

    .rad-client-carousel-pause {
        display: none
    }

    .rad-client-carousel__left {
        -ms-flex-negative: 0;
        flex-shrink: 0;
        width: calc(min(100vw, 1920px)/4*4 - var(--rad-spacing-sm)*2/4*4 - var(--rad-spacing-sm)*3/4*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-sm)*1);
        width: calc(min(100vw, 1920px)/8*4 - var(--rad-spacing-md)*2/8*4 - var(--rad-spacing-sm)*7/8*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*1)
    }
}

@media(min-width: 1024px) {
    .rad-client-carousel__left {
        width:calc(min(100vw, 1920px)/12*4 - var(--rad-spacing-md)*2/12*4 - var(--rad-spacing-sm)*11/12*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*1)
    }
}

@media(min-width: 600px) {
    .rad-client-carousel__right {
        padding:0
    }

    .rad-client-carousel__right .flickity-viewport {
        height: 12.5rem
    }

    .rad-client-carousel__right .rad-client-carousel__cell {
        padding-left: calc(min(100vw, 1920px)/4*1 - var(--rad-spacing-sm)*2/4*1 - var(--rad-spacing-sm)*3/4*1 + var(--rad-spacing-sm)*2 + var(--rad-spacing-sm)*0);
        padding-left: calc(min(100vw, 1920px)/8*1 - var(--rad-spacing-md)*2/8*1 - var(--rad-spacing-sm)*7/8*1 + var(--rad-spacing-sm)*2 + var(--rad-spacing-md)*0);
        padding-top: 0
    }
}

@media(min-width: 1024px) {
    .rad-client-carousel__right .rad-client-carousel__cell {
        padding-left:calc(min(100vw, 1920px)/12*1 - var(--rad-spacing-md)*2/12*1 - var(--rad-spacing-sm)*11/12*1 + var(--rad-spacing-sm)*2 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 600px) {
    .rad-client-carousel__right .rad-client-carousel__title {
        font-size:1.125rem;
        font-weight: 500;
        letter-spacing: -0.02em;
        line-height: 1.4;
        margin-bottom: .75rem
    }

    .rad-client-carousel__right .rad-client-carousel__title:lang(ja-JP),.rad-client-carousel__right .rad-client-carousel__title:lang(zh-CN) {
        font-weight: 700
    }

    .rad-client-carousel__right .rad-client-carousel__title {
        font-size: 1.25rem;
        line-height: 1.4
    }
}

@media(min-width: 1024px) {
    .rad-client-carousel__right .rad-client-carousel__title {
        font-size:1.5rem;
        line-height: 1.3
    }
}

@media(min-width: 1441px) {
    .rad-client-carousel__right .rad-client-carousel__title {
        font-size:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-client-carousel__right .rad-client-carousel__title {
        font-size:2rem
    }
}

@media(min-width: 600px) {
    .rad-client-carousel__right .rad-client-carousel__content {
        width:auto
    }

    .rad-client-carousel__right .rad-client-carousel__info {
        margin-bottom: 0
    }

    .rad-client-carousel__right .rad-client-carousel__read {
        margin-top: .25rem
    }
}

@media(min-width: 1024px) {
    .rad-client-carousel__container {
        margin-bottom:2rem
    }
}

@media(min-width: 1441px) {
    .rad-client-carousel__container {
        margin-bottom:calc(2rem + 2.22686vw - 32.08907px)
    }
}

@media(min-width: 1920px) {
    .rad-client-carousel__container {
        margin-bottom:2.6666666667rem
    }
}

@media(min-width: 1024px) {
    .rad-client-carousel {
        padding-right:5rem
    }

    .rad-client-carousel__left {
        width: calc(min(100vw, 1920px)/4*6 - var(--rad-spacing-sm)*2/4*6 - var(--rad-spacing-sm)*3/4*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-sm)*1);
        width: calc(min(100vw, 1920px)/8*6 - var(--rad-spacing-md)*2/8*6 - var(--rad-spacing-sm)*7/8*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*1);
        width: calc(min(100vw, 1920px)/12*6 - var(--rad-spacing-md)*2/12*6 - var(--rad-spacing-sm)*11/12*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*1)
    }

    .rad-client-carousel__right .flickity-viewport {
        min-height: 14.0625rem
    }
}

@media(min-width: 1441px) {
    .rad-client-carousel__right .flickity-viewport {
        min-height:calc(14.0625rem + 15.65762vw - 225.6263px)
    }
}

@media(min-width: 1920px) {
    .rad-client-carousel__right .flickity-viewport {
        min-height:18.75rem
    }
}

@media(min-width: 1024px) {
    .rad-client-carousel__right .rad-client-carousel__title {
        margin-bottom:1rem
    }
}

@media(min-width: 1441px) {
    .rad-client-carousel__right .rad-client-carousel__title {
        margin-bottom:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-client-carousel__right .rad-client-carousel__title {
        margin-bottom:1.3333333333rem
    }
}

@media(min-width: 1024px) {
    .rad-client-carousel__right .rad-client-carousel__read {
        margin-top:0.75rem
    }
}

@media(min-width: 1441px) {
    .rad-client-carousel__right .rad-client-carousel__read {
        margin-top:calc(0.75rem + 0.83507vw - 12.0334px)
    }
}

@media(min-width: 1920px) {
    .rad-client-carousel__right .rad-client-carousel__read {
        margin-top:1rem
    }
}

@media(min-width: 1024px) {
    .rad-client-carousel__right .rad-client-carousel__cell {
        padding-left:calc(min(100vw, 1920px)/4*2 - var(--rad-spacing-sm)*2/4*2 - var(--rad-spacing-sm)*3/4*2 + var(--rad-spacing-sm)*3 + var(--rad-spacing-sm)*0);
        padding-left: calc(min(100vw, 1920px)/8*2 - var(--rad-spacing-md)*2/8*2 - var(--rad-spacing-sm)*7/8*2 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0);
        padding-left: calc(min(100vw, 1920px)/12*2 - var(--rad-spacing-md)*2/12*2 - var(--rad-spacing-sm)*11/12*2 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0);
        padding-top: 0
    }
}

.rad-absorb-image__wrapper img {
    display: block;
    width: 100%
}

.rad-absorb-image__caption {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    font-size: .75rem;
    font-weight: 500;
    font-weight: 400;
    letter-spacing: -0.005em;
    line-height: 1.5;
    padding-top: .5rem;
    text-underline-offset: .25rem;
    width: calc(100% - var(--rad-spacing-sm)*3/4*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-sm)*0)
}

.rad-absorb-image__caption:lang(ja-JP),.rad-absorb-image__caption:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-absorb-image__caption {
        font-size:0.875rem
    }
}

@media(min-width: 1441px) {
    .rad-absorb-image__caption {
        font-size:calc(0.875rem + 0.97425vw - 14.03897px)
    }
}

@media(min-width: 1920px) {
    .rad-absorb-image__caption {
        font-size:1.1666666667rem
    }
}

.rad-absorb-image__caption:lang(ja-JP),.rad-absorb-image__caption:lang(zh-CN) {
    font-weight: 400
}

@media(min-width: 600px) {
    .rad-absorb-image__caption {
        width:calc(50% - var(--rad-spacing-sm)*7/8*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-absorb-image__caption {
        width:calc(33.33333% - var(--rad-spacing-sm)*11/12*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 600px) {
    .rad-absorb-image__caption {
        padding-left:0;
        width: calc(125% - var(--rad-spacing-sm)*3/4*5 + var(--rad-spacing-sm)*4 + var(--rad-spacing-sm)*0);
        width: calc(62.5% - var(--rad-spacing-sm)*7/8*5 + var(--rad-spacing-sm)*4 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-absorb-image__caption {
        padding-top:0.75rem;
        width: calc(41.66667% - var(--rad-spacing-sm)*11/12*5 + var(--rad-spacing-sm)*4 + var(--rad-spacing-md)*0);
        width: calc(150% - var(--rad-spacing-sm)*3/4*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-sm)*0);
        width: calc(75% - var(--rad-spacing-sm)*7/8*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0);
        width: calc(50% - var(--rad-spacing-sm)*11/12*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1441px) {
    .rad-absorb-image__caption {
        padding-top:calc(0.75rem + 0.83507vw - 12.0334px)
    }
}

@media(min-width: 1920px) {
    .rad-absorb-image__caption {
        padding-top:1rem
    }
}

.rad-absorb-image__caption-text {
    color: #a2a2a0;
    padding-top: .5rem
}

@media(min-width: 1024px) {
    .rad-absorb-image__caption-text {
        padding-top:0.25rem
    }
}

@media(min-width: 1441px) {
    .rad-absorb-image__caption-text {
        padding-top:calc(0.25rem + 0.27836vw - 4.01113px)
    }
}

@media(min-width: 1920px) {
    .rad-absorb-image__caption-text {
        padding-top:0.3333333333rem
    }
}

.rad-absorb-image__caption-text:first-child {
    color: #fff
}

.rad-absorb-image--img-large .rad-absorb-image__wrapper {
    margin: 0 var(--rad-spacing-sm)
}

@media(min-width: 600px) {
    .rad-absorb-image--img-large .rad-absorb-image__wrapper {
        margin:0 var(--rad-spacing-md)
    }
}

.rad-absorb-image--img-large .rad-absorb-image__caption {
    padding-left: var(--rad-spacing-sm);
    padding-right: var(--rad-spacing-sm)
}

@media(min-width: 600px) {
    .rad-absorb-image--img-large .rad-absorb-image__caption {
        padding-left:var(--rad-spacing-md);
        padding-right: var(--rad-spacing-md)
    }
}

.rad-absorb-image--img-large.rad-absorb-image--flush .rad-absorb-image__wrapper {
    margin: 0;
    width: 100%
}

.rad-absorb-image--img-medium {
    padding-left: var(--rad-spacing-sm);
    padding-right: var(--rad-spacing-sm)
}

@media(min-width: 600px) {
    .rad-absorb-image--img-medium {
        padding-left:var(--rad-spacing-md);
        padding-right: var(--rad-spacing-md)
    }
}

.rad-component--nested .rad-absorb-image--img-medium {
    padding-left: 0;
    padding-right: 0
}

.rad-absorb-image--img-medium .rad-absorb-image__wrapper {
    width: calc(100% - var(--rad-spacing-sm)*3/4*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-sm)*0)
}

@media(min-width: 600px) {
    .rad-absorb-image--img-medium .rad-absorb-image__wrapper {
        width:calc(50% - var(--rad-spacing-sm)*7/8*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-absorb-image--img-medium .rad-absorb-image__wrapper {
        width:calc(33.33333% - var(--rad-spacing-sm)*11/12*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 600px) {
    .rad-absorb-image--img-medium .rad-absorb-image__wrapper {
        width:calc(125% - var(--rad-spacing-sm)*3/4*5 + var(--rad-spacing-sm)*4 + var(--rad-spacing-sm)*0);
        width: calc(62.5% - var(--rad-spacing-sm)*7/8*5 + var(--rad-spacing-sm)*4 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-absorb-image--img-medium .rad-absorb-image__wrapper {
        width:calc(41.66667% - var(--rad-spacing-sm)*11/12*5 + var(--rad-spacing-sm)*4 + var(--rad-spacing-md)*0);
        width: calc(200% - var(--rad-spacing-sm)*3/4*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-sm)*0);
        width: calc(100% - var(--rad-spacing-sm)*7/8*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0);
        width: calc(66.66667% - var(--rad-spacing-sm)*11/12*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0)
    }
}

.rad-absorb-image--img-medium.rad-absorb-image--flush .rad-absorb-image__wrapper {
    margin-left: calc(var(--rad-spacing-sm)*-1);
    width: calc(100% - var(--rad-spacing-sm)*3/4*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-sm)*1)
}

@media(min-width: 600px) {
    .rad-absorb-image--img-medium.rad-absorb-image--flush .rad-absorb-image__wrapper {
        width:calc(50% - var(--rad-spacing-sm)*7/8*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*1)
    }
}

@media(min-width: 1024px) {
    .rad-absorb-image--img-medium.rad-absorb-image--flush .rad-absorb-image__wrapper {
        width:calc(33.33333% - var(--rad-spacing-sm)*11/12*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*1)
    }
}

.rad-absorb-image--img-medium.rad-absorb-image--flush .rad-absorb-image__wrapper img {
    max-width: none
}

@media(min-width: 600px) {
    .rad-absorb-image--img-medium.rad-absorb-image--flush .rad-absorb-image__wrapper {
        margin-left:calc(var(--rad-spacing-md)*-1);
        width: calc(125% - var(--rad-spacing-sm)*3/4*5 + var(--rad-spacing-sm)*4 + var(--rad-spacing-sm)*1);
        width: calc(62.5% - var(--rad-spacing-sm)*7/8*5 + var(--rad-spacing-sm)*4 + var(--rad-spacing-md)*1)
    }
}

@media(min-width: 1024px) {
    .rad-absorb-image--img-medium.rad-absorb-image--flush .rad-absorb-image__wrapper {
        width:calc(41.66667% - var(--rad-spacing-sm)*11/12*5 + var(--rad-spacing-sm)*4 + var(--rad-spacing-md)*1);
        width: calc(200% - var(--rad-spacing-sm)*3/4*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-sm)*1);
        width: calc(100% - var(--rad-spacing-sm)*7/8*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*1);
        width: calc(66.66667% - var(--rad-spacing-sm)*11/12*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*1)
    }
}

.rad-absorb-image--img-medium.rad-absorb-image--align-right.rad-absorb-image--flush .rad-absorb-image__wrapper {
    margin-left: 0;
    margin-right: calc(var(--rad-spacing-sm)*-1)
}

@media(min-width: 600px) {
    .rad-absorb-image--img-medium.rad-absorb-image--align-right.rad-absorb-image--flush .rad-absorb-image__wrapper {
        margin-right:calc(var(--rad-spacing-md)*-1)
    }
}

.rad-absorb-image--img-small {
    padding-left: var(--rad-spacing-sm);
    padding-right: var(--rad-spacing-sm)
}

@media(min-width: 600px) {
    .rad-absorb-image--img-small {
        padding-left:var(--rad-spacing-md);
        padding-right: var(--rad-spacing-md)
    }
}

.rad-component--nested .rad-absorb-image--img-small {
    padding-left: 0;
    padding-right: 0
}

.rad-absorb-image--img-small .rad-absorb-image__wrapper {
    width: calc(100% - var(--rad-spacing-sm)*3/4*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-sm)*0)
}

@media(min-width: 600px) {
    .rad-absorb-image--img-small .rad-absorb-image__wrapper {
        width:calc(100% - var(--rad-spacing-sm)*3/4*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-sm)*0);
        width: calc(50% - var(--rad-spacing-sm)*7/8*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-absorb-image--img-small .rad-absorb-image__wrapper {
        width:calc(33.33333% - var(--rad-spacing-sm)*11/12*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0);
        width: calc(150% - var(--rad-spacing-sm)*3/4*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-sm)*0);
        width: calc(75% - var(--rad-spacing-sm)*7/8*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0);
        width: calc(50% - var(--rad-spacing-sm)*11/12*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0)
    }
}

.rad-absorb-image--img-small.rad-absorb-image--flush .rad-absorb-image__wrapper {
    margin-left: calc(var(--rad-spacing-sm)*-1);
    width: calc(100% - var(--rad-spacing-sm)*3/4*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-sm)*1)
}

@media(min-width: 600px) {
    .rad-absorb-image--img-small.rad-absorb-image--flush .rad-absorb-image__wrapper {
        width:calc(50% - var(--rad-spacing-sm)*7/8*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*1)
    }
}

@media(min-width: 1024px) {
    .rad-absorb-image--img-small.rad-absorb-image--flush .rad-absorb-image__wrapper {
        width:calc(33.33333% - var(--rad-spacing-sm)*11/12*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*1)
    }
}

.rad-absorb-image--img-small.rad-absorb-image--flush .rad-absorb-image__wrapper img {
    max-width: none
}

@media(min-width: 600px) {
    .rad-absorb-image--img-small.rad-absorb-image--flush .rad-absorb-image__wrapper {
        margin-left:calc(var(--rad-spacing-md)*-1)
    }
}

@media(min-width: 1024px) {
    .rad-absorb-image--img-small.rad-absorb-image--flush .rad-absorb-image__wrapper {
        width:calc(150% - var(--rad-spacing-sm)*3/4*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-sm)*1);
        width: calc(75% - var(--rad-spacing-sm)*7/8*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*1);
        width: calc(50% - var(--rad-spacing-sm)*11/12*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*1)
    }
}

.rad-absorb-image--img-small.rad-absorb-image--align-right.rad-absorb-image--flush .rad-absorb-image__wrapper {
    margin-left: 0;
    margin-right: calc(var(--rad-spacing-sm)*-1)
}

@media(min-width: 600px) {
    .rad-absorb-image--img-small.rad-absorb-image--align-right.rad-absorb-image--flush .rad-absorb-image__wrapper {
        margin-right:calc(var(--rad-spacing-md)*-1)
    }
}

.rad-absorb-image--align-right {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column
}

@media(min-width: 600px) {
    .rad-absorb-image--align-right .rad-absorb-image__caption,.rad-absorb-image--align-right .rad-absorb-image__wrapper {
        -ms-flex-item-align:end;
        align-self: flex-end;
        text-align: right
    }
}

@media(max-width: 599px) {
    .rad-absorb-image--standard {
        display:none
    }
}

@media(min-width: 600px) {
    .rad-absorb-image--mobile {
        display:none
    }
}

.rad-authors {
    padding-left: var(--rad-spacing-sm);
    padding-right: var(--rad-spacing-sm)
}

@media(min-width: 600px) {
    .rad-authors {
        padding-left:var(--rad-spacing-md);
        padding-right: var(--rad-spacing-md)
    }
}

.rad-component--nested .rad-authors {
    padding-left: 0;
    padding-right: 0
}

@media(min-width: 1024px) {
    .rad-authors {
        padding-left:0;
        padding-top: 8.75rem;
        position: absolute;
        right: 0;
        top: 0
    }

    .rad-authors--horizontal {
        -webkit-column-gap: 32px;
        -moz-column-gap: 32px;
        column-gap: 32px;
        display: grid;
        grid-template-columns: repeat(4,3fr);
        margin-top: 60px;
        padding: 40px 80px 60px;
        position: relative;
        row-gap: 9px;
        width: 100%
    }
}

@media(min-width: 1441px) {
    .rad-authors--horizontal {
        row-gap:calc(-0.02505px + 0.6263vw)
    }
}

@media(min-width: 1920px) {
    .rad-authors--horizontal {
        row-gap:12px
    }
}

.rad-authors__label {
    border-bottom: 1px;
    border-color: #202020;
    border-style: solid;
    color: #a2a2a0;
    font-size: .75rem;
    font-weight: 500;
    letter-spacing: .02em;
    line-height: 1.2;
    padding-bottom: 16px
}

.rad-authors__label:lang(ja-JP),.rad-authors__label:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 1024px) {
    .rad-authors__label {
        font-size:0.875rem
    }
}

@media(min-width: 1441px) {
    .rad-authors__label {
        font-size:calc(0.875rem + 0.97425vw - 14.03897px)
    }
}

@media(min-width: 1920px) {
    .rad-authors__label {
        font-size:1.1666666667rem
    }
}

@media(min-width: 600px) {
    .rad-authors__label {
        padding-bottom:24px
    }
}

@media(min-width: 1024px) {
    .rad-authors__label {
        border-bottom-width:0;
        padding-bottom: 2rem
    }
}

@media(min-width: 1441px) {
    .rad-authors__label {
        padding-bottom:calc(2rem + 2.22686vw - 32.08907px)
    }
}

@media(min-width: 1920px) {
    .rad-authors__label {
        padding-bottom:2.6666666667rem
    }
}

@media(min-width: 1024px) {
    .rad-authors--horizontal .rad-authors__label {
        left:5rem;
        letter-spacing: .24px;
        padding-bottom: 1.5rem;
        position: absolute;
        text-transform: uppercase;
        top: 0
    }
}

.rad-authors__author {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    border-bottom: 1px;
    border-color: #202020;
    border-style: solid;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 24px;
    justify-content: space-between
}

@media(min-width: 600px) {
    .rad-authors__author {
        gap:32px
    }
}

@media(min-width: 1024px) {
    .rad-authors__author {
        -webkit-box-orient:vertical;
        -webkit-box-direction: normal;
        border-bottom-width: 0;
        -ms-flex-direction: column;
        flex-direction: column;
        gap: 4px;
        padding-bottom: 1.5rem;
        width: calc(min(100vw, 1920px)/4*2 - var(--rad-spacing-sm)*2/4*2 - var(--rad-spacing-sm)*3/4*2 + var(--rad-spacing-sm)*1 + var(--rad-spacing-sm)*0);
        width: calc(min(100vw, 1920px)/8*2 - var(--rad-spacing-md)*2/8*2 - var(--rad-spacing-sm)*7/8*2 + var(--rad-spacing-sm)*1 + var(--rad-spacing-md)*0);
        width: calc(min(100vw, 1920px)/12*2 - var(--rad-spacing-md)*2/12*2 - var(--rad-spacing-sm)*11/12*2 + var(--rad-spacing-sm)*1 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1441px) {
    .rad-authors__author {
        padding-bottom:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-authors__author {
        padding-bottom:2rem
    }
}

@media(min-width: 1024px) {
    .rad-authors__author:last-child {
        padding-bottom:0
    }

    .rad-authors--horizontal .rad-authors__author {
        border-color: #202020;
        border-top: 2px #202020;
        border-style: solid;
        padding-bottom: 0;
        padding-top: 24px;
        width: 100%
    }
}

.rad-authors__text {
    max-width: calc(100% - 7rem);
    padding: var(--rad-spacing-sm) 0
}

@media(min-width: 600px) {
    .rad-authors__text {
        max-width:calc(100% - 10rem);
        padding-bottom: 16px
    }
}

@media(min-width: 1024px) {
    .rad-authors__text {
        max-width:unset;
        padding-bottom: 0;
        padding-top: 0
    }
}

.rad-authors__author-name {
    font-size: .75rem;
    font-weight: 500;
    letter-spacing: -0.005em;
    line-height: 1.5;
    margin-bottom: 4px;
    text-underline-offset: .25rem
}

.rad-authors__author-name:lang(ja-JP),.rad-authors__author-name:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-authors__author-name {
        font-size:0.875rem
    }
}

@media(min-width: 1441px) {
    .rad-authors__author-name {
        font-size:calc(0.875rem + 0.97425vw - 14.03897px)
    }
}

@media(min-width: 1920px) {
    .rad-authors__author-name {
        font-size:1.1666666667rem
    }
}

@media(min-width: 1024px) {
    .rad-authors__author-name {
        margin-bottom:0
    }

    .rad-authors--horizontal .rad-authors__author-name {
        margin-bottom: 4px
    }
}

.rad-authors__author-title {
    color: #a2a2a0;
    font-size: .75rem;
    font-weight: 500;
    font-weight: 400;
    letter-spacing: -0.005em;
    line-height: 1.5;
    text-underline-offset: .25rem
}

.rad-authors__author-title:lang(ja-JP),.rad-authors__author-title:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-authors__author-title {
        font-size:0.875rem
    }
}

@media(min-width: 1441px) {
    .rad-authors__author-title {
        font-size:calc(0.875rem + 0.97425vw - 14.03897px)
    }
}

@media(min-width: 1920px) {
    .rad-authors__author-title {
        font-size:1.1666666667rem
    }
}

.rad-authors__author-title:lang(ja-JP),.rad-authors__author-title:lang(zh-CN) {
    font-weight: 400
}

.rad-authors__author-social {
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    -ms-flex-negative: 0;
    -webkit-box-pack: right;
    -ms-flex-pack: right;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    justify-content: right;
    padding-top: .5rem;
    width: 80px
}

@media(min-width: 600px) {
    .rad-authors__author-social {
        padding-top:1rem
    }
}

@media(min-width: 1024px) {
    .rad-authors__author-social {
        padding-top:0
    }
}

.rad-authors__author-social .rad-icon-button {
    -ms-flex-negative: 0;
    flex-shrink: 0
}

@media(min-width: 1024px) {
    .rad-authors__author-social {
        -webkit-box-pack:left;
        -ms-flex-pack: left;
        gap: 0;
        justify-content: left;
        margin-left: -14px
    }

    .rad-authors--horizontal .rad-authors__author-social {
        min-height: 15px
    }
}

.rad-absorb-stats {
    padding-left: var(--rad-spacing-sm);
    padding-right: var(--rad-spacing-sm)
}

@media(min-width: 600px) {
    .rad-absorb-stats {
        padding-left:var(--rad-spacing-md);
        padding-right: var(--rad-spacing-md)
    }
}

.rad-component--nested .rad-absorb-stats {
    padding-left: 0;
    padding-right: 0
}

.rad-absorb-stats__title {
    font-size: 1.375rem;
    font-weight: 500;
    letter-spacing: -0.02em;
    line-height: 1.25;
    margin-bottom: 1.5rem
}

.rad-absorb-stats__title:lang(ja-JP),.rad-absorb-stats__title:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-absorb-stats__title {
        font-size:1.75rem;
        line-height: 1.3
    }
}

@media(min-width: 1024px) {
    .rad-absorb-stats__title {
        font-size:2rem;
        line-height: 1.2
    }
}

@media(min-width: 1441px) {
    .rad-absorb-stats__title {
        font-size:calc(2rem + 2.22686vw - 32.08907px)
    }
}

@media(min-width: 1920px) {
    .rad-absorb-stats__title {
        font-size:2.6666666667rem
    }
}

@media(min-width: 600px) {
    .rad-absorb-stats__title {
        margin-bottom:2.5rem
    }
}

@media(min-width: 1024px) {
    .rad-absorb-stats__title {
        margin-bottom:3rem
    }
}

@media(min-width: 1441px) {
    .rad-absorb-stats__title {
        margin-bottom:calc(3rem + 3.34029vw - 48.13361px)
    }
}

@media(min-width: 1920px) {
    .rad-absorb-stats__title {
        margin-bottom:4rem
    }
}

.rad-absorb-stats__cards-container {
    -webkit-column-gap: var(--rad-spacing-sm);
    -moz-column-gap: var(--rad-spacing-sm);
    column-gap: var(--rad-spacing-sm);
    display: grid;
    grid-template-columns: repeat(2,1fr);
    row-gap: 2rem
}

@media(min-width: 600px) {
    .rad-absorb-stats__cards-container {
        row-gap:3rem
    }
}

@media(min-width: 1024px) {
    .rad-absorb-stats__cards-container {
        grid-template-columns:repeat(4,1fr)
    }
}

.rad-absorb-stats__eyebrow {
    font-size: .75rem;
    font-weight: 500;
    letter-spacing: .02em;
    line-height: 1.2;
    margin-bottom: .5rem;
    text-transform: uppercase
}

.rad-absorb-stats__eyebrow:lang(ja-JP),.rad-absorb-stats__eyebrow:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 1024px) {
    .rad-absorb-stats__eyebrow {
        font-size:0.875rem
    }
}

@media(min-width: 1441px) {
    .rad-absorb-stats__eyebrow {
        font-size:calc(0.875rem + 0.97425vw - 14.03897px)
    }
}

@media(min-width: 1920px) {
    .rad-absorb-stats__eyebrow {
        font-size:1.1666666667rem
    }
}

@media(min-width: 1024px) {
    .rad-absorb-stats__eyebrow {
        margin-bottom:0.75rem
    }
}

@media(min-width: 1441px) {
    .rad-absorb-stats__eyebrow {
        margin-bottom:calc(0.75rem + 0.83507vw - 12.0334px)
    }
}

@media(min-width: 1920px) {
    .rad-absorb-stats__eyebrow {
        margin-bottom:1rem
    }
}

.rad-absorb-stats__card--media {
    grid-column-end: span 2
}

@media(min-width: 1024px) {
    .rad-absorb-stats__card--media {
        grid-column-end:span 4
    }
}

@media(min-width: 600px) {
    .rad-absorb-stats__card--media .rad-absorb-stats__card-text {
        width:calc(100% - var(--rad-spacing-sm)*3/4*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-sm)*0);
        width: calc(50% - var(--rad-spacing-sm)*7/8*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-absorb-stats__card--media .rad-absorb-stats__card-text {
        width:calc(33.33333% - var(--rad-spacing-sm)*11/12*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0)
    }
}

.rad-absorb-stats__card--media .rad-absorb-stats__card-stat {
    font-size: 1.75rem;
    font-weight: 500;
    letter-spacing: -0.03em;
    line-height: 1.3
}

.rad-absorb-stats__card--media .rad-absorb-stats__card-stat:lang(ja-JP),.rad-absorb-stats__card--media .rad-absorb-stats__card-stat:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-absorb-stats__card--media .rad-absorb-stats__card-stat {
        font-size:2.5rem;
        line-height: 1.2
    }
}

@media(min-width: 1024px) {
    .rad-absorb-stats__card--media .rad-absorb-stats__card-stat {
        font-size:3rem;
        line-height: 1.2
    }
}

@media(min-width: 1441px) {
    .rad-absorb-stats__card--media .rad-absorb-stats__card-stat {
        font-size:calc(3rem + 3.34029vw - 48.13361px)
    }
}

@media(min-width: 1920px) {
    .rad-absorb-stats__card--media .rad-absorb-stats__card-stat {
        font-size:4rem
    }
}

.rad-absorb-stats__card--media .rad-absorb-stats__card-detail {
    margin-bottom: 2rem
}

@media(max-width: 599px) {
    .rad-absorb-stats__card--media .rad-absorb-stats__card-image-container {
        margin:0 calc(var(--rad-spacing-sm)*-1);
        max-width: 100vw
    }
}

@media(min-width: 600px) {
    .rad-absorb-stats__card--media .rad-absorb-stats__card-image-container {
        width:calc(100% - var(--rad-spacing-sm)*3/4*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-sm)*0);
        width: calc(50% - var(--rad-spacing-sm)*7/8*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-absorb-stats__card--media .rad-absorb-stats__card-image-container {
        width:calc(33.33333% - var(--rad-spacing-sm)*11/12*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0);
        width: calc(150% - var(--rad-spacing-sm)*3/4*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-sm)*0);
        width: calc(75% - var(--rad-spacing-sm)*7/8*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0);
        width: calc(50% - var(--rad-spacing-sm)*11/12*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0)
    }
}

.rad-absorb-stats__card--media .rad-absorb-stats__card-image {
    display: block
}

@media(min-width: 600px) {
    .rad-absorb-stats__card--media {
        -webkit-box-pack:justify;
        -ms-flex-pack: justify;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        justify-content: space-between;
        width: calc(200% - var(--rad-spacing-sm)*3/4*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-sm)*0);
        width: calc(100% - var(--rad-spacing-sm)*7/8*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-absorb-stats__card--media {
        width:calc(66.66667% - var(--rad-spacing-sm)*11/12*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0);
        width: calc(300% - var(--rad-spacing-sm)*3/4*12 + var(--rad-spacing-sm)*11 + var(--rad-spacing-sm)*0);
        width: calc(150% - var(--rad-spacing-sm)*7/8*12 + var(--rad-spacing-sm)*11 + var(--rad-spacing-md)*0);
        width: calc(100% - var(--rad-spacing-sm)*11/12*12 + var(--rad-spacing-sm)*11 + var(--rad-spacing-md)*0)
    }
}

.rad-absorb-stats__card-text:before {
    background: #a100ff;
    content: "";
    display: block;
    height: 0.25rem;
    margin-bottom: .5rem;
    width: 1.5rem
}

@media(min-width: 1441px) {
    .rad-absorb-stats__card-text:before {
        height:calc(0.25rem + 0.27836vw - 4.01113px)
    }
}

@media(min-width: 1920px) {
    .rad-absorb-stats__card-text:before {
        height:0.3333333333rem
    }
}

@media(min-width: 600px) {
    .rad-absorb-stats__card-text:before {
        margin-bottom:.75rem
    }
}

@media(min-width: 1024px) {
    .rad-absorb-stats__card-text:before {
        margin-bottom:1rem;
        width: 2rem
    }
}

@media(min-width: 1441px) {
    .rad-absorb-stats__card-text:before {
        margin-bottom:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-absorb-stats__card-text:before {
        margin-bottom:1.3333333333rem
    }
}

.rad-absorb-stats__card-stat {
    font-size: 1.75rem;
    font-weight: 500;
    letter-spacing: -0.03em;
    line-height: 1.3;
    margin-bottom: .5rem
}

.rad-absorb-stats__card-stat:lang(ja-JP),.rad-absorb-stats__card-stat:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-absorb-stats__card-stat {
        font-size:2.5rem;
        line-height: 1.2
    }
}

@media(min-width: 1024px) {
    .rad-absorb-stats__card-stat {
        font-size:3rem;
        line-height: 1.2
    }
}

@media(min-width: 1441px) {
    .rad-absorb-stats__card-stat {
        font-size:calc(3rem + 3.34029vw - 48.13361px)
    }
}

@media(min-width: 1920px) {
    .rad-absorb-stats__card-stat {
        font-size:4rem
    }
}

@media(min-width: 1024px) {
    .rad-absorb-stats__card-stat {
        margin-bottom:0.75rem
    }
}

@media(min-width: 1441px) {
    .rad-absorb-stats__card-stat {
        margin-bottom:calc(0.75rem + 0.83507vw - 12.0334px)
    }
}

@media(min-width: 1920px) {
    .rad-absorb-stats__card-stat {
        margin-bottom:1rem
    }
}

.rad-absorb-stats__card-detail>h2,.rad-absorb-stats__card-detail>h3,.rad-absorb-stats__card-detail>h4,.rad-absorb-stats__card-detail>h5,.rad-absorb-stats__card-detail>ol,.rad-absorb-stats__card-detail>p,.rad-absorb-stats__card-detail>ul {
    width: calc(100% - var(--rad-spacing-sm)*3/4*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-sm)*0)
}

@media(min-width: 600px) {
    .rad-absorb-stats__card-detail>h2,.rad-absorb-stats__card-detail>h3,.rad-absorb-stats__card-detail>h4,.rad-absorb-stats__card-detail>h5,.rad-absorb-stats__card-detail>ol,.rad-absorb-stats__card-detail>p,.rad-absorb-stats__card-detail>ul {
        width:calc(50% - var(--rad-spacing-sm)*7/8*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-absorb-stats__card-detail>h2,.rad-absorb-stats__card-detail>h3,.rad-absorb-stats__card-detail>h4,.rad-absorb-stats__card-detail>h5,.rad-absorb-stats__card-detail>ol,.rad-absorb-stats__card-detail>p,.rad-absorb-stats__card-detail>ul {
        width:calc(33.33333% - var(--rad-spacing-sm)*11/12*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0)
    }
}

.rad-absorb-stats__card-detail h2 {
    font-size: 1.75rem;
    font-weight: 500;
    letter-spacing: -0.03em;
    line-height: 1.3
}

.rad-absorb-stats__card-detail h2:lang(ja-JP),.rad-absorb-stats__card-detail h2:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-absorb-stats__card-detail h2 {
        font-size:2.5rem;
        line-height: 1.2
    }
}

@media(min-width: 1024px) {
    .rad-absorb-stats__card-detail h2 {
        font-size:3rem;
        line-height: 1.2
    }
}

@media(min-width: 1441px) {
    .rad-absorb-stats__card-detail h2 {
        font-size:calc(3rem + 3.34029vw - 48.13361px)
    }
}

@media(min-width: 1920px) {
    .rad-absorb-stats__card-detail h2 {
        font-size:4rem
    }
}

.rad-absorb-stats__card-detail h2+h2,.rad-absorb-stats__card-detail h2+h3 {
    margin-top: 1rem
}

.rad-absorb-stats__card-detail h2+h4 {
    margin-top: 1.5rem
}

.rad-absorb-stats__card-detail h2+h5 {
    margin-top: .5rem
}

.rad-absorb-stats__card-detail h2+ol,.rad-absorb-stats__card-detail h2+p,.rad-absorb-stats__card-detail h2+ul {
    margin-top: 1.5rem
}

.rad-absorb-stats__card-detail h3 {
    font-size: 1.375rem;
    font-weight: 500;
    letter-spacing: -0.02em;
    line-height: 1.25
}

.rad-absorb-stats__card-detail h3:lang(ja-JP),.rad-absorb-stats__card-detail h3:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-absorb-stats__card-detail h3 {
        font-size:1.75rem;
        line-height: 1.3
    }
}

@media(min-width: 1024px) {
    .rad-absorb-stats__card-detail h3 {
        font-size:2rem;
        line-height: 1.2
    }
}

@media(min-width: 1441px) {
    .rad-absorb-stats__card-detail h3 {
        font-size:calc(2rem + 2.22686vw - 32.08907px)
    }
}

@media(min-width: 1920px) {
    .rad-absorb-stats__card-detail h3 {
        font-size:2.6666666667rem
    }
}

.rad-absorb-stats__card-detail h3+h2 {
    margin-top: 2rem
}

.rad-absorb-stats__card-detail h3+h3 {
    margin-top: 1rem
}

.rad-absorb-stats__card-detail h3+h4 {
    margin-top: .5rem
}

.rad-absorb-stats__card-detail h3+h5 {
    margin-top: 1rem
}

.rad-absorb-stats__card-detail h3+ol,.rad-absorb-stats__card-detail h3+p,.rad-absorb-stats__card-detail h3+ul {
    margin-top: 1.5rem
}

.rad-absorb-stats__card-detail h4 {
    font-size: 1.125rem;
    font-weight: 500;
    letter-spacing: -0.02em;
    line-height: 1.4
}

.rad-absorb-stats__card-detail h4:lang(ja-JP),.rad-absorb-stats__card-detail h4:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-absorb-stats__card-detail h4 {
        font-size:1.25rem;
        line-height: 1.4
    }
}

@media(min-width: 1024px) {
    .rad-absorb-stats__card-detail h4 {
        font-size:1.5rem;
        line-height: 1.3
    }
}

@media(min-width: 1441px) {
    .rad-absorb-stats__card-detail h4 {
        font-size:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-absorb-stats__card-detail h4 {
        font-size:2rem
    }
}

.rad-absorb-stats__card-detail h4+h2,.rad-absorb-stats__card-detail h4+h3 {
    margin-top: 2rem
}

.rad-absorb-stats__card-detail h4+h4 {
    margin-top: .5rem
}

.rad-absorb-stats__card-detail h4+h5 {
    margin-top: 2rem
}

.rad-absorb-stats__card-detail h4+ol,.rad-absorb-stats__card-detail h4+p,.rad-absorb-stats__card-detail h4+ul {
    margin-top: .5rem
}

.rad-absorb-stats__card-detail h5 {
    font-family: GT Sectra Fine,Palatino;
    font-size: 1.25rem;
    font-weight: 300;
    line-height: 1.25
}

@media(min-width: 600px) {
    .rad-absorb-stats__card-detail h5 {
        font-size:1.5rem;
        line-height: 1.25
    }
}

@media(min-width: 1024px) {
    .rad-absorb-stats__card-detail h5 {
        font-size:1.75rem;
        line-height: 1.25
    }
}

@media(min-width: 1441px) {
    .rad-absorb-stats__card-detail h5 {
        font-size:calc(1.75rem + 1.9485vw - 28.07794px)
    }
}

@media(min-width: 1920px) {
    .rad-absorb-stats__card-detail h5 {
        font-size:2.3333333333rem
    }
}

.rad-absorb-stats__card-detail h5+h2 {
    margin-top: 2rem
}

.rad-absorb-stats__card-detail h5+h3,.rad-absorb-stats__card-detail h5+h4 {
    margin-top: 1.5rem
}

.rad-absorb-stats__card-detail h5+h5 {
    margin-top: 1rem
}

.rad-absorb-stats__card-detail h5+ol,.rad-absorb-stats__card-detail h5+p,.rad-absorb-stats__card-detail h5+ul {
    margin-top: 1.5rem
}

.rad-absorb-stats__card-detail ol,.rad-absorb-stats__card-detail p,.rad-absorb-stats__card-detail ul {
    font-size: .875rem;
    font-weight: 500;
    font-weight: 400;
    letter-spacing: -0.005em;
    line-height: 1.5;
    padding-right: var(--scrollbar-width);
    text-underline-offset: .25rem
}

.rad-absorb-stats__card-detail ol:lang(ja-JP),.rad-absorb-stats__card-detail ol:lang(zh-CN),.rad-absorb-stats__card-detail p:lang(ja-JP),.rad-absorb-stats__card-detail p:lang(zh-CN),.rad-absorb-stats__card-detail ul:lang(ja-JP),.rad-absorb-stats__card-detail ul:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-absorb-stats__card-detail ol,.rad-absorb-stats__card-detail p,.rad-absorb-stats__card-detail ul {
        font-size:1rem
    }
}

@media(min-width: 1024px) {
    .rad-absorb-stats__card-detail ol,.rad-absorb-stats__card-detail p,.rad-absorb-stats__card-detail ul {
        font-size:1.125rem;
        line-height: 1.6
    }
}

@media(min-width: 1441px) {
    .rad-absorb-stats__card-detail ol,.rad-absorb-stats__card-detail p,.rad-absorb-stats__card-detail ul {
        font-size:calc(1.125rem + 1.25261vw - 18.0501px)
    }
}

@media(min-width: 1920px) {
    .rad-absorb-stats__card-detail ol,.rad-absorb-stats__card-detail p,.rad-absorb-stats__card-detail ul {
        font-size:1.5rem
    }
}

.rad-absorb-stats__card-detail ol:lang(ja-JP),.rad-absorb-stats__card-detail ol:lang(zh-CN),.rad-absorb-stats__card-detail p:lang(ja-JP),.rad-absorb-stats__card-detail p:lang(zh-CN),.rad-absorb-stats__card-detail ul:lang(ja-JP),.rad-absorb-stats__card-detail ul:lang(zh-CN) {
    font-weight: 400
}

.rad-absorb-stats__card-detail ol a,.rad-absorb-stats__card-detail p a,.rad-absorb-stats__card-detail ul a {
    font-weight: 500
}

.rad-absorb-stats__card-detail ol a:focus-visible,.rad-absorb-stats__card-detail p a:focus-visible,.rad-absorb-stats__card-detail ul a:focus-visible {
    outline-offset: 2px
}

.rad-absorb-stats__card-detail ol+h2,.rad-absorb-stats__card-detail ol+h3,.rad-absorb-stats__card-detail ol+h4,.rad-absorb-stats__card-detail ol+h5,.rad-absorb-stats__card-detail p+h2,.rad-absorb-stats__card-detail p+h3,.rad-absorb-stats__card-detail p+h4,.rad-absorb-stats__card-detail p+h5,.rad-absorb-stats__card-detail ul+h2,.rad-absorb-stats__card-detail ul+h3,.rad-absorb-stats__card-detail ul+h4,.rad-absorb-stats__card-detail ul+h5 {
    margin-top: 2rem
}

.rad-absorb-stats__card-detail ol+ol,.rad-absorb-stats__card-detail ol+p,.rad-absorb-stats__card-detail ol+ul,.rad-absorb-stats__card-detail p+ol,.rad-absorb-stats__card-detail p+p,.rad-absorb-stats__card-detail p+ul,.rad-absorb-stats__card-detail ul+ol,.rad-absorb-stats__card-detail ul+p,.rad-absorb-stats__card-detail ul+ul {
    margin-top: .5rem
}

.rad-absorb-stats__card-detail p {
    font-size: 1rem;
    font-weight: 500;
    font-weight: 400;
    letter-spacing: -0.005em;
    line-height: 1.6;
    text-underline-offset: .25rem
}

.rad-absorb-stats__card-detail p:lang(ja-JP),.rad-absorb-stats__card-detail p:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-absorb-stats__card-detail p {
        font-size:1.125rem
    }
}

@media(min-width: 1024px) {
    .rad-absorb-stats__card-detail p {
        font-size:1.25rem
    }
}

@media(min-width: 1441px) {
    .rad-absorb-stats__card-detail p {
        font-size:calc(1.25rem + 1.39179vw - 20.05567px)
    }
}

@media(min-width: 1920px) {
    .rad-absorb-stats__card-detail p {
        font-size:1.6666666667rem
    }
}

.rad-absorb-stats__card-detail p:lang(ja-JP),.rad-absorb-stats__card-detail p:lang(zh-CN) {
    font-weight: 400
}

.rad-absorb-stats__card-detail ol li {
    counter-increment: li;
    list-style-type: none;
    padding-left: 30px;
    position: relative
}

@media(min-width: 1441px) {
    .rad-absorb-stats__card-detail ol li {
        padding-left:calc(-0.08351px + 2.08768vw)
    }
}

@media(min-width: 1920px) {
    .rad-absorb-stats__card-detail ol li {
        padding-left:40px
    }
}

.rad-absorb-stats__card-detail ol li:before {
    color: #a100ff;
    content: counter(li) attr(start) ". ";
    left: 0;
    position: absolute
}

.rad-absorb-stats__card-detail ul {
    list-style: none;
    padding-left: 0
}

.rad-absorb-stats__card-detail ul li {
    padding-left: 29px;
    position: relative
}

@media(min-width: 1441px) {
    .rad-absorb-stats__card-detail ul li {
        padding-left:calc(-0.08072px + 2.01809vw)
    }
}

@media(min-width: 1920px) {
    .rad-absorb-stats__card-detail ul li {
        padding-left:38.6666666667px
    }
}

.rad-absorb-stats__card-detail ul li:before {
    background-color: #a100ff;
    content: "";
    display: inline-block;
    height: .375rem;
    left: 0;
    position: absolute;
    text-align: center;
    top: 10px;
    width: .375rem
}

@media(min-width: 1441px) {
    .rad-absorb-stats__card-detail ul li:before {
        top:calc(-0.02784px + 0.69589vw)
    }
}

@media(min-width: 1920px) {
    .rad-absorb-stats__card-detail ul li:before {
        top:13.3333333333px
    }
}

@media(min-width: 600px) {
    .rad-absorb-stats__card-detail>h2,.rad-absorb-stats__card-detail>h3,.rad-absorb-stats__card-detail>h4,.rad-absorb-stats__card-detail>h5,.rad-absorb-stats__card-detail>ol,.rad-absorb-stats__card-detail>p,.rad-absorb-stats__card-detail>ul {
        width:calc(150% - var(--rad-spacing-sm)*3/4*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-sm)*0);
        width: calc(75% - var(--rad-spacing-sm)*7/8*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-absorb-stats__card-detail>h2,.rad-absorb-stats__card-detail>h3,.rad-absorb-stats__card-detail>h4,.rad-absorb-stats__card-detail>h5,.rad-absorb-stats__card-detail>ol,.rad-absorb-stats__card-detail>p,.rad-absorb-stats__card-detail>ul {
        width:calc(50% - var(--rad-spacing-sm)*11/12*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 600px) {
    .rad-absorb-stats__card-detail h2+h2,.rad-absorb-stats__card-detail h2+h3 {
        margin-top:1.5rem
    }

    .rad-absorb-stats__card-detail h2+h4 {
        margin-top: 2rem
    }

    .rad-absorb-stats__card-detail h2+h5 {
        margin-top: .5rem
    }

    .rad-absorb-stats__card-detail h2+ol,.rad-absorb-stats__card-detail h2+p,.rad-absorb-stats__card-detail h2+ul {
        margin-top: 2rem
    }

    .rad-absorb-stats__card-detail h3+h2 {
        margin-top: 3rem
    }

    .rad-absorb-stats__card-detail h3+h3 {
        margin-top: 1.5rem
    }

    .rad-absorb-stats__card-detail h3+h4 {
        margin-top: .75rem
    }

    .rad-absorb-stats__card-detail h3+h5 {
        margin-top: 1.5rem
    }

    .rad-absorb-stats__card-detail h3+ol,.rad-absorb-stats__card-detail h3+p,.rad-absorb-stats__card-detail h3+ul {
        margin-top: 2rem
    }

    .rad-absorb-stats__card-detail h4+h2,.rad-absorb-stats__card-detail h4+h3 {
        margin-top: 3rem
    }

    .rad-absorb-stats__card-detail h4+h4 {
        margin-top: 1rem
    }

    .rad-absorb-stats__card-detail h4+h5 {
        margin-top: 3rem
    }

    .rad-absorb-stats__card-detail h4+p {
        margin-top: .75rem
    }

    .rad-absorb-stats__card-detail h4+ol,.rad-absorb-stats__card-detail h4+ul {
        margin-top: 1rem
    }

    .rad-absorb-stats__card-detail h5+h2 {
        margin-top: 3rem
    }

    .rad-absorb-stats__card-detail h5+h3 {
        margin-top: 2.5rem
    }

    .rad-absorb-stats__card-detail h5+h4 {
        margin-top: 2rem
    }

    .rad-absorb-stats__card-detail h5+h5 {
        margin-top: 1.5rem
    }

    .rad-absorb-stats__card-detail h5+ol,.rad-absorb-stats__card-detail h5+p,.rad-absorb-stats__card-detail h5+ul {
        margin-top: 2rem
    }

    .rad-absorb-stats__card-detail ol,.rad-absorb-stats__card-detail p,.rad-absorb-stats__card-detail ul {
        padding-right: 0
    }

    .rad-absorb-stats__card-detail ol+h2,.rad-absorb-stats__card-detail ol+h3,.rad-absorb-stats__card-detail ol+h4,.rad-absorb-stats__card-detail ol+h5,.rad-absorb-stats__card-detail p+h2,.rad-absorb-stats__card-detail p+h3,.rad-absorb-stats__card-detail p+h4,.rad-absorb-stats__card-detail p+h5,.rad-absorb-stats__card-detail ul+h2,.rad-absorb-stats__card-detail ul+h3,.rad-absorb-stats__card-detail ul+h4,.rad-absorb-stats__card-detail ul+h5 {
        margin-top: 3rem
    }

    .rad-absorb-stats__card-detail ol+ol,.rad-absorb-stats__card-detail ol+p,.rad-absorb-stats__card-detail ol+ul,.rad-absorb-stats__card-detail p+ol,.rad-absorb-stats__card-detail p+p,.rad-absorb-stats__card-detail p+ul,.rad-absorb-stats__card-detail ul+ol,.rad-absorb-stats__card-detail ul+p,.rad-absorb-stats__card-detail ul+ul {
        margin-top: 1rem
    }

    .rad-absorb-stats__card-detail ol li:before,.rad-absorb-stats__card-detail ul li:before {
        height: .5rem;
        width: .5rem
    }
}

@media(min-width: 1024px) {
    .rad-absorb-stats__card-detail>h2,.rad-absorb-stats__card-detail>h3,.rad-absorb-stats__card-detail>h4,.rad-absorb-stats__card-detail>h5,.rad-absorb-stats__card-detail>ol,.rad-absorb-stats__card-detail>p,.rad-absorb-stats__card-detail>ul {
        width:calc(200% - var(--rad-spacing-sm)*3/4*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-sm)*0);
        width: calc(100% - var(--rad-spacing-sm)*7/8*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0);
        width: calc(66.66667% - var(--rad-spacing-sm)*11/12*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0)
    }

    .rad-absorb-stats__card-detail h2+h2,.rad-absorb-stats__card-detail h2+h3 {
        margin-top: 2rem
    }

    .rad-absorb-stats__card-detail h2+h4 {
        margin-top: 3rem
    }

    .rad-absorb-stats__card-detail h2+h5 {
        margin-top: .5rem
    }

    .rad-absorb-stats__card-detail h2+ol,.rad-absorb-stats__card-detail h2+p,.rad-absorb-stats__card-detail h2+ul {
        margin-top: 3rem
    }

    .rad-absorb-stats__card-detail h3+h2 {
        margin-top: 4rem
    }

    .rad-absorb-stats__card-detail h3+h3 {
        margin-top: 2rem
    }

    .rad-absorb-stats__card-detail h3+h4 {
        margin-top: 1rem
    }

    .rad-absorb-stats__card-detail h3+h5 {
        margin-top: 2rem
    }

    .rad-absorb-stats__card-detail h3+ol,.rad-absorb-stats__card-detail h3+p,.rad-absorb-stats__card-detail h3+ul {
        margin-top: 3rem
    }

    .rad-absorb-stats__card-detail h4+h2,.rad-absorb-stats__card-detail h4+h3 {
        margin-top: 4rem
    }

    .rad-absorb-stats__card-detail h4+h4 {
        margin-top: 1.5rem
    }

    .rad-absorb-stats__card-detail h4+h5 {
        margin-top: 4rem
    }

    .rad-absorb-stats__card-detail h4+p {
        margin-top: 1rem
    }

    .rad-absorb-stats__card-detail h4+ol,.rad-absorb-stats__card-detail h4+ul {
        margin-top: 1.5rem
    }

    .rad-absorb-stats__card-detail h5+h2 {
        margin-top: 4rem
    }

    .rad-absorb-stats__card-detail h5+h3,.rad-absorb-stats__card-detail h5+h4 {
        margin-top: 3rem
    }

    .rad-absorb-stats__card-detail h5+h5 {
        margin-top: 2rem
    }

    .rad-absorb-stats__card-detail h5+ol,.rad-absorb-stats__card-detail h5+p,.rad-absorb-stats__card-detail h5+ul {
        margin-top: 3rem
    }

    .rad-absorb-stats__card-detail ol+h2,.rad-absorb-stats__card-detail ol+h3,.rad-absorb-stats__card-detail ol+h4,.rad-absorb-stats__card-detail ol+h5,.rad-absorb-stats__card-detail p+h2,.rad-absorb-stats__card-detail p+h3,.rad-absorb-stats__card-detail p+h4,.rad-absorb-stats__card-detail p+h5,.rad-absorb-stats__card-detail ul+h2,.rad-absorb-stats__card-detail ul+h3,.rad-absorb-stats__card-detail ul+h4,.rad-absorb-stats__card-detail ul+h5 {
        margin-top: 4rem
    }

    .rad-absorb-stats__card-detail ol+ol,.rad-absorb-stats__card-detail ol+p,.rad-absorb-stats__card-detail ol+ul,.rad-absorb-stats__card-detail p+ol,.rad-absorb-stats__card-detail p+p,.rad-absorb-stats__card-detail p+ul,.rad-absorb-stats__card-detail ul+ol,.rad-absorb-stats__card-detail ul+p,.rad-absorb-stats__card-detail ul+ul {
        margin-top: 1.5rem
    }
}

.rad-absorb-stats__card-detail>h2,.rad-absorb-stats__card-detail>h3,.rad-absorb-stats__card-detail>h4,.rad-absorb-stats__card-detail>h5,.rad-absorb-stats__card-detail>ol,.rad-absorb-stats__card-detail>p,.rad-absorb-stats__card-detail>ul {
    width: 100%
}

.rad-absorb-stats__card-detail>ol,.rad-absorb-stats__card-detail>p,.rad-absorb-stats__card-detail>ul {
    font-size: .875rem;
    font-weight: 500;
    font-weight: 400;
    letter-spacing: -0.005em;
    line-height: 1.5;
    text-underline-offset: .25rem
}

.rad-absorb-stats__card-detail>ol:lang(ja-JP),.rad-absorb-stats__card-detail>ol:lang(zh-CN),.rad-absorb-stats__card-detail>p:lang(ja-JP),.rad-absorb-stats__card-detail>p:lang(zh-CN),.rad-absorb-stats__card-detail>ul:lang(ja-JP),.rad-absorb-stats__card-detail>ul:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-absorb-stats__card-detail>ol,.rad-absorb-stats__card-detail>p,.rad-absorb-stats__card-detail>ul {
        font-size:1rem
    }
}

@media(min-width: 1441px) {
    .rad-absorb-stats__card-detail>ol,.rad-absorb-stats__card-detail>p,.rad-absorb-stats__card-detail>ul {
        font-size:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-absorb-stats__card-detail>ol,.rad-absorb-stats__card-detail>p,.rad-absorb-stats__card-detail>ul {
        font-size:1.3333333333rem
    }
}

.rad-absorb-stats__card-detail>ol:lang(ja-JP),.rad-absorb-stats__card-detail>ol:lang(zh-CN),.rad-absorb-stats__card-detail>p:lang(ja-JP),.rad-absorb-stats__card-detail>p:lang(zh-CN),.rad-absorb-stats__card-detail>ul:lang(ja-JP),.rad-absorb-stats__card-detail>ul:lang(zh-CN) {
    font-weight: 400
}

.rad-absorb-stats--two-columns .rad-absorb-stats__cards-container {
    -webkit-column-gap: 16px;
    -moz-column-gap: 16px;
    column-gap: 16px;
    grid-template-columns: repeat(2,1fr);
    row-gap: 32px
}

@media(min-width: 600px) {
    .rad-absorb-stats--two-columns .rad-absorb-stats__cards-container {
        -webkit-column-gap:24px;
        -moz-column-gap: 24px;
        column-gap: 24px;
        row-gap: 40px
    }
}

@media(min-width: 1024px) {
    .rad-absorb-stats--two-columns .rad-absorb-stats__cards-container {
        -webkit-column-gap:32px;
        -moz-column-gap: 32px;
        column-gap: 32px;
        row-gap: 48px
    }
}

@media(min-width: 1441px) {
    .rad-absorb-stats--two-columns .rad-absorb-stats__cards-container {
        -webkit-column-gap:calc(-0.08907px + 2.22686vw);
        -moz-column-gap: calc(-0.08907px + 2.22686vw);
        column-gap: calc(-0.08907px + 2.22686vw)
    }
}

@media(min-width: 1920px) {
    .rad-absorb-stats--two-columns .rad-absorb-stats__cards-container {
        -webkit-column-gap:42.6666666667px;
        -moz-column-gap: 42.6666666667px;
        column-gap: 42.6666666667px
    }
}

@media(min-width: 1441px) {
    .rad-absorb-stats--two-columns .rad-absorb-stats__cards-container {
        row-gap:calc(-0.13361px + 3.34029vw)
    }
}

@media(min-width: 1920px) {
    .rad-absorb-stats--two-columns .rad-absorb-stats__cards-container {
        row-gap:64px
    }
}

@media(min-width: 1024px) {
    .rad-absorb-stats.rad-absorb-stats--five-or-six-cards .rad-absorb-stats__cards-container {
        grid-template-columns:repeat(3,1fr);
        width: calc(225% - var(--rad-spacing-sm)*3/4*9 + var(--rad-spacing-sm)*8 + var(--rad-spacing-sm)*0);
        width: calc(112.5% - var(--rad-spacing-sm)*7/8*9 + var(--rad-spacing-sm)*8 + var(--rad-spacing-md)*0);
        width: calc(75% - var(--rad-spacing-sm)*11/12*9 + var(--rad-spacing-sm)*8 + var(--rad-spacing-md)*0)
    }
}

.rad-component__title--large-text .rad-absorb-stats__title {
    font-size: 1.75rem;
    font-weight: 500;
    letter-spacing: -0.03em;
    line-height: 1.3
}

.rad-component__title--large-text .rad-absorb-stats__title:lang(ja-JP),.rad-component__title--large-text .rad-absorb-stats__title:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-component__title--large-text .rad-absorb-stats__title {
        font-size:2.5rem;
        line-height: 1.2
    }
}

@media(min-width: 1024px) {
    .rad-component__title--large-text .rad-absorb-stats__title {
        font-size:3rem;
        line-height: 1.2
    }
}

@media(min-width: 1441px) {
    .rad-component__title--large-text .rad-absorb-stats__title {
        font-size:calc(3rem + 3.34029vw - 48.13361px)
    }
}

@media(min-width: 1920px) {
    .rad-component__title--large-text .rad-absorb-stats__title {
        font-size:4rem
    }
}

.rad-quote-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: var(--rad-spacing-sm);
    padding-right: var(--rad-spacing-sm)
}

@media(min-width: 600px) {
    .rad-quote-container {
        padding-left:var(--rad-spacing-md);
        padding-right: var(--rad-spacing-md)
    }
}

.rad-component--nested .rad-quote-container {
    padding-left: 0;
    padding-right: 0
}

.rad-image-qoute .rad-quote-container {
    padding-left: 0
}

.rad-quote-content {
    padding-left: 1rem;
    padding-top: 1.5rem;
    width: calc(100% - var(--rad-spacing-sm)*3/4*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-sm)*0)
}

@media(min-width: 600px) {
    .rad-quote-content {
        width:calc(50% - var(--rad-spacing-sm)*7/8*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-quote-content {
        width:calc(33.33333% - var(--rad-spacing-sm)*11/12*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0)
    }
}

.rad-quote-image {
    -webkit-filter: grayscale(1);
    filter: grayscale(1);
    height: auto;
    width: calc(min(100vw, 1920px)/4*2 - var(--rad-spacing-sm)*2/4*2 - var(--rad-spacing-sm)*3/4*2 + var(--rad-spacing-sm)*1 + var(--rad-spacing-sm)*1)
}

@media(min-width: 600px) {
    .rad-quote-image {
        width:calc(min(100vw, 1920px)/8*2 - var(--rad-spacing-md)*2/8*2 - var(--rad-spacing-sm)*7/8*2 + var(--rad-spacing-sm)*1 + var(--rad-spacing-md)*1)
    }
}

@media(min-width: 1024px) {
    .rad-quote-image {
        width:calc(min(100vw, 1920px)/12*2 - var(--rad-spacing-md)*2/12*2 - var(--rad-spacing-sm)*11/12*2 + var(--rad-spacing-sm)*1 + var(--rad-spacing-md)*1)
    }
}

.rad-quote-headline {
    font-size: 1.5rem;
    font-weight: 500;
    letter-spacing: -0.03em;
    line-height: 1.35;
    margin-bottom: 1rem
}

.rad-quote-headline:lang(ja-JP),.rad-quote-headline:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-quote-headline {
        font-size:2rem;
        line-height: 1.2
    }
}

@media(min-width: 1024px) {
    .rad-quote-headline {
        font-size:2.5rem;
        line-height: 1.2
    }
}

@media(min-width: 1441px) {
    .rad-quote-headline {
        font-size:calc(2.5rem + 2.78358vw - 40.11134px)
    }
}

@media(min-width: 1920px) {
    .rad-quote-headline {
        font-size:3.3333333333rem
    }
}

.rad-quote-headline .rad-quote-text:before {
    content: "“"
}

.rad-quote-headline .rad-quote-text:after {
    content: "”"
}

.rad-quote-body {
    color: #a2a2a0;
    font-size: .75rem;
    font-weight: 500;
    letter-spacing: -0.005em;
    line-height: 1.5;
    text-underline-offset: .25rem
}

.rad-quote-body:lang(ja-JP),.rad-quote-body:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-quote-body {
        font-size:0.875rem
    }
}

@media(min-width: 1441px) {
    .rad-quote-body {
        font-size:calc(0.875rem + 0.97425vw - 14.03897px)
    }
}

@media(min-width: 1920px) {
    .rad-quote-body {
        font-size:1.1666666667rem
    }
}

.rad-quote-right .rad-quote-container {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end
}

.rad-quote-left .rad-quote-content,.rad-quote-right .rad-quote-content {
    margin: 0;
    padding: 0
}

@media(min-width: 600px) {
    .rad-quote-container {
        -webkit-box-orient:horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row
    }

    .rad-quote-content {
        margin: auto 0;
        padding-left: 0;
        padding-left: calc(min(100vw, 1920px)/4*1 - var(--rad-spacing-sm)*2/4*1 - var(--rad-spacing-sm)*3/4*1 + var(--rad-spacing-sm)*2 + var(--rad-spacing-sm)*0);
        padding-top: 0;
        width: calc(min(100vw, 1920px)/4*5 - var(--rad-spacing-sm)*2/4*5 - var(--rad-spacing-sm)*3/4*5 + var(--rad-spacing-sm)*4 + var(--rad-spacing-sm)*2);
        width: calc(min(100vw, 1920px)/8*5 - var(--rad-spacing-md)*2/8*5 - var(--rad-spacing-sm)*7/8*5 + var(--rad-spacing-sm)*4 + var(--rad-spacing-md)*2)
    }
}

@media(min-width: 1024px) {
    .rad-quote-content {
        width:calc(min(100vw, 1920px)/12*5 - var(--rad-spacing-md)*2/12*5 - var(--rad-spacing-sm)*11/12*5 + var(--rad-spacing-sm)*4 + var(--rad-spacing-md)*2)
    }
}

@media(min-width: 600px) {
    .rad-quote-content {
        padding-left:calc(min(100vw, 1920px)/8*1 - var(--rad-spacing-md)*2/8*1 - var(--rad-spacing-sm)*7/8*1 + var(--rad-spacing-sm)*2 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-quote-content {
        padding-left:calc(min(100vw, 1920px)/12*1 - var(--rad-spacing-md)*2/12*1 - var(--rad-spacing-sm)*11/12*1 + var(--rad-spacing-sm)*2 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 600px) {
    .rad-quote-headline {
        margin-bottom:1.5rem
    }

    .rad-quote-left .rad-quote-content,.rad-quote-right .rad-quote-content {
        margin-left: 0;
        max-width: 31.125rem;
        padding-left: 0
    }
}

@media(min-width: 1024px) {
    .rad-quote-image {
        width:calc(min(100vw, 1920px)/4*4 - var(--rad-spacing-sm)*2/4*4 - var(--rad-spacing-sm)*3/4*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-sm)*1);
        width: calc(min(100vw, 1920px)/8*4 - var(--rad-spacing-md)*2/8*4 - var(--rad-spacing-sm)*7/8*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*1);
        width: calc(min(100vw, 1920px)/12*4 - var(--rad-spacing-md)*2/12*4 - var(--rad-spacing-sm)*11/12*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*1)
    }

    .rad-quote-headline {
        margin-bottom: 2rem
    }

    .rad-quote-content {
        max-width: 45.75rem;
        width: calc(min(100vw, 1920px)/4*7 - var(--rad-spacing-sm)*2/4*7 - var(--rad-spacing-sm)*3/4*7 + var(--rad-spacing-sm)*6 + var(--rad-spacing-sm)*2);
        width: calc(min(100vw, 1920px)/8*7 - var(--rad-spacing-md)*2/8*7 - var(--rad-spacing-sm)*7/8*7 + var(--rad-spacing-sm)*6 + var(--rad-spacing-md)*2);
        width: calc(min(100vw, 1920px)/12*7 - var(--rad-spacing-md)*2/12*7 - var(--rad-spacing-sm)*11/12*7 + var(--rad-spacing-sm)*6 + var(--rad-spacing-md)*2)
    }

    .rad-quote-left .rad-quote-content,.rad-quote-right .rad-quote-content {
        min-width: 52.625rem
    }
}

@media(min-width: 1441px) {
    .rad-quote-left .rad-quote-content,.rad-quote-right .rad-quote-content {
        min-width:calc(52.625rem + 58.59429vw - 844.34377px)
    }
}

@media(min-width: 1920px) {
    .rad-quote-left .rad-quote-content,.rad-quote-right .rad-quote-content {
        min-width:70.1666666667rem
    }
}

@media(min-width: 1441px) {
    .rad-quote-container {
        padding-bottom:80px;
        padding-right: 80px;
        padding-top: 80px;
        padding-top: calc(-0.22269px + 5.56715vw)
    }
}

@media(min-width: 1920px) {
    .rad-quote-container {
        padding-top:106.6666666667px
    }
}

@media(min-width: 1441px) {
    .rad-quote-container {
        padding-right:calc(-0.22269px + 5.56715vw)
    }
}

@media(min-width: 1920px) {
    .rad-quote-container {
        padding-right:106.6666666667px
    }
}

@media(min-width: 1441px) {
    .rad-quote-container {
        padding-bottom:calc(-0.22269px + 5.56715vw)
    }
}

@media(min-width: 1920px) {
    .rad-quote-container {
        padding-bottom:106.6666666667px
    }
}

@media(min-width: 1441px) {
    .rad-quote-content {
        max-width:876px;
        max-width: calc(-2.43841px + 60.96033vw);
        padding-left: 143px
    }
}

@media(min-width: 1920px) {
    .rad-quote-content {
        max-width:1168px
    }
}

@media(min-width: 1441px) {
    .rad-quote-content {
        padding-left:calc(-0.39805px + 9.95129vw)
    }
}

@media(min-width: 1920px) {
    .rad-quote-content {
        padding-left:190.6666666667px
    }
}

.rad-quote--no-marks .rad-quote-headline .rad-quote-text:after,.rad-quote--no-marks .rad-quote-headline .rad-quote-text:before {
    display: none
}

.rad-awards-card {
    --rad-award-card-height: 378px;
    --rad-award-card-width: 252px;
    color: #fff;
    height: var(--rad-award-card-height);
    overflow: hidden;
    position: relative;
    width: var(--rad-award-card-width)
}

@media(min-width: 600px) {
    .rad-awards-card {
        --rad-award-card-height:273px;
        --rad-award-card-width: 410px
    }
}

@media(min-width: 1024px) {
    .rad-awards-card {
        --rad-award-card-height:343px;
        --rad-award-card-width: 515px
    }
}

@media(min-width: 1441px) {
    .rad-awards-card {
        --rad-award-card-height:calc(-0.95477px + 23.86917vw)
    }
}

@media(min-width: 1920px) {
    .rad-awards-card {
        --rad-award-card-height:457.3333333333px
    }
}

@media(min-width: 1441px) {
    .rad-awards-card {
        --rad-award-card-width:calc(-1.43354px + 35.83855vw)
    }
}

@media(min-width: 1920px) {
    .rad-awards-card {
        --rad-award-card-width:686.6666666667px
    }
}

.rad-awards-card--purple,.rad-awards-card--purple-override .rad-awards-card {
    background-color: #460073
}

.rad-awards-card--purple .rad-awards-card__motion-bg,.rad-awards-card--purple-override .rad-awards-card .rad-awards-card__motion-bg {
    opacity: .3
}

.rad-awards-card--blue,.rad-awards-card--blue-override .rad-awards-card {
    background-color: #0041f0
}

.rad-awards-card--blue .rad-awards-card__motion-bg,.rad-awards-card--blue-override .rad-awards-card .rad-awards-card__motion-bg {
    opacity: .3
}

.rad-awards-card--red,.rad-awards-card--red-override .rad-awards-card {
    background-color: #e2062e
}

.rad-awards-card--red .rad-awards-card__motion-bg,.rad-awards-card--red-override .rad-awards-card .rad-awards-card__motion-bg {
    opacity: .5
}

.rad-awards-card__toggle {
    clip: rect(0 0 0 0);
    -webkit-clip-path: inset(50%);
    clip-path: inset(50%);
    height: 1px;
    white-space: nowrap;
    width: 1px
}

.rad-awards-card__motion-bg,.rad-awards-card__toggle {
    height: 100%;
    overflow: hidden;
    position: absolute;
    width: 100%
}

.rad-awards-card__motion-bg {
    pointer-events: none;
    -webkit-transition-timing-function: linear;
    transition-timing-function: linear
}

@media(min-width: 600px) {
    .rad-awards-card__motion-bg {
        height:105%;
        margin-left: -3%;
        margin-top: -1%;
        width: 105%
    }
}

.rad-awards-card__motion-bg .rad-awards-card__lp {
    display: block;
    height: 378px;
    margin-left: -157px;
    width: 567px
}

@media(min-width: 600px) {
    .rad-awards-card__motion-bg .rad-awards-card__lp {
        height:100%;
        margin-left: 0;
        width: 100%
    }
}

.rad-awards-card__cover {
    height: 100%;
    position: relative
}

.rad-awards-card__title {
    bottom: 0;
    padding: 24px;
    position: absolute
}

@media(min-width: 1024px) {
    .rad-awards-card__title {
        padding:32px
    }
}

.rad-awards-card__detail {
    bottom: 0;
    position: absolute;
    top: 0;
    visibility: hidden;
    width: 100%
}

.rad-awards-card__description {
    padding: 24px 24px 16px;
    -webkit-transform: translateY(460px);
    transform: translateY(460px)
}

@media(min-width: 1024px) {
    .rad-awards-card__description {
        padding:32px 32px 16px
    }
}

.rad-awards-card__subheader {
    margin-bottom: 1rem
}

.rad-awards-card__rte ul {
    list-style: none;
    margin-left: .25rem;
    padding-left: 0
}

.rad-awards-card__rte ul li {
    padding-left: 1.875rem;
    position: relative
}

.rad-awards-card__rte ul li:before {
    background-color: #fff;
    content: "";
    display: inline-block;
    height: .375rem;
    left: 0;
    position: absolute;
    text-align: center;
    top: 10px;
    width: .375rem
}

@media(min-width: 1441px) {
    .rad-awards-card__rte ul li:before {
        top:calc(-0.02784px + 0.69589vw)
    }
}

@media(min-width: 1920px) {
    .rad-awards-card__rte ul li:before {
        top:13.3333333333px
    }
}

@media(min-width: 600px) {
    .rad-awards-card__rte ul li:before {
        height:.5rem;
        width: .5rem
    }
}

.rad-awards-card .rad-button {
    bottom: 24px;
    position: absolute;
    right: 24px;
    -webkit-transform: translateY(460px);
    transform: translateY(460px)
}

.rad-awards-card:focus-within {
    outline: 2px solid #a100ff;
    outline-offset: 8px
}

.rad-awards-card:not(.rad-awards-card--expanded) .rad-awards-card__cover,.rad-awards-card:not(.rad-awards-card--expanded) .rad-awards-card__toggle,.rad-awards-card:not(.rad-awards-card--expanded):focus-within .rad-awards-card__cover,.rad-awards-card:not(.rad-awards-card--expanded):focus-within .rad-awards-card__toggle {
    border: 1px solid transparent;
    bottom: 0;
    rotate: none;
    scale: none;
    top: 0;
    -webkit-transform: none;
    transform: none;
    translate: none
}

.rad-awards-card:not(.rad-awards-card--expanded) .rad-awards-card__title,.rad-awards-card:not(.rad-awards-card--expanded):focus-within .rad-awards-card__title {
    rotate: none;
    scale: none;
    -webkit-transform: translate(0);
    transform: translate(0);
    translate: none
}

.rad-awards {
    padding-left: var(--rad-spacing-sm);
    padding-right: var(--rad-spacing-sm);
    position: relative
}

@media(min-width: 600px) {
    .rad-awards {
        padding-left:var(--rad-spacing-md);
        padding-right: var(--rad-spacing-md)
    }
}

.rad-component--nested .rad-awards {
    padding-left: 0;
    padding-right: 0
}

.rad-awards__stage {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 100svh;
    position: sticky;
    top: 0;
    width: calc(100% - var(--rad-spacing-sm)*3/4*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-sm)*0)
}

@media(min-width: 600px) {
    .rad-awards__stage {
        width:calc(50% - var(--rad-spacing-sm)*7/8*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-awards__stage {
        width:calc(33.33333% - var(--rad-spacing-sm)*11/12*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 600px) {
    .rad-awards__stage {
        width:calc(200% - var(--rad-spacing-sm)*3/4*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-sm)*0);
        width: calc(100% - var(--rad-spacing-sm)*7/8*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-awards__stage {
        width:calc(66.66667% - var(--rad-spacing-sm)*11/12*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0);
        width: calc(300% - var(--rad-spacing-sm)*3/4*12 + var(--rad-spacing-sm)*11 + var(--rad-spacing-sm)*0);
        width: calc(150% - var(--rad-spacing-sm)*7/8*12 + var(--rad-spacing-sm)*11 + var(--rad-spacing-md)*0);
        width: calc(100% - var(--rad-spacing-sm)*11/12*12 + var(--rad-spacing-sm)*11 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1441px) {
    .rad-awards__stage {
        margin-left:auto;
        margin-right: auto;
        width: calc(250% - var(--rad-spacing-sm)*3/4*10 + var(--rad-spacing-sm)*9 + var(--rad-spacing-sm)*0);
        width: calc(125% - var(--rad-spacing-sm)*7/8*10 + var(--rad-spacing-sm)*9 + var(--rad-spacing-md)*0);
        width: calc(83.33333% - var(--rad-spacing-sm)*11/12*10 + var(--rad-spacing-sm)*9 + var(--rad-spacing-md)*0)
    }
}

.rad-awards__headline {
    color: #fff;
    font-size: 3rem;
    font-weight: 500;
    letter-spacing: -0.03em;
    line-height: 1.15;
    text-align: center;
    width: 100%
}

.rad-awards__headline:lang(ja-JP),.rad-awards__headline:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-awards__headline {
        font-size:5rem;
        line-height: 1.1
    }
}

@media(min-width: 1024px) {
    .rad-awards__headline {
        font-size:6.25rem
    }
}

@media(min-width: 1441px) {
    .rad-awards__headline {
        font-size:calc(6.25rem + 6.95894vw - 100.27836px)
    }
}

@media(min-width: 1920px) {
    .rad-awards__headline {
        font-size:8.3333333333rem
    }
}

.rad-awards-cards__presenter {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-bottom: 100svh;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    width: calc(100% - var(--rad-spacing-sm)*2)
}

@media(min-width: 600px) {
    .rad-awards-cards__presenter {
        width:calc(100% - var(--rad-spacing-md)*2)
    }
}

.rad-awards .cmp-floating-awards-card:nth-child(2) {
    margin: 80px 0 80px auto
}

.rad-awards .cmp-floating-awards-card:nth-child(3) {
    margin-left: auto;
    margin-right: auto
}

.rad-awards .rad-awards-card .rad-button {
    bottom: 1rem
}

@media(min-width: 1024px) {
    .rad-awards .rad-awards-card .rad-button {
        bottom:1.5rem
    }
}

.rad-awards .rad-awards-card__title {
    font-family: GT Sectra Fine,Palatino;
    font-size: 1.25rem;
    font-weight: 300;
    line-height: 1.25
}

@media(min-width: 600px) {
    .rad-awards .rad-awards-card__title {
        font-size:1.5rem;
        line-height: 1.25
    }
}

@media(min-width: 1024px) {
    .rad-awards .rad-awards-card__title {
        font-size:1.75rem;
        line-height: 1.25
    }
}

@media(min-width: 1441px) {
    .rad-awards .rad-awards-card__title {
        font-size:calc(1.75rem + 1.9485vw - 28.07794px)
    }
}

@media(min-width: 1920px) {
    .rad-awards .rad-awards-card__title {
        font-size:2.3333333333rem
    }
}

.rad-awards .rad-awards-card__subheader {
    font-size: .875rem;
    font-weight: 500;
    letter-spacing: -0.005em;
    line-height: 1.5;
    text-underline-offset: .25rem
}

.rad-awards .rad-awards-card__subheader:lang(ja-JP),.rad-awards .rad-awards-card__subheader:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-awards .rad-awards-card__subheader {
        font-size:1rem
    }
}

@media(min-width: 1441px) {
    .rad-awards .rad-awards-card__subheader {
        font-size:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-awards .rad-awards-card__subheader {
        font-size:1.3333333333rem
    }
}

.rad-awards .rad-awards-card__rte {
    font-size: .875rem;
    font-weight: 500;
    font-weight: 400;
    letter-spacing: -0.005em;
    line-height: 1.5;
    text-underline-offset: .25rem
}

.rad-awards .rad-awards-card__rte:lang(ja-JP),.rad-awards .rad-awards-card__rte:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-awards .rad-awards-card__rte {
        font-size:1rem
    }
}

@media(min-width: 1441px) {
    .rad-awards .rad-awards-card__rte {
        font-size:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-awards .rad-awards-card__rte {
        font-size:1.3333333333rem
    }
}

.rad-awards .rad-awards-card__rte:lang(ja-JP),.rad-awards .rad-awards-card__rte:lang(zh-CN) {
    font-weight: 400
}

.rad-awards-grid {
    padding-left: var(--rad-spacing-sm);
    padding-right: var(--rad-spacing-sm)
}

@media(min-width: 600px) {
    .rad-awards-grid {
        padding-left:var(--rad-spacing-md);
        padding-right: var(--rad-spacing-md)
    }
}

.rad-component--nested .rad-awards-grid {
    padding-left: 0;
    padding-right: 0
}

.rad-awards-grid__heading-and-cta {
    margin-bottom: 2.5rem
}

@media(min-width: 600px) {
    .rad-awards-grid__heading-and-cta {
        -webkit-box-pack:justify;
        -ms-flex-pack: justify;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        justify-content: space-between;
        margin-bottom: 2.5rem
    }
}

@media(min-width: 1024px) {
    .rad-awards-grid__heading-and-cta {
        margin-bottom:3rem
    }
}

@media(min-width: 1441px) {
    .rad-awards-grid__heading-and-cta {
        margin-bottom:calc(3rem + 3.34029vw - 48.13361px)
    }
}

@media(min-width: 1920px) {
    .rad-awards-grid__heading-and-cta {
        margin-bottom:4rem
    }
}

.rad-awards-grid__heading {
    font-size: 1.75rem;
    font-weight: 500;
    letter-spacing: -0.03em;
    line-height: 1.3
}

.rad-awards-grid__heading:lang(ja-JP),.rad-awards-grid__heading:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-awards-grid__heading {
        font-size:2.5rem;
        line-height: 1.2
    }
}

@media(min-width: 1024px) {
    .rad-awards-grid__heading {
        font-size:3rem;
        line-height: 1.2
    }
}

@media(min-width: 1441px) {
    .rad-awards-grid__heading {
        font-size:calc(3rem + 3.34029vw - 48.13361px)
    }
}

@media(min-width: 1920px) {
    .rad-awards-grid__heading {
        font-size:4rem
    }
}

@media(min-width: 600px) {
    .rad-awards-grid__heading {
        width:calc(125% - var(--rad-spacing-sm)*3/4*5 + var(--rad-spacing-sm)*4 + var(--rad-spacing-sm)*0);
        width: calc(62.5% - var(--rad-spacing-sm)*7/8*5 + var(--rad-spacing-sm)*4 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-awards-grid__heading {
        width:calc(41.66667% - var(--rad-spacing-sm)*11/12*5 + var(--rad-spacing-sm)*4 + var(--rad-spacing-md)*0);
        width: calc(200% - var(--rad-spacing-sm)*3/4*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-sm)*0);
        width: calc(100% - var(--rad-spacing-sm)*7/8*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0);
        width: calc(66.66667% - var(--rad-spacing-sm)*11/12*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0)
    }
}

.rad-awards-grid__cta {
    margin-top: .25rem
}

@media(min-width: 1024px) {
    .rad-awards-grid__cta {
        margin-top:0.75rem
    }
}

@media(min-width: 1441px) {
    .rad-awards-grid__cta {
        margin-top:calc(0.75rem + 0.83507vw - 12.0334px)
    }
}

@media(min-width: 1920px) {
    .rad-awards-grid__cta {
        margin-top:1rem
    }
}

@media(min-width: 600px) {
    .rad-awards-grid__grid {
        -webkit-column-gap:1.5rem;
        -moz-column-gap: 1.5rem;
        column-gap: 1.5rem;
        display: grid;
        grid-template-columns: 1fr 1fr;
        row-gap: 1.5rem
    }
}

@media(min-width: 1024px) {
    .rad-awards-grid__grid {
        -webkit-column-gap:2rem;
        -moz-column-gap: 2rem;
        column-gap: 2rem;
        grid-template-columns: 1fr 1fr 1fr;
        row-gap: 2rem
    }
}

@media(min-width: 1441px) {
    .rad-awards-grid__grid {
        -webkit-column-gap:calc(2rem + 2.22686vw - 32.08907px);
        -moz-column-gap: calc(2rem + 2.22686vw - 32.08907px);
        column-gap: calc(2rem + 2.22686vw - 32.08907px)
    }
}

@media(min-width: 1920px) {
    .rad-awards-grid__grid {
        -webkit-column-gap:2.6666666667rem;
        -moz-column-gap: 2.6666666667rem;
        column-gap: 2.6666666667rem
    }
}

@media(min-width: 1441px) {
    .rad-awards-grid__grid {
        row-gap:calc(2rem + 2.22686vw - 32.08907px)
    }
}

@media(min-width: 1920px) {
    .rad-awards-grid__grid {
        row-gap:2.6666666667rem
    }
}

.rad-awards-grid .rad-awards-card {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    height: 13.6875rem;
    width: 100%
}

@media(max-width: 599px) {
    .rad-awards-grid .rad-awards-card {
        margin-top:1.5rem
    }
}

@media(min-width: 600px) {
    .rad-awards-grid .rad-awards-card {
        height:13.5rem
    }
}

@media(min-width: 1441px) {
    .rad-awards-grid .rad-awards-card {
        height:16.875rem;
        height: calc(16.875rem + 18.78914vw - 270.75157px)
    }
}

@media(min-width: 1920px) {
    .rad-awards-grid .rad-awards-card {
        height:22.5rem
    }
}

.rad-awards-grid .rad-awards-card__motion-bg lottie-player {
    margin-left: 0
}

.rad-awards-grid .rad-awards-grid__grid .rad-awards-card__title {
    font-family: GT Sectra Fine,Palatino;
    font-size: 1.125rem;
    font-weight: 300;
    line-height: 1.3;
    padding: 1.5rem
}

@media(min-width: 1441px) {
    .rad-awards-grid .rad-awards-grid__grid .rad-awards-card__title {
        padding:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-awards-grid .rad-awards-grid__grid .rad-awards-card__title {
        padding:2rem
    }
}

@media(min-width: 600px) {
    .rad-awards-grid .rad-awards-grid__grid .rad-awards-card__title {
        font-size:1.25rem;
        line-height: 1.625rem
    }
}

@media(min-width: 1024px) {
    .rad-awards-grid .rad-awards-grid__grid .rad-awards-card__title {
        font-size:1.25rem;
        line-height: 1.625rem
    }
}

@media(min-width: 1440px) {
    .rad-awards-grid .rad-awards-grid__grid .rad-awards-card__title {
        font-size:1.5rem;
        line-height: 1.875rem
    }
}

.rad-awards-grid .rad-awards-card__subheader {
    font-size: .75rem;
    font-weight: 500;
    letter-spacing: -0.005em;
    line-height: 1.5;
    text-underline-offset: .25rem
}

.rad-awards-grid .rad-awards-card__subheader:lang(ja-JP),.rad-awards-grid .rad-awards-card__subheader:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-awards-grid .rad-awards-card__subheader {
        font-size:0.875rem
    }
}

@media(min-width: 1441px) {
    .rad-awards-grid .rad-awards-card__subheader {
        font-size:calc(0.875rem + 0.97425vw - 14.03897px)
    }
}

@media(min-width: 1920px) {
    .rad-awards-grid .rad-awards-card__subheader {
        font-size:1.1666666667rem
    }
}

@media(min-width: 1440px) {
    .rad-awards-grid .rad-awards-card__subheader {
        font-size:.875rem;
        font-weight: 500;
        letter-spacing: -0.005em;
        line-height: 1.5;
        text-underline-offset: .25rem
    }

    .rad-awards-grid .rad-awards-card__subheader:lang(ja-JP),.rad-awards-grid .rad-awards-card__subheader:lang(zh-CN) {
        font-weight: 700
    }

    .rad-awards-grid .rad-awards-card__subheader {
        font-size: 1rem
    }
}

@media(min-width: 1441px) {
    .rad-awards-grid .rad-awards-card__subheader {
        font-size:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-awards-grid .rad-awards-card__subheader {
        font-size:1.3333333333rem
    }
}

.rad-awards-grid .rad-awards-card__description {
    padding-bottom: .75rem
}

@media(min-width: 1024px) {
    .rad-awards-grid .rad-awards-card__description {
        padding:1.5rem 1.5rem .75rem
    }
}

@media(min-width: 1440px) {
    .rad-awards-grid .rad-awards-card__description {
        padding:1.5rem 1.5rem 0.75rem
    }
}

@media(min-width: 1441px) {
    .rad-awards-grid .rad-awards-card__description {
        padding-top:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-awards-grid .rad-awards-card__description {
        padding-top:2rem
    }
}

@media(min-width: 1441px) {
    .rad-awards-grid .rad-awards-card__description {
        padding-right:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-awards-grid .rad-awards-card__description {
        padding-right:2rem
    }
}

@media(min-width: 1441px) {
    .rad-awards-grid .rad-awards-card__description {
        padding-left:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-awards-grid .rad-awards-card__description {
        padding-left:2rem
    }
}

@media(min-width: 1441px) {
    .rad-awards-grid .rad-awards-card__description {
        padding-bottom:calc(0.75rem + 0.83507vw - 12.0334px)
    }
}

@media(min-width: 1920px) {
    .rad-awards-grid .rad-awards-card__description {
        padding-bottom:1rem
    }
}

.rad-awards-grid .rad-awards-card__rte {
    font-size: .75rem;
    font-weight: 500;
    font-weight: 400;
    letter-spacing: -0.005em;
    line-height: 1.5;
    text-underline-offset: .25rem
}

.rad-awards-grid .rad-awards-card__rte:lang(ja-JP),.rad-awards-grid .rad-awards-card__rte:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-awards-grid .rad-awards-card__rte {
        font-size:0.875rem
    }
}

@media(min-width: 1441px) {
    .rad-awards-grid .rad-awards-card__rte {
        font-size:calc(0.875rem + 0.97425vw - 14.03897px)
    }
}

@media(min-width: 1920px) {
    .rad-awards-grid .rad-awards-card__rte {
        font-size:1.1666666667rem
    }
}

.rad-awards-grid .rad-awards-card__rte:lang(ja-JP),.rad-awards-grid .rad-awards-card__rte:lang(zh-CN) {
    font-weight: 400
}

@media(min-width: 1440px) {
    .rad-awards-grid .rad-awards-card__rte {
        font-size:.875rem;
        font-weight: 500;
        font-weight: 400;
        letter-spacing: -0.005em;
        line-height: 1.5;
        text-underline-offset: .25rem
    }

    .rad-awards-grid .rad-awards-card__rte:lang(ja-JP),.rad-awards-grid .rad-awards-card__rte:lang(zh-CN) {
        font-weight: 700
    }

    .rad-awards-grid .rad-awards-card__rte {
        font-size: 1rem
    }
}

@media(min-width: 1441px) {
    .rad-awards-grid .rad-awards-card__rte {
        font-size:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-awards-grid .rad-awards-card__rte {
        font-size:1.3333333333rem
    }
}

@media(min-width: 1440px) {
    .rad-awards-grid .rad-awards-card__rte:lang(ja-JP),.rad-awards-grid .rad-awards-card__rte:lang(zh-CN) {
        font-weight:400
    }
}

.rad-awards-grid .rad-awards-card .rad-button {
    bottom: 0.75rem;
    right: 1rem
}

@media(min-width: 1441px) {
    .rad-awards-grid .rad-awards-card .rad-button {
        bottom:calc(0.75rem + 0.83507vw - 12.0334px)
    }
}

@media(min-width: 1920px) {
    .rad-awards-grid .rad-awards-card .rad-button {
        bottom:1rem
    }
}

@media(min-width: 1441px) {
    .rad-awards-grid .rad-awards-card .rad-button {
        right:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-awards-grid .rad-awards-card .rad-button {
        right:1.3333333333rem
    }
}

.rad-frontpage-hero {
    aspect-ratio: 360/250;
    margin-bottom: 0;
    max-width: 100vw
}

@media(min-width: 600px) {
    .rad-frontpage-hero {
        aspect-ratio:770/360
    }
}

@media(min-width: 1024px) {
    .rad-frontpage-hero {
        aspect-ratio:1170/260
    }
}

@media(min-width: 1920px) {
    .rad-frontpage-hero {
        margin-left:auto;
        margin-right: auto;
        max-width: 1920px
    }
}

.rad-frontpage-hero__wrapper-link {
    display: block
}

.rad-frontpage-hero__heading {
    clip: rect(0 0 0 0);
    -webkit-clip-path: inset(50%);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px
}

.rad-frontpage-hero__lottie-wrapper {
    margin: 0 auto
}

.rad-text-block-with-icon {
    padding-left: var(--rad-spacing-sm);
    padding-right: var(--rad-spacing-sm)
}

@media(min-width: 600px) {
    .rad-text-block-with-icon {
        padding-left:var(--rad-spacing-md);
        padding-right: var(--rad-spacing-md)
    }
}

.rad-component--nested .rad-text-block-with-icon {
    padding-left: 0;
    padding-right: 0
}

.rad-text-block-with-icon__headline {
    font-size: 1.375rem;
    font-weight: 500;
    letter-spacing: -0.02em;
    line-height: 1.25;
    margin-bottom: 1.5rem;
    width: calc(100% - var(--rad-spacing-sm)*3/4*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-sm)*0)
}

.rad-text-block-with-icon__headline:lang(ja-JP),.rad-text-block-with-icon__headline:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-text-block-with-icon__headline {
        font-size:1.75rem;
        line-height: 1.3
    }
}

@media(min-width: 1024px) {
    .rad-text-block-with-icon__headline {
        font-size:2rem;
        line-height: 1.2
    }
}

@media(min-width: 1441px) {
    .rad-text-block-with-icon__headline {
        font-size:calc(2rem + 2.22686vw - 32.08907px)
    }
}

@media(min-width: 1920px) {
    .rad-text-block-with-icon__headline {
        font-size:2.6666666667rem
    }
}

@media(min-width: 600px) {
    .rad-text-block-with-icon__headline {
        width:calc(50% - var(--rad-spacing-sm)*7/8*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-text-block-with-icon__headline {
        width:calc(33.33333% - var(--rad-spacing-sm)*11/12*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 600px) {
    .rad-text-block-with-icon__headline {
        margin-bottom:2.5rem;
        width: calc(150% - var(--rad-spacing-sm)*3/4*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-sm)*0);
        width: calc(75% - var(--rad-spacing-sm)*7/8*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-text-block-with-icon__headline {
        margin-bottom:3rem;
        width: calc(50% - var(--rad-spacing-sm)*11/12*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0);
        width: calc(200% - var(--rad-spacing-sm)*3/4*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-sm)*0);
        width: calc(100% - var(--rad-spacing-sm)*7/8*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0);
        width: calc(66.66667% - var(--rad-spacing-sm)*11/12*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1441px) {
    .rad-text-block-with-icon__headline {
        margin-bottom:calc(3rem + 3.34029vw - 48.13361px)
    }
}

@media(min-width: 1920px) {
    .rad-text-block-with-icon__headline {
        margin-bottom:4rem
    }
}

.rad-text-block-with-icon__block-container {
    -webkit-column-gap: var(--rad-spacing-sm);
    -moz-column-gap: var(--rad-spacing-sm);
    column-gap: var(--rad-spacing-sm);
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 2rem
}

@media(min-width: 600px) {
    .rad-text-block-with-icon__block-container {
        grid-template-columns:repeat(2,1fr);
        row-gap: 4rem
    }
}

@media(min-width: 1024px) {
    .rad-text-block-with-icon__block-container {
        grid-template-columns:repeat(3,1fr);
        row-gap: 3rem;
        width: calc(225% - var(--rad-spacing-sm)*3/4*9 + var(--rad-spacing-sm)*8 + var(--rad-spacing-sm)*0);
        width: calc(112.5% - var(--rad-spacing-sm)*7/8*9 + var(--rad-spacing-sm)*8 + var(--rad-spacing-md)*0);
        width: calc(75% - var(--rad-spacing-sm)*11/12*9 + var(--rad-spacing-sm)*8 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1441px) {
    .rad-text-block-with-icon__block-container {
        row-gap:calc(3rem + 3.34029vw - 48.13361px)
    }
}

@media(min-width: 1920px) {
    .rad-text-block-with-icon__block-container {
        row-gap:4rem
    }
}

.rad-text-block-with-icon__block-pictogram img {
    display: block;
    height: 3rem;
    margin-bottom: 1rem;
    width: 3rem
}

@media(min-width: 600px) {
    .rad-text-block-with-icon__block-pictogram img {
        margin-bottom:1.5rem
    }
}

@media(min-width: 1441) {
    .rad-text-block-with-icon__block-pictogram img {
        height:3rem;
        margin-bottom: 1.5rem;
        width: 3rem
    }
}

@media(min-width: 1441px) {
    .rad-text-block-with-icon__block-pictogram img {
        margin-bottom:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-text-block-with-icon__block-pictogram img {
        margin-bottom:2rem
    }
}

@media(min-width: 1441px) {
    .rad-text-block-with-icon__block-pictogram img {
        width:calc(3rem + 3.34029vw - 48.13361px)
    }
}

@media(min-width: 1920px) {
    .rad-text-block-with-icon__block-pictogram img {
        width:4rem
    }
}

@media(min-width: 1441px) {
    .rad-text-block-with-icon__block-pictogram img {
        height:calc(3rem + 3.34029vw - 48.13361px)
    }
}

@media(min-width: 1920px) {
    .rad-text-block-with-icon__block-pictogram img {
        height:4rem
    }
}

.rad-text-block-with-icon__block-title {
    font-size: 1.125rem;
    font-weight: 500;
    letter-spacing: -0.02em;
    line-height: 1.4;
    margin-bottom: .5rem
}

.rad-text-block-with-icon__block-title:lang(ja-JP),.rad-text-block-with-icon__block-title:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-text-block-with-icon__block-title {
        font-size:1.25rem;
        line-height: 1.4
    }
}

@media(min-width: 1024px) {
    .rad-text-block-with-icon__block-title {
        font-size:1.5rem;
        line-height: 1.3
    }
}

@media(min-width: 1441px) {
    .rad-text-block-with-icon__block-title {
        font-size:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-text-block-with-icon__block-title {
        font-size:2rem
    }
}

@media(min-width: 600px) {
    .rad-text-block-with-icon__block-title {
        margin-bottom:.75rem
    }
}

@media(min-width: 1024px) {
    .rad-text-block-with-icon__block-title {
        margin-bottom:1rem
    }
}

@media(min-width: 1441px) {
    .rad-text-block-with-icon__block-title {
        margin-bottom:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-text-block-with-icon__block-title {
        margin-bottom:1.3333333333rem
    }
}

.rad-text-block-with-icon__block-body {
    font-size: .875rem;
    font-weight: 500;
    font-weight: 400;
    letter-spacing: -0.005em;
    line-height: 1.5;
    text-underline-offset: .25rem
}

.rad-text-block-with-icon__block-body:lang(ja-JP),.rad-text-block-with-icon__block-body:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-text-block-with-icon__block-body {
        font-size:1rem
    }
}

@media(min-width: 1441px) {
    .rad-text-block-with-icon__block-body {
        font-size:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-text-block-with-icon__block-body {
        font-size:1.3333333333rem
    }
}

.rad-text-block-with-icon__block-body:lang(ja-JP),.rad-text-block-with-icon__block-body:lang(zh-CN) {
    font-weight: 400
}

.rad-text-block-with-icon__block .rad-button {
    margin-top: .25rem
}

@media(min-width: 1024px) {
    .rad-text-block-with-icon__block .rad-button {
        margin-top:0.75rem
    }
}

@media(min-width: 1441px) {
    .rad-text-block-with-icon__block .rad-button {
        margin-top:calc(0.75rem + 0.83507vw - 12.0334px)
    }
}

@media(min-width: 1920px) {
    .rad-text-block-with-icon__block .rad-button {
        margin-top:1rem
    }
}

@media(min-width: 1024px) {
    .rad-text-block-with-icon--four-blocks .rad-text-block-with-icon__block-container {
        grid-template-columns:repeat(4,1fr);
        width: calc(300% - var(--rad-spacing-sm)*3/4*12 + var(--rad-spacing-sm)*11 + var(--rad-spacing-sm)*0);
        width: calc(150% - var(--rad-spacing-sm)*7/8*12 + var(--rad-spacing-sm)*11 + var(--rad-spacing-md)*0);
        width: calc(100% - var(--rad-spacing-sm)*11/12*12 + var(--rad-spacing-sm)*11 + var(--rad-spacing-md)*0)
    }
}

.rad-component__title--large-text .rad-text-block-with-icon__headline {
    font-size: 1.75rem;
    font-weight: 500;
    letter-spacing: -0.03em;
    line-height: 1.3
}

.rad-component__title--large-text .rad-text-block-with-icon__headline:lang(ja-JP),.rad-component__title--large-text .rad-text-block-with-icon__headline:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-component__title--large-text .rad-text-block-with-icon__headline {
        font-size:2.5rem;
        line-height: 1.2
    }
}

@media(min-width: 1024px) {
    .rad-component__title--large-text .rad-text-block-with-icon__headline {
        font-size:3rem;
        line-height: 1.2
    }
}

@media(min-width: 1441px) {
    .rad-component__title--large-text .rad-text-block-with-icon__headline {
        font-size:calc(3rem + 3.34029vw - 48.13361px)
    }
}

@media(min-width: 1920px) {
    .rad-component__title--large-text .rad-text-block-with-icon__headline {
        font-size:4rem
    }
}

.rad-hero-image {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: var(--rad-spacing-sm);
    padding-right: var(--rad-spacing-sm)
}

@media(min-width: 600px) {
    .rad-hero-image {
        padding-left:var(--rad-spacing-md);
        padding-right: var(--rad-spacing-md)
    }
}

.rad-component--nested .rad-hero-image {
    padding-left: 0;
    padding-right: 0
}

.rad-hero-image .cmp-video__video {
    aspect-ratio: 3/2;
    display: block;
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%
}

.rad-hero-image__btn {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 1rem;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content
}

@media(min-width: 600px) {
    .rad-hero-image__btn {
        -webkit-box-orient:horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        gap: 1.5rem;
        width: auto
    }
}

@media(min-width: 1024px) {
    .rad-hero-image__btn {
        gap:2rem
    }
}

@media(min-width: 1441px) {
    .rad-hero-image__btn {
        gap:calc(2rem + 2.22686vw - 32.08907px)
    }
}

@media(min-width: 1920px) {
    .rad-hero-image__btn {
        gap:2.6666666667rem
    }
}

.rad-hero-image__btn .rad-button {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
    width: 100%
}

@media(min-width: 600px) {
    .rad-hero-image__btn .rad-button {
        width:auto
    }
}

.rad-hero-image__content,.xfradpage .rad-header .rad-header__text-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-top: 2.5rem;
    row-gap: 1rem
}

.rad-hero-image__photo,.rad-hero-image__video {
    display: block;
    height: auto;
    margin-left: calc(var(--rad-spacing-sm)*-1);
    width: 258px
}

.rad-hero-image__headline,.xfradpage .cmp-title__text,.xfradpage .rad-header.alignment-center .rad-header__headline,.xfradpage .rad-header.alignment-left .rad-header__headline {
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: -0.03em;
    line-height: 1.25;
    word-break: break-word
}

.rad-hero-image__headline:lang(ja-JP),.rad-hero-image__headline:lang(zh-CN),.xfradpage .cmp-title__text:lang(ja-JP),.xfradpage .cmp-title__text:lang(zh-CN),.xfradpage .rad-header.alignment-center .rad-header__headline:lang(ja-JP),.xfradpage .rad-header.alignment-center .rad-header__headline:lang(zh-CN),.xfradpage .rad-header.alignment-left .rad-header__headline:lang(ja-JP),.xfradpage .rad-header.alignment-left .rad-header__headline:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-hero-image__headline,.xfradpage .cmp-title__text,.xfradpage .rad-header.alignment-center .rad-header__headline,.xfradpage .rad-header.alignment-left .rad-header__headline {
        font-size:1.75rem;
        line-height: 1.15
    }
}

@media(min-width: 1441px) {
    .rad-hero-image__headline,.xfradpage .cmp-title__text,.xfradpage .rad-header.alignment-center .rad-header__headline,.xfradpage .rad-header.alignment-left .rad-header__headline {
        font-size:calc(1.75rem + 4.17537vw - 60.16701px)
    }
}

@media(min-width: 1920px) {
    .rad-hero-image__headline,.xfradpage .cmp-title__text,.xfradpage .rad-header.alignment-center .rad-header__headline,.xfradpage .rad-header.alignment-left .rad-header__headline {
        font-size:1rem
    }
}

.rad-hero-image__body,.xfradpage .cmp-text,.xfradpage .rad-header.alignment-center .rad-header__sub-headline,.xfradpage .rad-header.alignment-left .rad-header__sub-headline {
    font-family: GT Sectra Fine,Palatino;
    font-size: 1.125rem;
    font-weight: 300;
    line-height: 1.3;
    word-break: break-word
}

@media(min-width: 600px) {
    .rad-hero-image__body,.xfradpage .cmp-text,.xfradpage .rad-header.alignment-center .rad-header__sub-headline,.xfradpage .rad-header.alignment-left .rad-header__sub-headline {
        font-size:1.25rem;
        line-height: 1.3
    }
}

@media(min-width: 1024px) {
    .rad-hero-image__body,.xfradpage .cmp-text,.xfradpage .rad-header.alignment-center .rad-header__sub-headline,.xfradpage .rad-header.alignment-left .rad-header__sub-headline {
        font-size:1.5rem;
        line-height: 1.25
    }
}

@media(min-width: 1441px) {
    .rad-hero-image__body,.xfradpage .cmp-text,.xfradpage .rad-header.alignment-center .rad-header__sub-headline,.xfradpage .rad-header.alignment-left .rad-header__sub-headline {
        font-size:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-hero-image__body,.xfradpage .cmp-text,.xfradpage .rad-header.alignment-center .rad-header__sub-headline,.xfradpage .rad-header.alignment-left .rad-header__sub-headline {
        font-size:2rem
    }
}

@media(min-width: 600px) {
    .rad-hero-image__photo,.rad-hero-image__video {
        margin-left:calc(var(--rad-spacing-md)*-1)
    }

    .rad-hero-image__content,.xfradpage .rad-header .rad-header__text-container {
        padding-top: 3.75rem;
        row-gap: 1.5rem
    }
}

@media(min-width: 768px) {
    .rad-hero-image__photo,.rad-hero-image__video {
        width:calc(75% - var(--rad-spacing-sm)*3/4*3 + var(--rad-spacing-sm)*2 + var(--rad-spacing-sm)*1);
        width: calc(37.5% - var(--rad-spacing-sm)*7/8*3 + var(--rad-spacing-sm)*2 + var(--rad-spacing-md)*1)
    }
}

@media(min-width: 1024px) {
    .rad-hero-image__photo,.rad-hero-image__video {
        width:calc(25% - var(--rad-spacing-sm)*11/12*3 + var(--rad-spacing-sm)*2 + var(--rad-spacing-md)*1)
    }

    .rad-hero-image {
        -webkit-box-align: end;
        -ms-flex-align: end;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        align-items: flex-end;
        -ms-flex-direction: row;
        flex-direction: row;
        justify-content: space-between
    }

    .rad-hero-image__photo,.rad-hero-image__video {
        width: calc(125% - var(--rad-spacing-sm)*3/4*5 + var(--rad-spacing-sm)*4 + var(--rad-spacing-sm)*1);
        width: calc(62.5% - var(--rad-spacing-sm)*7/8*5 + var(--rad-spacing-sm)*4 + var(--rad-spacing-md)*1);
        width: calc(41.66667% - var(--rad-spacing-sm)*11/12*5 + var(--rad-spacing-sm)*4 + var(--rad-spacing-md)*1)
    }

    .rad-hero-image__content,.xfradpage .rad-header .rad-header__text-container {
      
        padding-top: 0;
        row-gap: 2rem;
        width: calc(150% - var(--rad-spacing-sm)*3/4*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-sm)*0);
        width: calc(75% - var(--rad-spacing-sm)*7/8*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0);
        width: calc(50% - var(--rad-spacing-sm)*11/12*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1441px) {
    .rad-hero-image__content,.xfradpage .rad-header .rad-header__text-container {
        row-gap:calc(2rem + 2.22686vw - 32.08907px)
    }
}

@media(min-width: 1920px) {
    .rad-hero-image__content,.xfradpage .rad-header .rad-header__text-container {
        row-gap:2.6666666667rem
    }
}

.xfradpage .rad-header.alignment-center,.xfradpage .rad-header.alignment-left {
    padding-left: 0;
    padding-right: 0
}

.xfradpage .rad-header.alignment-center .rad-header__wrapper,.xfradpage .rad-header.alignment-left .rad-header__wrapper {
    -webkit-box-align: unset;
    -ms-flex-align: unset;
    align-items: unset
}

.xfradpage .rad-header.alignment-center .rad-header__text-container,.xfradpage .rad-header.alignment-left .rad-header__text-container {
    padding: 0;
    text-align: left;
    width: auto
}

.xfradpage .rad-header.alignment-center .rad-header__headline,.xfradpage .rad-header.alignment-center .rad-header__sub-headline,.xfradpage .rad-header.alignment-left .rad-header__headline,.xfradpage .rad-header.alignment-left .rad-header__sub-headline {
    margin: 0
}

.xfradpage .cmp-text {
    padding-left: 0;
    padding-right: 0;
    padding-top: 16px
}

@media(min-width: 600px) {
    .xfradpage .cmp-text {
        padding-top:24px
    }
}

@media(min-width: 1024px) {
    .xfradpage .cmp-text {
        padding-top:32px
    }
}

.rad-hero-leader--bw .rad-hero-image__photo {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%)
}

.rad-leaders {
    padding-left: var(--rad-spacing-sm);
    padding-right: var(--rad-spacing-sm)
}

@media(min-width: 600px) {
    .rad-leaders {
        padding-left:var(--rad-spacing-md);
        padding-right: var(--rad-spacing-md)
    }
}

.rad-component--nested .rad-leaders {
    padding-left: 0;
    padding-right: 0
}

.rad-leaders__heading-and-cta {
    margin-bottom: 1.5rem
}

@media(min-width: 600px) {
    .rad-leaders__heading-and-cta {
        -webkit-box-align:center;
        -ms-flex-align: center;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        align-items: center;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        justify-content: space-between;
        margin-bottom: 2.5rem
    }
}

@media(min-width: 1024px) {
    .rad-leaders__heading-and-cta {
        margin-bottom:3rem
    }
}

@media(min-width: 1441px) {
    .rad-leaders__heading-and-cta {
        margin-bottom:calc(3rem + 3.34029vw - 48.13361px)
    }
}

@media(min-width: 1920px) {
    .rad-leaders__heading-and-cta {
        margin-bottom:4rem
    }
}

.rad-leaders__heading {
    font-size: 1.75rem;
    font-weight: 500;
    letter-spacing: -0.03em;
    line-height: 1.3
}

.rad-leaders__heading:lang(ja-JP),.rad-leaders__heading:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-leaders__heading {
        font-size:2.5rem;
        line-height: 1.2
    }
}

@media(min-width: 1024px) {
    .rad-leaders__heading {
        font-size:3rem;
        line-height: 1.2
    }
}

@media(min-width: 1441px) {
    .rad-leaders__heading {
        font-size:calc(3rem + 3.34029vw - 48.13361px)
    }
}

@media(min-width: 1920px) {
    .rad-leaders__heading {
        font-size:4rem
    }
}

@media(max-width: 599px) {
    .rad-leaders__heading {
        margin-bottom:.25rem
    }
}

@media(min-width: 600px) {
    .rad-leaders__heading {
        width:calc(125% - var(--rad-spacing-sm)*3/4*5 + var(--rad-spacing-sm)*4 + var(--rad-spacing-sm)*0);
        width: calc(62.5% - var(--rad-spacing-sm)*7/8*5 + var(--rad-spacing-sm)*4 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-leaders__heading {
        width:calc(41.66667% - var(--rad-spacing-sm)*11/12*5 + var(--rad-spacing-sm)*4 + var(--rad-spacing-md)*0);
        width: calc(200% - var(--rad-spacing-sm)*3/4*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-sm)*0);
        width: calc(100% - var(--rad-spacing-sm)*7/8*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0);
        width: calc(66.66667% - var(--rad-spacing-sm)*11/12*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0)
    }
}

.rad-leaders__cta {
    -ms-flex-negative: 0;
    flex-shrink: 0
}

.rad-leaders__leader {
    -webkit-column-gap: var(--rad-spacing-sm);
    -moz-column-gap: var(--rad-spacing-sm);
    column-gap: var(--rad-spacing-sm);
    display: grid;
    grid-template-areas: "image-container leader-name" "image-container role" "image-container linkedin" "image-container x-twitter" "bio bio";
    grid-template-columns: 30% auto;
    grid-template-rows: auto auto 1fr auto;
    width: 100%
}

@media(min-width: 600px) {
    .rad-leaders__leader {
        grid-template-areas:"image-container leader-name" "image-container role" "image-container linkedin" "image-container x-twitter" "image-container bio";
        grid-template-columns: calc(25% - var(--rad-spacing-sm)*7/8*2 + var(--rad-spacing-sm)*1 + var(--rad-spacing-md)*0) 1fr;
        grid-template-rows: auto auto auto 1fr
    }
}

@media(min-width: 1024px) {
    .rad-leaders__leader {
        grid-template-columns:calc(16.66667% - var(--rad-spacing-sm)*11/12*2 + var(--rad-spacing-sm)*1 + var(--rad-spacing-md)*0) 1fr
    }
}

.rad-leaders__leader-image-container {
    grid-area: image-container
}

.rad-leaders__leader-image,.rad-leaders__leader-image-container .cmp-image {
    -webkit-filter: grayscale(1);
    filter: grayscale(1)
}

.rad-leaders__leader-image {
    display: block;
    min-width: 100%
}

.rad-leaders__leader-name {
    font-size: 1rem;
    font-weight: 500;
    font-weight: 400;
    grid-area: leader-name;
    letter-spacing: -0.005em;
    line-height: 1.6;
    text-underline-offset: .25rem
}

.rad-leaders__leader-name:lang(ja-JP),.rad-leaders__leader-name:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-leaders__leader-name {
        font-size:1.125rem
    }
}

@media(min-width: 1024px) {
    .rad-leaders__leader-name {
        font-size:1.25rem
    }
}

@media(min-width: 1441px) {
    .rad-leaders__leader-name {
        font-size:calc(1.25rem + 1.39179vw - 20.05567px)
    }
}

@media(min-width: 1920px) {
    .rad-leaders__leader-name {
        font-size:1.6666666667rem
    }
}

.rad-leaders__leader-name:lang(ja-JP),.rad-leaders__leader-name:lang(zh-CN) {
    font-weight: 400
}

.rad-leaders__leader-role {
    font-size: .75rem;
    font-weight: 500;
    font-weight: 400;
    grid-area: role;
    letter-spacing: -0.005em;
    line-height: 1.5;
    margin-top: 0.5rem;
    text-underline-offset: .25rem
}

.rad-leaders__leader-role:lang(ja-JP),.rad-leaders__leader-role:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-leaders__leader-role {
        font-size:0.875rem
    }
}

@media(min-width: 1441px) {
    .rad-leaders__leader-role {
        font-size:calc(0.875rem + 0.97425vw - 14.03897px)
    }
}

@media(min-width: 1920px) {
    .rad-leaders__leader-role {
        font-size:1.1666666667rem
    }
}

.rad-leaders__leader-role:lang(ja-JP),.rad-leaders__leader-role:lang(zh-CN) {
    font-weight: 400
}

@media(min-width: 1441px) {
    .rad-leaders__leader-role {
        margin-top:calc(0.5rem + 0.55672vw - 8.02227px)
    }
}

@media(min-width: 1920px) {
    .rad-leaders__leader-role {
        margin-top:0.6666666667rem
    }
}

.rad-leaders__leader-icon-container {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    gap: 0.75rem;
    grid-area: linkedin,x-twitter;
    margin-left: -1rem
}

@media(min-width: 1441px) {
    .rad-leaders__leader-icon-container {
        margin-left:calc(-1rem - 1.11343vw + 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-leaders__leader-icon-container {
        margin-left:-1.3333333333rem
    }
}

@media(min-width: 1441px) {
    .rad-leaders__leader-icon-container {
        gap:calc(0.75rem + 0.83507vw - 12.0334px)
    }
}

@media(min-width: 1920px) {
    .rad-leaders__leader-icon-container {
        gap:1rem
    }
}

.rad-leaders__leader-linkedin,.rad-leaders__leader-x-twitter {
    margin-right: -0.9375rem;
    margin-top: -0.5rem
}

@media(min-width: 1441px) {
    .rad-leaders__leader-linkedin,.rad-leaders__leader-x-twitter {
        margin-right:calc(-0.9375rem - 1.04384vw + 15.04175px)
    }
}

@media(min-width: 1920px) {
    .rad-leaders__leader-linkedin,.rad-leaders__leader-x-twitter {
        margin-right:-1.25rem
    }
}

@media(min-width: 1441px) {
    .rad-leaders__leader-linkedin,.rad-leaders__leader-x-twitter {
        margin-top:calc(-0.5rem - 0.55672vw + 8.02227px)
    }
}

@media(min-width: 1920px) {
    .rad-leaders__leader-linkedin,.rad-leaders__leader-x-twitter {
        margin-top:-0.6666666667rem
    }
}

.rad-leaders .rad-button--ghost {
    margin-top: auto
}

.rad-leaders__leader-bio {
    font-size: .75rem;
    font-weight: 500;
    font-weight: 400;
    grid-area: bio;
    letter-spacing: -0.005em;
    line-height: 1.5;
    text-underline-offset: .25rem
}

.rad-leaders__leader-bio:lang(ja-JP),.rad-leaders__leader-bio:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-leaders__leader-bio {
        font-size:0.875rem
    }
}

@media(min-width: 1441px) {
    .rad-leaders__leader-bio {
        font-size:calc(0.875rem + 0.97425vw - 14.03897px)
    }
}

@media(min-width: 1920px) {
    .rad-leaders__leader-bio {
        font-size:1.1666666667rem
    }
}

.rad-leaders__leader-bio:lang(ja-JP),.rad-leaders__leader-bio:lang(zh-CN) {
    font-weight: 400
}

@media(min-width: 600px) {
    .rad-leaders__leader-bio {
        width:calc(66.66667% - var(--rad-spacing-sm)*5/6*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-leaders__leader-bio {
        width:calc(60% - var(--rad-spacing-sm)*9/10*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0)
    }
}

.rad-leaders:not([data-authoring]) .rad-leaders__leader:not(:has(*)) {
    display: none
}

@media(min-width: 600px) {
    .rad-leader--bio .rad-leaders__leader-icon-container,.rad-leader--bio .rad-leaders__leader-name,.rad-leader--bio .rad-leaders__leader-role {
        width:calc(66.66667% - var(--rad-spacing-sm)*5/6*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-leader--bio .rad-leaders__leader-icon-container,.rad-leader--bio .rad-leaders__leader-name,.rad-leader--bio .rad-leaders__leader-role {
        width:calc(57.14286% - var(--rad-spacing-sm)*6/7*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0)
    }
}

.rad-leader--bio .rad-leaders__leader-bio {
    margin-top: auto;
    padding-top: 1rem
}

@media(min-width: 1441px) {
    .rad-leader--bio .rad-leaders__leader-bio {
        padding-top:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-leader--bio .rad-leaders__leader-bio {
        padding-top:1.3333333333rem
    }
}

.rad-leader--bio .rad-leaders__leader-icon-container+.rad-leaders__leader-bio {
    padding-top: 0
}

@media(min-width: 600px) {
    .rad-leaders--list .rad-leaders__leaders-container {
        -webkit-column-gap:var(--rad-spacing-sm);
        -moz-column-gap: var(--rad-spacing-sm);
        column-gap: var(--rad-spacing-sm);
        display: grid;
        grid-template-columns: repeat(2,1fr)
    }
}

@media(min-width: 1024px) {
    .rad-leaders--list .rad-leaders__leaders-container {
        margin-left:calc(100% - var(--rad-spacing-sm)*3/4*4 + var(--rad-spacing-sm)*4 + var(--rad-spacing-sm)*0);
        margin-left: calc(50% - var(--rad-spacing-sm)*7/8*4 + var(--rad-spacing-sm)*4 + var(--rad-spacing-md)*0);
        margin-left: calc(33.33333% - var(--rad-spacing-sm)*11/12*4 + var(--rad-spacing-sm)*4 + var(--rad-spacing-md)*0)
    }
}

.rad-leaders--list .rad-leaders__leader {
    align-self: start;
    grid-template-columns: 30% auto;
    margin-bottom: 1.5rem;
    width: 100%
}

.rad-leaders--list .rad-leaders__leader:last-child {
    margin-bottom: 0
}

@media(min-width: 600px) {
    .rad-leaders--list .rad-leaders__leader {
        -ms-flex-negative:0;
        -webkit-column-gap: 1rem;
        -moz-column-gap: 1rem;
        column-gap: 1rem;
        flex-shrink: 0;
        grid-template-areas: "image-container leader-name" "image-container role" "image-container linkedin" "image-container x-twitter";
        grid-template-rows: auto auto 1fr;
        margin-bottom: 2rem
    }

    .rad-leaders--list .rad-leaders__leader:nth-child(odd):nth-last-child(2) {
        margin-bottom: 0
    }
}

@media(min-width: 1024px) {
    .rad-leaders--list .rad-leaders__leader {
        -webkit-column-gap:1.5rem;
        -moz-column-gap: 1.5rem;
        column-gap: 1.5rem;
        margin-bottom: 2.5rem
    }
}

@media(min-width: 1441px) {
    .rad-leaders--list .rad-leaders__leader {
        margin-bottom:calc(2.5rem + 2.78358vw - 40.11134px)
    }
}

@media(min-width: 1920px) {
    .rad-leaders--list .rad-leaders__leader {
        margin-bottom:3.3333333333rem
    }
}

@media(min-width: 1441px) {
    .rad-leaders--list .rad-leaders__leader {
        -webkit-column-gap:calc(1.5rem + 1.67015vw - 24.06681px);
        -moz-column-gap: calc(1.5rem + 1.67015vw - 24.06681px);
        column-gap: calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-leaders--list .rad-leaders__leader {
        -webkit-column-gap:2rem;
        -moz-column-gap: 2rem;
        column-gap: 2rem
    }
}

.rad-leaders--list .rad-leaders__leader-name {
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: -0.005em;
    line-height: 1.6;
    text-underline-offset: .25rem
}

.rad-leaders--list .rad-leaders__leader-name:lang(ja-JP),.rad-leaders--list .rad-leaders__leader-name:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-leaders--list .rad-leaders__leader-name {
        font-size:1.125rem
    }
}

@media(min-width: 1024px) {
    .rad-leaders--list .rad-leaders__leader-name {
        font-size:1.25rem
    }
}

@media(min-width: 1441px) {
    .rad-leaders--list .rad-leaders__leader-name {
        font-size:calc(1.25rem + 1.39179vw - 20.05567px)
    }
}

@media(min-width: 1920px) {
    .rad-leaders--list .rad-leaders__leader-name {
        font-size:1.6666666667rem
    }
}

.rad-leaders--list .rad-leaders__leader-icon-container {
    margin-bottom: -1rem;
    margin-top: auto
}

@media(min-width: 1441px) {
    .rad-leaders--list .rad-leaders__leader-icon-container {
        margin-bottom:calc(-1rem - 1.11343vw + 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-leaders--list .rad-leaders__leader-icon-container {
        margin-bottom:-1.3333333333rem
    }
}

.rad-leaders--list .rad-leaders__leader-icon-container:has(+.rad-button--ghost) {
    margin-bottom: auto
}

@media(min-width: 1024px) {
    .rad-leaders--list-single .rad-leaders__leaders-container {
        display:block;
        margin-left: 0
    }

    .rad-leaders--list-single .rad-leaders__leader {
        width: calc(100% - var(--rad-spacing-sm)*3/4*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-sm)*0);
        width: calc(50% - var(--rad-spacing-sm)*7/8*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0);
        width: calc(33.33333% - var(--rad-spacing-sm)*11/12*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0)
    }
}

.rad-leaders--list-three-column .rad-leaders__leaders-container {
    display: grid;
    row-gap: 1.5rem
}

@media(min-width: 600px) {
    .rad-leaders--list-three-column .rad-leaders__leaders-container {
        -webkit-column-gap:var(--rad-spacing-sm);
        -moz-column-gap: var(--rad-spacing-sm);
        column-gap: var(--rad-spacing-sm);
        grid-template-columns: repeat(2,1fr);
        row-gap: 2rem
    }
}

@media(min-width: 1024px) {
    .rad-leaders--list-three-column .rad-leaders__leaders-container {
        grid-template-columns:repeat(3,1fr);
        margin-left: 0;
        row-gap: 2.5rem
    }
}

@media(min-width: 1441px) {
    .rad-leaders--list-three-column .rad-leaders__leaders-container {
        row-gap:calc(2.5rem + 2.78358vw - 40.11134px)
    }
}

@media(min-width: 1920px) {
    .rad-leaders--list-three-column .rad-leaders__leaders-container {
        row-gap:3.3333333333rem
    }
}

.rad-leaders--list-three-column .rad-leaders__leader {
    align-self: start;
    grid-template-columns: 30% auto;
    margin-bottom: 0;
    width: 100%
}

@media(min-width: 600px) {
    .rad-leaders--list-three-column .rad-leaders__leader {
        -ms-flex-negative:0;
        -webkit-column-gap: 1rem;
        -moz-column-gap: 1rem;
        column-gap: 1rem;
        flex-shrink: 0;
        grid-template-areas: "image-container leader-name" "image-container role" "image-container linkedin" "image-container x-twitter";
        grid-template-rows: auto auto 1fr
    }
}

@media(min-width: 1024px) {
    .rad-leaders--list-three-column .rad-leaders__leader {
        -webkit-column-gap:1.5rem;
        -moz-column-gap: 1.5rem;
        column-gap: 1.5rem
    }
}

@media(min-width: 1441px) {
    .rad-leaders--list-three-column .rad-leaders__leader {
        -webkit-column-gap:calc(1.5rem + 1.67015vw - 24.06681px);
        -moz-column-gap: calc(1.5rem + 1.67015vw - 24.06681px);
        column-gap: calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-leaders--list-three-column .rad-leaders__leader {
        -webkit-column-gap:2rem;
        -moz-column-gap: 2rem;
        column-gap: 2rem
    }
}

.rad-leaders--list-three-column .rad-leaders__leader-name {
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: -0.005em;
    line-height: 1.6;
    text-underline-offset: .25rem
}

.rad-leaders--list-three-column .rad-leaders__leader-name:lang(ja-JP),.rad-leaders--list-three-column .rad-leaders__leader-name:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-leaders--list-three-column .rad-leaders__leader-name {
        font-size:1.125rem
    }
}

@media(min-width: 1024px) {
    .rad-leaders--list-three-column .rad-leaders__leader-name {
        font-size:1.25rem
    }
}

@media(min-width: 1441px) {
    .rad-leaders--list-three-column .rad-leaders__leader-name {
        font-size:calc(1.25rem + 1.39179vw - 20.05567px)
    }
}

@media(min-width: 1920px) {
    .rad-leaders--list-three-column .rad-leaders__leader-name {
        font-size:1.6666666667rem
    }
}

.rad-leaders--list-three-column .rad-leaders__leader-icon-container {
    margin-top: 0
}

.rad-hero-text__help-button {
    margin-left: var(--rad-spacing-sm)
}

@media(min-width: 600px) {
    .rad-hero-text__help-button {
        margin-left:var(--rad-spacing-md)
    }
}

.rad-hero-text__text-content {
    padding-left: var(--rad-spacing-sm);
    padding-right: var(--rad-spacing-sm)
}

@media(min-width: 600px) {
    .rad-hero-text__text-content {
        padding-left:var(--rad-spacing-md);
        padding-right: var(--rad-spacing-md)
    }
}

.rad-component--nested .rad-hero-text__text-content {
    padding-left: 0;
    padding-right: 0
}

.rad-hero-text__eyebrow {
    font-size: .75rem;
    font-weight: 500;
    letter-spacing: .02em;
    line-height: 1.2;
    padding: 1.5rem 0;
    text-transform: uppercase;
    width: calc(100% - var(--rad-spacing-sm)*3/4*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-sm)*0)
}

.rad-hero-text__eyebrow:lang(ja-JP),.rad-hero-text__eyebrow:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 1024px) {
    .rad-hero-text__eyebrow {
        font-size:0.875rem
    }
}

@media(min-width: 1441px) {
    .rad-hero-text__eyebrow {
        font-size:calc(0.875rem + 0.97425vw - 14.03897px)
    }
}

@media(min-width: 1920px) {
    .rad-hero-text__eyebrow {
        font-size:1.1666666667rem
    }
}

@media(min-width: 600px) {
    .rad-hero-text__eyebrow {
        width:calc(50% - var(--rad-spacing-sm)*7/8*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-hero-text__eyebrow {
        width:calc(33.33333% - var(--rad-spacing-sm)*11/12*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 600px) {
    .rad-hero-text__eyebrow {
        padding:3.75rem 0 1.5rem;
        width: calc(150% - var(--rad-spacing-sm)*3/4*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-sm)*0);
        width: calc(75% - var(--rad-spacing-sm)*7/8*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-hero-text__eyebrow {
        padding-bottom:2rem;
        padding-top: 3.75rem;
        width: calc(50% - var(--rad-spacing-sm)*11/12*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0);
        width: calc(200% - var(--rad-spacing-sm)*3/4*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-sm)*0);
        width: calc(100% - var(--rad-spacing-sm)*7/8*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0);
        width: calc(66.66667% - var(--rad-spacing-sm)*11/12*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1441px) {
    .rad-hero-text__eyebrow {
        padding-top:calc(3.75rem + 4.17537vw - 60.16701px)
    }
}

@media(min-width: 1920px) {
    .rad-hero-text__eyebrow {
        padding-top:5rem
    }
}

@media(min-width: 1441px) {
    .rad-hero-text__eyebrow {
        padding-bottom:calc(2rem + 2.22686vw - 32.08907px)
    }
}

@media(min-width: 1920px) {
    .rad-hero-text__eyebrow {
        padding-bottom:2.6666666667rem
    }
}

.rad-hero-text__headline {
    font-size: 2rem;
    font-weight: 500;
    letter-spacing: -0.03em;
    line-height: 1.25;
    width: calc(100% - var(--rad-spacing-sm)*3/4*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-sm)*0)
}

.rad-hero-text__headline:lang(ja-JP),.rad-hero-text__headline:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-hero-text__headline {
        font-size:3.75rem;
        line-height: 1.15
    }
}

@media(min-width: 1441px) {
    .rad-hero-text__headline {
        font-size:calc(3.75rem + 4.17537vw - 60.16701px)
    }
}

@media(min-width: 1920px) {
    .rad-hero-text__headline {
        font-size:5rem
    }
}

@media(min-width: 600px) {
    .rad-hero-text__headline {
        width:calc(50% - var(--rad-spacing-sm)*7/8*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-hero-text__headline {
        width:calc(33.33333% - var(--rad-spacing-sm)*11/12*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 600px) {
    .rad-hero-text__headline {
        width:calc(175% - var(--rad-spacing-sm)*3/4*7 + var(--rad-spacing-sm)*6 + var(--rad-spacing-sm)*0);
        width: calc(87.5% - var(--rad-spacing-sm)*7/8*7 + var(--rad-spacing-sm)*6 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-hero-text__headline {
        width:calc(58.33333% - var(--rad-spacing-sm)*11/12*7 + var(--rad-spacing-sm)*6 + var(--rad-spacing-md)*0);
        width: calc(200% - var(--rad-spacing-sm)*3/4*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-sm)*0);
        width: calc(100% - var(--rad-spacing-sm)*7/8*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0);
        width: calc(66.66667% - var(--rad-spacing-sm)*11/12*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0)
    }
}

.rad-hero-text__subheader {
    font-family: GT Sectra Fine,Palatino;
    font-size: 1.25rem;
    font-weight: 300;
    line-height: 1.25;
    padding-top: 1rem;
    width: calc(100% - var(--rad-spacing-sm)*3/4*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-sm)*0)
}

@media(min-width: 600px) {
    .rad-hero-text__subheader {
        font-size:1.5rem;
        line-height: 1.25
    }
}

@media(min-width: 1024px) {
    .rad-hero-text__subheader {
        font-size:1.75rem;
        line-height: 1.25
    }
}

@media(min-width: 1441px) {
    .rad-hero-text__subheader {
        font-size:calc(1.75rem + 1.9485vw - 28.07794px)
    }
}

@media(min-width: 1920px) {
    .rad-hero-text__subheader {
        font-size:2.3333333333rem
    }
}

@media(min-width: 600px) {
    .rad-hero-text__subheader {
        width:calc(50% - var(--rad-spacing-sm)*7/8*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-hero-text__subheader {
        width:calc(33.33333% - var(--rad-spacing-sm)*11/12*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0)
    }
}

.rad-hero-text__subheader h2 {
    font-size: 1.75rem;
    font-weight: 500;
    letter-spacing: -0.03em;
    line-height: 1.3
}

.rad-hero-text__subheader h2:lang(ja-JP),.rad-hero-text__subheader h2:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-hero-text__subheader h2 {
        font-size:2.5rem;
        line-height: 1.2
    }
}

@media(min-width: 1024px) {
    .rad-hero-text__subheader h2 {
        font-size:3rem;
        line-height: 1.2
    }
}

@media(min-width: 1441px) {
    .rad-hero-text__subheader h2 {
        font-size:calc(3rem + 3.34029vw - 48.13361px)
    }
}

@media(min-width: 1920px) {
    .rad-hero-text__subheader h2 {
        font-size:4rem
    }
}

.rad-hero-text__subheader h2+h2,.rad-hero-text__subheader h2+h3 {
    margin-top: 1rem
}

.rad-hero-text__subheader h2+h4 {
    margin-top: 1.5rem
}

.rad-hero-text__subheader h2+h5 {
    margin-top: .5rem
}

.rad-hero-text__subheader h2+p {
    margin-top: 1.5rem
}

.rad-hero-text__subheader h3 {
    font-size: 1.375rem;
    font-weight: 500;
    letter-spacing: -0.02em;
    line-height: 1.25
}

.rad-hero-text__subheader h3:lang(ja-JP),.rad-hero-text__subheader h3:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-hero-text__subheader h3 {
        font-size:1.75rem;
        line-height: 1.3
    }
}

@media(min-width: 1024px) {
    .rad-hero-text__subheader h3 {
        font-size:2rem;
        line-height: 1.2
    }
}

@media(min-width: 1441px) {
    .rad-hero-text__subheader h3 {
        font-size:calc(2rem + 2.22686vw - 32.08907px)
    }
}

@media(min-width: 1920px) {
    .rad-hero-text__subheader h3 {
        font-size:2.6666666667rem
    }
}

.rad-hero-text__subheader h3+h2 {
    margin-top: 2rem
}

.rad-hero-text__subheader h3+h3 {
    margin-top: 1rem
}

.rad-hero-text__subheader h3+h4 {
    margin-top: .5rem
}

.rad-hero-text__subheader h3+h5 {
    margin-top: 1rem
}

.rad-hero-text__subheader h3+p {
    margin-top: 1.5rem
}

.rad-hero-text__subheader h4 {
    font-size: 1.125rem;
    font-weight: 500;
    letter-spacing: -0.02em;
    line-height: 1.4
}

.rad-hero-text__subheader h4:lang(ja-JP),.rad-hero-text__subheader h4:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-hero-text__subheader h4 {
        font-size:1.25rem;
        line-height: 1.4
    }
}

@media(min-width: 1024px) {
    .rad-hero-text__subheader h4 {
        font-size:1.5rem;
        line-height: 1.3
    }
}

@media(min-width: 1441px) {
    .rad-hero-text__subheader h4 {
        font-size:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-hero-text__subheader h4 {
        font-size:2rem
    }
}

.rad-hero-text__subheader h4+h2,.rad-hero-text__subheader h4+h3 {
    margin-top: 2rem
}

.rad-hero-text__subheader h4+h4 {
    margin-top: .5rem
}

.rad-hero-text__subheader h4+h5 {
    margin-top: 2rem
}

.rad-hero-text__subheader h4+p {
    margin-top: .5rem
}

.rad-hero-text__subheader h5 {
    font-family: GT Sectra Fine,Palatino;
    font-size: 1.25rem;
    font-weight: 300;
    line-height: 1.25
}

@media(min-width: 600px) {
    .rad-hero-text__subheader h5 {
        font-size:1.5rem;
        line-height: 1.25
    }
}

@media(min-width: 1024px) {
    .rad-hero-text__subheader h5 {
        font-size:1.75rem;
        line-height: 1.25
    }
}

@media(min-width: 1441px) {
    .rad-hero-text__subheader h5 {
        font-size:calc(1.75rem + 1.9485vw - 28.07794px)
    }
}

@media(min-width: 1920px) {
    .rad-hero-text__subheader h5 {
        font-size:2.3333333333rem
    }
}

.rad-hero-text__subheader h5+h2 {
    margin-top: 2rem
}

.rad-hero-text__subheader h5+h3,.rad-hero-text__subheader h5+h4 {
    margin-top: 1.5rem
}

.rad-hero-text__subheader h5+h5 {
    margin-top: 1rem
}

.rad-hero-text__subheader h5+p {
    margin-top: 1.5rem
}

.rad-hero-text__subheader p {
    padding-right: var(--scrollbar-width)
}

.rad-hero-text__subheader p a {
    font-weight: 500
}

.rad-hero-text__subheader p a:focus-visible {
    outline-offset: 2px
}

.rad-hero-text__subheader p+h2,.rad-hero-text__subheader p+h3,.rad-hero-text__subheader p+h4,.rad-hero-text__subheader p+h5 {
    margin-top: 2rem
}

.rad-hero-text__subheader p+p {
    margin-top: .5rem
}

@media(min-width: 600px) {
    .rad-hero-text__subheader {
        padding-top:1.5rem;
        width: calc(200% - var(--rad-spacing-sm)*3/4*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-sm)*0);
        width: calc(100% - var(--rad-spacing-sm)*7/8*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-hero-text__subheader {
        width:calc(66.66667% - var(--rad-spacing-sm)*11/12*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 600px) {
    .rad-hero-text__subheader h2+h2,.rad-hero-text__subheader h2+h3 {
        margin-top:1.5rem
    }

    .rad-hero-text__subheader h2+h4 {
        margin-top: 2rem
    }

    .rad-hero-text__subheader h2+h5 {
        margin-top: .5rem
    }

    .rad-hero-text__subheader h2+p {
        margin-top: 2rem
    }

    .rad-hero-text__subheader h3+h2 {
        margin-top: 3rem
    }

    .rad-hero-text__subheader h3+h3 {
        margin-top: 1.5rem
    }

    .rad-hero-text__subheader h3+h4 {
        margin-top: .75rem
    }

    .rad-hero-text__subheader h3+h5 {
        margin-top: 1.5rem
    }

    .rad-hero-text__subheader h3+p {
        margin-top: 2rem
    }

    .rad-hero-text__subheader h4+h2,.rad-hero-text__subheader h4+h3 {
        margin-top: 3rem
    }

    .rad-hero-text__subheader h4+h4 {
        margin-top: 1rem
    }

    .rad-hero-text__subheader h4+h5 {
        margin-top: 3rem
    }

    .rad-hero-text__subheader h4+p {
        margin-top: .75rem
    }

    .rad-hero-text__subheader h5+h2 {
        margin-top: 3rem
    }

    .rad-hero-text__subheader h5+h3 {
        margin-top: 2.5rem
    }

    .rad-hero-text__subheader h5+h4 {
        margin-top: 2rem
    }

    .rad-hero-text__subheader h5+h5 {
        margin-top: 1.5rem
    }

    .rad-hero-text__subheader h5+p {
        margin-top: 2rem
    }

    .rad-hero-text__subheader p {
        padding-right: 0
    }

    .rad-hero-text__subheader p+h2,.rad-hero-text__subheader p+h3,.rad-hero-text__subheader p+h4,.rad-hero-text__subheader p+h5 {
        margin-top: 3rem
    }

    .rad-hero-text__subheader p+p {
        margin-top: 1rem
    }
}

@media(min-width: 1024px) {
    .rad-hero-text__subheader {
        padding-top:2rem;
        width: calc(225% - var(--rad-spacing-sm)*3/4*9 + var(--rad-spacing-sm)*8 + var(--rad-spacing-sm)*0);
        width: calc(112.5% - var(--rad-spacing-sm)*7/8*9 + var(--rad-spacing-sm)*8 + var(--rad-spacing-md)*0);
        width: calc(75% - var(--rad-spacing-sm)*11/12*9 + var(--rad-spacing-sm)*8 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1441px) {
    .rad-hero-text__subheader {
        padding-top:calc(2rem + 2.22686vw - 32.08907px)
    }
}

@media(min-width: 1920px) {
    .rad-hero-text__subheader {
        padding-top:2.6666666667rem
    }
}

@media(min-width: 1024px) {
    .rad-hero-text__subheader h2+h2,.rad-hero-text__subheader h2+h3 {
        margin-top:2rem
    }

    .rad-hero-text__subheader h2+h4 {
        margin-top: 3rem
    }

    .rad-hero-text__subheader h2+h5 {
        margin-top: .5rem
    }

    .rad-hero-text__subheader h2+p {
        margin-top: 3rem
    }

    .rad-hero-text__subheader h3+h2 {
        margin-top: 4rem
    }

    .rad-hero-text__subheader h3+h3 {
        margin-top: 2rem
    }

    .rad-hero-text__subheader h3+h4 {
        margin-top: 1rem
    }

    .rad-hero-text__subheader h3+h5 {
        margin-top: 2rem
    }

    .rad-hero-text__subheader h3+p {
        margin-top: 3rem
    }

    .rad-hero-text__subheader h4+h2,.rad-hero-text__subheader h4+h3 {
        margin-top: 4rem
    }

    .rad-hero-text__subheader h4+h4 {
        margin-top: 1.5rem
    }

    .rad-hero-text__subheader h4+h5 {
        margin-top: 4rem
    }

    .rad-hero-text__subheader h4+p {
        margin-top: 1rem
    }

    .rad-hero-text__subheader h5+h2 {
        margin-top: 4rem
    }

    .rad-hero-text__subheader h5+h3,.rad-hero-text__subheader h5+h4 {
        margin-top: 3rem
    }

    .rad-hero-text__subheader h5+h5 {
        margin-top: 2rem
    }

    .rad-hero-text__subheader h5+p {
        margin-top: 3rem
    }

    .rad-hero-text__subheader p+h2,.rad-hero-text__subheader p+h3,.rad-hero-text__subheader p+h4,.rad-hero-text__subheader p+h5 {
        margin-top: 4rem
    }

    .rad-hero-text__subheader p+p {
        margin-top: 1.5rem
    }
}

.rad-hero-text__more-about {
    font-size: .875rem;
    font-weight: 500;
    font-weight: 400;
    letter-spacing: -0.005em;
    line-height: 1.5;
    padding-top: 1rem;
    text-underline-offset: .25rem;
    width: calc(100% - var(--rad-spacing-sm)*3/4*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-sm)*0)
}

.rad-hero-text__more-about:lang(ja-JP),.rad-hero-text__more-about:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-hero-text__more-about {
        font-size:1rem
    }
}

@media(min-width: 1441px) {
    .rad-hero-text__more-about {
        font-size:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-hero-text__more-about {
        font-size:1.3333333333rem
    }
}

.rad-hero-text__more-about:lang(ja-JP),.rad-hero-text__more-about:lang(zh-CN) {
    font-weight: 400
}

@media(min-width: 600px) {
    .rad-hero-text__more-about {
        width:calc(50% - var(--rad-spacing-sm)*7/8*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-hero-text__more-about {
        width:calc(33.33333% - var(--rad-spacing-sm)*11/12*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 600px) {
    .rad-hero-text__more-about {
        padding-top:1.5rem;
        width: calc(150% - var(--rad-spacing-sm)*3/4*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-sm)*0);
        width: calc(75% - var(--rad-spacing-sm)*7/8*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-hero-text__more-about {
        padding-top:2rem;
        width: calc(50% - var(--rad-spacing-sm)*11/12*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0);
        width: calc(200% - var(--rad-spacing-sm)*3/4*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-sm)*0);
        width: calc(100% - var(--rad-spacing-sm)*7/8*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0);
        width: calc(66.66667% - var(--rad-spacing-sm)*11/12*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1441px) {
    .rad-hero-text__more-about {
        padding-top:calc(2rem + 2.22686vw - 32.08907px)
    }
}

@media(min-width: 1920px) {
    .rad-hero-text__more-about {
        padding-top:2.6666666667rem
    }
}

.rad-hero-text--no-top-padding {
    padding-top: 0
}

.rad-hero-text--no-bottom-padding {
    padding-bottom: 0
}

.rad-hero-text--no-eyebrow .rad-hero-text__headline {
    padding-top: 24px
}

@media(min-width: 600px) {
    .rad-hero-text--no-eyebrow .rad-hero-text__headline {
        padding-top:3.75rem
    }
}

@media(min-width: 1441px) {
    .rad-hero-text--no-eyebrow .rad-hero-text__headline {
        padding-top:calc(3.75rem + 4.17537vw - 60.16701px)
    }
}

@media(min-width: 1920px) {
    .rad-hero-text--no-eyebrow .rad-hero-text__headline {
        padding-top:5rem
    }
}

.rad-hero-text__btn {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 1rem;
    margin-top: 1.5rem;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content
}

@media(min-width: 600px) {
    .rad-hero-text__btn {
        -webkit-box-orient:horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        gap: 1.5rem;
        margin-top: 2rem
    }
}

@media(min-width: 1024px) {
    .rad-hero-text__btn {
        gap:2rem;
        margin-top: 3rem
    }
}

@media(min-width: 1441px) {
    .rad-hero-text__btn {
        margin-top:calc(3rem + 3.34029vw - 48.13361px)
    }
}

@media(min-width: 1920px) {
    .rad-hero-text__btn {
        margin-top:4rem
    }
}

@media(min-width: 1441px) {
    .rad-hero-text__btn {
        gap:calc(2rem + 2.22686vw - 32.08907px)
    }
}

@media(min-width: 1920px) {
    .rad-hero-text__btn {
        gap:2.6666666667rem
    }
}

.rad-hero-text__btn .rad-button {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
    width: 100%
}

@media(min-width: 600px) {
    .rad-hero-text__btn .rad-button {
        width:auto
    }
}

.rad-hero-text--no-back .rad-hero-text__eyebrow {
    padding-top: 0
}

.rad-hero-text--headline-xl .rad-hero-text__headline {
    font-size: 3rem;
    font-weight: 500;
    letter-spacing: -0.03em;
    line-height: 1.15
}

.rad-hero-text--headline-xl .rad-hero-text__headline:lang(ja-JP),.rad-hero-text--headline-xl .rad-hero-text__headline:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-hero-text--headline-xl .rad-hero-text__headline {
        font-size:5rem;
        line-height: 1.1
    }
}

@media(min-width: 1024px) {
    .rad-hero-text--headline-xl .rad-hero-text__headline {
        font-size:6.25rem
    }
}

@media(min-width: 1441px) {
    .rad-hero-text--headline-xl .rad-hero-text__headline {
        font-size:calc(6.25rem + 6.95894vw - 100.27836px)
    }
}

@media(min-width: 1920px) {
    .rad-hero-text--headline-xl .rad-hero-text__headline {
        font-size:8.3333333333rem
    }
}

@media(min-width: 600px) {
    .rad-hero-text--headline-xl .rad-hero-text__headline {
        width:calc(200% - var(--rad-spacing-sm)*3/4*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-sm)*0);
        width: calc(100% - var(--rad-spacing-sm)*7/8*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-hero-text--headline-xl .rad-hero-text__headline {
        width:calc(66.66667% - var(--rad-spacing-sm)*11/12*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0)
    }
}

.rad-hero-text--headline-xl .rad-hero-text__subheader {
    padding-top: .5rem
}

@media(min-width: 600px) {
    .rad-hero-text--headline-xl .rad-hero-text__subheader {
        padding-top:1rem
    }
}

@media(min-width: 1024px) {
    .rad-hero-text--headline-xl .rad-hero-text__subheader {
        padding-top:1.5rem
    }
}

@media(min-width: 1441px) {
    .rad-hero-text--headline-xl .rad-hero-text__subheader {
        padding-top:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-hero-text--headline-xl .rad-hero-text__subheader {
        padding-top:2rem
    }
}

.rad-hero-text--center-aligned .rad-hero-text__text-content {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    text-align: center
}

.rad-hero-text--center-aligned .rad-hero-text__btn {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.rad-quote-carousel .flickity-enabled {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    justify-content: flex-end
}

.rad-quote-carousel .flickity-viewport {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%
}

.rad-quote-carousel .flickity-button {
    background: none
}

.rad-quote-carousel .flickity-button:focus {
    -webkit-box-shadow: none;
    box-shadow: none
}

.rad-quote-carousel .flickity-prev-next-button {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    align-items: center;
    background: none;
    border-radius: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 48px;
    justify-content: center;
    left: 0;
    margin-top: -12px;
    position: relative;
    right: 0;
    -webkit-transform: unset;
    transform: unset;
    width: 48px
}

@media(min-width: 1441px) {
    .rad-quote-carousel .flickity-prev-next-button {
        width:calc(-0.13361px + 3.34029vw)
    }
}

@media(min-width: 1920px) {
    .rad-quote-carousel .flickity-prev-next-button {
        width:64px
    }
}

@media(min-width: 1441px) {
    .rad-quote-carousel .flickity-prev-next-button {
        height:calc(-0.13361px + 3.34029vw)
    }
}

@media(min-width: 1920px) {
    .rad-quote-carousel .flickity-prev-next-button {
        height:64px
    }
}

@media(min-width: 600px) {
    .rad-quote-carousel .flickity-prev-next-button {
        margin-top:0
    }
}

.rad-quote-carousel .next,.rad-quote-carousel .previous {
    --rad-icon-button-grey-size: 32px
}

@media(min-width: 600px) {
    .rad-quote-carousel .next,.rad-quote-carousel .previous {
        --rad-icon-button-grey-size:40px
    }
}

@media(min-width: 1024px) {
    .rad-quote-carousel .next,.rad-quote-carousel .previous {
        --rad-icon-button-grey-size:48px
    }
}

@media(min-width: 1441px) {
    .rad-quote-carousel .next,.rad-quote-carousel .previous {
        --rad-icon-button-grey-size:calc(-0.13361px + 3.34029vw)
    }
}

@media(min-width: 1920px) {
    .rad-quote-carousel .next,.rad-quote-carousel .previous {
        --rad-icon-button-grey-size:64px
    }
}

@media(min-width: 1024px) {
    .rad-quote-carousel .next .rad-icon:after,.rad-quote-carousel .previous .rad-icon:after {
        scale:1.2
    }
}

.rad-quote-carousel .previous {
    margin-right: 4px
}

@media(min-width: 600px) {
    .rad-quote-carousel .previous {
        margin-right:16px
    }
}

@media(min-width: 1024px) {
    .rad-quote-carousel .previous {
        margin-right:24px
    }
}

@media(min-width: 1441px) {
    .rad-quote-carousel .previous {
        margin-right:calc(-0.06681px + 1.67015vw)
    }
}

@media(min-width: 1920px) {
    .rad-quote-carousel .previous {
        margin-right:32px
    }
}

.rad-quote-carousel .next {
    margin-right: var(--rad-spacing-sm)
}

@media(min-width: 600px) {
    .rad-quote-carousel .next {
        margin-right:var(--rad-spacing-md)
    }
}

.rad-quote-carousel .flickity-button-icon {
    fill: #fff;
    position: static;
    width: 1.25rem
}

@media(min-width: 1441px) {
    .rad-quote-carousel .flickity-button-icon {
        width:calc(1.25rem + 1.39179vw - 20.05567px)
    }
}

@media(min-width: 1920px) {
    .rad-quote-carousel .flickity-button-icon {
        width:1.6666666667rem
    }
}

.rad-quote-carousel .rad-quote-carousel-title__container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 1rem 1.5rem;
    row-gap: .25rem
}

.rad-quote-carousel .rad-quote-carousel-title {
    font-size: 1.375rem;
    font-weight: 500;
    letter-spacing: -0.02em;
    line-height: 1.25
}

.rad-quote-carousel .rad-quote-carousel-title:lang(ja-JP),.rad-quote-carousel .rad-quote-carousel-title:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-quote-carousel .rad-quote-carousel-title {
        font-size:1.75rem;
        line-height: 1.3
    }
}

@media(min-width: 1024px) {
    .rad-quote-carousel .rad-quote-carousel-title {
        font-size:2rem;
        line-height: 1.2
    }
}

@media(min-width: 1441px) {
    .rad-quote-carousel .rad-quote-carousel-title {
        font-size:calc(2rem + 2.22686vw - 32.08907px)
    }
}

@media(min-width: 1920px) {
    .rad-quote-carousel .rad-quote-carousel-title {
        font-size:2.6666666667rem
    }
}

.rad-quote-carousel .rad-quote-carousel__sub {
    font-size: .875rem;
    font-weight: 500;
    letter-spacing: -0.005em;
    line-height: 1.5;
    text-underline-offset: .25rem
}

.rad-quote-carousel .rad-quote-carousel__sub:lang(ja-JP),.rad-quote-carousel .rad-quote-carousel__sub:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-quote-carousel .rad-quote-carousel__sub {
        font-size:1rem
    }
}

@media(min-width: 1441px) {
    .rad-quote-carousel .rad-quote-carousel__sub {
        font-size:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-quote-carousel .rad-quote-carousel__sub {
        font-size:1.3333333333rem
    }
}

.rad-quote-carousel .flickity-enabled {
    position: static
}

.rad-quote-carousel .flickity-cell {
    top: 0
}

.rad-quote-carousel .flickity-button,.rad-quote-carousel .rad-carousel__autorotate-toggle {
    bottom: 0;
    position: absolute;
    translate: 0 100%
}

.rad-quote-carousel .rad-carousel__autorotate-toggle {
    left: var(--rad-spacing-sm)
}

.rad-quote-carousel .next {
    right: 0
}

.rad-quote-carousel .rad-carousel__page-numbers {
    bottom: 0;
    height: 48px;
    position: absolute;
    right: var(--rad-spacing-sm);
    translate: -48px 100%
}

@media(min-width: 1441px) {
    .rad-quote-carousel .rad-carousel__page-numbers {
        height:calc(-0.13361px + 3.34029vw)
    }
}

@media(min-width: 1920px) {
    .rad-quote-carousel .rad-carousel__page-numbers {
        height:64px
    }
}

@media(min-width: 600px) {
    .rad-quote-carousel .rad-carousel__page-numbers {
        translate:-100% 100%
    }
}

.rad-quote-carousel .previous {
    margin-right: 0;
    right: var(--rad-spacing-sm);
    translate: calc(-100% - 40px) 100%
}

@media(min-width: 600px) {
    .rad-quote-carousel .previous {
        translate:-200% 100%
    }
}

.rad-quote-carousel__container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-box-align: baseline;
    -ms-flex-align: baseline;
    align-items: baseline;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-bottom: 48px;
    padding-bottom: 16px;
    position: relative
}

@media(min-width: 1441px) {
    .rad-quote-carousel__container {
        margin-bottom:calc(-0.13361px + 3.34029vw)
    }
}

@media(min-width: 1920px) {
    .rad-quote-carousel__container {
        margin-bottom:64px
    }
}

.rad-quote-carousel__cell,.rad-quote-carousel__left {
    width: 100%
}

.rad-quote-carousel__left .rad-quote-carousel__image-container .rad-quote-carousel__image {
    display: block;
    width: 100%
}

.rad-quote-carousel__right {
    padding: 0 1rem;
    width: 100%
}

.rad-quote-carousel__right .rad-quote-carousel__cell {
    padding-top: 1.5rem
}

.rad-quote-carousel__right .rad-quote-carousel__cell.is-selected .rad-quote-carousel__read {
    visibility: visible
}

.rad-quote-carousel__right .rad-quote-headline {
    margin-bottom: 1.5rem
}

@media(min-width: 600px) {
    .rad-quote-carousel .rad-quote-carousel-title__container {
        -webkit-box-orient:horizontal;
        -webkit-box-direction: normal;
        -webkit-box-align: start;
        -ms-flex-align: start;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        align-items: flex-start;
        -webkit-column-gap: 1.5rem;
        -moz-column-gap: 1.5rem;
        column-gap: 1.5rem;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: row;
        flex-direction: row;
        justify-content: space-between;
        padding-bottom: 2.5rem;
        padding-left: var(--rad-spacing-sm);
        padding-left: var(--rad-spacing-md);
        padding-right: var(--rad-spacing-sm);
        padding-right: var(--rad-spacing-md)
    }

    .rad-component--nested .rad-quote-carousel .rad-quote-carousel-title__container {
        padding-left: 0;
        padding-right: 0
    }

    .rad-quote-carousel .rad-quote-carousel-title {
        width: calc(150% - var(--rad-spacing-sm)*3/4*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-sm)*0);
        width: calc(75% - var(--rad-spacing-sm)*7/8*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-quote-carousel .rad-quote-carousel-title {
        width:calc(50% - var(--rad-spacing-sm)*11/12*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 600px) {
    .rad-quote-carousel .rad-quote-carousel__cta {
        -webkit-box-pack:end;
        -ms-flex-pack: end;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        justify-content: flex-end;
        width: calc(50% - var(--rad-spacing-sm)*3/4*2 + var(--rad-spacing-sm)*1 + var(--rad-spacing-sm)*0);
        width: calc(25% - var(--rad-spacing-sm)*7/8*2 + var(--rad-spacing-sm)*1 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-quote-carousel .rad-quote-carousel__cta {
        width:calc(16.66667% - var(--rad-spacing-sm)*11/12*2 + var(--rad-spacing-sm)*1 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 600px) {
    .rad-quote-carousel .flickity-cell {
        bottom:auto;
        margin-bottom: 0
    }

    .rad-quote-carousel .rad-carousel__autorotate-toggle {
        left: var(--rad-spacing-md)
    }

    .rad-quote-carousel .previous,.rad-quote-carousel .rad-carousel__page-numbers {
        right: var(--rad-spacing-md)
    }

    .rad-quote-carousel .previous {
        margin-right: 24px
    }

    .rad-quote-carousel__container {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: row;
        flex-direction: row;
        padding-bottom: 24px;
        padding-right: 3rem
    }
}

@media(min-width: 600px) {
    .rad-quote-carousel__container .flickity-enabled:focus-visible {
        outline:.125rem solid #a100ff
    }
}

@media(min-width: 600px) {
    .rad-quote-carousel__left {
        -ms-flex-negative:0;
        flex-shrink: 0;
        width: calc(min(100vw, 1920px)/4*2 - var(--rad-spacing-sm)*2/4*2 - var(--rad-spacing-sm)*3/4*2 + var(--rad-spacing-sm)*1 + var(--rad-spacing-sm)*1);
        width: calc(min(100vw, 1920px)/8*2 - var(--rad-spacing-md)*2/8*2 - var(--rad-spacing-sm)*7/8*2 + var(--rad-spacing-sm)*1 + var(--rad-spacing-md)*1)
    }
}

@media(min-width: 1024px) {
    .rad-quote-carousel__left {
        width:calc(min(100vw, 1920px)/12*2 - var(--rad-spacing-md)*2/12*2 - var(--rad-spacing-sm)*11/12*2 + var(--rad-spacing-sm)*1 + var(--rad-spacing-md)*1)
    }
}

@media(min-width: 600px) {
    .rad-quote-carousel__right {
        margin:auto 0;
        padding: 0
    }

    .rad-quote-carousel__right .flickity-viewport {
        height: 12.5rem
    }

    .rad-quote-carousel__right .flickity-slider {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex
    }

    .rad-quote-carousel__right .rad-quote-carousel__cell {
        padding-left: calc(min(100vw, 1920px)/4*1 - var(--rad-spacing-sm)*2/4*1 - var(--rad-spacing-sm)*3/4*1 + var(--rad-spacing-sm)*2 + var(--rad-spacing-sm)*0);
        padding-left: calc(min(100vw, 1920px)/8*1 - var(--rad-spacing-md)*2/8*1 - var(--rad-spacing-sm)*7/8*1 + var(--rad-spacing-sm)*2 + var(--rad-spacing-md)*0);
        padding-top: 0
    }
}

@media(min-width: 1024px) {
    .rad-quote-carousel .rad-quote-carousel-title__container {
        -webkit-column-gap:2rem;
        -moz-column-gap: 2rem;
        column-gap: 2rem;
        padding-bottom: 5rem
    }

    .rad-quote-carousel .rad-quote-carousel-title {
        width: calc(200% - var(--rad-spacing-sm)*3/4*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-sm)*0);
        width: calc(100% - var(--rad-spacing-sm)*7/8*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0);
        width: calc(66.66667% - var(--rad-spacing-sm)*11/12*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0)
    }

    .rad-quote-carousel .rad-quote-carousel__cta {
        width: calc(100% - var(--rad-spacing-sm)*3/4*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-sm)*0);
        width: calc(50% - var(--rad-spacing-sm)*7/8*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0);
        width: calc(33.33333% - var(--rad-spacing-sm)*11/12*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0)
    }

    .rad-quote-carousel .previous {
        margin-right: 32px
    }
}

@media(min-width: 1441px) {
    .rad-quote-carousel .previous {
        margin-right:calc(-0.08907px + 2.22686vw)
    }
}

@media(min-width: 1920px) {
    .rad-quote-carousel .previous {
        margin-right:42.6666666667px
    }
}

@media(min-width: 1024px) {
    .rad-quote-carousel__container {
        padding-bottom:32px
    }
}

@media(min-width: 1441px) {
    .rad-quote-carousel__container {
        padding-bottom:calc(-0.08907px + 2.22686vw)
    }
}

@media(min-width: 1920px) {
    .rad-quote-carousel__container {
        padding-bottom:42.6666666667px
    }
}

@media(min-width: 1024px) {
    .rad-quote-carousel__left {
        width:calc(min(100vw, 1920px)/4*4 - var(--rad-spacing-sm)*2/4*4 - var(--rad-spacing-sm)*3/4*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-sm)*1);
        width: calc(min(100vw, 1920px)/8*4 - var(--rad-spacing-md)*2/8*4 - var(--rad-spacing-sm)*7/8*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*1);
        width: calc(min(100vw, 1920px)/12*4 - var(--rad-spacing-md)*2/12*4 - var(--rad-spacing-sm)*11/12*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*1)
    }

    .rad-quote-carousel__right .flickity-viewport {
        min-height: 14.0625rem
    }
}

@media(min-width: 1441px) {
    .rad-quote-carousel__right .flickity-viewport {
        min-height:calc(14.0625rem + 15.65762vw - 225.6263px)
    }
}

@media(min-width: 1920px) {
    .rad-quote-carousel__right .flickity-viewport {
        min-height:18.75rem
    }
}

@media(min-width: 1024px) {
    .rad-quote-carousel__right .rad-quote-carousel__title {
        margin-bottom:1.25rem
    }
}

@media(min-width: 1441px) {
    .rad-quote-carousel__right .rad-quote-carousel__title {
        margin-bottom:calc(1.25rem + 1.39179vw - 20.05567px)
    }
}

@media(min-width: 1920px) {
    .rad-quote-carousel__right .rad-quote-carousel__title {
        margin-bottom:1.6666666667rem
    }
}

@media(min-width: 1024px) {
    .rad-quote-carousel__right .rad-quote-carousel__info {
        margin-bottom:1.5rem
    }
}

@media(min-width: 1441px) {
    .rad-quote-carousel__right .rad-quote-carousel__info {
        margin-bottom:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-quote-carousel__right .rad-quote-carousel__info {
        margin-bottom:2rem
    }
}

@media(min-width: 1024px) {
    .rad-quote-carousel__right .rad-quote-carousel__cell {
        padding-left:calc(min(100vw, 1920px)/4*1 - var(--rad-spacing-sm)*2/4*1 - var(--rad-spacing-sm)*3/4*1 + var(--rad-spacing-sm)*2 + var(--rad-spacing-sm)*0);
        padding-left: calc(min(100vw, 1920px)/8*1 - var(--rad-spacing-md)*2/8*1 - var(--rad-spacing-sm)*7/8*1 + var(--rad-spacing-sm)*2 + var(--rad-spacing-md)*0);
        padding-left: calc(min(100vw, 1920px)/12*1 - var(--rad-spacing-md)*2/12*1 - var(--rad-spacing-sm)*11/12*1 + var(--rad-spacing-sm)*2 + var(--rad-spacing-md)*0);
        padding-top: 0
    }

    .rad-quote-carousel__right .rad-quote-headline {
        margin-bottom: 2rem
    }
}

@media(min-width: 1441px) {
    .rad-quote-carousel__right .rad-quote-headline {
        margin-bottom:calc(2rem + 2.22686vw - 32.08907px)
    }
}

@media(min-width: 1920px) {
    .rad-quote-carousel__right .rad-quote-headline {
        margin-bottom:2.6666666667rem
    }
}

.rad-component__title--large-text .rad-quote-carousel-title {
    font-size: 1.75rem;
    font-weight: 500;
    letter-spacing: -0.03em;
    line-height: 1.3
}

.rad-component__title--large-text .rad-quote-carousel-title:lang(ja-JP),.rad-component__title--large-text .rad-quote-carousel-title:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-component__title--large-text .rad-quote-carousel-title {
        font-size:2.5rem;
        line-height: 1.2
    }
}

@media(min-width: 1024px) {
    .rad-component__title--large-text .rad-quote-carousel-title {
        font-size:3rem;
        line-height: 1.2
    }
}

@media(min-width: 1441px) {
    .rad-component__title--large-text .rad-quote-carousel-title {
        font-size:calc(3rem + 3.34029vw - 48.13361px)
    }
}

@media(min-width: 1920px) {
    .rad-component__title--large-text .rad-quote-carousel-title {
        font-size:4rem
    }
}

:root {
    --rad-subnav-margin-tablet: 48px;
    --rad-subnav-margin-desktop: 80px;
    --rad-subnav-margin-wide-desktop: 107px
}

.subnavigation {
    padding-bottom: 3.25rem
}

.rad-subnav {
    background-color: #460073;
    color: #fff;
    height: 3.25rem;
    position: fixed;
    top: var(--rad-nav-height);
    -webkit-transition-duration: 550ms;
    transition-duration: 550ms;
    -webkit-transition-property: top;
    transition-property: top;
    width: 100%;
    z-index: 1001
}

@media(min-width: 1920px) {
    .rad-subnav {
        margin-left:calc(-50vw + 960px);
        margin-right: calc(-50vw + 960px)
    }
}

.rad-global-header--offscreen .rad-subnav {
    top: 0
}

.rad-subnav-bar {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 1920px;
    padding: 0 1rem
}

@media(min-width: 600px) {
    .rad-subnav-bar {
        padding:0 var(--rad-subnav-margin-tablet)
    }
}

@media(min-width: 1024px) {
    .rad-subnav-bar {
        padding:0 var(--rad-subnav-margin-desktop)
    }
}

@media(min-width: 1441px) {
    .rad-subnav-bar {
        padding-left:5rem;
        padding-left: calc(5rem + 5.56715vw - 80.22269px);
        padding-right: 5rem
    }
}

@media(min-width: 1920px) {
    .rad-subnav-bar {
        padding-left:6.6666666667rem
    }
}

@media(min-width: 1441px) {
    .rad-subnav-bar {
        padding-right:calc(5rem + 5.56715vw - 80.22269px)
    }
}

@media(min-width: 1920px) {
    .rad-subnav-bar {
        padding-right:6.6666666667rem
    }
}

.rad-subnav-bar__title {
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-size: .875rem;
    font-weight: 500;
    height: 3.25rem;
    letter-spacing: -0.005em;
    line-height: 1.5;
    overflow: hidden;
    text-overflow: ellipsis;
    text-underline-offset: .25rem;
    vertical-align: middle;
    white-space: nowrap
}

.rad-subnav-bar__title:lang(ja-JP),.rad-subnav-bar__title:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-subnav-bar__title {
        font-size:1rem
    }
}

@media(min-width: 1441px) {
    .rad-subnav-bar__title {
        font-size:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-subnav-bar__title {
        font-size:1.3333333333rem
    }
}

@media(min-width: 600px) {
    .rad-subnav-bar__title {
        -webkit-box-flex:0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        font-size: 1rem;
        padding-right: 2.5rem
    }
}

@media(min-width: 1920px) {
    .rad-subnav-bar__title {
        padding-right:3.3125rem
    }
}

.rad-subnav-bar__anchor-top {
    -ms-flex-item-align: center;
    align-self: center;
    outline-offset: -2px;
    padding: .875rem 0;
    text-decoration: none
}

.rad-subnav-bar__anchor-top:hover,.rad-subnav-bar__anchor-top:visited {
    color: #fff
}

.rad-subnav-bar .subnav-bar__link,.rad-subnav-bar .subnav-bar__link--anchor {
    text-decoration: none
}

@media(min-width: 600px) {
    .rad-subnav-bar__items {
        -webkit-box-pack:end;
        -ms-flex-pack: end;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        height: 3.25rem;
        justify-content: flex-end
    }
}

.rad-subnav-bar__items .subnav-menu-toggle {
    padding-right: 1rem;
    width: auto
}

@media(min-width: 600px) {
    .rad-subnav-bar__items .subnav-menu-toggle {
        display:none;
        margin-right: -10px
    }
}

.rad-subnav-bar__items .rad-subnav-bar__section-display {
    display: none;
    padding-left: 1.5rem;
    padding-right: 1rem
}

.rad-subnav-bar__dropdown-links {
    background-color: #202020;
    border-bottom: 1px solid #616160;
    border-top: 1px solid #7500c0;
    display: none;
    font-size: .75rem;
    font-size: .875rem;
    font-weight: 500;
    font-weight: 400;
    height: 0;
    letter-spacing: -0.005em;
    line-height: 1.5;
    padding: .5rem 1rem;
    position: absolute;
    text-underline-offset: .25rem;
    -webkit-transition-duration: 250ms;
    transition-duration: 250ms;
    -webkit-transition-property: display,height;
    transition-property: display,height;
    -webkit-transition-timing-function: cubic-bezier(0.77,0,0.18,1);
    transition-timing-function: cubic-bezier(0.77,0,0.18,1);
    width: 100%
}

.rad-subnav-bar__dropdown-links:lang(ja-JP),.rad-subnav-bar__dropdown-links:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-subnav-bar__dropdown-links {
        font-size:0.875rem
    }
}

@media(min-width: 1441px) {
    .rad-subnav-bar__dropdown-links {
        font-size:calc(0.875rem + 0.97425vw - 14.03897px)
    }
}

@media(min-width: 1920px) {
    .rad-subnav-bar__dropdown-links {
        font-size:1.1666666667rem
    }
}

.rad-subnav-bar__dropdown-links:lang(ja-JP),.rad-subnav-bar__dropdown-links:lang(zh-CN) {
    font-weight: 400
}

.rad-subnav-bar__dropdown-links .subnav-bar__link,.rad-subnav-bar__dropdown-links .subnav-bar__link--anchor {
    color: #fff;
    display: block;
    padding: 1rem 0;
    text-align: left;
    -webkit-transition-duration: 50ms;
    transition-duration: 50ms;
    -webkit-transition-property: font-weight;
    transition-property: font-weight;
    -webkit-transition-timing-function: cubic-bezier(0.85,0,0,1);
    transition-timing-function: cubic-bezier(0.85,0,0,1)
}

.rad-subnav-bar__dropdown-links .subnav-bar__link--anchor.current-section,.rad-subnav-bar__dropdown-links .subnav-bar__link--anchor:hover,.rad-subnav-bar__dropdown-links .subnav-bar__link.current-section,.rad-subnav-bar__dropdown-links .subnav-bar__link:hover {
    color: #fff;
    font-weight: 500
}

@media(min-width: 600px) {
    .rad-subnav-bar__dropdown-links {
        margin-top:3.25rem;
        padding-left: 3rem
    }
}

@media(min-width: 1024px) {
    .rad-subnav-bar__dropdown-links {
        padding-left:5rem
    }
}

.rad-subnav-bar__links {
    display: none;
    font-size: .75rem;
    font-size: .875rem;
    font-weight: 500;
    font-weight: 400;
    letter-spacing: -0.005em;
    line-height: 1.5;
    text-underline-offset: .25rem
}

.rad-subnav-bar__links:lang(ja-JP),.rad-subnav-bar__links:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-subnav-bar__links {
        font-size:0.875rem
    }
}

@media(min-width: 1441px) {
    .rad-subnav-bar__links {
        font-size:calc(0.875rem + 0.97425vw - 14.03897px)
    }
}

@media(min-width: 1920px) {
    .rad-subnav-bar__links {
        font-size:1.1666666667rem
    }
}

.rad-subnav-bar__links:lang(ja-JP),.rad-subnav-bar__links:lang(zh-CN) {
    font-weight: 400
}

.rad-subnav-bar__links .rad-subnav-bar__link-text {
    position: relative
}

.rad-subnav-bar__links .rad-subnav-bar__link-text:after {
    border-bottom: 1px solid;
    bottom: 0;
    content: "";
    display: block;
    left: 0;
    position: absolute;
    -webkit-transition-duration: 550ms;
    transition-duration: 550ms;
    -webkit-transition-property: width;
    transition-property: width;
    -webkit-transition-timing-function: cubic-bezier(0.85,0,0,1);
    transition-timing-function: cubic-bezier(0.85,0,0,1);
    width: 0
}

:hover>.rad-subnav-bar__link-text:after {
    width: 100%
}

@media(min-width: 600px) {
    .rad-subnav-bar__links {
        -webkit-box-align:stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        padding: 0
    }
}

.rad-subnav-bar__links .subnav-bar__link,.rad-subnav-bar__links .subnav-bar__link--anchor {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 3.25rem;
    padding: 0 1.25rem;
    -webkit-transition-duration: 50ms;
    transition-duration: 50ms;
    -webkit-transition-property: background-color;
    transition-property: background-color;
    -webkit-transition-timing-function: cubic-bezier(0.85,0,0,1);
    transition-timing-function: cubic-bezier(0.85,0,0,1);
    white-space: nowrap
}

.rad-subnav-bar__links .subnav-bar__link--anchor:hover,.rad-subnav-bar__links .subnav-bar__link:hover {
    background-color: #7500c0;
    color: #fff
}

@media(min-width: 1920px) {
    .rad-subnav-bar__links .subnav-bar__link,.rad-subnav-bar__links .subnav-bar__link--anchor {
        padding-left:1.25rem;
        padding-left: calc(1.25rem + 1.39179vw - 20.05567px);
        padding-left: 1.6666666667rem;
        padding-right: 1.25rem;
        padding-right: calc(1.25rem + 1.39179vw - 20.05567px);
        padding-right: 1.6666666667rem
    }
}

@media(min-width: 600px) {
    .rad-subnav--links-inline .rad-subnav-bar__links {
        display:-webkit-box;
        display: -ms-flexbox;
        display: flex;
        margin-right: -1.25rem
    }
}

@media(min-width: 1920px) {
    .rad-subnav--links-inline .rad-subnav-bar__links {
        margin-right:-1.25rem;
        margin-right: calc(-1.25rem - 1.39179vw + 20.05567px);
        margin-right: -1.6666666667rem
    }
}

@media(min-width: 600px) {
    .rad-subnav--links-inline .rad-subnav-bar__section-display,.rad-subnav--links-inline .subnav-menu-toggle {
        display:none
    }

    .rad-subnav.rad-subnav__highlights--visible:not(.rad-subnav--links-inline) .rad-subnav-bar__links {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        visibility: hidden
    }

    .rad-subnav.rad-subnav__highlights--visible:not(.rad-subnav--links-inline) .rad-subnav-bar__section-display {
        -ms-flex-item-align: center;
        align-self: center;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        visibility: visible;
        white-space: nowrap
    }
}

.rad-subnav.rad-subnav__highlights--visible .rad-subnav-bar__links .subnav-bar__link--anchor.current-section {
    background-color: #7500c0;
    color: #fff
}

.rad-subnav.rad-subnav__dropdown--active .rad-subnav-bar__dropdown-links,.rad-subnav.rad-subnav__dropdown--active.rad-subnav__highlights--visible .rad-subnav-bar__dropdown-links {
    display: block;
    height: auto;
    left: 0;
    max-height: 100dvh;
    right: 0
}

.rad-subnav.rad-subnav__dropdown--active .subnav-menu-toggle .rad-icon,.rad-subnav.rad-subnav__dropdown--active.rad-subnav__highlights--visible .subnav-menu-toggle .rad-icon {
    rotate: 180deg
}

@media(min-width: 600px) {
    .rad-subnav.rad-subnav__dropdown--active .rad-subnav-bar__section-display,.rad-subnav.rad-subnav__dropdown--active.rad-subnav__highlights--visible .rad-subnav-bar__section-display {
        display:none
    }
}

.rad-accordion {
    padding-left: var(--rad-spacing-sm);
    padding-right: var(--rad-spacing-sm)
}

@media(min-width: 600px) {
    .rad-accordion {
        padding-left:var(--rad-spacing-md);
        padding-right: var(--rad-spacing-md)
    }
}

.rad-component--nested .rad-accordion {
    padding-left: 0;
    padding-right: 0
}

.rad-accordion__header-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-bottom: 1.5rem;
    row-gap: 0.5rem
}

@media(min-width: 1441px) {
    .rad-accordion__header-container {
        row-gap:calc(0.5rem + 0.55672vw - 8.02227px)
    }
}

@media(min-width: 1920px) {
    .rad-accordion__header-container {
        row-gap:0.6666666667rem
    }
}

.rad-accordion__headline {
    font-size: 1.75rem;
    font-weight: 500;
    letter-spacing: -0.03em;
    line-height: 1.3
}

.rad-accordion__headline:lang(ja-JP),.rad-accordion__headline:lang(zh-CN) {
    font-weight: 700
}

.rad-accordion__more {
    margin-top: .25rem
}

.rad-accordion__more .rad-button:focus-visible {
    margin-left: .125rem;
    outline-offset: 0
}

.rad-accordion__header-title {
    font-size: 1.125rem;
    font-weight: 500;
    gap: 1rem;
    letter-spacing: -0.02em;
    line-height: 1.4
}

.rad-accordion__header-title:lang(ja-JP),.rad-accordion__header-title:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-accordion__header-title {
        font-size:1.25rem;
        line-height: 1.4
    }
}

@media(min-width: 1024px) {
    .rad-accordion__header-title {
        font-size:1.5rem;
        line-height: 1.3
    }
}

@media(min-width: 1441px) {
    .rad-accordion__header-title {
        font-size:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-accordion__header-title {
        font-size:2rem
    }
}

.rad-accordion__subheader {
    font-family: GT Sectra Fine,Palatino;
    font-size: 1.25rem;
    font-weight: 300;
    line-height: 1.25
}

.rad-accordion__content {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-right: 3.25rem;
    padding-bottom: 1.5rem
}

.rad-accordion__content,.rad-accordion__header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.rad-accordion__header {
    -webkit-box-align: start;
    -ms-flex-align: start;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    align-items: flex-start;
    cursor: pointer;
    gap: 16px;
    justify-content: space-between;
    padding: 16px 11px 16px 0;
    text-align: left;
    width: 100%
}


.rad-accordion .rad-accordion__container--open:hover .accordioncard .rad-accordion__purple {
    border-top-color: #a100ff
}

.rad-accordion .rad-accordion__container--open .rad-accordion__header {
    border-top-color: #616160;
    color: #a2a2a0
}

.rad-accordion .rad-accordion__container--open .rad-accordion__header .rad-accordion__icon:after,.rad-accordion .rad-accordion__container--open .rad-accordion__header .rad-accordion__icon:before {
    background-color: #a2a2a0
}

.rad-accordion .rad-accordion__container--open .rad-accordion__purple {
    border-top: 2px solid #a100ff
}

.rad-accordion .rad-accordion__container--open .accordioncard:last-child .rad-accordion__item {
    border-bottom-color: #616160
}

.rad-accordion__container {
    pointer-events: none
}

.rad-accordion__container .accordioncard .rad-accordion__item--open .rad-accordion__header {
    border-color: #a100ff;
    color: #fff
}

.rad-accordion__container .accordioncard .rad-accordion__item--open .rad-accordion__header .rad-accordion__icon:after,.rad-accordion__container .accordioncard .rad-accordion__item--open .rad-accordion__header .rad-accordion__icon:before {
    background-color: #fff
}

.rad-accordion__container:hover .accordioncard .rad-accordion__header {
    border-top-color: #616160;
    color: #a2a2a0
}

.rad-accordion__container:hover .accordioncard .rad-accordion__item--open .rad-accordion__header {
    border-top-color: #a100ff;
    color: #fff
}

.rad-accordion__container:hover .accordioncard:last-child .rad-accordion__item {
    border-bottom-color: #616160
}

.rad-accordion .rad-accordion__header {
    border-top: 2px solid #a100ff
}

.rad-accordion .accordioncard {
    pointer-events: auto
}

.rad-accordion .accordioncard:last-child .rad-accordion__item {
    border-bottom: 2px solid #a100ff
}

.rad-accordion .accordioncard:last-child .rad-accordion__item.rad-accordion__item--open {
    border-color: #a100ff
}

.rad-accordion .accordioncard:hover .rad-accordion__header {
    border-color: #a100ff;
    color: #fff
}

.rad-accordion .accordioncard:hover+.experiencefragment .rad-accordion__header {
    border-top-color: #a100ff
}

.rad-accordion .accordioncard:hover~.experiencefragment:last-child {
    border-bottom-color: #616160
}

.rad-accordion .accordioncard:hover:last-child .rad-accordion__item {
    border-bottom-color: #a100ff
}

.rad-accordion .accordioncard:hover .rad-accordion__icon:after,.rad-accordion .accordioncard:hover .rad-accordion__icon:before {
    background-color: #fff
}

.rad-accordion .accordioncard:hover+.accordioncard .rad-accordion__header {
    border-top: 2px solid #a100ff
}

.rad-accordion .experiencefragment:last-child {
    border-bottom: 2px solid #a100ff
}

.rad-accordion__item.rad-accordion__item--open .rad-accordion__icon:after {
    rotate: 0deg
}

.rad-accordion__item.rad-accordion__item--open .rad-accordion__content-wrapper {
    visibility: visible
}

.rad-accordion__icon {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    height: 14px;
    margin: .3125rem auto;
    position: relative;
    width: 14px
}

@media(min-width: 1441px) {
    .rad-accordion__icon {
        height:calc(-0.03897px + 0.97425vw)
    }
}

@media(min-width: 1920px) {
    .rad-accordion__icon {
        height:18.6666666667px
    }
}

@media(min-width: 1441px) {
    .rad-accordion__icon {
        width:calc(-0.03897px + 0.97425vw)
    }
}

@media(min-width: 1920px) {
    .rad-accordion__icon {
        width:18.6666666667px
    }
}

.rad-accordion__icon:after,.rad-accordion__icon:before {
    background-color: #fff;
    content: "";
    display: block;
    height: 2px;
    position: absolute;
    top: 50%;
    -webkit-transition: rotate 550ms cubic-bezier(0.85,0,0,1);
    transition: rotate 550ms cubic-bezier(0.85,0,0,1);
    translate: 0 -50%;
    width: 100%
}

@media(min-width: 1441px) {
    .rad-accordion__icon:after,.rad-accordion__icon:before {
        height:calc(-0.00557px + 0.13918vw)
    }
}

@media(min-width: 1920px) {
    .rad-accordion__icon:after,.rad-accordion__icon:before {
        height:2.6666666667px
    }
}

.rad-accordion__icon:after {
    rotate: -90deg
}

.rad-accordion__footer {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: .25rem;
    padding-top: 1.5rem
}

.rad-accordion__footer-title {
    font-size: 1.25rem;
    font-weight: 500;
    letter-spacing: -0.02em;
    line-height: 1.3;
    text-align: right
}

.rad-accordion__footer-title:lang(ja-JP),.rad-accordion__footer-title:lang(zh-CN) {
    font-weight: 700
}

.rad-accordion__content-wrapper {
    max-height: 0;
    overflow: hidden;
    -webkit-transition: max-height 550ms cubic-bezier(0.85,0,0,1),visibility 550ms cubic-bezier(0.85,0,0,1);
    transition: max-height 550ms cubic-bezier(0.85,0,0,1),visibility 550ms cubic-bezier(0.85,0,0,1);
    visibility: hidden
}

.rad-accordion__content-wrapper>h2,.rad-accordion__content-wrapper>h3,.rad-accordion__content-wrapper>h4,.rad-accordion__content-wrapper>h5,.rad-accordion__content-wrapper>ol,.rad-accordion__content-wrapper>p,.rad-accordion__content-wrapper>ul {
    width: calc(100% - var(--rad-spacing-sm)*3/4*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-sm)*0)
}

@media(min-width: 600px) {
    .rad-accordion__content-wrapper>h2,.rad-accordion__content-wrapper>h3,.rad-accordion__content-wrapper>h4,.rad-accordion__content-wrapper>h5,.rad-accordion__content-wrapper>ol,.rad-accordion__content-wrapper>p,.rad-accordion__content-wrapper>ul {
        width:calc(50% - var(--rad-spacing-sm)*7/8*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-accordion__content-wrapper>h2,.rad-accordion__content-wrapper>h3,.rad-accordion__content-wrapper>h4,.rad-accordion__content-wrapper>h5,.rad-accordion__content-wrapper>ol,.rad-accordion__content-wrapper>p,.rad-accordion__content-wrapper>ul {
        width:calc(33.33333% - var(--rad-spacing-sm)*11/12*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0)
    }
}

.rad-accordion__content-wrapper h2 {
    font-size: 1.75rem;
    font-weight: 500;
    letter-spacing: -0.03em;
    line-height: 1.3
}

.rad-accordion__content-wrapper h2:lang(ja-JP),.rad-accordion__content-wrapper h2:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-accordion__content-wrapper h2 {
        font-size:2.5rem;
        line-height: 1.2
    }
}

@media(min-width: 1024px) {
    .rad-accordion__content-wrapper h2 {
        font-size:3rem;
        line-height: 1.2
    }
}

@media(min-width: 1441px) {
    .rad-accordion__content-wrapper h2 {
        font-size:calc(3rem + 3.34029vw - 48.13361px)
    }
}

@media(min-width: 1920px) {
    .rad-accordion__content-wrapper h2 {
        font-size:4rem
    }
}

.rad-accordion__content-wrapper h2+h2,.rad-accordion__content-wrapper h2+h3 {
    margin-top: 1rem
}

.rad-accordion__content-wrapper h2+h4 {
    margin-top: 1.5rem
}

.rad-accordion__content-wrapper h2+h5 {
    margin-top: .5rem
}

.rad-accordion__content-wrapper h2+ol,.rad-accordion__content-wrapper h2+p,.rad-accordion__content-wrapper h2+ul {
    margin-top: 1.5rem
}

.rad-accordion__content-wrapper h3 {
    font-size: 1.375rem;
    font-weight: 500;
    letter-spacing: -0.02em;
    line-height: 1.25
}

.rad-accordion__content-wrapper h3:lang(ja-JP),.rad-accordion__content-wrapper h3:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-accordion__content-wrapper h3 {
        font-size:1.75rem;
        line-height: 1.3
    }
}

@media(min-width: 1024px) {
    .rad-accordion__content-wrapper h3 {
        font-size:2rem;
        line-height: 1.2
    }
}

@media(min-width: 1441px) {
    .rad-accordion__content-wrapper h3 {
        font-size:calc(2rem + 2.22686vw - 32.08907px)
    }
}

@media(min-width: 1920px) {
    .rad-accordion__content-wrapper h3 {
        font-size:2.6666666667rem
    }
}

.rad-accordion__content-wrapper h3+h2 {
    margin-top: 2rem
}

.rad-accordion__content-wrapper h3+h3 {
    margin-top: 1rem
}

.rad-accordion__content-wrapper h3+h4 {
    margin-top: .5rem
}

.rad-accordion__content-wrapper h3+h5 {
    margin-top: 1rem
}

.rad-accordion__content-wrapper h3+ol,.rad-accordion__content-wrapper h3+p,.rad-accordion__content-wrapper h3+ul {
    margin-top: 1.5rem
}

.rad-accordion__content-wrapper h4 {
    font-size: 1.125rem;
    font-weight: 500;
    letter-spacing: -0.02em;
    line-height: 1.4
}

.rad-accordion__content-wrapper h4:lang(ja-JP),.rad-accordion__content-wrapper h4:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-accordion__content-wrapper h4 {
        font-size:1.25rem;
        line-height: 1.4
    }
}

@media(min-width: 1024px) {
    .rad-accordion__content-wrapper h4 {
        font-size:1.5rem;
        line-height: 1.3
    }
}

@media(min-width: 1441px) {
    .rad-accordion__content-wrapper h4 {
        font-size:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-accordion__content-wrapper h4 {
        font-size:2rem
    }
}

.rad-accordion__content-wrapper h4+h2,.rad-accordion__content-wrapper h4+h3 {
    margin-top: 2rem
}

.rad-accordion__content-wrapper h4+h4 {
    margin-top: .5rem
}

.rad-accordion__content-wrapper h4+h5 {
    margin-top: 2rem
}

.rad-accordion__content-wrapper h4+ol,.rad-accordion__content-wrapper h4+p,.rad-accordion__content-wrapper h4+ul {
    margin-top: .5rem
}

.rad-accordion__content-wrapper h5 {
    font-family: GT Sectra Fine,Palatino;
    font-size: 1.25rem;
    font-weight: 300;
    line-height: 1.25
}

@media(min-width: 600px) {
    .rad-accordion__content-wrapper h5 {
        font-size:1.5rem;
        line-height: 1.25
    }
}

@media(min-width: 1024px) {
    .rad-accordion__content-wrapper h5 {
        font-size:1.75rem;
        line-height: 1.25
    }
}

@media(min-width: 1441px) {
    .rad-accordion__content-wrapper h5 {
        font-size:calc(1.75rem + 1.9485vw - 28.07794px)
    }
}

@media(min-width: 1920px) {
    .rad-accordion__content-wrapper h5 {
        font-size:2.3333333333rem
    }
}

.rad-accordion__content-wrapper h5+h2 {
    margin-top: 2rem
}

.rad-accordion__content-wrapper h5+h3,.rad-accordion__content-wrapper h5+h4 {
    margin-top: 1.5rem
}

.rad-accordion__content-wrapper h5+h5 {
    margin-top: 1rem
}

.rad-accordion__content-wrapper h5+ol,.rad-accordion__content-wrapper h5+p,.rad-accordion__content-wrapper h5+ul {
    margin-top: 1.5rem
}

.rad-accordion__content-wrapper ol,.rad-accordion__content-wrapper p,.rad-accordion__content-wrapper ul {
    font-size: .875rem;
    line-height: 1.5;
    padding-right: var(--scrollbar-width)
}

@media(min-width: 600px) {
    .rad-accordion__content-wrapper ol,.rad-accordion__content-wrapper p,.rad-accordion__content-wrapper ul {
        font-size:1rem
    }
}

@media(min-width: 1024px) {
    .rad-accordion__content-wrapper ol,.rad-accordion__content-wrapper p,.rad-accordion__content-wrapper ul {
        font-size:1.125rem;
        line-height: 1.6
    }
}

@media(min-width: 1441px) {
    .rad-accordion__content-wrapper ol,.rad-accordion__content-wrapper p,.rad-accordion__content-wrapper ul {
        font-size:calc(1.125rem + 1.25261vw - 18.0501px)
    }
}

@media(min-width: 1920px) {
    .rad-accordion__content-wrapper ol,.rad-accordion__content-wrapper p,.rad-accordion__content-wrapper ul {
        font-size:1.5rem
    }
}

.rad-accordion__content-wrapper ol a,.rad-accordion__content-wrapper p a,.rad-accordion__content-wrapper ul a {
    font-weight: 500
}

.rad-accordion__content-wrapper ol a:focus-visible,.rad-accordion__content-wrapper p a:focus-visible,.rad-accordion__content-wrapper ul a:focus-visible {
    outline-offset: 2px
}

.rad-accordion__content-wrapper ol+h2,.rad-accordion__content-wrapper ol+h3,.rad-accordion__content-wrapper ol+h4,.rad-accordion__content-wrapper ol+h5,.rad-accordion__content-wrapper p+h2,.rad-accordion__content-wrapper p+h3,.rad-accordion__content-wrapper p+h4,.rad-accordion__content-wrapper p+h5,.rad-accordion__content-wrapper ul+h2,.rad-accordion__content-wrapper ul+h3,.rad-accordion__content-wrapper ul+h4,.rad-accordion__content-wrapper ul+h5 {
    margin-top: 2rem
}

.rad-accordion__content-wrapper ol+ol,.rad-accordion__content-wrapper ol+p,.rad-accordion__content-wrapper ol+ul,.rad-accordion__content-wrapper p+ol,.rad-accordion__content-wrapper p+p,.rad-accordion__content-wrapper p+ul,.rad-accordion__content-wrapper ul+ol,.rad-accordion__content-wrapper ul+p,.rad-accordion__content-wrapper ul+ul {
    margin-top: .5rem
}

.rad-accordion__content-wrapper p {
    font-size: 1rem;
    font-weight: 500;
    font-weight: 400;
    letter-spacing: -0.005em;
    line-height: 1.6;
    text-underline-offset: .25rem
}

.rad-accordion__content-wrapper p:lang(ja-JP),.rad-accordion__content-wrapper p:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-accordion__content-wrapper p {
        font-size:1.125rem
    }
}

@media(min-width: 1024px) {
    .rad-accordion__content-wrapper p {
        font-size:1.25rem
    }
}

@media(min-width: 1441px) {
    .rad-accordion__content-wrapper p {
        font-size:calc(1.25rem + 1.39179vw - 20.05567px)
    }
}

@media(min-width: 1920px) {
    .rad-accordion__content-wrapper p {
        font-size:1.6666666667rem
    }
}

.rad-accordion__content-wrapper p:lang(ja-JP),.rad-accordion__content-wrapper p:lang(zh-CN) {
    font-weight: 400
}

.rad-accordion__content-wrapper ol li {
    counter-increment: li;
    list-style-type: none;
    padding-left: 30px;
    position: relative
}

@media(min-width: 1441px) {
    .rad-accordion__content-wrapper ol li {
        padding-left:calc(-0.08351px + 2.08768vw)
    }
}

@media(min-width: 1920px) {
    .rad-accordion__content-wrapper ol li {
        padding-left:40px
    }
}

.rad-accordion__content-wrapper ol li:before {
    color: #a100ff;
    content: counter(li) attr(start) ". ";
    left: 0;
    position: absolute
}

.rad-accordion__content-wrapper ul {
    list-style: none;
    padding-left: 0
}

.rad-accordion__content-wrapper ul li {
    padding-left: 29px;
    position: relative
}

@media(min-width: 1441px) {
    .rad-accordion__content-wrapper ul li {
        padding-left:calc(-0.08072px + 2.01809vw)
    }
}

@media(min-width: 1920px) {
    .rad-accordion__content-wrapper ul li {
        padding-left:38.6666666667px
    }
}

.rad-accordion__content-wrapper ul li:before {
    background-color: #a100ff;
    content: "";
    display: inline-block;
    height: .375rem;
    left: 0;
    position: absolute;
    text-align: center;
    top: 10px;
    width: .375rem
}

@media(min-width: 1441px) {
    .rad-accordion__content-wrapper ul li:before {
        top:calc(-0.02784px + 0.69589vw)
    }
}

@media(min-width: 1920px) {
    .rad-accordion__content-wrapper ul li:before {
        top:13.3333333333px
    }
}

@media(min-width: 600px) {
    .rad-accordion__content-wrapper>h2,.rad-accordion__content-wrapper>h3,.rad-accordion__content-wrapper>h4,.rad-accordion__content-wrapper>h5,.rad-accordion__content-wrapper>ol,.rad-accordion__content-wrapper>p,.rad-accordion__content-wrapper>ul {
        width:calc(150% - var(--rad-spacing-sm)*3/4*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-sm)*0);
        width: calc(75% - var(--rad-spacing-sm)*7/8*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-accordion__content-wrapper>h2,.rad-accordion__content-wrapper>h3,.rad-accordion__content-wrapper>h4,.rad-accordion__content-wrapper>h5,.rad-accordion__content-wrapper>ol,.rad-accordion__content-wrapper>p,.rad-accordion__content-wrapper>ul {
        width:calc(50% - var(--rad-spacing-sm)*11/12*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 600px) {
    .rad-accordion__content-wrapper h2+h2,.rad-accordion__content-wrapper h2+h3 {
        margin-top:1.5rem
    }

    .rad-accordion__content-wrapper h2+h4 {
        margin-top: 2rem
    }

    .rad-accordion__content-wrapper h2+h5 {
        margin-top: .5rem
    }

    .rad-accordion__content-wrapper h2+ol,.rad-accordion__content-wrapper h2+p,.rad-accordion__content-wrapper h2+ul {
        margin-top: 2rem
    }

    .rad-accordion__content-wrapper h3+h2 {
        margin-top: 3rem
    }

    .rad-accordion__content-wrapper h3+h3 {
        margin-top: 1.5rem
    }

    .rad-accordion__content-wrapper h3+h4 {
        margin-top: .75rem
    }

    .rad-accordion__content-wrapper h3+h5 {
        margin-top: 1.5rem
    }

    .rad-accordion__content-wrapper h3+ol,.rad-accordion__content-wrapper h3+p,.rad-accordion__content-wrapper h3+ul {
        margin-top: 2rem
    }

    .rad-accordion__content-wrapper h4+h2,.rad-accordion__content-wrapper h4+h3 {
        margin-top: 3rem
    }

    .rad-accordion__content-wrapper h4+h4 {
        margin-top: 1rem
    }

    .rad-accordion__content-wrapper h4+h5 {
        margin-top: 3rem
    }

    .rad-accordion__content-wrapper h4+p {
        margin-top: .75rem
    }

    .rad-accordion__content-wrapper h4+ol,.rad-accordion__content-wrapper h4+ul {
        margin-top: 1rem
    }

    .rad-accordion__content-wrapper h5+h2 {
        margin-top: 3rem
    }

    .rad-accordion__content-wrapper h5+h3 {
        margin-top: 2.5rem
    }

    .rad-accordion__content-wrapper h5+h4 {
        margin-top: 2rem
    }

    .rad-accordion__content-wrapper h5+h5 {
        margin-top: 1.5rem
    }

    .rad-accordion__content-wrapper h5+ol,.rad-accordion__content-wrapper h5+p,.rad-accordion__content-wrapper h5+ul {
        margin-top: 2rem
    }

    .rad-accordion__content-wrapper ol,.rad-accordion__content-wrapper p,.rad-accordion__content-wrapper ul {
        padding-right: 0
    }

    .rad-accordion__content-wrapper ol+h2,.rad-accordion__content-wrapper ol+h3,.rad-accordion__content-wrapper ol+h4,.rad-accordion__content-wrapper ol+h5,.rad-accordion__content-wrapper p+h2,.rad-accordion__content-wrapper p+h3,.rad-accordion__content-wrapper p+h4,.rad-accordion__content-wrapper p+h5,.rad-accordion__content-wrapper ul+h2,.rad-accordion__content-wrapper ul+h3,.rad-accordion__content-wrapper ul+h4,.rad-accordion__content-wrapper ul+h5 {
        margin-top: 3rem
    }

    .rad-accordion__content-wrapper ol+ol,.rad-accordion__content-wrapper ol+p,.rad-accordion__content-wrapper ol+ul,.rad-accordion__content-wrapper p+ol,.rad-accordion__content-wrapper p+p,.rad-accordion__content-wrapper p+ul,.rad-accordion__content-wrapper ul+ol,.rad-accordion__content-wrapper ul+p,.rad-accordion__content-wrapper ul+ul {
        margin-top: 1rem
    }

    .rad-accordion__content-wrapper ol li:before,.rad-accordion__content-wrapper ul li:before {
        height: .5rem;
        width: .5rem
    }
}

@media(min-width: 1024px) {
    .rad-accordion__content-wrapper>h2,.rad-accordion__content-wrapper>h3,.rad-accordion__content-wrapper>h4,.rad-accordion__content-wrapper>h5,.rad-accordion__content-wrapper>ol,.rad-accordion__content-wrapper>p,.rad-accordion__content-wrapper>ul {
        width:calc(200% - var(--rad-spacing-sm)*3/4*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-sm)*0);
        width: calc(100% - var(--rad-spacing-sm)*7/8*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0);
        width: calc(66.66667% - var(--rad-spacing-sm)*11/12*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0)
    }

    .rad-accordion__content-wrapper h2+h2,.rad-accordion__content-wrapper h2+h3 {
        margin-top: 2rem
    }

    .rad-accordion__content-wrapper h2+h4 {
        margin-top: 3rem
    }

    .rad-accordion__content-wrapper h2+h5 {
        margin-top: .5rem
    }

    .rad-accordion__content-wrapper h2+ol,.rad-accordion__content-wrapper h2+p,.rad-accordion__content-wrapper h2+ul {
        margin-top: 3rem
    }

    .rad-accordion__content-wrapper h3+h2 {
        margin-top: 4rem
    }

    .rad-accordion__content-wrapper h3+h3 {
        margin-top: 2rem
    }

    .rad-accordion__content-wrapper h3+h4 {
        margin-top: 1rem
    }

    .rad-accordion__content-wrapper h3+h5 {
        margin-top: 2rem
    }

    .rad-accordion__content-wrapper h3+ol,.rad-accordion__content-wrapper h3+p,.rad-accordion__content-wrapper h3+ul {
        margin-top: 3rem
    }

    .rad-accordion__content-wrapper h4+h2,.rad-accordion__content-wrapper h4+h3 {
        margin-top: 4rem
    }

    .rad-accordion__content-wrapper h4+h4 {
        margin-top: 1.5rem
    }

    .rad-accordion__content-wrapper h4+h5 {
        margin-top: 4rem
    }

    .rad-accordion__content-wrapper h4+p {
        margin-top: 1rem
    }

    .rad-accordion__content-wrapper h4+ol,.rad-accordion__content-wrapper h4+ul {
        margin-top: 1.5rem
    }

    .rad-accordion__content-wrapper h5+h2 {
        margin-top: 4rem
    }

    .rad-accordion__content-wrapper h5+h3,.rad-accordion__content-wrapper h5+h4 {
        margin-top: 3rem
    }

    .rad-accordion__content-wrapper h5+h5 {
        margin-top: 2rem
    }

    .rad-accordion__content-wrapper h5+ol,.rad-accordion__content-wrapper h5+p,.rad-accordion__content-wrapper h5+ul {
        margin-top: 3rem
    }

    .rad-accordion__content-wrapper ol+h2,.rad-accordion__content-wrapper ol+h3,.rad-accordion__content-wrapper ol+h4,.rad-accordion__content-wrapper ol+h5,.rad-accordion__content-wrapper p+h2,.rad-accordion__content-wrapper p+h3,.rad-accordion__content-wrapper p+h4,.rad-accordion__content-wrapper p+h5,.rad-accordion__content-wrapper ul+h2,.rad-accordion__content-wrapper ul+h3,.rad-accordion__content-wrapper ul+h4,.rad-accordion__content-wrapper ul+h5 {
        margin-top: 4rem
    }

    .rad-accordion__content-wrapper ol+ol,.rad-accordion__content-wrapper ol+p,.rad-accordion__content-wrapper ol+ul,.rad-accordion__content-wrapper p+ol,.rad-accordion__content-wrapper p+p,.rad-accordion__content-wrapper p+ul,.rad-accordion__content-wrapper ul+ol,.rad-accordion__content-wrapper ul+p,.rad-accordion__content-wrapper ul+ul {
        margin-top: 1.5rem
    }
}

.rad-accordion__content-wrapper ol li {
    translate: .375rem
}

.rad-accordion__content-wrapper ul li {
    translate: .125rem
}

.rad-accordion__content-wrapper ol,.rad-accordion__content-wrapper p,.rad-accordion__content-wrapper ul {
    font-size: 1rem;
    font-weight: 500;
    font-weight: 400;
    letter-spacing: -0.005em;
    line-height: 1.6;
    text-underline-offset: .25rem
}

.rad-accordion__content-wrapper ol:lang(ja-JP),.rad-accordion__content-wrapper ol:lang(zh-CN),.rad-accordion__content-wrapper p:lang(ja-JP),.rad-accordion__content-wrapper p:lang(zh-CN),.rad-accordion__content-wrapper ul:lang(ja-JP),.rad-accordion__content-wrapper ul:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-accordion__content-wrapper ol,.rad-accordion__content-wrapper p,.rad-accordion__content-wrapper ul {
        font-size:1.125rem
    }
}

@media(min-width: 1024px) {
    .rad-accordion__content-wrapper ol,.rad-accordion__content-wrapper p,.rad-accordion__content-wrapper ul {
        font-size:1.25rem
    }
}

@media(min-width: 1441px) {
    .rad-accordion__content-wrapper ol,.rad-accordion__content-wrapper p,.rad-accordion__content-wrapper ul {
        font-size:calc(1.25rem + 1.39179vw - 20.05567px)
    }
}

@media(min-width: 1920px) {
    .rad-accordion__content-wrapper ol,.rad-accordion__content-wrapper p,.rad-accordion__content-wrapper ul {
        font-size:1.6666666667rem
    }
}

.rad-accordion__content-wrapper ol:lang(ja-JP),.rad-accordion__content-wrapper ol:lang(zh-CN),.rad-accordion__content-wrapper p:lang(ja-JP),.rad-accordion__content-wrapper p:lang(zh-CN),.rad-accordion__content-wrapper ul:lang(ja-JP),.rad-accordion__content-wrapper ul:lang(zh-CN) {
    font-weight: 400
}

.rad-accordion .rad-accordion__image {
    aspect-ratio: 5/3;
    display: block;
    overflow: hidden
}

.rad-accordion .rad-accordion__image img {
    height: 100%;
    width: 100%
}

@media(min-width: 600px) {
    .rad-accordion {
        width:calc(200% - var(--rad-spacing-sm)*3/4*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-sm)*0);
        width: calc(100% - var(--rad-spacing-sm)*7/8*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-accordion {
        width:calc(66.66667% - var(--rad-spacing-sm)*11/12*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 600px) {
    .rad-accordion__header-container {
        padding-bottom:2.5rem
    }

    .rad-accordion__header {
        gap: 1.5rem;
        padding-right: 0.875rem
    }
}

@media(min-width: 1441px) {
    .rad-accordion__header {
        padding-right:calc(0.875rem + 0.97425vw - 14.03897px)
    }
}

@media(min-width: 1920px) {
    .rad-accordion__header {
        padding-right:1.1666666667rem
    }
}

@media(min-width: 600px) {
    .rad-accordion__headline {
        font-size:1.75rem;
        font-weight: 500;
        letter-spacing: -0.03em;
        line-height: 1.3
    }

    .rad-accordion__headline:lang(ja-JP),.rad-accordion__headline:lang(zh-CN) {
        font-weight: 700
    }

    .rad-accordion__headline {
        font-size: 2.5rem;
        line-height: 1.2
    }
}

@media(min-width: 1024px) {
    .rad-accordion__headline {
        font-size:3rem;
        line-height: 1.2
    }
}

@media(min-width: 1441px) {
    .rad-accordion__headline {
        font-size:calc(3rem + 3.34029vw - 48.13361px)
    }
}

@media(min-width: 1920px) {
    .rad-accordion__headline {
        font-size:4rem
    }
}

@media(min-width: 600px) {
    .rad-accordion__more {
        margin-top:.5rem
    }

    .rad-accordion__header-title {
        gap: 1.5rem;
        width: calc(175% - var(--rad-spacing-sm)*3/4*7 + var(--rad-spacing-sm)*6 + var(--rad-spacing-sm)*0);
        width: calc(87.5% - var(--rad-spacing-sm)*7/8*7 + var(--rad-spacing-sm)*6 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-accordion__header-title {
        width:calc(58.33333% - var(--rad-spacing-sm)*11/12*7 + var(--rad-spacing-sm)*6 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 600px) {
    .rad-accordion__content {
        margin-right:0;
        padding-bottom: 1.5rem
    }
}

@media(min-width: 1441px) {
    .rad-accordion__content {
        padding-bottom:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-accordion__content {
        padding-bottom:2rem
    }
}

@media(min-width: 600px) {
    .rad-accordion__detail {
        width:calc(175% - var(--rad-spacing-sm)*3/4*7 + var(--rad-spacing-sm)*6 + var(--rad-spacing-sm)*0);
        width: calc(87.5% - var(--rad-spacing-sm)*7/8*7 + var(--rad-spacing-sm)*6 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-accordion__detail {
        width:calc(58.33333% - var(--rad-spacing-sm)*11/12*7 + var(--rad-spacing-sm)*6 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 600px) {
    .rad-accordion__subheader {
        font-family:GT Sectra Fine,Palatino;
        font-size: 1.25rem;
        font-size: 1.5rem;
        font-weight: 300;
        line-height: 1.25
    }
}

@media(min-width: 1024px) {
    .rad-accordion__subheader {
        font-size:1.75rem;
        line-height: 1.25
    }
}

@media(min-width: 1441px) {
    .rad-accordion__subheader {
        font-size:calc(1.75rem + 1.9485vw - 28.07794px)
    }
}

@media(min-width: 1920px) {
    .rad-accordion__subheader {
        font-size:2.3333333333rem
    }
}

@media(min-width: 600px) {
    .rad-accordion__footer {
        padding-top:2.5rem
    }

    .rad-accordion__footer-title {
        font-size: 1.25rem;
        font-weight: 500;
        letter-spacing: -0.02em;
        line-height: 1.3
    }

    .rad-accordion__footer-title:lang(ja-JP),.rad-accordion__footer-title:lang(zh-CN) {
        font-weight: 700
    }

    .rad-accordion__footer-title {
        font-size: 1.5rem;
        line-height: 1.3
    }
}

@media(min-width: 1024px) {
    .rad-accordion__footer-title {
        font-size:1.75rem;
        line-height: 1.25
    }
}

@media(min-width: 1441px) {
    .rad-accordion__footer-title {
        font-size:calc(1.75rem + 1.9485vw - 28.07794px)
    }
}

@media(min-width: 1920px) {
    .rad-accordion__footer-title {
        font-size:2.3333333333rem
    }
}

@media(min-width: 600px) {
    .rad-accordion__icon-container {
        width:9%
    }
}

@media(min-width: 1024px) {
    .rad-accordion {
        width:calc(300% - var(--rad-spacing-sm)*3/4*12 + var(--rad-spacing-sm)*11 + var(--rad-spacing-sm)*0);
        width: calc(150% - var(--rad-spacing-sm)*7/8*12 + var(--rad-spacing-sm)*11 + var(--rad-spacing-md)*0);
        width: calc(100% - var(--rad-spacing-sm)*11/12*12 + var(--rad-spacing-sm)*11 + var(--rad-spacing-md)*0)
    }

    .rad-accordion__header-container {
        padding-bottom: 3rem
    }
}

@media(min-width: 1441px) {
    .rad-accordion__header-container {
        padding-bottom:calc(3rem + 3.34029vw - 48.13361px)
    }
}

@media(min-width: 1920px) {
    .rad-accordion__header-container {
        padding-bottom:4rem
    }
}

@media(min-width: 1024px) {
    .rad-accordion__headline {
        width:calc(200% - var(--rad-spacing-sm)*3/4*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-sm)*0);
        width: calc(100% - var(--rad-spacing-sm)*7/8*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0);
        width: calc(66.66667% - var(--rad-spacing-sm)*11/12*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0)
    }

    .rad-accordion__more {
        margin-top: 0.75rem
    }
}

@media(min-width: 1441px) {
    .rad-accordion__more {
        margin-top:calc(0.75rem + 0.83507vw - 12.0334px)
    }
}

@media(min-width: 1920px) {
    .rad-accordion__more {
        margin-top:1rem
    }
}

@media(min-width: 1024px) {
    .rad-accordion__header {
        padding-bottom:1.5rem;
        padding-top: 1.5rem
    }
}

@media(min-width: 1441px) {
    .rad-accordion__header {
        padding-top:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-accordion__header {
        padding-top:2rem
    }
}

@media(min-width: 1441px) {
    .rad-accordion__header {
        padding-bottom:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-accordion__header {
        padding-bottom:2rem
    }
}

@media(min-width: 1024px) {
    .rad-accordion__header-title {
        gap:2rem;
        width: calc(200% - var(--rad-spacing-sm)*3/4*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-sm)*0)
    }
}

@media(min-width: 1441px) {
    .rad-accordion__header-title {
        gap:calc(2rem + 2.22686vw - 32.08907px)
    }
}

@media(min-width: 1920px) {
    .rad-accordion__header-title {
        gap:2.6666666667rem
    }
}

@media(min-width: 1024px) {
    .rad-accordion__header-title {
        width:calc(100% - var(--rad-spacing-sm)*7/8*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0);
        width: calc(66.66667% - var(--rad-spacing-sm)*11/12*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0)
    }

    .rad-accordion__subheader {
        width: calc(150% - var(--rad-spacing-sm)*3/4*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-sm)*0);
        width: calc(75% - var(--rad-spacing-sm)*7/8*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0);
        width: calc(50% - var(--rad-spacing-sm)*11/12*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0)
    }

    .rad-accordion__icon {
        margin-bottom: 0.4375rem;
        margin-top: 0.4375rem
    }
}

@media(min-width: 1441px) {
    .rad-accordion__icon {
        margin-top:calc(0.4375rem + 0.48713vw - 7.01949px)
    }
}

@media(min-width: 1920px) {
    .rad-accordion__icon {
        margin-top:0.5833333333rem
    }
}

@media(min-width: 1441px) {
    .rad-accordion__icon {
        margin-bottom:calc(0.4375rem + 0.48713vw - 7.01949px)
    }
}

@media(min-width: 1920px) {
    .rad-accordion__icon {
        margin-bottom:0.5833333333rem
    }
}

@media(min-width: 1024px) {
    .rad-accordion__detail {
        width:calc(200% - var(--rad-spacing-sm)*3/4*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-sm)*0);
        width: calc(100% - var(--rad-spacing-sm)*7/8*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0);
        width: calc(66.66667% - var(--rad-spacing-sm)*11/12*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0)
    }

    .rad-accordion__footer {
        gap: 1rem;
        padding-top: 4rem
    }
}

@media(min-width: 1441px) {
    .rad-accordion__footer {
        padding-top:calc(4rem + 4.45372vw - 64.17815px)
    }
}

@media(min-width: 1920px) {
    .rad-accordion__footer {
        padding-top:5.3333333333rem
    }
}

@media(min-width: 1441px) {
    .rad-accordion__footer {
        gap:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-accordion__footer {
        gap:1.3333333333rem
    }
}

@media(min-width: 1024px) {
    .rad-accordion__footer-title {
        width:calc(150% - var(--rad-spacing-sm)*3/4*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-sm)*0);
        width: calc(75% - var(--rad-spacing-sm)*7/8*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0);
        width: calc(50% - var(--rad-spacing-sm)*11/12*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0)
    }

    .rad-accordion__icon-container {
        width: 6%
    }
}

.rad-accordion__stat .rad-accordion__stat-text:before {
    background-color: #a100ff;
    content: "";
    display: block;
    height: 0.25rem;
    margin-bottom: 0.5rem;
    width: 2rem
}

@media(min-width: 1441px) {
    .rad-accordion__stat .rad-accordion__stat-text:before {
        height:calc(0.25rem + 0.27836vw - 4.01113px)
    }
}

@media(min-width: 1920px) {
    .rad-accordion__stat .rad-accordion__stat-text:before {
        height:0.3333333333rem
    }
}

@media(min-width: 1441px) {
    .rad-accordion__stat .rad-accordion__stat-text:before {
        margin-bottom:calc(0.5rem + 0.55672vw - 8.02227px)
    }
}

@media(min-width: 1920px) {
    .rad-accordion__stat .rad-accordion__stat-text:before {
        margin-bottom:0.6666666667rem
    }
}

.rad-accordion__stat .rad-accordion__stat-percentage {
    font-size: 1.75rem;
    font-weight: 500;
    letter-spacing: -0.03em;
    line-height: 1.3;
    margin-bottom: 0.5rem
}

.rad-accordion__stat .rad-accordion__stat-percentage:lang(ja-JP),.rad-accordion__stat .rad-accordion__stat-percentage:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-accordion__stat .rad-accordion__stat-percentage {
        font-size:2.5rem;
        line-height: 1.2
    }
}

@media(min-width: 1024px) {
    .rad-accordion__stat .rad-accordion__stat-percentage {
        font-size:3rem;
        line-height: 1.2
    }
}

@media(min-width: 1441px) {
    .rad-accordion__stat .rad-accordion__stat-percentage {
        font-size:calc(3rem + 3.34029vw - 48.13361px)
    }
}

@media(min-width: 1920px) {
    .rad-accordion__stat .rad-accordion__stat-percentage {
        font-size:4rem
    }
}

@media(min-width: 1441px) {
    .rad-accordion__stat .rad-accordion__stat-percentage {
        margin-bottom:calc(0.5rem + 0.55672vw - 8.02227px)
    }
}

@media(min-width: 1920px) {
    .rad-accordion__stat .rad-accordion__stat-percentage {
        margin-bottom:0.6666666667rem
    }
}

.rad-accordion__stat .rad-accordion__stat-detail ol,.rad-accordion__stat .rad-accordion__stat-detail p,.rad-accordion__stat .rad-accordion__stat-detail ul {
    font-size: .875rem;
    font-weight: 500;
    font-weight: 400;
    letter-spacing: -0.005em;
    line-height: 1.5;
    text-underline-offset: .25rem
}

.rad-accordion__stat .rad-accordion__stat-detail ol:lang(ja-JP),.rad-accordion__stat .rad-accordion__stat-detail ol:lang(zh-CN),.rad-accordion__stat .rad-accordion__stat-detail p:lang(ja-JP),.rad-accordion__stat .rad-accordion__stat-detail p:lang(zh-CN),.rad-accordion__stat .rad-accordion__stat-detail ul:lang(ja-JP),.rad-accordion__stat .rad-accordion__stat-detail ul:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-accordion__stat .rad-accordion__stat-detail ol,.rad-accordion__stat .rad-accordion__stat-detail p,.rad-accordion__stat .rad-accordion__stat-detail ul {
        font-size:1rem
    }
}

@media(min-width: 1441px) {
    .rad-accordion__stat .rad-accordion__stat-detail ol,.rad-accordion__stat .rad-accordion__stat-detail p,.rad-accordion__stat .rad-accordion__stat-detail ul {
        font-size:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-accordion__stat .rad-accordion__stat-detail ol,.rad-accordion__stat .rad-accordion__stat-detail p,.rad-accordion__stat .rad-accordion__stat-detail ul {
        font-size:1.3333333333rem
    }
}

.rad-accordion__stat .rad-accordion__stat-detail ol:lang(ja-JP),.rad-accordion__stat .rad-accordion__stat-detail ol:lang(zh-CN),.rad-accordion__stat .rad-accordion__stat-detail p:lang(ja-JP),.rad-accordion__stat .rad-accordion__stat-detail p:lang(zh-CN),.rad-accordion__stat .rad-accordion__stat-detail ul:lang(ja-JP),.rad-accordion__stat .rad-accordion__stat-detail ul:lang(zh-CN) {
    font-weight: 400
}

.rad-accordion__stat .rad-accordion__more {
    margin-bottom: 2rem
}

@media(min-width: 1441px) {
    .rad-accordion__stat .rad-accordion__more {
        margin-bottom:calc(2rem + 2.22686vw - 32.08907px)
    }
}

@media(min-width: 1920px) {
    .rad-accordion__stat .rad-accordion__more {
        margin-bottom:2.6666666667rem
    }
}

@media(min-width: 600px) {
    .rad-accordion__stat .rad-accordion__content-text {
        width:calc(125% - var(--rad-spacing-sm)*3/4*5 + var(--rad-spacing-sm)*4 + var(--rad-spacing-sm)*0);
        width: calc(62.5% - var(--rad-spacing-sm)*7/8*5 + var(--rad-spacing-sm)*4 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-accordion__stat .rad-accordion__content-text {
        width:calc(41.66667% - var(--rad-spacing-sm)*11/12*5 + var(--rad-spacing-sm)*4 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 600px) {
    .rad-accordion__stat .rad-accordion__stat-content {
        -ms-flex-item-align:end;
        align-self: flex-end;
        margin-right: calc(25% - var(--rad-spacing-sm)*3/4*1 + var(--rad-spacing-sm)*1 + var(--rad-spacing-sm)*0);
        width: calc(100% - var(--rad-spacing-sm)*3/4*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-sm)*0);
        width: calc(50% - var(--rad-spacing-sm)*7/8*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-accordion__stat .rad-accordion__stat-content {
        width:calc(33.33333% - var(--rad-spacing-sm)*11/12*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 600px) {
    .rad-accordion__stat .rad-accordion__stat-content {
        margin-right:calc(12.5% - var(--rad-spacing-sm)*7/8*1 + var(--rad-spacing-sm)*1 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-accordion__stat .rad-accordion__stat-content {
        margin-right:calc(8.33333% - var(--rad-spacing-sm)*11/12*1 + var(--rad-spacing-sm)*1 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 600px) {
    .rad-accordion__stat .rad-accordion__stat-percentage,.rad-accordion__stat .rad-accordion__stat-text:before {
        margin-bottom:1rem
    }
}

@media(min-width: 1441px) {
    .rad-accordion__stat .rad-accordion__stat-percentage,.rad-accordion__stat .rad-accordion__stat-text:before {
        margin-bottom:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-accordion__stat .rad-accordion__stat-percentage,.rad-accordion__stat .rad-accordion__stat-text:before {
        margin-bottom:1.3333333333rem
    }
}

@media(min-width: 1024px) {
    .rad-accordion__stat .rad-accordion__content {
        -webkit-box-orient:horizontal;
        -webkit-box-direction: normal;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        -ms-flex-direction: row;
        flex-direction: row;
        justify-content: space-between
    }

    .rad-accordion__stat .rad-accordion__content-text {
        width: calc(150% - var(--rad-spacing-sm)*3/4*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-sm)*0);
        width: calc(75% - var(--rad-spacing-sm)*7/8*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0);
        width: calc(50% - var(--rad-spacing-sm)*11/12*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0)
    }

    .rad-accordion__stat .rad-accordion__stat-content {
        -ms-flex-item-align: stretch;
        align-self: stretch;
        width: calc(75% - var(--rad-spacing-sm)*3/4*3 + var(--rad-spacing-sm)*2 + var(--rad-spacing-sm)*0);
        width: calc(37.5% - var(--rad-spacing-sm)*7/8*3 + var(--rad-spacing-sm)*2 + var(--rad-spacing-md)*0);
        width: calc(25% - var(--rad-spacing-sm)*11/12*3 + var(--rad-spacing-sm)*2 + var(--rad-spacing-md)*0)
    }

    .rad-accordion__stat .rad-accordion__stat-percentage,.rad-accordion__stat .rad-accordion__stat-text:before {
        margin-bottom: 1.5rem
    }
}

@media(min-width: 1441px) {
    .rad-accordion__stat .rad-accordion__stat-percentage,.rad-accordion__stat .rad-accordion__stat-text:before {
        margin-bottom:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-accordion__stat .rad-accordion__stat-percentage,.rad-accordion__stat .rad-accordion__stat-text:before {
        margin-bottom:2rem
    }
}

.rad-accordion__top-image .rad-accordion__content-wrapper ol,.rad-accordion__top-image .rad-accordion__content-wrapper p,.rad-accordion__top-image .rad-accordion__content-wrapper ul {
    font-size: .875rem;
    font-weight: 500;
    font-weight: 400;
    letter-spacing: -0.005em;
    line-height: 1.5;
    text-underline-offset: .25rem
}

.rad-accordion__top-image .rad-accordion__content-wrapper ol:lang(ja-JP),.rad-accordion__top-image .rad-accordion__content-wrapper ol:lang(zh-CN),.rad-accordion__top-image .rad-accordion__content-wrapper p:lang(ja-JP),.rad-accordion__top-image .rad-accordion__content-wrapper p:lang(zh-CN),.rad-accordion__top-image .rad-accordion__content-wrapper ul:lang(ja-JP),.rad-accordion__top-image .rad-accordion__content-wrapper ul:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-accordion__top-image .rad-accordion__content-wrapper ol,.rad-accordion__top-image .rad-accordion__content-wrapper p,.rad-accordion__top-image .rad-accordion__content-wrapper ul {
        font-size:1rem
    }
}

@media(min-width: 1441px) {
    .rad-accordion__top-image .rad-accordion__content-wrapper ol,.rad-accordion__top-image .rad-accordion__content-wrapper p,.rad-accordion__top-image .rad-accordion__content-wrapper ul {
        font-size:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-accordion__top-image .rad-accordion__content-wrapper ol,.rad-accordion__top-image .rad-accordion__content-wrapper p,.rad-accordion__top-image .rad-accordion__content-wrapper ul {
        font-size:1.3333333333rem
    }
}

.rad-accordion__top-image .rad-accordion__content-wrapper ol:lang(ja-JP),.rad-accordion__top-image .rad-accordion__content-wrapper ol:lang(zh-CN),.rad-accordion__top-image .rad-accordion__content-wrapper p:lang(ja-JP),.rad-accordion__top-image .rad-accordion__content-wrapper p:lang(zh-CN),.rad-accordion__top-image .rad-accordion__content-wrapper ul:lang(ja-JP),.rad-accordion__top-image .rad-accordion__content-wrapper ul:lang(zh-CN) {
    font-weight: 400
}

.rad-accordion__top-image .rad-accordion__image {
    margin-bottom: 1rem
}

.rad-accordion__top-image .rad-accordion__detail {
    font-size: .75rem;
    font-weight: 500;
    font-weight: 400;
    letter-spacing: -0.005em;
    line-height: 1.5;
    text-underline-offset: .25rem
}

.rad-accordion__top-image .rad-accordion__detail:lang(ja-JP),.rad-accordion__top-image .rad-accordion__detail:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-accordion__top-image .rad-accordion__detail {
        font-size:0.875rem
    }
}

@media(min-width: 1441px) {
    .rad-accordion__top-image .rad-accordion__detail {
        font-size:calc(0.875rem + 0.97425vw - 14.03897px)
    }
}

@media(min-width: 1920px) {
    .rad-accordion__top-image .rad-accordion__detail {
        font-size:1.1666666667rem
    }
}

.rad-accordion__top-image .rad-accordion__detail:lang(ja-JP),.rad-accordion__top-image .rad-accordion__detail:lang(zh-CN) {
    font-weight: 400
}

@media(min-width: 600px) {
    .rad-accordion__top-image .rad-accordion__content {
        width:calc(150% - var(--rad-spacing-sm)*3/4*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-sm)*0);
        width: calc(75% - var(--rad-spacing-sm)*7/8*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-accordion__top-image .rad-accordion__content {
        -webkit-box-align:end;
        -ms-flex-align: end;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        align-items: flex-end;
        -ms-flex-direction: row;
        flex-direction: row;
        justify-content: space-between;
        width: calc(50% - var(--rad-spacing-sm)*11/12*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0);
        width: 100%
    }

    .rad-accordion__top-image .rad-accordion__image {
        margin: 0;
        width: calc(175% - var(--rad-spacing-sm)*3/4*7 + var(--rad-spacing-sm)*6 + var(--rad-spacing-sm)*0);
        width: calc(87.5% - var(--rad-spacing-sm)*7/8*7 + var(--rad-spacing-sm)*6 + var(--rad-spacing-md)*0);
        width: calc(58.33333% - var(--rad-spacing-sm)*11/12*7 + var(--rad-spacing-sm)*6 + var(--rad-spacing-md)*0)
    }

    .rad-accordion__top-image .rad-accordion__detail {
        font-size: .875rem;
        font-weight: 500;
        font-weight: 400;
        letter-spacing: -0.005em;
        line-height: 1.5;
        margin-right: calc(25% - var(--rad-spacing-sm)*3/4*1 + var(--rad-spacing-sm)*1 + var(--rad-spacing-sm)*0);
        text-underline-offset: .25rem;
        width: calc(75% - var(--rad-spacing-sm)*3/4*3 + var(--rad-spacing-sm)*2 + var(--rad-spacing-sm)*0);
        width: calc(37.5% - var(--rad-spacing-sm)*7/8*3 + var(--rad-spacing-sm)*2 + var(--rad-spacing-md)*0);
        width: calc(25% - var(--rad-spacing-sm)*11/12*3 + var(--rad-spacing-sm)*2 + var(--rad-spacing-md)*0)
    }

    .rad-accordion__top-image .rad-accordion__detail:lang(ja-JP),.rad-accordion__top-image .rad-accordion__detail:lang(zh-CN) {
        font-weight: 700
    }

    .rad-accordion__top-image .rad-accordion__detail {
        font-size: 1rem
    }
}

@media(min-width: 1441px) {
    .rad-accordion__top-image .rad-accordion__detail {
        font-size:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-accordion__top-image .rad-accordion__detail {
        font-size:1.3333333333rem
    }
}

@media(min-width: 1024px) {
    .rad-accordion__top-image .rad-accordion__detail:lang(ja-JP),.rad-accordion__top-image .rad-accordion__detail:lang(zh-CN) {
        font-weight:400
    }

    .rad-accordion__top-image .rad-accordion__detail {
        margin-right: calc(12.5% - var(--rad-spacing-sm)*7/8*1 + var(--rad-spacing-sm)*1 + var(--rad-spacing-md)*0);
        margin-right: calc(8.33333% - var(--rad-spacing-sm)*11/12*1 + var(--rad-spacing-sm)*1 + var(--rad-spacing-md)*0)
    }

    .rad-accordion__bottom-image .rad-accordion__stat-content {
        width: calc(100% - var(--rad-spacing-sm)*3/4*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-sm)*0);
        width: calc(50% - var(--rad-spacing-sm)*7/8*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0);
        width: calc(33.33333% - var(--rad-spacing-sm)*11/12*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0)
    }
}

.rad-accordion__container .experiencefragment .accordioncard:last-child .rad-accordion__item {
    border-bottom: 0
}

.rad-accordion__container .experiencefragment:hover~.experiencefragment:last-child {
    border-bottom-color: #616160
}

.rad-accordion__container .experiencefragment:hover+.accordioncard .rad-accordion__header,.rad-accordion__container .experiencefragment:hover+.experiencefragment .accordioncard .rad-accordion__header {
    border-top-color: #a100ff
}

@media(min-width: 1024px) {
    .rad-accordion-podcast .rad-accordion__header-container {
        padding-bottom:3rem
    }
}

@media(min-width: 1441px) {
    .rad-accordion-podcast .rad-accordion__header-container {
        padding-bottom:calc(3rem + 3.34029vw - 48.13361px)
    }
}

@media(min-width: 1920px) {
    .rad-accordion-podcast .rad-accordion__header-container {
        padding-bottom:4rem
    }
}

.rad-accordion-podcast .rad-accordion__headline {
    font-size: 1.75rem;
    font-weight: 500;
    letter-spacing: -0.03em;
    line-height: 1.3;
    width: 100%
}

.rad-accordion-podcast .rad-accordion__headline:lang(ja-JP),.rad-accordion-podcast .rad-accordion__headline:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-accordion-podcast .rad-accordion__headline {
        font-size:2.5rem;
        line-height: 1.2
    }
}

@media(min-width: 1024px) {
    .rad-accordion-podcast .rad-accordion__headline {
        font-size:3rem;
        line-height: 1.2
    }
}

@media(min-width: 1441px) {
    .rad-accordion-podcast .rad-accordion__headline {
        font-size:calc(3rem + 3.34029vw - 48.13361px)
    }
}

@media(min-width: 1920px) {
    .rad-accordion-podcast .rad-accordion__headline {
        font-size:4rem
    }
}

.rad-accordion-podcast .rad-accordion__subheader {
    font-family: GT Sectra Fine,Palatino;
    font-size: 1.25rem;
    font-weight: 300;
    line-height: 1.25
}

@media(min-width: 600px) {
    .rad-accordion-podcast .rad-accordion__subheader {
        font-size:1.5rem;
        line-height: 1.25
    }
}

@media(min-width: 1024px) {
    .rad-accordion-podcast .rad-accordion__subheader {
        font-size:1.75rem;
        line-height: 1.25
    }
}

@media(min-width: 1441px) {
    .rad-accordion-podcast .rad-accordion__subheader {
        font-size:calc(1.75rem + 1.9485vw - 28.07794px)
    }
}

@media(min-width: 1920px) {
    .rad-accordion-podcast .rad-accordion__subheader {
        font-size:2.3333333333rem
    }
}

.rad-accordion-podcast__count-and-sort {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-size: .875rem;
    font-weight: 500;
    justify-content: space-between;
    letter-spacing: -0.005em;
    line-height: 1.5;
    text-underline-offset: .25rem
}

.rad-accordion-podcast__count-and-sort:lang(ja-JP),.rad-accordion-podcast__count-and-sort:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-accordion-podcast__count-and-sort {
        font-size:1rem
    }
}

@media(min-width: 1441px) {
    .rad-accordion-podcast__count-and-sort {
        font-size:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-accordion-podcast__count-and-sort {
        font-size:1.3333333333rem
    }
}

.rad-accordion-podcast .rad-dropdown__category-title-selected-item {
    font-size: .875rem;
    font-weight: 500;
    letter-spacing: -0.005em;
    line-height: 1.5;
    text-underline-offset: .25rem
}

.rad-accordion-podcast .rad-dropdown__category-title-selected-item:lang(ja-JP),.rad-accordion-podcast .rad-dropdown__category-title-selected-item:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-accordion-podcast .rad-dropdown__category-title-selected-item {
        font-size:1rem
    }
}

@media(min-width: 1441px) {
    .rad-accordion-podcast .rad-dropdown__category-title-selected-item {
        font-size:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-accordion-podcast .rad-dropdown__category-title-selected-item {
        font-size:1.3333333333rem
    }
}

.rad-accordion-podcast .rad-accordion__container {
    margin-top: .5rem
}

@media(min-width: 1024px) {
    .rad-accordion-podcast .rad-accordion__container {
        margin-top:1rem
    }
}

@media(min-width: 1441px) {
    .rad-accordion-podcast .rad-accordion__container {
        margin-top:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-accordion-podcast .rad-accordion__container {
        margin-top:1.3333333333rem
    }
}

.rad-accordion-podcast .rad-accordion__header {
    -webkit-column-gap: var(--rad-spacing-sm);
    -moz-column-gap: var(--rad-spacing-sm);
    column-gap: var(--rad-spacing-sm);
    display: grid;
    grid-template-areas: "image headings icon" "details details details";
    grid-template-columns: 4.375rem 1fr 2.25rem;
    grid-template-rows: -webkit-min-content 1fr;
    grid-template-rows: min-content 1fr;
    padding: .9375rem 0;
    row-gap: 1rem
}

@media(min-width: 1441px) {
    .rad-accordion-podcast .rad-accordion__header {
        row-gap:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-accordion-podcast .rad-accordion__header {
        row-gap:1.3333333333rem
    }
}

@media(min-width: 600px) {
    .rad-accordion-podcast .rad-accordion__header {
        grid-template-areas:"image headings icon" "image details icon";
        grid-template-columns: calc(min(100vw, 1920px)/8*2 - var(--rad-spacing-md)*2/8*2 - var(--rad-spacing-sm)*7/8*2 + var(--rad-spacing-sm)*1 + var(--rad-spacing-md)*0) 1fr calc(min(100vw, 1920px)/8*1 - var(--rad-spacing-md)*2/8*1 - var(--rad-spacing-sm)*7/8*1 + var(--rad-spacing-sm)*0 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-accordion-podcast .rad-accordion__header {
        grid-template-columns:calc(min(100vw, 1920px)/12*3 - var(--rad-spacing-md)*2/12*3 - var(--rad-spacing-sm)*11/12*3 + var(--rad-spacing-sm)*2 + var(--rad-spacing-md)*0) 1fr calc(min(100vw, 1920px)/12*1 - var(--rad-spacing-md)*2/12*1 - var(--rad-spacing-sm)*11/12*1 + var(--rad-spacing-sm)*0 + var(--rad-spacing-md)*0);
        padding: 1.4375rem 0
    }
}

.rad-accordion-podcast .rad-accordion__header .cmp-image {
    grid-area: image;
    width: 4.375rem
}

@media(min-width: 600px) {
    .rad-accordion-podcast .rad-accordion__header .cmp-image {
        width:100%
    }
}

@media(min-width: 1024px) {
    .rad-accordion-podcast .rad-accordion__header .cmp-image {
        padding-right:calc(min(100vw, 1920px)/4*1 - var(--rad-spacing-sm)*2/4*1 - var(--rad-spacing-sm)*3/4*1 + var(--rad-spacing-sm)*1 + var(--rad-spacing-sm)*0);
        padding-right: calc(min(100vw, 1920px)/8*1 - var(--rad-spacing-md)*2/8*1 - var(--rad-spacing-sm)*7/8*1 + var(--rad-spacing-sm)*1 + var(--rad-spacing-md)*0);
        padding-right: calc(min(100vw, 1920px)/12*1 - var(--rad-spacing-md)*2/12*1 - var(--rad-spacing-sm)*11/12*1 + var(--rad-spacing-sm)*1 + var(--rad-spacing-md)*0)
    }
}

.rad-accordion-podcast .rad-accordion__header .cmp-image .cmp-image__image {
    aspect-ratio: 1;
    display: block;
    width: 100%
}

@media(min-width: 1024px) {
    .rad-accordion-podcast__accordion-item-details,.rad-accordion-podcast__accordion-item-headings {
        margin-left:auto;
        width: calc(min(100vw, 1920px)/4*8 - var(--rad-spacing-sm)*2/4*8 - var(--rad-spacing-sm)*3/4*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-sm)*0);
        width: calc(min(100vw, 1920px)/8*8 - var(--rad-spacing-md)*2/8*8 - var(--rad-spacing-sm)*7/8*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0);
        width: calc(min(100vw, 1920px)/12*8 - var(--rad-spacing-md)*2/12*8 - var(--rad-spacing-sm)*11/12*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0)
    }
}

.rad-accordion-podcast__accordion-item-headings {
    grid-area: headings;
    text-align: left
}

@media(min-width: 1024px) {
    .rad-accordion-podcast__accordion-item-headings {
        width:calc(min(100vw, 1920px)/4*8 - var(--rad-spacing-sm)*2/4*8 - var(--rad-spacing-sm)*3/4*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-sm)*0);
        width: calc(min(100vw, 1920px)/8*8 - var(--rad-spacing-md)*2/8*8 - var(--rad-spacing-sm)*7/8*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0);
        width: calc(min(100vw, 1920px)/12*8 - var(--rad-spacing-md)*2/12*8 - var(--rad-spacing-sm)*11/12*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0)
    }
}

.rad-accordion-podcast__episode-number-text {
    font-size: .75rem;
    font-weight: 500;
    letter-spacing: .02em;
    line-height: 1.2
}

.rad-accordion-podcast__episode-number-text:lang(ja-JP),.rad-accordion-podcast__episode-number-text:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 1024px) {
    .rad-accordion-podcast__episode-number-text {
        font-size:0.875rem
    }
}

@media(min-width: 1441px) {
    .rad-accordion-podcast__episode-number-text {
        font-size:calc(0.875rem + 0.97425vw - 14.03897px)
    }
}

@media(min-width: 1920px) {
    .rad-accordion-podcast__episode-number-text {
        font-size:1.1666666667rem
    }
}

.rad-accordion-podcast__episode-headline {
    font-size: 1.125rem;
    font-weight: 500;
    letter-spacing: -0.02em;
    line-height: 1.4;
    margin-top: .5rem
}

.rad-accordion-podcast__episode-headline:lang(ja-JP),.rad-accordion-podcast__episode-headline:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-accordion-podcast__episode-headline {
        font-size:1.25rem;
        line-height: 1.4
    }
}

@media(min-width: 1024px) {
    .rad-accordion-podcast__episode-headline {
        font-size:1.5rem;
        line-height: 1.3
    }
}

@media(min-width: 1441px) {
    .rad-accordion-podcast__episode-headline {
        font-size:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-accordion-podcast__episode-headline {
        font-size:2rem
    }
}

@media(min-width: 600px) {
    .rad-accordion-podcast__episode-headline {
        margin-top:1rem
    }
}

@media(min-width: 1441px) {
    .rad-accordion-podcast__episode-headline {
        margin-top:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-accordion-podcast__episode-headline {
        margin-top:1.3333333333rem
    }
}

.rad-accordion-podcast__accordion-item-details {
    grid-area: details;
    text-align: left
}

.rad-accordion-podcast__episode-description-body {
    font-size: .875rem;
    font-weight: 500;
    font-weight: 400;
    letter-spacing: -0.005em;
    line-height: 1.5;
    text-underline-offset: .25rem
}

.rad-accordion-podcast__episode-description-body:lang(ja-JP),.rad-accordion-podcast__episode-description-body:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-accordion-podcast__episode-description-body {
        font-size:1rem
    }
}

@media(min-width: 1024px) {
    .rad-accordion-podcast__episode-description-body {
        font-size:1.125rem;
        line-height: 1.6
    }
}

@media(min-width: 1441px) {
    .rad-accordion-podcast__episode-description-body {
        font-size:calc(1.125rem + 1.25261vw - 18.0501px)
    }
}

@media(min-width: 1920px) {
    .rad-accordion-podcast__episode-description-body {
        font-size:1.5rem
    }
}

.rad-accordion-podcast__episode-description-body:lang(ja-JP),.rad-accordion-podcast__episode-description-body:lang(zh-CN) {
    font-weight: 400
}

.rad-accordion-podcast .rad-accordion-podcast__episode-date-and-length,.rad-accordion-podcast .rad-accordion-podcast__listen-on-label,.rad-accordion-podcast .rad-accordion-podcast__related-content-label,.rad-accordion-podcast .rad-accordion-podcast__speakers-label {
    font-size: .75rem;
    font-weight: 500;
    letter-spacing: .02em;
    line-height: 1.2;
    text-transform: uppercase
}

.rad-accordion-podcast .rad-accordion-podcast__episode-date-and-length:lang(ja-JP),.rad-accordion-podcast .rad-accordion-podcast__episode-date-and-length:lang(zh-CN),.rad-accordion-podcast .rad-accordion-podcast__listen-on-label:lang(ja-JP),.rad-accordion-podcast .rad-accordion-podcast__listen-on-label:lang(zh-CN),.rad-accordion-podcast .rad-accordion-podcast__related-content-label:lang(ja-JP),.rad-accordion-podcast .rad-accordion-podcast__related-content-label:lang(zh-CN),.rad-accordion-podcast .rad-accordion-podcast__speakers-label:lang(ja-JP),.rad-accordion-podcast .rad-accordion-podcast__speakers-label:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 1024px) {
    .rad-accordion-podcast .rad-accordion-podcast__episode-date-and-length,.rad-accordion-podcast .rad-accordion-podcast__listen-on-label,.rad-accordion-podcast .rad-accordion-podcast__related-content-label,.rad-accordion-podcast .rad-accordion-podcast__speakers-label {
        font-size:0.875rem
    }
}

@media(min-width: 1441px) {
    .rad-accordion-podcast .rad-accordion-podcast__episode-date-and-length,.rad-accordion-podcast .rad-accordion-podcast__listen-on-label,.rad-accordion-podcast .rad-accordion-podcast__related-content-label,.rad-accordion-podcast .rad-accordion-podcast__speakers-label {
        font-size:calc(0.875rem + 0.97425vw - 14.03897px)
    }
}

@media(min-width: 1920px) {
    .rad-accordion-podcast .rad-accordion-podcast__episode-date-and-length,.rad-accordion-podcast .rad-accordion-podcast__listen-on-label,.rad-accordion-podcast .rad-accordion-podcast__related-content-label,.rad-accordion-podcast .rad-accordion-podcast__speakers-label {
        font-size:1.1666666667rem
    }
}

.rad-accordion-podcast ul li {
    padding-left: 0;
    translate: none
}

.rad-accordion-podcast ul li:before {
    display: none
}

.rad-accordion-podcast__episode-date-and-length {
    -webkit-column-gap: 1.5rem;
    -moz-column-gap: 1.5rem;
    column-gap: 1.5rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-top: 1rem
}

@media(min-width: 1441px) {
    .rad-accordion-podcast__episode-date-and-length {
        margin-top:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-accordion-podcast__episode-date-and-length {
        margin-top:1.3333333333rem
    }
}

@media(min-width: 600px) {
    .rad-accordion-podcast__episode-date-and-length {
        -webkit-column-gap:2rem;
        -moz-column-gap: 2rem;
        column-gap: 2rem
    }
}

@media(min-width: 1441px) {
    .rad-accordion-podcast__episode-date-and-length {
        -webkit-column-gap:calc(2rem + 2.22686vw - 32.08907px);
        -moz-column-gap: calc(2rem + 2.22686vw - 32.08907px);
        column-gap: calc(2rem + 2.22686vw - 32.08907px)
    }
}

@media(min-width: 1920px) {
    .rad-accordion-podcast__episode-date-and-length {
        -webkit-column-gap:2.6666666667rem;
        -moz-column-gap: 2.6666666667rem;
        column-gap: 2.6666666667rem
    }
}

.rad-accordion-podcast__episode-date,.rad-accordion-podcast__episode-length {
    color: #a2a2a0
}

.rad-accordion-podcast__episode-date:before,.rad-accordion-podcast__episode-length:before {
    background: #a100ff;
    content: "";
    display: inline-block;
    height: 0.5rem;
    margin-right: 0.5rem;
    width: 0.5rem
}

@media(min-width: 1441px) {
    .rad-accordion-podcast__episode-date:before,.rad-accordion-podcast__episode-length:before {
        height:calc(0.5rem + 0.55672vw - 8.02227px)
    }
}

@media(min-width: 1920px) {
    .rad-accordion-podcast__episode-date:before,.rad-accordion-podcast__episode-length:before {
        height:0.6666666667rem
    }
}

@media(min-width: 1441px) {
    .rad-accordion-podcast__episode-date:before,.rad-accordion-podcast__episode-length:before {
        margin-right:calc(0.5rem + 0.55672vw - 8.02227px)
    }
}

@media(min-width: 1920px) {
    .rad-accordion-podcast__episode-date:before,.rad-accordion-podcast__episode-length:before {
        margin-right:0.6666666667rem
    }
}

@media(min-width: 1441px) {
    .rad-accordion-podcast__episode-date:before,.rad-accordion-podcast__episode-length:before {
        width:calc(0.5rem + 0.55672vw - 8.02227px)
    }
}

@media(min-width: 1920px) {
    .rad-accordion-podcast__episode-date:before,.rad-accordion-podcast__episode-length:before {
        width:0.6666666667rem
    }
}

.rad-accordion-podcast .rad-accordion__icon-container {
    grid-area: icon
}

@media(min-width: 600px) {
    .rad-accordion-podcast .rad-accordion__icon-container {
        width:100%
    }
}

.rad-accordion-podcast .rad-accordion__content-wrapper {
    margin: -10px;
    padding: 10px
}

.rad-accordion-podcast .rad-accordion__content {
    font-size: .875rem;
    font-weight: 500;
    font-weight: 400;
    letter-spacing: -0.005em;
    line-height: 1.5;
    margin-right: 0;
    padding-bottom: 1rem;
    text-underline-offset: .25rem
}

.rad-accordion-podcast .rad-accordion__content:lang(ja-JP),.rad-accordion-podcast .rad-accordion__content:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-accordion-podcast .rad-accordion__content {
        font-size:1rem
    }
}

@media(min-width: 1441px) {
    .rad-accordion-podcast .rad-accordion__content {
        font-size:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-accordion-podcast .rad-accordion__content {
        font-size:1.3333333333rem
    }
}

.rad-accordion-podcast .rad-accordion__content:lang(ja-JP),.rad-accordion-podcast .rad-accordion__content:lang(zh-CN) {
    font-weight: 400
}

@media(min-width: 768px) {
    .rad-accordion-podcast .rad-accordion__content {
        margin-left:calc(50% - var(--rad-spacing-sm)*3/4*2 + var(--rad-spacing-sm)*2 + var(--rad-spacing-sm)*0);
        margin-left: calc(25% - var(--rad-spacing-sm)*7/8*2 + var(--rad-spacing-sm)*2 + var(--rad-spacing-md)*0);
        margin-right: 5.5rem
    }
}

@media(min-width: 1024px) {
    .rad-accordion-podcast .rad-accordion__content {
        margin-left:calc(16.66667% - var(--rad-spacing-sm)*11/12*2 + var(--rad-spacing-sm)*2 + var(--rad-spacing-md)*0);
        margin-left: calc(75% - var(--rad-spacing-sm)*3/4*3 + var(--rad-spacing-sm)*3 + var(--rad-spacing-sm)*0);
        margin-right: calc(25% - var(--rad-spacing-sm)*3/4*1 + var(--rad-spacing-sm)*1 + var(--rad-spacing-sm)*0);
        padding-bottom: 1.5rem
    }
}

@media(min-width: 1441px) {
    .rad-accordion-podcast .rad-accordion__content {
        padding-bottom:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-accordion-podcast .rad-accordion__content {
        padding-bottom:2rem
    }
}

@media(min-width: 1024px) {
    .rad-accordion-podcast .rad-accordion__content {
        margin-left:calc(37.5% - var(--rad-spacing-sm)*7/8*3 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0);
        margin-left: calc(25% - var(--rad-spacing-sm)*11/12*3 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0);
        margin-right: calc(12.5% - var(--rad-spacing-sm)*7/8*1 + var(--rad-spacing-sm)*1 + var(--rad-spacing-md)*0);
        margin-right: calc(8.33333% - var(--rad-spacing-sm)*11/12*1 + var(--rad-spacing-sm)*1 + var(--rad-spacing-md)*0)
    }
}

.rad-accordion-podcast .rad-accordion__content li {
    font-size: .875rem;
    font-weight: 500;
    font-weight: 400;
    letter-spacing: -0.005em;
    line-height: 1.5;
    text-underline-offset: .25rem
}

.rad-accordion-podcast .rad-accordion__content li:lang(ja-JP),.rad-accordion-podcast .rad-accordion__content li:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-accordion-podcast .rad-accordion__content li {
        font-size:1rem
    }
}

@media(min-width: 1441px) {
    .rad-accordion-podcast .rad-accordion__content li {
        font-size:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-accordion-podcast .rad-accordion__content li {
        font-size:1.3333333333rem
    }
}

.rad-accordion-podcast .rad-accordion__content li:lang(ja-JP),.rad-accordion-podcast .rad-accordion__content li:lang(zh-CN) {
    font-weight: 400
}

.rad-accordion-podcast .rad-accordion__content>:first-child {
    margin-top: 1.0625rem
}

@media(min-width: 1024px) {
    .rad-accordion-podcast .rad-accordion__content>:first-child {
        margin-top:0.5625rem
    }
}

@media(min-width: 1441px) {
    .rad-accordion-podcast .rad-accordion__content>:first-child {
        margin-top:calc(0.5625rem + 0.6263vw - 9.02505px)
    }
}

@media(min-width: 1920px) {
    .rad-accordion-podcast .rad-accordion__content>:first-child {
        margin-top:0.75rem
    }
}

.rad-accordion-podcast__libsyn-player iframe {
    display: block
}

.rad-accordion-podcast__libsyn-view-transcript {
    display: block;
    font-size: .875rem;
    font-weight: 500;
    letter-spacing: -0.005em;
    line-height: 1.5;
    margin-left: auto;
    margin-top: 1rem;
    text-underline-offset: .25rem;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content
}

.rad-accordion-podcast__libsyn-view-transcript:lang(ja-JP),.rad-accordion-podcast__libsyn-view-transcript:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-accordion-podcast__libsyn-view-transcript {
        font-size:1rem
    }
}

@media(min-width: 1441px) {
    .rad-accordion-podcast__libsyn-view-transcript {
        font-size:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-accordion-podcast__libsyn-view-transcript {
        font-size:1.3333333333rem
    }
}

@media(min-width: 1441px) {
    .rad-accordion-podcast__libsyn-view-transcript {
        margin-top:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-accordion-podcast__libsyn-view-transcript {
        margin-top:1.3333333333rem
    }
}

.rad-accordion-podcast__speakers {
    margin-top: 2rem
}

@media(min-width: 1441px) {
    .rad-accordion-podcast__speakers {
        margin-top:calc(2rem + 2.22686vw - 32.08907px)
    }
}

@media(min-width: 1920px) {
    .rad-accordion-podcast__speakers {
        margin-top:2.6666666667rem
    }
}

.rad-accordion-podcast .rad-accordion-podcast__speakers-list {
    margin-top: 1rem
}

@media(min-width: 1441px) {
    .rad-accordion-podcast .rad-accordion-podcast__speakers-list {
        margin-top:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-accordion-podcast .rad-accordion-podcast__speakers-list {
        margin-top:1.3333333333rem
    }
}

.rad-accordion-podcast__speaker-info {
    line-height: 1.5;
    margin-top: 0.5rem
}

@media(min-width: 1441px) {
    .rad-accordion-podcast__speaker-info {
        margin-top:calc(0.5rem + 0.55672vw - 8.02227px)
    }
}

@media(min-width: 1920px) {
    .rad-accordion-podcast__speaker-info {
        margin-top:0.6666666667rem
    }
}

.rad-accordion-podcast__speaker-name {
    color: #fff;
    font-weight: 500
}

.rad-accordion-podcast__speaker-name:after {
    content: ","
}

.rad-accordion-podcast__speaker-job {
    color: #a2a2a0
}

.rad-accordion-podcast__listen-on {
    margin-top: 2rem
}

@media(min-width: 1441px) {
    .rad-accordion-podcast__listen-on {
        margin-top:calc(2rem + 2.22686vw - 32.08907px)
    }
}

@media(min-width: 1920px) {
    .rad-accordion-podcast__listen-on {
        margin-top:2.6666666667rem
    }
}

.rad-accordion-podcast .rad-accordion-podcast__listen-on-list {
    margin-top: 1rem
}

@media(min-width: 1441px) {
    .rad-accordion-podcast .rad-accordion-podcast__listen-on-list {
        margin-top:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-accordion-podcast .rad-accordion-podcast__listen-on-list {
        margin-top:1.3333333333rem
    }
}

@media(min-width: 600px) {
    .rad-accordion-podcast .rad-accordion-podcast__listen-on-list {
        display:-webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        gap: 1.5rem
    }
}

@media(min-width: 1024px) {
    .rad-accordion-podcast .rad-accordion-podcast__listen-on-list {
        -webkit-column-gap:2rem;
        -moz-column-gap: 2rem;
        column-gap: 2rem;
        row-gap: 1.5rem
    }
}

@media(min-width: 1441px) {
    .rad-accordion-podcast .rad-accordion-podcast__listen-on-list {
        -webkit-column-gap:calc(2rem + 2.22686vw - 32.08907px);
        -moz-column-gap: calc(2rem + 2.22686vw - 32.08907px);
        column-gap: calc(2rem + 2.22686vw - 32.08907px)
    }
}

@media(min-width: 1920px) {
    .rad-accordion-podcast .rad-accordion-podcast__listen-on-list {
        -webkit-column-gap:2.6666666667rem;
        -moz-column-gap: 2.6666666667rem;
        column-gap: 2.6666666667rem
    }
}

@media(min-width: 1441px) {
    .rad-accordion-podcast .rad-accordion-podcast__listen-on-list {
        row-gap:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-accordion-podcast .rad-accordion-podcast__listen-on-list {
        row-gap:2rem
    }
}

.rad-accordion-podcast__listen-on-badge {
    margin-top: 1rem
}

@media(min-width: 600px) {
    .rad-accordion-podcast__listen-on-badge {
        margin-top:0
    }
}

.rad-accordion-podcast__listen-on-badge .cmp-image__image {
    display: block
}

.rad-accordion-podcast__related-content {
    margin-top: 2rem
}

@media(min-width: 1441px) {
    .rad-accordion-podcast__related-content {
        margin-top:calc(2rem + 2.22686vw - 32.08907px)
    }
}

@media(min-width: 1920px) {
    .rad-accordion-podcast__related-content {
        margin-top:2.6666666667rem
    }
}

.rad-accordion-podcast .rad-accordion-podcast__related-content-body {
    font-size: .875rem;
    font-weight: 500;
    font-weight: 400;
    letter-spacing: -0.005em;
    line-height: 1.5;
    margin-top: 1rem;
    text-underline-offset: .25rem
}

@media(min-width: 1441px) {
    .rad-accordion-podcast .rad-accordion-podcast__related-content-body {
        margin-top:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-accordion-podcast .rad-accordion-podcast__related-content-body {
        margin-top:1.3333333333rem
    }
}

.rad-accordion-podcast .rad-accordion-podcast__related-content-body:lang(ja-JP),.rad-accordion-podcast .rad-accordion-podcast__related-content-body:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-accordion-podcast .rad-accordion-podcast__related-content-body {
        font-size:1rem
    }
}

@media(min-width: 1441px) {
    .rad-accordion-podcast .rad-accordion-podcast__related-content-body {
        font-size:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-accordion-podcast .rad-accordion-podcast__related-content-body {
        font-size:1.3333333333rem
    }
}

.rad-accordion-podcast .rad-accordion-podcast__related-content-body:lang(ja-JP),.rad-accordion-podcast .rad-accordion-podcast__related-content-body:lang(zh-CN) {
    font-weight: 400
}

.rad-accordion-podcast .rad-accordion-podcast__related-content-body a {
    font-size: .875rem;
    font-weight: 500;
    letter-spacing: -0.005em;
    line-height: 1.5;
    text-underline-offset: .25rem
}

.rad-accordion-podcast .rad-accordion-podcast__related-content-body a:lang(ja-JP),.rad-accordion-podcast .rad-accordion-podcast__related-content-body a:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-accordion-podcast .rad-accordion-podcast__related-content-body a {
        font-size:1rem
    }
}

@media(min-width: 1441px) {
    .rad-accordion-podcast .rad-accordion-podcast__related-content-body a {
        font-size:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-accordion-podcast .rad-accordion-podcast__related-content-body a {
        font-size:1.3333333333rem
    }
}

.rad-accordion-podcast .rad-accordion-podcast__related-content-body p,.rad-accordion-podcast .rad-accordion-podcast__related-content-body span {
    font-size: .875rem;
    font-weight: 500;
    font-weight: 400;
    letter-spacing: -0.005em;
    line-height: 1.5;
    text-underline-offset: .25rem
}

.rad-accordion-podcast .rad-accordion-podcast__related-content-body p:lang(ja-JP),.rad-accordion-podcast .rad-accordion-podcast__related-content-body p:lang(zh-CN),.rad-accordion-podcast .rad-accordion-podcast__related-content-body span:lang(ja-JP),.rad-accordion-podcast .rad-accordion-podcast__related-content-body span:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-accordion-podcast .rad-accordion-podcast__related-content-body p,.rad-accordion-podcast .rad-accordion-podcast__related-content-body span {
        font-size:1rem
    }
}

@media(min-width: 1441px) {
    .rad-accordion-podcast .rad-accordion-podcast__related-content-body p,.rad-accordion-podcast .rad-accordion-podcast__related-content-body span {
        font-size:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-accordion-podcast .rad-accordion-podcast__related-content-body p,.rad-accordion-podcast .rad-accordion-podcast__related-content-body span {
        font-size:1.3333333333rem
    }
}

.rad-accordion-podcast .rad-accordion-podcast__related-content-body p:lang(ja-JP),.rad-accordion-podcast .rad-accordion-podcast__related-content-body p:lang(zh-CN),.rad-accordion-podcast .rad-accordion-podcast__related-content-body span:lang(ja-JP),.rad-accordion-podcast .rad-accordion-podcast__related-content-body span:lang(zh-CN) {
    font-weight: 400
}

.rad-accordion-podcast__load-more {
    margin-left: auto;
    margin-right: auto;
    margin-top: .5rem
}

@media(min-width: 1024px) {
    .rad-accordion-podcast__load-more {
        margin-top:1rem
    }
}

@media(min-width: 1441px) {
    .rad-accordion-podcast__load-more {
        margin-top:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-accordion-podcast__load-more {
        margin-top:1.3333333333rem
    }
}

.rad-accordion-podcast__load-more--hidden {
    display: none
}

.rad-mega-accordion {
    padding-left: var(--rad-spacing-sm);
    padding-right: var(--rad-spacing-sm)
}

@media(min-width: 600px) {
    .rad-mega-accordion {
        padding-left:var(--rad-spacing-md);
        padding-right: var(--rad-spacing-md)
    }
}

.rad-component--nested .rad-mega-accordion {
    padding-left: 0;
    padding-right: 0
}

.rad-mega-accordion__item {
    display: grid;
    grid-template-areas: "number icon icon" "headline headline headline" "subheader subheader subheader" "content content content";
    grid-template-columns: calc(min(100vw, 1920px)/4*1 - var(--rad-spacing-sm)*2/4*1 - var(--rad-spacing-sm)*3/4*1 + var(--rad-spacing-sm)*0 + var(--rad-spacing-sm)*0) 1fr calc(min(100vw, 1920px)/4*1 - var(--rad-spacing-sm)*2/4*1 - var(--rad-spacing-sm)*3/4*1 + var(--rad-spacing-sm)*0 + var(--rad-spacing-sm)*0);
    padding: .5rem 0 1.5rem
}

@media(min-width: 600px) {
    .rad-mega-accordion__item {
        grid-template-areas:"number icon icon" "headline headline headline" "subheader subheader subheader" "content content content";
        grid-template-columns: calc(min(100vw, 1920px)/7*1 - var(--rad-spacing-md)*2/7*1 - var(--rad-spacing-sm)*6/7*1 + var(--rad-spacing-sm)*0 + var(--rad-spacing-md)*0) 1fr calc(min(100vw, 1920px)/7*1 - var(--rad-spacing-md)*2/7*1 - var(--rad-spacing-sm)*6/7*1 + var(--rad-spacing-sm)*0 + var(--rad-spacing-md)*0);
        padding: 1rem 0 3rem
    }
}

@media(min-width: 1024px) {
    .rad-mega-accordion__item {
        grid-template-areas:"number headline icon" "number subheader icon" "content content content";
        grid-template-columns: calc(min(100vw, 1920px)/12*1 - var(--rad-spacing-md)*2/12*1 - var(--rad-spacing-sm)*11/12*1 + var(--rad-spacing-sm)*0 + var(--rad-spacing-md)*0) 1fr calc(min(100vw, 1920px)/12*3 - var(--rad-spacing-md)*2/12*3 - var(--rad-spacing-sm)*11/12*3 + var(--rad-spacing-sm)*2 + var(--rad-spacing-md)*0);
        padding-bottom: 3rem;
        padding-top: 3rem;
        position: relative
    }
}

@media(min-width: 1441px) {
    .rad-mega-accordion__item {
        padding-top:calc(3rem + 3.34029vw - 48.13361px)
    }
}

@media(min-width: 1920px) {
    .rad-mega-accordion__item {
        padding-top:4rem
    }
}

@media(min-width: 1441px) {
    .rad-mega-accordion__item {
        padding-bottom:calc(3rem + 3.34029vw - 48.13361px)
    }
}

@media(min-width: 1920px) {
    .rad-mega-accordion__item {
        padding-bottom:4rem
    }
}

.rad-mega-accordion__item.rad-mega-accordion__item--open .rad-mega-accordion__icon:after {
    rotate: 0deg
}

.rad-mega-accordion__item.rad-mega-accordion__item--open .rad-mega-accordion__content-wrapper {
    visibility: visible
}

.rad-mega-accordion__item.rad-mega-accordion__item--open.rad-mega-accordion__item--small-toggle .rad-mega-accordion__desktop-open {
    display: none
}

.rad-mega-accordion__item.rad-mega-accordion__item--open.rad-mega-accordion__item--small-toggle .rad-mega-accordion__desktop-close {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.rad-mega-accordion__item.rad-mega-accordion__item-no-number {
    grid-template-areas: "icon icon icon" "headline headline headline" "subheader subheader subheader" "content content content"
}

@media(min-width: 1024px) {
    .rad-mega-accordion__item.rad-mega-accordion__item-no-number {
        grid-template-areas:"number headline icon" "number subheader icon" "content content content"
    }
}

.rad-mega-accordion__headline {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: start;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 1.5rem;
    font-weight: 500;
    grid-area: headline;
    justify-content: flex-start;
    letter-spacing: -0.03em;
    line-height: 1.35;
    padding-top: 0.75rem
}

.rad-mega-accordion__headline:lang(ja-JP),.rad-mega-accordion__headline:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-mega-accordion__headline {
        font-size:2rem;
        line-height: 1.2
    }
}

@media(min-width: 1024px) {
    .rad-mega-accordion__headline {
        font-size:2.5rem;
        line-height: 1.2
    }
}

@media(min-width: 1441px) {
    .rad-mega-accordion__headline {
        font-size:calc(2.5rem + 2.78358vw - 40.11134px)
    }
}

@media(min-width: 1920px) {
    .rad-mega-accordion__headline {
        font-size:3.3333333333rem
    }
}

@media(min-width: 1441px) {
    .rad-mega-accordion__headline {
        padding-top:calc(0.75rem + 0.83507vw - 12.0334px)
    }
}

@media(min-width: 1920px) {
    .rad-mega-accordion__headline {
        padding-top:1rem
    }
}

@media(min-width: 600px) {
    .rad-mega-accordion__headline {
        width:calc(100%*(min(100vw, 1920px)/4 - var(--rad-spacing-sm)*2/4 - var(--rad-spacing-sm)*3/4) + 99%*var(--rad-spacing-sm) + var(--rad-spacing-sm)*0);
        width: calc(100%*(min(100vw, 1920px)/8 - var(--rad-spacing-md)*2/8 - var(--rad-spacing-sm)*7/8) + 99%*var(--rad-spacing-sm) + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-mega-accordion__headline {
        width:calc(100%*(min(100vw, 1920px)/12 - var(--rad-spacing-md)*2/12 - var(--rad-spacing-sm)*11/12) + 99%*var(--rad-spacing-sm) + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 600px) {
    .rad-mega-accordion__headline.rad-mega-accordion--no-left-padding .rad-mega-accordion__headline {
        grid-column-end:headline;
        grid-column-start: number;
        grid-row-end: headline;
        grid-row-start: headline;
        margin-left: 0
    }
}

@media(min-width: 1024px) {
    .rad-mega-accordion__headline {
        margin-left:calc(min(100vw, 1920px)/4*0 - var(--rad-spacing-sm)*2/4*0 - var(--rad-spacing-sm)*3/4*0 + var(--rad-spacing-sm)*2 + var(--rad-spacing-sm)*0);
        margin-left: calc(min(100vw, 1920px)/8*0 - var(--rad-spacing-md)*2/8*0 - var(--rad-spacing-sm)*7/8*0 + var(--rad-spacing-sm)*2 + var(--rad-spacing-md)*0);
        margin-left: calc(min(100vw, 1920px)/12*0 - var(--rad-spacing-md)*2/12*0 - var(--rad-spacing-sm)*11/12*0 + var(--rad-spacing-sm)*2 + var(--rad-spacing-md)*0);
        width: calc(min(100vw, 1920px)/4*7 - var(--rad-spacing-sm)*2/4*7 - var(--rad-spacing-sm)*3/4*7 + var(--rad-spacing-sm)*6 + var(--rad-spacing-sm)*0);
        width: calc(min(100vw, 1920px)/8*7 - var(--rad-spacing-md)*2/8*7 - var(--rad-spacing-sm)*7/8*7 + var(--rad-spacing-sm)*6 + var(--rad-spacing-md)*0);
        width: calc(min(100vw, 1920px)/12*7 - var(--rad-spacing-md)*2/12*7 - var(--rad-spacing-sm)*11/12*7 + var(--rad-spacing-sm)*6 + var(--rad-spacing-md)*0)
    }

    .rad-mega-accordion__headline.rad-mega-accordion--no-left-padding {
        grid-column-end: headline;
        grid-column-start: number;
        grid-row-end: headline;
        grid-row-start: headline;
        margin-left: 0
    }
}

.rad-mega-accordion__subheader {
    font-family: GT Sectra Fine,Palatino;
    font-size: 1.125rem;
    font-weight: 300;
    grid-area: subheader;
    line-height: 1.3;
    padding-top: 0.5rem
}

@media(min-width: 600px) {
    .rad-mega-accordion__subheader {
        font-size:1.25rem;
        line-height: 1.3
    }
}

@media(min-width: 1024px) {
    .rad-mega-accordion__subheader {
        font-size:1.5rem;
        line-height: 1.25
    }
}

@media(min-width: 1441px) {
    .rad-mega-accordion__subheader {
        font-size:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-mega-accordion__subheader {
        font-size:2rem
    }
}

@media(min-width: 1441px) {
    .rad-mega-accordion__subheader {
        padding-top:calc(0.5rem + 0.55672vw - 8.02227px)
    }
}

@media(min-width: 1920px) {
    .rad-mega-accordion__subheader {
        padding-top:0.6666666667rem
    }
}

@media(min-width: 600px) {
    .rad-mega-accordion__subheader {
        width:calc(min(100vw, 1920px)/4*6 - var(--rad-spacing-sm)*2/4*6 - var(--rad-spacing-sm)*3/4*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-sm)*0);
        width: calc(min(100vw, 1920px)/8*6 - var(--rad-spacing-md)*2/8*6 - var(--rad-spacing-sm)*7/8*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-mega-accordion__subheader {
        width:calc(min(100vw, 1920px)/12*6 - var(--rad-spacing-md)*2/12*6 - var(--rad-spacing-sm)*11/12*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 600px) {
    .rad-mega-accordion__subheader.rad-mega-accordion--no-left-padding .rad-mega-accordion__subheader {
        grid-column-end:subheader;
        grid-column-start: number;
        grid-row-end: subheader;
        grid-row-start: subheader;
        margin-left: 0
    }
}

@media(min-width: 1024px) {
    .rad-mega-accordion__subheader {
        margin-left:calc(min(100vw, 1920px)/4*0 - var(--rad-spacing-sm)*2/4*0 - var(--rad-spacing-sm)*3/4*0 + var(--rad-spacing-sm)*2 + var(--rad-spacing-sm)*0);
        margin-left: calc(min(100vw, 1920px)/8*0 - var(--rad-spacing-md)*2/8*0 - var(--rad-spacing-sm)*7/8*0 + var(--rad-spacing-sm)*2 + var(--rad-spacing-md)*0);
        margin-left: calc(min(100vw, 1920px)/12*0 - var(--rad-spacing-md)*2/12*0 - var(--rad-spacing-sm)*11/12*0 + var(--rad-spacing-sm)*2 + var(--rad-spacing-md)*0);
        width: calc(min(100vw, 1920px)/4*7 - var(--rad-spacing-sm)*2/4*7 - var(--rad-spacing-sm)*3/4*7 + var(--rad-spacing-sm)*6 + var(--rad-spacing-sm)*0);
        width: calc(min(100vw, 1920px)/8*7 - var(--rad-spacing-md)*2/8*7 - var(--rad-spacing-sm)*7/8*7 + var(--rad-spacing-sm)*6 + var(--rad-spacing-md)*0);
        width: calc(min(100vw, 1920px)/12*7 - var(--rad-spacing-md)*2/12*7 - var(--rad-spacing-sm)*11/12*7 + var(--rad-spacing-sm)*6 + var(--rad-spacing-md)*0)
    }

    .rad-mega-accordion__subheader.rad-mega-accordion--no-left-padding {
        grid-column-end: subheader;
        grid-column-start: number;
        grid-row-end: subheader;
        grid-row-start: subheader;
        margin-left: 0
    }
}

.rad-mega-accordion__number {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: start;
    background-color: #000;
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 1.5rem;
    font-weight: 500;
    grid-area: number;
    grid-row: 1/span 1;
    height: 3.5rem;
    justify-content: flex-start;
    letter-spacing: -0.03em;
    line-height: 1.35;
    padding-bottom: 0.75rem;
    padding-top: 0.75rem;
    z-index: 1
}

.rad-mega-accordion__number:lang(ja-JP),.rad-mega-accordion__number:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-mega-accordion__number {
        font-size:2rem;
        line-height: 1.2
    }
}

@media(min-width: 1024px) {
    .rad-mega-accordion__number {
        font-size:2.5rem;
        line-height: 1.2
    }
}

@media(min-width: 1441px) {
    .rad-mega-accordion__number {
        font-size:calc(2.5rem + 2.78358vw - 40.11134px)
    }
}

@media(min-width: 1920px) {
    .rad-mega-accordion__number {
        font-size:3.3333333333rem
    }
}

@media(min-width: 1441px) {
    .rad-mega-accordion__number {
        padding-top:calc(0.75rem + 0.83507vw - 12.0334px)
    }
}

@media(min-width: 1920px) {
    .rad-mega-accordion__number {
        padding-top:1rem
    }
}

@media(min-width: 1441px) {
    .rad-mega-accordion__number {
        padding-bottom:calc(0.75rem + 0.83507vw - 12.0334px)
    }
}

@media(min-width: 1920px) {
    .rad-mega-accordion__number {
        padding-bottom:1rem
    }
}

@media(min-width: 600px) {
    .rad-mega-accordion__number {
        height:4rem
    }
}

@media(min-width: 1024px) {
    .rad-mega-accordion__number {
        height:4.5rem
    }
}

@media(min-width: 1441px) {
    .rad-mega-accordion__number {
        height:calc(4.5rem + 5.01044vw - 72.20042px)
    }
}

@media(min-width: 1920px) {
    .rad-mega-accordion__number {
        height:6rem
    }
}

.rad-mega-accordion__icon-container {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: start;
    background-color: #000;
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    grid-area: icon;
    grid-row: 1/span 1;
    height: 3.5rem;
    justify-content: flex-end;
    padding-bottom: 0.75rem;
    padding-top: 0.75rem;
    z-index: 1
}

@media(min-width: 1441px) {
    .rad-mega-accordion__icon-container {
        padding-top:calc(0.75rem + 0.83507vw - 12.0334px)
    }
}

@media(min-width: 1920px) {
    .rad-mega-accordion__icon-container {
        padding-top:1rem
    }
}

@media(min-width: 1441px) {
    .rad-mega-accordion__icon-container {
        padding-bottom:calc(0.75rem + 0.83507vw - 12.0334px)
    }
}

@media(min-width: 1920px) {
    .rad-mega-accordion__icon-container {
        padding-bottom:1rem
    }
}

@media(min-width: 600px) {
    .rad-mega-accordion__icon-container {
        height:4rem
    }
}

@media(min-width: 1024px) {
    .rad-mega-accordion__icon-container {
        height:4.5rem;
        z-index: 1
    }
}

@media(min-width: 1441px) {
    .rad-mega-accordion__icon-container {
        height:calc(4.5rem + 5.01044vw - 72.20042px)
    }
}

@media(min-width: 1920px) {
    .rad-mega-accordion__icon-container {
        height:6rem
    }
}

.rad-mega-accordion__icon {
    grid-area: icon;
    height: 24px;
    position: relative;
    width: 24px
}

@media(min-width: 1441px) {
    .rad-mega-accordion__icon {
        height:calc(-0.06681px + 1.67015vw)
    }
}

@media(min-width: 1920px) {
    .rad-mega-accordion__icon {
        height:32px
    }
}

@media(min-width: 1441px) {
    .rad-mega-accordion__icon {
        width:calc(-0.06681px + 1.67015vw)
    }
}

@media(min-width: 1920px) {
    .rad-mega-accordion__icon {
        width:32px
    }
}

.rad-mega-accordion__icon:after,.rad-mega-accordion__icon:before {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #fff;
    content: "";
    display: block;
    height: 2px;
    position: absolute;
    top: 50%;
    -webkit-transition-duration: 550ms;
    transition-duration: 550ms;
    -webkit-transition-property: rotate;
    transition-property: rotate;
    -webkit-transition-timing-function: cubic-bezier(0.85,0,0,1);
    transition-timing-function: cubic-bezier(0.85,0,0,1);
    translate: 0 -50%;
    width: 100%
}

@media(min-width: 1441px) {
    .rad-mega-accordion__icon:after,.rad-mega-accordion__icon:before {
        height:calc(-0.00557px + 0.13918vw)
    }
}

@media(min-width: 1920px) {
    .rad-mega-accordion__icon:after,.rad-mega-accordion__icon:before {
        height:2.6666666667px
    }
}

.rad-mega-accordion__icon:after {
    rotate: -90deg
}

@media(min-width: 600px) {
    .rad-mega-accordion__icon {
        height:40px;
        width: 40px
    }
}

@media(min-width: 1441px) {
    .rad-mega-accordion__icon {
        height:calc(-0.11134px + 2.78358vw)
    }
}

@media(min-width: 1920px) {
    .rad-mega-accordion__icon {
        height:53.3333333333px
    }
}

@media(min-width: 1441px) {
    .rad-mega-accordion__icon {
        width:calc(-0.11134px + 2.78358vw)
    }
}

@media(min-width: 1920px) {
    .rad-mega-accordion__icon {
        width:53.3333333333px
    }
}

@media(min-width: 1024px) {
    .rad-mega-accordion__icon {
        height:48px;
        width: 48px
    }
}

@media(min-width: 1441px) {
    .rad-mega-accordion__icon {
        height:calc(-0.13361px + 3.34029vw)
    }
}

@media(min-width: 1920px) {
    .rad-mega-accordion__icon {
        height:64px
    }
}

@media(min-width: 1441px) {
    .rad-mega-accordion__icon {
        width:calc(-0.13361px + 3.34029vw)
    }
}

@media(min-width: 1920px) {
    .rad-mega-accordion__icon {
        width:64px
    }
}

.rad-mega-accordion__desktop-open {
    display: none
}

@media(min-width: 1024px) {
    .rad-mega-accordion__desktop-open {
        bottom:0;
        display: block;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 2
    }
}

.rad-mega-accordion__desktop-close {
    display: none
}

@media(min-width: 1024px) {
    .rad-mega-accordion__desktop-close {
        -webkit-box-pack:end;
        -ms-flex-pack: end;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        background-color: #000;
        -ms-flex-direction: row;
        flex-direction: row;
        gap: 0.5rem;
        grid-area: icon;
        height: 4.5rem;
        justify-content: flex-end;
        padding-right: 0.5rem;
        position: sticky
    }
}

@media(min-width: 1441px) {
    .rad-mega-accordion__desktop-close {
        gap:calc(0.5rem + 0.55672vw - 8.02227px)
    }
}

@media(min-width: 1920px) {
    .rad-mega-accordion__desktop-close {
        gap:0.6666666667rem
    }
}

@media(min-width: 1441px) {
    .rad-mega-accordion__desktop-close {
        padding-right:calc(0.5rem + 0.55672vw - 8.02227px)
    }
}

@media(min-width: 1920px) {
    .rad-mega-accordion__desktop-close {
        padding-right:0.6666666667rem
    }
}

@media(min-width: 1441px) {
    .rad-mega-accordion__desktop-close {
        height:calc(4.5rem + 5.01044vw - 72.20042px)
    }
}

@media(min-width: 1920px) {
    .rad-mega-accordion__desktop-close {
        height:6rem
    }
}

@media(min-width: 1024px) {
    .rad-mega-accordion__desktop-close-text {
        font-size:.875rem;
        font-weight: 500;
        letter-spacing: -0.005em;
        line-height: 1.5;
        position: relative;
        text-underline-offset: .25rem
    }

    .rad-mega-accordion__desktop-close-text:lang(ja-JP),.rad-mega-accordion__desktop-close-text:lang(zh-CN) {
        font-weight: 700
    }

    .rad-mega-accordion__desktop-close-text {
        font-size: 1rem
    }
}

@media(min-width: 1441px) {
    .rad-mega-accordion__desktop-close-text {
        font-size:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-mega-accordion__desktop-close-text {
        font-size:1.3333333333rem
    }
}

@media(min-width: 1024px) {
    .rad-mega-accordion__desktop-close-text:after {
        border-bottom:1px solid;
        bottom: 0;
        content: "";
        display: block;
        left: 0;
        position: absolute;
        -webkit-transition-duration: 550ms;
        transition-duration: 550ms;
        -webkit-transition-property: width;
        transition-property: width;
        -webkit-transition-timing-function: cubic-bezier(0.85,0,0,1);
        transition-timing-function: cubic-bezier(0.85,0,0,1);
        width: 0
    }

    :hover>.rad-mega-accordion__desktop-close-text:after {
        width: 100%
    }

    .rad-mega-accordion__desktop-close-icon {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        height: 3rem;
        position: relative;
        rotate: 45deg;
        width: 3rem
    }
}

@media(min-width: 1441px) {
    .rad-mega-accordion__desktop-close-icon {
        height:calc(3rem + 3.34029vw - 48.13361px)
    }
}

@media(min-width: 1920px) {
    .rad-mega-accordion__desktop-close-icon {
        height:4rem
    }
}

@media(min-width: 1441px) {
    .rad-mega-accordion__desktop-close-icon {
        width:calc(3rem + 3.34029vw - 48.13361px)
    }
}

@media(min-width: 1920px) {
    .rad-mega-accordion__desktop-close-icon {
        width:4rem
    }
}

@media(min-width: 1024px) {
    .rad-mega-accordion__desktop-close-icon:after,.rad-mega-accordion__desktop-close-icon:before {
        -webkit-box-align:center;
        -ms-flex-align: center;
        align-items: center;
        background-color: #fff;
        content: "";
        display: block;
        height: 2px;
        position: absolute;
        top: 50%;
        -webkit-transition-duration: 550ms;
        transition-duration: 550ms;
        -webkit-transition-property: rotate;
        transition-property: rotate;
        -webkit-transition-timing-function: cubic-bezier(0.85,0,0,1);
        transition-timing-function: cubic-bezier(0.85,0,0,1);
        translate: 0 -50%;
        width: 100%
    }
}

@media(min-width: 1441px) {
    .rad-mega-accordion__desktop-close-icon:after,.rad-mega-accordion__desktop-close-icon:before {
        height:calc(-0.00557px + 0.13918vw)
    }
}

@media(min-width: 1920px) {
    .rad-mega-accordion__desktop-close-icon:after,.rad-mega-accordion__desktop-close-icon:before {
        height:2.6666666667px
    }
}

@media(min-width: 1024px) {
    .rad-mega-accordion__desktop-close-icon:after {
        rotate:-90deg
    }
}

@media(max-width: 1023px) {
    .rad-mega-accordion__desktop-close-text {
        display:none
    }
}

.rad-mega-accordion .rad-mega-accordion__container--open:hover .megaaccordioncard .rad-accordion__purple {
    border-top-color: #a100ff
}

.rad-mega-accordion .rad-mega-accordion__container--open .rad-mega-accordion__item {
    border-top-color: #616160;
    color: #a2a2a0
}

.rad-mega-accordion .rad-mega-accordion__container--open .rad-mega-accordion__item .rad-mega-accordion__icon:after,.rad-mega-accordion .rad-mega-accordion__container--open .rad-mega-accordion__item .rad-mega-accordion__icon:before {
    background-color: #a2a2a0
}

.rad-mega-accordion .rad-mega-accordion__container--open .rad-mega-accordion__purple {
    border-top: 2px solid #a100ff
}

.rad-mega-accordion .rad-mega-accordion__container--open .megaaccordioncard:last-child .rad-mega-accordion__item {
    border-bottom-color: #616160
}

.rad-mega-accordion__container {
    pointer-events: none
}

.rad-mega-accordion__container .megaaccordioncard .rad-mega-accordion__item.rad-mega-accordion__item--open {
    border-color: #a100ff;
    color: #fff
}

.rad-mega-accordion__container .megaaccordioncard .rad-mega-accordion__item.rad-mega-accordion__item--open .rad-mega-accordion__icon:after,.rad-mega-accordion__container .megaaccordioncard .rad-mega-accordion__item.rad-mega-accordion__item--open .rad-mega-accordion__icon:before {
    background-color: #fff
}

.rad-mega-accordion__container:hover .megaaccordioncard .rad-mega-accordion__item {
    border-top-color: #616160;
    color: #a2a2a0
}

.rad-mega-accordion__container:hover .megaaccordioncard .rad-mega-accordion__item.rad-mega-accordion__item--open {
    border-top-color: #a100ff;
    color: #fff
}

.rad-mega-accordion__container:hover .megaaccordioncard:last-child .rad-mega-accordion__item {
    border-bottom-color: #616160
}

.rad-mega-accordion .rad-mega-accordion__item {
    border-top: 2px solid #a100ff
}

@media(max-width: 1023px) {
    .rad-mega-accordion .rad-mega-accordion__item--open .rad-mega-accordion__icon-container,.rad-mega-accordion .rad-mega-accordion__item--open .rad-mega-accordion__number {
        position:sticky;
        top: 0
    }
}

@media(min-width: 1024px) {
    .rad-mega-accordion .rad-mega-accordion__item--open .rad-mega-accordion__desktop-close {
        top:0;
        z-index: 2
    }
}

.rad-mega-accordion .megaaccordioncard {
    pointer-events: auto
}

.rad-mega-accordion .megaaccordioncard:last-child .rad-mega-accordion__item {
    border-bottom: 2px solid #a100ff
}

.rad-mega-accordion .megaaccordioncard:last-child .rad-mega-accordion__item.rad-mega-accordion__item--open {
    border-color: #a100ff
}

.rad-mega-accordion .megaaccordioncard:hover .rad-mega-accordion__item {
    border-color: #a100ff;
    color: #fff
}

.rad-mega-accordion .megaaccordioncard:hover:last-child .rad-mega-accordion__item {
    border-bottom-color: #a100ff
}

.rad-mega-accordion .megaaccordioncard:hover .rad-mega-accordion__icon:after,.rad-mega-accordion .megaaccordioncard:hover .rad-mega-accordion__icon:before {
    background-color: #fff
}

.rad-mega-accordion .megaaccordioncard:hover+.megaaccordioncard .rad-mega-accordion__item {
    border-top: 2px solid #a100ff
}

.rad-mega-accordion__content-wrapper {
    grid-area: content;
    max-height: 0;
    overflow: hidden;
    -webkit-transition: max-height 550ms cubic-bezier(0.85,0,0,1),visibility 550ms cubic-bezier(0.85,0,0,1);
    transition: max-height 550ms cubic-bezier(0.85,0,0,1),visibility 550ms cubic-bezier(0.85,0,0,1);
    visibility: hidden
}

.rad-mega-accordion__content-wrapper ol li {
    translate: .375rem
}

.rad-mega-accordion__content-wrapper ul li {
    translate: .125rem
}

.rad-mega-accordion__content-wrapper ol,.rad-mega-accordion__content-wrapper p,.rad-mega-accordion__content-wrapper ul {
    font-size: 1rem;
    font-weight: 500;
    font-weight: 400;
    letter-spacing: -0.005em;
    line-height: 1.6;
    text-underline-offset: .25rem
}

.rad-mega-accordion__content-wrapper ol:lang(ja-JP),.rad-mega-accordion__content-wrapper ol:lang(zh-CN),.rad-mega-accordion__content-wrapper p:lang(ja-JP),.rad-mega-accordion__content-wrapper p:lang(zh-CN),.rad-mega-accordion__content-wrapper ul:lang(ja-JP),.rad-mega-accordion__content-wrapper ul:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-mega-accordion__content-wrapper ol,.rad-mega-accordion__content-wrapper p,.rad-mega-accordion__content-wrapper ul {
        font-size:1.125rem
    }
}

@media(min-width: 1024px) {
    .rad-mega-accordion__content-wrapper ol,.rad-mega-accordion__content-wrapper p,.rad-mega-accordion__content-wrapper ul {
        font-size:1.25rem
    }
}

@media(min-width: 1441px) {
    .rad-mega-accordion__content-wrapper ol,.rad-mega-accordion__content-wrapper p,.rad-mega-accordion__content-wrapper ul {
        font-size:calc(1.25rem + 1.39179vw - 20.05567px)
    }
}

@media(min-width: 1920px) {
    .rad-mega-accordion__content-wrapper ol,.rad-mega-accordion__content-wrapper p,.rad-mega-accordion__content-wrapper ul {
        font-size:1.6666666667rem
    }
}

.rad-mega-accordion__content-wrapper ol:lang(ja-JP),.rad-mega-accordion__content-wrapper ol:lang(zh-CN),.rad-mega-accordion__content-wrapper p:lang(ja-JP),.rad-mega-accordion__content-wrapper p:lang(zh-CN),.rad-mega-accordion__content-wrapper ul:lang(ja-JP),.rad-mega-accordion__content-wrapper ul:lang(zh-CN) {
    font-weight: 400
}

.rad-mega-accordion__content-wrapper .rad-absorb-editorial-text li {
    font-size: .875rem;
    font-weight: 500;
    font-weight: 400;
    letter-spacing: -0.005em;
    line-height: 1.5;
    text-underline-offset: .25rem
}

.rad-mega-accordion__content-wrapper .rad-absorb-editorial-text li:lang(ja-JP),.rad-mega-accordion__content-wrapper .rad-absorb-editorial-text li:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-mega-accordion__content-wrapper .rad-absorb-editorial-text li {
        font-size:1rem
    }
}

@media(min-width: 1441px) {
    .rad-mega-accordion__content-wrapper .rad-absorb-editorial-text li {
        font-size:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-mega-accordion__content-wrapper .rad-absorb-editorial-text li {
        font-size:1.3333333333rem
    }
}

.rad-mega-accordion__content-wrapper .rad-absorb-editorial-text li:lang(ja-JP),.rad-mega-accordion__content-wrapper .rad-absorb-editorial-text li:lang(zh-CN) {
    font-weight: 400
}

@media(min-width: 1024px) {
    .rad-mega-accordion__content-wrapper .rad-absorb-editorial-text li {
        font-size:.875rem;
        font-weight: 500;
        font-weight: 400;
        letter-spacing: -0.005em;
        line-height: 1.5;
        text-underline-offset: .25rem
    }

    .rad-mega-accordion__content-wrapper .rad-absorb-editorial-text li:lang(ja-JP),.rad-mega-accordion__content-wrapper .rad-absorb-editorial-text li:lang(zh-CN) {
        font-weight: 700
    }

    .rad-mega-accordion__content-wrapper .rad-absorb-editorial-text li {
        font-size: 1rem;
        font-size: 1.125rem;
        line-height: 1.6
    }
}

@media(min-width: 1441px) {
    .rad-mega-accordion__content-wrapper .rad-absorb-editorial-text li {
        font-size:calc(1.125rem + 1.25261vw - 18.0501px)
    }
}

@media(min-width: 1920px) {
    .rad-mega-accordion__content-wrapper .rad-absorb-editorial-text li {
        font-size:1.5rem
    }
}

@media(min-width: 1024px) {
    .rad-mega-accordion__content-wrapper .rad-absorb-editorial-text li:lang(ja-JP),.rad-mega-accordion__content-wrapper .rad-absorb-editorial-text li:lang(zh-CN) {
        font-weight:400
    }
}

.rad-mega-accordion__content-wrapper .rad-absorb-stats__eyebrow {
    font-size: .75rem;
    font-weight: 500;
    letter-spacing: .02em;
    line-height: 1.2
}

.rad-mega-accordion__content-wrapper .rad-absorb-stats__eyebrow:lang(ja-JP),.rad-mega-accordion__content-wrapper .rad-absorb-stats__eyebrow:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 1024px) {
    .rad-mega-accordion__content-wrapper .rad-absorb-stats__eyebrow {
        font-size:0.875rem
    }
}

@media(min-width: 1441px) {
    .rad-mega-accordion__content-wrapper .rad-absorb-stats__eyebrow {
        font-size:calc(0.875rem + 0.97425vw - 14.03897px)
    }
}

@media(min-width: 1920px) {
    .rad-mega-accordion__content-wrapper .rad-absorb-stats__eyebrow {
        font-size:1.1666666667rem
    }
}

.rad-mega-accordion__content-wrapper .rad-absorb-stats__card-detail p {
    font-size: .875rem;
    line-height: 1.3125rem
}

@media(min-width: 600px) {
    .rad-mega-accordion__content-wrapper .rad-absorb-stats__card-detail p {
        font-size:1rem;
        line-height: 1.5rem
    }
}

@media(min-width: 1441px) {
    .rad-mega-accordion__content-wrapper .rad-absorb-stats__card-detail p {
        font-size:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-mega-accordion__content-wrapper .rad-absorb-stats__card-detail p {
        font-size:1.3333333333rem
    }
}

@media(min-width: 1441px) {
    .rad-mega-accordion__content-wrapper .rad-absorb-stats__card-detail p {
        line-height:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-mega-accordion__content-wrapper .rad-absorb-stats__card-detail p {
        line-height:2rem
    }
}

.rad-mega-accordion__content-wrapper .rad-media--anchored__caption-text,.rad-mega-accordion__content-wrapper .rad-media--image__caption-text,.rad-mega-accordion__content-wrapper .rad-media--mp4__caption-text {
    font-size: .75rem;
    line-height: 1.125rem
}

@media(min-width: 600px) {
    .rad-mega-accordion__content-wrapper .rad-media--anchored__caption-text,.rad-mega-accordion__content-wrapper .rad-media--image__caption-text,.rad-mega-accordion__content-wrapper .rad-media--mp4__caption-text {
        font-size:0.875rem;
        line-height: 1.3125rem
    }
}

@media(min-width: 1441px) {
    .rad-mega-accordion__content-wrapper .rad-media--anchored__caption-text,.rad-mega-accordion__content-wrapper .rad-media--image__caption-text,.rad-mega-accordion__content-wrapper .rad-media--mp4__caption-text {
        font-size:calc(0.875rem + 0.97425vw - 14.03897px)
    }
}

@media(min-width: 1920px) {
    .rad-mega-accordion__content-wrapper .rad-media--anchored__caption-text,.rad-mega-accordion__content-wrapper .rad-media--image__caption-text,.rad-mega-accordion__content-wrapper .rad-media--mp4__caption-text {
        font-size:1.1666666667rem
    }
}

@media(min-width: 1441px) {
    .rad-mega-accordion__content-wrapper .rad-media--anchored__caption-text,.rad-mega-accordion__content-wrapper .rad-media--image__caption-text,.rad-mega-accordion__content-wrapper .rad-media--mp4__caption-text {
        line-height:calc(1.3125rem + 1.46138vw - 21.05846px)
    }
}

@media(min-width: 1920px) {
    .rad-mega-accordion__content-wrapper .rad-media--anchored__caption-text,.rad-mega-accordion__content-wrapper .rad-media--image__caption-text,.rad-mega-accordion__content-wrapper .rad-media--mp4__caption-text {
        line-height:1.75rem
    }
}

.rad-mega-accordion .megaaccordioncard-active .rad-mega-accordion__content-wrapper {
    overflow: visible
}

.rad-mega-accordion .megaaccordioncard-active .rad-layout-division--sticky-containers .rad-layout-division__container:nth-child(2) {
    top: 4.5rem
}

@media(min-width: 1024px) {
    .rad-mega-accordion .megaaccordioncard-active .rad-layout-division--sticky-containers .rad-layout-division__container:nth-child(2) {
        position:sticky;
        top: 5.5rem
    }
}

.rad-mega-accordion .megaaccordioncard-active .rad-mega-accordion__icon-container,.rad-mega-accordion .megaaccordioncard-active .rad-mega-accordion__number {
    position: sticky;
    top: 0
}

.rad-mega-accordion .rad-layout-division {
    padding-left: 0;
    padding-right: 0
}

@media(min-width: 1024px) {
    .rad-mega-accordion .rad-horizontal-indent .rad-layout-division {
        margin-left:calc(25% - var(--rad-spacing-sm)*3/4*1 + var(--rad-spacing-sm)*2 + var(--rad-spacing-sm)*0);
        margin-left: calc(12.5% - var(--rad-spacing-sm)*7/8*1 + var(--rad-spacing-sm)*2 + var(--rad-spacing-md)*0);
        margin-left: calc(8.33333% - var(--rad-spacing-sm)*11/12*1 + var(--rad-spacing-sm)*2 + var(--rad-spacing-md)*0);
        margin-right: calc(25% - var(--rad-spacing-sm)*3/4*1 + var(--rad-spacing-sm)*2 + var(--rad-spacing-sm)*0);
        margin-right: calc(12.5% - var(--rad-spacing-sm)*7/8*1 + var(--rad-spacing-sm)*2 + var(--rad-spacing-md)*0);
        margin-right: calc(8.33333% - var(--rad-spacing-sm)*11/12*1 + var(--rad-spacing-sm)*2 + var(--rad-spacing-md)*0)
    }
}

.rad-mega-accordion .rad-horizontal-indent .rad-absorb-cta {
    padding-left: 0;
    padding-right: 0
}

.rad-mega-accordion--no-subhead .rad-mega-accordion__item {
    padding-bottom: .5rem;
    -webkit-transition: padding-bottom 550ms cubic-bezier(0.85,0,0,1);
    transition: padding-bottom 550ms cubic-bezier(0.85,0,0,1)
}

.rad-mega-accordion--no-subhead .rad-mega-accordion__item--open {
    padding-bottom: 1.5rem
}

@media(min-width: 600px) {
    .rad-mega-accordion--no-subhead .rad-mega-accordion__item {
        padding-bottom:1rem
    }

    .rad-mega-accordion--no-subhead .rad-mega-accordion__item--open {
        padding-bottom: 3rem
    }
}

@media(min-width: 1024px) {
    .rad-mega-accordion--no-subhead .rad-mega-accordion__item,.rad-mega-accordion--no-subhead--open {
        padding-bottom:3rem
    }
}

@media(min-width: 1441px) {
    .rad-mega-accordion--no-subhead .rad-mega-accordion__item,.rad-mega-accordion--no-subhead--open {
        padding-bottom:calc(3rem + 3.34029vw - 48.13361px)
    }
}

@media(min-width: 1920px) {
    .rad-mega-accordion--no-subhead .rad-mega-accordion__item,.rad-mega-accordion--no-subhead--open {
        padding-bottom:4rem
    }
}

.rad-mega-accordion__item:not(.rad-mega-accordion__item--small-toggle) .rad-mega-accordion__cta,.rad-mega-accordion__item:not(.rad-mega-accordion__item--small-toggle) .rad-related-links {
    z-index: 2
}

.rad-mega-accordion__cta-wrapper {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-top: 24px
}

@media(min-width: 600px) {
    .rad-mega-accordion__cta-wrapper {
        display:-webkit-box;
        display: -ms-flexbox;
        display: flex;
        gap: 24px;
        margin-top: 40px
    }
}

@media(min-width: 1024px) {
    .rad-mega-accordion__cta-wrapper {
        gap:32px;
        margin-top: 48px
    }
}

@media(min-width: 1441px) {
    .rad-mega-accordion__cta-wrapper {
        gap:calc(-0.08907px + 2.22686vw)
    }
}

@media(min-width: 1920px) {
    .rad-mega-accordion__cta-wrapper {
        gap:42.6666666667px
    }
}

@media(min-width: 1441px) {
    .rad-mega-accordion__cta-wrapper {
        margin-top:calc(-0.13361px + 3.34029vw)
    }
}

@media(min-width: 1920px) {
    .rad-mega-accordion__cta-wrapper {
        margin-top:64px
    }
}

@media(min-width: 1024px) {
    .rad-mega-accordion__cta-wrapper:not(.rad-mega-accordion__cta-wrapper--no-left-margin) {
        margin-left:calc(25% - var(--rad-spacing-sm)*3/4*1 + var(--rad-spacing-sm)*2 + var(--rad-spacing-sm)*0);
        margin-left: calc(12.5% - var(--rad-spacing-sm)*7/8*1 + var(--rad-spacing-sm)*2 + var(--rad-spacing-md)*0);
        margin-left: calc(8.33333% - var(--rad-spacing-sm)*11/12*1 + var(--rad-spacing-sm)*2 + var(--rad-spacing-md)*0)
    }
}

.rad-mega-accordion__cta:not(:first-child) {
    margin-top: 16px
}

@media(min-width: 600px) {
    .rad-mega-accordion__cta:not(:first-child) {
        margin-top:0
    }
}

.rad-mega-accordion .rad-related-links {
    padding: 24px 0 0;
    position: relative
}

@media(min-width: 600px) {
    .rad-mega-accordion .rad-related-links {
        padding-top:40px
    }
}

@media(min-width: 1024px) {
    .rad-mega-accordion .rad-related-links {
        padding-top:48px
    }
}

@media(min-width: 1441px) {
    .rad-mega-accordion .rad-related-links {
        padding-top:calc(-0.13361px + 3.34029vw)
    }
}

@media(min-width: 1920px) {
    .rad-mega-accordion .rad-related-links {
        padding-top:64px
    }
}

@media(min-width: 1024px) {
    .rad-mega-accordion .rad-related-links:not(.rad-mega-accordion .rad-related-links--no-left-margin) {
        margin-left:calc(25% - var(--rad-spacing-sm)*3/4*1 + var(--rad-spacing-sm)*2 + var(--rad-spacing-sm)*0);
        margin-left: calc(12.5% - var(--rad-spacing-sm)*7/8*1 + var(--rad-spacing-sm)*2 + var(--rad-spacing-md)*0);
        margin-left: calc(8.33333% - var(--rad-spacing-sm)*11/12*1 + var(--rad-spacing-sm)*2 + var(--rad-spacing-md)*0)
    }
}

@media(max-width: 1023px) {
    .rad--has-subnav .rad-mega-accordion .rad-mega-accordion__item--open .rad-mega-accordion__icon-container,.rad--has-subnav .rad-mega-accordion .rad-mega-accordion__item--open .rad-mega-accordion__number {
        top:3.25rem
    }
}

@media(min-width: 1024px) {
    .rad--has-subnav .rad-mega-accordion .megaaccordioncard-active .rad-mega-accordion__number,.rad--has-subnav .rad-mega-accordion .rad-mega-accordion__container--open .rad-mega-accordion__desktop-close {
        top:3.25rem
    }
}

.rad-mosaic {
    --gap: 24px;
    padding-left: 16px;
    padding-right: 16px
}

@media(min-width: 600px) {
    .rad-mosaic {
        --card-ht:17rem;
        --short-row-ht: calc(var(--card-ht) - var(--gap));
        --s-clump: var(--card-ht) var(--short-row-ht) var(--card-ht);
        padding-left: 48px;
        padding-right: 48px
    }
    .rad-mosaic {
        --card-ht:0rem;
        --short-row-ht: calc(var(--card-ht) - var(--gap));
        --s-clump: var(--card-ht) var(--short-row-ht) var(--card-ht);
        padding-left: 48px;
        padding-right: 48px
    }
}

@media(min-width: 1024px) {
    .rad-mosaic {
        --gap:32px;
        --card-ht: 17.5rem;
        padding-left: 80px;
        padding-right: 80px
    }
    
}

@media(min-width: 1441px) {
    .rad-mosaic {
        --card-ht:18.75rem;
        --card-ht: calc(18.75rem + 20.87683vw - 300.83507px)
    }
}

@media(min-width: 1920px) {
    .rad-mosaic {
        --card-ht:25rem
    }
}

.rad-mosaic__heading {
    font-size: 1.375rem;
    font-weight: 500;
    letter-spacing: -0.02em;
    line-height: 1.25;
    margin-bottom: 24px
}

.rad-mosaic__heading:lang(ja-JP),.rad-mosaic__heading:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-mosaic__heading {
        font-size:1.75rem;
        line-height: 1.3
    }
}

@media(min-width: 1024px) {
    .rad-mosaic__heading {
        font-size:2rem;
        line-height: 1.2
    }
}

@media(min-width: 1441px) {
    .rad-mosaic__heading {
        font-size:calc(2rem + 2.22686vw - 32.08907px)
    }
}

@media(min-width: 1920px) {
    .rad-mosaic__heading {
        font-size:2.6666666667rem
    }
}

@media(min-width: 600px) {
    .rad-mosaic__heading {
        margin-bottom:40px
    }
}

@media(min-width: 1024px) {
    .rad-mosaic__heading {
        margin-bottom:48px
    }
}

@media(min-width: 1441px) {
    .rad-mosaic__heading {
        margin-bottom:calc(-0.13361px + 3.34029vw)
    }
}

@media(min-width: 1920px) {
    .rad-mosaic__heading {
        margin-bottom:64px
    }
}

.rad-mosaic__grid {
    display: grid;
    gap: var(--gap)
}

@media(min-width: 600px) {
    .rad-mosaic__grid {
        grid-template-columns:repeat(2,1fr)
    }

    .rad-mosaic--1-card .rad-mosaic__grid {
        grid-template-rows: var(--card-ht)
    }

    .rad-mosaic--2-cards .rad-mosaic__grid {
        grid-template-rows: var(--card-ht) var(--short-row-ht)
    }

    .rad-mosaic--3-cards .rad-mosaic__grid {
        grid-template-rows: var(--card-ht) var(--card-ht) var(--short-row-ht)
    }

    .rad-mosaic--4-cards .rad-mosaic__grid {
        grid-template-rows: var(--s-clump)
    }

    .rad-mosaic--5-cards .rad-mosaic__grid {
        grid-template-rows: var(--card-ht) var(--s-clump)
    }

    .rad-mosaic--6-cards .rad-mosaic__grid {
        grid-template-rows: var(--s-clump) var(--card-ht)
    }

    .rad-mosaic--7-cards .rad-mosaic__grid {
        grid-template-rows: var(--card-ht) var(--s-clump) var(--card-ht)
    }

    .rad-mosaic--8-cards .rad-mosaic__grid {
        grid-template-rows: repeat(2,var(--s-clump))
    }

    .rad-mosaic--9-cards .rad-mosaic__grid {
        grid-template-rows: var(--card-ht) repeat(2,var(--s-clump))
    }

    .rad-mosaic--10-cards .rad-mosaic__grid {
        grid-template-rows: var(--s-clump) var(--card-ht) var(--s-clump)
    }

    .rad-mosaic--11-cards .rad-mosaic__grid {
        grid-template-rows: var(--card-ht) var(--s-clump) var(--card-ht) var(--s-clump)
    }

    .rad-mosaic--12-cards .rad-mosaic__grid {
        grid-template-rows: repeat(2,var(--s-clump) var(--card-ht))
    }
}

.rad-mosaic__card {
    background: #bcbcbc;
    display: grid;
    grid-column: auto/span 1;
    outline-offset: .5rem;
    overflow: hidden;
    position: relative;
    text-decoration: none;
    -webkit-transition-duration: 550ms;
    transition-duration: 550ms;
    -webkit-transition-property: background-color;
    transition-property: background-color;
    -webkit-transition-timing-function: cubic-bezier(0.85,0,0,1);
    transition-timing-function: cubic-bezier(0.85,0,0,1)
}

.rad-mosaic__card-description {
    padding: 24px
}

@media(min-width: 600px) {
    .rad-mosaic__card-description {
        padding-bottom:0
    }
}

@media(min-width: 1024px) {
    .rad-mosaic__card-description {
        padding:40px 80px 0 40px
    }
}

@media(min-width: 1441px) {
    .rad-mosaic__card-description {
        padding-right:108px
    }
}

.rad-mosaic__card-description-eyebrow {
    font-size: .75rem;
    font-weight: 500;
    letter-spacing: .02em;
    line-height: 1.2;
    margin-bottom: 12px;
    text-align: left;
    text-transform: uppercase
}

.rad-mosaic__card-description-eyebrow:lang(ja-JP),.rad-mosaic__card-description-eyebrow:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 1024px) {
    .rad-mosaic__card-description-eyebrow {
        font-size:0.875rem
    }
}

@media(min-width: 1441px) {
    .rad-mosaic__card-description-eyebrow {
        font-size:calc(0.875rem + 0.97425vw - 14.03897px)
    }
}

@media(min-width: 1920px) {
    .rad-mosaic__card-description-eyebrow {
        font-size:1.1666666667rem
    }
}

@media(min-width: 1024px) {
    .rad-mosaic__card-description-eyebrow {
        margin-bottom:24px
    }
}

.rad-mosaic__card-description-eyebrow:before {
    background: #a100ff;
    content: "";
    display: block;
    height: .25rem;
    margin-bottom: 12px;
    width: 2rem
}

@media(min-width: 600px) {
    .rad-mosaic__card-description-eyebrow:before {
        width:2rem
    }
}

@media(min-width: 1024px) {
    .rad-mosaic__card-description-eyebrow:before {
        margin-bottom:24px
    }
}

@media(min-width: 1441px) {
    .rad-mosaic__card-description-eyebrow:before {
        margin-bottom:calc(-0.06681px + 1.67015vw)
    }
}

@media(min-width: 1920px) {
    .rad-mosaic__card-description-eyebrow:before {
        margin-bottom:32px
    }
}

.rad-mosaic__card-description-title {
    font-size: 1.25rem;
    font-weight: 500;
    letter-spacing: -0.02em;
    line-height: 1.3;
    text-align: left
}

.rad-mosaic__card-description-title:lang(ja-JP),.rad-mosaic__card-description-title:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-mosaic__card-description-title {
        font-size:1.5rem;
        line-height: 1.3
    }
}

@media(min-width: 1024px) {
    .rad-mosaic__card-description-title {
        font-size:1.75rem;
        font-size: 1.125rem;
        font-weight: 500;
        letter-spacing: -0.02em;
        line-height: 1.25;
        line-height: 1.4
    }

    .rad-mosaic__card-description-title:lang(ja-JP),.rad-mosaic__card-description-title:lang(zh-CN) {
        font-weight: 700
    }

    .rad-mosaic__card-description-title {
        font-size: 1.25rem;
        font-size: 1.5rem;
        line-height: 1.4;
        line-height: 1.3
    }
}

@media(min-width: 1441px) {
    .rad-mosaic__card-description-title {
        font-size:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-mosaic__card-description-title {
        font-size:2rem
    }
}

@media(min-width: 1200px) {
    .rad-mosaic__card-description-title {
        font-size:1.25rem;
        font-weight: 500;
        letter-spacing: -0.02em;
        line-height: 1.3
    }

    .rad-mosaic__card-description-title:lang(ja-JP),.rad-mosaic__card-description-title:lang(zh-CN) {
        font-weight: 700
    }

    .rad-mosaic__card-description-title {
        font-size: 1.5rem;
        font-size: 1.75rem;
        line-height: 1.3;
        line-height: 1.25
    }
}

@media(min-width: 1441px) {
    .rad-mosaic__card-description-title {
        font-size:calc(1.75rem + 1.9485vw - 28.07794px)
    }
}

@media(min-width: 1920px) {
    .rad-mosaic__card-description-title {
        font-size:2.3333333333rem
    }
}

.rad-mosaic__card .rad-icon-button {
    background: -webkit-gradient(linear,left top,right top,color-stop(50%,#7500c0),color-stop(50%,#a100ff));
    background: linear-gradient(90deg,#7500c0 50%,#a100ff 0);
    background-position-x: 100%;
    background-size: 200% 200%;
    bottom: 0;
    height: 2.5rem;
    position: absolute;
    right: 0;
    -webkit-transition: background-position-x 550ms cubic-bezier(0.85,0,0,1);
    transition: background-position-x 550ms cubic-bezier(0.85,0,0,1);
    width: 2.5rem
}

@media(min-width: 1024px) {
    .rad-mosaic__card .rad-icon-button {
        height:3.25rem;
        width: 3.25rem
    }
}

@media(min-width: 1441px) {
    .rad-mosaic__card .rad-icon-button {
        height:calc(3.25rem + 3.61865vw - 52.14475px)
    }
}

@media(min-width: 1920px) {
    .rad-mosaic__card .rad-icon-button {
        height:4.3333333333rem
    }
}

@media(min-width: 1441px) {
    .rad-mosaic__card .rad-icon-button {
        width:calc(3.25rem + 3.61865vw - 52.14475px)
    }
}

@media(min-width: 1920px) {
    .rad-mosaic__card .rad-icon-button {
        width:4.3333333333rem
    }
}



.rad-mosaic__card:active {
    background: #101010
}

.rad-mosaic__card:active .rad-icon-button {
    background: #57008f
}

.rad-mosaic__card:visited {
    color: #fff
}


.rad-mosaic__card--no-image .rad-mosaic__card-description {
    padding-bottom: 64px
}

@media(min-width: 600px) {
    .rad-mosaic__card--no-image .rad-mosaic__card-description {
        padding-bottom:0
    }
}

.rad-mosaic__image-card {
    padding-bottom: 0
}

.rad-mosaic__image-card-image {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 200px
}

@media(min-width: 600px) {
    .rad-mosaic__image-card-image {
        height:100%
    }
}

.rad-mosaic__image-card-image .cmp-image {
    height: 100%;
    width: 100%
}

.rad-mosaic__image-card-image img {
    display: block;
    min-height: 100%;
    min-width: 100%;
    -o-object-fit: cover;
    object-fit: cover
}

.rad-mosaic__stat-card-stat {
    padding-bottom: 2.5rem;
    padding-left: 24px;
    padding-right: 40px
}

.rad-mosaic__stat-card-stat-figure {
    font-size: 1.75rem;
    font-weight: 500;
    letter-spacing: -0.03em;
    line-height: 1.3;
    margin-bottom: 8px;
    text-align: left
}

.rad-mosaic__stat-card-stat-figure:lang(ja-JP),.rad-mosaic__stat-card-stat-figure:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-mosaic__stat-card-stat-figure {
        font-size:2.5rem;
        line-height: 1.2
    }
}

@media(min-width: 1024px) {
    .rad-mosaic__stat-card-stat-figure {
        font-size:3rem;
        line-height: 1.2
    }
}

@media(min-width: 1441px) {
    .rad-mosaic__stat-card-stat-figure {
        font-size:calc(3rem + 3.34029vw - 48.13361px)
    }
}

@media(min-width: 1920px) {
    .rad-mosaic__stat-card-stat-figure {
        font-size:4rem
    }
}

@media(min-width: 1024px) {
    .rad-mosaic__stat-card-stat-figure {
        margin-bottom:12px
    }
}

@media(min-width: 1441px) {
    .rad-mosaic__stat-card-stat-figure {
        margin-bottom:calc(-0.0334px + 0.83507vw)
    }
}

@media(min-width: 1920px) {
    .rad-mosaic__stat-card-stat-figure {
        margin-bottom:16px
    }
}

.rad-mosaic__stat-card-stat-text {
    font-size: .875rem;
    font-weight: 500;
    font-weight: 400;
    letter-spacing: -0.005em;
    line-height: 1.5;
    text-align: left;
    text-underline-offset: .25rem
}

.rad-mosaic__stat-card-stat-text:lang(ja-JP),.rad-mosaic__stat-card-stat-text:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-mosaic__stat-card-stat-text {
        font-size:1rem
    }
}

@media(min-width: 1441px) {
    .rad-mosaic__stat-card-stat-text {
        font-size:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-mosaic__stat-card-stat-text {
        font-size:1.3333333333rem
    }
}

.rad-mosaic__stat-card-stat-text:lang(ja-JP),.rad-mosaic__stat-card-stat-text:lang(zh-CN) {
    font-weight: 400
}

@media(min-width: 1441px) {
    .rad-mosaic__stat-card-stat-text {
        padding-right:64px
    }
}

@media(min-width: 600px) {
    /* .rad-mosaic__card--horizontal {
        display:grid;
        gap: var(--gap);
        grid-column: auto/span 2;
        grid-template-columns: repeat(2,1fr)
    } */

    .rad-mosaic__card--horizontal .rad-mosaic__card-description {
        padding-right: 0
    }
}

@media(min-width: 1024px) {
    .rad-mosaic__card--horizontal .rad-mosaic__card-description {
        padding-right:calc(80px - var(--gap))
    }
}

@media(min-width: 1441px) {
    .rad-mosaic__card--horizontal .rad-mosaic__card-description {
        padding-right:76px
    }
}

@media(min-width: 600px) {
    .rad-mosaic__stat-card.rad-mosaic__card--horizontal .rad-mosaic__stat-card-stat {
        padding:44px 40px 72px 0
    }
}

@media(min-width: 1024px) {
    .rad-mosaic__stat-card.rad-mosaic__card--horizontal .rad-mosaic__stat-card-stat {
        padding:64px 148px 44px 0
    }
}

@media(min-width: 600px) {
    .rad-mosaic__card--vertical {
        -webkit-box-orient:vertical;
        -webkit-box-direction: normal;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
        grid-row: span 2;
        justify-content: space-between;
        row-gap: 24px
    }
    .rad-mosaic__card--vertical1 {
        -webkit-box-orient:vertical;
        -webkit-box-direction: normal;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: row;
        flex-direction: row;
        grid-row: span 1;
        width: 100%;
        justify-content: space-between;
        row-gap: 24px
    }
    .rad-mosaic__card--vertical .rad-mosaic__image-card-image {
        height: var(--card-ht);
        min-height: 0;
        min-width: 100%
    }

    .rad-mosaic__stat-card.rad-mosaic__card--vertical .rad-mosaic__stat-card-stat {
        padding: 0 40px 2.5rem 24px
    }
}

@media(min-width: 1024px) {
    .rad-mosaic__stat-card.rad-mosaic__card--vertical .rad-mosaic__stat-card-stat {
        padding:0 80px 4.75rem 40px
    }
}

@media(min-width: 1200px) {
    .rad-mosaic__stat-card.rad-mosaic__card--vertical .rad-mosaic__stat-card-stat {
        padding-right:108px
    }
}

.rad-component__title--large-text .rad-mosaic__heading {
    font-size: 1.75rem;
    font-weight: 500;
    letter-spacing: -0.03em;
    line-height: 1.3
}

.rad-component__title--large-text .rad-mosaic__heading:lang(ja-JP),.rad-component__title--large-text .rad-mosaic__heading:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-component__title--large-text .rad-mosaic__heading {
        font-size:2.5rem;
        line-height: 1.2
    }
}

@media(min-width: 1024px) {
    .rad-component__title--large-text .rad-mosaic__heading {
        font-size:3rem;
        line-height: 1.2
    }
}

@media(min-width: 1441px) {
    .rad-component__title--large-text .rad-mosaic__heading {
        font-size:calc(3rem + 3.34029vw - 48.13361px)
    }
}

@media(min-width: 1920px) {
    .rad-component__title--large-text .rad-mosaic__heading {
        font-size:4rem
    }
}

.rad-mosaic-2 {
    padding-left: var(--rad-spacing-sm);
    padding-right: var(--rad-spacing-sm)
}

@media(min-width: 600px) {
    .rad-mosaic-2 {
        padding-left:var(--rad-spacing-md);
        padding-right: var(--rad-spacing-md)
    }
}

.rad-component--nested .rad-mosaic-2 {
    padding-left: 0;
    padding-right: 0
}

.rad-mosaic-2 .rad-header {
    margin-bottom: 1.25rem;
    padding: 0
}

@media(min-width: 600px) {
    .rad-mosaic-2 .rad-header {
        margin-bottom:2.5rem
    }
}

@media(min-width: 1024px) {
    .rad-mosaic-2 .rad-header {
        margin-bottom:3rem
    }
}

@media(min-width: 1441px) {
    .rad-mosaic-2 .rad-header {
        margin-bottom:calc(3rem + 3.34029vw - 48.13361px)
    }
}

@media(min-width: 1920px) {
    .rad-mosaic-2 .rad-header {
        margin-bottom:4rem
    }
}

.rad-mosaic-2__grid {
    display: grid;
    gap: 24px
}

@media(min-width: 600px) {
    .rad-mosaic-2__grid {
        grid-template-columns:repeat(2,1fr)
    }
}

@media(min-width: 1024px) {
    .rad-mosaic-2__grid {
        gap:32px
    }
}

@media(min-width: 1441px) {
    .rad-mosaic-2__grid {
        gap:calc(-0.08907px + 2.22686vw)
    }
}

@media(min-width: 1920px) {
    .rad-mosaic-2__grid {
        gap:42.6666666667px
    }
}

.rad-mosaic-2__card {
    background-color: #202020;
    min-height: 200px;
    overflow: hidden;
    position: relative;
    -webkit-transition-duration: 550ms;
    transition-duration: 550ms;
    -webkit-transition-property: background-color;
    transition-property: background-color;
    -webkit-transition-timing-function: cubic-bezier(0.85,0,0,1);
    transition-timing-function: cubic-bezier(0.85,0,0,1)
}

@media(min-width: 600px) {
    .rad-mosaic-2__card {
        -webkit-box-orient:vertical;
        -webkit-box-direction: normal;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
        height: 322px
    }
}

@media(min-width: 1024px) {
    .rad-mosaic-2__card {
        height:300px
    }
}

@media(min-width: 1441px) {
    .rad-mosaic-2__card {
        height:calc(-0.83507px + 20.87683vw)
    }
}

@media(min-width: 1920px) {
    .rad-mosaic-2__card {
        height:400px
    }
}

.rad-mosaic-2__card:hover {
    background-color: #2b2b2b
}

@media(min-width: 1024px) {
    .rad-mosaic-2__card:hover .rad-mosaic-2__card-title {
        top:-100%
    }

    .rad-mosaic-2__card:hover .rad-mosaic-2__card-body {
        translate: 0
    }
}

.rad-mosaic-2__card:active {
    background-color: #101010
}

@media(min-width: 600px) {
    .rad-mosaic-2__card--vertical {
        grid-row:span 2;
        height: 644px
    }
}

@media(min-width: 1024px) {
    .rad-mosaic-2__card--vertical {
        -webkit-box-pack:justify;
        -ms-flex-pack: justify;
        height: 600px;
        justify-content: space-between
    }
}

@media(min-width: 1441px) {
    .rad-mosaic-2__card--vertical {
        height:calc(-1.67015px + 41.75365vw)
    }
}

@media(min-width: 1920px) {
    .rad-mosaic-2__card--vertical {
        height:800px
    }
}

@media(min-width: 600px) {
    .rad-mosaic-2__card--vertical .rad-mosaic-2__card-front-content {
        -webkit-box-orient:vertical;
        -webkit-box-direction: normal;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
        justify-content: space-between
    }
}

.rad-mosaic-2__card--vertical .rad-mosaic-2__card-description-content {
    height: auto
}

@media(min-width: 1024px) {
    .rad-mosaic-2__card--vertical .rad-mosaic-2__card-image {
        height:300px
    }
}

@media(min-width: 1441px) {
    .rad-mosaic-2__card--vertical .rad-mosaic-2__card-image {
        height:calc(-0.83507px + 20.87683vw)
    }
}

@media(min-width: 1920px) {
    .rad-mosaic-2__card--vertical .rad-mosaic-2__card-image {
        height:400px
    }
}

@media(min-width: 600px) {
    .rad-mosaic-2__card--vertical .cmp-image,.rad-mosaic-2__card--vertical .cmp-image__image {
        height:322px
    }
}

@media(min-width: 1024px) {
    .rad-mosaic-2__card--vertical .cmp-image,.rad-mosaic-2__card--vertical .cmp-image__image {
        height:100%
    }
}

@media(min-width: 600px) {
    .rad-mosaic-2__card--horizontal {
        grid-column:span 2
    }

    .rad-mosaic-2__card--horizontal .rad-mosaic-2__card-eyebrow {
        z-index: 2
    }
}

@media(min-width: 1024px) {
    .rad-mosaic-2__card--horizontal {
        -webkit-box-orient:horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row
    }
}

@media(min-width: 600px) {
    .rad-mosaic-2__card--horizontal .rad-mosaic-2__card-front-content {
        -webkit-column-gap:24px;
        -moz-column-gap: 24px;
        column-gap: 24px;
        display: grid;
        grid-template-columns: repeat(2,1fr)
    }

    .rad-mosaic-2__card--horizontal .rad-mosaic-2__card-front-content .rad-mosaic-2__card-stat-eyebrow {
        margin-top: -1.65rem;
        position: relative
    }

    .rad-mosaic-2__card--horizontal .rad-mosaic-2__card-front-content .rad-mosaic-2__card-stat-eyebrow:before {
        background-color: #202020;
        content: "";
        display: block;
        height: 100%;
        position: absolute;
        -webkit-transition-duration: 550ms;
        transition-duration: 550ms;
        -webkit-transition-property: width,background-color;
        transition-property: width,background-color;
        -webkit-transition-timing-function: cubic-bezier(0.85,0,0,1);
        transition-timing-function: cubic-bezier(0.85,0,0,1);
        width: 0
    }
}

.rad-mosaic-2__card--horizontal .rad-mosaic-2__card-description-content {
    height: auto
}

.rad-mosaic-2__card--horizontal .rad-mosaic-2__card-figure {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    width: calc(150% - var(--rad-spacing-sm)*3/4*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-sm)*0)
}

@media(min-width: 600px) {
    .rad-mosaic-2__card--horizontal .rad-mosaic-2__card-figure {
        width:calc(75% - var(--rad-spacing-sm)*7/8*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-mosaic-2__card--horizontal .rad-mosaic-2__card-figure {
        width:calc(50% - var(--rad-spacing-sm)*11/12*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0)
    }

    .rad-mosaic-2__card--horizontal .rad-mosaic-2__card-image {
        height: 100%
    }
}

@media(min-width: 600px) {
    .rad-mosaic-2__card--horizontal .cmp-image,.rad-mosaic-2__card--horizontal .cmp-image__image {
        height:100%
    }
}

@media(min-width: 600px) {
    .rad-mosaic-2__card:is(.rad-mosaic-2__card--horizontal):is(.rad-mosaic-2__card--image) {
        height:344px
    }
}

@media(min-width: 1024px) {
    .rad-mosaic-2__card:is(.rad-mosaic-2__card--horizontal):is(.rad-mosaic-2__card--image) {
        height:300px
    }
}

@media(min-width: 1441px) {
    .rad-mosaic-2__card:is(.rad-mosaic-2__card--horizontal):is(.rad-mosaic-2__card--image) {
        height:calc(-0.83507px + 20.87683vw)
    }
}

@media(min-width: 1920px) {
    .rad-mosaic-2__card:is(.rad-mosaic-2__card--horizontal):is(.rad-mosaic-2__card--image) {
        height:400px
    }
}

@media(min-width: 1024px) {
    .rad-mosaic-2__card:is(.rad-mosaic-2__card--horizontal):is(.rad-mosaic-2__card--stat) .rad-mosaic-2__card-stat {
        margin-top:4rem
    }
}

@media(min-width: 1441px) {
    .rad-mosaic-2__card:is(.rad-mosaic-2__card--horizontal):is(.rad-mosaic-2__card--stat) .rad-mosaic-2__card-stat {
        margin-top:calc(4rem + 4.45372vw - 64.17815px)
    }
}

@media(min-width: 1920px) {
    .rad-mosaic-2__card:is(.rad-mosaic-2__card--horizontal):is(.rad-mosaic-2__card--stat) .rad-mosaic-2__card-stat {
        margin-top:5.3333333333rem
    }
}

@media(min-width: 1024px) {
    .rad-mosaic-2__card:is(.rad-mosaic-2__card--vertical):is(.rad-mosaic-2__card--stat) .rad-mosaic-2__card-stat {
        margin-bottom:2rem
    }
}

@media(min-width: 1441px) {
    .rad-mosaic-2__card:is(.rad-mosaic-2__card--vertical):is(.rad-mosaic-2__card--stat) .rad-mosaic-2__card-stat {
        margin-bottom:calc(2rem + 2.22686vw - 32.08907px)
    }
}

@media(min-width: 1920px) {
    .rad-mosaic-2__card:is(.rad-mosaic-2__card--vertical):is(.rad-mosaic-2__card--stat) .rad-mosaic-2__card-stat {
        margin-bottom:2.6666666667rem
    }
}

@media(min-width: 1024px) {
    .rad-mosaic-2__card:is(.rad-mosaic-2__card--vertical):is(.rad-mosaic-2__card--stat) .rad-mosaic-2__card-cta-button {
        margin-bottom:24px;
        margin-left: 40px;
        margin-right: 40px
    }
}

@media(min-width: 1441px) {
    .rad-mosaic-2__card:is(.rad-mosaic-2__card--vertical):is(.rad-mosaic-2__card--stat) .rad-mosaic-2__card-cta-button {
        margin-bottom:calc(-0.06681px + 1.67015vw)
    }
}

@media(min-width: 1920px) {
    .rad-mosaic-2__card:is(.rad-mosaic-2__card--vertical):is(.rad-mosaic-2__card--stat) .rad-mosaic-2__card-cta-button {
        margin-bottom:32px
    }
}

@media(min-width: 1441px) {
    .rad-mosaic-2__card:is(.rad-mosaic-2__card--vertical):is(.rad-mosaic-2__card--stat) .rad-mosaic-2__card-cta-button {
        margin-left:calc(-0.11134px + 2.78358vw)
    }
}

@media(min-width: 1920px) {
    .rad-mosaic-2__card:is(.rad-mosaic-2__card--vertical):is(.rad-mosaic-2__card--stat) .rad-mosaic-2__card-cta-button {
        margin-left:53.3333333333px
    }
}

@media(min-width: 1441px) {
    .rad-mosaic-2__card:is(.rad-mosaic-2__card--vertical):is(.rad-mosaic-2__card--stat) .rad-mosaic-2__card-cta-button {
        margin-right:calc(-0.11134px + 2.78358vw)
    }
}

@media(min-width: 1920px) {
    .rad-mosaic-2__card:is(.rad-mosaic-2__card--vertical):is(.rad-mosaic-2__card--stat) .rad-mosaic-2__card-cta-button {
        margin-right:53.3333333333px
    }
}

.rad-mosaic-2__card-cta-cover,.rad-mosaic-2__card-front-toggle {
    left: 0;
    position: absolute;
    right: 0;
    z-index: 1
}

.rad-mosaic-2__card-front-toggle {
    height: 100%;
    top: 0
}

.rad-mosaic-2__card-cta-cover {
    bottom: 0
}

.rad-mosaic-2__card:before {
    background-color: #a100ff;
    content: "";
    display: block;
    height: 4px;
    margin: 24px 0 16px 24px;
    width: 32px
}

@media(min-width: 600px) {
    .rad-mosaic-2__card:before {
        margin-bottom:24px
    }
}

@media(min-width: 1024px) {
    .rad-mosaic-2__card:before {
        display:none
    }
}

.rad-mosaic-2__card-close-button {
    position: absolute;
    right: -100%;
    top: 0;
    -webkit-transition-duration: 550ms;
    transition-duration: 550ms;
    -webkit-transition-property: right;
    transition-property: right;
    -webkit-transition-timing-function: cubic-bezier(0.85,0,0,1);
    transition-timing-function: cubic-bezier(0.85,0,0,1)
}

.rad-mosaic-2__card-eyebrow {
    font-size: .75rem;
    font-weight: 500;
    letter-spacing: .02em;
    line-height: 1.2;
    margin: 0 1.5rem .75rem;
    text-transform: uppercase
}

.rad-mosaic-2__card-eyebrow:lang(ja-JP),.rad-mosaic-2__card-eyebrow:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 1024px) {
    .rad-mosaic-2__card-eyebrow {
        font-size:0.875rem
    }
}

@media(min-width: 1441px) {
    .rad-mosaic-2__card-eyebrow {
        font-size:calc(0.875rem + 0.97425vw - 14.03897px)
    }
}

@media(min-width: 1920px) {
    .rad-mosaic-2__card-eyebrow {
        font-size:1.1666666667rem
    }
}

@media(min-width: 1024px) {
    .rad-mosaic-2__card-eyebrow {
        margin-bottom:1.5rem;
        margin-left: 0;
        margin-right: 0
    }
}

@media(min-width: 1441px) {
    .rad-mosaic-2__card-eyebrow {
        margin-bottom:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-mosaic-2__card-eyebrow {
        margin-bottom:2rem
    }
}

.rad-mosaic-2__card-sliding-content {
    display: grid;
    grid-template-columns: repeat(2,1fr);
    min-height: 0;
    -webkit-transition-duration: 550ms;
    transition-duration: 550ms;
    -webkit-transition-property: translate;
    transition-property: translate;
    -webkit-transition-timing-function: cubic-bezier(0.85,0,0,1);
    transition-timing-function: cubic-bezier(0.85,0,0,1);
    width: 200%
}

@media(min-width: 600px) {
    .rad-mosaic-2__card-sliding-content {
        -webkit-box-flex:1;
        -ms-flex-positive: 1;
        flex-grow: 1
    }
}

.rad-mosaic-2__card-back-content,.rad-mosaic-2__card-front-content {
    min-height: 0;
    position: relative
}

.rad-mosaic-2__card-back-content {
    -webkit-box-align: end;
    -ms-flex-align: end;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    align-items: flex-end;
    -ms-flex-direction: column;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 24px 16px
}

.rad-mosaic-2__card-back-content,.rad-mosaic-2__card-description-content {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.rad-mosaic-2__card-description-content {
    -webkit-box-flex: 1;
    -ms-flex: 1 0;
    flex: 1 0;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 100%;
    padding: 40px 40px 24px
}

@media(min-width: 1441px) {
    .rad-mosaic-2__card-description-content {
        padding-bottom:calc(-0.06681px + 1.67015vw)
    }
}

@media(min-width: 1920px) {
    .rad-mosaic-2__card-description-content {
        padding-bottom:32px
    }
}

@media(min-width: 1441px) {
    .rad-mosaic-2__card-description-content {
        padding-left:calc(-0.11134px + 2.78358vw)
    }
}

@media(min-width: 1920px) {
    .rad-mosaic-2__card-description-content {
        padding-left:53.3333333333px
    }
}

@media(min-width: 1441px) {
    .rad-mosaic-2__card-description-content {
        padding-right:calc(-0.11134px + 2.78358vw)
    }
}

@media(min-width: 1920px) {
    .rad-mosaic-2__card-description-content {
        padding-right:53.3333333333px
    }
}

@media(min-width: 1441px) {
    .rad-mosaic-2__card-description-content {
        padding-top:calc(-0.11134px + 2.78358vw)
    }
}

@media(min-width: 1920px) {
    .rad-mosaic-2__card-description-content {
        padding-top:53.3333333333px
    }
}

.rad-mosaic-2__card-description-content:before {
    background-color: #a100ff;
    content: "";
    display: block;
    height: 4px;
    margin-bottom: 24px;
    width: 32px
}

@media(min-width: 1441px) {
    .rad-mosaic-2__card-description-content:before {
        height:calc(-0.01113px + 0.27836vw)
    }
}

@media(min-width: 1920px) {
    .rad-mosaic-2__card-description-content:before {
        height:5.3333333333px
    }
}

@media(min-width: 1441px) {
    .rad-mosaic-2__card-description-content:before {
        margin-bottom:calc(-0.06681px + 1.67015vw)
    }
}

@media(min-width: 1920px) {
    .rad-mosaic-2__card-description-content:before {
        margin-bottom:32px
    }
}

@media(min-width: 1441px) {
    .rad-mosaic-2__card-description-content:before {
        width:calc(-0.08907px + 2.22686vw)
    }
}

@media(min-width: 1920px) {
    .rad-mosaic-2__card-description-content:before {
        width:42.6666666667px
    }
}

.rad-mosaic-2__card-title {
    font-size: 1.25rem;
    font-weight: 500;
    letter-spacing: -0.02em;
    line-height: 1.3;
    margin-bottom: 1.5rem;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    word-break: break-word
}

@media(min-width: 1441px) {
    .rad-mosaic-2__card-title {
        margin-bottom:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-mosaic-2__card-title {
        margin-bottom:2rem
    }
}

.rad-mosaic-2__card-title:lang(ja-JP),.rad-mosaic-2__card-title:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-mosaic-2__card-title {
        font-size:1.5rem;
        line-height: 1.3
    }
}

@media(min-width: 1024px) {
    .rad-mosaic-2__card-title {
        font-size:1.75rem;
        font-size: 1.125rem;
        font-weight: 500;
        left: 0;
        letter-spacing: -0.02em;
        line-height: 1.25;
        line-height: 1.4;
        margin-left: 0;
        margin-right: 0;
        position: absolute;
        top: 0;
        -webkit-transition-duration: 550ms;
        transition-duration: 550ms;
        -webkit-transition-property: top;
        transition-property: top;
        -webkit-transition-timing-function: cubic-bezier(0.85,0,0,1);
        transition-timing-function: cubic-bezier(0.85,0,0,1)
    }

    .rad-mosaic-2__card-title:lang(ja-JP),.rad-mosaic-2__card-title:lang(zh-CN) {
        font-weight: 700
    }

    .rad-mosaic-2__card-title {
        font-size: 1.25rem;
        font-size: 1.5rem;
        line-height: 1.4;
        line-height: 1.3
    }
}

@media(min-width: 1441px) {
    .rad-mosaic-2__card-title {
        font-size:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-mosaic-2__card-title {
        font-size:2rem
    }
}

@media(min-width: 1441px) {
    .rad-mosaic-2__card-title {
        font-size:1.25rem;
        font-weight: 500;
        letter-spacing: -0.02em;
        line-height: 1.3
    }

    .rad-mosaic-2__card-title:lang(ja-JP),.rad-mosaic-2__card-title:lang(zh-CN) {
        font-weight: 700
    }

    .rad-mosaic-2__card-title {
        font-size: 1.5rem;
        font-size: 1.75rem;
        font-size: calc(1.75rem + 1.9485vw - 28.07794px);
        line-height: 1.3;
        line-height: 1.25
    }
}

@media(min-width: 1920px) {
    .rad-mosaic-2__card-title {
        font-size:2.3333333333rem
    }
}

.rad-mosaic-2__card-sliding-title {
    margin-bottom: 1rem;
    overflow: hidden;
    position: relative
}

@media(min-width: 1441px) {
    .rad-mosaic-2__card-sliding-title {
        margin-bottom:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-mosaic-2__card-sliding-title {
        margin-bottom:1.3333333333rem
    }
}

.rad-mosaic-2__card .cmp-image__image {
    display: block;
    min-height: 100%;
    min-width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%
}

.rad-mosaic-2__card-stat {
    margin: 0 24px 40px
}

@media(min-width: 600px) {
    .rad-mosaic-2__card-stat {
        margin-bottom:60px
    }
}

@media(min-width: 1024px) {
    .rad-mosaic-2__card-stat {
        margin-bottom:24px;
        margin-left: 40px;
        margin-right: 40px
    }
}

@media(min-width: 1441px) {
    .rad-mosaic-2__card-stat {
        margin-bottom:calc(-0.06681px + 1.67015vw)
    }
}

@media(min-width: 1920px) {
    .rad-mosaic-2__card-stat {
        margin-bottom:32px
    }
}

@media(min-width: 1441px) {
    .rad-mosaic-2__card-stat {
        margin-left:calc(-0.11134px + 2.78358vw)
    }
}

@media(min-width: 1920px) {
    .rad-mosaic-2__card-stat {
        margin-left:53.3333333333px
    }
}

@media(min-width: 1441px) {
    .rad-mosaic-2__card-stat {
        margin-right:calc(-0.11134px + 2.78358vw)
    }
}

@media(min-width: 1920px) {
    .rad-mosaic-2__card-stat {
        margin-right:53.3333333333px
    }
}

.rad-mosaic-2__card-stat-eyebrow {
    font-size: .75rem;
    font-weight: 500;
    letter-spacing: .02em;
    line-height: 1.2;
    margin-bottom: .5rem;
    text-transform: uppercase
}

.rad-mosaic-2__card-stat-eyebrow:lang(ja-JP),.rad-mosaic-2__card-stat-eyebrow:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 1024px) {
    .rad-mosaic-2__card-stat-eyebrow {
        font-size:0.875rem
    }
}

@media(min-width: 1441px) {
    .rad-mosaic-2__card-stat-eyebrow {
        font-size:calc(0.875rem + 0.97425vw - 14.03897px)
    }
}

@media(min-width: 1920px) {
    .rad-mosaic-2__card-stat-eyebrow {
        font-size:1.1666666667rem
    }
}

@media(min-width: 1024px) {
    .rad-mosaic-2__card-stat-eyebrow {
        margin-bottom:0.75rem
    }
}

@media(min-width: 1441px) {
    .rad-mosaic-2__card-stat-eyebrow {
        margin-bottom:calc(0.75rem + 0.83507vw - 12.0334px)
    }
}

@media(min-width: 1920px) {
    .rad-mosaic-2__card-stat-eyebrow {
        margin-bottom:1rem
    }
}

.rad-mosaic-2__card-stat-figure {
    font-size: 1.75rem;
    font-weight: 500;
    letter-spacing: -0.03em;
    line-height: 1.3
}

.rad-mosaic-2__card-stat-figure:lang(ja-JP),.rad-mosaic-2__card-stat-figure:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-mosaic-2__card-stat-figure {
        font-size:2.5rem;
        line-height: 1.2
    }
}

@media(min-width: 1024px) {
    .rad-mosaic-2__card-stat-figure {
        font-size:3rem;
        line-height: 1.2
    }
}

@media(min-width: 1441px) {
    .rad-mosaic-2__card-stat-figure {
        font-size:calc(3rem + 3.34029vw - 48.13361px)
    }
}

@media(min-width: 1920px) {
    .rad-mosaic-2__card-stat-figure {
        font-size:4rem
    }
}

.rad-mosaic-2__card-stat-text {
    font-size: .875rem;
    font-weight: 500;
    font-weight: 400;
    letter-spacing: -0.005em;
    line-height: 1.5;
    text-underline-offset: .25rem
}

.rad-mosaic-2__card-stat-text:lang(ja-JP),.rad-mosaic-2__card-stat-text:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-mosaic-2__card-stat-text {
        font-size:1rem
    }
}

@media(min-width: 1441px) {
    .rad-mosaic-2__card-stat-text {
        font-size:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-mosaic-2__card-stat-text {
        font-size:1.3333333333rem
    }
}

.rad-mosaic-2__card-stat-text:lang(ja-JP),.rad-mosaic-2__card-stat-text:lang(zh-CN) {
    font-weight: 400
}

@media(min-width: 1024px) {
    .rad-mosaic-2__card-stat-text {
        padding-right:132px
    }
}

.rad-mosaic-2__card-arrow-icon {
    background-color: #a100ff;
    bottom: 0;
    height: 40px;
    position: absolute;
    right: 0;
    width: 40px
}

@media(min-width: 600px) {
    .rad-mosaic-2__card-arrow-icon {
        height:48px;
        width: 48px
    }
}

.rad-mosaic-2__card-body {
    font-size: .875rem;
    font-weight: 500;
    font-weight: 400;
    letter-spacing: -0.005em;
    line-height: 1.5;
    text-underline-offset: .25rem;
    width: 100%
}

.rad-mosaic-2__card-body:lang(ja-JP),.rad-mosaic-2__card-body:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-mosaic-2__card-body {
        font-size:1rem
    }
}

@media(min-width: 1441px) {
    .rad-mosaic-2__card-body {
        font-size:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-mosaic-2__card-body {
        font-size:1.3333333333rem
    }
}

.rad-mosaic-2__card-body:lang(ja-JP),.rad-mosaic-2__card-body:lang(zh-CN) {
    font-weight: 400
}

@media(min-width: 600px) {
    .rad-mosaic-2__card-body {
        -webkit-line-clamp:7;
        line-clamp: 7;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        min-height: 0;
        overflow: hidden
    }
}

@media(min-width: 1024px) {
    .rad-mosaic-2__card-body {
        -webkit-line-clamp:4;
        line-clamp: 4;
        position: relative;
        -webkit-transition-duration: 550ms;
        transition-duration: 550ms;
        -webkit-transition-property: translate;
        transition-property: translate;
        -webkit-transition-timing-function: cubic-bezier(0.85,0,0,1);
        transition-timing-function: cubic-bezier(0.85,0,0,1);
        translate: 0 100%
    }
}

.rad-mosaic-2__card-cta-button {
    margin-top: 16px;
    pointer-events: none
}

.rad-mosaic-2__card-cta-button .rad-icon:before {
    background: #7500c0
}

@media(min-width: 1024px) {
    .rad-mosaic-2__card-cta-button {
        margin-top:auto
    }
}

.rad-mosaic-2__card--open .rad-mosaic-2__card-front-toggle {
    height: calc(100% - 64px)
}

.rad-mosaic-2__card--open .rad-mosaic-2__card-cta-cover {
    height: 72px
}

.rad-mosaic-2__card--open .rad-mosaic-2__card-close-button {
    right: 0
}

.rad-mosaic-2__card--open .rad-mosaic-2__card-sliding-content {
    translate: -50%
}

.rad-mosaic-2__card--mobile {
    display: grid;
    grid-template-rows: auto auto 1fr
}

@media(min-width: 600px) {
    .rad-mosaic-2__card--mobile.rad-mosaic-2__card--horizontal.rad-mosaic-2__card--open .rad-mosaic-2__card-front-content .rad-mosaic-2__card-stat-eyebrow:before {
        background-color:#2b2b2b;
        width: 100%
    }
}

@media(min-width: 1024px) {
    .rad-mosaic-2__card--mobile {
        display:none
    }
}

.rad-mosaic-2__card--desktop {
    text-decoration: none
}

@media(max-width: 1023px) {
    .rad-mosaic-2__card--desktop {
        display:none
    }
}

.rad-mosaic-2__card--desktop .rad-mosaic-2__card-cta-button .rad-icon:before {
    background-image: -webkit-gradient(linear,left top,right top,color-stop(50%,#7500c0),color-stop(50%,#a100ff));
    background-image: linear-gradient(90deg,#7500c0 50%,#a100ff 0);
    background-position-x: 100%;
    background-size: 200% 200%
}

.rad-mosaic-2__card--desktop:hover .rad-mosaic-2__card-cta-button .rad-icon {
    translate: 0.25rem
}

@media(min-width: 1441px) {
    .rad-mosaic-2__card--desktop:hover .rad-mosaic-2__card-cta-button .rad-icon {
        translate:calc(0.25rem + 0.27836vw - 4.01113px)
    }
}

@media(min-width: 1920px) {
    .rad-mosaic-2__card--desktop:hover .rad-mosaic-2__card-cta-button .rad-icon {
        translate:0.3333333333rem
    }
}

.rad-mosaic-2__card--desktop:hover .rad-mosaic-2__card-cta-button .rad-icon:before {
    background-position-x: 0
}

.rad-mosaic-2__card--desktop:hover,.rad-mosaic-2__card--desktop:visited {
    color: #fff
}

.mosaic-modal {
    -ms-scroll-chaining: none;
    background-color: #000;
    bottom: 0;
    color: #fff;
    cursor: -webkit-grab;
    cursor: grab;
    height: 100%;
    left: 100vw;
    overflow-x: scroll;
    overscroll-behavior: none;
    position: fixed;
    right: -100vw;
    scrollbar-color: #a100ff #460073;
    scrollbar-width: thin;
    top: 0;
    -webkit-transition-duration: 1.25s;
    transition-duration: 1.25s;
    -webkit-transition-property: right,left;
    transition-property: right,left;
    -webkit-transition-timing-function: cubic-bezier(0.77,0,0.18,1);
    transition-timing-function: cubic-bezier(0.77,0,0.18,1);
    visibility: hidden
}

.mosaic-modal::-webkit-scrollbar {
    height: 4px;
    width: 4px
}

.mosaic-modal::-webkit-scrollbar-track {
    background-color: #460073
}

.mosaic-modal::-webkit-scrollbar-thumb {
    background-color: #a100ff
}

.mosaic-modal:active {
    cursor: -webkit-grabbing;
    cursor: grabbing
}

.mosaic-modal.mosaic-modal--visible {
    visibility: visible;
    z-index: 1110
}

.mosaic-modal.mosaic-modal--visible .mosaic-modal__mobile-next-button .rad-icon:after {
    -webkit-animation-delay: 200ms;
    animation-delay: 200ms;
    -webkit-animation-direction: alternate;
    animation-direction: alternate;
    -webkit-animation-duration: .55s;
    animation-duration: .55s;
    -webkit-animation-iteration-count: 10;
    animation-iteration-count: 10;
    -webkit-animation-name: arrowBounce;
    animation-name: arrowBounce;
    -webkit-animation-timing-function: cubic-bezier(0.75,0,0.75,0.9);
    animation-timing-function: cubic-bezier(0.75,0,0.75,0.9);
    -webkit-transform: translateX(0);
    transform: translateX(0)
}

.mosaic-modal.mosaic-modal--animating {
    left: 0;
    right: 0;
    visibility: visible;
    z-index: 1110
}

.mosaic-modal.mosaic-modal--animating .mosaic-modal__close-icon {
    right: .75rem
}

@media(min-width: 600px) {
    .mosaic-modal.mosaic-modal--animating .mosaic-modal__close-icon {
        right:2.25rem
    }
}

.mosaic-modal.mosaic-modal--animating .mosaic-modal__close {
    right: 5rem
}

@media(min-width: 600px) {
    .mosaic-modal.mosaic-modal--animating .mosaic-modal__cta {
        right:3rem
    }
}

@media(min-width: 1024px) {
    .mosaic-modal.mosaic-modal--animating .mosaic-modal__cta {
        right:5rem
    }
}

@media(min-width: 600px) {
    .mosaic-modal {
        padding-top:3rem
    }
}

@media(min-width: 1024px) {
    .mosaic-modal {
        padding-top:0
    }
}

.mosaic-modal .modal-hero {
    -ms-flex-negative: 0;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    flex-shrink: 0;
    justify-content: center;
    padding: 0 1rem;
    width: calc(100% - var(--rad-spacing-sm)*3/4*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-sm)*0)
}

@media(min-width: 600px) {
    .mosaic-modal .modal-hero {
        width:calc(50% - var(--rad-spacing-sm)*7/8*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .mosaic-modal .modal-hero {
        width:calc(33.33333% - var(--rad-spacing-sm)*11/12*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 600px) {
    .mosaic-modal .modal-hero {
        -webkit-box-sizing:content-box;
        box-sizing: content-box;
        padding-left: 3rem;
        width: calc(125% - var(--rad-spacing-sm)*3/4*5 + var(--rad-spacing-sm)*4 + var(--rad-spacing-sm)*0);
        width: calc(62.5% - var(--rad-spacing-sm)*7/8*5 + var(--rad-spacing-sm)*4 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .mosaic-modal .modal-hero {
        padding-left:5rem;
        width: calc(41.66667% - var(--rad-spacing-sm)*11/12*5 + var(--rad-spacing-sm)*4 + var(--rad-spacing-md)*0);
        width: calc(min(100vw, 1920px)/4*8 - var(--rad-spacing-sm)*2/4*8 - var(--rad-spacing-sm)*3/4*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-sm)*0);
        width: calc(min(100vw, 1920px)/8*8 - var(--rad-spacing-md)*2/8*8 - var(--rad-spacing-sm)*7/8*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0);
        width: calc(min(100vw, 1920px)/12*8 - var(--rad-spacing-md)*2/12*8 - var(--rad-spacing-sm)*11/12*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0)
    }
}

.mosaic-modal .modal-hero__eyebrow {
    font-size: .75rem;
    font-weight: 500;
    letter-spacing: .02em;
    line-height: 1.2;
    padding-bottom: 1.5rem;
    padding-top: 1.5rem;
    text-transform: uppercase
}

.mosaic-modal .modal-hero__eyebrow:lang(ja-JP),.mosaic-modal .modal-hero__eyebrow:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 1024px) {
    .mosaic-modal .modal-hero__eyebrow {
        font-size:0.875rem
    }
}

@media(min-width: 1441px) {
    .mosaic-modal .modal-hero__eyebrow {
        font-size:calc(0.875rem + 0.97425vw - 14.03897px)
    }
}

@media(min-width: 1920px) {
    .mosaic-modal .modal-hero__eyebrow {
        font-size:1.1666666667rem
    }
}

@media(min-width: 600px) {
    .mosaic-modal .modal-hero__eyebrow {
        padding-bottom:2rem;
        padding-top: 0
    }
}

@media(min-width: 1024px) {
    .mosaic-modal .modal-hero__eyebrow {
        padding-bottom:1.5rem
    }
}

.mosaic-modal .modal-hero__headline {
    font-size: 1.75rem;
    font-weight: 500;
    letter-spacing: -0.03em;
    line-height: 1.3
}

.mosaic-modal .modal-hero__headline:lang(ja-JP),.mosaic-modal .modal-hero__headline:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .mosaic-modal .modal-hero__headline {
        font-size:2.5rem;
        line-height: 1.2
    }
}

@media(min-width: 1024px) {
    .mosaic-modal .modal-hero__headline {
        font-size:3rem;
        line-height: 1.2
    }
}

@media(min-width: 1441px) {
    .mosaic-modal .modal-hero__headline {
        font-size:calc(3rem + 3.34029vw - 48.13361px)
    }
}

@media(min-width: 1920px) {
    .mosaic-modal .modal-hero__headline {
        font-size:4rem
    }
}

.mosaic-modal .modal-hero__subheader {
    font-family: GT Sectra Fine,Palatino;
    font-size: 1.125rem;
    font-weight: 300;
    line-height: 1.3
}

@media(min-width: 600px) {
    .mosaic-modal .modal-hero__subheader {
        font-size:1.25rem;
        line-height: 1.3
    }
}

@media(min-width: 1024px) {
    .mosaic-modal .modal-hero__subheader {
        font-size:1.5rem;
        line-height: 1.25
    }
}

@media(min-width: 1441px) {
    .mosaic-modal .modal-hero__subheader {
        font-size:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .mosaic-modal .modal-hero__subheader {
        font-size:2rem
    }
}

.mosaic-modal .modal-hero:focus-visible {
    outline-offset: -0.5rem
}

@media(min-width: 1024px) {
    .mosaic-modal .modal-hero:focus-visible {
        outline-offset:-1rem
    }
}

.mosaic-modal .modal-inner-scroll {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    gap: 3rem;
    height: 100%;
    min-height: -webkit-max-content;
    min-height: -moz-max-content;
    min-height: max-content;
    padding-bottom: 3rem
}

@media(min-width: 600px) {
    .mosaic-modal .modal-inner-scroll {
        gap:14.5vw
    }
}

@media(min-width: 1024px) {
    .mosaic-modal .modal-inner-scroll {
        gap:6.6vw;
        padding: 100px 0
    }
}

@media(min-width: 1024px)and (min-height:868px) {
    .mosaic-modal .modal-inner-scroll {
        gap:10vw;
        padding: 104px 0
    }
}

@media(min-width: 1024px)and (min-height:1036px) {
    .mosaic-modal .modal-inner-scroll {
        padding:136px 0
    }
}

.mosaic-modal--with-stat .modal-hero {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

@media(min-width: 1024px) {
    .mosaic-modal--with-stat .modal-hero {
        -webkit-box-pack:center;
        -ms-flex-pack: center;
        justify-content: center
    }
}

@media(min-width: 1024px)and (min-height:868px) {
    .mosaic-modal--with-stat .modal-hero {
        gap:3rem
    }
}

@media(min-width: 1024px)and (min-height:1036px) {
    .mosaic-modal--with-stat .modal-hero {
        gap:5rem
    }
}

.mosaic-modal--with-stat .modal-hero__stat {
    margin-right: 20%;
    padding-top: 2rem
}

@media(min-width: 600px) {
    .mosaic-modal--with-stat .modal-hero__stat {
        padding-top:3rem
    }
}

@media(min-width: 1024px) {
    .mosaic-modal--with-stat .modal-hero__stat {
        width:calc(min(100vw, 1920px)/4*4 - var(--rad-spacing-sm)*2/4*4 - var(--rad-spacing-sm)*3/4*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-sm)*0);
        width: calc(min(100vw, 1920px)/8*4 - var(--rad-spacing-md)*2/8*4 - var(--rad-spacing-sm)*7/8*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0);
        width: calc(min(100vw, 1920px)/12*4 - var(--rad-spacing-md)*2/12*4 - var(--rad-spacing-sm)*11/12*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0)
    }
}

.mosaic-modal--with-stat .modal-hero__stat .stat__digits {
    font-size: 1.75rem;
    font-weight: 500;
    letter-spacing: -0.03em;
    line-height: 1.3;
    padding-bottom: .5rem
}

.mosaic-modal--with-stat .modal-hero__stat .stat__digits:lang(ja-JP),.mosaic-modal--with-stat .modal-hero__stat .stat__digits:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .mosaic-modal--with-stat .modal-hero__stat .stat__digits {
        font-size:2.5rem;
        line-height: 1.2
    }
}

@media(min-width: 1024px) {
    .mosaic-modal--with-stat .modal-hero__stat .stat__digits {
        font-size:3rem;
        line-height: 1.2
    }
}

@media(min-width: 1441px) {
    .mosaic-modal--with-stat .modal-hero__stat .stat__digits {
        font-size:calc(3rem + 3.34029vw - 48.13361px)
    }
}

@media(min-width: 1920px) {
    .mosaic-modal--with-stat .modal-hero__stat .stat__digits {
        font-size:4rem
    }
}

@media(min-width: 1024px) {
    .mosaic-modal--with-stat .modal-hero__stat .stat__digits {
        padding-bottom:0.75rem
    }
}

@media(min-width: 1441px) {
    .mosaic-modal--with-stat .modal-hero__stat .stat__digits {
        padding-bottom:calc(0.75rem + 0.83507vw - 12.0334px)
    }
}

@media(min-width: 1920px) {
    .mosaic-modal--with-stat .modal-hero__stat .stat__digits {
        padding-bottom:1rem
    }
}

.mosaic-modal--with-stat .modal-hero__stat .stat__digits:before {
    background: #a100ff;
    content: "";
    display: block;
    height: .25rem;
    margin-bottom: .5rem;
    width: 1.5rem
}

@media(min-width: 600px) {
    .mosaic-modal--with-stat .modal-hero__stat .stat__digits:before {
        margin-bottom:.75rem
    }
}

@media(min-width: 1024px) {
    .mosaic-modal--with-stat .modal-hero__stat .stat__digits:before {
        margin-bottom:1rem;
        width: 2rem
    }
}

@media(min-width: 1441px) {
    .mosaic-modal--with-stat .modal-hero__stat .stat__digits:before {
        margin-bottom:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .mosaic-modal--with-stat .modal-hero__stat .stat__digits:before {
        margin-bottom:1.3333333333rem
    }
}

.mosaic-modal--with-stat .modal-hero__stat .stat__detail {
    font-size: .875rem;
    font-weight: 500;
    font-weight: 400;
    letter-spacing: -0.005em;
    line-height: 1.5;
    padding-bottom: 2rem;
    text-underline-offset: .25rem
}

.mosaic-modal--with-stat .modal-hero__stat .stat__detail:lang(ja-JP),.mosaic-modal--with-stat .modal-hero__stat .stat__detail:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .mosaic-modal--with-stat .modal-hero__stat .stat__detail {
        font-size:1rem
    }
}

@media(min-width: 1441px) {
    .mosaic-modal--with-stat .modal-hero__stat .stat__detail {
        font-size:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .mosaic-modal--with-stat .modal-hero__stat .stat__detail {
        font-size:1.3333333333rem
    }
}

.mosaic-modal--with-stat .modal-hero__stat .stat__detail:lang(ja-JP),.mosaic-modal--with-stat .modal-hero__stat .stat__detail:lang(zh-CN) {
    font-weight: 400
}

@media(min-width: 600px)and (max-width:1023px) {
    .mosaic-modal--with-stat .modal-hero__stat .stat__detail {
        padding-bottom:5.5rem
    }
}

@media(min-width: 1024px)and (max-height:867px) {
    .mosaic-modal--with-stat .modal-hero {
        -webkit-box-orient:horizontal;
        -webkit-box-direction: normal;
        -ms-flex-item-align: center;
        align-self: center;
        -ms-flex-direction: row;
        flex-direction: row;
        gap: 6.6%;
        width: calc(275% - var(--rad-spacing-sm)*3/4*11 + var(--rad-spacing-sm)*10 + var(--rad-spacing-sm)*0);
        width: calc(137.5% - var(--rad-spacing-sm)*7/8*11 + var(--rad-spacing-sm)*10 + var(--rad-spacing-md)*0);
        width: calc(91.66667% - var(--rad-spacing-sm)*11/12*11 + var(--rad-spacing-sm)*10 + var(--rad-spacing-md)*0)
    }

    .mosaic-modal--with-stat .modal-hero__top {
        padding-bottom: 2.25rem;
        padding-top: 2.25rem;
        width: calc(min(100vw, 1920px)/4*8 - var(--rad-spacing-sm)*2/4*8 - var(--rad-spacing-sm)*3/4*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-sm)*0);
        width: calc(min(100vw, 1920px)/8*8 - var(--rad-spacing-md)*2/8*8 - var(--rad-spacing-sm)*7/8*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0);
        width: calc(min(100vw, 1920px)/12*8 - var(--rad-spacing-md)*2/12*8 - var(--rad-spacing-sm)*11/12*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0)
    }

    .mosaic-modal--with-stat .modal-hero__subheader {
        padding-bottom: 2.25rem
    }

    .mosaic-modal--with-stat .modal-hero__stat {
        margin-right: 5vw;
        padding-top: 60px;
        width: calc(min(100vw, 1920px)/4*3 - var(--rad-spacing-sm)*2/4*3 - var(--rad-spacing-sm)*3/4*3 + var(--rad-spacing-sm)*2 + var(--rad-spacing-sm)*0);
        width: calc(min(100vw, 1920px)/8*3 - var(--rad-spacing-md)*2/8*3 - var(--rad-spacing-sm)*7/8*3 + var(--rad-spacing-sm)*2 + var(--rad-spacing-md)*0);
        width: calc(min(100vw, 1920px)/12*3 - var(--rad-spacing-md)*2/12*3 - var(--rad-spacing-sm)*11/12*3 + var(--rad-spacing-sm)*2 + var(--rad-spacing-md)*0)
    }

    .mosaic-modal--with-stat .modal-inner-scroll {
        gap: 0
    }
}

@media(min-width: 1024px)and (min-height:1036px) {
    .mosaic-modal--with-stat {
        padding-top:3rem
    }

    .mosaic-modal--with-stat .modal-inner-scroll {
        gap: 10vw
    }
}

.modal__card-stack {
    -ms-flex-item-align: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    align-self: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    gap: 3rem
}

@media(min-width: 600px) {
    .modal__card-stack {
        gap:10vw
    }
}

@media(min-width: 1024px) {
    .modal__card-stack {
        gap:5vw;
        padding-right: 5rem
    }
}

.modal__card-stack .card {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    width: 72.4vw
}

@media(min-width: 600px) {
    .modal__card-stack .card {
        width:calc(min(100vw, 1920px)/4*5 - var(--rad-spacing-sm)*2/4*5 - var(--rad-spacing-sm)*3/4*5 + var(--rad-spacing-sm)*4 + var(--rad-spacing-sm)*0);
        width: calc(min(100vw, 1920px)/8*5 - var(--rad-spacing-md)*2/8*5 - var(--rad-spacing-sm)*7/8*5 + var(--rad-spacing-sm)*4 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .modal__card-stack .card {
        width:calc(min(100vw, 1920px)/12*5 - var(--rad-spacing-md)*2/12*5 - var(--rad-spacing-sm)*11/12*5 + var(--rad-spacing-sm)*4 + var(--rad-spacing-md)*0);
        width: calc(min(100vw, 1920px)/4*4 - var(--rad-spacing-sm)*2/4*4 - var(--rad-spacing-sm)*3/4*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-sm)*0);
        width: calc(min(100vw, 1920px)/8*4 - var(--rad-spacing-md)*2/8*4 - var(--rad-spacing-sm)*7/8*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0);
        width: calc(min(100vw, 1920px)/12*4 - var(--rad-spacing-md)*2/12*4 - var(--rad-spacing-sm)*11/12*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0)
    }
}

.modal__card-stack .card img {
    height: 3rem;
    width: 3rem
}

@media(min-width: 600px) {
    .modal__card-stack .card img {
        height:5rem;
        width: 5rem
    }
}

@media(min-width: 1441px) {
    .modal__card-stack .card img {
        height:calc(5rem + 5.56715vw - 80.22269px)
    }
}

@media(min-width: 1920px) {
    .modal__card-stack .card img {
        height:6.6666666667rem
    }
}

@media(min-width: 1441px) {
    .modal__card-stack .card img {
        width:calc(5rem + 5.56715vw - 80.22269px)
    }
}

@media(min-width: 1920px) {
    .modal__card-stack .card img {
        width:6.6666666667rem
    }
}

.modal__card-stack .card__title {
    font-size: 1.125rem;
    font-weight: 500;
    letter-spacing: -0.02em;
    line-height: 1.4;
    padding-bottom: .5rem;
    padding-top: 1rem
}

.modal__card-stack .card__title:lang(ja-JP),.modal__card-stack .card__title:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .modal__card-stack .card__title {
        font-size:1.25rem;
        line-height: 1.4
    }
}

@media(min-width: 1024px) {
    .modal__card-stack .card__title {
        font-size:1.5rem;
        line-height: 1.3
    }
}

@media(min-width: 1441px) {
    .modal__card-stack .card__title {
        font-size:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .modal__card-stack .card__title {
        font-size:2rem
    }
}

@media(min-width: 600px) {
    .modal__card-stack .card__title {
        padding-bottom:.75rem;
        padding-top: 1.5rem
    }
}

@media(min-width: 1441px) {
    .modal__card-stack .card__title {
        padding-top:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .modal__card-stack .card__title {
        padding-top:2rem
    }
}

@media(min-width: 1024px) {
    .modal__card-stack .card__title {
        padding-bottom:1rem
    }
}

@media(min-width: 1441px) {
    .modal__card-stack .card__title {
        padding-bottom:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .modal__card-stack .card__title {
        padding-bottom:1.3333333333rem
    }
}

.modal__card-stack .card__body {
    font-size: .875rem;
    font-weight: 500;
    font-weight: 400;
    letter-spacing: -0.005em;
    line-height: 1.5;
    text-underline-offset: .25rem
}

.modal__card-stack .card__body:lang(ja-JP),.modal__card-stack .card__body:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .modal__card-stack .card__body {
        font-size:1rem
    }
}

@media(min-width: 1441px) {
    .modal__card-stack .card__body {
        font-size:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .modal__card-stack .card__body {
        font-size:1.3333333333rem
    }
}

.modal__card-stack .card__body:lang(ja-JP),.modal__card-stack .card__body:lang(zh-CN) {
    font-weight: 400
}

.mosaic-modal__close-icon {
    position: fixed;
    right: -100vw;
    top: .75rem;
    -webkit-transition-duration: 1.25s;
    transition-duration: 1.25s;
    -webkit-transition-property: right,left;
    transition-property: right,left;
    -webkit-transition-timing-function: cubic-bezier(0.77,0,0.18,1);
    transition-timing-function: cubic-bezier(0.77,0,0.18,1)
}

@media(min-width: 600px) {
    .mosaic-modal__close-icon {
        top:2.25rem
    }
}

@media(min-width: 1024px) {
    .mosaic-modal__close-icon {
        display:none
    }
}

.mosaic-modal__close {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    display: none;
    height: 3rem;
    justify-content: center;
    position: fixed;
    right: -100vw;
    top: 1.5rem;
    -webkit-transition-duration: 1.25s;
    transition-duration: 1.25s;
    -webkit-transition-property: right,left;
    transition-property: right,left;
    -webkit-transition-timing-function: cubic-bezier(0.77,0,0.18,1);
    transition-timing-function: cubic-bezier(0.77,0,0.18,1);
    width: 3rem
}

@media(min-width: 1024px) {
    .mosaic-modal__close {
        display:-webkit-box;
        display: -ms-flexbox;
        display: flex;
        top: 1.5rem
    }
}

.mosaic-modal__buttons-wrapper {
    height: 1px
}

.mosaic-modal__mobile-next-button {
    bottom: 3rem;
    height: 1.5rem;
    overflow: hidden;
    position: absolute;
    right: 1rem;
    width: 1.5rem
}

@-webkit-keyframes arrowBounce {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }

    to {
        -webkit-transform: translateX(-5px);
        transform: translateX(-5px)
    }
}

@keyframes arrowBounce {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }

    to {
        -webkit-transform: translateX(-5px);
        transform: translateX(-5px)
    }
}

@media(min-width: 600px) {
    .mosaic-modal__mobile-next-button {
        display:none
    }
}

.mosaic-modal__cta {
    background-color: #000;
    position: absolute;
    top: calc(100% - 5.5rem);
    -webkit-transform: translateX(140vw);
    transform: translateX(140vw)
}

@media(min-width: 600px) {
    .mosaic-modal__cta {
        bottom:3rem;
        position: fixed;
        right: -100vw;
        -webkit-transform: none;
        transform: none;
        -webkit-transition-duration: 1.25s;
        transition-duration: 1.25s;
        -webkit-transition-property: right,left;
        transition-property: right,left;
        -webkit-transition-timing-function: cubic-bezier(0.77,0,0.18,1);
        transition-timing-function: cubic-bezier(0.77,0,0.18,1)
    }
}

.mosaic-modal__cta--is-fixed {
    padding-left: 1.875rem;
    position: fixed;
    right: 1rem;
    top: calc(100% - 4px - 5.5rem);
    -webkit-transform: none;
    transform: none
}

.background-dimmer {
    background-color: #000;
    bottom: 0;
    left: 0;
    opacity: 0;
    position: fixed;
    right: 0;
    top: 0;
    -webkit-transition-duration: .7s;
    transition-duration: .7s;
    -webkit-transition-property: opacity;
    transition-property: opacity;
    -webkit-transition-timing-function: linear;
    transition-timing-function: linear
}

.background-dimmer--darkened {
    opacity: 1
}

@media(min-width: 1920px) {
    #main .rad-global-nav {
        margin-left:calc(-50vw + 960px);
        margin-right: calc(-50vw + 960px)
    }
}

.rad-global-nav {
    -ms-scroll-chaining: none;
    overscroll-behavior: none;
    padding-top: var(--rad-nav-height)
}

.rad-global-nav__wrapper {
    -webkit-animation-duration: 550ms;
    animation-duration: 550ms;
    -webkit-animation-name: enter-from-top;
    animation-name: enter-from-top;
    -webkit-animation-timing-function: cubic-bezier(0.85,0,0,1);
    animation-timing-function: cubic-bezier(0.85,0,0,1);
    background-color: #000;
    position: fixed;
    top: 0;
    -webkit-transition-duration: 550ms;
    transition-duration: 550ms;
    -webkit-transition-property: top;
    transition-property: top;
    width: 100%;
    z-index: 1010
}

@-webkit-keyframes enter-from-top {
    0% {
        top: calc(var(--rad-nav-height)*-1)
    }
}

@keyframes enter-from-top {
    0% {
        top: calc(var(--rad-nav-height)*-1)
    }
}

.rad-global-nav__container {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #000;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: var(--rad-nav-height);
    justify-content: space-between;
    padding-left: .125rem;
    padding-right: .125rem
}

.rad-global-nav__container a:focus-visible,.rad-global-nav__container button:focus-visible {
    outline-offset: 0
}

@media(min-width: 600px) {
    .rad-global-nav__container {
        padding-left:2.125rem;
        padding-right: 3rem
    }
}

@media(min-width: 1024px) {
    .rad-global-nav__container {
        padding-left:5rem;
        padding-right: 5rem;
        position: absolute;
        width: 100%
    }
}

@media(min-width: 1441px) {
    .rad-global-nav__container {
        left:50%;
        max-width: 1920px;
        padding-left: 5rem;
        padding-left: calc(5rem + 5.56715vw - 80.22269px);
        padding-right: 5rem;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%)
    }
}

@media(min-width: 1920px) {
    .rad-global-nav__container {
        padding-left:6.6666666667rem
    }
}

@media(min-width: 1441px) {
    .rad-global-nav__container {
        padding-right:calc(5rem + 5.56715vw - 80.22269px)
    }
}

@media(min-width: 1920px) {
    .rad-global-nav__container {
        padding-right:6.6666666667rem
    }
}

.rad-global-nav__menu .rad-icon__menu {
    height: 1.25rem;
    width: 1.25rem
}

.rad-global-nav__menu .rad-icon__menu:after {
    font-size: .833125rem
}

@media(min-width: 1024px) {
    .rad-global-nav__menu {
        display:none
    }
}

.rad-global-nav__logo {
    left: 50vw;
    position: absolute;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%)
}

.rad-global-nav__logo .cmp-logo {
    display: none
}

@media(min-width: 600px) {
    .rad-global-nav__logo {
        left:45vw;
        position: absolute;
        -webkit-transform: none;
        transform: none
    }
}

@media(min-width: 1024px) {
    .rad-global-nav__logo {
        position:static
    }

    .rad-global-nav__logo a:focus-visible {
        outline-offset: 8px
    }
}

@media(min-width: 1200px) {
    .rad-global-nav__logo .cmp-logo {
        display:block;
        height: 2rem
    }
}

@media(min-width: 1441px) {
    .rad-global-nav__logo .cmp-logo {
        height:calc(2rem + 2.22686vw - 32.08907px)
    }
}

@media(min-width: 1920px) {
    .rad-global-nav__logo .cmp-logo {
        height:2.6666666667rem
    }
}

@media(min-width: 1200px) {
    .rad-global-nav__logo .cmp-logo__svg-wrapper {
        width:7.625rem
    }
}

@media(min-width: 1441px) {
    .rad-global-nav__logo .cmp-logo__svg-wrapper {
        width:calc(7.625rem + 8.48991vw - 122.3396px)
    }
}

@media(min-width: 1920px) {
    .rad-global-nav__logo .cmp-logo__svg-wrapper {
        width:10.1666666667rem
    }
}

.rad-global-nav__static-logo {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 3rem;
    justify-content: center;
    width: 3rem
}

.rad-global-nav__static-logo svg {
    height: 1.5rem;
    width: 1.5rem
}

@media(min-width: 600px) {
    .rad-global-nav__static-logo {
        translate:-8px
    }

    .rad-global-nav__static-logo svg {
        height: 2rem;
        width: 2rem
    }
}

@media(min-width: 1024px) {
    .rad-global-nav__static-logo {
        height:2.5rem;
        translate: none;
        width: 2.5rem
    }
}

@media(min-width: 1200px) {
    .rad-global-nav__static-logo {
        display:none
    }
}

.rad-global-nav__mobile-footer {
    display: none
}

.rad-global-nav__primary-nav {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    background-color: #202020;
    bottom: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 100svh;
    justify-content: space-between;
    left: 100vw;
    position: absolute;
    top: 0;
    -webkit-transition-duration: 550ms;
    transition-duration: 550ms;
    -webkit-transition-property: left;
    transition-property: left;
    -webkit-transition-timing-function: cubic-bezier(0.85,0,0,1);
    transition-timing-function: cubic-bezier(0.85,0,0,1);
    width: 100vw
}

@media(min-width: 1024px) {
    .rad-global-nav__primary-nav {
        -webkit-box-orient:horizontal;
        -webkit-box-direction: normal;
        background-color: transparent;
        bottom: unset;
        -ms-flex-direction: row;
        flex-direction: row;
        height: 100%;
        left: unset;
        position: static;
        top: unset;
        -webkit-transition: none;
        transition: none;
        width: auto
    }
}

.rad-global-nav__menu-close {
    left: .125rem;
    position: absolute;
    top: .25rem
}

@media(min-width: 600px) {
    .rad-global-nav__menu-close {
        left:2.125rem;
        top: .5rem
    }
}

@media(min-width: 1024px) {
    .rad-global-nav__menu-close {
        display:none
    }
}

.rad-global-nav__menu-close .rad-icon__close:after {
    font-size: .729375rem
}

.rad-global-nav__menu-close .rad-icon__search {
    height: 20px;
    width: 20px
}

.rad-global-nav__menu-items {
    font-size: .875rem;
    font-weight: 500;
    letter-spacing: -0.005em;
    line-height: 1.5;
    margin-top: var(--rad-nav-height);
    padding: 1rem 1rem 0;
    text-underline-offset: .25rem
}

.rad-global-nav__menu-items:lang(ja-JP),.rad-global-nav__menu-items:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-global-nav__menu-items {
        font-size:1rem
    }
}

@media(min-width: 1441px) {
    .rad-global-nav__menu-items {
        font-size:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-global-nav__menu-items {
        font-size:1.3333333333rem
    }
}

@media(min-width: 600px) {
    .rad-global-nav__menu-items {
        padding-left:3rem;
        padding-right: 3rem;
        padding-top: 2rem
    }
}

@media(min-width: 1024px) {
    .rad-global-nav__menu-items {
        -webkit-box-orient:horizontal;
        -webkit-box-direction: normal;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: row;
        flex-direction: row;
        font-size: .75rem;
        font-weight: 500;
        font-weight: 400;
        gap: .5rem;
        letter-spacing: -0.005em;
        line-height: 1.5;
        margin-top: 0;
        padding-top: 0;
        text-underline-offset: .25rem
    }

    .rad-global-nav__menu-items:lang(ja-JP),.rad-global-nav__menu-items:lang(zh-CN) {
        font-weight: 700
    }

    .rad-global-nav__menu-items {
        font-size: 0.875rem
    }
}

@media(min-width: 1441px) {
    .rad-global-nav__menu-items {
        font-size:calc(0.875rem + 0.97425vw - 14.03897px)
    }
}

@media(min-width: 1920px) {
    .rad-global-nav__menu-items {
        font-size:1.1666666667rem
    }
}

@media(min-width: 1024px) {
    .rad-global-nav__menu-items:lang(ja-JP),.rad-global-nav__menu-items:lang(zh-CN) {
        font-weight:400
    }
}

.rad-global-nav__l1--button,.rad-global-nav__l1--link {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    height: 100%;
    justify-content: space-between;
    width: 100%
}

@media(min-width: 1024px) {
    .rad-global-nav__l1--button,.rad-global-nav__l1--link {
        -webkit-box-pack:normal;
        -ms-flex-pack: normal;
        font-size: .75rem;
        font-weight: 500;
        font-weight: 400;
        justify-content: normal;
        letter-spacing: -0.005em;
        line-height: 1.5;
        padding-left: .5rem;
        padding-right: .5rem;
        text-underline-offset: .25rem;
        white-space: nowrap;
        width: auto
    }

    .rad-global-nav__l1--button:lang(ja-JP),.rad-global-nav__l1--button:lang(zh-CN),.rad-global-nav__l1--link:lang(ja-JP),.rad-global-nav__l1--link:lang(zh-CN) {
        font-weight: 700
    }

    .rad-global-nav__l1--button,.rad-global-nav__l1--link {
        font-size: 0.875rem
    }
}

@media(min-width: 1441px) {
    .rad-global-nav__l1--button,.rad-global-nav__l1--link {
        font-size:calc(0.875rem + 0.97425vw - 14.03897px)
    }
}

@media(min-width: 1920px) {
    .rad-global-nav__l1--button,.rad-global-nav__l1--link {
        font-size:1.1666666667rem
    }
}

@media(min-width: 1024px) {
    .rad-global-nav__l1--button:lang(ja-JP),.rad-global-nav__l1--button:lang(zh-CN),.rad-global-nav__l1--link:lang(ja-JP),.rad-global-nav__l1--link:lang(zh-CN) {
        font-weight:400
    }

    .rad-global-nav__l1--button .rad-button__icon-right,.rad-global-nav__l1--link .rad-button__icon-right {
        margin-left: 0.5rem
    }
}

@media(min-width: 1441px) {
    .rad-global-nav__l1--button .rad-button__icon-right,.rad-global-nav__l1--link .rad-button__icon-right {
        margin-left:calc(0.5rem + 0.55672vw - 8.02227px)
    }
}

@media(min-width: 1920px) {
    .rad-global-nav__l1--button .rad-button__icon-right,.rad-global-nav__l1--link .rad-button__icon-right {
        margin-left:0.6666666667rem
    }
}

@media(min-width: 1024px) {
    .rad-global-nav__l1--button .rad-button__text:after,.rad-global-nav__l1--link .rad-button__text:after {
        display:none;
        -webkit-transition: none;
        transition: none
    }

    .rad-global-nav__l1--button:active,.rad-global-nav__l1--link:active {
        color: #e3e3df
    }

    .rad-global-nav__l1--button:hover,.rad-global-nav__l1--link:hover {
        color: #a2a2a0
    }

    .rad-global-nav__l1--button:hover .rad-button__text:after,.rad-global-nav__l1--link:hover .rad-button__text:after {
        display: none;
        -webkit-transition: none;
        transition: none
    }

    .rad-global-nav__l1--button.rad-global-nav__l1--long-content .rad-button__text,.rad-global-nav__l1--link.rad-global-nav__l1--long-content .rad-button__text {
        text-align: center;
        white-space: normal
    }
}

.rad-global-nav__l1--button .rad-icon__chevron-down:after,.rad-global-nav__l1--link .rad-icon__chevron-down:after {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    -webkit-transition-duration: 550ms;
    transition-duration: 550ms;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform,-webkit-transform;
    -webkit-transition-timing-function: cubic-bezier(0.85,0,0,1);
    transition-timing-function: cubic-bezier(0.85,0,0,1)
}

@media(min-width: 1024px) {
    .rad-global-nav__l1--button .rad-icon__chevron-down:after,.rad-global-nav__l1--link .rad-icon__chevron-down:after {
        -webkit-transform:rotate(0);
        transform: rotate(0)
    }
}

.rad-global-nav__l1--button .rad-button__text {
    position: relative
}

.rad-global-nav__l1--button .rad-button__text:after {
    border-bottom: 1px solid;
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    width: 0
}

.rad-global-nav__l1--button .rad-button__text:after,.rad-global-nav__l1--button .rad-button__text:hover:after {
    display: block;
    -webkit-transition-duration: 550ms;
    transition-duration: 550ms;
    -webkit-transition-property: width;
    transition-property: width;
    -webkit-transition-timing-function: cubic-bezier(0.85,0,0,1);
    transition-timing-function: cubic-bezier(0.85,0,0,1)
}

.rad-global-nav__l1--active .rad-global-nav__l1--button .rad-icon__chevron-down:after {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg)
}

.rad-global-nav__l1--active .rad-global-nav__l1--button .rad-button__text:after {
    width: 100%
}

.rad-global-nav__l1--active .rad-global-nav__l1--button:active {
    color: #e3e3df
}

.rad-global-nav__l1--active .rad-global-nav__l1--button:hover {
    color: #a2a2a0
}

.rad-global-nav__l1--active .rad-global-nav__l1--button:focus-visible {
    color: #fff
}

.rad-global-nav__l2-button {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: space-between;
    padding: .5rem 0;
    text-align: left;
    text-decoration: none;
    width: 100%
}

.rad-global-nav__l2-button:visited {
    color: #fff
}

@media(max-width: 1023px) {
    .rad-global-nav__l2-button {
        position:static
    }

    .rad-global-nav__l2-button:after {
        display: none
    }
}

.rad-global-nav__l2-label {
    display: none
}

@media(min-width: 600px) {
    .rad-global-nav .rad-global-nav__l2-button {
        display:none
    }

    .rad-global-nav .rad-global-nav__l2-label {
        color: #a2a2a0;
        display: block;
        font-size: .75rem;
        font-weight: 500;
        font-weight: 400;
        letter-spacing: -0.005em;
        line-height: 1.5;
        text-underline-offset: .25rem
    }

    .rad-global-nav .rad-global-nav__l2-label:lang(ja-JP),.rad-global-nav .rad-global-nav__l2-label:lang(zh-CN) {
        font-weight: 700
    }

    .rad-global-nav .rad-global-nav__l2-label {
        font-size: 0.875rem
    }
}

@media(min-width: 1441px) {
    .rad-global-nav .rad-global-nav__l2-label {
        font-size:calc(0.875rem + 0.97425vw - 14.03897px)
    }
}

@media(min-width: 1920px) {
    .rad-global-nav .rad-global-nav__l2-label {
        font-size:1.1666666667rem
    }
}

@media(min-width: 600px) {
    .rad-global-nav .rad-global-nav__l2-label:lang(ja-JP),.rad-global-nav .rad-global-nav__l2-label:lang(zh-CN) {
        font-weight:400
    }
}

.rad-global-nav__menu-item-content {
    background-color: #202020;
    bottom: 0;
    height: calc(100svh - var(--rad-nav-height));
    left: 100vw;
    padding-top: 1rem;
    position: absolute;
    top: var(--rad-nav-height);
    -webkit-transition-duration: 550ms;
    transition-duration: 550ms;
    -webkit-transition-property: left;
    transition-property: left;
    -webkit-transition-timing-function: cubic-bezier(0.85,0,0,1);
    transition-timing-function: cubic-bezier(0.85,0,0,1);
    width: 100%;
    z-index: 3
}

.rad-global-nav__menu-item-content a:focus-visible {
    outline-offset: -2px
}

.rad-global-nav__menu-item-content.rad-global-nav__drawer--open {
    left: 0
}

@media(min-width: 600px) {
    .rad-global-nav__menu-item-content {
        padding-left:3rem;
        padding-top: 2rem
    }
}

@media(min-width: 1024px) {
    .rad-global-nav__menu-item-content {
        bottom:unset;
        height: 0;
        left: 0;
        overflow: hidden;
        padding-left: 5rem;
        padding-right: 5rem;
        padding-top: 0;
        right: 0;
        top: 4.5rem;
        -webkit-transition-duration: 550ms;
        transition-duration: 550ms;
        -webkit-transition-property: height;
        transition-property: height;
        -webkit-transition-timing-function: cubic-bezier(0.85,0,0,1);
        transition-timing-function: cubic-bezier(0.85,0,0,1);
        width: 100vw;
        z-index: -1
    }
}

@media(min-width: 1441px) {
    .rad-global-nav__menu-item-content {
        top:calc(4.5rem + 5.01044vw - 72.20042px)
    }
}

@media(min-width: 1920px) {
    .rad-global-nav__menu-item-content {
        top:6rem
    }
}

@media(min-width: 1441px) {
    .rad-global-nav__menu-item-content {
        padding-left:calc(5rem + 5.56715vw - 80.22269px)
    }
}

@media(min-width: 1920px) {
    .rad-global-nav__menu-item-content {
        padding-left:6.6666666667rem
    }
}

@media(min-width: 1441px) {
    .rad-global-nav__menu-item-content {
        padding-right:calc(5rem + 5.56715vw - 80.22269px)
    }
}

@media(min-width: 1920px) {
    .rad-global-nav__menu-item-content {
        padding-right:6.6666666667rem
    }
}

@media(min-width: 1024px) {
    .rad-global-nav__menu-item-content a:focus-visible {
        outline-offset:8px
    }
}

@media(min-width: 1024px) {
    .rad-global-nav__menu-item-content:before {
        background-color:transparent;
        content: "";
        display: block;
        height: 3.75rem;
        position: relative;
        width: 100%
    }
}

@media(min-width: 1441px) {
    .rad-global-nav__menu-item-content:before {
        height:calc(3.75rem + 4.17537vw - 60.16701px)
    }
}

@media(min-width: 1920px) {
    .rad-global-nav__menu-item-content:before {
        height:5rem
    }
}

@media(min-width: 1024px) {
    .rad-global-nav__menu-item-content.rad-global-nav__drawer--open {
        border-bottom:1px solid #616160;
        max-height: calc(100vh - var(--rad-nav-height))
    }
}

@media(min-width: 1441px) {
    .rad-global-nav__menu-item-content,.rad-global-nav__menu-item-content.rad-global-nav__drawer--open {
        left:50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%)
    }
}

.rad-global-nav .rad-global-nav__menu-items .aem-Grid .rad-global-nav__menu-item-content {
    width: 100vw
}

.rad-global-nav .menu-item-content__back-button {
    background-color: #202020;
    height: 3rem;
    margin-left: 1rem;
    margin-top: .25rem;
    position: absolute;
    top: calc(var(--rad-nav-height)*-1);
    visibility: hidden
}

@media(min-width: 600px) {
    .rad-global-nav .menu-item-content__back-button {
        margin-left:0;
        margin-top: .5rem
    }
}

@media(min-width: 1024px) {
    .rad-global-nav .menu-item-content__back-button {
        display:none
    }
}

.rad-global-nav .menu-item-content__back-button .rad-button__text {
    font-size: .875rem;
    font-weight: 500;
    font-weight: 400;
    letter-spacing: -0.005em;
    line-height: 1.5;
    text-underline-offset: .25rem
}

.rad-global-nav .menu-item-content__back-button .rad-button__text:lang(ja-JP),.rad-global-nav .menu-item-content__back-button .rad-button__text:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-global-nav .menu-item-content__back-button .rad-button__text {
        font-size:1rem
    }
}

@media(min-width: 1441px) {
    .rad-global-nav .menu-item-content__back-button .rad-button__text {
        font-size:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-global-nav .menu-item-content__back-button .rad-button__text {
        font-size:1.3333333333rem
    }
}

.rad-global-nav .menu-item-content__back-button .rad-button__text:lang(ja-JP),.rad-global-nav .menu-item-content__back-button .rad-button__text:lang(zh-CN) {
    font-weight: 400
}

.rad-global-nav .rad-submenu__cta-wrapper {
    padding-bottom: 2rem
}

@media(min-width: 600px) {
    .rad-global-nav .rad-submenu__cta-wrapper {
        padding-bottom:3rem
    }
}

@media(min-width: 1024px) {
    .rad-global-nav .rad-submenu__cta-wrapper {
        margin:0 auto;
        max-width: 1920px
    }
}

@media(min-width: 1920px) {
    .rad-global-nav .rad-submenu__cta-wrapper {
        margin-left:50%;
        max-width: unset;
        padding-left: 5rem;
        padding-left: calc(5rem + 5.56715vw - 80.22269px);
        padding-left: 6.6666666667rem;
        padding-right: 5rem;
        padding-right: calc(5rem + 5.56715vw - 80.22269px);
        padding-right: 6.6666666667rem;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        width: 1920px
    }
}

.rad-global-nav .rad-submenu__cta {
    font-size: 1.25rem;
    font-weight: 500;
    height: 2.75rem;
    letter-spacing: -0.02em;
    line-height: 1.3;
    margin-left: 1rem;
    position: relative
}

.rad-global-nav .rad-submenu__cta:lang(ja-JP),.rad-global-nav .rad-submenu__cta:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-global-nav .rad-submenu__cta {
        font-size:1.5rem;
        line-height: 1.3
    }
}

@media(min-width: 1024px) {
    .rad-global-nav .rad-submenu__cta {
        font-size:1.75rem;
        line-height: 1.25
    }
}

@media(min-width: 1441px) {
    .rad-global-nav .rad-submenu__cta {
        font-size:calc(1.75rem + 1.9485vw - 28.07794px)
    }
}

@media(min-width: 1920px) {
    .rad-global-nav .rad-submenu__cta {
        font-size:2.3333333333rem
    }
}

.rad-global-nav .rad-submenu__cta .rad-button__text:after {
    border-bottom: 1px solid;
    bottom: 0;
    content: "";
    display: block;
    left: 0;
    position: absolute;
    -webkit-transition: width 550ms cubic-bezier(0.85,0,0,1);
    transition: width 550ms cubic-bezier(0.85,0,0,1);
    width: 0
}

.rad-global-nav .rad-submenu__cta:hover .rad-button__text:after {
    width: 100%
}

@media(min-width: 600px) {
    .rad-global-nav .rad-submenu__cta {
        margin-left:0
    }
}

@media(min-width: 1024px) {
    .rad-global-nav .rad-submenu__cta {
        height:3.5rem;
        padding-bottom: 0.5rem;
        padding-top: 0.5rem
    }
}

@media(min-width: 1441px) {
    .rad-global-nav .rad-submenu__cta {
        height:calc(3.5rem + 3.89701vw - 56.15588px)
    }
}

@media(min-width: 1920px) {
    .rad-global-nav .rad-submenu__cta {
        height:4.6666666667rem
    }
}

@media(min-width: 1441px) {
    .rad-global-nav .rad-submenu__cta {
        padding-top:calc(0.5rem + 0.55672vw - 8.02227px)
    }
}

@media(min-width: 1920px) {
    .rad-global-nav .rad-submenu__cta {
        padding-top:0.6666666667rem
    }
}

@media(min-width: 1441px) {
    .rad-global-nav .rad-submenu__cta {
        padding-bottom:calc(0.5rem + 0.55672vw - 8.02227px)
    }
}

@media(min-width: 1920px) {
    .rad-global-nav .rad-submenu__cta {
        padding-bottom:0.6666666667rem
    }
}

.rad-global-nav__submenu {
    max-height: 100%;
    overflow: auto;
    padding: 0 1rem 7.5rem
}

@media(min-width: 600px) {
    .rad-global-nav__submenu {
        -webkit-box-orient:horizontal;
        -webkit-box-direction: normal;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: row;
        flex-direction: row;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        gap: 1.5rem;
        padding-left: 0;
        padding-right: 0;
        row-gap: 3rem
    }
}

@media(min-width: 600px)and (max-width:1023px) {
    .rad-global-nav__submenu .l2-item {
        width:calc(50% - 12px)
    }
}

@media(min-width: 1024px) {
    .rad-global-nav__submenu {
        -ms-flex-wrap:nowrap;
        flex-wrap: nowrap;
        gap: 2rem;
        margin: 0 auto;
        max-height: unset;
        overflow: visible;
        padding: 0 0 3.75rem
    }
}

@media(min-width: 1441px) {
    .rad-global-nav__submenu {
        padding-bottom:calc(3.75rem + 4.17537vw - 60.16701px)
    }
}

@media(min-width: 1920px) {
    .rad-global-nav__submenu {
        padding-bottom:5rem
    }
}

@media(min-width: 1441px) {
    .rad-global-nav__submenu {
        gap:calc(2rem + 2.22686vw - 32.08907px)
    }
}

@media(min-width: 1920px) {
    .rad-global-nav__submenu {
        gap:2.6666666667rem;
        margin-left: 50%;
        padding-left: 5rem;
        padding-left: calc(5rem + 5.56715vw - 80.22269px);
        padding-left: 6.6666666667rem;
        padding-right: 5rem;
        padding-right: calc(5rem + 5.56715vw - 80.22269px);
        padding-right: 6.6666666667rem;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        width: 1920px
    }
}

.rad-global-nav .l2-links {
    font-size: .875rem;
    font-weight: 500;
    font-weight: 400;
    height: 0;
    letter-spacing: -0.005em;
    line-height: 1.5;
    overflow: hidden;
    text-underline-offset: .25rem;
    -webkit-transition-duration: 550ms;
    transition-duration: 550ms;
    -webkit-transition-property: height;
    transition-property: height;
    -webkit-transition-timing-function: cubic-bezier(0.85,0,0,1);
    transition-timing-function: cubic-bezier(0.85,0,0,1)
}

.rad-global-nav .l2-links:lang(ja-JP),.rad-global-nav .l2-links:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-global-nav .l2-links {
        font-size:1rem
    }
}

@media(min-width: 1441px) {
    .rad-global-nav .l2-links {
        font-size:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-global-nav .l2-links {
        font-size:1.3333333333rem
    }
}

.rad-global-nav .l2-links:lang(ja-JP),.rad-global-nav .l2-links:lang(zh-CN) {
    font-weight: 400
}

.rad-global-nav .l2-links .rad-global-nav__l2-link:first-child {
    margin-top: 8px
}

.rad-global-nav .l2-links .rad-global-nav__l2-link:last-child {
    margin-bottom: 8px
}

@media(min-width: 600px) {
    .rad-global-nav .l2-links {
        height:auto;
        overflow: visible;
        padding: 1rem 0 0
    }

    .rad-global-nav .l2-links.l2-links--long-list {
        padding-bottom: 3rem
    }
}

@media(min-width: 600px)and (max-width:1023px) {
    .rad-global-nav .l2-links .rad-global-nav__l2-link:first-child,.rad-global-nav .l2-links .rad-global-nav__l2-link:last-child {
        margin:0
    }
}

@media(min-width: 1024px) {
    .rad-global-nav .l2-links {
        padding-bottom:0;
        padding-top: 1rem;
        width: calc(min(100vw, 1920px)/4*3 - var(--rad-spacing-sm)*2/4*3 - var(--rad-spacing-sm)*3/4*3 + var(--rad-spacing-sm)*2 + var(--rad-spacing-sm)*0);
        width: calc(min(100vw, 1920px)/8*3 - var(--rad-spacing-md)*2/8*3 - var(--rad-spacing-sm)*7/8*3 + var(--rad-spacing-sm)*2 + var(--rad-spacing-md)*0);
        width: calc(min(100vw, 1920px)/12*3 - var(--rad-spacing-md)*2/12*3 - var(--rad-spacing-sm)*11/12*3 + var(--rad-spacing-sm)*2 + var(--rad-spacing-md)*0)
    }

    .rad-global-nav .l2-links.l2-links--long-list {
        -webkit-columns: 2;
        -moz-columns: 2;
        column-count: 2;
        -webkit-column-gap: 2rem;
        -moz-column-gap: 2rem;
        column-gap: 2rem;
        padding-bottom: 0;
        width: calc(min(100vw, 1920px)/4*6 - var(--rad-spacing-sm)*2/4*6 - var(--rad-spacing-sm)*3/4*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-sm)*0);
        width: calc(min(100vw, 1920px)/8*6 - var(--rad-spacing-md)*2/8*6 - var(--rad-spacing-sm)*7/8*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0);
        width: calc(min(100vw, 1920px)/12*6 - var(--rad-spacing-md)*2/12*6 - var(--rad-spacing-sm)*11/12*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0)
    }

    .rad-global-nav .l2-links.l2-links--long-list .rad-global-nav__l2-link:first-child {
        margin: 0
    }
}

.rad-global-nav__l2-button:after {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-font-smoothing: antialiased;
    align-items: center;
    content: "";
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-family: icont;
    font-size: 0.625rem;
    font-style: normal;
    font-weight: 400;
    justify-content: center;
    text-decoration: none;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    -webkit-transition-duration: 550ms;
    transition-duration: 550ms;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform,-webkit-transform;
    -webkit-transition-timing-function: cubic-bezier(0.85,0,0,1);
    transition-timing-function: cubic-bezier(0.85,0,0,1)
}

@media(min-width: 1441px) {
    .rad-global-nav__l2-button:after {
        font-size:calc(0.625rem + 0.69589vw - 10.02784px)
    }
}

@media(min-width: 1920px) {
    .rad-global-nav__l2-button:after {
        font-size:0.8333333333rem
    }
}

@media(min-width: 600px) {
    .rad-global-nav__l2-button:after {
        display:none
    }
}

.rad-global-nav .l2-item--active .rad-global-nav__l2-button {
    color: #a2a2a0
}

.rad-global-nav .l2-item--active .rad-global-nav__l2-button:after {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg)
}

.rad-global-nav__l2-link {
    display: block;
    padding: .5rem 0 .5rem 1rem;
    text-decoration: none
}

@media(min-width: 600px) {
    .rad-global-nav__l2-link {
        padding-left:0
    }
}

@media(min-width: 1024px) {
    .rad-global-nav__l2-link {
        margin:.5rem 0;
        padding: 0
    }

    .rad-global-nav__l2-link:after {
        content: "";
        display: block
    }
}

.rad-global-nav__l2-link:visited {
    color: #fff
}

.rad-global-nav__l2-link:hover {
    color: #fff;
    text-decoration: underline
}

.rad-global-nav__utility-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: transparent;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row
}

@media(min-width: 600px) {
    .rad-global-nav__utility-nav {
        width:-webkit-max-content;
        width: -moz-max-content;
        width: max-content
    }

    .rad-global-nav .rad-header__search {
        margin-right: .625rem
    }
}

.rad-global-nav .rad-header__search .rad-icon:after {
    font-size: .9375rem
}

@media(min-width: 1441px) {
    .rad-global-nav .rad-header__search .rad-icon:after {
        font-size:0.9375rem;
        font-size: calc(0.9375rem + 1.04384vw - 15.04175px)
    }
}

@media(min-width: 1920px) {
    .rad-global-nav .rad-header__search .rad-icon:after {
        font-size:1.25rem
    }
}

.rad-global-nav .rad-global-nav__language-container {
    display: none;
    margin-right: 1rem;
    -webkit-transform: translateX(100vw);
    transform: translateX(100vw);
    -webkit-transition-duration: 550ms;
    transition-duration: 550ms;
    -webkit-transition-property: z-index,-webkit-transform;
    transition-property: z-index,-webkit-transform;
    transition-property: transform,z-index;
    transition-property: transform,z-index,-webkit-transform;
    -webkit-transition-timing-function: cubic-bezier(0.85,0,0,1);
    transition-timing-function: cubic-bezier(0.85,0,0,1)
}

.rad-global-nav .rad-global-nav__language-container a:focus-visible {
    outline-offset: 8px
}

@media(min-width: 600px) {
    .rad-global-nav .rad-global-nav__language-container {
        display:-webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-transform: none;
        transform: none
    }
}

.rad-global-nav .cmp-global-header__language-selector {
    background-color: transparent;
    margin: 0
}

.rad-global-nav__overlay {
    background-color: #000;
    bottom: 0;
    display: none;
    height: 100vh;
    left: 0;
    opacity: .5;
    position: fixed;
    right: 0;
    top: 0;
    width: 100vw
}

.rad-global-nav--menu-open .rad-global-nav__utility-nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: var(--rad-nav-height);
    position: absolute;
    right: 0;
    top: 0;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    z-index: 3
}

@media(min-width: 600px) {
    .rad-global-nav--menu-open .rad-global-nav__utility-nav {
        right:3rem
    }
}

.rad-global-nav--menu-open .rad-global-nav__language-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-transform: translateX(0);
    transform: translateX(0)
}

.rad-global-nav--menu-open .rad-header__search {
    display: none
}

@media(min-width: 600px) {
    .rad-global-nav--menu-open .rad-header__search {
        display:initial
    }
}

.rad-global-nav--menu-open .rad-global-nav__primary-nav {
    left: 0;
    z-index: 2
}

.rad-global-nav--menu-open .rad-global-nav__mobile-footer {
    display: block;
    padding-bottom: 3rem
}

@media(min-width: 600px) {
    .rad-global-nav--menu-open .rad-global-nav__mobile-footer {
        padding-bottom:3.75rem
    }
}

.rad-global-nav--menu-open .rad-global-nav__utility-links {
    padding: 0 1rem
}

@media(min-width: 600px) {
    .rad-global-nav--menu-open .rad-global-nav__utility-links {
        padding:0 3rem
    }
}

.rad-global-nav--menu-open .rad-global-nav__footer-link {
    display: block;
    height: 2.5rem;
    letter-spacing: -0.005em;
    line-height: 1.5;
    padding: .5rem 0;
    text-decoration: none;
    text-underline-offset: .25rem
}

.rad-global-nav--menu-open .rad-global-nav__footer-link:hover {
    color: #fff;
    text-decoration: underline
}

.rad-global-nav--menu-open .rad-global-nav__social-links {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    gap: .25rem;
    padding-left: 8px;
    padding-top: 32px
}

@media(min-width: 600px) {
    .rad-global-nav--menu-open .rad-global-nav__social-links {
        padding-left:2.25rem
    }
}

.rad-global-nav--menu-open .rad-nav-icon-button .rad-icon {
    height: 1.25rem;
    width: 1.25rem
}

.rad-global-nav--menu-open .rad-global-nav__overlay {
    display: block;
    z-index: 1
}

.rad-global-nav--menu-open.rad-global-nav--submenu-open .rad-global-nav__mobile-footer {
    display: none
}

.rad-global-nav--dropdown-open .rad-global-nav__overlay {
    display: block;
    z-index: 1
}

.rad-global-header--offscreen .rad-global-nav__wrapper {
    top: calc(var(--rad-nav-height)*-1)
}

@media(min-width: 1441px) {
    .rad-global-header--offscreen .rad-global-nav__wrapper {
        top:-4.5rem;
        top: calc(-4.5rem - 5.01044vw + 72.20042px)
    }
}

@media(min-width: 1920px) {
    .rad-global-header--offscreen .rad-global-nav__wrapper {
        top:-6rem
    }
}

.rad-global-nav__language-selector {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border: none;
    color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 48px;
    min-height: 100%;
    opacity: 1
}

@media(min-width: 1441px) {
    .rad-global-nav__language-selector {
        height:calc(-0.13361px + 3.34029vw)
    }
}

@media(min-width: 1920px) {
    .rad-global-nav__language-selector {
        height:64px
    }
}

.rad-global-nav__language-selector:before {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    align-items: center;
    content: "";
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-family: icont;
    font-size: 1.03125rem;
    font-style: normal;
    font-weight: 400;
    justify-content: center
}

@media(min-width: 1441px) {
    .rad-global-nav__language-selector:before {
        font-size:1.03125rem;
        font-size: calc(1.03125rem + 1.14823vw - 16.54593px)
    }
}

@media(min-width: 1920px) {
    .rad-global-nav__language-selector:before {
        font-size:1.375rem
    }
}

.rad-global-nav__language-selector .current-country-text {
    font-size: .875rem;
    font-weight: 500;
    font-weight: 400;
    letter-spacing: -0.005em;
    line-height: 1.5;
    padding-left: 6px;
    text-underline-offset: .25rem
}

.rad-global-nav__language-selector .current-country-text:lang(ja-JP),.rad-global-nav__language-selector .current-country-text:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-global-nav__language-selector .current-country-text {
        font-size:1rem
    }
}

@media(min-width: 1441px) {
    .rad-global-nav__language-selector .current-country-text {
        font-size:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-global-nav__language-selector .current-country-text {
        font-size:1.3333333333rem
    }
}

.rad-global-nav__language-selector .current-country-text:lang(ja-JP),.rad-global-nav__language-selector .current-country-text:lang(zh-CN) {
    font-weight: 400
}

.rad-global-nav__language-selector:focus,.rad-global-nav__language-selector:hover {
    outline-offset: -1px
}

.rad-global-nav__language-selector .icon-down-caret {
    display: inline-block;
    height: 24px;
    position: relative;
    width: 24px
}

.rad-global-nav__language-selector .icon-down-caret:before {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    align-items: center;
    content: "";
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-family: icont;
    font-size: .46875rem;
    font-style: normal;
    font-weight: 400;
    height: 100%;
    justify-content: center;
    -webkit-transition: -webkit-transform .5s ease-in-out;
    transition: -webkit-transform .5s ease-in-out;
    transition: transform .5s ease-in-out;
    transition: transform .5s ease-in-out,-webkit-transform .5s ease-in-out
}

@media(min-width: 1441px) {
    .rad-global-nav__language-selector .icon-down-caret:before {
        font-size:0.46875rem;
        font-size: calc(0.46875rem + 0.52192vw - 7.52088px)
    }
}

@media(min-width: 1920px) {
    .rad-global-nav__language-selector .icon-down-caret:before {
        font-size:0.625rem
    }
}

.rad-global-nav__language-selector[aria-expanded=true] .icon-down-caret:before {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg)
}

.rad-global-nav__language-options {
    background-color: #202020;
    display: none;
    position: absolute;
    right: 20px;
    top: var(--rad-nav-height);
    width: 304px
}

.rad-global-nav__language-options .arrow-up {
    display: none
}

.rad-global-nav__language-options .input-group {
    height: 16px;
    width: 100%
}

.rad-global-nav__language-options .country-list {
    height: 410px;
    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-color: #a100ff #460073;
    scrollbar-width: thin
}

.rad-global-nav__language-options .country-list::-webkit-scrollbar {
    height: 4px;
    width: 4px
}

.rad-global-nav__language-options .country-list::-webkit-scrollbar-track {
    background-color: #460073
}

.rad-global-nav__language-options .country-list::-webkit-scrollbar-thumb {
    background-color: #a100ff
}

.rad-global-nav__language-menu {
    font-size: .875rem;
    font-weight: 500;
    font-weight: 400;
    letter-spacing: -0.005em;
    line-height: 1.5;
    list-style-type: none;
    margin: 0;
    padding: 0;
    text-underline-offset: .25rem
}

.rad-global-nav__language-menu:lang(ja-JP),.rad-global-nav__language-menu:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-global-nav__language-menu {
        font-size:1rem
    }
}

@media(min-width: 1441px) {
    .rad-global-nav__language-menu {
        font-size:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-global-nav__language-menu {
        font-size:1.3333333333rem
    }
}

.rad-global-nav__language-menu:lang(ja-JP),.rad-global-nav__language-menu:lang(zh-CN) {
    font-weight: 400
}

.rad-global-nav__language-menu li {
    -webkit-box-shadow: inset 0 -1px 0 0 hsla(0,0%,100%,.2);
    box-shadow: inset 0 -1px 0 0 hsla(0,0%,100%,.2);
    padding: 12px 32px
}

.rad-global-nav__language-menu li a {
    color: #fff;
    text-decoration: none
}

.rad-global-nav__language-menu li :hover {
    color: #a2a2a0;
    text-decoration: underline
}

.rad-global-nav__language-menu li :focus {
    text-decoration: underline
}

.rad-global-nav__language-menu .default {
    -webkit-box-shadow: none;
    box-shadow: none;
    font-size: .75rem;
    font-weight: 500;
    letter-spacing: -0.005em;
    line-height: 1.5;
    text-underline-offset: .25rem
}

.rad-global-nav__language-menu .default:lang(ja-JP),.rad-global-nav__language-menu .default:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-global-nav__language-menu .default {
        font-size:0.875rem
    }
}

@media(min-width: 1441px) {
    .rad-global-nav__language-menu .default {
        font-size:calc(0.875rem + 0.97425vw - 14.03897px)
    }
}

@media(min-width: 1920px) {
    .rad-global-nav__language-menu .default {
        font-size:1.1666666667rem
    }
}

.rad-global-nav__language-menu .dropdown-header {
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #fff;
    font-size: .75rem;
    font-weight: 500;
    letter-spacing: .02em;
    line-height: 1.2;
    padding-bottom: 24px;
    padding-top: 32px
}

.rad-global-nav__language-menu .dropdown-header:lang(ja-JP),.rad-global-nav__language-menu .dropdown-header:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 1024px) {
    .rad-global-nav__language-menu .dropdown-header {
        font-size:0.875rem
    }
}

@media(min-width: 1441px) {
    .rad-global-nav__language-menu .dropdown-header {
        font-size:calc(0.875rem + 0.97425vw - 14.03897px)
    }
}

@media(min-width: 1920px) {
    .rad-global-nav__language-menu .dropdown-header {
        font-size:1.1666666667rem
    }
}

.rad-global-nav .rad-global-nav__language-selector--show,.rad-global-nav .rad-global-nav__signed-in-option-selector--show {
    display: block
}

#rad-global-nav__current-country {
    display: none
}

.rad-vidyard-player iframe {
    aspect-ratio: 16/9;
    display: block;
    height: 100%;
    margin: 0 auto;
    width: 100%
}

@media(min-width: 1024px) {
    .rad-vidyard-player__container {
        padding-left:5rem;
        padding-right: 5rem
    }
}

@media(min-width: 1441px) {
    .rad-vidyard-player__container {
        padding-right:calc(5rem + 5.56715vw - 80.22269px)
    }
}

@media(min-width: 1920px) {
    .rad-vidyard-player__container {
        padding-right:6.6666666667rem
    }
}

@media(min-width: 1441px) {
    .rad-vidyard-player__container {
        padding-left:calc(5rem + 5.56715vw - 80.22269px)
    }
}

@media(min-width: 1920px) {
    .rad-vidyard-player__container {
        padding-left:6.6666666667rem
    }
}

.rad-vidyard-player__transcript {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-size: .875rem;
    font-weight: 500;
    font-weight: 400;
    gap: 5rem;
    justify-content: flex-end;
    letter-spacing: -0.005em;
    line-height: 1.5;
    padding-right: 1rem;
    padding-top: 1rem;
    text-underline-offset: .25rem
}

.rad-vidyard-player__transcript:lang(ja-JP),.rad-vidyard-player__transcript:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-vidyard-player__transcript {
        font-size:1rem
    }
}

@media(min-width: 1441px) {
    .rad-vidyard-player__transcript {
        font-size:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-vidyard-player__transcript {
        font-size:1.3333333333rem
    }
}

.rad-vidyard-player__transcript:lang(ja-JP),.rad-vidyard-player__transcript:lang(zh-CN) {
    font-weight: 400
}

@media(min-width: 1441px) {
    .rad-vidyard-player__transcript {
        padding-top:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-vidyard-player__transcript {
        padding-top:1.3333333333rem
    }
}

@media(min-width: 600px) {
    .rad-vidyard-player__transcript {
        padding-right:3rem
    }
}

@media(min-width: 1024px) {
    .rad-vidyard-player__transcript {
        padding-right:0
    }
}

.rad-article-table {
    color: #fff;
    padding-left: var(--rad-spacing-sm);
    padding-right: var(--rad-spacing-sm)
}

@media(min-width: 600px) {
    .rad-article-table {
        padding-left:var(--rad-spacing-md);
        padding-right: var(--rad-spacing-md)
    }
}

.rad-component--nested .rad-article-table {
    padding-left: 0;
    padding-right: 0
}

.rad-article-table__container {
    overflow-x: auto;
    padding-bottom: .5rem;
    scrollbar-color: #a100ff #460073;
    scrollbar-width: thin;
    width: calc(100% - var(--rad-spacing-sm)*3/4*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-sm)*0)
}

@media(min-width: 600px) {
    .rad-article-table__container {
        width:calc(50% - var(--rad-spacing-sm)*7/8*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-article-table__container {
        width:calc(33.33333% - var(--rad-spacing-sm)*11/12*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0)
    }
}

.rad-article-table__container::-webkit-scrollbar {
    height: 4px;
    width: 4px
}

.rad-article-table__container::-webkit-scrollbar-track {
    background-color: #460073
}

.rad-article-table__container::-webkit-scrollbar-thumb {
    background-color: #a100ff
}

@media(min-width: 600px) {
    .rad-article-table__container {
        width:calc(150% - var(--rad-spacing-sm)*3/4*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-sm)*0);
        width: calc(75% - var(--rad-spacing-sm)*7/8*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-article-table__container {
        width:calc(50% - var(--rad-spacing-sm)*11/12*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0);
        width: calc(200% - var(--rad-spacing-sm)*3/4*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-sm)*0);
        width: calc(100% - var(--rad-spacing-sm)*7/8*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0);
        width: calc(66.66667% - var(--rad-spacing-sm)*11/12*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0)
    }
}

.rad-article-table__headline {
    font-size: .875rem;
    font-weight: 500;
    letter-spacing: -0.005em;
    line-height: 1.5;
    margin-bottom: 1rem;
    text-underline-offset: .25rem
}

.rad-article-table__headline:lang(ja-JP),.rad-article-table__headline:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-article-table__headline {
        font-size:1rem
    }
}

@media(min-width: 1024px) {
    .rad-article-table__headline {
        font-size:1.125rem;
        line-height: 1.6
    }
}

@media(min-width: 1441px) {
    .rad-article-table__headline {
        font-size:calc(1.125rem + 1.25261vw - 18.0501px)
    }
}

@media(min-width: 1920px) {
    .rad-article-table__headline {
        font-size:1.5rem
    }
}

@media(min-width: 1024px) {
    .rad-article-table__headline {
        margin-bottom:1.5rem
    }
}

.rad-article-table table {
    background-color: #000;
    border: 1px solid #a2a2a0;
    border-collapse: collapse;
    border-top: 0 solid #a2a2a0;
    font-size: .875rem;
    font-weight: 500;
    font-weight: 400;
    letter-spacing: -0.005em;
    line-height: 1.5;
    text-underline-offset: .25rem
}

.rad-article-table table:lang(ja-JP),.rad-article-table table:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-article-table table {
        font-size:1rem
    }
}

@media(min-width: 1441px) {
    .rad-article-table table {
        font-size:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-article-table table {
        font-size:1.3333333333rem
    }
}

.rad-article-table table:lang(ja-JP),.rad-article-table table:lang(zh-CN) {
    font-weight: 400
}

.rad-article-table table caption {
    clip: rect(0 0 0 0);
    -webkit-clip-path: inset(50%);
    clip-path: inset(50%);
    display: inline;
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px
}

.rad-article-table__caption {
    color: #a2a2a0;
    font-size: .75rem;
    font-weight: 500;
    font-weight: 400;
    letter-spacing: -0.005em;
    line-height: 1.5;
    padding-top: .5rem;
    text-underline-offset: .25rem;
    width: calc(100% - var(--rad-spacing-sm)*3/4*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-sm)*0)
}

.rad-article-table__caption:lang(ja-JP),.rad-article-table__caption:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-article-table__caption {
        font-size:0.875rem
    }
}

@media(min-width: 1441px) {
    .rad-article-table__caption {
        font-size:calc(0.875rem + 0.97425vw - 14.03897px)
    }
}

@media(min-width: 1920px) {
    .rad-article-table__caption {
        font-size:1.1666666667rem
    }
}

.rad-article-table__caption:lang(ja-JP),.rad-article-table__caption:lang(zh-CN) {
    font-weight: 400
}

@media(min-width: 600px) {
    .rad-article-table__caption {
        width:calc(50% - var(--rad-spacing-sm)*7/8*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-article-table__caption {
        width:calc(33.33333% - var(--rad-spacing-sm)*11/12*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 600px) {
    .rad-article-table__caption {
        width:calc(150% - var(--rad-spacing-sm)*3/4*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-sm)*0);
        width: calc(75% - var(--rad-spacing-sm)*7/8*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-article-table__caption {
        width:calc(50% - var(--rad-spacing-sm)*11/12*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0)
    }
}

.rad-article-table td,.rad-article-table th {
    border: 1px solid #a2a2a0;
    border-collapse: collapse;
    max-width: 43.5rem;
    min-width: 9rem
}

.rad-article-table th {
    font-size: .875rem;
    font-weight: 500;
    letter-spacing: -0.005em;
    line-height: 1.5;
    padding: 1rem .5rem;
    text-align: left;
    text-underline-offset: .25rem
}

.rad-article-table th:lang(ja-JP),.rad-article-table th:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-article-table th {
        font-size:1rem
    }
}

@media(min-width: 1441px) {
    .rad-article-table th {
        font-size:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-article-table th {
        font-size:1.3333333333rem
    }
}

@media(min-width: 600px) {
    .rad-article-table th {
        padding:1.5rem 1rem
    }
}

.rad-article-table td {
    padding: .5rem
}

@media(min-width: 600px) {
    .rad-article-table td {
        padding:1rem
    }
}

.rad-article-table--grey-headers th {
    background-color: #2b2b2b
}

.rad-article-table--purple-headers th {
    background-color: #7500c0
}

@media(min-width: 600px) {
    .rad-article-table--long-table .rad-article-table__container {
        width:calc(200% - var(--rad-spacing-sm)*3/4*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-sm)*0);
        width: calc(100% - var(--rad-spacing-sm)*7/8*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-article-table--long-table .rad-article-table__container {
        width:calc(66.66667% - var(--rad-spacing-sm)*11/12*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0);
        width: calc(300% - var(--rad-spacing-sm)*3/4*12 + var(--rad-spacing-sm)*11 + var(--rad-spacing-sm)*0);
        width: calc(150% - var(--rad-spacing-sm)*7/8*12 + var(--rad-spacing-sm)*11 + var(--rad-spacing-md)*0);
        width: calc(100% - var(--rad-spacing-sm)*11/12*12 + var(--rad-spacing-sm)*11 + var(--rad-spacing-md)*0)
    }
}

.rad-grid-card-carousel .flickity-enabled {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    justify-content: flex-end
}

.rad-grid-card-carousel .flickity-viewport {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%
}

.rad-grid-card-carousel .flickity-button {
    background: none
}

.rad-grid-card-carousel .flickity-button:focus {
    -webkit-box-shadow: none;
    box-shadow: none
}

.rad-grid-card-carousel .flickity-prev-next-button {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    align-items: center;
    background: none;
    border-radius: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 48px;
    justify-content: center;
    left: 0;
    margin-top: -12px;
    position: relative;
    right: 0;
    -webkit-transform: unset;
    transform: unset;
    width: 48px
}

@media(min-width: 1441px) {
    .rad-grid-card-carousel .flickity-prev-next-button {
        width:calc(-0.13361px + 3.34029vw)
    }
}

@media(min-width: 1920px) {
    .rad-grid-card-carousel .flickity-prev-next-button {
        width:64px
    }
}

@media(min-width: 1441px) {
    .rad-grid-card-carousel .flickity-prev-next-button {
        height:calc(-0.13361px + 3.34029vw)
    }
}

@media(min-width: 1920px) {
    .rad-grid-card-carousel .flickity-prev-next-button {
        height:64px
    }
}

@media(min-width: 600px) {
    .rad-grid-card-carousel .flickity-prev-next-button {
        margin-top:0
    }
}

.rad-grid-card-carousel .next,.rad-grid-card-carousel .previous {
    --rad-icon-button-grey-size: 32px
}

@media(min-width: 600px) {
    .rad-grid-card-carousel .next,.rad-grid-card-carousel .previous {
        --rad-icon-button-grey-size:40px
    }
}

@media(min-width: 1024px) {
    .rad-grid-card-carousel .next,.rad-grid-card-carousel .previous {
        --rad-icon-button-grey-size:48px
    }
}

@media(min-width: 1441px) {
    .rad-grid-card-carousel .next,.rad-grid-card-carousel .previous {
        --rad-icon-button-grey-size:calc(-0.13361px + 3.34029vw)
    }
}

@media(min-width: 1920px) {
    .rad-grid-card-carousel .next,.rad-grid-card-carousel .previous {
        --rad-icon-button-grey-size:64px
    }
}

@media(min-width: 1024px) {
    .rad-grid-card-carousel .next .rad-icon:after,.rad-grid-card-carousel .previous .rad-icon:after {
        scale:1.2
    }
}

.rad-grid-card-carousel .previous {
    margin-right: 4px
}

@media(min-width: 600px) {
    .rad-grid-card-carousel .previous {
        margin-right:16px
    }
}

@media(min-width: 1024px) {
    .rad-grid-card-carousel .previous {
        margin-right:24px
    }
}

@media(min-width: 1441px) {
    .rad-grid-card-carousel .previous {
        margin-right:calc(-0.06681px + 1.67015vw)
    }
}

@media(min-width: 1920px) {
    .rad-grid-card-carousel .previous {
        margin-right:32px
    }
}

.rad-grid-card-carousel .next {
    margin-right: var(--rad-spacing-sm)
}

@media(min-width: 600px) {
    .rad-grid-card-carousel .next {
        margin-right:var(--rad-spacing-md)
    }
}

.rad-grid-card-carousel .flickity-button-icon {
    fill: #fff;
    position: static;
    width: 1.25rem
}

@media(min-width: 1441px) {
    .rad-grid-card-carousel .flickity-button-icon {
        width:calc(1.25rem + 1.39179vw - 20.05567px)
    }
}

@media(min-width: 1920px) {
    .rad-grid-card-carousel .flickity-button-icon {
        width:1.6666666667rem
    }
}

.rad-grid-card-carousel__text-container {
    padding-left: var(--rad-spacing-sm);
    padding-right: var(--rad-spacing-sm)
}

@media(min-width: 600px) {
    .rad-grid-card-carousel__text-container {
        padding-left:var(--rad-spacing-md);
        padding-right: var(--rad-spacing-md)
    }
}

.rad-component--nested .rad-grid-card-carousel__text-container {
    padding-left: 0;
    padding-right: 0
}

.rad-grid-card-carousel__heading {
    font-size: 1.75rem;
    font-weight: 500;
    letter-spacing: -0.03em;
    line-height: 1.3;
    margin-bottom: .5rem
}

.rad-grid-card-carousel__heading:lang(ja-JP),.rad-grid-card-carousel__heading:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-grid-card-carousel__heading {
        font-size:2.5rem;
        line-height: 1.2
    }
}

@media(min-width: 1024px) {
    .rad-grid-card-carousel__heading {
        font-size:3rem;
        line-height: 1.2
    }
}

@media(min-width: 1441px) {
    .rad-grid-card-carousel__heading {
        font-size:calc(3rem + 3.34029vw - 48.13361px)
    }
}

@media(min-width: 1920px) {
    .rad-grid-card-carousel__heading {
        font-size:4rem
    }
}

@media(min-width: 600px) {
    .rad-grid-card-carousel__heading {
        width:calc(150% - var(--rad-spacing-sm)*3/4*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-sm)*0);
        width: calc(75% - var(--rad-spacing-sm)*7/8*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-grid-card-carousel__heading {
        width:calc(50% - var(--rad-spacing-sm)*11/12*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0);
        width: calc(200% - var(--rad-spacing-sm)*3/4*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-sm)*0);
        width: calc(100% - var(--rad-spacing-sm)*7/8*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0);
        width: calc(66.66667% - var(--rad-spacing-sm)*11/12*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0)
    }
}

.rad-grid-card-carousel__sub-heading {
    font-family: GT Sectra Fine,Palatino;
    font-size: 1.25rem;
    font-weight: 300;
    line-height: 1.25;
    margin-bottom: 1rem
}

@media(min-width: 600px) {
    .rad-grid-card-carousel__sub-heading {
        font-size:1.5rem;
        line-height: 1.25
    }
}

@media(min-width: 1024px) {
    .rad-grid-card-carousel__sub-heading {
        font-size:1.75rem;
        line-height: 1.25
    }
}

@media(min-width: 1441px) {
    .rad-grid-card-carousel__sub-heading {
        font-size:calc(1.75rem + 1.9485vw - 28.07794px)
    }
}

@media(min-width: 1920px) {
    .rad-grid-card-carousel__sub-heading {
        font-size:2.3333333333rem
    }
}

@media(min-width: 600px) {
    .rad-grid-card-carousel__sub-heading {
        width:calc(150% - var(--rad-spacing-sm)*3/4*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-sm)*0);
        width: calc(75% - var(--rad-spacing-sm)*7/8*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-grid-card-carousel__sub-heading {
        margin-bottom:1.5rem;
        width: calc(50% - var(--rad-spacing-sm)*11/12*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0);
        width: calc(200% - var(--rad-spacing-sm)*3/4*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-sm)*0);
        width: calc(100% - var(--rad-spacing-sm)*7/8*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0);
        width: calc(66.66667% - var(--rad-spacing-sm)*11/12*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1441px) {
    .rad-grid-card-carousel__sub-heading {
        margin-bottom:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-grid-card-carousel__sub-heading {
        margin-bottom:2rem
    }
}

.rad-grid-card-carousel__view-all-button-wrapper {
    margin-bottom: 1.5rem
}

@media(min-width: 600px) {
    .rad-grid-card-carousel__view-all-button-wrapper {
        margin-bottom:2.5rem
    }
}

@media(min-width: 1024px) {
    .rad-grid-card-carousel__view-all-button-wrapper {
        margin-bottom:3rem
    }
}

@media(min-width: 1441px) {
    .rad-grid-card-carousel__view-all-button-wrapper {
        margin-bottom:calc(3rem + 3.34029vw - 48.13361px)
    }
}

@media(min-width: 1920px) {
    .rad-grid-card-carousel__view-all-button-wrapper {
        margin-bottom:4rem
    }
}

.rad-grid-card-carousel .rad-content-grid-card {
    -ms-flex-negative: 0;
    flex-shrink: 0
}

.rad-grid-card-carousel .flickity-viewport {
    height: 26.5rem;
    margin-bottom: 16px
}

@media(min-width: 1441px) {
    .rad-grid-card-carousel .flickity-viewport {
        height:calc(26.5rem + 29.50592vw - 425.18024px)
    }
}

@media(min-width: 1920px) {
    .rad-grid-card-carousel .flickity-viewport {
        height:35.3333333333rem
    }
}

@media(min-width: 600px) {
    .rad-grid-card-carousel .flickity-viewport {
        margin-bottom:24px
    }
}

@media(min-width: 1024px) {
    .rad-grid-card-carousel .flickity-viewport {
        margin-bottom:32px
    }
}

@media(min-width: 1441px) {
    .rad-grid-card-carousel .flickity-viewport {
        margin-bottom:calc(-0.08907px + 2.22686vw)
    }
}

@media(min-width: 1920px) {
    .rad-grid-card-carousel .flickity-viewport {
        margin-bottom:42.6666666667px
    }
}

.rad-grid-card-carousel .flickity-cell {
    height: 100%;
    padding-left: 1rem
}

@media(min-width: 600px) {
    .rad-grid-card-carousel .flickity-cell {
        margin-right:calc(var(--rad-spacing-md)*-1 + 2.5rem);
        padding-left: var(--rad-spacing-md);
        width: calc(17.75rem + var(--rad-spacing-md))
    }
}

@media(min-width: 1441px) {
    .rad-grid-card-carousel .flickity-cell {
        margin-right:calc(var(--rad-spacing-md)*-1 - 0.11134px + 2.78358vw);
        width: calc(-0.79054px + 19.7634vw + var(--rad-spacing-md))
    }
}

@media(min-width: 1920px) {
    .rad-grid-card-carousel .flickity-cell {
        margin-right:calc(var(--rad-spacing-md)*-1 + 53.33333px);
        width: calc(378.66667px + var(--rad-spacing-md))
    }
}

@media(min-width: 600px) {
    .rad-grid-card-carousel__card-wrapper {
        -webkit-box-align:center;
        -ms-flex-align: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        align-items: center;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        justify-content: space-evenly;
     
    }
}

@media(min-width: 1441px) {
    .rad-grid-card-carousel__card-wrapper {
       
    }
}

@media(min-width: 1920px) {
    .rad-grid-card-carousel__card-wrapper {
 
    }
}

@media(min-width: 1024px) {
    .rad-grid-card-carousel__card-wrapper {
        -webkit-box-pack:start;
        -ms-flex-pack: start;
 
    }
}

.rad-grid-card-carousel__card-size-maintainer {
    height: 26.5rem;
    width: 18.75rem
}

@media(min-width: 600px) {
    .rad-grid-card-carousel__card-size-maintainer {
        height:unset;
        width: 276px
    }
}

.rad-grid-card-carousel.rad-grid-card-carousel--min-cards .rad-grid-card-carousel__cards-container {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-column-gap: 1rem;
    -moz-column-gap: 1rem;
    column-gap: 1rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 26.5rem;
    padding-left: var(--rad-spacing-sm);
    padding-right: var(--rad-spacing-sm);
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content
}

@media(min-width: 600px) {
    .rad-grid-card-carousel.rad-grid-card-carousel--min-cards .rad-grid-card-carousel__cards-container {
        padding-left:var(--rad-spacing-md);
        padding-right: var(--rad-spacing-md)
    }
}

.rad-component--nested .rad-grid-card-carousel.rad-grid-card-carousel--min-cards .rad-grid-card-carousel__cards-container {
    padding-left: 0;
    padding-right: 0
}

@media(min-width: 600px) {
    .rad-grid-card-carousel.rad-grid-card-carousel--min-cards .rad-grid-card-carousel__cards-container {
        -webkit-column-gap:3rem;
        -moz-column-gap: 3rem;
        column-gap: 3rem;
        height: 27.75rem
    }
}

@media(min-width: 1441px) {
    .rad-grid-card-carousel.rad-grid-card-carousel--min-cards .rad-grid-card-carousel__cards-container {
        height:calc(27.75rem + 30.8977vw - 445.23591px)
    }
}

@media(min-width: 1920px) {
    .rad-grid-card-carousel.rad-grid-card-carousel--min-cards .rad-grid-card-carousel__cards-container {
        height:37rem
    }
}

.rad-card-grid__cards-viewport {
    -ms-overflow-style: none;
    margin-top: -10px;
    overflow-y: scroll;
    padding-top: 10px;
    scrollbar-width: none
}

.rad-card-grid__cards-viewport::-webkit-scrollbar {
    display: none
}

@media(min-width: 600px) {
    .rad-card-grid__cards-viewport {
        margin-top:0;
        overflow-y: unset;
        padding-top: 0
    }
}

.rad-card-grid__cards-container {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    -ms-overflow-style: none;
    align-items: center;
    -webkit-column-gap: 1rem;
    -moz-column-gap: 1rem;
    column-gap: 1rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 1.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    scrollbar-width: none;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content
}

.rad-card-grid__cards-container::-webkit-scrollbar {
    display: none
}

@media(min-width: 600px) {
    .rad-card-grid__cards-container {
        -webkit-column-gap:3rem;
        -moz-column-gap: 3rem;
        column-gap: 3rem;
        display: grid;
        grid-template-columns: repeat(2,1fr);
        margin: 0 auto 3rem;
        padding: 0;
        row-gap: 3rem
    }
}

@media(min-width: 1441px) {
    .rad-card-grid__cards-container {
        -webkit-column-gap:calc(3rem + 3.34029vw - 48.13361px);
        -moz-column-gap: calc(3rem + 3.34029vw - 48.13361px);
        column-gap: calc(3rem + 3.34029vw - 48.13361px)
    }
}

@media(min-width: 1920px) {
    .rad-card-grid__cards-container {
        -webkit-column-gap:4rem;
        -moz-column-gap: 4rem;
        column-gap: 4rem
    }
}

@media(min-width: 1441px) {
    .rad-card-grid__cards-container {
        row-gap:calc(3rem + 3.34029vw - 48.13361px)
    }
}

@media(min-width: 1920px) {
    .rad-card-grid__cards-container {
        row-gap:4rem
    }
}

@media(min-width: 1441px) {
    .rad-card-grid__cards-container {
        margin-bottom:calc(3rem + 3.34029vw - 48.13361px)
    }
}

@media(min-width: 1920px) {
    .rad-card-grid__cards-container {
        margin-bottom:4rem
    }
}

@media(min-width: 924px) {
    .rad-card-grid__cards-container {
        grid-template-columns:repeat(3,1fr)
    }
}

@media(min-width: 1248px) {
    .rad-card-grid__cards-container {
        grid-template-columns:repeat(4,1fr)
    }
}

.rad-card-grid__slider-container {
    margin: 0 auto 1.5rem;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content
}

@media(min-width: 1024px) {
    .rad-card-grid__slider-container {
        display:none
    }
}

.rad-card-grid__slider-container .rad__range-input-slider-label {
    clip: rect(0 0 0 0);
    -webkit-clip-path: inset(50%);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px
}

.rad-card-grid__slider-container input[id][type=range] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: transparent;
    display: block;
    margin: 0;
    padding: .5rem 0;
    width: 17.25rem
}

@media(min-width: 600px) {
    .rad-card-grid__slider-container input[id][type=range] {
        padding:1.25rem 0
    }
}

.rad-card-grid__slider-container ::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    background: #fff;
    border: none;
    border-radius: 0;
    -webkit-box-shadow: .5rem 0 0 #000,-0.5rem 0 0 #000;
    box-shadow: .5rem 0 0 #000,-0.5rem 0 0 #000;
    cursor: -webkit-grab;
    cursor: grab;
    height: .5rem;
    width: .5rem
}

.rad-card-grid__slider-container ::-moz-range-thumb {
    -moz-appearance: none;
    appearance: none;
    background: #fff;
    border: none;
    border-radius: 0;
    box-shadow: .5rem 0 0 #000,-0.5rem 0 0 #000;
    cursor: grab;
    height: .5rem;
    width: .5rem
}

.rad-card-grid__slider-container ::-ms-thumb {
    appearance: none;
    background: #fff;
    border: none;
    border-radius: 0;
    box-shadow: .5rem 0 0 #000,-0.5rem 0 0 #000;
    cursor: grab;
    height: .5rem;
    width: .5rem
}

.rad-card-grid__slider-container ::-webkit-slider-runnable-track {
    background: #616160;
    cursor: pointer;
    height: .5rem;
    width: 100%
}

.rad-card-grid__slider-container ::-moz-range-track {
    background: #616160;
    cursor: pointer;
    height: .5rem;
    width: 100%
}

.rad-card-grid__slider-container ::-ms-track {
    background: #616160;
    cursor: pointer;
    height: .5rem;
    width: 100%
}

@media(min-width: 1441px) {
    .rad-card-grid__slider-container {
        margin-bottom:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-card-grid__slider-container {
        margin-bottom:2rem
    }
}

@media(min-width: 600px) {
    .rad-card-grid__slider-container {
        display:none
    }
}

.rad-card-grid__explore-more {
    margin: 0 auto
}

.rad-editorial-grid__header {
    margin: 0 1rem 1.5rem
}

@media(min-width: 600px) {
    .rad-editorial-grid__header {
        margin:0 3rem 2.5rem
    }
}

@media(min-width: 1024px) {
    .rad-editorial-grid__header {
        margin-bottom:3rem;
        margin-left: 5rem;
        margin-right: 5rem;
        width: calc(200% - var(--rad-spacing-sm)*3/4*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-sm)*0);
        width: calc(100% - var(--rad-spacing-sm)*7/8*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0);
        width: calc(66.66667% - var(--rad-spacing-sm)*11/12*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1441px) {
    .rad-editorial-grid__header {
        margin-left:calc(5rem + 5.56715vw - 80.22269px)
    }
}

@media(min-width: 1920px) {
    .rad-editorial-grid__header {
        margin-left:6.6666666667rem
    }
}

@media(min-width: 1441px) {
    .rad-editorial-grid__header {
        margin-right:calc(5rem + 5.56715vw - 80.22269px)
    }
}

@media(min-width: 1920px) {
    .rad-editorial-grid__header {
        margin-right:6.6666666667rem
    }
}

@media(min-width: 1441px) {
    .rad-editorial-grid__header {
        margin-bottom:calc(3rem + 3.34029vw - 48.13361px)
    }
}

@media(min-width: 1920px) {
    .rad-editorial-grid__header {
        margin-bottom:4rem
    }
}

.rad-editorial-grid__headline {
    font-size: 1.75rem;
    font-weight: 500;
    letter-spacing: -0.03em;
    line-height: 1.3
}

.rad-editorial-grid__headline:lang(ja-JP),.rad-editorial-grid__headline:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-editorial-grid__headline {
        font-size:2.5rem;
        line-height: 1.2
    }
}

@media(min-width: 1024px) {
    .rad-editorial-grid__headline {
        font-size:3rem;
        line-height: 1.2
    }
}

@media(min-width: 1441px) {
    .rad-editorial-grid__headline {
        font-size:calc(3rem + 3.34029vw - 48.13361px)
    }
}

@media(min-width: 1920px) {
    .rad-editorial-grid__headline {
        font-size:4rem
    }
}

.rad-editorial-grid__headline+.rad-editorial-grid__subheader {
    margin-top: .5rem
}

@media(min-width: 1024px) {
    .rad-editorial-grid__headline+.rad-editorial-grid__subheader {
        margin-top:1rem
    }
}

@media(min-width: 1441px) {
    .rad-editorial-grid__headline+.rad-editorial-grid__subheader {
        margin-top:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-editorial-grid__headline+.rad-editorial-grid__subheader {
        margin-top:1.3333333333rem
    }
}

.rad-editorial-grid__subheader {
    font-family: GT Sectra Fine,Palatino;
    font-size: 1.25rem;
    font-weight: 300;
    line-height: 1.25
}

@media(min-width: 600px) {
    .rad-editorial-grid__subheader {
        font-size:1.5rem;
        line-height: 1.25
    }
}

@media(min-width: 1024px) {
    .rad-editorial-grid__subheader {
        font-size:1.75rem;
        line-height: 1.25
    }
}

@media(min-width: 1441px) {
    .rad-editorial-grid__subheader {
        font-size:calc(1.75rem + 1.9485vw - 28.07794px)
    }
}

@media(min-width: 1920px) {
    .rad-editorial-grid__subheader {
        font-size:2.3333333333rem
    }
}

.rad-editorial-grid__card {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    text-decoration: none;
    width: calc(100% - var(--rad-spacing-sm)*3/4*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-sm)*0)
}

@media(min-width: 600px) {
    .rad-editorial-grid__card {
        width:calc(50% - var(--rad-spacing-sm)*7/8*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-editorial-grid__card {
        width:calc(33.33333% - var(--rad-spacing-sm)*11/12*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0)
    }
}

.rad-editorial-grid__card .editorial-grid-card__copy-wrapper {
    padding-top: 1rem
}

@media(min-width: 600px) {
    .rad-editorial-grid__card .editorial-grid-card__copy-wrapper {
        padding-top:1.5rem
    }
}

@media(min-width: 1441px) {
    .rad-editorial-grid__card .editorial-grid-card__copy-wrapper {
        padding-top:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-editorial-grid__card .editorial-grid-card__copy-wrapper {
        padding-top:2rem
    }
}

.rad-editorial-grid__card .rad-button {
    margin-top: .25rem
}

@media(min-width: 1024px) {
    .rad-editorial-grid__card .rad-button {
        margin-top:0.75rem
    }
}

@media(min-width: 1441px) {
    .rad-editorial-grid__card .rad-button {
        margin-top:calc(0.75rem + 0.83507vw - 12.0334px)
    }
}

@media(min-width: 1920px) {
    .rad-editorial-grid__card .rad-button {
        margin-top:1rem
    }
}

.rad-editorial-grid__card:hover {
    color: #fff
}

@media(max-width: 599px) {
    .rad-editorial-grid__card.rad-editorial-grid__card--mobile-img-disabled:before {
        border:solid #a100ff;
        border-width: .25rem 0 0;
        content: "";
        display: block;
        height: 1.5rem;
        width: 2rem
    }

    .rad-editorial-grid__card.rad-editorial-grid__card--mobile-img-disabled .editorial-grid-card__image,.rad-editorial-grid__card.rad-editorial-grid__card--mobile-img-disabled .editorial-grid-card__video {
        display: none
    }

    .rad-editorial-grid__card.rad-editorial-grid__card--mobile-img-disabled .editorial-grid-card__copy-wrapper {
        padding-top: 0
    }
}

.rad-editorial-grid__card:hover,.rad-editorial-grid__card:visited {
    color: #fff
}

.rad-editorial-grid .editorial-grid-card__label {
    font-size: .75rem;
    font-weight: 500;
    letter-spacing: .02em;
    line-height: 1.2;
    margin-bottom: .5rem;
    text-transform: uppercase
}

.rad-editorial-grid .editorial-grid-card__label:lang(ja-JP),.rad-editorial-grid .editorial-grid-card__label:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 1024px) {
    .rad-editorial-grid .editorial-grid-card__label {
        font-size:0.875rem
    }
}

@media(min-width: 1441px) {
    .rad-editorial-grid .editorial-grid-card__label {
        font-size:calc(0.875rem + 0.97425vw - 14.03897px)
    }
}

@media(min-width: 1920px) {
    .rad-editorial-grid .editorial-grid-card__label {
        font-size:1.1666666667rem
    }
}

@media(min-width: 1024px) {
    .rad-editorial-grid .editorial-grid-card__label {
        margin-bottom:1rem
    }
}

@media(min-width: 1441px) {
    .rad-editorial-grid .editorial-grid-card__label {
        margin-bottom:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-editorial-grid .editorial-grid-card__label {
        margin-bottom:1.3333333333rem
    }
}

.rad-editorial-grid .editorial-grid-card__title {
    font-size: 1.125rem;
    font-weight: 500;
    letter-spacing: -0.02em;
    line-height: 1.4
}

.rad-editorial-grid .editorial-grid-card__title:lang(ja-JP),.rad-editorial-grid .editorial-grid-card__title:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-editorial-grid .editorial-grid-card__title {
        font-size:1.25rem;
        line-height: 1.4
    }
}

@media(min-width: 1024px) {
    .rad-editorial-grid .editorial-grid-card__title {
        font-size:1.5rem;
        line-height: 1.3
    }
}

@media(min-width: 1441px) {
    .rad-editorial-grid .editorial-grid-card__title {
        font-size:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-editorial-grid .editorial-grid-card__title {
        font-size:2rem
    }
}

.rad-editorial-grid .editorial-grid-card__body {
    font-size: .875rem;
    font-weight: 500;
    font-weight: 400;
    letter-spacing: -0.005em;
    line-height: 1.5;
    margin-top: .5rem;
    text-underline-offset: .25rem
}

.rad-editorial-grid .editorial-grid-card__body:lang(ja-JP),.rad-editorial-grid .editorial-grid-card__body:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-editorial-grid .editorial-grid-card__body {
        font-size:1rem
    }
}

@media(min-width: 1441px) {
    .rad-editorial-grid .editorial-grid-card__body {
        font-size:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-editorial-grid .editorial-grid-card__body {
        font-size:1.3333333333rem
    }
}

.rad-editorial-grid .editorial-grid-card__body:lang(ja-JP),.rad-editorial-grid .editorial-grid-card__body:lang(zh-CN) {
    font-weight: 400
}

@media(min-width: 600px) {
    .rad-editorial-grid .editorial-grid-card__body {
        margin-top:.75rem
    }
}

@media(min-width: 1024px) {
    .rad-editorial-grid .editorial-grid-card__body {
        margin-top:1rem
    }
}

@media(min-width: 1441px) {
    .rad-editorial-grid .editorial-grid-card__body {
        margin-top:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-editorial-grid .editorial-grid-card__body {
        margin-top:1.3333333333rem
    }
}

.rad-editorial-grid .editorial-grid-card__image {
    width: 100%
}

.rad-editorial-grid .editorial-grid-card__image .cmp-image__image {
    display: block;
    width: 100%
}

.rad-editorial-grid .editorial-grid-card__video {
    position: relative;
    width: 100%
}

.rad-editorial-grid .editorial-grid-card__video .rad-video {
    aspect-ratio: 16/9;
    display: block;
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%
}

.rad-editorial-grid__cards-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 2.5rem;
    padding-left: var(--rad-spacing-sm);
    padding-right: var(--rad-spacing-sm)
}

@media(min-width: 600px) {
    .rad-editorial-grid__cards-container {
        padding-left:var(--rad-spacing-md);
        padding-right: var(--rad-spacing-md)
    }
}

.rad-component--nested .rad-editorial-grid__cards-container {
    padding-left: 0;
    padding-right: 0
}

@media(min-width: 600px) {
    .rad-editorial-grid__cards-container {
        -webkit-box-orient:horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        gap: 1.5rem;
        row-gap: 3.75rem
    }
}

@media(min-width: 1024px) {
    .rad-editorial-grid__cards-container {
        gap:2rem;
        row-gap: 5rem
    }
}

@media(min-width: 1441px) {
    .rad-editorial-grid__cards-container {
        gap:calc(2rem + 2.22686vw - 32.08907px)
    }
}

@media(min-width: 1920px) {
    .rad-editorial-grid__cards-container {
        gap:2.6666666667rem
    }
}

@media(min-width: 1441px) {
    .rad-editorial-grid__cards-container {
        row-gap:calc(5rem + 5.56715vw - 80.22269px)
    }
}

@media(min-width: 1920px) {
    .rad-editorial-grid__cards-container {
        row-gap:6.6666666667rem
    }
}

.rad-editorial-grid .rad-media-overlay--hide {
    display: none
}

.rad-editorial-grid.rad-editorial-grid--three-items-a .rad-editorial-grid__header {
    margin: 0 auto 1.5rem;
    text-align: center
}

@media(min-width: 600px) {
    .rad-editorial-grid.rad-editorial-grid--three-items-a .rad-editorial-grid__header {
        margin:0 auto 2.5rem
    }
}

@media(min-width: 1024px) {
    .rad-editorial-grid.rad-editorial-grid--three-items-a .rad-editorial-grid__header {
        margin:0 auto 3rem
    }

    .rad-editorial-grid.rad-editorial-grid--two-items .rad-editorial-grid__header {
        width: calc(175% - var(--rad-spacing-sm)*3/4*7 + var(--rad-spacing-sm)*6 + var(--rad-spacing-sm)*0);
        width: calc(87.5% - var(--rad-spacing-sm)*7/8*7 + var(--rad-spacing-sm)*6 + var(--rad-spacing-md)*0);
        width: calc(58.33333% - var(--rad-spacing-sm)*11/12*7 + var(--rad-spacing-sm)*6 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-editorial-grid.rad-editorial-grid--two-items .rad-editorial-grid__card:nth-child(2):not(:has(video)) {
        width:calc(125% - var(--rad-spacing-sm)*3/4*5 + var(--rad-spacing-sm)*4 + var(--rad-spacing-sm)*0)
    }
}

@media(min-width: 1024px) {
    .rad-editorial-grid.rad-editorial-grid--two-items .rad-editorial-grid__card:nth-child(2):not(:has(video)) {
        width:calc(62.5% - var(--rad-spacing-sm)*7/8*5 + var(--rad-spacing-sm)*4 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-editorial-grid.rad-editorial-grid--two-items .rad-editorial-grid__card:nth-child(2):not(:has(video)) {
        width:calc(41.66667% - var(--rad-spacing-sm)*11/12*5 + var(--rad-spacing-sm)*4 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 600px) {
    .rad-editorial-grid.rad-editorial-grid--three-items-b .rad-editorial-grid__card:nth-child(3) {
        margin-left:calc(50% - var(--rad-spacing-sm)*3/4*2 + var(--rad-spacing-sm)*2 + var(--rad-spacing-sm)*0);
        width: calc(150% - var(--rad-spacing-sm)*3/4*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-sm)*0);
        width: calc(75% - var(--rad-spacing-sm)*7/8*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-editorial-grid.rad-editorial-grid--three-items-b .rad-editorial-grid__card:nth-child(3) {
        width:calc(50% - var(--rad-spacing-sm)*11/12*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 600px) {
    .rad-editorial-grid.rad-editorial-grid--three-items-b .rad-editorial-grid__card:nth-child(3) {
        margin-left:calc(25% - var(--rad-spacing-sm)*7/8*2 + var(--rad-spacing-sm)*2 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-editorial-grid.rad-editorial-grid--three-items-b .rad-editorial-grid__card:nth-child(3) {
        margin-left:calc(16.66667% - var(--rad-spacing-sm)*11/12*2 + var(--rad-spacing-sm)*2 + var(--rad-spacing-md)*0)
    }

    .rad-editorial-grid.rad-editorial-grid--three-items-b .rad-editorial-grid__cards-container {
        display: grid;
        gap: 2rem;
        grid-template-areas: "one two two ." "one . three three";
        grid-template-columns: calc(min(100vw, 1920px)/12*4 - var(--rad-spacing-md)*2/12*4 - var(--rad-spacing-sm)*11/12*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0) calc(min(100vw, 1920px)/12*2 - var(--rad-spacing-md)*2/12*2 - var(--rad-spacing-sm)*11/12*2 + var(--rad-spacing-sm)*1 + var(--rad-spacing-md)*0) calc(min(100vw, 1920px)/12*2 - var(--rad-spacing-md)*2/12*2 - var(--rad-spacing-sm)*11/12*2 + var(--rad-spacing-sm)*1 + var(--rad-spacing-md)*0) calc(min(100vw, 1920px)/12*4 - var(--rad-spacing-md)*2/12*4 - var(--rad-spacing-sm)*11/12*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0);
        grid-template-rows: minmax(calc(min(100vw, 1920px)/12*4/2*3 - var(--rad-spacing-md)*2/12*4/2*3 - var(--rad-spacing-sm)*11/12*4/2*3 + var(--rad-spacing-sm)*3/2*3 + var(--rad-spacing-md)*0/2*3),-webkit-max-content) 1fr;
        grid-template-rows: minmax(calc(min(100vw, 1920px)/12*4/2*3 - var(--rad-spacing-md)*2/12*4/2*3 - var(--rad-spacing-sm)*11/12*4/2*3 + var(--rad-spacing-sm)*3/2*3 + var(--rad-spacing-md)*0/2*3),max-content) 1fr;
        row-gap: 5rem
    }
}

@media(min-width: 1441px) {
    .rad-editorial-grid.rad-editorial-grid--three-items-b .rad-editorial-grid__cards-container {
        gap:calc(2rem + 2.22686vw - 32.08907px)
    }
}

@media(min-width: 1920px) {
    .rad-editorial-grid.rad-editorial-grid--three-items-b .rad-editorial-grid__cards-container {
        gap:2.6666666667rem
    }
}

@media(min-width: 1441px) {
    .rad-editorial-grid.rad-editorial-grid--three-items-b .rad-editorial-grid__cards-container {
        row-gap:calc(5rem + 5.56715vw - 80.22269px)
    }
}

@media(min-width: 1920px) {
    .rad-editorial-grid.rad-editorial-grid--three-items-b .rad-editorial-grid__cards-container {
        row-gap:6.6666666667rem
    }
}

@media(min-width: 1024px) {
    .rad-editorial-grid.rad-editorial-grid--three-items-b .rad-editorial-grid__cards-container>* {
        height:-webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        width: 100%
    }

    .rad-editorial-grid.rad-editorial-grid--three-items-b .rad-editorial-grid__cards-container>:first-child {
        grid-area: one;
        width: calc(min(100vw, 1920px)/12*4 - var(--rad-spacing-md)*2/12*4 - var(--rad-spacing-sm)*11/12*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0)
    }

    .rad-editorial-grid.rad-editorial-grid--three-items-b .rad-editorial-grid__cards-container>:nth-child(2) {
        grid-area: two;
        width: calc(min(100vw, 1920px)/12*4 - var(--rad-spacing-md)*2/12*4 - var(--rad-spacing-sm)*11/12*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0)
    }

    .rad-editorial-grid.rad-editorial-grid--three-items-b .rad-editorial-grid__cards-container>:nth-child(3) {
        grid-area: three;
        margin-left: 0;
        width: calc(min(100vw, 1920px)/12*6 - var(--rad-spacing-md)*2/12*6 - var(--rad-spacing-sm)*11/12*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 600px) {
    .rad-editorial-grid.rad-editorial-grid--four-items .rad-editorial-grid__card:nth-child(3) {
        margin-left:calc(50% - var(--rad-spacing-sm)*3/4*2 + var(--rad-spacing-sm)*2 + var(--rad-spacing-sm)*0);
        width: calc(150% - var(--rad-spacing-sm)*3/4*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-sm)*0);
        width: calc(75% - var(--rad-spacing-sm)*7/8*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-editorial-grid.rad-editorial-grid--four-items .rad-editorial-grid__card:nth-child(3) {
        width:calc(50% - var(--rad-spacing-sm)*11/12*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 600px) {
    .rad-editorial-grid.rad-editorial-grid--four-items .rad-editorial-grid__card:nth-child(3) {
        margin-left:calc(25% - var(--rad-spacing-sm)*7/8*2 + var(--rad-spacing-sm)*2 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-editorial-grid.rad-editorial-grid--four-items .rad-editorial-grid__card:nth-child(3) {
        margin-left:calc(16.66667% - var(--rad-spacing-sm)*11/12*2 + var(--rad-spacing-sm)*2 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 600px) {
    .rad-editorial-grid.rad-editorial-grid--four-items .rad-editorial-grid__card:nth-child(4) {
        width:calc(125% - var(--rad-spacing-sm)*3/4*5 + var(--rad-spacing-sm)*4 + var(--rad-spacing-sm)*0);
        width: calc(62.5% - var(--rad-spacing-sm)*7/8*5 + var(--rad-spacing-sm)*4 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-editorial-grid.rad-editorial-grid--four-items .rad-editorial-grid__card:nth-child(4) {
        width:calc(41.66667% - var(--rad-spacing-sm)*11/12*5 + var(--rad-spacing-sm)*4 + var(--rad-spacing-md)*0)
    }

    .rad-editorial-grid.rad-editorial-grid--four-items .rad-editorial-grid__cards-container {
        display: grid;
        gap: 2rem;
        grid-template-areas: "one two two ." "one . three three" "four four . .";
        grid-template-columns: calc(min(100vw, 1920px)/12*4 - var(--rad-spacing-md)*2/12*4 - var(--rad-spacing-sm)*11/12*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0) calc(min(100vw, 1920px)/12*2 - var(--rad-spacing-md)*2/12*2 - var(--rad-spacing-sm)*11/12*2 + var(--rad-spacing-sm)*1 + var(--rad-spacing-md)*0) calc(min(100vw, 1920px)/12*2 - var(--rad-spacing-md)*2/12*2 - var(--rad-spacing-sm)*11/12*2 + var(--rad-spacing-sm)*1 + var(--rad-spacing-md)*0) calc(min(100vw, 1920px)/12*4 - var(--rad-spacing-md)*2/12*4 - var(--rad-spacing-sm)*11/12*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0);
        grid-template-rows: minmax(calc(min(100vw, 1920px)/12*4/2*3 - var(--rad-spacing-md)*2/12*4/2*3 - var(--rad-spacing-sm)*11/12*4/2*3 + var(--rad-spacing-sm)*3/2*3 + var(--rad-spacing-md)*0/2*3),-webkit-max-content) 1fr;
        grid-template-rows: minmax(calc(min(100vw, 1920px)/12*4/2*3 - var(--rad-spacing-md)*2/12*4/2*3 - var(--rad-spacing-sm)*11/12*4/2*3 + var(--rad-spacing-sm)*3/2*3 + var(--rad-spacing-md)*0/2*3),max-content) 1fr;
        row-gap: 5rem
    }
}

@media(min-width: 1441px) {
    .rad-editorial-grid.rad-editorial-grid--four-items .rad-editorial-grid__cards-container {
        gap:calc(2rem + 2.22686vw - 32.08907px)
    }
}

@media(min-width: 1920px) {
    .rad-editorial-grid.rad-editorial-grid--four-items .rad-editorial-grid__cards-container {
        gap:2.6666666667rem
    }
}

@media(min-width: 1441px) {
    .rad-editorial-grid.rad-editorial-grid--four-items .rad-editorial-grid__cards-container {
        row-gap:calc(5rem + 5.56715vw - 80.22269px)
    }
}

@media(min-width: 1920px) {
    .rad-editorial-grid.rad-editorial-grid--four-items .rad-editorial-grid__cards-container {
        row-gap:6.6666666667rem
    }
}

@media(min-width: 1024px) {
    .rad-editorial-grid.rad-editorial-grid--four-items .rad-editorial-grid__cards-container>* {
        height:-webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        width: 100%
    }

    .rad-editorial-grid.rad-editorial-grid--four-items .rad-editorial-grid__cards-container>:first-child {
        grid-area: one;
        width: calc(min(100vw, 1920px)/12*4 - var(--rad-spacing-md)*2/12*4 - var(--rad-spacing-sm)*11/12*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0)
    }

    .rad-editorial-grid.rad-editorial-grid--four-items .rad-editorial-grid__cards-container>:nth-child(2) {
        grid-area: two;
        width: calc(min(100vw, 1920px)/12*4 - var(--rad-spacing-md)*2/12*4 - var(--rad-spacing-sm)*11/12*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0)
    }

    .rad-editorial-grid.rad-editorial-grid--four-items .rad-editorial-grid__cards-container>:nth-child(3) {
        grid-area: three;
        margin-left: 0;
        width: calc(min(100vw, 1920px)/12*6 - var(--rad-spacing-md)*2/12*6 - var(--rad-spacing-sm)*11/12*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0)
    }

    .rad-editorial-grid.rad-editorial-grid--four-items .rad-editorial-grid__cards-container>:nth-child(4) {
        grid-area: four;
        width: calc(min(100vw, 1920px)/12*7 - var(--rad-spacing-md)*2/12*7 - var(--rad-spacing-sm)*11/12*7 + var(--rad-spacing-sm)*6 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 600px) {
    .rad-editorial-grid.rad-editorial-grid--five-items .rad-editorial-grid__card:nth-child(3) {
        margin-left:calc(50% - var(--rad-spacing-sm)*3/4*2 + var(--rad-spacing-sm)*2 + var(--rad-spacing-sm)*0);
        width: calc(150% - var(--rad-spacing-sm)*3/4*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-sm)*0);
        width: calc(75% - var(--rad-spacing-sm)*7/8*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-editorial-grid.rad-editorial-grid--five-items .rad-editorial-grid__card:nth-child(3) {
        width:calc(50% - var(--rad-spacing-sm)*11/12*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 600px) {
    .rad-editorial-grid.rad-editorial-grid--five-items .rad-editorial-grid__card:nth-child(3) {
        margin-left:calc(25% - var(--rad-spacing-sm)*7/8*2 + var(--rad-spacing-sm)*2 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-editorial-grid.rad-editorial-grid--five-items .rad-editorial-grid__card:nth-child(3) {
        margin-left:calc(16.66667% - var(--rad-spacing-sm)*11/12*2 + var(--rad-spacing-sm)*2 + var(--rad-spacing-md)*0)
    }

    .rad-editorial-grid.rad-editorial-grid--five-items .rad-editorial-grid__cards-container {
        display: grid;
        gap: 2rem;
        grid-template-areas: "one two two ." "one . three three" "four five five .";
        grid-template-columns: calc(min(100vw, 1920px)/12*4 - var(--rad-spacing-md)*2/12*4 - var(--rad-spacing-sm)*11/12*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0) calc(min(100vw, 1920px)/12*2 - var(--rad-spacing-md)*2/12*2 - var(--rad-spacing-sm)*11/12*2 + var(--rad-spacing-sm)*1 + var(--rad-spacing-md)*0) calc(min(100vw, 1920px)/12*2 - var(--rad-spacing-md)*2/12*2 - var(--rad-spacing-sm)*11/12*2 + var(--rad-spacing-sm)*1 + var(--rad-spacing-md)*0) calc(min(100vw, 1920px)/12*4 - var(--rad-spacing-md)*2/12*4 - var(--rad-spacing-sm)*11/12*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0);
        grid-template-rows: minmax(calc(min(100vw, 1920px)/12*4/2*3 - var(--rad-spacing-md)*2/12*4/2*3 - var(--rad-spacing-sm)*11/12*4/2*3 + var(--rad-spacing-sm)*3/2*3 + var(--rad-spacing-md)*0/2*3),-webkit-max-content) 1fr;
        grid-template-rows: minmax(calc(min(100vw, 1920px)/12*4/2*3 - var(--rad-spacing-md)*2/12*4/2*3 - var(--rad-spacing-sm)*11/12*4/2*3 + var(--rad-spacing-sm)*3/2*3 + var(--rad-spacing-md)*0/2*3),max-content) 1fr;
        row-gap: 5rem
    }
}

@media(min-width: 1441px) {
    .rad-editorial-grid.rad-editorial-grid--five-items .rad-editorial-grid__cards-container {
        gap:calc(2rem + 2.22686vw - 32.08907px)
    }
}

@media(min-width: 1920px) {
    .rad-editorial-grid.rad-editorial-grid--five-items .rad-editorial-grid__cards-container {
        gap:2.6666666667rem
    }
}

@media(min-width: 1441px) {
    .rad-editorial-grid.rad-editorial-grid--five-items .rad-editorial-grid__cards-container {
        row-gap:calc(5rem + 5.56715vw - 80.22269px)
    }
}

@media(min-width: 1920px) {
    .rad-editorial-grid.rad-editorial-grid--five-items .rad-editorial-grid__cards-container {
        row-gap:6.6666666667rem
    }
}

@media(min-width: 1024px) {
    .rad-editorial-grid.rad-editorial-grid--five-items .rad-editorial-grid__cards-container>* {
        height:-webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        width: 100%
    }

    .rad-editorial-grid.rad-editorial-grid--five-items .rad-editorial-grid__cards-container>:first-child {
        grid-area: one;
        width: calc(min(100vw, 1920px)/12*4 - var(--rad-spacing-md)*2/12*4 - var(--rad-spacing-sm)*11/12*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0)
    }

    .rad-editorial-grid.rad-editorial-grid--five-items .rad-editorial-grid__cards-container>:nth-child(2) {
        grid-area: two;
        width: calc(min(100vw, 1920px)/12*4 - var(--rad-spacing-md)*2/12*4 - var(--rad-spacing-sm)*11/12*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0)
    }

    .rad-editorial-grid.rad-editorial-grid--five-items .rad-editorial-grid__cards-container>:nth-child(3) {
        grid-area: three;
        margin-left: 0;
        width: calc(min(100vw, 1920px)/12*6 - var(--rad-spacing-md)*2/12*6 - var(--rad-spacing-sm)*11/12*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0)
    }

    .rad-editorial-grid.rad-editorial-grid--five-items .rad-editorial-grid__cards-container>:nth-child(4) {
        grid-area: four;
        width: calc(min(100vw, 1920px)/12*4 - var(--rad-spacing-md)*2/12*4 - var(--rad-spacing-sm)*11/12*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0)
    }

    .rad-editorial-grid.rad-editorial-grid--five-items .rad-editorial-grid__cards-container>:nth-child(5) {
        grid-area: five;
        width: calc(min(100vw, 1920px)/12*4 - var(--rad-spacing-md)*2/12*4 - var(--rad-spacing-sm)*11/12*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0)
    }
}

.rad-editorial-grid.rad-editorial-grid--six-items .rad-editorial-grid__cards-container--group-one {
    padding-bottom: 2.5rem
}

@media(min-width: 600px) {
    .rad-editorial-grid.rad-editorial-grid--six-items .rad-editorial-grid__cards-container--group-one {
        padding-bottom:3.75rem
    }

    .rad-editorial-grid.rad-editorial-grid--six-items .rad-editorial-grid__cards-container--group-one .rad-editorial-grid__card:nth-child(3) {
        margin-left: calc(50% - var(--rad-spacing-sm)*3/4*2 + var(--rad-spacing-sm)*2 + var(--rad-spacing-sm)*0);
        width: calc(150% - var(--rad-spacing-sm)*3/4*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-sm)*0);
        width: calc(75% - var(--rad-spacing-sm)*7/8*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-editorial-grid.rad-editorial-grid--six-items .rad-editorial-grid__cards-container--group-one .rad-editorial-grid__card:nth-child(3) {
        width:calc(50% - var(--rad-spacing-sm)*11/12*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 600px) {
    .rad-editorial-grid.rad-editorial-grid--six-items .rad-editorial-grid__cards-container--group-one .rad-editorial-grid__card:nth-child(3) {
        margin-left:calc(25% - var(--rad-spacing-sm)*7/8*2 + var(--rad-spacing-sm)*2 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-editorial-grid.rad-editorial-grid--six-items .rad-editorial-grid__cards-container--group-one .rad-editorial-grid__card:nth-child(3) {
        margin-left:calc(16.66667% - var(--rad-spacing-sm)*11/12*2 + var(--rad-spacing-sm)*2 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 600px) {
    .rad-editorial-grid.rad-editorial-grid--six-items .rad-editorial-grid__cards-container--group-two .rad-editorial-grid__card:first-child {
        width:calc(100% - var(--rad-spacing-sm)*3/4*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-sm)*0);
        width: calc(50% - var(--rad-spacing-sm)*7/8*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-editorial-grid.rad-editorial-grid--six-items .rad-editorial-grid__cards-container--group-two .rad-editorial-grid__card:first-child {
        width:calc(33.33333% - var(--rad-spacing-sm)*11/12*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 600px) {
    .rad-editorial-grid.rad-editorial-grid--six-items .rad-editorial-grid__cards-container--group-two .rad-editorial-grid__card:first-child .cmp-image__image {
        aspect-ratio:1;
        -o-object-fit: cover;
        object-fit: cover
    }

    .rad-editorial-grid.rad-editorial-grid--six-items .rad-editorial-grid__cards-container--group-two .rad-editorial-grid__card:first-child .rad-video {
        aspect-ratio: 16/9;
        -o-object-fit: cover;
        object-fit: cover
    }

    .rad-editorial-grid.rad-editorial-grid--six-items .rad-editorial-grid__cards-container--group-two .rad-editorial-grid__card:nth-child(2) {
        width: calc(100% - var(--rad-spacing-sm)*3/4*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-sm)*0);
        width: calc(50% - var(--rad-spacing-sm)*7/8*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-editorial-grid.rad-editorial-grid--six-items .rad-editorial-grid__cards-container--group-two .rad-editorial-grid__card:nth-child(2) {
        width:calc(33.33333% - var(--rad-spacing-sm)*11/12*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 600px) {
    .rad-editorial-grid.rad-editorial-grid--six-items .rad-editorial-grid__cards-container--group-two .rad-editorial-grid__card:nth-child(3) {
        margin-left:calc(50% - var(--rad-spacing-sm)*3/4*2 + var(--rad-spacing-sm)*2 + var(--rad-spacing-sm)*0);
        width: calc(150% - var(--rad-spacing-sm)*3/4*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-sm)*0);
        width: calc(75% - var(--rad-spacing-sm)*7/8*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-editorial-grid.rad-editorial-grid--six-items .rad-editorial-grid__cards-container--group-two .rad-editorial-grid__card:nth-child(3) {
        width:calc(50% - var(--rad-spacing-sm)*11/12*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 600px) {
    .rad-editorial-grid.rad-editorial-grid--six-items .rad-editorial-grid__cards-container--group-two .rad-editorial-grid__card:nth-child(3) {
        margin-left:calc(25% - var(--rad-spacing-sm)*7/8*2 + var(--rad-spacing-sm)*2 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-editorial-grid.rad-editorial-grid--six-items .rad-editorial-grid__cards-container--group-two .rad-editorial-grid__card:nth-child(3) {
        margin-left:calc(16.66667% - var(--rad-spacing-sm)*11/12*2 + var(--rad-spacing-sm)*2 + var(--rad-spacing-md)*0)
    }

    .rad-editorial-grid.rad-editorial-grid--six-items .rad-editorial-grid__cards-container.rad-editorial-grid__cards-container--group-one {
        display: grid;
        gap: 2rem;
        grid-template-areas: "one two two ." "one . three three";
        grid-template-columns: calc(min(100vw, 1920px)/12*4 - var(--rad-spacing-md)*2/12*4 - var(--rad-spacing-sm)*11/12*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0) calc(min(100vw, 1920px)/12*2 - var(--rad-spacing-md)*2/12*2 - var(--rad-spacing-sm)*11/12*2 + var(--rad-spacing-sm)*1 + var(--rad-spacing-md)*0) calc(min(100vw, 1920px)/12*2 - var(--rad-spacing-md)*2/12*2 - var(--rad-spacing-sm)*11/12*2 + var(--rad-spacing-sm)*1 + var(--rad-spacing-md)*0) calc(min(100vw, 1920px)/12*4 - var(--rad-spacing-md)*2/12*4 - var(--rad-spacing-sm)*11/12*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0);
        grid-template-rows: minmax(calc(min(100vw, 1920px)/12*4/2*3 - var(--rad-spacing-md)*2/12*4/2*3 - var(--rad-spacing-sm)*11/12*4/2*3 + var(--rad-spacing-sm)*3/2*3 + var(--rad-spacing-md)*0/2*3),-webkit-max-content) 1fr;
        grid-template-rows: minmax(calc(min(100vw, 1920px)/12*4/2*3 - var(--rad-spacing-md)*2/12*4/2*3 - var(--rad-spacing-sm)*11/12*4/2*3 + var(--rad-spacing-sm)*3/2*3 + var(--rad-spacing-md)*0/2*3),max-content) 1fr;
        padding-bottom: 5rem;
        row-gap: 5rem
    }
}

@media(min-width: 1441px) {
    .rad-editorial-grid.rad-editorial-grid--six-items .rad-editorial-grid__cards-container.rad-editorial-grid__cards-container--group-one {
        padding-bottom:calc(5rem + 5.56715vw - 80.22269px)
    }
}

@media(min-width: 1920px) {
    .rad-editorial-grid.rad-editorial-grid--six-items .rad-editorial-grid__cards-container.rad-editorial-grid__cards-container--group-one {
        padding-bottom:6.6666666667rem
    }
}

@media(min-width: 1441px) {
    .rad-editorial-grid.rad-editorial-grid--six-items .rad-editorial-grid__cards-container.rad-editorial-grid__cards-container--group-one {
        gap:calc(2rem + 2.22686vw - 32.08907px)
    }
}

@media(min-width: 1920px) {
    .rad-editorial-grid.rad-editorial-grid--six-items .rad-editorial-grid__cards-container.rad-editorial-grid__cards-container--group-one {
        gap:2.6666666667rem
    }
}

@media(min-width: 1441px) {
    .rad-editorial-grid.rad-editorial-grid--six-items .rad-editorial-grid__cards-container.rad-editorial-grid__cards-container--group-one {
        row-gap:calc(5rem + 5.56715vw - 80.22269px)
    }
}

@media(min-width: 1920px) {
    .rad-editorial-grid.rad-editorial-grid--six-items .rad-editorial-grid__cards-container.rad-editorial-grid__cards-container--group-one {
        row-gap:6.6666666667rem
    }
}

@media(min-width: 1024px) {
    .rad-editorial-grid.rad-editorial-grid--six-items .rad-editorial-grid__cards-container.rad-editorial-grid__cards-container--group-one>* {
        height:-webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        width: 100%
    }

    .rad-editorial-grid.rad-editorial-grid--six-items .rad-editorial-grid__cards-container.rad-editorial-grid__cards-container--group-one>:first-child {
        grid-area: one;
        width: calc(min(100vw, 1920px)/12*4 - var(--rad-spacing-md)*2/12*4 - var(--rad-spacing-sm)*11/12*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0)
    }

    .rad-editorial-grid.rad-editorial-grid--six-items .rad-editorial-grid__cards-container.rad-editorial-grid__cards-container--group-one>:nth-child(2) {
        grid-area: two;
        width: calc(min(100vw, 1920px)/12*4 - var(--rad-spacing-md)*2/12*4 - var(--rad-spacing-sm)*11/12*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0)
    }

    .rad-editorial-grid.rad-editorial-grid--six-items .rad-editorial-grid__cards-container.rad-editorial-grid__cards-container--group-one>:nth-child(3) {
        grid-area: three;
        margin-left: 0;
        width: calc(min(100vw, 1920px)/12*6 - var(--rad-spacing-md)*2/12*6 - var(--rad-spacing-sm)*11/12*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0)
    }

    .rad-editorial-grid.rad-editorial-grid--six-items .rad-editorial-grid__cards-container.rad-editorial-grid__cards-container--group-two {
        display: grid;
        gap: 2rem;
        grid-template-areas: "one one two" ". three three";
        grid-template-columns: calc(min(100vw, 1920px)/12*6 - var(--rad-spacing-md)*2/12*6 - var(--rad-spacing-sm)*11/12*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0) calc(min(100vw, 1920px)/12*1 - var(--rad-spacing-md)*2/12*1 - var(--rad-spacing-sm)*11/12*1 + var(--rad-spacing-sm)*0 + var(--rad-spacing-md)*0) calc(min(100vw, 1920px)/12*5 - var(--rad-spacing-md)*2/12*5 - var(--rad-spacing-sm)*11/12*5 + var(--rad-spacing-sm)*4 + var(--rad-spacing-md)*0);
        row-gap: 5rem
    }
}

@media(min-width: 1441px) {
    .rad-editorial-grid.rad-editorial-grid--six-items .rad-editorial-grid__cards-container.rad-editorial-grid__cards-container--group-two {
        gap:calc(2rem + 2.22686vw - 32.08907px)
    }
}

@media(min-width: 1920px) {
    .rad-editorial-grid.rad-editorial-grid--six-items .rad-editorial-grid__cards-container.rad-editorial-grid__cards-container--group-two {
        gap:2.6666666667rem
    }
}

@media(min-width: 1441px) {
    .rad-editorial-grid.rad-editorial-grid--six-items .rad-editorial-grid__cards-container.rad-editorial-grid__cards-container--group-two {
        row-gap:calc(5rem + 5.56715vw - 80.22269px)
    }
}

@media(min-width: 1920px) {
    .rad-editorial-grid.rad-editorial-grid--six-items .rad-editorial-grid__cards-container.rad-editorial-grid__cards-container--group-two {
        row-gap:6.6666666667rem
    }
}

@media(min-width: 1024px) {
    .rad-editorial-grid.rad-editorial-grid--six-items .rad-editorial-grid__cards-container.rad-editorial-grid__cards-container--group-two>* {
        height:-webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        width: 100%
    }

    .rad-editorial-grid.rad-editorial-grid--six-items .rad-editorial-grid__cards-container.rad-editorial-grid__cards-container--group-two>:first-child {
        grid-area: one;
        width: calc(min(100vw, 1920px)/12*7 - var(--rad-spacing-md)*2/12*7 - var(--rad-spacing-sm)*11/12*7 + var(--rad-spacing-sm)*6 + var(--rad-spacing-md)*0)
    }

    .rad-editorial-grid.rad-editorial-grid--six-items .rad-editorial-grid__cards-container.rad-editorial-grid__cards-container--group-two>:first-child .cmp-image__image {
        aspect-ratio: auto;
        -o-object-fit: fill;
        object-fit: fill
    }

    .rad-editorial-grid.rad-editorial-grid--six-items .rad-editorial-grid__cards-container.rad-editorial-grid__cards-container--group-two>:first-child .rad-video {
        aspect-ratio: 16/9;
        -o-object-fit: fill;
        object-fit: fill
    }

    .rad-editorial-grid.rad-editorial-grid--six-items .rad-editorial-grid__cards-container.rad-editorial-grid__cards-container--group-two>:nth-child(2) {
        grid-area: two;
        width: calc(min(100vw, 1920px)/12*4 - var(--rad-spacing-md)*2/12*4 - var(--rad-spacing-sm)*11/12*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0)
    }

    .rad-editorial-grid.rad-editorial-grid--six-items .rad-editorial-grid__cards-container.rad-editorial-grid__cards-container--group-two>:nth-child(3) {
        grid-area: three;
        margin-left: 0;
        width: calc(min(100vw, 1920px)/12*6 - var(--rad-spacing-md)*2/12*6 - var(--rad-spacing-sm)*11/12*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0)
    }
}

.rad-editorial-grid__header--centered .rad-editorial-grid__header {
    text-align: center
}

.rad-editorial-grid__headline--xl .rad-editorial-grid__headline {
    font-size: 3rem;
    font-weight: 500;
    letter-spacing: -0.03em;
    line-height: 1.15
}

.rad-editorial-grid__headline--xl .rad-editorial-grid__headline:lang(ja-JP),.rad-editorial-grid__headline--xl .rad-editorial-grid__headline:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-editorial-grid__headline--xl .rad-editorial-grid__headline {
        font-size:5rem;
        line-height: 1.1
    }
}

@media(min-width: 1024px) {
    .rad-editorial-grid__headline--xl .rad-editorial-grid__headline {
        font-size:6.25rem
    }
}

@media(min-width: 1441px) {
    .rad-editorial-grid__headline--xl .rad-editorial-grid__headline {
        font-size:calc(6.25rem + 6.95894vw - 100.27836px)
    }
}

@media(min-width: 1920px) {
    .rad-editorial-grid__headline--xl .rad-editorial-grid__headline {
        font-size:8.3333333333rem
    }
}

.rad-banner-image-and-text {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: var(--rad-spacing-sm);
    padding-right: var(--rad-spacing-sm);
    position: relative
}

@media(min-width: 600px) {
    .rad-banner-image-and-text {
        padding-left:var(--rad-spacing-md);
        padding-right: var(--rad-spacing-md)
    }
}

.rad-component--nested .rad-banner-image-and-text {
    padding-left: 0;
    padding-right: 0
}

@media(min-width: 600px) {
    .rad-banner-image-and-text {
        width:calc(200% - var(--rad-spacing-sm)*3/4*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-sm)*0);
        width: calc(100% - var(--rad-spacing-sm)*7/8*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-banner-image-and-text {
        width:calc(66.66667% - var(--rad-spacing-sm)*11/12*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0);
        width: calc(300% - var(--rad-spacing-sm)*3/4*12 + var(--rad-spacing-sm)*11 + var(--rad-spacing-sm)*0);
        width: calc(150% - var(--rad-spacing-sm)*7/8*12 + var(--rad-spacing-sm)*11 + var(--rad-spacing-md)*0);
        width: calc(100% - var(--rad-spacing-sm)*11/12*12 + var(--rad-spacing-sm)*11 + var(--rad-spacing-md)*0)
    }
}

.rad-banner-image-and-text__container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column
}

@media(min-width: 600px) {
    .rad-banner-image-and-text__container {
        -webkit-box-pack:justify;
        -ms-flex-pack: justify;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        justify-content: space-between
    }
}

.rad-banner-image-and-text__image {
    margin-bottom: 1.5rem
}

@media(min-width: 600px) {
    .rad-banner-image-and-text__image {
        margin:0;
        width: calc(100% - var(--rad-spacing-sm)*3/4*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-sm)*0);
        width: calc(50% - var(--rad-spacing-sm)*7/8*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-banner-image-and-text__image {
        width:calc(33.33333% - var(--rad-spacing-sm)*11/12*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0);
        width: calc(150% - var(--rad-spacing-sm)*3/4*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-sm)*0);
        width: calc(75% - var(--rad-spacing-sm)*7/8*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0);
        width: calc(50% - var(--rad-spacing-sm)*11/12*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0)
    }
}

.rad-banner-image-and-text__image .rad-banner-image-and-text__primary-image {
    display: block;
    width: 100%
}

.rad-banner-image-and-text__text {
    position: relative;
    text-decoration: none
}

.rad-banner-image-and-text__text:hover,.rad-banner-image-and-text__text:visited {
    color: #fff
}

.rad-banner-image-and-text__text>* {
    margin-bottom: 8px
}

.rad-banner-image-and-text__text>:last-child {
    margin-bottom: 0
}

.rad-banner-image-and-text__text-title {
    font-size: 1.125rem;
    font-weight: 500;
    letter-spacing: -0.02em;
    line-height: 1.4
}

.rad-banner-image-and-text__text-title:lang(ja-JP),.rad-banner-image-and-text__text-title:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-banner-image-and-text__text-title {
        font-size:1.25rem;
        line-height: 1.4
    }
}

@media(min-width: 1024px) {
    .rad-banner-image-and-text__text-title {
        font-size:1.5rem;
        line-height: 1.3
    }
}

@media(min-width: 1441px) {
    .rad-banner-image-and-text__text-title {
        font-size:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-banner-image-and-text__text-title {
        font-size:2rem
    }
}

.rad-banner-image-and-text__text-description {
    font-size: .875rem;
    font-weight: 500;
    font-weight: 400;
    letter-spacing: -0.005em;
    line-height: 1.5;
    margin-bottom: .25rem;
    text-underline-offset: .25rem
}

.rad-banner-image-and-text__text-description:lang(ja-JP),.rad-banner-image-and-text__text-description:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-banner-image-and-text__text-description {
        font-size:1rem
    }
}

@media(min-width: 1441px) {
    .rad-banner-image-and-text__text-description {
        font-size:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-banner-image-and-text__text-description {
        font-size:1.3333333333rem
    }
}

.rad-banner-image-and-text__text-description:lang(ja-JP),.rad-banner-image-and-text__text-description:lang(zh-CN) {
    font-weight: 400
}

.rad-banner-image-and-text__text-description h2 {
    font-size: 1.75rem;
    font-weight: 500;
    letter-spacing: -0.03em;
    line-height: 1.3
}

.rad-banner-image-and-text__text-description h2:lang(ja-JP),.rad-banner-image-and-text__text-description h2:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-banner-image-and-text__text-description h2 {
        font-size:2.5rem;
        line-height: 1.2
    }
}

@media(min-width: 1024px) {
    .rad-banner-image-and-text__text-description h2 {
        font-size:3rem;
        line-height: 1.2
    }
}

@media(min-width: 1441px) {
    .rad-banner-image-and-text__text-description h2 {
        font-size:calc(3rem + 3.34029vw - 48.13361px)
    }
}

@media(min-width: 1920px) {
    .rad-banner-image-and-text__text-description h2 {
        font-size:4rem
    }
}

.rad-banner-image-and-text__text-description h2+h2,.rad-banner-image-and-text__text-description h2+h3 {
    margin-top: 1rem
}

.rad-banner-image-and-text__text-description h2+h4 {
    margin-top: 1.5rem
}

.rad-banner-image-and-text__text-description h2+h5 {
    margin-top: .5rem
}

.rad-banner-image-and-text__text-description h2+ol,.rad-banner-image-and-text__text-description h2+p,.rad-banner-image-and-text__text-description h2+ul {
    margin-top: 1.5rem
}

.rad-banner-image-and-text__text-description h3 {
    font-size: 1.375rem;
    font-weight: 500;
    letter-spacing: -0.02em;
    line-height: 1.25
}

.rad-banner-image-and-text__text-description h3:lang(ja-JP),.rad-banner-image-and-text__text-description h3:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-banner-image-and-text__text-description h3 {
        font-size:1.75rem;
        line-height: 1.3
    }
}

@media(min-width: 1024px) {
    .rad-banner-image-and-text__text-description h3 {
        font-size:2rem;
        line-height: 1.2
    }
}

@media(min-width: 1441px) {
    .rad-banner-image-and-text__text-description h3 {
        font-size:calc(2rem + 2.22686vw - 32.08907px)
    }
}

@media(min-width: 1920px) {
    .rad-banner-image-and-text__text-description h3 {
        font-size:2.6666666667rem
    }
}

.rad-banner-image-and-text__text-description h3+h2 {
    margin-top: 2rem
}

.rad-banner-image-and-text__text-description h3+h3 {
    margin-top: 1rem
}

.rad-banner-image-and-text__text-description h3+h4 {
    margin-top: .5rem
}

.rad-banner-image-and-text__text-description h3+h5 {
    margin-top: 1rem
}

.rad-banner-image-and-text__text-description h3+ol,.rad-banner-image-and-text__text-description h3+p,.rad-banner-image-and-text__text-description h3+ul {
    margin-top: 1.5rem
}

.rad-banner-image-and-text__text-description h4 {
    font-size: 1.125rem;
    font-weight: 500;
    letter-spacing: -0.02em;
    line-height: 1.4
}

.rad-banner-image-and-text__text-description h4:lang(ja-JP),.rad-banner-image-and-text__text-description h4:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-banner-image-and-text__text-description h4 {
        font-size:1.25rem;
        line-height: 1.4
    }
}

@media(min-width: 1024px) {
    .rad-banner-image-and-text__text-description h4 {
        font-size:1.5rem;
        line-height: 1.3
    }
}

@media(min-width: 1441px) {
    .rad-banner-image-and-text__text-description h4 {
        font-size:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-banner-image-and-text__text-description h4 {
        font-size:2rem
    }
}

.rad-banner-image-and-text__text-description h4+h2,.rad-banner-image-and-text__text-description h4+h3 {
    margin-top: 2rem
}

.rad-banner-image-and-text__text-description h4+h4 {
    margin-top: .5rem
}

.rad-banner-image-and-text__text-description h4+h5 {
    margin-top: 2rem
}

.rad-banner-image-and-text__text-description h4+ol,.rad-banner-image-and-text__text-description h4+p,.rad-banner-image-and-text__text-description h4+ul {
    margin-top: .5rem
}

.rad-banner-image-and-text__text-description h5 {
    font-family: GT Sectra Fine,Palatino;
    font-size: 1.25rem;
    font-weight: 300;
    line-height: 1.25
}

@media(min-width: 600px) {
    .rad-banner-image-and-text__text-description h5 {
        font-size:1.5rem;
        line-height: 1.25
    }
}

@media(min-width: 1024px) {
    .rad-banner-image-and-text__text-description h5 {
        font-size:1.75rem;
        line-height: 1.25
    }
}

@media(min-width: 1441px) {
    .rad-banner-image-and-text__text-description h5 {
        font-size:calc(1.75rem + 1.9485vw - 28.07794px)
    }
}

@media(min-width: 1920px) {
    .rad-banner-image-and-text__text-description h5 {
        font-size:2.3333333333rem
    }
}

.rad-banner-image-and-text__text-description h5+h2 {
    margin-top: 2rem
}

.rad-banner-image-and-text__text-description h5+h3,.rad-banner-image-and-text__text-description h5+h4 {
    margin-top: 1.5rem
}

.rad-banner-image-and-text__text-description h5+h5 {
    margin-top: 1rem
}

.rad-banner-image-and-text__text-description h5+ol,.rad-banner-image-and-text__text-description h5+p,.rad-banner-image-and-text__text-description h5+ul {
    margin-top: 1.5rem
}

.rad-banner-image-and-text__text-description ol,.rad-banner-image-and-text__text-description p,.rad-banner-image-and-text__text-description ul {
    padding-right: var(--scrollbar-width)
}

.rad-banner-image-and-text__text-description ol a,.rad-banner-image-and-text__text-description p a,.rad-banner-image-and-text__text-description ul a {
    font-weight: 500
}

.rad-banner-image-and-text__text-description ol a:focus-visible,.rad-banner-image-and-text__text-description p a:focus-visible,.rad-banner-image-and-text__text-description ul a:focus-visible {
    outline-offset: 2px
}

.rad-banner-image-and-text__text-description ol+h2,.rad-banner-image-and-text__text-description ol+h3,.rad-banner-image-and-text__text-description ol+h4,.rad-banner-image-and-text__text-description ol+h5,.rad-banner-image-and-text__text-description p+h2,.rad-banner-image-and-text__text-description p+h3,.rad-banner-image-and-text__text-description p+h4,.rad-banner-image-and-text__text-description p+h5,.rad-banner-image-and-text__text-description ul+h2,.rad-banner-image-and-text__text-description ul+h3,.rad-banner-image-and-text__text-description ul+h4,.rad-banner-image-and-text__text-description ul+h5 {
    margin-top: 2rem
}

.rad-banner-image-and-text__text-description ol+ol,.rad-banner-image-and-text__text-description ol+p,.rad-banner-image-and-text__text-description ol+ul,.rad-banner-image-and-text__text-description p+ol,.rad-banner-image-and-text__text-description p+p,.rad-banner-image-and-text__text-description p+ul,.rad-banner-image-and-text__text-description ul+ol,.rad-banner-image-and-text__text-description ul+p,.rad-banner-image-and-text__text-description ul+ul {
    margin-top: .5rem
}

.rad-banner-image-and-text__text-description ol li {
    counter-increment: li;
    list-style-type: none;
    padding-left: 30px;
    position: relative
}

@media(min-width: 1441px) {
    .rad-banner-image-and-text__text-description ol li {
        padding-left:calc(-0.08351px + 2.08768vw)
    }
}

@media(min-width: 1920px) {
    .rad-banner-image-and-text__text-description ol li {
        padding-left:40px
    }
}

.rad-banner-image-and-text__text-description ol li:before {
    color: #a100ff;
    content: counter(li) attr(start) ". ";
    left: 0;
    position: absolute
}

.rad-banner-image-and-text__text-description ul {
    list-style: none;
    padding-left: 0
}

.rad-banner-image-and-text__text-description ul li {
    padding-left: 29px;
    position: relative
}

@media(min-width: 1441px) {
    .rad-banner-image-and-text__text-description ul li {
        padding-left:calc(-0.08072px + 2.01809vw)
    }
}

@media(min-width: 1920px) {
    .rad-banner-image-and-text__text-description ul li {
        padding-left:38.6666666667px
    }
}

.rad-banner-image-and-text__text-description ul li:before {
    background-color: #a100ff;
    content: "";
    display: inline-block;
    height: .375rem;
    left: 0;
    position: absolute;
    text-align: center;
    top: 10px;
    width: .375rem
}

@media(min-width: 1441px) {
    .rad-banner-image-and-text__text-description ul li:before {
        top:calc(-0.02784px + 0.69589vw)
    }
}

@media(min-width: 1920px) {
    .rad-banner-image-and-text__text-description ul li:before {
        top:13.3333333333px
    }
}

@media(min-width: 600px) {
    .rad-banner-image-and-text__text {
        width:calc(min(100vw, 1920px)/4*4 - var(--rad-spacing-sm)*2/4*4 - var(--rad-spacing-sm)*3/4*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-sm)*0)
    }

    .rad-banner-image-and-text__text>* {
        margin-bottom: 1rem
    }
}

@media(min-width: 1441px) {
    .rad-banner-image-and-text__text>* {
        margin-bottom:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-banner-image-and-text__text>* {
        margin-bottom:1.3333333333rem
    }
}

@media(min-width: 600px) {
    .rad-banner-image-and-text__text:before {
        background-color:#a100ff;
        content: "";
        display: block;
        height: .25rem;
        margin-bottom: 1.5rem;
        width: 2rem
    }
}

@media(min-width: 1024px) {
    .rad-banner-image-and-text__text:before {
        margin-bottom:1.5rem
    }
}

@media(min-width: 1441px) {
    .rad-banner-image-and-text__text:before {
        margin-bottom:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-banner-image-and-text__text:before {
        margin-bottom:2rem
    }
}

@media(min-width: 600px) {
    .rad-banner-image-and-text__text-title {
        margin-bottom:.75rem
    }

    .rad-banner-image-and-text__text-description {
        margin-bottom: .25rem
    }

    .rad-banner-image-and-text__text {
        width: calc(min(100vw, 1920px)/8*4 - var(--rad-spacing-md)*2/8*4 - var(--rad-spacing-sm)*7/8*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-banner-image-and-text__text {
        width:calc(min(100vw, 1920px)/12*4 - var(--rad-spacing-md)*2/12*4 - var(--rad-spacing-sm)*11/12*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 600px) {
    .rad-banner-image-and-text__text h2+h2,.rad-banner-image-and-text__text h2+h3 {
        margin-top:1.5rem
    }

    .rad-banner-image-and-text__text h2+h4 {
        margin-top: 2rem
    }

    .rad-banner-image-and-text__text h2+h5 {
        margin-top: .5rem
    }

    .rad-banner-image-and-text__text h2+ol,.rad-banner-image-and-text__text h2+p,.rad-banner-image-and-text__text h2+ul {
        margin-top: 2rem
    }

    .rad-banner-image-and-text__text h3+h2 {
        margin-top: 3rem
    }

    .rad-banner-image-and-text__text h3+h3 {
        margin-top: 1.5rem
    }

    .rad-banner-image-and-text__text h3+h4 {
        margin-top: .75rem
    }

    .rad-banner-image-and-text__text h3+h5 {
        margin-top: 1.5rem
    }

    .rad-banner-image-and-text__text h3+ol,.rad-banner-image-and-text__text h3+p,.rad-banner-image-and-text__text h3+ul {
        margin-top: 2rem
    }

    .rad-banner-image-and-text__text h4+h2,.rad-banner-image-and-text__text h4+h3 {
        margin-top: 3rem
    }

    .rad-banner-image-and-text__text h4+h4 {
        margin-top: 1rem
    }

    .rad-banner-image-and-text__text h4+h5 {
        margin-top: 3rem
    }

    .rad-banner-image-and-text__text h4+p {
        margin-top: .75rem
    }

    .rad-banner-image-and-text__text h4+ol,.rad-banner-image-and-text__text h4+ul {
        margin-top: 1rem
    }

    .rad-banner-image-and-text__text h5+h2 {
        margin-top: 3rem
    }

    .rad-banner-image-and-text__text h5+h3 {
        margin-top: 2.5rem
    }

    .rad-banner-image-and-text__text h5+h4 {
        margin-top: 2rem
    }

    .rad-banner-image-and-text__text h5+h5 {
        margin-top: 1.5rem
    }

    .rad-banner-image-and-text__text h5+ol,.rad-banner-image-and-text__text h5+p,.rad-banner-image-and-text__text h5+ul {
        margin-top: 2rem
    }

    .rad-banner-image-and-text__text ol,.rad-banner-image-and-text__text p,.rad-banner-image-and-text__text ul {
        padding-right: 0
    }

    .rad-banner-image-and-text__text ol+h2,.rad-banner-image-and-text__text ol+h3,.rad-banner-image-and-text__text ol+h4,.rad-banner-image-and-text__text ol+h5,.rad-banner-image-and-text__text p+h2,.rad-banner-image-and-text__text p+h3,.rad-banner-image-and-text__text p+h4,.rad-banner-image-and-text__text p+h5,.rad-banner-image-and-text__text ul+h2,.rad-banner-image-and-text__text ul+h3,.rad-banner-image-and-text__text ul+h4,.rad-banner-image-and-text__text ul+h5 {
        margin-top: 3rem
    }

    .rad-banner-image-and-text__text ol+ol,.rad-banner-image-and-text__text ol+p,.rad-banner-image-and-text__text ol+ul,.rad-banner-image-and-text__text p+ol,.rad-banner-image-and-text__text p+p,.rad-banner-image-and-text__text p+ul,.rad-banner-image-and-text__text ul+ol,.rad-banner-image-and-text__text ul+p,.rad-banner-image-and-text__text ul+ul {
        margin-top: 1rem
    }

    .rad-banner-image-and-text__text ol li:before,.rad-banner-image-and-text__text ul li:before {
        height: .5rem;
        width: .5rem
    }
}

@media(min-width: 1024px) {
    .rad-banner-image-and-text__text {
        margin:auto 0;
        width: calc(125% - var(--rad-spacing-sm)*3/4*5 + var(--rad-spacing-sm)*4 + var(--rad-spacing-sm)*0);
        width: calc(62.5% - var(--rad-spacing-sm)*7/8*5 + var(--rad-spacing-sm)*4 + var(--rad-spacing-md)*0);
        width: calc(41.66667% - var(--rad-spacing-sm)*11/12*5 + var(--rad-spacing-sm)*4 + var(--rad-spacing-md)*0)
    }

    .rad-banner-image-and-text__text-title {
        margin-bottom: 1rem
    }

    .rad-banner-image-and-text__text-description {
        margin-bottom: 0.75rem
    }
}

@media(min-width: 1441px) {
    .rad-banner-image-and-text__text-description {
        margin-bottom:calc(0.75rem + 0.83507vw - 12.0334px)
    }
}

@media(min-width: 1920px) {
    .rad-banner-image-and-text__text-description {
        margin-bottom:1rem
    }
}

@media(min-width: 1024px) {
    .rad-banner-image-and-text__text h2+h2,.rad-banner-image-and-text__text h2+h3 {
        margin-top:2rem
    }

    .rad-banner-image-and-text__text h2+h4 {
        margin-top: 3rem
    }

    .rad-banner-image-and-text__text h2+h5 {
        margin-top: .5rem
    }

    .rad-banner-image-and-text__text h2+ol,.rad-banner-image-and-text__text h2+p,.rad-banner-image-and-text__text h2+ul {
        margin-top: 3rem
    }

    .rad-banner-image-and-text__text h3+h2 {
        margin-top: 4rem
    }

    .rad-banner-image-and-text__text h3+h3 {
        margin-top: 2rem
    }

    .rad-banner-image-and-text__text h3+h4 {
        margin-top: 1rem
    }

    .rad-banner-image-and-text__text h3+h5 {
        margin-top: 2rem
    }

    .rad-banner-image-and-text__text h3+ol,.rad-banner-image-and-text__text h3+p,.rad-banner-image-and-text__text h3+ul {
        margin-top: 3rem
    }

    .rad-banner-image-and-text__text h4+h2,.rad-banner-image-and-text__text h4+h3 {
        margin-top: 4rem
    }

    .rad-banner-image-and-text__text h4+h4 {
        margin-top: 1.5rem
    }

    .rad-banner-image-and-text__text h4+h5 {
        margin-top: 4rem
    }

    .rad-banner-image-and-text__text h4+p {
        margin-top: 1rem
    }

    .rad-banner-image-and-text__text h4+ol,.rad-banner-image-and-text__text h4+ul {
        margin-top: 1.5rem
    }

    .rad-banner-image-and-text__text h5+h2 {
        margin-top: 4rem
    }

    .rad-banner-image-and-text__text h5+h3,.rad-banner-image-and-text__text h5+h4 {
        margin-top: 3rem
    }

    .rad-banner-image-and-text__text h5+h5 {
        margin-top: 2rem
    }

    .rad-banner-image-and-text__text h5+ol,.rad-banner-image-and-text__text h5+p,.rad-banner-image-and-text__text h5+ul {
        margin-top: 3rem
    }

    .rad-banner-image-and-text__text ol+h2,.rad-banner-image-and-text__text ol+h3,.rad-banner-image-and-text__text ol+h4,.rad-banner-image-and-text__text ol+h5,.rad-banner-image-and-text__text p+h2,.rad-banner-image-and-text__text p+h3,.rad-banner-image-and-text__text p+h4,.rad-banner-image-and-text__text p+h5,.rad-banner-image-and-text__text ul+h2,.rad-banner-image-and-text__text ul+h3,.rad-banner-image-and-text__text ul+h4,.rad-banner-image-and-text__text ul+h5 {
        margin-top: 4rem
    }

    .rad-banner-image-and-text__text ol+ol,.rad-banner-image-and-text__text ol+p,.rad-banner-image-and-text__text ol+ul,.rad-banner-image-and-text__text p+ol,.rad-banner-image-and-text__text p+p,.rad-banner-image-and-text__text p+ul,.rad-banner-image-and-text__text ul+ol,.rad-banner-image-and-text__text ul+p,.rad-banner-image-and-text__text ul+ul {
        margin-top: 1.5rem
    }
}

.rad-banner-image-and-text__header {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-bottom: 1.5rem;
    row-gap: .5rem;
    width: calc(100% - var(--rad-spacing-sm)*3/4*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-sm)*0)
}

@media(min-width: 600px) {
    .rad-banner-image-and-text__header {
        width:calc(50% - var(--rad-spacing-sm)*7/8*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-banner-image-and-text__header {
        width:calc(33.33333% - var(--rad-spacing-sm)*11/12*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 600px) {
    .rad-banner-image-and-text__header {
        margin-bottom:2.5rem;
        width: calc(150% - var(--rad-spacing-sm)*3/4*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-sm)*0);
        width: calc(75% - var(--rad-spacing-sm)*7/8*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-banner-image-and-text__header {
        margin-bottom:3rem;
        row-gap: 0.5rem;
        width: calc(50% - var(--rad-spacing-sm)*11/12*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0);
        width: calc(200% - var(--rad-spacing-sm)*3/4*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-sm)*0)
    }
}

@media(min-width: 1441px) {
    .rad-banner-image-and-text__header {
        margin-bottom:calc(3rem + 3.34029vw - 48.13361px)
    }
}

@media(min-width: 1920px) {
    .rad-banner-image-and-text__header {
        margin-bottom:4rem
    }
}

@media(min-width: 1441px) {
    .rad-banner-image-and-text__header {
        row-gap:calc(0.5rem + 0.55672vw - 8.02227px)
    }
}

@media(min-width: 1920px) {
    .rad-banner-image-and-text__header {
        row-gap:0.6666666667rem
    }
}

@media(min-width: 1024px) {
    .rad-banner-image-and-text__header {
        width:calc(100% - var(--rad-spacing-sm)*7/8*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0);
        width: calc(66.66667% - var(--rad-spacing-sm)*11/12*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0)
    }
}

.rad-banner-image-and-text__header-headline {
    font-size: 1.75rem;
    font-weight: 500;
    letter-spacing: -0.03em;
    line-height: 1.3
}

.rad-banner-image-and-text__header-headline:lang(ja-JP),.rad-banner-image-and-text__header-headline:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-banner-image-and-text__header-headline {
        font-size:2.5rem;
        line-height: 1.2
    }
}

@media(min-width: 1024px) {
    .rad-banner-image-and-text__header-headline {
        font-size:3rem;
        line-height: 1.2
    }
}

@media(min-width: 1441px) {
    .rad-banner-image-and-text__header-headline {
        font-size:calc(3rem + 3.34029vw - 48.13361px)
    }
}

@media(min-width: 1920px) {
    .rad-banner-image-and-text__header-headline {
        font-size:4rem
    }
}

.rad-banner-image-and-text__header-subheadline {
    font-family: GT Sectra Fine,Palatino;
    font-size: 1.25rem;
    font-weight: 300;
    line-height: 1.25
}

@media(min-width: 600px) {
    .rad-banner-image-and-text__header-subheadline {
        font-size:1.5rem;
        line-height: 1.25
    }
}

@media(min-width: 1024px) {
    .rad-banner-image-and-text__header-subheadline {
        font-size:1.75rem;
        line-height: 1.25
    }
}

@media(min-width: 1441px) {
    .rad-banner-image-and-text__header-subheadline {
        font-size:calc(1.75rem + 1.9485vw - 28.07794px)
    }
}

@media(min-width: 1920px) {
    .rad-banner-image-and-text__header-subheadline {
        font-size:2.3333333333rem
    }
}

.rad-banner-image-and-text .rad-absorb-video__transcript,.rad-banner-image-and-text .rad-vidyard-player__transcript {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    padding-right: 0;
    padding-top: .25rem
}

@media(min-width: 600px) {
    .rad-banner-image-and-text .rad-absorb-video__transcript,.rad-banner-image-and-text .rad-vidyard-player__transcript {
        -webkit-box-pack:start;
        -ms-flex-pack: start;
        justify-content: flex-start
    }
}

@media(min-width: 1024px) {
    .rad-banner-image-and-text .rad-absorb-video__transcript,.rad-banner-image-and-text .rad-vidyard-player__transcript {
        padding-top:.75rem
    }
}

.rad-banner-image-and-text .rad-vidyard-player {
    padding-bottom: 0
}

.rad-banner-image-and-text .rad-absorb-video {
    margin: 0
}

@media(min-width: 600px) {
    .rad-banner-image-and-text--media-right .rad-banner-image-and-text__container {
        -webkit-box-orient:horizontal;
        -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
        flex-direction: row-reverse
    }
}

.rad-banner-image-and-text--media-right .rad-absorb-video__transcript,.rad-banner-image-and-text--media-right .rad-vidyard-player__transcript {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end
}

.rad-banner-image-and-text--bottom-padding-disabled {
    padding-bottom: 0
}

.rad-banner-image-and-text--top-padding-disabled {
    padding-top: 0
}

.rad-link-list__lists {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    row-gap: .75rem
}

@media(min-width: 600px) {
    .rad-link-list__lists {
        row-gap:1rem
    }
}

@media(min-width: 1441px) {
    .rad-link-list__lists {
        row-gap:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-link-list__lists {
        row-gap:1.3333333333rem
    }
}

.rad-link-list__wrapper .rad-link-list__link {
    font-size: .875rem;
    font-weight: 500;
    letter-spacing: -0.005em;
    line-height: 1.5;
    text-underline-offset: .25rem;
    text-underline-offset: .375rem
}

.rad-link-list__wrapper .rad-link-list__link:lang(ja-JP),.rad-link-list__wrapper .rad-link-list__link:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-link-list__wrapper .rad-link-list__link {
        font-size:1rem
    }
}

@media(min-width: 1441px) {
    .rad-link-list__wrapper .rad-link-list__link {
        font-size:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-link-list__wrapper .rad-link-list__link {
        font-size:1.3333333333rem
    }
}

.rad-link-list__list-item:last-child {
    margin-bottom: 12px
}

@media(min-width: 600px) {
    .rad-link-list__list-item:last-child {
        margin-bottom:16px
    }
}

@media(min-width: 1441px) {
    .rad-link-list__list-item:last-child {
        margin-bottom:calc(-0.04454px + 1.11343vw)
    }
}

@media(min-width: 1920px) {
    .rad-link-list__list-item:last-child {
        margin-bottom:21.3333333333px
    }
}

.rad-link-list__list-item :focus-visible {
    outline-offset: 0;
    padding: 8px 0
}

.rad-link-list.rad-spacing-bottom-md {
    padding-bottom: 0
}

.linklist[class*=rad-component-spacing-bottom] .rad-link-list__list-item:last-child {
    margin-bottom: 0
}

.rad-header {
    padding-left: var(--rad-spacing-sm);
    padding-right: var(--rad-spacing-sm)
}

@media(min-width: 600px) {
    .rad-header {
        padding-left:var(--rad-spacing-md);
        padding-right: var(--rad-spacing-md)
    }
}

.rad-component--nested .rad-header {
    padding-left: 0;
    padding-right: 0
}

.rad-header__wrapper {
    -ms-flex-item-align: stretch;
    align-self: stretch;
    -ms-flex-direction: column;
    flex-direction: column
}

.rad-header__text-container,.rad-header__wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.rad-header__text-container {
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%
}

@media(min-width: 600px) {
    .rad-header__text-container {
        width:calc(150% - var(--rad-spacing-sm)*3/4*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-sm)*0);
        width: calc(75% - var(--rad-spacing-sm)*7/8*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-header__text-container {
        width:calc(50% - var(--rad-spacing-sm)*11/12*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0);
        width: calc(200% - var(--rad-spacing-sm)*3/4*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-sm)*0);
        width: calc(100% - var(--rad-spacing-sm)*7/8*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0);
        width: calc(66.66667% - var(--rad-spacing-sm)*11/12*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0)
    }
}

.rad-header__headline {
    margin-bottom: 0.25rem
}

@media(min-width: 1441px) {
    .rad-header__headline {
        margin-bottom:calc(0.25rem + 0.27836vw - 4.01113px)
    }
}

@media(min-width: 1920px) {
    .rad-header__headline {
        margin-bottom:0.3333333333rem
    }
}

.rad-header__sub-headline {
    margin-bottom: 16px
}

@media(min-width: 1024px) {
    .rad-header__sub-headline {
        margin-bottom:1.5rem
    }
}

@media(min-width: 1441px) {
    .rad-header__sub-headline {
        margin-bottom:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-header__sub-headline {
        margin-bottom:2rem
    }
}

.rad-header.alignment-left.cta-position-right .rad-header__wrapper {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

@media(min-width: 600px) {
    .rad-header.alignment-left.cta-position-right .rad-header__wrapper {
        -webkit-box-orient:horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row
    }
}

.rad-header.alignment-left.cta-position-bottom .rad-header__wrapper {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start
}

.rad-header.alignment-left .rad-header__headline {
    font-size: 1.75rem;
    font-weight: 500;
    letter-spacing: -0.03em;
    line-height: 1.3
}

.rad-header.alignment-left .rad-header__headline:lang(ja-JP),.rad-header.alignment-left .rad-header__headline:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-header.alignment-left .rad-header__headline {
        font-size:2.5rem;
        line-height: 1.2
    }
}

@media(min-width: 1024px) {
    .rad-header.alignment-left .rad-header__headline {
        font-size:3rem;
        line-height: 1.2
    }
}

@media(min-width: 1441px) {
    .rad-header.alignment-left .rad-header__headline {
        font-size:calc(3rem + 3.34029vw - 48.13361px)
    }
}

@media(min-width: 1920px) {
    .rad-header.alignment-left .rad-header__headline {
        font-size:4rem
    }
}

.rad-header.alignment-left .rad-header__sub-headline {
    font-family: GT Sectra Fine,Palatino;
    font-size: 1.25rem;
    font-weight: 300;
    line-height: 1.25;
    margin-top: 0.25rem
}

@media(min-width: 600px) {
    .rad-header.alignment-left .rad-header__sub-headline {
        font-size:1.5rem;
        line-height: 1.25
    }
}

@media(min-width: 1024px) {
    .rad-header.alignment-left .rad-header__sub-headline {
        font-size:1.75rem;
        line-height: 1.25
    }
}

@media(min-width: 1441px) {
    .rad-header.alignment-left .rad-header__sub-headline {
        font-size:calc(1.75rem + 1.9485vw - 28.07794px)
    }
}

@media(min-width: 1920px) {
    .rad-header.alignment-left .rad-header__sub-headline {
        font-size:2.3333333333rem
    }
}

@media(min-width: 1441px) {
    .rad-header.alignment-left .rad-header__sub-headline {
        margin-top:calc(0.25rem + 0.27836vw - 4.01113px)
    }
}

@media(min-width: 1920px) {
    .rad-header.alignment-left .rad-header__sub-headline {
        margin-top:0.3333333333rem
    }
}

.rad-header.alignment-center .rad-header__wrapper {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.rad-header.alignment-center .rad-header__text-container {
    text-align: center
}

.rad-header.alignment-center .rad-header__headline {
    font-size: 3rem;
    font-weight: 500;
    letter-spacing: -0.03em;
    line-height: 1.15
}

.rad-header.alignment-center .rad-header__headline:lang(ja-JP),.rad-header.alignment-center .rad-header__headline:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-header.alignment-center .rad-header__headline {
        font-size:5rem;
        line-height: 1.1
    }
}

@media(min-width: 1024px) {
    .rad-header.alignment-center .rad-header__headline {
        font-size:6.25rem
    }
}

@media(min-width: 1441px) {
    .rad-header.alignment-center .rad-header__headline {
        font-size:calc(6.25rem + 6.95894vw - 100.27836px)
    }
}

@media(min-width: 1920px) {
    .rad-header.alignment-center .rad-header__headline {
        font-size:8.3333333333rem
    }
}

.rad-header.alignment-center .rad-header__sub-headline {
    font-family: GT Sectra Fine,Palatino;
    font-size: 1.25rem;
    font-weight: 300;
    line-height: 1.25
}

@media(min-width: 600px) {
    .rad-header.alignment-center .rad-header__sub-headline {
        font-size:1.5rem;
        line-height: 1.25
    }
}

@media(min-width: 1024px) {
    .rad-header.alignment-center .rad-header__sub-headline {
        font-size:1.75rem;
        line-height: 1.25
    }
}

@media(min-width: 1441px) {
    .rad-header.alignment-center .rad-header__sub-headline {
        font-size:calc(1.75rem + 1.9485vw - 28.07794px)
    }
}

@media(min-width: 1920px) {
    .rad-header.alignment-center .rad-header__sub-headline {
        font-size:2.3333333333rem
    }
}

@media(min-width: 600px) {
    .rad-header.alignment-center .rad-header__sub-headline {
        margin-top:0.25rem
    }
}

@media(min-width: 1441px) {
    .rad-header.alignment-center .rad-header__sub-headline {
        margin-top:calc(0.25rem + 0.27836vw - 4.01113px)
    }
}

@media(min-width: 1920px) {
    .rad-header.alignment-center .rad-header__sub-headline {
        margin-top:0.3333333333rem
    }
}

.rad-header__padding-standard {
    padding-bottom: 1.5rem;
    padding-top: 1.5rem
}

@media(min-width: 600px) {
    .rad-header__padding-standard {
        padding-bottom:2.5rem;
        padding-top: 3.75rem
    }
}

@media(min-width: 1441px) {
    .rad-header__padding-standard {
        padding-top:calc(3.75rem + 4.17537vw - 60.16701px)
    }
}

@media(min-width: 1920px) {
    .rad-header__padding-standard {
        padding-top:5rem
    }
}

@media(min-width: 1024px) {
    .rad-header__padding-standard {
        padding-bottom:3rem
    }
}

@media(min-width: 1441px) {
    .rad-header__padding-standard {
        padding-bottom:calc(3rem + 3.34029vw - 48.13361px)
    }
}

@media(min-width: 1920px) {
    .rad-header__padding-standard {
        padding-bottom:4rem
    }
}

.rad-header__padding-large {
    padding-bottom: 1.5rem;
    padding-top: 1.5rem
}

@media(min-width: 600px) {
    .rad-header__padding-large {
        padding-bottom:2.5rem;
        padding-top: 3.75rem
    }
}

@media(min-width: 1441px) {
    .rad-header__padding-large {
        padding-top:calc(3.75rem + 4.17537vw - 60.16701px)
    }
}

@media(min-width: 1920px) {
    .rad-header__padding-large {
        padding-top:5rem
    }
}

@media(min-width: 1024px) {
    .rad-header__padding-large {
        padding-bottom:5rem
    }
}

@media(min-width: 1441px) {
    .rad-header__padding-large {
        padding-bottom:calc(5rem + 5.56715vw - 80.22269px)
    }
}

@media(min-width: 1920px) {
    .rad-header__padding-large {
        padding-bottom:6.6666666667rem
    }
}

.rad-header__headline-xl .rad-header__text-container .rad-header__headline {
    font-size: 3rem;
    font-weight: 500;
    letter-spacing: -0.03em;
    line-height: 1.15
}

.rad-header__headline-xl .rad-header__text-container .rad-header__headline:lang(ja-JP),.rad-header__headline-xl .rad-header__text-container .rad-header__headline:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-header__headline-xl .rad-header__text-container .rad-header__headline {
        font-size:5rem;
        line-height: 1.1
    }
}

@media(min-width: 1024px) {
    .rad-header__headline-xl .rad-header__text-container .rad-header__headline {
        font-size:6.25rem
    }
}

@media(min-width: 1441px) {
    .rad-header__headline-xl .rad-header__text-container .rad-header__headline {
        font-size:calc(6.25rem + 6.95894vw - 100.27836px)
    }
}

@media(min-width: 1920px) {
    .rad-header__headline-xl .rad-header__text-container .rad-header__headline {
        font-size:8.3333333333rem
    }
}

.rad-header__title-large .rad-header__text-container .rad-header__headline {
    font-size: 1.375rem;
    font-weight: 500;
    letter-spacing: -0.02em;
    line-height: 1.25
}

.rad-header__title-large .rad-header__text-container .rad-header__headline:lang(ja-JP),.rad-header__title-large .rad-header__text-container .rad-header__headline:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-header__title-large .rad-header__text-container .rad-header__headline {
        font-size:1.75rem;
        line-height: 1.3
    }
}

@media(min-width: 1024px) {
    .rad-header__title-large .rad-header__text-container .rad-header__headline {
        font-size:2rem;
        line-height: 1.2
    }
}

@media(min-width: 1441px) {
    .rad-header__title-large .rad-header__text-container .rad-header__headline {
        font-size:calc(2rem + 2.22686vw - 32.08907px)
    }
}

@media(min-width: 1920px) {
    .rad-header__title-large .rad-header__text-container .rad-header__headline {
        font-size:2.6666666667rem
    }
}

.rad-header__headline-medium .rad-header__text-container .rad-header__headline {
    font-size: 1.75rem;
    font-weight: 500;
    letter-spacing: -0.03em;
    line-height: 1.3
}

.rad-header__headline-medium .rad-header__text-container .rad-header__headline:lang(ja-JP),.rad-header__headline-medium .rad-header__text-container .rad-header__headline:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-header__headline-medium .rad-header__text-container .rad-header__headline {
        font-size:2.5rem;
        line-height: 1.2
    }
}

@media(min-width: 1024px) {
    .rad-header__headline-medium .rad-header__text-container .rad-header__headline {
        font-size:3rem;
        line-height: 1.2
    }
}

@media(min-width: 1441px) {
    .rad-header__headline-medium .rad-header__text-container .rad-header__headline {
        font-size:calc(3rem + 3.34029vw - 48.13361px)
    }
}

@media(min-width: 1920px) {
    .rad-header__headline-medium .rad-header__text-container .rad-header__headline {
        font-size:4rem
    }
}

.rad-content-card {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    background-color: #202020;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    justify-content: space-between;
    min-height: 13.6875rem;
    padding: 1rem 1.5rem
}

@media(min-width: 600px) {
    .rad-content-card {
        height:100%;
        min-height: 13.5rem;
        padding: 1.5rem
    }
}

@media(min-width: 1024px) {
    .rad-content-card {
        min-height:16.875rem
    }
}

@media(min-width: 1441px) {
    .rad-content-card {
        min-height:calc(16.875rem + 18.78914vw - 270.75157px)
    }
}

@media(min-width: 1920px) {
    .rad-content-card {
        min-height:22.5rem
    }
}

.rad-content-card__deco-line {
    background-color: #a100ff;
    display: block;
    height: .25rem;
    margin-bottom: 1rem;
    width: 2rem
}

@media(min-width: 600px) {
    .rad-content-card__deco-line {
        margin-bottom:1.5rem
    }
}

@media(min-width: 1024px) {
    .rad-content-card__deco-line {
        margin-bottom:1rem
    }
}

@media(min-width: 1441px) {
    .rad-content-card__deco-line {
        margin-bottom:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-content-card__deco-line {
        margin-bottom:1.3333333333rem
    }
}

.rad-content-card__save-button {
    float: right;
    margin-left: .25rem
}

@media(min-width: 600px) {
    .rad-content-card__save-button {
        margin-left:0.5rem
    }
}

@media(min-width: 1441px) {
    .rad-content-card__save-button {
        margin-left:calc(0.5rem + 0.55672vw - 8.02227px)
    }
}

@media(min-width: 1920px) {
    .rad-content-card__save-button {
        margin-left:0.6666666667rem
    }
}

.rad-content-card__eyebrow {
    font-size: .75rem;
    font-weight: 500;
    letter-spacing: .02em;
    line-height: 1.2;
    margin-bottom: .5rem
}

.rad-content-card__eyebrow:lang(ja-JP),.rad-content-card__eyebrow:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 1024px) {
    .rad-content-card__eyebrow {
        font-size:0.875rem
    }
}

@media(min-width: 1441px) {
    .rad-content-card__eyebrow {
        font-size:calc(0.875rem + 0.97425vw - 14.03897px)
    }
}

@media(min-width: 1920px) {
    .rad-content-card__eyebrow {
        font-size:1.1666666667rem
    }
}

@media(min-width: 1024px) {
    .rad-content-card__eyebrow {
        margin-bottom:1rem
    }
}

@media(min-width: 1441px) {
    .rad-content-card__eyebrow {
        margin-bottom:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-content-card__eyebrow {
        margin-bottom:1.3333333333rem
    }
}

.rad-content-card__title {
    font-size: 1.125rem;
    font-weight: 500;
    letter-spacing: -0.02em;
    line-height: 1.4;
    word-break: break-word
}

.rad-content-card__title:lang(ja-JP),.rad-content-card__title:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-content-card__title {
        font-size:1.25rem;
        line-height: 1.4
    }
}

@media(min-width: 1024px) {
    .rad-content-card__title {
        font-size:1.5rem;
        line-height: 1.3
    }
}

@media(min-width: 1441px) {
    .rad-content-card__title {
        font-size:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-content-card__title {
        font-size:2rem
    }
}

.rad-content-card__body {
    font-size: .75rem;
    font-weight: 500;
    font-weight: 400;
    letter-spacing: -0.005em;
    line-height: 1.5;
    margin-top: .5rem;
    text-underline-offset: .25rem
}

.rad-content-card__body:lang(ja-JP),.rad-content-card__body:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-content-card__body {
        font-size:0.875rem
    }
}

@media(min-width: 1441px) {
    .rad-content-card__body {
        font-size:calc(0.875rem + 0.97425vw - 14.03897px)
    }
}

@media(min-width: 1920px) {
    .rad-content-card__body {
        font-size:1.1666666667rem
    }
}

.rad-content-card__body:lang(ja-JP),.rad-content-card__body:lang(zh-CN) {
    font-weight: 400
}

@media(min-width: 600px) {
    .rad-content-card__body {
        margin-top:.75rem
    }
}

@media(min-width: 1024px) {
    .rad-content-card__body {
        margin-top:1rem
    }
}

@media(min-width: 1441px) {
    .rad-content-card__body {
        margin-top:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-content-card__body {
        margin-top:1.3333333333rem
    }
}

.rad-content-card__job-data {
    color: #a2a2a0;
    font-size: .75rem;
    font-weight: 500;
    font-weight: 400;
    letter-spacing: -0.005em;
    line-height: 1.5;
    padding-top: .5rem;
    text-underline-offset: .25rem
}

.rad-content-card__job-data:lang(ja-JP),.rad-content-card__job-data:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-content-card__job-data {
        font-size:0.875rem
    }
}

@media(min-width: 1441px) {
    .rad-content-card__job-data {
        font-size:calc(0.875rem + 0.97425vw - 14.03897px)
    }
}

@media(min-width: 1920px) {
    .rad-content-card__job-data {
        font-size:1.1666666667rem
    }
}

.rad-content-card__job-data:lang(ja-JP),.rad-content-card__job-data:lang(zh-CN) {
    font-weight: 400
}

@media(min-width: 1024px) {
    .rad-content-card__job-data {
        padding-top:.75rem
    }
}

.rad-content-card__cta {
    margin-top: .25rem
}

@media(min-width: 600px) {
    .rad-content-card__cta {
        margin-top:0.75rem
    }
}

@media(min-width: 1441px) {
    .rad-content-card__cta {
        margin-top:calc(0.75rem + 0.83507vw - 12.0334px)
    }
}

@media(min-width: 1920px) {
    .rad-content-card__cta {
        margin-top:1rem
    }
}

.rad-content-cards-block {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 1.5rem 1rem;
    row-gap: 1.5rem
}

@media(min-width: 600px) {
    .rad-content-cards-block {
        -webkit-column-gap:1.5rem;
        -moz-column-gap: 1.5rem;
        column-gap: 1.5rem;
        display: grid;
        grid-template-columns: repeat(2,1fr);
        padding-bottom: 3.75rem;
        padding-left: var(--rad-spacing-sm);
        padding-left: var(--rad-spacing-md);
        padding-right: var(--rad-spacing-sm);
        padding-right: var(--rad-spacing-md);
        padding-top: 3.75rem
    }

    .rad-component--nested .rad-content-cards-block {
        padding-left: 0;
        padding-right: 0
    }
}

@media(min-width: 1441px) {
    .rad-content-cards-block {
        padding-top:calc(3.75rem + 4.17537vw - 60.16701px)
    }
}

@media(min-width: 1920px) {
    .rad-content-cards-block {
        padding-top:5rem
    }
}

@media(min-width: 1441px) {
    .rad-content-cards-block {
        padding-bottom:calc(3.75rem + 4.17537vw - 60.16701px)
    }
}

@media(min-width: 1920px) {
    .rad-content-cards-block {
        padding-bottom:5rem
    }
}

@media(min-width: 1024px) {
    .rad-content-cards-block {
        -webkit-column-gap:2rem;
        -moz-column-gap: 2rem;
        column-gap: 2rem;
        grid-template-columns: repeat(3,1fr);
        row-gap: 2rem
    }
}

@media(min-width: 1441px) {
    .rad-content-cards-block {
        row-gap:calc(2rem + 2.22686vw - 32.08907px)
    }
}

@media(min-width: 1920px) {
    .rad-content-cards-block {
        row-gap:2.6666666667rem
    }
}

@media(min-width: 1441px) {
    .rad-content-cards-block {
        -webkit-column-gap:calc(2rem + 2.22686vw - 32.08907px);
        -moz-column-gap: calc(2rem + 2.22686vw - 32.08907px);
        column-gap: calc(2rem + 2.22686vw - 32.08907px)
    }
}

@media(min-width: 1920px) {
    .rad-content-cards-block {
        -webkit-column-gap:2.6666666667rem;
        -moz-column-gap: 2.6666666667rem;
        column-gap: 2.6666666667rem
    }
}

.rad-job-detail__gen-description {
    font-size: 1.125rem;
    font-weight: 500;
    letter-spacing: -0.02em;
    line-height: 1.4;
    padding: 0 1rem 1.5rem
}

.rad-job-detail__gen-description:lang(ja-JP),.rad-job-detail__gen-description:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-job-detail__gen-description {
        font-size:1.25rem;
        line-height: 1.4
    }
}

@media(min-width: 1024px) {
    .rad-job-detail__gen-description {
        font-size:1.5rem;
        line-height: 1.3
    }
}

@media(min-width: 1441px) {
    .rad-job-detail__gen-description {
        font-size:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-job-detail__gen-description {
        font-size:2rem
    }
}

@media(min-width: 600px) {
    .rad-job-detail__gen-description {
        padding:0 3rem 2.5rem
    }
}

@media(min-width: 1024px) {
    .rad-job-detail__gen-description {
        padding:0 0 3rem
    }
}

.rad-job-detail__accordion {
    padding: 0 1rem 1.5rem
}

@media(min-width: 600px) {
    .rad-job-detail__accordion {
        padding:0 3rem 3rem
    }
}

@media(min-width: 1024px) {
    .rad-job-detail__accordion {
        padding:0 0 3.75rem
    }
}

.rad-job-detail__accordion .rad-accordion-atom {
    border-top: 2px solid #a100ff
}

.rad-job-detail__accordion .rad-accordion-atom:last-child {
    border-bottom: 2px solid #a100ff
}

.rad-job-detail__accordion .rad-accordion-atom__toggle {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 1.125rem;
    font-weight: 500;
    gap: 1.5rem;
    justify-content: space-between;
    letter-spacing: -0.02em;
    line-height: 1.4;
    outline-offset: -2px;
    padding: 1rem 0;
    width: 100%
}

.rad-job-detail__accordion .rad-accordion-atom__toggle:lang(ja-JP),.rad-job-detail__accordion .rad-accordion-atom__toggle:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-job-detail__accordion .rad-accordion-atom__toggle {
        font-size:1.25rem;
        line-height: 1.4
    }
}

@media(min-width: 1024px) {
    .rad-job-detail__accordion .rad-accordion-atom__toggle {
        font-size:1.5rem;
        line-height: 1.3
    }
}

@media(min-width: 1441px) {
    .rad-job-detail__accordion .rad-accordion-atom__toggle {
        font-size:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-job-detail__accordion .rad-accordion-atom__toggle {
        font-size:2rem
    }
}

@media(min-width: 600px) {
    .rad-job-detail__accordion .rad-accordion-atom__toggle {
        padding-bottom:1.5rem;
        padding-top: 1.5rem
    }
}

@media(min-width: 1441px) {
    .rad-job-detail__accordion .rad-accordion-atom__toggle {
        padding-bottom:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-job-detail__accordion .rad-accordion-atom__toggle {
        padding-bottom:2rem
    }
}

@media(min-width: 1441px) {
    .rad-job-detail__accordion .rad-accordion-atom__toggle {
        padding-top:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-job-detail__accordion .rad-accordion-atom__toggle {
        padding-top:2rem
    }
}

.rad-job-detail__accordion .rad-accordion-atom__toggle:focus-visible .rad-accordion-atom__toggle-icon {
    outline: none
}

.rad-job-detail__accordion .rad-accordion-atom__toggle-icon {
    height: 1.5rem;
    position: relative;
    width: 1.5rem
}

@media(min-width: 1441px) {
    .rad-job-detail__accordion .rad-accordion-atom__toggle-icon {
        width:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-job-detail__accordion .rad-accordion-atom__toggle-icon {
        width:2rem
    }
}

@media(min-width: 1441px) {
    .rad-job-detail__accordion .rad-accordion-atom__toggle-icon {
        height:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-job-detail__accordion .rad-accordion-atom__toggle-icon {
        height:2rem
    }
}

.rad-job-detail__accordion .rad-accordion-atom__toggle-icon:after,.rad-job-detail__accordion .rad-accordion-atom__toggle-icon:before {
    background-color: #fff;
    content: "";
    display: block;
    height: 2px;
    margin: 0 auto;
    position: absolute;
    top: 50%;
    -webkit-transition-duration: 550ms;
    transition-duration: 550ms;
    -webkit-transition-property: rotate;
    transition-property: rotate;
    -webkit-transition-timing-function: cubic-bezier(0.85,0,0,1);
    transition-timing-function: cubic-bezier(0.85,0,0,1);
    translate: 0 -50%;
    width: 0.875rem
}

@media(min-width: 1441px) {
    .rad-job-detail__accordion .rad-accordion-atom__toggle-icon:after,.rad-job-detail__accordion .rad-accordion-atom__toggle-icon:before {
        height:calc(-0.00557px + 0.13918vw)
    }
}

@media(min-width: 1920px) {
    .rad-job-detail__accordion .rad-accordion-atom__toggle-icon:after,.rad-job-detail__accordion .rad-accordion-atom__toggle-icon:before {
        height:2.6666666667px
    }
}

@media(min-width: 1441px) {
    .rad-job-detail__accordion .rad-accordion-atom__toggle-icon:after,.rad-job-detail__accordion .rad-accordion-atom__toggle-icon:before {
        width:calc(0.875rem + 0.97425vw - 14.03897px)
    }
}

@media(min-width: 1920px) {
    .rad-job-detail__accordion .rad-accordion-atom__toggle-icon:after,.rad-job-detail__accordion .rad-accordion-atom__toggle-icon:before {
        width:1.1666666667rem
    }
}

.rad-job-detail__accordion .rad-accordion-atom__toggle-icon:after {
    rotate: -90deg
}

@media(min-width: 600px) {
    .rad-job-detail__accordion .rad-accordion-atom__toggle-icon {
        margin-right:1.5rem
    }
}

@media(min-width: 1441px) {
    .rad-job-detail__accordion .rad-accordion-atom__toggle-icon {
        margin-right:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-job-detail__accordion .rad-accordion-atom__toggle-icon {
        margin-right:2rem
    }
}

.rad-job-detail__accordion .rad-accordion-atom__toggle--open .rad-accordion-atom__toggle-icon:after {
    rotate: 0deg
}

.rad-job-detail__accordion .rad-accordion-atom__content {
    font-size: .875rem;
    font-weight: 500;
    font-weight: 400;
    letter-spacing: -0.005em;
    line-height: 1.5;
    padding-bottom: 1rem;
    text-underline-offset: .25rem
}

.rad-job-detail__accordion .rad-accordion-atom__content:lang(ja-JP),.rad-job-detail__accordion .rad-accordion-atom__content:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-job-detail__accordion .rad-accordion-atom__content {
        font-size:1rem
    }
}

@media(min-width: 1024px) {
    .rad-job-detail__accordion .rad-accordion-atom__content {
        font-size:1.125rem;
        line-height: 1.6
    }
}

@media(min-width: 1441px) {
    .rad-job-detail__accordion .rad-accordion-atom__content {
        font-size:calc(1.125rem + 1.25261vw - 18.0501px)
    }
}

@media(min-width: 1920px) {
    .rad-job-detail__accordion .rad-accordion-atom__content {
        font-size:1.5rem
    }
}

.rad-job-detail__accordion .rad-accordion-atom__content:lang(ja-JP),.rad-job-detail__accordion .rad-accordion-atom__content:lang(zh-CN) {
    font-weight: 400
}

@media(min-width: 600px) {
    .rad-job-detail__accordion .rad-accordion-atom__content {
        padding-bottom:1.5rem;
        padding-right: calc(25% - var(--rad-spacing-sm)*7/8*2 + var(--rad-spacing-sm)*1 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-job-detail__accordion .rad-accordion-atom__content {
        padding-right:0
    }
}

.rad-job-detail__accordion .rad-accordion-atom__content ol+p,.rad-job-detail__accordion .rad-accordion-atom__content p+p,.rad-job-detail__accordion .rad-accordion-atom__content ul+p {
    margin-top: .5rem
}

@media(min-width: 600px) {
    .rad-job-detail__accordion .rad-accordion-atom__content ol+p,.rad-job-detail__accordion .rad-accordion-atom__content p+p,.rad-job-detail__accordion .rad-accordion-atom__content ul+p {
        margin-top:1rem
    }
}

@media(min-width: 1024px) {
    .rad-job-detail__accordion .rad-accordion-atom__content ol+p,.rad-job-detail__accordion .rad-accordion-atom__content p+p,.rad-job-detail__accordion .rad-accordion-atom__content ul+p {
        margin-top:1.5rem
    }
}

@media(min-width: 1441px) {
    .rad-job-detail__accordion .rad-accordion-atom__content ol+p,.rad-job-detail__accordion .rad-accordion-atom__content p+p,.rad-job-detail__accordion .rad-accordion-atom__content ul+p {
        margin-top:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-job-detail__accordion .rad-accordion-atom__content ol+p,.rad-job-detail__accordion .rad-accordion-atom__content p+p,.rad-job-detail__accordion .rad-accordion-atom__content ul+p {
        margin-top:2rem
    }
}

.rad-job-detail__accordion .rad-accordion-atom__content ol,.rad-job-detail__accordion .rad-accordion-atom__content ul {
    margin-top: .5rem
}

@media(min-width: 600px) {
    .rad-job-detail__accordion .rad-accordion-atom__content ol,.rad-job-detail__accordion .rad-accordion-atom__content ul {
        margin-top:1rem
    }
}

@media(min-width: 1024px) {
    .rad-job-detail__accordion .rad-accordion-atom__content ol,.rad-job-detail__accordion .rad-accordion-atom__content ul {
        margin-top:1.5rem
    }
}

@media(min-width: 1441px) {
    .rad-job-detail__accordion .rad-accordion-atom__content ol,.rad-job-detail__accordion .rad-accordion-atom__content ul {
        margin-top:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-job-detail__accordion .rad-accordion-atom__content ol,.rad-job-detail__accordion .rad-accordion-atom__content ul {
        margin-top:2rem
    }
}

.rad-job-detail__accordion .rad-accordion-atom__content ol li {
    counter-increment: li;
    list-style-type: none;
    padding-left: 30px;
    position: relative
}

@media(min-width: 1441px) {
    .rad-job-detail__accordion .rad-accordion-atom__content ol li {
        padding-left:calc(-0.08351px + 2.08768vw)
    }
}

@media(min-width: 1920px) {
    .rad-job-detail__accordion .rad-accordion-atom__content ol li {
        padding-left:40px
    }
}

.rad-job-detail__accordion .rad-accordion-atom__content ol li:before {
    color: #a100ff;
    content: counter(li) attr(start) ". ";
    left: 0;
    position: absolute
}

.rad-job-detail__accordion .rad-accordion-atom__content ul {
    list-style: none;
    padding-left: 0
}

.rad-job-detail__accordion .rad-accordion-atom__content ul li {
    padding-left: 29px;
    position: relative
}

@media(min-width: 1441px) {
    .rad-job-detail__accordion .rad-accordion-atom__content ul li {
        padding-left:calc(-0.08072px + 2.01809vw)
    }
}

@media(min-width: 1920px) {
    .rad-job-detail__accordion .rad-accordion-atom__content ul li {
        padding-left:38.6666666667px
    }
}

.rad-job-detail__accordion .rad-accordion-atom__content ul li:before {
    background-color: #a100ff;
    content: "";
    display: inline-block;
    height: .375rem;
    left: 0;
    position: absolute;
    text-align: center;
    top: 10px;
    width: .375rem
}

@media(min-width: 1441px) {
    .rad-job-detail__accordion .rad-accordion-atom__content ul li:before {
        top:calc(-0.02784px + 0.69589vw)
    }
}

@media(min-width: 1920px) {
    .rad-job-detail__accordion .rad-accordion-atom__content ul li:before {
        top:13.3333333333px
    }
}

.rad-job-detail__similar-jobs-title {
    font-size: 1.375rem;
    font-weight: 500;
    letter-spacing: -0.02em;
    line-height: 1.25;
    padding-left: 1rem;
    padding-top: 1.5rem
}

.rad-job-detail__similar-jobs-title:lang(ja-JP),.rad-job-detail__similar-jobs-title:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-job-detail__similar-jobs-title {
        font-size:1.75rem;
        line-height: 1.3
    }
}

@media(min-width: 1024px) {
    .rad-job-detail__similar-jobs-title {
        font-size:2rem;
        line-height: 1.2
    }
}

@media(min-width: 1441px) {
    .rad-job-detail__similar-jobs-title {
        font-size:calc(2rem + 2.22686vw - 32.08907px)
    }
}

@media(min-width: 1920px) {
    .rad-job-detail__similar-jobs-title {
        font-size:2.6666666667rem
    }
}

@media(min-width: 600px) {
    .rad-job-detail__similar-jobs-title {
        padding-left:3rem;
        padding-top: 3.75rem
    }
}

@media(min-width: 1024px) {
    .rad-job-detail__similar-jobs-title {
        font-size:1.125rem;
        font-weight: 500;
        letter-spacing: -0.02em;
        line-height: 1.4;
        padding-left: 0;
        padding-top: 0
    }

    .rad-job-detail__similar-jobs-title:lang(ja-JP),.rad-job-detail__similar-jobs-title:lang(zh-CN) {
        font-weight: 700
    }

    .rad-job-detail__similar-jobs-title {
        font-size: 1.25rem;
        font-size: 1.5rem;
        line-height: 1.4;
        line-height: 1.3
    }
}

@media(min-width: 1441px) {
    .rad-job-detail__similar-jobs-title {
        font-size:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-job-detail__similar-jobs-title {
        font-size:2rem
    }
}

@media(min-width: 600px) {
    .rad-job-detail__similar-jobs .rad-content-cards-block {
        padding-top:2.5rem
    }
}

@media(min-width: 1024px) {
    .rad-job-detail__similar-jobs .rad-content-cards-block {
        grid-auto-rows:1fr;
        grid-template-columns: 1fr;
        padding: 2rem 0 0
    }

    .rad-job-detail {
        -webkit-column-gap: calc(8.33333% - var(--rad-spacing-sm)*11/12*1 + var(--rad-spacing-sm)*2 + var(--rad-spacing-md)*0);
        -moz-column-gap: calc(8.33333% - var(--rad-spacing-sm)*11/12*1 + var(--rad-spacing-sm)*2 + var(--rad-spacing-md)*0);
        column-gap: calc(8.33333% - var(--rad-spacing-sm)*11/12*1 + var(--rad-spacing-sm)*2 + var(--rad-spacing-md)*0);
        display: grid;
        grid-template-areas: "primary sidebar";
        grid-template-columns: calc(58.33333% - var(--rad-spacing-sm)*11/12*7 + var(--rad-spacing-sm)*6 + var(--rad-spacing-md)*0) calc(33.33333% - var(--rad-spacing-sm)*11/12*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0);
        padding-left: var(--rad-spacing-sm);
        padding-left: var(--rad-spacing-md);
        padding-right: var(--rad-spacing-sm);
        padding-right: var(--rad-spacing-md)
    }

    .rad-component--nested .rad-job-detail {
        padding-left: 0;
        padding-right: 0
    }

    .rad-job-detail__primary-content {
        grid-area: primary
    }

    .rad-job-detail__similiar-jobs {
        grid-area: sidebar
    }
}

.rad-job-details-hero {
    padding-bottom: 1.5rem;
    padding-left: var(--rad-spacing-sm);
    padding-right: var(--rad-spacing-sm);
    padding-top: 1.5rem
}

@media(min-width: 600px) {
    .rad-job-details-hero {
        padding-left:var(--rad-spacing-md);
        padding-right: var(--rad-spacing-md)
    }
}

.rad-component--nested .rad-job-details-hero {
    padding-left: 0;
    padding-right: 0
}

@media(min-width: 600px) {
    .rad-job-details-hero {
        padding-bottom:3.75rem;
        padding-top: 3.75rem
    }
}

@media(min-width: 1441px) {
    .rad-job-details-hero {
        padding-top:calc(3.75rem + 4.17537vw - 60.16701px)
    }
}

@media(min-width: 1920px) {
    .rad-job-details-hero {
        padding-top:5rem
    }
}

@media(min-width: 1441px) {
    .rad-job-details-hero {
        padding-bottom:calc(3.75rem + 4.17537vw - 60.16701px)
    }
}

@media(min-width: 1920px) {
    .rad-job-details-hero {
        padding-bottom:5rem
    }
}

@media(min-width: 1024px) {
    .rad-job-details-hero {
        display:grid;
        grid-template-areas: "title actions" "job-data ." "cta .";
        grid-template-columns: calc(66.66667% - var(--rad-spacing-sm)*11/12*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0) auto;
        grid-template-rows: auto
    }
}

.rad-job-details-hero__title {
    font-size: 1.75rem;
    font-weight: 500;
    letter-spacing: -0.03em;
    line-height: 1.3;
    padding-bottom: 1rem
}

.rad-job-details-hero__title:lang(ja-JP),.rad-job-details-hero__title:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-job-details-hero__title {
        font-size:2.5rem;
        line-height: 1.2
    }
}

@media(min-width: 1024px) {
    .rad-job-details-hero__title {
        font-size:3rem;
        line-height: 1.2
    }
}

@media(min-width: 1441px) {
    .rad-job-details-hero__title {
        font-size:calc(3rem + 3.34029vw - 48.13361px)
    }
}

@media(min-width: 1920px) {
    .rad-job-details-hero__title {
        font-size:4rem
    }
}

@media(min-width: 1024px) {
    .rad-job-details-hero__title {
        grid-area:title;
        padding-bottom: 2rem
    }
}

.rad-job-details-hero__job-data {
    font-size: .75rem;
    font-weight: 500;
    font-weight: 400;
    letter-spacing: -0.005em;
    line-height: 1.5;
    padding-bottom: 1rem;
    text-underline-offset: .25rem
}

.rad-job-details-hero__job-data:lang(ja-JP),.rad-job-details-hero__job-data:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-job-details-hero__job-data {
        font-size:0.875rem
    }
}

@media(min-width: 1441px) {
    .rad-job-details-hero__job-data {
        font-size:calc(0.875rem + 0.97425vw - 14.03897px)
    }
}

@media(min-width: 1920px) {
    .rad-job-details-hero__job-data {
        font-size:1.1666666667rem
    }
}

.rad-job-details-hero__job-data:lang(ja-JP),.rad-job-details-hero__job-data:lang(zh-CN) {
    font-weight: 400
}

@media(min-width: 1024px) {
    .rad-job-details-hero__job-data {
        grid-area:job-data;
        padding-bottom: 2.5rem
    }
}

.rad-job-details-hero .job-data__divider {
    padding-left: 0.75rem;
    padding-right: 0.75rem
}

@media(min-width: 1441px) {
    .rad-job-details-hero .job-data__divider {
        padding-left:calc(0.75rem + 0.83507vw - 12.0334px)
    }
}

@media(min-width: 1920px) {
    .rad-job-details-hero .job-data__divider {
        padding-left:1rem
    }
}

@media(min-width: 1441px) {
    .rad-job-details-hero .job-data__divider {
        padding-right:calc(0.75rem + 0.83507vw - 12.0334px)
    }
}

@media(min-width: 1920px) {
    .rad-job-details-hero .job-data__divider {
        padding-right:1rem
    }
}

.rad-job-details-hero .job-data__row--two {
    padding-top: 0.75rem
}

@media(min-width: 1441px) {
    .rad-job-details-hero .job-data__row--two {
        padding-top:calc(0.75rem + 0.83507vw - 12.0334px)
    }
}

@media(min-width: 1920px) {
    .rad-job-details-hero .job-data__row--two {
        padding-top:1rem
    }
}

@media(max-width: 599px) {
    .rad-job-details-hero__apply-cta {
        -webkit-box-pack:center;
        -ms-flex-pack: center;
        justify-content: center;
        width: 100%
    }
}

@media(min-width: 1024px) {
    .rad-job-details-hero__apply-cta {
        grid-area:cta
    }
}

.rad-job-details-hero__actions {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-column-gap: .25rem;
    -moz-column-gap: .25rem;
    column-gap: .25rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-bottom: 1rem
}

@media(min-width: 600px) {
    .rad-job-details-hero__actions {
        -webkit-column-gap:.625rem;
        -moz-column-gap: .625rem;
        column-gap: .625rem
    }
}

@media(min-width: 1024px) {
    .rad-job-details-hero__actions {
        grid-area:actions;
        justify-self: end
    }
}

.rad-job-details-hero__share-button {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 0
}

.rad-job-details-hero__share-button[aria-expanded=true] {
    background-color: #101010;
    border-bottom: 2px solid #a100ff;
    border-top: 2px solid transparent;
    color: #a2a2a0
}

.rad-job-details-hero__share-menu.rad-dropdown__list {
    margin-top: 0.5rem;
    right: unset
}

@media(min-width: 1441px) {
    .rad-job-details-hero__share-menu.rad-dropdown__list {
        margin-top:calc(0.5rem + 0.55672vw - 8.02227px)
    }
}

@media(min-width: 1920px) {
    .rad-job-details-hero__share-menu.rad-dropdown__list {
        margin-top:0.6666666667rem
    }
}

@media(min-width: 1024px) {
    .rad-job-details-hero__share-menu.rad-dropdown__list {
        right:0
    }
}

.rad-job-details-hero__share-menu .rad-dropdown__list-item {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-column-gap: 0.5rem;
    -moz-column-gap: 0.5rem;
    column-gap: 0.5rem;
    justify-content: flex-start
}

@media(min-width: 1441px) {
    .rad-job-details-hero__share-menu .rad-dropdown__list-item {
        -webkit-column-gap:calc(0.5rem + 0.55672vw - 8.02227px);
        -moz-column-gap: calc(0.5rem + 0.55672vw - 8.02227px);
        column-gap: calc(0.5rem + 0.55672vw - 8.02227px)
    }
}

@media(min-width: 1920px) {
    .rad-job-details-hero__share-menu .rad-dropdown__list-item {
        -webkit-column-gap:0.6666666667rem;
        -moz-column-gap: 0.6666666667rem;
        column-gap: 0.6666666667rem
    }
}

.rad-stat-helper__container {
    background-color: #310051
}

.rad-stat-helper__wrapper {
    padding-bottom: 1.5rem;
    padding-left: 1.5rem;
    padding-top: 1rem
}

.rad-stat-helper__card-detail {
    font-size: .875rem;
    font-weight: 500;
    font-weight: 400;
    letter-spacing: -0.005em;
    line-height: 1.5;
    text-underline-offset: .25rem
}

.rad-stat-helper__card-detail:lang(ja-JP),.rad-stat-helper__card-detail:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-stat-helper__card-detail {
        font-size:1rem
    }
}

@media(min-width: 1441px) {
    .rad-stat-helper__card-detail {
        font-size:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-stat-helper__card-detail {
        font-size:1.3333333333rem
    }
}

.rad-stat-helper__card-detail:lang(ja-JP),.rad-stat-helper__card-detail:lang(zh-CN) {
    font-weight: 400
}

.rad-stat-helper__title {
    font-size: 1.125rem;
    font-weight: 500;
    letter-spacing: -0.02em;
    line-height: 1.4
}

.rad-stat-helper__title:lang(ja-JP),.rad-stat-helper__title:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-stat-helper__title {
        font-size:1.25rem;
        line-height: 1.4
    }
}

@media(min-width: 1024px) {
    .rad-stat-helper__title {
        font-size:1.5rem;
        line-height: 1.3
    }
}

@media(min-width: 1441px) {
    .rad-stat-helper__title {
        font-size:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-stat-helper__title {
        font-size:2rem
    }
}

@media(min-width: 1024px) {
    .rad-stat-helper__title {
        line-height:1.875rem
    }
}

.rad-stat-helper__header {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 3rem;
    justify-content: space-between;
    margin-bottom: .75rem;
    margin-right: .75rem;
    text-align: left
}

.rad-stat-helper__header-label {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-size: .75rem;
    font-weight: 500;
    letter-spacing: .02em;
    line-height: 1.2;
    padding-right: .5rem
}

.rad-stat-helper__header-label:lang(ja-JP),.rad-stat-helper__header-label:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 1024px) {
    .rad-stat-helper__header-label {
        font-size:0.875rem
    }
}

@media(min-width: 1441px) {
    .rad-stat-helper__header-label {
        font-size:calc(0.875rem + 0.97425vw - 14.03897px)
    }
}

@media(min-width: 1920px) {
    .rad-stat-helper__header-label {
        font-size:1.1666666667rem
    }
}

.rad-stat-helper__accordion-container,.rad-stat-helper__card-text-main,.rad-stat-helper__title {
    padding-right: 1.5rem
}

.rad-stat-helper__card-stat {
    font-size: 1.75rem;
    font-weight: 500;
    letter-spacing: -0.03em;
    line-height: 1.3;
    padding-bottom: .5rem
}

.rad-stat-helper__card-stat:lang(ja-JP),.rad-stat-helper__card-stat:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-stat-helper__card-stat {
        font-size:2.5rem;
        line-height: 1.2
    }
}

@media(min-width: 1024px) {
    .rad-stat-helper__card-stat {
        font-size:3rem;
        line-height: 1.2
    }
}

@media(min-width: 1441px) {
    .rad-stat-helper__card-stat {
        font-size:calc(3rem + 3.34029vw - 48.13361px)
    }
}

@media(min-width: 1920px) {
    .rad-stat-helper__card-stat {
        font-size:4rem
    }
}

@media(min-width: 1024px) {
    .rad-stat-helper__card-stat {
        padding-bottom:.75rem
    }
}

.rad-stat-helper__sublabel {
    font-size: .75rem;
    font-weight: 500;
    grid-column-start: span 2;
    letter-spacing: .02em;
    line-height: 1.2;
    padding-bottom: .75rem;
    width: 100%
}

.rad-stat-helper__sublabel:lang(ja-JP),.rad-stat-helper__sublabel:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 1024px) {
    .rad-stat-helper__sublabel {
        font-size:0.875rem
    }
}

@media(min-width: 1441px) {
    .rad-stat-helper__sublabel {
        font-size:calc(0.875rem + 0.97425vw - 14.03897px)
    }
}

@media(min-width: 1920px) {
    .rad-stat-helper__sublabel {
        font-size:1.1666666667rem
    }
}

@media(min-width: 1024px) {
    .rad-stat-helper__sublabel {
        padding-bottom:1rem
    }
}

.rad-stat-helper__sublabel-one {
    grid-row-start: 1
}

.rad-stat-helper__sublabel-two {
    grid-row-start: 3
}

.rad-stat-helper__acc-content,.rad-stat-helper__no-label,.rad-stat-helper__sublabel-two {
    padding-top: 1.5rem
}

@media(min-width: 1024px) {
    .rad-stat-helper__acc-content,.rad-stat-helper__no-label,.rad-stat-helper__sublabel-two {
        padding-top:2rem
    }
}

.rad-stat-helper__card-substat {
    font-size: 1.25rem;
    font-weight: 500;
    letter-spacing: -0.02em;
    line-height: 1.3;
    padding-bottom: .5rem
}

.rad-stat-helper__card-substat:lang(ja-JP),.rad-stat-helper__card-substat:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-stat-helper__card-substat {
        font-size:1.5rem;
        line-height: 1.3
    }
}

@media(min-width: 1024px) {
    .rad-stat-helper__card-substat {
        font-size:1.75rem;
        line-height: 1.25
    }
}

@media(min-width: 1441px) {
    .rad-stat-helper__card-substat {
        font-size:calc(1.75rem + 1.9485vw - 28.07794px)
    }
}

@media(min-width: 1920px) {
    .rad-stat-helper__card-substat {
        font-size:2.3333333333rem
    }
}

@media(min-width: 1024px) {
    .rad-stat-helper__card-substat {
        padding-bottom:.75rem
    }
}

.rad-stat-helper__card-subdetail {
    font-size: .75rem;
    font-weight: 500;
    font-weight: 400;
    letter-spacing: -0.005em;
    line-height: 1.5;
    text-underline-offset: .25rem
}

.rad-stat-helper__card-subdetail:lang(ja-JP),.rad-stat-helper__card-subdetail:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-stat-helper__card-subdetail {
        font-size:0.875rem
    }
}

@media(min-width: 1441px) {
    .rad-stat-helper__card-subdetail {
        font-size:calc(0.875rem + 0.97425vw - 14.03897px)
    }
}

@media(min-width: 1920px) {
    .rad-stat-helper__card-subdetail {
        font-size:1.1666666667rem
    }
}

.rad-stat-helper__card-subdetail:lang(ja-JP),.rad-stat-helper__card-subdetail:lang(zh-CN) {
    font-weight: 400
}

.rad-stat-helper__acc-content {
    -webkit-column-gap: 1rem;
    -moz-column-gap: 1rem;
    column-gap: 1rem;
    display: grid;
    grid-template-columns: repeat(2,1fr)
}

.rad-stat-helper__card-detail h2,.rad-stat-helper__card-subdetail h2 {
    font-size: 1.75rem;
    font-weight: 500;
    letter-spacing: -0.03em;
    line-height: 1.3
}

.rad-stat-helper__card-detail h2:lang(ja-JP),.rad-stat-helper__card-detail h2:lang(zh-CN),.rad-stat-helper__card-subdetail h2:lang(ja-JP),.rad-stat-helper__card-subdetail h2:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-stat-helper__card-detail h2,.rad-stat-helper__card-subdetail h2 {
        font-size:2.5rem;
        line-height: 1.2
    }
}

@media(min-width: 1024px) {
    .rad-stat-helper__card-detail h2,.rad-stat-helper__card-subdetail h2 {
        font-size:3rem;
        line-height: 1.2
    }
}

@media(min-width: 1441px) {
    .rad-stat-helper__card-detail h2,.rad-stat-helper__card-subdetail h2 {
        font-size:calc(3rem + 3.34029vw - 48.13361px)
    }
}

@media(min-width: 1920px) {
    .rad-stat-helper__card-detail h2,.rad-stat-helper__card-subdetail h2 {
        font-size:4rem
    }
}

.rad-stat-helper__card-detail h2+h2,.rad-stat-helper__card-detail h2+h3,.rad-stat-helper__card-subdetail h2+h2,.rad-stat-helper__card-subdetail h2+h3 {
    margin-top: 1rem
}

.rad-stat-helper__card-detail h2+h4,.rad-stat-helper__card-subdetail h2+h4 {
    margin-top: 1.5rem
}

.rad-stat-helper__card-detail h2+h5,.rad-stat-helper__card-subdetail h2+h5 {
    margin-top: .5rem
}

.rad-stat-helper__card-detail h2+ol,.rad-stat-helper__card-detail h2+p,.rad-stat-helper__card-detail h2+ul,.rad-stat-helper__card-subdetail h2+ol,.rad-stat-helper__card-subdetail h2+p,.rad-stat-helper__card-subdetail h2+ul {
    margin-top: 1.5rem
}

.rad-stat-helper__card-detail h3,.rad-stat-helper__card-subdetail h3 {
    font-size: 1.375rem;
    font-weight: 500;
    letter-spacing: -0.02em;
    line-height: 1.25
}

.rad-stat-helper__card-detail h3:lang(ja-JP),.rad-stat-helper__card-detail h3:lang(zh-CN),.rad-stat-helper__card-subdetail h3:lang(ja-JP),.rad-stat-helper__card-subdetail h3:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-stat-helper__card-detail h3,.rad-stat-helper__card-subdetail h3 {
        font-size:1.75rem;
        line-height: 1.3
    }
}

@media(min-width: 1024px) {
    .rad-stat-helper__card-detail h3,.rad-stat-helper__card-subdetail h3 {
        font-size:2rem;
        line-height: 1.2
    }
}

@media(min-width: 1441px) {
    .rad-stat-helper__card-detail h3,.rad-stat-helper__card-subdetail h3 {
        font-size:calc(2rem + 2.22686vw - 32.08907px)
    }
}

@media(min-width: 1920px) {
    .rad-stat-helper__card-detail h3,.rad-stat-helper__card-subdetail h3 {
        font-size:2.6666666667rem
    }
}

.rad-stat-helper__card-detail h3+h2,.rad-stat-helper__card-subdetail h3+h2 {
    margin-top: 2rem
}

.rad-stat-helper__card-detail h3+h3,.rad-stat-helper__card-subdetail h3+h3 {
    margin-top: 1rem
}

.rad-stat-helper__card-detail h3+h4,.rad-stat-helper__card-subdetail h3+h4 {
    margin-top: .5rem
}

.rad-stat-helper__card-detail h3+h5,.rad-stat-helper__card-subdetail h3+h5 {
    margin-top: 1rem
}

.rad-stat-helper__card-detail h3+ol,.rad-stat-helper__card-detail h3+p,.rad-stat-helper__card-detail h3+ul,.rad-stat-helper__card-subdetail h3+ol,.rad-stat-helper__card-subdetail h3+p,.rad-stat-helper__card-subdetail h3+ul {
    margin-top: 1.5rem
}

.rad-stat-helper__card-detail h4,.rad-stat-helper__card-subdetail h4 {
    font-size: 1.125rem;
    font-weight: 500;
    letter-spacing: -0.02em;
    line-height: 1.4
}

.rad-stat-helper__card-detail h4:lang(ja-JP),.rad-stat-helper__card-detail h4:lang(zh-CN),.rad-stat-helper__card-subdetail h4:lang(ja-JP),.rad-stat-helper__card-subdetail h4:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-stat-helper__card-detail h4,.rad-stat-helper__card-subdetail h4 {
        font-size:1.25rem;
        line-height: 1.4
    }
}

@media(min-width: 1024px) {
    .rad-stat-helper__card-detail h4,.rad-stat-helper__card-subdetail h4 {
        font-size:1.5rem;
        line-height: 1.3
    }
}

@media(min-width: 1441px) {
    .rad-stat-helper__card-detail h4,.rad-stat-helper__card-subdetail h4 {
        font-size:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-stat-helper__card-detail h4,.rad-stat-helper__card-subdetail h4 {
        font-size:2rem
    }
}

.rad-stat-helper__card-detail h4+h2,.rad-stat-helper__card-detail h4+h3,.rad-stat-helper__card-subdetail h4+h2,.rad-stat-helper__card-subdetail h4+h3 {
    margin-top: 2rem
}

.rad-stat-helper__card-detail h4+h4,.rad-stat-helper__card-subdetail h4+h4 {
    margin-top: .5rem
}

.rad-stat-helper__card-detail h4+h5,.rad-stat-helper__card-subdetail h4+h5 {
    margin-top: 2rem
}

.rad-stat-helper__card-detail h4+ol,.rad-stat-helper__card-detail h4+p,.rad-stat-helper__card-detail h4+ul,.rad-stat-helper__card-subdetail h4+ol,.rad-stat-helper__card-subdetail h4+p,.rad-stat-helper__card-subdetail h4+ul {
    margin-top: .5rem
}

.rad-stat-helper__card-detail h5,.rad-stat-helper__card-subdetail h5 {
    font-family: GT Sectra Fine,Palatino;
    font-size: 1.25rem;
    font-weight: 300;
    line-height: 1.25
}

@media(min-width: 600px) {
    .rad-stat-helper__card-detail h5,.rad-stat-helper__card-subdetail h5 {
        font-size:1.5rem;
        line-height: 1.25
    }
}

@media(min-width: 1024px) {
    .rad-stat-helper__card-detail h5,.rad-stat-helper__card-subdetail h5 {
        font-size:1.75rem;
        line-height: 1.25
    }
}

@media(min-width: 1441px) {
    .rad-stat-helper__card-detail h5,.rad-stat-helper__card-subdetail h5 {
        font-size:calc(1.75rem + 1.9485vw - 28.07794px)
    }
}

@media(min-width: 1920px) {
    .rad-stat-helper__card-detail h5,.rad-stat-helper__card-subdetail h5 {
        font-size:2.3333333333rem
    }
}

.rad-stat-helper__card-detail h5+h2,.rad-stat-helper__card-subdetail h5+h2 {
    margin-top: 2rem
}

.rad-stat-helper__card-detail h5+h3,.rad-stat-helper__card-detail h5+h4,.rad-stat-helper__card-subdetail h5+h3,.rad-stat-helper__card-subdetail h5+h4 {
    margin-top: 1.5rem
}

.rad-stat-helper__card-detail h5+h5,.rad-stat-helper__card-subdetail h5+h5 {
    margin-top: 1rem
}

.rad-stat-helper__card-detail h5+ol,.rad-stat-helper__card-detail h5+p,.rad-stat-helper__card-detail h5+ul,.rad-stat-helper__card-subdetail h5+ol,.rad-stat-helper__card-subdetail h5+p,.rad-stat-helper__card-subdetail h5+ul {
    margin-top: 1.5rem
}

.rad-stat-helper__card-detail ol,.rad-stat-helper__card-detail p,.rad-stat-helper__card-detail ul,.rad-stat-helper__card-subdetail ol,.rad-stat-helper__card-subdetail p,.rad-stat-helper__card-subdetail ul {
    padding-right: var(--scrollbar-width)
}

.rad-stat-helper__card-detail ol a,.rad-stat-helper__card-detail p a,.rad-stat-helper__card-detail ul a,.rad-stat-helper__card-subdetail ol a,.rad-stat-helper__card-subdetail p a,.rad-stat-helper__card-subdetail ul a {
    font-weight: 500
}

.rad-stat-helper__card-detail ol a:focus-visible,.rad-stat-helper__card-detail p a:focus-visible,.rad-stat-helper__card-detail ul a:focus-visible,.rad-stat-helper__card-subdetail ol a:focus-visible,.rad-stat-helper__card-subdetail p a:focus-visible,.rad-stat-helper__card-subdetail ul a:focus-visible {
    outline-offset: 2px
}

.rad-stat-helper__card-detail ol+h2,.rad-stat-helper__card-detail ol+h3,.rad-stat-helper__card-detail ol+h4,.rad-stat-helper__card-detail ol+h5,.rad-stat-helper__card-detail p+h2,.rad-stat-helper__card-detail p+h3,.rad-stat-helper__card-detail p+h4,.rad-stat-helper__card-detail p+h5,.rad-stat-helper__card-detail ul+h2,.rad-stat-helper__card-detail ul+h3,.rad-stat-helper__card-detail ul+h4,.rad-stat-helper__card-detail ul+h5,.rad-stat-helper__card-subdetail ol+h2,.rad-stat-helper__card-subdetail ol+h3,.rad-stat-helper__card-subdetail ol+h4,.rad-stat-helper__card-subdetail ol+h5,.rad-stat-helper__card-subdetail p+h2,.rad-stat-helper__card-subdetail p+h3,.rad-stat-helper__card-subdetail p+h4,.rad-stat-helper__card-subdetail p+h5,.rad-stat-helper__card-subdetail ul+h2,.rad-stat-helper__card-subdetail ul+h3,.rad-stat-helper__card-subdetail ul+h4,.rad-stat-helper__card-subdetail ul+h5 {
    margin-top: 2rem
}

.rad-stat-helper__card-detail ol+ol,.rad-stat-helper__card-detail ol+p,.rad-stat-helper__card-detail ol+ul,.rad-stat-helper__card-detail p+ol,.rad-stat-helper__card-detail p+p,.rad-stat-helper__card-detail p+ul,.rad-stat-helper__card-detail ul+ol,.rad-stat-helper__card-detail ul+p,.rad-stat-helper__card-detail ul+ul,.rad-stat-helper__card-subdetail ol+ol,.rad-stat-helper__card-subdetail ol+p,.rad-stat-helper__card-subdetail ol+ul,.rad-stat-helper__card-subdetail p+ol,.rad-stat-helper__card-subdetail p+p,.rad-stat-helper__card-subdetail p+ul,.rad-stat-helper__card-subdetail ul+ol,.rad-stat-helper__card-subdetail ul+p,.rad-stat-helper__card-subdetail ul+ul {
    margin-top: .5rem
}

.rad-stat-helper__card-detail ol li,.rad-stat-helper__card-subdetail ol li {
    counter-increment: li;
    list-style-type: none;
    padding-left: 30px;
    position: relative
}

@media(min-width: 1441px) {
    .rad-stat-helper__card-detail ol li,.rad-stat-helper__card-subdetail ol li {
        padding-left:calc(-0.08351px + 2.08768vw)
    }
}

@media(min-width: 1920px) {
    .rad-stat-helper__card-detail ol li,.rad-stat-helper__card-subdetail ol li {
        padding-left:40px
    }
}

.rad-stat-helper__card-detail ol li:before,.rad-stat-helper__card-subdetail ol li:before {
    color: #a100ff;
    content: counter(li) attr(start) ". ";
    left: 0;
    position: absolute
}

.rad-stat-helper__card-detail ul,.rad-stat-helper__card-subdetail ul {
    list-style: none;
    padding-left: 0
}

.rad-stat-helper__card-detail ul li,.rad-stat-helper__card-subdetail ul li {
    padding-left: 29px;
    position: relative
}

@media(min-width: 1441px) {
    .rad-stat-helper__card-detail ul li,.rad-stat-helper__card-subdetail ul li {
        padding-left:calc(-0.08072px + 2.01809vw)
    }
}

@media(min-width: 1920px) {
    .rad-stat-helper__card-detail ul li,.rad-stat-helper__card-subdetail ul li {
        padding-left:38.6666666667px
    }
}

.rad-stat-helper__card-detail ul li:before,.rad-stat-helper__card-subdetail ul li:before {
    background-color: #a100ff;
    content: "";
    display: inline-block;
    height: .375rem;
    left: 0;
    position: absolute;
    text-align: center;
    top: 10px;
    width: .375rem
}

@media(min-width: 1441px) {
    .rad-stat-helper__card-detail ul li:before,.rad-stat-helper__card-subdetail ul li:before {
        top:calc(-0.02784px + 0.69589vw)
    }
}

@media(min-width: 1920px) {
    .rad-stat-helper__card-detail ul li:before,.rad-stat-helper__card-subdetail ul li:before {
        top:13.3333333333px
    }
}

@media(min-width: 600px) {
    .rad-stat-helper__card-detail h2+h2,.rad-stat-helper__card-detail h2+h3,.rad-stat-helper__card-subdetail h2+h2,.rad-stat-helper__card-subdetail h2+h3 {
        margin-top:1.5rem
    }

    .rad-stat-helper__card-detail h2+h4,.rad-stat-helper__card-subdetail h2+h4 {
        margin-top: 2rem
    }

    .rad-stat-helper__card-detail h2+h5,.rad-stat-helper__card-subdetail h2+h5 {
        margin-top: .5rem
    }

    .rad-stat-helper__card-detail h2+ol,.rad-stat-helper__card-detail h2+p,.rad-stat-helper__card-detail h2+ul,.rad-stat-helper__card-subdetail h2+ol,.rad-stat-helper__card-subdetail h2+p,.rad-stat-helper__card-subdetail h2+ul {
        margin-top: 2rem
    }

    .rad-stat-helper__card-detail h3+h2,.rad-stat-helper__card-subdetail h3+h2 {
        margin-top: 3rem
    }

    .rad-stat-helper__card-detail h3+h3,.rad-stat-helper__card-subdetail h3+h3 {
        margin-top: 1.5rem
    }

    .rad-stat-helper__card-detail h3+h4,.rad-stat-helper__card-subdetail h3+h4 {
        margin-top: .75rem
    }

    .rad-stat-helper__card-detail h3+h5,.rad-stat-helper__card-subdetail h3+h5 {
        margin-top: 1.5rem
    }

    .rad-stat-helper__card-detail h3+ol,.rad-stat-helper__card-detail h3+p,.rad-stat-helper__card-detail h3+ul,.rad-stat-helper__card-subdetail h3+ol,.rad-stat-helper__card-subdetail h3+p,.rad-stat-helper__card-subdetail h3+ul {
        margin-top: 2rem
    }

    .rad-stat-helper__card-detail h4+h2,.rad-stat-helper__card-detail h4+h3,.rad-stat-helper__card-subdetail h4+h2,.rad-stat-helper__card-subdetail h4+h3 {
        margin-top: 3rem
    }

    .rad-stat-helper__card-detail h4+h4,.rad-stat-helper__card-subdetail h4+h4 {
        margin-top: 1rem
    }

    .rad-stat-helper__card-detail h4+h5,.rad-stat-helper__card-subdetail h4+h5 {
        margin-top: 3rem
    }

    .rad-stat-helper__card-detail h4+p,.rad-stat-helper__card-subdetail h4+p {
        margin-top: .75rem
    }

    .rad-stat-helper__card-detail h4+ol,.rad-stat-helper__card-detail h4+ul,.rad-stat-helper__card-subdetail h4+ol,.rad-stat-helper__card-subdetail h4+ul {
        margin-top: 1rem
    }

    .rad-stat-helper__card-detail h5+h2,.rad-stat-helper__card-subdetail h5+h2 {
        margin-top: 3rem
    }

    .rad-stat-helper__card-detail h5+h3,.rad-stat-helper__card-subdetail h5+h3 {
        margin-top: 2.5rem
    }

    .rad-stat-helper__card-detail h5+h4,.rad-stat-helper__card-subdetail h5+h4 {
        margin-top: 2rem
    }

    .rad-stat-helper__card-detail h5+h5,.rad-stat-helper__card-subdetail h5+h5 {
        margin-top: 1.5rem
    }

    .rad-stat-helper__card-detail h5+ol,.rad-stat-helper__card-detail h5+p,.rad-stat-helper__card-detail h5+ul,.rad-stat-helper__card-subdetail h5+ol,.rad-stat-helper__card-subdetail h5+p,.rad-stat-helper__card-subdetail h5+ul {
        margin-top: 2rem
    }

    .rad-stat-helper__card-detail ol,.rad-stat-helper__card-detail p,.rad-stat-helper__card-detail ul,.rad-stat-helper__card-subdetail ol,.rad-stat-helper__card-subdetail p,.rad-stat-helper__card-subdetail ul {
        padding-right: 0
    }

    .rad-stat-helper__card-detail ol+h2,.rad-stat-helper__card-detail ol+h3,.rad-stat-helper__card-detail ol+h4,.rad-stat-helper__card-detail ol+h5,.rad-stat-helper__card-detail p+h2,.rad-stat-helper__card-detail p+h3,.rad-stat-helper__card-detail p+h4,.rad-stat-helper__card-detail p+h5,.rad-stat-helper__card-detail ul+h2,.rad-stat-helper__card-detail ul+h3,.rad-stat-helper__card-detail ul+h4,.rad-stat-helper__card-detail ul+h5,.rad-stat-helper__card-subdetail ol+h2,.rad-stat-helper__card-subdetail ol+h3,.rad-stat-helper__card-subdetail ol+h4,.rad-stat-helper__card-subdetail ol+h5,.rad-stat-helper__card-subdetail p+h2,.rad-stat-helper__card-subdetail p+h3,.rad-stat-helper__card-subdetail p+h4,.rad-stat-helper__card-subdetail p+h5,.rad-stat-helper__card-subdetail ul+h2,.rad-stat-helper__card-subdetail ul+h3,.rad-stat-helper__card-subdetail ul+h4,.rad-stat-helper__card-subdetail ul+h5 {
        margin-top: 3rem
    }

    .rad-stat-helper__card-detail ol+ol,.rad-stat-helper__card-detail ol+p,.rad-stat-helper__card-detail ol+ul,.rad-stat-helper__card-detail p+ol,.rad-stat-helper__card-detail p+p,.rad-stat-helper__card-detail p+ul,.rad-stat-helper__card-detail ul+ol,.rad-stat-helper__card-detail ul+p,.rad-stat-helper__card-detail ul+ul,.rad-stat-helper__card-subdetail ol+ol,.rad-stat-helper__card-subdetail ol+p,.rad-stat-helper__card-subdetail ol+ul,.rad-stat-helper__card-subdetail p+ol,.rad-stat-helper__card-subdetail p+p,.rad-stat-helper__card-subdetail p+ul,.rad-stat-helper__card-subdetail ul+ol,.rad-stat-helper__card-subdetail ul+p,.rad-stat-helper__card-subdetail ul+ul {
        margin-top: 1rem
    }

    .rad-stat-helper__card-detail ol li:before,.rad-stat-helper__card-detail ul li:before,.rad-stat-helper__card-subdetail ol li:before,.rad-stat-helper__card-subdetail ul li:before {
        height: .5rem;
        width: .5rem
    }
}

@media(min-width: 1024px) {
    .rad-stat-helper__card-detail h2+h2,.rad-stat-helper__card-detail h2+h3,.rad-stat-helper__card-subdetail h2+h2,.rad-stat-helper__card-subdetail h2+h3 {
        margin-top:2rem
    }

    .rad-stat-helper__card-detail h2+h4,.rad-stat-helper__card-subdetail h2+h4 {
        margin-top: 3rem
    }

    .rad-stat-helper__card-detail h2+h5,.rad-stat-helper__card-subdetail h2+h5 {
        margin-top: .5rem
    }

    .rad-stat-helper__card-detail h2+ol,.rad-stat-helper__card-detail h2+p,.rad-stat-helper__card-detail h2+ul,.rad-stat-helper__card-subdetail h2+ol,.rad-stat-helper__card-subdetail h2+p,.rad-stat-helper__card-subdetail h2+ul {
        margin-top: 3rem
    }

    .rad-stat-helper__card-detail h3+h2,.rad-stat-helper__card-subdetail h3+h2 {
        margin-top: 4rem
    }

    .rad-stat-helper__card-detail h3+h3,.rad-stat-helper__card-subdetail h3+h3 {
        margin-top: 2rem
    }

    .rad-stat-helper__card-detail h3+h4,.rad-stat-helper__card-subdetail h3+h4 {
        margin-top: 1rem
    }

    .rad-stat-helper__card-detail h3+h5,.rad-stat-helper__card-subdetail h3+h5 {
        margin-top: 2rem
    }

    .rad-stat-helper__card-detail h3+ol,.rad-stat-helper__card-detail h3+p,.rad-stat-helper__card-detail h3+ul,.rad-stat-helper__card-subdetail h3+ol,.rad-stat-helper__card-subdetail h3+p,.rad-stat-helper__card-subdetail h3+ul {
        margin-top: 3rem
    }

    .rad-stat-helper__card-detail h4+h2,.rad-stat-helper__card-detail h4+h3,.rad-stat-helper__card-subdetail h4+h2,.rad-stat-helper__card-subdetail h4+h3 {
        margin-top: 4rem
    }

    .rad-stat-helper__card-detail h4+h4,.rad-stat-helper__card-subdetail h4+h4 {
        margin-top: 1.5rem
    }

    .rad-stat-helper__card-detail h4+h5,.rad-stat-helper__card-subdetail h4+h5 {
        margin-top: 4rem
    }

    .rad-stat-helper__card-detail h4+p,.rad-stat-helper__card-subdetail h4+p {
        margin-top: 1rem
    }

    .rad-stat-helper__card-detail h4+ol,.rad-stat-helper__card-detail h4+ul,.rad-stat-helper__card-subdetail h4+ol,.rad-stat-helper__card-subdetail h4+ul {
        margin-top: 1.5rem
    }

    .rad-stat-helper__card-detail h5+h2,.rad-stat-helper__card-subdetail h5+h2 {
        margin-top: 4rem
    }

    .rad-stat-helper__card-detail h5+h3,.rad-stat-helper__card-detail h5+h4,.rad-stat-helper__card-subdetail h5+h3,.rad-stat-helper__card-subdetail h5+h4 {
        margin-top: 3rem
    }

    .rad-stat-helper__card-detail h5+h5,.rad-stat-helper__card-subdetail h5+h5 {
        margin-top: 2rem
    }

    .rad-stat-helper__card-detail h5+ol,.rad-stat-helper__card-detail h5+p,.rad-stat-helper__card-detail h5+ul,.rad-stat-helper__card-subdetail h5+ol,.rad-stat-helper__card-subdetail h5+p,.rad-stat-helper__card-subdetail h5+ul {
        margin-top: 3rem
    }

    .rad-stat-helper__card-detail ol+h2,.rad-stat-helper__card-detail ol+h3,.rad-stat-helper__card-detail ol+h4,.rad-stat-helper__card-detail ol+h5,.rad-stat-helper__card-detail p+h2,.rad-stat-helper__card-detail p+h3,.rad-stat-helper__card-detail p+h4,.rad-stat-helper__card-detail p+h5,.rad-stat-helper__card-detail ul+h2,.rad-stat-helper__card-detail ul+h3,.rad-stat-helper__card-detail ul+h4,.rad-stat-helper__card-detail ul+h5,.rad-stat-helper__card-subdetail ol+h2,.rad-stat-helper__card-subdetail ol+h3,.rad-stat-helper__card-subdetail ol+h4,.rad-stat-helper__card-subdetail ol+h5,.rad-stat-helper__card-subdetail p+h2,.rad-stat-helper__card-subdetail p+h3,.rad-stat-helper__card-subdetail p+h4,.rad-stat-helper__card-subdetail p+h5,.rad-stat-helper__card-subdetail ul+h2,.rad-stat-helper__card-subdetail ul+h3,.rad-stat-helper__card-subdetail ul+h4,.rad-stat-helper__card-subdetail ul+h5 {
        margin-top: 4rem
    }

    .rad-stat-helper__card-detail ol+ol,.rad-stat-helper__card-detail ol+p,.rad-stat-helper__card-detail ol+ul,.rad-stat-helper__card-detail p+ol,.rad-stat-helper__card-detail p+p,.rad-stat-helper__card-detail p+ul,.rad-stat-helper__card-detail ul+ol,.rad-stat-helper__card-detail ul+p,.rad-stat-helper__card-detail ul+ul,.rad-stat-helper__card-subdetail ol+ol,.rad-stat-helper__card-subdetail ol+p,.rad-stat-helper__card-subdetail ol+ul,.rad-stat-helper__card-subdetail p+ol,.rad-stat-helper__card-subdetail p+p,.rad-stat-helper__card-subdetail p+ul,.rad-stat-helper__card-subdetail ul+ol,.rad-stat-helper__card-subdetail ul+p,.rad-stat-helper__card-subdetail ul+ul {
        margin-top: 1.5rem
    }
}

.rad-stat-helper__btn {
    -ms-flex-line-pack: center;
    align-content: center;
    display: grid
}

.rad-stat-helper__btn .rad-icon,.rad-stat-helper__btn:after,.rad-stat-helper__btn:before {
    grid-area: button
}

.rad-stat-helper__btn:after,.rad-stat-helper__btn:before {
    background-color: currentcolor;
    content: "";
    display: block;
    height: 1.5px;
    justify-self: center;
    -webkit-transition-duration: 550ms;
    transition-duration: 550ms;
    -webkit-transition-property: rotate,visibility;
    transition-property: rotate,visibility;
    -webkit-transition-timing-function: cubic-bezier(0.85,0,0,1);
    transition-timing-function: cubic-bezier(0.85,0,0,1);
    visibility: visible;
    width: 12px;
    z-index: 1
}

@media(min-width: 1441px) {
    .rad-stat-helper__btn:after,.rad-stat-helper__btn:before {
        height:calc(-0.00418px + 0.10438vw)
    }
}

@media(min-width: 1920px) {
    .rad-stat-helper__btn:after,.rad-stat-helper__btn:before {
        height:2px
    }
}

@media(min-width: 1441px) {
    .rad-stat-helper__btn:after,.rad-stat-helper__btn:before {
        width:calc(-0.0334px + 0.83507vw)
    }
}

@media(min-width: 1920px) {
    .rad-stat-helper__btn:after,.rad-stat-helper__btn:before {
        width:16px
    }
}

.rad-stat-helper__btn:after {
    rotate: -90deg
}

.rad-stat-helper__btn.rad-accordion-atom__toggle--open:after {
    rotate: 0deg;
    visibility: hidden
}

.rad-layout-division .rad-stat-helper {
    padding: 0
}

.rad-saved-jobs__job-cards {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: var(--rad-spacing-sm);
    padding-right: var(--rad-spacing-sm);
    row-gap: 1rem
}

@media(min-width: 600px) {
    .rad-saved-jobs__job-cards {
        padding-left:var(--rad-spacing-md);
        padding-right: var(--rad-spacing-md)
    }
}

.rad-component--nested .rad-saved-jobs__job-cards {
    padding-left: 0;
    padding-right: 0
}

@media(min-width: 600px) {
    .rad-saved-jobs__job-cards {
        row-gap:1.5rem
    }
}

@media(min-width: 1441px) {
    .rad-saved-jobs__job-cards {
        row-gap:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-saved-jobs__job-cards {
        row-gap:2rem
    }
}

@media(min-width: 1024px) {
    .rad-saved-jobs__job-cards {
        -webkit-box-align:end;
        -ms-flex-align: end;
        align-items: flex-end
    }

    .rad-saved-jobs__job-cards .rad-filters-vertical__job-card {
        width: calc(200% - var(--rad-spacing-sm)*3/4*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-sm)*0);
        width: calc(100% - var(--rad-spacing-sm)*7/8*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0);
        width: calc(66.66667% - var(--rad-spacing-sm)*11/12*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0)
    }
}

.rad-saved-jobs__job-cards .rad-discard-job-button {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start
}

.rad-saved-jobs__pagination {
    padding-left: var(--rad-spacing-sm);
    padding-right: var(--rad-spacing-sm)
}

@media(min-width: 600px) {
    .rad-saved-jobs__pagination {
        padding-left:var(--rad-spacing-md);
        padding-right: var(--rad-spacing-md)
    }
}

.rad-component--nested .rad-saved-jobs__pagination {
    padding-left: 0;
    padding-right: 0
}

@media(min-width: 600px) {
    .rad-saved-jobs__pagination {
        -webkit-box-pack:end;
        -ms-flex-pack: end;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        justify-content: flex-end
    }
}

.rad-saved-jobs__pagination--hidden {
    display: none
}

.rad-saved-jobs .rad-pagination--initialized {
    margin-top: 1rem
}

@media(min-width: 600px) {
    .rad-saved-jobs .rad-pagination--initialized {
        -ms-flex-item-align:end;
        align-self: flex-end;
        margin-top: 1.5rem
    }
}

@media(min-width: 1024px) {
    .rad-saved-jobs .rad-pagination--initialized {
        margin-top:2rem
    }
}

.rad-saved-jobs__no-results--visible {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.rad-saved-jobs__no-results .no-results__title {
    font-size: 1.375rem;
    font-weight: 500;
    letter-spacing: -0.02em;
    line-height: 1.25;
    margin-bottom: 1.5rem
}

.rad-saved-jobs__no-results .no-results__title:lang(ja-JP),.rad-saved-jobs__no-results .no-results__title:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-saved-jobs__no-results .no-results__title {
        font-size:1.75rem;
        line-height: 1.3
    }
}

@media(min-width: 1024px) {
    .rad-saved-jobs__no-results .no-results__title {
        font-size:2rem;
        line-height: 1.2
    }
}

@media(min-width: 1441px) {
    .rad-saved-jobs__no-results .no-results__title {
        font-size:calc(2rem + 2.22686vw - 32.08907px)
    }
}

@media(min-width: 1920px) {
    .rad-saved-jobs__no-results .no-results__title {
        font-size:2.6666666667rem
    }
}

@media(min-width: 1024px) {
    .rad-saved-jobs__no-results .no-results__title {
        margin-bottom:1rem
    }
}

.rad-saved-jobs__no-results .no-results__message {
    font-size: .875rem;
    font-weight: 500;
    font-weight: 400;
    letter-spacing: -0.005em;
    line-height: 1.5;
    margin-bottom: 1.5rem;
    text-underline-offset: .25rem
}

.rad-saved-jobs__no-results .no-results__message:lang(ja-JP),.rad-saved-jobs__no-results .no-results__message:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-saved-jobs__no-results .no-results__message {
        font-size:1rem
    }
}

@media(min-width: 1441px) {
    .rad-saved-jobs__no-results .no-results__message {
        font-size:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-saved-jobs__no-results .no-results__message {
        font-size:1.3333333333rem
    }
}

.rad-saved-jobs__no-results .no-results__message:lang(ja-JP),.rad-saved-jobs__no-results .no-results__message:lang(zh-CN) {
    font-weight: 400
}

.rad-storytelling-hero {
    color: #fff;
    overflow: hidden;
    position: relative
}

.rad-storytelling-hero__date-and-btn-container,.rad-storytelling-hero__eyebrow-container,.rad-storytelling-hero__headline-container {
    background-color: #000;
    padding-left: var(--rad-spacing-sm);
    padding-right: var(--rad-spacing-sm);
    position: relative;
    z-index: 100
}

@media(min-width: 600px) {
    .rad-storytelling-hero__date-and-btn-container,.rad-storytelling-hero__eyebrow-container,.rad-storytelling-hero__headline-container {
        padding-left:var(--rad-spacing-md);
        padding-right: var(--rad-spacing-md)
    }
}

.rad-component--nested .rad-storytelling-hero__date-and-btn-container,.rad-component--nested .rad-storytelling-hero__eyebrow-container,.rad-component--nested .rad-storytelling-hero__headline-container {
    padding-left: 0;
    padding-right: 0
}

.rad-storytelling-hero__eyebrow {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    font-size: .75rem;
    font-weight: 500;
    gap: 1rem;
    justify-content: flex-start;
    letter-spacing: .02em;
    line-height: 1.2;
    padding-bottom: 1rem;
    text-transform: uppercase;
    width: calc(100% - var(--rad-spacing-sm)*3/4*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-sm)*0)
}

.rad-storytelling-hero__eyebrow:lang(ja-JP),.rad-storytelling-hero__eyebrow:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 1024px) {
    .rad-storytelling-hero__eyebrow {
        font-size:0.875rem
    }
}

@media(min-width: 1441px) {
    .rad-storytelling-hero__eyebrow {
        font-size:calc(0.875rem + 0.97425vw - 14.03897px)
    }
}

@media(min-width: 1920px) {
    .rad-storytelling-hero__eyebrow {
        font-size:1.1666666667rem
    }
}

@media(min-width: 600px) {
    .rad-storytelling-hero__eyebrow {
        width:calc(50% - var(--rad-spacing-sm)*7/8*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-storytelling-hero__eyebrow {
        width:calc(33.33333% - var(--rad-spacing-sm)*11/12*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 600px) {
    .rad-storytelling-hero__eyebrow {
        gap:1.5rem;
        padding-bottom: 1.5rem;
        width: calc(150% - var(--rad-spacing-sm)*3/4*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-sm)*0);
        width: calc(75% - var(--rad-spacing-sm)*7/8*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-storytelling-hero__eyebrow {
        gap:2rem;
        padding-bottom: 2rem;
        width: calc(50% - var(--rad-spacing-sm)*11/12*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0);
        width: calc(225% - var(--rad-spacing-sm)*3/4*9 + var(--rad-spacing-sm)*8 + var(--rad-spacing-sm)*0);
        width: calc(112.5% - var(--rad-spacing-sm)*7/8*9 + var(--rad-spacing-sm)*8 + var(--rad-spacing-md)*0);
        width: calc(75% - var(--rad-spacing-sm)*11/12*9 + var(--rad-spacing-sm)*8 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1441px) {
    .rad-storytelling-hero__eyebrow {
        gap:calc(2rem + 2.22686vw - 32.08907px)
    }
}

@media(min-width: 1920px) {
    .rad-storytelling-hero__eyebrow {
        gap:2.6666666667rem
    }
}

@media(min-width: 1441px) {
    .rad-storytelling-hero__eyebrow {
        padding-bottom:calc(2rem + 2.22686vw - 32.08907px)
    }
}

@media(min-width: 1920px) {
    .rad-storytelling-hero__eyebrow {
        padding-bottom:2.6666666667rem
    }
}

.rad-storytelling-hero__eyebrow-container {
    padding-top: 1.5rem
}

@media(min-width: 600px) {
    .rad-storytelling-hero__eyebrow-container {
        padding-top:3.75rem
    }
}

@media(min-width: 1441px) {
    .rad-storytelling-hero__eyebrow-container {
        padding-top:calc(3.75rem + 4.17537vw - 60.16701px)
    }
}

@media(min-width: 1920px) {
    .rad-storytelling-hero__eyebrow-container {
        padding-top:5rem
    }
}

.rad-storytelling-hero__headline {
    color: #fff;
    font-size: 2rem;
    font-weight: 500;
    letter-spacing: -0.03em;
    line-height: 1.25
}

.rad-storytelling-hero__headline:lang(ja-JP),.rad-storytelling-hero__headline:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-storytelling-hero__headline {
        font-size:3.75rem;
        line-height: 1.15
    }
}

@media(min-width: 1441px) {
    .rad-storytelling-hero__headline {
        font-size:calc(3.75rem + 4.17537vw - 60.16701px)
    }
}

@media(min-width: 1920px) {
    .rad-storytelling-hero__headline {
        font-size:5rem
    }
}

@media(min-width: 600px) {
    .rad-storytelling-hero__headline {
        padding-bottom:.5rem
    }
}

@media(min-width: 1024px) {
    .rad-storytelling-hero__headline {
        padding-bottom:1rem;
        width: calc(225% - var(--rad-spacing-sm)*3/4*9 + var(--rad-spacing-sm)*8 + var(--rad-spacing-sm)*0);
        width: calc(112.5% - var(--rad-spacing-sm)*7/8*9 + var(--rad-spacing-sm)*8 + var(--rad-spacing-md)*0);
        width: calc(75% - var(--rad-spacing-sm)*11/12*9 + var(--rad-spacing-sm)*8 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1441px) {
    .rad-storytelling-hero__headline {
        padding-bottom:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-storytelling-hero__headline {
        padding-bottom:1.3333333333rem
    }
}

.rad-storytelling-hero__headline-container {
    mix-blend-mode: multiply
}

.rad-storytelling-hero__text--white-overlay {
    position: relative
}

.rad-storytelling-hero__text--white-overlay:after {
    background-color: hsla(0,0%,100%,.5);
    bottom: 0;
    content: "";
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 10
}

.rad-storytelling-hero__date-and-btn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.rad-storytelling-hero__date-and-btn-container {
    padding-bottom: .5rem
}

@media(min-width: 600px) {
    .rad-storytelling-hero__date-and-btn-container {
        padding-bottom:2.5rem
    }
}

@media(min-width: 1441px) {
    .rad-storytelling-hero__date-and-btn-container {
        padding-bottom:calc(2.5rem + 2.78358vw - 40.11134px)
    }
}

@media(min-width: 1920px) {
    .rad-storytelling-hero__date-and-btn-container {
        padding-bottom:3.3333333333rem
    }
}

.rad-storytelling-hero__time-and-date {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-size: .75rem;
    font-weight: 500;
    letter-spacing: .02em;
    line-height: 1.2;
    margin: auto;
    min-height: 48px;
    text-transform: uppercase;
    width: 100%
}

@media(min-width: 1441px) {
    .rad-storytelling-hero__time-and-date {
        min-height:calc(-0.13361px + 3.34029vw)
    }
}

@media(min-width: 1920px) {
    .rad-storytelling-hero__time-and-date {
        min-height:64px
    }
}

.rad-storytelling-hero__time-and-date:lang(ja-JP),.rad-storytelling-hero__time-and-date:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 1024px) {
    .rad-storytelling-hero__time-and-date {
        font-size:0.875rem
    }
}

@media(min-width: 1441px) {
    .rad-storytelling-hero__time-and-date {
        font-size:calc(0.875rem + 0.97425vw - 14.03897px)
    }
}

@media(min-width: 1920px) {
    .rad-storytelling-hero__time-and-date {
        font-size:1.1666666667rem
    }
}

.rad-storytelling-hero__time-and-date .rad-storytelling-hero__publish-date,.rad-storytelling-hero__time-and-date .rad-storytelling-hero__read-time {
    display: inline-block;
    margin-bottom: auto;
    margin-top: auto;
    padding-right: 16px
}

@media(min-width: 600px) {
    .rad-storytelling-hero__time-and-date .rad-storytelling-hero__publish-date,.rad-storytelling-hero__time-and-date .rad-storytelling-hero__read-time {
        padding-right:24px
    }
}

@media(min-width: 1024px) {
    .rad-storytelling-hero__time-and-date .rad-storytelling-hero__publish-date,.rad-storytelling-hero__time-and-date .rad-storytelling-hero__read-time {
        padding-right:2rem
    }
}

@media(min-width: 1441px) {
    .rad-storytelling-hero__time-and-date .rad-storytelling-hero__publish-date,.rad-storytelling-hero__time-and-date .rad-storytelling-hero__read-time {
        padding-right:calc(2rem + 2.22686vw - 32.08907px)
    }
}

@media(min-width: 1920px) {
    .rad-storytelling-hero__time-and-date .rad-storytelling-hero__publish-date,.rad-storytelling-hero__time-and-date .rad-storytelling-hero__read-time {
        padding-right:2.6666666667rem
    }
}

.rad-storytelling-hero__subheader-container {
    background-color: rgba(0,0,0,.6);
    min-height: 20.25rem;
    overflow: auto;
    padding-bottom: 1.5rem;
    padding-left: var(--rad-spacing-sm);
    padding-right: var(--rad-spacing-sm);
    padding-top: 1.5rem;
    position: relative;
    vertical-align: top;
    z-index: 100
}

@media(min-width: 600px) {
    .rad-storytelling-hero__subheader-container {
        padding-left:var(--rad-spacing-md);
        padding-right: var(--rad-spacing-md)
    }
}

.rad-component--nested .rad-storytelling-hero__subheader-container {
    padding-left: 0;
    padding-right: 0
}

@media(min-width: 600px) {
    .rad-storytelling-hero__subheader-container {
        min-height:22.5rem;
        padding-bottom: 60px;
        padding-top: 60px
    }
}

@media(min-width: 1441px) {
    .rad-storytelling-hero__subheader-container {
        padding-top:calc(-0.16701px + 4.17537vw)
    }
}

@media(min-width: 1920px) {
    .rad-storytelling-hero__subheader-container {
        padding-top:80px
    }
}

@media(min-width: 1441px) {
    .rad-storytelling-hero__subheader-container {
        padding-bottom:calc(-0.16701px + 4.17537vw)
    }
}

@media(min-width: 1920px) {
    .rad-storytelling-hero__subheader-container {
        padding-bottom:80px
    }
}

@media(min-width: 600px) {
    .rad-storytelling-hero__alignment-wrapper {
        margin-left:auto;
        width: calc(125% - var(--rad-spacing-sm)*3/4*5 + var(--rad-spacing-sm)*4 + var(--rad-spacing-sm)*0);
        width: calc(62.5% - var(--rad-spacing-sm)*7/8*5 + var(--rad-spacing-sm)*4 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-storytelling-hero__alignment-wrapper {
        width:calc(41.66667% - var(--rad-spacing-sm)*11/12*5 + var(--rad-spacing-sm)*4 + var(--rad-spacing-md)*0);
        width: calc(175% - var(--rad-spacing-sm)*3/4*7 + var(--rad-spacing-sm)*6 + var(--rad-spacing-sm)*0);
        width: calc(87.5% - var(--rad-spacing-sm)*7/8*7 + var(--rad-spacing-sm)*6 + var(--rad-spacing-md)*0);
        width: calc(58.33333% - var(--rad-spacing-sm)*11/12*7 + var(--rad-spacing-sm)*6 + var(--rad-spacing-md)*0)
    }
}

.rad-storytelling-hero__subheader {
    font-family: GT Sectra Fine,Palatino;
    font-size: 1.25rem;
    font-weight: 300;
    line-height: 1.25;
    padding-bottom: 1.5rem
}

@media(min-width: 600px) {
    .rad-storytelling-hero__subheader {
        font-size:1.5rem;
        line-height: 1.25
    }
}

@media(min-width: 1024px) {
    .rad-storytelling-hero__subheader {
        font-size:1.75rem;
        line-height: 1.25
    }
}

@media(min-width: 1441px) {
    .rad-storytelling-hero__subheader {
        font-size:calc(1.75rem + 1.9485vw - 28.07794px)
    }
}

@media(min-width: 1920px) {
    .rad-storytelling-hero__subheader {
        font-size:2.3333333333rem
    }
}

.rad-storytelling-hero__subheader h2 {
    font-size: 1.75rem;
    font-weight: 500;
    letter-spacing: -0.03em;
    line-height: 1.3
}

.rad-storytelling-hero__subheader h2:lang(ja-JP),.rad-storytelling-hero__subheader h2:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-storytelling-hero__subheader h2 {
        font-size:2.5rem;
        line-height: 1.2
    }
}

@media(min-width: 1024px) {
    .rad-storytelling-hero__subheader h2 {
        font-size:3rem;
        line-height: 1.2
    }
}

@media(min-width: 1441px) {
    .rad-storytelling-hero__subheader h2 {
        font-size:calc(3rem + 3.34029vw - 48.13361px)
    }
}

@media(min-width: 1920px) {
    .rad-storytelling-hero__subheader h2 {
        font-size:4rem
    }
}

.rad-storytelling-hero__subheader h2+h2,.rad-storytelling-hero__subheader h2+h3 {
    margin-top: 1rem
}

.rad-storytelling-hero__subheader h2+h4 {
    margin-top: 1.5rem
}

.rad-storytelling-hero__subheader h2+h5 {
    margin-top: .5rem
}

.rad-storytelling-hero__subheader h2+p {
    margin-top: 1.5rem
}

.rad-storytelling-hero__subheader h3 {
    font-size: 1.375rem;
    font-weight: 500;
    letter-spacing: -0.02em;
    line-height: 1.25
}

.rad-storytelling-hero__subheader h3:lang(ja-JP),.rad-storytelling-hero__subheader h3:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-storytelling-hero__subheader h3 {
        font-size:1.75rem;
        line-height: 1.3
    }
}

@media(min-width: 1024px) {
    .rad-storytelling-hero__subheader h3 {
        font-size:2rem;
        line-height: 1.2
    }
}

@media(min-width: 1441px) {
    .rad-storytelling-hero__subheader h3 {
        font-size:calc(2rem + 2.22686vw - 32.08907px)
    }
}

@media(min-width: 1920px) {
    .rad-storytelling-hero__subheader h3 {
        font-size:2.6666666667rem
    }
}

.rad-storytelling-hero__subheader h3+h2 {
    margin-top: 2rem
}

.rad-storytelling-hero__subheader h3+h3 {
    margin-top: 1rem
}

.rad-storytelling-hero__subheader h3+h4 {
    margin-top: .5rem
}

.rad-storytelling-hero__subheader h3+h5 {
    margin-top: 1rem
}

.rad-storytelling-hero__subheader h3+p {
    margin-top: 1.5rem
}

.rad-storytelling-hero__subheader h4 {
    font-size: 1.125rem;
    font-weight: 500;
    letter-spacing: -0.02em;
    line-height: 1.4
}

.rad-storytelling-hero__subheader h4:lang(ja-JP),.rad-storytelling-hero__subheader h4:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-storytelling-hero__subheader h4 {
        font-size:1.25rem;
        line-height: 1.4
    }
}

@media(min-width: 1024px) {
    .rad-storytelling-hero__subheader h4 {
        font-size:1.5rem;
        line-height: 1.3
    }
}

@media(min-width: 1441px) {
    .rad-storytelling-hero__subheader h4 {
        font-size:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-storytelling-hero__subheader h4 {
        font-size:2rem
    }
}

.rad-storytelling-hero__subheader h4+h2,.rad-storytelling-hero__subheader h4+h3 {
    margin-top: 2rem
}

.rad-storytelling-hero__subheader h4+h4 {
    margin-top: .5rem
}

.rad-storytelling-hero__subheader h4+h5 {
    margin-top: 2rem
}

.rad-storytelling-hero__subheader h4+p {
    margin-top: .5rem
}

.rad-storytelling-hero__subheader h5 {
    font-family: GT Sectra Fine,Palatino;
    font-size: 1.25rem;
    font-weight: 300;
    line-height: 1.25
}

@media(min-width: 600px) {
    .rad-storytelling-hero__subheader h5 {
        font-size:1.5rem;
        line-height: 1.25
    }
}

@media(min-width: 1024px) {
    .rad-storytelling-hero__subheader h5 {
        font-size:1.75rem;
        line-height: 1.25
    }
}

@media(min-width: 1441px) {
    .rad-storytelling-hero__subheader h5 {
        font-size:calc(1.75rem + 1.9485vw - 28.07794px)
    }
}

@media(min-width: 1920px) {
    .rad-storytelling-hero__subheader h5 {
        font-size:2.3333333333rem
    }
}

.rad-storytelling-hero__subheader h5+h2 {
    margin-top: 2rem
}

.rad-storytelling-hero__subheader h5+h3,.rad-storytelling-hero__subheader h5+h4 {
    margin-top: 1.5rem
}

.rad-storytelling-hero__subheader h5+h5 {
    margin-top: 1rem
}

.rad-storytelling-hero__subheader h5+p {
    margin-top: 1.5rem
}

.rad-storytelling-hero__subheader p {
    padding-right: var(--scrollbar-width)
}

.rad-storytelling-hero__subheader p a {
    font-weight: 500
}

.rad-storytelling-hero__subheader p a:focus-visible {
    outline-offset: 2px
}

.rad-storytelling-hero__subheader p+h2,.rad-storytelling-hero__subheader p+h3,.rad-storytelling-hero__subheader p+h4,.rad-storytelling-hero__subheader p+h5 {
    margin-top: 2rem
}

.rad-storytelling-hero__subheader p+p {
    margin-top: .5rem
}

@media(min-width: 600px) {
    .rad-storytelling-hero__subheader h2+h2,.rad-storytelling-hero__subheader h2+h3 {
        margin-top:1.5rem
    }

    .rad-storytelling-hero__subheader h2+h4 {
        margin-top: 2rem
    }

    .rad-storytelling-hero__subheader h2+h5 {
        margin-top: .5rem
    }

    .rad-storytelling-hero__subheader h2+p {
        margin-top: 2rem
    }

    .rad-storytelling-hero__subheader h3+h2 {
        margin-top: 3rem
    }

    .rad-storytelling-hero__subheader h3+h3 {
        margin-top: 1.5rem
    }

    .rad-storytelling-hero__subheader h3+h4 {
        margin-top: .75rem
    }

    .rad-storytelling-hero__subheader h3+h5 {
        margin-top: 1.5rem
    }

    .rad-storytelling-hero__subheader h3+p {
        margin-top: 2rem
    }

    .rad-storytelling-hero__subheader h4+h2,.rad-storytelling-hero__subheader h4+h3 {
        margin-top: 3rem
    }

    .rad-storytelling-hero__subheader h4+h4 {
        margin-top: 1rem
    }

    .rad-storytelling-hero__subheader h4+h5 {
        margin-top: 3rem
    }

    .rad-storytelling-hero__subheader h4+p {
        margin-top: .75rem
    }

    .rad-storytelling-hero__subheader h5+h2 {
        margin-top: 3rem
    }

    .rad-storytelling-hero__subheader h5+h3 {
        margin-top: 2.5rem
    }

    .rad-storytelling-hero__subheader h5+h4 {
        margin-top: 2rem
    }

    .rad-storytelling-hero__subheader h5+h5 {
        margin-top: 1.5rem
    }

    .rad-storytelling-hero__subheader h5+p {
        margin-top: 2rem
    }

    .rad-storytelling-hero__subheader p {
        padding-right: 0
    }

    .rad-storytelling-hero__subheader p+h2,.rad-storytelling-hero__subheader p+h3,.rad-storytelling-hero__subheader p+h4,.rad-storytelling-hero__subheader p+h5 {
        margin-top: 3rem
    }

    .rad-storytelling-hero__subheader p+p {
        margin-top: 1rem
    }
}

@media(min-width: 1024px) {
    .rad-storytelling-hero__subheader {
        padding-bottom:2rem
    }
}

@media(min-width: 1441px) {
    .rad-storytelling-hero__subheader {
        padding-bottom:calc(2rem + 2.22686vw - 32.08907px)
    }
}

@media(min-width: 1920px) {
    .rad-storytelling-hero__subheader {
        padding-bottom:2.6666666667rem
    }
}

@media(min-width: 1024px) {
    .rad-storytelling-hero__subheader h2+h2,.rad-storytelling-hero__subheader h2+h3 {
        margin-top:2rem
    }

    .rad-storytelling-hero__subheader h2+h4 {
        margin-top: 3rem
    }

    .rad-storytelling-hero__subheader h2+h5 {
        margin-top: .5rem
    }

    .rad-storytelling-hero__subheader h2+p {
        margin-top: 3rem
    }

    .rad-storytelling-hero__subheader h3+h2 {
        margin-top: 4rem
    }

    .rad-storytelling-hero__subheader h3+h3 {
        margin-top: 2rem
    }

    .rad-storytelling-hero__subheader h3+h4 {
        margin-top: 1rem
    }

    .rad-storytelling-hero__subheader h3+h5 {
        margin-top: 2rem
    }

    .rad-storytelling-hero__subheader h3+p {
        margin-top: 3rem
    }

    .rad-storytelling-hero__subheader h4+h2,.rad-storytelling-hero__subheader h4+h3 {
        margin-top: 4rem
    }

    .rad-storytelling-hero__subheader h4+h4 {
        margin-top: 1.5rem
    }

    .rad-storytelling-hero__subheader h4+h5 {
        margin-top: 4rem
    }

    .rad-storytelling-hero__subheader h4+p {
        margin-top: 1rem
    }

    .rad-storytelling-hero__subheader h5+h2 {
        margin-top: 4rem
    }

    .rad-storytelling-hero__subheader h5+h3,.rad-storytelling-hero__subheader h5+h4 {
        margin-top: 3rem
    }

    .rad-storytelling-hero__subheader h5+h5 {
        margin-top: 2rem
    }

    .rad-storytelling-hero__subheader h5+p {
        margin-top: 3rem
    }

    .rad-storytelling-hero__subheader p+h2,.rad-storytelling-hero__subheader p+h3,.rad-storytelling-hero__subheader p+h4,.rad-storytelling-hero__subheader p+h5 {
        margin-top: 4rem
    }

    .rad-storytelling-hero__subheader p+p {
        margin-top: 1.5rem
    }
}

.rad-storytelling-hero__btn {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 16px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content
}

@media(min-width: 600px) {
    .rad-storytelling-hero__btn {
        -webkit-box-orient:horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        gap: 24px
    }
}

@media(min-width: 1024px) {
    .rad-storytelling-hero__btn {
        gap:32px
    }
}

@media(min-width: 1441px) {
    .rad-storytelling-hero__btn {
        gap:calc(-0.08907px + 2.22686vw)
    }
}

@media(min-width: 1920px) {
    .rad-storytelling-hero__btn {
        gap:42.6666666667px
    }
}

.rad-storytelling-hero__btn .rad-button {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100%
}

@media(min-width: 600px) {
    .rad-storytelling-hero__btn .rad-button {
        width:auto
    }
}

.rad-storytelling-hero__background-media {
    -webkit-clip-path: inset(0 0 0 0);
    clip-path: inset(0 0 0 0);
    height: 100%;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0
}

.rad-storytelling-hero__background-media .dynamicmedia,.rad-storytelling-hero__background-media .radimage {
    left: 0;
    position: fixed;
    top: 0
}

.rad-storytelling-hero__background-media div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    width: 100%
}

.rad-storytelling-hero__background-media img,.rad-storytelling-hero__background-media video {
    -webkit-filter: blur(8px);
    filter: blur(8px);
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%
}

.rad-storytelling-hero .rad-media-overlay {
    background: none;
    height: auto;
    margin-left: auto;
    position: relative;
    width: auto
}

.rad-storytelling-hero .rad-media-overlay__toggle {
    background-color: inherit;
    bottom: auto;
    left: auto;
    position: relative
}

.rad-storytelling-hero .rad-media-overlay--hide {
    display: none
}

.rad-storytelling-hero .rad-icon-button:hover {
    color: #a2a2a0
}

.rad-storytelling-hero.rad-storytelling-hero__two-media-variation .rad-storytelling-hero__background-container {
    background-color: rgba(0,0,0,.6);
    padding-bottom: 4rem;
    padding-left: var(--rad-spacing-sm);
    padding-right: var(--rad-spacing-sm);
    padding-top: 1.5rem;
    position: relative;
    z-index: 100
}

@media(min-width: 600px) {
    .rad-storytelling-hero.rad-storytelling-hero__two-media-variation .rad-storytelling-hero__background-container {
        padding-left:var(--rad-spacing-md);
        padding-right: var(--rad-spacing-md)
    }
}

.rad-component--nested .rad-storytelling-hero.rad-storytelling-hero__two-media-variation .rad-storytelling-hero__background-container {
    padding-left: 0;
    padding-right: 0
}

@media(min-width: 600px) {
    .rad-storytelling-hero.rad-storytelling-hero__two-media-variation .rad-storytelling-hero__background-container {
        padding-bottom:3rem;
        padding-top: 3.75rem
    }
}

@media(min-width: 1441px) {
    .rad-storytelling-hero.rad-storytelling-hero__two-media-variation .rad-storytelling-hero__background-container {
        padding-top:calc(3.75rem + 4.17537vw - 60.16701px)
    }
}

@media(min-width: 1920px) {
    .rad-storytelling-hero.rad-storytelling-hero__two-media-variation .rad-storytelling-hero__background-container {
        padding-top:5rem
    }
}

@media(min-width: 1441px) {
    .rad-storytelling-hero.rad-storytelling-hero__two-media-variation .rad-storytelling-hero__background-container {
        padding-bottom:calc(3rem + 3.34029vw - 48.13361px)
    }
}

@media(min-width: 1920px) {
    .rad-storytelling-hero.rad-storytelling-hero__two-media-variation .rad-storytelling-hero__background-container {
        padding-bottom:4rem
    }
}

.rad-storytelling-hero.rad-storytelling-hero__two-media-variation .rad-storytelling-hero__text-content {
    -webkit-clip-path: inset(0 0 0 0);
    clip-path: inset(0 0 0 0);
    position: relative
}

.rad-storytelling-hero.rad-storytelling-hero__two-media-variation .rad-storytelling-hero__headline {
    color: #fff;
    min-height: 7.5rem
}

@media(min-width: 600px) {
    .rad-storytelling-hero.rad-storytelling-hero__two-media-variation .rad-storytelling-hero__headline {
        min-height:8.625rem
    }
}

.rad-storytelling-hero.rad-storytelling-hero__two-media-variation .rad-storytelling-hero__date-and-btn {
    padding-bottom: .5rem
}

@media(min-width: 600px) {
    .rad-storytelling-hero.rad-storytelling-hero__two-media-variation .rad-storytelling-hero__date-and-btn {
        padding-bottom:0
    }
}

.rad-storytelling-hero.rad-storytelling-hero__two-media-variation .rad-storytelling-hero__background-media div {
    pointer-events: none
}

.rad-storytelling-hero.rad-storytelling-hero__two-media-variation .rad-storytelling-hero__background-media img,.rad-storytelling-hero.rad-storytelling-hero__two-media-variation .rad-storytelling-hero__background-media video {
    -webkit-filter: blur(50px);
    filter: blur(50px)
}

.rad-storytelling-hero.rad-storytelling-hero__two-media-variation .rad-storytelling-hero__foreground-content {
    padding-bottom: 1.5rem;
    padding-left: var(--rad-spacing-sm);
    padding-right: var(--rad-spacing-sm);
    width: 100%
}

@media(min-width: 600px) {
    .rad-storytelling-hero.rad-storytelling-hero__two-media-variation .rad-storytelling-hero__foreground-content {
        padding-left:var(--rad-spacing-md);
        padding-right: var(--rad-spacing-md)
    }
}

.rad-component--nested .rad-storytelling-hero.rad-storytelling-hero__two-media-variation .rad-storytelling-hero__foreground-content {
    padding-left: 0;
    padding-right: 0
}

@media(min-width: 600px) {
    .rad-storytelling-hero.rad-storytelling-hero__two-media-variation .rad-storytelling-hero__foreground-content {
        padding-bottom:3.75rem
    }
}

@media(min-width: 1441px) {
    .rad-storytelling-hero.rad-storytelling-hero__two-media-variation .rad-storytelling-hero__foreground-content {
        padding-bottom:calc(3.75rem + 4.17537vw - 60.16701px)
    }
}

@media(min-width: 1920px) {
    .rad-storytelling-hero.rad-storytelling-hero__two-media-variation .rad-storytelling-hero__foreground-content {
        padding-bottom:5rem
    }
}

.rad-storytelling-hero.rad-storytelling-hero__two-media-variation .rad-storytelling-hero__foreground-container {
    margin-left: auto;
    margin-top: -4rem;
    pointer-events: none;
    position: relative;
    width: 100%;
    z-index: 100
}

@media(min-width: 600px) {
    .rad-storytelling-hero.rad-storytelling-hero__two-media-variation .rad-storytelling-hero__foreground-container {
        margin-top:-6rem;
        padding-top: 1rem;
        width: calc(125% - var(--rad-spacing-sm)*3/4*5 + var(--rad-spacing-sm)*4 + var(--rad-spacing-sm)*0);
        width: calc(62.5% - var(--rad-spacing-sm)*7/8*5 + var(--rad-spacing-sm)*4 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-storytelling-hero.rad-storytelling-hero__two-media-variation .rad-storytelling-hero__foreground-container {
        width:calc(41.66667% - var(--rad-spacing-sm)*11/12*5 + var(--rad-spacing-sm)*4 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1441px) {
    .rad-storytelling-hero.rad-storytelling-hero__two-media-variation .rad-storytelling-hero__foreground-container {
        margin-top:calc(-6rem - 6.68058vw + 96.26722px)
    }
}

@media(min-width: 1920px) {
    .rad-storytelling-hero.rad-storytelling-hero__two-media-variation .rad-storytelling-hero__foreground-container {
        margin-top:-8rem
    }
}

@media(min-width: 1441px) {
    .rad-storytelling-hero.rad-storytelling-hero__two-media-variation .rad-storytelling-hero__foreground-container {
        padding-top:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-storytelling-hero.rad-storytelling-hero__two-media-variation .rad-storytelling-hero__foreground-container {
        padding-top:1.3333333333rem
    }
}

@media(min-width: 1024px) {
    .rad-storytelling-hero.rad-storytelling-hero__two-media-variation .rad-storytelling-hero__foreground-container {
        width:calc(175% - var(--rad-spacing-sm)*3/4*7 + var(--rad-spacing-sm)*6 + var(--rad-spacing-sm)*0);
        width: calc(87.5% - var(--rad-spacing-sm)*7/8*7 + var(--rad-spacing-sm)*6 + var(--rad-spacing-md)*0);
        width: calc(58.33333% - var(--rad-spacing-sm)*11/12*7 + var(--rad-spacing-sm)*6 + var(--rad-spacing-md)*0)
    }
}

.rad-storytelling-hero.rad-storytelling-hero__two-media-variation .rad-storytelling-hero__foreground-container * {
    pointer-events: auto
}

.rad-storytelling-hero.rad-storytelling-hero__two-media-variation .rad-storytelling-hero__foreground-container--overflow {
    margin-bottom: 1rem;
    pointer-events: none
}

@media(min-width: 600px) {
    .rad-storytelling-hero.rad-storytelling-hero__two-media-variation .rad-storytelling-hero__foreground-container--overflow {
        margin-bottom:1.5rem;
        width: calc(100vw - 96px)
    }
}

@media(min-width: 1024px) {
    .rad-storytelling-hero.rad-storytelling-hero__two-media-variation .rad-storytelling-hero__foreground-container--overflow {
        margin-bottom:2rem;
        width: calc(100vw - 160px)
    }
}

@media(min-width: 1441px) {
    .rad-storytelling-hero.rad-storytelling-hero__two-media-variation .rad-storytelling-hero__foreground-container--overflow {
        margin-bottom:calc(2rem + 2.22686vw - 32.08907px)
    }
}

@media(min-width: 1920px) {
    .rad-storytelling-hero.rad-storytelling-hero__two-media-variation .rad-storytelling-hero__foreground-container--overflow {
        margin-bottom:2.6666666667rem
    }
}

.rad-storytelling-hero.rad-storytelling-hero__two-media-variation .rad-storytelling-hero__foreground-media {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    pointer-events: none;
    position: relative;
    width: calc(75% - var(--rad-spacing-sm)*3/4*3 + var(--rad-spacing-sm)*2 + var(--rad-spacing-sm)*0)
}

@media(min-width: 600px) {
    .rad-storytelling-hero.rad-storytelling-hero__two-media-variation .rad-storytelling-hero__foreground-media {
        width:calc(37.5% - var(--rad-spacing-sm)*7/8*3 + var(--rad-spacing-sm)*2 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-storytelling-hero.rad-storytelling-hero__two-media-variation .rad-storytelling-hero__foreground-media {
        width:calc(25% - var(--rad-spacing-sm)*11/12*3 + var(--rad-spacing-sm)*2 + var(--rad-spacing-md)*0)
    }
}

.rad-storytelling-hero.rad-storytelling-hero__two-media-variation .rad-storytelling-hero__foreground-media div {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    pointer-events: none;
    position: relative;
    width: 100%
}

.rad-storytelling-hero.rad-storytelling-hero__two-media-variation .rad-storytelling-hero__foreground-media img,.rad-storytelling-hero.rad-storytelling-hero__two-media-variation .rad-storytelling-hero__foreground-media video {
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%
}

.rad-storytelling-hero.rad-storytelling-hero__two-media-variation .rad-storytelling-hero__foreground-media .rad-storytelling-hero__foreground-media--overlay {
    background-image: -webkit-gradient(linear,left top,left bottom,from(transparent),to(rgba(0,0,0,.75)));
    background-image: linear-gradient(180deg,transparent,rgba(0,0,0,.75));
    height: 100%;
    pointer-events: auto;
    position: absolute
}

.rad-storytelling-hero.rad-storytelling-hero__two-media-variation .rad-storytelling-hero__foreground-media .rad-storytelling-hero__foreground-media--overlay div {
    position: absolute;
    width: auto
}

.rad-storytelling-hero.rad-storytelling-hero__two-media-variation .rad-storytelling-hero__foreground-media .rad-storytelling-hero__foreground-media--overlay .rad-media-overlay {
    bottom: 0;
    height: auto;
    right: 0
}

.rad-storytelling-hero.rad-storytelling-hero__two-media-variation .rad-storytelling-hero__foreground-media .rad-storytelling-hero__foreground-media--overlay .rad-media-overlay--hide {
    display: none
}

.rad-storytelling-hero.rad-storytelling-hero__two-media-variation .rad-storytelling-hero__subheader {
    font-family: GT Sectra Fine,Palatino;
    font-size: 1.125rem;
    font-weight: 300;
    line-height: 1.3;
    padding-bottom: 1rem
}

@media(min-width: 600px) {
    .rad-storytelling-hero.rad-storytelling-hero__two-media-variation .rad-storytelling-hero__subheader {
        font-size:1.25rem;
        line-height: 1.3
    }
}

@media(min-width: 1024px) {
    .rad-storytelling-hero.rad-storytelling-hero__two-media-variation .rad-storytelling-hero__subheader {
        font-size:1.5rem;
        line-height: 1.25
    }
}

@media(min-width: 1441px) {
    .rad-storytelling-hero.rad-storytelling-hero__two-media-variation .rad-storytelling-hero__subheader {
        font-size:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-storytelling-hero.rad-storytelling-hero__two-media-variation .rad-storytelling-hero__subheader {
        font-size:2rem
    }
}

@media(min-width: 600px) {
    .rad-storytelling-hero.rad-storytelling-hero__two-media-variation .rad-storytelling-hero__subheader {
        padding-bottom:1.5rem
    }
}

@media(min-width: 1024px) {
    .rad-storytelling-hero.rad-storytelling-hero__two-media-variation .rad-storytelling-hero__subheader {
        padding-bottom:2rem
    }
}

@media(min-width: 1441px) {
    .rad-storytelling-hero.rad-storytelling-hero__two-media-variation .rad-storytelling-hero__subheader {
        padding-bottom:calc(2rem + 2.22686vw - 32.08907px)
    }
}

@media(min-width: 1920px) {
    .rad-storytelling-hero.rad-storytelling-hero__two-media-variation .rad-storytelling-hero__subheader {
        padding-bottom:2.6666666667rem
    }
}

.rad-media--image__wrapper img,.rad-media--lottie__wrapper img {
    display: block;
    width: 100%
}

.rad-media--image__caption,.rad-media--lottie__caption {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    font-size: .75rem;
    font-weight: 500;
    font-weight: 400;
    letter-spacing: -0.005em;
    line-height: 1.5;
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
    padding-bottom: .25rem;
    padding-top: .5rem;
    text-underline-offset: .25rem;
    width: calc(100% - var(--rad-spacing-sm)*3/4*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-sm)*0)
}

.rad-media--image__caption:lang(ja-JP),.rad-media--image__caption:lang(zh-CN),.rad-media--lottie__caption:lang(ja-JP),.rad-media--lottie__caption:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-media--image__caption,.rad-media--lottie__caption {
        font-size:0.875rem
    }
}

@media(min-width: 1441px) {
    .rad-media--image__caption,.rad-media--lottie__caption {
        font-size:calc(0.875rem + 0.97425vw - 14.03897px)
    }
}

@media(min-width: 1920px) {
    .rad-media--image__caption,.rad-media--lottie__caption {
        font-size:1.1666666667rem
    }
}

.rad-media--image__caption:lang(ja-JP),.rad-media--image__caption:lang(zh-CN),.rad-media--lottie__caption:lang(ja-JP),.rad-media--lottie__caption:lang(zh-CN) {
    font-weight: 400
}

@media(min-width: 600px) {
    .rad-media--image__caption,.rad-media--lottie__caption {
        width:calc(50% - var(--rad-spacing-sm)*7/8*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-media--image__caption,.rad-media--lottie__caption {
        width:calc(33.33333% - var(--rad-spacing-sm)*11/12*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 600px) {
    .rad-media--image__caption,.rad-media--lottie__caption {
        padding-left:0;
        width: calc(125% - var(--rad-spacing-sm)*3/4*5 + var(--rad-spacing-sm)*4 + var(--rad-spacing-sm)*0);
        width: calc(62.5% - var(--rad-spacing-sm)*7/8*5 + var(--rad-spacing-sm)*4 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-media--image__caption,.rad-media--lottie__caption {
        padding-bottom:0.25rem;
        padding-top: 0.75rem;
        width: calc(41.66667% - var(--rad-spacing-sm)*11/12*5 + var(--rad-spacing-sm)*4 + var(--rad-spacing-md)*0);
        width: calc(150% - var(--rad-spacing-sm)*3/4*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-sm)*0);
        width: calc(75% - var(--rad-spacing-sm)*7/8*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0);
        width: calc(50% - var(--rad-spacing-sm)*11/12*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1441px) {
    .rad-media--image__caption,.rad-media--lottie__caption {
        padding-top:calc(0.75rem + 0.83507vw - 12.0334px)
    }
}

@media(min-width: 1920px) {
    .rad-media--image__caption,.rad-media--lottie__caption {
        padding-top:1rem
    }
}

@media(min-width: 1441px) {
    .rad-media--image__caption,.rad-media--lottie__caption {
        padding-bottom:calc(0.25rem + 0.27836vw - 4.01113px)
    }
}

@media(min-width: 1920px) {
    .rad-media--image__caption,.rad-media--lottie__caption {
        padding-bottom:0.3333333333rem
    }
}

.rad-media--image__caption .rad-media--anchored__caption-text,.rad-media--image__caption-text,.rad-media--lottie__caption .rad-media--anchored__caption-text,.rad-media--lottie__caption-text {
    color: #a2a2a0;
    max-width: 32.5rem;
    padding-top: .25rem
}

@media(min-width: 1441px) {
    .rad-media--image__caption .rad-media--anchored__caption-text,.rad-media--image__caption-text,.rad-media--lottie__caption .rad-media--anchored__caption-text,.rad-media--lottie__caption-text {
        max-width:calc(32.5rem + 36.1865vw - 521.44746px)
    }
}

@media(min-width: 1920px) {
    .rad-media--image__caption .rad-media--anchored__caption-text,.rad-media--image__caption-text,.rad-media--lottie__caption .rad-media--anchored__caption-text,.rad-media--lottie__caption-text {
        max-width:43.3333333333rem
    }
}

@media(min-width: 1024px) {
    .rad-media--image__caption .rad-media--anchored__caption-text,.rad-media--image__caption-text,.rad-media--lottie__caption .rad-media--anchored__caption-text,.rad-media--lottie__caption-text {
        padding-top:0.25rem
    }
}

@media(min-width: 1441px) {
    .rad-media--image__caption .rad-media--anchored__caption-text,.rad-media--image__caption-text,.rad-media--lottie__caption .rad-media--anchored__caption-text,.rad-media--lottie__caption-text {
        padding-top:calc(0.25rem + 0.27836vw - 4.01113px)
    }
}

@media(min-width: 1920px) {
    .rad-media--image__caption .rad-media--anchored__caption-text,.rad-media--image__caption-text,.rad-media--lottie__caption .rad-media--anchored__caption-text,.rad-media--lottie__caption-text {
        padding-top:0.3333333333rem
    }
}

.rad-media--image__caption .rad-media--anchored__caption-text:first-child,.rad-media--image__caption-text:first-child,.rad-media--lottie__caption .rad-media--anchored__caption-text:first-child,.rad-media--lottie__caption-text:first-child {
    color: #fff
}

.rad-media--image.rad-media--anchored:focus-within,.rad-media--lottie.rad-media--anchored:focus-within {
    outline: 2px solid #a100ff;
    outline-offset: 8px
}

.rad-media .rad-button {
    min-height: auto
}

.rad-media .rad-button .rad-button--ghost :hover {
    color: #fff
}

.rad-media .rad-button .rad-button__icon-right {
    margin-left: .25rem
}

.rad-media.rad-media--anchored:hover .rad-media--anchored__caption-text__wrapper .rad-media--anchored__caption-text:after {
    width: 100%
}

.rad-media.rad-media--anchored .rad-media--anchored__link {
    text-decoration: none
}

.rad-media.rad-media--anchored .rad-media--anchored__caption-text__wrapper,.rad-media.rad-media--anchored .rad-media--mp4__caption {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    text-decoration: none
}

.rad-media.rad-media--anchored .rad-media--anchored__caption-text__wrapper .rad-media--anchored__caption-text,.rad-media.rad-media--anchored .rad-media--mp4__caption .rad-media--anchored__caption-text {
    font-size: .75rem;
    font-weight: 500;
    font-weight: 400;
    letter-spacing: -0.005em;
    line-height: 1.5;
    position: relative;
    text-underline-offset: .25rem
}

.rad-media.rad-media--anchored .rad-media--anchored__caption-text__wrapper .rad-media--anchored__caption-text:lang(ja-JP),.rad-media.rad-media--anchored .rad-media--anchored__caption-text__wrapper .rad-media--anchored__caption-text:lang(zh-CN),.rad-media.rad-media--anchored .rad-media--mp4__caption .rad-media--anchored__caption-text:lang(ja-JP),.rad-media.rad-media--anchored .rad-media--mp4__caption .rad-media--anchored__caption-text:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-media.rad-media--anchored .rad-media--anchored__caption-text__wrapper .rad-media--anchored__caption-text,.rad-media.rad-media--anchored .rad-media--mp4__caption .rad-media--anchored__caption-text {
        font-size:0.875rem
    }
}

@media(min-width: 1441px) {
    .rad-media.rad-media--anchored .rad-media--anchored__caption-text__wrapper .rad-media--anchored__caption-text,.rad-media.rad-media--anchored .rad-media--mp4__caption .rad-media--anchored__caption-text {
        font-size:calc(0.875rem + 0.97425vw - 14.03897px)
    }
}

@media(min-width: 1920px) {
    .rad-media.rad-media--anchored .rad-media--anchored__caption-text__wrapper .rad-media--anchored__caption-text,.rad-media.rad-media--anchored .rad-media--mp4__caption .rad-media--anchored__caption-text {
        font-size:1.1666666667rem
    }
}

.rad-media.rad-media--anchored .rad-media--anchored__caption-text__wrapper .rad-media--anchored__caption-text:lang(ja-JP),.rad-media.rad-media--anchored .rad-media--anchored__caption-text__wrapper .rad-media--anchored__caption-text:lang(zh-CN),.rad-media.rad-media--anchored .rad-media--mp4__caption .rad-media--anchored__caption-text:lang(ja-JP),.rad-media.rad-media--anchored .rad-media--mp4__caption .rad-media--anchored__caption-text:lang(zh-CN) {
    font-weight: 400
}

.rad-media.rad-media--anchored .rad-media--anchored__caption-text__wrapper .rad-media--anchored__caption-text:after,.rad-media.rad-media--anchored .rad-media--mp4__caption .rad-media--anchored__caption-text:after {
    border-bottom: 1px solid;
    bottom: 0;
    content: "";
    display: block;
    left: 0;
    position: absolute;
    -webkit-transition-duration: 550ms;
    transition-duration: 550ms;
    -webkit-transition-property: width;
    transition-property: width;
    -webkit-transition-timing-function: cubic-bezier(0.85,0,0,1);
    transition-timing-function: cubic-bezier(0.85,0,0,1);
    width: 0
}

.rad-media.rad-media--anchored .rad-media--anchored__caption-text__wrapper .rad-button__icon-right,.rad-media.rad-media--anchored .rad-media--mp4__caption .rad-button__icon-right {
    color: #fff;
    padding: .25rem .5rem 0
}

@media(min-width: 600px) {
    .rad-media.rad-media--anchored .rad-media--anchored__caption-text__wrapper .rad-button__icon-right,.rad-media.rad-media--anchored .rad-media--mp4__caption .rad-button__icon-right {
        padding:.25rem .5rem 0
    }
}

@media(min-width: 1024px) {
    .rad-media.rad-media--anchored .rad-media--anchored__caption-text__wrapper .rad-button__icon-right,.rad-media.rad-media--anchored .rad-media--mp4__caption .rad-button__icon-right {
        padding-top:0.3125rem
    }
}

@media(min-width: 1441px) {
    .rad-media.rad-media--anchored .rad-media--anchored__caption-text__wrapper .rad-button__icon-right,.rad-media.rad-media--anchored .rad-media--mp4__caption .rad-button__icon-right {
        padding-top:calc(0.3125rem + 0.34795vw - 5.01392px)
    }
}

@media(min-width: 1920px) {
    .rad-media.rad-media--anchored .rad-media--anchored__caption-text__wrapper .rad-button__icon-right,.rad-media.rad-media--anchored .rad-media--mp4__caption .rad-button__icon-right {
        padding-top:0.4166666667rem
    }
}

.rad-media .rad-spacing-vertical-md,.rad-media .rad-vidyard-player__container {
    padding: 0
}

.rad-media .rad-absorb-video {
    margin: 0
}

.rad-media .rad-absorb-video__iframe {
    max-height: 100%;
    max-width: 100%
}

@media(min-width: 1024px) {
    .rad-media .rad-absorb-video__iframe {
        margin:0
    }
}

.rad-media .rad-absorb-video__transcript,.rad-media .rad-vidyard-player__transcript {
    padding-right: 0
}

.rad-media--mp4 {
    position: relative
}

.rad-media--mp4__caption {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    font-size: .75rem;
    font-weight: 500;
    font-weight: 400;
    letter-spacing: -0.005em;
    line-height: 1.5;
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
    padding-bottom: .25rem;
    padding-top: .5rem;
    text-underline-offset: .25rem;
    width: calc(100% - var(--rad-spacing-sm)*3/4*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-sm)*0)
}

.rad-media--mp4__caption:lang(ja-JP),.rad-media--mp4__caption:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-media--mp4__caption {
        font-size:0.875rem
    }
}

@media(min-width: 1441px) {
    .rad-media--mp4__caption {
        font-size:calc(0.875rem + 0.97425vw - 14.03897px)
    }
}

@media(min-width: 1920px) {
    .rad-media--mp4__caption {
        font-size:1.1666666667rem
    }
}

.rad-media--mp4__caption:lang(ja-JP),.rad-media--mp4__caption:lang(zh-CN) {
    font-weight: 400
}

@media(min-width: 600px) {
    .rad-media--mp4__caption {
        width:calc(50% - var(--rad-spacing-sm)*7/8*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-media--mp4__caption {
        width:calc(33.33333% - var(--rad-spacing-sm)*11/12*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 600px) {
    .rad-media--mp4__caption {
        padding-left:0;
        width: calc(125% - var(--rad-spacing-sm)*3/4*5 + var(--rad-spacing-sm)*4 + var(--rad-spacing-sm)*0);
        width: calc(62.5% - var(--rad-spacing-sm)*7/8*5 + var(--rad-spacing-sm)*4 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-media--mp4__caption {
        padding-bottom:0.25rem;
        padding-top: 0.75rem;
        width: calc(41.66667% - var(--rad-spacing-sm)*11/12*5 + var(--rad-spacing-sm)*4 + var(--rad-spacing-md)*0);
        width: calc(150% - var(--rad-spacing-sm)*3/4*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-sm)*0);
        width: calc(75% - var(--rad-spacing-sm)*7/8*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0);
        width: calc(50% - var(--rad-spacing-sm)*11/12*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1441px) {
    .rad-media--mp4__caption {
        padding-top:calc(0.75rem + 0.83507vw - 12.0334px)
    }
}

@media(min-width: 1920px) {
    .rad-media--mp4__caption {
        padding-top:1rem
    }
}

@media(min-width: 1441px) {
    .rad-media--mp4__caption {
        padding-bottom:calc(0.25rem + 0.27836vw - 4.01113px)
    }
}

@media(min-width: 1920px) {
    .rad-media--mp4__caption {
        padding-bottom:0.3333333333rem
    }
}

.rad-media--mp4__caption .rad-media--anchored__caption-text,.rad-media--mp4__caption-text {
    color: #a2a2a0;
    font-size: .75rem;
    font-weight: 500;
    font-weight: 400;
    letter-spacing: -0.005em;
    line-height: 1.5;
    max-width: 32.5rem;
    padding-top: .25rem;
    text-underline-offset: .25rem
}

.rad-media--mp4__caption .rad-media--anchored__caption-text:lang(ja-JP),.rad-media--mp4__caption .rad-media--anchored__caption-text:lang(zh-CN),.rad-media--mp4__caption-text:lang(ja-JP),.rad-media--mp4__caption-text:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-media--mp4__caption .rad-media--anchored__caption-text,.rad-media--mp4__caption-text {
        font-size:0.875rem
    }
}

@media(min-width: 1441px) {
    .rad-media--mp4__caption .rad-media--anchored__caption-text,.rad-media--mp4__caption-text {
        font-size:calc(0.875rem + 0.97425vw - 14.03897px)
    }
}

@media(min-width: 1920px) {
    .rad-media--mp4__caption .rad-media--anchored__caption-text,.rad-media--mp4__caption-text {
        font-size:1.1666666667rem
    }
}

.rad-media--mp4__caption .rad-media--anchored__caption-text:lang(ja-JP),.rad-media--mp4__caption .rad-media--anchored__caption-text:lang(zh-CN),.rad-media--mp4__caption-text:lang(ja-JP),.rad-media--mp4__caption-text:lang(zh-CN) {
    font-weight: 400
}

@media(min-width: 1441px) {
    .rad-media--mp4__caption .rad-media--anchored__caption-text,.rad-media--mp4__caption-text {
        max-width:calc(32.5rem + 36.1865vw - 521.44746px)
    }
}

@media(min-width: 1920px) {
    .rad-media--mp4__caption .rad-media--anchored__caption-text,.rad-media--mp4__caption-text {
        max-width:43.3333333333rem
    }
}

@media(min-width: 1024px) {
    .rad-media--mp4__caption .rad-media--anchored__caption-text,.rad-media--mp4__caption-text {
        padding-top:0.25rem
    }
}

@media(min-width: 1441px) {
    .rad-media--mp4__caption .rad-media--anchored__caption-text,.rad-media--mp4__caption-text {
        padding-top:calc(0.25rem + 0.27836vw - 4.01113px)
    }
}

@media(min-width: 1920px) {
    .rad-media--mp4__caption .rad-media--anchored__caption-text,.rad-media--mp4__caption-text {
        padding-top:0.3333333333rem
    }
}

.rad-media--mp4__caption .rad-media--anchored__caption-text:first-child,.rad-media--mp4__caption-text:first-child {
    color: #fff
}

.rad-media--mp4__caption .rad-button__icon-right {
    color: #fff;
    padding: .25rem .5rem 0
}

@media(min-width: 600px) {
    .rad-media--mp4__caption .rad-button__icon-right {
        padding:.25rem .5rem 0
    }
}

@media(min-width: 1024px) {
    .rad-media--mp4__caption .rad-button__icon-right {
        padding-top:0.3125rem
    }
}

@media(min-width: 1441px) {
    .rad-media--mp4__caption .rad-button__icon-right {
        padding-top:calc(0.3125rem + 0.34795vw - 5.01392px)
    }
}

@media(min-width: 1920px) {
    .rad-media--mp4__caption .rad-button__icon-right {
        padding-top:0.4166666667rem
    }
}

.rad-media--anchored__caption-text__wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    text-decoration: none
}


.rad-media--anchored__caption-text__wrapper .rad-media--anchored__caption-text {
    position: relative
}

.rad-media--anchored__caption-text__wrapper .rad-media--anchored__caption-text:after {
    border-bottom: 1px solid;
    bottom: 0;
    content: "";
    display: block;
    left: 0;
    position: absolute;
    -webkit-transition-duration: 550ms;
    transition-duration: 550ms;
    -webkit-transition-property: width;
    transition-property: width;
    -webkit-transition-timing-function: cubic-bezier(0.85,0,0,1);
    transition-timing-function: cubic-bezier(0.85,0,0,1);
    width: 0
}

.rad-media--anchored__caption-text__wrapper .rad-media--anchored__caption-text:hover:after {
    width: 100%
}

.rad-media video {
    aspect-ratio: 16/9;
    display: block;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%
}

.rad-media .rad-media-overlay--hide,.rad-media .s7videoviewer .s7controlbar,.rad-media .s7videoviewer .s7iconeffect,.rad-media .s7videoviewer .s7socialshare,.rad-media .s7videoviewer .s7waiticon {
    display: none
}

@media(min-width: 1024px) {
    .experiencefragment:has(.rad-media--sticky)~:not(.experiencefragment),.rad-media--sticky~:not(.rad-media--sticky) {
        background-color:#000;
        position: relative;
        z-index: 1
    }

    .experiencefragment:has(.rad-media--sticky),.rad-media--sticky {
        position: sticky;
        top: 0
    }
}

.rad-media .rad-button--ghost:hover {
    color: #fff
}

@media(min-width: 1024px) {
    .rad-nested-background-image--right-bleed {
        margin-right:calc(var(--rad-spacing-md)*-1)
    }
}

@media(min-width: 1024px) {
    .experiencefragment:has(.rad-nested-background-image--right-bleed)~:not(.experiencefragment),.rad-media--sticky:has(.rad-nested-background-image--right-bleed)~:not(.rad-media--sticky) {
        margin-right:calc(var(--rad-spacing-md)*-1);
        padding-right: var(--rad-spacing-md)
    }
}

.rad-nested-background-image .cmp-image__image {
    aspect-ratio: 3/2;
    display: block;
    -webkit-filter: blur(8px);
    filter: blur(8px)
}

.rad-aoi-segment {
    padding-left: var(--rad-spacing-sm);
    padding-right: var(--rad-spacing-sm)
}

@media(min-width: 600px) {
    .rad-aoi-segment {
        padding-left:var(--rad-spacing-md);
        padding-right: var(--rad-spacing-md)
    }
}

.rad-component--nested .rad-aoi-segment {
    padding-left: 0;
    padding-right: 0
}

.rad-aoi-segment__headline {
    font-size: 1.75rem;
    font-weight: 500;
    letter-spacing: -0.03em;
    line-height: 1.3;
    margin-bottom: 1.5rem
}

.rad-aoi-segment__headline:lang(ja-JP),.rad-aoi-segment__headline:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-aoi-segment__headline {
        font-size:2.5rem;
        line-height: 1.2
    }
}

@media(min-width: 1024px) {
    .rad-aoi-segment__headline {
        font-size:3rem;
        line-height: 1.2
    }
}

@media(min-width: 1441px) {
    .rad-aoi-segment__headline {
        font-size:calc(3rem + 3.34029vw - 48.13361px)
    }
}

@media(min-width: 1920px) {
    .rad-aoi-segment__headline {
        font-size:4rem
    }
}

@media(min-width: 600px) {
    .rad-aoi-segment__headline {
        margin-bottom:2.5rem;
        width: calc(150% - var(--rad-spacing-sm)*3/4*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-sm)*0);
        width: calc(75% - var(--rad-spacing-sm)*7/8*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-aoi-segment__headline {
        margin-bottom:3rem;
        width: calc(50% - var(--rad-spacing-sm)*11/12*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0);
        width: calc(200% - var(--rad-spacing-sm)*3/4*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-sm)*0)
    }
}

@media(min-width: 1441px) {
    .rad-aoi-segment__headline {
        margin-bottom:calc(3rem + 3.34029vw - 48.13361px)
    }
}

@media(min-width: 1920px) {
    .rad-aoi-segment__headline {
        margin-bottom:4rem
    }
}

@media(min-width: 1024px) {
    .rad-aoi-segment__headline {
        width:calc(100% - var(--rad-spacing-sm)*7/8*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0);
        width: calc(66.66667% - var(--rad-spacing-sm)*11/12*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0)
    }
}

.rad-aoi-segment .rad-horizontal-tabs__tabs-lists {
    left: 0;
    margin-top: .125rem;
    padding-left: 0;
    padding-right: 0;
    width: 100%
}

@media(min-width: 600px) {
    .rad-aoi-segment .rad-horizontal-tabs__tabs-lists {
        margin-top:0
    }
}

.rad-aoi-segment .rad-horizontal-tabs .cmp-tabs__tabpanel {
    margin-bottom: .5rem;
    margin-top: 1.5rem
}

@media(min-width: 600px) {
    .rad-aoi-segment .rad-horizontal-tabs .cmp-tabs__tabpanel {
        margin-bottom:0;
        margin-top: 1rem
    }
}

@media(min-width: 1024px) {
    .rad-aoi-segment .rad-horizontal-tabs .cmp-tabs__tabpanel {
        margin-top:1.5rem
    }
}

@media(min-width: 1441px) {
    .rad-aoi-segment .rad-horizontal-tabs .cmp-tabs__tabpanel {
        margin-top:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-aoi-segment .rad-horizontal-tabs .cmp-tabs__tabpanel {
        margin-top:2rem
    }
}

.rad-aoi-segment .rad-horizontal-tabs .cmp-tabs__tabpanel-container {
    padding-bottom: 1rem;
    padding-top: 1rem
}

@media(min-width: 600px) {
    .rad-aoi-segment .rad-horizontal-tabs .cmp-tabs__tabpanel-container {
        padding-bottom:0;
        padding-top: 1rem
    }
}

@media(min-width: 1024px) {
    .rad-aoi-segment .rad-horizontal-tabs .cmp-tabs__tabpanel-container {
        padding-top:1.5rem
    }
}

@media(min-width: 1441px) {
    .rad-aoi-segment .rad-horizontal-tabs .cmp-tabs__tabpanel-container {
        padding-top:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-aoi-segment .rad-horizontal-tabs .cmp-tabs__tabpanel-container {
        padding-top:2rem
    }
}

.rad-aoi-segment .rad-horizontal-tabs .cmp-tabs__tabpanel-title {
    font-size: 1.125rem;
    font-weight: 500;
    letter-spacing: -0.02em;
    line-height: 1.4;
    margin-bottom: .5rem
}

.rad-aoi-segment .rad-horizontal-tabs .cmp-tabs__tabpanel-title:lang(ja-JP),.rad-aoi-segment .rad-horizontal-tabs .cmp-tabs__tabpanel-title:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-aoi-segment .rad-horizontal-tabs .cmp-tabs__tabpanel-title {
        font-size:1.25rem;
        line-height: 1.4
    }
}

@media(min-width: 1024px) {
    .rad-aoi-segment .rad-horizontal-tabs .cmp-tabs__tabpanel-title {
        font-size:1.5rem;
        line-height: 1.3
    }
}

@media(min-width: 1441px) {
    .rad-aoi-segment .rad-horizontal-tabs .cmp-tabs__tabpanel-title {
        font-size:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-aoi-segment .rad-horizontal-tabs .cmp-tabs__tabpanel-title {
        font-size:2rem
    }
}

@media(min-width: 600px) {
    .rad-aoi-segment .rad-horizontal-tabs .cmp-tabs__tabpanel-title {
        margin-bottom:.75rem
    }
}

@media(min-width: 1024px) {
    .rad-aoi-segment .rad-horizontal-tabs .cmp-tabs__tabpanel-title {
        margin-bottom:1rem
    }
}

@media(min-width: 1441px) {
    .rad-aoi-segment .rad-horizontal-tabs .cmp-tabs__tabpanel-title {
        margin-bottom:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-aoi-segment .rad-horizontal-tabs .cmp-tabs__tabpanel-title {
        margin-bottom:1.3333333333rem
    }
}

.rad-aoi-segment .rad-horizontal-tabs .cmp-tabs__tabpanel-body {
    font-size: 1rem;
    font-weight: 500;
    font-weight: 400;
    letter-spacing: -0.005em;
    line-height: 1.6;
    text-underline-offset: .25rem
}

.rad-aoi-segment .rad-horizontal-tabs .cmp-tabs__tabpanel-body:lang(ja-JP),.rad-aoi-segment .rad-horizontal-tabs .cmp-tabs__tabpanel-body:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-aoi-segment .rad-horizontal-tabs .cmp-tabs__tabpanel-body {
        font-size:1.125rem
    }
}

@media(min-width: 1024px) {
    .rad-aoi-segment .rad-horizontal-tabs .cmp-tabs__tabpanel-body {
        font-size:1.25rem
    }
}

@media(min-width: 1441px) {
    .rad-aoi-segment .rad-horizontal-tabs .cmp-tabs__tabpanel-body {
        font-size:calc(1.25rem + 1.39179vw - 20.05567px)
    }
}

@media(min-width: 1920px) {
    .rad-aoi-segment .rad-horizontal-tabs .cmp-tabs__tabpanel-body {
        font-size:1.6666666667rem
    }
}

.rad-aoi-segment .rad-horizontal-tabs .cmp-tabs__tabpanel-body:lang(ja-JP),.rad-aoi-segment .rad-horizontal-tabs .cmp-tabs__tabpanel-body:lang(zh-CN) {
    font-weight: 400
}

@media(min-width: 600px) {
    .rad-aoi-segment .rad-horizontal-tabs .cmp-tabs__tabpanel-body {
        width:calc(150% - var(--rad-spacing-sm)*3/4*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-sm)*0);
        width: calc(75% - var(--rad-spacing-sm)*7/8*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-aoi-segment .rad-horizontal-tabs .cmp-tabs__tabpanel-body {
        width:calc(50% - var(--rad-spacing-sm)*11/12*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0);
        width: calc(200% - var(--rad-spacing-sm)*3/4*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-sm)*0);
        width: calc(100% - var(--rad-spacing-sm)*7/8*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0);
        width: calc(66.66667% - var(--rad-spacing-sm)*11/12*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0)
    }
}

.rad-aoi-segment .rad-horizontal-tabs-mobile-dropdown {
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
    width: 100%
}

.rad-aoi-segment__hidden-tabs .rad-aoi-segment__headline {
    margin-bottom: .5rem
}

@media(min-width: 600px) {
    .rad-aoi-segment__hidden-tabs .rad-aoi-segment__headline {
        margin-bottom:1.25rem
    }
}

@media(min-width: 1024px) {
    .rad-aoi-segment__hidden-tabs .rad-aoi-segment__headline {
        margin-bottom:1.5rem
    }
}

@media(min-width: 1441px) {
    .rad-aoi-segment__hidden-tabs .rad-aoi-segment__headline {
        margin-bottom:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-aoi-segment__hidden-tabs .rad-aoi-segment__headline {
        margin-bottom:2rem
    }
}

.rad-aoi-segment__hidden-tabs .rad-aoi-segment__cards-container {
    padding-top: .5rem
}

@media(min-width: 600px) {
    .rad-aoi-segment__hidden-tabs .rad-aoi-segment__cards-container {
        padding-top:1.25rem
    }
}

@media(min-width: 1441px) {
    .rad-aoi-segment__hidden-tabs .rad-aoi-segment__cards-container {
        padding-top:calc(1.25rem + 1.39179vw - 20.05567px)
    }
}

@media(min-width: 1920px) {
    .rad-aoi-segment__hidden-tabs .rad-aoi-segment__cards-container {
        padding-top:1.6666666667rem
    }
}

@media(min-width: 1024px) {
    .rad-aoi-segment__hidden-tabs .rad-aoi-segment__cards-container {
        padding-top:1.5rem
    }
}

@media(min-width: 1441px) {
    .rad-aoi-segment__hidden-tabs .rad-aoi-segment__cards-container {
        padding-top:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-aoi-segment__hidden-tabs .rad-aoi-segment__cards-container {
        padding-top:2rem
    }
}

.rad-aoi-segment__cards-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 1.5rem 0
}

@media(min-width: 600px) {
    .rad-aoi-segment__cards-container {
        padding-bottom:3.75rem;
        padding-top: 3.75rem
    }
}

@media(min-width: 1441px) {
    .rad-aoi-segment__cards-container {
        padding-bottom:calc(3.75rem + 4.17537vw - 60.16701px)
    }
}

@media(min-width: 1920px) {
    .rad-aoi-segment__cards-container {
        padding-bottom:5rem
    }
}

@media(min-width: 1441px) {
    .rad-aoi-segment__cards-container {
        padding-top:calc(3.75rem + 4.17537vw - 60.16701px)
    }
}

@media(min-width: 1920px) {
    .rad-aoi-segment__cards-container {
        padding-top:5rem
    }
}

.rad-aoi-segment__cards-list {
    display: grid;
    gap: 1.5rem
}

@media(min-width: 600px) {
    .rad-aoi-segment__cards-list {
        grid-template-columns:repeat(2,1fr)
    }
}

@media(min-width: 1024px) {
    .rad-aoi-segment__cards-list {
        gap:2rem;
        grid-template-columns: repeat(3,1fr)
    }
}

@media(min-width: 1441px) {
    .rad-aoi-segment__cards-list {
        gap:calc(2rem + 2.22686vw - 32.08907px)
    }
}

@media(min-width: 1920px) {
    .rad-aoi-segment__cards-list {
        gap:2.6666666667rem
    }
}

.rad-aoi-segment__cards-list--hidden {
    display: none
}

.rad-aoi-segment__no-results {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    row-gap: 3rem
}

@media(min-width: 600px) {
    .rad-aoi-segment__no-results {
        row-gap:2rem
    }
}

@media(min-width: 1024px) {
    .rad-aoi-segment__no-results {
        row-gap:3rem
    }
}

@media(min-width: 1441px) {
    .rad-aoi-segment__no-results {
        row-gap:calc(3rem + 3.34029vw - 48.13361px)
    }
}

@media(min-width: 1920px) {
    .rad-aoi-segment__no-results {
        row-gap:4rem
    }
}

.rad-aoi-segment__no-results--hidden {
    display: none
}

.rad-aoi-segment__no-results-text-content {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    margin: 0 auto;
    row-gap: 1.5rem;
    text-align: center
}

@media(min-width: 600px) {
    .rad-aoi-segment__no-results-text-content {
        width:calc(150% - var(--rad-spacing-sm)*3/4*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-sm)*0);
        width: calc(75% - var(--rad-spacing-sm)*7/8*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-aoi-segment__no-results-text-content {
        row-gap:1rem;
        width: calc(50% - var(--rad-spacing-sm)*11/12*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0);
        width: calc(200% - var(--rad-spacing-sm)*3/4*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-sm)*0);
        width: calc(100% - var(--rad-spacing-sm)*7/8*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0);
        width: calc(66.66667% - var(--rad-spacing-sm)*11/12*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1441px) {
    .rad-aoi-segment__no-results-text-content {
        row-gap:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-aoi-segment__no-results-text-content {
        row-gap:1.3333333333rem
    }
}

.rad-aoi-segment__no-results-text-content div {
    font-size: 1.375rem;
    font-weight: 500;
    letter-spacing: -0.02em;
    line-height: 1.25
}

.rad-aoi-segment__no-results-text-content div:lang(ja-JP),.rad-aoi-segment__no-results-text-content div:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-aoi-segment__no-results-text-content div {
        font-size:1.75rem;
        line-height: 1.3
    }
}

@media(min-width: 1024px) {
    .rad-aoi-segment__no-results-text-content div {
        font-size:2rem;
        line-height: 1.2
    }
}

@media(min-width: 1441px) {
    .rad-aoi-segment__no-results-text-content div {
        font-size:calc(2rem + 2.22686vw - 32.08907px)
    }
}

@media(min-width: 1920px) {
    .rad-aoi-segment__no-results-text-content div {
        font-size:2.6666666667rem
    }
}

.rad-aoi-segment__no-results-text-content p {
    font-size: .875rem;
    font-weight: 500;
    font-weight: 400;
    letter-spacing: -0.005em;
    line-height: 1.5;
    text-underline-offset: .25rem
}

.rad-aoi-segment__no-results-text-content p:lang(ja-JP),.rad-aoi-segment__no-results-text-content p:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-aoi-segment__no-results-text-content p {
        font-size:1rem
    }
}

@media(min-width: 1441px) {
    .rad-aoi-segment__no-results-text-content p {
        font-size:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-aoi-segment__no-results-text-content p {
        font-size:1.3333333333rem
    }
}

.rad-aoi-segment__no-results-text-content p:lang(ja-JP),.rad-aoi-segment__no-results-text-content p:lang(zh-CN) {
    font-weight: 400
}

.rad-aoi-segment__no-results-graphic-content {
    width: 320px
}

@media(min-width: 1441px) {
    .rad-aoi-segment__no-results-graphic-content {
        width:calc(-0.89074px + 22.26862vw)
    }
}

@media(min-width: 1920px) {
    .rad-aoi-segment__no-results-graphic-content {
        width:426.6666666667px
    }
}

@media(min-width: 600px) {
    .rad-aoi-segment__no-results-graphic-content {
        -webkit-box-ordinal-group:0;
        -ms-flex-order: -1;
        order: -1
    }
}

.rad-aoi-segment__pagination {
    margin-top: 1rem
}

@media(min-width: 600px) {
    .rad-aoi-segment__pagination {
        -ms-flex-item-align:end;
        align-self: flex-end;
        margin-top: 1.5rem
    }
}

@media(min-width: 1024px) {
    .rad-aoi-segment__pagination {
        margin-top:2rem
    }
}

@media(min-width: 1441px) {
    .rad-aoi-segment__pagination {
        margin-top:calc(2rem + 2.22686vw - 32.08907px)
    }
}

@media(min-width: 1920px) {
    .rad-aoi-segment__pagination {
        margin-top:2.6666666667rem
    }
}

.rad-aoi-segment__pagination--hidden {
    display: none
}

.rad-aoi-segment__eeo-wrapper {
    font-size: .875rem;
    font-weight: 500;
    font-weight: 400;
    letter-spacing: -0.005em;
    line-height: 1.5;
    margin-top: 3rem;
    text-underline-offset: .25rem;
    width: 100%
}

@media(min-width: 1441px) {
    .rad-aoi-segment__eeo-wrapper {
        margin-top:calc(3rem + 3.34029vw - 48.13361px)
    }
}

@media(min-width: 1920px) {
    .rad-aoi-segment__eeo-wrapper {
        margin-top:4rem
    }
}

.rad-aoi-segment__eeo-wrapper:lang(ja-JP),.rad-aoi-segment__eeo-wrapper:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-aoi-segment__eeo-wrapper {
        font-size:1rem
    }
}

@media(min-width: 1024px) {
    .rad-aoi-segment__eeo-wrapper {
        font-size:1.125rem;
        line-height: 1.6
    }
}

@media(min-width: 1441px) {
    .rad-aoi-segment__eeo-wrapper {
        font-size:calc(1.125rem + 1.25261vw - 18.0501px)
    }
}

@media(min-width: 1920px) {
    .rad-aoi-segment__eeo-wrapper {
        font-size:1.5rem
    }
}

.rad-aoi-segment__eeo-wrapper:lang(ja-JP),.rad-aoi-segment__eeo-wrapper:lang(zh-CN) {
    font-weight: 400
}

.rad-homepage-hero {
    color: #fff;
    overflow: hidden;
    padding-bottom: 16px;
    padding-left: var(--rad-spacing-sm);
    padding-right: var(--rad-spacing-sm);
    padding-top: 24px;
    position: relative
}

@media(min-width: 600px) {
    .rad-homepage-hero {
        padding-left:var(--rad-spacing-md);
        padding-right: var(--rad-spacing-md)
    }
}

.rad-component--nested .rad-homepage-hero {
    padding-left: 0;
    padding-right: 0
}

@media(min-width: 600px) {
    .rad-homepage-hero {
        padding-bottom:24px;
        padding-top: 48px
    }
}

@media(min-width: 1441px) {
    .rad-homepage-hero {
        padding-top:calc(-0.13361px + 3.34029vw)
    }
}

@media(min-width: 1920px) {
    .rad-homepage-hero {
        padding-top:64px
    }
}

@media(min-width: 1441px) {
    .rad-homepage-hero {
        padding-bottom:calc(-0.06681px + 1.67015vw)
    }
}

@media(min-width: 1920px) {
    .rad-homepage-hero {
        padding-bottom:32px
    }
}

.rad-homepage-hero__background {
    background-color: #000;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 0
}

.rad-homepage-hero__background--with-overlay:after {
    background-color: #000;
    bottom: 0;
    content: "";
    left: 0;
    opacity: .6;
    position: absolute;
    right: 0;
    top: 0
}

.rad-homepage-hero__background .cmp-image,.rad-homepage-hero__background .cmp-video-container {
    height: 100%;
    max-height: 100%
}

.rad-homepage-hero__background .cmp-video,.rad-homepage-hero__background .cmp-video__player {
    height: 100%
}

.rad-homepage-hero__background .cmp-image__image,.rad-homepage-hero__background video {
    display: block;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center;
    object-position: center;
    width: 100%
}

.rad-homepage-hero__inner {
    -webkit-animation: hero-content-reveal 1000ms cubic-bezier(0.85,0,0,1) 1ms forwards;
    animation: hero-content-reveal 1000ms cubic-bezier(0.85,0,0,1) 1ms forwards;
    opacity: 0;
    position: relative;
    z-index: 1
}

@media(min-width: 600px) {
    .rad-homepage-hero__top-content {
        width:calc(150% - var(--rad-spacing-sm)*3/4*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-sm)*0);
        width: calc(75% - var(--rad-spacing-sm)*7/8*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-homepage-hero__top-content {
        width:calc(50% - var(--rad-spacing-sm)*11/12*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0);
        width: calc(200% - var(--rad-spacing-sm)*3/4*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-sm)*0);
        width: calc(100% - var(--rad-spacing-sm)*7/8*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0);
        width: calc(66.66667% - var(--rad-spacing-sm)*11/12*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0)
    }
}

.rad-homepage-hero__eyebrow {
    font-size: .75rem;
    font-weight: 500;
    letter-spacing: .02em;
    line-height: 1.2;
    padding-bottom: 1rem;
    text-transform: uppercase
}

.rad-homepage-hero__eyebrow:lang(ja-JP),.rad-homepage-hero__eyebrow:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 1024px) {
    .rad-homepage-hero__eyebrow {
        font-size:0.875rem
    }
}

@media(min-width: 1441px) {
    .rad-homepage-hero__eyebrow {
        font-size:calc(0.875rem + 0.97425vw - 14.03897px)
    }
}

@media(min-width: 1920px) {
    .rad-homepage-hero__eyebrow {
        font-size:1.1666666667rem
    }
}

@media(min-width: 600px) {
    .rad-homepage-hero__eyebrow {
        padding-bottom:.5rem
    }
}

@media(min-width: 1024px) {
    .rad-homepage-hero__eyebrow {
        padding-bottom:1rem
    }
}

@media(min-width: 1441px) {
    .rad-homepage-hero__eyebrow {
        padding-bottom:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-homepage-hero__eyebrow {
        padding-bottom:1.3333333333rem
    }
}

.rad-homepage-hero__headline {
    font-size: 2rem;
    font-weight: 500;
    letter-spacing: -0.03em;
    line-height: 1.1
}

@media(min-width: 600px) {
    .rad-homepage-hero__headline {
        font-size:calc(4vh + 4vw)
    }
}

@media(min-width: 1024px) {
    .rad-homepage-hero__headline {
        font-size:calc(3vh + 4.2vw)
    }
}

@media(min-width: 1441px) {
    .rad-homepage-hero__headline {
        font-size:calc(5.2vh + 4vw)
    }
}

.rad-homepage-hero__headline:lang(ja-JP),.rad-homepage-hero__headline:lang(zh-CN) {
    font-weight: 700
}

.rad-homepage-hero__headline-line-one,.rad-homepage-hero__headline-line-two {
    display: block
}

.rad-homepage-hero__subheader {
    font-size: 1.375rem;
    font-weight: 500;
    letter-spacing: -0.02em;
    line-height: 1.25
}

.rad-homepage-hero__subheader:lang(ja-JP),.rad-homepage-hero__subheader:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-homepage-hero__subheader {
        font-size:1.75rem;
        line-height: 1.3
    }
}

@media(min-width: 1024px) {
    .rad-homepage-hero__subheader {
        font-size:2rem;
        line-height: 1.2
    }
}

@media(min-width: 1441px) {
    .rad-homepage-hero__subheader {
        font-size:calc(2rem + 2.22686vw - 32.08907px)
    }
}

@media(min-width: 1920px) {
    .rad-homepage-hero__subheader {
        font-size:2.6666666667rem
    }
}

.rad-homepage-hero .deco-line {
    background-color: #a100ff;
    display: block;
    height: 0.25rem;
    margin-bottom: 1rem;
    width: 2rem
}

@media(min-width: 1441px) {
    .rad-homepage-hero .deco-line {
        width:calc(2rem + 2.22686vw - 32.08907px)
    }
}

@media(min-width: 1920px) {
    .rad-homepage-hero .deco-line {
        width:2.6666666667rem
    }
}

@media(min-width: 1441px) {
    .rad-homepage-hero .deco-line {
        height:calc(0.25rem + 0.27836vw - 4.01113px)
    }
}

@media(min-width: 1920px) {
    .rad-homepage-hero .deco-line {
        height:0.3333333333rem
    }
}

@media(min-width: 600px) {
    .rad-homepage-hero .deco-line {
        margin-bottom:1.5rem
    }
}

@media(min-width: 1441px) {
    .rad-homepage-hero .deco-line {
        margin-bottom:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-homepage-hero .deco-line {
        margin-bottom:2rem
    }
}

.rad-homepage-hero__content-title {
    font-size: 1.125rem;
    font-weight: 500;
    letter-spacing: -0.02em;
    line-height: 1.4;
    padding-bottom: .5rem
}

.rad-homepage-hero__content-title:lang(ja-JP),.rad-homepage-hero__content-title:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-homepage-hero__content-title {
        font-size:1.25rem;
        line-height: 1.4
    }
}

@media(min-width: 1024px) {
    .rad-homepage-hero__content-title {
        font-size:1.5rem;
        line-height: 1.3
    }
}

@media(min-width: 1441px) {
    .rad-homepage-hero__content-title {
        font-size:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-homepage-hero__content-title {
        font-size:2rem
    }
}

@media(min-width: 600px) {
    .rad-homepage-hero__content-title {
        padding-bottom:.5rem
    }
}

@media(min-width: 1024px) {
    .rad-homepage-hero__content-title {
        padding-bottom:1rem
    }
}

@media(min-width: 1441px) {
    .rad-homepage-hero__content-title {
        padding-bottom:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-homepage-hero__content-title {
        padding-bottom:1.3333333333rem
    }
}

.rad-homepage-hero__content-copy {
    font-size: .875rem;
    font-weight: 500;
    font-weight: 400;
    letter-spacing: -0.005em;
    line-height: 1.5;
    padding-bottom: .25rem;
    text-underline-offset: .25rem
}

.rad-homepage-hero__content-copy:lang(ja-JP),.rad-homepage-hero__content-copy:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-homepage-hero__content-copy {
        font-size:1rem
    }
}

@media(min-width: 1441px) {
    .rad-homepage-hero__content-copy {
        font-size:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-homepage-hero__content-copy {
        font-size:1.3333333333rem
    }
}

.rad-homepage-hero__content-copy:lang(ja-JP),.rad-homepage-hero__content-copy:lang(zh-CN) {
    font-weight: 400
}

@media(min-width: 1024px) {
    .rad-homepage-hero__content-copy {
        padding-bottom:0.75rem
    }
}

@media(min-width: 1441px) {
    .rad-homepage-hero__content-copy {
        padding-bottom:calc(0.75rem + 0.83507vw - 12.0334px)
    }
}

@media(min-width: 1920px) {
    .rad-homepage-hero__content-copy {
        padding-bottom:1rem
    }
}

.rad-homepage-hero__media-control {
    z-index: 1
}

.rad-homepage-hero__media-control--main {
    bottom: 0;
    left: 0;
    position: absolute
}

.rad-homepage-hero__media-control--foreground {
    position: relative;
    top: -48px
}

@media(min-width: 1441px) {
    .rad-homepage-hero__media-control--foreground {
        top:calc(0.13361px - 3.34029vw)
    }
}

@media(min-width: 1920px) {
    .rad-homepage-hero__media-control--foreground {
        top:-64px
    }
}

.rad-homepage-hero__media-control .rad-icon__play-default,.rad-homepage-hero__media-control--hidden {
    display: none
}

.rad-homepage-hero__content {
    margin-left: calc(25% - var(--rad-spacing-sm)*3/4*1 + var(--rad-spacing-sm)*0 + var(--rad-spacing-sm)*0);
    width: calc(75% - var(--rad-spacing-sm)*3/4*3 + var(--rad-spacing-sm)*2 + var(--rad-spacing-sm)*0)
}

@media(min-width: 600px) {
    .rad-homepage-hero__content {
        width:calc(37.5% - var(--rad-spacing-sm)*7/8*3 + var(--rad-spacing-sm)*2 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-homepage-hero__content {
        width:calc(25% - var(--rad-spacing-sm)*11/12*3 + var(--rad-spacing-sm)*2 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 600px) {
    .rad-homepage-hero__content {
        margin-left:calc(37.5% - var(--rad-spacing-sm)*7/8*3 + var(--rad-spacing-sm)*2 + var(--rad-spacing-md)*0);
        width: calc(125% - var(--rad-spacing-sm)*3/4*5 + var(--rad-spacing-sm)*4 + var(--rad-spacing-sm)*0);
        width: calc(62.5% - var(--rad-spacing-sm)*7/8*5 + var(--rad-spacing-sm)*4 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-homepage-hero__content {
        -ms-flex-item-align:end;
        align-self: flex-end;
        margin-left: 0;
        width: calc(41.66667% - var(--rad-spacing-sm)*11/12*5 + var(--rad-spacing-sm)*4 + var(--rad-spacing-md)*0);
        width: calc(100% - var(--rad-spacing-sm)*3/4*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-sm)*0);
        width: calc(50% - var(--rad-spacing-sm)*7/8*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0);
        width: calc(33.33333% - var(--rad-spacing-sm)*11/12*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0)
    }
}

.rad-homepage-hero__foreground-media {
    height: 6.5rem;
    margin-bottom: 1rem;
    width: 9.75rem
}

@media(min-width: 600px) {
    .rad-homepage-hero__foreground-media {
        height:9.875rem;
        margin-bottom: 1.5rem;
        width: 14.8125rem
    }
}

@media(min-width: 1024px) {
    .rad-homepage-hero__foreground-media {
        height:9rem;
        width: 13.5625rem
    }
}

@media(min-width: 1441px) {
    .rad-homepage-hero__foreground-media {
        width:calc(13.5625rem + 15.1009vw - 217.60404px)
    }
}

@media(min-width: 1920px) {
    .rad-homepage-hero__foreground-media {
        width:18.0833333333rem
    }
}

@media(min-width: 1441px) {
    .rad-homepage-hero__foreground-media {
        height:calc(9rem + 10.02088vw - 144.40084px)
    }
}

@media(min-width: 1920px) {
    .rad-homepage-hero__foreground-media {
        height:12rem
    }
}

.rad-homepage-hero__foreground-media .cmp-image,.rad-homepage-hero__foreground-media .cmp-video,.rad-homepage-hero__foreground-media .cmp-video-container,.rad-homepage-hero__foreground-media .cmp-video__player {
    height: 100%
}

.rad-homepage-hero__foreground-media .cmp-image__image,.rad-homepage-hero__foreground-media video {
    display: block;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center;
    object-position: center;
    width: 100%
}

.rad-homepage-hero__foreground-media--with-overlay {
    position: relative
}

.rad-homepage-hero__foreground-media--with-overlay:after {
    background-color: #000;
    bottom: 0;
    content: "";
    left: 0;
    opacity: .6;
    position: absolute;
    right: 0;
    top: 0
}

.rad-homepage-hero--small .rad-homepage-hero__inner {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    justify-content: space-between;
    min-height: calc(15rem - 40px);
    row-gap: 1.5rem
}

@media(min-width: 600px) {
    .rad-homepage-hero--small .rad-homepage-hero__inner {
        min-height:calc(20rem - 72px);
        row-gap: 2rem
    }
}

@media(min-width: 1024px) {
    .rad-homepage-hero--small .rad-homepage-hero__inner {
        min-height:174px;
        row-gap: 1rem
    }
}

@media(min-width: 1441px) {
    .rad-homepage-hero--small .rad-homepage-hero__inner {
        row-gap:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-homepage-hero--small .rad-homepage-hero__inner {
        row-gap:1.3333333333rem
    }
}

@media(min-width: 1441px) {
    .rad-homepage-hero--small .rad-homepage-hero__inner {
        min-height:calc(-0.48434px + 12.10856vw)
    }
}

@media(min-width: 1920px) {
    .rad-homepage-hero--small .rad-homepage-hero__inner {
        min-height:232px
    }
}

.rad-homepage-hero--small .rad-homepage-hero__media-control--main {
    left: unset;
    right: 0
}

@media(min-width: 1024px) {
    .rad-homepage-hero--small .rad-homepage-hero__headline-line-one,.rad-homepage-hero--small .rad-homepage-hero__headline-line-two {
        width:calc(250% - var(--rad-spacing-sm)*3/4*10 + var(--rad-spacing-sm)*9 + var(--rad-spacing-sm)*0);
        width: calc(125% - var(--rad-spacing-sm)*7/8*10 + var(--rad-spacing-sm)*9 + var(--rad-spacing-md)*0);
        width: calc(83.33333% - var(--rad-spacing-sm)*11/12*10 + var(--rad-spacing-sm)*9 + var(--rad-spacing-md)*0)
    }
}

.rad-homepage-hero--medium .rad-homepage-hero__inner {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    justify-content: space-between;
    min-height: calc(17.5rem - 40px);
    row-gap: 1.5rem
}

@media(min-width: 600px) {
    .rad-homepage-hero--medium .rad-homepage-hero__inner {
        min-height:328px;
        row-gap: 3rem
    }
}

@media(min-width: 1441px) {
    .rad-homepage-hero--medium .rad-homepage-hero__inner {
        min-height:calc(-0.91301px + 22.82533vw)
    }
}

@media(min-width: 1920px) {
    .rad-homepage-hero--medium .rad-homepage-hero__inner {
        min-height:437.3333333333px
    }
}

@media(min-width: 1024px) {
    .rad-homepage-hero--medium .rad-homepage-hero__inner {
        row-gap:5rem
    }
}

@media(min-width: 1441px) {
    .rad-homepage-hero--medium .rad-homepage-hero__inner {
        row-gap:calc(5rem + 5.56715vw - 80.22269px)
    }
}

@media(min-width: 1920px) {
    .rad-homepage-hero--medium .rad-homepage-hero__inner {
        row-gap:6.6666666667rem
    }
}

.rad-homepage-hero--medium .rad-homepage-hero__content {
    -webkit-animation: content-shift-left 800ms cubic-bezier(0,-0.01,0.01,1) 1051ms forwards;
    animation: content-shift-left 800ms cubic-bezier(0,-0.01,0.01,1) 1051ms forwards;
    opacity: 0
}

.rad-homepage-hero--large .rad-homepage-hero__inner {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-height: calc(17.5rem - 40px);
    row-gap: 1rem
}

.rad-homepage-hero--large .rad-homepage-hero__inner .rad-homepage-hero__eyebrow,.rad-homepage-hero--large .rad-homepage-hero__inner .rad-homepage-hero__headline {
    padding-bottom: 0
}

.rad-homepage-hero--large .rad-homepage-hero__inner .rad-homepage-hero__content {
    -ms-flex-item-align: end;
    align-self: flex-end;
    padding-top: .5rem
}

@media(min-width: 600px) {
    .rad-homepage-hero--large .rad-homepage-hero__inner {
        display:grid;
        grid-template-areas: "headline headline" "subheader subheader" ". content";
        grid-template-columns: calc(37.5% - var(--rad-spacing-sm)*7/8*3 + var(--rad-spacing-sm)*2 + var(--rad-spacing-md)*0) calc(62.5% - var(--rad-spacing-sm)*7/8*5 + var(--rad-spacing-sm)*4 + var(--rad-spacing-md)*0);
        grid-template-rows: auto;
        min-height: calc(33.875rem - 72px);
        row-gap: 0
    }

    .rad-homepage-hero--large .rad-homepage-hero__headline {
        grid-area: headline
    }

    .rad-homepage-hero--large .rad-homepage-hero__subheader {
        grid-area: subheader
    }

    .rad-homepage-hero--large .rad-homepage-hero__content {
        grid-area: content;
        padding-top: 0
    }

    .rad-homepage-hero--large .rad-homepage-hero__content-title {
        padding-bottom: .75rem
    }

    .rad-homepage-hero--large.rad-homepage-hero--eyebrow .rad-homepage-hero__inner {
        grid-template-areas: "eyebrow eyebrow" "headline headline" "subheader subheader" ". content";
        grid-template-rows: -webkit-min-content -webkit-min-content -webkit-min-content auto;
        grid-template-rows: min-content min-content min-content auto
    }

    .rad-homepage-hero--large.rad-homepage-hero--eyebrow .rad-homepage-hero__eyebrow {
        grid-area: eyebrow;
        padding-bottom: .5rem
    }
}

@media(min-width: 1024px) {
    .rad-homepage-hero--large.rad-homepage-hero--eyebrow .rad-homepage-hero__eyebrow {
        padding-bottom:1rem
    }
}

@media(min-width: 1441px) {
    .rad-homepage-hero--large.rad-homepage-hero--eyebrow .rad-homepage-hero__eyebrow {
        padding-bottom:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-homepage-hero--large.rad-homepage-hero--eyebrow .rad-homepage-hero__eyebrow {
        padding-bottom:1.3333333333rem
    }
}

@media(min-width: 600px) {
    .rad-homepage-hero--large.rad-homepage-hero--eyebrow .rad-homepage-hero__headline {
        padding-bottom:1rem
    }

    .rad-homepage-hero--large.rad-homepage-hero--eyebrow .rad-homepage-hero__subheader {
        padding-bottom: 2rem
    }
}

@media(min-width: 1024px) {
    .rad-homepage-hero--large .rad-homepage-hero__inner {
        -webkit-column-gap:2rem;
        -moz-column-gap: 2rem;
        column-gap: 2rem;
        grid-template-areas: "headline headline" "subheader content";
        grid-template-columns: calc(66.66667% - var(--rad-spacing-sm)*11/12*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0) calc(33.33333% - var(--rad-spacing-sm)*11/12*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0);
        min-height: 432px;
        row-gap: 2rem
    }
}

@media(min-width: 1441px) {
    .rad-homepage-hero--large .rad-homepage-hero__inner {
        row-gap:calc(2rem + 2.22686vw - 32.08907px)
    }
}

@media(min-width: 1920px) {
    .rad-homepage-hero--large .rad-homepage-hero__inner {
        row-gap:2.6666666667rem
    }
}

@media(min-width: 1441px) {
    .rad-homepage-hero--large .rad-homepage-hero__inner {
        -webkit-column-gap:calc(2rem + 2.22686vw - 32.08907px);
        -moz-column-gap: calc(2rem + 2.22686vw - 32.08907px);
        column-gap: calc(2rem + 2.22686vw - 32.08907px)
    }
}

@media(min-width: 1920px) {
    .rad-homepage-hero--large .rad-homepage-hero__inner {
        -webkit-column-gap:2.6666666667rem;
        -moz-column-gap: 2.6666666667rem;
        column-gap: 2.6666666667rem
    }
}

@media(min-width: 1441px) {
    .rad-homepage-hero--large .rad-homepage-hero__inner {
        min-height:calc(-1.20251px + 30.06263vw)
    }
}

@media(min-width: 1920px) {
    .rad-homepage-hero--large .rad-homepage-hero__inner {
        min-height:576px
    }
}

@media(min-width: 1024px) {
    .rad-homepage-hero--large .rad-homepage-hero__headline {
        grid-area:headline;
        padding-bottom: 0
    }

    .rad-homepage-hero--large .rad-homepage-hero__content {
        align-self: end;
        grid-area: content;
        justify-self: end;
        margin-left: 0;
        width: auto
    }

    .rad-homepage-hero--large .rad-homepage-hero__content-title {
        padding-bottom: 1rem
    }

    .rad-homepage-hero--large.rad-homepage-hero--eyebrow .rad-homepage-hero__inner {
        grid-template-areas: "eyebrow eyebrow" "headline headline" "subheader content";
        grid-template-rows: -webkit-min-content auto auto;
        grid-template-rows: min-content auto auto;
        row-gap: 0
    }

    .rad-homepage-hero--large.rad-homepage-hero--eyebrow .rad-homepage-hero__eyebrow {
        grid-area: eyebrow
    }

    .rad-homepage-hero--large.rad-homepage-hero--eyebrow .rad-homepage-hero__headline {
        padding-bottom: 2rem
    }
}

@media(min-width: 1441px) {
    .rad-homepage-hero--large.rad-homepage-hero--eyebrow .rad-homepage-hero__headline {
        padding-bottom:calc(2rem + 2.22686vw - 32.08907px)
    }
}

@media(min-width: 1920px) {
    .rad-homepage-hero--large.rad-homepage-hero--eyebrow .rad-homepage-hero__headline {
        padding-bottom:2.6666666667rem
    }
}

.rad-homepage-hero--center .rad-homepage-hero__inner {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.rad-homepage-hero--center .rad-homepage-hero__headline {
    text-align: center
}

@media(min-width: 1024px) {
    .rad-homepage-hero--center .rad-homepage-hero__headline .rad-homepage-hero__headline-line-one,.rad-homepage-hero--center .rad-homepage-hero__headline .rad-homepage-hero__headline-line-two {
        margin:0 auto
    }
}

.rad-homepage-hero--left .rad-homepage-hero__inner {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start
}

.rad-homepage-hero--cross .rad-homepage-hero__headline-line-one {
    text-align: left
}

@media(min-width: 1024px) {
    .rad-homepage-hero--cross .rad-homepage-hero__headline-line-one {
        -webkit-animation:headline-text-left 800ms cubic-bezier(0,-0.01,0.01,1) 1051ms forwards;
        animation: headline-text-left 800ms cubic-bezier(0,-0.01,0.01,1) 1051ms forwards;
        -webkit-transform: translateX(10%);
        transform: translateX(10%)
    }
}

.rad-homepage-hero--cross .rad-homepage-hero__headline-line-two {
    text-align: right
}

@media(min-width: 1024px) {
    .rad-homepage-hero--cross .rad-homepage-hero__headline-line-two {
        -webkit-animation:headline-text-right 800ms cubic-bezier(0,-0.01,0.01,1) 1051ms forwards;
        animation: headline-text-right 800ms cubic-bezier(0,-0.01,0.01,1) 1051ms forwards;
        -webkit-transform: translateX(-10%);
        transform: translateX(-10%)
    }
}

.rad-homepage-hero--cross .rad-homepage-hero__cta {
    -ms-flex-item-align: center;
    align-self: center
}

.rad-homepage-hero--media-fit .rad-homepage-hero__background .cmp-image {
    height: 100%
}

.rad-homepage-hero--media-fit .rad-homepage-hero__background .cmp-image__image,.rad-homepage-hero--media-fit .rad-homepage-hero__background video {
    margin: 0 auto;
    -o-object-fit: contain;
    object-fit: contain;
    width: unset
}

@media(min-width: 1024px) {
    .rad-homepage-hero.rad-homepage-hero--small.rad-homepage-hero--cross .rad-homepage-hero__headline {
        -webkit-box-orient:vertical;
        -webkit-box-direction: normal;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column
    }

    .rad-homepage-hero.rad-homepage-hero--small.rad-homepage-hero--cross .rad-homepage-hero__headline-line-one {
        -ms-flex-item-align: start;
        align-self: start;
        text-align: left;
        width: calc(250% - var(--rad-spacing-sm)*3/4*10 + var(--rad-spacing-sm)*9 + var(--rad-spacing-sm)*0);
        width: calc(125% - var(--rad-spacing-sm)*7/8*10 + var(--rad-spacing-sm)*9 + var(--rad-spacing-md)*0);
        width: calc(83.33333% - var(--rad-spacing-sm)*11/12*10 + var(--rad-spacing-sm)*9 + var(--rad-spacing-md)*0)
    }

    .rad-homepage-hero.rad-homepage-hero--small.rad-homepage-hero--cross .rad-homepage-hero__headline-line-two {
        -ms-flex-item-align: end;
        align-self: end;
        text-align: right;
        width: calc(250% - var(--rad-spacing-sm)*3/4*10 + var(--rad-spacing-sm)*9 + var(--rad-spacing-sm)*0);
        width: calc(125% - var(--rad-spacing-sm)*7/8*10 + var(--rad-spacing-sm)*9 + var(--rad-spacing-md)*0);
        width: calc(83.33333% - var(--rad-spacing-sm)*11/12*10 + var(--rad-spacing-sm)*9 + var(--rad-spacing-md)*0)
    }

    .rad-homepage-hero.rad-homepage-hero--medium.rad-homepage-hero--left .rad-homepage-hero__top-content {
        -webkit-animation: medium-left-shift 800ms cubic-bezier(0,-0.01,0.01,1) 1051ms forwards;
        animation: medium-left-shift 800ms cubic-bezier(0,-0.01,0.01,1) 1051ms forwards;
        -webkit-transform: translateX(calc(8.33333% - var(--rad-spacing-sm)*11/12*1 + var(--rad-spacing-sm)*0 + var(--rad-spacing-md)*0));
        transform: translateX(calc(8.33333% - var(--rad-spacing-sm)*11/12*1 + var(--rad-spacing-sm)*0 + var(--rad-spacing-md)*0))
    }

    .rad-homepage-hero.rad-homepage-hero--medium.rad-homepage-hero--cross .rad-homepage-hero__inner {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row
    }

    .rad-homepage-hero.rad-homepage-hero--medium.rad-homepage-hero--cross .rad-homepage-hero__headline {
        padding-bottom: 5rem
    }
}

@media(min-width: 1441px) {
    .rad-homepage-hero.rad-homepage-hero--medium.rad-homepage-hero--cross .rad-homepage-hero__headline {
        padding-bottom:calc(5rem + 5.56715vw - 80.22269px)
    }
}

@media(min-width: 1920px) {
    .rad-homepage-hero.rad-homepage-hero--medium.rad-homepage-hero--cross .rad-homepage-hero__headline {
        padding-bottom:6.6666666667rem
    }
}

@-webkit-keyframes hero-content-reveal {
    0% {
        opacity: 0;
        -webkit-transform: translateY(100%);
        transform: translateY(100%)
    }

    to {
        opacity: 1;
        -webkit-transform: translateY(0%);
        transform: translateY(0%)
    }
}

@keyframes hero-content-reveal {
    0% {
        opacity: 0;
        -webkit-transform: translateY(100%);
        transform: translateY(100%)
    }

    to {
        opacity: 1;
        -webkit-transform: translateY(0%);
        transform: translateY(0%)
    }
}

@-webkit-keyframes headline-text-left {
    0% {
        -webkit-transform: translateX(10%);
        transform: translateX(10%)
    }

    to {
        -webkit-transform: translateX(0%);
        transform: translateX(0%)
    }
}

@keyframes headline-text-left {
    0% {
        -webkit-transform: translateX(10%);
        transform: translateX(10%)
    }

    to {
        -webkit-transform: translateX(0%);
        transform: translateX(0%)
    }
}

@-webkit-keyframes headline-text-right {
    0% {
        -webkit-transform: translateX(-10%);
        transform: translateX(-10%)
    }

    to {
        -webkit-transform: translateX(0%);
        transform: translateX(0%)
    }
}

@keyframes headline-text-right {
    0% {
        -webkit-transform: translateX(-10%);
        transform: translateX(-10%)
    }

    to {
        -webkit-transform: translateX(0%);
        transform: translateX(0%)
    }
}

@-webkit-keyframes content-shift-left {
    0% {
        opacity: 0;
        -webkit-transform: translateX(100%);
        transform: translateX(100%)
    }

    to {
        opacity: 1;
        -webkit-transform: translateX(0%);
        transform: translateX(0%)
    }
}

@keyframes content-shift-left {
    0% {
        opacity: 0;
        -webkit-transform: translateX(100%);
        transform: translateX(100%)
    }

    to {
        opacity: 1;
        -webkit-transform: translateX(0%);
        transform: translateX(0%)
    }
}

@-webkit-keyframes medium-left-shift {
    0% {
        -webkit-transform: translateX(calc(8.33333% - var(--rad-spacing-sm)*11/12*1 + var(--rad-spacing-sm)*0 + var(--rad-spacing-md)*0));
        transform: translateX(calc(8.33333% - var(--rad-spacing-sm)*11/12*1 + var(--rad-spacing-sm)*0 + var(--rad-spacing-md)*0))
    }

    to {
        -webkit-transform: translateX(0%);
        transform: translateX(0%)
    }
}

@keyframes medium-left-shift {
    0% {
        -webkit-transform: translateX(calc(8.33333% - var(--rad-spacing-sm)*11/12*1 + var(--rad-spacing-sm)*0 + var(--rad-spacing-md)*0));
        transform: translateX(calc(8.33333% - var(--rad-spacing-sm)*11/12*1 + var(--rad-spacing-sm)*0 + var(--rad-spacing-md)*0))
    }

    to {
        -webkit-transform: translateX(0%);
        transform: translateX(0%)
    }
}

.rad-search-locations-office-card {
    background-color: #202020;
    cursor: pointer
}

.rad-search-locations-office-card:hover {
    background-color: #2b2b2b
}

.rad-search-locations-office-card:active {
    background-color: #101010
}

.rad-search-locations-office-card__link {
    text-decoration: none
}

.rad-search-locations-office-card__link:focus,.rad-search-locations-office-card__link:focus-visible,.rad-search-locations-office-card__link:hover {
    color: #fff;
    text-decoration: none
}

.rad-search-locations-office-card__title {
    color: #fff;
    font-size: .875rem;
    font-weight: 500;
    letter-spacing: -0.005em;
    line-height: 1.5;
    padding: 1rem;
    text-underline-offset: .25rem
}

.rad-search-locations-office-card__title:lang(ja-JP),.rad-search-locations-office-card__title:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-search-locations-office-card__title {
        font-size:1rem
    }
}

@media(min-width: 1024px) {
    .rad-search-locations-office-card__title {
        font-size:1.125rem;
        line-height: 1.6
    }
}

@media(min-width: 1441px) {
    .rad-search-locations-office-card__title {
        font-size:calc(1.125rem + 1.25261vw - 18.0501px)
    }
}

@media(min-width: 1920px) {
    .rad-search-locations-office-card__title {
        font-size:1.5rem
    }
}

@media(min-width: 600px) {
    .rad-search-locations-office-card__title {
        padding:1rem 1.5rem
    }
}

@media(min-width: 1024px) {
    .rad-search-locations-office-card__title {
        padding:1.25rem 1.5rem
    }
}

@media(min-width: 1441px) {
    .rad-search-locations-office-card__title {
        padding-top:calc(1.25rem + 1.39179vw - 20.05567px)
    }
}

@media(min-width: 1920px) {
    .rad-search-locations-office-card__title {
        padding-top:1.6666666667rem
    }
}

@media(min-width: 1441px) {
    .rad-search-locations-office-card__title {
        padding-bottom:calc(1.25rem + 1.39179vw - 20.05567px)
    }
}

@media(min-width: 1920px) {
    .rad-search-locations-office-card__title {
        padding-bottom:1.6666666667rem
    }
}

@media(min-width: 1441px) {
    .rad-search-locations-office-card__title {
        padding-left:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-search-locations-office-card__title {
        padding-left:2rem
    }
}

@media(min-width: 1441px) {
    .rad-search-locations-office-card__title {
        padding-right:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-search-locations-office-card__title {
        padding-right:2rem
    }
}

.rad-search-locations-office-card__map {
    display: none;
    width: 100%
}

@media(min-width: 600px) {
    .rad-search-locations-office-card__map {
        display:block;
        height: 7.6875rem
    }
}

@media(min-width: 1024px) {
    .rad-search-locations-office-card__map {
        height:9.0625rem
    }
}

.rad-search-locations-office-card__map-image {
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%
}

.rad-search-locations__card-list {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    grid-gap: .25rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-bottom: 1.5rem;
    padding-top: 2rem
}

@media(min-width: 600px) {
    .rad-search-locations__card-list {
        grid-gap:1.5rem;
        display: grid;
        grid-template-columns: 1fr 1fr;
        padding-bottom: 3.75rem;
        padding-top: 3rem
    }
}

@media(min-width: 1441px) {
    .rad-search-locations__card-list {
        padding-top:calc(3rem + 3.34029vw - 48.13361px)
    }
}

@media(min-width: 1920px) {
    .rad-search-locations__card-list {
        padding-top:4rem
    }
}

@media(min-width: 1441px) {
    .rad-search-locations__card-list {
        padding-bottom:calc(3.75rem + 4.17537vw - 60.16701px)
    }
}

@media(min-width: 1920px) {
    .rad-search-locations__card-list {
        padding-bottom:5rem
    }
}

@media(min-width: 1024px) {
    .rad-search-locations__card-list {
        -webkit-column-gap:2rem;
        -moz-column-gap: 2rem;
        column-gap: 2rem;
        grid-template-columns: auto auto auto;
        row-gap: 3rem
    }
}

@media(min-width: 1441px) {
    .rad-search-locations__card-list {
        row-gap:calc(3rem + 3.34029vw - 48.13361px)
    }
}

@media(min-width: 1920px) {
    .rad-search-locations__card-list {
        row-gap:4rem
    }
}

@media(min-width: 1441px) {
    .rad-search-locations__card-list {
        -webkit-column-gap:calc(2rem + 2.22686vw - 32.08907px);
        -moz-column-gap: calc(2rem + 2.22686vw - 32.08907px);
        column-gap: calc(2rem + 2.22686vw - 32.08907px)
    }
}

@media(min-width: 1920px) {
    .rad-search-locations__card-list {
        -webkit-column-gap:2.6666666667rem;
        -moz-column-gap: 2.6666666667rem;
        column-gap: 2.6666666667rem
    }
}

.rad-search-locations__office-details-list {
    grid-row-gap: 2rem;
    display: grid;
    grid-template-columns: 1fr;
    margin-bottom: 1.5rem;
    margin-top: 2rem
}

@media(min-width: 600px) {
    .rad-search-locations__office-details-list {
        margin-bottom:3.75rem;
        margin-top: 3rem;
        row-gap: 3rem
    }
}

@media(min-width: 1441px) {
    .rad-search-locations__office-details-list {
        row-gap:calc(3rem + 3.34029vw - 48.13361px)
    }
}

@media(min-width: 1920px) {
    .rad-search-locations__office-details-list {
        row-gap:4rem
    }
}

@media(min-width: 1441px) {
    .rad-search-locations__office-details-list {
        margin-top:calc(3rem + 3.34029vw - 48.13361px)
    }
}

@media(min-width: 1920px) {
    .rad-search-locations__office-details-list {
        margin-top:4rem
    }
}

@media(min-width: 1441px) {
    .rad-search-locations__office-details-list {
        margin-bottom:calc(3.75rem + 4.17537vw - 60.16701px)
    }
}

@media(min-width: 1920px) {
    .rad-search-locations__office-details-list {
        margin-bottom:5rem
    }
}

.rad-search-locations-office-details {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    background-color: #202020;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse
}

.rad-search-locations-office-details:hover {
    background-color: #2b2b2b
}

@media(min-width: 600px) {
    .rad-search-locations-office-details {
        -webkit-box-orient:horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row
    }
}

.rad-search-locations-office-details__office-name {
    color: #fff;
    font-size: 1.125rem;
    font-weight: 500;
    letter-spacing: -0.02em;
    line-height: 1.4;
    margin-bottom: 0.5rem
}

.rad-search-locations-office-details__office-name:lang(ja-JP),.rad-search-locations-office-details__office-name:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-search-locations-office-details__office-name {
        font-size:1.25rem;
        line-height: 1.4
    }
}

@media(min-width: 1024px) {
    .rad-search-locations-office-details__office-name {
        font-size:1.5rem;
        line-height: 1.3
    }
}

@media(min-width: 1441px) {
    .rad-search-locations-office-details__office-name {
        font-size:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-search-locations-office-details__office-name {
        font-size:2rem
    }
}

@media(min-width: 1441px) {
    .rad-search-locations-office-details__office-name {
        margin-bottom:calc(0.5rem + 0.55672vw - 8.02227px)
    }
}

@media(min-width: 1920px) {
    .rad-search-locations-office-details__office-name {
        margin-bottom:0.6666666667rem
    }
}

.rad-search-locations-office-details__container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column
}

@media(min-width: 1024px) {
    .rad-search-locations-office-details__container {
        -webkit-box-orient:horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row
    }
}

.rad-search-locations-office-details__info {
    padding: 1.5rem 1rem 2rem
}

@media(min-width: 600px) {
    .rad-search-locations-office-details__info {
        padding:2rem;
        width: 50%
    }
}

@media(min-width: 1441px) {
    .rad-search-locations-office-details__info {
        padding-top:calc(2rem + 2.22686vw - 32.08907px)
    }
}

@media(min-width: 1920px) {
    .rad-search-locations-office-details__info {
        padding-top:2.6666666667rem
    }
}

@media(min-width: 1441px) {
    .rad-search-locations-office-details__info {
        padding-bottom:calc(2rem + 2.22686vw - 32.08907px)
    }
}

@media(min-width: 1920px) {
    .rad-search-locations-office-details__info {
        padding-bottom:2.6666666667rem
    }
}

@media(min-width: 1441px) {
    .rad-search-locations-office-details__info {
        padding-left:calc(2rem + 2.22686vw - 32.08907px)
    }
}

@media(min-width: 1920px) {
    .rad-search-locations-office-details__info {
        padding-left:2.6666666667rem
    }
}

@media(min-width: 1441px) {
    .rad-search-locations-office-details__info {
        padding-right:calc(2rem + 2.22686vw - 32.08907px)
    }
}

@media(min-width: 1920px) {
    .rad-search-locations-office-details__info {
        padding-right:2.6666666667rem
    }
}

.rad-search-locations-office-details__region {
    display: inline-block;
    font-size: .75rem;
    font-weight: 500;
    font-weight: 400;
    letter-spacing: -0.005em;
    line-height: 1.5;
    margin-bottom: 2rem;
    text-transform: none;
    text-underline-offset: .25rem
}

.rad-search-locations-office-details__region:lang(ja-JP),.rad-search-locations-office-details__region:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-search-locations-office-details__region {
        font-size:0.875rem
    }
}

@media(min-width: 1441px) {
    .rad-search-locations-office-details__region {
        font-size:calc(0.875rem + 0.97425vw - 14.03897px)
    }
}

@media(min-width: 1920px) {
    .rad-search-locations-office-details__region {
        font-size:1.1666666667rem
    }
}

.rad-search-locations-office-details__region:lang(ja-JP),.rad-search-locations-office-details__region:lang(zh-CN) {
    font-weight: 400
}

@media(min-width: 1024px) {
    .rad-search-locations-office-details__region {
        margin-bottom:3rem
    }
}

@media(min-width: 1441px) {
    .rad-search-locations-office-details__region {
        margin-bottom:calc(3rem + 3.34029vw - 48.13361px)
    }
}

@media(min-width: 1920px) {
    .rad-search-locations-office-details__region {
        margin-bottom:4rem
    }
}

.rad-search-locations-office-details__region-divider {
    padding-left: 0.1875rem;
    padding-right: 0.1875rem
}

@media(min-width: 1441px) {
    .rad-search-locations-office-details__region-divider {
        padding-left:calc(0.1875rem + 0.20877vw - 3.00835px)
    }
}

@media(min-width: 1920px) {
    .rad-search-locations-office-details__region-divider {
        padding-left:0.25rem
    }
}

@media(min-width: 1441px) {
    .rad-search-locations-office-details__region-divider {
        padding-right:calc(0.1875rem + 0.20877vw - 3.00835px)
    }
}

@media(min-width: 1920px) {
    .rad-search-locations-office-details__region-divider {
        padding-right:0.25rem
    }
}

.rad-search-locations-office-details__region-tag {
    border-bottom: 1px solid #fff;
    color: #fff;
    display: inline-block;
    padding-bottom: 0.25rem;
    text-decoration: none;
    word-break: break-word
}

@media(min-width: 1441px) {
    .rad-search-locations-office-details__region-tag {
        padding-bottom:calc(0.25rem + 0.27836vw - 4.01113px)
    }
}

@media(min-width: 1920px) {
    .rad-search-locations-office-details__region-tag {
        padding-bottom:0.3333333333rem
    }
}

.rad-search-locations-office-details__region-tag:visited {
    color: #fff
}

.rad-search-locations-office-details__address-wrapper {
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    flex-grow: 0;
    padding-bottom: 1.5rem
}

@media(min-width: 1024px) {
    .rad-search-locations-office-details__address-wrapper {
        -webkit-box-orient:vertical;
        -webkit-box-direction: normal;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
        padding-bottom: 0
    }
}

.rad-search-locations-office-details__address-detail {
    color: #fff;
    font-size: .875rem;
    font-weight: 500;
    font-weight: 400;
    letter-spacing: -0.005em;
    line-height: 1.5;
    margin-bottom: .5rem;
    text-transform: none;
    text-underline-offset: .25rem
}

.rad-search-locations-office-details__address-detail:lang(ja-JP),.rad-search-locations-office-details__address-detail:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-search-locations-office-details__address-detail {
        font-size:1rem
    }
}

@media(min-width: 1441px) {
    .rad-search-locations-office-details__address-detail {
        font-size:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-search-locations-office-details__address-detail {
        font-size:1.3333333333rem
    }
}

.rad-search-locations-office-details__address-detail:lang(ja-JP),.rad-search-locations-office-details__address-detail:lang(zh-CN) {
    font-weight: 400
}

@media(min-width: 1024px) {
    .rad-search-locations-office-details__address-detail {
        margin-bottom:1.5rem
    }
}

@media(min-width: 1441px) {
    .rad-search-locations-office-details__address-detail {
        margin-bottom:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-search-locations-office-details__address-detail {
        margin-bottom:2rem
    }
}

@media(min-width: 1024px) {
    .rad-search-locations-office-details__contact-wrapper {
        -webkit-box-orient:vertical;
        -webkit-box-direction: normal;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
        padding-left: 3rem
    }
}

@media(min-width: 1441px) {
    .rad-search-locations-office-details__contact-wrapper {
        padding-left:calc(3rem + 3.34029vw - 48.13361px)
    }
}

@media(min-width: 1920px) {
    .rad-search-locations-office-details__contact-wrapper {
        padding-left:4rem
    }
}

.rad-search-locations-office-details__label {
    color: #a2a2a0;
    font-size: .75rem;
    font-weight: 500;
    letter-spacing: .02em;
    line-height: 1.2;
    margin-bottom: .5rem
}

.rad-search-locations-office-details__label:lang(ja-JP),.rad-search-locations-office-details__label:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 1024px) {
    .rad-search-locations-office-details__label {
        font-size:0.875rem
    }
}

@media(min-width: 1441px) {
    .rad-search-locations-office-details__label {
        font-size:calc(0.875rem + 0.97425vw - 14.03897px)
    }
}

@media(min-width: 1920px) {
    .rad-search-locations-office-details__label {
        font-size:1.1666666667rem
    }
}

@media(min-width: 1024px) {
    .rad-search-locations-office-details__label {
        margin-bottom:1rem
    }
}

@media(min-width: 1441px) {
    .rad-search-locations-office-details__label {
        margin-bottom:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-search-locations-office-details__label {
        margin-bottom:1.3333333333rem
    }
}

.rad-search-locations-office-details__link {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    text-decoration: none
}

.rad-search-locations-office-details__link [id^=direction-link-aria] {
    display: none
}

.rad-search-locations-office-details__link-icon {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    align-items: center;
    color: #a100ff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center
}

.rad-search-locations-office-details__link-icon.rad-icon__call,.rad-search-locations-office-details__link-icon.rad-icon__fax {
    color: #fff
}

.rad-search-locations-office-details__link-label {
    color: #fff;
    font-size: .875rem;
    font-weight: 500;
    letter-spacing: -0.005em;
    line-height: 1.5;
    margin-left: 0.5rem;
    text-underline-offset: .25rem
}

@media(min-width: 1441px) {
    .rad-search-locations-office-details__link-label {
        margin-left:calc(0.5rem + 0.55672vw - 8.02227px)
    }
}

@media(min-width: 1920px) {
    .rad-search-locations-office-details__link-label {
        margin-left:0.6666666667rem
    }
}

.rad-search-locations-office-details__link-label:lang(ja-JP),.rad-search-locations-office-details__link-label:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-search-locations-office-details__link-label {
        font-size:1rem
    }
}

@media(min-width: 1441px) {
    .rad-search-locations-office-details__link-label {
        font-size:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-search-locations-office-details__link-label {
        font-size:1.3333333333rem
    }
}

.rad-search-locations-office-details__link--direction .rad-search-locations-office-details__link-label {
    text-decoration: underline;
    text-decoration-thickness: 1px;
    text-underline-offset: .375rem
}

@media(min-width: 1024px) {
    .rad-search-locations-office-details__link--direction {
        margin-top:auto
    }
}

.rad-search-locations-office-details__link--call,.rad-search-locations-office-details__link--fax {
    margin-bottom: .75rem
}

@media(min-width: 1024px) {
    .rad-search-locations-office-details__link--call,.rad-search-locations-office-details__link--fax {
        margin-bottom:1rem
    }
}

@media(min-width: 1441px) {
    .rad-search-locations-office-details__link--call,.rad-search-locations-office-details__link--fax {
        margin-bottom:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-search-locations-office-details__link--call,.rad-search-locations-office-details__link--fax {
        margin-bottom:1.3333333333rem
    }
}

.rad-search-locations-office-details__link--website .rad-search-locations-office-details__link-label {
    text-decoration: underline;
    text-decoration-thickness: 1px;
    text-underline-offset: .375rem
}

@media(min-width: 1024px) {
    .rad-search-locations-office-details__link--website {
        margin-top:auto
    }
}

@media(min-width: 600px) {
    .rad-search-locations-office-details__map {
        position:relative;
        width: 50%
    }
}

.rad-search-locations-office-details__map-link {
    display: block;
    height: 100%;
    width: 100%
}

@media(min-width: 600px) {
    .rad-search-locations-office-details__map-link-group {
        bottom:0;
        left: 0;
        position: absolute;
        right: 0;
        top: 0
    }
}

.rad-search-locations-office-details__map-link span {
    display: none
}

.rad-search-locations-office-details__map-img {
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%
}

.rad-search-locations-office-details__map-img:nth-child(odd) {
    display: none
}

@media(min-width: 600px) {
    .rad-search-locations-office-details__map-img:nth-child(odd) {
        display:-webkit-box;
        display: -ms-flexbox;
        display: flex
    }
}

.rad-search-locations-office-details__map-img:first-child {
    height: 50%;
    width: 100%
}

@media(min-width: 1024px) {
    .rad-search-locations-office-details__map-img:first-child {
        float:left;
        height: 100%;
        width: 50%
    }
}

@media(min-width: 600px) {
    .rad-search-locations-office-details__map-img:not(:first-child) {
        float:left;
        height: 50%;
        width: 50%
    }
}

.rad-search-locations-header-details {
    display: grid
}

@media(min-width: 600px) {
    .rad-search-locations-header-details {
        display:-webkit-box;
        display: -ms-flexbox;
        display: flex
    }

    .rad-search-locations-header-details__heading {
        padding-right: 3rem
    }
}

@media(min-width: 1024px) {
    .rad-search-locations-header-details__heading {
        padding-right:7rem
    }
}

@media(min-width: 1441px) {
    .rad-search-locations-header-details__heading {
        padding-right:calc(7rem + 7.79402vw - 112.31176px)
    }
}

@media(min-width: 1920px) {
    .rad-search-locations-header-details__heading {
        padding-right:9.3333333333rem
    }
}

.rad-search-locations-header-details__heading-location {
    font-size: 1.375rem;
    font-weight: 500;
    letter-spacing: -0.02em;
    line-height: 1.25;
    margin-bottom: 0.5rem
}

.rad-search-locations-header-details__heading-location:lang(ja-JP),.rad-search-locations-header-details__heading-location:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-search-locations-header-details__heading-location {
        font-size:1.75rem;
        line-height: 1.3
    }
}

@media(min-width: 1024px) {
    .rad-search-locations-header-details__heading-location {
        font-size:2rem;
        line-height: 1.2
    }
}

@media(min-width: 1441px) {
    .rad-search-locations-header-details__heading-location {
        font-size:calc(2rem + 2.22686vw - 32.08907px)
    }
}

@media(min-width: 1920px) {
    .rad-search-locations-header-details__heading-location {
        font-size:2.6666666667rem
    }
}

@media(min-width: 1441px) {
    .rad-search-locations-header-details__heading-location {
        margin-bottom:calc(0.5rem + 0.55672vw - 8.02227px)
    }
}

@media(min-width: 1920px) {
    .rad-search-locations-header-details__heading-location {
        margin-bottom:0.6666666667rem
    }
}

.rad-search-locations-header-details__region {
    margin-bottom: 1.5rem
}

@media(min-width: 600px) {
    .rad-search-locations-header-details__region {
        margin-bottom:0
    }
}

.rad-search-locations-header-details__region a {
    border-bottom: 1px solid #fff;
    display: inline-block;
    font-size: .75rem;
    font-weight: 500;
    font-weight: 400;
    letter-spacing: -0.005em;
    line-height: 1.5;
    padding-bottom: 0.25rem;
    text-decoration: none;
    text-underline-offset: .25rem
}

@media(min-width: 1441px) {
    .rad-search-locations-header-details__region a {
        padding-bottom:calc(0.25rem + 0.27836vw - 4.01113px)
    }
}

@media(min-width: 1920px) {
    .rad-search-locations-header-details__region a {
        padding-bottom:0.3333333333rem
    }
}

.rad-search-locations-header-details__region a:lang(ja-JP),.rad-search-locations-header-details__region a:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-search-locations-header-details__region a {
        font-size:0.875rem
    }
}

@media(min-width: 1441px) {
    .rad-search-locations-header-details__region a {
        font-size:calc(0.875rem + 0.97425vw - 14.03897px)
    }
}

@media(min-width: 1920px) {
    .rad-search-locations-header-details__region a {
        font-size:1.1666666667rem
    }
}

.rad-search-locations-header-details__region a:lang(ja-JP),.rad-search-locations-header-details__region a:lang(zh-CN) {
    font-weight: 400
}

.rad-search-locations-header-details__region a:focus,.rad-search-locations-header-details__region a:hover {
    color: #fff
}

.rad-search-locations-header-details__region-divider {
    font-size: 1rem
}

@media(min-width: 1441px) {
    .rad-search-locations-header-details__region-divider {
        font-size:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-search-locations-header-details__region-divider {
        font-size:1.3333333333rem
    }
}

.rad-search-locations-header-details__options {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.rad-search-locations-header-details__options ul {
    display: block
}

@media(min-width: 600px) {
    .rad-search-locations-header-details__options ul {
        -webkit-box-align:center;
        -ms-flex-align: center;
        align-items: center;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        width: 100%
    }
}

.rad-search-locations-header-details__options ul li {
    margin-bottom: 1rem;
    padding-right: 0
}

@media(min-width: 600px) {
    .rad-search-locations-header-details__options ul li {
        margin-bottom:0;
        padding-right: 1.5rem
    }
}

@media(min-width: 1024px) {
    .rad-search-locations-header-details__options ul li {
        padding-right:3rem
    }
}

@media(min-width: 1441px) {
    .rad-search-locations-header-details__options ul li {
        padding-right:calc(3rem + 3.34029vw - 48.13361px)
    }
}

@media(min-width: 1920px) {
    .rad-search-locations-header-details__options ul li {
        padding-right:4rem
    }
}

.rad-search-locations-header-details__options ul li:last-of-type {
    margin-bottom: 0;
    padding-right: 0
}

.rad-search-locations-header-details__options a {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #fff;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    text-decoration: none
}

@media(min-width: 600px) {
    .rad-search-locations-header-details__options a {
        padding-bottom:0
    }
}

.rad-search-locations-header-details__options a:focus,.rad-search-locations-header-details__options a:hover,.rad-search-locations-header-details__options a:visited {
    color: #fff;
    text-decoration: none
}

.rad-search-locations-header-details__options .rad-icon {
    color: #a100ff
}

.rad-search-locations-header-details__options--text-link {
    font-size: .875rem;
    font-weight: 500;
    letter-spacing: -0.005em;
    line-height: 1.5;
    margin-left: 0.5rem;
    text-decoration: underline;
    text-decoration-thickness: 1px;
    text-underline-offset: .25rem;
    text-underline-offset: .375rem
}

@media(min-width: 1441px) {
    .rad-search-locations-header-details__options--text-link {
        margin-left:calc(0.5rem + 0.55672vw - 8.02227px)
    }
}

@media(min-width: 1920px) {
    .rad-search-locations-header-details__options--text-link {
        margin-left:0.6666666667rem
    }
}

.rad-search-locations-header-details__options--text-link:lang(ja-JP),.rad-search-locations-header-details__options--text-link:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-search-locations-header-details__options--text-link {
        font-size:1rem
    }
}

@media(min-width: 1441px) {
    .rad-search-locations-header-details__options--text-link {
        font-size:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-search-locations-header-details__options--text-link {
        font-size:1.3333333333rem
    }
}

.rad-search-locations-header-details__options--text-link:focus {
    color: #fff
}

.rad-search-locations-header-details__options--text-link:hover {
    color: #fff;
    text-decoration-thickness: 1px
}

.rad-search-locations {
    padding-left: var(--rad-spacing-sm);
    padding-right: var(--rad-spacing-sm)
}

@media(min-width: 600px) {
    .rad-search-locations {
        padding-left:var(--rad-spacing-md);
        padding-right: var(--rad-spacing-md)
    }
}

.rad-component--nested .rad-search-locations {
    padding-left: 0;
    padding-right: 0
}

.rad-search-locations__hero {
    position: relative
}

.rad-search-locations__content {
    padding-bottom: 2.5rem;
    padding-top: 2.5rem
}

@media(min-width: 600px) {
    .rad-search-locations__content {
        padding-bottom:3.75rem;
        padding-top: 3.75rem
    }
}

@media(min-width: 1441px) {
    .rad-search-locations__content {
        padding-top:calc(3.75rem + 4.17537vw - 60.16701px)
    }
}

@media(min-width: 1920px) {
    .rad-search-locations__content {
        padding-top:5rem
    }
}

@media(min-width: 1441px) {
    .rad-search-locations__content {
        padding-bottom:calc(3.75rem + 4.17537vw - 60.16701px)
    }
}

@media(min-width: 1920px) {
    .rad-search-locations__content {
        padding-bottom:5rem
    }
}

.rad-search-locations__content .title {
    margin-bottom: 2.5rem
}

@media(min-width: 1441px) {
    .rad-search-locations__content .title {
        margin-bottom:calc(2.5rem + 2.78358vw - 40.11134px)
    }
}

@media(min-width: 1920px) {
    .rad-search-locations__content .title {
        margin-bottom:3.3333333333rem
    }
}

.rad-search-locations__content .title h1 {
    font-size: 2rem;
    font-weight: 500;
    letter-spacing: -0.03em;
    line-height: 1.25
}

.rad-search-locations__content .title h1:lang(ja-JP),.rad-search-locations__content .title h1:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-search-locations__content .title h1 {
        font-size:3.75rem;
        line-height: 1.15
    }
}

@media(min-width: 1441px) {
    .rad-search-locations__content .title h1 {
        font-size:calc(3.75rem + 4.17537vw - 60.16701px)
    }
}

@media(min-width: 1920px) {
    .rad-search-locations__content .title h1 {
        font-size:5rem
    }
}

@media(min-width: 1024px) {
    .rad-search-locations__form-container {
        width:calc(200% - var(--rad-spacing-sm)*3/4*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-sm)*0);
        width: calc(100% - var(--rad-spacing-sm)*7/8*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0);
        width: calc(66.66667% - var(--rad-spacing-sm)*11/12*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0)
    }
}

.rad-search-locations__form {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.rad-search-locations__form-label {
    display: none
}

.rad-search-locations__form .rad-button {
    height: inherit
}

.rad-search-locations__form .rad-button__text {
    display: none
}

@media(min-width: 600px) {
    .rad-search-locations__form .rad-button__text {
        display:block;
        margin-left: 0.3125rem
    }
}

@media(min-width: 1441px) {
    .rad-search-locations__form .rad-button__text {
        margin-left:calc(0.3125rem + 0.34795vw - 5.01392px)
    }
}

@media(min-width: 1920px) {
    .rad-search-locations__form .rad-button__text {
        margin-left:0.4166666667rem
    }
}

.rad-search-locations__search {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 0px;
    flex: 1 0 0;
    width: 100%
}

.rad-search-locations__search-input {
    background-color: #202020;
    border: 0;
    color: #fff;
    font-size: .875rem;
    font-weight: 500;
    font-weight: 400;
    height: 3.3125rem;
    letter-spacing: -0.005em;
    line-height: 1.5;
    padding-left: 0.9375rem;
    padding-right: 3.125rem;
    text-underline-offset: .25rem;
    width: 100%
}

@media(min-width: 1441px) {
    .rad-search-locations__search-input {
        padding-left:calc(0.9375rem + 1.04384vw - 15.04175px)
    }
}

@media(min-width: 1920px) {
    .rad-search-locations__search-input {
        padding-left:1.25rem
    }
}

@media(min-width: 1441px) {
    .rad-search-locations__search-input {
        padding-right:calc(3.125rem + 3.47947vw - 50.13918px)
    }
}

@media(min-width: 1920px) {
    .rad-search-locations__search-input {
        padding-right:4.1666666667rem
    }
}

.rad-search-locations__search-input:lang(ja-JP),.rad-search-locations__search-input:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-search-locations__search-input {
        font-size:1rem
    }
}

@media(min-width: 1441px) {
    .rad-search-locations__search-input {
        font-size:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-search-locations__search-input {
        font-size:1.3333333333rem
    }
}

.rad-search-locations__search-input:lang(ja-JP),.rad-search-locations__search-input:lang(zh-CN) {
    font-weight: 400
}



@media(min-width: 600px) {
    .rad-search-locations__search-input {
        height:3.5rem
    }
}

@media(min-width: 1024px) {
    .rad-search-locations__search-input {
        height:3.625rem
    }
}

@media(min-width: 1441px) {
    .rad-search-locations__search-input {
        height:calc(3.625rem + 4.03619vw - 58.16145px)
    }
}

@media(min-width: 1920px) {
    .rad-search-locations__search-input {
        height:4.8333333333rem
    }
}

.rad-search-locations__search-suggestions {
    position: relative
}

.rad-search-locations__search-suggestions--show .rad-search-locations__suggestions-list {
    display: block
}

.rad-search-locations__suggestions-list {
    background-color: #202020;
    border-top: 2px solid rgba(230,220,255,.349);
    display: none;
    left: 0;
    overflow: hidden;
    padding: 1rem 0;
    position: absolute;
    right: 0;
    z-index: 951
}

@media(min-width: 1024px) {
    .rad-search-locations__suggestions-list {
        padding-bottom:1.5rem;
        padding-top: 1.5rem
    }
}

@media(min-width: 1441px) {
    .rad-search-locations__suggestions-list {
        padding-top:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-search-locations__suggestions-list {
        padding-top:2rem
    }
}

@media(min-width: 1441px) {
    .rad-search-locations__suggestions-list {
        padding-bottom:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-search-locations__suggestions-list {
        padding-bottom:2rem
    }
}

.rad-search-locations__suggestions-item {
    border: 1px solid transparent;
    display: block
}

.rad-search-locations__suggestions-item--active {
    border: 2px solid #a100ff
}

.rad-search-locations__suggestions-item a {
    display: block;
    font-size: .875rem;
    font-weight: 500;
    font-weight: 400;
    letter-spacing: -0.005em;
    line-height: 1.5;
    margin-bottom: 0;
    padding: .375rem 1rem;
    text-decoration: none;
    text-underline-offset: .25rem
}

.rad-search-locations__suggestions-item a:lang(ja-JP),.rad-search-locations__suggestions-item a:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-search-locations__suggestions-item a {
        font-size:1rem
    }
}

@media(min-width: 1441px) {
    .rad-search-locations__suggestions-item a {
        font-size:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-search-locations__suggestions-item a {
        font-size:1.3333333333rem
    }
}

.rad-search-locations__suggestions-item a:lang(ja-JP),.rad-search-locations__suggestions-item a:lang(zh-CN) {
    font-weight: 400
}

.rad-search-locations__suggestions-item a:hover {
    background-color: #2b2b2b;
    color: #fff
}

.rad-search-locations__suggestions-item a:active {
    background-color: #101010
}

@media(min-width: 1024px) {
    .rad-search-locations__suggestions-item a {
        padding:0.75rem 3rem
    }
}

@media(min-width: 1441px) {
    .rad-search-locations__suggestions-item a {
        padding-top:calc(0.75rem + 0.83507vw - 12.0334px)
    }
}

@media(min-width: 1920px) {
    .rad-search-locations__suggestions-item a {
        padding-top:1rem
    }
}

@media(min-width: 1441px) {
    .rad-search-locations__suggestions-item a {
        padding-bottom:calc(0.75rem + 0.83507vw - 12.0334px)
    }
}

@media(min-width: 1920px) {
    .rad-search-locations__suggestions-item a {
        padding-bottom:1rem
    }
}

@media(min-width: 1441px) {
    .rad-search-locations__suggestions-item a {
        padding-right:calc(3rem + 3.34029vw - 48.13361px)
    }
}

@media(min-width: 1920px) {
    .rad-search-locations__suggestions-item a {
        padding-right:4rem
    }
}

@media(min-width: 1441px) {
    .rad-search-locations__suggestions-item a {
        padding-left:calc(3rem + 3.34029vw - 48.13361px)
    }
}

@media(min-width: 1920px) {
    .rad-search-locations__suggestions-item a {
        padding-left:4rem
    }
}

.rad-search-locations__highlight {
    font-size: .875rem;
    font-weight: 500;
    letter-spacing: -0.005em;
    line-height: 1.5;
    text-underline-offset: .25rem
}

.rad-search-locations__highlight:lang(ja-JP),.rad-search-locations__highlight:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-search-locations__highlight {
        font-size:1rem
    }
}

@media(min-width: 1441px) {
    .rad-search-locations__highlight {
        font-size:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-search-locations__highlight {
        font-size:1.3333333333rem
    }
}

.rad-search-locations__results-container.rad-search-locations__results-container--hide,.rad-search-locations__results-container:empty {
    display: none
}

.rad-search-locations__no-search-term {
    display: none;
    padding-bottom: 1.5rem;
    padding-top: 1.5rem
}

@media(min-width: 600px) {
    .rad-search-locations__no-search-term {
        padding-bottom:3.75rem;
        padding-top: 3.75rem
    }
}

@media(min-width: 1441px) {
    .rad-search-locations__no-search-term {
        padding-top:calc(3.75rem + 4.17537vw - 60.16701px)
    }
}

@media(min-width: 1920px) {
    .rad-search-locations__no-search-term {
        padding-top:5rem
    }
}

@media(min-width: 1441px) {
    .rad-search-locations__no-search-term {
        padding-bottom:calc(3.75rem + 4.17537vw - 60.16701px)
    }
}

@media(min-width: 1920px) {
    .rad-search-locations__no-search-term {
        padding-bottom:5rem
    }
}

.rad-search-locations__no-search-term.rad-search-locations__no-search-term--show {
    display: block;
    font-size: 1rem;
    font-weight: 500;
    font-weight: 400;
    letter-spacing: -0.005em;
    line-height: 1.6;
    text-underline-offset: .25rem
}

.rad-search-locations__no-search-term.rad-search-locations__no-search-term--show:lang(ja-JP),.rad-search-locations__no-search-term.rad-search-locations__no-search-term--show:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-search-locations__no-search-term.rad-search-locations__no-search-term--show {
        font-size:1.125rem
    }
}

@media(min-width: 1024px) {
    .rad-search-locations__no-search-term.rad-search-locations__no-search-term--show {
        font-size:1.25rem
    }
}

@media(min-width: 1441px) {
    .rad-search-locations__no-search-term.rad-search-locations__no-search-term--show {
        font-size:calc(1.25rem + 1.39179vw - 20.05567px)
    }
}

@media(min-width: 1920px) {
    .rad-search-locations__no-search-term.rad-search-locations__no-search-term--show {
        font-size:1.6666666667rem
    }
}

.rad-search-locations__no-search-term.rad-search-locations__no-search-term--show:lang(ja-JP),.rad-search-locations__no-search-term.rad-search-locations__no-search-term--show:lang(zh-CN) {
    font-weight: 400
}

.rad-search-locations__no-results {
    display: none;
    font-size: 1rem;
    font-weight: 500;
    font-weight: 400;
    letter-spacing: -0.005em;
    line-height: 1.6;
    padding-bottom: 1.5rem;
    padding-top: 1.5rem;
    text-underline-offset: .25rem
}

.rad-search-locations__no-results:lang(ja-JP),.rad-search-locations__no-results:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-search-locations__no-results {
        font-size:1.125rem
    }
}

@media(min-width: 1024px) {
    .rad-search-locations__no-results {
        font-size:1.25rem
    }
}

@media(min-width: 1441px) {
    .rad-search-locations__no-results {
        font-size:calc(1.25rem + 1.39179vw - 20.05567px)
    }
}

@media(min-width: 1920px) {
    .rad-search-locations__no-results {
        font-size:1.6666666667rem
    }
}

.rad-search-locations__no-results:lang(ja-JP),.rad-search-locations__no-results:lang(zh-CN) {
    font-weight: 400
}

@media(min-width: 600px) {
    .rad-search-locations__no-results {
        padding-bottom:3.75rem;
        padding-top: 3.75rem
    }
}

@media(min-width: 1441px) {
    .rad-search-locations__no-results {
        padding-top:calc(3.75rem + 4.17537vw - 60.16701px)
    }
}

@media(min-width: 1920px) {
    .rad-search-locations__no-results {
        padding-top:5rem
    }
}

@media(min-width: 1441px) {
    .rad-search-locations__no-results {
        padding-bottom:calc(3.75rem + 4.17537vw - 60.16701px)
    }
}

@media(min-width: 1920px) {
    .rad-search-locations__no-results {
        padding-bottom:5rem
    }
}

.rad-search-locations__no-results .rad-search-title__text {
    font-size: 1.75rem;
    font-weight: 500;
    letter-spacing: -0.03em;
    line-height: 1.3;
    margin-bottom: 1.5rem
}

.rad-search-locations__no-results .rad-search-title__text:lang(ja-JP),.rad-search-locations__no-results .rad-search-title__text:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-search-locations__no-results .rad-search-title__text {
        font-size:2.5rem;
        line-height: 1.2
    }
}

@media(min-width: 1024px) {
    .rad-search-locations__no-results .rad-search-title__text {
        font-size:3rem;
        line-height: 1.2
    }
}

@media(min-width: 1441px) {
    .rad-search-locations__no-results .rad-search-title__text {
        font-size:calc(3rem + 3.34029vw - 48.13361px)
    }
}

@media(min-width: 1920px) {
    .rad-search-locations__no-results .rad-search-title__text {
        font-size:4rem
    }
}

@media(min-width: 600px) {
    .rad-search-locations__no-results .rad-search-title__text {
        margin-bottom:2.5rem
    }
}

@media(min-width: 1024px) {
    .rad-search-locations__no-results .rad-search-title__text {
        margin-bottom:3rem
    }
}

@media(min-width: 1441px) {
    .rad-search-locations__no-results .rad-search-title__text {
        margin-bottom:calc(3rem + 3.34029vw - 48.13361px)
    }
}

@media(min-width: 1920px) {
    .rad-search-locations__no-results .rad-search-title__text {
        margin-bottom:4rem
    }
}

.rad-search-locations__no-results.rad-search-locations__no-results--show {
    display: block
}

.rad-aoi-hero {
    padding-left: var(--rad-spacing-sm);
    padding-right: var(--rad-spacing-sm)
}

@media(min-width: 600px) {
    .rad-aoi-hero {
        padding-left:var(--rad-spacing-md);
        padding-right: var(--rad-spacing-md)
    }
}

.rad-component--nested .rad-aoi-hero {
    padding-left: 0;
    padding-right: 0
}

@media(min-width: 1024px) {
    .rad-aoi-hero {
        -webkit-box-align:end;
        -ms-flex-align: end;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        align-items: flex-end;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        justify-content: space-between
    }
}

.rad-aoi-hero__media {
    height: 10.75rem;
    margin-left: calc(var(--rad-spacing-sm)*-1);
    width: 16.125rem
}

@media(min-width: 600px) {
    .rad-aoi-hero__media {
        margin-left:calc(var(--rad-spacing-md)*-1)
    }
}

@media(min-width: 768px) {
    .rad-aoi-hero__media {
        height:100%;
        width: calc(100% + var(--rad-spacing-md))
    }
}

@media(min-width: 1024px) {
    .rad-aoi-hero__media {
        height:auto
    }
}

.rad-aoi-hero__media * {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%
}

.rad-aoi-hero__media-container {
    padding-bottom: 2.5rem
}

@media(min-width: 600px) {
    .rad-aoi-hero__media-container {
        padding-bottom:3.75rem
    }
}

@media(min-width: 768px) {
    .rad-aoi-hero__media-container {
        width:calc(75% - var(--rad-spacing-sm)*3/4*3 + var(--rad-spacing-sm)*2 + var(--rad-spacing-sm)*0);
        width: calc(37.5% - var(--rad-spacing-sm)*7/8*3 + var(--rad-spacing-sm)*2 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-aoi-hero__media-container {
        padding-bottom:0;
        width: calc(25% - var(--rad-spacing-sm)*11/12*3 + var(--rad-spacing-sm)*2 + var(--rad-spacing-md)*0);
        width: calc(125% - var(--rad-spacing-sm)*3/4*5 + var(--rad-spacing-sm)*4 + var(--rad-spacing-sm)*0);
        width: calc(62.5% - var(--rad-spacing-sm)*7/8*5 + var(--rad-spacing-sm)*4 + var(--rad-spacing-md)*0);
        width: calc(41.66667% - var(--rad-spacing-sm)*11/12*5 + var(--rad-spacing-sm)*4 + var(--rad-spacing-md)*0)
    }
}

.rad-aoi-hero__text-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 16px;
    width: calc(100% - var(--rad-spacing-sm)*3/4*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-sm)*0)
}

@media(min-width: 600px) {
    .rad-aoi-hero__text-container {
        width:calc(50% - var(--rad-spacing-sm)*7/8*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-aoi-hero__text-container {
        width:calc(33.33333% - var(--rad-spacing-sm)*11/12*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 600px) {
    .rad-aoi-hero__text-container {
        gap:24px;
        width: calc(200% - var(--rad-spacing-sm)*3/4*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-sm)*0);
        width: calc(100% - var(--rad-spacing-sm)*7/8*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-aoi-hero__text-container {
        gap:32px;
        padding-bottom: var(--rad-spacing-md);
        width: calc(66.66667% - var(--rad-spacing-sm)*11/12*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0);
        width: calc(150% - var(--rad-spacing-sm)*3/4*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-sm)*0);
        width: calc(75% - var(--rad-spacing-sm)*7/8*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0);
        width: calc(50% - var(--rad-spacing-sm)*11/12*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1441px) {
    .rad-aoi-hero__text-container {
        gap:calc(-0.08907px + 2.22686vw)
    }
}

@media(min-width: 1920px) {
    .rad-aoi-hero__text-container {
        gap:42.6666666667px
    }
}

.rad-aoi-hero__headline {
    font-size: 2rem;
    font-weight: 500;
    letter-spacing: -0.03em;
    line-height: 1.25
}

.rad-aoi-hero__headline:lang(ja-JP),.rad-aoi-hero__headline:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-aoi-hero__headline {
        font-size:3.75rem;
        line-height: 1.15
    }
}

@media(min-width: 1441px) {
    .rad-aoi-hero__headline {
        font-size:calc(3.75rem + 4.17537vw - 60.16701px)
    }
}

@media(min-width: 1920px) {
    .rad-aoi-hero__headline {
        font-size:5rem
    }
}

@media(min-width: 1024px) {
    .rad-aoi-hero__headline {
        margin-top:2rem
    }
}

@media(min-width: 1441px) {
    .rad-aoi-hero__headline {
        margin-top:calc(2rem + 2.22686vw - 32.08907px)
    }
}

@media(min-width: 1920px) {
    .rad-aoi-hero__headline {
        margin-top:2.6666666667rem
    }
}

.rad-aoi-hero__subheader {
    font-family: GT Sectra Fine,Palatino;
    font-size: 1.125rem;
    font-weight: 300;
    line-height: 1.3
}

@media(min-width: 600px) {
    .rad-aoi-hero__subheader {
        font-size:1.25rem;
        line-height: 1.3
    }
}

@media(min-width: 1024px) {
    .rad-aoi-hero__subheader {
        font-size:1.5rem;
        line-height: 1.25
    }
}

@media(min-width: 1441px) {
    .rad-aoi-hero__subheader {
        font-size:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-aoi-hero__subheader {
        font-size:2rem
    }
}

.rad-aoi-hero__subheader h2 {
    font-size: 1.75rem;
    font-weight: 500;
    letter-spacing: -0.03em;
    line-height: 1.3
}

.rad-aoi-hero__subheader h2:lang(ja-JP),.rad-aoi-hero__subheader h2:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-aoi-hero__subheader h2 {
        font-size:2.5rem;
        line-height: 1.2
    }
}

@media(min-width: 1024px) {
    .rad-aoi-hero__subheader h2 {
        font-size:3rem;
        line-height: 1.2
    }
}

@media(min-width: 1441px) {
    .rad-aoi-hero__subheader h2 {
        font-size:calc(3rem + 3.34029vw - 48.13361px)
    }
}

@media(min-width: 1920px) {
    .rad-aoi-hero__subheader h2 {
        font-size:4rem
    }
}

.rad-aoi-hero__subheader h2+h2,.rad-aoi-hero__subheader h2+h3 {
    margin-top: 1rem
}

.rad-aoi-hero__subheader h2+h4 {
    margin-top: 1.5rem
}

.rad-aoi-hero__subheader h2+h5 {
    margin-top: .5rem
}

.rad-aoi-hero__subheader h2+p {
    margin-top: 1.5rem
}

.rad-aoi-hero__subheader h3 {
    font-size: 1.375rem;
    font-weight: 500;
    letter-spacing: -0.02em;
    line-height: 1.25
}

.rad-aoi-hero__subheader h3:lang(ja-JP),.rad-aoi-hero__subheader h3:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-aoi-hero__subheader h3 {
        font-size:1.75rem;
        line-height: 1.3
    }
}

@media(min-width: 1024px) {
    .rad-aoi-hero__subheader h3 {
        font-size:2rem;
        line-height: 1.2
    }
}

@media(min-width: 1441px) {
    .rad-aoi-hero__subheader h3 {
        font-size:calc(2rem + 2.22686vw - 32.08907px)
    }
}

@media(min-width: 1920px) {
    .rad-aoi-hero__subheader h3 {
        font-size:2.6666666667rem
    }
}

.rad-aoi-hero__subheader h3+h2 {
    margin-top: 2rem
}

.rad-aoi-hero__subheader h3+h3 {
    margin-top: 1rem
}

.rad-aoi-hero__subheader h3+h4 {
    margin-top: .5rem
}

.rad-aoi-hero__subheader h3+h5 {
    margin-top: 1rem
}

.rad-aoi-hero__subheader h3+p {
    margin-top: 1.5rem
}

.rad-aoi-hero__subheader h4 {
    font-size: 1.125rem;
    font-weight: 500;
    letter-spacing: -0.02em;
    line-height: 1.4
}

.rad-aoi-hero__subheader h4:lang(ja-JP),.rad-aoi-hero__subheader h4:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-aoi-hero__subheader h4 {
        font-size:1.25rem;
        line-height: 1.4
    }
}

@media(min-width: 1024px) {
    .rad-aoi-hero__subheader h4 {
        font-size:1.5rem;
        line-height: 1.3
    }
}

@media(min-width: 1441px) {
    .rad-aoi-hero__subheader h4 {
        font-size:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-aoi-hero__subheader h4 {
        font-size:2rem
    }
}

.rad-aoi-hero__subheader h4+h2,.rad-aoi-hero__subheader h4+h3 {
    margin-top: 2rem
}

.rad-aoi-hero__subheader h4+h4 {
    margin-top: .5rem
}

.rad-aoi-hero__subheader h4+h5 {
    margin-top: 2rem
}

.rad-aoi-hero__subheader h4+p {
    margin-top: .5rem
}

.rad-aoi-hero__subheader h5 {
    font-family: GT Sectra Fine,Palatino;
    font-size: 1.25rem;
    font-weight: 300;
    line-height: 1.25
}

@media(min-width: 600px) {
    .rad-aoi-hero__subheader h5 {
        font-size:1.5rem;
        line-height: 1.25
    }
}

@media(min-width: 1024px) {
    .rad-aoi-hero__subheader h5 {
        font-size:1.75rem;
        line-height: 1.25
    }
}

@media(min-width: 1441px) {
    .rad-aoi-hero__subheader h5 {
        font-size:calc(1.75rem + 1.9485vw - 28.07794px)
    }
}

@media(min-width: 1920px) {
    .rad-aoi-hero__subheader h5 {
        font-size:2.3333333333rem
    }
}

.rad-aoi-hero__subheader h5+h2 {
    margin-top: 2rem
}

.rad-aoi-hero__subheader h5+h3,.rad-aoi-hero__subheader h5+h4 {
    margin-top: 1.5rem
}

.rad-aoi-hero__subheader h5+h5 {
    margin-top: 1rem
}

.rad-aoi-hero__subheader h5+p {
    margin-top: 1.5rem
}

.rad-aoi-hero__subheader p {
    padding-right: var(--scrollbar-width)
}

.rad-aoi-hero__subheader p a {
    font-weight: 500
}

.rad-aoi-hero__subheader p a:focus-visible {
    outline-offset: 2px
}

.rad-aoi-hero__subheader p+h2,.rad-aoi-hero__subheader p+h3,.rad-aoi-hero__subheader p+h4,.rad-aoi-hero__subheader p+h5 {
    margin-top: 2rem
}

.rad-aoi-hero__subheader p+p {
    margin-top: .5rem
}

@media(min-width: 600px) {
    .rad-aoi-hero__subheader h2+h2,.rad-aoi-hero__subheader h2+h3 {
        margin-top:1.5rem
    }

    .rad-aoi-hero__subheader h2+h4 {
        margin-top: 2rem
    }

    .rad-aoi-hero__subheader h2+h5 {
        margin-top: .5rem
    }

    .rad-aoi-hero__subheader h2+p {
        margin-top: 2rem
    }

    .rad-aoi-hero__subheader h3+h2 {
        margin-top: 3rem
    }

    .rad-aoi-hero__subheader h3+h3 {
        margin-top: 1.5rem
    }

    .rad-aoi-hero__subheader h3+h4 {
        margin-top: .75rem
    }

    .rad-aoi-hero__subheader h3+h5 {
        margin-top: 1.5rem
    }

    .rad-aoi-hero__subheader h3+p {
        margin-top: 2rem
    }

    .rad-aoi-hero__subheader h4+h2,.rad-aoi-hero__subheader h4+h3 {
        margin-top: 3rem
    }

    .rad-aoi-hero__subheader h4+h4 {
        margin-top: 1rem
    }

    .rad-aoi-hero__subheader h4+h5 {
        margin-top: 3rem
    }

    .rad-aoi-hero__subheader h4+p {
        margin-top: .75rem
    }

    .rad-aoi-hero__subheader h5+h2 {
        margin-top: 3rem
    }

    .rad-aoi-hero__subheader h5+h3 {
        margin-top: 2.5rem
    }

    .rad-aoi-hero__subheader h5+h4 {
        margin-top: 2rem
    }

    .rad-aoi-hero__subheader h5+h5 {
        margin-top: 1.5rem
    }

    .rad-aoi-hero__subheader h5+p {
        margin-top: 2rem
    }

    .rad-aoi-hero__subheader p {
        padding-right: 0
    }

    .rad-aoi-hero__subheader p+h2,.rad-aoi-hero__subheader p+h3,.rad-aoi-hero__subheader p+h4,.rad-aoi-hero__subheader p+h5 {
        margin-top: 3rem
    }

    .rad-aoi-hero__subheader p+p {
        margin-top: 1rem
    }
}

@media(min-width: 1024px) {
    .rad-aoi-hero__subheader h2+h2,.rad-aoi-hero__subheader h2+h3 {
        margin-top:2rem
    }

    .rad-aoi-hero__subheader h2+h4 {
        margin-top: 3rem
    }

    .rad-aoi-hero__subheader h2+h5 {
        margin-top: .5rem
    }

    .rad-aoi-hero__subheader h2+p {
        margin-top: 3rem
    }

    .rad-aoi-hero__subheader h3+h2 {
        margin-top: 4rem
    }

    .rad-aoi-hero__subheader h3+h3 {
        margin-top: 2rem
    }

    .rad-aoi-hero__subheader h3+h4 {
        margin-top: 1rem
    }

    .rad-aoi-hero__subheader h3+h5 {
        margin-top: 2rem
    }

    .rad-aoi-hero__subheader h3+p {
        margin-top: 3rem
    }

    .rad-aoi-hero__subheader h4+h2,.rad-aoi-hero__subheader h4+h3 {
        margin-top: 4rem
    }

    .rad-aoi-hero__subheader h4+h4 {
        margin-top: 1.5rem
    }

    .rad-aoi-hero__subheader h4+h5 {
        margin-top: 4rem
    }

    .rad-aoi-hero__subheader h4+p {
        margin-top: 1rem
    }

    .rad-aoi-hero__subheader h5+h2 {
        margin-top: 4rem
    }

    .rad-aoi-hero__subheader h5+h3,.rad-aoi-hero__subheader h5+h4 {
        margin-top: 3rem
    }

    .rad-aoi-hero__subheader h5+h5 {
        margin-top: 2rem
    }

    .rad-aoi-hero__subheader h5+p {
        margin-top: 3rem
    }

    .rad-aoi-hero__subheader p+h2,.rad-aoi-hero__subheader p+h3,.rad-aoi-hero__subheader p+h4,.rad-aoi-hero__subheader p+h5 {
        margin-top: 4rem
    }

    .rad-aoi-hero__subheader p+p {
        margin-top: 1.5rem
    }
}

.rad-dynamic-header {
    overflow-y: clip
}

.rad-dynamic-header__inner {
    max-height: inherit;
    overflow: hidden
}

.rad-dynamic-header__eyebrow {
    font-size: .75rem;
    font-weight: 500;
    letter-spacing: .02em;
    line-height: 1.2;
    margin-bottom: .5rem;
    padding-top: .875rem;
    position: relative;
    -webkit-transition-duration: 1s;
    transition-duration: 1s;
    -webkit-transition-property: padding-top;
    transition-property: padding-top;
    -webkit-transition-timing-function: cubic-bezier(0.85,0,0,1);
    transition-timing-function: cubic-bezier(0.85,0,0,1)
}

.rad-dynamic-header__eyebrow:lang(ja-JP),.rad-dynamic-header__eyebrow:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 1024px) {
    .rad-dynamic-header__eyebrow {
        font-size:0.875rem
    }
}

@media(min-width: 1441px) {
    .rad-dynamic-header__eyebrow {
        font-size:calc(0.875rem + 0.97425vw - 14.03897px)
    }
}

@media(min-width: 1920px) {
    .rad-dynamic-header__eyebrow {
        font-size:1.1666666667rem
    }
}

@media(min-width: 600px) {
    .rad-dynamic-header__eyebrow {
        margin-bottom:.75rem;
        padding-top: 1.125rem
    }
}

@media(min-width: 1024px) {
    .rad-dynamic-header__eyebrow {
        margin-bottom:1.5rem;
        padding-top: 0
    }
}

@media(min-width: 1441px) {
    .rad-dynamic-header__eyebrow {
        margin-bottom:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-dynamic-header__eyebrow {
        margin-bottom:2rem
    }
}

.rad-dynamic-header__eyebrow:after {
    background-color: #000;
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0
}

.rad-dynamic-header__text {
    font-size: 1.5rem;
    font-weight: 500;
    letter-spacing: -0.03em;
    line-height: 1.35;
    position: relative
}

.rad-dynamic-header__text:lang(ja-JP),.rad-dynamic-header__text:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-dynamic-header__text {
        font-size:2rem;
        line-height: 1.2
    }
}

@media(min-width: 1024px) {
    .rad-dynamic-header__text {
        font-size:2.5rem;
        line-height: 1.2
    }
}

@media(min-width: 1441px) {
    .rad-dynamic-header__text {
        font-size:calc(2.5rem + 2.78358vw - 40.11134px)
    }
}

@media(min-width: 1920px) {
    .rad-dynamic-header__text {
        font-size:3.3333333333rem
    }
}

.rad-dynamic-header__text:after {
    background-color: #000;
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0
}

.rad-dynamic-header__deco-line {
    background-color: #a100ff;
    height: 0.25rem;
    margin-bottom: .75rem;
    width: 0
}

@media(min-width: 1441px) {
    .rad-dynamic-header__deco-line {
        height:calc(0.25rem + 0.27836vw - 4.01113px)
    }
}

@media(min-width: 1920px) {
    .rad-dynamic-header__deco-line {
        height:0.3333333333rem
    }
}

@media(min-width: 1024px) {
    .rad-dynamic-header__deco-line {
        margin-bottom:1.5rem
    }
}

@media(min-width: 1441px) {
    .rad-dynamic-header__deco-line {
        margin-bottom:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-dynamic-header__deco-line {
        margin-bottom:2rem
    }
}

.rad-dynamic-header__gradient-line {
    background: -webkit-gradient(linear,left top,right top,color-stop(13.38%,#460073),color-stop(36.59%,#a100ff),color-stop(65.35%,#ff50a0),color-stop(81.57%,#ff7800),color-stop(91.26%,#feb149));
    background: linear-gradient(90deg,#460073 13.38%,#a100ff 36.59%,#ff50a0 65.35%,#ff7800 81.57%,#feb149 91.26%);
    height: .125rem;
    margin-bottom: .75rem;
    width: 0
}

@media(min-width: 600px) {
    .rad-dynamic-header__gradient-line {
        margin-bottom:1rem
    }
}

@media(min-width: 1024px) {
    .rad-dynamic-header__gradient-line {
        display:none
    }
}

.rad-dynamic-header__gradient-line--desktop {
    display: none
}

@media(min-width: 1024px) {
    .rad-dynamic-header__gradient-line--desktop {
        background:-webkit-gradient(linear,left top,left bottom,color-stop(13.38%,#460073),color-stop(36.59%,#a100ff),color-stop(65.35%,#ff50a0),color-stop(81.57%,#ff7800),color-stop(91.26%,#feb149));
        background: linear-gradient(180deg,#460073 13.38%,#a100ff 36.59%,#ff50a0 65.35%,#ff7800 81.57%,#feb149 91.26%);
        display: block;
        height: 0;
        max-height: 23.5625rem;
        position: absolute;
        translate: -24px;
        width: 2px
    }
}

@media(min-width: 1441px) {
    .rad-dynamic-header__gradient-line--desktop {
        max-height:calc(23.5625rem + 26.23521vw - 378.04941px)
    }
}

@media(min-width: 1920px) {
    .rad-dynamic-header__gradient-line--desktop {
        max-height:31.4166666667rem
    }
}

@media(min-width: 1441px) {
    .rad-dynamic-header__gradient-line--desktop {
        translate:calc(0.06681px - 1.67015vw)
    }
}

@media(min-width: 1920px) {
    .rad-dynamic-header__gradient-line--desktop {
        translate:-32px
    }
}

.rad-dynamic-header__header {
    color: #616160;
    top: 0;
    -webkit-transition-duration: 1s;
    transition-duration: 1s;
    -webkit-transition-property: color;
    transition-property: color;
    -webkit-transition-timing-function: cubic-bezier(0.85,0,0,1);
    transition-timing-function: cubic-bezier(0.85,0,0,1);
    -webkit-transition: top 550ms;
    transition: top 550ms
}

@media(min-width: 1024px) {
    .rad-dynamic-header__header {
        --top-gap:3.75rem;
        position: sticky;
        top: calc(var(--rad-nav-height) + var(--top-gap))
    }
}

@media(min-width: 1441px) {
    .rad-dynamic-header__header {
        --top-gap:calc(3.75rem + 4.17537vw - 60.16701px)
    }
}

@media(min-width: 1920px) {
    .rad-dynamic-header__header {
        --top-gap:5rem
    }
}

@media(min-width: 1024px) {
    .rad-global-header--offscreen .rad-dynamic-header__header {
        top:var(--top-gap)
    }

    .rad--has-subnav.rad-global-header--offscreen .rad-dynamic-header__header {
        top: calc(var(--top-gap) + 3.25rem)
    }

    .rad--has-subnav .rad-dynamic-header__header {
        top: calc(var(--rad-nav-height) + 3.25rem + var(--top-gap))
    }
}

.rad-dynamic-header__header-one {
    translate: 0 1lh
}

.rad-dynamic-header__header-two {
    color: #616160;
    opacity: 0;
    -webkit-transition: color 550ms cubic-bezier(0.85,0,0,1) 550ms,opacity 550ms cubic-bezier(0.85,0,0,1),top 550ms ease,translate 1100ms cubic-bezier(0.85,0,0,1);
    transition: color 550ms cubic-bezier(0.85,0,0,1) 550ms,opacity 550ms cubic-bezier(0.85,0,0,1),top 550ms ease,translate 1100ms cubic-bezier(0.85,0,0,1);
    translate: 0 1lh
}

.rad-dynamic-header__header-two .rad-dynamic-header__eyebrow:after,.rad-dynamic-header__header-two .rad-dynamic-header__text:after {
    bottom: 0;
    -webkit-transition: bottom 550ms cubic-bezier(0.85,0,0,1);
    transition: bottom 550ms cubic-bezier(0.85,0,0,1)
}

.rad-dynamic-header--active .rad-dynamic-header__header-one {
    color: #fff;
    max-height: 100vh;
    opacity: 1;
    -webkit-transition: color .5s cubic-bezier(0.85,0,0,1) 550ms,max-height 0s linear 375ms,opacity 1100ms cubic-bezier(0.85,0,0,1) 550ms,top 550ms linear,translate 1s cubic-bezier(0.85,0,0,1) 550ms;
    transition: color .5s cubic-bezier(0.85,0,0,1) 550ms,max-height 0s linear 375ms,opacity 1100ms cubic-bezier(0.85,0,0,1) 550ms,top 550ms linear,translate 1s cubic-bezier(0.85,0,0,1) 550ms;
    translate: 0
}

.rad-dynamic-header--active .rad-dynamic-header__header-one .rad-dynamic-header__eyebrow:after,.rad-dynamic-header--active .rad-dynamic-header__header-one .rad-dynamic-header__text:after {
    bottom: 100%;
    -webkit-transition-delay: 550ms;
    transition-delay: 550ms;
    -webkit-transition-duration: 1s;
    transition-duration: 1s;
    -webkit-transition-property: bottom;
    transition-property: bottom;
    -webkit-transition-timing-function: cubic-bezier(0.85,0,0,1);
    transition-timing-function: cubic-bezier(0.85,0,0,1)
}

.rad-dynamic-header--active .rad-dynamic-header__header-one .rad-dynamic-header__deco-line {
    -webkit-transition-duration: 1s;
    transition-duration: 1s;
    -webkit-transition-property: width;
    transition-property: width;
    -webkit-transition-timing-function: cubic-bezier(0.85,0,0,1);
    transition-timing-function: cubic-bezier(0.85,0,0,1);
    width: 1.5rem
}

@media(min-width: 1024px) {
    .rad-dynamic-header--active .rad-dynamic-header__header-one .rad-dynamic-header__deco-line {
        width:2rem
    }
}

@media(min-width: 1441px) {
    .rad-dynamic-header--active .rad-dynamic-header__header-one .rad-dynamic-header__deco-line {
        width:calc(2rem + 2.22686vw - 32.08907px)
    }
}

@media(min-width: 1920px) {
    .rad-dynamic-header--active .rad-dynamic-header__header-one .rad-dynamic-header__deco-line {
        width:2.6666666667rem
    }
}

@-webkit-keyframes deco-line-out {
    50%,to {
        width: 0
    }
}

@keyframes deco-line-out {
    50%,to {
        width: 0
    }
}

@-webkit-keyframes deco-line-in {
    50% {
        width: 0
    }

    to {
        width: 1.5rem
    }
}

@keyframes deco-line-in {
    50% {
        width: 0
    }

    to {
        width: 1.5rem
    }
}

@media(min-width: 1024px) {
    @-webkit-keyframes deco-line-in {
        50% {
            width:0
        }

        to {
            width: 2rem;
            @media(min-width: 1441px) {
                .rad-dynamic-header--switch-headers {
                    width:calc(2rem + 2.22686vw - 32.08907px)
                }
            }

            @media(min-width: 1920px) {
                .rad-dynamic-header--switch-headers {
                    width:2.6666666667rem
                }
            }
        }
    }

    @keyframes deco-line-in {
        50% {
            width: 0
        }

        to {
            width: 2rem;
            @media(min-width: 1441px) {
                .rad-dynamic-header--switch-headers {
                    width:calc(2rem + 2.22686vw - 32.08907px)
                }
            }

            @media(min-width: 1920px) {
                .rad-dynamic-header--switch-headers {
                    width:2.6666666667rem
                }
            }
        }
    }
}

@media(min-width: 1441px) {
    @-webkit-keyframes deco-line-in {
        50% {
            width:0
        }

        to {
            width: calc(2rem + 2.22686vw - 32.08907px)
        }
    }

    @keyframes deco-line-in {
        50% {
            width: 0
        }

        to {
            width: calc(2rem + 2.22686vw - 32.08907px)
        }
    }
}

@media(min-width: 1920px) {
    @-webkit-keyframes deco-line-in {
        50% {
            width:0
        }

        to {
            width: 2.6666666667rem
        }
    }

    @keyframes deco-line-in {
        50% {
            width: 0
        }

        to {
            width: 2.6666666667rem
        }
    }
}

@-webkit-keyframes gradient-line-in {
    50% {
        width: 0
    }

    to {
        width: 100%
    }
}

@keyframes gradient-line-in {
    50% {
        width: 0
    }

    to {
        width: 100%
    }
}

@-webkit-keyframes gradient-line-desktop-in {
    50% {
        height: 0
    }

    to {
        height: 100%
    }
}

@keyframes gradient-line-desktop-in {
    50% {
        height: 0
    }

    to {
        height: 100%
    }
}

.rad-dynamic-header--switch-headers .rad-dynamic-header__header-one {
    color: #616160;
    max-height: 0;
    opacity: 0;
    -webkit-transition-delay: 0s,550ms,550ms,0s,0s;
    transition-delay: 0s,550ms,550ms,0s,0s;
    translate: 0 -1lh
}

.rad-dynamic-header--switch-headers .rad-dynamic-header__header-one .rad-dynamic-header__deco-line {
    -webkit-animation: deco-line-out 1100ms cubic-bezier(0.85,0,0,1) forwards;
    animation: deco-line-out 1100ms cubic-bezier(0.85,0,0,1) forwards
}

.rad-dynamic-header--switch-headers .rad-dynamic-header__header-one .rad-dynamic-header__eyebrow:after,.rad-dynamic-header--switch-headers .rad-dynamic-header__header-one .rad-dynamic-header__text:after {
    bottom: 0;
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
    -webkit-transition-duration: .5s;
    transition-duration: .5s
}

.rad-dynamic-header--switch-headers .rad-dynamic-header__header-two {
    color: #fff;
    opacity: 1;
    -webkit-transition: color .5s cubic-bezier(0.85,0,0,1) 550ms,opacity 550ms cubic-bezier(0.85,0,0,1) 550ms,top 550ms ease,translate 1100ms cubic-bezier(0.85,0,0,1) 550ms;
    transition: color .5s cubic-bezier(0.85,0,0,1) 550ms,opacity 550ms cubic-bezier(0.85,0,0,1) 550ms,top 550ms ease,translate 1100ms cubic-bezier(0.85,0,0,1) 550ms;
    translate: 0
}

.rad-dynamic-header--switch-headers .rad-dynamic-header__header-two .rad-dynamic-header__deco-line {
    -webkit-animation: deco-line-in 1100ms cubic-bezier(0.85,0,0,1) forwards;
    animation: deco-line-in 1100ms cubic-bezier(0.85,0,0,1) forwards
}

.rad-dynamic-header--switch-headers .rad-dynamic-header__header-two .rad-dynamic-header__gradient-line {
    -webkit-animation: gradient-line-in 1100ms cubic-bezier(0.85,0,0,1) forwards;
    animation: gradient-line-in 1100ms cubic-bezier(0.85,0,0,1) forwards
}

.rad-dynamic-header--switch-headers .rad-dynamic-header__header-two .rad-dynamic-header__gradient-line--desktop {
    -webkit-animation: gradient-line-desktop-in 1100ms cubic-bezier(0.85,0,0,1) forwards;
    animation: gradient-line-desktop-in 1100ms cubic-bezier(0.85,0,0,1) forwards
}

.rad-dynamic-header--switch-headers .rad-dynamic-header__header-two .rad-dynamic-header__eyebrow:after,.rad-dynamic-header--switch-headers .rad-dynamic-header__header-two .rad-dynamic-header__text:after {
    bottom: 100%;
    -webkit-transition: bottom 550ms cubic-bezier(0.85,0,0,1) 550ms;
    transition: bottom 550ms cubic-bezier(0.85,0,0,1) 550ms
}

.rad-dynamic-header--switch-headers .rad-dynamic-header__eyebrow {
    padding-top: 0
}

@-webkit-keyframes slide-in-from-right {
    0% {
        translate: 80px;
        @media(min-width: 1441px) {
            translate:calc(-0.22269px + 5.56715vw)
        }

        @media(min-width: 1920px) {
            translate:106.6666666667px
        }
    }

    to {
        translate: 0
    }
}

@keyframes slide-in-from-right {
    0% {
        translate: 80px;
        @media(min-width: 1441px) {
            translate:calc(-0.22269px + 5.56715vw)
        }

        @media(min-width: 1920px) {
            translate:106.6666666667px
        }
    }

    to {
        translate: 0
    }
}

.rad-carousel-image-and-text,.rad-carousel-image-and-text__heading-and-title {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column
}

.rad-carousel-image-and-text__heading-and-title {
    margin-bottom: 1.5rem;
    padding-left: var(--rad-spacing-sm);
    padding-right: var(--rad-spacing-sm);
    row-gap: 0.5rem
}

@media(min-width: 600px) {
    .rad-carousel-image-and-text__heading-and-title {
        padding-left:var(--rad-spacing-md);
        padding-right: var(--rad-spacing-md)
    }
}

.rad-component--nested .rad-carousel-image-and-text__heading-and-title {
    padding-left: 0;
    padding-right: 0
}

@media(min-width: 1441px) {
    .rad-carousel-image-and-text__heading-and-title {
        row-gap:calc(0.5rem + 0.55672vw - 8.02227px)
    }
}

@media(min-width: 1920px) {
    .rad-carousel-image-and-text__heading-and-title {
        row-gap:0.6666666667rem
    }
}

@media(min-width: 600px) {
    .rad-carousel-image-and-text__heading-and-title {
        margin-bottom:2.5rem
    }
}

@media(min-width: 1024px) {
    .rad-carousel-image-and-text__heading-and-title {
        margin-bottom:3rem
    }
}

@media(min-width: 1441px) {
    .rad-carousel-image-and-text__heading-and-title {
        margin-bottom:calc(3rem + 3.34029vw - 48.13361px)
    }
}

@media(min-width: 1920px) {
    .rad-carousel-image-and-text__heading-and-title {
        margin-bottom:4rem
    }
}

@media(min-width: 600px) {
    .rad-carousel-image-and-text__heading,.rad-carousel-image-and-text__title {
        width:calc(150% - var(--rad-spacing-sm)*3/4*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-sm)*0);
        width: calc(75% - var(--rad-spacing-sm)*7/8*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-carousel-image-and-text__heading,.rad-carousel-image-and-text__title {
        width:calc(50% - var(--rad-spacing-sm)*11/12*6 + var(--rad-spacing-sm)*5 + var(--rad-spacing-md)*0);
        width: calc(200% - var(--rad-spacing-sm)*3/4*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-sm)*0);
        width: calc(100% - var(--rad-spacing-sm)*7/8*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0);
        width: calc(66.66667% - var(--rad-spacing-sm)*11/12*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0)
    }
}

.rad-carousel-image-and-text__heading {
    font-size: 1.75rem;
    font-weight: 500;
    letter-spacing: -0.03em;
    line-height: 1.3
}

.rad-carousel-image-and-text__heading:lang(ja-JP),.rad-carousel-image-and-text__heading:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-carousel-image-and-text__heading {
        font-size:2.5rem;
        line-height: 1.2
    }
}

@media(min-width: 1024px) {
    .rad-carousel-image-and-text__heading {
        font-size:3rem;
        line-height: 1.2
    }
}

@media(min-width: 1441px) {
    .rad-carousel-image-and-text__heading {
        font-size:calc(3rem + 3.34029vw - 48.13361px)
    }
}

@media(min-width: 1920px) {
    .rad-carousel-image-and-text__heading {
        font-size:4rem
    }
}

.rad-carousel-image-and-text__heading-title {
    font-size: 1.375rem;
    font-weight: 500;
    letter-spacing: -0.02em;
    line-height: 1.25
}

.rad-carousel-image-and-text__heading-title:lang(ja-JP),.rad-carousel-image-and-text__heading-title:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-carousel-image-and-text__heading-title {
        font-size:1.75rem;
        line-height: 1.3
    }
}

@media(min-width: 1024px) {
    .rad-carousel-image-and-text__heading-title {
        font-size:2rem;
        line-height: 1.2
    }
}

@media(min-width: 1441px) {
    .rad-carousel-image-and-text__heading-title {
        font-size:calc(2rem + 2.22686vw - 32.08907px)
    }
}

@media(min-width: 1920px) {
    .rad-carousel-image-and-text__heading-title {
        font-size:2.6666666667rem
    }
}

.rad-carousel-image-and-text__title {
    font-family: GT Sectra Fine,Palatino;
    font-size: 1.25rem;
    font-weight: 300;
    line-height: 1.25
}

@media(min-width: 600px) {
    .rad-carousel-image-and-text__title {
        font-size:1.5rem;
        line-height: 1.25
    }
}

@media(min-width: 1024px) {
    .rad-carousel-image-and-text__title {
        font-size:1.75rem;
        line-height: 1.25
    }
}

@media(min-width: 1441px) {
    .rad-carousel-image-and-text__title {
        font-size:calc(1.75rem + 1.9485vw - 28.07794px)
    }
}

@media(min-width: 1920px) {
    .rad-carousel-image-and-text__title {
        font-size:2.3333333333rem
    }
}

.rad-carousel-image-and-text .flickity-viewport {
    -webkit-animation: slide-in-from-right 550ms cubic-bezier(0.85,0,0,1);
    animation: slide-in-from-right 550ms cubic-bezier(0.85,0,0,1)
}

.rad-carousel-image-and-text__slide {
    --columns-width: calc(100% - var(--rad-spacing-sm)*2);
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: var(--rad-spacing-sm);
    row-gap: 1.5rem;
    width: calc(var(--columns-width)*0.95 + var(--rad-spacing-sm))
}

@media(min-width: 600px) {
    .rad-carousel-image-and-text__slide {
        --columns-width:calc(100% - var(--rad-spacing-md)*2);
        padding-left: var(--rad-spacing-md);
        padding-right: calc(var(--columns-width)*0.16 - var(--rad-spacing-md));
        width: calc(var(--columns-width)*0.9)
    }
}

@media(min-width: 1024px) {
    .rad-carousel-image-and-text__slide {
        --columns-width:calc((100% - var(--rad-spacing-md))/0.89);
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        -ms-flex-direction: row;
        flex-direction: row;
        justify-content: space-between;
        padding-left: 0;
        padding-right: calc(11% - var(--rad-spacing-md)*2*0.11);
        width: calc(100% - var(--rad-spacing-md))
    }
}

.rad-carousel-image-and-text__slide .cmp-image__image {
    display: block
}

.rad-carousel-image-and-text__slide .cmp-video__video {
    aspect-ratio: 16/9;
    display: block;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%
}

.rad-carousel-image-and-text__slide .rad-media-overlay--hide {
    display: none
}

@media(min-width: 1024px) {
    .rad-carousel-image-and-text__slide .cmp-image,.rad-carousel-image-and-text__slide .rad-media {
        width:calc(var(--columns-width)*0.4 + var(--rad-spacing-md))
    }
}

.rad-carousel-image-and-text__slide-text {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    justify-content: flex-end
}

@media(min-width: 1024px) {
    .rad-carousel-image-and-text__slide-text {
        width:calc(var(--columns-width)*0.44)
    }
}

.rad-carousel-image-and-text__slide-title {
    font-size: 1.125rem;
    font-weight: 500;
    letter-spacing: -0.02em;
    line-height: 1.4
}

.rad-carousel-image-and-text__slide-title:lang(ja-JP),.rad-carousel-image-and-text__slide-title:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-carousel-image-and-text__slide-title {
        font-size:1.25rem;
        line-height: 1.4
    }
}

@media(min-width: 1024px) {
    .rad-carousel-image-and-text__slide-title {
        font-size:1.5rem;
        line-height: 1.3
    }
}

@media(min-width: 1441px) {
    .rad-carousel-image-and-text__slide-title {
        font-size:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-carousel-image-and-text__slide-title {
        font-size:2rem
    }
}

.rad-carousel-image-and-text__slide .rad-carousel-block__body,.rad-carousel-image-and-text__slide-body {
    font-size: .875rem;
    font-weight: 500;
    font-weight: 400;
    letter-spacing: -0.005em;
    line-height: 1.5;
    margin-top: .5rem;
    text-underline-offset: .25rem
}

.rad-carousel-image-and-text__slide .rad-carousel-block__body:lang(ja-JP),.rad-carousel-image-and-text__slide .rad-carousel-block__body:lang(zh-CN),.rad-carousel-image-and-text__slide-body:lang(ja-JP),.rad-carousel-image-and-text__slide-body:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-carousel-image-and-text__slide .rad-carousel-block__body,.rad-carousel-image-and-text__slide-body {
        font-size:1rem
    }
}

@media(min-width: 1441px) {
    .rad-carousel-image-and-text__slide .rad-carousel-block__body,.rad-carousel-image-and-text__slide-body {
        font-size:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-carousel-image-and-text__slide .rad-carousel-block__body,.rad-carousel-image-and-text__slide-body {
        font-size:1.3333333333rem
    }
}

.rad-carousel-image-and-text__slide .rad-carousel-block__body:lang(ja-JP),.rad-carousel-image-and-text__slide .rad-carousel-block__body:lang(zh-CN),.rad-carousel-image-and-text__slide-body:lang(ja-JP),.rad-carousel-image-and-text__slide-body:lang(zh-CN) {
    font-weight: 400
}

@media(min-width: 600px) {
    .rad-carousel-image-and-text__slide .rad-carousel-block__body,.rad-carousel-image-and-text__slide-body {
        margin-top:.75rem
    }
}

@media(min-width: 1024px) {
    .rad-carousel-image-and-text__slide .rad-carousel-block__body,.rad-carousel-image-and-text__slide-body {
        margin-top:1rem
    }
}

@media(min-width: 1441px) {
    .rad-carousel-image-and-text__slide .rad-carousel-block__body,.rad-carousel-image-and-text__slide-body {
        margin-top:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-carousel-image-and-text__slide .rad-carousel-block__body,.rad-carousel-image-and-text__slide-body {
        margin-top:1.3333333333rem
    }
}

.rad-carousel-image-and-text__slide-cta {
    margin-top: .25rem
}

@media(min-width: 600px) {
    .rad-carousel-image-and-text__slide-cta {
        margin-bottom:10px
    }
}

@media(min-width: 1024px) {
    .rad-carousel-image-and-text__slide-cta {
        margin-top:0.75rem
    }
}

@media(min-width: 1441px) {
    .rad-carousel-image-and-text__slide-cta {
        margin-top:calc(0.75rem + 0.83507vw - 12.0334px)
    }
}

@media(min-width: 1920px) {
    .rad-carousel-image-and-text__slide-cta {
        margin-top:1rem
    }
}

.rad-carousel-image-and-text__slide .cmp-image__image--is-loading {
    aspect-ratio: 3/2
}

.rad-carousel-image-and-text__slide .cmp-container,.rad-carousel-image-and-text__slide .cmp-experiencefragment {
    width: 100%
}

.rad-carousel-image-and-text__slide .cmp-container .rad-carousel-image-and-text {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 0;
    padding: 0
}

.rad-carousel-image-and-text__slide .cmp-container .rad-carousel-image-and-text__slide {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    margin: 0;
    padding: 0
}

.rad-carousel-image-and-text__slide .cmp-container .rad-carousel-image-and-text__slide-text .rad-carousel-block__body,.rad-carousel-image-and-text__slide .cmp-container .rad-carousel-image-and-text__slide-text .rad-carousel-block__title {
    margin-bottom: 0
}

.rad-carousel-image-and-text__custom-controls-wrapper {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: space-between;
    margin-right: var(--rad-spacing-sm);
    order: 3
}

@media(min-width: 600px) {
    .rad-carousel-image-and-text__custom-controls-wrapper {
        margin-right:var(--rad-spacing-md)
    }
}

.rad-carousel-image-and-text__play-pause-wrapper {
    position: relative
}

.rad-carousel-image-and-text__prev-next-wrapper,.rad-form__section {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.rad-form__section {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-bottom: 3rem
}

@media(min-width: 1441px) {
    .rad-form__section {
        padding-bottom:calc(3rem + 3.34029vw - 48.13361px)
    }
}

@media(min-width: 1920px) {
    .rad-form__section {
        padding-bottom:4rem
    }
}

.rad-form__section .button.form-inputs+.button.form-inputs,.rad-form__section .button.form-inputs+.formcaptcha.captcha,.rad-form__section .button.form-inputs+.formoptions.options,.rad-form__section .button.form-inputs+.text.form-inputs,.rad-form__section .formcaptcha.captcha+.button.form-inputs,.rad-form__section .formcaptcha.captcha+.formcaptcha.captcha,.rad-form__section .formcaptcha.captcha+.formoptions.options,.rad-form__section .formcaptcha.captcha+.text.form-inputs,.rad-form__section .formoptions.options+.button.form-inputs,.rad-form__section .formoptions.options+.formcaptcha.captcha,.rad-form__section .formoptions.options+.formoptions.options,.rad-form__section .formoptions.options+.text.form-inputs,.rad-form__section .text.form-inputs+.button.form-inputs,.rad-form__section .text.form-inputs+.formcaptcha.captcha,.rad-form__section .text.form-inputs+.formoptions.options,.rad-form__section .text.form-inputs+.text.form-inputs {
    padding-top: 2rem
}

@media(min-width: 1441px) {
    .rad-form__section .button.form-inputs+.button.form-inputs,.rad-form__section .button.form-inputs+.formcaptcha.captcha,.rad-form__section .button.form-inputs+.formoptions.options,.rad-form__section .button.form-inputs+.text.form-inputs,.rad-form__section .formcaptcha.captcha+.button.form-inputs,.rad-form__section .formcaptcha.captcha+.formcaptcha.captcha,.rad-form__section .formcaptcha.captcha+.formoptions.options,.rad-form__section .formcaptcha.captcha+.text.form-inputs,.rad-form__section .formoptions.options+.button.form-inputs,.rad-form__section .formoptions.options+.formcaptcha.captcha,.rad-form__section .formoptions.options+.formoptions.options,.rad-form__section .formoptions.options+.text.form-inputs,.rad-form__section .text.form-inputs+.button.form-inputs,.rad-form__section .text.form-inputs+.formcaptcha.captcha,.rad-form__section .text.form-inputs+.formoptions.options,.rad-form__section .text.form-inputs+.text.form-inputs {
        padding-top:calc(2rem + 2.22686vw - 32.08907px)
    }
}

@media(min-width: 1920px) {
    .rad-form__section .button.form-inputs+.button.form-inputs,.rad-form__section .button.form-inputs+.formcaptcha.captcha,.rad-form__section .button.form-inputs+.formoptions.options,.rad-form__section .button.form-inputs+.text.form-inputs,.rad-form__section .formcaptcha.captcha+.button.form-inputs,.rad-form__section .formcaptcha.captcha+.formcaptcha.captcha,.rad-form__section .formcaptcha.captcha+.formoptions.options,.rad-form__section .formcaptcha.captcha+.text.form-inputs,.rad-form__section .formoptions.options+.button.form-inputs,.rad-form__section .formoptions.options+.formcaptcha.captcha,.rad-form__section .formoptions.options+.formoptions.options,.rad-form__section .formoptions.options+.text.form-inputs,.rad-form__section .text.form-inputs+.button.form-inputs,.rad-form__section .text.form-inputs+.formcaptcha.captcha,.rad-form__section .text.form-inputs+.formoptions.options,.rad-form__section .text.form-inputs+.text.form-inputs {
        padding-top:2.6666666667rem
    }
}

.rad-form__section--checks .button.form-inputs+.button.form-inputs,.rad-form__section--checks .button.form-inputs+.formcaptcha.captcha,.rad-form__section--checks .button.form-inputs+.formoptions.options,.rad-form__section--checks .button.form-inputs+.text.form-inputs,.rad-form__section--checks .formcaptcha.captcha+.button.form-inputs,.rad-form__section--checks .formcaptcha.captcha+.formcaptcha.captcha,.rad-form__section--checks .formcaptcha.captcha+.formoptions.options,.rad-form__section--checks .formcaptcha.captcha+.text.form-inputs,.rad-form__section--checks .formoptions.options+.button.form-inputs,.rad-form__section--checks .formoptions.options+.formcaptcha.captcha,.rad-form__section--checks .formoptions.options+.formoptions.options,.rad-form__section--checks .formoptions.options+.text.form-inputs,.rad-form__section--checks .text.form-inputs+.button.form-inputs,.rad-form__section--checks .text.form-inputs+.formcaptcha.captcha,.rad-form__section--checks .text.form-inputs+.formoptions.options,.rad-form__section--checks .text.form-inputs+.text.form-inputs {
    padding-top: 1.5rem
}

@media(min-width: 1441px) {
    .rad-form__section--checks .button.form-inputs+.button.form-inputs,.rad-form__section--checks .button.form-inputs+.formcaptcha.captcha,.rad-form__section--checks .button.form-inputs+.formoptions.options,.rad-form__section--checks .button.form-inputs+.text.form-inputs,.rad-form__section--checks .formcaptcha.captcha+.button.form-inputs,.rad-form__section--checks .formcaptcha.captcha+.formcaptcha.captcha,.rad-form__section--checks .formcaptcha.captcha+.formoptions.options,.rad-form__section--checks .formcaptcha.captcha+.text.form-inputs,.rad-form__section--checks .formoptions.options+.button.form-inputs,.rad-form__section--checks .formoptions.options+.formcaptcha.captcha,.rad-form__section--checks .formoptions.options+.formoptions.options,.rad-form__section--checks .formoptions.options+.text.form-inputs,.rad-form__section--checks .text.form-inputs+.button.form-inputs,.rad-form__section--checks .text.form-inputs+.formcaptcha.captcha,.rad-form__section--checks .text.form-inputs+.formoptions.options,.rad-form__section--checks .text.form-inputs+.text.form-inputs {
        padding-top:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-form__section--checks .button.form-inputs+.button.form-inputs,.rad-form__section--checks .button.form-inputs+.formcaptcha.captcha,.rad-form__section--checks .button.form-inputs+.formoptions.options,.rad-form__section--checks .button.form-inputs+.text.form-inputs,.rad-form__section--checks .formcaptcha.captcha+.button.form-inputs,.rad-form__section--checks .formcaptcha.captcha+.formcaptcha.captcha,.rad-form__section--checks .formcaptcha.captcha+.formoptions.options,.rad-form__section--checks .formcaptcha.captcha+.text.form-inputs,.rad-form__section--checks .formoptions.options+.button.form-inputs,.rad-form__section--checks .formoptions.options+.formcaptcha.captcha,.rad-form__section--checks .formoptions.options+.formoptions.options,.rad-form__section--checks .formoptions.options+.text.form-inputs,.rad-form__section--checks .text.form-inputs+.button.form-inputs,.rad-form__section--checks .text.form-inputs+.formcaptcha.captcha,.rad-form__section--checks .text.form-inputs+.formoptions.options,.rad-form__section--checks .text.form-inputs+.text.form-inputs {
        padding-top:2rem
    }
}

.rad-form__section-header {
    color: #fff;
    font-size: 1.25rem;
    font-weight: 500;
    letter-spacing: -0.02em;
    line-height: 1.3;
    margin-bottom: 1rem
}

.rad-form__section-header:lang(ja-JP),.rad-form__section-header:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-form__section-header {
        font-size:1.5rem;
        line-height: 1.3
    }
}

@media(min-width: 1024px) {
    .rad-form__section-header {
        font-size:1.75rem;
        line-height: 1.25
    }
}

@media(min-width: 1441px) {
    .rad-form__section-header {
        font-size:calc(1.75rem + 1.9485vw - 28.07794px)
    }
}

@media(min-width: 1920px) {
    .rad-form__section-header {
        font-size:2.3333333333rem
    }
}

@media(min-width: 1441px) {
    .rad-form__section-header {
        margin-bottom:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-form__section-header {
        margin-bottom:1.3333333333rem
    }
}

.rad-form-field__message {
    color: #a2a2a0;
    display: none;
    font-size: .75rem;
    font-weight: 500;
    font-weight: 400;
    letter-spacing: -0.005em;
    line-height: 1.5;
    margin-top: 0.5rem;
    text-underline-offset: .25rem
}

.rad-form-field__message:lang(ja-JP),.rad-form-field__message:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-form-field__message {
        font-size:0.875rem
    }
}

@media(min-width: 1441px) {
    .rad-form-field__message {
        font-size:calc(0.875rem + 0.97425vw - 14.03897px)
    }
}

@media(min-width: 1920px) {
    .rad-form-field__message {
        font-size:1.1666666667rem
    }
}

.rad-form-field__message:lang(ja-JP),.rad-form-field__message:lang(zh-CN) {
    font-weight: 400
}

@media(min-width: 1441px) {
    .rad-form-field__message {
        margin-top:calc(0.5rem + 0.55672vw - 8.02227px)
    }
}

@media(min-width: 1920px) {
    .rad-form-field__message {
        margin-top:0.6666666667rem
    }
}

.rad-form__label {
    color: #a2a2a0;
    display: block;
    font-size: .75rem;
    font-weight: 500;
    letter-spacing: -0.005em;
    line-height: 1.5;
    margin-bottom: 0.5rem;
    text-underline-offset: .25rem
}

.rad-form__label:lang(ja-JP),.rad-form__label:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-form__label {
        font-size:0.875rem
    }
}

@media(min-width: 1441px) {
    .rad-form__label {
        font-size:calc(0.875rem + 0.97425vw - 14.03897px)
    }
}

@media(min-width: 1920px) {
    .rad-form__label {
        font-size:1.1666666667rem
    }
}

@media(min-width: 1441px) {
    .rad-form__label {
        margin-bottom:calc(0.5rem + 0.55672vw - 8.02227px)
    }
}

@media(min-width: 1920px) {
    .rad-form__label {
        margin-bottom:0.6666666667rem
    }
}

.rad-form .rad-dropdown--select {
    font-family: Graphik;
    font-size: 1rem;
    font-weight: 400;
    letter-spacing: -0.005em;
    line-height: 1.5;
    text-underline-offset: .25rem
}

.rad-form .rad-dropdown--select.rad-dropdown--untouched .rad-dropdown__category:hover {
    background-color: #2b2b2b
}

.rad-form .rad-dropdown--select.rad-dropdown--untouched .rad-dropdown__category-title {
    color: #a2a2a0
}

.rad-form .rad-dropdown--select .rad-dropdown__category:focus-visible {
    outline-width: 1px
}

.rad-form .rad-dropdown--select--outline .rad-dropdown__category {
    border: 1px solid #616160;
    border-radius: 1px
}

.rad-form .rad-dropdown--select--outline .rad-dropdown__category:hover {
    border-color: #a2a2a0
}

.rad-form .rad-dropdown--select--outline .rad-dropdown__category:focus-visible {
    border-color: transparent
}

.rad-form .rad-dropdown--select-error+.rad-form-field__message {
    color: #ff3246;
    display: block
}

.rad-form .rad-dropdown--select-error .rad-dropdown__category .rad-icon {
    color: #ff3246
}

.rad-form .rad-dropdown--select-error .rad-dropdown__category-title {
    color: #a2a2a0
}

.rad-form .rad-dropdown--select-error.rad-dropdown--select--outline {
    border-bottom-width: 0
}

.rad-form .rad-dropdown--select-error.rad-dropdown--select--outline .rad-dropdown__category {
    border-bottom-color: #ff3246
}

.rad-form .rad-dropdown--select-error.rad-dropdown--select--outline .rad-dropdown__category:focus-visible {
    border-width: 0;
    outline-color: #ff3246
}

.rad-form__textarea {
    background-color: #202020;
    border: 1px solid transparent;
    border-radius: 1px;
    color: #fff;
    font-family: Graphik;
    font-size: 1rem;
    font-weight: 400;
    letter-spacing: -0.005em;
    line-height: 1.5;
    min-height: 7.75rem;
    min-width: 6.625rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    padding-top: 0.875rem;
    text-underline-offset: .25rem;
    width: 100%
}

@media(min-width: 1441px) {
    .rad-form__textarea {
        padding-top:calc(0.875rem + 0.97425vw - 14.03897px)
    }
}

@media(min-width: 1920px) {
    .rad-form__textarea {
        padding-top:1.1666666667rem
    }
}

@media(min-width: 1441px) {
    .rad-form__textarea {
        padding-left:calc(0.75rem + 0.83507vw - 12.0334px)
    }
}

@media(min-width: 1920px) {
    .rad-form__textarea {
        padding-left:1rem
    }
}

@media(min-width: 1441px) {
    .rad-form__textarea {
        padding-right:calc(0.75rem + 0.83507vw - 12.0334px)
    }
}

@media(min-width: 1920px) {
    .rad-form__textarea {
        padding-right:1rem
    }
}

.rad-form__textarea:focus-visible {
    outline-offset: 0;
    outline-width: 1px
}

.rad-form__textarea:hover {
    background-color: #2b2b2b
}

.rad-form__textarea--outline {
    border: 1px solid #616160;
    border-radius: 1px
}

.rad-form__textarea--outline:hover {
    border-color: #a2a2a0
}

.rad-form__textarea--outline.rad-form__textarea--error:hover {
    border-bottom-color: #ff3246
}

.rad-form__textarea--outline.rad-form__textarea--error:focus-visible {
    border-color: transparent
}

.rad-form__textarea--error {
    border-bottom-color: #ff3246;
    color: #ff3246
}

.rad-form__textarea--error:focus-visible {
    border-bottom-color: transparent;
    outline-color: #ff3246
}

.rad-form__textarea--error::-moz-selection {
    background-color: #39005e
}

.rad-form__textarea--error::selection {
    background-color: #39005e
}

.rad-form .rad-textarea__helper-content {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    gap: 0.5rem;
    justify-content: space-between
}

@media(min-width: 1441px) {
    .rad-form .rad-textarea__helper-content {
        gap:calc(0.5rem + 0.55672vw - 8.02227px)
    }
}

@media(min-width: 1920px) {
    .rad-form .rad-textarea__helper-content {
        gap:0.6666666667rem
    }
}

.rad-form .rad-textarea__helper-content .rad-form-field__message {
    display: block;
    visibility: hidden
}

.rad-form .rad-textarea__helper-content .rad-form-field__message--shown {
    color: #ff3246;
    visibility: visible
}

.rad-form__char-count {
    -ms-flex-item-align: end;
    align-self: flex-end;
    color: #a2a2a0;
    font-size: .75rem;
    font-weight: 500;
    font-weight: 400;
    letter-spacing: -0.005em;
    line-height: 1.5;
    margin-top: 0.5rem;
    text-underline-offset: .25rem
}

.rad-form__char-count:lang(ja-JP),.rad-form__char-count:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-form__char-count {
        font-size:0.875rem
    }
}

@media(min-width: 1441px) {
    .rad-form__char-count {
        font-size:calc(0.875rem + 0.97425vw - 14.03897px)
    }
}

@media(min-width: 1920px) {
    .rad-form__char-count {
        font-size:1.1666666667rem
    }
}

.rad-form__char-count:lang(ja-JP),.rad-form__char-count:lang(zh-CN) {
    font-weight: 400
}

@media(min-width: 1441px) {
    .rad-form__char-count {
        margin-top:calc(0.5rem + 0.55672vw - 8.02227px)
    }
}

@media(min-width: 1920px) {
    .rad-form__char-count {
        margin-top:0.6666666667rem
    }
}

.rad-form .rad-textarea__a11y-content {
    clip: rect(0 0 0 0);
    -webkit-clip-path: inset(50%);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px
}

.rad-form__textbox {
    background-color: #202020;
    border: 0;
    color: #fff;
    font-family: Graphik;
    font-size: 1rem;
    font-weight: 400;
    letter-spacing: -0.005em;
    line-height: 1.5;
    padding: 0.875rem 0.75rem;
    text-underline-offset: .25rem;
    width: 100%
}

@media(min-width: 1441px) {
    .rad-form__textbox {
        padding-top:calc(0.875rem + 0.97425vw - 14.03897px)
    }
}

@media(min-width: 1920px) {
    .rad-form__textbox {
        padding-top:1.1666666667rem
    }
}

@media(min-width: 1441px) {
    .rad-form__textbox {
        padding-bottom:calc(0.875rem + 0.97425vw - 14.03897px)
    }
}

@media(min-width: 1920px) {
    .rad-form__textbox {
        padding-bottom:1.1666666667rem
    }
}

@media(min-width: 1441px) {
    .rad-form__textbox {
        padding-left:calc(0.75rem + 0.83507vw - 12.0334px)
    }
}

@media(min-width: 1920px) {
    .rad-form__textbox {
        padding-left:1rem
    }
}

@media(min-width: 1441px) {
    .rad-form__textbox {
        padding-right:calc(0.75rem + 0.83507vw - 12.0334px)
    }
}

@media(min-width: 1920px) {
    .rad-form__textbox {
        padding-right:1rem
    }
}

.rad-form__textbox:hover {
    background-color: #2b2b2b
}

.rad-form__textbox:focus-visible {
    background-color: #202020;
    outline-offset: 0;
    outline-width: 1px
}

.rad-form__textbox--outline {
    border: 1px solid #616160;
    border-radius: 1px
}

.rad-form__textbox--outline:hover {
    border-color: #a2a2a0
}

.rad-form__textbox--outline:focus-visible {
    border: 0
}

.rad-form__textbox--outline.rad-form__textbox--error:hover {
    border-bottom-color: #ff3246
}

.rad-form__textbox--error {
    border-bottom: 1px solid #ff3246;
    color: #a2a2a0
}

.rad-form__textbox--error+.rad-form-field__message {
    color: #ff3246;
    display: block
}

.rad-form__textbox--error:focus-visible {
    border-color: transparent;
    outline-color: #ff3246
}

.rad-form__textbox--error::-moz-selection {
    background-color: #39005e
}

.rad-form__textbox--error::selection {
    background-color: #39005e
}

.rad-form__field--check {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    gap: 0.5rem;
    justify-content: flex-start;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem
}

@media(min-width: 1441px) {
    .rad-form__field--check {
        gap:calc(0.5rem + 0.55672vw - 8.02227px)
    }
}

@media(min-width: 1920px) {
    .rad-form__field--check {
        gap:0.6666666667rem
    }
}

@media(min-width: 1441px) {
    .rad-form__field--check {
        padding-top:calc(0.5rem + 0.55672vw - 8.02227px)
    }
}

@media(min-width: 1920px) {
    .rad-form__field--check {
        padding-top:0.6666666667rem
    }
}

@media(min-width: 1441px) {
    .rad-form__field--check {
        padding-bottom:calc(0.5rem + 0.55672vw - 8.02227px)
    }
}

@media(min-width: 1920px) {
    .rad-form__field--check {
        padding-bottom:0.6666666667rem
    }
}

.rad-form__checkbox {
    -ms-flex-negative: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: #000;
    border: 1px solid #e3e3df;
    flex-shrink: 0;
    height: 1.125rem;
    width: 1.125rem
}

@media(min-width: 1441px) {
    .rad-form__checkbox {
        width:calc(1.125rem + 1.25261vw - 18.0501px)
    }
}

@media(min-width: 1920px) {
    .rad-form__checkbox {
        width:1.5rem
    }
}

@media(min-width: 1441px) {
    .rad-form__checkbox {
        height:calc(1.125rem + 1.25261vw - 18.0501px)
    }
}

@media(min-width: 1920px) {
    .rad-form__checkbox {
        height:1.5rem
    }
}

.rad-form__checkbox:hover {
    border-color: #a2a2a0
}

.rad-form__checkbox:active {
    border-color: #e3e3df
}

.rad-form__checkbox:focus-visible {
    border-color: #fff;
    outline-offset: 2px
}

.rad-form__checkbox:checked {
    background-color: #a100ff;
    border-color: #a100ff
}

.rad-form__checkbox:checked:after {
    color: #fff;
    content: "";
    font-family: icont;
    font-size: .5rem;
    font-style: normal;
    font-weight: 400;
    margin: 0.1875rem;
    position: absolute
}

@media(min-width: 1441px) {
    .rad-form__checkbox:checked:after {
        margin:calc(0.1875rem + 0.20877vw - 3.00835px)
    }
}

@media(min-width: 1920px) {
    .rad-form__checkbox:checked:after {
        margin:0.25rem
    }
}

.rad-form__checkbox:checked:hover {
    background-color: #7500c0;
    border-color: #7500c0
}

.rad-form__checkbox:checked:active {
    background-color: #460073;
    border-color: #460073
}

.rad-form__checkbox:checked:focus-visible {
    background-color: #a100ff;

}

.rad-form__live-region {
    clip: rect(0 0 0 0);
    -webkit-clip-path: inset(50%);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px
}

.rad-form--contact {
    padding-left: var(--rad-spacing-sm);
    padding-right: var(--rad-spacing-sm)
}

@media(min-width: 600px) {
    .rad-form--contact {
        padding-left:var(--rad-spacing-md);
        padding-right: var(--rad-spacing-md)
    }
}

.rad-component--nested .rad-form--contact {
    padding-left: 0;
    padding-right: 0
}

@media(min-width: 1024px) {
    .rad-form--contact .rad-form__field {
        width:calc(200% - var(--rad-spacing-sm)*3/4*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-sm)*0);
        width: calc(100% - var(--rad-spacing-sm)*7/8*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0);
        width: calc(66.66667% - var(--rad-spacing-sm)*11/12*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0)
    }
}

.rad-form__modal {
    background-color: rgba(0,0,0,.6);
    bottom: 0;
    display: none;
    left: 0;
    padding: 1rem;
    position: absolute;
    right: 0;
    top: 0
}

@media(min-width: 600px) {
    .rad-form__modal {
        padding:3rem 4rem 0
    }
}

.rad-form__modal--open {
    display: block
}

.rad-form__modal .rad-form {
    background-color: #2b2b2b;
    padding-top: 1rem
}

@media(min-width: 600px) {
    .rad-form__modal .rad-form {
        padding-top:2rem
    }
}

@media(min-width: 1024px) {
    .rad-form__modal .rad-form {
        margin:0 auto;
        width: 50rem
    }
}

@media(min-width: 1441px) {
    .rad-form__modal .rad-form {
        width:calc(50rem + 55.67154vw - 802.22686px)
    }
}

@media(min-width: 1920px) {
    .rad-form__modal .rad-form {
        width:66.6666666667rem
    }
}

@media(min-width: 1024px) {
    .rad-form__modal .rad-form .rad-form__field {
        width:100%
    }
}

.rad-form__modal .rad-form__section {
    paddgng-bottom: 2rem
}

@media(min-width: 1441px) {
    .rad-form__modal .rad-form__section {
        paddgng-bottom:calc(2rem + 2.22686vw - 32.08907px)
    }
}

@media(min-width: 1920px) {
    .rad-form__modal .rad-form__section {
        paddgng-bottom:2.6666666667rem
    }
}

.rad-form__modal .rad-form__buttons {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    gap: 1rem;
    justify-content: flex-end;
    padding-bottom: 1rem
}

@media(min-width: 1441px) {
    .rad-form__modal .rad-form__buttons {
        gap:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-form__modal .rad-form__buttons {
        gap:1.3333333333rem
    }
}

@media(min-width: 600px) {
    .rad-form__modal .rad-form__buttons {
        padding-bottom:2rem
    }
}

@media(min-width: 1441px) {
    .rad-form__modal .rad-form__buttons {
        padding-bottom:calc(2rem + 2.22686vw - 32.08907px)
    }
}

@media(min-width: 1920px) {
    .rad-form__modal .rad-form__buttons {
        padding-bottom:2.6666666667rem
    }
}

.rad-text-card__visual {
    margin-bottom: 1rem
}

@media(min-width: 600px) {
    .rad-text-card__visual {
        margin-bottom:1.5rem
    }
}

@media(min-width: 1441px) {
    .rad-text-card__visual {
        margin-bottom:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-text-card__visual {
        margin-bottom:2rem
    }
}

.rad-text-card .cmp-image__image,.rad-text-card .cmp-video__video {
    display: block
}

.rad-text-card__decorative-line {
    background-color: #a100ff;
    height: 4px;
    width: 32px
}

@media(min-width: 1441px) {
    .rad-text-card__decorative-line {
        height:calc(-0.01113px + 0.27836vw)
    }
}

@media(min-width: 1920px) {
    .rad-text-card__decorative-line {
        height:5.3333333333px
    }
}

@media(min-width: 1441px) {
    .rad-text-card__decorative-line {
        width:calc(-0.08907px + 2.22686vw)
    }
}

@media(min-width: 1920px) {
    .rad-text-card__decorative-line {
        width:42.6666666667px
    }
}

.rad-text-card__decorative-video {
    position: relative
}

.rad-text-card__decorative-video .cmp-video__video {
    width: 100%
}

.rad-text-card__logo .cmp-image__image {
    aspect-ratio: 2;
    height: 80px;
    width: auto
}

@media(min-width: 600px) {
    .rad-text-card__logo .cmp-image__image {
        height:48px
    }
}

@media(min-width: 1024px) {
    .rad-text-card__logo .cmp-image__image {
        height:80px
    }
}

@media(min-width: 1441px) {
    .rad-text-card__logo .cmp-image__image {
        height:calc(-0.22269px + 5.56715vw)
    }
}

@media(min-width: 1920px) {
    .rad-text-card__logo .cmp-image__image {
        height:106.6666666667px
    }
}

.rad-text-card__pictogram .cmp-image__image {
    aspect-ratio: 1;
    height: 80px;
    width: auto
}

@media(min-width: 600px) {
    .rad-text-card__pictogram .cmp-image__image {
        height:48px
    }
}

@media(min-width: 1024px) {
    .rad-text-card__pictogram .cmp-image__image {
        height:80px
    }
}

@media(min-width: 1441px) {
    .rad-text-card__pictogram .cmp-image__image {
        height:calc(-0.22269px + 5.56715vw)
    }
}

@media(min-width: 1920px) {
    .rad-text-card__pictogram .cmp-image__image {
        height:106.6666666667px
    }
}

.rad-text-card__label {
    font-size: .75rem;
    font-weight: 500;
    letter-spacing: .02em;
    line-height: 1.2;
    margin-bottom: .5rem;
    text-transform: uppercase
}

.rad-text-card__label:lang(ja-JP),.rad-text-card__label:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 1024px) {
    .rad-text-card__label {
        font-size:0.875rem
    }
}

@media(min-width: 1441px) {
    .rad-text-card__label {
        font-size:calc(0.875rem + 0.97425vw - 14.03897px)
    }
}

@media(min-width: 1920px) {
    .rad-text-card__label {
        font-size:1.1666666667rem
    }
}

@media(min-width: 1024px) {
    .rad-text-card__label {
        margin-bottom:1rem
    }
}

@media(min-width: 1441px) {
    .rad-text-card__label {
        margin-bottom:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-text-card__label {
        margin-bottom:1.3333333333rem
    }
}

.rad-text-card--large-text .rad-text-card__label {
    margin-bottom: .75rem
}

@media(min-width: 1024px) {
    .rad-text-card--large-text .rad-text-card__label {
        margin-bottom:1.5rem
    }
}

@media(min-width: 1441px) {
    .rad-text-card--large-text .rad-text-card__label {
        margin-bottom:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-text-card--large-text .rad-text-card__label {
        margin-bottom:2rem
    }
}

.rad-text-card__title {
    font-size: 1.125rem;
    font-weight: 500;
    letter-spacing: -0.02em;
    line-height: 1.4;
    margin-bottom: .5rem
}

.rad-text-card__title:lang(ja-JP),.rad-text-card__title:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-text-card__title {
        font-size:1.25rem;
        line-height: 1.4
    }
}

@media(min-width: 1024px) {
    .rad-text-card__title {
        font-size:1.5rem;
        line-height: 1.3
    }
}

@media(min-width: 1441px) {
    .rad-text-card__title {
        font-size:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-text-card__title {
        font-size:2rem
    }
}

@media(min-width: 600px) {
    .rad-text-card__title {
        margin-bottom:.75rem
    }
}

@media(min-width: 1024px) {
    .rad-text-card__title {
        margin-bottom:1rem
    }
}

@media(min-width: 1441px) {
    .rad-text-card__title {
        margin-bottom:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-text-card__title {
        margin-bottom:1.3333333333rem
    }
}

.rad-text-card--large-text .rad-text-card__title {
    font-size: 1.25rem;
    font-weight: 500;
    letter-spacing: -0.02em;
    line-height: 1.3;
    margin-bottom: .75rem
}

.rad-text-card--large-text .rad-text-card__title:lang(ja-JP),.rad-text-card--large-text .rad-text-card__title:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-text-card--large-text .rad-text-card__title {
        font-size:1.5rem;
        line-height: 1.3
    }
}

@media(min-width: 1024px) {
    .rad-text-card--large-text .rad-text-card__title {
        font-size:1.75rem;
        line-height: 1.25
    }
}

@media(min-width: 1441px) {
    .rad-text-card--large-text .rad-text-card__title {
        font-size:calc(1.75rem + 1.9485vw - 28.07794px)
    }
}

@media(min-width: 1920px) {
    .rad-text-card--large-text .rad-text-card__title {
        font-size:2.3333333333rem
    }
}

@media(min-width: 600px) {
    .rad-text-card--large-text .rad-text-card__title {
        margin-bottom:1rem
    }
}

@media(min-width: 1024px) {
    .rad-text-card--large-text .rad-text-card__title {
        margin-bottom:1.5rem
    }
}

@media(min-width: 1441px) {
    .rad-text-card--large-text .rad-text-card__title {
        margin-bottom:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-text-card--large-text .rad-text-card__title {
        margin-bottom:2rem
    }
}

.rad-text-card__body {
    font-size: .875rem;
    font-weight: 500;
    font-weight: 400;
    letter-spacing: -0.005em;
    line-height: 1.5;
    margin-bottom: .25rem;
    text-underline-offset: .25rem
}

.rad-text-card__body:lang(ja-JP),.rad-text-card__body:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-text-card__body {
        font-size:1rem
    }
}

@media(min-width: 1441px) {
    .rad-text-card__body {
        font-size:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-text-card__body {
        font-size:1.3333333333rem
    }
}

.rad-text-card__body:lang(ja-JP),.rad-text-card__body:lang(zh-CN) {
    font-weight: 400
}

@media(min-width: 1024px) {
    .rad-text-card__body {
        margin-bottom:0.75rem
    }
}

@media(min-width: 1441px) {
    .rad-text-card__body {
        margin-bottom:calc(0.75rem + 0.83507vw - 12.0334px)
    }
}

@media(min-width: 1920px) {
    .rad-text-card__body {
        margin-bottom:1rem
    }
}

.rad-text-card--large-text .rad-text-card__body {
    font-size: 1rem;
    font-weight: 500;
    font-weight: 400;
    letter-spacing: -0.005em;
    line-height: 1.6;
    text-underline-offset: .25rem
}

.rad-text-card--large-text .rad-text-card__body:lang(ja-JP),.rad-text-card--large-text .rad-text-card__body:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-text-card--large-text .rad-text-card__body {
        font-size:1.125rem
    }
}

@media(min-width: 1024px) {
    .rad-text-card--large-text .rad-text-card__body {
        font-size:1.25rem
    }
}

@media(min-width: 1441px) {
    .rad-text-card--large-text .rad-text-card__body {
        font-size:calc(1.25rem + 1.39179vw - 20.05567px)
    }
}

@media(min-width: 1920px) {
    .rad-text-card--large-text .rad-text-card__body {
        font-size:1.6666666667rem
    }
}

.rad-text-card--large-text .rad-text-card__body:lang(ja-JP),.rad-text-card--large-text .rad-text-card__body:lang(zh-CN) {
    font-weight: 400
}

@media(min-width: 600px)and (max-width:1023px) {
    .rad-text-card--large-text .rad-text-card__body {
        margin-bottom:.5rem
    }
}

.rad-snackbar {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    align-items: center;
    background-color: #f1f1ef;
    border-radius: .25rem;
    -webkit-box-shadow: 0 8px 12px 6px rgba(0,0,0,.15),0 4px 4px rgba(0,0,0,.3);
    box-shadow: 0 8px 12px 6px rgba(0,0,0,.15),0 4px 4px rgba(0,0,0,.3);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    justify-content: space-between;
    padding: .25rem .75rem
}

@media(min-width: 600px) {
    .rad-snackbar {
        -webkit-box-pack:start;
        -ms-flex-pack: start;
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        justify-content: flex-start;
        padding: .5rem 1.5rem
    }
}

@media(min-width: 1024px) {
    .rad-snackbar {
        max-width:60%
    }
}

.rad-snackbar__container {
    bottom: 0;
    display: none;
    left: 0;
    padding: 0 .5rem .5rem;
    position: fixed;
    right: 0;
    z-index: 960
}

@media(min-width: 600px) {
    .rad-snackbar__container {
        padding-bottom:1.5rem;
        padding-left: 1.5rem;
        padding-right: 1.5rem
    }
}

@media(min-width: 1441px) {
    .rad-snackbar__container {
        padding-bottom:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-snackbar__container {
        padding-bottom:2rem
    }
}

@media(min-width: 1441px) {
    .rad-snackbar__container {
        padding-right:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-snackbar__container {
        padding-right:2rem
    }
}

@media(min-width: 1441px) {
    .rad-snackbar__container {
        padding-left:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-snackbar__container {
        padding-left:2rem
    }
}

@media(min-width: 1024px) {
    .rad-snackbar__container {
        margin:0 auto;
        max-width: 1920px
    }
}

.rad-snackbar__container--visible {
    display: block
}

.rad-snackbar__content {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row
}

.rad-snackbar__icon {
    -ms-flex-negative: 0;
    color: #37d002;
    flex-shrink: 0;
    margin-right: 0.5rem;
    width: 1.5rem
}

@media(min-width: 1441px) {
    .rad-snackbar__icon {
        margin-right:calc(0.5rem + 0.55672vw - 8.02227px)
    }
}

@media(min-width: 1920px) {
    .rad-snackbar__icon {
        margin-right:0.6666666667rem
    }
}

@media(min-width: 1441px) {
    .rad-snackbar__icon {
        width:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-snackbar__icon {
        width:2rem
    }
}

.rad-snackbar__message {
    color: #000;
    font-size: .875rem;
    font-weight: 500;
    font-weight: 400;
    letter-spacing: -0.005em;
    line-height: 1.5;
    text-underline-offset: .25rem
}

.rad-snackbar__message:lang(ja-JP),.rad-snackbar__message:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-snackbar__message {
        font-size:1rem
    }
}

@media(min-width: 1441px) {
    .rad-snackbar__message {
        font-size:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-snackbar__message {
        font-size:1.3333333333rem
    }
}

.rad-snackbar__message:lang(ja-JP),.rad-snackbar__message:lang(zh-CN) {
    font-weight: 400
}

.rad-snackbar__dismiss-button {
    margin-left: .75rem
}

.rad-snackbar__dismiss-button .rad-button__text {
    color: #000
}

@media(min-width: 600px) {
    .rad-snackbar__dismiss-button {
        margin-left:1.5rem
    }
}

@media(min-width: 1441px) {
    .rad-snackbar__dismiss-button {
        margin-left:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-snackbar__dismiss-button {
        margin-left:2rem
    }
}

.rad-contact-text {
    margin-right: 2rem;
    width: calc(75% - var(--rad-spacing-sm)*3/4*3 + var(--rad-spacing-sm)*2 + var(--rad-spacing-sm)*0)
}

@media(min-width: 1441px) {
    .rad-contact-text {
        margin-right:calc(2rem + 2.22686vw - 32.08907px)
    }
}

@media(min-width: 1920px) {
    .rad-contact-text {
        margin-right:2.6666666667rem
    }
}

@media(min-width: 600px) {
    .rad-contact-text {
        width:calc(37.5% - var(--rad-spacing-sm)*7/8*3 + var(--rad-spacing-sm)*2 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-contact-text {
        width:calc(25% - var(--rad-spacing-sm)*11/12*3 + var(--rad-spacing-sm)*2 + var(--rad-spacing-md)*0)
    }
}

.rad-contact-text__name {
    font-size: 1.125rem;
    font-weight: 500;
    letter-spacing: -0.02em;
    line-height: 1.4
}

.rad-contact-text__name:lang(ja-JP),.rad-contact-text__name:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-contact-text__name {
        font-size:1.25rem;
        line-height: 1.4
    }
}

@media(min-width: 1024px) {
    .rad-contact-text__name {
        font-size:1.5rem;
        line-height: 1.3
    }
}

@media(min-width: 1441px) {
    .rad-contact-text__name {
        font-size:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-contact-text__name {
        font-size:2rem
    }
}

.rad-contact-text__title {
    font-size: .875rem;
    font-weight: 500;
    letter-spacing: -0.005em;
    line-height: 1.5;
    margin-top: .5rem;
    text-underline-offset: .25rem
}

.rad-contact-text__title:lang(ja-JP),.rad-contact-text__title:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-contact-text__title {
        font-size:1rem
    }
}

@media(min-width: 1441px) {
    .rad-contact-text__title {
        font-size:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-contact-text__title {
        font-size:1.3333333333rem
    }
}

@media(min-width: 600px) {
    .rad-contact-text__title {
        margin-top:1rem
    }
}

@media(min-width: 1441px) {
    .rad-contact-text__title {
        margin-top:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-contact-text__title {
        margin-top:1.3333333333rem
    }
}

.rad-contact-text__phone {
    font-size: .875rem;
    font-weight: 500;
    font-weight: 400;
    letter-spacing: -0.005em;
    line-height: 1.5;
    margin-top: 1rem;
    text-underline-offset: .25rem
}

.rad-contact-text__phone:lang(ja-JP),.rad-contact-text__phone:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-contact-text__phone {
        font-size:1rem
    }
}

@media(min-width: 1441px) {
    .rad-contact-text__phone {
        font-size:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-contact-text__phone {
        font-size:1.3333333333rem
    }
}

.rad-contact-text__phone:lang(ja-JP),.rad-contact-text__phone:lang(zh-CN) {
    font-weight: 400
}

@media(min-width: 1441px) {
    .rad-contact-text__phone {
        margin-top:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-contact-text__phone {
        margin-top:1.3333333333rem
    }
}

.rad-contact-text__cta {
    margin-top: 0.75rem
}

@media(min-width: 1441px) {
    .rad-contact-text__cta {
        margin-top:calc(0.75rem + 0.83507vw - 12.0334px)
    }
}

@media(min-width: 1920px) {
    .rad-contact-text__cta {
        margin-top:1rem
    }
}

.rad-mega-accordion__content-wrapper .rad-contact-text__title {
    font-size: .875rem;
    font-weight: 500;
    letter-spacing: -0.005em;
    line-height: 1.5;
    text-underline-offset: .25rem
}

.rad-mega-accordion__content-wrapper .rad-contact-text__title:lang(ja-JP),.rad-mega-accordion__content-wrapper .rad-contact-text__title:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-mega-accordion__content-wrapper .rad-contact-text__title {
        font-size:1rem
    }
}

@media(min-width: 1441px) {
    .rad-mega-accordion__content-wrapper .rad-contact-text__title {
        font-size:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-mega-accordion__content-wrapper .rad-contact-text__title {
        font-size:1.3333333333rem
    }
}

.rad-mega-accordion__content-wrapper .rad-contact-text__phone {
    font-size: .875rem;
    font-weight: 500;
    font-weight: 400;
    letter-spacing: -0.005em;
    line-height: 1.5;
    text-underline-offset: .25rem
}

.rad-mega-accordion__content-wrapper .rad-contact-text__phone:lang(ja-JP),.rad-mega-accordion__content-wrapper .rad-contact-text__phone:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-mega-accordion__content-wrapper .rad-contact-text__phone {
        font-size:1rem
    }
}

@media(min-width: 1441px) {
    .rad-mega-accordion__content-wrapper .rad-contact-text__phone {
        font-size:calc(1rem + 1.11343vw - 16.04454px)
    }
}

@media(min-width: 1920px) {
    .rad-mega-accordion__content-wrapper .rad-contact-text__phone {
        font-size:1.3333333333rem
    }
}

.rad-mega-accordion__content-wrapper .rad-contact-text__phone:lang(ja-JP),.rad-mega-accordion__content-wrapper .rad-contact-text__phone:lang(zh-CN) {
    font-weight: 400
}

.rad-events-card {
    background-color: #101010;
    -webkit-transition-duration: 550ms;
    transition-duration: 550ms;
    -webkit-transition-property: background-color;
    transition-property: background-color;
    -webkit-transition-timing-function: cubic-bezier(0.85,0,0,1);
    transition-timing-function: cubic-bezier(0.85,0,0,1)
}

.rad-events-card--open,.rad-events-card:hover {
    background-color: #202020
}

.rad-events-card:has(.rad-events-card__header:hover) .rad-events-card__toggle {
    background-color: #7500c0
}

.rad-events-card__header {
    -webkit-column-gap: 1.5rem;
    -moz-column-gap: 1.5rem;
    column-gap: 1.5rem;
    cursor: pointer;
    display: grid;
    grid-template-areas: "date toggle" "title toggle" "time toggle";
    grid-template-columns: 1fr fit-content(0);
    padding: 24px 16px
}

@media(min-width: 1441px) {
    .rad-events-card__header {
        -webkit-column-gap:calc(1.5rem + 1.67015vw - 24.06681px);
        -moz-column-gap: calc(1.5rem + 1.67015vw - 24.06681px);
        column-gap: calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-events-card__header {
        -webkit-column-gap:2rem;
        -moz-column-gap: 2rem;
        column-gap: 2rem
    }
}

@media(min-width: 1441px) {
    .rad-events-card__header {
        padding-top:calc(-0.06681px + 1.67015vw)
    }
}

@media(min-width: 1920px) {
    .rad-events-card__header {
        padding-top:32px
    }
}

@media(min-width: 1441px) {
    .rad-events-card__header {
        padding-bottom:calc(-0.06681px + 1.67015vw)
    }
}

@media(min-width: 1920px) {
    .rad-events-card__header {
        padding-bottom:32px
    }
}

@media(min-width: 600px) {
    .rad-events-card__header {
        padding-left:32px;
        padding-right: 32px
    }
}

@media(min-width: 1441px) {
    .rad-events-card__header {
        padding-left:calc(-0.08907px + 2.22686vw)
    }
}

@media(min-width: 1920px) {
    .rad-events-card__header {
        padding-left:42.6666666667px
    }
}

@media(min-width: 1441px) {
    .rad-events-card__header {
        padding-right:calc(-0.08907px + 2.22686vw)
    }
}

@media(min-width: 1920px) {
    .rad-events-card__header {
        padding-right:42.6666666667px
    }
}

@media(min-width: 1024px) {
    .rad-events-card__header {
        grid-template-areas:"date title toggle" "date time toggle";
        grid-template-columns: fit-content(100px) 1fr fit-content(0)
    }
}

@media(min-width: 1441px) {
    .rad-events-card__header {
        grid-template-columns:fit-content(calc(-0.27836px + 6.95894vw)) 1fr fit-content(0)
    }
}

@media(min-width: 1920px) {
    .rad-events-card__header {
        grid-template-columns:fit-content(133.3333333333px) 1fr fit-content(0)
    }
}

.rad-events-card__date {
    font-size: .875rem;
    font-weight: 500;
    grid-area: date;
    letter-spacing: -0.005em;
    line-height: 1.5;
    margin-bottom: .25rem;
    text-underline-offset: .25rem
}

.rad-events-card__date:lang(ja-JP),.rad-events-card__date:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-events-card__date {
        font-size:1rem
    }
}

@media(min-width: 1024px) {
    .rad-events-card__date {
        font-size:1.125rem;
        line-height: 1.6
    }
}

@media(min-width: 1441px) {
    .rad-events-card__date {
        font-size:calc(1.125rem + 1.25261vw - 18.0501px)
    }
}

@media(min-width: 1920px) {
    .rad-events-card__date {
        font-size:1.5rem
    }
}

@media(min-width: 1024px) {
    .rad-events-card__date {
        margin-bottom:0
    }
}

.rad-events-card__title {
    font-size: 1rem;
    font-weight: 500;
    grid-area: title;
    letter-spacing: -0.005em;
    line-height: 1.6;
    margin-bottom: 0.75rem;
    text-underline-offset: .25rem
}

.rad-events-card__title:lang(ja-JP),.rad-events-card__title:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-events-card__title {
        font-size:1.125rem
    }
}

@media(min-width: 1024px) {
    .rad-events-card__title {
        font-size:1.25rem
    }
}

@media(min-width: 1441px) {
    .rad-events-card__title {
        font-size:calc(1.25rem + 1.39179vw - 20.05567px)
    }
}

@media(min-width: 1920px) {
    .rad-events-card__title {
        font-size:1.6666666667rem
    }
}

@media(min-width: 1441px) {
    .rad-events-card__title {
        margin-bottom:calc(0.75rem + 0.83507vw - 12.0334px)
    }
}

@media(min-width: 1920px) {
    .rad-events-card__title {
        margin-bottom:1rem
    }
}

.rad-events-card__time {
    color: #e3e3df;
    font-size: .75rem;
    font-weight: 500;
    font-weight: 400;
    grid-area: time;
    letter-spacing: -0.005em;
    line-height: 1.5;
    text-underline-offset: .25rem
}

.rad-events-card__time:lang(ja-JP),.rad-events-card__time:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-events-card__time {
        font-size:0.875rem
    }
}

@media(min-width: 1441px) {
    .rad-events-card__time {
        font-size:calc(0.875rem + 0.97425vw - 14.03897px)
    }
}

@media(min-width: 1920px) {
    .rad-events-card__time {
        font-size:1.1666666667rem
    }
}

.rad-events-card__time:lang(ja-JP),.rad-events-card__time:lang(zh-CN) {
    font-weight: 400
}

.rad-events-card__toggle {
    align-self: center;
    background-color: #a100ff;
    grid-area: toggle;
    height: 24px;
    justify-self: center;
    min-height: auto;
    min-width: auto;
    pointer-events: none;
    -webkit-transition-duration: 550ms;
    transition-duration: 550ms;
    -webkit-transition-property: background-color;
    transition-property: background-color;
    -webkit-transition-timing-function: cubic-bezier(0.85,0,0,1);
    transition-timing-function: cubic-bezier(0.85,0,0,1);
    width: 24px
}

@media(min-width: 1441px) {
    .rad-events-card__toggle {
        height:calc(-0.06681px + 1.67015vw)
    }
}

@media(min-width: 1920px) {
    .rad-events-card__toggle {
        height:32px
    }
}

@media(min-width: 1441px) {
    .rad-events-card__toggle {
        width:calc(-0.06681px + 1.67015vw)
    }
}

@media(min-width: 1920px) {
    .rad-events-card__toggle {
        width:32px
    }
}

.rad-events-card__toggle .rad-accordion-atom__toggle-icon {
    height: 100%;
    width: 100%
}

.rad-events-card__toggle .rad-accordion-atom__toggle-icon:after,.rad-events-card__toggle .rad-accordion-atom__toggle-icon:before {
    left: 50%;
    translate: -50% -50%
}

.rad-events-card__content {
    padding: 4px 16px 24px
}

@media(min-width: 1441px) {
    .rad-events-card__content {
        padding-bottom:calc(-0.06681px + 1.67015vw)
    }
}

@media(min-width: 1920px) {
    .rad-events-card__content {
        padding-bottom:32px
    }
}

@media(min-width: 600px) {
    .rad-events-card__content {
        padding-left:32px;
        padding-right: 80px;
        padding-top: 8px
    }
}

@media(min-width: 1441px) {
    .rad-events-card__content {
        padding-right:calc(-0.22269px + 5.56715vw)
    }
}

@media(min-width: 1920px) {
    .rad-events-card__content {
        padding-right:106.6666666667px
    }
}

@media(min-width: 1441px) {
    .rad-events-card__content {
        padding-top:calc(-0.02227px + 0.55672vw)
    }
}

@media(min-width: 1920px) {
    .rad-events-card__content {
        padding-top:10.6666666667px
    }
}

@media(min-width: 1024px) {
    .rad-events-card__content {
        padding-left:156px
    }
}

@media(min-width: 1441px) {
    .rad-events-card__content {
        padding-left:calc(-0.43424px + 10.85595vw)
    }
}

@media(min-width: 1920px) {
    .rad-events-card__content {
        padding-left:208px
    }
}

.rad-events-card__location {
    color: #e3e3df;
    font-size: .75rem;
    font-weight: 500;
    font-weight: 400;
    letter-spacing: -0.005em;
    line-height: 1.5;
    margin-bottom: 0.75rem;
    text-underline-offset: .25rem;
    white-space: preserve-spaces
}

.rad-events-card__location:lang(ja-JP),.rad-events-card__location:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-events-card__location {
        font-size:0.875rem
    }
}

@media(min-width: 1441px) {
    .rad-events-card__location {
        font-size:calc(0.875rem + 0.97425vw - 14.03897px)
    }
}

@media(min-width: 1920px) {
    .rad-events-card__location {
        font-size:1.1666666667rem
    }
}

.rad-events-card__location:lang(ja-JP),.rad-events-card__location:lang(zh-CN) {
    font-weight: 400
}

@media(min-width: 1441px) {
    .rad-events-card__location {
        margin-bottom:calc(0.75rem + 0.83507vw - 12.0334px)
    }
}

@media(min-width: 1920px) {
    .rad-events-card__location {
        margin-bottom:1rem
    }
}

.rad-events-card__description {
    font-size: .75rem;
    font-weight: 500;
    font-weight: 400;
    letter-spacing: -0.005em;
    line-height: 1.5;
    margin-bottom: 0.75rem;
    text-underline-offset: .25rem
}

.rad-events-card__description:lang(ja-JP),.rad-events-card__description:lang(zh-CN) {
    font-weight: 700
}

@media(min-width: 600px) {
    .rad-events-card__description {
        font-size:0.875rem
    }
}

@media(min-width: 1441px) {
    .rad-events-card__description {
        font-size:calc(0.875rem + 0.97425vw - 14.03897px)
    }
}

@media(min-width: 1920px) {
    .rad-events-card__description {
        font-size:1.1666666667rem
    }
}

.rad-events-card__description:lang(ja-JP),.rad-events-card__description:lang(zh-CN) {
    font-weight: 400
}

@media(min-width: 1441px) {
    .rad-events-card__description {
        margin-bottom:calc(0.75rem + 0.83507vw - 12.0334px)
    }
}

@media(min-width: 1920px) {
    .rad-events-card__description {
        margin-bottom:1rem
    }
}

.rad-events-card__buttons {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-column-gap: 24px;
    -moz-column-gap: 24px;
    column-gap: 24px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    row-gap: 4px
}

@media(min-width: 1441px) {
    .rad-events-card__buttons {
        -webkit-column-gap:calc(-0.06681px + 1.67015vw);
        -moz-column-gap: calc(-0.06681px + 1.67015vw);
        column-gap: calc(-0.06681px + 1.67015vw)
    }
}

@media(min-width: 1920px) {
    .rad-events-card__buttons {
        -webkit-column-gap:32px;
        -moz-column-gap: 32px;
        column-gap: 32px
    }
}

@media(min-width: 600px) {
    .rad-events-card__buttons {
        -webkit-box-orient:horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row
    }
}

.rad-events-page {
    padding-bottom: 3rem;
    padding-left: var(--rad-spacing-sm);
    padding-right: var(--rad-spacing-sm)
}

@media(min-width: 600px) {
    .rad-events-page {
        padding-left:var(--rad-spacing-md);
        padding-right: var(--rad-spacing-md)
    }
}

.rad-component--nested .rad-events-page {
    padding-left: 0;
    padding-right: 0
}

@media(min-width: 600px) {
    .rad-events-page {
        padding-bottom:3.75rem
    }
}

@media(min-width: 1024px) {
    .rad-events-page {
        -webkit-column-gap:3.25rem;
        -moz-column-gap: 3.25rem;
        column-gap: 3.25rem;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        padding-bottom: 3rem
    }
}

@media(min-width: 1441px) {
    .rad-events-page {
        -webkit-column-gap:calc(3.25rem + 3.61865vw - 52.14475px);
        -moz-column-gap: calc(3.25rem + 3.61865vw - 52.14475px);
        column-gap: calc(3.25rem + 3.61865vw - 52.14475px)
    }
}

@media(min-width: 1920px) {
    .rad-events-page {
        -webkit-column-gap:4.3333333333rem;
        -moz-column-gap: 4.3333333333rem;
        column-gap: 4.3333333333rem
    }
}

@media(min-width: 1441px) {
    .rad-events-page {
        padding-bottom:calc(3rem + 3.34029vw - 48.13361px)
    }
}

@media(min-width: 1920px) {
    .rad-events-page {
        padding-bottom:4rem
    }
}

.rad-events-page__live-region {
    clip: rect(0 0 0 0);
    -webkit-clip-path: inset(50%);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px
}

@media(min-width: 1024px) {
    .rad-events-page__filters {
        width:calc(min(100vw, 1920px)/4*4 - var(--rad-spacing-sm)*2/4*4 - var(--rad-spacing-sm)*3/4*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-sm)*0);
        width: calc(min(100vw, 1920px)/8*4 - var(--rad-spacing-md)*2/8*4 - var(--rad-spacing-sm)*7/8*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0);
        width: calc(min(100vw, 1920px)/12*4 - var(--rad-spacing-md)*2/12*4 - var(--rad-spacing-sm)*11/12*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0)
    }
}

.rad-events-page .rad-filters-vertical__mobile-filter-panel-header {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end
}

@media(min-width: 1024px) {
    .rad-events-page__main {
        width:calc(min(100vw, 1920px)/4*8 - var(--rad-spacing-sm)*2/4*8 - var(--rad-spacing-sm)*3/4*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-sm)*0);
        width: calc(min(100vw, 1920px)/8*8 - var(--rad-spacing-md)*2/8*8 - var(--rad-spacing-sm)*7/8*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0);
        width: calc(min(100vw, 1920px)/12*8 - var(--rad-spacing-md)*2/12*8 - var(--rad-spacing-sm)*11/12*8 + var(--rad-spacing-sm)*7 + var(--rad-spacing-md)*0)
    }
}

.rad-events-page__cards {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    row-gap: 1rem
}

@media(min-width: 1024px) {
    .rad-events-page__cards {
        row-gap:1.5rem
    }
}

@media(min-width: 1441px) {
    .rad-events-page__cards {
        row-gap:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-events-page__cards {
        row-gap:2rem
    }
}

.rad-events-page__pagination {
    margin-top: 1rem
}

@media(min-width: 600px) {
    .rad-events-page__pagination {
        margin:1.5rem auto 0
    }
}

@media(min-width: 1024px) {
    .rad-events-page__pagination {
        margin-right:0;
        margin-top: 2rem
    }
}

@media(min-width: 1441px) {
    .rad-events-page__pagination {
        margin-top:calc(2rem + 2.22686vw - 32.08907px)
    }
}

@media(min-width: 1920px) {
    .rad-events-page__pagination {
        margin-top:2.6666666667rem
    }
}

.rad-events-page__no-results {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    display: none;
    -ms-flex-direction: column;
    flex-direction: column;
    justify-content: center;
    padding-top: 3rem;
    row-gap: 3rem;
    width: calc(100% - var(--rad-spacing-sm)*3/4*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-sm)*0)
}

@media(min-width: 600px) {
    .rad-events-page__no-results {
        width:calc(50% - var(--rad-spacing-sm)*7/8*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 1024px) {
    .rad-events-page__no-results {
        width:calc(33.33333% - var(--rad-spacing-sm)*11/12*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 600px) {
    .rad-events-page__no-results {
        margin:0 auto;
        padding-top: 3.75rem;
        row-gap: 2rem
    }
}

@media(min-width: 1441px) {
    .rad-events-page__no-results {
        padding-top:calc(3.75rem + 4.17537vw - 60.16701px)
    }
}

@media(min-width: 1920px) {
    .rad-events-page__no-results {
        padding-top:5rem
    }
}

@media(min-width: 1024px) {
    .rad-events-page__no-results {
        row-gap:1.5rem;
        width: calc(min(100vw, 1920px)/4*4 - var(--rad-spacing-sm)*2/4*4 - var(--rad-spacing-sm)*3/4*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-sm)*0)
    }
}

@media(min-width: 1441px) {
    .rad-events-page__no-results {
        row-gap:calc(1.5rem + 1.67015vw - 24.06681px)
    }
}

@media(min-width: 1920px) {
    .rad-events-page__no-results {
        row-gap:2rem
    }
}

@media(min-width: 1024px) {
    .rad-events-page__no-results {
        width:calc(min(100vw, 1920px)/8*4 - var(--rad-spacing-md)*2/8*4 - var(--rad-spacing-sm)*7/8*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0);
        width: calc(min(100vw, 1920px)/12*4 - var(--rad-spacing-md)*2/12*4 - var(--rad-spacing-sm)*11/12*4 + var(--rad-spacing-sm)*3 + var(--rad-spacing-md)*0)
    }
}

@media(min-width: 600px) {
    .rad-events-page__no-results-graphic-content {
        -webkit-box-ordinal-group:0;
        -ms-flex-order: -1;
        order: -1
    }
}

.rad-events-page__no-results--visible {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}
