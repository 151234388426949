.blog-page {
    display: flex;
    padding: 2rem;
    background-color: #ebebeb;
}
@media (max-width: 991px) {
    .blog-page {
        display: block;
        padding: 2rem;
    }
}
.sidebar {
    flex: 0 0 300px;
    padding-right: 2rem;
}


.sidebar input[type="text"] {
    border: none;
    border-bottom: 1px solid #000000; /* Only bottom border */
    outline: none;
    background: transparent;
    padding: 8px;
    width: 100%;
    padding: 15px;
    font-size: 18px;
    margin: 25px 10px 65px;
    padding-bottom: 5px;
}

/* When the input is focused, remove the bottom border */
.sidebar input[type="text"]:focus {
    border-bottom: 1px solid #000000; /* Only bottom border */
}
.filters label {
    font-weight: bold;
    display: block;
    margin-bottom: 0.5rem;
}

.filters select {
    width: 100%;
    padding: 0.5rem;

    margin-bottom: 10px;
    width: 100%;
    border: 2px solid #637284;
    border-radius: 30px;
    padding: 10px !important;
    background-position: 98% 22px;
    background-color: var(--white);
    font-size: 16px;
    line-height: 36px;
    font-family: var(--pnr);
    appearance: none;
    background-image: url(../../public/down-arrow.png);
    background-repeat: no-repeat;
    background-size: 35px;
    margin-top: 10px;
    color: #5d6c80;
    margin-bottom: 1rem;
}
.filters option{
    border-radius: 20px;
    border-color: #637284;
}


.content {
    flex: 1;
}

.featured-blog .featured-card {
    background-color: #2b2b2b;
    color: #fff;
    padding: 1rem;
    margin-bottom: 2rem;
    border-radius: 8px;
}

.featured-card img {
    width: 100%;
    border-radius: 8px;
    margin-bottom: 1rem;
}

.featured-card h3 {
    font-size: 1.5rem;
    color: #7F3E98;
}

.blog-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 1.5rem;
}

.blog-card {
    background-color: #fff;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.blog-card img {
    width: 100%;
    height: auto;
}

.blog-card h3 {
    padding: 1rem;
    font-size: 1.25rem;
}

.blog-card p {
    padding: 0 1rem 1rem;
    color: #666;
}
