:root {
  --body-font-family: 'proxima Nova', sans-serif;
  ---background-color: #7F3E98;

}

.engineer h1 {
  font-family: var(--body-font-family);
  color: #222222;
  font-size: 30px;
  font-weight: 800;
}

.engineer h2 {
  font-family: var(--body-font-family);
  font-weight: 700;
}

/* Section Styling */
.services-sections {
  padding: 50px 20px;
  background-color: #f5f5f5;
}

@media (max-width:991px) {
  .cards-container {
    display: block !important;
  }
  .card1{
    width: 100% !important;
  }
}
.card1{
  width: 50%;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.section-title {
  font-size: 28px;
  margin-bottom: 30px;
  color: #333;
  text-align: left;
}

/* Cards Layout */
.cards-container {
  display: flex;
  /* justify-content: center; */
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  margin: auto;
  justify-content: center;
}

.card {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  justify-content: center;

}

.card:hover {
  transform: translateY(-10px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
}

.card-img {
  width: 100%;
  height: 180px;
  object-fit: cover;
}

.card-content {
  padding: 20px;
}

.card-content h3 {
  font-size: 20px;
  margin-bottom: 10px;
  color: #333;
}

.card-content p {
  font-size: 16px;
  color: #666;
  line-height: 1.6;
}

/* Large Card for 'Resource Skill Enhancement' */
.large-card .card-img {
  height: 230px;

}

.read-more-btn {
  display: inline-block;
  padding: 10px 20px;
  background-color: #800080;
  color: white;
  font-size: 16px;
  border: none;
  border-radius: 20px;
  margin-top: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.read-more-btn:hover {
  background-color: #5e005e;
}